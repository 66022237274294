/* eslint-disable */
import React from 'react';

function withWindowDimensions(WrappedComponent) {
    return class extends React.Component {
        state = { width: 0, height: 0 };

        componentDidMount() {
            this.updateWindowDimensions();
            window.addEventListener('resize', this.updateWindowDimensions);
        }

        componentWillUnmount() {
            window.removeEventListener('resize', this.updateWindowDimensions);
        }

        updateWindowDimensions = () => {
            this.setState({
                width: window.innerWidth,
                height: window.innerHeight
            });
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    windowWidth={this.state.width}
                    windowHeight={this.state.height}
                />
            );
        }
    };
}

type WindowDimensionProps = {
    windowWidth: number;
    windowHeight: number;
};

export type { WindowDimensionProps }

export default withWindowDimensions;
