/* eslint-disable */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Table } from 'react-bootstrap';
import { ContactAvailabilitySummaryModel, Skill } from '../../store/Availability';
import { ContactListDto } from '../../api/contacts/ResponseTypes';
import { Link as RouterLink } from 'react-router-dom';
import history from '../../history';
import theme from '../../css/theme';
import { DateFormats, simpleSearch } from '../../constants';
import ProfileApi from '../../api/profile/Profile';
import CompanyApi from '../../api/company/Company';
import { Period } from './CreateOffer';
import { ProfileImage } from '../ui-components/ProfileImage';
import { AsyncOverlay } from '../ui-components/AsyncOverlay';
import SmallLabel from '../ui-components/SmallLabel';
import SmallInput from '../ui-components/SmallInput';
import SmallSelect from '../ui-components/SmallSelect';
import WarningText from '../ui-components/WarningText';
import SimpleTooltip from '../ui-components/SimpleTooltip';
import Dialog from '../ui-components/Dialog';
import { WithContext as ReactTags } from 'react-tag-input';
import LookupsAPI from 'src/api/lookups/Lookups';
import * as ContactAvailabilityStore from '../../store/Availability';
import '../../css/ReactTagsOverrides.css';
import ReactStars from "react-rating-stars-component";
import SignalRService from '../../services/signalr.service';

interface Props {
    skills: Skill[],
    setSkills: (
        skills: Skill[]
    ) => void;
    warningTextStyle?: React.CSSProperties;
    isLoadingContacts: boolean;
    contacts: ContactAvailabilitySummaryModel[];
    offerPeriods: Period[];
    listId: number;
    lists: ContactListDto[];
    handleListChange: (listId: number) => void;
    selectWorker: (
        workerId: ContactAvailabilitySummaryModel['contact']['userId']
    ) => void;
    selectedWorkerIds: Array<
        ContactAvailabilitySummaryModel['contact']['userId']
    >;
    showWorkerList?: boolean;
    showToggleAll?: boolean;
    hideFilters?: boolean;
    hideAvailability?: boolean;
    hideExpand?: boolean;
}

let workerSelectorChunkedHubConnections: any[] = [];

const WorkerSelector = ({
    skills,
    setSkills,
    isLoadingContacts,
    contacts,
    lists,
    listId,
    handleListChange,
    selectWorker,
    selectedWorkerIds,
    offerPeriods,
    warningTextStyle,
    showWorkerList,
    showToggleAll,
    hideFilters,
    hideAvailability,
    hideExpand
}: Props) => {
    const [filterValue, setFilterValue] = useState('');
    const [hasScrolled, setHasScrolled] = useState(false);
    const [skillSuggestions, setSkillSuggestions] = useState([]);
    const [skillsQueryKeywords, setSkillsQueryKeywords] = useState("");
    const [expanded, setExpanded] = useState(false);

    useEffect(() => {
        setSkillsQueryKeywords("");
        setFilterValue("");
        getSkillSuggestions("", true);
        (window as any).onSignalREventsUpdatedWorkerSelector = (userId: string) => {
            handleListChange(listId)
        }
    }, [])

    async function terminateChunkedSignalRConnections () {
        while (workerSelectorChunkedHubConnections.length) {
            let connection = workerSelectorChunkedHubConnections.pop();
            // @ts-ignore
            if (connection && connection.connection.connectionState === 1) {
                connection.stop();
            }
        }
    };

    async function getSkillSuggestions(keyword: string, force?: boolean) {
        
        if (!force) {
            clearTimeout((window as any).getSkillSuggestionsDebounce);
            (window as any).getSkillSuggestionsDebounce = setTimeout(() => {
                getSkillSuggestions(keyword, true);
            }, 400)
            return;
        }

        if (keyword.trim().length !== 0) {
            let data = await LookupsAPI.subSectors(keyword.trim(), 0);
            // @ts-ignore
            if (data && data.data) {
                // @ts-ignore 
                setSkillSuggestions(data.data.map(item => { return { id: item.id + "", text: item.value } }))
            } else {
                setSkillSuggestions([])
            }
        }
        
        setTimeout(() => {
            if (keyword === "") {
                let skillsWithoutPendingInput: ContactAvailabilityStore.Skill[] = skills || [];
                skillsWithoutPendingInput = skillsWithoutPendingInput.filter(item => !item.pendingAdd);
                if (skills?.length !== skillsWithoutPendingInput.length) {
                    setSkills(skillsWithoutPendingInput);

                }
            } else {
                let newSkills: ContactAvailabilityStore.Skill[] = skills || [];
                newSkills = newSkills.filter(item => !item.pendingAdd);
                const alreadyExists = newSkills.find(item => item.text === keyword);
                if (!alreadyExists) {
                    newSkills.push({ text: keyword, id: keyword, pendingAdd: true })
                    setSkills(newSkills);
                }
            }
        }, 1000)
    }

    function renderContacts(hideAvailability) {

        let base = contacts.map(({ contact }) => contact);
        let searched = hideAvailability ? base : simpleSearch(
            filterValue,
            base,
            ['fullName']
        )

        return searched.sort((a, b) => {
            // Sorting to the start if user id is present in the url
            if (window.location.href.indexOf(a.userId) !== -1) {
                return -1;
            }
            if (window.location.href.indexOf(b.userId) !== -1) {
                return 1;
            }
            return 0;
        }).map((contact) => {
            const imageUrl = ProfileApi.getProfileImageUrl(
                contact.userId
            ).split('?')[0];

            return (
                <ContactRow
                    key={`contact-${contact.userId}`}
                    onClick={() => selectWorker(contact.userId)}
                    data-fullname={contact.firstName + " " + contact.lastName}
                    className={
                        selectedWorkerIds.indexOf(contact.userId) >= 0
                            ? 'selectable-contanct selected'
                            : 'selectable-contanct'
                    }
                >
                    <td
                        className="layout horizontal center"
                        style={{
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            position: "relative",
                            display: "flex",
                            width: hideAvailability ? '100%': '230px'
                        }}
                    >
                        <ProfileImage
                            type={'Organisation'}
                            selectable={false}
                            url={imageUrl}
                            size={
                                document.documentElement.clientHeight < 720
                                    ? 24
                                    : 32
                            }
                            style={{
                                border: "none",
                                padding: 0,
                                borderRadius: "100%",
                                overflow: "hidden"
                            }}
                            data-report-blurred={contact.reported}
                            data-user-id={contact.userId}
                        />
                        { contact.smsNotifications &&
                            <SimpleTooltip
                                id="sms-status"
                                text={`${contact.firstName} has SMS notifications enabled`}
                            >
                                <i
                                    className="fas fa-comment m-l-xs m-r-xs"
                                    style={{
                                        fontSize: '16px',
                                        opacity: 1,
                                        position: 'absolute',
                                        top: 13,
                                        left: -4,
                                        color: theme.colours.blue2
                                    }}
                                />
                            </SimpleTooltip>
                        }
                        <SimpleTooltip
                            id="contact-verified-status"
                            text={`${contact.firstName}'s identity has ${
                                !contact.verified ? 'not' : ''
                            } been verified`}
                        >
                            <i
                                className="fa fa-shield m-l-xs m-r-xs"
                                style={{
                                    color: contact.verified
                                        ? theme.colours.green
                                        : theme.colours.red,
                                    fontSize: '18px',
                                    opacity: 1,
                                    position: 'absolute',
                                    top: 12,
                                    left: 24
                                }}
                            />
                        </SimpleTooltip>
                        <div 
                            className='side'
                            style={{
                                height: 32,
                                paddingLeft: 14,
                                display: "flex",
                                alignItems: "center",
                                flexWrap: "wrap",
                                maxWidth: "calc(100% - 32px)"
                            }}
                        >
                            <span
                                data-report-blurred={contact.reported}
                                data-user-id={contact.userId}
                                style={{
                                   flexBasis: '100%'
                                }}
                            >{contact.fullName || (contact.firstName + " " + contact.lastName)}</span>
                            { (contact.ownRatingStars || (contact.representedByOrganisations && contact.representedByOrganisations?.length !== 0) || (contact.matchedKeywords && contact.matchedKeywords?.length !== 0)) &&
                                <div className='bottom'>
                                    { (contact.ownRatingStars) &&
                                        <div 
                                            className='stars-wrapper'
                                            data-stars={contact.ownRatingStars}
                                        >
                                            <ReactStars
                                                value={contact.ownRatingStars}
                                                count={5}
                                                size={12}
                                                edit={false}
                                                activeColor={"#ffd700"}
                                            />
                                        </div>
                                    }
                                    { (!hideAvailability && contact.matchedKeywords && contact.matchedKeywords?.length !== 0) &&
                                        <SimpleTooltip
                                            id="contact-verified-status"
                                            text={contact.matchedKeywords?.map(skill => skill.match).join(", ")}
                                        >
                                            <label className='contact-skills-badge'>
                                                {contact.matchedKeywords?.length}
                                            </label>
                                        </SimpleTooltip>
                                    }
                                    <div className='represented-by'>
                                        { (contact.representedByOrganisations && contact.representedByOrganisations?.length !== 0) ?
                                            contact.representedByOrganisations.map((organisation, index) => {
                                                return (
                                                    <SimpleTooltip
                                                        id="contact-represented-by"
                                                        text={"Represented, Verified and Introduced* by " + organisation.organisationName}
                                                    >
                                                        <img 
                                                            style={{
                                                                width: 16,
                                                                height: 16,
                                                                borderRadius: 100,
                                                                marginRight: 4,
                                                                marginTop: -3
                                                            }} 
                                                            src={CompanyApi.getOrganisationProfileImageUrl(organisation.organisationId)}
                                                        />
                                                    </SimpleTooltip>
                                                )
                                            })
                                        : null }
                                    </div>
                                </div>
                            }
                        </div>
                    </td>
                    {!hideAvailability && offerPeriods.map((p) => (
                        <td
                            key={`avail-${contact.userId}-${p.date}-${p.timePreset}`}
                            style={{
                                textAlign: 'center',
                                minWidth: '55px',
                                minHeight: '43px',
                                padding: '8px 0'
                            }}
                        >
                            {renderDayAvailability(contact, p)}
                        </td>
                    ))}
                </ContactRow>
            );
        });
    }

    function renderPeriodHeader(period: Period) {
        const formattedStart = `${('0' + period.customStart.hour).slice(-2)}:${(
            '0' + period.customStart.minute
        ).slice(-2)}`;
        const formattedEnd = `${('0' + period.customEnd.hour).slice(-2)}:${(
            '0' + period.customEnd.minute
        ).slice(-2)}`;

        return (
            <th className='period-header' key={`period-header-${period.date}-${period.timePreset}`}>
                <span>{period.date.substr(0, period.date.length - 5)}</span>
                <br />
                <span>{`${formattedStart} - ${formattedEnd}`}</span>
            </th>
        );
    }

    function renderDayAvailability(
        contact: ContactAvailabilitySummaryModel['contact'],
        period: Period
    ) {
        const occupancies = contacts.find((c) => c.contact.userId === contact.userId)
            ?.occupancy.occupancies!;

        const occupancy = occupancies.find((o) => {
            // @ts-ignore
            return moment(o.interval.start).isSame(
                moment(period.date, DateFormats.ShortDateWithDay),
                'day'
            );
        });

        // @ts-ignore
        if (occupancy && occupancy.interval) {
            // @ts-ignore
            if (occupancy.interval.occupiedPercentage == 0) {
                return <i className="fa fa-check text-success fa-2x day-availability" />;
            } else {
                return <i className="fa fa-times text-danger fa-2x day-availability" />;
            }
        }
    }

    return (
        <Wrapper className="worker-selector" data-expanded={expanded}>
            { (!hideFilters || expanded) &&
                <div className="filters">
                    <div>
                        <SmallInput
                            id="filterName"
                            placeholder="Search by Name"
                            value={filterValue}
                            onChange={(ev) => setFilterValue(ev.target.value)}
                        />
                    </div>
                    <div>
                        <SmallInput
                            id="filterSkill"
                            placeholder="Search by Skill"
                            value={skillsQueryKeywords}
                            onChange={(ev) => {
                                setSkillsQueryKeywords(ev.target.value);
                                getSkillSuggestions(ev.target.value)
                            }}
                        />
                    </div>
                    <div className="layout horizontal center">
                        {/* <SmallLabel>Filter Contacts by List:</SmallLabel> */}
                        <SmallSelect
                            value={listId}
                            onChange={(ev) =>
                                handleListChange(Number(ev.target.value))
                            }
                        >
                            {lists
                                .sort((a, b) => {
                                    if (a.name === 'EVERYONE') return -1;
                                    if (b.name === 'EVERYONE') return 1;
                                    return a.name.localeCompare(b.name);
                                })
                                .map((l) => (
                                    <option key={`list-${l.id}`} value={l.id}>
                                        {l.name}
                                    </option>
                                ))}
                        </SmallSelect>
                    </div>
                </div>
            }
            <div className='filters options'>
                {!isLoadingContacts && contacts.length && showToggleAll && !expanded ? (
                    <div className="select-deselect" onClick={() => {
                        if (selectedWorkerIds && selectedWorkerIds.length !== 0) {
                            for (let i = 0; i < selectedWorkerIds.length; i++) {
                                selectWorker(selectedWorkerIds[i]);
                            }
                        } else {
                            simpleSearch(
                                filterValue,
                                contacts.map(({ contact }) => contact),
                                ['fullName']
                            ).map((contact) => {
                                selectWorker(contact.userId);
                            })
                        }
                    }}>
                        <button>{(selectedWorkerIds && selectedWorkerIds.length !== 0) ? "Deselect all" : "Select everyone" }</button>
                    </div>
                ) : null}
                { (!hideExpand) &&
                    <div className='expand-minimize' onClick={() => {
                        setExpanded(!expanded);
                    }}>
                        <button>
                            { (expanded) ?
                                <span>Save and Close</span> :
                                <i className="fas fa-expand-alt"></i>
                            }
                        </button>
                    </div>
                }
            </div>
            {!isLoadingContacts && !contacts.length ? (
                <>
                    <div
                        className="layout vertical center-center"
                        style={{
                            minHeight: '100px',
                            ...warningTextStyle
                        }}
                    >
                        <Dialog
                            type="error"
                            body={
                                'No contacts matching the filters. Visit the invite page to add new contacts, or adjust your filters.'
                            }
                            onClick={() => { history.push("/invite") }}
                        />
                    </div>
                </>
            ) : (
                <div 
                    className="table-wrapper"
                    style={{
                        maxHeight: showWorkerList || hasScrolled ? 238 : 152,
                        marginBottom: hideAvailability ? 12 : undefined
                    }}
                    onScroll={() => {
                        if (!hasScrolled)
                            setHasScrolled(true)
                    }}
                >
                    { !hideAvailability &&
                        <React.Fragment>
                            <Table 
                                condensed 
                                style={{ 
                                    marginBottom: '0', 
                                    maxWidth: 'unset', 
                                    width: (offerPeriods.length*86 + 234)
                                }}
                            >
                                <thead>
                                    <tr>
                                        <th className="name-header">Availability</th>
                                        {offerPeriods.map((p) => renderPeriodHeader(p))}
                                    </tr>
                                </thead>
                            </Table>
                            <div className="flex" style={{ overflowY: 'auto', width: (offerPeriods.length*86 + 234) }}>
                                <Table condensed hover style={{ marginBottom: '5px' }}>
                                    <tbody>{renderContacts(hideAvailability)}</tbody>
                                </Table>
                                <AsyncOverlay show={isLoadingContacts} />
                            </div>
                        </React.Fragment>
                    }
                    { hideAvailability &&
                        <React.Fragment>
                            <Table condensed hover style={{ marginBottom: '5px' }}>
                                <tbody>{renderContacts(hideAvailability)}</tbody>
                            </Table>
                            <AsyncOverlay show={isLoadingContacts} />
                        </React.Fragment>
                    }
                </div>
            )}  
        </Wrapper>
    );
};

const Wrapper = styled.div`
    position: relative;

    &[data-expanded="true"] {
        position: fixed;
        z-index: 100;
        background: white;
        top: 0;
        left: 0;
        padding: 10px;
        height: 100%;
        width: 100vw;

        .table-wrapper {
            margin-top: 0;
            max-width: unset !important;
            max-height: 100% !important;
            overflow: scroll !important;
            padding-bottom: 80px;
            z-index: 1;
            position: relative;
        }
    }

    .contact-skills {
        margin: 6px 0 0 0;

        &>div {
            white-space: nowrap;
            border-radius: 5px;
            display: inline-block;
            padding: 2px 8px;
            overflow: hidden;
            margin: 0 8px 8px 0;
            color: white;
        }
    }

    .contact-skills-badge {
        background: ${theme.colours.darkBlue};
        color: white;
        width: 20px;
        height: 20px;
        display: inline-block;
        margin: 0;
        padding: 1px 7px;
        border-radius: 52px;
        position: absolute;
        top: 11px;
        right: 6px;
    }

    .filter.skills {
        margin-top: 10px;

        .ReactTags__tagInput {
            background: ${theme.colours.blue2};
        }

        input {
            color: white;
        }

        input::placeholder {
            color: white; opacity: 1;
        }
            
        input:-ms-input-placeholder {
            color: white;
        }
        
        input::-ms-input-placeholder {
            color: white;
        }
    }

    .select-deselect {
        min-width: 140px;
    }

    .select-deselect, .expand-minimize {
        margin: 0;
        text-align: center;
        margin-left: 10px;

        button {
            width: 100%;
            display: block;
            background-color: ${theme.colours.darkBlue};
            padding: 9px 12px 10px 12px;
            color: white;
            border: none;
            border-radius: 6px;
        }
    }

    .table-wrapper {
        width: 100%;
        overflow: scroll;
        margin-top: 15px;
        box-shadow: inset 0px 11px 8px -10px #ccc, inset 0px -11px 8px -10px #ccc;
        max-height: 246px;
        padding-bottom: 48px;

        table {
            th,
            td {
                width: 86px;
                float: left;
                &:first-child,
                &:first-child {
                    width: 230px;
                    display: inline-block;
                }
            }
        }
    }

    .filters {
        display: flex;
        align-items: center;
        justify-content: space-around;
        & > div {
            flex-basis: 100%;

            &:nth-child(2),
            &:nth-child(3)
            {
                margin-left: 10px;
            }

            input, select {
                width: 100%;
                padding: 6px 8px;
                height: auto;
                border: 1px solid #bfbfbf !important;
                border-radius: 4px;
                margin: 0;
            }

            select {
                padding-left: 4px;
            }
        }
    }

    .filters.options {
        position: absolute;
        bottom: 15px;
        right: 15px;
        z-index: 2;
    }

    &[data-expanded="true"] {
        .filters:not(.options) {
            padding-bottom: 10px;
        }

        .filters.options {
            bottom: 15px;
        }
    }

    @media (max-width: 1020px) {

        .filters:not(.options) {
            flex-wrap: wrap;

            & > div {

                &:nth-child(1),
                &:nth-child(2) {
                    flex-basis: calc(50% - 5px);
                }
    
                &:nth-child(3) {
                    flex-basis: 100%;
                    margin: 10px 0 0 0;
                }

            }

            .select-deselect {
                margin: 10px 0 0px 0;
            }

        }

    }
`;

const ContactRow = styled.tr`
    cursor: pointer;

    &:not(.selected):hover > td {
        background-color: #e6f2ff;
    }

    &.selected > td {
        background-color: #b8daff !important;
    }

    > td {
        vertical-align: middle !important;
    }

    .bottom {
        display: flex;
        align-items: center;

        &>div {
            display: inline-block;

            &.stars-wrapper {
                margin-right: 6px;
            }
        }
    }
`;

export default WorkerSelector;
