/* eslint-disable */
import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';

import AvailabilityApi from '../../api/availability/Availability';
import { ContactAvailabilitySummaryModel } from '../../store/Availability';
import Analytics from '../../services/analytics.service';
import InternalTracker from '../../InternalTracker';

export interface ContactRemovalProviderPassedProps {}

export type WorkerToRemoveModel = Pick<
    ContactAvailabilitySummaryModel,
    'contact'
>;

export interface ContactRemovalProviderProps {
    displayConfirmationModal: boolean;
    blockBecauseOfRating?: boolean;
    closeConfirmationModal: () => void;
    workersToRemove: WorkerToRemoveModel[];
    onSuccess?: Function;
    onFailure?: Function;
    render: (ContactRemovalProviderPassedProps) => JSX.Element;
}

class ContactRemovalProvider extends React.Component<
    ContactRemovalProviderProps,
    {}
> {
    render() {
        const {
            displayConfirmationModal,
            closeConfirmationModal,
            workersToRemove,
            render
        } = this.props;

        return (
            <>
                {this.props.displayConfirmationModal &&
                    workersToRemove.length !== 0 && (
                        <Modal
                            show={displayConfirmationModal}
                            onHide={closeConfirmationModal}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    {this.props.blockBecauseOfRating ? "Do you want to also block this contact?" : "Remove Contact"}
                                    {workersToRemove.length !== 1 ? 's' : ''}
                                </Modal.Title>
                            </Modal.Header>
                            { (this.props.blockBecauseOfRating) ?
                                <Modal.Body>
                                    <p>Blocking will remove this contact from the availability grid, and will also prevent them to add you again.</p>
                                </Modal.Body>
                                :
                                <Modal.Body>
                                    <p>
                                        You are about to remove{' '}
                                        {workersToRemove.length === 1
                                            ? workersToRemove[0].contact.fullName
                                            : `${workersToRemove.length} contacts`}{' '}
                                        from your availability grid.
                                    </p>
                                    <p>
                                        You will no longer see their availability
                                        unless you invite them to connect again.
                                    </p>
                                    <p>
                                        If they have not updated their availability
                                        recently consider nudging them instead.
                                    </p>
                                    <p>
                                        Blocking {' '}
                                        {workersToRemove.length === 1
                                            ? workersToRemove[0].contact.fullName
                                            : `${workersToRemove.length} contacts`}{' '}
                                        will prevent them from adding you again as a contact
                                    </p>
                                </Modal.Body>
                            }
                            { (this.props.blockBecauseOfRating) ?
                                <Modal.Footer>
                                    <Button onClick={closeConfirmationModal}>
                                        Keep Contact
                                    </Button>
                                    <Button
                                        onClick={() => {this.removeWorkers(true)}}
                                        bsStyle="danger"
                                        id="confirm-remove-worker-btn"
                                    >
                                        Block Contact
                                        {workersToRemove.length !== 1 ? 's' : ''}
                                    </Button>
                                </Modal.Footer>
                                :
                                <Modal.Footer>
                                    <Button onClick={closeConfirmationModal}>
                                        Cancel
                                    </Button>
                                    <Button
                                        onClick={() => {this.removeWorkers(false)}}
                                        bsStyle="danger"
                                        id="confirm-remove-worker-btn"
                                    >
                                        Remove Contact
                                        {workersToRemove.length !== 1 ? 's' : ''}
                                    </Button>
                                    <Button
                                        onClick={() => {this.removeWorkers(true)}}
                                        bsStyle="danger"
                                        id="confirm-remove-worker-btn"
                                    >
                                        Block Contact
                                        {workersToRemove.length !== 1 ? 's' : ''}
                                    </Button>
                                </Modal.Footer>
                            }
                        </Modal>
                    )}
                {render({})}
            </>
        );
    }

    private removeWorkers = (block: boolean) => {
        const {
            workersToRemove,
            onSuccess,
            onFailure,
            closeConfirmationModal
        } = this.props;

        closeConfirmationModal();
        const workerIds = workersToRemove.map(({ contact }) => contact.userId);

        InternalTracker.trackEvent(block ? "Contact Blocked" : "Contact Removed", {
            userIds: workerIds
        })

        AvailabilityApi.removeWorkers(workerIds, block)
            .then(() => {
                toast.success(
                    `Contact${workerIds.length !== 1 ? 's' : ''} ${ block ? 'blocked' : 'removed' }`
                );
                if (onSuccess) onSuccess();
            })
            .catch(() => {
                if (onFailure) onFailure();
            });
    };
}

export default ContactRemovalProvider;
