import React from 'react';
import styled from 'styled-components';

import Circle from './Circle';
import Description from './Description';
import Icon from './Icon';
import { Popovers } from '../Tasks';
import TaskPopover from './TaskPopover';
import DashboardContainer from '../../../state-containers/dashboard.container';

interface props {
    iconName: string;
    description: string;
    subtitle: string;
    popover: Popovers;
    selected: boolean;
    onClick: (number) => any;
    completed: boolean;
    index: number;
    first: boolean;
    last: boolean;
    handlePrevious: (MouseEvent) => void;
    handleNext: (MouseEvent) => void;
    dashboardContainer: DashboardContainer;
    dashboardReloader: () => void;
    pulse: boolean;
}

export default class Index extends React.Component<props, {}> {
    public render() {
        return (
            <Task onClick={this.props.onClick}>
                {/* <TaskPopover
                    completed={this.props.completed}
                    which={this.props.popover}
                    visible={this.props.selected}
                    index={this.props.index}
                    first={this.props.first}
                    last={this.props.last}
                    handlePrevious={this.props.handlePrevious}
                    handleNext={this.props.handleNext}
                    description={this.props.description}
                    subtitle={this.props.subtitle}
                    dashboardContainer={this.props.dashboardContainer}
                    dashboardReloader={this.props.dashboardReloader}
                /> */}
                <Circle checked={this.props.completed} pulse={this.props.pulse}>
                    <Icon
                        name={this.props.iconName}
                        completed={this.props.completed}
                    />
                </Circle>
                <Description completed={this.props.completed}>
                    {this.props.description}
                </Description>
            </Task>
        );
    }
}

const Task = styled.div`
    cursor: pointer;
    position: relative;
    align-items: center;
    margin: 0;
    flex-basis: 12.5%;
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    max-width: 96px;
    padding: 0 4px;
    z-index: 0;
`;
