/* eslint-disable */
export async function getValue(key) {
    if (!window.localIndexedDb) return null;
    let row = await window.localIndexedDb.keyValueCache.where({ key: key }).toArray();
    return row[0] ? row[0].value : null;
};

export async function setValue(key, value) {
    if (!window.localIndexedDb) return [];
    const res = await window.localIndexedDb.keyValueCache.put({ key: key, value: value });
    return res;
}

export async function deleteValue(key) {
    if (!window.localIndexedDb) return [];
    const res = await window.localIndexedDb.keyValueCache.delete(key);
    return res;
}