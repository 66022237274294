/* eslint-disable */
import React from 'react';
import { Button } from 'react-bootstrap';
import moment from 'moment';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import { Spinner } from '../ui-components/Spinner';
import { nameofFactory } from './../../constants';
import ClockPicker from '../ui-components/ClockPicker';
import { ApplicationState } from '../../store';
import Analytics from '../../services/analytics.service';
import InternalTracker from '../../InternalTracker';
import * as TimePresetsActionCreators from './../../store/timepresets/ActionCreators';
import * as TimePresetsModels from './../../store/timepresets/Models';
import styled from 'styled-components';
import theme from '../../css/theme';
import 
    withWindowDimensions, 
    { WindowDimensionProps } 
from '../../components/util/withWindowDimensions';

import Utilities from '../../Utilities';
import TimePresetSlider from './TimePresetSlider';

interface LocalState {
    editPreset: TimePresetsModels.TimePresetEdit;
    lastAddedPresetId: string | null;
    lastIds: string[],
    hintOptionBtn: null | string,
    justEditedId: null | string
}

export type SearchTimePresetEditorProps = Omit<
    TimePresetsModels.TimePresets,
    'selected'
> &
    typeof TimePresetsActionCreators.actionCreators & {
        getSearchTimePresets: any;
    };

const editPresetNameOf = nameofFactory<LocalState['editPreset']>();

export const ALL_DAY_PRESET_NAME = 'All Day';

const defaultPreset = {
    name: '',
    startHour: 9,
    startMinute: 0,
    endHour: 17,
    endMinute: 0,
    $id: null,
    userId: null,
    id: null,
    startTime: null,
    endTime: null
};

let lastHintedPreset: any = null;

export class SearchTimePresetEditor extends React.Component<
    SearchTimePresetEditorProps & WindowDimensionProps,
    LocalState
> {
    constructor(props) {
        super(props);
        this.state = {
            editPreset: {
                startHour: 9, // moment().hour(),
                startMinute: 0, // moment().minutes(),
                endHour: 17, // moment().hour(),
                endMinute: 0, // moment().minutes(),
                name: ''
            },
            lastAddedPresetId: "",
            lastIds: [],
            hintOptionBtn: null,
            justEditedId: null
        };

        this.removeTimePreset = this.removeTimePreset.bind(this);
        this.editTimePreset = this.editTimePreset.bind(this);
    }

    componentDidMount() {
        this.props.getSearchTimePresets();
        this.props.presets.forEach((value, index) => {
            this.props.editSearchTimePresetEnd(value.userId);
        });
    }

    newRowRef = React.createRef<HTMLTableRowElement>();

    componentDidUpdate(prevProps) {
    }

    startTimePresetEdit(id) {
        // Excludes un-needed keys
        const { startTime, endTime, userId, id: _, ...editPreset } =
            this.props.presets.find((preset) => preset.id === id) ||
            defaultPreset;
        this.setState({ editPreset });
        this.props.editSearchTimePresetStart(id);
    }

    editTimePreset() {
        const editedName = this.state.editPreset.name;
        const presetsWithSameName = this.props.presets.filter(preset => preset.name === editedName && preset.id !== this.props.editing);
        if (presetsWithSameName.length && editedName !== "Untitled") {
            toast.error("You already have a preset with this name, please choose a different name");
            return;
        }
        this.setState({
            justEditedId: this.props.editing || null
        }, () => {
            setTimeout(() => {
                this.setState({
                    justEditedId: null
                })
            }, 1200)
        })
        this.props.editSearchTimePresetSubmit(
            this.props.editing as string,
            {
                ...this.state.editPreset,
                name: this.state.editPreset.name,
                startHour: Math.round(this.state.editPreset.startHour),
                startMinute: Math.round(this.state.editPreset.startMinute),
                endHour: Math.round(this.state.editPreset.endHour),
                endMinute: Math.round(this.state.editPreset.endMinute),
            }
        );
        InternalTracker.trackEvent('Preset Edited');
    }

    setEditPresetValue = (key: keyof LocalState['editPreset'], value) => {
        this.setState(({ editPreset }) => ({
            editPreset: {
                ...editPreset,
                [key]: value
            }
        }))
    };

    commitEditedToState = () => {
        let newPresets = JSON.parse(JSON.stringify(this.props.presets));
        newPresets = newPresets.map(preset => {
            if (preset.id === this.props.editing) {
                preset = {
                    ...preset,
                    ...this.state.editPreset
                }
            }
            return preset;
        });
        this.props.editLocalList(newPresets)
        return newPresets;
    }

    commitDeletedToState = (deletedId) => {
        let newPresets = JSON.parse(JSON.stringify(this.props.presets));
        newPresets = newPresets.filter(preset => preset.id !== deletedId);
        console.log(JSON.parse(JSON.stringify(this.props.presets)), "====>", JSON.parse(JSON.stringify(newPresets)))
        this.props.editLocalList(newPresets)
    }

    removeTimePreset(id: string) {
        this.props.removeSearchTimePreset(id);
        InternalTracker.trackEvent('Preset Removed');
    }

    public render() {
        const presets = sortTimePresets(this.props.presets, this.state.lastAddedPresetId);

        return (
            <Wrapper>
                <Spinner text="Loading Presets" hide={this.props.loaded} />
                {this.props.loaded && (
                    <React.Fragment>
                        <div className="table">
                            {presets.filter(preset => preset.name !== "Custom").map((preset, presetI) => {
                                return (
                                    <div
                                        key={`a-time-preset-${preset.id + '' + preset.createdAt}`}
                                        data-testid="preset-row"
                                        className='preset-row'
                                        data-id={preset.id}
                                        data-just-added={preset.id === this.state.lastAddedPresetId}
                                        ref={preset.id === this.state.lastAddedPresetId ? this.newRowRef : null}
                                        data-editing={(this.props.editing && this.props.editing === preset.id)}
                                    >
                                        { (this.props.editing && this.props.editing !== preset.id) &&
                                            <div className='mask'>
                                                <p>Save the currently edited preset, to make further changes.</p>
                                            </div>
                                        }
                                        { (!this.props.editing && "unsaved" === preset.id) &&
                                            <div className='mask'>
                                                <p>Saving...</p>
                                            </div>
                                        }
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={this.props.editing === preset.id ? 
                                                (this.state.editPreset.name === "Untitled" ? "" : this.state.editPreset.name) : 
                                                (preset.name === "Untitled" ? "" : preset.name)
                                            }
                                            name={editPresetNameOf('name')}
                                            data-editing={this.props.editing === preset.id}
                                            disabled={preset.name === ALL_DAY_PRESET_NAME && presetI === 0}
                                            onChange={({ target: { name, value } }) => {
                                                if (ALL_DAY_PRESET_NAME === preset.name || this.props.editing !== preset.id) {
                                                    return
                                                }
                                                this.setEditPresetValue("name", value);
                                            }}
                                            onClick={() => {
                                                if (!this.props.editing) {
                                                    setTimeout(() => {
                                                        this.startTimePresetEdit(preset.id);
                                                    }, 100)
                                                }
                                            }}
                                            placeholder="Enter name"
                                            style={{
                                                width: '120px',
                                                display: 'inline-block'
                                            }}
                                        />
                                        <div
                                            style={{
                                                position: 'relative',
                                                width: 'calc(100% - 120px)'
                                            }}
                                        >
                                            <TimePresetSlider
                                                compactDrag={true}
                                                overnight={preset.startHour > preset.endHour}
                                                values={[preset.startHour + preset.startMinute / 60, preset.endHour + preset.endMinute / 60]}
                                                previewOnly={this.props.editing !== preset.id}
                                                previewOnlyInteraction={() => {
                                                    setTimeout(() => {
                                                        this.startTimePresetEdit(preset.id);
                                                    }, 100)
                                                }}
                                                onFinalChange={(values) => {
                                                    preset.startHour = Math.floor(values[0]);
                                                    preset.startMinute = Utilities.getDecimal(values[0]) * 60;
                                                    preset.endHour = Math.floor(values[1]);
                                                    preset.endMinute = Utilities.getDecimal(values[1]) * 60;
                                                    if (Math.round(preset.startMinute) === 60) {
                                                        preset.startHour++;
                                                        preset.startMinute = 0;
                                                    }
                                                    if (Math.round(preset.endMinute) === 60) {
                                                        preset.endHour++;
                                                        preset.endMinute = 0;
                                                    }
                                                    console.log(preset);
                                                    preset.startTime = 
                                                        (preset.startHour < 10 ? "0" + preset.startHour : preset.startHour) + 
                                                        ":" +
                                                        (preset.startMinute < 10 ? "0" + preset.startMinute : preset.startMinute);
                                                        preset!.endTime = 
                                                        (preset.endHour < 10 ? "0" + preset.endHour : preset.endHour) + 
                                                        ":" +
                                                        (preset.endMinute < 10 ? "0" + preset.endMinute : preset.endMinute);
                                                    console.log("Changed to: " + preset.startTime + " " + preset.endTime)
                                                    this.setEditPresetValue("startHour", preset.startHour)
                                                    this.setEditPresetValue("startMinute", preset.startMinute)
                                                    this.setEditPresetValue("endHour", preset.endHour)
                                                    this.setEditPresetValue("endMinute", preset.endMinute)
                        
                                                    InternalTracker.trackEvent('Preset Time Updated', {
                                                        preset: preset.name,
                                                        time: preset.startTime + "-" + preset.endTime
                                                    });
                                                }}
                                                onChange={(values) => {

                                                }}
                                            />
                                        </div>
                                        { (this.state.justEditedId === preset.id) && 
                                            <div className='just-edited'>
                                                <i className="fas fa-check"></i>
                                                Just Saved
                                            </div>
                                        }
                                        <div className="options">
                                            <i 
                                                className="fas fa-trash-alt"
                                                style={{
                                                    visibility: (preset.name === "All Day" || preset.id === this.props.editing) ? 'hidden' : 'visible'
                                                }}
                                                onClick={() => {
                                                    this.removeTimePreset(preset.id);
                                                    this.commitDeletedToState(preset.id);
                                                }}
                                            ></i>
                                        </div>
                                        { (this.props.editing && this.props.editing === preset.id) &&
                                            <div className='options-bottom'>
                                                <button 
                                                    onClick={() => {
                                                        const latestState = this.commitEditedToState();
                                                        if (this.props.editing === "unsaved") {
                                                            let newPreset = latestState.find(preset => preset.id === "unsaved");
                                                            if (newPreset) {
                                                                delete newPreset.createdAt;
                                                                newPreset.startHour = Math.round(newPreset.startHour),
                                                                newPreset.startMinute = Math.round(newPreset.startMinute),
                                                                newPreset.endHour = Math.round(newPreset.endHour),
                                                                newPreset.endMinute = Math.round(newPreset.endMinute),
                                                                this.props.createSearchTimePresetSubmit({
                                                                    ...newPreset
                                                                })
                                                            }
                                                        } else {
                                                            this.editTimePreset();
                                                        }
                                                    }}
                                                    className='pul se no-size'
                                                >
                                                    Save Preset
                                                </button>
                                            </div>
                                        }
                                    </div>
                                )
                            } )}
                        </div>
                    </React.Fragment>
                )}
            </Wrapper>
        );
    }
}

const Wrapper = styled.div`
    .table {
        margin-bottom: 0;
        border: 1px solid lightgrey;

        &>div {
            display: flex;
            padding: 8px;
            position: relative;

            .just-edited {
                position: absolute;
                top: 8px;
                right: 8px;
                background-color: ${theme.colours.green2};
                color: white;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 8px 14px;
                border-radius: 52px;

                i {
                    margin-right: 6px;
                }
            }

            .mask {
                position: absolute;
                top: 0;
                left: 0;
                background: rgba(255,255,255,0.75);
                width: 100%;
                height: 100%;
                align-items: center;
                justify-content: center;
                display: flex;
                padding: 0 20px;
                z-index: 2;

                p {
                    margin: 0;
                }
            }

            input {
                margin-right: 12px;
            }

            &:not(:last-child) {
                border-bottom: 1px solid lightgrey;
            }

            &[data-editing="true"] {
                padding-bottom: 44px;
            }

            .options-bottom {
                position: absolute;
                bottom: 0;
                left: 0;
                display: block;
                width: 100%;

                button {
                    background-color: ${theme.colours.green2};
                    color: white;
                    width: 100%;
                    display: block;
                    border: none;
                    padding: 6px;
                    font-size: 15px;
                    border-radius: 0;
                }
            }

            .options {
                align-items: center;
                justify-content: center;
                display: flex;
                margin-left: 10px;

                button {
                    padding: 7px 11px;
                    border: none;
                    border-radius: 52px;
                    background-color: #444;
                    color: white;
                    margin-right: 6px;
                }
    
                i {
                    width: 100%;
                    height: 100%;
                    display: inline-block;
                    color: ${theme.colours.red2};
                    text-align: center;
                    padding: 10px 6px;
                }
            }

            &[data-just-added="true"] {
                background-color: #b9b9b9;
            }
        }

        @media (max-width: 720px) {

            &>div {
                display: block;
    
                input {
                    margin-bottom: 6px;
                    width: calc(100% - 152px) !important;
                }
    
                &>div:not(.options) {
                    width: 100% !important;
    
                    .toggle {
                        position: absolute;
                        top: -38px;
                        right: 86px;
                        margin: 0;
                    }
    
                    .slider {
                        width: 100% !important;
                        flex-basis: 100% !important;
                        margin: 8px !important;
                    }
                }
    
                .options {
                    position: absolute;
                    top: 0;
                    right: 0;
                    margin: 10px;
                    height: 28px;
                }
            }
        }
    }
`;

export const sortTimePresets = (
    presets: TimePresetsModels.TimePreset[],
    justAdded?: null | string
): TimePresetsModels.TimePreset[] => {
    return presets.sort((a, b) => {
        // if (justAdded && a.id === justAdded) return -1;
        // if (justAdded && b.id === justAdded) return 1;
        if (a.name === ALL_DAY_PRESET_NAME) return -1;
        if (b.name === ALL_DAY_PRESET_NAME) return 1;
        if (a.id === "unsaved") return 1;
        if (b.id === "unsaved") return -1;
        return new Date(a.createdAt || "") < new Date(b.createdAt || "") ? -1 : 1;
        // return (
        //     a.startHour * 60 +
        //     a.startMinute -
        //     (b.startHour * 60 + b.startMinute)
        // );
    });
};


export default withWindowDimensions(connect(
    (state: ApplicationState) => state.timePresetManagement.timePresetMgtState, // get redux state object
    TimePresetsActionCreators.actionCreators
)(SearchTimePresetEditor));