import React from 'react';

function SvgUpdates(props) {
    return (
        <svg height="1em" viewBox="0 0 512 512" width="1em" {...props}>
            <path
                d="M512 256c0 23.418-3.145 46.102-9.04 67.656a252.963 252.963 0 01-13.937 38.496 253.584 253.584 0 01-11.965 23.04 255.371 255.371 0 01-13.269 20.374 256.2 256.2 0 01-21.348 25.864C395.754 481.027 329.488 512 256 512 114.613 512 0 397.387 0 256S114.613 0 256 0s256 114.613 256 256zm0 0"
                fill="#ffe152"
            />
            <path
                d="M263.102 280.973h232.015c9.324 0 16.88-7.559 16.88-16.88v-75.952c0-9.32-7.556-16.875-16.88-16.875H263.102c-9.32 0-16.875 7.554-16.875 16.875v75.953c-.004 9.32 7.554 16.879 16.875 16.879zm0 0"
                fill="#dde8ed"
            />
            <path
                d="M326.84 226.117c0 13.074-10.598 23.676-23.676 23.676-13.074 0-23.672-10.602-23.672-23.676 0-13.074 10.598-23.672 23.672-23.672 13.078 0 23.676 10.598 23.676 23.672zm0 0"
                fill="#66baed"
            />
            <path
                d="M346.746 197.125H489.02v20.371H346.746zm0 0M346.746 234.738H489.02v20.375H346.746zm0 0"
                fill="#3d4159"
            />
            <path
                d="M502.96 323.656a252.963 252.963 0 01-13.937 38.496 253.584 253.584 0 01-11.965 23.04 255.371 255.371 0 01-13.269 20.374 256.2 256.2 0 01-21.348 25.864H263.105c-9.32 0-16.886-7.555-16.886-16.875v-75.957c0-9.32 7.566-16.875 16.886-16.875h232.008c2.832 0 5.508.703 7.848 1.933zm0 0"
                fill="#dde8ed"
            />
            <path
                d="M326.84 376.574c0 13.078-10.598 23.676-23.676 23.676-13.074 0-23.672-10.598-23.672-23.676 0-13.074 10.598-23.672 23.672-23.672 13.078 0 23.676 10.598 23.676 23.672zm0 0"
                fill="#3fb777"
            />
            <path
                d="M489.023 347.586v14.566a231.984 231.984 0 01-2.738 5.809H346.75v-20.375zm0 0M477.059 385.191a255.371 255.371 0 01-13.27 20.375H346.75v-20.375zm0 0"
                fill="#3d4159"
            />
            <path
                d="M306.887 449.578v29.246a254.105 254.105 0 01-88.535 30.426c-42.715-6.297-82.004-23.14-115.118-47.8a257.463 257.463 0 01-43.875-41.528c11.684-35.649 43.875-62.024 82.641-65.156 2.57-.211 5.164-.317 7.785-.317h61.953c7.325 0 14.45.817 21.293 2.383 42.301 9.676 73.856 47.531 73.856 92.746zm0 0"
                fill="#dd3033"
            />
            <path
                d="M306.887 449.578v57.363A257.471 257.471 0 01256 512c-9.082 0-18.047-.469-26.887-1.398-46.883-4.891-89.996-22.438-125.879-49.153v-11.87c0-52.54 42.602-95.13 95.149-95.13h13.355c23.239 0 44.535 8.328 61.055 22.164v.008a95.475 95.475 0 0123.625 29.531c6.695 13.02 10.469 27.785 10.469 43.426zm0 0"
                fill="#f74f4f"
            />
            <path
                d="M87.652 249.535c0 15.711-11.476 28.742-26.507 31.16a31.688 31.688 0 01-5.06.403c-17.437 0-31.562-14.125-31.562-31.563s14.125-31.566 31.563-31.566c1.727 0 3.414.14 5.059.406 15.03 2.418 26.507 15.445 26.507 31.16zm0 0"
                fill="#d3a489"
            />
            <path
                d="M206.3 388.773l-25.534 40.961-25.54-40.96"
                fill="#dde8ed"
            />
            <path
                d="M203.223 506.547a253.223 253.223 0 01-41.692-12.55l19.235-74.919zm0 0"
                fill="#dde8ed"
            />
            <path
                d="M87.652 249.535c0 15.711-11.476 28.742-26.507 31.16a117.376 117.376 0 01-4.192-31.16c0-10.785 1.457-21.234 4.192-31.16 15.03 2.418 26.507 15.445 26.507 31.16zm0 0"
                fill="#bf8e75"
            />
            <path
                d="M67.367 249.535c0 6.23-5.05 11.281-11.281 11.281-6.23 0-11.281-5.05-11.281-11.28 0-6.231 5.05-11.282 11.28-11.282 6.231 0 11.282 5.05 11.282 11.281zm0 0"
                fill="#bf8e75"
            />
            <path
                d="M180.754 393.395l-42.828 23.8-13.028-60.601 26.614-15.676M180.754 393.395l42.832 23.8 13.027-60.601-26.61-15.676"
                fill="#dd3033"
            />
            <path
                d="M273.867 249.535c0 15.711 11.48 28.742 26.512 31.16a31.64 31.64 0 005.055.403c17.437 0 31.566-14.125 31.566-31.563s-14.129-31.566-31.566-31.566c-1.723 0-3.414.14-5.055.406-15.031 2.418-26.512 15.445-26.512 31.16zm0 0"
                fill="#d3a489"
            />
            <g fill="#bf8e75">
                <path d="M273.867 249.535c0 15.711 11.48 28.742 26.512 31.16a117.374 117.374 0 004.187-31.16c0-10.785-1.457-21.234-4.187-31.16-15.031 2.418-26.512 15.445-26.512 31.16zm0 0" />
                <path d="M316.715 249.535c0 6.23-5.05 11.281-11.281 11.281-6.23 0-11.282-5.05-11.282-11.28 0-6.231 5.051-11.282 11.282-11.282 6.23 0 11.28 5.05 11.28 11.281zm0 0" />
                <path d="M298.207 232.758c0 58.441-42.7 106.926-98.598 115.926a116.11 116.11 0 01-18.847 1.515c-64.867 0-117.442-52.578-117.442-117.441 0-30.692 22.418-76.762 41.692-97.684 21.46-23.3 41.574-19.762 75.75-19.762 6.422 0 12.718.508 18.847 1.516 55.899 9.004 98.598 57.484 98.598 115.93zm0 0" />
                <path d="M214.703 317.8v41.657c0 15.25-10.055 28.14-23.902 32.422a33.994 33.994 0 01-10.047 1.516c-18.742 0-33.934-15.196-33.934-33.938v-41.656zm0 0" />
            </g>
            <path
                d="M214.703 356.816v2.641c0 15.25-10.055 28.14-23.902 32.422-12.496-3.844-21.895-14.707-23.63-28.004a116.144 116.144 0 0025.688-1.309 115.074 115.074 0 0021.844-5.75zm0 0M298.207 232.758c0 58.441-42.7 106.926-98.598 115.926-55.898-9-98.593-57.485-98.593-115.926 0-35.988 16.187-68.2 41.691-89.746 15.898-13.446 35.43-22.723 56.902-26.184 52.118 8.387 92.766 51.11 98.016 104.219.383 3.851.582 7.765.582 11.71zm0 0"
                fill="#d3a489"
            />
            <path
                d="M180.762 295.824c-9.836 0-19.668-2.465-28.633-7.39a5.231 5.231 0 01-2.063-7.098 5.228 5.228 0 017.098-2.059c14.777 8.121 32.422 8.121 47.195 0a5.223 5.223 0 017.094 2.059 5.223 5.223 0 01-2.058 7.098c-8.965 4.925-18.801 7.39-28.633 7.39zm0 0"
                fill="#bf8e75"
            />
            <path
                d="M155.75 225.922c0 7.36-5.965 13.32-13.32 13.32-7.36 0-13.325-5.96-13.325-13.32s5.965-13.324 13.325-13.324c7.355 0 13.32 5.964 13.32 13.324zm0 0M232.418 225.922c0 7.36-5.965 13.32-13.32 13.32-7.36 0-13.325-5.96-13.325-13.32s5.965-13.324 13.325-13.324c7.355 0 13.32 5.964 13.32 13.324zm0 0"
                fill="#3d4159"
            />
            <path
                d="M153.035 223.57a5.71 5.71 0 01-11.418 0 5.71 5.71 0 0111.418 0zm0 0M230.355 223.57a5.706 5.706 0 11-11.414 0 5.706 5.706 0 1111.414 0zm0 0"
                fill="#fff"
            />
            <path
                d="M194.086 259.465H167.44a5.746 5.746 0 110-11.492h26.645a5.746 5.746 0 110 11.492zm0 0"
                fill="#bf8e75"
            />
            <path d="M219.43 446.453h51.199v15.676h-51.2zm0 0" fill="#dd3033" />
            <path
                d="M173.89 81.762c35.575 2.543 64.141 31.281 65.954 67.46l.336 6.79h20.46l11.028 73.3 26.7-8.265-3.587-71.824c-1.894-38-33.324-67.786-71.363-67.645zm0 0"
                fill="#2a2f4f"
            />
            <path
                d="M292.52 53.418c28.328 28.328 28.328 74.262 0 102.594-28.333 28.328-74.266 28.328-102.598 0zm0 0"
                fill="#3d4159"
            />
            <path
                d="M189.922 156.008h-89.035l-11.028 73.304-26.695-8.261 3.582-71.832c1.89-37.992 33.324-67.785 71.367-67.64l128.586.48 17.004 30.601"
                fill="#3d4159"
            />
            <path
                d="M187.633 81.762c-35.574 2.543-64.14 31.281-65.953 67.46l-.336 6.79h-20.465l-11.024 73.3-26.699-8.265 3.586-71.824c1.895-38 33.324-67.786 71.363-67.645zm0 0"
                fill="#2a2f4f"
            />
            <path
                d="M263.102 136.785h232.015c9.324 0 16.88-7.558 16.88-16.879V43.953c0-9.32-7.556-16.879-16.88-16.879H263.102c-9.32 0-16.875 7.559-16.875 16.88v75.952c-.004 9.32 7.554 16.88 16.875 16.88zm0 0"
                fill="#dde8ed"
            />
            <path
                d="M326.84 81.93c0 13.074-10.598 23.675-23.676 23.675-13.074 0-23.672-10.601-23.672-23.675 0-13.075 10.598-23.676 23.672-23.676 13.078 0 23.676 10.601 23.676 23.676zm0 0"
                fill="#f74f4f"
            />
            <path
                d="M346.746 52.934H489.02v20.375H346.746zm0 0M346.746 90.547H489.02v20.375H346.746zm0 0"
                fill="#3d4159"
            />
        </svg>
    );
}

export default SvgUpdates;
