/* eslint-disable */
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { DropdownButton, MenuItem, Button } from 'react-bootstrap';
import { Image } from 'react-bootstrap';
import ProfileAPI from '../../api/profile/Profile';

import { simpleSearch } from '../../constants';
import * as ContactActionCreators from '../../store/contacts/ActionCreators';
import { ApplicationState } from '../../store';
import { Spinner } from '../ui-components/Spinner';
import styled from 'styled-components';
import InternalTracker from '../../InternalTracker';
import ContactListModal from './ContactListModal';
import Utilities from 'src/Utilities';

export type ContactListProps = ConnectedProps<typeof connector>;

interface State {
    filterValue: string;
    addingGroupMembersModal: number | null;
}

const DEFAULT_LIST_NAME = 'EVERYONE';

export class ContactList extends React.Component<ContactListProps, State> {
    constructor(props) {
        super(props);

        this.state = {
            filterValue: '',
            addingGroupMembersModal: null
        };
    }

    addContactToList = (listId, contactId) => {
        this.props.addContactToList(listId, contactId);
        InternalTracker.trackEvent("Contact Added to List", {
            listId,
            contactId
        });
    };

    removeContactFromList = (listId, contactId) => {
        this.props.removeContactFromList(listId, contactId);
        InternalTracker.trackEvent("Contact Removed from List", {
            listId,
            contactId
        });
    };

    /**
     * Renders the list
     */
    render() {
        const contactMgtState = this.props.contactMgtState;
        const listMgtState = this.props.listMgtState;

        let filteredContacts = simpleSearch(
            this.state.filterValue,
            listMgtState.lists.find((list) => list.id === listMgtState.selected.id)?.members || [],
            ['firstName', 'lastName']
        )

        filteredContacts = filteredContacts.map(fc => {
            return {
                ...fc,
                fullName: fc.firstName + " " + fc.lastName
            }
        })

        // console.log("BEFORE SORT", JSON.parse(JSON.stringify(filteredContacts.map(a => a.firstName))));

        filteredContacts = filteredContacts.filter(c => c.fullName && c.fullName.toLowerCase().indexOf(this.state.filterValue.toLowerCase()) > -1);

        // console.log("AFTER SORT", JSON.parse(JSON.stringify(filteredContacts.map(a => a.firstName))));

        const filteredLists = listMgtState.lists
            .filter((list) => list.id !== listMgtState.selected.id)
            .filter((list) => list.name !== DEFAULT_LIST_NAME);

        console.log(filteredContacts, "<<<<<<<")

        return (
            <Wrapper>
                <Spinner
                    text="Loading Contacts"
                    hide={contactMgtState.loaded}
                />
                {contactMgtState.loaded && (
                    <>
                        <h2
                            style={{
                                fontWeight: 700,
                                margin: "0px 0px 12px 0",
                                fontSize: '1.6em'
                            }}
                        >
                            {listMgtState.selected.contactGroupName}
                        </h2>
                        { (listMgtState.selected.id !== 0) &&
                            <div className="options">
                                <button 
                                    onClick={() => {
                                        this.setState({ addingGroupMembersModal: listMgtState.selected.id })
                                    }}
                                    className={listMgtState.selected.memberCount === 0 ? 'pu lse no-s ize' : ''}
                                    id="group-contacts-add-btn"
                                >
                                    <i className="fas fa-user-plus"></i>
                                    Add Contacts to this group
                                </button>
                                <button 
                                    onClick={() => {
                                        const el: HTMLElement | null = document.querySelector("button[data-group-sharing-id='" + listMgtState.selected.id + "']");
                                        if (el) {
                                            el.click();
                                        }
                                    }}
                                    className="group-sharing-prefs-btn"
                                >
                                    <i className="fas fa-share-square"></i>
                                    Change the Teams this group is shared with
                                </button>
                            </div>
                        }
                        <input
                            placeholder="Search..."
                            value={this.state.filterValue}
                            onChange={(ev) =>
                                this.setState({
                                    filterValue: ev.target.value
                                })
                            }
                            style={{
                                width: '100%',
                                background: 'whitesmoke',
                                padding: '12px 16px',
                                borderRadius: 8,
                                border: '1px solid #d4d4d4',
                                marginBottom: 20
                            }}
                        />
                        <div>
                            <table 
                                className="table table-striped"
                                style={{
                                    marginBottom: 0
                                }}
                                id="group-contacts"
                            >
                                <tbody>
                                    {filteredContacts.map((contact) => (
                                        <tr key={contact.userId} className="group-contact" data-name={contact.firstName + " " + contact.lastName}>
                                            <td className="align-middle col-md-1" style={{ border: 0 }}>
                                                <Image
                                                    src={
                                                        ProfileAPI.getProfileImageUrl(contact.userId)
                                                    }
                                                    circle
                                                    alt={contact.firstName + ' ' + contact.lastName}
                                                    style={{
                                                        height: '48px',
                                                        width: '48px'
                                                    }}
                                                    data-report-blurred={contact.reported}
                                                    data-user-id={contact.userId}
                                                />
                                            </td>
                                            <td
                                                className="align-middle col-md-7"
                                                style={{ border: 0 }}
                                                data-report-blurred={contact.reported}
                                                data-user-id={contact.userId}
                                            >
                                                {contact.firstName}{' '}{contact.lastName}
                                            </td>
                                            <td className="align-middle text-right col-md-4" style={{ border: 0 }}>
                                                <DropdownButton
                                                    id={`${contact.userId}_addToGroup`}
                                                    bsStyle="success"
                                                    bsSize="small"
                                                    title={
                                                        <React.Fragment>
                                                            <i className="fa fa-plus" />
                                                            <span
                                                                style={{
                                                                    margin: '0 4px 0 6px'
                                                                }}
                                                            >
                                                                Add To Group
                                                            </span>
                                                        </React.Fragment>
                                                    }
                                                    style={{
                                                        marginRight: 12
                                                    }}
                                                >
                                                    {filteredLists
                                                        .filter(fl => fl.id !== 0 && (!fl.members || fl.members.map(m => m.userId).indexOf(contact.userId) === -1))
                                                        .map(
                                                            (list) => (
                                                                <MenuItem
                                                                    onSelect={() =>
                                                                        this.addContactToList(
                                                                            list.id,
                                                                            contact.userId
                                                                        )
                                                                    }
                                                                    key={`${contact.userId}_${list.id}`}
                                                                >
                                                                    {list.name}
                                                                </MenuItem>
                                                            )
                                                        )
                                                    }
                                                    {filteredLists.length ===
                                                        undefined ||
                                                        (filteredLists.length ===
                                                            0 && (
                                                            <li className="disabled">
                                                                {' '}
                                                                No Groups
                                                                Found
                                                            </li>
                                                        ))}
                                                </DropdownButton>
                                                {listMgtState.selected.id >
                                                    0 && (
                                                    <Button
                                                        bsStyle="danger"
                                                        bsSize="small"
                                                        onClick={(e) => {
                                                            this.removeContactFromList(
                                                                listMgtState
                                                                    .selected
                                                                    .id,
                                                                contact.userId
                                                            );
                                                            e.stopPropagation();
                                                        }}
                                                    >
                                                        <i className="fa fa-times" />
                                                    </Button>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </>
                )}
                { (this.state.addingGroupMembersModal) &&
                    <ContactListModal
                        onClose={() => {
                            this.setState({
                                addingGroupMembersModal: null
                            })
                            // this.props.reloadContactList(listMgtState.selected.id);
                        }}
                    />
                }
            </Wrapper>
        );
    }
}

const Wrapper = styled.div`

    .options {
        margin-bottom: 20px;

        button {
            margin: 0 10px 0 0;
            padding: 6px 12px;
            border-radius: 5px;
            border: none;

            i {
                padding-right: 6px;
            }
        }
    }
`

const connector = connect(
    (state: ApplicationState) => state.contactManagement,
    ContactActionCreators.actionCreators
);

export default connector(ContactList);
