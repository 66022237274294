import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { Picker } from 'emoji-mart';

interface Props {
    onSelected: (emoji: string) => void;
}

export default function EmojiButton(props: Props) {
    const popover = (
        <Popover className="emoji-popover" id="emoji-popover">
            <div style={{ width: '355px' }}>
                <Picker
                    showPreview={false}
                    showSkinTones={false}
                    onSelect={(emoji) => props.onSelected(emoji.native)}
                    darkMode={false}
                />
            </div>
        </Popover>
    );

    return (
        <OverlayTrigger
            trigger="click"
            placement="top"
            overlay={popover}
            rootClose={true}
        >
            <i
                className="fa fa-smile"
                style={{ fontSize: '22px', margin: '5px', cursor: 'pointer' }}
            />
        </OverlayTrigger>
    );
}
