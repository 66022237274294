/* eslint-disable */
import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { RegularExpressions } from '../../constants';
import ProfileApi from '../../api/profile/Profile';
import TokenApi from '../../api/token/Token';
import { JwtValidationStatus } from '../../api/token/ResponseTypes';
import AuthFlowButton from '../../components/ui-components/AuthFlowButton';
import Hyperlink from '../../components/ui-components/Hyperlink';
import { AsyncOverlay } from '../../components/ui-components/AsyncOverlay';
import Analytics from '../../services/analytics.service';
import JwtDecode from 'jwt-decode';

interface Props {
    location: Location;
}

interface State {
    loading: boolean;
    token: string;
    validationStatus: JwtValidationStatus;
    resetStatus: ResetStatus;
    password: string;
    passwordVerify: string;
    mode: 'reset' | 'set';
}

enum ResetStatus {
    Pending,
    Success,
    Error
}

export default class PasswordResetComplete extends React.Component<
    Props,
    State
> {
    state: State = {
        password: '',
        passwordVerify: '',
        validationStatus: JwtValidationStatus.Unknown,
        resetStatus: ResetStatus.Pending,
        loading: false,
        token: '',
        mode: window.location.pathname.includes('completePasswordReset') ? 'reset' : 'set'
    } as State;

    componentDidMount() {
        const result = RegularExpressions.tokenQueryParam.exec(
            this.props.location.search
        );

        if (result) {
            const token = decodeURIComponent(result[1]);

            this.setState({
                token,
                loading: true
            });

            TokenApi.validate(token).then((status) => {
                this.setState({
                    validationStatus: status,
                    loading: false
                });
            });
        } else {
            this.setState({
                validationStatus: JwtValidationStatus.Invalid
            });
        }
    }

    get showDontMatch(): boolean {
        const p1 = this.state.password;
        const p2 = this.state.passwordVerify;
        return p1.length > 0 && p2.length > 0 && p1 !== p2;
    }

    get showInvalid(): boolean {
        const p = this.state.password;

        // Don't show invalid if haven't entered passwords
        if (!p || !p.length) return false;

        return !RegularExpressions.password.test(this.state.password);
    }

    get isValid(): boolean {
        const p1Valid = RegularExpressions.password.test(this.state.password);

        // Valid if password meets requirements and matches verify
        return p1Valid && this.state.password === this.state.passwordVerify;
    }

    handleConfirm = async () => {
        this.setState({
            loading: true
        });

        ProfileApi.confirmPasswordReset(
            this.state.password,
            this.state.token
        ).then(async (success) => {
            if (success) {
                if (this.state.mode === "set") {
                    const decodedToken = JwtDecode(this.state.token);
                    const email = decodedToken.email;
                    const loginLink = await ProfileApi.getLoginLinkForEmail(email).catch(() => {});
                    console.log(loginLink)
                    window.location.href = (loginLink && loginLink.startsWith("https://")) ? loginLink : "/"
                } else {
                    this.setState({
                        resetStatus: success
                            ? ResetStatus.Success
                            : ResetStatus.Error,
                        loading: false
                    });
    
                    Analytics.trackEvent('password-reset-complete');
                }
            } else {
                Analytics.trackEvent('password-reset-failed');
            }
        });
    };

    render() {
        let content: JSX.Element;

        if (this.state.loading) {
            return <AsyncOverlay show={true} />;
        }

        if (this.state.resetStatus == ResetStatus.Success) {
            content = this.renderSuccess();
        } else if (this.state.resetStatus == ResetStatus.Error) {
            content = this.renderError(
                'Unable to reset password',
                'Unfortuantely your password could not be reset. Please contact us on support@updatedge.com'
            );
        } else {
            switch (this.state.validationStatus) {
                case JwtValidationStatus.Success:
                    content = this.renderReady();
                    break;
                case JwtValidationStatus.Expired:
                    content = this.renderError(
                        'Password reset link expired',
                        'The password reset request has expired. Please request another if you still wish to reset your password.'
                    );
                    break;
                default:
                case JwtValidationStatus.Invalid:
                    content = this.renderError(
                        'Invalid reset link',
                        'The provided password reset token is invalid'
                    );
                    break;
            }
        }

        return <div>{content}</div>;
    }

    renderError(title: string, body: string) {
        return (
            <div style={{ textAlign: 'center' }}>
                <h4>{title}</h4>
                <div style={{ marginTop: '40px' }}>{body}</div>
                <Hyperlink as={Link} to="/requestPasswordReset">
                    Click here to try again
                </Hyperlink>
            </div>
        );
    }

    renderReady() {
        return (
            <Container>
                <h4>{ this.state.mode === "reset" ? "Reset your password" : "Set a password to activate your account" }</h4>
                { (this.state.mode === "reset") &&
                    <div>Please enter a new password</div>
                }
                <input
                    autoFocus
                    type="password"
                    className="form-control"
                    style={{ marginBottom: '10px', marginTop: '10px' }}
                    placeholder="Password..."
                    value={this.state.password}
                    onChange={(ev) =>
                        this.setState({ password: ev.target.value })
                    }
                />
                <input
                    type="password"
                    className="form-control"
                    style={{ marginBottom: '10px' }}
                    placeholder="Confirm password..."
                    value={this.state.passwordVerify}
                    onChange={(ev) =>
                        this.setState({ passwordVerify: ev.target.value })
                    }
                />
                {this.showDontMatch && (
                    <div className="validation-error">
                        Passwords do not match
                    </div>
                )}
                {this.showInvalid && (
                    <div className="validation-error">
                        Password must be at least 8 characters long, contain at
                        least one uppercase letter, and at least one special
                        character
                    </div>
                )}
                <AuthFlowButton
                    onClick={this.handleConfirm}
                    disabled={!this.isValid}
                >
                    { this.state.mode === "reset" ? "Reset password" : "Save Password" }
                </AuthFlowButton>
            </Container>
        );
    }

    renderSuccess() {
        return (
            <div>
                <h4 style={{ marginTop: '70px', textAlign: 'center' }}>
                    Successfully reset password!
                </h4>
            </div>
        );
    }
}

const Container = styled.div`
    h4 {
        text-align: center;

        + div {
            margin: 20px 0 10px;
        }
    }

    input {
        margin-bottom: 10px;
    }

    .validation-error {
        margin: 10px 0;
        color: orangered;
        font-weight: bold;
    }

    button {
        margin-top: 10px;
    }
`;
