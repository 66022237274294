import * as React from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { Pagination } from 'react-bootstrap';
import { debounce } from 'ts-debounce';

import Splash from '../../components/ui-components/Splash';
import OrganisationApi from '../../api/organisation/Organisation';
import { OrganisationDTO } from '../../api/organisation/ResponseTypes';
import theme from '../../css/theme';
import { supportedImageFormats } from '../../constants';
import AuthenticatedFetch from '../../api/AuthenticatedFetch';
import AppConfig from '../../components/config/Config';
import GridComponent from './GridComponent';
// import LocationPicker from '../../components/profile/LocationPicker';

interface State {
    loading: boolean;
    organisations: OrganisationDTO[];
    filteredOrgs: OrganisationDTO[][];
    search: string;
    editingId: string;
    isAddingLocation: boolean;
    selectedPage: number;
}

class Logos extends React.Component<{}, State> {
    fileInput: React.RefObject<HTMLInputElement>;

    pageSize = 20;
    locationSetters = {};

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            search: '',
            editingId: '',
            organisations: [],
            filteredOrgs: [[]],
            selectedPage: 0,
            isAddingLocation: false
        };

        this.fileInput = React.createRef();
    }

    async componentDidMount() {
        await this.refresh();
    }

    async refresh() {
        const organisations = await OrganisationApi.getAll(
            undefined,
            undefined,
            false
        );
        this.setState({
            organisations: organisations ? organisations.data : [],
            loading: false
        });
        this.filterOrgs();
    }

    filterOrgs() {
        const searchTerm = this.state.search;
        this.setState((prevState) => {
            const filtered = prevState.organisations.filter(
                (o) =>
                    o.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !==
                    -1
            );

            const paginated = this.chunk(
                filtered ? filtered : [],
                this.pageSize
            );

            return {
                ...prevState,
                filteredOrgs: paginated
            };
        });
    }

    debouncedFilterOrgs = debounce(this.filterOrgs.bind(this), 500);

    get pageLength() {
        return this.state.filteredOrgs ? this.state.filteredOrgs.length : 0;
    }

    chunk = (arr, size) =>
        Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
            arr.slice(i * size, i * size + size)
        );

    private handleSearch = (v) => {
        this.setState(
            {
                search: v,
                selectedPage: 0
            },
            () => {
                this.filterOrgs();
            }
        );
    };

    debouncedSearch = debounce(this.handleSearch.bind(this), 500);

    render() {
        if (this.state.loading) {
            return <Splash />;
        }

        let items: JSX.Element[] = [];

        for (let i = 0; i < this.pageLength; i++) {
            items.push(
                <Pagination.Item
                    key={`pagination-${i}`}
                    active={this.state.selectedPage === i}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        this.setState({ selectedPage: i });
                    }}
                >
                    {i + 1}
                </Pagination.Item>
            );
        }

        return (
            <Wrapper>
                <div className="layout horizontal center justified">
                    <h3 style={{ marginRight: '20px' }}>Organisations</h3>
                    <Pagination>
                        <Pagination.First
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                if (this.state.selectedPage !== 0) {
                                    this.setState({ selectedPage: 0 });
                                }
                            }}
                            disabled={this.state.selectedPage === 0}
                        />
                        <Pagination.Prev
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                if (this.state.selectedPage !== 0) {
                                    this.setState((prevState) => {
                                        return {
                                            ...prevState,
                                            selectedPage:
                                                prevState.selectedPage - 1
                                        };
                                    });
                                }
                            }}
                            disabled={this.state.selectedPage === 0}
                        />
                        {items}
                        <Pagination.Next
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                if (
                                    this.state.selectedPage !==
                                    this.pageLength - 1
                                ) {
                                    this.setState((prevState) => {
                                        return {
                                            ...prevState,
                                            selectedPage:
                                                prevState.selectedPage + 1
                                        };
                                    });
                                }
                            }}
                            disabled={
                                this.state.selectedPage === this.pageLength - 1
                            }
                        />
                        <Pagination.Last
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                if (
                                    this.state.selectedPage !==
                                    this.pageLength - 1
                                ) {
                                    this.setState({
                                        selectedPage: this.pageLength - 1
                                    });
                                }
                            }}
                            disabled={
                                this.state.selectedPage === this.pageLength - 1
                            }
                        />
                    </Pagination>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        style={{ width: '30rem' }}
                        // value={this.state.search}
                        onChange={(ev) => {
                            this.debouncedSearch(ev.target.value);
                        }}
                    />
                </div>
                <div className="horizontal layout wrap">
                    {this.state.filteredOrgs &&
                        this.state.filteredOrgs[this.state.selectedPage] &&
                        this.state.filteredOrgs[this.state.selectedPage].map(
                            (o, index) => {
                                return (
                                    <GridComponent
                                        // gridId={index}
                                        refresh={this.refresh.bind(this)}
                                        key={`card-${index}-${Date.now()}`}
                                        organisation={o}
                                        handleClick={this.handleClick.bind(
                                            this
                                        )}
                                        editingId={this.state.editingId}
                                        setEditingId={this.setEditingId.bind(
                                            this
                                        )}
                                        setIsAddingLocation={this.setIsAddingLocation.bind(
                                            this
                                        )}
                                    />
                                );
                            }
                        )}
                </div>
                <input
                    type="file"
                    accept="image/*"
                    ref={this.fileInput}
                    style={{ display: 'none' }}
                    onChange={(e) => this.handleChange(e.target.files)}
                />
            </Wrapper>
        );
    }

    setEditingId(id: string) {
        this.setState(
            {
                editingId: id
            },
            () => {
                console.log(`editing id now ${this.state.editingId}`);
            }
        );
    }

    setIsAddingLocation(to: boolean) {
        this.setState({
            isAddingLocation: to
        });
    }

    handleClick(id: string) {
        this.setEditingId(id);
        this.fileInput.current!.click();
        window.addEventListener('focus', this.handleFocusBack.bind(this));
    }

    handleFocusBack(e) {
        // console.log(e);
        // this.setState({
        //     editingId: ''
        // });
        window.removeEventListener('focus', this.handleFocusBack.bind(this));
    }
    async handleChange(files: FileList | null) {
        if (this.state.editingId && files && files.length) {
            if (supportedImageFormats.indexOf(files[0].type) < 0) {
                toast.error('Please select a JPG or PNG');
            } else {
                const toastId = toast.info('Uploading logo...');

                await AuthenticatedFetch.uploadFile(
                    `${AppConfig.Settings.api.baseUri}/publicapi/org/image/${this.state.editingId}`,
                    files[0],
                    'Failed to upload company profile image.'
                )
                    .then(async (e) => {
                        console.log('e:', e);
                        await this.refresh();
                        toast.dismiss(toastId);
                        toast.success('Image updated!');
                        // this.setState({ editingId: '' }, async () => {
                        //
                        // });
                    })
                    .catch((e) => {
                        console.error(e);
                    });

                return;
            }
        }
        // this.setState({ editingId: '' });
    }

    // handleCreateLocation(
    //     placeId: string,
    //     placeName: string,
    //     components: google.maps.GeocoderAddressComponent[]
    // ) {
    //     this.locationSetters[this.state.editingId](
    //         placeId,
    //         placeName,
    //         components
    //     );
    // }
    //
    // setLocationSetter(
    //     editingId: string,
    //     setter: (
    //         placeId: string,
    //         placeName: string,
    //         components: google.maps.GeocoderAddressComponent[]
    //     ) => void
    // ): void {
    //     this.locationSetters[editingId] = setter;
    //     console.log('locationSetter: ', editingId, setter);
    // }
}

export default Logos;

const Wrapper = styled.div`
    color: ${theme.colours.updatedge};

    input {
        margin-left: 20px;
        width: 150px;
    }
`;
//
// interface paginatorProps {
//     length: number;
//     onSelect: any;
// }
//
// const Paginator = (props: paginatorProps) => {
//     const { length } = props;
//     const [currentIndex, setCurrentIndex] = React.useState(0);
//
//     useEffect(() => {}, []);
//
//     return (
//         <span>
//             {new Array(length).fill(0).map((v, i) => {
//                 return squareWithValue(i, () => {
//                     setCurrentIndex(i);
//                 });
//             })}
//         </span>
//     );
// };
//
// const squareWithValue = (value, p: () => void) => {
//     return <StyledSquare>{value}</StyledSquare>;
// };
//
// const StyledSquare = styled.Button`
//     border: 1px solid black;
//     padding: 0.5rem;
// `;
