import styled from 'styled-components';

import theme from '../../css/theme';

export const GREY_LIGHT = 'rgba(10, 30, 65, .1)';
export const GREY_LIGHTER = 'rgba(10, 30, 65, .05)';

const PopoverSelect = styled.div`
    padding: 5px 10px;
    color: ${theme.colours.updatedge};
    text-transform: capitalize;
    background: ${GREY_LIGHTER};
    border-radius: 3px;
    cursor: ${(props) => (props.hoverable ? 'pointer' : 'default')};

    :hover {
        background: ${(props) => (props.hoverable ? GREY_LIGHT : GREY_LIGHTER)};
    }

    > span {
        display: inline-block;
        min-width: 50px;
    }

    > i {
        margin-left: 8px;
        font-size: 12px;
    }
`;

export { PopoverSelect };
