import React from 'react';

const SvgOffer = (props) => (
    <svg height="1em" viewBox="0 0 512 512" width="1em" {...props}>
        <path
            d="M512 256c0 23.48-3.168 46.227-9.09 67.836a254.777 254.777 0 01-11.254 32.348c-12.152 28.546-29.34 54.441-50.437 76.539a257.718 257.718 0 01-30.543 27.273 235.55 235.55 0 01-3.52 2.633 268.996 268.996 0 01-11.902 8.21 252.433 252.433 0 01-22.52 13.052 155.194 155.194 0 01-4.03 2.02 250.06 250.06 0 01-30.888 12.726 249.62 249.62 0 01-24.996 7.031c-16.527 3.75-33.664 5.895-51.242 6.27-1.848.042-3.707.062-5.578.062-.437 0-.879 0-1.316-.012-27.18-.133-53.352-4.504-77.887-12.484a254.501 254.501 0 01-59.969-28.61 260.817 260.817 0 01-13.258-9.207s-.011 0-.011-.011a251.64 251.64 0 01-13.414-10.668 252.715 252.715 0 01-17.641-16.52 258.484 258.484 0 01-27.176-33.02 256.461 256.461 0 01-13.039-20.937c0-.011-.012-.011-.012-.02a255.844 255.844 0 01-13.742-28.683 257.47 257.47 0 01-9.969-29.883C2.976 300.898 0 278.801 0 256 0 114.613 114.613 0 256 0s256 114.613 256 256zm0 0"
            fill="#66baed"
        />
        <path
            d="M438.96 435.055c-45.265 46.246-107.917 75.398-177.382 76.882v-6.73c0-37.875 25.715-69.734 60.649-79.078a77.36 77.36 0 013.863-.938 81.13 81.13 0 0110.648-1.57 84.512 84.512 0 016.7-.27h53.308a81.77 81.77 0 0117.367 1.84c.325.063.649.133.961.207.942.22 1.883.45 2.813.7.031.011.043.011.074.023a81.447 81.447 0 0121 8.934zm0 0"
            fill="#3d4159"
        />
        <path
            d="M418.156 425.191l-.195.93-7.285 33.875a235.55 235.55 0 01-3.52 2.633c-19.52 14.305-41.14 25.914-64.324 34.262-1.664.597-3.336 1.18-5.016 1.746l-15.59-72.508-.199-.938zm0 0"
            fill="#66baed"
        />
        <path
            d="M392.066 452.89l-19.332 31a155.194 155.194 0 01-4.03 2.02l-20.587-33.02zm0 0"
            fill="#3d4159"
        />
        <path
            d="M370.09 456.863l-36.856 20.48-11.21-52.148 22.902-13.488M418.156 425.2l-7.48 34.796a235.55 235.55 0 01-3.52 2.633 268.996 268.996 0 01-11.902 8.21l-25.16-13.98 25.16-45.148zm0 0"
            fill="#2ba1cf"
        />
        <path
            d="M441.219 432.723a430.88 430.88 0 01-2.258 2.332 257.65 257.65 0 01-31.805 27.574l10.73-36.531.27-.907zm0 0M342.832 496.89a253.445 253.445 0 01-30.012 8.778l-4.148-10.106h11.695l-23.836-62.14 25.223-8.23.273.906zm0 0"
            fill="#2a2f4f"
        />
        <path
            d="M399.3 391.816v35.844c0 13.121-8.652 24.215-20.57 27.899a29.227 29.227 0 01-8.64 1.304c-16.13 0-29.203-13.074-29.203-29.203v-35.844zm0 0"
            fill="#eab599"
        />
        <path
            d="M399.3 425.39v2.27c0 13.121-8.652 24.215-20.57 27.899-10.753-3.305-18.835-12.657-20.332-24.094a99.975 99.975 0 0022.105-1.125 99.561 99.561 0 0018.798-4.95zm0 0M289.977 333.074c0 13.524-9.875 24.735-22.809 26.813-1.414.23-2.867.347-4.352.347-15.003 0-27.16-12.156-27.16-27.16s12.157-27.16 27.16-27.16c1.485 0 2.938.117 4.352.348 12.93 2.082 22.809 13.293 22.809 26.812zm0 0"
            fill="#f6caae"
        />
        <path
            d="M289.977 333.074c0 13.524-9.875 24.735-22.809 26.813a100.862 100.862 0 01-3.605-26.813c0-9.277 1.253-18.27 3.605-26.808 12.93 2.078 22.809 13.289 22.809 26.808zm0 0"
            fill="#eab599"
        />
        <path
            d="M272.523 333.074c0 5.364-4.347 9.707-9.707 9.707a9.704 9.704 0 01-9.707-9.707c0-5.36 4.344-9.707 9.707-9.707 5.36 0 9.707 4.348 9.707 9.707zm0 0"
            fill="#eab599"
        />
        <path
            d="M502.91 323.836a254.777 254.777 0 01-11.254 32.348 27.024 27.024 0 01-14.285 4.054c-1.484 0-2.934-.125-4.355-.355-12.926-2.078-22.813-13.29-22.813-26.813 0-13.52 9.887-24.722 22.813-26.812a27.613 27.613 0 014.355-.344c11.766 0 21.766 7.473 25.54 17.922zm0 0"
            fill="#f6caae"
        />
        <path
            d="M450.207 333.074c0 13.524 9.879 24.735 22.813 26.813a101.02 101.02 0 003.601-26.813c0-9.277-1.254-18.27-3.601-26.808-12.934 2.078-22.813 13.289-22.813 26.808zm0 0"
            fill="#eab599"
        />
        <path
            d="M471.148 280.164v38.48c0 50.286-36.738 92.004-84.84 99.747-.16.027-.331.054-.492.082a99.75 99.75 0 01-15.726 1.222c-55.805 0-101.047-45.242-101.047-101.05v-38.48c0-11.75 2.004-23.048 5.707-33.536 13.82-39.328 51.293-67.524 95.34-67.524h.012c5.347 0 10.605.415 15.73 1.223 48.336 7.55 85.316 49.379 85.316 99.836zm0 0"
            fill="#eab599"
        />
        <path
            d="M487.078 333.074c0 5.364-4.348 9.707-9.707 9.707a9.704 9.704 0 01-9.707-9.707c0-5.36 4.344-9.707 9.707-9.707 5.36 0 9.707 4.348 9.707 9.707zm0 0"
            fill="#eab599"
        />
        <path
            d="M471.148 280.164v38.48c0 50.286-36.738 92.004-84.84 99.747-.16.027-.331.054-.492.082-48.336-7.555-85.308-49.38-85.308-99.828v-38.48c0-50.458 36.98-92.286 85.324-99.837 48.336 7.55 85.316 49.375 85.316 99.836zm0 0"
            fill="#f6caae"
        />
        <path
            d="M348.574 312.758c0 6.332-5.133 11.465-11.465 11.465s-11.464-5.133-11.464-11.465 5.132-11.465 11.464-11.465 11.465 5.133 11.465 11.465zm0 0M414.543 312.758c0 6.332-5.133 11.465-11.465 11.465s-11.465-5.133-11.465-11.465 5.133-11.465 11.465-11.465 11.465 5.133 11.465 11.465zm0 0"
            fill="#3d4159"
        />
        <path
            d="M346.234 310.734a4.912 4.912 0 11-9.824.004 4.912 4.912 0 019.824-.004zm0 0M412.77 310.734a4.913 4.913 0 11-9.826.003 4.913 4.913 0 019.826-.003zm0 0"
            fill="#fff"
        />
        <path
            d="M381.559 337.125h-22.926a4.947 4.947 0 01-4.95-4.945 4.947 4.947 0 014.95-4.946h22.926a4.946 4.946 0 010 9.891zm0 0M370.094 368.41c-8.461 0-16.922-2.117-24.637-6.36a4.496 4.496 0 014.332-7.878c12.715 6.988 27.899 6.988 40.613 0a4.496 4.496 0 014.332 7.879c-7.714 4.242-16.18 6.36-24.64 6.36zm0 0"
            fill="#eab599"
        />
        <path
            d="M471.148 245.238v39.422H452.34v-30.87l-135.766-25.231-28.726-5.34v61.437h-18.809v-39.418c0-44.937 36.422-81.36 81.352-81.36h39.406c1.07 0 2.14.017 3.21.071 43.438 1.676 78.141 37.422 78.141 81.29zm0 0"
            fill="#2a2f4f"
        />
        <path
            d="M471.148 245.238v39.422H452.34v-30.87l-135.766-25.231c7.48-35.926 38.653-63.153 76.434-64.61 43.437 1.676 78.14 37.422 78.14 81.29zm0 0"
            fill="#3d4159"
        />
        <path
            d="M254.684 504.844v7.144c-27.18-.133-53.352-4.504-77.887-12.484-26.582-8.633-51.254-21.504-73.227-37.817a252.124 252.124 0 01-13.425-10.68 252.715 252.715 0 01-17.641-16.519 82.97 82.97 0 0137.816-12.664 81.858 81.858 0 016.813-.27h54.242c6.059 0 11.973.637 17.668 1.872.324.062.66.133.973.207a62.6 62.6 0 012.863.722c.031 0 .05 0 .07.008a82.688 82.688 0 0125.863 12.008c21.68 15.035 35.872 40.102 35.872 68.473zm0 0"
            fill="#e8c934"
        />
        <path
            d="M254.684 504.844v7.144c-62.766-.312-120.176-23.207-164.54-60.98 10.793-12.746 25.36-22.192 41.973-26.633a80.02 80.02 0 013.93-.95 83.731 83.731 0 0110.844-1.6 81.858 81.858 0 016.812-.27h17.672c6.059 0 11.973.636 17.668 1.87.324.063.66.134.973.208a62.6 62.6 0 012.863.722c.031 0 .05 0 .07.008a82.688 82.688 0 0125.863 12.008c21.68 15.035 35.872 40.102 35.872 68.473zm0 0"
            fill="#ffe152"
        />
        <path
            d="M193.16 423.426l-.21.937-16.153 75.14c-26.582-8.632-51.254-21.503-73.227-37.816l-8.023-37.312-.211-.95zm0 0"
            fill="#f2f0ec"
        />
        <path
            d="M144.25 455.648l-27.422 15.247a260.817 260.817 0 01-13.258-9.208s-.011 0-.011-.011l-8.223-38.242 23.3-13.73zm0 0"
            fill="#d8d5d0"
        />
        <path
            d="M144.246 455.652l37.5 20.84 11.406-53.058-23.3-13.727"
            fill="#d8d5d0"
        />
        <path
            d="M256.23 275.852v125.617H45.328A256.461 256.461 0 0132.29 380.53v-104.68c0-12.757 2.129-25.265 6.332-37.163 15.715-44.743 58.168-74.805 105.629-74.805 5.883 0 11.754.449 17.45 1.355 26.077 4.067 49.995 17.38 67.343 37.473a111.977 111.977 0 0127.187 73.14zm0 0"
            fill="#f74f4f"
        />
        <path
            d="M78.348 280.766c0 44.425 26.015 95.836 40.3 120.703h-73.32a258.015 258.015 0 01-13.05-20.957v-104.66c0-12.758 2.132-25.266 6.331-37.165 15.633-44.484 57.688-74.46 104.825-74.796-34.418.859-65.086 56.09-65.086 116.875zm0 0"
            fill="#dd3033"
        />
        <path
            d="M173.977 389.469v36.469c0 13.351-8.805 24.64-20.93 28.386a29.603 29.603 0 01-8.797 1.328c-16.41 0-29.71-13.3-29.71-29.715V389.47zm0 0"
            fill="#eab599"
        />
        <path
            d="M173.977 423.629v2.308c0 13.352-8.805 24.641-20.93 28.387-10.942-3.363-19.168-12.875-20.688-24.515 1.985.12 3.98.18 5.985.18 5.625 0 11.136-.446 16.504-1.325a101.063 101.063 0 0019.129-5.035zm0 0M62.734 329.695c0 13.762-10.05 25.164-23.207 27.285a28.443 28.443 0 01-4.43.356 27.543 27.543 0 01-16.562-5.508 257.47 257.47 0 01-9.969-29.883c3.344-11.496 13.961-19.886 26.532-19.886 1.515 0 2.988.125 4.43.355 13.156 2.121 23.206 13.52 23.206 27.281zm0 0"
            fill="#f6caae"
        />
        <path
            d="M62.734 329.7c0 13.753-10.047 25.163-23.207 27.28a102.737 102.737 0 01-3.668-27.28c0-9.442 1.278-18.59 3.668-27.286 13.16 2.117 23.207 13.527 23.207 27.285zm0 0"
            fill="#eab599"
        />
        <path
            d="M44.977 329.7c0 5.452-4.422 9.874-9.88 9.874-5.452 0-9.874-4.422-9.874-9.875 0-5.457 4.422-9.879 9.875-9.879a9.878 9.878 0 019.879 9.88zm0 0"
            fill="#eab599"
        />
        <path
            d="M215.215 294.41v94.996c-14.68 14.016-33.508 23.723-54.461 27.098l-.5.082c-5.207.836-10.55 1.246-16 1.246-56.785 0-102.82-46.035-102.82-102.82v-39.157c0-5.011.355-9.945 1.05-14.765 24.465 17.594 59.54 15.855 81.887-5.27 7.402-6.988 12.543-15.312 15.453-24.14 1.621 15.46 8.692 30.508 21.223 42.355 14.922 14.09 34.637 20.887 54.168 20.375zm0 0"
            fill="#eab599"
        />
        <path
            d="M215.215 294.41v94.996c-14.68 14.016-33.508 23.723-54.461 27.098l-.5.082c-49.184-7.684-86.8-50.242-86.8-101.574v-39.157c0-1.042.019-2.074.054-3.109 18.133 1.856 36.96-3.777 50.867-16.926 7.398-6.988 12.543-15.312 15.45-24.14 1.62 15.46 8.69 30.508 21.226 42.355 14.918 14.09 34.633 20.887 54.164 20.375zm0 0"
            fill="#f6caae"
        />
        <path
            d="M199.477 306.328c0-9.433-7.649-17.078-17.079-17.078s-17.078 7.645-17.078 17.078M123.2 306.328c0-9.433-7.645-17.078-17.075-17.078-9.434 0-17.078 7.645-17.078 17.078"
            fill="#eab599"
        />
        <path
            d="M117.79 313.598c0 6.441-5.224 11.664-11.669 11.664-6.441 0-11.664-5.223-11.664-11.664s5.223-11.664 11.664-11.664c6.445 0 11.668 5.222 11.668 11.664zm0 0M194.063 313.598c0 6.441-5.223 11.664-11.665 11.664-6.441 0-11.664-5.223-11.664-11.664s5.223-11.664 11.664-11.664c6.442 0 11.665 5.222 11.665 11.664zm0 0"
            fill="#3d4159"
        />
        <path
            d="M155.926 342.969h-23.328a5.03 5.03 0 01-5.032-5.031 5.028 5.028 0 015.032-5.032h23.328a5.03 5.03 0 110 10.063zm0 0M144.262 374.8c-8.61 0-17.223-2.155-25.07-6.472a4.57 4.57 0 01-1.805-6.21 4.575 4.575 0 016.21-1.805c12.938 7.113 28.387 7.113 41.325 0a4.57 4.57 0 016.21 1.804 4.57 4.57 0 01-1.804 6.211c-7.848 4.317-16.457 6.473-25.066 6.473zm0 0"
            fill="#eab599"
        />
        <path
            d="M115.41 311.54a4.999 4.999 0 11-9.995.002 4.999 4.999 0 019.995-.003zm0 0M192.258 311.54a4.999 4.999 0 11-9.995.002 4.999 4.999 0 019.995-.003zm0 0"
            fill="#fff"
        />
        <path
            d="M214.242 342.008c0 7.074-5.734 12.808-12.808 12.808-7.075 0-12.809-5.734-12.809-12.808s5.734-12.809 12.809-12.809c7.074 0 12.808 5.735 12.808 12.809zm0 0M99.887 342.008c0 7.074-5.735 12.808-12.809 12.808s-12.808-5.734-12.808-12.808 5.734-12.809 12.808-12.809 12.809 5.735 12.809 12.809zm0 0"
            fill="#eab599"
        />
        <g fill="#f2f0ec">
            <path d="M332.129 142.727H172.69c-22.625 0-40.96-18.34-40.96-40.961V40.96C131.73 18.34 150.07 0 172.69 0H332.13c22.621 0 40.96 18.34 40.96 40.96v60.806c0 22.62-18.339 40.96-40.96 40.96zm0 0" />
            <path d="M174.79 154.703c0 17-13.782 30.781-30.786 30.781-17 0-30.781-13.78-30.781-30.78 0-17.005 13.78-30.786 30.78-30.786 17.005 0 30.786 13.781 30.786 30.785zm0 0M106.691 185.645c0 8.5-6.89 15.39-15.39 15.39s-15.39-6.89-15.39-15.39 6.89-15.391 15.39-15.391 15.39 6.89 15.39 15.39zm0 0M396.684 154.703c0 17-13.786 30.781-30.786 30.781s-30.785-13.78-30.785-30.78c0-17.005 13.785-30.786 30.785-30.786s30.786 13.781 30.786 30.785zm0 0M433.996 185.645c0 8.5-6.894 15.39-15.394 15.39s-15.391-6.89-15.391-15.39 6.89-15.391 15.39-15.391 15.395 6.89 15.395 15.39zm0 0" />
        </g>
        <path
            d="M229.945 71.363c0 7.504-6.082 13.582-13.586 13.582-7.5 0-13.582-6.078-13.582-13.582 0-7.5 6.082-13.582 13.582-13.582 7.504 0 13.586 6.082 13.586 13.582zm0 0M265.992 71.363c0 7.504-6.082 13.582-13.582 13.582-7.504 0-13.586-6.078-13.586-13.582 0-7.5 6.082-13.582 13.586-13.582 7.5 0 13.582 6.082 13.582 13.582zm0 0M302.043 71.363c0 7.504-6.082 13.582-13.586 13.582-7.5 0-13.582-6.078-13.582-13.582 0-7.5 6.082-13.582 13.582-13.582 7.504 0 13.586 6.082 13.586 13.582zm0 0"
            fill="#3d4159"
        />
    </svg>
);

export default SvgOffer;
