import { TimePresets } from './Models';

/** Time preset management default state */
export const defaultState: TimePresetManagementState = {
    timePresetMgtState: {
        presets: [],
        creating: false,
        loaded: false,
        selected: {
            id: '',
            endHour: 0,
            endMinute: 0,
            name: '',
            startHour: 0,
            startMinute: 0,
            userId: '',
            startTime: '',
            endTime: ''
        }
    }
};

/** Stores state when using time preset management  */
export interface TimePresetManagementState {
    timePresetMgtState: TimePresets;
}
