import React from 'react';
import styled, { keyframes } from 'styled-components';
import { ProgressBar } from 'react-bootstrap';
import SvgMedalBronze from '../../components/svg/MedalBronze';
import SvgMedalSilver from '../../components/svg/ModalSilver';
import SvgMedalGold from '../../components/svg/MedalGold';
import theme from '../../css/theme';

interface ProfileProgressProps {
    completePercentageGold?: number;
    completePercentageSilver?: number;
    completePercentageBronze?: number;
    displayAnimation?: boolean;
}

const options = {
    bronze: {
        Icon: SvgMedalBronze,
        label: 'Bronze Profile'
    },
    silver: {
        Icon: SvgMedalSilver,
        label: 'Silver Profile'
    },
    gold: {
        Icon: SvgMedalGold,
        label: 'Gold Profile'
    }
};

const ProgressBarWrapper = styled.div`
    width: 100%;
    margin-bottom: 15px;

    div.progress {
        max-width: 85%;
        margin: 0 auto 5px;
        background-color: #eee;

        .progress-bar {
            color: white;
            background: ${theme.colours.green};
            border-right: none;
        }
    }
`;

const ConditionalWrapper = ({ condition, wrapper: Wrapper, children }) =>
    condition ? <Wrapper>{children}</Wrapper> : children;

class ProfileProgress extends React.Component<ProfileProgressProps> {
    state = {
        hasIncreasedTier: false,
        hasIncreasedProgress: false
    };

    progressRef: React.RefObject<HTMLDivElement> = React.createRef();

    componentDidUpdate(prevProps) {
        const propNames = [
            'completePercentageGold',
            'completePercentageSilver',
            'completePercentageBronze'
        ];

        const hasIncreasedTier = propNames.some(
            (name) => prevProps[name] !== 100 && this.props[name] === 100
        );

        const hasIncreasedProgress = propNames.some(
            (name) => this.props[name] > prevProps[name]
        );

        if (!hasIncreasedTier && !hasIncreasedProgress) return;

        this.setState({ hasIncreasedTier, hasIncreasedProgress }, () => {
            if (hasIncreasedTier) {
                this.triggerFireworks();
            }
            setTimeout(() => {
                this.setState({
                    hasIncreasedTier: false,
                    hasIncreasedProgress: false
                });
            }, 1000);
        });
    }

    triggerFireworks() {
        // Disabled temporarily for IE11
        // if (!this.props.displayAnimation) return;
        // const progressElement = ReactDOM.findDOMNode(
        //     this.progressRef.current
        // ) as HTMLElement;
        // const rect = progressElement.getBoundingClientRect();
        // fx({
        //     x: (rect.left + rect.right) / 2,
        //     y: (rect.top + rect.bottom) / 2,
        //     colors: ['#cc3333', '#4CAF50', '#81C784']
        // });
    }

    getConfig() {
        const {
            completePercentageGold = 0,
            completePercentageSilver = 0,
            completePercentageBronze = 0
        } = this.props;

        let config = {
            Icon: SvgMedalBronze,
            label: '',
            percentage: 0
        };

        if (completePercentageGold <= 100) {
            config = { ...options.gold, percentage: completePercentageGold };
        }

        if (completePercentageSilver < 100) {
            config = {
                ...options.silver,
                percentage: completePercentageSilver
            };
        }

        if (completePercentageBronze < 100) {
            config = {
                ...options.bronze,
                percentage: completePercentageBronze
            };
        }

        return config;
    }

    render() {
        const config = this.getConfig();

        return (
            <>
                <ConditionalWrapper
                    condition={
                        (this.state.hasIncreasedTier ||
                            this.state.hasIncreasedProgress) &&
                        this.props.displayAnimation
                    }
                    wrapper={AnimationContainer}
                    children={
                        <div ref={this.progressRef}>
                            <config.Icon />
                            <br />
                            <label>{config.label}</label>
                        </div>
                    }
                />
                <ProgressBarWrapper>
                    {/* <ProgressBar now={config.percentage} bsStyle="success" />
                    <span>{`${config.percentage}% complete`}</span> */}
                    <p>Complete Next Step</p>
                </ProgressBarWrapper>
            </>
        );
    }
}

const PuffIn = keyframes`
    0% {
        transform: scale(2, 2);
        transform-origin: 50% 50%;
        opacity: 0;
        filter: blur(2px);
    }

    100% {
        transform: scale(1, 1);
        transform-origin: 50% 50%;
        opacity: 1;
        filter: blur(0px);
    }
`;

const AnimationContainer = styled.div`
    animation: ${PuffIn} 1s;
`;

export default ProfileProgress;
