/* eslint-disable */
import styled from 'styled-components';
import React from 'react';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { Modal } from 'react-bootstrap';
import { FileType } from 'src/store/contacts/Models';
import theme from 'src/css/theme';
import FileApi from '../api/files/Files';
import { toast } from 'react-toastify';
import InternalTracker from 'src/InternalTracker';

interface props {
    uri: string,
    type: FileType | null;
    name: string;
    id: number | null;
    close: () => void
}

class DocumentViewer extends React.Component<
    props,
    {}
>{

  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id && this.props.id) {
      InternalTracker.trackEvent("Viewed File", {
        fileId: this.props.id
      })
    }
  }
  
  render() {
    const youtubeId = this.props.type === FileType.VideoLink ? this.props.uri.split("v=")[1].split("&")[0] : null;
    const TRACKING_DIMENSIONS = {
      id: this.props.id,
      type: this.props.type
    }

    return (
      <Modal show={true} onHide={() => { this.props.close(); }}>
        <DocViewerModal>
          <div className='header'>
            <h3>{this.props.name}</h3>
            <div className='options'>
              { (this.props.type === FileType.TextDocument || this.props.type === FileType.Image) &&
                <button onClick={() => {
                  InternalTracker.trackEvent("File Downloaded", TRACKING_DIMENSIONS);
                  window.open(this.props.uri) 
                }}>
                  <i className="fas fa-file-download"></i>
                  Download
                </button>
              }
              <button onClick={() => {
                if (window.confirm("Are you sure you want to report this file?")) {
                  InternalTracker.trackEvent("File Reported", TRACKING_DIMENSIONS);
                  FileApi.reportFile(this.props.id || 0).then(() => {
                    toast.success("File reported successfully");
                  }).catch(() => {
                    toast.error("Failed to report file");
                  })
                }
              }}>
                <i className="fas fa-flag"></i>
                Report
              </button>
              <button onClick={() => { this.props.close() }}>
                <i className="fas fa-times"></i>
                Close
              </button>
            </div>
          </div>
          { this.props.type === FileType.Link &&
            <div className='link-warning'>
              This is an external link. We have no control over the content on <strong>{this.props.uri}</strong>. Proceed with caution.
              <button onClick={() => {
                InternalTracker.trackEvent("Opened File Link", TRACKING_DIMENSIONS);
                window.open(this.props.uri);
              }}>Open External Link</button>
            </div>
          }
          { (this.props.type === FileType.TextDocument) &&
            <DocViewer prefetchMethod="GET" pluginRenderers={DocViewerRenderers} documents={[{ uri: this.props.uri }]} />
          }
          { (this.props.type === FileType.Image) &&
            <div className='img'>
              <img src={this.props.uri} />
            </div>
          }
          { this.props.type === FileType.VideoLink &&
            <iframe width="100%" height="360" src={"https://www.youtube.com/embed/" + youtubeId + "?autoplay=1"} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen ></iframe>
          }
        </DocViewerModal>
      </Modal>
    );
  }
}

const DocViewerModal = styled.div`
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h3 {
        white-space: nowrap;
        overflow: hidden;
        padding-left: 10px;
        font-size: 15px;
        font-weight: 700;
      }

      .options {
        display: flex;
        justify-content: flex-end;
        padding: 12px;
  
        button {
          border: none;
          padding: 4px 10px;
          border-radius: 12px;
          font-size: 14px;
          margin-left: 10px;
  
          i {
            margin-right: 4px;
          }
        }
      }
    }

    .img {
      width: 100%;

      img {
        width: 100%;
        display: block;
      }
    }

    #react-doc-viewer {
      min-height: 600px;
    }

    iframe {
      display: block;
    }

    .link-warning {
      text-align: center;
      font-size: 1.4em;
      padding-bottom: 12px;

      button {
        width: 220px;
        display: inline-block;
        background-color: ${theme.colours.red2};
        color: white;
        border-radius: 52px;
        padding: 8px 12px;
        margin: 20px auto 12px auto;
        border: none;
        display: block;
      }
    }
`;

export default DocumentViewer;