/* eslint-disable */
import * as React from 'react';
import { Range, getTrackBackground } from 'react-range';
import theme from '../../css/theme';
import Utilities from '../../Utilities';

const STEP = 0.08333333333
const MIN: number = 0;
const MAX = 23;

export interface Props {
    values: number[],
    overnight: boolean,
    onChange: (values: number[]) => void,
    onFinalChange: (values: number[]) => void,
    compactDrag?: boolean,
    previewOnly?: boolean,
    previewOnlyInteraction?: () => void
}

interface State {
    values: number[]
}

class TimePresetSlider extends React.Component<
    Props,
    State
> {
    constructor(props) {
        super(props);
    }

    state: State = {
        values: this.props.overnight ? [this.props.values[1], this.props.values[0]] : this.props.values
    };

    componentWillReceiveProps(nextProps: Props) {
        if (!this.props.overnight && nextProps.values[0] > nextProps.values[1])
            return null;
        if (nextProps.values[0] !== this.state.values[0] || nextProps.values[1] !== this.state.values[1] || (nextProps.values[0] === this.state.values[1] && nextProps.values[1] === this.state.values[0])) {
            this.setState({
                values: this.props.overnight ? [Utilities.floor025(nextProps.values[1]), Utilities.floor025(nextProps.values[0])] : [Utilities.floor025(nextProps.values[0]), Utilities.floor025(nextProps.values[1])]
            })
        }
    }

  render() {
    
    const COLORS = 
        !this.props.overnight ? 
        ['whitesmoke', theme.colours.orange, 'whitesmoke', 'whitesmoke', 'whitesmoke'] :
        ['#444', 'whitesmoke', '#444', '#444', '#444']

    const now = new Date();
    const startRoundedToTwoDecimals = Math.round(this.state.values[0]*100)/100;
    const endRoundedToTwoDecimals = Math.round(this.state.values[1]*100)/100;
    const timeStartStr = 
        Math.floor(startRoundedToTwoDecimals) + ":" + 
        (Math.round(Utilities.getDecimal(startRoundedToTwoDecimals)*60) === 5 ? "0" : "") +
        (Math.round(Utilities.getDecimal(startRoundedToTwoDecimals)*60)) + 
        (Math.round(startRoundedToTwoDecimals) === startRoundedToTwoDecimals ? "0" : "")
    const timeEndStr = 
        Math.floor(endRoundedToTwoDecimals) + ":" + 
        (Math.round(Utilities.getDecimal(endRoundedToTwoDecimals)*60) === 5 ? "0" : "") +
        (Math.round(Utilities.getDecimal(endRoundedToTwoDecimals)*60)) + 
        (Math.round(endRoundedToTwoDecimals) === endRoundedToTwoDecimals ? "0" : "")

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexWrap: 'wrap'
        }}
      >
        <div
            style={{
                flexBasis: 'calc(100% - 110px)',
                margin: '0 15px'
            }}
            className="slider"
        >
            <Range
            values={this.state.values}
            step={STEP}
            min={0}
            max={23}
            onFinalChange={values => {

                if (values[0] === values[1]) {
                    return null;
                }

                if (this.props.previewOnly) {
                    if (this.props.previewOnlyInteraction)
                        this.props.previewOnlyInteraction();
                } else {
                    this.setState({ values })
                    this.props.onFinalChange(this.props.overnight ? [values[1], values[0]]: values );
                }

            }}
            onChange={values => {

                if (values[0] === values[1]) {
                    return null;
                }

                if (this.props.previewOnly) {
                    if (this.props.previewOnlyInteraction)
                        this.props.previewOnlyInteraction();
                } else {
                    this.setState({ values })
                    this.props.onChange(this.props.overnight ? [values[1], values[0]]: values );
                }

            }}
            renderTrack={({ props, children }) => (
                <div
                onMouseDown={props.onMouseDown}
                onTouchStart={props.onTouchStart}
                style={{
                    ...props.style,
                    height: '36px',
                    display: 'flex',
                    width: '100%'
                }}
                >
                <div
                    ref={props.ref}
                    style={{
                    height: '5px',
                    width: '100%',
                    borderRadius: '4px',
                    background: getTrackBackground({
                        values: this.state.values,
                        colors: COLORS,
                        min: MIN,
                        max: MAX
                    }),
                    alignSelf: 'center'
                    }}
                >
                    {children}
                </div>
                </div>
            )}
            renderThumb={({ props, index, isDragged }) => (
                <div
                    // @ts-ignore 
                    {...props}
                    style={{
                        ...props.style,
                        height: this.props.compactDrag ? '15px' : '26px',
                        width: this.props.compactDrag ? '15px' : '60px',
                        borderRadius: this.props.previewOnly ? '4px' : (this.props.compactDrag && index === 0) ? '0 0 52px 52px' : (this.props.compactDrag && index === 1) ? '52px 52px 0 0' : '52px',
                        backgroundColor: this.props.overnight ? '#444' : theme.colours.orange,
                        color: 'white',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        boxShadow: '0px 2px 6px #AAA'
                    }}
                >
                <div
                    style={{
                        height: '12px',
                        width: '3px',
                        borderRadius: '5px',
                        margin: '0px 3px',
                        backgroundColor: isDragged ? '#548BF4' : 'white',
                        display: this.props.compactDrag ? 'none' : 'unset'
                    }}
                /> 
                    <span
                        style={this.props.compactDrag ? {
                            position: "absolute",
                            top: this.props.previewOnly ? 0 : index === 0 ? -16 : 15,
                            background: this.props.overnight ? '#444' : theme.colours.orange,
                            padding: "4px 6px",
                            fontSize: "0.8em",
                            lineHeight: "0.86em",
                            borderRadius: this.props.previewOnly ? "4px 4px 4px 4px" : index === 0 ? "4px 4px 4px 0" : "4px 0 4px 4px",
                            left: index === 0 ? 0 : 'unset',
                            right: index === 1 ? 0 : 'unset'
                        } : {}}
                    >
                        { index === 0 ? timeStartStr : timeEndStr }
                    </span>
                <div
                    style={{
                        height: this.props.compactDrag ? '5px' : '12px',
                        width: this.props.compactDrag ? '5px' : '3px',
                        borderRadius: '5px',
                        margin: '0px 3px',
                        backgroundColor: isDragged ? '#548BF4' : 'white'
                    }}
                /> 
                </div>
            )}
            />
        </div>
        <div
            style={{
                flexBasis: 64,
                marginLeft: 16,
                backgroundColor: 'whitesmoke',
                display: 'inline-flex',
                borderRadius: 52,
                alignItems: 'center',
                justifyContent: 'center'
            }}
            className="toggle"
        >
            <div 
                style={{
                    width: 28,
                    background: !this.props.overnight ? theme.colours.orange : 'whitesmoke',
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: !this.props.overnight ? 'white' : theme.colours.blue2,
                    height: 28,
                    borderRadius: "100%",
                    cursor: 'pointer',
                }}
                onClick={() => {
                    if (this.props.previewOnly) {
                        if (this.props.previewOnlyInteraction)
                            this.props.previewOnlyInteraction();
                    } else {
                        this.props.onFinalChange(this.state.values[0] < this.state.values[1] ? this.state.values : [this.state.values[1], this.state.values[0]])
                    }
                }}
            >
                <i 
                    className="fas fa-sun"
                    style={{
                        color: !this.props.overnight ? 'white' : theme.colours.blue2,
                    }}
                ></i>
            </div>
            <div 
                style={{
                    width: 28,
                    background: this.props.overnight ? '#444' : 'whitesmoke',
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: this.props.overnight ? 'white' : theme.colours.blue2,
                    height: 28,
                    borderRadius: "100%",
                    cursor: 'pointer'
                }}
                onClick={() => {
                    if (this.props.previewOnly) {
                        if (this.props.previewOnlyInteraction)
                            this.props.previewOnlyInteraction();
                    } else {
                        this.props.onFinalChange(this.state.values[0] > this.state.values[1] ? this.state.values : [this.state.values[1], this.state.values[0]])
                    }
                }}
            >
                <i 
                    className="fas fa-moon"
                    style={{
                        color: this.props.overnight ? 'white' : theme.colours.blue2,
                    }}
                ></i>
            </div>
        </div>
        { ((window as any).Cypress) &&
            <div className="inputs">
                <div>
                    <input
                        value={timeStartStr + "-" + timeEndStr}
                        className="timepreset-input"
                        onChange={(e) => {

                            const newValues = e.target.value;
                            const newStartStr = newValues.split("-")[0];
                            const newEndStr = newValues.split("-")[1]

                            this.props.onFinalChange([parseInt(newStartStr.split(":")[0]) + parseInt(newStartStr.split(":")[1])/60, parseInt(newEndStr.split(":")[0]) + parseInt(newEndStr.split(":")[1])/60]);
                        }}
                    />
                </div>
            </div>
        }
      </div>
    );
  }
}

export default TimePresetSlider;