import React from 'react';
import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';

import theme from '../../css/theme';

export interface LayoutProps {
    children?: React.ReactNode;
}

export class MobileActionLayout extends React.Component<LayoutProps, {}> {
    public render() {
        return (
            <Wrapper className="container app">
                <ToastContainer position="top-center" />
                <div className="row">{this.props.children}</div>
            </Wrapper>
        );
    }
}

const Wrapper = styled.div`
    width: 100vw;
    height: 100vh;
    padding-top: 20px;
    overflow-y: scroll;
    color: #ddd;
    background: ${theme.colours.updatedge};
`;
