/* eslint-disable */

import React, { useEffect, useState, useCallback } from 'react';
import { debounce } from 'ts-debounce';

import OrganisationApi from '../../api/organisation/Organisation';
import { ProfileImage } from '../ui-components/ProfileImage';
import { LogoSuggestionDTO } from '../../api/organisation/ResponseTypes';

enum Status {
    Loading,
    Errored,
    Found,
    NotFound
}

interface Props {
    organisationName: string;
    onSelect?: (...any) => any;
}

const InlineOrganisationImageSuggestions = ({
    organisationName,
    onSelect
}: Props) => {
    const [status, setStatus] = useState(Status.Loading);
    const [imageUrls, setImageUrls] = useState<Array<string>>([]);

    const getSuggestedImages = async (organisationName) => {
        setStatus(Status.Loading);
        try {
            OrganisationApi.getLogoSuggestions(organisationName).then(
                ({ data }) => {
                    const urls = data.map((l: LogoSuggestionDTO) => {
                        return l.logoUrl;
                    });
                    setStatus(Status.Found);
                    setImageUrls(urls);
                }
            );
        } catch (error) {
            setStatus(Status.Errored);
        }
    };

    const debouncedImageGetter = useCallback(
        debounce(getSuggestedImages, 300),
        []
    );

    useEffect(() => {
        if (!organisationName) return;
        debouncedImageGetter(organisationName);
    }, [debouncedImageGetter, organisationName]);

    return (
        <React.Fragment>
            <div
                className="layout horizontal center-center logo-suggestion"
                style={{
                    display: 'inline-block'
                }}
            >
                {status === Status.Found &&
                    imageUrls.slice(0, 5).map((url) => {
                        return (
                            <ProfileImage
                                aria-label="logoSuggestion"
                                url={url}
                                style={{ marginRight: 8 }}
                                key={url}
                                size={32}
                                type={'Organisation'}
                                selectable={true}
                                onSelect={() => {
                                    if (onSelect) {
                                        onSelect(url);
                                    }
                                }}
                            />
                        );
                    })}
            </div>
            <div>
                <a
                    style={{
                        display: 'block',
                        color: 'blue',
                        margin: '4px 0 8px 0'
                    }}
                    href="https://clearbit.com"
                    target="_blank"
                    rel="noreferrer"
                >
                    Logos provided by Clearbit
                </a>
            </div>
        </React.Fragment>
    );
};

export default InlineOrganisationImageSuggestions;