/* eslint-disable */
import React from 'react';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { FeatureItem } from '../../api/subscriptions/ResponseTypes';
import { connect } from 'react-redux';

import { CompanyRoles } from '../../api/company/ResponseTypes';
import SubscriptionsApi from '../../api/subscriptions/Subscriptions';
import AppConfig from '../config/Config';
import { sortByProp, Feature } from '../../constants';
import SimpleTooltip from '../ui-components/SimpleTooltip';
import InternalTracker from '../../InternalTracker';
import { UserDto } from '../../api/user/ResponseTypes';
import Overlay from '../ui-components/Overlay';
import { FreeTrialText } from './Marketing';
import InputRange from '../ui-components/InputRange';
import { SubscriptionUtilsProvider, Content } from './subscription-utils';
import { Spinner } from '../ui-components/Spinner';
import SvgProtection from '../svg/protection';
import * as EventsState from '../../store/Events';
import { Subscribe } from '../../unstated-fork/unstated';
import DashboardContainer from '../../state-containers/dashboard.container';
import { UserTypeContainer } from '../../state-containers/user-type.container';

export interface Props {
    members: UserDto[];
    self: UserDto;
    isFirstTeam: boolean;
    onNoCheckoutSessionId?: Function;
    refreshOverlayMenu: typeof EventsState.actionCreators['refreshOverlayMenu'];
}

interface State {
    name: string;
    leaderEmail: string;
    userGroups: number;
    createdClicked: boolean;
}

export const TEAM_CREATED_SUCCESS_MESSAGE = `Team created! Don't forget to add members to your new team.`;
class TeamsSubscription extends React.Component<Props, State> {
    state = {
        name: '',
        leaderEmail: this.self!.email,
        userGroups: 1,
        createdClicked: false
    } as State;

    render() {
        return (
            <SubscriptionUtilsProvider
                featureId={Feature.Teams}
                render={(feature) => (
                    <Content isAdmin={this.self!.roleId == CompanyRoles.Admin}>
                        <h4>Create Your Team</h4>
                        {feature == null && <Spinner />}

                        {feature != null && (
                            <div>
                                <div className="layout horizontal center-justified wrap">
                                    <div
                                        className="split"
                                        style={{
                                            marginBottom: '20px',
                                            position: 'relative'
                                        }}
                                    >
                                        <label htmlFor="team-name">
                                            Team Name
                                        </label>
                                        {this.state.createdClicked &&
                                            !this.state.name.length && (
                                                <i
                                                    className="fa fa-warning"
                                                    style={{
                                                        position: 'absolute',
                                                        top: '32px',
                                                        left: '-24px',
                                                        color: 'orangered',
                                                        fontSize: '16px'
                                                    }}
                                                />
                                            )}
                                        <input
                                            id="team-name"
                                            type="text"
                                            placeholder={
                                                'E.g. "catering" or "cover staff"'
                                            }
                                            className="form-control"
                                            value={this.state.name}
                                            onChange={(ev) =>
                                                this.setState({
                                                    name: ev.target.value
                                                })
                                            }
                                            maxLength={255}
                                        />
                                        <div className="layout horizontal center justified m-t-sm">
                                            <label>Team Manager</label>
                                            <SimpleTooltip
                                                id="team-manager-tip"
                                                text="Only admins and the manager of a team can manage team members"
                                            >
                                                <i
                                                    className="fa fa-question-circle m-l-xs"
                                                    style={{
                                                        color: '#66F',
                                                        cursor: 'help',
                                                        fontSize: '14px'
                                                    }}
                                                />
                                            </SimpleTooltip>
                                        </div>
                                        <select
                                            className="form-control"
                                            value={this.state.leaderEmail}
                                            onChange={(ev) =>
                                                this.setState({
                                                    leaderEmail: ev.target.value
                                                })
                                            }
                                        >
                                            {sortByProp(
                                                this.props.members,
                                                (m) => m.lastName
                                            ).map((m) => (
                                                <option
                                                    key={m.email}
                                                    value={m.email}
                                                >
                                                    {m.firstName} {m.lastName}
                                                </option>
                                            ))}
                                        </select>
                                        <label>Team Size</label>
                                        <InputRange
                                            maxValue={17}
                                            minValue={1}
                                            step={1}
                                            value={this.state.userGroups}
                                            onChange={(value) =>
                                                this.setState({
                                                    userGroups: value as number
                                                })
                                            }
                                            formatLabel={() =>
                                                this.userCountLabel
                                            }
                                        />
                                    </div>
                                    <div className="split plan">
                                        <label>Plan Summary</label>
                                        <div className="layout horizontal justified center">
                                            <span>
                                                1 Team, {this.userCountLabel}
                                            </span>
                                            <div className="right">
                                                <strong>
                                                    £{feature.singularPrice} GBP
                                                </strong>
                                                <small>Per user</small>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="layout horizontal justified total">
                                            <span>Total</span>
                                            <div className="right">
                                                {this.props.isFirstTeam && (
                                                    <>
                                                        <strong>£0</strong>
                                                        <small>
                                                            For {feature!.trial}{' '}
                                                            days
                                                        </small>
                                                    </>
                                                )}
                                                <strong
                                                    style={{
                                                        marginTop: '10px'
                                                    }}
                                                >
                                                    {this.props.isFirstTeam
                                                        ? 'Then'
                                                        : ''}{' '}
                                                    £
                                                    {this.userCount > 50
                                                        ? '??'
                                                        : (
                                                              this.state
                                                                  .userGroups *
                                                              (feature!
                                                                  .unitPrice /
                                                                  100)
                                                          ).toFixed(2)}{' '}
                                                    GBP
                                                </strong>
                                                <small>
                                                    {feature!.billingFrequency}
                                                </small>
                                            </div>
                                        </div>
                                        {this.props.isFirstTeam && (
                                            <div
                                                className="marketing"
                                                data-testid="teams-free-trial-marketing"
                                            >
                                                <SvgProtection />

                                                <FreeTrialText
                                                    totalTrialDays={
                                                        feature.trial
                                                    }
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {this.userCount > 50 && (
                                    <a
                                        href="mailto:support@updatedge.com?subject=Large Team Query"
                                        style={{
                                            display: 'block',
                                            color: 'orangered',
                                            fontSize: '15px',
                                            fontWeight: 600,
                                            marginBottom: '-10.5px'
                                        }}
                                    >
                                        Please contact us for teams with more
                                        than 50 users
                                    </a>
                                )}
                                {this.state.createdClicked &&
                                    (!this.state.name.length ||
                                        !this.state.leaderEmail) && (
                                        <div
                                            style={{
                                                color: 'orangered',
                                                fontSize: '15px',
                                                fontWeight: 600,
                                                marginTop: '-10px',
                                                marginBottom: '-10.5px'
                                            }}
                                        >
                                            {!this.state.name.length && (
                                                <span>
                                                    Please enter a name for the
                                                    team
                                                </span>
                                            )}
                                            {!this.state.name.length &&
                                                !this.state.leaderEmail && (
                                                    <br />
                                                )}
                                            {!this.state.leaderEmail && (
                                                <span>
                                                    Please select a manager for
                                                    the team
                                                </span>
                                            )}
                                        </div>
                                    )}
                                <Button
                                    bsStyle="success"
                                    bsSize="small"
                                    onClick={() =>
                                        this.handleCreateTeamClick(feature!)
                                    }
                                    disabled={this.userCount > 50}
                                >
                                    Create Team
                                </Button>
                            </div>
                        )}

                        {this.self!.roleId != CompanyRoles.Admin && (
                            <Overlay>
                                Only organization administrators can create
                                teams.
                                <br />
                                Ask one of the following admins to create a team
                                for you:
                                <ul>
                                    {sortByProp(
                                        this.allAdmins.slice(0, 6),
                                        (m) => m.lastName
                                    ).map((m) => (
                                        <li key={m.contactId}>
                                            {m.firstName} {m.lastName}
                                        </li>
                                    ))}
                                    {this.allAdmins.length > 6 && (
                                        <li
                                            title={this.allAdmins
                                                .slice(6, this.allAdmins.length)
                                                .map(
                                                    (m) =>
                                                        `${m.firstName} ${m.lastName}`
                                                )
                                                .join(', ')}
                                        >
                                            + {this.allAdmins.length - 6}{' '}
                                            more...
                                        </li>
                                    )}
                                </ul>
                            </Overlay>
                        )}
                    </Content>
                )}
            />
        );
    }

    get self() {
        return this.props.self;
    }

    get allAdmins() {
        return this.props.members.filter((m) => m.roleId == CompanyRoles.Admin);
    }

    get userCount() {
        return this.state.userGroups * 3;
    }

    get userCountLabel() {
        return this.userCount > 50 ? '>50 users' : `${this.userCount} users`;
    }

    handleCreateTeamClick = async (feature: FeatureItem) => {
        this.setState({ createdClicked: true });

        InternalTracker.trackEvent("", {
            category: 'Teams',
            action: 'Create Team',
            customDimensions: [{ id: "REPLACE", value: this.state.name }]
        });

        if (this.state.name.length) {
            const { publicApiKey } = AppConfig.Settings.stripe;

            const leaderUser = this.props.members.find(
                ({ email }) => email === this.state.leaderEmail
            ) || { id: null };

            const leaderUserId = leaderUser.id || '';

            if (!leaderUserId) {
                toast.error(
                    'The selected user must create an account with a matching email address to be a team leader.'
                );

                return;
            }

            const sessionId = await SubscriptionsApi.createCheckoutSession({
                items: [
                    {
                        unitsPurchased: this.state.userGroups,
                        priceId: feature!.priceId,
                        productOptions: {
                            team: {
                                name: this.state.name,
                                leaderUserId
                            }
                        }
                    }
                ]
            });

            if (sessionId === '') {
                const { onNoCheckoutSessionId } = this.props;
                toast.success(TEAM_CREATED_SUCCESS_MESSAGE);
                if (this.props.refreshOverlayMenu) {
                    this.props.refreshOverlayMenu();
                }
                if (onNoCheckoutSessionId) onNoCheckoutSessionId();
                return;
            }

            // TODO: Replace with @stripe/stripe-js for types and better fraud protection
            const Stripe = (window as any).Stripe;
            if (Stripe == null) return;
            const stripe = Stripe(publicApiKey);
            stripe
                .redirectToCheckout({ sessionId })
                .then((result) => toast.error(result && result.error ? result.error.message : ""));
        }
    };
}

export default connect(null, { ...EventsState.actionCreators })((props) => (
    <Subscribe to={[DashboardContainer, UserTypeContainer]}>
        {(dashboard: DashboardContainer, userType: UserTypeContainer) => (
            <TeamsSubscription
                // @ts-ignore
                {...props}
                dashboardContainer={dashboard}
                userType={userType}
            />
        )}
    </Subscribe>
)) as any;
