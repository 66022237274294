/* eslint-disable */
import React from 'react';
import ReactStars from "react-rating-stars-component";
import './WorkerRater.css';
import ProfileApi from '../../api/profile/Profile';
import { ProfileImage } from '../ui-components/ProfileImage';
import RatingApi from '../../api/ratings/Rating';
import { ToastType } from 'react-toastify';
import * as Notifications from '../../store/Notifications';
import Dialog from '../ui-components/Dialog';
import { Modal } from 'react-bootstrap';
import { actionCreators } from 'src/store/Profile';
import InternalTracker from 'src/InternalTracker';
import theme from 'src/css/theme';

export interface ContactWithRating {
    contactId?: string,
    stars: number,
    userId: string,
    publicComment: string,
    privateComment: string,
    name: string,
    hasChanged?: boolean,
    recipientFirstName?: string,
    recipientLastName?: string,
    recipientUserId?: string
}

type Props = {
    contacts: ContactWithRating[];
    onEdit: (contact: ContactWithRating) => {};
    onClick?: () => void;
    onSave?: (contact: ContactWithRating, i: number) => {};
    onSaveDone?: (stars: number) => void;
    showProfile?: boolean;
    showSave?: boolean;
    extendedHeightWithoutFocus?: boolean;
    warnOneStarHide?: boolean;
    hideOnOneStar?: boolean;
    mode?: string;
    title?: string;
    id?: string;
    className?: string;
}

interface State {
    focusedUserId: string | null;
    contacts: ContactWithRating[];
    contactModal: ContactWithRating | null;
    contactModalI: number | null;
    tempHideForReload: boolean;
    unsavedData: boolean;
    loading: boolean;
}

class WorkerRater extends React.Component<Props, State> {

    private loading: boolean = false;

    constructor(props: Props) {
        super(props);

        this.state = {
            focusedUserId: null,
            contacts: props.contacts || [],
            contactModal: null,
            contactModalI: null,
            tempHideForReload: false,
            unsavedData: false,
            loading: false
        };
    }

    save(contact: ContactWithRating, i: number) {
        InternalTracker.trackEvent("Rating Saved");

        if (this.loading) {
            setTimeout(() => {
                this.save(contact, i);
            }, 500)
            return;
        }

        this.loading = true;
        this.setState({ loading: true })
        let orgId = localStorage.getItem("dashboard") ? JSON.parse(localStorage.getItem("dashboard") || "").companyId : null;
        const orgIsAgency = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user") || "").organisationIsAgency : false;

        if (!orgId && window.location.href.indexOf("/external") === -1) {
            this.loading = false;
            this.setState({ loading: false })
            alert("Ratings are saved on organization level, please create an organization before rating");
            return;
        }

        if (window.location.pathname.startsWith("/contact")) {
            localStorage.setItem("justUpdatedContactRating", contact.userId)
        }

        if (typeof this.props.onSave !== 'undefined') {
            this.props.onSave(contact, i);
            this.loading = false;
            this.setState({ loading: false })
        } else {
            // @ts-ignore
            RatingApi.postWorkerRatingByHirerOrg(orgId, [contact], orgIsAgency).then(data => {
                Notifications.actionCreators.display(
                    ToastType.SUCCESS,
                    "Rating Saved"
                );
                this.loading = false;
                this.setState({ loading: false })
                if (this.props.onSaveDone)
                    this.props.onSaveDone(contact.stars);
                this.setState({
                    unsavedData: false
                })
            }).catch(e => {
                Notifications.actionCreators.display(
                    ToastType.ERROR,
                    "Failed to save Rating"
                );
                this.loading = false;
                this.setState({ loading: false })
            })
        }
    }

    onFocus(txtElement) {
        if (!(window as any).Cypress) {
            setTimeout(() => {
                txtElement.focus();
                txtElement.setSelectionRange(0,0);
            }, 100)
        }
    }

    render() {

        const STARS_DOM = (contact, i) => <div 
            className='stars-wrapper'
            data-stars={contact.stars}
            style={(!this.props.showProfile) ? { marginLeft: 0, flexWrap: 'wrap' } : { flexWrap: 'wrap' }}
        >
            { this.props.title &&
                <h2>{this.props.title}</h2>
            }
            <ReactStars
                value={contact.stars}
                count={5}
                onChange={(newStars) => {
                    let contacts = this.state.contacts;
                    contacts[i].stars = newStars;
                    InternalTracker.trackEvent("Rating Stars Changed", {
                        stars: newStars
                    });
                    contacts[i].hasChanged = true;
                    this.setState({
                        contacts: JSON.parse(JSON.stringify(contacts)),
                        tempHideForReload: true
                    }, () => {
                        this.setState({
                            tempHideForReload: false
                        })
                        if (this.props.mode !== "modal") {
                            this.save(this.state.contacts[i], i);
                        } else {
                            this.props.onEdit(contacts[i])
                        }
                    });
                }}
                size={24}
                activeColor="#ffd700"
            />
        </div>

        const PUBLIC_COMMENT_DOM = (contact, i, expanded) => <div className='comments public'>
            <label>
                <i className="fas fa-globe"></i>
                <span>Public comment for all to see</span>
            </label>
            <textarea
                className="form-control"
                placeholder="Public comment for all to see"
                value={contact.publicComment}
                onChange={(ev) => {
                    let contacts = this.state.contacts;
                    contacts[i].publicComment = ev.target.value;
                    contacts[i].hasChanged = true;
                    this.setState({
                        contacts: contacts,
                        contactModal: contacts[i],
                        unsavedData: true
                    });
                    this.props.onEdit(contacts[i])
                }}
                rows={this.state.focusedUserId === contact.userId || this.props.extendedHeightWithoutFocus || expanded ? 4 : 1}
                style={{
                    overflow: this.state.focusedUserId === contact.userId || this.props.extendedHeightWithoutFocus || expanded ? "auto" : "hidden"
                }}
                onFocus={((e) => {
                    this.onFocus(e.target);
                    setTimeout(() => {
                        this.setState({
                            focusedUserId: contact.userId
                        })
                    }, 400)
                })}
                onBlur={(() => {
                    InternalTracker.trackEvent("Rating Public Changed");
                    setTimeout(() => {
                        this.setState({
                            focusedUserId: null
                        })
                    }, 200)
                })}
            />
        </div>

        const PRIVATE_COMMENT_DOM = (contact, i, expanded?) => <div className='comments private'>
            <label>
                <i className="fas fa-lock"></i>
                <span>Private comment only for you</span>
            </label>
            <textarea
                className="form-control"
                placeholder="Private comment only you can see"
                value={contact.privateComment}
                onChange={(ev) => {
                    let contacts = this.state.contacts;
                    contacts[i].privateComment = ev.target.value;
                    contacts[i].hasChanged = true;
                    this.setState({
                        contacts: contacts,
                        contactModal: contacts[i],
                        unsavedData: true
                    });

                    this.props.onEdit(contacts[i])
                }}
                rows={this.state.focusedUserId === contact.userId || this.props.extendedHeightWithoutFocus || expanded ? 4 : 1}
                style={{
                    overflow: this.state.focusedUserId === contact.userId || this.props.extendedHeightWithoutFocus || expanded ? "auto" : "hidden"
                }}
                onFocus={((e) => {
                    this.onFocus(e.target);
                    setTimeout(() => {
                        this.setState({
                            focusedUserId: contact.userId
                        })
                    }, 400)
                })}
                onBlur={(() => {
                    InternalTracker.trackEvent("Rating Public Changed");
                    setTimeout(() => {
                        this.setState({
                            focusedUserId: null
                        })
                    }, 200)
                })}
            />
        </div>

        const PROFILE_DOM = (contact) => <div className='profile'>
            <ProfileImage 
                size={32}
                url={ProfileApi.getProfileImageUrl(contact.userId || "")}
                selectable={false}
                style={{
                    padding: 0,
                    border: 0,
                    marginRight: 6
                }}
            />
            <p>
                {contact.name}
            </p>
        </div>

        const ONE_STAR_HIDE_WARN = (contact) => <Dialog 
            type="warning" 
            body={"We will automatically hide contacts, including " + contact.name + " with 1 star ratings from your next timesheet."} 
            style={{
                marginBottom: 24
            }}
        />

        const SAVE_BTN = (contact, i) => <button
            className={'save-rating-btn' + (this.props.mode === "inline" && this.state.unsavedData ? " pulse no-size" : "")}
            style={{ alignSelf: this.props.mode === "inline" ? 'flex-start' : undefined, background: this.state.unsavedData ? theme.colours.red2 : undefined }}
            onClick={() => {
                let contact = this.state.contacts[i];
                if (!contact.stars) {
                    Notifications.actionCreators.display(
                        ToastType.ERROR,
                        "Select a star rating from 1 to 5"
                    );
                    return;
                }
                this.save(contact, i);
                this.setState({
                    contactModal: null,
                    contactModalI: null
                })
            }}
        >
            { this.props.mode === "inline" ? (this.state.unsavedData ? "Save (Unsaved Data)" : this.state.loading ? "Saving..." : "Save") : "Save" }
        </button>
    
        return (
            <div className={'worker-rater ' + this.props.className || ""} data-mode={this.props.mode}>
                { this.state.contacts.map((contact, i) => {
                    return (
                        <React.Fragment>
                            <div 
                                className='worker'
                                data-focused={contact.userId === this.state.focusedUserId}
                                data-worker-id={contact.userId}
                            >
                                { (this.props.showProfile) &&
                                    PROFILE_DOM(contact)
                                }
                                { (this.props.mode === "modal") &&
                                    <div 
                                        className='stars-wrapper'
                                        data-stars={contact.stars}
                                        id={this.props.id}
                                        style={{
                                            flexBasis: "unset",
                                            position: "relative",
                                            left: -3,
                                            top: -3
                                        }}
                                        onClick={() => {
                                            if (this.props.onClick) {
                                                this.props.onClick();
                                            }
                                            this.setState({
                                                contactModal: contact,
                                                contactModalI: i
                                            })
                                        }}
                                    >
                                        { (!this.state.tempHideForReload) &&
                                            <ReactStars
                                                value={contact.stars}
                                                count={5}
                                                edit={false}
                                                onChange={(newStars) => {
                                                
                                                }}
                                                size={14}
                                                activeColor="#ffd700"
                                            />
                                        }
                                    </div>
                                }
                                { (this.props.mode !== "modal") &&
                                    STARS_DOM(contact, i)
                                }
                                { (this.props.mode !== "modal") &&
                                    PUBLIC_COMMENT_DOM(contact, i, false)
                                }
                                { (this.props.mode !== "modal") &&
                                    PRIVATE_COMMENT_DOM(contact, i, false)
                                }
                                { (this.props.showSave) &&
                                    <button
                                        className={'save-rating-btn' + (this.props.mode === "inline" && this.state.unsavedData ? " pulse no-size" : "")}
                                        style={{ alignSelf: this.props.mode === "inline" ? 'flex-start' : undefined, background: this.state.unsavedData ? theme.colours.red2 : undefined }}
                                        onClick={() => {
                                            let contact = this.state.contacts[i];
                                            if (!contact.stars) {
                                                Notifications.actionCreators.display(
                                                    ToastType.ERROR,
                                                    "Select a star rating from 1 to 5"
                                                );
                                                return;
                                            }
                                            if (this.props.mode !== "modal") {
                                                this.save(contact, i);
                                            }
                                            this.setState({
                                                contactModal: null,
                                                contactModalI: null
                                            })
                                            this.props.onEdit(contact);
                                        }}
                                    >
                                        { this.props.mode === "inline" ? (this.state.unsavedData ? "Save (Unsaved Data)" : this.state.loading ? "Saving..." : "Save") : "Save" }
                                    </button>
                                }
                            </div>
                            { (contact.stars === 1 && this.props.hideOnOneStar && this.props.mode !== "modal") &&
                                ONE_STAR_HIDE_WARN(contact)
                            }
                        </React.Fragment>
                    )
                }) }
                { (this.state.contactModal && this.state.contactModalI !== null) &&
                    <Modal
                        show={true}
                        onHide={() => {
                            this.setState({
                                contactModal: null,
                                contactModalI: null
                            })
                        }}
                        className="rate-modal"
                    >
                        <Modal.Body>
                            <div className="worker" style={{ marginBottom: 12 }}>
                                {PROFILE_DOM(this.state.contactModal)}
                                {STARS_DOM(this.state.contactModal, this.state.contactModalI)}
                                {PUBLIC_COMMENT_DOM(this.state.contactModal, this.state.contactModalI, true)}
                                {PRIVATE_COMMENT_DOM(this.state.contactModal, this.state.contactModalI, true)}
                                { (this.state.contactModal.stars === 1 && this.props.hideOnOneStar) &&
                                    ONE_STAR_HIDE_WARN(this.state.contactModal)
                                }
                                {SAVE_BTN(this.state.contactModal, this.state.contactModalI)}
                            </div>
                        </Modal.Body>
                        {this.props.children}
                    </Modal>
                }
            </div>
        )
    }
}

export default WorkerRater;