/* eslint-disable */
import React from 'react';
import styled from 'styled-components';
import { Table, Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { ToastType, ToastContainer } from 'react-toastify';
import * as Notifications from '../../store/Notifications';

import AppConfig from '../../components/config/Config';
import VerificationApi from '../../api/verification/Verification';
import OrganisationApi from '../../api/organisation/Organisation';
import { ExternalOrganisationLogoRecord } from '../../api/organisation/ResponseTypes';
import { AsyncOverlay } from '../../components/ui-components/AsyncOverlay';
import { CompanyRoles } from '../../api/company/ResponseTypes';
import { ProfileImage } from '../../components/ui-components/ProfileImage';
import ProfileApi from '../../api/profile/Profile';
import { UserType } from '../../state-containers/user-type.container';
import theme from '../../css/theme';
import Utilities from 'src/Utilities';

interface State {
    loading: boolean;
    results: ExternalOrganisationLogoRecord[];
    search: string;
    statusTypeId: string;
    reloadsI: number;
    page: number;
    imageUploadUrl: string;
}

const RESULTS_PER_PAGE = 25

export default class ExternalOrgLogos extends React.Component<{}, State> {
    state = {
        loading: true,
        results: [],
        search: '',
        statusTypeId: '1',
        reloadsI: 0,
        page: 0,
        imageUploadUrl: ''
    } as State;

    componentDidMount() {
        this.reload();
    }

    async reload() {
        this.setState({
            loading: true
        })
        const results = await OrganisationApi.getAllExternalOrganisationLogos();
        this.setState({
            results: results,
            loading: false,
            reloadsI: this.state.reloadsI++
        })
    }

    updateValue(url, name, value) {
        let results = this.state.results;
        const i = results.findIndex(r => r.url === url);
        results[i][name] = name === "approved" ? ( !results[i][name] ) : value;
        if (name === "approved") {
            results[i].justApproved = true;
        }
        this.setState({ results: results })
    }

    save(url) {
        let logo = this.state.results[this.state.results.findIndex(r => r.url === url)];
        // @ts-ignore
        logo.approved = logo.approved === true || logo.approved === "on";
        if (logo.newLogoUrl && !Utilities.isValidURL(logo.newLogoUrl)) {
            alert("Invalid image url");
            return;
        }
        // this.setState({
        //     loading: true
        // })

        OrganisationApi
            .updateExternalOrganisationLogo(logo)
            .then(data => {
                Notifications.actionCreators.display(ToastType.SUCCESS, "Saved");
                this.setState({
                    loading: false
                })
                // this.reload();
            })
            .catch(data => { 
                Notifications.actionCreators.display(ToastType.ERROR, "Failed to Save");
                this.setState({
                    loading: false
                })
            })
    }

    filterList(results) {
        return results.filter((res, resI) => {
            if (this.state.statusTypeId === '1' && res.approved && !res.justApproved) {
                return false;
            }
            return true;
        }).filter((res, resI) => {
            return resI < (this.state.page + 1) * RESULTS_PER_PAGE;
        })
    }

    async handleUpload(file) {
        console.log(file)
        const base64 = ((await Utilities.resizeAndRotateImageV2(file, 256, true)) as string)
        console.log(base64)
        
        this.setState({
            loading: true
        })

        await OrganisationApi.uploadImageToExternalOrg(this.state.imageUploadUrl, base64.split(',')[1]);

        this.setState({
            loading: false,
            reloadsI: this.state.reloadsI + 1
        })
    }
    
    render() {
        return (
            <Wrapper>
                <ToastContainer position="top-center" />
                <h3>External Organisation Logos</h3>
                <div style={{
                    marginBottom: 20
                }}>
                    <button onClick={() => { this.reload(); }}>Reload Data</button>
                    <select
                        value={this.state.statusTypeId}
                        onChange={(e) => {
                            this.setState({
                                statusTypeId: e.target.value
                            })
                        }}
                    >
                        { [
                            {
                                id: '0',
                                label: "All Logos"
                            },
                            {
                                id: '1',
                                label: "Unapproved Logos"
                            }
                        ].map(item => {
                            return (
                                <option value={item.id}>{item.label}</option>
                            )
                        }) }
                    </select>
                </div>
                <Table condensed striped responsive bordered>
                    <thead>
                        <tr>
                            <th>Domain</th>
                            <th>Logo</th>
                            <th>New Logo Url</th>
                            <th>Name</th>
                            <th>Postcode</th>
                            <th>Approved</th>
                        </tr>
                    </thead>
                    <tbody>
                        { this.filterList(this.state.results).map((res, i) => {
                            return (
                                <tr key={res.url}>
                                    <td>{res.url}</td>
                                    <td>
                                        <img style={{ objectFit: "cover" }} src={`${AppConfig.Settings.api.externalUri}/organisations/external/logo/${res.url}?api-version=1.0&v=${this.state.reloadsI}`} />
                                    </td>
                                    <td>
                                        <input name="newLogoUrl" placeholder='Enter Link' value={res.newLogoUrl} onChange={({ target: { name, value } }) => { this.updateValue(res.url, name, value) }} />
                                        <button className='option-btn' onClick={() => {
                                            this.setState({
                                                imageUploadUrl: res.url
                                            })
                                            let inputEl = document.getElementById("image-file-input");
                                            if (inputEl) {
                                                inputEl.click();
                                            }
                                        }}>or Upload</button>
                                    </td>
                                    <td><input name="name" value={res.name} onChange={({ target: { name, value } }) => { this.updateValue(res.url, name, value) }} /></td>
                                    <td><input name="postcode" value={res.postcode} onChange={({ target: { name, value } }) => { this.updateValue(res.url, name, value) }} /></td>
                                    <td><input name="approved" type="checkbox" defaultChecked={res.approved} onChange={({ target: { name, value } }) => { this.updateValue(res.url, name, value) }} /></td>
                                    <td>
                                        <button onClick={() => {
                                            this.save(res.url);
                                        }}>Save</button>
                                    </td>
                                </tr>
                            )
                        }) }
                    </tbody>
                </Table>
                <input 
                    id="image-file-input" 
                    type="file" 
                    style={{
                        display: 'none'
                    }}
                    onChange={(e) => {
                        this.handleUpload(e.target.files ? e.target.files[0] : null);
                    }}
                ></input>
                <button
                    style={{
                        width: '100%'
                    }}
                    onClick={() => {
                        this.setState({
                            page: this.state.page + 1
                        })
                    }}
                >
                    Show More
                </button>
                <AsyncOverlay show={this.state.loading} />
            </Wrapper>
        );
    }

}

const Wrapper = styled.div`

    button:not(.option-btn) {
        background: ${theme.colours.blue2};
        border: none;
        padding: 12px;
        marginBottom: 20px;
        color: white;
    }

    select {
        padding: 12px;
        margin-left: 20px;
    }
 
    table {

        img {
            width: 64px;
            height: 64px;
            border-radius: 100%;
        }

        input:not([type="checkbox"]) {
            width: 80px;
            max-width: 100%;
        }
    }

`;
