import React from 'react';
import CookieConsent from 'react-cookie-consent';

import Analytics from '../../services/analytics.service';

const UECookieConsent = () => {
    return (
        <CookieConsent style={{ background: 'grey' }}>
            This website uses cookies to enhance the user experience. Read our
            <a
                onClick={() => {
                    Analytics.trackEvent(
                        'cookie-consent-privacy-policy-clicked'
                    );
                }}
                href="https://ukliveue.blob.core.windows.net/policies/PortalPrivacy.htm"
                target="new"
            >
                privacy policy
            </a>
            for more information on what we do with your data.
        </CookieConsent>
    );
};

export default UECookieConsent;
