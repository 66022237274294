/* eslint-disable */
import React from 'react';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';

import { Subscribe } from '../../unstated-fork/unstated';
import { nameofFactory, KeyCodes } from '../../constants';
import ContactApi from '../../api/contacts/Contacts';
import Analytics from '../../services/analytics.service';
import { ProfileVerificationContainer } from '../../state-containers/profile-verification.container';
import theme from '../../css/theme';
import InternalTracker from '../../InternalTracker';
import * as EventsState from '../../store/Events';
import DashboardContainer from '../../state-containers/dashboard.container';
import { UserTypeContainer } from '../../state-containers/user-type.container';
import { RegularExpressions } from '../../constants';

type InviteProps = {
    onInvited?: () => void;
    autoOpen?: boolean;
    // refreshOverlayMenu: EventsState.actionCreators['refreshOverlayMenu'];
} & typeof EventsState.actionCreators;

interface InviteState {
    emailAddresses: string;
    showModal: boolean;
}

const nameof = nameofFactory<InviteState>();

class ContactInvite extends React.Component<InviteProps, InviteState> {
    constructor(props) {
        super(props);
        this.state = {
            emailAddresses: '',
            showModal: false || props.autoOpen
        };
        this.handleFieldContentChange = this.handleFieldContentChange.bind(
            this
        );
    }

    /** Fires when the user an input element's  field content and updates local draft state */
    handleFieldContentChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
        this.setState({ ...this.state, [e.target.name]: e.target.value });
    }

    handleKeyPress = (
        ev: React.KeyboardEvent<HTMLTextAreaElement>,
        verified: boolean
    ) => {
        if (!verified && ev.nativeEvent.keyCode == KeyCodes.ENTER) {
            ev.preventDefault();
        }
    };

    /** Sends the invitations */
    sendInvitations(verified: boolean) {

        let emails = this.state.emailAddresses.split('\n').map(email => email.replace(",", "").trim());

        if (!verified) {
            emails = [emails[0]];
        }

        let validEmails = emails.filter((item) =>
            RegularExpressions.emailAddress.test(item.trim())
        );

        if (validEmails.length !== emails.length) {
            toast.error(
                emails.length - validEmails.length === 1
                    ? 'There is an invalid email in your list'
                    : 'There are ' +
                          (emails.length - validEmails.length) +
                          ' invalid emails in your list'
            );
            return;
        }

        const requests = emails.map((email) => {
            InternalTracker.trackEvent("Invite Sent", {
                by: "email",
                emails: email
            })

            return ContactApi.inviteContact(JSON.parse(localStorage.getItem("user") || "{}").id, email);
        });

        // clear email address after sending
        this.setState({ emailAddresses: '' });
        toast.success('Invitations sent successfully');

        // Wait for all requests to complete
        Promise.all(requests).then(() => {
            // Then notify parent of completion
            if (this.props.onInvited) {
                this.props.onInvited();
            }
            if (this.props.refreshOverlayMenu) {
                //console.log('refreshOverlayMenu:', this);
                this.props.refreshOverlayMenu();
            }
        });
    }

    /** Renders the output */
    public render() {
        return (
            <Subscribe to={[ProfileVerificationContainer]}>
                {(verification: ProfileVerificationContainer) => (
                    <>
                        <div id="invite-by-email-btn" onClick={() => {
                            this.setState({
                                showModal: true
                            })
                        }}>
                            <img src="/img/invite-email.png" />
                            <p>Invite contacts by email</p>
                        </div>

                        <Modal
                            show={this.state.showModal}
                            onHide={() => {
                                this.setState({
                                    showModal: false
                                })
                            }}
                            dialogClassName="modal-dialog-compact"
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>Invite Contacts</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {verification.state.verified ? (
                                    <div>
                                        <p>
                                            Once verified, invite multiple contacts from
                                            email lists, by simply copying and pasting.
                                        </p>
                                    </div>
                                ) : (
                                    <div
                                        style={{ color: theme.colours.red }}
                                    >
                                        <i className="fa fa-shield m-r-xs" />
                                        <span style={{ fontWeight: 600 }}>
                                            You can only invite one contact at a time
                                            until you verify your profile
                                        </span>
                                    </div>
                                )}
                                <textarea
                                    className="form-control"
                                    value={this.state.emailAddresses}
                                    name={nameof('emailAddresses')}
                                    onChange={this.handleFieldContentChange}
                                    placeholder="Enter email"
                                    onKeyPress={(ev) =>
                                        this.handleKeyPress(
                                            ev,
                                            verification.state.verified
                                        )
                                    }
                                />
                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    bsSize="small"
                                    bsStyle="success"
                                    onClick={() => {
                                        this.sendInvitations(
                                            verification.state.verified
                                        );
                                    }}
                                    disabled={!this.state.emailAddresses}
                                >
                                    Send Invitations
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </>
                )}
            </Subscribe>
        );
    }
}

export default connect(null, { ...EventsState.actionCreators })((props) => (
    <Subscribe to={[DashboardContainer, UserTypeContainer]}>
        {(dashboard: DashboardContainer, userType: UserTypeContainer) => (
            <ContactInvite
                // @ts-ignore
                {...props}
                dashboardContainer={dashboard}
                userType={userType}
            />
        )}
    </Subscribe>
)) as any;
