import styled from 'styled-components';

const SmallSelect = styled.select`
    width: 120px;
    height: 24px;
    margin-left: 5px;
    padding: 0 2px;
    color: #555;
    font-weight: normal;
    background-color: #fff;
    border: 1px solid #eaeaea;
    border-radius: 4px;
    box-shadow: none;
`;

export default SmallSelect;
