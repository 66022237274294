/* eslint-disable */
import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';
import styled from 'styled-components';
import { UserThirdParty } from '../../api/profile/ResponseTypes';

import ProfileApi from '../../api/profile/Profile';
import DismissableInfo from '../ui-components/DismissableInfo';
import ProfileAgencies from '../profile/ProfileAgencies';

interface Props {
    show: boolean;
    onCancel: () => void;
    onConfirm: (engageDirectly: boolean) => void;
}

interface State {
    loading: boolean;
    engageDirectly: boolean;
    engageAgency: boolean;
    agencies: UserThirdParty[];
    childModalShown: boolean;
}

export default class WorkerEngagementSelector extends React.Component<
    Props,
    State
> {
    state = {
        loading: true,
        engageDirectly: false,
        engageAgency: false,
        agencies: [],
        childModalShown: false
    } as State;

    componentDidMount() {
        ProfileApi.getThirdParties().then(({ data: agencies }) => {
            this.setState({
                agencies,
                loading: false,
                engageAgency: true,
                engageDirectly: false
            });
        });

        if (
            (localStorage.getItem('WorkerEngagementSelector.showDisclaimer') ||
                '') === ''
        ) {
            localStorage.setItem(
                'WorkerEngagementSelector.showDisclaimer',
                '0'
            );
        }
    }

    handleChildModalOpen = () => {
        this.setState({
            childModalShown: true
        });
    };

    handleChildModalHide = () => {
        this.setState({
            childModalShown: false
        });
    };

    get isValid(): boolean {
        return (
            this.state.engageDirectly ||
            (this.state.engageAgency && !!this.state.agencies.length)
        );
    }

    render() {
        return (
            <ModalFull
                show={this.props.show}
                onHide={this.props.onCancel}
                style={{
                    display: this.state.childModalShown ? 'none' : 'block'
                }}
            >
                <Modal.Body>
                    <div className="layout vertical">
                        <h5 style={{ textAlign: 'center', fontSize: '15px' }}>
                            Choose your agencies and/or direct engagement with
                            hirers
                        </h5>
                        <div>
                            <span className="layout horizontal center m-t-md">
                                <Toggle
                                    id="engage-directly"
                                    defaultChecked={this.state.engageDirectly}
                                    onChange={() =>
                                        this.setState({
                                            engageDirectly: !this.state
                                                .engageDirectly
                                        })
                                    }
                                />
                                <label
                                    htmlFor="engage-directly"
                                    className="m-l-sm"
                                    style={{
                                        fontWeight: 'bold',
                                        fontSize: '14px'
                                    }}
                                >
                                    Direct?
                                </label>
                            </span>
                            <OptionInfo>
                                Allow hirers to engage and pay me directly
                                {this.state.engageDirectly && (
                                    <>
                                        <br />
                                        <div
                                            style={{
                                                fontSize: '11px',
                                                fontStyle: 'italic',
                                                marginTop: '5px'
                                            }}
                                        >
                                            I warrant agreements are in place
                                            including, pay, right to work & all
                                            legal obligations with each hirer
                                        </div>
                                    </>
                                )}
                            </OptionInfo>
                        </div>
                        <div>
                            <span className="layout horizontal center m-t-md">
                                <Toggle
                                    id="engage-agency"
                                    defaultChecked={this.state.engageAgency}
                                    onChange={() =>
                                        this.setState({
                                            engageAgency: !this.state
                                                .engageAgency
                                        })
                                    }
                                />
                                <label
                                    htmlFor="engage-agency"
                                    className="m-l-sm"
                                    style={{
                                        fontWeight: 'bold',
                                        fontSize: '14px'
                                    }}
                                >
                                    Via agencies or third-parties?
                                </label>
                            </span>
                            <OptionInfo style={{ marginTop: '5px' }}>
                                Hirers can choose from my agencies/third-parties
                                (these choices are seen by hirers & saved here
                                for future offers)
                                {this.state.engageAgency && (
                                    <>
                                        <br />
                                        <div
                                            style={{
                                                fontSize: '11px',
                                                fontStyle: 'italic',
                                                marginTop: '5px'
                                            }}
                                        >
                                            I warrant I have an agreement with
                                            each organization listed
                                        </div>
                                    </>
                                )}
                            </OptionInfo>
                        </div>
                        <AgenciesPanel
                            className={
                                'flex ' +
                                (this.state.agencies.length
                                    ? 'has-agencies'
                                    : '') +
                                (this.state.engageAgency ? ' expanded' : '')
                            }
                        >
                            <ProfileAgencies
                                isMobile={true}
                                disableContactRequirement={true}
                                disableAgencyListSearch={true}
                                onConfirm={(agencies) => {
                                    this.setState({ agencies });
                                }}
                            />
                        </AgenciesPanel>
                        {!this.isValid && (
                            <WarningPanel className="layout horizontal center">
                                <i className="fa fa-exclamation-triangle" />
                                <span className="title">
                                    At least one engagement method required
                                </span>
                            </WarningPanel>
                        )}
                        <div style={{ paddingTop: '5px' }}>
                            <DismissableInfo
                                id="WorkerEngagementSelector.showDisclaimer"
                                maxHeight={120}
                                title="Your obligations"
                                body="For the avoidance of doubt updatedge facilitates availability and offers. We do not engage or employ end-users contacts and do not assess the right to work, references, suitability, or do any checks, nor do we invoice or pay for assignments. These obligations remain the end-user’s responsibility and or the agency through which end-users engage any contacts or temporary workers as per their normal terms of engagement for temporary workers."
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.props.onCancel}>Cancel</Button>
                    <Button
                        bsStyle="primary"
                        onClick={() => {
                            this.props.onConfirm(this.state.engageDirectly);
                        }}
                        disabled={!this.isValid}
                    >
                        Confirm
                    </Button>
                </Modal.Footer>
            </ModalFull>
        );
    }
}

const ModalFull = styled(Modal)`
    .modal-dialog {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        margin: 0;

        .modal-body {
            padding: 0;

            > div {
                height: calc(100vh - 56px);
                padding: 25px 15px 15px 15px;

                @media (max-width: 375px) {
                    padding: 10px;
                }
            }

            .btn-default {
                color: white;
                background: #34495e;
            }

            div.expanded:not(.has-agencies) .btn-default {
                margin-top: 30px;
            }
        }
    }
`;

const AgenciesPanel = styled.div`
    padding-top: 15px;
    overflow: hidden;
    opacity: 0;
    transition: opacity 0.4s ease;

    &.expanded {
        overflow: auto;
        opacity: 1;
    }

    thead {
        display: none;
    }
`;

const WarningPanel = styled.div`
    margin-top: 10px;

    > i {
        min-width: 20px;
        margin-right: 10px;
        font-size: 20px;
        cursor: help;

        &.fa-exclamation-triangle {
            color: orangered;
        }

        &.fa-info {
            color: grey;
        }
    }

    .title {
        font-weight: bold;
    }

    .body {
        font-size: 12px;
    }
`;

const OptionInfo = styled.div`
    margin-top: 5px;
    padding-left: 60px;

    @media (max-width: 374px) {
        padding-left: 0px;
    }
`;
