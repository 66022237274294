/* eslint-disable */
import { Rating } from "../offers/ResponseTypes";

export interface ContactResult {
    id: string;
    name: string;
    email: string;
    isExternal: boolean;
    profileImageUrl?: string | null;
    organisations?: ContactOrganisationResult[];
}

export interface ContactOrganisationResult {
    id: string;
    name: string;
    ratings: ContactCompanyRatingResult[] | null;
}

export interface ContactCompanyRatingResult {
    rating: number | undefined;
    ratingProvider: string | null;
    lastWorked: string | null;
}

export interface InviteResult {
    id: string;
    name: string;
    organisationId: string | null;
    organisationName: string | null;
    status: NotificationStatusType;
    created: string;
}

export interface InvitationRating {
    hirerName: string;
    id: number;
    rating: number;
    suggestionOrganisationId: string;
    userInvitationId: string;
    profileImageUrl: string | null;
}

export interface OutgoingInvitationResult {
    id: string;
    name: string;
    email: string;
    autoinvited: boolean;
    status: NotificationStatusType;
    contactIsExternal: boolean;
    sharingAvailability: boolean;
    contactId: string;
    receivingUserId: string;
    created: string;
    ratings: Array<InvitationRating>;
    platformRatings: Rating[]
}

export enum NotificationStatusType {
    NotSpecified = 0,
    Pending = 1,
    Receiving = 2,
    Failed = 3,
    Rejected = 4
}

export interface InvitationDetailResult {
    inviterUserId: string;
    inviterFullName: string;
    invitationEmail: string;
    taken: boolean;
}
