/* eslint-disable */
import React from 'react';
import styled from 'styled-components';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { InvitationDetailResult } from '../../api/invites/ResponseTypes';
import { toast } from 'react-toastify';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import Splash from '../../components/ui-components/Splash';
import InviteApi from '../../api/invites/Invites';
import ProfileApi from '../../api/profile/Profile';
import AuthApi from '../../components/auth/Auth';
import { ProfileImage } from '../../components/ui-components/ProfileImage';
import { ApplicationState } from '../../store';
import { actionCreators as profileActionCreators } from '../../store/Profile';

export const AcceptInvite = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    // @ts-ignore
    const { id } = useParams();
    const { search } = useLocation();
    const [isLoading, setIsLoading] = React.useState(true);
    const [hasAccepted, setHasAccepted] = React.useState(false);
    const [
        inviteDetail,
        setInviteDetail
    ] = React.useState<InvitationDetailResult | null>(null);

    const loggedInEmail = useSelector(
        ({ profile }: ApplicationState) =>
            profile.authenticatedUserProfile.emailAddress
    );

    React.useEffect(() => {
        dispatch(profileActionCreators.getUserProfile());
    }, [dispatch]);

    const inviteeMatchesLoggedInUser =
        inviteDetail != null && inviteDetail.invitationEmail === loggedInEmail;

    const isMobile = new URLSearchParams(search).get('mobile') === '1';

    React.useEffect(() => {
        InviteApi.getInvitationDetail(id!)
            .then((inviteDetail) => {
                setIsLoading(false);
                setInviteDetail(inviteDetail);
            })
            .catch(() => {
                setIsLoading(false);
            });
    }, [id]);

    React.useEffect(() => {
        if (!localStorage.getItem("user")) {
            localStorage.setItem("redirectToOnLogin", window.location.href);
            window.location.href = "/";
        }
    }, []);

    const acceptInvite = () => {
        InviteApi.acceptInvitation(id!)
            .then(handleInvitationAccept)
            .catch(() => {
                toast.error('Unable to accept invite');
            });
    };

    const handleInvitationAccept = () => {
        if (!isMobile) return history.push('/');
        setHasAccepted(true);
    };

    if (isLoading || loggedInEmail == null) return <Splash />;

    if (inviteDetail == null)
        return (
            <Container>
                <h4>Unable to find the invitation</h4>
            </Container>
        );

    if (inviteDetail.taken)
        return (
            <Container>
                <h4>This invitation can no longer be accepted</h4>
            </Container>
        );

    if (hasAccepted && isMobile)
        return (
            <Container>
                <h4>You have accepted the invitation</h4>
            </Container>
        );

    return (
        <Container>
            <InviterContainer>
                <ProfileImage
                    url={ProfileApi.getProfileImageUrl(
                        inviteDetail.inviterUserId
                    )}
                    size={48}
                    selectable={false}
                />
                <strong>{inviteDetail.inviterFullName}</strong>
            </InviterContainer>
            <p>
                {inviteDetail.inviterFullName} has invited you to join their
                network!
            </p>

            {!inviteeMatchesLoggedInUser && (
                <p>
                    You are currently logged in with the email{' '}
                    <strong>{loggedInEmail}</strong> but the invite was sent to{' '}
                    <strong>{inviteDetail.invitationEmail}</strong>.
                </p>
            )}

            {!inviteeMatchesLoggedInUser && (
                <p>
                    By accepting the invite both these emails will be associated
                    to the same account.
                </p>
            )}

            {!hasAccepted && (
                <Button bsStyle="success" onClick={acceptInvite} block>
                    Accept Invite
                </Button>
            )}

            {!isMobile && (
                <Button onClick={AuthApi.policyLogout} block>
                    Logout
                </Button>
            )}
        </Container>
    );
};

const Container = styled.div`
    text-align: center;
`;

const InviterContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    padding: 5px;
    background-color: #f7f9fa;

    img {
        margin-right: 10px;
    }
`;

export default AcceptInvite;
