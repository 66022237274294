import React from 'react';

function SvgMedalGold(props) {
    return (
        <svg viewBox="0 0 300.439 300.439" width="1em" height="1em" {...props}>
            <path
                d="M276.967 0h-84.498L70.415 178.385h84.498L276.967 0z"
                fill="#bf392c"
            />
            <path
                d="M23.472 0h84.498l122.053 178.385h-84.498L23.472 0z"
                fill="#e2574c"
            />
            <path
                d="M154.914 93.887c57.271 0 103.276 46.005 103.276 103.276s-46.005 103.276-103.276 103.276S51.638 254.434 51.638 197.163 97.643 93.887 154.914 93.887z"
                fill="#efc75e"
            />
            <path
                d="M154.914 122.053c-41.31 0-75.11 33.799-75.11 75.11s33.799 75.11 75.11 75.11 75.11-33.799 75.11-75.11-33.8-75.11-75.11-75.11zm0 131.442c-30.983 0-56.332-25.35-56.332-56.332s25.35-56.332 56.332-56.332 56.332 25.35 56.332 56.332-25.35 56.332-56.332 56.332z"
                fill="#d7b354"
            />
        </svg>
    );
}

export default SvgMedalGold;
