import React from 'react';

const SvgQuestion = (props) => (
    <svg height="1em" viewBox="0 0 512 512" width="1em" {...props}>
        <path
            d="M512 256c0 141.387-114.613 256-256 256S0 397.387 0 256 114.613 0 256 0s256 114.613 256 256zm0 0"
            fill="#3fb777"
        />
        <path
            d="M382.121 449.578v29.246a253.932 253.932 0 01-83.902 29.707 259.987 259.987 0 01-19.563 2.477c-4.105.363-8.242.625-12.414.781-3.394.149-6.812.211-10.238.211a259.01 259.01 0 01-22.676-.992 254.977 254.977 0 01-54.855-10.961c-.63-.2-1.266-.41-1.891-.617a254.27 254.27 0 01-46.7-20.606v-29.246c0-44.012 29.888-81.043 70.481-91.898a89.61 89.61 0 014.492-1.09 95.512 95.512 0 0120.168-2.14h61.95c6.93 0 13.68.73 20.18 2.14.374.074.75.156 1.117.242 1.097.25 2.195.523 3.27.816.03.008.05.008.085.02a94.722 94.722 0 0129.54 13.707c24.75 17.18 40.956 45.8 40.956 78.203zm0 0"
            fill="#2a2f4f"
        />
        <path
            d="M382.121 449.578v29.246a253.932 253.932 0 01-83.902 29.707 259.987 259.987 0 01-19.563 2.477c-4.105.363-8.242.625-12.414.781-3.394.149-6.812.211-10.238.211a259.01 259.01 0 01-22.676-.992 254.977 254.977 0 01-54.855-10.961c-.63-.2-1.266-.41-1.891-.617v-49.852c0-44.012 29.883-81.043 70.477-91.898a89.61 89.61 0 014.492-1.09 95.512 95.512 0 0120.168-2.14h15.254c6.93 0 13.68.73 20.18 2.14.374.074.75.156 1.117.242 1.097.25 2.195.523 3.27.816.03.008.05.008.085.02a94.722 94.722 0 0129.54 13.707c24.75 17.18 40.956 45.8 40.956 78.203zm0 0"
            fill="#3d4159"
        />
        <path
            d="M311.852 356.59l-.23 1.078-32.97 153.336c-7.46.66-15.023.992-22.652.992-7.648 0-15.203-.332-22.676-.992L200.36 357.676l-.23-1.086zm0 0"
            fill="#f2f0ec"
        />
        <path
            d="M255.988 393.395l-42.828 23.8-13.031-60.601 26.613-15.676M255.988 393.395l42.832 23.8 13.028-60.601-26.614-15.676"
            fill="#d8d5d0"
        />
        <path
            d="M128.11 331.512V188.039c0-14.578 2.433-28.863 7.23-42.453 17.957-51.102 66.445-85.445 120.656-85.445 6.715 0 13.414.523 19.926 1.55 29.781 4.653 57.105 19.856 76.918 42.809 20.023 23.191 31.05 52.86 31.05 83.54v143.472"
            fill="#f74f4f"
        />
        <path
            d="M180.719 193.64c0 50.75 29.715 109.462 46.039 137.876h-98.649V188.039c0-14.578 2.434-28.86 7.23-42.453 17.845-50.805 65.891-85.047 119.724-85.434-39.32.985-74.344 64.063-74.344 133.489zm0 0"
            fill="#dd3033"
        />
        <path
            d="M289.945 317.8v41.657c0 15.25-10.054 28.137-23.906 32.418a33.842 33.842 0 01-10.043 1.52c-18.746 0-33.937-15.196-33.937-33.938v-41.656zm0 0"
            fill="#eab599"
        />
        <path
            d="M289.945 356.816v2.641c0 15.25-10.054 28.137-23.906 32.418-12.496-3.84-21.89-14.707-23.625-28 2.266.14 4.547.207 6.836.207 6.422 0 12.715-.508 18.848-1.516a115.328 115.328 0 0021.847-5.75zm0 0M162.89 249.531c0 15.715-11.476 28.742-26.507 31.16a31.24 31.24 0 01-5.059.407c-17.437 0-31.562-14.13-31.562-31.567 0-17.433 14.125-31.562 31.562-31.562 1.727 0 3.414.136 5.059.406 15.031 2.418 26.508 15.445 26.508 31.156zm0 0"
            fill="#f6caae"
        />
        <path
            d="M162.89 249.531c0 15.715-11.476 28.742-26.507 31.16a117.333 117.333 0 01-4.192-31.16c0-10.781 1.461-21.23 4.192-31.156 15.031 2.418 26.508 15.445 26.508 31.156zm0 0"
            fill="#eab599"
        />
        <path
            d="M142.61 249.531c0 6.235-5.051 11.285-11.282 11.285s-11.281-5.05-11.281-11.285c0-6.23 5.05-11.281 11.281-11.281s11.281 5.05 11.281 11.281zm0 0"
            fill="#eab599"
        />
        <path
            d="M337.047 209.227V317.73c-16.77 16.008-38.274 27.094-62.2 30.95l-.577.093c-5.946.954-12.047 1.422-18.274 1.422-64.855 0-117.437-52.578-117.437-117.437v-44.719c0-5.727.41-11.36 1.203-16.867 27.941 20.094 68 18.11 93.527-6.016 8.453-7.984 14.328-17.492 17.649-27.578 1.851 17.66 9.925 34.848 24.242 48.379 17.043 16.094 39.558 23.855 61.867 23.27zm0 0"
            fill="#eab599"
        />
        <path
            d="M337.047 209.23v108.5c-16.774 16.008-38.277 27.094-62.203 30.95-.188.03-.387.062-.574.097-56.176-8.777-99.141-57.386-99.141-116.015v-44.723c0-1.191.02-2.371.062-3.555 20.711 2.121 42.215-4.312 58.094-19.328 8.457-7.984 14.328-17.492 17.649-27.574 1.851 17.656 9.93 34.848 24.242 48.379a83.982 83.982 0 0016.394 12.078c3 1.691 6.082 3.195 9.227 4.504h.012c10.972 4.555 22.757 6.789 34.523 6.707.574 0 1.14-.012 1.715-.02zm0 0"
            fill="#f6caae"
        />
        <path d="M228.445 218.477h86.262v41.46h-86.262zm0 0" fill="#eab599" />
        <path
            d="M335.938 263.594c0 8.078-6.551 14.629-14.63 14.629-8.081 0-14.628-6.551-14.628-14.63s6.547-14.628 14.629-14.628c8.078 0 14.628 6.55 14.628 14.629zm0 0M205.324 263.594c0 8.078-6.55 14.629-14.629 14.629-8.078 0-14.629-6.551-14.629-14.63s6.551-14.628 14.63-14.628c8.077 0 14.628 6.55 14.628 14.629zm0 0"
            fill="#eab599"
        />
        <path
            d="M176.555 234.988V235c.289 17.863 14.754 32.238 32.62 32.45.782.007 1.567.007 2.352-.005 18.008-.242 32.461-14.965 32.461-32.976v.523-10.449l-.347-12.969a4.246 4.246 0 00-2.864-3.89 92.728 92.728 0 00-61.375.128 4.242 4.242 0 00-2.843 3.997v23.699-.52zm0 0M336.46 224.535v9.93c0 18.012-14.452 32.734-32.464 32.984a78.65 78.65 0 01-2.351 0c-17.868-.207-32.329-14.586-32.614-32.453V211.81a4.245 4.245 0 012.844-4 92.852 92.852 0 0128.922-5.266h.012c10.972 4.555 22.757 6.789 34.523 6.707a4.21 4.21 0 01.785 2.32zm0 0"
            fill="#dde8ed"
        />
        <path
            d="M319.07 222.84c0-10.77-8.734-19.504-19.504-19.504-10.773 0-19.507 8.73-19.507 19.504M231.953 222.84c0-10.77-8.734-19.504-19.508-19.504-10.77 0-19.504 8.73-19.504 19.504"
            fill="#bfd5de"
        />
        <path
            d="M225.77 231.145c0 7.359-5.965 13.324-13.32 13.324-7.36 0-13.325-5.965-13.325-13.324 0-7.356 5.965-13.32 13.324-13.32 7.356 0 13.32 5.964 13.32 13.32zm0 0M312.887 231.145c0 7.359-5.965 13.324-13.32 13.324-7.36 0-13.325-5.965-13.325-13.324 0-7.356 5.965-13.32 13.324-13.32 7.356 0 13.32 5.964 13.32 13.32zm0 0"
            fill="#3d4159"
        />
        <path
            d="M256.008 301.047c-9.836 0-19.668-2.465-28.633-7.39a5.22 5.22 0 01-2.063-7.094 5.22 5.22 0 017.094-2.063c14.778 8.125 32.422 8.121 47.2 0a5.222 5.222 0 017.093 2.063 5.22 5.22 0 01-2.062 7.093c-8.961 4.926-18.797 7.39-28.63 7.39zm0 0"
            fill="#eab599"
        />
        <path
            d="M223.055 228.793c0 3.152-2.559 5.71-5.711 5.71a5.71 5.71 0 010-11.417 5.71 5.71 0 015.71 5.707zm0 0"
            fill="#fff"
        />
        <path
            d="M270.375 267.3H243.73a5.746 5.746 0 110-11.492h26.645a5.746 5.746 0 110 11.493zm0 0"
            fill="#eab599"
        />
        <path
            d="M310.824 228.793a5.71 5.71 0 01-5.707 5.71 5.713 5.713 0 01-5.71-5.71 5.71 5.71 0 0111.417 0zm0 0"
            fill="#fff"
        />
        <g fill="#f2f0ec">
            <path d="M182.496 235.512h-10.45v-23.703c0-4 2.544-7.586 6.329-8.922 20.89-7.375 43.918-7.422 64.836-.133 3.703 1.289 6.262 4.777 6.367 8.684l.348 13.105v10.45h-10.45v-10.38l-.328-12.207c-18.304-6.222-38.37-6.183-56.652.11zm0 0M337.047 209.23v25.754h-5.102V224.61l-.324-12.203c-18.305-6.226-38.367-6.187-56.652.106v22.996h-10.45v-23.7c0-4 2.551-7.585 6.333-8.921a98.018 98.018 0 0120.718-4.848c13.93 7.867 29.762 11.598 45.477 11.191zm0 0" />
            <path d="M244.355 211.574h25.391v10.45h-25.39zm0 0M178.91 213.71l-3.293 9.919-7.168-2.383L138.555 212v-10.992l31.472 9.746zm0 0" />
        </g>
        <path
            d="M228.574 383.645l-10.512 13.667-28.703 7.657-16.949 4.515-13.226-12.195c-.368-.344-.743-.68-1.098-1.035-37.281-34.793-54.899-62.149-71.137-110.496a47.4 47.4 0 01-.48-1.434l-5.633-17.082 11.14-13.562 18.848-22.957 16.77-3.918 2.605 6.613 7.324 18.633 10.606 26.976-13.805 12.602-9.32 8.508c4.273 13.699 25.047 45.965 35.746 55.535l11.598-4.965 17.18-7.375 20.175 20.844 13.93 14.379.012-.012zm0 0"
            fill="#66baed"
        />
        <path
            d="M189.531 343.336l-17.187 7.371 45.722 46.602 10.508-13.66zm0 0M148.133 279.031l-13.828 12.59-23.489-60.91 16.786-3.91zm0 0"
            fill="#f2f0ec"
        />
        <path
            d="M189.36 404.969l-16.95 4.515-13.226-12.195c-.368-.344-.743-.68-1.098-1.035-37.281-34.793-54.899-62.149-71.137-110.496a47.4 47.4 0 01-.48-1.434l-5.633-17.082 11.14-13.562s3.551 92.558 97.372 151.28c0 0 .011 0 .011.009zm0 0"
            fill="#2ba1cf"
        />
        <path
            d="M157.809 344.125l-15.887 15.887a21.575 21.575 0 01-15.332 6.347 21.558 21.558 0 01-10.246-2.566 21.815 21.815 0 01-5.086-3.781c-8.465-8.465-8.465-22.196 0-30.66l15.887-15.887c8.468-8.469 22.195-8.469 30.664 0a21.714 21.714 0 013.78 5.082c4.411 8.21 3.141 18.656-3.78 25.578zm0 0"
            fill="#eab599"
        />
        <path
            d="M157.809 344.125l-15.887 15.887a21.575 21.575 0 01-15.332 6.347 21.558 21.558 0 01-10.246-2.566c-4.41-8.207-3.14-18.652 3.781-25.578l15.887-15.883c6.922-6.926 17.37-8.195 25.578-3.785 4.41 8.21 3.14 18.656-3.781 25.578zm0 0"
            fill="#f6caae"
        />
        <path
            d="M137.262 482.855a256.576 256.576 0 01-34.45-21.734 252.328 252.328 0 01-16.417-13.371 247.446 247.446 0 0115.87-94.535c4.59 1.734 9.188 3.469 13.774 5.203 9.906 3.742 19.813 7.492 29.727 11.234a200.962 200.962 0 00-8.504 113.203zm0 0"
            fill="#2a2f4f"
        />
        <path
            d="M137.262 482.855a256.576 256.576 0 01-34.45-21.734 247.42 247.42 0 0113.227-102.703c9.906 3.742 19.813 7.492 29.727 11.234a200.962 200.962 0 00-8.504 113.203zm0 0"
            fill="#3d4159"
        />
        <path d="M294.813 17h190.539v143.227h-190.54zm0 0" fill="#f2f0ec" />
        <path
            d="M391.61 151.273l-20.462 48.594 89.516-51.152zm0 0"
            fill="#f2f0ec"
        />
        <path
            d="M387.2 115.652c-6.329 0-11.477 5.203-11.477 11.594 0 6.223 5.254 11.473 11.476 11.473 6.324 0 11.473-5.145 11.473-11.473 0-6.39-5.149-11.594-11.473-11.594zm0 0M388.16 38.504c-11.68 0-24.695 6.488-24.695 15.8 0 5.676 4.71 9.192 9.07 9.192 3.48 0 5.664-2.105 7.11-3.496 1.734-1.672 3.234-3.117 8.035-3.117 9.914 0 9.914 6.562 9.914 8.719 0 6.843-4.313 10.644-8.88 14.671-1.163 1.024-2.26 1.997-3.288 3.024-.364.36-.758.676-1.215 1.035-4.207 3.336-6.086 6.688-6.086 17.742 0 6.055 3.39 9.672 9.074 9.672 6.395 0 9.309-4.39 9.309-8.469 0-2.328 0-4.968.238-5.695.078-.238 1.074-1.227 1.875-2.02l.922-.914.191-.199c.668-.742 1.649-1.648 2.782-2.699 5.304-4.914 14.18-13.14 14.18-26.992 0-15.703-11.47-26.254-28.536-26.254zm0 0"
            fill="#3d4159"
        />
    </svg>
);

export default SvgQuestion;
