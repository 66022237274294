import React from 'react';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import moment from 'moment';

import NotificationApi from '../../api/notifications/Notifications';
import { OutstandingNotifications } from '../../api/notifications/ResponseTypes';
import Analytics from '../../services/analytics.service';
import {
    UserOfferDto,
    OfferResponseTypes,
    OfferConfirmationResponseTypes
} from '../../api/offers/ResponseTypes';
import { ProfileImage } from '../../components/ui-components/ProfileImage';
import CompanyApi from '../../api/company/Company';
import ProfileApi from '../../api/profile/Profile';
import { DateFormats, minDuration } from '../../constants';
import {
    InviteResult,
    NotificationStatusType
} from '../../api/invites/ResponseTypes';
import InviteApi from '../../api/invites/Invites';
import { PendingEventSet } from '../../api/events/ResponseTypes';
import Splash from '../../components/ui-components/Splash';
import PendingEventsModal from '../../pages/mobile/pendingeventsmodal';
import MobileOfferModal from '../../pages/mobile/mobileoffersmodal';

interface State {
    loading: boolean;
    outstandingNotifications: OutstandingNotifications | undefined;
    selectedEventSet: PendingEventSet | undefined;
}

export class ComingSoon extends React.Component<
    RouteComponentProps<{ id: string }>,
    State
> {
    state = {
        loading: true,
        outstandingNotifications: undefined,
        selectedEventSet: undefined
    } as State;

    offerModal: React.RefObject<MobileOfferModal>;

    constructor(props) {
        super(props);

        this.offerModal = React.createRef();
    }

    componentDidMount() {
        const notificationId = this.props.match.params.id;

        NotificationApi.getOutstanding().then((outstandingNotifications) => {
            this.setState(
                {
                    loading: false,
                    outstandingNotifications
                },
                () => {
                    if (notificationId) {
                        this.selectNotification(notificationId);
                    }
                }
            );

            Analytics.trackEvent('more-features-loaded');
        });
    }

    render() {
        if (this.state.loading) {
            return <Splash />;
        }
        return (
            <div>
                <div
                    className="layout vertical"
                    style={{ padding: '0 10px', height: 'calc(100vh - 20px)' }}
                >
                    {!this.state.loading &&
                        this.state.outstandingNotifications &&
                        this.renderBody()}
                </div>
            </div>
        );
    }

    private renderBody = () => {
        const data = this.state.outstandingNotifications!;

        return (
            <>
                <h3
                    style={{
                        textAlign: 'center',
                        fontWeight: 600,
                        marginBottom: '20px'
                    }}
                >
                    Notifications
                </h3>
                <div
                    className="flex"
                    style={{ overflowY: 'auto', paddingBottom: '20px' }}
                >
                    {this.renderOffers(data.offersPending, 'new')}
                    {this.renderOffers(data.offersWithdrawn, 'withdrawn')}
                    {this.renderOffers(data.offersConfirmed, 'confirmed')}
                    {this.renderEventSets(data.events)}
                    {this.renderShareRequests(data.shareRequests)}
                    {this.renderNone()}
                    <PendingEventsModal
                        eventSet={this.state.selectedEventSet}
                        onClose={this.handleEventSetClose}
                        onConfirm={this.handleEventSetConfirm}
                    />
                    <MobileOfferModal
                        ref={this.offerModal}
                        onChange={this.handleOfferChange}
                    />
                </div>
            </>
        );
    };

    private renderOffers = (offers: UserOfferDto[], status: string) => {
        return (
            offers.length > 0 &&
            offers.map((o) => {
                const companyImage = !!o.metadata.createdByOrganisationId
                    ? CompanyApi.getOrganisationProfileImageUrl(
                          o.metadata.createdByOrganisationId
                      )
                    : ProfileApi.getProfileImageUrl(o.metadata.createdById);

                const isPending =
                    o.response == OfferResponseTypes.Pending &&
                    !o.withdrawn &&
                    o.confirmation != OfferConfirmationResponseTypes.Rejected;
                const isWithdrawn =
                    (o.withdrawn ||
                        o.confirmation ==
                            OfferConfirmationResponseTypes.Rejected) &&
                    !o.withdrawnAcknowledged;
                const isConfirmed =
                    o.confirmation ==
                        OfferConfirmationResponseTypes.Confirmed &&
                    !o.addedToSchedule &&
                    !o.addedToScheduleDeclined;
                const dismissed = !isPending && !isWithdrawn && !isConfirmed;

                return (
                    <Card className={`${status}`} key={o.id}>
                        <div className="card-header">{status} offer</div>
                        <div
                            className="details layout horizontal center"
                            onClick={() => this.offerModal.current!.open(o)}
                        >
                            <ProfileImage
                                selectable={false}
                                url={companyImage}
                                size={54}
                                type="Organisation"
                            />
                            <div
                                className="layout vertical flex"
                                style={{ margin: '0 15px' }}
                            >
                                <span
                                    style={{
                                        fontSize: '14px',
                                        fontWeight: 600
                                    }}
                                >
                                    {o.title}
                                </span>
                                <span
                                    style={{
                                        fontSize: '11px',
                                        marginTop: '5px'
                                    }}
                                >
                                    {moment(o.events[0].start).format(
                                        DateFormats.ShortDateWithDay
                                    )}
                                </span>
                            </div>
                            <div>
                                <i className="fas fa-arrow-right" />
                            </div>
                        </div>
                        <div className="card-footer layout horizontal">
                            <span className="flex">
                                {o.metadata.createdByFullName}
                            </span>
                            {o.metadata.createdByOrganisationName && (
                                <>
                                    <span style={{ margin: '0 2px' }}>|</span>
                                    <span className="flex">
                                        {o.metadata.createdByOrganisationName}
                                    </span>
                                </>
                            )}
                        </div>
                        <div
                            className={`dismissed-overlay ${
                                dismissed ? 'dismissed' : ''
                            } layout horizontal center-center`}
                        >
                            <i className="fas fa-check" />
                        </div>
                    </Card>
                );
            })
        );
    };

    private renderShareRequests = (shareRequests: InviteResult[]) => {
        return (
            shareRequests.length > 0 &&
            shareRequests.map((r) => {
                const companyImage = CompanyApi.getOrganisationProfileImageUrl(
                    r.organisationId
                );

                const dismissed =
                    r.status == NotificationStatusType.Receiving ||
                    r.status == NotificationStatusType.Rejected;

                return (
                    <Card className="new" key={r.id}>
                        <div className="card-header">share request</div>
                        <div className="details layout horizontal center">
                            <ProfileImage
                                selectable={false}
                                url={companyImage}
                                size={54}
                                type="Organisation"
                            />
                            <div
                                className="layout vertical flex"
                                style={{ margin: '0 15px' }}
                            >
                                <span
                                    style={{
                                        fontSize: '14px',
                                        fontWeight: 600
                                    }}
                                >
                                    {r.name}
                                </span>
                                <span>
                                    would like to view your availability
                                </span>
                            </div>
                            <div style={{ width: '65px', textAlign: 'center' }}>
                                {this.renderShareRequestControls(r)}
                            </div>
                        </div>
                        <div className="card-footer layout horizontal">
                            <span className="flex">{r.name}</span>
                            {!!r.organisationName && (
                                <>
                                    <span style={{ margin: '0 2px' }}>|</span>
                                    <span className="flex">
                                        {r.organisationName}
                                    </span>
                                </>
                            )}
                        </div>
                        <div
                            className={`dismissed-overlay ${
                                dismissed ? 'dismissed' : ''
                            } layout horizontal center-center`}
                        >
                            <i className="fas fa-check" />
                        </div>
                    </Card>
                );
            })
        );
    };

    private renderShareRequestControls = (inv: InviteResult) => {
        switch (inv.status) {
            case NotificationStatusType.NotSpecified:
                return (
                    <span>
                        <i className="fa fa-spinner fa-spin" />
                    </span>
                );
            case NotificationStatusType.Receiving:
                return <span className="accepted status-text">Accepted</span>;
            case NotificationStatusType.Rejected:
                return <span className="rejected status-text">Rejected</span>;
            default:
                return (
                    <span>
                        <i
                            className="fa fa-times rejected m-l-xs"
                            onClick={() => this.handleShareRequestRejected(inv)}
                        />
                        <i
                            className="fa fa-check accepted m-l-xs"
                            onClick={() => this.handleShareRequestAccepted(inv)}
                        />
                    </span>
                );
        }
    };

    private renderEventSets = (events: PendingEventSet[]) => {
        return events.map((ev) => {
            const companyImage = CompanyApi.getOrganisationProfileImageUrl(
                ev.metadata.createdByOrganisationId
            );
            const dismissed = ev.accepted;

            return (
                <Card className="new" key={ev.id}>
                    <div className="card-header">assignment confirmation</div>
                    <div
                        className="details layout horizontal center"
                        onClick={() => this.setState({ selectedEventSet: ev })}
                    >
                        <ProfileImage
                            selectable={false}
                            url={companyImage}
                            size={54}
                        />
                        <div
                            className="layout vertical flex"
                            style={{ margin: '0 15px' }}
                        >
                            <span style={{ fontSize: '14px', fontWeight: 600 }}>
                                {ev.pendingEvents[0].title}
                            </span>
                            <span
                                style={{ fontSize: '11px', marginTop: '5px' }}
                            >
                                {moment(ev.pendingEvents[0].start).format(
                                    DateFormats.ShortDateWithDay
                                )}
                            </span>
                        </div>
                        <div>
                            <i className="fas fa-arrow-right" />
                        </div>
                    </div>
                    <div className="card-footer layout horizontal">
                        <span className="flex">
                            {ev.metadata.createdByFullName}
                        </span>
                        <span style={{ margin: '0 2px' }}>|</span>
                        <span className="flex">
                            {ev.metadata.createdByOrganisationName}
                        </span>
                    </div>
                    <div
                        className={`dismissed-overlay ${
                            dismissed ? 'dismissed' : ''
                        } layout horizontal center-center`}
                    >
                        <i className="fas fa-check" />
                    </div>
                </Card>
            );
        });
    };

    private renderNone = () => {
        const data = this.state.outstandingNotifications!;

        if (
            !data.events.length &&
            !data.offersConfirmed.length &&
            !data.offersPending.length &&
            !data.offersWithdrawn.length &&
            !data.shareRequests.length
        ) {
            return (
                <NoNotifications>
                    <i className="fas fa-clipboard-check" />
                    <div>No outstanding notifications</div>
                </NoNotifications>
            );
        }

        return null;
    };

    private handleShareRequestAccepted = async (inv: InviteResult) => {
        this.setShareRequestStatus(inv, NotificationStatusType.NotSpecified);

        // UX: Ensure async-indicator shows for a minimum of .5s, looks better than flash
        await minDuration(InviteApi.acceptInvitation(inv.id), 500);

        this.setShareRequestStatus(inv, NotificationStatusType.Receiving);

        Analytics.trackEvent('invitations-incoming-accepted');
    };

    private handleShareRequestRejected = async (inv: InviteResult) => {
        this.setShareRequestStatus(inv, NotificationStatusType.NotSpecified);

        // UX: Ensure async-indicator shows for a minimum of .5s, looks better than flash
        await minDuration(InviteApi.rejectInvitation(inv.id), 500);

        this.setShareRequestStatus(inv, NotificationStatusType.Rejected);

        Analytics.trackEvent('invitations-incoming-rejected');
    };

    private setShareRequestStatus = (
        inv: InviteResult,
        status: NotificationStatusType
    ) => {
        this.setState({
            outstandingNotifications: {
                ...this.state.outstandingNotifications!,
                shareRequests: this.state.outstandingNotifications!.shareRequests.map(
                    (r) => {
                        return r.id == inv.id ? { ...r, status } : r;
                    }
                )
            }
        });
    };

    private handleEventSetClose = () => {
        this.setState({
            selectedEventSet: undefined
        });
    };

    private handleEventSetConfirm = () => {
        const eventSet = this.state.selectedEventSet!;

        this.setState({
            loading: false,
            outstandingNotifications: {
                ...this.state.outstandingNotifications!,
                events: this.state.outstandingNotifications!.events.map((ev) =>
                    ev.id === eventSet.id ? { ...eventSet, accepted: true } : ev
                )
            },
            selectedEventSet: undefined
        });
    };

    private handleOfferChange = (offer: UserOfferDto) => {
        this.setState({
            outstandingNotifications: {
                ...this.state.outstandingNotifications!,
                offersPending: this.state.outstandingNotifications!.offersPending.map(
                    (o) => (o.id === offer.id ? offer : o)
                ),
                offersWithdrawn: this.state.outstandingNotifications!.offersWithdrawn.map(
                    (o) => (o.id === offer.id ? offer : o)
                ),
                offersConfirmed: this.state.outstandingNotifications!.offersConfirmed.map(
                    (o) => (o.id === offer.id ? offer : o)
                )
            }
        });
    };

    private selectNotification = (id: string) => {
        const data = this.state.outstandingNotifications!;

        const offer = data.offersConfirmed
            .concat(data.offersPending)
            .concat(data.offersWithdrawn)
            .find((o) => o.id === id);
        if (offer) {
            // id belongs to offer - open it
            this.offerModal.current!.open(offer);
        } else {
            const eventSet = data.events.find((e) => e.id === id);
            if (eventSet) {
                // id belongs to event set - open it
                this.setState({
                    selectedEventSet: eventSet
                });
            }
        }
    };
}

const NoNotifications = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    width: 100%;
    text-align: center;
    transform: translate(-50%, -50%);

    > i {
        margin-bottom: 25px;
        font-size: 70px;
        opacity: 0.85;
    }

    > div {
        font-size: 16px;
        text-align: center;
    }
`;

const Card = styled.div`
    position: relative;
    margin-top: 20px;
    color: black;
    background: #eaeaea;
    border-radius: 7px;

    &:first-child {
        margin-top: 0;
    }

    &.new {
        .card-header {
            background: #b3dcff;
        }
    }

    &.withdrawn {
        .card-header {
            background: #fecece;
        }
    }

    &.confirmed {
        .card-header {
            background: #c2fcd1;
        }
    }

    > .card-header {
        padding: 3px 0;
        font-weight: 600;
        font-size: 12px;
        letter-spacing: 0.7px;
        text-align: center;
        border-bottom: 1px solid #aaa;
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
    }

    > .details {
        padding: 10px;
        cursor: pointer;

        .fa-arrow-right {
            color: #444;
            font-size: 30px;
        }

        .fa-check {
            color: #62cb31;
            font-size: 30px;
        }

        .fa-times {
            color: #e74c3c;
            font-size: 30px;
        }

        .status-text.accepted {
            color: #62cb31;
            font-weight: 600;
        }

        .status-text.rejected {
            color: #e74c3c;
            font-weight: 600;
        }
    }

    > .card-footer {
        padding: 3px 0;
        border-top: 1px solid #aaa;

        > span:first-child {
            text-align: right;
        }
    }

    .dismissed-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.77);
        border-radius: 6px;
        visibility: hidden;
        opacity: 0;
        transition: opacity 1s ease;

        .fa-check {
            color: yellowgreen;
            font-size: 50px;
        }

        &.dismissed {
            visibility: visible;
            opacity: 1;
        }
    }
`;
