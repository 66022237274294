import {
    toast,
    ToastType,
    ToastOptions,
    UpdateOptions,
    TypeOptions
} from 'react-toastify';

export const actionCreators = {
    /** Displays a notification and returns the notification's id */
    display: (
        toastType: TypeOptions,
        toastText: string,
        options?: ToastOptions
    ): number => {
        let toastId: number;

        switch (toastType) {
            case ToastType.SUCCESS:
                toastId = toast.success(toastText, options);
                break;
            case ToastType.ERROR:
                toastId = toast.error(toastText, options);
                break;
            case ToastType.WARNING:
                toastId = toast.warn(toastText, options);
                break;
            case ToastType.INFO:
            default:
                toastId = toast.info(toastText, options);
                break;
        }

        // console.log(`New ToastId: ${toastId}`);
        return toastId;
    },
    update: (id: number, options?: UpdateOptions) => {
        toast.update(id, options);
    },
    dismiss: (id: number) => {
        toast.dismiss(id);
    }
};
