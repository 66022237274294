/* eslint-disable */
import React from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { Button } from 'react-bootstrap';

import ProfileApi from '../../api/profile/Profile';
import history from '../../history';
import Analytics from '../../services/analytics.service';
import AuthApi from '../../components/auth/Auth';
import InternalTracker from 'src/InternalTracker';

export default class CloseAccount extends React.Component<{}, {}> {
    state = {
        feedback: '',
        hasConfirmed: false,
        isClosing: false,
        hasClosed: false
    };

    handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        this.setState({ feedback: e.target.value });
    };

    handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ hasConfirmed: e.target.checked });
    };

    sendRequest = () => {
        const { hasConfirmed, feedback } = this.state;
        if (!hasConfirmed) {
            toast.error('Please confirm you wish to close your account');
            return;
        }

        InternalTracker.trackEvent("Closed Account");

        ProfileApi.closeAccount(feedback).then(this.accountDidClose);
    };

    accountDidClose = () => {
        Analytics.trackEvent('account-closed');
        AuthApi.policyLogout();
    };

    initHelp = () => {
        history.push('/support');
    };

    render() {
        return (
            <div className="row" data-section="close-account">
                <div className="hpanel">
                    <div className="panel-body">
                        <h2>Close Account</h2>
                        <small>
                            Closing your account will remove all information currently stored on this device
                            and flag your account for deletion on the server as per our Privacy Policy.
                        </small>
                        <Textarea
                            id="feedback"
                            name="feedback"
                            data-testid="delete-account-textarea"
                            placeholder="Please let us know why you are closing your account - feedback is very important to us!"
                            onChange={this.handleTextareaChange}
                            value={this.state.feedback}
                        />
                        <div className="m-t-sm">
                            <input
                                type="checkbox"
                                id="hasConfirmed"
                                name="hasConfirmed"
                                onChange={this.handleCheckboxChange}
                                checked={this.state.hasConfirmed}
                            />
                            <label
                                className="m-l-xs"
                                htmlFor="hasConfirmed"
                            >
                                I confirm I wish to close my account
                            </label>
                        </div>
                        <div className="m-t-sm">
                            <Button
                                onClick={this.initHelp}
                                bsStyle="primary"
                            >
                                Help Me First
                            </Button>
                            <Button
                                bsStyle="danger"
                                className="m-l-xs"
                                onClick={this.sendRequest}
                                style={{
                                    marginLeft: 10
                                }}
                            >
                                Close Account
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const Textarea = styled.textarea`
    width: 100%;
    max-height: 300px;
    resize: none;
    padding: 8px;
    margin-top: 12px;
`;
