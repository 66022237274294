/* eslint-disable */
import AppConfig from '../../components/config/Config';
import afetch from './../AuthenticatedFetch';
import { LocationsResponse, Location, ThirdPartyLocationsResponse } from './ResponseTypes';
import { NewLocation } from './RequestTypes';

const LocationsApi = {
    getAll() {
        const url = `${AppConfig.Settings.api.externalUri}/locations`;
        return afetch.request<null, LocationsResponse>(
            url,
            'GET',
            null,
            'Unable to get locations'
        );
    },
    getAllThirdParty() {
        const url = `${AppConfig.Settings.api.externalUri}/locations/thirdparty/received`;
        return afetch.request<null, ThirdPartyLocationsResponse>(
            url,
            'GET',
            null,
            'Unable to get hirer locations'
        );
    },
    create(payload: NewLocation) {
        const url = `${AppConfig.Settings.api.externalUri}/locations`;
        payload.locationFriendlyName = payload.locationFriendlyName.trim() === "" ? " " : payload.locationFriendlyName;
        payload.locationPlaceName = payload.locationPlaceName.trim() === "" ? " " : payload.locationPlaceName;
        return afetch.request<NewLocation, null>(
            url,
            'POST',
            payload,
            'Unable to create new location'
        );
    },
    edit(payload: { id: Location['id']; locationFriendlyName: string }) {
        payload.locationFriendlyName = payload.locationFriendlyName.trim() === "" ? " " : payload.locationFriendlyName;
        const url = `${AppConfig.Settings.api.externalUri}/locations`;
        return afetch.request<
            { id: string; locationFriendlyName: string },
            null
        >(url, 'PUT', payload, 'Unable to edit the location');
    },
    delete(id: Location['id']) {
        const url = `${AppConfig.Settings.api.externalUri}/locations/${id}`;
        return afetch.request<NewLocation, null>(
            url,
            'DELETE',
            null,
            'Unable to delete the location',
            undefined,
            true
        );
    },
    getStaticMapUrl(locationId: string) {
        return `${AppConfig.Settings.api.externalUri}/locations/static/${locationId}?api-version=1.0`;
    }
};

export default LocationsApi;
