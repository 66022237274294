/* eslint-disable */
import React, { CSSProperties } from 'react';
import styled from 'styled-components';

import DefaultOrganisationImage from '../../default-org-image.jpeg';
import { AsyncOverlay } from './AsyncOverlay';

interface ProfileImageProps {
    type?: 'Profile' | 'Organisation';
    url: string;
    size: number;
    selectable: boolean;
    onSelect?: (...args: any[]) => any;
    style?: CSSProperties;
    noReload?: boolean;
    ignoreImageSize?: boolean;
    onError?: (e: any) => void;
    reported?: boolean;
    'data-report-blurred='?: boolean;
    'data-user-id='?: string;
}

interface ProfileImageState {
    loaded: boolean;
    src: string;
}

// 1px white
export const EmptyProfileImage =
    'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs%3D';

const EmptyOrganisationImage = DefaultOrganisationImage;

export class ProfileImage extends React.Component<
    ProfileImageProps,
    ProfileImageState
> {
    static defaultProps = {
        type: 'Profile'
    };

    constructor(props: ProfileImageProps) {
        super(props);

        this.state = {
            loaded: false,
            src: this.getDefaultImage()
        };

        // Async load the real image
        this.load();
    }

    private onSelect = (...args) => {
        this.props.onSelect && this.props.onSelect(...args);
    };

    private getDefaultImage = () =>
        this.props.type === 'Profile'
            ? EmptyProfileImage
            : EmptyOrganisationImage;

    private getDisplayType = () =>
        this.props.type === 'Profile' ? 'circle' : 'square';

    private load() {
        //if (!this.props.url || this.props.url.length === 0) return;

        const image = new Image();
        image.src = this.props.url;

        image.onload = () => {
            // Swap to the image once downloaded
            this.setState({
                loaded: true,
                src: image.src
            });
        };

        setTimeout(() => {
            this.setState({
                loaded: true
                // src: this.getDefaultImage()
            });
        }, 1500);

        // image.onerror = (e) => {
        //     console.log('image error: ', e);
        //     this.setState({
        //         loaded: true,
        //         src: this.getDefaultImage()
        //     });
        // };
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.props.url !== prevProps.url &&
            this.props.url !== prevState.src &&
            !this.props.noReload
        ) {
            this.load();
        }
    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }

    render() {
        return (
            <Container
                className={`layout horizontal center-center thumbnail ${
                    this.props.selectable ? 'selectable' : ''
                }`}
                onClick={this.onSelect}
                style={{
                    display: 'inline-block',
                    ...this.props.style,
                    height: this.props.size + 'px',
                    width: this.props.size + 'px',
                    marginBottom: '0px',
                    // marginRight: '10px',
                    cursor: this.props.selectable
                        ? 'pointer'
                        : 'normal!important'
                }}
                data-report-blurred={this.props['data-report-blurred']}
                data-user-id={this.props['data-user-id']}
            >
                <img
                    onLoad={this.onPreviewImageLoad}
                    style={{
                        cursor: this.props.selectable
                            ? 'pointer'
                            : 'normal!important',
                        ...this.props.style
                    }}
                    onError={(e) => {
                        this.props.onError && this.props.onError(e);
                    }}
                    height={this.props.size}
                    width={this.props.size}
                    src={`${this.state.src}`}
                    {...(this.getDisplayType() === 'circle'
                        ? {
                              className: 'img-circle'
                          }
                        : {})}
                />
                <div style={{ position: 'relative' }}>
                    <AsyncOverlay show={!this.state.loaded} />
                </div>
            </Container>
        );
    }

    onPreviewImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
        // if (!this.props.ignoreImageSize) {
        //     console.log("RESIZE");
        //     const target = e.target as HTMLImageElement;
        //     const resizedDim = this.calculateAspectRatioFit(
        //         target.width,
        //         target.height,
        //         this.props.size,
        //         this.props.size
        //     );
        //     target.width = resizedDim.width;
        //     target.height = resizedDim.height;
        // }
    };

    calculateAspectRatioFit = (srcWidth, srcHeight, maxWidth, maxHeight) => {
        const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
        return { width: srcWidth * ratio, height: srcHeight * ratio };
    };

}

const Container = styled.div`
    position: relative;
    display: inline-block;
    vertical-align: middle;
    &.selectable {
        &:hover {
            border-color: #34495e;
        }
    }
`;
