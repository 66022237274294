/* eslint-disable */
/* eslint-disable @typescript-eslint/ban-ts-ignore */
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import moment, { Moment } from 'moment';
import { toast } from 'react-toastify';
import Select from 'react-select';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { formatDate, parseDate } from 'react-day-picker/moment';

import 'react-day-picker/lib/style.css';

import { connect } from 'react-redux';

import theme from '../../css/theme';
import PremiumBanner from '../organisation/PremiumBanner';
import Expander from '../ui-components/Expander';
import Hyperlink from '../ui-components/Hyperlink';
import ClockPicker, { ClockTime } from '../ui-components/ClockPicker';
import {
    DateFormats,
    isToday as isDateToday,
    formatLocationName,
    Feature
} from '../../constants';
import { Spinner } from '../ui-components/Spinner';
import SimpleTooltip from '../ui-components/SimpleTooltip';
import PrefixedInput from '../ui-components/PrefixedInput';
import { CreateOfferDto } from '../../api/offers/ResponseTypes';
import OffersAPI from '../../api/offers/Offers';
import ProfileApi from '../../api/profile/Profile'
import { UserThirdParty } from '../../api/profile/ResponseTypes';
import DateRangePicker from '../ui-components/DateRangePicker';
import Analytics from '../../services/analytics.service';
import WarningText from '../ui-components/WarningText';
import InternalTracker from '../../InternalTracker';
import { sortTimePresets } from '../availability/SearchTimePresetEditor';
import { OFFER_MODAL_BREAKPOINT } from './breakpoints';
import history from '../../history';
import { sortLocations } from '../organisation/Locations';
import { Star } from '../ui-components/StarRating';
import {
    CreateOfferUtilsProviderReturnValue,
    CustomPreset,
    LAST_PRESET
} from './CreateOfferUtilsProvider';
import WorkerSelector from './WorkerSelector';
import * as EventsState from '../../store/Events';
import { Subscribe } from '../../unstated-fork/unstated';
import DashboardContainer from '../../state-containers/dashboard.container';
import OrganisationContainer from '../../state-containers/organisation.container';
import { UserType, UserTypeContainer } from '../../state-containers/user-type.container';
import Dialog from '../ui-components/Dialog';
import { TimePresetDto } from '../../api/timepresets/RequestTypes';
import { ProfileImage } from '../../components/ui-components/ProfileImage';
import CompanyAPI from '../../api/company/Company';
import OrganisationAPI from '../../api/organisation/Organisation';
import { Agency, AgencyNotificationConfig } from '../../api/dashboard/ResponseTypes';
import Utilities from '../../Utilities';
import { Setting } from '../../api/settings/ResponseTypes';
import SettingsApi from '../../api/settings/Settings';

import HelpEvent from '../svg/help-event';
import HelpPost from '../svg/help-post';
import NoHelp from '../svg/nohelp';

// import OfferAgency from '../svg/offer-agency';
// import OfferWorker from '../svg/offer-worker';
import { Skill } from 'src/store/Availability';
import Toggle from 'react-toggle';
import { actionCreators as profileActionCreators } from '../../store/Profile';
import 'react-toggle/style.css';

import DateTimePicker from 'react-datetime-picker';
import Files from 'src/pages/Files';
import LocationsApi from 'src/api/locations/Locations';
import UserAgencySelector from '../profile/UserAgencySelector';

let tickInterval;

const DEADLINE_PRESETS = [
    "Off",
    "Custom Time",
    (window as any).Cypress ? "in 1 minute" : "in 10 minutes",
    "in 15 minutes",
    "in 30 minutes",
    "in 1 hour",
    "in 90 minutes",
    "in 2 hours",
    "in 4 hours",
    "in 6 hours",
    "in 8 hours",
    "in 12 hours",
    "in 1 day",
    "in 2 days",
    "in 3 days",
    "in 4 days",
    "in 5 days",
    "in 6 days",
    "in 1 week"
];

type Props = {
    onCancel: () => void;
    onReset: () => void;
    showScheduleTrial: () => void;
    onCreate: (offer: CreateOfferDto, successCb?: Function) => void;
    organisationName: string | null;
    createOfferUtilsData: CreateOfferUtilsProviderReturnValue['data'];
    createOfferUtilsFuncs: CreateOfferUtilsProviderReturnValue['functions'];
    previousTitles?: string[];
    previousDescriptions?: string[];
    windowWidth?: number;
    show?: boolean;
} & typeof EventsState.actionCreators;

interface DHMSCountdown {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
}

interface State {
    showDescriptionField: boolean;
    filterValue: string;
    listId: number;
    showRangePicker: boolean;
    offerJustSent: boolean;
    mutualAgenciesLoading: boolean;
    agenciesLoading: boolean;
    promptSameTime: {
        start?: ClockTime;
        end?: ClockTime;
        presetId?: string;
        presetValue?: string;
        takeTimeAsRef?: boolean;
    } | null;
    previousAgencyTimeouts: AgencyTimeout[],
    showTitleSuggestions: boolean;
    showDescriptionSuggestions: boolean;
    interacted: boolean;
    agencies: UserThirdParty[];
    locationsHidden: boolean;
    descriptionsHidden: boolean;
    offerTypeHidden: boolean;
    fileManagerHidden: boolean;
    agencyTypeHidden: boolean;
    workerSelectFilterHidden: boolean;
    showAllOptions: boolean;
    smsMessage: boolean;
    hideDayPickerIndex: number | null;
    manuallyOverridenDeadline: boolean;
    setDeadline: boolean;
    selectingFiles: boolean;
    openFeature: "notes" | "location" | "deadline" | "files" | "sms" | "agency" | null;
    countdown: DHMSCountdown | null;
    fixedDeadline: Date | null;
    fixedDeadlineDate: Date | null;
    minimizedOfferTypeToggle: boolean;
    alwaysShowNotes: boolean;
    alwaysShowLocation: boolean;
    alwaysShowAgencyHelp: boolean;
    hideFiles: boolean;
    locationImgFailedToLoad: boolean;
    hasOffers: boolean;
    showAgencySelector: boolean;
    showAgencyOptions: boolean;
}

interface AgencyOrgRelation {
    organisationId: string;
    organisationName: string;
    contactId: number;
}

interface AgencyTimeout {
    timeout: number;
    agencyContactId: number;
}

export interface Period {
    date: typeof DateFormats['ShortDateWithDay'];
    timePreset: string;
    customStart: ClockTime;
    customEnd: ClockTime;
}

const agencyNotificationPeriods = [
    { label: 'Off', minutes: 100000 },
    { label: '10 mins', minutes: 10 },
    { label: '15 mins', minutes: 15 },
    { label: '30 mins', minutes: 30 },
    { label: '1 hour', minutes: 60 },
    { label: '2 hours', minutes: 120 },
    { label: '4 hours', minutes: 240 },
    { label: '8 hours', minutes: 480 },
    { label: '16 hours', minutes: 960 },
    { label: '1 day', minutes: 1440 },
    { label: '2 days', minutes: 2880 },
    { label: '3 days', minutes: 4320 },
]

export class CreateOffer extends React.Component<Props, State> {
    initialState = {
        showDescriptionField: false,
        filterValue: '',
        listId: 0,
        showRangePicker: false,
        promptSameTime: null,
        offerJustSent: false,
        mutualAgenciesLoading: false,
        previousAgencyTimeouts: [] as AgencyTimeout[],
        showTitleSuggestions: false,
        showDescriptionSuggestions: false,
        interacted: true,
        agenciesLoading: false,
        agencies: [],
        locationsHidden: false,
        offerTypeHidden: false,
        fileManagerHidden: true,
        agencyTypeHidden: false,
        workerSelectFilterHidden: false,
        descriptionsHidden: false,
        showAllOptions: true, //  localStorage.getItem("showAllOfferOptions") === "true",
        smsMessage: localStorage.getItem("sendSMSOption") === "true",
        hideDayPickerIndex: null,
        manuallyOverridenDeadline: false,
        setDeadline: false,
        selectingFiles: false,
        openFeature: null,
        countdown: null,
        fixedDeadline: null,
        fixedDeadlineDate: null,
        minimizedOfferTypeToggle: localStorage.getItem("fullWidthOfferTypeShown") === "true",
        alwaysShowNotes: localStorage.getItem("alwaysShowNotes") === "true",
        alwaysShowLocation: localStorage.getItem("alwaysShowLocation") === "true",
        alwaysShowAgencyHelp: localStorage.getItem("alwaysShowAgencyHelp") === "true",
        hideFiles: false,
        locationImgFailedToLoad: false,
        hasOffers: false,
        showAgencySelector: false,
        showAgencyOptions: false
    };

    state = this.initialState;
    autoFillWorkerInterval: any;

    constructor(props: Props) {
        super(props);
    }

    componentWillUnmount() {
        window.removeEventListener('focus', this.handleFocus.bind(this));
        document.body.style.overflow = 'unset';
        if (document.getElementsByClassName("content") && document.getElementsByClassName("content")[0]) {
            document.getElementsByClassName("content")[0].scrollTop = 0;
        }
        if (document.getElementById("offers-wrapper")) {
            document.getElementById("offers-wrapper")!.style.marginLeft = "inherit";
        }
    }

    handleFocus() {
        this.props?.organisationContainer?.getOrganisation();
    }

    componentDidMount() {
        if (document.getElementById("offers-wrapper")) {
            document.getElementById("offers-wrapper")!.style.marginLeft = 330 + "px";
        }

        if (!localStorage.getItem("fullWidthOfferTypeShown")) {
            localStorage.setItem("fullWidthOfferTypeShown", "true");
        }

        window.addEventListener('focus', this.handleFocus.bind(this));
        this.props?.organisationContainer?.getOrganisation();

        document.body.style.overflow = 'hidden';

        if (this.getRequiredData().hasLoaded) {
            this.bootstrapOfferFormData();
        }

        this.reloadAgencies();

        Analytics.trackEvent('create-offer-opening');

        if (window.location.href.indexOf("/worker/") !== -1) {
            clearInterval(this.autoFillWorkerInterval);
            this.autoFillWorkerIds();
        }

        let localUser = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user") || "") : null;

        this.setState({
            locationsHidden: localStorage.getItem("locationsHidden") ? true : false,
            offerTypeHidden: localStorage.getItem("offerTypeHidden") ? true : false,
            fileManagerHidden: localStorage.getItem("fileManagerHidden") ? true : false,
            agencyTypeHidden: localStorage.getItem("agencyTypeHidden") ? true : false,
            workerSelectFilterHidden: localStorage.getItem("workerSelectFilterHidden") ? true : false,
            descriptionsHidden: localStorage.getItem("descriptionsHidden") ? true : false,
            hasOffers: localStorage.getItem("hasOffers") ? true : false,
            showAgencyOptions: localUser && localUser?.organisationIsHirer
        })
    }

    autoFillWorkerIds() {
        const dateParam = Utilities.getParameterByName("date") || null;
        let parts = window.location.href.split("?")[0].split("/");
        let workerIds = parts[parts.length-1].split(",");
        let i = 0;
        this.autoFillWorkerInterval = setInterval(() => {
            let contacts = this.getRequiredData().contacts;
            if (contacts.length !== 0) {
                // clearInterval(intVal);
                for (let i = 0; i < workerIds.length; i++) {
                    this.handleWorkerSelect(workerIds[i], true);
                }
            }
            i++;
            if (i > 20) {
                console.log("@@@ cleared interval")
                clearInterval(this.autoFillWorkerInterval);
            }
        }, 500);
    }

    componentDidUpdate (prevProps) {
        if (
            this.props.createOfferUtilsData.hasLoaded &&
            !prevProps.createOfferUtilsData.hasLoaded
        ) {
            this.bootstrapOfferFormData();
        }
    }

    bootstrapOfferFormData = () => {
        let { initialOffer } = this.props.createOfferUtilsData;

        let dashboardSettings = localStorage.getItem("dashboard") ? JSON.parse(localStorage.getItem("dashboard") || "") : null;
        if (dashboardSettings) {
            if (dashboardSettings.offersDefaultType === "agency") {
                initialOffer.agencyOnly = true;
                this.reloadAgencies();
            } else {
                initialOffer.agencyOnly = false;
            }
            if (dashboardSettings.offersDefaultAgencies && dashboardSettings.offersDefaultAgencies !== 'null' && dashboardSettings.offersDefaultAgencies.split(",").length !== 0) {
                initialOffer.agencyDirectNotifications = dashboardSettings.offersDefaultAgencies.split(",").map(item => parseInt(item)).filter(item => !isNaN(item))
            }
            if (parseInt(dashboardSettings.offersDefaultMutualAgenciesAlertType) && initialOffer.agencyNotifications) {
                initialOffer.agencyNotifications.alertType = parseInt(dashboardSettings.offersDefaultMutualAgenciesAlertType)
            }
        }

        if (localStorage.getItem("sendSMSOption") === "true") { 
            initialOffer.sendSMS = true;
        }

        let defaultOfferLocation = localStorage.getItem("lastOfferLocation");
        if (defaultOfferLocation) {
            initialOffer.placeId = defaultOfferLocation;
        }      

        initialOffer.selectedFiles = [];
        initialOffer.selectedFolders = [];
        
        this.updateOfferFormData(initialOffer, () => {
            this.fetchAvailableContacts();
            this.updateAppropriateDeadline();
            setTimeout(() => {
                this.startCountdownInterval();
            }, 1000);
        });

        this.setState({
            hideFiles: true
        }, () => {
            setTimeout(() => {
                this.setState({
                    hideFiles: false
                })
            })
        })
    
        Analytics.trackEvent('create-offer-opened');
    };

    resetCurrentOffer = () => {
        this.updateOfferFormData(this.props.createOfferUtilsData.initialOffer);
        if (document.getElementsByClassName("content") && document.getElementsByClassName("content")[0]) {
            document.getElementsByClassName("content")[0].scrollTop = 0;
        }
    };

    fetchAvailableContacts = () => {
        const hasAnyOverlaps = this.getOfferFormData().periods.find(p => this.isPeriodOverlapped(p));
        if (!hasAnyOverlaps) {
            this.props.createOfferUtilsFuncs.fetchAvailableContacts(
                this.state.listId,
                this.getPeriodIntervals(),
                this.getOfferFormData().skills
            );
        } else {
            toast.error('Please remove overlapping periods to see availability');
        }
    };

    startCountdownInterval = async() => {
        clearInterval(tickInterval);
        this.setState({
            fixedDeadline: this.getFormattedDeadline(this.getOfferFormData().deadline)
        }, () => {
            this.countdownIntervalFunction();
            tickInterval = setInterval(this.countdownIntervalFunction, 1000)
        })
    }

    countdownIntervalFunction = async() => {
        let deadline = this.state.fixedDeadline;
        let now = new Date();
        // @ts-ignore
        if (this.getOfferFormData().deadline !== "Off" && deadline && deadline > now) {
            this.setState({
                countdown: Utilities.secondsToDhms(Utilities.differenceBetweenDatesSeconds(deadline, now))
            })
        } else {
            clearInterval(tickInterval);
            this.setState({
                countdown: null
            })
        }
    }

    getOrganisationPrefix = () =>
        this.props.organisationName ? `${this.props.organisationName} - ` : '';

    isLocationRequired = () => this.getRequiredData().locations.length > 1;

    getFormattedDeadline = (selectedDeadline) => selectedDeadline && selectedDeadline.startsWith("in") ?
        Utilities.dateAdd(new Date(), selectedDeadline.split(" ")[2].replace("s", ""), selectedDeadline.split(" ")[1]) :
        new Date(selectedDeadline);

    getLocationOptions = () =>
        this.getRequiredData()
            .locations.sort(sortLocations)
            .map(
                ({
                    id: value,
                    isDefault,
                    locationPlaceName,
                    locationFriendlyName,
                    locationPlaceId
                }) => ({
                    value,
                    locationPlaceName,
                    locationFriendlyName,
                    locationPlaceId,
                    label: locationFriendlyName,
                    isDefault,
                    isDisabled:
                        !isDefault &&
                        !this.getRequiredData().isLocationsSubscriptionActive
                })
            );

    getLocationOptionFromPlaceId = (locationPlaceId) =>
        this.getLocationOptions().find(
            ({ locationPlaceId: placeId }) => placeId === locationPlaceId
        );

    render() {
        if (!this.getRequiredData().hasLoaded) {
            return (
                <Wrapper className="masked layout vertical center-center">
                    <Spinner />
                </Wrapper>
            );
        }

        const { defaultLocationName } = this.props.createOfferUtilsData;
        const titleLatest = this.getOfferFormData().title;
        const descriptionLatest = this.getOfferFormData().description;
        const showAgencyOptions = this.props?.organisationContainer?.state?.organisation ? this.props?.organisationContainer?.state?.organisation.isHirer : this.props?.userType?.state?.type !== UserType.Agency;
        const selectedDeadline = this.getOfferFormData().deadline;
        const selectedDeadlineDate = this.getFormattedDeadline(selectedDeadline);
        const totalFilesCountWithFolders = (this.getOfferFormData().selectedFiles ? this.getOfferFormData().selectedFiles.length : 0) + (this.getOfferFormData().selectedFolders ? this.getOfferFormData().selectedFolders.length : 0);
        const remainingSMSCredits = this.props?.organisationContainer?.state?.organisation?.smsCredit || 0
        let stepCount = 0;
        const selectedLocation = this.props.createOfferUtilsData.offer.placeId ?
            this.getLocationOptionFromPlaceId(
                this.props.createOfferUtilsData.offer
                    .placeId
            ) :
            null;
            
        let optionalAgencies: Agency[] = [];
        if (this.getOfferFormData().agencyNotifications?.alertType === 1) {
            optionalAgencies = this.getOfferFormData().agencyNotifications?.agencies.filter(a => a.timeout !== 100000)
        }
        let selectedWorkers = this.getOfferFormData().workerIds;

        const lastAgencyDirectNotifications = this.getOfferFormData().agencyDirectNotifications;
        const availableAgencies = this.state.agencies;
        if (availableAgencies.length !== 0 && lastAgencyDirectNotifications.length !== 0) {
            // @ts-ignore
            const avaialbleSelectedAgencies = availableAgencies.filter(item => lastAgencyDirectNotifications.find(agency => agency === item.userContactId));
            if (avaialbleSelectedAgencies.length !== lastAgencyDirectNotifications.length) {
                this.updateOfferFormData({
                    // @ts-ignore
                    agencyDirectNotifications: avaialbleSelectedAgencies.map(item => item.userContactId)
                })
            }
        }
        
        const sendOfferBtnTxt = 
            this.state.offerJustSent ? <span>Offer sent</span> : 
            this.getOfferFormData().agencyOnly ?
            <span>
                <span>Send to {this.getOfferFormData().agencyDirectNotifications.length} agenc{this.getOfferFormData().agencyDirectNotifications.length > 1 ? "ies" : "y"}</span>
                <span className='agency-images' style={{ width: optionalAgencies.length === 0 ? 0 : (28 + ((optionalAgencies.length-1)*14)) }}>
                    {this.getOfferFormData().agencyDirectNotifications.slice(0, 5).map((agencyId) => {
                        // @ts-ignore
                        let agency = this.state.agencies.find(item => item.userContactId === agencyId) as UserThirdParty;
                        // @ts-ignore
                        const email = agency?.agencyEmail || agency?.email;
                        const domain = email ? email.split("@")[1] : null;
                        return (
                            <ProfileImage
                                key={agency?.organisationId}
                                url={agency?.organisationId ? CompanyAPI.getOrganisationProfileImageUrl(agency?.organisationId) : domain ? OrganisationAPI.getExtOrgPicture(domain) : ""}
                                size={28}
                                style={{
                                    padding: 0,
                                    border: 'none',
                                    borderRadius: '100%'
                                }}
                                selectable={false}
                            />
                        )
                    })}
                    {optionalAgencies.length > 5 && <div>+{optionalAgencies.length - 5}</div>}
                </span>
            </span> :
            selectedWorkers.length === 0 ? <span>Send Offer</span> : 
                <span>
                    <span>
                        Send to {selectedWorkers.length} contact{selectedWorkers.length > 1 ? "s" : ""} 
                        <span className='profile-images' style={{ width: selectedWorkers.length === 0 ? 0 : (28 + ((selectedWorkers.length-1)*14)) }}>
                            {selectedWorkers.slice(0, 5).map((workerId) => (
                                <ProfileImage
                                    key={workerId}
                                    url={ProfileApi.getProfileImageUrl(workerId)}
                                    size={28}
                                    style={{
                                        padding: 0,
                                        border: 'none',
                                        borderRadius: '100%'
                                    }}
                                    selectable={false}
                                />
                            ))}
                            {selectedWorkers.length > 5 && <div>+{selectedWorkers.length - 5}</div>}
                        </span>
                    </span>
                    <span>
                        {optionalAgencies.length > 0 && <span> and {optionalAgencies.length} agenc{optionalAgencies.length > 1 ? "ies" : "y"}</span>}
                        <span className='agency-images' style={{ width: optionalAgencies.length === 0 ? 0 : (28 + ((optionalAgencies.length-1)*14)) }}>
                            {optionalAgencies.slice(0, 5).map((agency) => {
                                return (
                                    <ProfileImage
                                        key={agency.agencyId}
                                        url={agency.agencyId ? CompanyAPI.getOrganisationProfileImageUrl(agency.agencyId) : agency.agencyDomain ? OrganisationAPI.getExtOrgPicture(agency.agencyDomain) : ""}
                                        size={28}
                                        style={{
                                            padding: 0,
                                            border: 'none',
                                            borderRadius: '100%'
                                        }}
                                        selectable={false}
                                    />
                                )
                            })}
                            {optionalAgencies.length > 5 && <div>+{optionalAgencies.length - 5}</div>}
                        </span>
                    </span>
                </span>

        const NOTES_DOM = 
            <div className='open'>
                <textarea
                    id="offerDescription"
                    className="form-control"
                    placeholder="E.g. health and safety concerns, equipment required, etc."
                    value={this.getOfferFormData().description}
                    onChange={(ev) => {
                        this.updateOfferFormData({
                            description: ev.target.value
                        });
                    }}
                    rows={this.state.alwaysShowNotes ? 4 : 7}
                    onFocus={(() => {
                        this.setState({
                            showDescriptionSuggestions: true
                        })
                    })}
                    onBlur={(() => {
                        setTimeout(() => {
                            this.setState({
                                showDescriptionSuggestions: false
                            })
                            InternalTracker.trackEvent("", {
                                category: 'Offers',
                                action: 'Offer Description Changed',
                                customDimensions: [{ id: "REPLACE", value: this.getOfferFormData().description }]
                            });
                        }, 400)
                    })}
                />
                { !this.state.alwaysShowNotes &&
                    <button onClick={() => {
                        this.setState({
                            openFeature: null
                        })
                    }}>Save and Continue</button>
                }
            </div>

        const LOCATIONS_DOM = 
            <div className='open'>
                <Select
                    formatOptionLabel={(
                        {
                            value,
                            isDefault,
                            locationFriendlyName,
                            locationPlaceName
                        },
                        { context }
                    ) => (
                        <div
                            style={{
                                display: 'flex',
                                position: 'relative'
                            }}
                        >
                            <div>
                                {formatLocationName({
                                    locationFriendlyName,
                                    locationPlaceName
                                })}
                            </div>
                            {isDefault && context === 'menu' && (
                                <Star
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        right: 0,
                                        transform:
                                            'translateY(-50%)'
                                    }}
                                />
                            )}
                        </div>
                    )}
                    components={{
                        NoOptionsMessage: () => {
                            return (
                                <div
                                    style={{
                                        padding: '8px 12px',
                                        textAlign: 'center'
                                    }}
                                >
                                    <Hyperlink
                                        as="button"
                                        onClick={() => {
                                            history.push(
                                                '/organisation',
                                                {
                                                    initialTab:
                                                        'locations'
                                                }
                                            );
                                        }}
                                    >
                                        No location found, visit
                                        your organization page
                                        to add more
                                    </Hyperlink>
                                </div>
                            );
                        }
                    }}
                    isSearchable={true}
                    value={this.getLocationOptionFromPlaceId(
                        this.props.createOfferUtilsData.offer
                            .placeId
                    )}
                    key={`location-select-${this.getLocationOptionFromPlaceId(
                        this.props.createOfferUtilsData.offer
                            .placeId
                    )}`}
                    onChange={(option) => {
                        localStorage.setItem("lastOfferLocation", option![
                            'locationPlaceId'
                        ])
                        InternalTracker.trackEvent("", {
                            category: 'Offers',
                            action: 'Offer Location Changed',
                            customDimensions: [{ id: "REPLACE", value: option.locationPlaceId }]
                        });
                        this.props.createOfferUtilsFuncs.updateOfferFormData(
                            {
                                placeId: option![
                                    'locationPlaceId'
                                ]
                            }
                        );
                        this.setState({ interacted: true })
                    }}
                    inputId="offerLocation"
                    placeholder="Select a location..."
                    options={this.getLocationOptions()}
                    menuShouldScrollIntoView={false}
                />

                <div className='buttons'>
                    <Hyperlink
                        color={theme.colours.updatedge}
                        as={RouterLink}
                        to={{
                            pathname: '/organisation',
                            state: { initialTab: 'locations' }
                        }}
                        className="m-t-xs"
                        style={{
                            margin: "12px 0 0 0"
                        }}
                    >
                        Add/Remove Locations
                    </Hyperlink>
                    { !this.state.alwaysShowLocation &&
                        <button onClick={() => {
                            this.setState({
                                openFeature: null
                            })
                        }}>
                            Save and Continue
                        </button>
                    }
                </div>
            </div>

        const AGENCY_HELP_DOM = 
            <div className='open'>
                <div className='open-content'>
                <div className="agency-help-option">
                    {[
                        {
                            id: 0,
                            label: 'I will deal with it. No help needed from my agencies.'
                        },
                        {
                            id: 1,
                            label: 'Notify the contact’s agencies <span>a set time after</span> sending the offer unless someone applies'
                        }
                    ].map((alertType) => (
                        <div 
                            key={alertType.id}
                            data-selected={this.getOfferFormData().agencyNotifications && this.getOfferFormData().agencyNotifications.alertType === alertType.id}
                            onClick={() => {
                                InternalTracker.trackEvent("", {
                                    category: 'Offers',
                                    action: 'Offer Fallback Changed',
                                    customDimensions: [{ id: "REPLACE", value: alertType.id === 0 ? "Off" : "Agencies" }]
                                });

                                let newValue = alertType.id;
                                this.updateOfferFormData(
                                    (prevOffer) => {
                                        let prevOfferAgencyNotification = prevOffer.agencyNotifications
                                        if (prevOfferAgencyNotification?.alertType === 0 && newValue !== 0) {
                                            this.reloadMutualAgencies()
                                        }
                                        prevOfferAgencyNotification!.alertType = newValue
                                        SettingsApi.update(Setting.Offers_DefaultMutualAgenciesAlertType, newValue.toString());
                                        Utilities.updateDashboardLocalStorage("offersDefaultMutualAgenciesAlertType", newValue);
                                        return {
                                            ...prevOffer,
                                            agencyNotifications: prevOfferAgencyNotification
                                        }
                                });
                            }}
                        >
                            { (alertType.id === 0) ? 
                                <NoHelp /> :
                                (alertType.id === 1) ?
                                    <HelpPost /> :
                                    <HelpEvent />
                            }
                            <p dangerouslySetInnerHTML={{ __html: alertType.label }}></p>
                        </div>
                    ))}
                </div>
                { (
                    (this.getOfferFormData().agencyNotifications && this.getOfferFormData().agencyNotifications.alertType !== 0 && !this.getOfferFormData().agencyNotifications?.agencies.length) ||
                    (this.state.mutualAgenciesLoading)
                ) ?
                    <div className='agency-notification-list'>
                        { (this.state.mutualAgenciesLoading) ?
                            <div className='no-res'>
                                <i className='fa fa-spinner fa-spin' />
                                <span>Loading agency list...</span>
                            </div> 
                            :
                            <div className='no-res'>
                                <i className='fas fa-exclamation-triangle' />
                                <span>No mutual agencies with selected contacts</span>
                            </div>   
                        }
                    </div>
                : null }
                { (this.getOfferFormData().agencyNotifications && this.getOfferFormData().agencyNotifications.alertType !== 0 && this.getOfferFormData().agencyNotifications?.agencies.length) ?
                    <div className="agency-notification-list">
                        { this.getOfferFormData().agencyNotifications?.agencies.map((agency, i) => {
                            return (
                                <div className="agency" key={agency.agencyId}>
                                    <div>
                                        <ProfileImage 
                                            url={agency.agencyId ? CompanyAPI.getOrganisationProfileImageUrl(agency.agencyId) : agency.agencyDomain ? OrganisationAPI.getExtOrgPicture(agency.agencyDomain) : "" }
                                            size={32}
                                            selectable={false}
                                            style={{
                                                padding: 0,
                                                border: 'none',
                                                borderRadius: '100%'
                                            }}
                                        />
                                        <h4>{agency.agencyName}</h4>
                                    </div>
                                    <select onChange={(ev) => {
                                        let newMinutes = parseInt(ev.target.value);

                                        let previousAgencyTimeouts: AgencyTimeout[] = this.state.previousAgencyTimeouts.filter(item => item.agencyContactId !== agency.agencyId);
                                        previousAgencyTimeouts.push({
                                            agencyContactId: agency.agencyId,
                                            timeout: newMinutes
                                        })

                                        this.setState({
                                            previousAgencyTimeouts: previousAgencyTimeouts
                                        })

                                        this.updateOfferFormData(
                                            (prevOffer) => {
                                                let prevOfferAgencyNotification = prevOffer.agencyNotifications
                                                prevOfferAgencyNotification!.agencies[i].timeout = newMinutes
                                                return {
                                                    ...prevOffer,
                                                    agencyNotifications: prevOfferAgencyNotification
                                                }
                                            });
                                        }}
                                        value={agency.timeout || 200000 }
                                    >
                                        {/* <option value={200000}>{agencyNotificationPeriods.find(item => item.minutes === this.getOfferFormData().agencyNotifications.defaultTimeout)?.label} (Default)</option> */}
                                        { agencyNotificationPeriods.map(period => <option key={period.minutes} value={period.minutes}>{period.label}</option>)}
                                    </select>
                                </div>
                            )
                        }) }
                    </div>
                : null }
                </div>
                { (!this.state.alwaysShowAgencyHelp && this.state.hasOffers && this.state.showAgencyOptions) &&
                    <button 
                        onClick={() => {
                            this.setState({
                                showAgencySelector: true
                            })
                        }}
                        style={{
                            marginRight: 8
                        }}
                    >
                        Add Agencies
                    </button>
                }
                { (!this.state.alwaysShowAgencyHelp && this.state.hasOffers) &&
                    <button onClick={() => {
                        this.setState({
                            openFeature: null
                        })
                    }}>
                        Save and Continue
                    </button>
                }
            </div>

        return (
            <Wrapper
                className={`masked layout vertical selecting-workers`}
                data-show={this.props.show}
            >
                <div
                    className='mask'
                    onClick={this.props.onCancel}
                ></div>
                <div className='cont'>
                    <i
                        className="fas fa-times"
                        onClick={this.props.onCancel}
                    />
                <div className='head'>
                    <h2>
                        Create an instant offer
                    </h2>
                </div>
                <main>
                    <div>
                        <div
                            className="layout vertical flex"
                            style={{ overflow: 'auto' }}
                            onClick={() => {
                                this.setState({ interacted: true })
                            }}
                        >
                            <TitleBar>
                                <span className="title-num">
                                    {++stepCount}
                                </span>
                                <label htmlFor="offerTitle">
                                    Enter a title for this offer
                                </label>
                            </TitleBar>
                            <div
                                className="layout horizontal center"
                            >
                                <PrefixedInput
                                    prefix={""/*this.getOrganisationPrefix()*/}
                                    value={this.getOfferFormData().title}
                                    onChange={(title) => {
                                        // this.setState({ interacted: true })
                                        this.updateOfferFormData({ title });
                                    }}
                                    inputProps={{
                                        id: 'offerTitle',
                                        autoFocus: true,
                                        maxLength: 60,
                                        onFocus: (() => {
                                            this.setState({
                                                showTitleSuggestions: true
                                            })
                                        }).bind(this),
                                        onBlur: (() => {
                                            setTimeout(() => {
                                                this.setState({
                                                    showTitleSuggestions: false
                                                })
                                                InternalTracker.trackEvent("", {
                                                    category: 'Offers',
                                                    action: 'Offer Title Changed',
                                                    customDimensions: [{ id: "REPLACE", value: this.getOfferFormData().title }]
                                                });
                                            }, 400);
                                        }).bind(this)
                                    }}
                                />
                            </div>
                            { (this.state.showTitleSuggestions && this.props.previousTitles && titleLatest && this.props.previousTitles.find(item => item.indexOf(titleLatest) !== -1)) &&
                                <div 
                                    className="suggestions"
                                    style={{
                                        marginTop: 10
                                    }}
                                >
                                    { this.props.previousTitles
                                        .filter(item => item.indexOf(titleLatest) !== -1)
                                        .filter((item, i) => { return i < 12 })
                                        .map(item => {
                                            return (
                                                <span
                                                    onClick={() => {
                                                        InternalTracker.trackEvent("", {
                                                            category: 'Offers',
                                                            action: 'Previous Offer Name Autofilled',
                                                            customDimensions: [{ id: "REPLACE", value: item }]
                                                        });
                                                        this.updateOfferFormData({ title: item });
                                                    }}
                                                >{item}</span>
                                            )
                                        })
                                    }
                                </div>
                            }
                        </div>
                        <TitleBar>
                            <span className="title-num">
                                {++stepCount}
                            </span>
                            <h5>Select dates and times of the vacancy</h5>
                        </TitleBar>
                        {this.state.promptSameTime && (
                            <div>
                                <Dialog
                                    body={this.renderApplyToAllPromptMessage()}
                                    type="warning-2"
                                    onClick={() => {
                                        this.udpateAllTime();
                                    }}
                                />
                            </div>
                        )}
                        <div
                            className="layout vertical"
                            style={{
                                overflowY: 'auto'
                            }}
                            id="offer-periods"
                        >
                            {this.renderPeriods()}
                        </div>
                        <div className="m-t-xs layout horizontal center additional-dates">
                            <Link onClick={this.handleAddSinglePeriod}>
                                <i className="fas fa-plus-circle"></i>
                                Add a date
                            </Link>
                            <div>
                                <Link
                                    onClick={() => {
                                        setTimeout(() => {
                                            this.setState({
                                                showRangePicker: true
                                            })
                                        })
                                    }}
                                >
                                    <i className="fas fa-plus-circle"></i>
                                    Add a date range
                                </Link>
                                <DateRangePicker
                                    show={this.state.showRangePicker}
                                    onCancel={() =>
                                        this.setState({
                                            showRangePicker: false
                                        })
                                    }
                                    onConfirm={this.handleAddPeriodsBetween}
                                />
                            </div>
                        </div>

                        <TitleBar>
                            <span className="title-num">
                                {++stepCount}
                            </span>
                            <h5>{ (this.state.showAgencyOptions) ? "Offer Type" : "Select Contacts" }</h5>
                        </TitleBar>

                        <div className='offer-type-wrapper'>

                            { (this.state.showAgencyOptions) &&
                                <div className="agency-help-option offer-type" data-minimized={this.state.minimizedOfferTypeToggle}>
                                    <SimpleTooltip
                                        id="worker-offer"
                                        text="Offer my contacts & alert my contacts agencies if I get no applicants within the specified interval."
                                    >
                                        <div 
                                            data-selected={!this.getOfferFormData().agencyOnly}
                                            onClick={() => {
                                                InternalTracker.trackEvent("", {
                                                    category: 'Offers',
                                                    action: 'Offer Target Changed',
                                                    customDimensions: [{ id: "REPLACE", value: "Contacts" }]
                                                });
                                                this.updateOfferFormData(
                                                    (prevOffer) => {
                                                        return {
                                                            ...prevOffer,
                                                            agencyOnly: false
                                                        }
                                                });
                                                this.setState({ interacted: true })
                                                SettingsApi.update(Setting.Offers_DefaultType, "worker");
                                                Utilities.updateDashboardLocalStorage("offersDefaultType", "worker");
                                            }}
                                        >
                                            <img src={'/img/offer-workers.png'} />
                                            <p>{ this.state.minimizedOfferTypeToggle ? "My Contacts": "My contacts (optional agencies)" }</p>
                                        </div>
                                    </SimpleTooltip>
                                    <SimpleTooltip
                                        id="agency-offer"
                                        text="Send this request to my agency contacts immediately for them to suggest suitable staff"
                                    >
                                        <div 
                                            data-selected={this.getOfferFormData().agencyOnly}
                                            onClick={() => {
                                                InternalTracker.trackEvent("", {
                                                    category: 'Offers',
                                                    action: 'Offer Target Changed',
                                                    customDimensions: [{ id: "REPLACE", value: "Agencies" }]
                                                });
                                                this.updateOfferFormData(
                                                    (prevOffer) => {
                                                        return {
                                                            ...prevOffer,
                                                            agencyOnly: true
                                                        }
                                                });
                                                this.reloadAgencies();
                                                this.setState({ interacted: true })
                                                SettingsApi.update(Setting.Offers_DefaultType, "agency");
                                                Utilities.updateDashboardLocalStorage("offersDefaultType", "agency");
                                            }}
                                        >
                                            <img src={'/img/offer-agencies.png'} />
                                            <p>{ this.state.minimizedOfferTypeToggle ? "My Agencies" : "My agencies only" }</p>
                                        </div>
                                    </SimpleTooltip>
                                </div>
                            }

                            {!this.getOfferFormData().agencyOnly &&
                                <>
                                    { !this.state.offerJustSent &&
                                        <WorkerSelector
                                            isLoadingContacts={
                                                this.getRequiredData().isLoadingContacts
                                            }
                                            hideFilters={false}
                                            showToggleAll={this.state.showAllOptions}
                                            skills={this.getRequiredData().offer.skills}
                                            contacts={this.getRequiredData().contacts}
                                            lists={this.getRequiredData().lists}
                                            listId={this.state.listId}
                                            handleListChange={this.handleListChange}
                                            selectWorker={this.handleWorkerSelect}
                                            setSkills={this.handleSetSkills}
                                            selectedWorkerIds={this.getOfferFormData().workerIds}
                                            offerPeriods={this.getOfferFormData().periods}
                                            showWorkerList={this.state.interacted || this.props.windowWidth < 1020}
                                        />
                                    }
                                </>
                            }

                            {this.getOfferFormData().agencyOnly && this.state.showAllOptions &&
                                <>
                                    <div className="agency-notification-list only-agency">
                                        { (this.state.agenciesLoading) &&
                                            <p>
                                                <i className='fa fa-spinner fa-spin' />
                                                Loading agency list...
                                            </p>
                                        }
                                        { this.state.agencies.map((ag, agI) => {
                                            const agency = ag as UserThirdParty;
                                            const domain = agency.email ? agency.email.split("@")[1] : "";
                                            if (!agency.userContactId)
                                                return null;
                                            
                                            const selected = this.getOfferFormData().agencyDirectNotifications.indexOf(agency.userContactId) !== -1;
                                            // @ts-ignore
                                            const alreadyRendered = this.state.agencies.slice(0, agI).find(item => item.organisationName === agency.organisationName)

                                            if (alreadyRendered) {
                                                return null;
                                            }
                                            
                                            return (
                                                <div 
                                                    className="agency"
                                                    data-selected={selected}
                                                    onClick={() => {
                                                        let selectedAgencies = this.getOfferFormData().agencyDirectNotifications;
                                                        let selected = selectedAgencies.indexOf((agency as UserThirdParty).userContactId);
                                                        if (selected === -1) {
                                                            selectedAgencies.push(agency.userContactId);
                                                        } else {
                                                            selectedAgencies.splice(selected, 1);
                                                        }
                                                        SettingsApi.update(Setting.Offers_DefaultAgencies, selectedAgencies.join(","));
                                                        Utilities.updateDashboardLocalStorage("offersDefaultAgencies", selectedAgencies.join(","));
                                                        this.updateOfferFormData(
                                                            (prevOffer) => ({
                                                                ...prevOffer,
                                                                agencyDirectNotifications: selectedAgencies
                                                            })
                                                        );
                                                    }}
                                                >
                                                    <ProfileImage 
                                                        url={
                                                            agency.organisationId ?
                                                            CompanyAPI.getOrganisationProfileImageUrl(agency.organisationId || '') :
                                                                domain ?
                                                                OrganisationAPI.getExtOrgPicture(domain):
                                                                    ""
                                                        }
                                                        size={32}
                                                        selectable={false}
                                                        style={{
                                                            padding: 0,
                                                            border: 'none',
                                                            borderRadius: '100%'
                                                        }}
                                                    />
                                                    <h4>{agency.organisationName}</h4>
                                                    { selected &&
                                                        <span className='selected'>
                                                            <i className="fas fa-check"></i>
                                                            <span>Selected</span>
                                                        </span>
                                                    }
                                                </div>
                                            )
                                        }) }
                                        { (this.state.showAgencyOptions) &&
                                            <button 
                                                onClick={() => {
                                                    this.setState({
                                                        showAgencySelector: true
                                                    })
                                                }}
                                                style={{
                                                    background: theme.colours.blue2,
                                                    border: "none",
                                                    borderRadius: 52,
                                                    color: "white",
                                                    padding: "8px 10px",
                                                    margin: "10px 0",
                                                    display: "block",
                                                    width: "100%"
                                                }}
                                            >
                                                Add Agencies
                                            </button>
                                        }
                                    </div>
                                </>
                            }

                        </div>

                        { (this.state.alwaysShowNotes) &&
                            <React.Fragment>
                                <TitleBar>
                                    <span className="title-num">
                                        {++stepCount}
                                    </span>
                                    <h5>
                                        Add Notes
                                        <button onClick={() => {
                                            this.setState({
                                                openFeature: null,
                                                alwaysShowNotes: !this.state.alwaysShowNotes
                                            })
                                            if (this.state.alwaysShowNotes) {
                                                localStorage.removeItem("alwaysShowNotes");
                                            } else {
                                                localStorage.setItem("alwaysShowNotes", "true");
                                            }
                                        }} data-selected={this.state.alwaysShowNotes}>
                                            {this.state.alwaysShowNotes ? "Hide" : "Always Show"}
                                        </button>
                                    </h5>
                                </TitleBar>
                                <div className='feature notes always-visible'>
                                    {NOTES_DOM}
                                </div>
                            </React.Fragment>
                        }

                        { (this.state.alwaysShowLocation) &&
                            <React.Fragment>
                                <TitleBar>
                                    <span className="title-num">
                                        {++stepCount}
                                    </span>
                                    <h5>
                                        Location
                                        <button onClick={() => {
                                            this.setState({
                                                openFeature: null,
                                                alwaysShowLocation: !this.state.alwaysShowLocation
                                            })
                                            if (this.state.alwaysShowLocation) {
                                                localStorage.removeItem("alwaysShowLocation");
                                            } else {
                                                localStorage.setItem("alwaysShowLocation", "true");
                                            }
                                        }} data-selected={this.state.alwaysShowLocation}>
                                            {this.state.alwaysShowLocation ? "Hide" : "Always Show"}
                                        </button>
                                    </h5>
                                </TitleBar>
                                <div className='feature location always-visible'>
                                    {LOCATIONS_DOM}
                                </div>
                            </React.Fragment>
                        }

                        { ((this.state.alwaysShowAgencyHelp || !this.state.hasOffers) && !this.getOfferFormData().agencyOnly && this.state.showAgencyOptions) &&
                            <React.Fragment>
                                <TitleBar>
                                    <span className="title-num">
                                        {++stepCount}
                                    </span>
                                    <h5>
                                        Agency Help
                                        { ((this.state.alwaysShowAgencyHelp || !this.state.hasOffers) && this.state.showAgencyOptions) &&
                                            <button 
                                                data-selected={"true"}
                                                onClick={() => {
                                                    this.setState({
                                                        showAgencySelector: true
                                                    })
                                                }}
                                                style={{
                                                    right: !this.state.hasOffers ? 10 :  64
                                                }}
                                            >
                                                Add Agencies
                                            </button>
                                        }
                                        { (this.state.hasOffers) &&
                                            <button onClick={() => {
                                                this.setState({
                                                    openFeature: null,
                                                    alwaysShowAgencyHelp: !this.state.alwaysShowAgencyHelp
                                                })
                                                if (!this.state.hasOffers) {
                                                    this.setState({
                                                        hasOffers: true
                                                    })
                                                } else {
                                                    if (this.state.alwaysShowAgencyHelp) {
                                                        localStorage.removeItem("alwaysShowAgencyHelp");
                                                    } else {
                                                        localStorage.setItem("alwaysShowAgencyHelp", "true");
                                                    }
                                                }
                                            }} data-selected={this.state.alwaysShowAgencyHelp}>
                                                {this.state.alwaysShowAgencyHelp ? "Hide" : "Always Show"}
                                            </button>
                                        }
                                    </h5>
                                </TitleBar>
                                <div className='feature location always-visible'>
                                    {AGENCY_HELP_DOM}
                                </div>
                            </React.Fragment>
                        }

                        <div className='extra-features' data-open={this.state.openFeature !== "notes"}>
                            { (!this.state.alwaysShowNotes) &&
                                <div 
                                    className='feature notes' 
                                    data-open={this.state.openFeature === "notes"}
                                    onClick={() => {
                                        if (this.state.openFeature !== "notes") {
                                            this.setState({
                                                openFeature: "notes"
                                            })
                                        }
                                    }}
                                >
                                    <h3>
                                        {this.getOfferFormData().description ? "Tap to Edit Notes" : "Tap to Add Notes"}
                                        { (this.state.openFeature === "notes") &&
                                            <button onClick={() => {
                                                this.setState({
                                                    openFeature: null,
                                                    alwaysShowNotes: !this.state.alwaysShowNotes
                                                })
                                                if (this.state.alwaysShowNotes) {
                                                    localStorage.removeItem("alwaysShowNotes");
                                                } else {
                                                    localStorage.setItem("alwaysShowNotes", "true");
                                                }
                                            }} data-selected={this.state.alwaysShowNotes}>
                                                {this.state.alwaysShowNotes ? "Hide by Default" : "Always Show"}
                                            </button>
                                        }
                                    </h3>
                                    { this.state.openFeature !== "notes" && !this.getOfferFormData().description &&
                                        <div className='uncompleted'>
                                            <img className='stock' src='/img/offer-notes.png' />
                                        </div>
                                    }
                                    { this.state.openFeature !== "notes" && this.getOfferFormData().description &&
                                        <div className='completed'>
                                            <div>
                                                <button>Edit Notes</button>
                                                <p>{this.getOfferFormData().description}</p>
                                            </div>
                                        </div>
                                    }
                                    { this.state.openFeature === "notes" &&
                                        NOTES_DOM
                                    }
                                </div>    
                            }
                            { (!this.state.alwaysShowLocation) &&
                                <div 
                                    className='feature location' 
                                    data-open={this.state.openFeature === "location"}
                                    onClick={() => {
                                        if (this.state.openFeature !== "location") {
                                            this.setState({
                                                openFeature: "location",
                                                locationImgFailedToLoad: false
                                            })
                                        }
                                    }}
                                >
                                    <h3>
                                        Change Location
                                        { (this.state.openFeature === "location") &&
                                            <button onClick={() => {
                                                this.setState({
                                                    openFeature: null,
                                                    alwaysShowLocation: !this.state.alwaysShowLocation
                                                })
                                                if (this.state.alwaysShowLocation) {
                                                    localStorage.removeItem("alwaysShowLocation");
                                                } else {
                                                    localStorage.setItem("alwaysShowLocation", "true");
                                                }
                                            }} data-selected={this.state.alwaysShowLocation}>
                                                {this.state.alwaysShowLocation ? "Hide" : "Always Show"}
                                            </button>
                                        }
                                    </h3>
                                    { (
                                        this.props.createOfferUtilsData.offer.placeId && 
                                        this.getLocationOptions() && 
                                        this.getLocationOptions().find(l => l.locationPlaceId === this.props.createOfferUtilsData.offer.placeId)
                                    ) &&
                                        <div className='uncompleted'>
                                            <div className='marker'>
                                                <i className="fas fa-map-marker"></i>
                                                <span>{this.getLocationOptions().find(l => l.locationPlaceId === this.props.createOfferUtilsData.offer.placeId).locationPlaceName}</span>
                                            </div>
                                            <img 
                                                style={ this.state.locationImgFailedToLoad ? { visibility: 'hidden' } : { } }
                                                src={
                                                    LocationsApi.getStaticMapUrl(
                                                        this.getLocationOptions().find(l => l.locationPlaceId === this.props.createOfferUtilsData.offer.placeId).value
                                                    )
                                                } 
                                                onError={() => {
                                                    this.setState({
                                                        locationImgFailedToLoad: true
                                                    })
                                                }}
                                            />
                                        </div>
                                    }
                                    { (this.state.openFeature === "location") &&
                                        LOCATIONS_DOM
                                    }
                                </div>
                            }
                            { (!this.getOfferFormData().agencyOnly) && !this.state.alwaysShowAgencyHelp && this.state.showAgencyOptions &&
                                <div 
                                    className='feature agency' 
                                    data-open={this.state.openFeature === "agency"}
                                    onClick={() => {
                                        if (this.state.openFeature !== "agency") {
                                            this.setState({
                                                openFeature: "agency"
                                            })
                                        }
                                    }}
                                >
                                    <h3>
                                        {this.getOfferFormData().agencyNotifications && this.getOfferFormData().agencyNotifications.alertType === 1 ? "Agency Help Requested" : "Request Agency Help"}
                                        { (this.state.openFeature === "agency") &&
                                            <button onClick={() => {
                                                this.setState({
                                                    openFeature: null,
                                                    alwaysShowAgencyHelp: !this.state.alwaysShowAgencyHelp
                                                })
                                                if (this.state.alwaysShowAgencyHelp) {
                                                    localStorage.removeItem("alwaysShowAgencyHelp");
                                                } else {
                                                    localStorage.setItem("alwaysShowAgencyHelp", "true");
                                                }
                                            }} data-selected={this.state.alwaysShowAgencyHelp}>
                                                {this.state.alwaysShowAgencyHelp ? "Hide" : "Always Show"}
                                            </button>
                                        }
                                    </h3>
                                    { (!(this.getOfferFormData().agencyNotifications && this.getOfferFormData().agencyNotifications.alertType === 1) && this.state.openFeature !== "agency") ?
                                        <div className='uncompleted'>
                                            <img className='stock' src='/img/offer-agency.png' />
                                        </div>
                                    : null }
                                    { ((this.getOfferFormData().agencyNotifications && this.getOfferFormData().agencyNotifications.alertType === 1) && this.state.openFeature !== "agency") ?
                                        <div className='completed'>
                                            <div>
                                                <button>Edit Agencies</button>
                                                {  this.getOfferFormData().mutualAgencyContacts.length === 0 &&
                                                    <p><span>No mutual agencies with selected contacts</span></p>
                                                }
                                                { this.getOfferFormData().agencyNotifications?.agencies.map((agency, i) => {
                                                    const selected = this.getOfferFormData().agencyNotifications?.agencies[i].timeout !== 100000;
                                                    console.log(agency);
                                                    return (
                                                        <div className='agency-help-wrapper'>
                                                            <ProfileImage 
                                                                url={
                                                                    agency.agencyId ?
                                                                    CompanyAPI.getOrganisationProfileImageUrl(agency.agencyId || '') :
                                                                    agency.agencyDomain ?
                                                                        OrganisationAPI.getExtOrgPicture(agency.agencyDomain):
                                                                            ""
                                                                }
                                                                size={28}
                                                                selectable={false}
                                                                style={{
                                                                    padding: 0,
                                                                    borderRadius: '100%',
                                                                    border: 'none' /* selected ? `1px solid ${theme.colours.green2}` : `1px solid ${theme.colours.red2}`*/
                                                                }}
                                                            />
                                                            <i className={`fas fa-${selected ? "check" : "times"}`} style={{ color: selected ? theme.colours.green2 : theme.colours.red2 }}></i>
                                                        </div>
                                                    )
                                                }) }
                                            </div>
                                        </div>
                                    : null }
                                    { this.state.openFeature === "agency" &&
                                        AGENCY_HELP_DOM
                                    }
                                </div>
                            }
                            { (!this.getOfferFormData().agencyOnly) &&
                                <div 
                                    className='feature deadline' 
                                    data-open={this.state.openFeature === "deadline"}
                                    onClick={() => {
                                        if (this.state.openFeature !== "deadline") {
                                            this.setState({
                                                openFeature: "deadline"
                                            })
                                        }
                                    }}
                                >
                                    <h3>{selectedDeadline !== "Off" ? "Deadline Set" : "Set Deadline"}</h3>
                                    { this.state.openFeature !== "deadline" && selectedDeadline === "Off" &&
                                        <div className='uncompleted'>
                                            <img className='stock' src='/img/offer-agency.png' />
                                        </div>
                                    }
                                    { this.state.openFeature !== "deadline" && selectedDeadline !== "Off" &&
                                        <div className='completed'>
                                            <div>
                                                <button>Edit Deadline</button>
                                                <div className='countdown'>
                                                    { (this.state.countdown !== null) &&
                                                        <ul>
                                                            {/* @ts-ignore */}
                                                            <li><span id="days">{this.state.countdown.days}</span>Days</li><li><span id="hours">{this.state.countdown.hours}</span>Hours</li><li><span id="minutes">{this.state.countdown.minutes}</span>Mins</li><li><span id="seconds">{this.state.countdown.seconds}</span>Secs</li>
                                                        </ul>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    { this.state.openFeature === "deadline" &&
                                        <div className='open'>

                                            <div className='opts'>
                                                <select 
                                                    value={this.getOfferFormData().deadline}
                                                    onChange={(ev) => {
                                                        const value = ev.target.value;
                                                        const newDeadlineStr = value === "Custom Time" ? Utilities.formatDate(Utilities.dateAdd(new Date(), "hour", 1), "") : value;
                                                        this.setState({
                                                            fixedDeadlineDate: this.getFormattedDeadline(newDeadlineStr)
                                                        })
                                                        this.updateOfferFormData(
                                                            (prevOffer) => {
                                                                return {
                                                                    ...prevOffer,
                                                                    deadline: newDeadlineStr,
                                                                }
                                                            });
                                                            this.setState({
                                                                manuallyOverridenDeadline: true
                                                            }, () => {
                                                                this.startCountdownInterval();
                                                            })
                                                        }
                                                    }
                                                >
                                                    { DEADLINE_PRESETS.map((period, i) => {
                                                        return (
                                                            <option key={period} value={i === 1 && selectedDeadline && !selectedDeadline.startsWith("in") && !selectedDeadline.startsWith("Off") ? selectedDeadline : period}>{period}</option>
                                                        )
                                                    })}
                                                </select>
                                                { !(selectedDeadline && !selectedDeadline.startsWith("in") && selectedDeadline !== "Off") &&
                                                    <React.Fragment>
                                                        <label>or</label>
                                                        <button onClick={() => {
                                                            const newDeadlineStr = Utilities.formatDate(Utilities.dateAdd(new Date(), "hour", 1), "");
                                                            this.setState({
                                                                fixedDeadlineDate: this.getFormattedDeadline(newDeadlineStr)
                                                            })
                                                            this.updateOfferFormData(
                                                            (prevOffer) => {
                                                                return {
                                                                    ...prevOffer,
                                                                    deadline: newDeadlineStr,
                                                                }
                                                            });
                                                            this.setState({
                                                                manuallyOverridenDeadline: true
                                                            }, () => {
                                                                this.startCountdownInterval();
                                                            })
                                                        }}>Select Custom Time</button>
                                                    </React.Fragment>
                                                }
                                            </div>

                                            { selectedDeadline && !selectedDeadline.startsWith("in") && selectedDeadline !== "Off" &&
                                                <div className='date-time-picker-wrapper'>
                                                    <DateTimePicker 
                                                        disableClock={true}
                                                        clearIcon={null}
                                                        onChange={(value) => {
                                                            this.setState({
                                                                fixedDeadlineDate: new Date(value)
                                                            })
                                                            this.updateOfferFormData(
                                                                (prevOffer) => {
                                                                    return {
                                                                        ...prevOffer,
                                                                        deadline: new Date(value).toString()
                                                                    }
                                                                });
                                                        }}
                                                        format="dd/MMM/y h:mm:ss a"
                                                        value={new Date(this.getOfferFormData().deadline)}
                                                    />
                                                </div>
                                            }

                                            { selectedDeadline && selectedDeadline !== "Off" && 
                                                <p>
                                                    Offers to contacts, who don’t respond by <span>{ Utilities.formatDate(new Date(selectedDeadlineDate), "ds DD MMM YYYY") } ({ Utilities.formatDate(new Date(selectedDeadlineDate), "HH:MM") })</span> will be auto declined.
                                                    This avoids uncertainty and encourages contacts to reply to offers promptly for greater choice.
                                                </p>
                                            }

                                            <button onClick={() => {
                                                this.setState({
                                                    openFeature: null
                                                })
                                                this.startCountdownInterval();
                                            }}>Save and Continue</button>
                                        
                                        </div>
                                    }
                                </div>
                            }
                            { (!this.getOfferFormData().agencyOnly) &&
                                <div 
                                    className='feature sms' 
                                    data-open={this.state.openFeature === "sms"}
                                    onClick={() => {
                                        // if (this.state.openFeature !== "sms") {
                                        //     this.setState({
                                        //         openFeature: "sms"
                                        //     })
                                        // }
                                    }}
                                >
                                    <h3>{!this.state.smsMessage ? "Enable SMS" : "SMS Sending is On"}</h3>
                                    { this.state.openFeature !== "sms" &&
                                        <div className={this.state.smsMessage ? 'completed' : 'uncompleted'}>
                                            <Toggle
                                                id="sms-message-toggle"
                                                defaultChecked={this.state.smsMessage}
                                                onChange={() => {
                                                    InternalTracker.trackEvent("", {
                                                        category: 'Offers',
                                                        action: (this.state.smsMessage) ? "Disabled SMS Sending" : "Enabled SMS Sending"
                                                    })
                                                    if (this.state.smsMessage) {
                                                        localStorage.setItem("sendSMSOption", "false");
                                                    } else {
                                                        localStorage.setItem("sendSMSOption", "true");
                                                    }
                                                    this.updateOfferFormData(
                                                        (prevOffer) => {
                                                            return {
                                                                ...prevOffer,
                                                                sendSMS: !this.state.smsMessage
                                                            }
                                                    });
                                                    this.setState({
                                                        smsMessage: !this.state.smsMessage
                                                    })
                                                    
                                                }}
                                            />
                                            { (!this.state.smsMessage) &&
                                                <p>
                                                    {/* When enabled, contacts with SMS on (<i style={{ color: theme.colours.updatedge2 }} className='fas fa-comment' />) receive an SMS Text notification if not responded within 2 minutes, so contacts don’t miss offers, when they have no data / internet. */}
                                                    <span>Enable, to send contacts an SMS, if the offer is not responded within 2 mins</span>
                                                </p>
                                            }
                                            { (this.state.smsMessage) &&
                                                <p>
                                                    { (typeof this.getRequiredData().contacts !== "boolean") ?
                                                        ( this.getRequiredData().contacts.filter(c => c.contact.smsNotifications && this.getOfferFormData().workerIds.indexOf(c.contact.userId) !== -1).length === 0 ?
                                                            <span>
                                                                You haven't selected anyone with SMS enabled
                                                            </span> :
                                                            <span
                                                                onClick={() => {
                                                                    if ((this.getRequiredData().contacts.filter(c => c.contact.smsNotifications && this.getOfferFormData().workerIds.indexOf(c.contact.userId) !== -1).length > remainingSMSCredits))
                                                                        window.location.href = "/organisation/subscriptions/sms"
                                                                }}
                                                            >
                                                                This offer will use up to {this.getRequiredData().contacts.filter(c => c.contact.smsNotifications && this.getOfferFormData().workerIds.indexOf(c.contact.userId) !== -1).length } of your {remainingSMSCredits} SMS Credits
                                                                { (this.getRequiredData().contacts.filter(c => c.contact.smsNotifications && this.getOfferFormData().workerIds.indexOf(c.contact.userId) !== -1).length > remainingSMSCredits) &&
                                                                    <span
                                                                        style={{
                                                                            display: 'inline-block',
                                                                            background: theme.colours.red2,
                                                                            color: 'white',
                                                                            borderRadius: 52,
                                                                            marginTop: 6,
                                                                            padding: "2px 8px"
                                                                        }}
                                                                    >
                                                                        Not enough credits, click to buy more
                                                                    </span>
                                                                }
                                                            </span>
                                                        ):
                                                        <span>No SMS messages will be sent.</span>
                                                    }
                                                </p>
                                            }
                                        </div>
                                    }
                                </div>
                            }
                            { ((!this.getOfferFormData().agencyOnly) && (!this.state.hideFiles)) &&
                                <div 
                                    className='feature files' 
                                    data-open={this.state.openFeature === "files"}
                                    onClick={() => {
                                        if (!this.state.selectingFiles) {
                                            this.setState({
                                                // openFeature: "files"
                                                selectingFiles: true
                                            })
                                        }
                                    }}
                                >
                                    <h3>{ totalFilesCountWithFolders ? "Files Added" : "Select Files"}</h3>
                                    { this.state.openFeature !== "files" && !totalFilesCountWithFolders ?
                                        <div className='uncompleted'>
                                            <img className='stock' src='/img/offer-files.png' />
                                        </div>
                                    : null }
                                    { this.state.openFeature !== "files" && totalFilesCountWithFolders ?
                                        <div className='completed'>
                                            <div>
                                                <button>Edit Files</button>
                                                <p><span>{totalFilesCountWithFolders}</span> {totalFilesCountWithFolders === 1  ? "File" : "Files"} Selected</p>
                                            </div>
                                        </div>
                                    : null }
                                    <Files
                                        noSelectedFilesText="You have no files/folders attached to this offer"
                                        mode={this.state.selectingFiles ? "picker" : "hidden"}
                                        onSelectedFilesUpdated={(ids) => {
                                            this.updateOfferFormData(
                                                (prevOffer) => {
                                                    return {
                                                        ...prevOffer,
                                                        selectedFiles: ids
                                                    }
                                            });
                                        }}
                                        onSelectedFoldersUpdated={(ids) => {
                                            this.updateOfferFormData(
                                                (prevOffer) => {
                                                    return {
                                                        ...prevOffer,
                                                        selectedFolders: ids
                                                    }
                                            });
                                        }}
                                        onClosed={() => {
                                            this.setState({
                                                selectingFiles: false,
                                                // openFeature: null
                                            })
                                        }}
                                        switchMode={(mode) => {
                                            if (mode === "picker") {
                                                this.setState({
                                                    selectingFiles: true
                                                })
                                            }
                                        }}
                                    />
                                </div>
                            }
                        </div>

                        <div
                            className="layout horizontal end-justified m-t-xs m-b-xs button-row send-offer-btn-wrapper"
                            style={{ minHeight: '32px', margin: '15px 0' }}
                        >
                            <SimpleTooltip
                                id="create-offer-send-offer"
                                hide={
                                    this.getOfferFormData().workerIds.length === 0
                                }
                                text={
                                    <span>
                                        Send this offer to{' '}
                                        <strong>
                                            {
                                                this.getOfferFormData().workerIds
                                                    .length
                                            }
                                        </strong>{' '}
                                        selected contact
                                        {this.getOfferFormData().workerIds.length ==
                                        1
                                            ? ''
                                            : 's'}{' '}
                                        immediately
                                    </span>
                                }
                            >
                                <Button
                                    className="send-offer"
                                    bsStyle="primary"
                                    disabled={this.state.offerJustSent || this.state.mutualAgenciesLoading || this.getOfferFormData().periods.some((p) => this.isPeriodOverlapped(p))}
                                    onClick={() => {
                                        if (!(this.state.offerJustSent || this.state.mutualAgenciesLoading || this.getOfferFormData().periods.some((p) => this.isPeriodOverlapped(p)))) {
                                            this.handleCreateClick();
                                        }
                                    }}
                                    style={{
                                        background: this.state.offerJustSent ? theme.colours.green : theme.colours.blue2,
                                    }}
                                >
                                    {sendOfferBtnTxt}
                                </Button>
                            </SimpleTooltip>
                        </div>
                        <div
                            className="layout horizontal end-justified m-t-xs m-b-xs button-row send-offer-btn-wrapper-floating"
                            style={{ minHeight: '32px', margin: '15px 0' }}
                        >
                            <SimpleTooltip
                                id="create-offer-send-offer"
                                hide={
                                    this.getOfferFormData().workerIds.length === 0
                                }
                                text={
                                    <span>
                                        Send this offer to{' '}
                                        <strong>
                                            {
                                                this.getOfferFormData().workerIds
                                                    .length
                                            }
                                        </strong>{' '}
                                        selected contact
                                        {this.getOfferFormData().workerIds.length ==
                                        1
                                            ? ''
                                            : 's'}{' '}
                                        immediately
                                    </span>
                                }
                            >
                                <Button
                                    className="send-offer send-offer-right"
                                    bsStyle="primary"
                                    disabled={this.state.offerJustSent || this.state.mutualAgenciesLoading || this.getOfferFormData().periods.some((p) => this.isPeriodOverlapped(p))}
                                    onClick={() => {
                                        if (!(this.state.offerJustSent || this.state.mutualAgenciesLoading || this.getOfferFormData().periods.some((p) => this.isPeriodOverlapped(p)))) {
                                            this.handleCreateClick();
                                        }
                                    }}
                                    style={{
                                        background: this.state.offerJustSent ? theme.colours.green : theme.colours.blue2,
                                    }}
                                >
                                    <i className="fas fa-paper-plane"></i>
                                    {sendOfferBtnTxt}
                                </Button>
                            </SimpleTooltip>
                        </div>
                    </div>
                   
                </main>
                </div>
                <UserAgencySelector
                    show={this.state.showAgencySelector}
                    autoAddOnOpen={true}
                    onHide={() => this.setState({ showAgencySelector: false })}
                    onConfirm={() => {
                        if (this.getOfferFormData().agencyOnly) {
                            this.reloadAgencies();
                        } else {
                            this.reloadMutualAgencies();
                        }
                    }}
                />
            </Wrapper>
        );
    }

    renderApplyToAllPromptMessage = () => {
        if (!this.state.promptSameTime) return "";
        // @ts-ignore
        return 'Tap to apply ' + (this.state.promptSameTime.start.hour < 10 ? "0" + this.state.promptSameTime.start.hour : this.state.promptSameTime.start.hour) + ':' + (this.state.promptSameTime.start.minute < 10 ? "0" + this.state.promptSameTime.start.minute : this.state.promptSameTime.start.minute) + ' - ' + (this.state.promptSameTime.end.hour < 10 ? "0" + this.state.promptSameTime.end.hour : this.state.promptSameTime.end.hour) + ':' + (this.state.promptSameTime.end.minute < 10 ? "0" + this.state.promptSameTime.end.minute : this.state.promptSameTime.end.minute) + ' to all the days?'
    }

    udpateAllTime = () => {
        if (this.state.promptSameTime) {
            // @ts-ignore
            if (!this.state.promptSameTime.takeTimeAsRef) {
                // @ts-ignore
                this.handleSelectPreset(0, this.state.promptSameTime.presetValue, true);
            } else {
                // @ts-ignore
                this.handleCustomPeriodChangeAll(this.state.promptSameTime.start, this.state.promptSameTime.end, this.state.promptSameTime.presetId)
            }
            this.setState({ promptSameTime: null });
        }
    }

    promptToUpdateAll = (start, end, periodI, presetValue, takeTimeAsRef?) => {

        let periods = this.getOfferFormData().periods;

        if (periods.length !== 1) {
            for (let i = 0; i < periods.length; i++) {
                const period = periods[i];
                if (
                    i !== periodI &&
                    (period.customStart.hour !== start.hour ||
                        period.customEnd.hour !== end.hour ||
                        period.customStart.minute !== start.minute ||
                        period.customEnd.minute !== end.minute)
                ) {
                    this.setState({
                        promptSameTime: {
                            start: start,
                            end: end,
                            presetId: periodI,
                            presetValue: presetValue,
                            takeTimeAsRef: takeTimeAsRef || false
                        }
                    });
                    break;
                }
            }

        }
    }

    renderPeriods() {
        return this.getOfferFormData().periods.map((p, periodIndex) => {
            const isOverlapped = this.isPeriodOverlapped(p);

            const start = new Date()
            start.setHours(p.customStart.hour);
            start.setMinutes(p.customStart.minute);
            const end = new Date()
            end.setHours(p.customEnd.hour);
            end.setMinutes(p.customEnd.minute);

            const durationInSeconds = new Date(p.customStart.hour) < new Date(p.customEnd.hour)
                ? Utilities.differenceBetweenDatesSeconds(new Date(start), new Date(end))
                : Utilities.differenceBetweenDatesSeconds(new Date(start), new Date(Utilities.dateAdd(new Date(end), "day", 1)))

            const duration = Utilities.toHoursMinutes(durationInSeconds, true)

            if (this.state.hideDayPickerIndex === periodIndex) {
                return null;
            }
            
            return (
                <PeriodRow
                    key={`period-${periodIndex}`}
                    className={`layout horizontal center period ${
                        isOverlapped ? 'overlapped' : ''
                    }`}
                    onClick={() => {
                        const inputEl = document.querySelector("#offer-periods input:nth-of-type(" + (periodIndex + 1) + ")") as HTMLInputElement;
                        if (inputEl) {
                            console.log(inputEl)
                            inputEl.value = new Date(p.date).toISOString().substr(0, 10);
                        }
                    }}
                >
                    <DayPickerInput
                        value={p.date}
                        format={DateFormats.ShortDate}
                        formatDate={formatDate}
                        parseDate={parseDate}
                        onDayChange={(date) => {
                            const newFormattedDate = moment(date).format(DateFormats.ShortDateWithDay);
                            if (newFormattedDate !== p.date) {
                                this.handleDateChange(periodIndex, date);
                                InternalTracker.trackEvent("", {
                                    category: 'Offers',
                                    action: "Offer Period Date Changed",
                                    customDimensions: [{ id: "REPLACE", value: date.toString() }]
                                })
                            } else {
                                this.setState({
                                    hideDayPickerIndex: periodIndex
                                }, () => {
                                    this.setState({
                                        hideDayPickerIndex: null
                                    })
                                })
                            }
                            setTimeout(() => {
                                this.updateAppropriateDeadline();
                            })
                            return newFormattedDate
                        }}
                        inputProps={{
                            readOnly: 'readOnly'
                        }}
                        dayPickerProps={{
                            disabledDays: [
                                {
                                    before: moment()
                                        .startOf('day')
                                        .toDate()
                                }
                            ],
                            firstDayOfWeek: 1
                        }}
                    />
                    <select
                        className="form-control"
                        value={p.timePreset}
                        onChange={(ev) => {
                            let prevPresetId = ev.target.value;
                            
                            InternalTracker.trackEvent("", {
                                category: 'Offers',
                                action: 'Offer Time Preset Selected',
                                customDimensions: [{ id: "REPLACE", value: ev.target.value }]
                            });

                            this.handleSelectPreset(
                                periodIndex,
                                ev.target.value
                            );
                            
                            setTimeout(() => {
                                this.updateAppropriateDeadline();
                            })

                            let preset: TimePresetDto | null = null;
                            const presets = this.getRequiredData().presets;
                            for (let i = 0; i < presets.length; i++) {
                                if (presets[i].id === prevPresetId) {
                                    preset = presets[i];
                                }
                            }
                            if (preset) {
                                this.promptToUpdateAll(
                                    {
                                        hour: preset.startHour,
                                        minute: preset.startMinute
                                    } as ClockTime,
                                    {
                                        hour: preset.endHour,
                                        minute: preset.endMinute
                                    } as ClockTime,
                                    periodIndex,
                                    ev.target.value
                                );
                            }
                        }}
                    >
                        {sortTimePresets(this.getRequiredData().presets)
                            .map((subP) => (
                                <option key={subP.id} value={subP.id}>
                                    {subP.id === p.timePreset
                                        ? `${subP.name}`
                                        : `${subP.name} (${subP.startTime} - ${subP.endTime})`}
                                </option>
                            ))
                            .concat(
                                <option
                                    key={`opt-${CustomPreset.id}`}
                                    value={CustomPreset.id}
                                >
                                    {CustomPreset.name}
                                </option>
                            )}
                    </select>
                    <ClockPicker
                        time={p.customStart}
                        onChange={(val) => {
                            InternalTracker.trackEvent("", {
                                category: 'Offers',
                                action: "Offer Period Start Time Changed",
                                customDimensions: [{ id: "REPLACE", value: val.toString() }]
                            })
                            this.handleCustomPeriodChange(
                                periodIndex,
                                'start',
                                val
                            );
                            this.promptToUpdateAll(
                                val,
                                p.customEnd,
                                periodIndex,
                                p.timePreset,
                                true
                            );
                            setTimeout(() => {
                                this.updateAppropriateDeadline();
                            })
                        }}
                    />
                    <span className="show-sm" style={{ margin: '2px' }}>
                        -
                    </span>
                    <ClockPicker
                        time={p.customEnd}
                        onChange={(val) => {
                            InternalTracker.trackEvent("", {
                                category: 'Offers',
                                action: "Offer Period End Time Changed",
                                customDimensions: [{ id: "REPLACE", value: val.toString() }]
                            })
                            this.handleCustomPeriodChange(
                                periodIndex,
                                'end',
                                val
                            );
                            this.promptToUpdateAll(
                                p.customStart,
                                val,
                                periodIndex,
                                p.timePreset,
                                true
                            );
                            setTimeout(() => {
                                this.updateAppropriateDeadline();
                            })
                        }}
                    />
                    <span className='duration' style={{
                        marginLeft: 4,
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "center",
                        fontSize: 11,
                        color: (durationInSeconds < (8*60*60+1)) ? 'green' : (durationInSeconds < (12*60*60+1)) ? 'orange' : 'red'
                    }}>
                        <i className="far fa-clock"></i>
                        {duration}
                    </span>
                    {p.customEnd.toString() < p.customStart.toString() && (
                        <SimpleTooltip
                            id="vacancy-period-overnight"
                            text="This vacancy time is overnight and ends the following day"
                        >
                            <i className="fas fa-moon m-l-xs" />
                        </SimpleTooltip>
                    )}
                    <i
                        className={`fa fa-trash-o fa-hover m-l-sm ${
                            this.getOfferFormData().periods.length == 1
                                ? 'disabled'
                                : ''
                        }`}
                        onClick={() => {
                            InternalTracker.trackEvent("", {
                                category: 'Offers',
                                action: "Offer Period Date Removed",
                                customDimensions: [{ id: "REPLACE", value: p.date }]
                            })
                            this.handleRemovePeriodAtIndex(periodIndex)
                        }}
                    />
                    {isOverlapped && (
                        <SimpleTooltip
                            id="vacancy-period-error"
                            text="Times must not overlap"
                        >
                            <i className="fa fa-exclamation-triangle" />
                        </SimpleTooltip>
                    )}
                </PeriodRow>
            );
        });
    }

    handleDateChange = (ix: number, date: Date) => {
        const formattedDate = moment(date).format(DateFormats.ShortDateWithDay);
        this.updateOfferFormData(
            (prevOffer) => ({
                ...prevOffer,
                periods: prevOffer.periods.map((period, pix) => {
                    if (ix === pix) {
                        const isToday = isDateToday(date);
                        const preset = this.getRequiredData().presets.find(
                            ({ id }) => id === period.timePreset
                        );
                        let customStart = period.customStart;
                        let timePreset = period.timePreset;

                        if (
                            isToday &&
                            customStart.toString() < moment().format('HH:mm')
                        ) {
                            timePreset = CustomPreset.id;
                            customStart = new ClockTime(
                                moment().hour(),
                                moment().minute()
                            );
                        }

                        if (!isToday && preset != null) {
                            customStart = new ClockTime(
                                preset.startHour,
                                preset.startMinute
                            );
                        }

                        return {
                            ...period,
                            timePreset,
                            date: formattedDate,
                            customStart
                        };
                    }

                    return period;
                })
            }),
            this.fetchAvailableContacts
        );

        InternalTracker.trackEvent("", {
            category: 'Offers',
            action: 'Offer Date Modified'
        });
        Analytics.trackEvent('create-offer-date-changed');
    };

    handleAddSinglePeriod = () => {
        const lastPeriod = this.getLastPeriod();
        const { timePreset, customStart, customEnd } = this.getNewTimePeriod();

        const matchingPreset = this.getRequiredData().presets.find(p => p.id === lastPeriod.timePreset);
        const allDayPreset = this.props.createOfferUtilsFuncs.getAllDayPreset()

        const nextDate = moment(
            lastPeriod.date,
            DateFormats.ShortDateWithDay
        ).add(1, 'days');

        InternalTracker.trackEvent("", {
            category: 'Offers',
            action: 'Offer Period Added',
            customDimensions: [{ id: "REPLACE", value: nextDate.toString()  }]
        })

        if (
            this.props.dashboardContainer?.state.data?.offersContinueOnWeekdays
        ) {
            if (nextDate.isoWeekday() === 6) {
                // Saturday -> Monday
                nextDate.add(2, 'day');
            } else if (nextDate.isoWeekday() === 7) {
                // Sunday -> Monday
                nextDate.add(1, 'day');
            }
        }

        // Add a new period for the next day with same time preset
        this.updateOfferFormData(
            (offer) => ({
                ...offer,
                periods: offer.periods.concat({
                    date: nextDate.format(DateFormats.ShortDateWithDay),
                    timePreset: matchingPreset && offer.periods.length !==1 ? timePreset : allDayPreset.id,
                    customStart: matchingPreset && offer.periods.length !==1 ? customStart : new ClockTime(allDayPreset.startHour, allDayPreset.startMinute),
                    customEnd: matchingPreset && offer.periods.length !==1 ? customEnd : new ClockTime(allDayPreset.endHour, allDayPreset.endMinute),
                })
            }),
            this.fetchAvailableContacts
        );

        setTimeout(() => {
            this.updateAppropriateDeadline();
        }, 1000)

        this.setState({ showRangePicker: false, interacted: true });
        Analytics.trackEvent('create-offer-add-date-clicked');
    };

    updateAppropriateDeadline = () => {
        const periods = JSON.parse(JSON.stringify(this.getRequiredData().offer.periods));
        if (periods.length !== 0 && !this.state.setDeadline && this.getRequiredData().offer.deadline !== "Custom") {
            const firstEvent = (periods.map(item => { item.dateInt = (new Date(item.date)).getTime(); return item }).sort(Utilities.dynamicSort("dateInt")))[0];
            const firstEventDate = new Date(firstEvent.date);
            firstEventDate.setHours(firstEvent.customStart.hour);
            firstEventDate.setMinutes(firstEvent.customEnd.minute);
            let suggestedTime;

            let i = 0;
            while (i < DEADLINE_PRESETS.length - 1) {
                if (DEADLINE_PRESETS[i].indexOf("in") !== -1) {
                    const deadlineWithThisPreset = Utilities.dateAdd(new Date(), DEADLINE_PRESETS[i].split(" ")[2].replace("s", ""), parseInt(DEADLINE_PRESETS[i].split(" ")[1]))
                    if (deadlineWithThisPreset < firstEventDate) {
                        suggestedTime = DEADLINE_PRESETS[i];
                    }
                }
                i++;
            }

            if (suggestedTime) {
                this.updateOfferFormData(
                    (prevOffer) => {
                    return {
                        ...prevOffer,
                        deadline: suggestedTime,
                    }
                });
                this.setState({
                    fixedDeadlineDate: this.getFormattedDeadline(suggestedTime)
                })
            }
        }
    }

    noPeriodsAddedAlert = () => {
        alert("No periods have been added as you only selected weekends which are currently disabled in your settings");
    }

    handleAddPeriodsBetween = (from: Moment, to: Moment) => {
        const { timePreset, customStart, customEnd } = this.getNewTimePeriod();

        const periods: Period[] = [];

        for (const m = from; m.diff(to, 'days') <= 0; m.add(1, 'days')) {
            if (localStorage.getItem("offersContinueOnWeekdays") && (m.isoWeekday() === 6 || m.isoWeekday() === 7)) {
                continue;
            }
            periods.push({
                date: m.format(DateFormats.ShortDateWithDay),
                timePreset,
                customStart,
                customEnd
            });
        }

        if (periods.length === 0) {
            this.noPeriodsAddedAlert();
        } else {
            InternalTracker.trackEvent("", {
                category: 'Offers',
                action: 'Offer Multiple Period Added',
                customDimensions: [{ id: "REPLACE", value: from + " " + to }]
            })

            this.updateOfferFormData(
                (offer) => ({
                    ...offer,
                    periods: offer.periods.concat(periods)
                }),
                this.fetchAvailableContacts
            );

            setTimeout(() => {
                this.updateAppropriateDeadline();
            })
        }

        this.setState({ showRangePicker: false, interacted: true });
        Analytics.trackEvent('create-offer-add-range-confirmed');
    };

    handleRemovePeriodAtIndex = (index) => {
        if (this.getOfferFormData().periods.length <= 1) return;
        this.updateOfferFormData(
            (prevOffer) => ({
                ...prevOffer,
                periods: prevOffer.periods.filter(
                    (_, periodIndex) => index !== periodIndex
                )
            }),
            this.fetchAvailableContacts
        );

        setTimeout(() => {
            this.updateAppropriateDeadline();
        })

        Analytics.trackEvent('create-offer-remove-period-clicked');
    };

    handleSelectPreset = (ix, presetId, updateAll?) => {
        const preset = this.getRequiredData().presets.find(
            (p) => p.id === presetId
        );

        this.updateOfferFormData(
            (offer) => ({
                ...offer,
                periods: offer.periods.map((p, pix) => {
                    if (ix === pix || updateAll) {
                        let customStart: ClockTime | undefined = undefined;

                        if (preset) {
                            customStart = new ClockTime(
                                preset.startHour,
                                preset.startMinute
                            );

                            if (
                                moment(
                                    p.date,
                                    DateFormats.ShortDateWithDay
                                ).isSame(new Date(), 'day') &&
                                customStart.toString() <
                                    moment().format('HH:mm')
                            ) {
                                customStart = new ClockTime(
                                    moment().hour(),
                                    moment().minute()
                                );
                            }

                            localStorage.setItem(LAST_PRESET, preset.id);
                        }

                        return {
                            ...p,
                            timePreset: presetId,
                            customStart: preset ? customStart! : p.customStart,
                            customEnd: preset
                                ? new ClockTime(
                                      preset.endHour,
                                      preset.endMinute
                                  )
                                : p.customEnd
                        };
                    }

                    return p;
                })
            }),
            this.fetchAvailableContacts
        );

        Analytics.trackEvent('create-offer-preset-changed');
    };

    handleCustomPeriodChangeAll = (
        start: ClockTime,
        end: ClockTime,
        presetId: string,
    ) => {
        this.updateOfferFormData(
            (offer) => ({
                ...offer,
                periods: offer.periods.map((p, pix) => {
                    p.timePreset = CustomPreset.id;
                    p.customStart = start;
                    p.customEnd = end;
                    return p;
                })
            }),
            this.fetchAvailableContacts
        );

        Analytics.trackEvent('create-offer-preset-changed');
    };

    handleCustomPeriodChange = (
        ix: number,
        startEnd: 'start' | 'end',
        val: ClockTime
    ) => {
        const period = this.getOfferFormData().periods.find(
            (_, pix) => ix === pix
        )!;
        if (!period) return;

        let newStart = startEnd == 'start' ? val : period.customStart;
        const newEnd = startEnd == 'end' ? val : period.customEnd;

        if (
            moment(period.date, DateFormats.ShortDateWithDay).isSame(
                new Date(),
                'day'
            ) &&
            newStart.toString() < moment().format('HH:mm')
        ) {
            newStart = new ClockTime(moment().hour(), moment().minute());
        }

        const hasChanged =
            newStart.toString() != period.customStart.toString() ||
            newEnd.toString() != period.customEnd.toString();

        if (hasChanged) {
            const newPeriod = {
                ...period,
                timePreset: CustomPreset.id!,
                customStart: newStart,
                customEnd: newEnd
            };

            this.updateOfferFormData(
                (offer) => ({
                    ...offer,
                    periods: offer.periods.map((p, pix) =>
                        ix === pix ? newPeriod : p
                    )
                }),
                this.fetchAvailableContacts
            );

            // localStorage.setItem(LAST_PRESET, CustomPreset.id);

            Analytics.trackEvent('create-offer-custom-time-changed');
        }
    };

    handleListChange = (listId: number) => {
        this.setState(
            {
                listId: listId
            },
            () => {
                this.fetchAvailableContacts();
            }
        );
        InternalTracker.trackEvent("", {
            category: 'Offers',
            action: 'Offer Contacts List Filtered',
            customDimensions: [
                {
                    id: "REPLACE",
                    value: String(listId)
                }
            ]
        });
        Analytics.trackEvent('create-offer-filter-list-changed');
    };

    handleSetSkills = (skills: Skill[]) => {
        console.log("@@ SET SKILL, ", skills);
        this.updateOfferFormData((offer) => ({
            ...offer,
            skills: skills
        }), () => {
            this.fetchAvailableContacts();
        });
    }

    handleWorkerSelect = (id: string, justAdd?: boolean) => {
        let workersBefore = this.getOfferFormData().workerIds.length;
        this.updateOfferFormData((offer) => ({
            ...offer,
            workerIds:
                justAdd ?
                    (offer.workerIds.indexOf(id) < 0
                    ? offer.workerIds.concat(id)
                    : offer.workerIds) :
                offer.workerIds.indexOf(id) < 0
                    ? offer.workerIds.concat(id)
                    : offer.workerIds.filter((s) => s !== id)
        }), () => {
            let workersAfter = this.getOfferFormData().workerIds.length;
            console.log(workersBefore + " " + workersAfter)
            if (workersBefore !== workersAfter) {
                const offerData = this.getOfferFormData();

                if (offerData.agencyNotifications && (offerData.agencyNotifications.alertType === 1 || offerData.agencyNotifications.alertType === 2) ) {
                    this.reloadMutualAgencies();
                }
            }
        });

        if (!justAdd && this.autoFillWorkerInterval) {
            clearInterval(this.autoFillWorkerInterval);
        }

        InternalTracker.trackEvent("", {
            category: 'Offers',
            action: 'Offer Worker Toggled',
            customDimensions: [{ id: "REPLACE", value: id }]
        });
    };

    reloadAgencies = async () => {
        this.setState({
            agenciesLoading: true
        })
        let agencies = await ProfileApi.getThirdParties();
        this.setState({
            agenciesLoading: false,
            agencies: agencies.data,
        }, () => {
            if (this.state.agencies.length === 1) {
                const selectedAgencies = this.getOfferFormData().agencyDirectNotifications;
                if (selectedAgencies.length === 0) {
                    const onlyAgency = document.querySelector(".agency-notification-list.only-agency .agency") as HTMLElement
                    if (onlyAgency) {
                        onlyAgency.click();
                    }
                }
            }
        });
    }

    reloadMutualAgencies = async () => {
        this.setState({
            mutualAgenciesLoading: true
        })
        const offerData = this.getOfferFormData();
        let mutualAgencyContacts: AgencyOrgRelation[] = [];
        if (offerData.workerIds.length !== 0) {
            let mutualAgencies = await OffersAPI.getMutualAgencies(offerData.workerIds || []);
            if (mutualAgencies) {
                this.updateOfferFormData(
                    (prevOffer) => {
                        let prevOfferAgencyNotification = prevOffer.agencyNotifications
                        prevOfferAgencyNotification!.agencies = [];
                        for (let i = 0; i < mutualAgencies.length; i++) {
                            const agency = mutualAgencies[i];
                            // @ts-ignore
                            const prevTimeout = this.state.previousAgencyTimeouts.find(item => item.agencyContactId === agency.agencyOrganisationId)
                            if (!agency.agencyOrganisationId || !prevOfferAgencyNotification!.agencies.find(item => item.agencyId === agency.agencyOrganisationId)) {
                                prevOfferAgencyNotification!.agencies.push({
                                    agencyName: agency.agencyName,
                                    agencyId: agency.agencyOrganisationId,
                                    agencyDomain: agency.hirerAgencyContactDomain,
                                    timeout: prevTimeout ? prevTimeout.timeout : (agency.timeout || 10) // we removed 200000) - default timeout switch, just setting al to 10 by default
                                })
                            }
                            mutualAgencyContacts.push({
                                organisationId: mutualAgencies[i].agencyOrganisationId,
                                organisationName: mutualAgencies[i].agencyName,
                                contactId: mutualAgencies[i].userContactId,
                            })
                        }
                        return {
                            ...prevOffer,
                            agencyNotifications: prevOfferAgencyNotification,
                            mutualAgencyContacts: mutualAgencyContacts
                        }
                });
            }
        } else {
            this.updateOfferFormData(
                (prevOffer) => {
                    let prevOfferAgencyNotification = prevOffer.agencyNotifications
                    prevOfferAgencyNotification!.agencies = [];
                    return {
                        ...prevOffer,
                        agencyNotifications: prevOfferAgencyNotification,
                        mutualAgencyContacts: mutualAgencyContacts
                    }
            });
        }
        this.setState({
            mutualAgenciesLoading: false
        })
    }

    handleCreateClick = (confirmImmediateAgencyNotification?: boolean) => {

        const locationPlaceId = this.getOfferFormData().placeId;
        const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user") || "{}") : null;
        const maxSMS = this.getOfferFormData().sendSMS ? this.getRequiredData().contacts.filter(c => c.contact.smsNotifications && this.getOfferFormData().workerIds.indexOf(c.contact.userId) !== -1).length : 0;

        const deadlineDate = this.getOfferFormData().deadline === "Off" ? null : this.state.fixedDeadlineDate // this.getFormattedDeadline(this.getOfferFormData().deadline)
        const periods = JSON.parse(JSON.stringify(this.getRequiredData().offer.periods));
        if (deadlineDate && periods.length !== 0) {
            const firstEvent = (periods.map(item => { item.dateInt = (new Date(item.date)).getTime(); return item }).sort(Utilities.dynamicSort("dateInt")))[0];
            const firstEventDate = new Date(firstEvent.date);
            firstEventDate.setHours(firstEvent.customStart.hour);
            firstEventDate.setMinutes(firstEvent.customEnd.minute);
            // @ts-ignore
            if (deadlineDate > firstEventDate) {
                toast.error('The selected deadline is after the first event\'s start date');
                return;
            }
        }

        if (!((window as any).Cypress)) {
            // @ts-ignore
            if (deadlineDate && deadlineDate < Utilities.dateAdd(new Date(), "minute", 9)) {
                toast.error('The selected deadline has to be over 10 minutes from now');
                return;
            }
        }

        const remainingSMSCredits = this.props?.organisationContainer?.state?.organisation?.smsCredit || 0
        
        if (this.getOfferFormData().sendSMS && remainingSMSCredits < maxSMS) {
            toast.error('You don\'t have enough SMS credits to send SMS notifications to all selected workers. Please add more SMS credits or turn off SMS notifications.');
            return;
        }

        if (!this.getOfferFormData().agencyOnly && this.getOfferFormData().workerIds.length === 0) {
            toast.error('You must select a contact to send the offer to');
            return;
        }

        if (this.getOfferFormData().title.trim().length === 0) {
            toast.error('Please add a title to the offer');
            return;
        }

        if (this.getOfferFormData().agencyOnly && this.getOfferFormData().agencyDirectNotifications.length === 0) {
            toast.error('Please select at least one agency');
            return;
        }

        if (
            this.isLocationRequired() &&
            !locationPlaceId &&
            this.getRequiredData().isLocationsSubscriptionActive
        ) {
            toast.error('Please select a location for this offer');
            return;
        }

        let correctedAgencyNotifications: AgencyNotificationConfig = JSON.parse(JSON.stringify(this.getOfferFormData().agencyNotifications));
        const mutualAgencyContacts = this.getOfferFormData().mutualAgencyContacts;
        // Compile list of new agencies with contact ids of all in org
        let newAgenciesListWithUniqueContactIds: AgencyTimeout[] = [];

        // Go over all agencies
        for (let i = 0; i < correctedAgencyNotifications.agencies.length; i++) {

            // Off - but we started sending it as it is, so off state can be saved on server
            if (correctedAgencyNotifications.agencies[i].timeout === 100000) {
                // correctedAgencyNotifications.agencies[i].timeout = null;
            // Default
            } else if (correctedAgencyNotifications.agencies[i].timeout === 200000) {
                correctedAgencyNotifications.agencies[i].timeout = correctedAgencyNotifications.defaultTimeout
            }
            
            let allContactsInOrg = mutualAgencyContacts.filter(item => {
                if (item.organisationId && correctedAgencyNotifications.agencies[i].agencyId && item.organisationId === correctedAgencyNotifications.agencies[i].agencyId) {
                    return true
                }
                if (item.organisationName && correctedAgencyNotifications.agencies[i].agencyName && item.organisationName === correctedAgencyNotifications.agencies[i].agencyName) {
                    return true
                }
                return false;
            });

            for (let j = 0; j < allContactsInOrg.length; j++) {
                const agencyContact = allContactsInOrg[j];
                if (!newAgenciesListWithUniqueContactIds.find(item => item.timeout === correctedAgencyNotifications.agencies[i].timeout && item.agencyContactId === agencyContact.contactId)) {
                    newAgenciesListWithUniqueContactIds.push({
                        timeout: correctedAgencyNotifications.agencies[i].timeout || 0,
                        agencyContactId: agencyContact.contactId
                    })
                }
            }

        }

        console.log(newAgenciesListWithUniqueContactIds);
        // return;

        let firstEvent = new Date(this.getPeriodIntervals().sort((a, b) => {
            let dateA = new Date(a.start);
            let dateB = new Date(b.start);
            return dateA < dateB ? -1 : 1
        })[0].start);

        const now: Date = new Date();
        let minutesBetweenNowAndFirstEventDate = Utilities.differenceBetweenDatesMinutes(now, firstEvent);

        if ((correctedAgencyNotifications.alertType === 1 || correctedAgencyNotifications.alertType === 2) && !confirmImmediateAgencyNotification) {
            
            let biggestNotificationTimeout: number = 0;

            console.log(correctedAgencyNotifications);

            for (let i = 0; i < correctedAgencyNotifications.agencies.length; i++) {
                const element = correctedAgencyNotifications.agencies[i];
                if ((!biggestNotificationTimeout || (element.timeout || 0) > biggestNotificationTimeout) && element.timeout !== 100000) {
                    biggestNotificationTimeout = element.timeout || 0;
                }
            }

            if (minutesBetweenNowAndFirstEventDate < biggestNotificationTimeout) {
                if (!window.confirm("One or more of your selected agency contacts will receive an email after the event starts. Please change the alert delay or confirm.")) {
                    return;
                }
            }

        }

        correctedAgencyNotifications.agencies = newAgenciesListWithUniqueContactIds;

        let agencyDirectNotifications = this.getOfferFormData().agencyOnly ? this.getOfferFormData().agencyDirectNotifications.filter(item => item !== null && item !== "null") : null
        // resetting delayed agency helps, since immediate agency help requested
        if (agencyDirectNotifications && agencyDirectNotifications.length !== 0) {
            correctedAgencyNotifications.agencies = [];
        }

        this.props.onCreate(
            {
                title: this.getOfferFormData().title,
                details: this.getOfferFormData().description,
                locationPlaceId,
                deadline: this.getOfferFormData().deadline === "Off" ? null : deadlineDate,
                workerIds: this.getOfferFormData().agencyOnly ? [] : this.getOfferFormData().workerIds,
                workerIdsToSendSMSTo: this.getRequiredData().contacts.filter(c => c.contact.smsNotifications && this.getOfferFormData().workerIds.indexOf(c.contact.userId) !== -1).map(c => c.contact.userId),
                events: this.getPeriodIntervals().map((p) => p as any),
                agencyNotifications: correctedAgencyNotifications,
                agencyDirectNotifications: agencyDirectNotifications,
                sendSMS: this.getOfferFormData().sendSMS,
                files: this.getOfferFormData().selectedFiles || [],
                folders: this.getOfferFormData().selectedFolders || [],
            },
            this.resetCurrentOffer
        );

        const sortedEvents = this.getPeriodIntervals().sort(Utilities.dynamicSort("start"))
        const lastEventDate = new Date(sortedEvents[sortedEvents.length-1]?.start);
        const localUser = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user") || "{}") : null;
        const rotaSubscription = localUser?.subscriptionNotifications?.subscriptions?.filter(item => item.featureName === "Rota")[0];
        const scheduleHideResultsAfter = Utilities.dateAdd(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999), "day", 7);
        const scheduleTrialPromoDialogueHidden = localStorage.getItem("scheduleTrialPromoDialogueHidden") === "true";

        if (!rotaSubscription && lastEventDate > scheduleHideResultsAfter && this.props.showScheduleTrial && !scheduleTrialPromoDialogueHidden) {
            this.props.showScheduleTrial();
        }
            

        this.setState({ offerJustSent: true }, () => {
            setTimeout(() => {
                this.setState({ offerJustSent: false });
            }, 2000);
        });

        InternalTracker.trackEvent("", {
            category: 'Offers',
            action: 'Offer Created',
            customDimensions: [
                {
                    id: "REPLACE",
                    value: String(this.getOfferFormData().workerIds.length)
                }
            ]
        });
        Analytics.trackEvent('create-offer-confirm-clicked');
        if (this.props.refreshOverlayMenu) {
            this.props.refreshOverlayMenu();
        }
    };

    getPeriodIntervals = () => {
        return this.getOfferFormData().periods.map((period) => ({
            start: this.asMoment(period, 'start').toISOString(),
            end: this.asMoment(period, 'end').toISOString()
        }));
    };

    isPeriodOverlapped = (period: Period): boolean => {
        const start = this.asMoment(period, 'start');
        const end = this.asMoment(period, 'end');

        const count = this.getOfferFormData().periods.filter((p) => {
            const s = this.asMoment(p, 'start');
            const e = this.asMoment(p, 'end');

            return (
                (s >= start && s < end) ||
                (e > start && e <= end) ||
                (s <= start && e >= end)
            );
        });

        return count.length > 1;
    };

    asMoment = (period: Period, startEnd: 'start' | 'end'): Moment => {
        const isOvernight =
            period.customEnd.toString() < period.customStart.toString();

        const time =
            startEnd == 'start' ? period.customStart : period.customEnd;

        return moment(period.date, DateFormats.ShortDateWithDay)
            .add(
                time.hour + (startEnd == 'end' && isOvernight ? 24 : 0),
                'hours'
            )
            .add(time.minute, 'minutes');
    };

    private getLastPeriod = () =>
        this.getOfferFormData().periods[
            this.getOfferFormData().periods.length - 1
        ];

    private getNewTimePeriod = (): Pick<
        Period,
        'timePreset' | 'customStart' | 'customEnd'
    > => {
        const lastPeriod = this.getLastPeriod();
        let { id, startHour, startMinute, endHour, endMinute } =
            lastPeriod.timePreset === CustomPreset.id
                ? this.props.createOfferUtilsFuncs.getAllDayPreset()!
                : this.getRequiredData().presets.find(
                      ({ id }) => id === lastPeriod.timePreset
                  )!;

        if (lastPeriod.timePreset === CustomPreset.id) {
            startHour = lastPeriod.customStart.hour;
            startMinute = lastPeriod.customStart.minute;
            endHour = lastPeriod.customEnd.hour;
            endMinute = lastPeriod.customEnd.minute;
            id = CustomPreset.id;
        }

        return {
            timePreset: id,
            customStart: new ClockTime(startHour, startMinute),
            customEnd: new ClockTime(endHour, endMinute)
        };
    };

    private getRequiredData = () => this.props.createOfferUtilsData;

    private getOfferFormData = () => this.props.createOfferUtilsData.offer;

    private updateOfferFormData = (
        ...args: Parameters<
            CreateOfferUtilsProviderReturnValue['functions']['updateOfferFormData']
        >
    ) => this.props.createOfferUtilsFuncs.updateOfferFormData(...args);
}

export default connect(null, { ...EventsState.actionCreators })((props) => (
    <Subscribe to={[DashboardContainer, UserTypeContainer, OrganisationContainer]}>
        {(dashboard: DashboardContainer, userType: UserTypeContainer, organisation: OrganisationContainer) => (
            <CreateOffer
                // @ts-ignore
                {...props}
                dashboardContainer={dashboard}
                userType={userType}
                organisationContainer={organisation}
            />
        )}
    </Subscribe>
)) as any;

const Wrapper = styled.div`
    margin: 0 auto;
    overflow: auto;
    z-index: 3;
    width: 100%;
    display: none !important;
    min-height: 100%;
    margin-right: 0;

    .mask {
        width: 100%;
        background-color: rgba(0, 0, 0, 0.4);
        position: fixed;
        width: 100%;
        height: 100vh;
        top: 0;
        left: 0;
        z-index: 2;
    }

    .cont {
        width: 520px;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        z-index: 3;
        background: white;
        overflow: auto;
    }

    &[data-show="true"] {
        display: block !important;
    }

    .head {
        background-color: ${theme.colours.blue2};
        padding: 15px;
        position: relative;

        h2 {
            font-weight: 700;
            margin: 0;
            color: white;
            font-size: 1.3em;
        }

        label {
            position: absolute;
            top: 0;
            right: 0;
            display: flex;
            align-items: center;
            color: white;
            padding: 12px 15px;

            .react-toggle {
                margin-right: 8px;
            }
        }
    }


    main {
        display: flex;
        padding: 12px 20px 0px 20px;
        background: white;
    }

    .offer-type-wrapper {
        background: whitesmoke;
        padding: 10px;
        border-radius: 16px;
        position: relative;

        .worker-select-filter-toggle {

        }

        .table-wrapper {
            max-width: 460px;
        }
    }

    .agency-help-option {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        background-color: gainsboro;
        padding: 6px;
        border-radius: 16px;

        & > div {
            padding: 8px;
            width: 50%;
            border-radius: 12px;
            cursor: pointer;

            svg {
                width: 100%;
                height: auto;
                display: none;
            }

            p {
                flex-basis: calc(100% - 90px);
                margin: 0;
                text-align: center;
                font-size: 0.9em;
                line-height: 1.1em;

                span {
                    text-decoration: underline;
                }
            }

            &[data-selected="false"] {
                background-color: none;
                color: black !important;
            }

            &[data-selected="true"] {
                background-color: ${theme.colours.blue2};
                
                p {
                    font-weight: 600;
                    color: white;
                }

            }

        }

        &.offer-type {

            & > div {
                width: 50%;
                display: inline-flex;
                align-items: center;

                img {
                    width: 80px;
                    margin: 0 12px 0 0;
                    display: block;
                    border-radius: 6px;
                }

                p {
                    margin: 0;
                }
            }

            &[data-minimized="true"] {
                position: absolute;
                top: -43px;
                right: 0;
                width: 180px;
                padding: 4px;

                &>div {
                    padding: 4px;

                    img {
                        display: none;
                    }
    
                    p {
                        flex-basis: 100% !important;
                    }
                }
            }
        }

    }

    .agency-notification-list {
        width: 100%;
        box-shadow: inset 0px 11px 8px -10px #ccc, inset 0px -11px 8px -10px #ccc;

        .no-res {
            background-color: ${theme.colours.amber};
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 20px;
            border-radius: 7px;
            padding: 8px 10px;

            i {
                margin-right: 10px;
            }
        }
        
        .default-time, .agency {
            display: flex;

            & > div {
                display: inline-flex;
                flex-basis: 200px;
                align-items: center;

                .thumbnail {
                    padding-right: 12px;
                }

                h4 {
                    overflow: hidden;
                    font-size: 1.1em;
                    padding: 0 10px;
                    line-height: 1.4em;
                    margin: 0;
                }

            }

            select {
                flex-basis: calc(100% - 200px);
                padding-left: 20px;
                border-color: #b1b1b1;
                border-radius: 4px;
                padding: 0 12px;
            }
        
            &:not(:first-child) {
                margin-top: 8px;
            }
        }

        &.default-time {
            padding-right: 16px;
            color: white;
        }

        &.only-agency {

            .agency {
                align-items: center;
                justify-content: flex-start;
                margin: 0;
                padding: 5px 0;
                position: relative;

                &>div:not(.checkbox) {
                    flex-basis: 32px;
                    margin: 0 12px;
                }

                .checkbox {
                    flex-basis: 28px;
                    font-size: 2em;
                    margin: 0;
                }

                &>h4 {
                    flex-basis: 100%;
                    margin: 0;
                }

                &[data-selected="true"] {
                    background-color: #b8daff !important
                }

                &:not(:first-child) {
                    border-top: 1px solid #ddd;
                }

                .selected {
                    display: inline-flex;
                    position: absolute;
                    top: 8px;
                    right: 5px;
                    background: white;
                    border-radius: 4px;
                    padding: 3px 8px;
                    align-items: center;

                    i {
                        margin-right: 6px;
                    }
                }
            }
        }

    }

    .head {
        position: relative;
        font-weight: 700;
    }

    .fa-times:not(.day-availability) {
        position: absolute;
        top: 13px;
        right: 24px;
        font-size: 24px;
        z-index: 2;
        color: white;
        cursor: pointer;
    }

    > h4 {
        text-align: center;
    }

    th {
        font-size: 12px;
    }

    td {
        padding: 0 5px;
    }

    .flex {
        overflow: visible !important;
    }

    .additional-dates {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        span:not(.DayPicker-NavButton) {
            display: inline-block;
            background-color: #34495e;
            color: white;
            opacity: 1;
            padding: 7px 10px 8px 10px;
            border-radius: 6px;
            margin: 6px 10px 16px 0px;

            i {
                margin-right: 10px;
            }
        }
    }

    .btn-default,
    .btn-default:active,
    .btn-default:hover,
    .btn-default:focus {
        background: transparent;
        border: 0;
        outline: 0;

        &:hover {
            text-decoration: underline;
        }

        &:active {
            box-shadow: none !important;
        }
    }

    .defaultLocationName {
        font-size: 1rem;
        font-style: italic;
        text-align: center;
    }

    .send-offer-btn-wrapper-floating {
        min-height: 32px;
        margin: 15px 0px;
        position: fixed;
        left: 504px;
        top: 50%;
        transform: translate(0, -50%);

        button {
            max-width: 312px !important;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            white-space: break-spaces;
            border-radius: 12px 52px 52px 12px !important;
            padding: 10px 12px !important;
            height: unset !important;

            &>span {
                flex-wrap: wrap;

                &>span {
                    height: 28px;

                    &:last-child {
                        margin-top: 6px;
                    }
                }
            }

            i {
                font-size: 20px;
                margin-right: 15px;
            }
        }
    }

    .send-offer {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 40px;
        border-radius: 52px;
        padding: 6px 8px;
        font-weight: 600;
        border: none;
        width: 100%;

        &>span {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            &>span {
                height: 100%;
                display: inline-flex;
                align-items: center;
            }
        }

        .agency-images, .profile-images {
            position: relative;
            margin: 0 5px;
            height: 28px;
            display: inline-block;
            max-width: 100%;

            &>div {
                position: absolute;
                top: 0;

                &:nth-child(1) {
                    left: 0;
                    z-index: 1;
                }

                &:nth-child(2) {
                    left: 14px;
                    z-index: 2;
                }

                &:nth-child(3) {
                    left: 28px;
                    z-index: 3;
                }

                &:nth-child(4) {
                    left: 42px;
                    z-index: 4;
                }

                &:nth-child(5) {
                    left: 56px;
                    z-index: 5;
                }

                &:nth-child(6) {
                    left: 70px;
                    z-index: 6;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    width: 28px;
                    height: 28px;
                    background: white;
                    border-radius: 100%;
                    color: black;
                }
            }
        }

        &.send-offer-right {

        }
    }

    .extra-features {
        display: flex;
        flex-wrap: wrap;
        margin-top: 20px;

        &[data-open="true"] {
            min-height: 270px;
        }
    }

        .feature {
            flex-basis: calc(33.3333% - 7px);
            margin: 0 10px 10px 0;
            height: 130px;
            color: white;
            border-radius: 16px;
            overflow: hidden;
            display: flex;
            flex-wrap: wrap;
            cursor: pointer;

            h3 {
                
                button {
                    float: right;

                    &[data-selected="false"] {
                        color: white !important;
                        background: transparent !important;
                        border: 1px solid white;
                    }
                }
            }

            .agency-notification-list {
                box-shadow: unset;
                color: white;
            }

            .agency-help-option {

                &>div[data-selected="false"] {
                    color: black !important;

                    p {
                        color: black !important;
                    }
                }
            }

            .uncompleted {

                &>img.stock {
                    margin-top: 10px;
                }
            }

            &.always-visible {
                background-color: transparent !important;
                height: unset !important;
                border-radius: 0;
                overflow: visible !important;

                .open {
                    padding: 0 !important;
                    height: unset !important;
                }
            }

            &.notes {
                background-color: ${theme.colours.blue2};

                textarea {
                    margin-bottom: 15px;
                    resize: none;
                    border-radius: 12px;
                }

                &.always-visible {
                    
                    .open {

                        textarea {
                            margin-bottom: 0;
                        }
                    }
                }
            }

            &.files {
                background-color: ${theme.colours.blue2};

                button {
                    margin-bottom: 8px !important;
                }

                .completed {

                    span {
                        background-color: white;
                        color: black;
                        display: inline-block;
                        padding: 0px 4px;
                        border-radius: 6px;
                        font-weight: 700;
                    }
                }
            }

            &.sms {
                background-color: ${theme.colours.green2};

                p { 
                    font-size: 0.9em;

                    span{
                        display: inline-block;
                        padding: 0 5px;
                    }
                }
            }

            &.agency {
                background-color: ${theme.colours.amber};

                p { 
                    font-size: 0.9em;
                }

                .open-content {
                    background-image: linear-gradient(to top, ${theme.colours.amber}, ${theme.colours.amber}), linear-gradient(to top, ${theme.colours.amber}, ${theme.colours.amber}), linear-gradient(to top, rgba(0, 0, 0, 0.25), rgba(255, 255, 255, 0)), linear-gradient(to bottom, rgba(0, 0, 0, 0.25), rgba(255, 255, 255, 0));
                    background-position: bottom center, top center, bottom center, top center;
                    background-color: ${theme.colours.amber};
                    background-repeat: no-repeat;
                    background-size: 100% 20px, 100% 20px, 100% 10px, 100% 10px;
                    background-attachment: local, local, scroll, scroll;
                }

                .open {

                    .open-content {
                        height: 158px !important;
                    }
                }

                .agency-list {
                    height: 170px;
                    margin-bottom: 10px;

                    .agency { 
                        display: flex;
                        align-items: center;
                        justify-content: left;
                        padding: 4px 8px;
                        border-radius: 10px;

                        .thumbnail {
                            border-radius: 100%;
                            margin: 0 8px;
                        }

                        h4 {
                            color: white;
                        }

                        .checkbox {
                            font-size: 24px;
                            margin: 0;
                        }

                        &[data-selected="true"] {
                            background: rgba(0,0,0,0.2);
                        }
                    }
                }

                .completed {

                    button {
                        display: block;
                        text-align: center;
                        margin: 0 auto 8px auto;
                    }
                }

                .open {

                    .open-content {
                        height: 164px;
                        overflow: auto;
                        margin-bottom: 10px;
                    }
                }

                .agency-help-wrapper {
                    display: inline-block;
                    margin: 0 4px;
                    position: relative;

                    i {
                        position: absolute;
                        bottom: -4px;
                        right: -4px;
                        width: 16px;
                        height: 16px;
                        border-radius: 100%;
                        background-color: white;
                        padding-top: 2px;
                        font-size: 11px;
                    }

                    &>div {
                        border: 1px solid white;
                    }
                }
            }

            &.deadline {
                background-color: ${theme.colours.red2};

                .opts {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 10px;
                    
                    button {
                        padding: 0 12px !important;
                        height: 30px;
                        font-weight: 500;
                    }

                    label {
                        margin: 0 10px;
                    }

                    select {
                        padding: 4px 8px;
                        border: none;
                    }
                }

                .open {
                    text-align: center;

                    p {
                        margin: 15px 0;

                        span {
                            background-color: white;
                            color: black;
                            display: inline-block;
                            padding: 2px 8px;
                            border-radius: 52px;
                        }
                    }

                    .react-datetime-picker {
                        background: white;
                        border-radius: 52px;
                        
                        &>div {
                            border: none;
                            padding: 0 2px 0 10px;
                        }
                    }
                }

                &[data-open="true"] {
                    overflow: visible !important;
                }
            }

            &.location {
                background-color: #A35D6A;

                .open {
                    height: 236px;

                    &>div>div {
                        color: black;
                        text-align: left;
                    }
                }

                .uncompleted {
                    position: relative;

                    img {
                        width: 100%;
                        height: 98px;
                        object-fit: cover;
                    }

                    .marker {
                        position: absolute;
                        top: 20px;
                        z-index: 2;
                        width: 100%;

                        span {
                            position: absolute;
                            top: -20px;
                            display: inline-block;
                            background-color: #A35D6A;
                            width: calc(100% - 10px);
                            left: 5px;
                            font-size: 0.8em;
                            max-height: 34px;
                            overflow: hidden;
                            top: -15px;
                            border-radius: 6px;
                            padding: 2px;
                        }

                        i {
                            color: #A35D6A;
                            font-size: 36px;
                            left: 50%;
                            transform: translate(-50%, 0);
                            position: absolute;
                            top: 0;
                        }
                    }
                }

                &[data-open="true"] {

                    .marker {
                        display: none;
                    }

                    img {
                        display: none;
                    }
                }
            }

            h3 {
                font-size: 0.9em;
                background-color: rgba(0, 0, 0, 0.3);
                margin: 0;
                padding: 10px 8px;
                color: white;
                font-weight: 700;
                align-self: flex-start;
                flex-basis: 100%;
            }

            select, input {
                color: black;
                border-radius: 52px;
            }

            &>div {
                align-self: flex-end;
                flex-basis: 100%;

                p {
                    color: white;
                }

                &.uncompleted {
                    text-align: center;

                    img {
                        display: block;
                        width: 100%;
                    }
                }

                &.completed {
                    padding: 10px;
                    align-self: center;
                    text-align: center;
                    
                    button {
                        border-radius: 52px;
                        padding: 4px 10px;
                        margin-bottom: 4px;
                        background: white;
                        border: none;
                        color: black;
                    }

                    p {
                        color: white
                    }
                }

                &.open {
                    padding: 15px;
                    text-align: right;
                }
            }

            &[data-open="true"] {
                position: absolute;
                width: calc(100% - 40px);
                margin: 0;
                height: 270px;
                z-index: 3;

                button, a {
                    border-radius: 52px;
                    padding: 6px 12px;
                    background: white !important;
                    display: inline-block;
                    border: none;
                    color: black;
                    opacity: 1;
                    font-weight: 700;
                }
            }

            .buttons {
                margin-top: 10px;

                a {
                    margin: 0 0 10px 10px !important;
                }
            }

            &:nth-child(3), &:nth-child(6) {
                margin-right: 0;
            }
        }

    .countdown {
        text-align: center;

        ul {
            margin: 0;
            padding: 0;

            li {
                display: inline-block;
                font-size: 10px;
                line-height: 10px;
                list-style-type: none;
                color: white;
                text-transform: uppercase;
                width: 32px;
            }
    
            li span {
                display: block;
                font-size: 16px;
                font-weight: 700;
                margin-bottom: 0px;
                line-height: 28px;
            }
        }
    }


    @media (max-width: 900px) {
        position: absolute;

        .head label {
            right: 60px;
        }

        .send-offer-btn-wrapper-floating { 
            display: none;
        }

        .additional-dates {

            & > span, & > div {
                flex-basis: calc(50% - 5px);
                text-align: center;

                & > span {
                    width: 100%;
                }

            }

        }

        .cont {
            width: 100%;
        }
    }

    @media (max-width: 860px) {

        main {
            display: block;
    
            &>div {
                width: 100% !important;

                &:last-child {
                    padding-left: 0;
                }
    
            }
    
        }

    }

    @media (max-width: 550px) {

        .send-offer {

            &>span {
                flex-wrap: wrap;

                &>span {
                    height: 28px;

                    &:last-child {
                        margin-top: 6px;
                    }
                }
            }
        }
    }

    @media (max-width: 520px) {
        width: 100vw;
        max-height: unset;
        padding: 20px 10px 10px 10px;
        height: calc(100vh - 160px);
        top: 50%;
        transform: translate(0, -50%);

        .agency-help-option:not(.offer-type) {
            display: block;

            & > div {
                width: 100% !important;
                display: flex;
                margin: 0 !important;

                svg {
                    width: 120px;
                    flex-basis: 120px
                }

                p {
                    flex-basis: calc(100% - 120px);
                    width: calc(100% - 120px);
                    text-align: left;
                    padding-left: 18px;
                }

            }
        
        }

        .head {

            .fa-times {
                color: black;
            }

            label {
                position: static;
            }
        }

        .extra-features {
            
            .feature {
                margin-right: 10px !important;
                flex-basis: calc(50% - 5px);


                &:nth-child(2),
                &:nth-child(4),
                &:nth-child(6)
                {
                    margin-right: 0px !important;
                }
            }
        }
    }   

`;

const TitleBar = styled.div`
    position: relative;
    padding: 15px 10px 10px 5px;

    > span {
        display: inline-block;
        width: 22px;
        height: 22px;
        margin-right: 10px;
        padding: 2px 0;
        color: white;
        font-weight: 600;
        text-align: center;
        background: ${theme.colours.blue2};
        border-radius: 50%;
    }

    > button {
        float: right;
        background-color: #34495e;
        border: none;
        color: white;
        border-radius: 6px;
        padding: 5px 10px;

        i {
            margin-right: 6px;
        }
    }

    h5,
    label {
        display: inline-block;
        color: #333;
        font-weight: 600;
        margin: 0;
    }

    h5 {
        
        button {

            &[data-selected="true"] {
                float: right;
                border: 1px solid ${theme.colours.updatedge};
                background: none;
                padding: 4px 8px;
                border-radius: 52px;
                position: absolute;
                right: 8px;
                top: 14px;
            }
        }
    }
`;

const PeriodRow = styled.div`
    position: relative;
    margin-top: 5px;
    display: flex;

    .DayPickerInput {
        flex-basis: 104px;
    }

    select {
        display: inline;
        margin: 0 8px;
        flex-basis: calc(100% - 280px);
    }

    .clock-picker {
        flex-basis: 56px;

        input {
            width: 56px !important;
        }

    }

    .show-sm {
        display: none;
    }

    input,
    select {
        text-align: center;
        padding: 5px 6px;
        border: 1px solid #bfbfbf !important;
        border-radius: 4px;
    }

    input {
        width: 100%;
    }

    .show-sm {
        display: block;
    }

    .DayPickerInput-OverlayWrapper {
        position: fixed;
        z-index: 1;
    }

    .fa-trash-o {
        font-size: 20px;
        flex-basis: 32px;
    }

    .fa-exclamation-triangle {
        position: absolute;
        top: 10px;
        color: ${theme.colours.red};
    }

    &.overlapped {
        input,
        select {
            background: #fcc !important;
        }
    }

    @media (max-width: 500px) {
        input,
        select {
            font-size: 11px;
            padding: 4px 5px;
            height: auto;
        }

        .DayPickerInput {
            flex-basis: 110px;
        }

        .clock-picker {
            flex-basis: 48px;

            input {
                width: 100%;
            }
        }

        select {
            flex-basis: calc(100% - 206px);
        }
    }
`;

const Link = styled(Hyperlink)`
    padding-left: 3px;
    font-weight: normal;
    font-style: normal;
    opacity: 0.6;
`;