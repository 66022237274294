/* eslint-disable */
import { ContactWithRating } from 'src/components/rating/WorkerRater';
import AppConfig from '../../components/config/Config';
import afetch from '../AuthenticatedFetch';
import { Rating } from '../offers/ResponseTypes';
import { RatingPostDTO, RatingRecordPostDTO } from './RequestTypes';
import UnauthenticatedFetch from '../UnauthenticatedFetch';

const RatingsApi = {
    postExternalRating(token: string, ratings: RatingRecordPostDTO[], ratingDirection?: number) {
        const url = `${AppConfig.Settings.api.externalUri}/rating`;
        return UnauthenticatedFetch.request<RatingPostDTO, null>(
            url,
            'POST',
            {
                ratingDirection: ratingDirection || 8,
                ratings: ratings,
                token: token,
            },
            'Unable to post ratings'
        );
    },

    postTimesheetRatings(token: string, providerOrgId: string, raterExternalOrgId: string, provideeOrgName: string, ratings: RatingRecordPostDTO[]) {
        const url = `${AppConfig.Settings.api.externalUri}/rating`;
        return afetch.request<RatingPostDTO, null>(
            url,
            'POST',
            {
                ratingDirection: 5,
                providerOrgId: providerOrgId,
                raterExternalOrgId: raterExternalOrgId,
                provideeOrgName: provideeOrgName,
                ratings: ratings,
                token: token,
            },
            'Unable to post ratings'
        );
    },

    postWorkerTimesheetRatings(token: string, providerOrgId: string, raterExternalId: string, raterId: string, ratings: RatingRecordPostDTO[]) {
        const url = `${AppConfig.Settings.api.externalUri}/rating`;
        return afetch.request<RatingPostDTO, null>(
            url,
            'POST',
            {
                ratingDirection: 1,
                ratings: ratings,
                token: token,
                providerOrgId: providerOrgId,
                raterExternalId: raterExternalId,
            },
            'Unable to post ratings'
        );
    },

    postWorkerRatingByHirerOrg(orgId: string, ratings: RatingRecordPostDTO[], organisationIsAgency: boolean) {
        const url = `${AppConfig.Settings.api.externalUri}/rating`;
        return afetch.request<RatingPostDTO, null>(
            url,
            'POST',
            {
                ratingDirection: organisationIsAgency ? 15 : 8,
                ratings: ratings.map(rating => {
                    if (rating.contactId && !rating.userId) {
                        rating.recipientContactId = rating.contactId;
                        delete rating.contactId;
                    }
                    return rating;
                }),
                raterOrgId: orgId
            },
            'Unable to post ratings'
        );
    },

    getAllMyRatings() {
        const url = `${AppConfig.Settings.api.externalUri}/rating/my`;
        return afetch.request<null, Rating[]>(
            url,
            'GET',
            null,
            'Unable to get ratings'
        );
    },

    approveRating(id: string) {
        const url = `${AppConfig.Settings.api.externalUri}/rating/${id}/recipient/approve`;
        return afetch.request<null, Rating[]>(
            url,
            'PUT',
            null,
            'Unable to approve rating'
        );
    },

    hideRating(id: string) {
        const url = `${AppConfig.Settings.api.externalUri}/rating/${id}/recipient/hide`;
        return afetch.request<null, Rating[]>(
            url,
            'PUT',
            null,
            'Unable to approve rating'
        );
    },

    reportRating(id: string) {
        const url = `${AppConfig.Settings.api.externalUri}/rating/${id}/report`;
        return afetch.request<null, null>(
            url,
            'PUT',
            null,
            'Unable to report rating'
        );
    },

    getExternalRating(token: string) {
        const url = `${AppConfig.Settings.api.externalUri}/rating/external?token=${token}`;
        return UnauthenticatedFetch.request<null, ContactWithRating>(
            url,
            'GET',
            null,
            'Unable to get ratings'
        );
    }
};

export default RatingsApi;
