/* eslint-disable */
export interface OfferInfoSearchOptions {
    historic: boolean;
    search: string;
}

export interface OfferRecipientAgency {
    recipientId: number;
    engageDirectly: boolean;
    engageAgency: boolean;
    agencyUserContactId: string | number | undefined | null;
}

export type NewFavouriteOfferDTO = {
    title: string;
    description: string;
    recipients: string[];
    locationPlaceId?: string;
    startTime: string;
    endTime: string;
};

export type OfferListParams = {
    rowsPerPage: number,
    sortDirection: "asc" | "desc",
    page: number,
    offerType?: OfferType
    orderByColumn?: SortableOffersColumns,
    keyword?: string
}

export enum OfferType {
    All = 0,
    Upcoming = 1,
    Resolved = 2
}

export enum SortableOffersColumns {
    FirstEventStart = 0,
    Title = 1,
    DateDiffFromNowInMins = 2
}