import { IpInfoResponseV2, SunriseSunsetResponse } from './ResponseTypes';

const IpInfo = {
    get() {
        // return fetch(`https://ipapi.co/json/`).then(
        //     (response) => response.json() as Promise<IpInfoResponse>
        // );
        return fetch(`http://ip-api.com/json`).then(
            (response) => response.json() as Promise<IpInfoResponseV2>
        );
    },

    getSunriseSunset(lat: number, lng: number, date?: string) {
        return fetch(
            `https://api.sunrise-sunset.org/json?lat=${lat}&lng=${lng}&formatted=0${
                date ? `&date=${date}` : ''
            }`
        )
            .then(
                (response) =>
                    response.json() as Promise<{
                        results: SunriseSunsetResponse;
                    }>
            )
            .then((response) => response.results);
    }
};

export default IpInfo;
