/* eslint-disable */
import fetch from 'cross-fetch';
import { ToastType } from 'react-toastify';

import afetch from '../AuthenticatedFetch';
import unafetch from '../UnauthenticatedFetch';
import AppConfig from '../../components/config/Config';
import {
    UpdateProfileDto,
    UserProfileDto,
    UserReputation,
    NewThirdParty,
    AddWorkerTemporaryOrganisationRequest,
    UserChangeThirdpartyRequest
} from './RequestTypes';
import * as Notifications from './../../store/Notifications';
import { SingleResponseDto } from '../BaseResponseTypes';
import {
    AadUserProfile,
    UserChangeThirdpartyResponse,
    UserThirdParty
} from './ResponseTypes';
import { UserDto } from '../user/ResponseTypes';

const apiUrls = {
    updateProfileUrl() {
        return `${AppConfig.Settings.api.baseUri}/publicapi/user`;
    },
    getProfile() {
        return `${AppConfig.Settings.api.externalUri}/userprofile`;
    },
    getProfileImage(id: string) {
        if (!id) {
            return "";
        }
        if ((window as any).globalUserId === id && localStorage.getItem("ownVersionOfProfileImage")) { 
            return `${AppConfig.Settings.api.imageCdnUri}/images/profile/${id.toLowerCase()}.png?v=` + localStorage.getItem("ownVersionOfProfileImage");
        }
        return `${AppConfig.Settings.api.imageCdnUri}/images/profile/${id.toLowerCase()}.png`;
    },
    getCompanyProfileImage(id: string) {
        return `${AppConfig.Settings.api.baseUri}/publicapi/company/image/${id}`;
    },
    updateProfileImage(id) {
        return `${AppConfig.Settings.api.imageCdnUri}/image/profile/${id.toLowerCase()}.png`;
    },
    getContactUserProfile(userId: string) {
        return `${AppConfig.Settings.api.baseUri}/publicapi/user/${userId}`;
    },
    confirmEmail(token: string) {
        return `${AppConfig.Settings.api.baseUri}/api/user/confirmEmail?token=${token}`;
    },
    sendActivateMail() {
        return `${AppConfig.Settings.api.baseUri}/api/user/sendActivateMail`;
    },
    aadUserProfile() {
        return `${AppConfig.Settings.api.baseUri}/api/user`;
    },
    requestPasswordReset(email: string) {
        return `${AppConfig.Settings.api.baseUri}/api/user/requestPasswordReset?email=${email}`;
    },
    confirmPasswordReset() {
        return `${AppConfig.Settings.api.baseUri}/api/user/confirmPasswordReset`;
    },
    pendingActivation() {
        return `${AppConfig.Settings.api.baseUri}/api/user/pendingActivation`;
    },
    closeAccount() {
        return `${AppConfig.Settings.api.baseUri}/api/user/closeAccount`;
    },
    updateProfileImageOnBehalfOf(id: string) {
        return `${AppConfig.Settings.api.baseUri}/api/profileimage/${id}`;
    },
    getBase64ProfileImage(id: string) {
        return `${AppConfig.Settings.api.externalUri}/userprofile/byId/${id}/image-base64?width=256&height=256`;
    },
    updatePortalNewsReadVersion(version: number) {
        return `${AppConfig.Settings.api.externalUri}/userprofile/portalupdatesversion/${version}`;
    },
    sendSMSVerificationCode() {
        return `${AppConfig.Settings.api.externalUri}/users/phone/send/verification`;
    },
    verifyOtpCode(phoneNumber, code) {
        return `${AppConfig.Settings.api.externalUri}/users/phone/verify/${phoneNumber}/${code}`;
    },
    getLoginLinkForEmail(email: string) {
        return `${AppConfig.Settings.api.externalUri}/userprofile/generateLoginLinkWithEmail?email=${email}`;
    },
    finaliseUser() {
        return `${AppConfig.Settings.api.externalUri}/userprofile/finaliseuser`;
    },
};

/** Profile API */
const self = {
    getLoginLinkForEmail: (email: string) => {
        return unafetch.request<null, string>(
            apiUrls.getLoginLinkForEmail(email),
            'GET',
            null,
            'Failed to get login link for email'
        );
    },
    finaliseUser: () => {
        return afetch.request<null>(
            apiUrls.finaliseUser(),
            'PUT',
            null,
            'Failed to finalise user'
        );
    },
    getProfile: async () => {
        const userProfile = await afetch.request<
            null,
            SingleResponseDto<UserProfileDto>
        >(
            apiUrls.getProfile(),
            'GET',
            null,
            'Could not retrieve profile information'
        );
        return userProfile;
    },
    /** Updates a user's profile information */
    updateProfile: (profileUpdate: UpdateProfileDto) => {
        return afetch.request<UpdateProfileDto>(
            apiUrls.updateProfileUrl(),
            'POST',
            profileUpdate,
            'Sorry but we could not update your profile.'
        );
    },
    sendSMSVerificationCode: (number: string) => {
        return afetch.request<{
            phoneNumber: string,
            source: string
        }>(
            apiUrls.sendSMSVerificationCode(),
            'POST',
            {
                phoneNumber: number,
                source: "portal"
            },
            'Sorry but we could not update your profile.'
        );
    },
    getProfileImageUrl: (id: string) => {
        return apiUrls.getProfileImage(id);
    },
    verifyOtpCode: (phoneNumber: string, code: string) => {
        return unafetch.request<UpdateProfileDto>(
            apiUrls.verifyOtpCode(phoneNumber, code),
            'GET',
            null,
            'Failed to verify OTP code'
        );
    },
    getCompanyProfileImageUrl: (id: string) => {
        return apiUrls.getCompanyProfileImage(id);
    },
    updateProfileImage: async (image: File, userId: string) => {
        const updatingToastId = Notifications.actionCreators.display(
            ToastType.INFO,
            'Uploading profile image, please wait...',
            { autoClose: false }
        );
        localStorage.setItem("ownVersionOfProfileImage", (localStorage.getItem("ownVersionOfProfileImage") ? ( parseInt(localStorage.getItem("ownVersionOfProfileImage") || "") + 1 ) : "2").toString() );
        return await afetch
            .uploadFile(
                apiUrls.updateProfileImage(userId),
                image,
                'Failed to upload profile image.'
            )
            .then(() => {
                Notifications.actionCreators.dismiss(updatingToastId);
                Notifications.actionCreators.display(
                    ToastType.SUCCESS,
                    'Profile image updated successfully.'
                );
            });
    },
    getContactUserProfile: (userId: string) => {
        return afetch
            .request<null, UserProfileDto>(
                apiUrls.getContactUserProfile(userId),
                'GET',
                null,
                "Could not retrieve contact's profile."
            )
            .then((contactUserProfile) => {
                return contactUserProfile;
            });
    },
    confirmEmail: (token: string) => {
        return fetch(apiUrls.confirmEmail(token));
    },
    sendActivateMail: () => {
        return afetch.request<null>(
            apiUrls.sendActivateMail(),
            'GET',
            null,
            'Unable to send activation email'
        );
    },
    setPendingActivation: () => {
        return afetch.request<null>(
            apiUrls.pendingActivation(),
            'POST',
            null,
            ''
        );
    },
    aadUserProfile: () => {
        return afetch.request<null, AadUserProfile>(
            apiUrls.aadUserProfile(),
            'GET',
            null,
            'Unable to get profile'
        );
    },
    requestPasswordReset: (email: string) => {
        return fetch(apiUrls.requestPasswordReset(email));
    },
    confirmPasswordReset: (password: string, token: string) => {
        return fetch(apiUrls.confirmPasswordReset(), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ password, token })
        }).then((response) => {
            return response.json() as Promise<boolean>;
        });
    },
    isUserVerified: () => {
        const url = `${AppConfig.Settings.api.baseUri}/publicapi/user/verified`;
        return afetch.request<null, boolean>(
            url,
            'GET',
            null,
            'Unable to get user verified'
        );
    },
    tryVerifyUser: () => {
        const url = `${AppConfig.Settings.api.baseUri}/publicapi/user/verify`;
        return afetch.request<null, boolean>(
            url,
            'GET',
            null,
            'Unable to request verification'
        );
    },
    getReputation: () => {
        const url = `${AppConfig.Settings.api.baseUri}/publicapi/user/reputation`;
        return afetch.request<null, UserReputation>(
            url,
            'GET',
            null,
            'Unable to get reputation'
        );
    },
    getType: () => {
        const url = `${AppConfig.Settings.api.baseUri}/api/user/type`;
        return afetch.request<null, number>(url, 'GET', null);
    },
    setType: (type: number) => {
        const url = `${AppConfig.Settings.api.externalUri}/userprofile/usertype/${type}`;
        return afetch.request<null>(url, 'PUT', null, 'Unable to set type');
    },
    updatePortalNewsReadVersion: (version: number) => {
        const url = apiUrls.updatePortalNewsReadVersion(version);
        return afetch.request<null>(url, 'PUT', null, 'Unable to update version number');
    },
    closeAccount: (feedback = '') => {
        const url = `${apiUrls.closeAccount()}${
            Boolean(feedback) ? `?feedback=${encodeURIComponent(feedback)}` : ''
        }`;

        return afetch.request<null, null>(
            url,
            'GET',
            null,
            'Unable close account'
        );
    },
    getThirdParties() {
        const url = `${AppConfig.Settings.api.externalUri}/users/thirdParties`;
        return afetch.request<null, { data: UserThirdParty[] }>(
            url,
            'GET',
            null,
            'Unable get third parties'
        );
    },
    getReceivedThirdParties() {
        const url = `${AppConfig.Settings.api.externalUri}/users/thirdParties/received`;
        return afetch.request<null, { data: UserThirdParty[] }>(
            url,
            'GET',
            null,
            'Unable get received third parties'
        );
    },
    changeThirdParty(change: UserChangeThirdpartyRequest) {
        const url = `${AppConfig.Settings.api.externalUri}/users/changeThirdParty`;
        return afetch.request<any, { data: UserChangeThirdpartyResponse } > (
            url,
            'POST',
            change,
            'Unable to update third party'
        );
    },
    removeThirdParty(id: UserThirdParty['userContactId']) {
        const url = `${AppConfig.Settings.api.externalUri}/users/primaryContact/${id}`;
        return afetch.request<null>(
            url,
            'DELETE',
            null,
            'Unable to remove third party',
            undefined,
            true
        );
    },
    getBase64ProfileImage(id: string) {
        return afetch.request<null, string>(
            apiUrls.getBase64ProfileImage(id),
            'GET',
            null,
            'Unable to image',
            undefined,
            true
        );
    },
    addThirdParty(payload: NewThirdParty | UserDto['id']) {
        if (typeof payload === 'string') {
            const url = `${AppConfig.Settings.api.externalUri}/users/primaryContact/${payload}`;
            return afetch.request<null, { data: UserThirdParty }>(
                url,
                'POST',
                null,
                'Unable to set third party'
            );
        }

        const url = `${AppConfig.Settings.api.externalUri}/users/primaryContact`;
        return afetch.request<NewThirdParty, { data: UserThirdParty }>(
            url,
            'POST',
            payload,
            'Unable to add third party',
            undefined,
            true
        );
    },
    addWorkerTemporaryOrganisation(
        payload: AddWorkerTemporaryOrganisationRequest
    ) {
        const url = `${AppConfig.Settings.api.externalUri}/users/workerTempOrganisation`;
        return afetch.request<
            AddWorkerTemporaryOrganisationRequest,
            { data: UserThirdParty }
        >(url, 'POST', payload, 'Unable to set third party');
    },
    removeWorkerTemporaryOrganisation(
        id: UserThirdParty['workerTempOrganisationId']
    ) {
        const url = `${AppConfig.Settings.api.externalUri}/users/workerTempOrganisation/${id}`;
        return afetch.request<null>(
            url,
            'DELETE',
            null,
            'Unable to remove third party',
            undefined,
            true
        );
    },
    updateProfileImageOnBehalfOf: async (image: File, userId: string) => {
        //debugger;
        const updatingToastId = Notifications.actionCreators.display(
            ToastType.INFO,
            'Uploading profile image, please wait...',
            { autoClose: false }
        );
        return await afetch
            .uploadFile(
                apiUrls.updateProfileImageOnBehalfOf(userId),
                image,
                'Failed to upload profile image.'
            )
            .then(() => {
                Notifications.actionCreators.dismiss(updatingToastId);
                Notifications.actionCreators.display(
                    ToastType.SUCCESS,
                    'Profile image updated successfully.'
                );
            });
    }
};

export default self;
