import React from 'react';
import styled from 'styled-components';

interface props {
    name: string;
    completed: boolean;
}

export default class Icon extends React.Component<props, {}> {
    public render() {
        return (
            <Wrapper>
                <i
                    className={this.props.name}
                    style={{
                        margin: 'auto',
                        // color: 'green',
                        color: this.props.completed ? '#fff' : '#333',
                        fontSize: '2.2rem',
                        transition: 'opacity 2s linear, color 2s linear'
                    }}
                />
            </Wrapper>
        );
    }
}

const Wrapper = styled.div`
    width: 4rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    font-size: 2.4rem;
`;
