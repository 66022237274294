/* eslint-disable */
import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { toast } from 'react-toastify';

import NotificationApi from '../../api/notifications/Notifications';
import SimpleTooltip from '../ui-components/SimpleTooltip';

interface ContactNudgeProps {
    contactId: string;
    style?: React.CSSProperties;
    hideLabel?: boolean;
    class?: string;
    onClick?: () => void;
}

class ContactNudgeButton extends React.Component<ContactNudgeProps> {
    render() {
        return (
            <SimpleTooltip
                id="nudge-contact-tt"
                text={'Send a nudge to this contact to prompt them to update their availability'}
            >
                <div
                    className={
                        'btn-nudge btn btn-primary btn-sm ' +
                        (this.props.class || '')
                    }
                    style={this.props.style}
                    onClick={this.sendNudge}
                >
                    <i className="fa fa-hand-point-right m-r-xs" />
                    {!this.props.hideLabel && (
                        <span>Nudge</span>
                    )}
                    {/* {NudgeInfo} */}
                </div>
            </SimpleTooltip>
        );
    }

    sendNudge = () => {
        if (window.location.pathname.startsWith("/external")) {
            if (this.props.onClick)
                this.props.onClick();
        } else {
            NotificationApi.nudgeContact(this.props.contactId).then((sent) => {
                if (sent) {
                    toast.success('Contact has been nudged');
                } else {
                    toast.warn('This contact has already been nudged recently');
                }
            });
        }
    };
}

const nudgePopover = (
    <Popover
        id="nudge-info-popover"
        style={{ position: 'relative', left: '14px' }}
    >
        A <strong>Nudge</strong> asks your contact to check and confirm their
        latest availability.
        <br /> <br />
        <small>
            You can only nudge each of your contacts twice in any 24 hour
            period.
        </small>
    </Popover>
);

export const NudgeInfo = (
    <OverlayTrigger placement="top" overlay={nudgePopover}>
        <i
            className="fa fa-question-circle hang-right"
            style={{ marginLeft: '5px' }}
        />
    </OverlayTrigger>
);

export default ContactNudgeButton;
