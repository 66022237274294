/* eslint-disable */
import React from 'react';
import { RouteComponentProps } from 'react-router';

export class MobileLoggedOut extends React.Component<
    RouteComponentProps<{}>,
    {}
> {
    componentDidMount(): void {
        setTimeout(() => {
            window.close();
        }, 100);
    }
    public render() {
        return (
            <div>
                <div
                    className="panel panel-default"
                    style={{ marginTop: '30px' }}
                >
                    <div className="panel-body">
                        <div className="text-center">
                            <h2 style={{ color: 'black' }}>You have been logged out</h2>
                            {/* <p style={{ color: 'black' }}>Close this window to log back in again</p> */}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
