/* eslint-disable */
import React from 'react';
import styled from 'styled-components';
import { Table, Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { toast } from 'react-toastify';

import VerificationApi from '../../api/verification/Verification';
import CompanyApi from '../../api/company/Company';
import { UserVerificationPendingResult } from '../../api/verification/ResponseTypes';
import { AsyncOverlay } from '../../components/ui-components/AsyncOverlay';
import { CompanyRoles } from '../../api/company/ResponseTypes';
import { ProfileImage } from '../../components/ui-components/ProfileImage';
import ProfileApi from '../../api/profile/Profile';
import { UserType } from '../../state-containers/user-type.container';
import theme from '../../css/theme';

interface State {
    loading: boolean;
    results: UserVerificationPendingResult['users'];
    search: string;
    userTypeId: string,
    currentPage: number,
    actionTakenSinceLoad: boolean
}

export default class Moderation extends React.Component<{}, State> {
    state = {
        loading: true,
        results: [],
        search: '',
        userTypeId: '0',
        currentPage: 0,
        actionTakenSinceLoad: false
    } as State;

    loadMore(overwrite?: boolean) {
        this.setState({
            loading: true
        }, () => { 
            VerificationApi.unverifiedPending(overwrite ? 0 : this.state.currentPage+1, parseInt(this.state.userTypeId)).then((results) => {
                this.setState({
                    results: overwrite ? results.users : this.state.results.concat(results.users),
                    loading: false,
                    currentPage: overwrite ? 0 : this.state.currentPage + 1,
                    actionTakenSinceLoad: false
                });
            });
        })
    }

    componentDidMount() {
        this.loadMore(true);
    }

    render() {
        return (
            <Wrapper>
                <h3>Moderation</h3>
                <div style={{
                    marginBottom: 20
                }}>
                    <button onClick={() => { this.componentDidMount(); }}>Reload Data</button>
                    <select
                        value={this.state.userTypeId}
                        onChange={(ev) =>
                            this.setState({ userTypeId: ev.target.value }, () => {
                                this.loadMore(true);
                            })
                        }
                    >
                        { [
                            {
                                id: '0',
                                label: "All Users"
                            },
                            {
                                id: '1',
                                label: "Worker"
                            },
                            {
                                id: '2',
                                label: "Hirer",
                            },
                            {
                                id: '3',
                                label: "Agency"
                            }
                        ].map(item => {
                            return (
                                <option value={item.id}>{item.label}</option>
                            )
                        }) }
                    </select>
                </div>
                {/* <div className="layout horizontal end-justified">
                    <input
                        className="form-control m-b-xs"
                        placeholder="Search..."
                        value={this.state.search}
                        onChange={(ev) =>
                            this.setState({ search: ev.target.value })
                        }
                    />
                </div> */}
                <Table condensed striped responsive bordered>
                    <thead>
                        <tr>
                            <th>User</th>
                            <th>Sharing With</th>
                            <th>Invited By</th>
                            <th>Options</th>
                        </tr>
                    </thead>
                    {this.renderResults(this.state.results)}
                    { (this.state.actionTakenSinceLoad) ?
                        <button onClick={() => { this.loadMore(true) }}>Reload Data</button>
                        :
                        <button onClick={() => { this.loadMore() }}>Load More Data</button>
                    }
                </Table>
                <AsyncOverlay show={this.state.loading} />
            </Wrapper>
        );
    }

    updateVerificationStatus(userId, status) {
        VerificationApi.updateVerificationStatus(userId, status);
        let results = this.state.results.filter(item => item.userId !== userId);
        this.setState({ 
            results: results,
            actionTakenSinceLoad: true
        });
    }

    renderResults(results) {
        if (results) {
            return (
                <tbody>
                    { results.map(item => {
                        return (
                            <tr>
                                <td>
                                    <div className="profile">
                                        <ProfileImage
                                            selectable={false}
                                            url={ProfileApi.getProfileImageUrl(item.userId)}
                                            size={80}
                                            type="Profile"
                                            style={{
                                                padding: 3,
                                                margin: 0,
                                                border: "none",
                                                background: "none"
                                            }}
                                        />
                                        <div>
                                            <h2>{item.firstName} {item.lastName}</h2>
                                            <p>{item.headline}</p>
                                            <p>{item.verified ? "Verified" : "Unverified"}</p>
                                            <p>{item.email}</p>
                                            <p>{item.userTypeId === UserType.Agency ? "Agency" : item.userTypeId === UserType.Hirer ? "Hirer" : "Worker"}</p>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="share-with">
                                        { item.sharingWith.map(sw => {
                                            if (sw.organisationId && sw.organisationName)
                                                return (
                                                    <div>
                                                        <ProfileImage
                                                            selectable={false}
                                                            url={CompanyApi.getOrganisationProfileImageUrl(sw.organisationId)}
                                                            size={32}
                                                            type="Profile"
                                                            style={{
                                                                padding: 3,
                                                                margin: 0,
                                                                border: "none",
                                                                background: "none"
                                                            }}
                                                        />
                                                        <h2>{sw.organisationName}</h2>
                                                    </div>
                                                )
                                            return null;
                                        }) }
                                    </div>
                                </td>
                                <td>
                                    <div className="share-with invited-by">
                                        { item.invitedBy.map(sw => {
                                            if (sw.organisationId && sw.organisationName)
                                                return (
                                                    <div>
                                                        <ProfileImage
                                                            selectable={false}
                                                            url={CompanyApi.getOrganisationProfileImageUrl(sw.organisationId)}
                                                            size={32}
                                                            type="Profile"
                                                            style={{
                                                                padding: 3,
                                                                margin: 0,
                                                                border: "none",
                                                                background: "none"
                                                            }}
                                                        />
                                                        <h2>{sw.organisationName}</h2>
                                                    </div>
                                                )
                                            return null;
                                        }) }
                                    </div>
                                </td>
                                <td className="options">
                                    <button 
                                        className="option-btn"
                                        onClick={() => {
                                            this.updateVerificationStatus(item.userId, true)
                                        }}
                                        style={{ background: '#28df99' }}
                                    >
                                        <i className="fas fa-thumbs-up"></i>
                                        Approve
                                    </button>
                                    <button
                                        className="option-btn"
                                        onClick={() => {
                                            this.updateVerificationStatus(item.userId, false)
                                        }}
                                        style={{ background: '#DD2E1B' }}
                                    >
                                        <i className="fas fa-thumbs-down"></i>
                                        Unapprove
                                    </button>
                                </td>
                            </tr>
                        )
                    }) }
                </tbody>
            )
        }
        return null;
    }

}

const Wrapper = styled.div`

    button:not(.option-btn) {
        background: ${theme.colours.blue2};
        border: none;
        padding: 12px;
        marginBottom: 20px;
        color: white;
    }

    select {
        padding: 12px;
        margin-left: 20px;
    }
 
    .profile {
        display: flex;
        align-items: center;
        padding: 10px 6px;

        div {
            margin: 0;
            font-size: 1.2em;
            padding-left: 12px;

            h2 {
                font-size: 16px;
                font-weight: 700;
                margin: 0 0 6px 0;
            }

            p {
                font-size: 12px;
                margin: 0 0 4px 0;
            }
        }
    }

    .share-with {

        & > div {
            display: flex;
            margin-bottom: 2px;
            align-items: center;

            h2 {
                margin: 0;
                padding-left: 4px;
                font-size: 16px;
                font-weight: 700;
            }
        }

    }

    .options {

        button {
            display: block;
            width: 100%;
            padding: 16px 10px;
            color: white;
            border: none;

            i {
                margin-right: 6px;
            }
        }

    }

`;
