import React from 'react';
import styled from 'styled-components';

interface Props {
    checked: boolean;
    pulse?: boolean;
}

export default class Circle extends React.Component<Props, {}> {
    public render() {
        return (
            <Border
                className={this.props.pulse ? 'pulse' : ''}
                style={{
                    background: this.props.checked ? '#28DF99' : '#EBEBEB'
                }}
            >
                {/* {this.props.children && this.props.children![0]}
                {
                    <i
                        className={
                            this.props.checked
                                ? 'fas fa-check'
                                : 'fas fa-circle'
                        }
                        style={{
                            margin: 'auto',
                            color: this.props.checked ? 'Green' : 'firebrick',
                            transition: 'opacity 2s linear, color 2s linear'
                        }}
                    />
                } */}
                {this.props.children}
            </Border>
        );
    }
}

const Border = styled.div`
    background-color: #ebebeb;
    border-radius: 100%;
    width: 56px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    z-index: 10;
    border: none;

    &:hover {
        transform: scale(1.2);
        //filter: drop-shadow(0 0 0.25rem rgba(106, 108, 111, 0.35));
        z-index: 1000;
    }
    transition: transform 0.25s linear, filter 0.25s linear, color 0.25s linear;
`;
