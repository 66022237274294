import React from 'react';
import { RouteComponentProps } from 'react-router';

import IncomingInvitations from '../../components/invitations/IncomingInvitations';

class Invitations extends React.Component<RouteComponentProps<{}>, {}> {
    public render() {
        return (
            <div>
                <IncomingInvitations />
            </div>
        );
    }
}

export default Invitations;
