/* eslint-disable */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import moment from 'moment';
import { debounce } from 'ts-debounce';
import { History } from 'history';
import history from '../../history';
import DemoResDetails from '../../demo/offerdetails.json'

import { DateFormats, formatLocationName } from '../../constants';
import theme from '../../css/theme';
import {
    EnrichedFavouriteOffer,
    OfferListTab,
    Offers,
    OfferStatus,
    OfferAgencyStatus,
    OfferListWithPagingParams,
    FavoriteOfferListWithPagingParams
} from '../../pages/Offers';
import {
    OfferConfirmationResponseTypes,
    OfferInfoDto,
    OfferResponseTypes,
    OfferAgencyResponsesType
} from '../../api/offers/ResponseTypes';
import {
    OFFER_MODAL_BREAKPOINT,
    OFFER_MODAL_BREAKPOINT_VALUE
} from './breakpoints';
import { Spinner } from '../ui-components/Spinner';
import SimpleTooltip from '../ui-components/SimpleTooltip';
import Tag from '../ui-components/Tag';
import Utilities from '../../Utilities';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css';
import ProfileAPI from '../../api/profile/Profile';
import CompanyApi from '../../api/company/Company';
import OrganisationApi from '../../api/organisation/Organisation';

import {
    getOfferStatus,
    getOfferWorkersByConfirmationType,
    getOfferWorkersByResponseType,
    withdrawOffer
} from '../../pages/OffersUtils';
import Dialog from '../ui-components/Dialog';

type Props = {
    offersPerPage: number;
    offers: OfferListWithPagingParams | FavoriteOfferListWithPagingParams;
    tabConfig: ReturnType<Offers['getTabConfig']>;
    selectedTab: OfferListTab;
    startInstantOffer: (event: React.MouseEvent<Button, MouseEvent>) => void;
    selectOffer: Offers['selectOffer'];
    reloadList: Offers['reload'];
    selectedOffer: OfferInfoDto | EnrichedFavouriteOffer | null;
    windowWidth: number;
    isOfferToday: Offers['isOfferToday'];
    handleViewCancel: Offers['handleViewCancel'];
    startChat: Offers['startChat'];
    handleDeleteFavouriteOffer: Offers['handleDeleteFavouriteOffer'],
    handleDeleteOffer: Offers['handleDeleteOffer'],
    handleKeywordFilterChange: Offers['handleKeywordFilterChange'],
    loadMore: Offers['loadMore'];
    showingDemoRes: boolean;
    staleData: boolean;
};

const OfferList = ({
    offersPerPage,
    selectedTab,
    offers,
    tabConfig,
    startInstantOffer,
    selectOffer,
    selectedOffer,
    windowWidth,
    isOfferToday,
    handleViewCancel,
    startChat,
    handleDeleteFavouriteOffer,
    handleDeleteOffer,
    loadMore,
    handleKeywordFilterChange,
    showingDemoRes,
    reloadList,
    staleData
}: Props) => {
    const [search, setSearchValue] = useState('');
    const [hoveredDay, setHoveredDay] = useState(Utilities.dateSub(new Date(), "month", 2));
    const [displayStartDate, setDisplayStartDate] = useState(new Date());

    useEffect(() => {
        setSearchValue('');
    }, [selectedTab]);

    const withdrawThisOffer = (offer: OfferInfoDto) => {
        withdrawOffer(offer);
    }

    useEffect(() => {
        handleKeywordFilterChange(search);
    }, [search]);

    const renderOffers = (
        offers: OfferListWithPagingParams,
        offersTitle: string,
        offersKey: OfferListTab,
        offersLoading: boolean,
        color: string,
        icon: string
    ) => {
        if (showingDemoRes && offersKey === OfferListTab.Favourite) {
            return null;
        }

        const isSearchResult = (o) =>
            !search ||
            o.title.toLowerCase().indexOf(search.toLowerCase()) >= 0 ||
            (o.description &&
                o.description.toLowerCase().indexOf(search.toLowerCase()) >=
                    0)

        const DATES_TO_SHOW = 30;
        let datesToDisplay = [displayStartDate];
        for (let i = 0; i < DATES_TO_SHOW; i++) {
            datesToDisplay.push(Utilities.dateAdd(new Date(datesToDisplay[datesToDisplay.length-1]), "day", 1));
        }

        return (
            <div
                style={{
                    background: color,
                    marginBottom: 32,
                    borderRadius: 12
                }}
            >
                <OfferListHeader>
                    <h2 
                        onClick={() => {
                            history.push('/offers#' + offersKey)
                        }}
                        /* @ts-ignore */
                        name={offersKey}
                    >
                        {offersKey !== OfferListTab.Favourite && (
                            <div className='agency-indicator'>
                                <i className="fas fa-building"></i>
                                <span>Agencies</span>
                            </div>
                        )}
                        {offersTitle}
                        { (offers.totalRows || offers.totalRows === 0) &&
                            <span>{offers.totalRows}</span>
                        }
                        {offersKey !== OfferListTab.Favourite && (
                        <div className='worker-indicator'>
                            <i className="fas fa-user"></i>
                            <span>Workers</span>
                        </div>
                        )}
                    </h2>
                </OfferListHeader>
                { (!offersLoading && !showingDemoRes && offers.data.length === 0) &&
                    <div className='offer-list'>
                        <div className='no-results'>
                            You have no{' ' + offersTitle}{search && (' matching ' + search)}
                        </div>
                    </div>
                }
                { (offers.data) &&
                    <div className='offer-list'>
                        { offers.data.map((o) => {
                                let statusCounts = {
                                    yes: 0,
                                    no: 0,
                                    pending: 0,
                                    confirmed: 0
                                }

                                let noLongerConfirmedContacts = o.recipients.filter(r => r.confirmation == OfferConfirmationResponseTypes.Confirmed && r.eventStates && r.eventStates.length > 0 && r.eventStates.some((es) => es.deleted));
                                let confirmedContacts = o.recipients.filter(r => r.confirmation === OfferConfirmationResponseTypes.Confirmed);
                                let appliedContacts = o.recipients.filter(r => r.response === OfferResponseTypes.Yes);
                                let pendingContacts = o.recipients.filter(r => r.response === OfferResponseTypes.Pending);
                                let rejectedContacts = o.recipients.filter(r => r.response === OfferResponseTypes.No);

                                let notyetSentAgencies = o.agencyResponses ? o.agencyResponses.filter(r => r.emailSent === false) : [];
                                let pendingAgencies = o.agencyResponses ? o.agencyResponses.filter(r => r.emailSent === true && r.responseType === OfferAgencyResponsesType.NoResponse) : [];
                                let acceptedAgencies = o.agencyResponses ? o.agencyResponses.filter(r => r.responseType === OfferAgencyResponsesType.Yes) : [];
                                let declinedAgencies = o.agencyResponses ? o.agencyResponses.filter(r => r.responseType === OfferAgencyResponsesType.No) : [];

                                const AGENCIES_TOOLTIP_DOM = 
                                    <div className='agency-replies-tooltip-wrapper'>
                                        { [
                                            { agencies: notyetSentAgencies, label: o.agencyResponses && o.agencyResponses.some(ar => ar.cancelled) ? "Not Needed" :  "Not Yet Sent" },
                                            { agencies: pendingAgencies, label: "Pending" },
                                            { agencies: declinedAgencies, label: "Unable to Help" },
                                            { agencies: acceptedAgencies, label: "Helping" } 
                                        ].map((agencies, i) => {
                                            return (
                                                <div className='agency-group'>
                                                    <label>{agencies.label}</label>
                                                    { agencies.agencies.map((agency, i) => {
                                                        return (
                                                            <div className='agency'>
                                                                <img src={agency.organisationId ? CompanyApi.getOrganisationProfileImageUrl(agency.organisationId) : agency.organisationDomain ? OrganisationApi.getExtOrgPicture(agency.organisationDomain) : ""} />
                                                                <span>
                                                                    {agency.userContactName} from {agency.organisationName}
                                                                    { (agencies.label === "Not Yet Sent" && !agency.emailSent && agency.scheduledAt) &&
                                                                        <span>Sending in {Utilities.toHHMM(Utilities.secondsDifferenceBetweenTwoDates(Utilities.dateAdd(new Date(agency.scheduledAt || ""), "minute", 1), new Date()))}</span>
                                                                    }
                                                                </span>
                                                            </div>
                                                        )
                                                    }) }
                                                </div>
                                            )
                                        }) }
                                    </div>

                                for (let i = 0; i < o.recipients.length; i++) {
                                    const recipient = o.recipients[i];
                                    statusCounts[recipient.confirmation == OfferConfirmationResponseTypes.Confirmed && recipient.eventStates && recipient.eventStates.length > 0 && recipient.eventStates.some((es) => es.deleted) ? "no" : recipient.confirmation === OfferConfirmationResponseTypes.Confirmed ? "confirmed" : recipient.response === OfferResponseTypes.Pending && recipient.confirmation === 0 ? 'pending' : recipient.response === OfferResponseTypes.Yes ? 'yes' : 'no']++;
                                }

                                const favoriteContacts = offersKey === OfferListTab.Favourite ? o.recipients : [];
                                // @ts-ignore
                                const favoriteTimeStr = offersKey === OfferListTab.Favourite ? o.startTime + " - " + o.endTime : [];
                      
                                return (
                                    <OfferRow
                                        key={`offer-row-item-${o.id}`}
                                        className={`${
                                            selectedOffer === o ? 'selected' : ''
                                        }`}
                                        onClick={() => {
                                            if (showingDemoRes) {
                                                // @ts-ignore
                                                selectOffer(DemoResDetails);
                                            } else {
                                                selectOffer(o)   
                                            }
                                        }}
                                        onMouseEnter={() => {
                                            if (document.documentElement.clientWidth < 580) {
                                                if (showingDemoRes) {
                                                    // @ts-ignore
                                                    selectOffer(DemoResDetails);
                                                } else {
                                                    selectOffer(o)   
                                                }
                                            } else {
                                                setDisplayStartDate(new Date(o.events ? o.events[0].start : new Date()))
                                            }
                                        }}
                                        data-favorite={offersKey === OfferListTab.Favourite ? "true" : "false"}
                                    > 
                                        <div className="">
                                            <div style={!o.events ? { padding: 0 } : {}}>
                                            <div className="details">
                                                {(() => {

                                                    const hasChanges =
                                                        o.recipients.filter((r) => {
                                                            return (
                                                                r.confirmation ==
                                                                    OfferConfirmationResponseTypes.Confirmed &&
                                                                r.eventStates &&
                                                                r.eventStates.length > 0 &&
                                                                r.eventStates.filter((es) => es.deleted)
                                                                    .length > 0
                                                            );
                                                        }).length > 0;

                                                    const config = getOfferStatusIndicatorConfig(
                                                        o,
                                                        hasChanges,
                                                        false
                                                    );

                                                    if (!config) 
                                                        return null;

                                                    if (offersTitle === "Favourites") {
                                                        return null;
                                                    }

                                                    return (
                                                        <>
                                                            <SimpleTooltip
                                                                id={`offer-status-${o.id}`}
                                                                text={config.tooltip}
                                                            >
                                                                { (staleData) ?
                                                                    <Skeleton count={1} />
                                                                    :
                                                                    <Tag background={config.color} className="tag">
                                                                        {config.label}
                                                                    </Tag>
                                                                }
                                                            </SimpleTooltip>
                                                        </>
                                                    );

                                                })()}
                                                <div className='meta'>
                                                    <i 
                                                        className="fa fa-trash" 
                                                        onClick={async (e) => {
                                                            e.stopPropagation();

                                                            if (!o.events) {
                                                                handleDeleteFavouriteOffer(o.id);
                                                            } else {
                                                                if (!o.isHistoric && !o.withdrawn) {
                                                                    if (o.complete) {
                                                                        if (!window.confirm("This offer is completed, but not yet withdrawn, to delete it confirm that you want to withdraw it by clicking on the 'OK' option")) {
                                                                            return;
                                                                        }
                                                                    }
                                                                    await withdrawThisOffer(o);
                                                                }
                                                                handleDeleteOffer(o.id)
                                                            }
                                                        }}
                                                    />
                                                    <div className="offer-title">
                                                        {o.title}
                                                        { (showingDemoRes) &&
                                                            <span style={{ position: 'relative', top: -2, marginLeft: 8, fontSize: 12, color: "white", borderRadius: 4, background: theme.colours.blue2, padding: '0px 5px 2px 5px' }}>
                                                                SAMPLE OFFER
                                                            </span>
                                                        }
                                                    </div>
                                                    { o.events &&
                                                        <div className="offer-date">
                                                            {Utilities.formatDate(
                                                                new Date(o.events[0].start),
                                                                'ds DD MMM YYYY'
                                                            )}{' '}
                                                            {Utilities.formatDate(
                                                                new Date(o.events[0].start),
                                                                'HH:MM'
                                                            )}
                                                            {' - '}
                                                            {Utilities.formatDate(
                                                                new Date(o.events[0].end),
                                                                'HH:MM'
                                                            )}{' '}
                                                            {o.events.length > 1 && (
                                                                <span
                                                                    style={{
                                                                        marginLeft: '5px',
                                                                        fontSize: '11px'
                                                                    }}
                                                                >
                                                                    {/* (
                                                                    <span style={{ letterSpacing: '-.7px' }}>
                                                                        + {o.events.length - 1} m
                                                                    </span>
                                                                    ore), ending on{' '} */}
                                                                    {Utilities.formatDate(
                                                                        new Date(
                                                                            o.events[o.events.length - 1].start
                                                                        ),
                                                                        'DD MMM YYYY'
                                                                    )}
                                                                </span>
                                                            )}
                                                        </div>
                                                    }
                                                    {(o.locationPlaceName && parseInt(localStorage.getItem("locationCount") || "2") > 1) && (
                                                        <div className="offer-date">
                                                            {formatLocationName({
                                                                locationFriendlyName:
                                                                    o.locationFriendlyName,
                                                                locationPlaceName: 
                                                                    o.locationFriendlyAddress ||
                                                                    o.locationPlaceName ||
                                                                    ""
                                                            })}
                                                        </div>
                                                    )}
                                                    { (favoriteTimeStr) &&
                                                        <div className='offer-date'>
                                                            {favoriteTimeStr}
                                                        </div>
                                                    }
                                                    { (confirmedContacts.length !== 0 && noLongerConfirmedContacts.length === 0) &&
                                                        <div className='confirmed-contacts'>
                                                            { confirmedContacts.map((recipient, i) => {
                                                                if (i !== 0 || !recipient.recipientName) return null
                                                                return (
                                                                    <div className='confirmed-contact'>
                                                                        <img data-report-blurred={recipient.reported} data-user-id={recipient.id} src={ProfileAPI.getProfileImageUrl(recipient.recipientUserId)} />
                                                                        <span data-report-blurred={recipient.reported} data-user-id={recipient.id} data-visible={i === 0}>
                                                                            {Utilities.shortenFullName(recipient.recipientName.replace(" (Demo)", ""))}
                                                                        </span>
                                                                        { confirmedContacts.length > 1 &&
                                                                            <label className='more'>
                                                                            
                                                                                +{confirmedContacts.length - 1}
                                                                            </label>
                                                                        }
                                                                    </div>
                                                                )
                                                            }) }
                                                        </div>
                                                    }
                                                    { (noLongerConfirmedContacts.length !== 0) &&
                                                        <div className='no-longer-confirmed-contacts'>
                                                            { noLongerConfirmedContacts.map((recipient, i) => {
                                                                if (i !== 0 || !recipient.recipientName) return null
                                                                return (
                                                                    <div className='no-longer-confirmed-contact'>
                                                                        <img data-report-blurred={recipient.reported} data-user-id={recipient.id} src={ProfileAPI.getProfileImageUrl(recipient.recipientUserId)} />
                                                                        <span data-report-blurred={recipient.reported} data-user-id={recipient.id} data-visible={i === 0}>
                                                                            {Utilities.shortenFullName(recipient.recipientName.replace(" (Demo)", ""))}
                                                                        </span>
                                                                        { noLongerConfirmedContacts.length > 1 &&
                                                                            <label className='more'>
                                                                            
                                                                                +{noLongerConfirmedContacts.length - 1}
                                                                            </label>
                                                                        }
                                                                    </div>
                                                                )
                                                            }) }
                                                        </div>
                                                    }
                                                    { (favoriteContacts.length !== 0) &&
                                                        <div className='applied-contacts'>
                                                            { favoriteContacts.map((recipient, i) => {
                                                                if (i !== 0 || !recipient.recipientName) return null
                                                                return (
                                                                    <div className='confirmed-contact'>
                                                                        <img data-report-blurred={recipient.reported} data-user-id={recipient.id} src={ProfileAPI.getProfileImageUrl(recipient.recipientUserId)} />
                                                                        <span data-report-blurred={recipient.reported} data-user-id={recipient.id} data-visible={i === 0}>
                                                                            {Utilities.shortenFullName(recipient.recipientName.replace(" (Demo)", ""))}
                                                                        </span>
                                                                        { favoriteContacts.length > 1 &&
                                                                            <label className='more'>
                                                                            
                                                                                +{favoriteContacts.length - 1}
                                                                            </label>
                                                                        }
                                                                    </div>
                                                                )
                                                            }) }
                                                        </div>
                                                    }

                                                    { (appliedContacts.length !== 0 && confirmedContacts.length === 0 && noLongerConfirmedContacts.length === 0) &&
                                                        <div className='applied-contacts'>
                                                            { appliedContacts.map((recipient, i) => {
                                                                if (i !== 0 || !recipient.recipientName) return null
                                                                return (
                                                                    <div className='confirmed-contact'>
                                                                        <img data-report-blurred={recipient.reported} data-user-id={recipient.id} src={ProfileAPI.getProfileImageUrl(recipient.recipientUserId)} />
                                                                        <span data-report-blurred={recipient.reported} data-user-id={recipient.id} data-visible={i === 0}>
                                                                            {Utilities.shortenFullName(recipient.recipientName.replace(" (Demo)", ""))}
                                                                        </span>
                                                                        { appliedContacts.length > 1 &&
                                                                            <label className='more'>
                                                                            
                                                                                +{appliedContacts.length - 1}
                                                                            </label>
                                                                        }
                                                                    </div>
                                                                )
                                                            }) }
                                                        </div>
                                                    }

                                                    { (pendingContacts.length !== 0 && confirmedContacts.length === 0 && appliedContacts.length === 0 && noLongerConfirmedContacts.length === 0) &&
                                                        <div className='pending-contacts'>
                                                            { pendingContacts.map((recipient, i) => {
                                                                if (i !== 0 || !recipient.recipientName) return null
                                                                return (
                                                                    <div className='confirmed-contact'>
                                                                        <img data-report-blurred={recipient.reported} data-user-id={recipient.id} src={ProfileAPI.getProfileImageUrl(recipient.recipientUserId)} />
                                                                        <span data-report-blurred={recipient.reported} data-user-id={recipient.id} data-visible={i === 0}>
                                                                            {Utilities.shortenFullName(recipient.recipientName.replace(" (Demo)", ""))}
                                                                        </span>
                                                                        { pendingContacts.length > 1 &&
                                                                            <label className='more'>
                                                                            
                                                                                +{pendingContacts.length - 1}
                                                                            </label>
                                                                        }
                                                                    </div>
                                                                )
                                                            }) }
                                                        </div>
                                                    }

                                                    { (rejectedContacts.length !== 0 && confirmedContacts.length === 0 && appliedContacts.length === 0 && pendingContacts.length === 0 && noLongerConfirmedContacts.length === 0) &&
                                                        <div className='rejected-contacts'>
                                                            { rejectedContacts.map((recipient, i) => {
                                                                if (i !== 0 || !recipient.recipientName) return null
                                                                return (
                                                                    <div className='confirmed-contact'>
                                                                        <img data-report-blurred={recipient.reported} data-user-id={recipient.id} src={ProfileAPI.getProfileImageUrl(recipient.recipientUserId)} />
                                                                        <span data-report-blurred={recipient.reported} data-user-id={recipient.id} data-visible={i === 0}>
                                                                            {Utilities.shortenFullName(recipient.recipientName.replace(" (Demo)", ""))}
                                                                        </span>
                                                                        { rejectedContacts.length > 1 &&
                                                                            <label className='more'>
                                                                            
                                                                                +{rejectedContacts.length - 1}
                                                                            </label>
                                                                        }
                                                                    </div>
                                                                )
                                                            }) }
                                                        </div>
                                                    }

                                                    { (notyetSentAgencies.length !== 0 && pendingAgencies.length === 0 && acceptedAgencies.length === 0 && declinedAgencies.length === 0) &&
                                                        <div className='notyetsent-agencies'>
                                                            { notyetSentAgencies.map((r, i) => {
                                                                if (i !== 0 || !r.userContactName) return null
                                                                return (
                                                                    <SimpleTooltip id="notyetesnt-agencies" text={AGENCIES_TOOLTIP_DOM}>
                                                                        <div className='notyetsent-agency'>
                                                                            <img src={r.organisationId ? CompanyApi.getOrganisationProfileImageUrl(r.organisationId) : r.organisationDomain ? OrganisationApi.getExtOrgPicture(r.organisationDomain) : ""} />
                                                                            <span data-visible={i === 0}>
                                                                                {Utilities.shortenFullName(r.userContactName.replace(" (Demo)", ""))}
                                                                            </span>
                                                                            { notyetSentAgencies.length > 1 &&
                                                                                <label className='more'>
                                                                                    +{notyetSentAgencies.length - 1}
                                                                                </label>
                                                                            }
                                                                        </div>
                                                                    </SimpleTooltip>
                                                                )
                                                            }) }
                                                        </div>
                                                    }

                                                    { (pendingAgencies.length !== 0 && acceptedAgencies.length === 0 && declinedAgencies.length === 0) &&
                                                        <div className='pending-agencies'>
                                                            { pendingAgencies.map((r, i) => {
                                                                if (i !== 0 || !r.userContactName) return null
                                                                return (
                                                                    <SimpleTooltip id="pending-agencies" text={AGENCIES_TOOLTIP_DOM}>
                                                                        <div className='pending-agency'>
                                                                            <img src={r.organisationId ? CompanyApi.getOrganisationProfileImageUrl(r.organisationId) : r.organisationDomain ? OrganisationApi.getExtOrgPicture(r.organisationDomain) : ""} />
                                                                            <span data-visible={i === 0}>
                                                                                {Utilities.shortenFullName(r.userContactName.replace(" (Demo)", ""))}
                                                                            </span>
                                                                            { pendingAgencies.length > 1 &&
                                                                                <label className='more'>
                                                                                    +{pendingAgencies.length - 1}
                                                                                </label>
                                                                            }
                                                                        </div>
                                                                    </SimpleTooltip>
                                                                )
                                                            }) }
                                                        </div>
                                                    }

                                                    { (declinedAgencies.length !== 0 && acceptedAgencies.length === 0) &&
                                                        <div className='no-agencies'>
                                                            { declinedAgencies.map((r, i) => {
                                                                if (i !== 0 || !r.userContactName) return null
                                                                return (
                                                                    <SimpleTooltip id="no-agencies" text={AGENCIES_TOOLTIP_DOM}>
                                                                        <div className='no-agency'>
                                                                            <img src={r.organisationId ? CompanyApi.getOrganisationProfileImageUrl(r.organisationId) : r.organisationDomain ? OrganisationApi.getExtOrgPicture(r.organisationDomain) : ""} />
                                                                            <span data-visible={i === 0}>
                                                                                {Utilities.shortenFullName(r.userContactName.replace(" (Demo)", ""))}
                                                                            </span>
                                                                            { declinedAgencies.length > 1 &&
                                                                                <label className='more'>
                                                                                    +{declinedAgencies.length - 1}
                                                                                </label>
                                                                            }
                                                                        </div>
                                                                    </SimpleTooltip>
                                                                )
                                                            }) }
                                                        </div>
                                                    }

                                                    { (acceptedAgencies.length !== 0) &&
                                                        <div className='yes-agencies'>
                                                            { acceptedAgencies.map((r, i) => {
                                                                if (i !== 0 || !r.userContactName) return null
                                                                return (
                                                                    <SimpleTooltip id="yes-agencies" text={AGENCIES_TOOLTIP_DOM}>
                                                                        <div className='yes-agency'>
                                                                            <img src={r.organisationId ? CompanyApi.getOrganisationProfileImageUrl(r.organisationId) : r.organisationDomain ? OrganisationApi.getExtOrgPicture(r.organisationDomain) : ""} />
                                                                            <span data-visible={i === 0}>
                                                                                {Utilities.shortenFullName(r.userContactName.replace(" (Demo)", ""))}
                                                                            </span>
                                                                            { acceptedAgencies.length > 1 &&
                                                                                <label className='more'>
                                                                                    +{acceptedAgencies.length - 1}
                                                                                </label>
                                                                            }
                                                                        </div>
                                                                    </SimpleTooltip>
                                                                )
                                                            }) }
                                                        </div>
                                                    }
                                                </div>
                                            </div>

                                            { offersKey !== OfferListTab.Favourite ?
                                                <div 
                                                    className="responses recipient-responses"
                                                    style={{
                                                        visibility: o.recipients.length > 0 ? "visible" : "hidden"
                                                    }}
                                                >
                                                    { ["pending", "no", "yes", "confirmed"].map(status => {
                                                        const statusCount = statusCounts[status];
                                                        if (staleData) {
                                                            return (
                                                                <div className='recipient recipient-response' style={{ marginRight: 12 }}>
                                                                    <Skeleton count={1} />
                                                                </div>
                                                            )
                                                        }
                                                        return (
                                                            <SimpleTooltip
                                                                id={`offer-status-${o.id}`}
                                                                text={
                                                                    <div className='offer-states'>
                                                                        <label>
                                                                            {
                                                                                status === "confirmed" ? (statusCount + " contact(s) confirmed") :
                                                                                status === "yes" ? (statusCount + " contact(s) applied") : 
                                                                                status === "no" ? (statusCount + " contact(s) rejected or have been withdrawn") : 
                                                                                ( o.complete || o.withdrawn ? (statusCount + " contact(s) have been informed as unsuccessful") : (statusCount + " contact(s) have not yet responded"))
                                                                            }
                                                                        </label>
                                                                        <div className='contact-list'>
                                                                            { o.recipients.filter(r => {
                                                                                const confirmedDeleted = r.confirmation == OfferConfirmationResponseTypes.Confirmed && r.eventStates && r.eventStates.length > 0 && r.eventStates.some((es) => es.deleted);
                                                                                return (
                                                                                    confirmedDeleted && status === "no") ||
                                                                                    (r.confirmation === OfferConfirmationResponseTypes.Confirmed && status === "confirmed") ||
                                                                                    (r.response === (
                                                                                        (status === "yes" && !confirmedDeleted) ? OfferResponseTypes.Yes :
                                                                                        (status === "no" || confirmedDeleted) ? OfferResponseTypes.No : 
                                                                                        status === "pending" ?  OfferResponseTypes.Pending : 
                                                                                        "unknown"
                                                                                    )
                                                                                )
                                                                            }).map((recipient, i) => {
                                                                                return (
                                                                                    <div className='contact'>
                                                                                        <img data-report-blurred={recipient.reported} data-user-id={recipient.id} src={ProfileAPI.getProfileImageUrl(recipient.recipientUserId)} />
                                                                                        <p data-report-blurred={recipient.reported} data-user-id={recipient.id} >{recipient.recipientName}</p>
                                                                                    </div>
                                                                                )
                                                                            }) }
                                                                        </div>
                                                                    </div>
                                                                }
                                                            >
                                                                <div className="recipient recipient-response" data-status={status === "confirmed" ? "3" : status === "pending" ? 0 : status === "yes" ? "1": "2"}>
                                                                    { 
                                                                        status === "confirmed" ? <i className="fas fa-trophy"></i> :
                                                                        status === "yes" ? <i className="fas fa-thumbs-up"></i> :
                                                                        status === "no" ? <i className="fas fa-thumbs-down"></i> :
                                                                        <i className="fas fa-hourglass-half"></i>
                                                                    }
                                                                    <span>
                                                                        {statusCount}
                                                                    </span>
                                                                </div>
                                                            </SimpleTooltip>
                                                        )
                                                    })}
                                                </div>
                                            : null }

                                            { (offersKey !== OfferListTab.Favourite) &&
                                                <div className='details-2'>
                                                    {(() => {
                                                        const config = getOfferStatusIndicatorConfig(
                                                            o,
                                                            false,
                                                            true
                                                        );

                                                        return (
                                                            <>
                                                                <SimpleTooltip
                                                                    id={`offer-status-${o.id}`}
                                                                    text={config.tooltip}
                                                                >
                                                                    { (staleData) ?
                                                                        <Skeleton count={1} />
                                                                        :
                                                                        <Tag background={config.color} className="tag">
                                                                            {config.label}
                                                                        </Tag>
                                                                    }
                                                                </SimpleTooltip>
                                                            </>
                                                        )
                                                    })()}
                                                </div>
                                            }

                                            </div>
                                            { offersKey !== OfferListTab.Favourite &&
                                                <div>
                                                    <div className='days'>
                                                        { datesToDisplay.map(day => {
                                                            const firstEventOnThisDay = o?.events?.find(e => Utilities.isSameDay(new Date(e.start), day));
                                                            return (
                                                                <div 
                                                                    className='day'
                                                                    data-occupied={firstEventOnThisDay ? "true" : "false"}
                                                                    data-hovered={Utilities.isSameDay(hoveredDay, day)}
                                                                    onMouseEnter={() => {
                                                                        setHoveredDay(day);
                                                                    }}
                                                                >
                                                                    { firstEventOnThisDay &&
                                                                        <div>
                                                                            <span>
                                                                                {Utilities.formatDate(new Date(firstEventOnThisDay.start), "HH")}<br />
                                                                                {Utilities.formatDate(new Date(firstEventOnThisDay.start), "MM")}
                                                                            </span>
                                                                            <span>-</span>
                                                                            <span>
                                                                                {Utilities.formatDate(new Date(firstEventOnThisDay.end), "HH")}<br />
                                                                                {Utilities.formatDate(new Date(firstEventOnThisDay.end), "MM")}
                                                                            </span>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            )
                                                        }) }
                                                    </div>  
                                                </div>
                                            }
                                        </div>
                                    </OfferRow>
                                )
                            })
                        }
                        { (offersLoading) &&
                            <Spinner />
                        }
                    </div>
                }
                { (!offersLoading && offers?.hasMorePages) &&
                    <button 
                        onClick={() => {
                            loadMore(offersKey);
                        }}
                        style={{
                            width: "100%",
                            border: "none",
                            fontWeight: 600,
                            padding: 12,
                            fontSize: 16,
                            background: "gainsboro"
                        }}
                    >
                        Load {(offers?.page || 0) + 1 === offers?.totalPages ? ((offers?.totalRows || 0) % offersPerPage) : offersPerPage} more of {offers.totalRows}
                    </button>
                }
            </div>
        )
    };

    const getOfferStatusIndicatorConfig = (
        offer: OfferInfoDto,
        withChanges = false,
        agency = false
    ) => {
        const offerStatus = getOfferStatus(offer, withChanges);
        const acceptedRecipients = getOfferWorkersByResponseType(
            offer,
            OfferResponseTypes.Yes
        );
        const acceptedRecipient = acceptedRecipients[0];
        const totalAccepted = acceptedRecipients.length;

        const confirmedRecipients = getOfferWorkersByConfirmationType(
            offer,
            OfferConfirmationResponseTypes.Confirmed
        );
        const confirmedRecipient = confirmedRecipients[0];
        const totalConfirmed = confirmedRecipients.length;

        const hasRepliesTooltip =
            acceptedRecipient &&
            `This offer can be confirmed now as ${
                acceptedRecipient.recipientName
            }${totalAccepted > 1 ? ` and ${totalAccepted - 1} more` : ''} applied`;

        const confirmedTooltip =
            confirmedRecipient &&
            `This offer has been confirmed for ${
                confirmedRecipient.recipientName
            }${totalConfirmed > 1 ? ` and ${totalConfirmed - 1} more` : ''}`;

        const confirmedAlterationsTooltip =
            confirmedRecipient &&
            `This offer has been confirmed for ${
                confirmedRecipient.recipientName
            }${
                totalConfirmed > 1
                    ? ` and ${totalConfirmed - 1} more with alterations`
                    : ''
            }`;

        const noLongerConfirmedTooltip =
            confirmedRecipient &&
            `One or more offer recipients have unconfirmed a previously confirmed event.`;

        const statusIndicatorConfig = {
            [OfferStatus.ConfirmedAlterations]: {
                label: 'Confirmed (with alterations)',
                tooltip: confirmedAlterationsTooltip,
                color: theme.colours.orange
            },
            [OfferStatus.ConfirmedDeletions]: {
                label: 'No Longer Confirmed',
                tooltip: noLongerConfirmedTooltip,
                color: theme.colours.red
            },
            [OfferStatus.Confirmed]: {
                label: 'Confirmed',
                tooltip: confirmedTooltip,
                color: theme.colours.green
            },
            [OfferStatus.Withdrawn]: {
                label: 'Withdrawn',
                tooltip: 'This offer was withdrawn and was not fulfilled',
                color: theme.colours.red
            },
            [OfferStatus.Historic]: {
                label: 'Historic',
                tooltip: 'This offer was confirmed and has now passed',
                color: theme.colours.grey
            },
            [OfferStatus.Waiting]: {
                label: 'No Replies', // 'Waiting for Replies',
                tooltip:
                    'None of your invited contacts have accepted this offer yet',
                color: theme.colours.updatedge
            },
            [OfferStatus.Ready]: {
                label: 'Has Replies',
                tooltip: hasRepliesTooltip,
                color: theme.colours.blue2
            },
            [OfferStatus.Declined]: {
                label: 'Declined',
                tooltip:
                    'All contacts this offer was sent to have declined the offer',
                color: theme.colours.red
            },
            [OfferStatus.NotRequested]: {
                label: 'Not Requested',
                tooltip: 'You haven’t asked anyone to help yet',
                color: theme.colours.grey
            }
        };

        const statusIndicatorConfigAgency = {
            [OfferAgencyStatus.NotRequested]: {
                label: "Not Requested",
                tooltip: "You haven't asked any agencies for help",
                color: theme.colours.grey
            },
            [OfferAgencyStatus.NotSent]: {
                label: "Not yet Sent",
                tooltip: "We haven't asked any agencies for help yet",
                color: theme.colours.grey
            },
            [OfferAgencyStatus.NotNeeded]: {
                label: "Not Needed",
                tooltip: "We haven't asked any agencies for help because there were applications",
                color: theme.colours.grey
            },
            [OfferAgencyStatus.Pending]: {
                label: "Pending",
                tooltip: "We've asked agencies for help and are waiting for their response",
                color: theme.colours.amber
            },
            [OfferAgencyStatus.Helping]: {
                label: "Helping",
                tooltip: "One or more agencies have accepted to help",
                color: theme.colours.blue2
            },
            [OfferAgencyStatus.NotHelping]: {
                label: "Not Helping",
                tooltip: "All agencies have declined to help",
                color: theme.colours.red2
            },
            [OfferAgencyStatus.Historic]: {
                label: "Historic",
                tooltip: "This offer has passed",
                color: theme.colours.grey
            }
        }

        if (agency) {
            let agencyStatus = OfferAgencyStatus.NotRequested;
            if (offer.agencyResponses && offer.agencyResponses.length > 0) {
                agencyStatus = OfferAgencyStatus.NotSent;
                if (offer.agencyResponses.filter(r => r.emailSent).length > 0) {
                    agencyStatus = OfferAgencyStatus.Pending;
                    if (offer.agencyResponses.filter(r => r.responseType === OfferAgencyResponsesType.Yes).length > 0) {
                        agencyStatus = OfferAgencyStatus.Helping;
                    } else if (offer.agencyResponses.filter(r => r.responseType === OfferAgencyResponsesType.No).length > 0) {
                        agencyStatus = OfferAgencyStatus.NotHelping;
                    }
                } else if (offer.agencyResponses.filter(r => r.cancelled).length > 0) {
                    agencyStatus = OfferAgencyStatus.NotNeeded;
                }
            }
            if (offerStatus === OfferStatus.Historic) {
                agencyStatus = OfferAgencyStatus.Historic
            }
            return statusIndicatorConfigAgency[agencyStatus];
        } else {
            if (offer.recipients.length === 0 && !offer.favourite) {
                return statusIndicatorConfig[OfferStatus.NotRequested];
            } else {
                return statusIndicatorConfig[offerStatus];
            }
        }
    };

    const DATES_TO_SHOW = 30;
    let datesToDisplay = [displayStartDate];
    for (let i = 0; i < DATES_TO_SHOW; i++) {
        datesToDisplay.push(Utilities.dateAdd(new Date(datesToDisplay[datesToDisplay.length-1]), "day", 1));
    }

    return (
        <OffersContainer 
            className="vertical layout center flex"
        >
            <SearchHeader>
                {(windowWidth < 1020 || true) &&
                    <Button
                        id="open-create-offer"
                        onClick={() => {
                            // startInstantOffer
                            history.push("/offers#create");
                        }}
                    >
                        Create instant offer
                    </Button>
                }
                <input
                    type="text"
                    className="form-control search-input"
                    placeholder="Search offers"
                    value={search}
                    onChange={(ev) => setSearchValue(ev.target.value)}
                />
            </SearchHeader>
            <ExistingOffers>
                { ( (!tabConfig.Resolved.loading || !tabConfig.Upcoming.loading) && (tabConfig.Upcoming.offers.data.length || tabConfig.Resolved.offers.data.length) ) ?
                    <div className='timeline-header'>
                        <div></div>
                        <div>
                            <button>
                                <i onClick={() => { setDisplayStartDate(Utilities.dateSub(displayStartDate, "day", 5)) }} className="fas fa-chevron-circle-left"></i>
                            </button>
                            <div className='days'>
                                { datesToDisplay.map(day => {
                                    return (
                                        <div
                                            className='day'
                                            data-hovered={Utilities.isSameDay(hoveredDay, day)}
                                            onMouseEnter={() => {
                                                setHoveredDay(day);
                                            }}
                                        >
                                            <span>{Utilities.formatDate(day, "ds")}</span>
                                            <span>{Utilities.formatDate(day, "d")}</span>
                                            <span>{Utilities.formatDate(day, "mms")}</span>
                                        </div>
                                    )
                                }) }
                            </div>  
                            <button>
                                <i onClick={() => { setDisplayStartDate(Utilities.dateAdd(displayStartDate, "day", 5)) }} className="fas fa-chevron-circle-right"></i>
                            </button>
                        </div>
                        <span onClick={() => { setDisplayStartDate(new Date()) }}>Jump to Today</span>
                    </div> : null
                }
                { (showingDemoRes) &&
                    <Dialog
                        body="There are no results to show in this view, so we populated it with some sample data, click here to dismiss"
                        type="info"
                        style={{
                            marginBottom: 15,
                            borderRadius: 12,
                        }}
                        onClick={() => {
                            localStorage.setItem("offer-demo-dismiss", "true");
                            reloadList();
                        }}
                        buttonText="Dismiss Example Data"
                    />
                }
                { (Object.keys(tabConfig).map((key, i) => {
                    let tab = tabConfig[key];
                    return (
                        <div style={{
                            borderRadius: 12,
                        }}>
                            {renderOffers(tab.offers, tab.title, tab.tab, tab.loading, tab.color, tab.icon)}
                        </div>
                    )
                })) }
            </ExistingOffers>
        </OffersContainer>
    );
};

const OfferListHeader = styled.div`
    position: relative;

    h2 {
        margin: 0;
        font-size: 1.5em;
        padding: 15px;
        font-weight: 700;
        text-align: center;
        color: white;
        max-width: 356px;
        position: relative;

        span {
            margin-left: 8px;
            background-color: white;
            color: #333;
            border-radius: 52px;
            padding: 2px 10px;
            font-size: 0.8em;
            position: relative;
            top: -1px;
        }
    }

    .agency-indicator, .worker-indicator {
        position: absolute;
        top: 8px;

        &.agency-indicator {
            right: 10px;
            text-align: right;
        }

        &.worker-indicator {
            left: 10px;
            text-align: left;
        }

        span {
            display: block;
            background: transparent;
            color: white;
            font-size: 11px;
            padding: 0;
            margin: 0;
        }

        i {
            font-size: 20px;
            margin-bottom: 4px;
        }
    }
`

const ExistingOffers = styled.div`
    // display: flex;
    margin-bottom: 120px;
    margin-top: 72px;

    .timeline-header {
        margin: 20px 0 8px 0 !important;
        display: flex;
        position: absolute;
        top: 64px;
        right: 20px;
        width: calc(100% - 40px - 323px);
        z-index: 1;
        max-width: 904px;
        overflow: visible;

        &>div:first-of-type {
            flex-basis: 336px;
            display: none;

            h2 {
                font-weight: 700;
                margin: 5px 0;
                font-size: 20px;
                text-align: center;
            }
        }

        &>div:last-of-type {
            float: right;
            padding: 0 32px;
            position: relative;
            overflow: hidden;
            white-space: nowrap;
            background: white;
            border-radius: 52px;
            background: gainsboro;

            .days {

                .day {
                    width: 32px;
                    display: inline-block;
                    text-align: center;

                    span {
                        display: block;

                        &:nth-child(2) {
                            font-weight: 700;
                        }
                    }
                }
            }

            button {
                position: absolute;
                top: 0;
                height: 56px;
                width: 36px;
                border: 0px;
                background: gainsboro;

                i {
                    font-size: 16px;
                    padding: 4px;
                }

                &:first-child {
                    left: 0;
                }

                &:last-child {
                    right: 0;
                }
            }
        }

        &>span {
            position: absolute;
            background-color: gainsboro;
            left: -132px;
            padding: 4px 12px 6px 12px;
            border-radius: 52px;
            top: 14px;
            cursor: pointer;
        }

        @media (min-width: 1500px) {
            margin-right: calc((100% - 1240px) / 2) !important;
            right: 0 !important;
            padding: 0 !important;
            justify-content: flex-end !important;
        }
    }

    & > div {
        max-width: 100%;
        overflow: hidden;
        height: fit-content;

        .offer-list {
            border-radius: 12px;
            overflow: hidden;
            background: white;

            .no-results {
                text-align: center;
                font-size: 1.2em;
                padding: 20px;
                font-weight: 700;
            }
        }

    }

    @media (max-width: 950px) {
        display: block;

        & > div {
            flex-basis: unset;
            max-width: unset;
            margin: 0 0 20px 0 !important;
        }
    }

    @media (max-width: 580px) {
        margin-top: 0 !important;

        .timeline-header {
            display: none;
        }
    }
`

const SearchHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    button {
        background-color: ${theme.colours.blue2};
        padding: 12px 20px;
        color: white;
        border-radius: 12px;
        margin-right: 15px;
        font-weight: 700;
    }

    input {
        width: 100%;
        padding: 21px 20px;
        border-radius: 12px;
        margin: 0;
        text-align: center;
        border-top: 4px solid ${theme.colours.blue2} !important;
    }

    // @media (max-width: 1020px) {

    //     input {
    //         margin: 0;
    //     }
    // }

    @media (max-width: 500px) {
        display: block !important;

        & > * {
            width: 100% !important;
        }

        button {
            margin: 0 0 10px 0 !important;
        }

    }

`

const OffersContainer = styled.div`
    max-width: 100%;
    overflow: auto;

    > div {
        width: 100%;

        &.tab-headers {
            flex-flow: row wrap;
            min-height: 51px;

            @media (max-width: 800px) {
                min-height: 34px;
            }
        }
    }

    .no-tab {
        border-bottom: 1px solid ${theme.colours.updatedge};
    }

    .swipe-helper {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 12px;

        p {
            padding-left: 16px;
            margin: 0;
        }

        i {
            animation-name: left-right;
            animation-duration: 1.5s;
            animation-timing-function: linear;
            animation-iteration-count: infinite;
            right: -5px;
            position: relative;
        }

        @keyframes left-right {
            0% {
                right: -5px;
            }
            50% {
                right: 5px;
            }
            100% {
                right: -5px;
            }
        }

    }

    .tab-alt {
        display: none;

        > select {
            display: inline-block;
            width: 150px;
            margin-left: 10px;
        }
    }
    
    @media (max-width: 800px) {
        .tab-alt {
            display: block;
        }

        .no-tab {
            border-bottom: 0;
        }
    }

    @media (max-width: 425px) {
        .tab-alt {
            > label {
                display: none;
            }

            > select {
                margin-left: 0;
            }
        }
    }

    @media (max-width: 360px) {
        .tab-alt {
            order: 2;
            width: 100%;

            > select {
                width: 100%;
            }
        }

        .no-tab {
            order: 1;
            width: 100%;

            > button {
                width: 100%;
                margin-bottom: 5px;
            }
        }
    }

    .days .day[data-hovered="true"] {
        background: ${theme.colours.grey} !important;
        border-color: ${theme.colours.grey} !important;
    }
`;

const OfferRow = styled.div`
    cursor: pointer;
    position: relative;
    overflow-x: auto;

    &[data-favorite="true"] {

        .details {
            min-height: unset !important;
            flex-basis: unset !important;
        }
    }

    &:not(:last-child) {
        border-bottom: 1px solid #dcdcdc;
    }

    &:hover {
        background: #f0f8fc;
    }

    &.selected {
        background: #def;
    }

    i {
        display: none;
    }

    &:hover {
        
        i {
            display: inline-block;
        }
    }

    & > div {
        display: flex;

        & > div: first-child {
            flex-basis: 355px;
            display: flex;

            & > div {
                display: flex;

                &:nth-child(2) {
                    flex-basis: 116px;
                }

                &.details, &.details-2 {
                    &.details {
                        display: flex;
                        flex-basis: calc(100% - 116px - 35px - 10px);
                        overflow: hidden;
                        min-height: 80px;
                    }

                    &.details-2 {
                        flex-basis: 35px;
                        padding-left: 10px;
                    }

                    .react-loading-skeleton {
                        width: 20px;
                        height: calc(100% + 3px);
                        position: relative;
                        top: -2px;
                    }

                    span.tag {
                        float: none;
                        display: inline-block;
                        font-size: 13px;
                        padding: 10px 8px 10px 8px !important;
                        writing-mode: vertical-lr;
                        transform: rotate(180deg);
                        height: auto;
                        flex-basis: 35px;
                        margin-right: 0;
                        border-radius: 0;
                    }

                    .meta {
                        flex-basis: calc(100% - 35px);
                        padding: 10px 10px;
                        position: relative;

                        .offer-title {
                            font-weight: 600;
                            font-size: 1.1em;
                            position: relative;
                            width: 100%;
                        }

                        .confirmed-contacts, .applied-contacts, .pending-contacts, .rejected-contacts, .notyetsent-agencies, .yes-agencies, .no-agencies, .pending-agencies, .no-longer-confirmed-contacts {
                            margin-top: 5px;
                            margin-bottom: -8px;
                            
                            &>div {
                                display: inline-flex;
                                margin: 0 8px 8px 0;
                                align-items: center;
                                background-color: ${theme.colours.green2};
                                border-radius: 52px;
                                position: relative;
                                z-index: 1;

                                img {
                                    width: 24px;
                                    height: 24px;
                                    border-radius: 100%;
                                    position: relative;
                                    z-index: 2;
                                }
    
                                span {
                                    display: none;
                                    padding: 2.5px 6px 2.5px 6px;
                                    color: white;
                                    white-space: nowrap;
                                    background-color: ${theme.colours.green2};
                                    z-index: 1;
                                    border-radius: 0 52px 52px 0;

                                    &[data-visible="true"] {
                                        display: inline-block;
                                    }
                                }

                                label {
                                    margin: 0 10px 0 0px;
                                    color: white;
                                    font-weight: 700;
                                    font-style: italic;
                                }

                                &:hover {
                                    z-index: 2;

                                    span[data-visible="false"] {
                                        display: inline-block;
                                        padding-left: 16px;
                                        position: absolute;
                                        left: 14px;
                                        top: 0;
                                    }
                                }
                            }

                            .extra {
                                display: inline-block;
                                margin-left: 8px;
                                font-size: 12px;
                                color: ${theme.colours.blue2};
                                font-weight: 600;
                            }
                            
                            &.applied-contacts, &.yes-agencies {

                                &>div {
                                    background-color: ${theme.colours.blue2};

                                    span {
                                        background-color: ${theme.colours.blue2};
                                    }
                                }
                            }

                            &.pending-contacts, &.pending-agencies {

                                &>div {
                                    background-color: ${theme.colours.amber};

                                    span {
                                        background-color: ${theme.colours.amber};
                                    }
                                }
                            }

                            &.rejected-contacts, &.no-agencies, &.no-longer-confirmed-contacts {

                                &>div {
                                    background-color: ${theme.colours.red};

                                    span {
                                        background-color: ${theme.colours.red};
                                    }
                                }
                            }

                            &.notyetsent-agencies {
                                    
                                    &>div {
                                        background-color: ${theme.colours.grey};
    
                                        span {
                                            background-color: ${theme.colours.grey};
                                        }
                                    }
                            }
                        }

                        i {
                            position: absolute;
                            color: ${theme.colours.red2};
                            background-color: white;
                            font-size: 18px;
                            top: 4px;
                            right: 4px;
                            z-index: 2;
                            padding: 4px 6px;
                            border-radius: 8px;
                            box-shadow: ${theme.colours.boxShadow};
                        }
            
                        &.favorite {
                            margin-bottom: 0;
                        }
                    }
                }
        
                &.responses, &.recipient-responses {
                    display: block;

                    &.recipient-responses {
                        display: flex !important;
                        align-items: center;
                    }

                    .react-loading-skeleton {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 20px;
                        height: 100%;
                    }
        
                    .recipient, .recipient-response {
                        position: relative;
                        display: inline-block;
                        background-color: ${theme.colours.red2};
                        height: 32px;
                        max-width: 100%;
                        border-radius: 32px;
                        display: inline-flex;
        
                        span {
                            display: inline-block;
                            color: white;
                            padding-top: 6px;
                            padding-right: 12px;
                        }
        
                        img {
                            width: 32px;
                            height: 32px;
                            border-radius: 100%;
                            display: block;
                            background: white;
                        }
        
                        i {
                            display: block;
                            color: white;
                            height: 32px;
                            width: 32px;
                            text-align: center;
                            padding-top: 9px;
                        }
        
                        &[data-status="0"] {
                            background-color: ${theme.colours.orange};
                        }
        
                        &[data-status="1"] {
                            background-color: ${theme.colours.blue2};
                        }

                        &[data-status="3"] {
                            background-color: ${theme.colours.green2};
                        }
        
                        &:not(:last-child) {
                            margin: 0 8px 8px 0;
                        }

                        &.recipient-response {
                            flex-wrap: wrap;
                            height: unset;
                            text-align: center;
                            padding: 8px 0 6px 0px;
                            margin-bottom: 0;
                            
                            &>* {
                                flex-basis: 100%;
                                width: 12px;
                                height: unset;
                                text-align: center;
                                margin: 0;
                                padding: 0;

                                &i {
                                    margin-bottom: 5px !important;
                                }
                            }
                        }
                    }
        
                    &.agency {
                        padding-left: 0;
                    }
        
                }
        
                .dates {
                    display: inline-block;
                }
        
            }
        }

        & > div: last-child {
            padding-left: 0; // 48px;
            flex-basis: calc(100% - 355px);
            overflow: hidden;
            display: flex;

            .days {
                white-space: nowrap;
                overflow: hidden;
                height: auto;

                .day {
                    width: 32px;
                    height: 100%;
                    display: inline-block;
                    position: relative;
                    border-left: 1px solid #dcdcdc;

                    &>div {
                        position: absolute;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        left: 50%;

                        &>span {
                            display: block;
                            text-align: center;
                            color: white;

                            &:first-child, &:last-child {
                                font-weight: 600;
                                font-size: 11px;
                            }

                            &:nth-of-type(2) {
                                font-weight: 300;
                                line-height: 1;
                            }
                        }
                    }

                    &[data-occupied="true"] {
                        background-color: ${theme.colours.blue} !important;
                    }
                }
            }
        }
    }

    @media (max-width: 580px) {

        &>div {
            display: block !important;

            &>div {

                &:nth-child(2) {
                    display: none;
                }
            }
        }
    }
`;

export default OfferList;
