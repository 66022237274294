/* eslint-disable */
import React from 'react';
import { RouteComponentProps } from 'react-router';

export default class Chat extends React.Component<
    RouteComponentProps<{ userId: string }> & {
        setChatNotificationNumber: (number) => any | null | undefined;
    },
    {}
> {

    render() {
        return null;
    }

}
