import AppConfig from '../../components/config/Config';
import afetch from '../AuthenticatedFetch';
import { ContactEmailDTO } from './ResponseTypes';

const apiCalls = {
    updatePrimaryEmail(id: ContactEmailDTO['id']) {
        const url = `${AppConfig.Settings.api.baseUri}/publicapi/userprofile/emails/${id}/setprimary`;
        return afetch.request(
            url,
            'PUT',
            null,
            'Unable to modify primary email'
        );
    },
    getAccountEmails() {
        const url = `${AppConfig.Settings.api.baseUri}/publicapi/userprofile/emails`;
        return afetch.request<
            null,
            {
                hasResults: boolean;
                success: boolean;
                results: ContactEmailDTO[];
            }
        >(url, 'GET', null, 'Unable to get emails');
    },
    addEmail(email: string) {
        const url = `${AppConfig.Settings.api.baseUri}/publicapi/userprofile/emails/add`;
        return afetch.request<{ email: string }>(
            url,
            'POST',
            { email },
            'Unable to add email to account',
            undefined,
            true
        );
    },
    removeEmail(id: ContactEmailDTO['id']) {
        const url = `${AppConfig.Settings.api.baseUri}/publicapi/userprofile/emails/${id}`;
        return afetch.request(url, 'DELETE', null, 'Unable to remove email');
    }
};

export default apiCalls;
