/* eslint-disable */
import { Action, Reducer } from 'redux';
import moment, { Moment } from 'moment';
import update from 'immutability-helper';
import { xor, xorWith } from 'lodash-es';

import AvailabilityApi from '../api/availability/Availability';
import OfferApi from './../api/offers/Offers';
import ContactApi from './../api/contacts/Contacts';
import ProfileApi from './../api/profile/Profile';
import { AppThunkAction, exhaustiveCheck } from '.';
import { PendingEventDto } from '../api/offers/ResponseTypes';
import { DayIntervalDto } from '../api/availability/RequestTypes';
import { TimePreset } from './timepresets/Models';
import { Contact } from './contacts/Models';
import InternalTracker from 'src/InternalTracker';
import { RatingType } from 'src/api/ratings/ResponseTypes';
import Utilities from 'src/Utilities';
import { getValue, setValue } from 'src/db/KeyValueCache';

let isFirstRequest: boolean = true;

export interface DaySlotModel {
    start: Moment;
    end: Moment;
}

export interface ContactAvailabilitySummaryModel {
    contact: Contact;
    occupancy: OccupanciesModel;
}

export interface OccupanciesModel {
    occupancies: OccupancyModel[];
    totalIntervalMinutes: number;
    totalOccupancyMinutes: number;
    totalOccupancyPercentage: number;
}

export interface OccupancyModel {
    end: Moment;
    intervalMinutes: number;
    occupiedMinutes: number;
    occupiedPercentage: number;
    start: Moment;
}

export interface ContactDayAvailabilityModel {
    date: Moment;
    percentageFree: number;
    fullyOccupied: boolean;
}

export interface CalendarEventsModel {
    events: TimelineEventModel[];
    loaded: boolean;
}

export interface TimelineEventModel {
    id: string;
    userId: string;
    title: string;
    start: Moment;
    end: Moment;
    eventTypeId: number;
    eventType: string;
    repeat: boolean;
    repeatUntil?: Moment;
    repeatType: string;
    lastUpdatedOn: Moment;
}

// -----------------
// STATE - This defines the type of data maintained in the Redux store.
export interface ContactAvailabilityState {
    days: DaySlotModel[];
    daysSelected: DaySlotModel[];
    contacts: ContactAvailabilitySummaryModel[];
    contactsSelected: ContactAvailabilitySummaryModel[];
    searchParams: AvailabilityParams;
    calendarEvents: CalendarEventsModel;
    loaded: boolean;
    selectionInProcess: boolean;
    stale?: boolean;
    lastFetched?: Date;
}

export interface Skill {
    value?: string;
    text?: string;
    name?: string;
    id: string;
    pendingAdd?: boolean;
}

/**
 * Search params for availability query
 */
export interface AvailabilityParams {
    start: Moment;
    consecutiveDays: number;
    groupId: number;
    days: DaySlotModel[];
    timePreset: TimePreset;
    overnight?: boolean;
    skills?: Skill[];
    hirerLocationId?: string;
    sortOrder: AvailabilityOrderingType,
    page: number;
    limit: number;
    name: string;
    updatedAfter: string | null;
    postcode: string | null;
    representingAgencyId: string;
}

export enum AvailabilityOrderingType {
    Alphabetical = 0,
    LastUpdated = 1,
    MostAvailable = 2,
    LocationFrom = 3,
    Rating = 4
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

interface AvailabilityRequestAction {
    type: 'AVAILABILITY_DATA.REQUEST';
}

interface AvailabilityRequestSuccessAction {
    type: 'AVAILABILITY_DATA.REQUEST.SUCCESS';
    startDateOfRange: Moment;
    days: DaySlotModel[];
    contacts: ContactAvailabilitySummaryModel[];
    groupId: number;
    stale?: boolean;
    lastFetched?: Date;
}

interface AvailabilityUserRequestSuccessAction {
    type: 'AVAILABILITY_DATA_USER.REQUEST.SUCCESS';
    contacts: ContactAvailabilitySummaryModel[];
}

interface AvailabilityStartDateChangeAction {
    type: 'AVAILABILITY_START_DATE.FILTER.CHANGE';
    date: Moment;
}

interface AvailabilityGroupChangeAction {
    type: 'AVAILABILITY_GROUP.FILTER.CHANGE';
    id: number;
}

interface AvailabilitySkillsChangeAction {
    type: 'AVAILABILITY_SKILLS.FILTER.CHANGE';
    skills: Skill[];
}

interface AvailabilityHirerLocationIdChangeAction {
    type: 'AVAILABILITY_HIRER_LOCATION_ID.FILTER.CHANGE';
    hirerLocationId: string
}

interface AvailabilityPageChangeAction {
    type: 'AVAILABILITY_PAGE.FILTER.CHANGE';
    page: number
}

interface AvailabilityNameChangeAction {
    type: 'AVAILABILITY_NAME.FILTER.CHANGE';
    name: string
}

interface AvailabilityRepresentedByAgencyChangeAction {
    type: 'AVAILABILITY_REPRESENTED_BY_AGENCY.FILTER.CHANGE';
    representingAgencyId: string
}

interface AvailabilitySortOrderChangeAction {
    type: 'AVAILABILITY_SORT_ORDER.FILTER.CHANGE';
    sortOrder: AvailabilityOrderingType
}

interface AvailabilityPostcodeChangeAction {
    type: 'AVAILABILITY_POSTCODE.FILTER.CHANGE';
    postcode: string
}

interface AvailabilityOvernightChangeAction {
    type: 'AVAILABILITY_OVERNIGHT.FILTER.CHANGE';
    overnight: boolean;
}

interface AvailabilityTimePresetChangeAction {
    type: 'AVAILABILITY_TIME_PRESET.FILTER.CHANGE';
    preset: TimePreset;
}

interface AvailabilityAddDayToSelectionAction {
    type: 'AVAILABILITY_SELECTION_DAY.TOGGLE';
    day: DaySlotModel;
}

interface AvailabilityClearSelectedDaysAction {
    type: 'AVAILABILITY_SELECTION_DAYS.CLEAR';
}

interface AvailabilityAddContactToSelectionAction {
    type: 'AVAILABILITY_SELECTION_CONTACT.TOGGLE';
    contact: ContactAvailabilitySummaryModel;
}

interface AvailabilityClearSelectedContactsAction {
    type: 'AVAILABILITY_SELECTION_CONTACTS.CLEAR';
}

interface AvailabilityAddAllContactsToSelectionAction {
    type: 'AVAILABILITY_SELECTION_CONTACT.ALL';
}

interface GetCalendarEventsAction {
    type: 'GET_CALENDAR_EVENTS.SUCCESS';
    events: CalendarEventsModel;
}

interface DetermineSelectionInProgressAction {
    type: 'AVAILABILITY_DETERMINE_SELECTION.INPROCESS';
}

interface OfferCreatedAction {
    type: 'OFFER_CREATED.SUCCESS';
}

interface OfferClearAction {
    type: 'OFFER.CLEAR';
}

interface LastUpdatedAvailabilityAction {
    type: 'AVAILABILITY_UPDATED.AFTER.FILTER.CHANGE';
    updatedAfter: string | null;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction =
    | AvailabilityRequestSuccessAction
    | AvailabilityUserRequestSuccessAction
    | AvailabilityRequestAction
    | AvailabilityStartDateChangeAction
    | AvailabilityGroupChangeAction
    | AvailabilitySkillsChangeAction
    | AvailabilityHirerLocationIdChangeAction
    | AvailabilityPageChangeAction
    | AvailabilityNameChangeAction
    | AvailabilityTimePresetChangeAction
    | AvailabilityAddDayToSelectionAction
    | AvailabilityClearSelectedDaysAction
    | AvailabilityClearSelectedContactsAction
    | AvailabilityAddContactToSelectionAction
    | AvailabilityAddAllContactsToSelectionAction
    | DetermineSelectionInProgressAction
    | GetCalendarEventsAction
    | OfferCreatedAction
    | AvailabilityOvernightChangeAction
    | AvailabilitySortOrderChangeAction
    | OfferClearAction
    | AvailabilityPostcodeChangeAction
    | LastUpdatedAvailabilityAction
    | AvailabilityRepresentedByAgencyChangeAction;

// ACTION CREATOR HELPER FUNCTIONS

/**
 * Wraps the fetch call for availability
 * @param params Query parameters
 */
function getAvailabilityAndDispatch(params: AvailabilityParams, dispatch, force?: boolean) {
    // Loading initial default timepreset, and last skills will trigger API call multiple times, so it needs this timeout, so it will only send the last final request
    if (!force) {
        clearTimeout((window as any).getAvailabilityAndDispatchDebounce);
        (window as any).getAvailabilityAndDispatchDebounce = setTimeout(() => {
            getAvailabilityAndDispatch(params, dispatch, true);
        }, 600)
        return;
    }

    localStorage.setItem("last-availability-search-order", params.sortOrder + "");

    params.consecutiveDays = 4;

    // Needs two extra days, so we can hide the weekends
    let skipWeekends = localStorage.getItem('offersContinueOnWeekdays') === 'true' || location.pathname.startsWith('/external/');
    if (skipWeekends) {
        params.consecutiveDays += 2;
    }

    (window as any).lastSearchParams = JSON.stringify(params);
    const inThisCallWeAskedFor = JSON.stringify(params);

    InternalTracker.trackEvent("Availability Filtered", params)

    dispatch({ type: 'AVAILABILITY_DATA.REQUEST' });
    const startOfSlot = params.start
        .clone()
        .add(params.timePreset.startHour, 'hours')
        .add(params.timePreset.startMinute, 'minutes');
    const endOfSlot = params.start
        .clone()
        .add(params.timePreset.endHour, 'hours')
        .add(params.timePreset.endMinute, 'minutes');

    if (endOfSlot < startOfSlot) {
        // Preset is overnight, add 24hr to end
        endOfSlot.add(24, 'hours');
    }

    const startDate = startOfSlot.toDate();
    const endDate = endOfSlot.toDate();
    if (startDate.getHours() === 0 && endDate.getHours() === 0) {
        return null;
    }

    return AvailabilityApi.getTotalAvailabilityConsecutivelyForContactList(
        params.groupId,
        startOfSlot,
        endOfSlot,
        params.consecutiveDays,
        params.skills || [], // searches for skills, education entries - subject, degree, school
        params.hirerLocationId || "",
        params.sortOrder,
        params.page,
        params.limit,
        params.name ? params.name.toLowerCase() : "",
        params.updatedAfter,
        params.postcode || undefined,
        params.representingAgencyId || undefined
    ).then((response) => {

        // Result for a previous search, so ignore
        if ((window as any).lastSearchParams !== inThisCallWeAskedFor) {
            return;
        }

        const moreContactsEl = document.getElementById('show-more-contacts');
        if (moreContactsEl) moreContactsEl.style.display = (response?.paging?.hasMorePages) ? "flex" : "none";

        const getMoreContactsButton = document.getElementById('get-more-contacts');
        if (getMoreContactsButton) getMoreContactsButton.style.display = (response?.paging?.hasMorePages) ? "none" : "flex";

        const moreContactsText = document.getElementById('availability-pagination-state');
        if (moreContactsText) moreContactsText.innerText = "Showing " + params.page + " of " + response?.paging?.totalPages + " pages"

        localStorage.setItem("last-availability-search-result-count", (response?.paging?.totalPages * params.limit) + "");
    
        // calculate days in slot
        const daysInRange = createSlotRange(
            startOfSlot,
            endOfSlot,
            params.consecutiveDays+1
        );

        const contactsData = response.data
            ? response.data.map((ca, cai) => {
                const lastUpdatedCalendar = new Date(ca.lastTimelineUpdate || "");
                const availabilityLastConfirmed = new Date(ca.availabilityLastConfirmed || "");
                const hoursBetweenLastAvailabilityUpdate =  lastUpdatedCalendar ? Utilities.hoursDiffBetween(new Date(lastUpdatedCalendar), new Date()) : null;
                const hoursBetweenLastAvailabilityConfirm = new Date(availabilityLastConfirmed) ? Utilities.hoursDiffBetween(new Date(availabilityLastConfirmed), new Date()) : null;
            
                const hoursBetweenLastAvailabilityConfidenceAction = 
                    (hoursBetweenLastAvailabilityUpdate !== null && hoursBetweenLastAvailabilityConfirm !== null) ?
                    (hoursBetweenLastAvailabilityUpdate < hoursBetweenLastAvailabilityConfirm ? hoursBetweenLastAvailabilityUpdate : hoursBetweenLastAvailabilityConfirm) :
                        (hoursBetweenLastAvailabilityUpdate || hoursBetweenLastAvailabilityConfirm || null);
            
                let availabilityConfidencePercentage: number = hoursBetweenLastAvailabilityConfidenceAction ? (hoursBetweenLastAvailabilityConfidenceAction < 24 ? 100 : hoursBetweenLastAvailabilityConfidenceAction > 240 ? 0 : Math.ceil(100 - (hoursBetweenLastAvailabilityConfidenceAction / 240 * 100))) : 0;

                if (window.location.pathname.startsWith("/external/timesheet/rota/worker") && cai === 0 && ca.timePresets) {
                    localStorage.setItem("workerPresets", ca.timePresets)
                }

                return {
                    contact: {
                        id: ca.contactId,
                        userId: ca.workerId,
                        firstName: ca.firstName,
                        lastName: ca.lastName,
                        fullName: ca.firstName + " " + ca.lastName,
                        profileImageUrl: ProfileApi.getProfileImageUrl(ca.workerId || ""),
                        lastAvailabilityUpdateOn: ca.lastTimelineUpdate,
                        verified: ca.verified,
                        headline: ca.headline,
                        matchedSkills: ca.matchedSkills || [],
                        matchedKeywords: ca.matchedKeywords || [],
                        distance: ca.distance,
                        rating: ca.rating && ca.rating.stars ? ca.rating.stars : ca.ownRatingStars,
                        avgRating: ca.avgRatingStars,
                        publicRating: ca.rating ? ca.rating.publicComment : undefined,
                        privateRating: ca.rating ? ca.rating.privateComment : undefined,
                        totalRatings: ca.totalRatings,
                        representedByOrganisations: ca.representedByOrganisations,
                        availabilityLastConfirmed: ca.availabilityLastConfirmed,
                        timePresets: ca.timePresets,
                        confidenceScore: availabilityConfidencePercentage,
                        alreadyInvitedByOrgId: ca.alreadyInvitedByOrgId,
                        alreadyInvitedByOrgName: ca.alreadyInvitedByOrgName,
                        verifications: ca.verifications,
                        notSharing: ca.notSharing,
                        maskedEmailAddress: ca.maskedEmailAddress,
                        maskedPhoneNumber: ca.maskedPhoneNumber,
                        reported: ca.reported,
                    },
                    occupancy: {
                        totalIntervalMinutes:ca.totalMinutesAvailable + ca.totalMinutesUnavailable,
                        totalOccupancyMinutes: ca.totalMinutesUnavailable,
                        totalOccupancyPercentage: ca.totalOccupancyPercentage,
                        occupancies: ca.occupancy.map(
                            (occupancy) => {
                                return {
                                    start: occupancy.start,
                                    end: occupancy.end,
                                    intervalMinutes:
                                        occupancy.intervalMinutes,
                                    occupiedMinutes:
                                        occupancy.occupiedMinutes,
                                    occupiedPercentage:
                                        occupancy.occupiedPercentage
                                } as OccupancyModel;
                            }
                        )
                    } as OccupanciesModel
                } as ContactAvailabilitySummaryModel;
            })
            : []

        const daysData = daysInRange.map((dateInRange) => {
            return {
                start: dateInRange.start,
                end: dateInRange.end
            } as DayIntervalDto;
        })

        setValue("availability-grid", JSON.stringify({
            startDateOfRange: params.start,
            groupId: params.groupId,
            contacts: contactsData,
            days: daysData,
            lastFetched: new Date()
        }))

        // hide availability for worker if non-ue worker
        if (contactsData.length === 0 && window.location.pathname.startsWith("/external/timesheet/rota/worker")) {
            const availabilityDOM = document.getElementById("availability-wrapper");
            if (availabilityDOM) {
                availabilityDOM.style.display = "none";
            }
        }

        // dispatch to reducer
        dispatch({
            type: 'AVAILABILITY_DATA.REQUEST.SUCCESS',
            startDateOfRange: params.start,
            groupId: params.groupId,
            contacts: contactsData,
            days: daysData
        })
    }).catch(e => {
        dispatch({
            type: 'AVAILABILITY_DATA.REQUEST.SUCCESS',
            startDateOfRange: params.start,
            groupId: params.groupId,
            contacts: [],
            days: []
        })
    });
}

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    getCachedAvailability: (): any => (dispatch): any => {    
        getValue('availability-grid').then((availabilityCache) => {
            if (availabilityCache) {
                availabilityCache = JSON.parse(availabilityCache);
                // if (Utilities.dateAdd(availabilityCache.lastFetched, "second", 15) < new Date()) {
                //     return;
                // }
                dispatch({
                    type: 'AVAILABILITY_DATA.REQUEST.SUCCESS',
                    startDateOfRange: availabilityCache.startDateOfRange,
                    groupId: availabilityCache.groupId,
                    contacts: availabilityCache.contacts,
                    days: availabilityCache.days.map(day => {
                        return {
                            start: moment(day.start),
                            end: moment(day.end)
                        }
                    }),
                    stale: true,
                    lastFetched: new Date(availabilityCache.lastFetched)
                })
            }
        })
    },
    getCalendarEvents: (userId: string, start: Moment, end: Moment): any => (
        dispatch
    ): Promise<void> => {
        return AvailabilityApi.getEventsForUserBetweenDatesIncNew(
            userId,
            start,
            end
        ).then((responseData) => {
            // @ts-ignore  - 204 is returning true, making this not clear claendar if this is the only event
            if (responseData === true) {
                responseData = [];
            }
            dispatch({
                type: 'GET_CALENDAR_EVENTS.SUCCESS',
                events: {
                    events: responseData.map((event) => {
                        return {
                            end: event.end,
                            eventType: event.eventType,
                            eventTypeId: event.eventTypeId,
                            repeatTypeId: 0,
                            repeatTypeName: 0,
                            start: event.start,
                            status: event.eventTypeId,
                            workerId: event.userId
                        }
                    }),
                    loaded: true
                }
            });
        });
    },
    changeStartDate: (firstDay: Moment): AppThunkAction<KnownAction> => (
        dispatch,
        getState
    ) => {
        dispatch({
            type: 'AVAILABILITY_START_DATE.FILTER.CHANGE',
            date: firstDay
        });
        getAvailabilityAndDispatch(
            getState().contactAvailability.searchParams,
            dispatch
        );
    },
    changeList: (groupId: number): AppThunkAction<KnownAction> => (
        dispatch,
        getState
    ) => {
        dispatch({ type: 'AVAILABILITY_GROUP.FILTER.CHANGE', id: groupId });
        getAvailabilityAndDispatch(
            getState().contactAvailability.searchParams,
            dispatch
        );
    },
    changeSkills: (skills: Skill[]): AppThunkAction<KnownAction> => (
        dispatch,
        getState
    ) => {
        InternalTracker.trackEvent("", {
            category: 'Availability',
            action: 'Availability Changed Skills',
            customDimensions: [{ id: "REPLACE", value: skills.join(", ") }]
        });

        dispatch({ type: 'AVAILABILITY_SKILLS.FILTER.CHANGE', skills: skills });
        localStorage.setItem("lastSkillFilters", JSON.stringify(skills.filter(item => !item.pendingAdd)));
        getAvailabilityAndDispatch(
            getState().contactAvailability.searchParams,
            dispatch
        );
    },
    changeHirerLocationId: (id: string): AppThunkAction<KnownAction> => (
        dispatch,
        getState
    ) => {
        dispatch({ type: 'AVAILABILITY_HIRER_LOCATION_ID.FILTER.CHANGE', hirerLocationId: id });
        // localStorage.setItem("lastSkillFilters", JSON.stringify(skills.filter(item => !item.pendingAdd)));
        getAvailabilityAndDispatch(
            getState().contactAvailability.searchParams,
            dispatch
        );
    },
    changePage: (page: number): AppThunkAction<KnownAction> => (
        dispatch,
        getState
    ) => {
        dispatch({ type: 'AVAILABILITY_PAGE.FILTER.CHANGE', page: page });
        getAvailabilityAndDispatch(
            getState().contactAvailability.searchParams,
            dispatch
        );
    },
    changeName: (name: string): AppThunkAction<KnownAction> => (
        dispatch,
        getState
    ) => {
        InternalTracker.trackEvent("", {
            category: 'Availability',
            action: 'Availability Changed Name',
            customDimensions: [{ id: "REPLACE", value: name }]
        });

        dispatch({ type: 'AVAILABILITY_NAME.FILTER.CHANGE', name: name });
        getAvailabilityAndDispatch(
            getState().contactAvailability.searchParams,
            dispatch
        );
    },
    changeRepresentingAgencyId: (representingAgencyId: string): AppThunkAction<KnownAction> => (
        dispatch,
        getState
    ) => {
        InternalTracker.trackEvent("", {
            category: 'Availability',
            action: 'Availability Changed Representing Agency',
            customDimensions: [{ id: "REPLACE", value: representingAgencyId }]
        });

        dispatch({ type: 'AVAILABILITY_REPRESENTED_BY_AGENCY.FILTER.CHANGE', representingAgencyId: representingAgencyId });
        getAvailabilityAndDispatch(
            getState().contactAvailability.searchParams,
            dispatch
        );
    },
    changeUpdatedAfter: (updatedAfter: string | null): AppThunkAction<KnownAction> => (
        dispatch,
        getState
    ) => {
        InternalTracker.trackEvent("", {
            category: 'Availability',
            action: 'Availability Changed Last Updated',
            customDimensions: [{ id: "REPLACE", value: updatedAfter || "Anytime" }]
        });

        dispatch({ type: 'AVAILABILITY_UPDATED.AFTER.FILTER.CHANGE', updatedAfter: updatedAfter });
        getAvailabilityAndDispatch(
            getState().contactAvailability.searchParams,
            dispatch
        );
    },
    changeSortOrder: (sortOrder: AvailabilityOrderingType): AppThunkAction<KnownAction> => (
        dispatch,
        getState
    ) => {
        dispatch({ type: 'AVAILABILITY_SORT_ORDER.FILTER.CHANGE', sortOrder: sortOrder });
        getAvailabilityAndDispatch(
            getState().contactAvailability.searchParams,
            dispatch
        );
    },
    changePostcode: (postcode: string): AppThunkAction<KnownAction> => (
        dispatch,
        getState
    ) => {
        dispatch({ type: 'AVAILABILITY_POSTCODE.FILTER.CHANGE', postcode: postcode });
        getAvailabilityAndDispatch(
            getState().contactAvailability.searchParams,
            dispatch
        );
    },
    changeOvernight: (overnight: boolean): AppThunkAction<KnownAction> => (
        dispatch,
        getState
    ) => {
        dispatch({
            type: 'AVAILABILITY_OVERNIGHT.FILTER.CHANGE',
            overnight: overnight
        });
        getAvailabilityAndDispatch(
            getState().contactAvailability.searchParams,
            dispatch
        );
    },
    changeTimePreset: (timePreset: TimePreset): AppThunkAction<KnownAction> => (
        dispatch,
        getState
    ) => {
        dispatch({
            type: 'AVAILABILITY_TIME_PRESET.FILTER.CHANGE',
            preset: timePreset
        });
        getAvailabilityAndDispatch(
            getState().contactAvailability.searchParams,
            dispatch
        );
    },
    getAvailability: (): AppThunkAction<KnownAction> => (
        dispatch,
        getState
    ) => {
        if (isFirstRequest && !window.location.pathname.startsWith("/external")) {
            isFirstRequest = false;
            return;
        }
        dispatch({ type: 'AVAILABILITY_DATA.REQUEST' });
        getAvailabilityAndDispatch(
            getState().contactAvailability.searchParams,
            dispatch
        );
    },
    getAvailabilityForUser: (userId: string, contactId?: string) => async (dispatch, getState) => {

        console.log("Getting updates for userL " + userId)

        const state = getState().contactAvailability;
        const params = state.searchParams;
        const startOfSlot = params.start
            .clone()
            .add(params.timePreset.startHour, 'hours')
            .add(params.timePreset.startMinute, 'minutes');
        const endOfSlot = params.start
            .clone()
            .add(params.timePreset.endHour, 'hours')
            .add(params.timePreset.endMinute, 'minutes');
        params.consecutiveDays = 4;

        if (endOfSlot < startOfSlot) {
            // Preset is overnight, add 24hr to end
            endOfSlot.add(24, 'hours');
        }

        const availability = await AvailabilityApi.getTotalAvailabilityConsecutivelyForUser(
            userId,
            startOfSlot,
            endOfSlot,
            params.consecutiveDays
        );

        let newContacts = state.contacts;
        const contactOnGrid = state.contacts.find((c) => c.contact.userId == userId);
        if (contactOnGrid) {
            newContacts = state.contacts.map((c) => {
                if (c.contact.userId == userId) {
                    return {
                        ...c,
                        contact: {
                            ...c.contact,
                            lastAvailabilityUpdateOn: moment().format()
                        },
                        occupancy: availability
                    };
                }
                return c;
            })
        } else {
            if (contactId) {
                const contactDetails = await ContactApi.getContactByContactId(contactId);
                
                if (contactDetails) {
                    newContacts = [{
                        contact: {
                            // avgRating
                            // confidenceScore
                            // distance,
                            // publicRating
                            // rating
                            profileImageUrl: ProfileApi.getProfileImageUrl(userId),
                            firstName: contactDetails.firstName,
                            fullName: contactDetails.firstName + " " + contactDetails.lastName,
                            headline:  contactDetails.headline,
                            id: contactDetails.id,
                            lastAvailabilityUpdateOn: contactDetails.lastAvailabilityUpdateOn,
                            lastName: contactDetails.lastName,
                            representedByOrganisations: contactDetails.representedByOrganisations,
                            userId: userId,
                            verified: contactDetails.verified,
                            hotLoaded: true,
                            // verifications: contactDetails.verifications
                        },
                        occupancy: availability
                    }].concat(state.contacts);
                    
                    (window as any).justHotLoaded = true;
                }
            }
        }

        dispatch({
            type: 'AVAILABILITY_DATA_USER.REQUEST.SUCCESS',
            contacts: newContacts
        });
    },
    toggleDayInSelection: (
        dayToToggle: DaySlotModel
    ): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({
            type: 'AVAILABILITY_SELECTION_DAY.TOGGLE',
            day: dayToToggle
        });
        // update cart
        dispatch({ type: 'AVAILABILITY_DETERMINE_SELECTION.INPROCESS' });
    },
    toggleContactInSelection: (
        contactToToggle: ContactAvailabilitySummaryModel
    ): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({
            type: 'AVAILABILITY_SELECTION_CONTACT.TOGGLE',
            contact: contactToToggle
        });
        // update cart
        dispatch({ type: 'AVAILABILITY_DETERMINE_SELECTION.INPROCESS' });
    },
    selectAllContacts: (): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'AVAILABILITY_SELECTION_CONTACT.ALL' });
    },
    clearSelectedContacts: (): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'AVAILABILITY_SELECTION_CONTACTS.CLEAR' });
    },
    clearSelectedDays: (): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'AVAILABILITY_SELECTION_DAYS.CLEAR' });
        dispatch({ type: 'AVAILABILITY_DETERMINE_SELECTION.INPROCESS' });
    },
    createOffer: (
        title: string,
        description: string,
        recipients: string[],
        events: PendingEventDto[]
    ): AppThunkAction<KnownAction> => (dispatch) => {
        OfferApi.createOffer({
            title: title,
            description: description,
            events: events,
            recipients: recipients,
            // TODO make possible
            sendSMS: false,
            deadline: Utilities.dateAdd(new Date(), "week", 1)
        }).then(() => {
            dispatch({ type: 'OFFER_CREATED.SUCCESS' });
        });
    },
    clearOffer: (): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'OFFER.CLEAR' });
        dispatch({ type: 'AVAILABILITY_DETERMINE_SELECTION.INPROCESS' });
    }
};

/**
 * Creates repeats of a slot for a given number of days
 * @param startOfSlot - The start of the slot
 * @param endOfSlot - The end of the slot
 * @param consecutiveDays - Number of consecutive days to repeat for
 */
function createSlotRange(
    startOfSlot: Moment,
    endOfSlot: Moment,
    consecutiveDays: number
): DaySlotModel[] {
    const dates: DaySlotModel[] = [];

    for (let i = 0; i < consecutiveDays; i++) {
        dates.push({
            start: startOfSlot.clone().add(i, 'days'),
            end: endOfSlot.clone().add(i, 'days')
        });
    }

    return dates;
}

const unloadedState: ContactAvailabilityState = {
    contacts: [],
    contactsSelected: [],
    days: [],
    daysSelected: [],
    calendarEvents: {
        events: [],
        loaded: false
    },
    searchParams: {
        start: moment().utc().startOf('day'),
        consecutiveDays: 1,
        days: [],
        groupId: 0,
        skills: [],
        timePreset: {
            id: '',
            name: '',
            startHour: 0,
            startMinute: 0,
            endHour: 0,
            endMinute: 0,
            startTime: '',
            endTime: '',
            userId: ''
        },
        sortOrder: AvailabilityOrderingType.LastUpdated,
        name: "",
        page: 1,
        limit: 25,
        updatedAfter: null, //moment(new Date(2022, 2, 20))
        postcode: null,
        representingAgencyId: "0"
    },
    loaded: false,
    selectionInProcess: false,
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
export const reducer: Reducer<ContactAvailabilityState | undefined> = (
    state: ContactAvailabilityState | undefined,
    incomingAction: Action
) => {
    if (state == undefined) return unloadedState;
    const action = incomingAction as KnownAction;

    switch (action.type) {
        case 'AVAILABILITY_DATA.REQUEST':
            return update(state, { loaded: { $set: false } });
        case 'AVAILABILITY_DATA.REQUEST.SUCCESS':
            return update(state, {
                days: { $set: action.days },
                contacts: { $set: (state.searchParams.page > 1) ? state.contacts.concat(action.contacts) : action.contacts },
                loaded: { $set: true },
                contactsSelected: {
                    $set: state.contactsSelected.filter(
                        ({ contact: { userId } }) =>
                            action.contacts
                                .map(({ contact }) => contact.userId)
                                .includes(userId)
                    )
                },
                stale: { $set: action.stale || false },
                lastFetched: { $set: action.lastFetched || undefined }
            });
        case 'AVAILABILITY_DATA_USER.REQUEST.SUCCESS':
            return update(state, {
                contacts: { $set: action.contacts }
            });
        case 'AVAILABILITY_OVERNIGHT.FILTER.CHANGE':
            return update(state, {
                searchParams: { overnight: { $set: action.overnight } }
            });
        case 'AVAILABILITY_START_DATE.FILTER.CHANGE':
            return update(state, {
                searchParams: { start: { $set: action.date }, page: { $set: 1 } },
                contacts: { $set: []}
            });
        case 'AVAILABILITY_NAME.FILTER.CHANGE':
            return update(state, {
                searchParams: { name: { $set: action.name }, page: { $set: 1 } },
                contacts: { $set: []}
            });
        case 'AVAILABILITY_REPRESENTED_BY_AGENCY.FILTER.CHANGE':
            return update(state, {
                searchParams: { representingAgencyId: { $set: action.representingAgencyId }, page: { $set: 1 } },
                contacts: { $set: []}
            });
        case 'AVAILABILITY_GROUP.FILTER.CHANGE':
            return update(state, {
                searchParams: { groupId: { $set: action.id }, page: { $set: 1 } },
                contacts: { $set: []}
            });
        case 'AVAILABILITY_SKILLS.FILTER.CHANGE':
            return update(state, {
                searchParams: { skills: { $set: JSON.parse(JSON.stringify(action.skills)) }, page: { $set: 1 } },
                contacts: { $set: []}
            });
        case 'AVAILABILITY_SORT_ORDER.FILTER.CHANGE':
            return update(state, {
                searchParams: { sortOrder: { $set: action.sortOrder }, hirerLocationId: { $set: undefined }, page: { $set: 1 } },
                contacts: { $set: []}
            });
        case 'AVAILABILITY_POSTCODE.FILTER.CHANGE':
            return update(state, {
                searchParams: { postcode: { $set: action.postcode }, page: { $set: 1 } },
                contacts: { $set: []}
            });
        case 'AVAILABILITY_PAGE.FILTER.CHANGE':
            return update(state, {
                searchParams: { page: { $set: action.page } }
            });
        case 'AVAILABILITY_HIRER_LOCATION_ID.FILTER.CHANGE':
            return update(state, {
                searchParams: { hirerLocationId: { $set: action.hirerLocationId}, sortOrder: { $set: AvailabilityOrderingType.LocationFrom },  page: { $set: 1 } },
                contacts: { $set: []}
            });
        case 'AVAILABILITY_TIME_PRESET.FILTER.CHANGE':
            return update(state, {
                searchParams: { timePreset: { $set: action.preset }, page: { $set: 1 } },
                contacts: { $set: []}
            });
        case 'AVAILABILITY_UPDATED.AFTER.FILTER.CHANGE':
            return update(state, {
                searchParams: { updatedAfter: { $set: action.updatedAfter }, page: { $set: 1 } },
                contacts: { $set: []}
            });
        case 'AVAILABILITY_SELECTION_DAY.TOGGLE':
            // add/remove the day appropriately
            return update(state, {
                daysSelected: {
                    $set: xorWith(
                        state.daysSelected,
                        [action.day],
                        (daySelected, dayInFocus) =>
                            daySelected.start.isSame(dayInFocus.start)
                    )
                }
            });
        case 'AVAILABILITY_DETERMINE_SELECTION.INPROCESS': {
            return update(state, {
                selectionInProcess: {
                    $set:
                        state.daysSelected.length > 0 ||
                        state.contactsSelected.length > 0
                }
            });
        }
        case 'AVAILABILITY_SELECTION_CONTACT.TOGGLE':
            // add/remove the contact appropriately
            return update(state, {
                contactsSelected: {
                    $set: xor(state.contactsSelected, [action.contact])
                }
            });
        case 'AVAILABILITY_SELECTION_CONTACT.ALL':
            return update(state, {
                contactsSelected: {
                    $set: state.contacts
                }
            });
        case 'AVAILABILITY_SELECTION_DAYS.CLEAR':
            return update(state, { daysSelected: { $set: [] } });
        case 'AVAILABILITY_SELECTION_CONTACTS.CLEAR':
            return update(state, { contactsSelected: { $set: [] } });
        case 'GET_CALENDAR_EVENTS.SUCCESS':
            const newState = update(state, {
                calendarEvents: {
                    $set: action.events
                },
                loaded: {
                    $set: action.events.loaded
                }
            });

            // update events and set title to event type
            state.calendarEvents.events = state.calendarEvents.events.map(
                (e) => {
                    e.title = e.eventType;
                    return e;
                }
            );

            return newState;
        case 'OFFER_CREATED.SUCCESS':
        case 'OFFER.CLEAR':
            return update(state, {
                contactsSelected: { $set: [] },
                daysSelected: { $set: [] }
            });
        default:
            exhaustiveCheck(action);
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || unloadedState;
};
