/* eslint-disable */
import React from 'react';
import { RouteComponentProps, RouteProps } from 'react-router-dom';

import AuthApi, { TokenStatus } from './Auth';
import { TrackedRoute } from '../../tracked-route';

type RouteComponent =
    | React.StatelessComponent<RouteComponentProps<{}>>
    | React.ComponentClass<any>;

export const AuthenticatedRoute: React.StatelessComponent<RouteProps> = ({
    component,
    ...rest
}: any) => {
    const renderFn = (Component?: RouteComponent) => (props: RouteProps) => {
        if (!Component) {
            return null;
        }
        if (AuthApi.getTokenStatus() == TokenStatus.Active || localStorage.getItem("access_token_otp")) {
            return <Component {...props} {...component} />;
        } else {
            AuthApi.login();
            return <div />;
        }
    };

    return <TrackedRoute {...rest} render={renderFn(component)} />;
};
