/* eslint-disable */
export async function getChannelIdByUserId(userId) {
    if (!window.localIndexedDb) return null;
    let row = await window.localIndexedDb.channelIdCache.where({ "userId": userId }).toArray();
    return row[0] ? row[0].channelId : null;
};

export async function saveChannels(channel) {
    if (!window.localIndexedDb) return [];
    const res = await window.localIndexedDb.channelIdCache.bulkPut(channel);
    return res;
}