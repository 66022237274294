/* eslint-disable */
import React from 'react';
import Utilities from 'src/Utilities';
import VerificationAPI from 'src/api/verification/Verification';
import UserApi from 'src/api/user/User';
import ProfileApi from 'src/api/profile/Profile';
import OrganisationApi from 'src/api/organisation/Organisation';
import CompanyApi from 'src/api/company/Company';
import FullScreenLoader from 'src/components/ui-components/FullScreenLoader';
import { DropdownButton, MenuItem, OverlayTrigger, Popover} from 'react-bootstrap';
import styled from 'styled-components';
import DayPicker from 'react-day-picker';

import '../css/ExternalVerification.css';
import { VerificationDto } from 'src/api/verification/ResponseTypes';
import { toast } from 'react-toastify';
import { Spinner } from 'src/components/ui-components/Spinner';
import { UserQualification } from 'src/api/contacts/ResponseTypes';
import SimpleTooltip from 'src/components/ui-components/SimpleTooltip';

// "workerContact": {
//     "id": "e7cea91d-ea81-4ceb-9d0b-6cc668ecad32",
//     "email": "hello@chillnstudy.com",
//     "firstName": "Dominik",
//     "lastName": "Gyecsek",
//     "phoneNumber": "447450264735"
// },
// "agencyContact": {
//     "id": "03F14573-E8A6-49DF-B701-15C43241930D",
//     "domain": "mailinator.com",
//     "hasOrganisation": false,
//     "firstName": "unagency contact6",
//     "organisationName": "unagency-6"
// }

interface Verification {
    start: Date | null;
    end: Date | null;
    typeId: number
}

export enum VerificationTypeId {
    Rejected = 0,
    Identity = 1,
    Representation = 2,
    Vetting = 3,
    Suspension = 4,
    Availability = 100, // not saved
    Suitability = 101 // not saved
}

export enum VerificationTrustId {
    VerifiedAgencyAccount = 50,
    VerifiedAgencyDomain = 40,
    UnknownPrivateEmailDomain = 30,
    UnknownPublicEmailDomain = 20,
    NotSet = 0
}

export default class ExternalVerification extends React.Component<{}, {
    workerContact: {
        id: string;
        userId: string;
        email: string;
        firstName: string;
        lastName: string;
        phoneNumber: string;
        headline: string;
        skills: string[];
        qualifications: UserQualification[];
    } | null;
    agencyContact: {
        id: string;
        domain: string;
        email: string;
        hasOrganisation: boolean;
        firstName: string;
        organisationName: string;
        organisationId: string;
    } | null;
    loading: boolean;
    expirationDate: Date;
    verifications: Verification[];
    selectableVerifications: number[];
    calendarIsOpen?: boolean;
    sharedAvailability: boolean;
    emailInputOne?: string;
    emailInputTwo?: string;
    telephoneNumberInput?: string;
    identityVerifiedWithServer?: boolean;
    errorShaking?: boolean;
    verificationLoading?: boolean;
    verificationError?: string;
    suitabilityConfirmed?: boolean;
    finalSuccessMessage: string | null;
    finalErrorMessage: string | null;
    rejectionComment: string | null;
}> {
    constructor(props) {
        super(props);
        this.state = {
            verificationLoading: false,
            verificationError: "",
            workerContact: null,
            agencyContact: null,
            loading: true,
            sharedAvailability: false,
            expirationDate: new Date(),
            verifications: [],
            selectableVerifications: [
                VerificationTypeId.Suitability,
                VerificationTypeId.Identity,
                VerificationTypeId.Availability,
                VerificationTypeId.Representation,
                VerificationTypeId.Vetting
            ],
            telephoneNumberInput: "",
            emailInputOne: "",
            emailInputTwo: "",
            errorShaking: false,
            identityVerifiedWithServer: false,
            suitabilityConfirmed: false,
            finalSuccessMessage: null,
            finalErrorMessage: null,
            rejectionComment: null
        };
    }

    componentDidMount() {
        this.loadDetails();
        const reactAppWrapper = document.querySelector('#react-app>div');
        if (reactAppWrapper) {
            reactAppWrapper.classList.add('external-verification-wrapper-page');
        }
    }

    toggleCalendar = (e) => {
        e && e.preventDefault();
        this.setState({ calendarIsOpen: !this.state.calendarIsOpen });
    }

    errorShake() {
        this.setState({ errorShaking: true }, () => {
            setTimeout(() => {
                this.setState({ errorShaking: false });
            }, 1500);
        });
    }

    async submitInformation(silent?: boolean, successOnFinished?: string, rejectedReason?: string) {
        const verifications = this.state.verifications;
        const token = Utilities.getParameterByName('token');
        this.setState({ loading: true })
        // @ts-ignore
        VerificationAPI.submitVerification(token || "", (verifications as VerificationDto[]).filter(item => item.typeId < 100), rejectedReason).then(data => {
            // todo reidect to signup if selected
            if (!silent) {
                toast.success('Verification information submitted successfully');
            }
            this.setState({ loading: false });
            if (successOnFinished) {
                this.setState({ finalSuccessMessage: successOnFinished })
            }
        }).catch(err => {
            if (!silent) {
                toast.error('Failed to submit verification information');
            }
            this.setState({ loading: false });
        })
    }

    async reject(id: string) {
        this.setState({ 
            loading: true,
            verifications: [{
                start: new Date(),
                end: null,
                typeId: VerificationTypeId.Rejected
            }]
        }, () => {
            this.submitInformation(true,  "We have let " + this.state.workerContact?.firstName + " know why you rejected their verification. They will be able to update their profile and ask you to verify it again.", id)
        })
    }

    async loadDetails() {
        let token = Utilities.getParameterByName('token');
        const details = await VerificationAPI.getFlags(token || "");
        if (details.data && details.data.workerContact && details.data.agencyContact && details.data.verifications) {
            if (details.data.verifications.find(v => v.typeId === VerificationTypeId.Rejected)) {
                this.setState({
                    finalErrorMessage: "You have already rejected this verification request. Ask " + details.data.workerContact.firstName + " to request it again",
                }) // todo need to clear when worker approves
            } else {
                this.setState({
                    workerContact: details.data.workerContact,
                    agencyContact: details.data.agencyContact,
                    expirationDate: new Date(details.data.tokenExpiration),
                    loading: false,
                    sharedAvailability: details.data.sharedAvailability,
                    verifications: details.data.verifications.map(v => {
                        return {
                            start: v.start ? new Date(v.start) : null,
                            end: v.end ? new Date(v.end) : null,
                            typeId: v.typeId
                        }
                    }),
                    identityVerifiedWithServer: details.data.verifications.length ? true : false,
                    suitabilityConfirmed: details.data.verifications.length ? true : false,
                    selectableVerifications: details.data.sharedAvailability ? 
                        [VerificationTypeId.Suitability, VerificationTypeId.Identity, VerificationTypeId.Representation, VerificationTypeId.Vetting] :
                        [VerificationTypeId.Representation, VerificationTypeId.Vetting]
                });
            }
        }
        console.log(token, details)
        // if (supportPrefillTextId === 'organisation-groups') {
        //     this.setState({
        //         comments: 'Hi, I would like to enquiry about Organisation-wide groups.',
        //         promptSend: true
        //     })
        // }
    }

    render() {
        const workerContact = this.state.workerContact;
        const agencyContact = this.state.agencyContact;
        const preCheckesCompleted = this.state.identityVerifiedWithServer && this.state.suitabilityConfirmed;

        return (
            <div className='external-verification-wrapper'>
                { (agencyContact && workerContact) &&
                    <div className='external-verification'>
                        <div className='expiration-header'>
                            This link expires in {Utilities.datediff(new Date(), this.state.expirationDate)} days
                        </div>
                        <div className='relationship-header'>
                            {/* <h2>“Safeguar your agency transfer fee {agencyContact?.organisationName}'s relationship with {workerContact?.firstName + " " + workerContact?.lastName}</h2> */}
                            <label className='fee-warning'>
                                <i className="fas fa-exclamation-triangle"></i>
                                <span>Protect your Introduction or Transfer fees for {workerContact?.firstName + " " + workerContact?.lastName}</span>
                            </label>
                            <div className='relationship-images'>
                                <img src={ProfileApi.getProfileImageUrl(workerContact?.userId || "")} alt='Worker' />
                                <i className="fas fa-link"></i>
                                <img src={agencyContact.organisationId ? CompanyApi.getOrganisationProfileImageUrl(agencyContact.organisationId) : OrganisationApi.getExtOrgPicture(agencyContact?.domain || "")} alt='Agency' />
                            </div>
                        </div>
                        <div className='worker-profile'>
                            <h3>{workerContact.firstName} {workerContact.lastName}</h3>
                            <p>{workerContact.headline}</p>
                            <div className='skills'>
                                {workerContact.skills.map((skill, index) => {
                                    return <span key={index}>{skill}</span>
                                })}
                            </div>
                            <div className='qualifications'>
                                {workerContact.qualifications.map((qualification, i) => {
                                    let PFormatted = "";
                                    let H3Formatted = "Empty Education";
                                    if (qualification.field) {
                                        H3Formatted = qualification.field;
                                        if (qualification.school) {
                                            H3Formatted += " (" + qualification.school + ")"
                                        }
                                    }
                                    if (qualification.degree || qualification.grade || qualification.endDate || qualification.description) {
                                        if (qualification.degree) PFormatted += " · " + qualification.degree;
                                        if (qualification.grade) PFormatted += " · " + qualification.grade;
                                        if (qualification.endDate && qualification.endDate.split("-").length === 2) {
                                            const month = parseInt(qualification.endDate.split("-")[1]);
                                            const shortMonth = month === 1 ? "Jan" : month === 2 ? "Feb" : month === 3 ? "Mar" : month === 4 ? "Apr" : month === 5 ? "May" : month === 6 ? "Jun" : month === 7 ? "Jul" : month === 8 ? "Aug" : month === 9 ? "Sep" : month === 10 ? "Oct" : month === 11 ? "Nov" : "Dec"
                                            PFormatted += " · " + shortMonth + " " + qualification.endDate.split("-")[0];
                                        }
                                        if (qualification.description) PFormatted += " · " + qualification.description;
                                        PFormatted = PFormatted.substr(2, PFormatted.length-1)
                                    }
                                    return (
                                        <span
                                        >
                                            {H3Formatted} {PFormatted}
                                        </span>
                                    )
                                })}
                            </div>
                        </div>
                        <div className='verifications'>
                            { (this.state.selectableVerifications.length > 0) && this.state.selectableVerifications.map((verification, index) => {
                                const verificationRecord = this.state.verifications.find(v => v.typeId === verification);
                                switch (verification) {
                                    case VerificationTypeId.Identity:
                                        return (
                                            <div style={ (!this.state.suitabilityConfirmed || this.state.identityVerifiedWithServer) ? { opacity: 0.4 } : { } } onClick={(e) => {
                                                if (!this.state.suitabilityConfirmed) {
                                                    this.errorShake();
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    return;
                                                }
                                            }} key={index} className='verification'>
                                                <div className='head checkbox-success'>
                                                    <label style={{ marginLeft: 0 }} onClick={() => {
                                                        const input = document.getElementById(`verify-${verification}`) as HTMLInputElement;
                                                        if (input) {
                                                            input.click();
                                                        }
                                                    }}>Verify {workerContact.firstName}'s Identity</label>
                                                </div>
                                                <p>Verify you know {workerContact.firstName}'s' by completing the missing characters in their email {(workerContact.phoneNumber && workerContact.phoneNumber[0] && workerContact.phoneNumber[1]) ? " or phone number" : ""}</p>
                                                <div data-error-shaking={this.state.errorShaking && this.state.suitabilityConfirmed} key={index} className='verification'>
                                                    <div className='body identity'>
                                                        { (!this.state.identityVerifiedWithServer) ?
                                                            <div className='sensitive-information'>
                                                                { (workerContact.phoneNumber && workerContact.phoneNumber[0] && workerContact.phoneNumber[1]) &&
                                                                    <React.Fragment>
                                                                        <div className='info'>
                                                                            <i className="fas fa-phone"></i>
                                                                            <span>+{workerContact.phoneNumber[0]}</span>
                                                                            <input type='text' value={this.state.telephoneNumberInput} onChange={e => {
                                                                                this.setState({ telephoneNumberInput: e.target.value });
                                                                            }} />
                                                                            <span>{workerContact.phoneNumber[1]}</span>
                                                                        </div>
                                                                        <div className='or'>
                                                                            <span>or</span>
                                                                        </div>
                                                                    </React.Fragment>
                                                                }
                                                                { (workerContact.email) &&
                                                                    <div className='info'>
                                                                        <i className="fas fa-envelope"></i>
                                                                        <span>{workerContact.email[0]}</span>
                                                                        <input type='text' value={this.state.emailInputOne} onChange={(e) => {
                                                                            this.setState({ emailInputOne: e.target.value });
                                                                        }} />
                                                                        <span>{workerContact.email[1]}@{workerContact.email[2]}</span>
                                                                        <input type='text' value={this.state.emailInputTwo} onChange={(e) => {
                                                                            this.setState({ emailInputTwo: e.target.value });
                                                                        }} />
                                                                        <span>{workerContact.email[3]}</span>

                                                                    </div>
                                                                }
                                                                { (this.state.verificationError) &&
                                                                    <div className='error'>
                                                                        {this.state.verificationError}
                                                                    </div>
                                                                }
                                                                { (this.state.verificationLoading) &&
                                                                    <Spinner text="Validating..." hide={false} />
                                                                }
                                                                <div className='options'>
                                                                    <button onClick={ async () => {
                                                                        this.setState({
                                                                            verificationError: ""
                                                                        })

                                                                        const filledPhoneNumber = this.state.telephoneNumberInput ? workerContact.phoneNumber[0] + this.state.telephoneNumberInput + workerContact.phoneNumber[1] : "";
                                                                        const filledEmail = this.state.emailInputOne && this.state.emailInputTwo ? (workerContact.email[0] +  this.state.emailInputOne + workerContact.email[1] + "@" + workerContact.email[2] + this.state.emailInputTwo + workerContact.email[3]) : "";
                                                                        let validated = false;

                                                                        if (!filledPhoneNumber && !filledEmail) {
                                                                            this.setState({
                                                                                verificationError: "Please fill in at least one of the above fields"
                                                                            })
                                                                            return;
                                                                        }

                                                                        this.setState({
                                                                            verificationLoading: true
                                                                        })

                                                                        if (filledPhoneNumber) {
                                                                            const isValid = await VerificationAPI.validateInformation(Utilities.getParameterByName('token')!, "phone", filledPhoneNumber).catch(err => {})
                                                                            if (isValid) {
                                                                                validated = true;
                                                                            }
                                                                        }

                                                                        if (filledEmail) {
                                                                            const isValid = await VerificationAPI.validateInformation(Utilities.getParameterByName('token')!, "email", filledEmail).catch(err => {})
                                                                            if (isValid) {
                                                                                validated = true;
                                                                            }
                                                                        }

                                                                        if (!validated) {
                                                                            this.setState({
                                                                                verificationError: "The information you provided does not match our records"
                                                                            })
                                                                        } else {
                                                                            this.setState({
                                                                                verifications: [...this.state.verifications, {
                                                                                    start: new Date(),
                                                                                    end: null,
                                                                                    typeId: VerificationTypeId.Identity
                                                                                }],
                                                                                identityVerifiedWithServer: true
                                                                            })
                                                                            toast.success('Information validated successfully');
                                                                        }
                                                                        this.setState({
                                                                            verificationLoading: false
                                                                        })
                                                                    }}>Verify Information</button>
                                                                    <button onClick={() => {
                                                                        this.reject("Couldn't verify your identity with the contact details I have on file");
                                                                    }}>I don't have any of the above information</button>
                                                                </div>
                                                            </div>
                                                            :
                                                            <div>
                                                                <div className='sensitive-information' data-completed="true">
                                                                    <div className='options'>
                                                                        <button>Profile Approved</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    case VerificationTypeId.Suitability:
                                        return (
                                            <div 
                                                style={ this.state.suitabilityConfirmed ? { opacity: 0.4 } : { } } 
                                                onClick={(e) => {
                                                    
                                                }}
                                                key={index}
                                                className='verification'
                                                data-error-shaking={this.state.errorShaking && !this.state.suitabilityConfirmed}
                                            >
                                                <div className='head checkbox-success'>
                                                    <label style={{ marginLeft: 0 }} onClick={() => {
                                                        const input = document.getElementById(`verify-${verification}`) as HTMLInputElement;
                                                        if (input) {
                                                            input.click();
                                                        }
                                                    }}>
                                                        Suitable profile?
                                                        <SimpleTooltip
                                                            id={"unsuitable-profile-help"}
                                                            text={<div style={{ textAlign: 'left' }}>
                                                                <div><strong>A suitable profile consists of:</strong></div>
                                                                <div><strong>Name</strong>: Is accurate</div>
                                                                <div><strong>Profile image</strong>: clearly showing their face (80% head, 20% shoulders)</div>
                                                                <div><strong>Headline</strong>: is descriptive e.g. “Qualified Teacher Maths & Physics to A level with 10 years of experience”</div>
                                                                <div><strong>Qualifications</strong>: PGCE added if a teacher, 1st Degree added. Teaching assistants may not have qualifications</div>
                                                                <div><strong>Skills</strong>: General Cover, PPA, Science A Level</div>
                                                            </div>}
                                                        >
                                                            <i className="fas fa-question-circle" style={{ margin: '0 0 0 10px' }}></i>
                                                        </SimpleTooltip>
                                                    </label>
                                                </div>
                                                <p>{workerContact.firstName} has asked your organisation to confirm their profile is suitable for verification.</p>
                                                <div className='options'>
                                                    { (this.state.rejectionComment === null) &&
                                                        <React.Fragment>
                                                            <button 
                                                                className='suitable' 
                                                                onClick={() => {
                                                                    this.setState({
                                                                        verifications: [...this.state.verifications, {
                                                                            start: new Date(),
                                                                            end: null,
                                                                            typeId: verification
                                                                        }],
                                                                        suitabilityConfirmed: true
                                                                    });
                                                                }}
                                                                style={ this.state.suitabilityConfirmed ? { flexBasis: '100%', margin: 0 } : { } }
                                                            >
                                                                {this.state.suitabilityConfirmed ? "Profile Suitable" : "Suitable"}
                                                            </button>
                                                            { (!this.state.suitabilityConfirmed) &&
                                                                <DropdownButton
                                                                    id="chat-filter"
                                                                    title="Unsuitable"
                                                                    className='unsuitable'
                                                                >
                                                                    {[
                                                                        { id: "unknown-person", text: "This person is unknown" },
                                                                        { id: "unknown-me", text: "I don't work with this agency" },
                                                                        { id: "inappropriate-incorrect-image", text: "Image unsuitable" },
                                                                        { id: "inappropriate-incorrect-bio", text: "Headline unsuitable" },
                                                                        { id: "inappropriate-incorrect-qulifications", text: "Qualifications unsuitable" },
                                                                        { id: "inappropriate-incorrect-skills", text: "Skills unsuitable" },
                                                                        { id: "inappropriate-incorrect-name", text: "Name unsuitable" },
                                                                        { id: "other", text: "Other" }
                                                                    ].map(
                                                                        (opt) => (
                                                                            <MenuItem
                                                                                onSelect={() => {
                                                                                    // this.reject(opt.id);
                                                                                    this.setState({
                                                                                        rejectionComment: opt.id !== "other" ? opt.text : ""
                                                                                    })
                                                                                }}
                                                                                key={opt + ""}
                                                                            >
                                                                                { opt.text }
                                                                            </MenuItem>
                                                                        )
                                                                    )}
                                                                </DropdownButton>
                                                            }
                                                        </React.Fragment>
                                                    }
                                                    { (this.state.rejectionComment !== null) &&
                                                        <React.Fragment>
                                                            <div className='more-comments-textbox'>
                                                                <textarea
                                                                    rows={4}
                                                                    placeholder='Add more comments'
                                                                    value={this.state.rejectionComment || ""}
                                                                    onChange={e => {
                                                                        this.setState({ rejectionComment: e.target.value });
                                                                    }}
                                                                />
                                                            </div>
                                                            <button 
                                                                className='suitable' 
                                                                onClick={() => {
                                                                    this.setState({
                                                                        verifications: [...this.state.verifications, {
                                                                            start: new Date(),
                                                                            end: null,
                                                                            typeId: verification
                                                                        }],
                                                                        suitabilityConfirmed: true,
                                                                        rejectionComment: null
                                                                    });
                                                                }}
                                                            >
                                                                Actually Suitable
                                                            </button>
                                                            <button
                                                                className='unsuitable'
                                                                onClick={() => {
                                                                    this.reject(this.state.rejectionComment || "Not Specified");
                                                                }}
                                                            >
                                                                Confirm Rejection
                                                            </button>
                                                        </React.Fragment>
                                                    }
                                                </div>
                                            </div>
                                        );
                                    case VerificationTypeId.Representation:
                                        return (
                                            <div onClick={(e) => {
                                                if (!preCheckesCompleted) {
                                                    this.errorShake();
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    return;
                                                }
                                            }} key={index} className='verification' style={ !preCheckesCompleted ? { opacity: 0.4 } : { } }>
                                                <div className='head checkbox-success'>
                                                    <div className='input-checkbox-wrapper'>
                                                        <input
                                                            type='checkbox'
                                                            id={`verify-${verification}`}
                                                            name={`verify-${verification}`}
                                                            checked={verificationRecord ? true : false}
                                                            onChange={(e) => {
                                                                if (preCheckesCompleted) {
                                                                    if (e.target.checked) {
                                                                        this.setState({
                                                                            verifications: [...this.state.verifications, {
                                                                                start: new Date(),
                                                                                end: null,
                                                                                typeId: verification
                                                                            }]
                                                                        });
                                                                    } else {
                                                                        this.setState({
                                                                            verifications: this.state.verifications.filter(v => v.typeId !== verification)
                                                                        });
                                                                    }
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                    <label onClick={() => {
                                                        const input = document.getElementById(`verify-${verification}`) as HTMLInputElement;
                                                        if (input) {
                                                            input.click();
                                                        }
                                                    }}>{agencyContact.organisationName} confirms current representation of {workerContact.firstName}</label>
                                                </div>
                                                <p>Fees related to a booking shall be arranged between {agencyContact.organisationName} and hirers. You agree you are actively representing this worker</p>
                                                { (verificationRecord) &&
                                                    <div className='date-selector-wrapper'>
                                                        <span>Start of Representation:</span>
                                                        <OverlayTrigger
                                                            rootClose
                                                            container={document.getElementById(
                                                                'calendar-toggle'
                                                            )}
                                                            trigger="click"
                                                            placement="top"
                                                            overlay={
                                                                <Popover
                                                                    id="daypicker-popover"
                                                                    className="popover-no-margin"
                                                                >
                                                                    <DayPickerWrapper>
                                                                        <DayPicker
                                                                            initialMonth={verificationRecord ? new Date(verificationRecord.start || "") : new Date()}
                                                                            firstDayOfWeek={1}
                                                                            selectedDays={verificationRecord ? new Date(verificationRecord.start || "") : new Date()}
                                                                            onDayClick={(day: Date) => {
                                                                                this.setState({
                                                                                    verifications: this.state.verifications.map(v => {
                                                                                        if (v.typeId === verification) {
                                                                                            return {
                                                                                                ...v,
                                                                                                start: day
                                                                                            }
                                                                                        }
                                                                                        return v;
                                                                                    })
                                                                                });
                                                                            }}  
                                                                            onTodayButtonClick={() => {
                                                                                this.setState({
                                                                                    verifications: this.state.verifications.map(v => {
                                                                                        if (v.typeId === verification) {
                                                                                            return {
                                                                                                ...v,
                                                                                                start: new Date()
                                                                                            }
                                                                                        }
                                                                                        return v;
                                                                                    })
                                                                                });
                                                                            }}
                                                                            todayButton="Today"
                                                                            onBlur={this.toggleCalendar.bind(this)}
                                                                        />
                                                                    </DayPickerWrapper>
                                                                </Popover>
                                                            }
                                                        >
                                                            <button
                                                                onClick={this.toggleCalendar}
                                                            >
                                                                {verificationRecord ? Utilities.formatDate(new Date(verificationRecord.start || ""), "DD MMM YYYY") : "Select Start Date"}
                                                            </button>
                                                        </OverlayTrigger>
                                                    </div>
                                                }
                                            </div>
                                        );
                                    case VerificationTypeId.Vetting:
                                        return (
                                            <div onClick={(e) => {
                                                if (!preCheckesCompleted) {
                                                    this.errorShake();
                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                    return;
                                                }
                                            }} key={index} className='verification' style={ !preCheckesCompleted ? { opacity: 0.4 } : { } }>
                                                <div className='head checkbox-success'>
                                                    <div className='input-checkbox-wrapper'>
                                                        <input
                                                            type='checkbox'
                                                            id={`verify-${verification}`}
                                                            name={`verify-${verification}`}
                                                            checked={verificationRecord ? true : false}
                                                            onChange={(e) => {
                                                                if (preCheckesCompleted) {
                                                                    if (e.target.checked) {
                                                                        this.setState({
                                                                            verifications: [...this.state.verifications, {
                                                                                start: new Date(),
                                                                                end: null,
                                                                                typeId: verification
                                                                            }]
                                                                        });
                                                                    } else {
                                                                        this.setState({
                                                                            verifications: this.state.verifications.filter(v => v.typeId !== verification)
                                                                        });
                                                                    }
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                    <label onClick={() => {
                                                        const input = document.getElementById(`verify-${verification}`) as HTMLInputElement;
                                                        if (input) {
                                                            input.click();
                                                        }
                                                    }}>{agencyContact.organisationName} confirms {workerContact.firstName} Safeguarding checks are complete & up to date. </label>
                                                </div>
                                                <p>If {workerContact.firstName} is not cleared safeguarding / vetted checks please leave this box clear.</p>
                                                { (verificationRecord) &&
                                                    <div className='date-selector-wrapper'>
                                                        <span>Last Vetting:</span>
                                                        <OverlayTrigger
                                                            rootClose
                                                            container={document.getElementById(
                                                                'calendar-toggle'
                                                            )}
                                                            trigger="click"
                                                            placement="top"
                                                            overlay={
                                                                <Popover
                                                                    id="daypicker-popover"
                                                                    className="popover-no-margin"
                                                                >
                                                                    <DayPickerWrapper>
                                                                        <DayPicker
                                                                            initialMonth={verificationRecord ? new Date(verificationRecord.start || "") : new Date()}
                                                                            firstDayOfWeek={1}
                                                                            selectedDays={verificationRecord ? new Date(verificationRecord.start || "") : new Date()}
                                                                            onDayClick={(day: Date) => {
                                                                                this.setState({
                                                                                    verifications: this.state.verifications.map(v => {
                                                                                        if (v.typeId === verification) {
                                                                                            return {
                                                                                                ...v,
                                                                                                start: day
                                                                                            }
                                                                                        }
                                                                                        return v;
                                                                                    })
                                                                                });
                                                                            }}  
                                                                            onTodayButtonClick={() => {
                                                                                this.setState({
                                                                                    verifications: this.state.verifications.map(v => {
                                                                                        if (v.typeId === verification) {
                                                                                            return {
                                                                                                ...v,
                                                                                                start: new Date()
                                                                                            }
                                                                                        }
                                                                                        return v;
                                                                                    })
                                                                                });
                                                                            }}
                                                                            todayButton="Today"
                                                                            onBlur={this.toggleCalendar.bind(this)}
                                                                        />
                                                                    </DayPickerWrapper>
                                                                </Popover>
                                                            }
                                                        >
                                                            <button
                                                                onClick={this.toggleCalendar}
                                                            >
                                                                {verificationRecord ? Utilities.formatDate(new Date(verificationRecord.start || ""), "DD MMM YYYY") : "Select Last Vet Date"}
                                                            </button>
                                                        </OverlayTrigger>
                                                    </div>
                                                }
                                            </div>
                                        );
                                    default:
                                        return null;
                                }
                            }) }
                        </div>
                        <button
                            className='main'
                            style={preCheckesCompleted || this.state.verifications.find(v => v.typeId === VerificationTypeId.Availability) ? { opacity: 1 } : { opacity: 0.4 }}
                            onClick={ async () => {
                                if (preCheckesCompleted || this.state.verifications.find(v => v.typeId === VerificationTypeId.Availability)) {
                                    if (preCheckesCompleted) {
                                        await this.submitInformation(true, "Thanks for submitting the information.  Sign up with updatedge today to get more from your workers and actively manage your representation"); 
                                    }
                                    // if (this.state.verifications.find(v => v.typeId === VerificationTypeId.Availability)) {
                                    //     setTimeout(() => {
                                    //         localStorage.setItem('signUpEmailHint', agencyContact.email);
                                    //         window.location.href = '/';
                                    //     }, 1500)
                                    // }
                                } else {
                                    this.setState({ errorShaking: true });
                                    setTimeout(() => {
                                        this.setState({ errorShaking: false });
                                    }, 1500);
                                }
                            }}
                        >
                            Submit Information{ this.state.verifications.find(v => v.typeId === VerificationTypeId.Availability) ? " and Sign Up" : "" }
                        </button>
                    </div>
                }
                <FullScreenLoader 
                    success={this.state.finalSuccessMessage !== null} 
                    error={this.state.finalErrorMessage !== null}
                    successMessage={this.state.finalSuccessMessage || ""} 
                    errorMessage={this.state.finalErrorMessage || ""}
                    noLoadingMessage={this.state.finalSuccessMessage !== null || this.state.finalErrorMessage !== null} 
                    loadingMessage={this.state.loading ? "Loading ..." : ""} 
                    ctaButtonStr={this.state.finalSuccessMessage !== null ? "Sign Up" : undefined}
                    ctaButtonOnClick={() => {
                        if (this.state.agencyContact) {
                            localStorage.setItem('signUpEmailHint', this.state.agencyContact.email);
                        }
                        window.location.href = '/';
                    }}
                    bottomImg={this.state.finalSuccessMessage !== null ? "/img/mac.png" : undefined}
                />
            </div>
        );
    }
}

const DayPickerWrapper = styled.div`
    .DayPicker-wrapper {
        z-index: 2;
        padding-bottom: 0;
        background: white;
    }

    .DayPicker-Footer {
        padding: 5px 0;
        font-weight: bold;
        text-align: center;
        background: #f0f0f0;
        border-top: 1px solid #aeaeae;
        cursor: pointer;

        button {
            width: 100%;
            color: #000;
        }
    }

    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
        background-color: #34495e;
    }
`;