/* eslint-disable */
import React from 'react';
import styled from 'styled-components';
import { Image, Button, Glyphicon } from 'react-bootstrap';
import { connect, ConnectedProps } from 'react-redux';
import { Link } from 'react-router-dom';

import history from '../../history';
import { Subscribe } from '../../unstated-fork/unstated';
import { ApplicationState } from '../../store';
import * as ProfileStore from '../../store/Profile';
import AuthApi from '../auth/Auth';
import Analytics from '../../services/analytics.service';
import { ProfileVerificationContainer } from '../../state-containers/profile-verification.container';
import theme from '../../css/theme';
import SimpleTooltip from '../ui-components/SimpleTooltip';
import { StorageKeys } from '../../constants';
import Utilities from 'src/Utilities';

type NavProfileProps = {
    isWorker?: boolean;
} & ConnectedProps<typeof connector>;

type NavProfileState = { mouseOverProfile: boolean };

interface AuthProviderInfo {
    name: string;
    iconVisible: boolean;
    iconClass: string;
}

class NavProfile extends React.Component<NavProfileProps, NavProfileState> {
    /** Executes after mounting */
    public componentDidMount() {
        this.props.getUserProfile();
    }

    constructor(props) {
        super(props);
        this.state = { mouseOverProfile: false };
    }

    /** Returns formatting info for the logged in auth provider */
    getAuthProviderInfo(
        authProvider: ProfileStore.AuthProviderType
    ): AuthProviderInfo {
        switch (authProvider) {
            case 'facebook.com':
                return {
                    name: authProvider,
                    iconClass: 'fab fa-facebook-f',
                    iconVisible: true
                };
            case 'twitter.com':
                return {
                    name: authProvider,
                    iconClass: 'fab fa-twitter',
                    iconVisible: true
                };
            case 'linkedin':
                return {
                    name: authProvider,
                    iconClass: 'fab fa-linkedin-in',
                    iconVisible: true
                };
            default:
                return {
                    name: 'updatedge',
                    iconClass: 'fas fa-user-circle',
                    iconVisible: true
                };
        }
    }

    /** Shortens text to the specified number of chars and appends an ellipsis (...) */
    private ellipsisText(text: string, chars: number) {
        return text !== undefined ? text.substring(0, chars) + '...' : text;
    }

    private logout = async () => {
        await Utilities.destroyStorage();
        if (localStorage.getItem("access_token_otp")) {
            localStorage.removeItem("access_token_otp");
            window.location.href = "/"
        } else if (localStorage.getItem('impersonatedAccessToken')) {
            localStorage.removeItem('impersonatedAccessToken');
            localStorage.removeItem('impersonatedAccessTokenExpired');
            localStorage.removeItem("user");
            window.location.href = '/internal/users';
        } else {
            Analytics.trackEvent('profile-logout-clicked');
            AuthApi.policyLogout();
        }
    };

    private profileImageLeave = () => {
        this.setState({ mouseOverProfile: false });
        //console.log(history);
    };

    private profileImageOver = () => {
        this.setState({ mouseOverProfile: true });
        //console.log(history);
    };

    public render() {
        const profile = this.props.authenticatedUserProfile;

        const authproviderInfo: AuthProviderInfo = this.getAuthProviderInfo(
            profile.authProvider
        );

        return (
            <Subscribe to={[ProfileVerificationContainer]}>
                {(verification: ProfileVerificationContainer) => (
                    <Wrapper className="profile-picture">
                        <div className="profile-head">
                            <Link to="/profile" style={{ position: 'relative' }}>
                                {/**
                                We have to append the profileImageUpdatedAt to ensure that React reloads the image
                                This is a bit hacky and the server does not make use of the query parameter
                                Using React's key prop would be better but that does not seem to work
                                */}
                                {profile.profileImageUrl && (
                                    <Image
                                        src={profile.profileImageUrl}
                                        circle
                                        className="m-b"
                                        alt="Profile Image"
                                        onMouseOver={this.profileImageOver}
                                        onFocus={this.profileImageOver}
                                        onMouseOut={this.profileImageLeave}
                                        style={{
                                            width: 40,
                                            height: 40
                                        }}
                                    />
                                )}
                                {!this.props.isWorker && (
                                    <div
                                        className={
                                            'layout vertical center-center ' +
                                            (verification.state.verified
                                                ? 'verified'
                                                : 'unverified')
                                        }
                                        style={{ 
                                            cursor: 'default',
                                            position: 'absolute',
                                            bottom: -5,
                                            right: -5
                                        }}
                                    >
                                        <SimpleTooltip
                                            id="profile-verify-status"
                                            text={`Your profile has ${
                                                !verification.state.verified
                                                    ? 'not yet'
                                                    : ''
                                            } been verified`}
                                            delay={200}
                                        >
                                            {verification.state.verified ? (
                                                <i className="fa fa-shield" style={{ fontSize: 20 }} />
                                            ) : (
                                                <Link to="/profile">
                                                    <i className="fa fa-shield" style={{ fontSize: 20 }} />
                                                </Link>
                                            )}
                                        </SimpleTooltip>
                                        {/* <span id="verify-status" className="verify-status">
                                            {verification.state.verified
                                                ? 'Verified'
                                                : 'Unverified'}
                                        </span> */}
                                    </div>
                                )}
                                {this.state.mouseOverProfile &&
                                    history.location.pathname != '/profile' && (
                                        <div
                                            style={{
                                                position: 'absolute',
                                                top: '0%',
                                                left: 0,
                                                pointerEvents: 'none',
                                                width: '100%'
                                            }}
                                        >
                                            <Button
                                                bsClass="xs btn-primary"
                                                className="text-muted updateButton"
                                                style={{
                                                    pointerEvents: 'none'
                                                }}
                                            >
                                                Update
                                            </Button>
                                        </div>
                                    )}
                            </Link>
                            <div>
                                <div className="stats-label text-color">
                                    { (profile.firstName !== "QUICK" && profile.lastName !== "SIGNUP") &&
                                        <span
                                            className="font-extra-bold font-uppercase" 
                                            style={{ wordBreak: 'break-word'}}
                                        >
                                            {profile.firstName} {profile.lastName}
                                        </span>
                                    }
                                </div>
                                <div className="dropdown">
                                    <span>
                                        <small
                                            className="text-muted"
                                            style={{
                                                wordBreak: 'break-word'
                                            }}
                                            id="navigation-email-address"
                                        >
                                            {profile.emailAddress}
                                        </small>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="profile-head-2">
                            <div>
                                <Button
                                    bsClass="xs btn-primary"
                                    onClick={this.logout}
                                    id="logout-btn"
                                    className="text-muted"
                                    style={{
                                        border: 'none',
                                        padding: '6px 6px',
                                        borderRadius: '5px',
                                        position: 'absolute',
                                        top: -43,
                                        right: 8
                                    }}
                                >
                                    LOG OUT
                                    <i
                                        className='fas fa-sign-out-alt'
                                        style={{
                                            marginLeft: 6,
                                            display: 'inline-block'
                                        }}
                                    ></i>
                                </Button>
                            </div>
                        </div>
                    </Wrapper>
                )}
            </Subscribe>
        );
    }
}

const Wrapper = styled.div`
    .fa-shield {
        font-size: 30px;
    }

    div.unverified {
        color: ${theme.colours.red};
        cursor: pointer;
    }

    .verify-status {
        font-weight: 600;
        font-size: 12px;
    }

    div.verified {
        color: ${theme.colours.green};
    }

    div.verified a,
    div.unverified a {
        color: inherit;
    }

    @media (max-width: 480px) {
        padding: 10px;

        .fa-shield {
            font-size: 20px;
        }
    }
`;

const connector = connect(
    (state: ApplicationState) => state.profile,
    ProfileStore.actionCreators
);

export default connector(NavProfile);
