/* eslint-disable */
import React from 'react';
import { Button } from 'react-bootstrap';
import { connect, ConnectedProps } from 'react-redux';
import { ApplicationState } from '../store';

import ContactList from '../components/contacts/ContactList';
import ContactLists from '../components/contacts/ContactLists';
import * as ContactActionCreators from '../store/contacts/ActionCreators';
import InternalTracker from '../InternalTracker';
import theme from '../css/theme';

export class ContactManagement extends React.Component<
    ConnectedProps<typeof connector>,
    {}
> {
    getSelectedGroupName() {
        return this.props.listMgtState.selected.name;
    }

    refreshLists = () => {
        this.props.getLists();
        InternalTracker.trackEvent("Contact Managmeent List Refreshed")
    };

    refreshList = () => {
        this.props.getContactsInList(this.props.listMgtState.selected.id);
        InternalTracker.trackEvent("Contact Managmeent Lists Refreshed")
    };

    public render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-4">
                        <div className="hpanel">
                            <div className="panel-body">
                                <h2
                                    style={{
                                        fontWeight: 700,
                                        margin: '0 0 12px 0',
                                        fontSize: '1.6em'
                                    }}
                                >Contact Groups</h2>
                                <small
                                    style={{
                                        marginBottom: 16,
                                        fontSize: '1em',
                                        display: 'block'
                                    }}
                                >
                                    Create Groups containing one or more contacts. Share Groups between Teams. Use Groups to filter contacts for availability and offers.
                                </small>
                                <ContactLists />
                                { (!this.props.listMgtState.creating) &&
                                    <Button
                                        style={{
                                            background: theme.colours.blue2,
                                            display: 'block',
                                            width: '100%',
                                            color: 'white',
                                            borderRadius: 6,
                                            marginTop: 12,
                                            padding: 10
                                        }}
                                        onClick={() =>
                                            this.props.newListStart()
                                        }
                                    >
                                        <i 
                                            className="fa fa-plus" 
                                            style={{
                                                marginRight: 8,
                                            }}
                                            id="add-new-group-btn"
                                        /> Add New Group
                                    </Button>   
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <div className="hpanel">
                            <div className="panel-body">
                                <ContactList />
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const connector = connect(
    (state: ApplicationState) => state.contactManagement,
    ContactActionCreators.actionCreators
);

export default connector(ContactManagement);
