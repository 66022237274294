/* eslint-disable */
import React from 'react';
import { Modal, Button, Table } from 'react-bootstrap';
import { nameofFactory, KeyCodes } from '../../constants';
import * as ContactAvailabilityStore from '../../store/Availability';
import ProfileApi from '../../api/profile/Profile';
import CompanyApi from '../../api/company/Company';
import ReferralApi from '../../api/referrals/Referrals';
import { UserThirdParty } from 'src/api/profile/ResponseTypes';
import Utilities from 'src/Utilities';
import { ProfileImage } from '../ui-components/ProfileImage';
import theme from 'src/css/theme';
import { toast } from 'react-toastify';
import '../../css/ReferContactModal.css'
import { getValue, setValue } from 'src/db/KeyValueCache';
import { ReferralContact } from 'src/api/referrals/RequestTypes';
import { MergedOrganisationDTO } from 'src/api/organisation/ResponseTypes';
import { BulkReferralOutcome } from 'src/api/referrals/ResponseTypes';

interface BulkReferralOutcomeWithReferredTo extends BulkReferralOutcome {
    referredToId: string;
    referredToName: string;
}

type ReferProps = {
    contacts: ContactAvailabilityStore.ContactAvailabilitySummaryModel['contact'][];
    onClose: () => void;
}

interface LastReferredContact {
    userId?: string;
    name?: string;
    email?: string;
}

interface UserThirdPartyWithLastReferred extends UserThirdParty {
    lastReferred?: number;
}

interface ManualContactEntry {
    email: string;
    firstName: string;
    lastName: string;
}

interface ReferState {
    selectedContactsManual: ManualContactEntry[];
    editedContactManual: ManualContactEntry;
    selectedContactsUserIds: string[];
    contactList: UserThirdPartyWithLastReferred[] | null;
    lastReferredContact: LastReferredContact | null;
    sendingReferral: boolean;
    processedReferrals: number;
    keywords: string;
    allReferralsCount: number;
    contactListExtensions: {
        organisationName: string;
        organisationId?: string | null;
        contactUserId?: string | null;
    }[];
    outcome: BulkReferralOutcomeWithReferredTo[];
}

const nameof = nameofFactory<ReferState>();

class ReferContactModal extends React.Component<ReferProps, ReferState> {
    constructor(props) {
        super(props);
        this.state = {
            selectedContactsUserIds: [],
            contactListExtensions: [],
            selectedContactsManual: [],
            editedContactManual: {
                email: '',
                firstName: '',
                lastName: ''
            },
            contactList: null,
            lastReferredContact: localStorage.getItem('lastReferred') ? JSON.parse(localStorage.getItem('lastReferred') || "{}") as LastReferredContact : null,
            sendingReferral: false,
            processedReferrals: 0,
            keywords: '',
            allReferralsCount: 0,
            outcome: []
        };
    }

    async componentDidMount() {
        const cachedReceivedThirdParties = await getValue('receivedThirdParties');
        if (cachedReceivedThirdParties) {
            this.setState({
                contactList: cachedReceivedThirdParties as UserThirdPartyWithLastReferred[]
            });
        }
        const receivedThirdParties = await ProfileApi.getReceivedThirdParties();
        if (receivedThirdParties.data) {
            const contactList = receivedThirdParties.data
                .sort(Utilities.dynamicSort("organisationId"))
                .filter((contact, index, self) =>
                index === self.findIndex((t) => (
                    t.contactUserId === contact.contactUserId
                )))
            setValue('receivedThirdParties', contactList);
            this.setState({
                contactList: contactList       
            });
        }
        const lastOrganisationSearchSelect = localStorage.getItem('lastOrganisationSearchSelect') ? JSON.parse(localStorage.getItem('lastOrganisationSearchSelect') || "{}") as MergedOrganisationDTO : null;
        if (lastOrganisationSearchSelect) {
            if (lastOrganisationSearchSelect.adminUserId) {
                toast.info("Organisation autoselected, please check and confirm");
                const existingRecord = this.state.contactList?.find(c => c.contactUserId === lastOrganisationSearchSelect.adminUserId);
                if (!existingRecord) {  
                    this.setState({
                        contactListExtensions: [{
                            organisationName: lastOrganisationSearchSelect.name,
                            organisationId: lastOrganisationSearchSelect.id,
                            contactUserId: lastOrganisationSearchSelect.adminUserId
                        }],
                        selectedContactsUserIds: [lastOrganisationSearchSelect.adminUserId]
                    })
                } else {
                    this.setState({
                        selectedContactsUserIds: [lastOrganisationSearchSelect.adminUserId]
                    })
                }
            } else if (lastOrganisationSearchSelect.email) {
                toast.info("Email address autofilled, please check and confirm");
                this.setState({
                    editedContactManual: {
                        ...this.state.editedContactManual,
                        email: lastOrganisationSearchSelect.email
                    }
                })
            }
        }
    }

    async sendReferral() {

        localStorage.removeItem("lastOrganisationSearchSelect")

        // @ts-ignore
        let allReferrals = this.state.selectedContactsManual.concat(this.state.selectedContactsUserIds);

        this.setState({
            sendingReferral: true,
            processedReferrals: 0,
            allReferralsCount: allReferrals.length
        })

        let results: BulkReferralOutcomeWithReferredTo[] = [];

        for (let j = 0; j < allReferrals.length; j++) {
            // @ts-ignore
            const hirer = typeof allReferrals[j] === 'string' ? this.state.contactList?.find(c => c.contactUserId === allReferrals[j]) : allReferrals[j];
            console.log(hirer);

            // @ts-ignore
            const response = await ReferralApi.sendBulkReferral(
                this.props.contacts.map(worker => { return { firstName: worker.firstName, lastName: worker.lastName, userId: worker.userId, type: 1 } }) as ReferralContact[], 
                // @ts-ignore
                (hirer.contactUserId ? { userId: hirer.contactUserId, type: 2 } : { email: hirer?.email, firstName: hirer.firstName, lastName: hirer.lastName, type: 2, userId: '' }) as ReferralContact
            ).catch((error) => {
                toast.error("Error occurred while sending referrals");
            })

            if (response) {
                results = results.concat(response.map((result) => {
                    return {
                        ...result,
                        // @ts-ignore
                        referredToId: hirer.contactUserId || "undefined",
                        // @ts-ignore
                        referredToName: hirer.contactUserId ? hirer.primaryContactName : (hirer.firstName + " " + hirer.lastName)
                    }
                }))
            }

            this.setState({
                processedReferrals: this.state.processedReferrals + 1
            })
        }

        this.setState({
            sendingReferral: false
        })

        if (!results.length) {
            this.props.onClose();
        } else {
            this.setState({
                outcome: results
            })
        }
    }

    public render() {
        if (this.state.outcome.length > 0) {
            return (
                <Modal
                    show={true}
                    onHide={() => {
                        this.setState({
                            outcome: []
                        })
                        this.props.onClose();
                    }}
                    dialogClassName="modal-dialog-compact"
                    className='refer-contact-outcome-modal'
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Referral Outcome</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Referred Contact</th>
                                    <th>Referred To Contact</th>
                                    <th>Outcome</th>
                                </tr>
                            </thead>
                            <tbody>
                                { this.state.outcome.map((outcome) => {
                                    return (
                                        <tr>
                                            <td>
                                                <img
                                                    src={ProfileApi.getProfileImageUrl(outcome.id)}
                                                />
                                                <span>{outcome.name}</span>
                                            </td>
                                            <td>
                                                <img
                                                    src={ProfileApi.getProfileImageUrl(outcome.referredToId)}
                                                />
                                                <span>{outcome.referredToName}</span>
                                            </td>
                                            <td>
                                                <i className={"fa fa-" + (outcome.success ? "check" : "times")} style={{ color: outcome.success ? theme.colours.green2 : theme.colours.red2 }} />
                                                <span>{outcome.success ? "Referral Sent" : (outcome.error || "Unknown Error")}</span>
                                            </td>
                                        </tr>
                                    )
                                }) }
                            </tbody>
                        </Table>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            bsSize="small"
                            bsStyle="success"
                            onClick={() => {
                                this.setState({
                                    outcome: []
                                })
                                this.props.onClose();
                            }}
                        >
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            )
        }

        return (
            <Modal
                show={true}
                onHide={() => {
                    this.props.onClose();
                }}
                dialogClassName="modal-dialog-compact"
                className='refer-contact-modal'
            >
                <Modal.Header closeButton>
                    <Modal.Title>Select Recipients</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    { (!this.state.contactList) ?
                        <div className="text-center">
                            <p>Loading Hirers that your agency represents</p>
                            <i className="fa fa-spinner fa-spin" />
                        </div>
                        :
                        <React.Fragment>
                            <h2>
                                Add a contact manually
                            </h2>

                            <div className='manual-contact'>
                                <input
                                    className="form-control"
                                    value={this.state.editedContactManual.email}
                                    onChange={(e) => {
                                        this.setState({
                                            editedContactManual: {
                                                ...this.state.editedContactManual,
                                                email: e.target.value
                                            }
                                        });
                                    }}
                                    placeholder="Email"
                                />
                                <input
                                    className="form-control"
                                    value={this.state.editedContactManual.firstName}
                                    onChange={(e) => {
                                        this.setState({
                                            editedContactManual: {
                                                ...this.state.editedContactManual,
                                                firstName: e.target.value
                                            }
                                        });
                                    }}
                                    placeholder="First Name"
                                />
                                <input
                                    className="form-control"
                                    value={this.state.editedContactManual.lastName}
                                    onChange={(e) => {
                                        this.setState({
                                            editedContactManual: {
                                                ...this.state.editedContactManual,
                                                lastName: e.target.value
                                            }
                                        });
                                    }}
                                    placeholder="Last Name"
                                />
                                <Button
                                    bsStyle="success"
                                    onClick={() => {
                                        if (!this.state.editedContactManual.email || !this.state.editedContactManual.firstName || !this.state.editedContactManual.lastName) {
                                            toast.error("Please enter an email address, first name and last name");
                                            return;
                                        }
                                        if (!Utilities.isEmail(this.state.editedContactManual.email)) {
                                            toast.error("Please enter a valid email address");
                                            return;
                                        }
                                        this.setState({
                                            selectedContactsManual: [...this.state.selectedContactsManual, this.state.editedContactManual],
                                            editedContactManual: {
                                                email: '',
                                                firstName: '',
                                                lastName: ''
                                            }
                                        });
                                    }}
                                >
                                    Add
                                </Button>
                            </div>

                            <h2>
                                Search by Organisations that have added you as an agency
                            </h2>

                            <div className='contact-list'>
                                <input 
                                    className="form-control"
                                    value={this.state.keywords}
                                    onChange={(e) => {
                                        this.setState({
                                            keywords: e.target.value.toLowerCase()
                                        })
                                    }}
                                    placeholder="Search"
                                    id="contact-search"
                                />
                                { (this.state.selectedContactsManual.map(contact => {
                                    return (
                                        <div
                                            key={contact.email}
                                            data-selected={this.state.selectedContactsManual.includes(contact)}
                                            onClick={() => {
                                                this.setState({
                                                    selectedContactsManual: this.state.selectedContactsManual.filter((manualContact) => manualContact.email !== contact.email)
                                                });
                                            }}
                                        >
                                            <img src={ProfileApi.getProfileImageUrl('undefined')} />
                                            <h3>
                                                {contact.firstName} {contact.lastName}
                                                <span>{contact.email}</span>
                                            </h3>
                                        </div>
                                    )
                                })) }
                                { (this.state.contactListExtensions.length > 0) ?
                                    <div
                                        key={this.state.contactListExtensions[0].contactUserId}
                                        data-selected={this.state.selectedContactsUserIds.includes(this.state.contactListExtensions[0].contactUserId || "")}
                                        onClick={() => {
                                            if (this.state.selectedContactsUserIds.includes(this.state.contactListExtensions[0].contactUserId || "")) {
                                                this.setState({
                                                    selectedContactsUserIds: this.state.selectedContactsUserIds.filter((userId) => userId !== this.state.contactListExtensions[0].contactUserId)
                                                });
                                            } else {
                                                this.setState({
                                                    selectedContactsUserIds: [...this.state.selectedContactsUserIds, this.state.contactListExtensions[0].contactUserId || ""]
                                                });
                                            }
                                        }}
                                    >
                                        <img src={CompanyApi.getOrganisationProfileImageUrl(this.state.contactListExtensions[0].organisationId || "")} />
                                        <h3>
                                            {this.state.contactListExtensions[0].organisationName}
                                            <span>
                                                <img src={ProfileApi.getProfileImageUrl(this.state.contactListExtensions[0].contactUserId || "")} />
                                            </span>
                                        </h3>
                                    </div> : null
                                }
                                { this.state.contactList.map(contact => {
                                    if (
                                        this.state.keywords && 
                                        !contact.primaryContactName?.toLowerCase().includes(this.state.keywords.toLowerCase()) &&
                                        !contact.organisationName?.toLowerCase().includes(this.state.keywords.toLowerCase())
                                    ) {
                                        return null;
                                    }
                                    return (
                                        <div
                                            key={contact.contactUserId}
                                            data-selected={this.state.selectedContactsUserIds.includes(contact.contactUserId || "")}
                                            onClick={() => {
                                                if (this.state.selectedContactsUserIds.includes(contact.contactUserId || "")) {
                                                    this.setState({
                                                        selectedContactsUserIds: this.state.selectedContactsUserIds.filter((userId) => userId !== contact.contactUserId)
                                                    });
                                                } else {
                                                    this.setState({
                                                        selectedContactsUserIds: [...this.state.selectedContactsUserIds, contact.contactUserId || ""]
                                                    });
                                                }
                                            }}
                                        >
                                            <img src={CompanyApi.getOrganisationProfileImageUrl(contact.organisationId || "")} />
                                            <h3>
                                                {contact.organisationName}
                                                <span>
                                                    <img src={ProfileApi.getProfileImageUrl(contact.contactUserId || "")} />
                                                    {contact.primaryContactName}
                                                </span>
                                            </h3>
                                        </div>
                                    )
                                }) }
                            </div>
                        </React.Fragment>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        bsSize="small"
                        bsStyle="success"
                        onClick={() => {
                            if (this.state.editedContactManual.email || this.state.editedContactManual.firstName || this.state.editedContactManual.lastName) {
                                toast.info("You have an unadded manual entry, clear these details, or add them before continuing");
                                return;
                            }
                            if (this.state.selectedContactsManual.length + this.state.selectedContactsUserIds.length === 0) {
                                toast.info("Select at least one contact to refer to");
                                return;
                            }
                            this.sendReferral()
                        }}
                        disabled={this.state.sendingReferral}
                    >
                        { (this.state.sendingReferral) ?
                            (
                                <React.Fragment>
                                    <i 
                                        className="fa fa-spinner fa-spin"
                                        style={{
                                            marginRight: 10
                                        }}
                                    />
                                    <span>{this.state.processedReferrals} of {this.state.allReferralsCount} Referrals Sent</span>
                                </React.Fragment>
                            )
                            : (
                                (this.state.selectedContactsManual.length + this.state.selectedContactsUserIds.length === 0) ? 
                                "Select at least one contact" :
                                "Refer " + this.props.contacts.length + " worker(s) to " + (this.state.selectedContactsManual.length + this.state.selectedContactsUserIds.length) + " contact(s)"
                            )
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default ReferContactModal