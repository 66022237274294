/* eslint-disable */
import React from 'react';

function SvgTeams(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="262" height="262" viewBox="0 0 262 262">
        <defs>
            <linearGradient id="linear-gradient" x1="0.5" y1="1" x2="0.5" gradientUnits="objectBoundingBox">
            <stop offset="0" stop-color="gray" stop-opacity="0.251"/>
            <stop offset="0.54" stop-color="gray" stop-opacity="0.122"/>
            <stop offset="1" stop-color="gray" stop-opacity="0.102"/>
            </linearGradient>
        </defs>
        <g id="Group_1714" data-name="Group 1714" transform="translate(-156 -384)">
            <rect id="Rectangle_4438" data-name="Rectangle 4438" width="262" height="262" rx="131" transform="translate(156 384)" fill="#f5f5f5"/>
            <g id="Group_1691" data-name="Group 1691" transform="translate(-11 14)">
            <g id="Group_1668" data-name="Group 1668" transform="translate(195.321 420.28)" opacity="0.5">
                <circle id="Ellipse_141" data-name="Ellipse 141" cx="27.057" cy="27.057" r="27.057" fill="url(#linear-gradient)"/>
            </g>
            <circle id="Ellipse_142" data-name="Ellipse 142" cx="26.485" cy="26.485" r="26.485" transform="translate(195.894 420.706)" fill="#fff"/>
            <path id="Path_6429" data-name="Path 6429" d="M276.388,274.185a26.361,26.361,0,0,0,14.559-4.357c-.976-5.332-4.69-5.638-4.69-5.638H265.779s-3.5.287-4.6,5.195a26.363,26.363,0,0,0,15.208,4.8Z" transform="translate(-53.935 199.565)" fill="#6c63ff"/>
            <circle id="Ellipse_143" data-name="Ellipse 143" cx="11.741" cy="11.741" r="11.741" transform="translate(210.435 434.865)" fill="#333"/>
            <path id="Path_6430" data-name="Path 6430" d="M98.18,164.57h8.192v6.554a4.1,4.1,0,0,1-4.1,4.1h0a4.1,4.1,0,0,1-4.1-4.1V164.57Z" transform="translate(119.87 292.686)" opacity="0.1"/>
            <path id="Path_6431" data-name="Path 6431" d="M98.97,163.27H105.6a.782.782,0,0,1,.782.782v5.771a4.1,4.1,0,0,1-4.1,4.1h0a4.1,4.1,0,0,1-4.1-4.1v-5.771A.782.782,0,0,1,98.97,163.27Z" transform="translate(119.862 293.682)" fill="#be7c5e"/>
            <path id="Path_6432" data-name="Path 6432" d="M307.53,246.373a11.79,11.79,0,0,0,8.193.023V245.39H307.53Z" transform="translate(-89.464 213.976)" opacity="0.1"/>
            <circle id="Ellipse_144" data-name="Ellipse 144" cx="11.741" cy="11.741" r="11.741" transform="translate(210.435 437.415)" fill="#be7c5e"/>
            <path id="Path_6433" data-name="Path 6433" d="M259.049,132.736a2.05,2.05,0,0,1,.766-1.328,2.181,2.181,0,0,1,1.221-.156,33.152,33.152,0,0,0,9.619-1.335c1.8-.441,3.736-.978,4.9-2.433s-.355-6.026-2.132-6.565c-1.167-.357-2.522.308-3.628-.215a7.043,7.043,0,0,1-1.279-1.011,7.693,7.693,0,0,0-3.072-1.249,6.193,6.193,0,0,0-3.836-.117,27.977,27.977,0,0,0-2.5,1.693c-1.683.934-3.736.5-5.664.495-.651,0-1.429.131-1.688.728a2.981,2.981,0,0,0-.016,1.31c.056,1.4-1.1,2.491-2.069,3.5s-1.884,2.454-1.272,3.71c.233.479.661.84.9,1.314a9.184,9.184,0,0,1,.36,2.722,6.85,6.85,0,0,0,.763,1.961c.719,1.4,4.331,8.942,5.991,7.025.8-.934.934-3.633,1.258-4.847Q258.349,135.323,259.049,132.736Z" transform="translate(-43.987 311.578)" fill="#333"/>
            <ellipse id="Ellipse_145" data-name="Ellipse 145" cx="1.093" cy="2.048" rx="1.093" ry="2.048" transform="translate(212.729 446.682)" fill="#be7c5e"/>
            </g>
            <g id="Group_1695" data-name="Group 1695" transform="translate(37 2)">
            <g id="Group_1669" data-name="Group 1669" transform="translate(258.976 487.169)" opacity="0.5">
                <circle id="Ellipse_146" data-name="Ellipse 146" cx="27.057" cy="27.057" r="27.057" fill="url(#linear-gradient)"/>
            </g>
            <circle id="Ellipse_147" data-name="Ellipse 147" cx="26.485" cy="26.485" r="26.485" transform="translate(259.6 487.568)" fill="#fff"/>
            <path id="Path_6434" data-name="Path 6434" d="M544.741,526.435a26.361,26.361,0,0,0,14.55-4.357c-.976-5.333-4.69-5.638-4.69-5.638H534.129s-3.5.287-4.6,5.195A26.363,26.363,0,0,0,544.741,526.435Z" transform="translate(-258.633 14.208)" fill="#6c63ff"/>
            <circle id="Ellipse_148" data-name="Ellipse 148" cx="12.785" cy="12.785" r="12.785" transform="translate(273.043 500.71)" fill="#f55f44"/>
            <path id="Path_6435" data-name="Path 6435" d="M366.53,416.8h8.193v6.554a4.1,4.1,0,0,1-4.1,4.1h0a4.1,4.1,0,0,1-4.1-4.1V416.8Z" transform="translate(-84.828 107.344)" opacity="0.1"/>
            <path id="Path_6436" data-name="Path 6436" d="M367.32,415.5h6.628a.782.782,0,0,1,.782.782v5.771a4.1,4.1,0,0,1-4.1,4.1h0a4.1,4.1,0,0,1-4.1-4.1v-5.771a.782.782,0,0,1,.782-.782Z" transform="translate(-84.836 108.34)" fill="#fda57d"/>
            <path id="Path_6437" data-name="Path 6437" d="M575.88,498.6a11.79,11.79,0,0,0,8.193.023V497.62H575.88Z" transform="translate(-294.162 28.634)" opacity="0.1"/>
            <circle id="Ellipse_149" data-name="Ellipse 149" cx="11.741" cy="11.741" r="11.741" transform="translate(274.086 504.303)" fill="#fda57d"/>
            <circle id="Ellipse_150" data-name="Ellipse 150" cx="4.725" cy="4.725" r="4.725" transform="translate(281.086 495.765)" fill="#f55f44"/>
            <circle id="Ellipse_151" data-name="Ellipse 151" cx="3.103" cy="3.103" r="3.103" transform="translate(282.708 492.735)" fill="#f55f44"/>
            <path id="Path_6438" data-name="Path 6438" d="M574.866,378.532a4.725,4.725,0,0,1-2.463-6.042c-.033.065-.065.133-.1.2a4.726,4.726,0,1,0,8.624,3.869c.03-.068.058-.135.086-.205a4.725,4.725,0,0,1-6.152,2.178Z" transform="translate(-291.104 124.55)" fill="#f55f44"/>
            <path id="Path_6439" data-name="Path 6439" d="M557.67,398.423s4.947,10.039,18.911,4.2l-3.25-5.1-5.76-2.069Z" transform="translate(-280.203 106.943)" opacity="0.1"/>
            <path id="Path_6440" data-name="Path 6440" d="M557.67,397.783s4.947,10.039,18.911,4.2l-3.25-5.1-5.76-2.069Z" transform="translate(-280.203 107.434)" fill="#f55f44"/>
            <path id="Path_6441" data-name="Path 6441" d="M574,391.26a58.333,58.333,0,0,1,9.147,0S576.391,391.888,574,391.26Z" transform="translate(-292.721 110.3)" fill="#333"/>
            <path id="Path_6442" data-name="Path 6442" d="M594.15,353.233a3.111,3.111,0,0,0-.7.054h.18a3.1,3.1,0,0,1,.467,6.147,3.1,3.1,0,1,0,.056-6.2Z" transform="translate(-307.63 139.313)" fill="#f55f44"/>
            <ellipse id="Ellipse_152" data-name="Ellipse 152" cx="1.093" cy="2.048" rx="1.093" ry="2.048" transform="translate(272.991 513.985)" fill="#fda57d"/>
            <ellipse id="Ellipse_153" data-name="Ellipse 153" cx="1.093" cy="2.048" rx="1.093" ry="2.048" transform="translate(296.477 513.985)" fill="#fda57d"/>
            <path id="Path_6443" data-name="Path 6443" d="M345.22,333.121l2.29-1.181.516,1.893-1.4.955Z" transform="translate(-68.493 172.392)" fill="#f55f44"/>
            </g>
            <g id="Group_1692" data-name="Group 1692" transform="translate(-10 -26.748)">
            <g id="Group_1670" data-name="Group 1670" transform="translate(258.95 426.952)" opacity="0.5">
                <circle id="Ellipse_154" data-name="Ellipse 154" cx="38.431" cy="38.431" r="38.431" fill="url(#linear-gradient)"/>
            </g>
            <circle id="Ellipse_155" data-name="Ellipse 155" cx="37.618" cy="37.618" r="37.618" transform="translate(259.763 427.556)" fill="#fff"/>
            <path id="Path_6444" data-name="Path 6444" d="M536.713,188.56s-4.265,21.011-6.593,19.847c0,0,22.45,19.393,42.274,0,0,0-3.492-14.807-8.532-19.847Z" transform="translate(-254.005 276.949)" fill="#333"/>
            <path id="Path_6445" data-name="Path 6445" d="M551.025,272.716a37.442,37.442,0,0,0,20.679-6.188c-1.386-7.574-6.662-8.008-6.662-8.008H535.953s-4.974.408-6.533,7.378A37.445,37.445,0,0,0,551.025,272.716Z" transform="translate(-253.538 230.188)" fill="#6c63ff"/>
            <circle id="Ellipse_156" data-name="Ellipse 156" cx="16.677" cy="16.677" r="16.677" transform="translate(280.412 447.668)" fill="#333"/>
            <path id="Path_6446" data-name="Path 6446" d="M366.42,158.88h11.636v9.308A5.817,5.817,0,0,1,372.25,174h0a5.817,5.817,0,0,1-5.817-5.816V158.88Z" transform="translate(-75.191 320.59)" opacity="0.1"/>
            <path id="Path_6447" data-name="Path 6447" d="M367.558,157.58h9.414a1.111,1.111,0,0,1,1.111,1.111v8.2a5.817,5.817,0,0,1-5.807,5.817h0a5.817,5.817,0,0,1-5.816-5.817v-8.2A1.111,1.111,0,0,1,367.558,157.58Z" transform="translate(-75.217 321.459)" fill="#be7c5e"/>
            <path id="Path_6448" data-name="Path 6448" d="M575.77,241.1a16.746,16.746,0,0,0,11.636.033V239.7H575.77Z" transform="translate(-284.517 242.767)" opacity="0.1"/>
            <circle id="Ellipse_157" data-name="Ellipse 157" cx="16.677" cy="16.677" r="16.677" transform="translate(280.412 451.286)" fill="#be7c5e"/>
            <path id="Path_6449" data-name="Path 6449" d="M545.3,152.555h31.8s-2.716-12.84-14.737-12.021S545.3,152.555,545.3,152.555Z" transform="translate(-264.151 309.074)" fill="#333"/>
            <ellipse id="Ellipse_158" data-name="Ellipse 158" cx="1.552" cy="2.908" rx="1.552" ry="2.908" transform="translate(279.206 464.345)" fill="#be7c5e"/>
            <ellipse id="Ellipse_159" data-name="Ellipse 159" cx="1.552" cy="2.908" rx="1.552" ry="2.908" transform="translate(311.782 464.345)" fill="#be7c5e"/>
            <path id="Path_6450" data-name="Path 6450" d="M545.3,153.855h31.8s-2.716-12.84-14.737-12.021S545.3,153.855,545.3,153.855Z" transform="translate(-264.151 308.205)" opacity="0.1"/>
            </g>
            <g id="Group_1694" data-name="Group 1694" transform="translate(11 7)">
            <g id="Group_1671" data-name="Group 1671" transform="translate(323.449 426.833)" opacity="0.5">
                <circle id="Ellipse_160" data-name="Ellipse 160" cx="27.057" cy="27.057" r="27.057" fill="url(#linear-gradient)"/>
            </g>
            <circle id="Ellipse_161" data-name="Ellipse 161" cx="26.485" cy="26.485" r="26.485" transform="translate(324.072 427.23)" fill="#fff"/>
            <path id="Path_6451" data-name="Path 6451" d="M820.9,268a26.361,26.361,0,0,0,14.559-4.357c-.978-5.335-4.69-5.641-4.69-5.641H810.289s-3.5.287-4.6,5.195A26.364,26.364,0,0,0,820.9,268Z" transform="translate(-470.317 212.31)" fill="#6c63ff"/>
            <circle id="Ellipse_162" data-name="Ellipse 162" cx="12.785" cy="12.785" r="12.785" transform="translate(337.518 440.372)" fill="#72351c"/>
            <path id="Path_6452" data-name="Path 6452" d="M642.68,158.36h8.193v6.554a4.1,4.1,0,0,1-4.1,4.1h0a4.1,4.1,0,0,1-4.1-4.1V158.36Z" transform="translate(-296.505 305.445)" opacity="0.1"/>
            <path id="Path_6453" data-name="Path 6453" d="M643.44,157.06h6.628a.782.782,0,0,1,.782.782v5.771a4.1,4.1,0,0,1-4.1,4.1h0a4.1,4.1,0,0,1-4.1-4.1v-5.771a.782.782,0,0,1,.782-.782Z" transform="translate(-296.489 306.442)" fill="#fda57d"/>
            <path id="Path_6454" data-name="Path 6454" d="M852,240.163a11.79,11.79,0,0,0,8.193.023V239.18H852Z" transform="translate(-505.815 226.736)" opacity="0.1"/>
            <circle id="Ellipse_163" data-name="Ellipse 163" cx="11.741" cy="11.741" r="11.741" transform="translate(338.562 443.965)" fill="#fda57d"/>
            <path id="Path_6455" data-name="Path 6455" d="M833.82,139.983s4.947,10.039,18.911,4.2l-3.25-5.1-5.76-2.069Z" transform="translate(-491.88 305.044)" opacity="0.1"/>
            <path id="Path_6456" data-name="Path 6456" d="M833.82,139.353s4.947,10.039,18.911,4.2l-3.25-5.1-5.76-2.069Z" transform="translate(-491.88 305.527)" fill="#72351c"/>
            <path id="Path_6457" data-name="Path 6457" d="M832.826,119.564a6.782,6.782,0,0,1,1.681-2.734c2.309-2.283,6.1-2.764,8.038-5.37a1.52,1.52,0,0,1-.7,2.143c1.868-.012,4.018-.175,5.069-1.707a3.481,3.481,0,0,1-.586,3.675c1.648.075,3.409,1.193,3.516,2.841a3.282,3.282,0,0,1-1.52,2.767,8.357,8.357,0,0,1-3.065,1.146C842.125,123.026,830.781,125.924,832.826,119.564Z" transform="translate(-490.931 324.637)" fill="#72351c"/>
            <path id="Path_6458" data-name="Path 6458" d="M842.855,195.89h-.311l-6.056,5.4c-5.837-2.732-10.119,0-10.119,0l-6.35-5.169-.64.079a11.741,11.741,0,1,0,23.479-.3ZM831.1,203.349c-2.283,0-4.135-.467-4.135-1.034s1.851-1.034,4.135-1.034,4.135.467,4.135,1.034-1.852,1.034-4.137,1.034Z" transform="translate(-480.812 259.919)" fill="#72351c"/>
            <ellipse id="Ellipse_164" data-name="Ellipse 164" cx="1.093" cy="2.048" rx="1.093" ry="2.048" transform="translate(337.464 453.647)" fill="#fda57d"/>
            <ellipse id="Ellipse_165" data-name="Ellipse 165" cx="1.093" cy="2.048" rx="1.093" ry="2.048" transform="translate(360.949 453.647)" fill="#fda57d"/>
            </g>
            <g id="Group_1698" data-name="Group 1698" transform="translate(28 4)">
            <g id="Group_1672" data-name="Group 1672" transform="translate(196.128 485.397)" opacity="0.5">
                <circle id="Ellipse_166" data-name="Ellipse 166" cx="27.057" cy="27.057" r="27.057" fill="url(#linear-gradient)"/>
            </g>
            <circle id="Ellipse_167" data-name="Ellipse 167" cx="26.485" cy="26.485" r="26.485" transform="translate(196.751 485.796)" fill="#fff"/>
            <path id="Path_6459" data-name="Path 6459" d="M275.52,518.845a26.361,26.361,0,0,0,14.557-4.357c-.976-5.332-4.69-5.638-4.69-5.638H264.9s-3.5.287-4.6,5.195A26.363,26.363,0,0,0,275.52,518.845Z" transform="translate(-52.261 20.026)" fill="#6c63ff"/>
            <circle id="Ellipse_168" data-name="Ellipse 168" cx="12.785" cy="12.785" r="12.785" transform="translate(210.194 498.938)" fill="#333"/>
            <path id="Path_6460" data-name="Path 6460" d="M97.34,409.21h8.192v6.554a4.1,4.1,0,0,1-4.1,4.1h0a4.1,4.1,0,0,1-4.1-4.1V409.21Z" transform="translate(121.514 113.162)" opacity="0.1"/>
            <path id="Path_6461" data-name="Path 6461" d="M98.122,407.9h6.628a.782.782,0,0,1,.782.782v5.776a4.1,4.1,0,0,1-4.1,4.1h0a4.1,4.1,0,0,1-4.1-4.1v-5.778a.782.782,0,0,1,.782-.782Z" transform="translate(121.514 114.166)" fill="#fdb797"/>
            <path id="Path_6462" data-name="Path 6462" d="M306.69,490.99a11.79,11.79,0,0,0,8.193.023V490H306.69Z" transform="translate(-87.819 34.475)" opacity="0.1"/>
            <circle id="Ellipse_169" data-name="Ellipse 169" cx="11.741" cy="11.741" r="11.741" transform="translate(211.238 502.531)" fill="#fdb797"/>
            <path id="Path_6463" data-name="Path 6463" d="M288.48,390.823s4.947,10.039,18.911,4.2l-3.25-5.1-5.76-2.069Z" transform="translate(-73.861 112.769)" opacity="0.1"/>
            <path id="Path_6464" data-name="Path 6464" d="M288.48,390.193s4.947,10.039,18.911,4.2l-3.25-5.1-5.76-2.069Z" transform="translate(-73.861 113.251)" fill="#333"/>
            <path id="Path_6465" data-name="Path 6465" d="M287.486,370.394a6.783,6.783,0,0,1,1.681-2.734c2.309-2.283,6.1-2.764,8.038-5.37a1.52,1.52,0,0,1-.7,2.143c1.868-.012,4.018-.175,5.069-1.707a3.481,3.481,0,0,1-.586,3.675c1.648.075,3.409,1.193,3.516,2.841a3.282,3.282,0,0,1-1.52,2.767,8.468,8.468,0,0,1-3.068,1.156C296.782,373.861,285.44,376.758,287.486,370.394Z" transform="translate(-72.911 132.369)" fill="#333"/>
            <ellipse id="Ellipse_170" data-name="Ellipse 170" cx="1.093" cy="2.048" rx="1.093" ry="2.048" transform="translate(210.141 512.213)" fill="#fdb797"/>
            <ellipse id="Ellipse_171" data-name="Ellipse 171" cx="1.093" cy="2.048" rx="1.093" ry="2.048" transform="translate(233.626 512.213)" fill="#fdb797"/>
            </g>
            <g id="Group_1693" data-name="Group 1693" transform="translate(-76 66)">
            <g id="Group_1673" data-name="Group 1673" transform="translate(324.458 488.397)" opacity="0.5">
                <circle id="Ellipse_172" data-name="Ellipse 172" cx="38.334" cy="38.334" r="38.334" fill="url(#linear-gradient)"/>
            </g>
            <circle id="Ellipse_173" data-name="Ellipse 173" cx="37.524" cy="37.524" r="37.524" transform="translate(325.267 488.999)" fill="#fff"/>
            <path id="Path_6466" data-name="Path 6466" d="M831.55,535.861a37.349,37.349,0,0,0,20.628-6.172c-1.383-7.555-6.645-7.988-6.645-7.988H816.516s-4.962.407-6.516,7.36A37.352,37.352,0,0,0,831.55,535.861Z" transform="translate(-468.652 28.299)" fill="#6c63ff"/>
            <path id="Path_6467" data-name="Path 6467" d="M647,422.06h11.607v9.285a5.8,5.8,0,0,1-5.8,5.8h0a5.8,5.8,0,0,1-5.805-5.8Z" transform="translate(-290.344 118.723)" opacity="0.1"/>
            <path id="Path_6468" data-name="Path 6468" d="M648.108,420.76H657.5a1.108,1.108,0,0,1,1.108,1.108v8.177a5.8,5.8,0,0,1-5.8,5.8h0a5.8,5.8,0,0,1-5.805-5.8v-8.177A1.108,1.108,0,0,1,648.108,420.76Z" transform="translate(-290.344 119.593)" fill="#fdb797"/>
            <path id="Path_6469" data-name="Path 6469" d="M856.35,504.273a16.7,16.7,0,0,0,11.607.033V502.88H856.35Z" transform="translate(-499.672 40.894)" opacity="0.1"/>
            <circle id="Ellipse_174" data-name="Ellipse 174" cx="16.635" cy="16.635" r="16.635" transform="translate(345.865 512.673)" fill="#fdb797"/>
            <ellipse id="Ellipse_175" data-name="Ellipse 175" cx="1.548" cy="2.901" rx="1.548" ry="2.901" transform="translate(344.661 525.696)" fill="#fdb797"/>
            <ellipse id="Ellipse_176" data-name="Ellipse 176" cx="1.548" cy="2.901" rx="1.548" ry="2.901" transform="translate(377.158 525.696)" fill="#fdb797"/>
            <rect id="Rectangle_4434" data-name="Rectangle 4434" width="7.115" height="3.559" rx="1.78" transform="translate(352.365 529.388)" fill="none" stroke="#333" stroke-miterlimit="10" stroke-width="2"/>
            <rect id="Rectangle_4435" data-name="Rectangle 4435" width="7.115" height="3.559" rx="1.78" transform="translate(372.639 532.943) rotate(180)" fill="none" stroke="#333" stroke-miterlimit="10" stroke-width="2"/>
            <path id="Path_6470" data-name="Path 6470" d="M864,459.329s2.256-1.224,6.45,0" transform="translate(-504.79 70.402)" fill="none" stroke="#333" stroke-miterlimit="10" stroke-width="2"/>
            <path id="Path_6471" data-name="Path 6471" d="M908.72,422.7l4.082,9.484S917.793,425.892,908.72,422.7Z" transform="translate(-534.718 94.551)" fill="#858585"/>
            <line id="Line_27" data-name="Line 27" y1="5.441" x2="6.093" transform="translate(372.639 525.726)" fill="none" stroke="#333" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
            <path id="Path_6472" data-name="Path 6472" d="M828.17,422.7l-4.082,9.484S819.1,425.892,828.17,422.7Z" transform="translate(-477.165 94.551)" fill="#858585"/>
            <line id="Line_28" data-name="Line 28" x1="6.176" y1="5.441" transform="translate(346.19 525.726)" fill="none" stroke="#333" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
            </g>
            <g id="Group_1697" data-name="Group 1697" transform="translate(-12 -2)">
            <g id="Group_1674" data-name="Group 1674" transform="translate(196.321 546.223)" opacity="0.5">
                <circle id="Ellipse_177" data-name="Ellipse 177" cx="27.057" cy="27.057" r="27.057" fill="url(#linear-gradient)"/>
            </g>
            <circle id="Ellipse_178" data-name="Ellipse 178" cx="26.485" cy="26.485" r="26.485" transform="translate(196.894 546.648)" fill="#fff"/>
            <path id="Path_6473" data-name="Path 6473" d="M295.425,660.319c0,9.754-6.364,20.613-14.216,20.613S267,670.073,267,660.319s6.364-14.709,14.216-14.709S295.425,650.567,295.425,660.319Z" transform="translate(-57.396 -84.804)" fill="#fdd835"/>
            <path id="Path_6474" data-name="Path 6474" d="M276.388,779.385a26.361,26.361,0,0,0,14.559-4.357c-.976-5.333-4.69-5.638-4.69-5.638H265.779s-3.5.287-4.6,5.195A26.363,26.363,0,0,0,276.388,779.385Z" transform="translate(-52.935 -179.686)" fill="#6c63ff"/>
            <path id="Path_6475" data-name="Path 6475" d="M98.18,669.74h8.192v6.554a4.1,4.1,0,0,1-4.1,4.1h0a4.1,4.1,0,0,1-4.1-4.1V669.74Z" transform="translate(120.87 -86.542)" opacity="0.1"/>
            <path id="Path_6476" data-name="Path 6476" d="M98.97,668.44H105.6a.782.782,0,0,1,.782.782v5.771a4.1,4.1,0,0,1-4.1,4.1h0a4.1,4.1,0,0,1-4.1-4.1v-5.771A.782.782,0,0,1,98.97,668.44Z" transform="translate(120.862 -85.546)" fill="#fdb797"/>
            <path id="Path_6477" data-name="Path 6477" d="M307.53,751.543a11.79,11.79,0,0,0,8.193.023V750.56H307.53Z" transform="translate(-88.464 -165.251)" opacity="0.1"/>
            <circle id="Ellipse_179" data-name="Ellipse 179" cx="11.741" cy="11.741" r="11.741" transform="translate(211.435 563.357)" fill="#fdb797"/>
            <ellipse id="Ellipse_180" data-name="Ellipse 180" cx="1.093" cy="2.048" rx="1.093" ry="2.048" transform="translate(210.584 572.549)" fill="#fdb797"/>
            <ellipse id="Ellipse_181" data-name="Ellipse 181" cx="1.093" cy="2.048" rx="1.093" ry="2.048" transform="translate(233.521 572.549)" fill="#fdb797"/>
            <path id="Path_6478" data-name="Path 6478" d="M280.57,657.921s6.638,9.084,21.86,5.982c0,0,1.7-2.069-2.437-8.419S284.336,650.168,280.57,657.921Z" transform="translate(-67.798 -89.192)" opacity="0.1"/>
            <path id="Path_6479" data-name="Path 6479" d="M280.57,657.291s6.638,9.084,21.86,5.982c0,0,1.7-2.069-2.437-8.419S284.336,649.538,280.57,657.291Z" transform="translate(-67.798 -88.709)" fill="#fdd835"/>
            </g>
            <g id="Group_1696" data-name="Group 1696" transform="translate(75 -5)">
            <g id="Group_1675" data-name="Group 1675" transform="translate(260.599 549.326)" opacity="0.5">
                <circle id="Ellipse_182" data-name="Ellipse 182" cx="27.057" cy="27.057" r="27.057" fill="url(#linear-gradient)"/>
            </g>
            <circle id="Ellipse_183" data-name="Ellipse 183" cx="26.485" cy="26.485" r="26.485" transform="translate(261.225 549.725)" fill="#fff"/>
            <path id="Path_6480" data-name="Path 6480" d="M566.233,670.393c0,8.811-6.329,13.147-14.55,13.147S536.47,679.2,536.47,670.393s6.663-15.953,14.881-15.953S566.233,661.575,566.233,670.393Z" transform="translate(-263.953 -91.573)" fill="#333"/>
            <path id="Path_6481" data-name="Path 6481" d="M551.7,792.665a26.36,26.36,0,0,0,14.562-4.357c-.976-5.332-4.69-5.638-4.69-5.638h-20.48s-3.5.287-4.6,5.195A26.364,26.364,0,0,0,551.7,792.665Z" transform="translate(-263.968 -189.865)" fill="#6c63ff"/>
            <path id="Path_6482" data-name="Path 6482" d="M373.467,683h8.192v6.554a4.1,4.1,0,0,1-4.1,4.1h0a4.1,4.1,0,0,1-4.1-4.1V683Z" transform="translate(-90.14 -96.707)" opacity="0.1"/>
            <path id="Path_6483" data-name="Path 6483" d="M374.247,681.722h6.628a.782.782,0,0,1,.782.782v5.771a4.1,4.1,0,0,1-4.1,4.1h0a4.1,4.1,0,0,1-4.1-4.1V682.5a.782.782,0,0,1,.782-.782Z" transform="translate(-90.14 -95.725)" fill="#fda57d"/>
            <path id="Path_6484" data-name="Path 6484" d="M582.84,764.833a11.79,11.79,0,0,0,8.193.023V763.85H582.84Z" transform="translate(-299.497 -175.438)" opacity="0.1"/>
            <circle id="Ellipse_184" data-name="Ellipse 184" cx="11.741" cy="11.741" r="11.741" transform="translate(275.711 566.458)" fill="#fda57d"/>
            <path id="Path_6485" data-name="Path 6485" d="M542.48,678.874a21.057,21.057,0,0,0,12.841-7.975s7.312,8.05,12.407,7.163c0,0,2.953-3.1,0-6.2s-5.244-7.016-9.157-6.5-8.715.738-10.782,2.585S542.48,678.874,542.48,678.874Z" transform="translate(-268.56 -99.907)" opacity="0.1"/>
            <path id="Path_6486" data-name="Path 6486" d="M542.48,678.244a21.057,21.057,0,0,0,12.841-7.975s7.312,8.05,12.407,7.163c0,0,2.953-3.1,0-6.2s-5.244-7.016-9.157-6.5-8.706.728-10.772,2.6S542.48,678.244,542.48,678.244Z" transform="translate(-268.56 -99.424)" fill="#333"/>
            <ellipse id="Ellipse_185" data-name="Ellipse 185" cx="1.093" cy="2.048" rx="1.093" ry="2.048" transform="translate(274.614 576.143)" fill="#fda57d"/>
            <ellipse id="Ellipse_186" data-name="Ellipse 186" cx="1.093" cy="2.048" rx="1.093" ry="2.048" transform="translate(298.099 576.143)" fill="#fda57d"/>
            </g>
        </g>
        </svg>
    );
}

export default SvgTeams;
