/* eslint-disable */
import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';

import { ApplicationState } from '../../store';
import CompanyAPI from '../../api/company/Company';
import ProfileAPI from '../../api/profile/Profile';
import InternalTracker from 'src/InternalTracker';

const InvitationPrintoutGenerator = () => {
    const {
        id: userId,
        displayName,
        emailAddress,
        organisationName,
        organisationId
    } = useSelector(
        (state: ApplicationState) => state.profile.authenticatedUserProfile
    );

    const organisationLogoUrl = CompanyAPI.getOrganisationProfileImageUrl(
        organisationId
    );

    const userProfileImageUrl = ProfileAPI.getProfileImageUrl(userId);

    const invitationLocation = `/app-static/printable-invitation.html?fullName=${displayName}&organisationName=${organisationName}&emailAddress=${emailAddress}&organisationLogoUrl=${organisationLogoUrl}&userProfileImageUrl=${userProfileImageUrl}`;

    const navigateToPrintableInvitation = () => {
        window.open(invitationLocation, '_blank');
    };

    return (
        <div onClick={() => {
            InternalTracker.trackEvent("Invite Printed");

            if (!organisationId) {
                alert('Please create or join an organization to generate a printable Updatedge invitation.')
            } else {
                navigateToPrintableInvitation();   
            }          
        }}>
            <img src="/img/invite-print.png" />
            <p>Print invitations to give to contacts</p>
        </div>
    );
};

export default InvitationPrintoutGenerator;
