import AppConfig from '../../components/config/Config';
import afetch from './../AuthenticatedFetch';
import { UserSetting, Setting } from './ResponseTypes';

const SettingsAPI = {
    get: () => {
        const url = `${AppConfig.Settings.api.baseUri}/api/settings`;

        return afetch.request<null, UserSetting[]>(
            url,
            'GET',
            null,
            'Unable to fetch user settings'
        );
    },

    update: (setting: Setting, value: string) => {
        const url = `${AppConfig.Settings.api.baseUri}/api/settings/${setting}/${value || 'null'}`;

        return afetch.request(
            url,
            'PATCH',
            null,
            'Unable to update user-setting'
        );
    }
};

export default SettingsAPI;
