import React from 'react';

import SignalRService, {
    HubName,
    MethodName
} from '../../services/signalr.service';

/**
 * A hook that returns a SignalR HubConnection that will subscribe to a certain event.
 * The connection will automatically terminate on un-mount.
 * @param hubName The SignalR hub to subscribe to.
 * @param methodName The SignalR event to subscribe to.
 * @param callback Callback called when event is emitted from SignalR.
 */
const useSocket = (
    hubName: HubName,
    methodName: MethodName,
    callback: Parameters<any['on']>[1]
) => {
    const socketConnection = React.useRef<any | null>(null);

    /**
     * Prevents a re-render of the parent component where the callback is not memo-ised
     * from causing the effect to run again and a new hub created
     */
    // @ts-ignore
    const cb = React.useCallback(callback, []);

    React.useEffect(() => {
        const socket = SignalRService.create(hubName);
        socket.on(methodName, cb);
        socketConnection.current = socket;
        return () => {
            if (socketConnection.current == null) return;
            socketConnection.current.stop();
        };
    }, [cb, hubName, methodName]);

    return socketConnection;
};

export default useSocket;
