/** REDUX State model definitions for contact profile view*/

import { RepresentedByOrganisation } from 'src/api/availability/ResponseTypes';

/**
 * Default state for REDUX state tree
 */
export const defaultState: RepresentedByState = {
    data: []
};

/** Stores state for contact profile */
export interface RepresentedByState {
    data: RepresentedByOrganisation[];
}
