import React from 'react';

import { UEText } from './UEText';
import { Spinner } from './Spinner';

const Splash = ({
    displaySpinner = true,
    tagline = 'Availability shared easily'
}) => {
    return (
        // Copy splash from MVC view so user continues to see same loading page
        <div className="splash">
            <div className="splash-title">
                <img
                    src="/img/branding/updateedge_90.png"
                    alt="Updatedge Logo"
                />
                <UEText />
                <p>{tagline}</p>
                {displaySpinner && <Spinner text="" />}
            </div>
        </div>
    );
};

export default Splash;
