/* eslint-disable */
/**
 * REDUX Store definition for time preset management
 */
import { ToastType } from 'react-toastify';

import { AppThunkAction } from '..';
import { KnownAction } from './Actions';
import TimePresetsApi from './../../api/timepresets/TimePresets';
import { TimePresetNew, TimePresetEdit, TimePreset } from './Models';
import * as Notifications from '../Notifications';
import { actionCreators as EventsActions } from '../Events';
import Utilities from 'src/Utilities';
import { getValue, setValue } from 'src/db/KeyValueCache';

const CUSTOM_PRESET = [
    {
        "id": "custom",
        "name": "Custom",
        "startHour": 0,
        "startMinute": 0,
        "endHour": 23,
        "endMinute": 0,
        "startTime": "00:00",
        "endTime": "23:00"
    }
];

const DEFAULT_PRESETS = [
    {
        "id": "3D7599A6-ADBC-4DCC-8921-296D17DB8F2B",
        "name": "AM",
        "startHour": 8,
        "startMinute": 0,
        "endHour": 13,
        "endMinute": 0,
        "startTime": "08:00",
        "endTime": "13:00"
    },
    {
        "id": "D795E0A9-FC33-49A0-A6F2-F4A1DC1CC460",
        "name": "Day",
        "startHour": 8,
        "startMinute": 0,
        "endHour": 16,
        "endMinute": 0,
        "startTime": "08:00",
        "endTime": "18:00"
    },
    {
        "id": "38666CBE-E16D-40CB-B5B2-E78C1DFC1888",
        "name": "PM",
        "startHour": 13,
        "startMinute": 0,
        "endHour": 16,
        "endMinute": 0,
        "startTime": "13:00",
        "endTime": "18:00"
    },
    {
        "id": "E8CA5E70-104B-4FD9-92F4-D0BFF579C13C",
        "name": "Night",
        "startHour": 18,
        "startMinute": 0,
        "endHour": 8,
        "endMinute": 0,
        "startTime": "18:00",
        "endTime": "08:00"
    }
]

function getPresetsTask(dispatch) {
    dispatch({ type: 'TIME_PRESETS_STORE.TIME_PRESETS.REQUEST.INIT' });
    if (window.location.pathname.startsWith('/external')) {
        return TimePresetsApi.getTimesheetPresets(window.location.href.split("?token=")[1]).then( async (p) => {
            let customPreset: number[] = await (new Promise((resolve, reject) => {
                if (window.location.pathname.startsWith("/external/schedule")) {
                    return resolve([])
                }
                let i = 0;
                let interval = setInterval(() => {
                    let start = localStorage.getItem("timesheet-custom-preset-start");
                    let end = localStorage.getItem("timesheet-custom-preset-end");
                    if (start && end) {
                        clearInterval(interval);
                        resolve([parseInt(start), parseInt(end)])
                    }
                    if (i === 40) {
                        clearInterval(interval);
                        resolve([])
                    }
                    i++;
                }, 250)
            }))
            // @ts-ignore
            let presets = p === true ? DEFAULT_PRESETS : p;
            if (customPreset[0] && customPreset[1]) {
                presets.unshift({
                    startHour: Math.floor(customPreset[0] / 60 / 60),
                    startMinute: customPreset[0] / 60 % 60,
                    endHour: Math.floor(customPreset[1] / 60 / 60),
                    endMinute: customPreset[1] / 60 % 60,
                    startTime: Utilities.toHHMM(customPreset[0]),
                    endTime: Utilities.toHHMM(customPreset[1]),
                    id: "custom",
                    name: "Custom",
                })
            }
            setValue("time-presets", JSON.stringify(presets));
            dispatch({
                type: 'TIME_PRESETS_STORE.TIME_PRESETS.REQUEST.SUCCESS',
                presets: presets
            });
        });
    } else {
        getValue("time-presets").then((p) => {
            if (p) {
                dispatch({
                    type: 'TIME_PRESETS_STORE.TIME_PRESETS.REQUEST.SUCCESS',
                    presets: JSON.parse(p)
                });
            }
        })

        return TimePresetsApi.getPresets().then((p) => {
            if (window.location.pathname.startsWith("/settings")) {
                dispatch({
                    type: 'TIME_PRESETS_STORE.TIME_PRESETS.REQUEST.SUCCESS',
                    // @ts-ignore
                    presets: []
                })
                setTimeout(() => {
                    dispatch({
                        type: 'TIME_PRESETS_STORE.TIME_PRESETS.REQUEST.SUCCESS',
                        // @ts-ignore
                        presets: p.concat(CUSTOM_PRESET)
                    }); 
                })
            } else {
                dispatch({
                    type: 'TIME_PRESETS_STORE.TIME_PRESETS.REQUEST.SUCCESS',
                    // @ts-ignore
                    presets: p.concat(CUSTOM_PRESET)
                }); 
                // @ts-ignore
                setValue("time-presets", JSON.stringify(p.concat(CUSTOM_PRESET)));
            }
        });
    }
}

export const actionCreators = {
    getSearchTimePresets: (): AppThunkAction<KnownAction> => (dispatch) => {
        getPresetsTask(dispatch);
    },
    /** Starts the new search time preset creation process */
    createSearchTimePresetStart: (): AppThunkAction<KnownAction> => (
        dispatch
    ) => {
        dispatch({ type: 'TIME_PRESETS_STORE.TIME_PRESETS.CREATE.INIT' });
    },

    /** Commits the new preset to storage */
    createSearchTimePresetSubmit: (
        preset: TimePresetNew
    ): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({
            type: 'TIME_PRESETS_STORE.TIME_PRESETS.EDIT.SUCCESS',
            id: "unsaved"
        })
        return TimePresetsApi.newPresetCreate(preset)
            .then(() =>
                dispatch({
                    type: 'TIME_PRESETS_STORE.TIME_PRESETS.CREATE.SUCCESS'
                })
            )
            .then(() => {
                Notifications.actionCreators.display(
                    ToastType.SUCCESS,
                    'Time preset created successfully.'
                );
                dispatch(EventsActions.refreshOverlayMenu());
            })
            .then(() => getPresetsTask(dispatch));
    },

    /** Starts the search time preset edit process for a particular ID */
    editSearchTimePresetStart: (
        id: TimePreset['id']
    ): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'TIME_PRESETS_STORE.TIME_PRESETS.EDIT.INIT', id });
    },

    /** Stops the search time preset edit process for a particular ID */
    editSearchTimePresetEnd: (
        id: TimePreset['id']
    ): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'TIME_PRESETS_STORE.TIME_PRESETS.EDIT.END', id });
    },

    editLocalList: (presets: TimePreset[]): AppThunkAction<KnownAction> => (dispatch) => {
        setValue("time-presets", JSON.stringify(presets));
        dispatch({
            type: 'TIME_PRESETS_STORE.TIME_PRESETS.REQUEST.SUCCESS',
            presets: presets
        });
    },

    /** Commits a preset edit to storage */
    editSearchTimePresetSubmit: (
        id: TimePreset['id'],
        preset: TimePresetEdit
    ): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({
            type: 'TIME_PRESETS_STORE.TIME_PRESETS.EDIT.SUCCESS',
            id
        })
        return TimePresetsApi.presetEdit(id, preset)
            .then(() => { })
            .then(() => {
                // Notifications.actionCreators.display(
                //     ToastType.SUCCESS,
                //     'Time preset updated successfully.'
                // )
            })
            .then(() => getPresetsTask(dispatch));
    },

    removeSearchTimePreset: (id: string): AppThunkAction<KnownAction> => (
        dispatch
    ) => {
        dispatch({ type: 'TIME_PRESETS_STORE.TIME_PRESETS.REMOVE.INIT' });
        return TimePresetsApi.presetDelete(id)
            .then(() =>
                dispatch({
                    type: 'TIME_PRESETS_STORE.TIME_PRESETS.REMOVE.SUCCESS'
                })
            )
            .then(() => {
                // Notifications.actionCreators.display(
                //     ToastType.SUCCESS,
                //     'Time preset removed successfully.'
                // )
            })
            .then(() => getPresetsTask(dispatch));
    }
};
