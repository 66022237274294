// TODONOW
/* eslint-disable */
import React from 'react';
import { Modal, Button, Popover, OverlayTrigger } from 'react-bootstrap';
import { debounce } from 'ts-debounce';
import { ContactResult } from '../../api/invites/ResponseTypes';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import moment from 'moment';

import InviteApi from '../../api/invites/Invites';
import ProfileApi from '../../api/profile/Profile';
import CompanyApi from '../../api/company/Company';
import { ProfileImage, EmptyProfileImage } from '../ui-components/ProfileImage';
import { StarRating } from '../ui-components/StarRating';
import Analytics from '../../services/analytics.service';

interface SearchContactModalProps {
    show: boolean;
    contactSearchType: ContactSearchType;
    multi?: boolean;
    organisationIds?: string[];
    onShowChanged: (show: boolean) => void;
    onSelected: (contacts: ContactResult[]) => void;
}

interface SearchContactModalState {
    results: ContactResult[];
    selectedIds: string[];
    searching: boolean;
    searched: boolean;
}

export enum ContactSearchType {
    Internal,
    External
}

export class SearchContactModal extends React.Component<
    SearchContactModalProps,
    SearchContactModalState
> {
    constructor(props: SearchContactModalProps) {
        super(props);

        this.state = {
            results: [],
            selectedIds: [],
            searching: false,
            searched: false
        };
    }

    private handleOpen = () => {
        this.setState({
            results: [],
            selectedIds: []
        });

        // If they're search for internal contacts then don't have initial search
        if (this.props.contactSearchType == ContactSearchType.External) {
            this.search();
        }

        Analytics.trackEvent('invite-search-contact-api-opened');
    };

    private handleSearchChange = debounce(this.search, 700);

    private search(search?: string) {
        this.setState({
            searching: true,
            searched: false
        });

        if (this.props.contactSearchType == ContactSearchType.Internal) {
            if (search && search.length) {
                InviteApi.searchInternalContacts(search).then((contacts) => {
                    this.setState({
                        results: contacts,
                        searching: false,
                        searched: true
                    });
                });
            } else {
                this.setState({
                    results: [],
                    searching: false,
                    searched: false
                });
            }
        } else {
            InviteApi.searchExternalContacts(this.props.organisationIds, search)
                .then(({ data }) => {
                    this.setState({
                        results: data,
                        searching: false,
                        searched: true
                    }, () => {
                        this.handleSelectAll();
                    });
                })
                .catch((err) => {
                    toast.error('Unable to contact the third-party system');

                    this.setState({
                        results: [],
                        searching: false,
                        searched: false
                    });

                    this.handleClose();
                });

            if (search) {
                Analytics.trackEvent('invite-search-contact-api-search', {
                    searchValue: search
                });
            }
        }
    }

    private handleClose = () => {
        this.props.onShowChanged(false);

        Analytics.trackEvent('invite-search-contact-api-close');
    };

    private continue = () => {
        const contacts = this.state.results.filter(
            (r) => this.state.selectedIds.indexOf(r.id) > -1
        );
        this.props.onSelected(contacts);
        this.handleClose();

        Analytics.trackEvent('invite-search-contact-api-complete', {
            contactCount: contacts.length
        });
    };

    private handleRowClick(id: string) {
        let ids: string[];

        if (this.isSelected(id)) {
            ids = this.state.selectedIds.filter((s) => s !== id);
        } else if (this.props.multi) {
            ids = this.state.selectedIds.concat(id);
        } else {
            ids = [id];
        }

        this.setState({
            selectedIds: ids
        });
    }

    private handleSelectAll = () => {
        this.setState({
            selectedIds: this.state.results.map((r) => r.id)
        });

        Analytics.trackEvent('invite-search-contact-api-select-all');
    };

    private handleDeselectAll = () => {
        this.setState({
            selectedIds: [],
        });

        Analytics.trackEvent('invite-search-contact-api-deselect-all');
    };

    private isSelected(id: string) {
        return this.state.selectedIds.indexOf(id) > -1;
    }

    render() {
        return (
            <div>
                <Modal
                    show={this.props.show}
                    onHide={this.handleClose}
                    onEnter={this.handleOpen}
                    dialogClassName="modal-dialog-compact"
                >
                    <Modal.Header>
                        <Modal.Title>Search contacts</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h5 className="m-b">
                            Please select the contacts you wish to invite to
                            share their availability
                        </h5>
                        <SearchInput
                            autoFocus
                            type="text"
                            className="form-control"
                            placeholder="Filter..."
                            onChange={(e) =>
                                this.handleSearchChange(e.target.value)
                            }
                        />
                        <ListContainer>{this.renderList()}</ListContainer>
                    </Modal.Body>
                    <Modal.Footer>
                        {this.props.contactSearchType ==
                        ContactSearchType.External ? (
                            <Button
                                onClick={() => {
                                    if (this.state.selectedIds.length === 0) {
                                        this.handleSelectAll();
                                    } else {
                                        this.handleDeselectAll();
                                    }
                                }}
                                style={{ float: 'left' }}
                            >
                                {this.state.selectedIds.length === 0
                                    ? 'Select all'
                                    : 'Deselect all'}
                            </Button>
                        ) : null}
                        <Button
                            onClick={this.continue}
                            disabled={!this.state.selectedIds.length}
                            bsStyle="primary"
                        >
                            Continue
                        </Button>
                        <Button onClick={this.handleClose}>Cancel</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }

    renderList() {
        if (this.state.searching) {
            return (
                <OverlayDiv>
                    <i className="fa fa-spinner fa-spin" />
                </OverlayDiv>
            );
        } else if (this.state.results.length) {
            return this.state.results.map((result) => this.renderRow(result));
        } else if (this.state.searched) {
            return <span>No contacts found</span>;
        }
    }

    renderUpdatedgeLogo(result: ContactResult) {
        const popover = (
            <Popover id={'popover-updatedge-' + result.id}>
                This contact uses Updatedge
            </Popover>
        );

        if (result.isExternal) {
            return <div style={{ width: '23px' }} />;
        } else {
            return (
                <OverlayTrigger
                    trigger={['hover', 'focus']}
                    placement="top"
                    overlay={popover}
                >
                    <UpdatedgeLogo src="/img/branding/updatedge-black-28.png" />
                </OverlayTrigger>
            );
        }
    }

    renderRow(result: ContactResult) {
        const contactImage = result.isExternal
            ? result.profileImageUrl || EmptyProfileImage
            : ProfileApi.getProfileImageUrl(result.id);

        return (
            <ResultRow
                key={'contact-row-' + result.id}
                onClick={() => this.handleRowClick(result.id)}
                className={
                    'layout horizontal center ' +
                    (this.isSelected(result.id) ? 'selected' : '')
                }
            >
                {this.renderUpdatedgeLogo(result)}
                <ProfileImage
                    url={result.profileImageUrl || contactImage}
                    size={48}
                    ignoreImageSize={true}
                    selectable={false}
                    style={{
                        objectFit: 'cover',
                        width: '100%',
                        height: '100%'
                    }}
                />
                <span className="user-name">{result.name}</span>
                <span className="companies">
                    {this.renderCompanies(result)}
                </span>
            </ResultRow>
        );
    }

    renderCompanies(result: ContactResult) {
        return (result.organisations || []).map((c) => {
            let rating: JSX.Element | null = null;
            if (c.ratings) {
                if (
                    c.ratings.length == 1 &&
                    c.ratings[0].rating !== undefined
                ) {
                    rating = <StarRating value={c.ratings[0].rating!} />;
                } else if (c.ratings.length > 1) {
                    rating = <span>multiple ratings</span>;
                }
            }

            let popover: JSX.Element;
            if (c.ratings && c.ratings.length) {
                popover = (
                    <Popover id={'popover-rating-' + c.id} title={c.name}>
                        <div>
                            {c.ratings.map((r) => {
                                const lastWorked = r.lastWorked
                                    ? moment(r.lastWorked)
                                    : null;

                                return (
                                    <div
                                        key={'rating' + r.ratingProvider}
                                        className="vertical layout center-center"
                                    >
                                        {r.rating !== undefined ? (
                                            <StarRating value={r.rating} />
                                        ) : null}
                                        <div>{r.ratingProvider}</div>
                                        {lastWorked && lastWorked.isValid() ? (
                                            <div
                                                style={{
                                                    fontSize: '11px',
                                                    fontStyle: 'italic',
                                                    textAlign: 'center'
                                                }}
                                            >
                                                <span>
                                                    Last worked:{' '}
                                                    {lastWorked.format(
                                                        'ddd DD MMM YY'
                                                    )}
                                                </span>
                                            </div>
                                        ) : null}
                                        {c.ratings!.indexOf(r) !==
                                        c.ratings!.length - 1 ? (
                                            <hr
                                                style={{
                                                    margin: '5px 0',
                                                    width: '100%'
                                                }}
                                            />
                                        ) : null}
                                    </div>
                                );
                            })}
                        </div>
                    </Popover>
                );
            } else {
                popover = (
                    <Popover id={'popover-rating-' + c.id}>{c.name}</Popover>
                );
            }

            return (
                <span key={`contact-company-${result.id}-${c.id}`}>
                    <OverlayTrigger
                        trigger={['hover', 'focus']}
                        placement="top"
                        overlay={popover}
                    >
                        <span>
                            {rating}
                            <img
                                src={CompanyApi.getCompanyProfileImageUrl(c.id)}
                                height="20"
                                width="20"
                            />
                        </span>
                    </OverlayTrigger>
                </span>
            );
        });
    }
}

const ResultRow = styled.div`
    position: relative;
    margin: 5px 0 5px 5px;
    cursor: pointer;

    &:hover {
        background-color: rgba(0, 0, 0, 0.075);
    }

    &.selected {
        background-color: #b8daff;
    }

    > .user-name {
        margin-left: 10px;
        font-size: 15px;
    }

    > .companies {
        position: absolute;
        right: 5px;

        img {
            height: 20px;
            margin: 0 5px;
        }
    }
`;

const SearchInput = styled.input`
    background: transparent;

    &:focus {
        background: white;
    }
`;

const OverlayDiv = styled.div`
    padding-top: 20px;
    font-size: 28px;
    text-align: center;
`;

const ListContainer = styled.div`
    max-height: calc(100vh - 345px);
    margin: 10px;
    overflow: auto;
`;

const UpdatedgeLogo = styled.img`
    width: 15px;
    height: 15px;
    margin-right: 3px;
`;
