import React from 'react';
import { Modal, Button } from 'react-bootstrap';

import ProfileAgencies, {
    Props as ProfileAgenciesProps
} from './ProfileAgencies';

interface Props {
    show: boolean;
    onHide: () => void;
    autoAddOnOpen?: boolean;
    onConfirm: ProfileAgenciesProps['onConfirm'];
}

export default class UserAgencySelector extends React.Component<Props, {}> {
    render() {
        return (
            <Modal show={this.props.show} onHide={this.props.onHide}>
                <Modal.Body>
                    <h4
                        style={{
                            marginBottom: '20px',
                            textAlign: 'center',
                            fontWeight: 600
                        }}
                    >
                        My Agencies
                    </h4>
                    <ProfileAgencies
                        onConfirm={this.props.onConfirm}
                        autoAddOnOpen={this.props.autoAddOnOpen}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
