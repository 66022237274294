/* eslint-disable */
/** Contact and group data models definitions for contact management */

import { Moment } from 'moment';
import { RepresentedByOrganisation, UserMatchedKeywords, UserSkillDto, VerificationDetailDto } from 'src/api/availability/ResponseTypes';
import { OrganisationRelationship, SubSector, TeamRelationship } from 'src/api/contacts/ResponseTypes';
import { Rating } from 'src/api/offers/ResponseTypes';
import { RatingType } from 'src/api/ratings/ResponseTypes';
import { Sector, UserQualification } from '../../api/contacts/ResponseTypes';

import * as BaseModels from '../BaseModels';

/**
 * Defines a Contact
 */
export interface Contact {
    /** Id of the contact */
    id: string;
    /** User id of the contact (if the contact has a user account) */
    userId: string;
    /** First name */
    firstName: string;
    /** Last name */
    lastName: string;
    /** Full name */
    fullName: string;
    headline?: string;
    /** Full url to profile image */
    profileImageUrl: string;
    /** When the contact last updated their availability (if the contact has a user account) */
    lastAvailabilityUpdateOn?: Moment;
    /** Contact email */
    email: string;
    /** Company id */
    companyId: string;
    /** Company name */
    companyName: string;
    /** Notification status type id */
    notificationStatusTypeId: number;
    /** Whether the user is verified or not */
    verified: boolean;
    dummy?: boolean;
    matchedSkills?: UserSkillDto[];
    matchedKeywords?: UserMatchedKeywords[];
    industries?: Sector[];
    qualifications?: UserQualification[];
    skills?: SubSector[];
    distance?: number | null;
    ownRating?: Rating,
    rating?: Rating // Own rating for external pages
    ownOrganisationRatings?: Rating[];
    externalRatings?: Rating[];
    avgRating?: string;
    totalRatings?: number;
    publicRating?: string;
    privateRating?: string;
    ownRatingStars?: number;
    avgRatingStars?: string;
    smsNotifications?: boolean;
    files?: UserFile[];
    folders?: UserFolder[];
    representedByOrganisations?: RepresentedByOrganisation[];
    availabilityLastConfirmed?: string;
    verifications?: VerificationDetailDto[];
    timePresets?: string;
    confidenceScore?: number;
    alreadyInvitedByOrgId?: string
    alreadyInvitedByOrgName?: string;
    hotLoaded?: boolean;
    workerAttributes?: Attribute[];
    notSharing?: boolean;
    maskedPhoneNumber?: string[];
    maskedEmailAddress?: string[];
    invitedByOrg?: boolean;
    reported?: boolean;
}

export interface Attribute {
    id: number;
    userId: string;
    sectorId: number;
    skillId: number;
    subSectorId: number;
    sectorName: string;
    skillName: string;
    subSectorName: string;
}

export interface UserFileRequest {
    fileId: number,
    userId: string,
    fileName: string,
    userName: string
}

export interface UserFileFolderRequest {
    folderId: number,
    userId: string,
    name: string,
    userName: string
}

export interface UserFile {
    id?: number;
    userId?: string;
    fileName?: string;
    fileFormat?: string;
    fileType?: FileType;
    fileCategory?: number;
    fileCategoryName?: string;
    description?: string;
    access: FileAccess;
    createdAt?: string;
    accessLink?: string;
    previewAccessLink?: string;
    displayOrder?: number;
    link?: string;
    thumbnailImg?: File | string;
    accessLinkExpiry?: number;
    folderId?: number;
    onlyConfirmedContacts?: boolean;
    virusScanned?: boolean;
    moderatorApproved?: any;
}

export interface UserFolder {
    id: number;
    name?: string;
    description?: string;
    access: FileAccess;
    userId?: string;
}

export interface UserFolderWithFilterType extends UserFolder {
    accessType?: string;
}

export interface UserFileWithFilterType extends UserFile {
    accessType: string;
}

export interface ServerConfig {
    allowedFileTypesToUpload: string[];
    fileCategorySuggestions: { id: number, name: string }[];
}

export interface ExplicitelySharedFile {
    fileId: number,
    userId: string,
    folderId?: number
}

export interface ExplicitelySharedFolder {
    folderId: number,
    userId: string,
    fileId?: number
}

// MS Office = xls, xlsx, doc, docx, ppt, pptx
// Graphics = jpg, jpeg, png
// Video = mp4, mov
// OpenOffice = odt, ods, opt
export enum FileType {
    Document = 0,
    TextDocument = 1,
    Image = 2,
    Video = 3,
    Link = 4,
    VideoLink = 5
}

export enum FileAccess {
    Public = 0,
    PublicToContacts = 1,
    OnRequest = 2,
    Private = 3
}

/**
 * Defines a group of Contacts
 */
export interface ContactList extends BaseModels.Loadable {
    /** Id of the contact group */
    id: number;
    /** Name of group */
    name: string;
    contactGroupName: string;
    /** Count of contacts in group */
    count: number;
    description?: string;
    memberCount?: number;
    createdAt?: string;
    createdByUserId?: string;
    membersLoaded?: boolean;
    organisationRelationships?: OrganisationRelationship[],
    teamRelationships?: TeamRelationship[]
    personal?: boolean;
    members?: {
        reported: boolean;
        firstName: string,
        lastName: string,
        userId: string,
        headline?: string,
        fullName? : string,
    }[]
}

/**
 * Defines a group of ContactGroups
 */
export interface ContactLists
    extends BaseModels.Creatable,
        BaseModels.Loadable,
        BaseModels.SingleItemSelectableCollection<ContactList> {
    /** Contact groups in this group */
    lists: ContactList[];
}
