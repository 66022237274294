/* eslint-disable */
import React from 'react';

function TaskListStage4(props) {
    return (
      <svg width="122.619" height="110.44" viewBox="0 0 122.619 110.44">
      <g id="Group_1756" data-name="Group 1756" transform="translate(-700.612 -197.676)">
        <g id="undraw_visual_data_b1wx" transform="translate(637.997 193.89)">
          <path id="Path_6606" data-name="Path 6606" d="M341.078,567.475v33.177q-3.635-.178-7.265-.427l-1.1-.078V567.475A1.3,1.3,0,0,1,334,566.18h5.778A1.3,1.3,0,0,1,341.078,567.475Z" transform="translate(-270.095 -487.012)" fill="#3f3d56"/>
          <path id="Path_6607" data-name="Path 6607" d="M462.71,472.231V416.475A1.3,1.3,0,0,1,464,415.18h5.778a1.3,1.3,0,0,1,1.295,1.295v55.9Z" transform="translate(-381.011 -358.179)" fill="#3f3d56"/>
          <path id="Path_6608" data-name="Path 6608" d="M592.71,539.808V495.475A1.3,1.3,0,0,1,594,494.18h5.778a1.3,1.3,0,0,1,1.295,1.295v44.238Z" transform="translate(-491.927 -425.582)" fill="#3f3d56"/>
          <path id="Path_6609" data-name="Path 6609" d="M731.078,271.628V357.4q-4.184.133-8.368.25V271.628A1.378,1.378,0,0,1,724,270.18h5.778A1.378,1.378,0,0,1,731.078,271.628Z" transform="translate(-602.844 -243.734)" fill="#3f3d56"/>
          <path id="Path_6612" data-name="Path 6612" d="M731.078,271.757v93.367q-4.184.145-8.368.272V271.757A1.457,1.457,0,0,1,724,270.18h5.778A1.457,1.457,0,0,1,731.078,271.757Z" transform="translate(-564.844 -251.481)" fill="#3f3d56"/>
          <path id="Path_6711" data-name="Path 6711" d="M731.078,272v107.99q-4.184.168-8.368.314V272c0-1.007.58-1.823,1.295-1.823h5.778C730.5,270.18,731.078,271,731.078,272Z" transform="translate(-545.844 -266.394)" fill="#3f3d56"/>
          <path id="Path_6610" data-name="Path 6610" d="M861.078,376.794v75.567c-2.789.168-5.578.287-8.368.393V376.794A1.481,1.481,0,0,1,854,375.18h5.778A1.481,1.481,0,0,1,861.078,376.794Z" transform="translate(-713.76 -339.387)" fill="#3f3d56"/>
        </g>
      </g>
    </svg>
    
    );
}

export default TaskListStage4;
