/* eslint-disable */
import afetch from '../AuthenticatedFetch';
import AppConfig from '../../components/config/Config';
import { AvailabilityFilterRequest } from './RequestTypes';
import { AvailabilityFilterResponse } from './ResponseTypes';
import UnauthenticatedFetch from 'src/api/UnauthenticatedFetch';

const apiCalls = {
    getAvailabilityFilters(entities: AvailabilityFilterRequest) {
        return afetch.request<AvailabilityFilterRequest, AvailabilityFilterResponse>(
            `${AppConfig.Settings.api.externalUri}/chatbot/availability`,
            'POST',
            entities,
            'Unable to confirm availability'
        );
    }
};

export default apiCalls;
