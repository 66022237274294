/* eslint-disable */
import afetch from '../AuthenticatedFetch';
import AppConfig from '../../components/config/Config';
import { BulkReferralOutcome, Referral } from './ResponseTypes';
import { ReferralContact } from './RequestTypes';

const apiCalls = {
    getAll: () => {
        return afetch.request<null, Referral[]>(
            `${AppConfig.Settings.api.externalUri}/referral/received`,
            'GET',
            null,
            'Unable to get referrals'
        );
    },
    acceptAllReferreals: (token: string) => {
        return fetch(`${AppConfig.Settings.api.externalUri}/referral/acceptall?token=${token}&api-version=1.0`, {
            method: 'PUT',
        });
    },
    acceptReferral: (id: number) => {
        return afetch.request<null, Referral>(
            `${AppConfig.Settings.api.externalUri}/referral/${id}/accept`,
            'PUT',
            null,
            'Unable to accept referral'
        );
    },
    rejectReferral: (id: number) => {
        return afetch.request<null, Referral>(
            `${AppConfig.Settings.api.externalUri}/referral/${id}/reject`,
            'PUT',
            null,
            'Unable to reject referral'
        );
    },
    sendReferral: (referredContact: ReferralContact, referredToContact: ReferralContact) => {
        return afetch.request<{
            referredContact: ReferralContact,
            referredToContact: ReferralContact
        }, number>(
            `${AppConfig.Settings.api.externalUri}/referral`,
            'PUT',
            {
                referredContact,
                referredToContact
            },
            'Unable to send referral'
        );
    },
    sendBulkReferral: (referredContacts: ReferralContact[], referredToContact: ReferralContact) => {
        return afetch.request<{
            referredContacts: ReferralContact[],
            referredToContact: ReferralContact
        }, BulkReferralOutcome[]>(
            `${AppConfig.Settings.api.externalUri}/referral/bulk`,
            'PUT',
            {
                referredContacts: referredContacts,
                referredToContact
            },
            'Unable to send referral'
        );
    }
};

export default apiCalls;