/* eslint-disable */

import React from 'react';
import styled from 'styled-components';

import AuthApi from '../../components/auth/Auth';
import ProfileApi from '../../api/profile/Profile';
import ReferralsApi from '../../api/referrals/Referrals';
import theme from '../../css/theme';
import history from '../../history';
import { RegularExpressions } from '../../constants';
import SvgFile from '../../components/svg/file';

enum Status {
    Pending,
    Success,
    Failed
}

enum ConfirmationType {
    Activation = 1,
    NewEmail = 2
}

export interface Props {
    location: Location;
}

interface State {
    status: Status;
    redirectToPortal: boolean;
    redirectTime: number;
    confirmationType: ConfirmationType | null;
    email: string | null;
    interval: number | null;
}

export default class EmailConfirmed extends React.Component<Props, State> {
    state = {
        status: Status.Pending,
        redirectTime: 5
    } as State;

    async componentDidMount() {
        const { search } = this.props.location;
        const result = RegularExpressions.tokenQueryParam.exec(search);

        if (!result) {
            return this.setState({ status: Status.Failed });
        }

        const token = decodeURIComponent(result[1]);
        const response = await ProfileApi.confirmEmail(token);
        await ReferralsApi.acceptAllReferreals(token)
        if (!response.ok) {
            return this.setState({
                status: Status.Failed
            });
        }

        AuthApi.refresh();

        let {
            redirectToPortal,
            confirmationType,
            email
        } = await (response.json() as Promise<{
            confirmationType: ConfirmationType;
            email: string;
            redirectToPortal: boolean;
        }>);

        if (localStorage.getItem("redirectToPortalOnVerification")) {
            localStorage.removeItem("redirectToPortalOnVerification");
            redirectToPortal = true;
        }

        this.setState({
            confirmationType,
            email,
            redirectToPortal,
            status: Status.Success
        });

        if (redirectToPortal) {
            const interval = window.setInterval(this.runRedirectInterval, 1500);
            this.setState({ interval });
        } else {
            if (window.location.href.startsWith("https://portal.updatedge.com")) {
                window.location.href = "https://my.updatedge.com/email-confirmed";
            } else {
                window.location.href = "https://test-my.updatedge.com/email-confirmed";
            }
        }
    }

    runRedirectInterval = () => {
        if (this.state.redirectTime <= 1) {
            this. redirect();
        } else {
            this.setState({
                redirectTime: this.state.redirectTime - 1
            });
        }
    };

    clearInterval = () => {
        if (this.state.interval) clearInterval(this.state.interval);
    };

    componentWillUnmount() {
        this.clearInterval();
    }

    redirect = (path = '/') => {
        this.clearInterval();
        // history.replace(path);
        window.location.href = "/"
    };

    render() {
        return <Container>{this.renderContent()}</Container>;
    }

    renderContent() {
        switch (this.state.status) {
            case Status.Pending:
                return this.renderPending();
            case Status.Success:
                if (this.state.confirmationType === ConfirmationType.NewEmail)
                    return this.renderSuccessNewEmail();

                return this.state.redirectToPortal
                    ? this.renderSuccessPortal()
                    : this.renderSuccess();
            case Status.Failed:
            default:
                return this.renderFailed();
        }
    }

    renderPending() {
        return (
            <div
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%,-50%)'
                }}
            >
                <i className="fa fa-spinner fa-spin" />
                <h4>Verifying...</h4>
            </div>
        );
    }

    renderSuccess() {
        return (
            <>
                <i className="fa fa-check" />
                <h4>Email confirmed!</h4>
                <div>
                    You can now start to share/receive availability and offers
                </div>
            </>
        );
    }

    renderSuccessNewEmail() {
        return (
            <>
                <i className="fa fa-check" />
                <h4>Email confirmed!</h4>
                <div>
                    The email '{this.state.email}' has been added to your
                    account
                </div>
                <div style={{ margin: '15px 0' }}>
                    <button
                        style={{ color: '#00F' }}
                        onClick={() => this.redirect('/profile')}
                    >
                        Go to the portal
                    </button>
                </div>
            </>
        );
    }

    renderFailed() {
        return (
            <div className="link-expired layout vertical center-center">
                <SvgFile />
                <h4 style={{ margin: '20px 0' }}>Invalid activation link</h4>
                <h4 style={{ margin: '0' }}>The link might have expired</h4>
            </div>
        );
    }

    renderSuccessPortal() {
        return (
            <div className="success-portal">
                <h4>Email confirmed!</h4>
                <img src="/app-static/assets/thumbs-up.svg" />
                <div style={{ marginTop: '10px' }}>
                    Receive availability and send instant offers
                </div>
                <div style={{ margin: '15px 0' }}>
                    <a
                        href="#"
                        style={{ color: '#00F' }}
                        onClick={() => this.redirect('/')}
                    >
                        Go to the portal
                    </a>
                </div>
                <div className="redirect">
                    Automatically redirecting you in...{' '}
                    <span>{this.state.redirectTime}</span>
                </div>
            </div>
        );
    }
}

const Container = styled.div`
    width: 100%;
    text-align: center;

    .fa {
        margin-bottom: 10px;
        font-size: 30px;

        &.fa-check {
            color: ${theme.colours.green};
        }

        &.fa-exclamation-triangle {
            color: ${theme.colours.red};
        }
    }

    .success-portal {
        h4 {
            color: black;
            font-size: 22px;
        }

        img {
            width: 82px;
            height: 82px;
            margin-bottom: 10px;
        }

        .redirect {
            margin-top: 10px;
            color: black;

            span {
                font-weight: bold;
            }
        }
    }

    .link-expired {
        height: 260px;
        margin-top: -80px;
        padding: 20px 0;
        color: ${theme.colours.updatedge};
        font-weight: 600;
        background: white;

        > svg {
            width: 70px;
            height: 70px;
        }
    }

    button {
        background: none;
        border: none;
    }
`;
