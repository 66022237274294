import React from 'react';

function SvgAnalytics(props) {
    return (
        <svg height="1em" viewBox="0 0 512 512" width="1em" {...props}>
            <path
                d="M512 256c0 141.387-114.613 256-256 256S0 397.387 0 256 114.613 0 256 0s256 114.613 256 256zm0 0"
                fill="#66baed"
            />
            <path
                d="M154.328 0H479.93C497.64 0 512 14.36 512 32.07v307.082c0 17.711-14.36 32.067-32.07 32.067H154.328c-17.715 0-32.07-14.356-32.07-32.067V32.07c0-17.71 14.355-32.07 32.07-32.07zm0 0"
                fill="#3d4159"
            />
            <path
                d="M154.328 350.32c-6.164 0-11.172-5.007-11.172-11.168V32.07c0-6.16 5.012-11.172 11.172-11.172H479.93c6.16 0 11.172 5.012 11.172 11.172v307.082c0 6.16-5.012 11.168-11.172 11.168zm0 0"
                fill="#f2f0ec"
            />
            <path
                d="M183.52 281.219h288.117v29.398H183.52zm0 0M200.18 46.023h33.32v209.715h-33.32zm0 0"
                fill="#713775"
            />
            <path
                d="M274.004 82.414h33.32v173.324h-33.32zm0 0"
                fill="#f74f4f"
            />
            <path
                d="M347.832 46.023h33.32v209.715h-33.32zm0 0"
                fill="#66baed"
            />
            <path
                d="M421.656 124.422h33.32v131.316h-33.32zm0 0"
                fill="#3fb777"
            />
            <path
                d="M375.727 426.402v55.922a252.053 252.053 0 01-31.25 13.961 248.587 248.587 0 01-22.028 6.988v-76.87zm0 0"
                fill="#dde8ed"
            />
            <path
                d="M344.477 426.402v69.883a248.587 248.587 0 01-22.028 6.988v-76.87zm0 0"
                fill="#bfd5de"
            />
            <path
                d="M425.277 411c0 8.504-6.898 15.402-15.402 15.402h-87.426V411c0-8.508 6.895-15.402 15.403-15.402h72.023c4.254 0 8.11 1.722 10.887 4.511a15.524 15.524 0 013.418 5.184 15.241 15.241 0 011.097 5.707zm0 0"
                fill="#eab599"
            />
            <path
                d="M424.18 405.293c-2.27 5.684-7.817 9.7-14.305 9.7h-87.426V411c0-8.508 6.895-15.402 15.403-15.402h72.023c4.254 0 8.11 1.722 10.887 4.511a15.524 15.524 0 013.418 5.184zm0 0"
                fill="#f6caae"
            />
            <path
                d="M306.89 449.578v29.246a254.105 254.105 0 01-88.535 30.426c-42.714-6.3-82.003-23.145-115.117-47.805a257.142 257.142 0 01-43.875-41.523c11.684-35.652 43.875-62.024 82.64-65.16a96.904 96.904 0 017.786-.313h61.953c7.324 0 14.45.817 21.293 2.383 42.297 9.676 73.856 47.531 73.856 92.746zm0 0"
                fill="#bfd5de"
            />
            <path
                d="M306.89 449.578v57.363A257.5 257.5 0 01256.005 512c-57.262 0-110.121-18.797-152.766-50.555v-11.867c0-52.539 42.602-95.129 95.149-95.129h13.355c52.559 0 95.149 42.59 95.149 95.13zm0 0"
                fill="#dde8ed"
            />
            <path
                d="M206.305 388.773l-25.54 40.961-25.535-40.96"
                fill="#f74f4f"
            />
            <path
                d="M203.227 506.543a253.213 253.213 0 01-41.692-12.547l19.235-74.918zm0 0"
                fill="#f74f4f"
            />
            <g fill="#bfd5de">
                <path d="M180.762 393.395l-42.832 23.8-13.028-60.601 26.61-15.676M180.762 393.395l42.828 23.8 13.027-60.601-26.613-15.676M219.434 446.453h51.199v15.672h-51.2zm0 0" />
            </g>
            <path
                d="M214.73 317.8v41.657c0 15.25-10.054 28.14-23.906 32.422a33.948 33.948 0 01-10.043 1.516c-18.742 0-33.937-15.196-33.937-33.938v-41.656zm0 0"
                fill="#eab599"
            />
            <path
                d="M214.73 356.816v2.641c0 15.25-10.054 28.14-23.906 32.422-12.496-3.844-21.89-14.707-23.625-28.004a116.192 116.192 0 0025.688-1.309 115.074 115.074 0 0021.843-5.75zm0 0M87.68 249.535c0 15.711-11.48 28.742-26.512 31.16a31.64 31.64 0 01-5.055.403c-17.437 0-31.566-14.125-31.566-31.563s14.129-31.566 31.566-31.566c1.723 0 3.414.14 5.055.406 15.031 2.418 26.512 15.445 26.512 31.16zm0 0"
                fill="#f6caae"
            />
            <path
                d="M87.68 249.535c0 15.711-11.48 28.742-26.512 31.16a117.374 117.374 0 01-4.188-31.16c0-10.785 1.458-21.234 4.188-31.16 15.031 2.418 26.512 15.445 26.512 31.16zm0 0"
                fill="#eab599"
            />
            <path
                d="M67.395 249.535c0 6.23-5.051 11.281-11.282 11.281s-11.281-5.05-11.281-11.28c0-6.231 5.05-11.282 11.281-11.282s11.282 5.05 11.282 11.281zm0 0"
                fill="#eab599"
            />
            <path
                d="M273.895 249.535c0 15.711 11.48 28.742 26.507 31.16 1.645.266 3.332.403 5.059.403 17.437 0 31.566-14.125 31.566-31.563s-14.129-31.566-31.566-31.566c-1.727 0-3.414.14-5.059.406-15.027 2.418-26.507 15.445-26.507 31.16zm0 0"
                fill="#f6caae"
            />
            <path
                d="M273.895 249.535c0 15.711 11.48 28.742 26.507 31.16a117.376 117.376 0 004.192-31.16c0-10.785-1.457-21.234-4.192-31.16-15.027 2.418-26.507 15.445-26.507 31.16zm0 0"
                fill="#eab599"
            />
            <path
                d="M298.23 188.04v44.722c0 58.441-42.695 106.922-98.597 115.918-.188.03-.387.062-.574.097-5.946.95-12.047 1.418-18.278 1.418-64.855 0-117.433-52.578-117.433-117.433v-44.723c0-13.656 2.328-26.781 6.632-38.977 16.063-45.703 59.614-78.468 110.801-78.468h.012c6.219 0 12.332.48 18.285 1.418 56.176 8.777 99.152 57.386 99.152 116.027zm0 0"
                fill="#eab599"
            />
            <path
                d="M316.742 249.535c0 6.23-5.05 11.281-11.281 11.281-6.23 0-11.281-5.05-11.281-11.28 0-6.231 5.05-11.282 11.28-11.282 6.231 0 11.282 5.05 11.282 11.281zm0 0"
                fill="#eab599"
            />
            <path
                d="M298.23 188.04v44.722c0 58.441-42.695 106.922-98.597 115.918-.188.03-.387.062-.574.097-56.176-8.777-99.141-57.386-99.141-116.015v-44.723c0-58.64 42.977-107.25 99.16-116.027 56.176 8.777 99.152 57.386 99.152 116.027zm0 0"
                fill="#f6caae"
            />
            <path
                d="M155.777 225.922c0 7.36-5.964 13.32-13.324 13.32-7.355 0-13.32-5.96-13.32-13.32s5.965-13.324 13.32-13.324c7.36 0 13.324 5.964 13.324 13.324zm0 0M232.445 225.922c0 7.36-5.965 13.32-13.324 13.32-7.355 0-13.32-5.96-13.32-13.32s5.965-13.324 13.32-13.324c7.36 0 13.324 5.964 13.324 13.324zm0 0"
                fill="#3d4159"
            />
            <path
                d="M153.059 223.57a5.706 5.706 0 11-11.414 0 5.706 5.706 0 1111.414 0zm0 0M230.383 223.57a5.706 5.706 0 01-5.707 5.707 5.707 5.707 0 110-11.414 5.706 5.706 0 015.707 5.707zm0 0"
                fill="#fff"
            />
            <path
                d="M194.11 254.242h-26.645a5.749 5.749 0 010-11.496h26.644a5.749 5.749 0 110 11.496zm0 0M180.79 290.602c-9.837 0-19.669-2.465-28.634-7.395a5.223 5.223 0 115.031-9.156c14.778 8.125 32.422 8.125 47.2 0a5.22 5.22 0 017.093 2.062 5.22 5.22 0 01-2.062 7.094c-8.961 4.93-18.797 7.395-28.629 7.395zm0 0"
                fill="#eab599"
            />
            <path
                d="M298.23 147.453v45.809h-21.859V157.39L118.59 128.07l-33.383-6.208v71.399h-21.86v-45.809c0-52.226 42.329-94.555 94.544-94.555h45.797c1.242 0 2.488.02 3.73.086 50.48 1.942 90.812 43.489 90.812 94.47zm0 0"
                fill="#e8c934"
            />
            <path
                d="M298.23 147.453v45.809h-21.859V157.39L118.59 128.07c8.695-41.755 44.922-73.395 88.828-75.087 50.48 1.942 90.812 43.489 90.812 94.47zm0 0"
                fill="#ffe152"
            />
        </svg>
    );
}

export default SvgAnalytics;
