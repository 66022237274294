// TODONOW
/* eslint-disable */
import React from 'react';
import styled from 'styled-components';
import { Modal, Table, Button } from 'react-bootstrap';
import {
    OrganisationDTO,
    OrganisationUser
} from '../../api/organisation/ResponseTypes';
import { debounce } from 'ts-debounce';
import {
    AddWorkerTemporaryOrganisationRequest,
    UserChangeThirdpartyRequest
} from '../../api/profile/RequestTypes';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import ReferralsApi from '../../api/referrals/Referrals';

import { simpleSearch } from '../../constants';
import CompanyApi from '../../api/company/Company';
import ProfileApi from '../../api/profile/Profile';
import OrganisationApi from '../../api/organisation/Organisation';
import { AsyncOverlay } from '../ui-components/AsyncOverlay';
import { UserThirdParty } from '../../api/profile/ResponseTypes';
import Hyperlink from '../ui-components/Hyperlink';
import { ProfileImage } from '../ui-components/ProfileImage';
import SimpleTooltip from '../ui-components/SimpleTooltip';
import ClearBit, { ClearBitCompany } from '../../services/clearbit.service';
import WarningTip from '../ui-components/WarningTip';
import { getFirstError } from '../../api/AuthenticatedFetch';
import { Spinner } from '../ui-components/Spinner';
import SmallInput, { SmallInputVariant } from '../ui-components/SmallInput';
import * as EventsState from '../../store/Events';
import { Subscribe } from '../../unstated-fork/unstated';
import DashboardContainer from '../../state-containers/dashboard.container';
import { UserTypeContainer } from '../../state-containers/user-type.container';
import InternalTracker from 'src/InternalTracker';
import theme from 'src/css/theme';
import { getValue, setValue } from 'src/db/KeyValueCache';
import { CircularProgress } from '@mui/material';
import { Referral } from 'src/api/referrals/ResponseTypes';
import Utilities from 'src/Utilities';

enum Stage {
    OrganisationSearch,
    ClearBitSearch,
    ContactSearch,
    ContactCreate
}

export type Props = {
    disableContactRequirement?: boolean;
    disableAgencyListSearch?: boolean;
    onConfirm?: (userAgencies: UserThirdParty[]) => void;
    isMobile?: boolean;
    autoAddOnOpen?: boolean;
} & typeof EventsState.actionCreators;

interface State {
    loading: boolean;
    userAgencies: UserThirdParty[];
    referrals: Referral[];
    isAddingNewAgency: boolean;
    stage: Stage;
    selectedOrganisationId: OrganisationDTO['id'] | null;
    selectedPrimaryContactUserId: OrganisationUser['id'] | null;
    selectedClearBitResultDomain: ClearBitCompany['domain'] | null;
    newOrganisationName: string;
    newContactName: string;
    newContactEmail: string;
    isSearchingOrganisations: boolean;
    isSearchingUsers: boolean;
    isSearchingClearBit: boolean;
    clearBitSearchResults: ClearBitCompany[];
    clearBitSearchNoResults: boolean;
    organisationSearchResults: OrganisationDTO[];
    organisationSearchNoResults: boolean;
    organisationMembersSearchResults: OrganisationUser[];
    organisationMembersSearchNoResults: boolean;
    filterValue: '';
    editing: UserThirdParty | null;
    editingValues: UserChangeThirdpartyRequest;
    autoSuggestedContactDetails: boolean;
    stale: boolean;
    loadingBlocked: boolean;
    canEditAgencies: boolean;
}

const SEARCH_DEBOUNCE_TIME = 700;

class ProfileAgencies extends React.Component<Props, State> {
    initialState = {
        loading: false,
        userAgencies: [],
        referrals: [],
        isAddingNewAgency: false,
        stage: Stage.OrganisationSearch,
        selectedOrganisationId: null,
        selectedPrimaryContactUserId: null,
        selectedClearBitResultDomain: null,
        newOrganisationName: '',
        newContactName: '',
        newContactEmail: '',
        isSearchingOrganisations: false,
        isSearchingUsers: false,
        isSearchingClearBit: false,
        clearBitSearchResults: [],
        clearBitSearchNoResults: false,
        organisationSearchResults: [],
        organisationSearchNoResults: false,
        organisationMembersSearchResults: [],
        organisationMembersSearchNoResults: false,
        filterValue: '',
        editing: null,
        editingValues: {
            organisationName: '',
            primaryContactName: '',
            email: '',
            isUser: false,
            userContactId: 0
        } as UserChangeThirdpartyRequest,
        autoSuggestedContactDetails: false,
        stale: false,
        loadingBlocked: false,
        canEditAgencies: (localStorage.getItem('user') && JSON.parse(localStorage.getItem('user') || "{}").role == 2) ? true : false
    } as State;

    state = this.initialState;

    async componentDidMount() {
        await this.getUserAgencies();
        (window as any).onSignalRReferralReceived = () => {
            this.getUserAgencies();
        }
    }

    private async getUserAgencies() {
        const staleUserAgencies = await getValue("agencies");
        if (staleUserAgencies) {
            this.setState({ userAgencies: staleUserAgencies, stale: true });
        }
        const { data: userAgencies } = await ProfileApi.getThirdParties();
        const referrals = await ReferralsApi.getAll();
        setValue("agencies", userAgencies);
        this.setState({
            userAgencies,
            referrals,
        });

        this.setState({ stale: false })
    }

    setAgencyName(name: string) {
        this.setState((prevState) => {
            return {
                ...prevState,
                editingValues: {
                    ...prevState.editingValues,
                    organisationName: name
                }
            };
        });
    }

    setAgencyPrimaryContact(name: string) {
        this.setState((prevState) => {
            return {
                ...prevState,
                editingValues: {
                    ...prevState.editingValues,
                    primaryContactName: name
                }
            };
        });
    }

    setAgencyEmail(email: string) {
        this.setState((prevState) => {
            return {
                ...prevState,
                editingValues: {
                    ...prevState.editingValues,
                    email: email
                }
            };
        });
    }

    setEditingAgency(anAgency: UserThirdParty | null) {
        if (anAgency) {
            this.setState({
                editing: anAgency,
                editingValues: {
                    organisationName: anAgency.organisationName,
                    primaryContactName: anAgency.primaryContactName!,
                    email: anAgency.email!,
                    isUser: !!anAgency.contactUserId,
                    userContactId: anAgency.userContactId!
                } as UserChangeThirdpartyRequest
            });
        } else {
            this.setState({
                editing: null,
                editingValues: {
                    organisationName: '',
                    primaryContactName: '',
                    email: '',
                    isUser: false,
                    userContactId: 0
                } as UserChangeThirdpartyRequest
            });
        }
    }

    capitaliseFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    async saveEditingAgency(which: number) {
        const oldValues = this.state.userAgencies[which];
        const newValues = this.state.editingValues;

        if (
            oldValues.primaryContactName !== newValues.primaryContactName ||
            oldValues.email !== newValues.email
        ) {
            await ProfileApi.changeThirdParty(this.state.editingValues)
                .then(async (r) => {
                    toast.success(
                        `${this.capitaliseFirstLetter(
                            r.data.request.organisationName
                        )} agency successfully updated.`
                    );
                    await this.getUserAgencies();
                    this.setEditingAgency(null);
                })
                .catch((e) => {
                    this.setEditingAgency(null);
                });
        } else {
            this.setEditingAgency(null);
        }
    }

    render() {
        if (!this.state.userAgencies.length && !this.state.referrals.length && this.state.canEditAgencies) {
            return (
                <>
                    <AddButton onClick={this.handleAdd} empty={true} />
                    <div className="layout horizontal justified">
                        <span style={{ fontStyle: 'italic', marginTop: '7px' }}>
                            You have not added any agencies yet
                        </span>
                    </div>
                    {this.renderModal()}
                </>
            );
        }

        const filteredAgencies = simpleSearch(
            this.state.filterValue,
            this.state.userAgencies,
            ['email', 'organisationName', 'primaryContactName']
        );

        return (
            <>
                { (this.state.canEditAgencies) &&
                    <div className="layout horizontal">
                        <AddButton onClick={this.handleAdd} empty={false} />
                    </div>
                }
                { (this.state.stale) &&
                    <div className="nonblocking-loading-wrapper">
                        <CircularProgress style={{ width: 24, height: 24 }} />
                        Fetching Latest
                    </div>
                }
                <Table
                    className="table-borderless"
                    // condensed
                    striped
                    responsive
                    style={{
                        marginBottom: 0,
                        display: this.state.isAddingNewAgency ? 'none' : undefined
                    }}
                >
                    <thead>
                        <tr>
                            <th />
                            <th>Agency</th>
                            <th style={{ whiteSpace: 'nowrap' }}>
                                Primary Contact
                                {PrimaryContactTooltip}
                            </th>
                            <th>Email Address</th>
                            {this.props.disableAgencyListSearch ? (
                                <th />
                            ) : (
                                <th colSpan={2}>
                                    <SmallInputVariant
                                        placeholder="Search..."
                                        onChange={(e) => {
                                            this.setState({
                                                filterValue: e.target.value
                                            });
                                        }}
                                        value={this.state.filterValue}
                                        className="pull-right"
                                        onBlur={() => {
                                            InternalTracker.trackEvent("", { 
                                                category: 'Agency', 
                                                action: 'Searched',
                                                customDimensions: [{ id: "REPLACE", value: this.state.filterValue}]
                                            });
                                        }}
                                    />
                                </th>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        { this.state.referrals.length > 0 && window.location.pathname.startsWith("/agencies") && this.state.referrals.map((referral, i) => {
                            return (
                                <AgencyRow
                                    key={referral.id}
                                    data-testid="agency-row"
                                >
                                    <td>
                                        <ProfileImage
                                            selectable={false}
                                            url={referral.referredToContactEmail ? OrganisationApi.getExtOrgPicture(referral.referredToContactEmail.split("@")[1]) : ""}
                                            type="Organisation"
                                            size={40}
                                        />
                                    </td>   
                                    <td className="agency-details-col">{referral.referredToContactOrgName}</td>
                                    <td>{referral.referredToContactFirstName}</td>
                                    <td>{referral.referredToContactEmail}</td>
                                    <td>
                                        <Button
                                            bsStyle="success"
                                            className="btn m-xxs"
                                            bsSize="small"
                                            onClick={() => {
                                                ReferralsApi.acceptReferral(referral.id).then(() => {
                                                    this.getUserAgencies();
                                                    toast.success("Referral accepted");
                                                }).catch(() => {
                                                    this.getUserAgencies();
                                                    toast.error("Failed to accept referral");
                                                })
                                            }}
                                        >
                                            <i className="fa fa-check" />{' '}
                                            Accept
                                        </Button>
                                    </td>
                                    <td>
                                        <Button
                                            bsStyle="danger"
                                            className="btn m-xxs"
                                            bsSize="small"
                                            onClick={() => {
                                                ReferralsApi.rejectReferral(referral.id).then(() => {
                                                    this.getUserAgencies();
                                                    toast.success("Referral rejected");
                                                }).catch(() => {
                                                    this.getUserAgencies();
                                                    toast.error("Failed to reject referral");
                                                })
                                            }}
                                        >
                                            <i className="fa fa-times" style={{ padding: 'unset' }} />{' '}
                                            Reject
                                        </Button>
                                    </td>
                                </AgencyRow>
                            )
                        }) }
                        {filteredAgencies.length === 0 && this.state.referrals.length === 0 ? (
                            <AgencyRow>
                                <td colSpan={4}>No results found...</td>
                            </AgencyRow>
                        ) : (
                            filteredAgencies
                                .sort(
                                    (
                                        { organisationName: a },
                                        { organisationName: b }
                                    ) => a && b ? a.localeCompare(b) : 0
                                )
                                .map((ua, i) => {
                                    const imgUrl = ua.organisationId ? CompanyApi.getOrganisationProfileImageUrl(ua.organisationId) :
                                        ua.email && ua.email.split('@')[1] ? OrganisationApi.getExtOrgPicture(ua.email.split('@')[1]) :
                                        CompanyApi.getTemporaryOrganisationProfileImageUrl(ua.imageId);

                                    return (
                                        <AgencyRow
                                            key={`${ua.imageId}-${ua.primaryContactName}`}
                                            data-testid="agency-row"
                                        >
                                            <td>
                                                <ProfileImage
                                                    selectable={false}
                                                    url={imgUrl}
                                                    type="Organisation"
                                                    size={40}
                                                />
                                            </td>

                                            <td className="agency-details-col">
                                                {false ? (
                                                    <SmallInput
                                                        type="text"
                                                        className="form-control"
                                                        style={{
                                                            width: '100%',
                                                            marginLeft: 0
                                                        }}
                                                        value={
                                                            this.state
                                                                .editingValues
                                                                .organisationName
                                                        }
                                                        name={
                                                            ua.organisationName
                                                        }
                                                        onChange={(e) => {
                                                            this.setAgencyName(
                                                                e.target.value
                                                            );
                                                        }}
                                                        placeholder="Enter new agency name"
                                                    />
                                                ) : ua.organisationName ? (
                                                    ua.organisationName
                                                ) : (
                                                    <span className="not-set">
                                                        Unknown
                                                    </span>
                                                )}
                                            </td>

                                            {this.props.isMobile ?? (
                                                <>
                                                    <td>
                                                        {ua ===
                                                            this.state
                                                                .editing &&
                                                        !this.state
                                                            .editingValues
                                                            .isUser ? (
                                                            <SmallInput
                                                                type="text"
                                                                className="form-control"
                                                                style={{
                                                                    width:
                                                                        '100%',
                                                                    marginLeft: 0
                                                                }}
                                                                value={
                                                                    this.state
                                                                        .editingValues
                                                                        .primaryContactName
                                                                }
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    this.setAgencyPrimaryContact(
                                                                        e.target
                                                                            .value
                                                                    );
                                                                }}
                                                            />
                                                        ) : ua.primaryContactName ? (
                                                            ua.primaryContactName
                                                        ) : (
                                                            <span className="not-set">
                                                                Unspecified
                                                            </span>
                                                        )}
                                                    </td>
                                                    <td>
                                                        {ua ===
                                                        this.state.editing ? (
                                                            <SmallInput
                                                                type="text"
                                                                className="form-control"
                                                                style={{
                                                                    width:
                                                                        '100%',
                                                                    marginLeft: 0
                                                                }}
                                                                value={
                                                                    this.state
                                                                        .editingValues
                                                                        .email
                                                                }
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    this.setAgencyEmail(
                                                                        e.target
                                                                            .value
                                                                    );
                                                                }}
                                                            />
                                                        ) : ua.email ? (
                                                            ua.email
                                                        ) : (
                                                            <span className="not-set">
                                                                Unknown
                                                            </span>
                                                        )}
                                                    </td>
                                                </>
                                            )}
                                            { (this.state.canEditAgencies) ?
                                                <td
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'flex-end'
                                                    }}
                                                >
                                                    {ua === this.state.editing ? (
                                                        <Button
                                                            style={{
                                                                visibility: true //!ua.organisationId
                                                                    ? 'visible'
                                                                    : 'hidden'
                                                            }}
                                                            bsStyle="warning"
                                                            className="btn m-xxs"
                                                            bsSize="small"
                                                            onClick={() => {
                                                                InternalTracker.trackEvent("", { category: 'Agency', action: 'Updated Agency' });
                                                                this.saveEditingAgency(
                                                                    i
                                                                );
                                                            }}
                                                        >
                                                            <i className="fas fa-save" />{' '}
                                                            Save
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            style={{
                                                                visibility: true //!ua.organisationId
                                                                    ? 'visible'
                                                                    : 'hidden'
                                                            }}
                                                            bsStyle="primary"
                                                            className="btn m-xxs"
                                                            bsSize="small"
                                                            onClick={() => {
                                                                this.setEditingAgency(
                                                                    ua
                                                                );
                                                            }}
                                                        >
                                                            <i className="fa fa-pencil" />{' '}
                                                            Edit
                                                        </Button>
                                                    )}
                                                </td>
                                                : 
                                                <td></td>
                                            }
                                            { (this.state.canEditAgencies) ?
                                                <td>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent:
                                                                'flex-end',
                                                            alignItems: 'baseline'
                                                        }}
                                                    >
                                                        {getAgencyRowWarningText(
                                                            ua
                                                        ) != null ? (
                                                            <SimpleTooltip
                                                                id={`uc-${ua.userContactId}-warning`}
                                                                text={getAgencyRowWarningText(
                                                                    ua
                                                                )}
                                                            >
                                                                <WarningTip
                                                                    data-testid={`uc-${ua.userContactId}-warning`}
                                                                    className="fa fa-exclamation-triangle m-r"
                                                                />
                                                            </SimpleTooltip>
                                                        ) : null}

                                                        <i
                                                            className="fa fa-trash-o fa-hover"
                                                            aria-label="Remove Third Party"
                                                            onClick={() => {
                                                                InternalTracker.trackEvent("", { category: 'Agency', action: 'Removed Agency' });
                                                                this.handleRemove(
                                                                    ua
                                                                )
                                                            }}
                                                        />
                                                    </div>
                                                </td>
                                                :
                                                <td></td>
                                            }
                                        </AgencyRow>
                                    );
                                })
                        )}
                    </tbody>
                </Table>
                {this.renderModal()}
            </>
        );
    }

    renderModal() {
        if (!this.state.isAddingNewAgency) return null;

        return (
            <Modal
                show={this.state.isAddingNewAgency}
                backdrop="static"
                onHide={() => {}}
                keyboard={false}
            >
                <Modal.Body>
                    <ModalBodyWrapper>
                        {this.renderStage()}
                        <AsyncOverlay show={this.state.loading} />
                    </ModalBodyWrapper>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.resetAddAgencyState}>Cancel</Button>
                    <Button
                        bsStyle="primary"
                        onClick={this.handleNext}
                        disabled={!this.isNextEnabled() || this.state.loadingBlocked}
                    >
                        {this.isFinalStage() ? 'Done' : 'Next'}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    async getAgencySuggestion() {
        const data = await CompanyApi.getAgencySuggestion(this.state.selectedClearBitResultDomain || "");
        if (data && data[0] && data[0].email && data[0].name) {
            this.setState({
                newContactEmail: data[0].email,
                newContactName: data[0].name,
                autoSuggestedContactDetails: true
            })
        }
    }

    renderStage() {
        const { stage } = this.state;
        switch (stage) {
            case Stage.OrganisationSearch:
                return (
                    <SelectContainer>
                        <ModalHeading>
                            <label htmlFor="organisationSearch">
                                Enter your agency's organization name
                            </label>
                        </ModalHeading>
                        <input
                            key="organisationSearch"
                            id="organisationSearch"
                            autoFocus
                            type="text"
                            className="form-control m-b"
                            placeholder="Agency's organization name"
                            autoComplete="none"
                            value={this.state.newOrganisationName}
                            onChange={this.handleOrganisationSearchChange}
                        />

                        {this.renderOrganisationList()}

                        <Table condensed striped hover>
                            <tbody>
                                {this.state.clearBitSearchResults.map(
                                    ({ domain, name, logo, notes }) => {
                                        const imgUrl = logo || '';
                                        return (
                                            <tr
                                                key={domain}
                                                onClick={() =>
                                                    this.setState({
                                                        selectedClearBitResultDomain: domain,
                                                        newOrganisationName: name,
                                                        selectedOrganisationId: null,
                                                        newContactName: "",
                                                        newContactEmail: "",
                                                        autoSuggestedContactDetails: false
                                                    }, () => {
                                                        this.getAgencySuggestion();
                                                        this.handleNext();
                                                    })
                                                }
                                                className={
                                                    this.state
                                                        .selectedClearBitResultDomain ===
                                                    domain
                                                        ? 'selected'
                                                        : ''
                                                }
                                            >
                                                <td>
                                                    <div
                                                        className="profile" 
                                                        style={{ position: 'relative' }}
                                                    >
                                                        <ProfileImage
                                                            selectable={false}
                                                            url={imgUrl}
                                                            size={40}
                                                            type="Organisation"
                                                        />
                                                        <h3>
                                                            {name}
                                                            {/* { notes &&
                                                                <span>{notes}</span>
                                                            } */}
                                                        </h3>
                                                        <SimpleTooltip
                                                            id="org-unverified"
                                                            text="These organizations have not been verified by Updatedge."
                                                            delay={200}
                                                        >
                                                            <i 
                                                                style={{ 
                                                                    color: theme.colours.red2,
                                                                    position: "absolute",
                                                                    top: 16,
                                                                    right: 12,
                                                                    fontSize: 28
                                                                }} 
                                                                className="fa fa-shield"
                                                            />
                                                        </SimpleTooltip>
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    }
                                )}
                                
                            </tbody>
                        </Table>

                        { (this.state.newOrganisationName && !this.state.isSearchingOrganisations) &&
                            <Table condensed striped hover style={{ marginTop: -20 }}>
                                <tbody>
                                    <tr onClick={() => {
                                        this.setStage(Stage.ContactCreate);
                                        this.setState({
                                            selectedClearBitResultDomain: null
                                        });
                                    }}>
                                        <td style={{
                                            padding: 20,
                                            textAlign: "center"
                                        }}>
                                            <Hyperlink
                                                className="link"
                                                style={{ fontWeight: 600 }}
                                            >
                                                Add "{this.state.newOrganisationName}" manually.
                                            </Hyperlink>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        }

                        { (this.state.isSearchingOrganisations) &&
                            <SearchHelperText
                                isSearching={this.state.isSearchingOrganisations}
                                noResultsFound={
                                    this.state.organisationSearchNoResults
                                }
                            />
                        }
                    </SelectContainer>
                );

            case Stage.ClearBitSearch:
                return (
                    <SelectContainer>
                        <ModalHeading>
                            <label htmlFor="clearBitSearch">
                                Search for a company
                            </label>
                            <br />
                            <small style={{ marginTop: 10, display: 'block', lineHeight: '1.5em' }}>
                                These organizations have not been verified by
                                updatedge.
                            </small>
                        </ModalHeading>
                        <input
                            id="clearBitSearch"
                            autoFocus
                            type="text"
                            className="form-control m-b"
                            placeholder="Search for a company..."
                            autoComplete="none"
                            value={this.state.newOrganisationName}
                            onChange={this.handleOrganisationSearchChange}
                        />
                        <div
                            style={{
                                position: 'relative',
                                minHeight: '150px'
                            }}
                        >
                            <Table condensed striped hover>
                                <tbody>
                                    {this.state.clearBitSearchResults.map(
                                        ({ domain, name, logo }) => {
                                            const imgUrl = logo || '';
                                            return (
                                                <tr
                                                    key={domain}
                                                    onClick={() =>
                                                        this.setState({
                                                            selectedClearBitResultDomain: domain,
                                                            newOrganisationName: name
                                                        })
                                                    }
                                                    className={
                                                        this.state
                                                            .selectedClearBitResultDomain ===
                                                        domain
                                                            ? 'selected'
                                                            : ''
                                                    }
                                                >
                                                    <td>
                                                        <div className="profile">
                                                            <ProfileImage
                                                                selectable={false}
                                                                url={imgUrl}
                                                                size={40}
                                                                type="Organisation"
                                                            />
                                                            <h3>{name}</h3>
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        }
                                    )}
                                </tbody>
                            </Table>
                            {!this.state.isSearchingClearBit &&
                                !this.state.clearBitSearchResults.length && (
                                    <SearchHelperText
                                        isSearching={
                                            this.state.isSearchingClearBit
                                        }
                                        noResultsFound={
                                            this.state.clearBitSearchNoResults
                                        }
                                    />
                                )}
                            <Hyperlink
                                className="link"
                                onClick={() => {
                                    this.setStage(Stage.ContactCreate);
                                    this.setState({
                                        selectedClearBitResultDomain: null
                                    });
                                }}
                                style={{ fontWeight: 600 }}
                            >
                                Still not found?
                            </Hyperlink>
                        </div>
                    </SelectContainer>
                );

            case Stage.ContactSearch:
                return (
                    <SelectContainer>
                        <ModalHeading>
                            <label htmlFor="primaryContactSearch">
                                Enter your agency consultant's details
                            </label>
                        </ModalHeading>
                        <input
                            key="primaryContactSearch"
                            id="primaryContactSearch"
                            autoFocus
                            type="text"
                            className="form-control m-b"
                            placeholder="Enter your agency consultant's details"
                            onChange={(e) => {
                                this.setState({ isSearchingUsers: true });
                                this.handleOrganisationUserSearchChange(
                                    e.target.value
                                );
                            }}
                        />

                        {this.renderContactList()}

                        {!this.state.isSearchingUsers &&
                            <Table condensed striped hover style={{ marginTop: -20 }}>
                                <tbody>
                                    <tr onClick={() => {
                                        this.setStage(Stage.ContactCreate);
                                        this.setState({
                                            selectedPrimaryContactUserId: null
                                        });
                                    }}>
                                        <td style={{
                                            padding: 20,
                                            textAlign: "center"
                                        }}>
                                            <Hyperlink
                                                className="link"
                                                style={{ fontWeight: 600 }}
                                            >
                                                Contact not listed?
                                            </Hyperlink>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        }

                        <SearchHelperText
                            isSearching={this.state.isSearchingUsers}
                            noResultsFound={
                                this.state.organisationMembersSearchNoResults
                            }
                        />
                    </SelectContainer>
                );

            case Stage.ContactCreate:
                return (
                    <>
                        <ModalHeading>
                            Enter your agency consultant's details {this.state.selectedOrganisationId === null ? ('at ' + this.state.newOrganisationName) : "" }
                            {/* {!this.props.disableContactRequirement && (
                                <>
                                    <br />
                                    <small style={{ marginTop: 10, display: 'block', lineHeight: '1.4em' }}>
                                        Adding a contact will allow them to
                                        receive an assignment confirmation when
                                        used as a third party
                                    </small>
                                </>
                            )} */}
                        </ModalHeading>
                        {/* {this.state.selectedOrganisationId == null && (
                            <>
                                <label
                                    htmlFor="organisationName"
                                    className="m-t-sm"
                                >
                                    Agency's Organization Name
                                </label>
                                <input
                                    id="organisationName"
                                    type="text"
                                    className="form-control"
                                    value={this.state.newOrganisationName}
                                    onChange={(ev) =>
                                        this.setState({
                                            newOrganisationName: ev.target.value
                                        })
                                    }
                                    autoComplete="none"
                                />
                            </>
                        )} */}
                        {!this.props.disableContactRequirement && (
                            <>
                                <label 
                                    htmlFor="contactName"
                                    className="m-t-sm"
                                >
                                    Consultant's Name
                                </label>
                                <input
                                    id="contactName"
                                    autoFocus
                                    type="text"
                                    className="form-control"
                                    value={this.state.newContactName}
                                    onChange={(ev) =>
                                        this.setState({
                                            newContactName: ev.target.value
                                        })
                                    }
                                />
                                <label
                                    htmlFor="contactEmail"
                                    className="m-t-sm"
                                >
                                    Consultant's Email
                                </label>
                                <input
                                    id="contactEmail"
                                    type="text"
                                    className="form-control"
                                    value={this.state.newContactEmail}
                                    onChange={(ev) =>
                                        this.setState({
                                            newContactEmail: ev.target.value
                                        })
                                    }
                                />
                                { (this.state.autoSuggestedContactDetails) &&
                                    <span style={{
                                        color: theme.colours.red2,
                                        fontWeight: 700,
                                        textAlign: "center",
                                        display: "block",
                                        marginTop: 20
                                    }}>Make sure the above contact details are correct before adding</span>
                                }
                            </>
                        )}
                    </>
                );

            default:
                return null;
        }
    }

    renderOrganisationList() {
        const { organisationSearchResults: organisations } = this.state;
        if (organisations.length === 0) return null;

        return (
            <div>
                <Table condensed striped hover style={{ marginBottom: 0 }}>
                    <tbody>
                        {organisations.map(({ id, name }) => {
                            const imgUrl = CompanyApi.getOrganisationProfileImageUrl(
                                id
                            );
                            return (
                                <tr
                                    key={id}
                                    onClick={() =>
                                        this.setState({
                                            selectedClearBitResultDomain: null,
                                            selectedOrganisationId: id,
                                            newContactName: "",
                                            newContactEmail: "",
                                            autoSuggestedContactDetails: false
                                        }, () => {
                                            this.handleNext();
                                        })
                                    }
                                    className={
                                        this.state.selectedOrganisationId === id
                                            ? 'selected'
                                            : ''
                                    }
                                >
                                    <td>
                                        <div className="profile">
                                            <ProfileImage
                                                selectable={false}
                                                url={imgUrl}
                                                size={40}
                                                type="Organisation"
                                            />
                                            <h3>
                                                {name}
                                                {/* { name === "4myschools" &&
                                                    <span>Lot 1 & Lot 4 CCS STaTS RM6265</span>
                                                } */}
                                            </h3>
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </div>
        );
    }

    renderContactList() {
        const contacts = this.state.organisationMembersSearchResults;
        if (contacts.length === 0) return null;

        return (
            <div
                style={{
                    textAlign: 'center'
                }}
            >
                <Table condensed striped hover>
                    <tbody>
                        {contacts.map(({ firstName, lastName, id }) => {
                            return (
                                <tr
                                    key={id}
                                    style={{ cursor: 'pointer' }}
                                    className={
                                        this.state
                                            .selectedPrimaryContactUserId === id
                                            ? 'selected'
                                            : ''
                                    }
                                    onClick={() =>
                                        this.setState({
                                            selectedPrimaryContactUserId: id
                                        })
                                    }
                                >
                                    <td>
                                        <div className="profile">
                                            <ProfileImage
                                                selectable={false}
                                                url={ProfileApi.getProfileImageUrl(
                                                    id
                                                )}
                                                type="Profile"
                                                size={40}
                                            />
                                            <h3>{firstName} {lastName}</h3>
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </div>
        );
    }

    handleRemove = async (thirdParty: UserThirdParty) => {
        const key = !!thirdParty.userContactId
            ? 'userContactId'
            : 'workerTempOrganisationId';

        const value = thirdParty[key];

        const removeAgencyFromState = () => {
            this.setState((prevState) => ({
                userAgencies: prevState.userAgencies.filter(
                    (agency) => agency[key] !== value
                )
            }));
        };

        const handleError = (errorResponse) => {
            const errorMessage =
                getFirstError(errorResponse) || 'Unable to remove third party';
            toast.error(errorMessage);
        };

        const method =
            key === 'userContactId'
                ? ProfileApi.removeThirdParty
                : ProfileApi.removeWorkerTemporaryOrganisation;

        return method(value)
            .then(removeAgencyFromState)
            .catch(handleError);
    };

    handleAdd = () => {
        InternalTracker.trackEvent("", { category: 'Agency', action: 'Agency Add Button Clicked' });

        this.setState({
            isAddingNewAgency: true,
            stage: Stage.OrganisationSearch,
            newContactName: '',
            newContactEmail: '',
            isSearchingOrganisations: false,
            isSearchingUsers: false,
            isSearchingClearBit: false,
            clearBitSearchNoResults: false,
            clearBitSearchResults: []
        });
    };

    /**
     * Handle next and get text could be a single function e.g. getConfig
     */
    handleNext = async () => {
        const { stage } = this.state;
        const { disableContactRequirement } = this.props;

        if (stage === Stage.OrganisationSearch && disableContactRequirement) {
            InternalTracker.trackEvent("", { category: 'Agency', action: 'Created Worker Third Party' });
            return this.createWorkerThirdParty();
        }

        if (stage == Stage.ContactCreate && disableContactRequirement) {
            InternalTracker.trackEvent("", { category: 'Agency', action: 'Created Worker Third Party' });
            return this.createWorkerThirdParty();
        }

        if (stage == Stage.ContactSearch || stage == Stage.ContactCreate) {
            InternalTracker.trackEvent("", { category: 'Agency', action: 'Created Third Party' });
            this.createThirdParty();
        }

        // if (stage == Stage.OrganisationSearch) {
        if (this.state.selectedOrganisationId) {
            this.handleOrganisationUserSearchChange('');
            return this.setStage(Stage.ContactSearch);
        }

        // if (stage == Stage.ClearBitSearch) {
        if (this.state.selectedClearBitResultDomain) {
            return this.setStage(Stage.ContactCreate);
        }
    };

    handleOrganisationSearchChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        const search = Utilities.capitalizeEachFirstLetter(e.target.value);
        const shouldSearchOrganisations =
            this.state.stage === Stage.OrganisationSearch;

        this.setState({
            newOrganisationName: search,
            isSearchingClearBit: true,
            isSearchingOrganisations: shouldSearchOrganisations
        });

        if (shouldSearchOrganisations) {
            this.handleOrganisationSearch(search);
        }

        /**
         * Pre-emptively searches ClearBit even if not yet at that stage.
         *
         * This means that if the organisation search brings back no results
         * when the user navigates to the ClearBit page they may have instant results
         */
        this.handleClearBitSearch(search);
    };

    handleOrganisationSearch = debounce(async (search: string) => {
        if (!search || search.length === 0) {
            return this.setState({
                isSearchingOrganisations: false,
                organisationSearchResults: [],
                organisationSearchNoResults: false
            });
        }

        const response = await OrganisationApi.getAll(search, 'agency');
        const results = (response && response.data) || [];

        this.setState({
            isSearchingOrganisations: false,
            organisationSearchResults: results,
            organisationSearchNoResults: !results.length
        });
    }, SEARCH_DEBOUNCE_TIME);

    handleOrganisationUserSearchChange = debounce(async (search: string) => {
        // if (!search || search.length === 0) {
        //     return this.setState({
        //         isSearchingUsers: false,
        //         organisationMembersSearchResults: [],
        //         organisationMembersSearchNoResults: false
        //     });
        // }

        const organisationId = this.state.selectedOrganisationId!;

        const response = await OrganisationApi.getUsers(organisationId, search);
        const results = (response && response.data) || [];

        this.setState({
            isSearchingUsers: false,
            organisationMembersSearchResults: results,
            organisationMembersSearchNoResults: !results.length
        });
    }, SEARCH_DEBOUNCE_TIME);

    handleClearBitSearch = debounce((search: string) => {
        this.setState({ selectedClearBitResultDomain: null });

        if (search && search.length) {

            Promise.all([
                ClearBit.searchCompanies(search),
                CompanyApi.getAgencySuggestion(search)
            ]).then((data) => {

                let clearBitCompanies = data[0].filter(d => d.name !== "4myschools Teacher Recruitment");
                let internalSuggestionCompanies = data[1].filter(d => d.org !== "4myschools");
                let internalSuggestionCompaniesNotes = {};
                for (let i = 0; i < internalSuggestionCompanies.length; i++) {
                    if (internalSuggestionCompanies[i].notes) {
                        internalSuggestionCompaniesNotes[internalSuggestionCompanies[i].domain] = internalSuggestionCompanies[i].notes
                    }
                }

                const clearBitDomainMatches = clearBitCompanies.map(cbc => cbc.domain);
                // Removing internal results, if result containing logo from clearbit is available
                internalSuggestionCompanies = internalSuggestionCompanies.filter(isc => clearBitDomainMatches.indexOf(isc.domain) === -1);

                let allResults = clearBitCompanies.map(cbc => {
                    cbc.notes = internalSuggestionCompaniesNotes[cbc.domain];
                    return cbc;
                }).concat(internalSuggestionCompanies.map(isc => {
                    return {
                        domain: isc.domain,
                        name: isc.org,
                        logo: isc.domain ? OrganisationApi.getExtOrgPicture(isc.domain) : "https://test-images.updatedge.com/profile/noprofile.png",
                        notes: isc.notes
                    }
                }));

                console.log(allResults, "<<<")

                this.setState({
                    isSearchingClearBit: false,
                    clearBitSearchResults: allResults.sort((a, b) => {
                        const aVal = a.name.toUpperCase();
                        const bVal = b.name.toUpperCase();
                        return aVal < bVal ? -1 : aVal > bVal ? 1 : 0;
                    }).sort((a, b) => {
                        return a.notes && !b.notes ? -1 : !a.notes && b.notes ? 1 : 0;
                    }),
                    clearBitSearchNoResults: !allResults.length
                });
            });
        } else {
            this.setState({
                isSearchingClearBit: false,
                clearBitSearchResults: [],
                clearBitSearchNoResults: false
            });
        }
    }, SEARCH_DEBOUNCE_TIME);

    isNextEnabled = () => {
        const stage = this.state.stage;

        if (
            stage == Stage.OrganisationSearch &&
            (this.state.selectedOrganisationId != null || this.state.selectedClearBitResultDomain != null)
        )
            return true;

        if (
            stage == Stage.ContactSearch &&
            this.state.selectedPrimaryContactUserId != null
        ) {
            return true;
        }

        if (stage === Stage.ContactCreate && !!this.state.newOrganisationName) {
            return true;
        }

        if (
            stage === Stage.ContactCreate &&
            (this.state.selectedOrganisationId != null ||
                !!this.state.newOrganisationName) &&
            !!this.state.newContactName &&
            !!this.state.newContactEmail
        ) {
            return true;
        }

        return false;
    };

    isFinalStage = (stage = this.state.stage) => {
        const { disableContactRequirement } = this.props;
        if (disableContactRequirement && stage == Stage.OrganisationSearch)
            return true;
        return stage == Stage.ContactSearch || stage == Stage.ContactCreate;
    };

    /**
     * We have to ensure we don't effect the state of the listed agencies here
     * This is a sign that these components should probably be split-up with separate state
     */
    resetAddAgencyState = async () => {
        this.setState(({ userAgencies }) => ({
            ...this.initialState,
            userAgencies
        }));
    };

    createThirdParty = async () => {
        const {
            selectedPrimaryContactUserId,
            selectedOrganisationId,
            newOrganisationName,
            newContactName,
            newContactEmail
        } = this.state;

        if (
            selectedPrimaryContactUserId == null &&
            !newContactEmail.includes('@')
        ) {
            toast.error('Please enter a valid email address.');
            return;
        }

        if (
            this.state.userAgencies.some(
                (ua) => ua.email === newContactEmail.trim()
            )
        ) {
            toast.error(`You've already added this contact to an agency.`);
            return;
        }

        this.setState({ loadingBlocked: true });

        try {
            const payload =
                selectedPrimaryContactUserId != null
                    ? selectedPrimaryContactUserId
                    : {
                          name: newContactName.trim(),
                          email: newContactEmail.trim(),
                          ...(selectedOrganisationId == null
                              ? {
                                    organisationName: newOrganisationName.trim()
                                }
                              : {
                                    organisationId: selectedOrganisationId
                                })
                      };

            const { data: agency } = await ProfileApi.addThirdParty(
                payload
            ).catch((error) => {
                // alert(JSON.stringify(error))
                throw new Error(
                    getFirstError(error) || 'Unable to set third party'
                );
            });

            await this.uploadThirdPartyImage(agency.imageId!);
            this.setState({ loadingBlocked: false });

            this.onAgencyCreate(agency);
            if (this.props.refreshOverlayMenu) {
                this.props.refreshOverlayMenu();
            }
        } catch (error) {
            toast.error(error ? (error as any).message : "Error");
            this.setState({ loadingBlocked: false });
        }
    };

    createWorkerThirdParty = async () => {
        this.setState({
            loadingBlocked: true
        })

        const {
            selectedOrganisationId: organisationId,
            newOrganisationName: tempOrganisationName
        } = this.state;

        if (organisationId == null && tempOrganisationName == null) return;
        let payload: AddWorkerTemporaryOrganisationRequest = {
            tempOrganisationName
        };
        if (organisationId != null) payload = { organisationId };

        const {
            data: agency
        } = await ProfileApi.addWorkerTemporaryOrganisation(payload);

        await this.uploadThirdPartyImage(
            agency.imageId!,
            CompanyApi.updateWorkerTemporaryOrganisationImage
        );

        this.setState({
            loadingBlocked: true
        })

        this.onAgencyCreate(agency);
        if (this.props.refreshOverlayMenu) {
            this.props.refreshOverlayMenu();
        }
    };

    uploadThirdPartyImage = async (
        imageId: string,
        method = CompanyApi.updateTemporaryOrganisationImage
    ) => {
        if (this.state.selectedClearBitResultDomain == null) return;
        const { selectedClearBitResultDomain: domain } = this.state;
        const image = await ClearBit.downloadLogoFromDomain(domain);
        const file = new File([image], '');
        await method(imageId, file);
    };

    onAgencyCreate = (newAgency) => {
        this.setState(({ userAgencies }) => ({
            userAgencies: [...userAgencies, newAgency]
        }));

        if (this.props.onConfirm) {
            this.props.onConfirm(this.state.userAgencies);
        }

        this.resetAddAgencyState();
    };

    private setStage = (stage: Stage) => {
        this.setState({ stage });
    };
}

export default connect(null, { ...EventsState.actionCreators })((props) => (
    <Subscribe to={[DashboardContainer, UserTypeContainer]}>
        {(dashboard: DashboardContainer, userType: UserTypeContainer) => (
            <ProfileAgencies
                // @ts-ignore
                {...props}
                dashboardContainer={dashboard}
                userType={userType}
            />
        )}
    </Subscribe>
)) as any;

function getAgencyRowWarningText(agency: UserThirdParty) {
    if (!agency.organisationId)
        return `
Confirmed orders to this organization automatically send an email to your contact.
Delivery status of emails will be shown.
Your organization contacts do not need to use Updatedge to receive assignment order requests.
This contact has not yet created a profile on Updatedge.
        `;

    if (!agency.organisationName)
        return 'This contact is no longer a member their organization.';

    return null;
}

const AddButton = ({ onClick, empty }) => (
    <Button
        onClick={onClick}
        bsStyle="success"
        className={empty ? ' pulse no-size' : ''}
        style={{
            marginBottom: 15
        }}
        id="add-new-agency-btn"
    >
        <i className="fa fa-plus m-r-xs" />
        Add agency
    </Button>
);

const SearchHelperText = ({ noResultsFound, isSearching }) =>
    (isSearching && <Spinner noText />) ||
    (noResultsFound && (
        <div
            style={{
                textAlign: 'center'
            }}
        >
            <label
                style={{
                    fontStyle: 'italic',
                    fontWeight: 400
                }}
            >
                No results found...
            </label>
        </div>
    ));

const PrimaryContactTooltip = (
    <SimpleTooltip
        id="primary-contact-info"
        text="A primary contact will receive the assignment confirmation request when you confirm an offer with their agency"
    >
        <i
            className="fa fa-info-circle"
            style={{
                color: '#77F',
                position: 'relative',
                left: '5px',
                top: '-5px'
            }}
        />
    </SimpleTooltip>
);

const ModalBodyWrapper = styled.div`

    .profile {
        display: flex;
        align-items: center;
        padding: 10px 6px;

        h3 {
            margin: 0;
            font-size: 1.2em;
            padding-left: 12px;

            span {
                background: ${theme.colours.blue2};
                color: white;
                font-size: 0.8em;
                line-height: 0.8em;
                padding: 4px 6px 6px 6px;
                border-radius: 9px;
                top: -1px;
                position: relative;
                margin-left: 4px;
                display: inline-block;
            }
        }
    }

`

const AgencyRow = styled.tr`
    td {
        vertical-align: middle !important;

        span.not-set {
            font-style: italic;
            opacity: 0.7;
        }

        &:last-child {
            text-align: center;

            i {
                margin: 0;
                padding: 0 10px;
            }

            .fa-trash-o {
                font-size: 22px;
            }
        }
    }
`;

const ModalHeading = styled.h4`
    margin-bottom: 25px;
    font-weight: 600;
    text-align: center;
`;

const SelectContainer = styled.div`
    position: relative;
    text-align: center;

    tbody > tr {
        cursor: pointer;

        td {
            width: 50%;
            padding: 0 5px;
            font-size: 15px;
            vertical-align: middle !important;

            &:first-child {
                text-align: right;
            }

            &:last-child {
                text-align: left;
            }
        }
    }
`;
