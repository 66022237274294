import React from 'react';
import styled from 'styled-components';

import Analytics from '../../services/analytics.service';

interface Props {
    id: string;
    maxHeight: number;
    title: string;
    body: string;
}

interface State {
    show: boolean;
}

export default class DismissableInfo extends React.Component<Props, State> {
    state = {
        show: false
    };

    componentDidMount() {
        this.setState({
            show: localStorage.getItem(this.props.id) !== '0'
        });
    }

    handleShow = () => {
        this.setState({
            show: true
        });

        localStorage.setItem(this.props.id, '1');

        Analytics.trackEvent('dismissable-info-show', { id: this.props.id });
    };

    handleHide = () => {
        this.setState({
            show: false
        });

        localStorage.setItem(this.props.id, '0');

        Analytics.trackEvent('dismissable-info-hide', { id: this.props.id });
    };

    render() {
        return (
            <Disclaimer className={this.state.show ? 'show' : ''}>
                <div className="collapsed">
                    <label onClick={this.handleShow}>
                        {this.props.title}
                        <i className="fa fa-caret-up m-l-xs" />
                    </label>
                </div>
                <div
                    className="expanded"
                    style={{ maxHeight: this.props.maxHeight + 20 + 'px' }}
                >
                    <div className="title layout horizontal justified center">
                        <label>{this.props.title}</label>
                        <span className="toggle" onClick={this.handleHide}>
                            dismiss
                            <i className="fa fa-caret-down m-l-xs" />
                        </span>
                    </div>
                    <div
                        className="message"
                        style={{ maxHeight: this.props.maxHeight + 'px' }}
                    >
                        {this.props.body}
                    </div>
                </div>
            </Disclaimer>
        );
    }
}

const Disclaimer = styled.div`
    label {
        margin-bottom: 0;
    }

    .collapsed {
        padding-top: 5px;
        color: #00e;
        text-align: right;

        > label {
            cursor: pointer;
            opacity: 0.7;

            &:hover {
                opacity: 1;
            }
        }
    }

    .expanded {
        overflow: hidden;
        border-radius: 3px;
        box-shadow: 0 0 5px 0 black;
        transition: max-height 0.75s ease;

        > .title {
            padding: 4px 5px 0 5px;

            span {
                font-size: 11px;
            }
        }

        > .message {
            max-height: 80px;
            padding: 0 5px 5px 5px;
            overflow-y: auto;
            font-size: 11px;
        }

        .toggle {
            width: fit-content;
            color: #00e;
            font-size: 12px;
            cursor: pointer;
            opacity: 0.8;

            &:hover {
                opacity: 1;
            }
        }
    }

    &.show {
        .collapsed {
            opacity: 0;
        }

        .expanded {
            max-height: 100px;
        }
    }

    :not(.show) {
        .collapsed {
            opacity: 1;
            transition: opacity 0.5s ease;
            transition-delay: 0.75s;
        }

        .expanded {
            max-height: 0 !important;
        }
    }
`;
