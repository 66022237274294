import AppConfig from '../../components/config/Config';
import { JwtValidationStatus } from './ResponseTypes';

const apiUrls = {
    validate(jwt: string) {
        return `${AppConfig.Settings.api.baseUri}/api/token/validate?jwt=${jwt}`;
    }
};

const apiCalls = {
    validate: (jwt: string) => {
        return fetch(apiUrls.validate(jwt)).then((response) => {
            return response.json() as Promise<JwtValidationStatus>;
        });
    }
};

export default apiCalls;
