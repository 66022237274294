import React from 'react';
import { Modal } from 'react-bootstrap';
import {
    FacebookIcon,
    FacebookShareButton,
    LineIcon,
    LineShareButton,
    RedditIcon,
    RedditShareButton,
    TelegramIcon,
    TelegramShareButton,
    TwitterIcon,
    TwitterShareButton,
    ViberIcon,
    ViberShareButton,
    WhatsappIcon,
    WhatsappShareButton
} from 'react-share';

import '../../css/ShareModal.css';

interface Props {
    onHide: () => any;
    show: boolean;
    quote: string;
    title: string;
    link: string;
}

interface State {
    quote: string;
}

export default class ShareModal extends React.Component<Props, State> {

    constructor(props) {
        super(props);
        this.state = {
            quote: props.quote
        } as State;

    }

    logShare = (platform: string) => {
        
    }

    render() {
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                className="share-modal"
            >
                <Modal.Header>
                    <Modal.Title>{this.props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="quote" style={{ marginBottom: 12 }}>
                        <h5>What do you want to share?</h5>
                        <input
                            id="share-text"
                            type="text"
                            className="form-control"
                            value={this.state.quote}
                            onChange={(ev) => {
                                this.setState({ quote: ev.target.value })
                            }}
                        />
                    </div>
                    <div>
                        <h5>Select Platform</h5>
                        <div className="platforms">
                            <button
                                className="email"
                                onClick={() => {
                                    this.logShare('email');
                                    window.open('mailto:?body=' + this.state.quote + '&subject=Check out Updatedge')
                                }}
                            >
                                <i className="fas fa-envelope" />
                            </button>
                            <FacebookShareButton onClick={() => { this.logShare("facebook") }} url={this.props.link} title={this.state.quote} className="platform facebook"> <FacebookIcon /> </FacebookShareButton>
                            <LineShareButton onClick={() => { this.logShare("line") }} url={this.props.link} title={this.state.quote} className="platform facebook"> <LineIcon /> </LineShareButton>
                            <RedditShareButton onClick={() => { this.logShare("reddit") }} url={this.props.link} title={this.state.quote} className="platform facebook"> <RedditIcon /> </RedditShareButton>
                            <TelegramShareButton onClick={() => { this.logShare("telegram") }} url={this.props.link} title={this.state.quote} className="platform facebook"> <TelegramIcon /> </TelegramShareButton>
                            <TwitterShareButton onClick={() => { this.logShare("twitter") }} url={this.props.link} title={this.state.quote} className="platform facebook"> <TwitterIcon /> </TwitterShareButton>
                            <ViberShareButton onClick={() => { this.logShare("viber") }} url={this.props.link} title={this.state.quote} className="platform facebook"> <ViberIcon /> </ViberShareButton>
                            <WhatsappShareButton onClick={() => { this.logShare("whatsapp") }} url={this.props.link} title={this.state.quote} className="platform facebook"> <WhatsappIcon /> </WhatsappShareButton>
                        </div>
                    </div>
                </Modal.Body>
                {this.props.children}
            </Modal>
        );
    }
}
