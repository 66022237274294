import { Reducer, Action } from 'redux';
import update from 'immutability-helper';

import { exhaustiveCheck } from '..';
import { KnownAction } from './Actions';
import { TimePresetManagementState, defaultState } from './States';

/**
 * REDUX Reducer for Time Preset management
 * For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
 */

export const reducer: Reducer<TimePresetManagementState | undefined> = (
    state: TimePresetManagementState | undefined,
    incomingAction: Action
) => {
    if (state == undefined) return defaultState;
    const action = incomingAction as KnownAction;

    switch (action.type) {
        case 'TIME_PRESETS_STORE.TIME_PRESETS.REQUEST.INIT':
            break;
        case 'TIME_PRESETS_STORE.TIME_PRESETS.REQUEST.SUCCESS':
            return update(state, {
                timePresetMgtState: {
                    presets: { $set: action.presets },
                    loaded: { $set: true },
                    creating: { $set: false }
                }
            });
        case 'TIME_PRESETS_STORE.TIME_PRESETS.CREATE.INIT':
            return update(state, {
                timePresetMgtState: {
                    creating: { $set: true }
                }
            });
        case 'TIME_PRESETS_STORE.TIME_PRESETS.CREATE.SUCCESS':
            return update(state, {
                timePresetMgtState: {
                    creating: { $set: false }
                }
            });
        case 'TIME_PRESETS_STORE.TIME_PRESETS.REMOVE.INIT':
            return state;

        case 'TIME_PRESETS_STORE.TIME_PRESETS.REMOVE.SUCCESS':
            return state;

        case 'TIME_PRESETS_STORE.TIME_PRESETS.EDIT.INIT':
            return update(state, {
                timePresetMgtState: {
                    editing: { $set: action.id }
                }
            });

        case 'TIME_PRESETS_STORE.TIME_PRESETS.EDIT.SUCCESS':
            return update(state, {
                timePresetMgtState: {
                    editing: { $set: undefined }
                }
            });

        case 'TIME_PRESETS_STORE.TIME_PRESETS.EDIT.END':
            return update(state, {
                timePresetMgtState: {
                    editing: { $set: undefined }
                }
            });

        default:
            exhaustiveCheck(action);
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || defaultState;
};
