/* eslint-disable */
import afetch from '../AuthenticatedFetch';
import AppConfig from '../../components/config/Config';
import { TimePresetNew, TimePresetEdit } from '../../store/timepresets/Models';
import { TimePresetDto } from './RequestTypes';
import { TimePresetEditDto } from './ResponseTypes';

// API urls
const apiUrls = {
    getTimesheetPresets(token: string) {
        return `${AppConfig.Settings.api.externalUri}/timepreset/timesheet/${token}`;
    },
    getPresets() {
        return `${AppConfig.Settings.api.baseUri}/publicapi/timepresets`;
    },
    createPreset() {
        return `${AppConfig.Settings.api.baseUri}/publicapi/timepreset`;
    },
    removePreset(id: string) {
        return `${AppConfig.Settings.api.baseUri}/publicapi/timepreset/${id}`;
    },
    editPreset(id: string) {
        return `${AppConfig.Settings.api.baseUri}/publicapi/timepreset/${id}`;
    }
};

/** Setings API */
const self = {
    /** Retrieves all time presets for a user */
    getPresets: () => {
        return afetch
            .request<null, TimePresetDto[]>(
                apiUrls.getPresets(),
                'GET',
                null,
                'Could not retrieve your time presets.'
            )
            .then((presets) => {
                return presets.sort(function (a, b) {   
                    return (a.startHour * 60 + a.startMinute) - (b.startHour * 60 + b.startMinute) || (a.endHour * 60 + a.endMinute) - (b.endHour * 60 + b.endMinute);
                });
            });
    },

    /** Creates a new time preset */
    newPresetCreate: (preset: TimePresetNew) => {
        return afetch.request<TimePresetNew, null>(
            apiUrls.createPreset(),
            'POST',
            preset,
            'Could not create new preset'
        );
    },

    /** Removes a preset */
    presetDelete: (id: string) => {
        return afetch.request<null, boolean>(
            apiUrls.removePreset(id),
            'DELETE',
            null,
            'Could not delete preset'
        );
    },

    /** Edit a preset */
    presetEdit: (id: string, preset: TimePresetEdit) => {
        return afetch.request<TimePresetEdit, TimePresetEditDto>(
            apiUrls.editPreset(id),
            'PUT',
            preset,
            'Could not edit preset'
        );
    },

    getTimesheetPresets: (token: string) => {
        return afetch
            .request<null, TimePresetDto[]>(
                apiUrls.getTimesheetPresets(token),
                'GET',
                null,
                'Could not retrieve time presets.'
            )
            .then((presets) => {
                // @ts-ignore
                if (presets && presets.length === 0 || presets === true) {
                    return [
                        {
                            "id": "A795E0A9-FC33-49A0-A6F2-F4A1DC1CC460",
                            "name": "All Day",
                            "startHour": 8,
                            "startMinute": 0,
                            "endHour": 18,
                            "endMinute": 0,
                            "startTime": "18:00",
                            "endTime": "08:00"
                        }
                    ];
                }
                return presets.sort(function (a, b) {
                    return (a.startHour * 60 + a.startMinute) - (b.startHour * 60 + b.startMinute) || (a.endHour * 60 + a.endMinute) - (b.endHour * 60 + b.endMinute);
                });
            });
    },
};

export default self;
