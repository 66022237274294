import afetch from '../AuthenticatedFetch';
import AppConfig from '../../components/config/Config';
import { FeedbackDto } from './RequestTypes';

// API urls
const apiUrls = {
    sendFeedback() {
        return `${AppConfig.Settings.api.baseUri}/api/messaging/feedback`;
    }
};

/** Profile API */
const self = {
    /** Sends feedback from a user */
    sendFeedback: (comments: string) => {
        return afetch.request<FeedbackDto>(
            apiUrls.sendFeedback(),
            'POST',
            { comments: comments },
            'Sorry but we could not send your feedback.'
        );
    }
};

export default self;
