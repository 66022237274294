/* eslint-disable */
// TODONOW
import OfferApi from '../api/offers/Offers'
import InternalTracker from '../InternalTracker';
import Analytics from '../services/analytics.service';
import moment from 'moment';
import { DateFormats } from '../constants';

import {
    OfferInfoDto,
    OfferResponseTypes,
    OfferConfirmationResponseTypes,
    OfferRecipientDto,
    CreateOfferDto,
    FavouriteOfferDTO,
    OfferEventDeletedDto
} from '../api/offers/ResponseTypes';

export enum OfferStatus {
    Confirmed,
    Withdrawn,
    Historic,
    Waiting,
    Ready,
    Declined,
    Favourite,
    Unknown,
    Deleted,
    ConfirmedAlterations,
    ConfirmedDeletions
}

export async function favoriteOffer(offer: OfferInfoDto) {
    const payload = {
        title: offer.title,
        description: offer.description,
        locationPlaceId: offer.locationPlaceId,
        recipients: offer.recipients.map(
            ({ recipientUserId }) => recipientUserId
        ),
        startTime: moment(offer.events[0].start).format(
            DateFormats.Time24Hour
        ),
        endTime: moment(offer.events[0].end).format(DateFormats.Time24Hour)
    };
    const favourite = await OfferApi.saveToFavourites(payload);
    return favourite;
}

export async function withdrawOffer(offer: OfferInfoDto) {
    (window as any).lastWithdrawnOfferId = offer.id;
    let res = await OfferApi.withdrawOffer(offer.id);
    InternalTracker.trackEvent("", {
        category: 'Offers',
        action: 'Offer Withdrawn'
    });
    Analytics.trackEvent('offers-page-offer-withdrawn');
    return res;
}

export function getOfferWorkersByResponseType (offer: OfferInfoDto, responseType: OfferResponseTypes) {
    return offer.recipients.filter(({ response }) => response === responseType);
}

export function isOfferResolved (offer: OfferInfoDto) {
    return offer.isHistoric || offer.withdrawn;
}

export function getOfferWorkersByConfirmationType (offer: OfferInfoDto, confirmationType: OfferConfirmationResponseTypes) {
    return offer.recipients.filter(
        ({ confirmation }) => confirmation === confirmationType
    );
}

export function getOfferStatus (offer: OfferInfoDto, withDeletions?): OfferStatus {
    const {
        withdrawn,
        isHistoric,
        recipients,
        complete,
        favourite
    } = offer;
    //debugger;
    if (favourite) {
        return OfferStatus.Favourite;
    }

    if (complete && !isHistoric && !withdrawn) {
        if (withDeletions) {
            return OfferStatus.ConfirmedDeletions;
        } else {
            return OfferStatus.Confirmed;
        }
    }

    if (withdrawn) {
        return OfferStatus.Withdrawn;
    }

    if (isHistoric) {
        return OfferStatus.Historic;
    }

    if (
        recipients.every(
            ({ response }) => response === OfferResponseTypes.Pending
        )
    ) {
        return OfferStatus.Waiting;
    }

    if (
        recipients.some(
            ({ response }) => response === OfferResponseTypes.Yes
        )
    ) {
        return OfferStatus.Ready;
    }

    if (
        recipients.every(
            ({ response, confirmation }) => response === OfferResponseTypes.No || confirmation === OfferConfirmationResponseTypes.Rejected
        )
    ) {
        return OfferStatus.Declined;
    }

    if (
        recipients.some(
            ({ response, confirmation }) => response === OfferResponseTypes.Pending && confirmation !== OfferConfirmationResponseTypes.Rejected
        )
    ) {
        return OfferStatus.Ready;
    }

    return OfferStatus.Unknown;
};