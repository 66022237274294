/* eslint-disable */
// TODONOW this whole folder
import React from 'react';
import styled from 'styled-components';
import { Table, Button } from 'react-bootstrap';
import Toggle from 'react-toggle';

import CompanyApi from '../../api/company/Company';
import ProfileApi from '../../api/profile/Profile';
import OfferApi from '../../api/offers/Offers';
import { ProfileImage } from '../ui-components/ProfileImage';
import { OfferRecipientDto } from '../../api/offers/ResponseTypes';
import { OfferRecipientAgency } from '../../api/offers/RequestTypes';
import { UserThirdParty } from '../../api/profile/ResponseTypes';
import SimpleTooltip from '../ui-components/SimpleTooltip';
import theme from '../../css/theme';
import 'react-toggle/style.css';
import DismissableInfo from '../ui-components/DismissableInfo';
import UserAgencySelector from '../profile/UserAgencySelector';
import Device from '../../css/device';
import Analytics from '../../services/analytics.service';
import Files from 'src/pages/Files';

interface Item
    extends OfferRecipientDto,
        Omit<OfferRecipientAgency, 'recipientId'> {}

export interface Props {
    recipients: OfferRecipientDto[];
    offerId: string;
    showUnsuccessful: boolean;
    autoComplete?: boolean;
    onCancel: () => void;
    onConfirm: (
        recipientAgencies: OfferRecipientAgency[],
        rejectUnsuccessful: boolean,
        filesCount: number
    ) => void;
    alreadySelectedFiles: number[];
}

interface State {
    items: Item[];
    selectedItemId: Item['id'] | null;
    preferredAgencies: UserThirdParty[];
    rejectUnsuccessful: boolean;
    showAgencySelector: boolean;
    showEngagementSelector: boolean;
    selectingFiles: boolean;
    confirmedFiles: number[];
    confirmedFolders: number[];
    completeWithoutSelection: boolean;
}

interface AgencyMutuality extends UserThirdParty {
    isHirers: boolean;
    isWorkers: boolean;
}

export default class ConfirmOffer extends React.Component<Props, State> {
    state = {
        items: [],
        selectedItemId: null,
        preferredAgencies: [],
        rejectUnsuccessful: false,
        showAgencySelector: false,
        showEngagementSelector: false,
        selectingFiles: false,
        confirmedFiles: [],
        confirmedFolders: [],
        completeWithoutSelection: false
    } as State;

    async componentDidMount() {

        // Default disclaimer to collapsed if it wasn't explicitly left open
        if (localStorage.getItem('ConfirmOffer.showDisclaimer') !== '1') {
            localStorage.setItem('ConfirmOffer.showDisclaimer', '0');
        }

        Analytics.trackEvent('confirm-offer-opened');

        const items: Item[] = this.props.recipients.map((r) => {
            return {
                ...r,
                engageDirectly: false,
                engageAgency: true,
                agencyUserContactId: null
            };
        });

        const preferredAgencies = (await ProfileApi.getThirdParties()).data;
        const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')!) : null;

        this.setState(
            {
                items,
                selectedItemId: items[0] ? items[0].id : null,
                preferredAgencies,
                showEngagementSelector: !(user && user.organisationIsAgency)
            },
            () => {
                let combined = this.getCombinedAgencies();
                for (let i = 0; i < combined.length; i++) {
                    const element = combined[i];
                    if (element.isWorkers && element.isHirers) {
                        this.handleAgencySelectForSelectedItem(element);
                        break;
                    }
                }
                if (this.props.autoComplete) {
                    // this.onCompleteOffer();
                    this.setState({
                        completeWithoutSelection: true
                    })
                } else if (items.length === 1 && user && !user.organisationIsAgency && preferredAgencies.length === 1) {
                    this.handleAgencySelectForSelectedItem(preferredAgencies[0]);
                }
            }
        );
    }

    autoAgencyAdd = () => {
        setTimeout(() => {
            const addBtn = document.getElementById("add-new-agency-btn");
            if (addBtn) {
                addBtn.click();
                setTimeout(() => {
                    const orgSearchInput = document.getElementById("organisationSearch");
                    if (orgSearchInput) {
                        orgSearchInput.focus();
                    }
                }, 250)
            }
        }, 250);
    }

    render() {
        if (!this.getSelectedItem()) return null;

        // @ts-ignore
        const isSmall = Device.isSmallerThan(480);

        return (
            <Wrapper className="layout vertical">
                {this.state.items.length > 1 && (
                    <>
                        <div style={{ textAlign: 'center', marginTop: '5px' }}>
                            For each worker, select whether you wish to engage
                            directly or via an agency
                        </div>
                        <div
                            style={{
                                maxHeight: '250px',
                                overflowY: 'auto',
                                marginTop: '7px'
                            }}
                        >
                            <Table condensed>
                                {!isSmall && (
                                    <thead>
                                        <tr>
                                            <th />
                                            <th style={{ textAlign: 'center' }}>
                                                Engagement method
                                            </th>
                                            <th />
                                        </tr>
                                    </thead>
                                )}
                                <tbody>
                                    {this.state.items.map((r) => {
                                        const imageUrl = ProfileApi.getProfileImageUrl(
                                            r.recipientUserId
                                        );

                                        const selectedAgency =
                                            r.engageAgency &&
                                            r.agencyUserContactId
                                                ? this.getCombinedAgencies().find(
                                                      ({ userContactId }) =>
                                                          userContactId ===
                                                          r.agencyUserContactId
                                                  )
                                                : null;

                                        return (
                                            <tr
                                                key={`confirmation-${r.id}`}
                                                className={
                                                    this.state
                                                        .selectedItemId === r.id
                                                        ? 'selected'
                                                        : ''
                                                }
                                                style={{ cursor: 'pointer' }}
                                                onClick={() =>
                                                    this.setState({
                                                        selectedItemId: r.id
                                                    })
                                                }
                                            >
                                                <td style={{ padding: '2px' }}>
                                                    <ProfileImage
                                                        selectable={false}
                                                        url={imageUrl}
                                                        size={38}
                                                    />
                                                    <span className="m-l-xs">
                                                        {r.recipientName}
                                                    </span>
                                                </td>
                                                <td
                                                    style={{
                                                        textAlign: 'center'
                                                    }}
                                                >
                                                    {r.engageDirectly && (
                                                        <span>directly</span>
                                                    )}
                                                    {selectedAgency && (
                                                        <span>
                                                            {
                                                                selectedAgency!
                                                                    .organisationName
                                                            }
                                                        </span>
                                                    )}
                                                    {!r.engageDirectly &&
                                                        (!r.engageAgency ||
                                                            !r.agencyUserContactId) && (
                                                            <span>
                                                                <strong>
                                                                    Not set
                                                                </strong>
                                                            </span>
                                                        )}
                                                </td>
                                                <td>
                                                    <i
                                                        className="fa fa-exclamation-triangle"
                                                        style={{
                                                            color: 'orangered',
                                                            fontSize: '20px',
                                                            visibility:
                                                                !!r.agencyUserContactId ||
                                                                r.engageDirectly
                                                                    ? 'hidden'
                                                                    : 'visible'
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        </div>
                    </>
                )}
                { (this.state.showEngagementSelector) &&
                    <div className="layout vertical">
                        <label
                            style={{
                                fontSize: '15px',
                                fontWeight: 400,
                                textAlign: 'center'
                            }}
                        >
                            {isSmall ? 'Engagement' : 'Select engagement'} method
                            for{' '}
                            <strong>{this.getSelectedItem()!.recipientName}</strong>
                            :
                        </label>
                        <div>
                            <div className="layout horizontal center m-t">
                                {this.getSelectedItem()!.allowDirectEngagement ? (
                                    <Toggle
                                        id="engage-directly"
                                        checked={
                                            this.getSelectedItem()!.engageDirectly
                                        }
                                        onChange={() =>
                                            this.handleEngageDirectlyToggle()
                                        }
                                    />
                                ) : (
                                    <div style={{ height: '24px' }}>
                                        <SimpleTooltip
                                            id="worker-no-direct-engagement"
                                            text={NoDirectEngagement}
                                        >
                                            <div>
                                                <Toggle
                                                    id="engage-directly"
                                                    defaultChecked={false}
                                                    disabled={true}
                                                />
                                            </div>
                                        </SimpleTooltip>
                                    </div>
                                )}
                                <label
                                    htmlFor="engage-directly"
                                    className="m-l-sm"
                                    style={{ fontWeight: 'bold', fontSize: '14px' }}
                                >
                                    Directly
                                </label>
                            </div>
                            <div
                                style={{
                                    paddingLeft: isSmall ? '0' : '60px',
                                    marginTop: '5px'
                                }}
                            >
                                Engage this worker directly
                            </div>
                            {this.getSelectedItem()!.allowDirectEngagement && (
                                <div
                                    className="info-dialog"
                                    style={{
                                        marginTop: '10px',
                                        background: theme.colours.blue2,
                                        color: 'white'
                                    }}
                                >
                                    <i className="fas fa-comment-dots" />
                                    <div>
                                        <p
                                            style={{
                                                color: 'white'
                                            }}
                                        >
                                            This worker is open for direct
                                            engagement. Please make sure you have a
                                            contractual agreement in place
                                        </p>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div>
                            <div className="layout horizontal center m-t-lg">
                                <Toggle
                                    id="engage-agency"
                                    checked={this.getSelectedItem()!.engageAgency}
                                    onChange={() => this.handleEngageAgencyToggle()}
                                />
                                <label
                                    htmlFor="engage-agency"
                                    className="m-l-sm"
                                    style={{ fontWeight: 'bold', fontSize: '14px' }}
                                >
                                    Via agency/third-party
                                </label>
                            </div>
                            <div
                                style={{
                                    paddingLeft: isSmall ? '0' : '60px',
                                    marginTop: '5px'
                                }}
                            >
                                Select an agency to engage this worker through
                                {this.getSelectedItem()!.engageAgency && (
                                    <>
                                        <br />
                                        If your agency isn't listed{' '}
                                        <Link
                                            onClick={() =>
                                                this.setState({
                                                    showAgencySelector: true
                                                }, () => {
                                                    this.autoAgencyAdd();
                                                })
                                            }
                                        >
                                            add them now
                                        </Link>
                                    </>
                                )}
                            </div>
                            {this.getSelectedItem()!.engageAgency && (
                                <div
                                    style={{
                                        fontSize: '10px',
                                        fontStyle: 'italic',
                                        marginTop: '10px',
                                        paddingLeft: isSmall ? '0' : '60px'
                                    }}
                                >
                                    Please make sure you have a contractual
                                    agreement with each selected organization
                                </div>
                            )}
                            <div>
                                {this.getSelectedItem()!.engageAgency && (
                                    <Table condensed className="m-t">
                                        <tbody>
                                            {this.getCombinedAgencies().map((a) => {
                                                const {
                                                    userContactId,
                                                    organisationName,
                                                    // primaryContactName,
                                                    imageId,
                                                    isHirers,
                                                    isWorkers,
                                                    organisationId
                                                } = a;

                                                const getImgUrl = !organisationId
                                                    ? isWorkers && !isHirers
                                                        ? CompanyApi.getWorkerTemporaryOrganisationImage
                                                        : CompanyApi.getTemporaryOrganisationProfileImageUrl
                                                    : CompanyApi.getOrganisationProfileImageUrl;

                                                const imageUrl =
                                                    imageId == null
                                                        ? ''
                                                        : getImgUrl(imageId);

                                                return (
                                                    <tr
                                                        key={`worker-agency-${imageId}`}
                                                        style={{
                                                            cursor: 'pointer'
                                                        }}
                                                        className={
                                                            this.getSelectedItem()!
                                                                .agencyUserContactId ===
                                                            userContactId
                                                                ? 'selected'
                                                                : ''
                                                        }
                                                        onClick={() =>
                                                            this.handleAgencySelectForSelectedItem(
                                                                a
                                                            )
                                                        }
                                                    >
                                                        <td
                                                            style={{
                                                                width: '60px',
                                                                padding: '2px'
                                                            }}
                                                        >
                                                            <ProfileImage
                                                                selectable={false}
                                                                url={imageUrl}
                                                                size={38}
                                                            />
                                                        </td>
                                                        <td
                                                            style={{
                                                                verticalAlign:
                                                                    'middle'
                                                            }}
                                                        >
                                                            <div className="overflow-ellipsis">
                                                                {organisationName}
                                                                {/* {primaryContactName && (
                                                                    <span className="contact-name">
                                                                        (
                                                                        {
                                                                            primaryContactName
                                                                        }
                                                                        )
                                                                    </span>
                                                                )} */}
                                                            </div>
                                                        </td>
                                                        <td
                                                            style={{
                                                                verticalAlign:
                                                                    'middle',
                                                                textAlign: 'right',
                                                                minWidth: '165px'
                                                            }}
                                                        >
                                                            {this.renderRowBadge(a)}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                            <tr 
                                                onClick={() => {
                                                    this.setState({
                                                        showAgencySelector: true
                                                    }, () => {
                                                        this.autoAgencyAdd();
                                                    })
                                                }}
                                                style={{
                                                    cursor: 'pointer',
                                                    background: theme.colours.blue2,
                                                    color: 'white'
                                                }}
                                            >
                                                <td 
                                                    colSpan={3}
                                                    style={{
                                                        padding: 10,
                                                        textAlign: 'center'
                                                    }}
                                                >
                                                    Add new agency
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                )}
                            </div>
                        </div>
                    </div>
                }
                { this.state.showEngagementSelector && this.missingPrimaryContact && (
                    <div
                        className="layout horizontal center m-t-lg m-b-sm"
                        style={{
                            margin: '20px 0 -5px 0',
                            padding: '2px',
                            boxShadow: '0px 0px 7px 0px orangered'
                        }}
                    >
                        <WarningTip className="fa fa-exclamation-triangle m-r" />
                        <div>
                            <div style={{ fontWeight: 600 }}>
                                You have not set a primary contact for the
                                selected agency
                            </div>
                            <div>
                                If you wish to use this agency{' '}
                                <Link
                                    onClick={() =>
                                        this.setState({
                                            showAgencySelector: true
                                        })
                                    }
                                >
                                    please add a contact
                                </Link>
                            </div>
                        </div>
                    </div>
                )}
                {this.state.showEngagementSelector && this.getSelectedItem()!.agencyUserContactId &&
                    !this.missingPrimaryContact && (
                        <div
                            className="layout horizontal center m-t-lg m-b-sm"
                            style={{
                                margin: '20px 0 -5px 0',
                                padding: '2px',
                                boxShadow: `0px 0px 7px 0px ${theme.colours.green}`
                            }}
                        >
                            <WarningTip
                                className="fas fa-clipboard-check m-r"
                                style={{ color: theme.colours.green }}
                            />
                            <div style={{ fontSize: '12px' }}>
                                When "Complete Offer" is clicked an Assignment
                                Confirmation Request will be emailed to your
                                contact at{' '}
                                {
                                    this.getCombinedAgencies().find(
                                        (a) =>
                                            a.userContactId ===
                                            this.getSelectedItem()!
                                                .agencyUserContactId
                                    )?.organisationName
                                }{' '}
                                and copied to you
                            </div>
                        </div>
                    )}
                <h5 style={{
                    textAlign: 'left',
                    marginTop: '20px'
                }}>
                    Add further files for confirmed applicants
                    { (this.state.confirmedFiles.length > 0 || this.state.confirmedFolders.length > 0) &&
                        <button 
                            className='main'
                            style={{
                                margin: '10px 0 0 10px'
                            }}
                            onClick={() => {
                                this.setState({
                                    selectingFiles: true
                                })
                            }}
                        >
                            Edit Selection
                        </button>
                    }
                </h5>
                <Files
                    page="confirm-offer"
                    addContentText="Add more files/links"
                    mode={this.state.selectingFiles ? "picker" : "show-selected"}
                    onSelectedFilesUpdated={(ids) => {
                        this.setState({
                            confirmedFiles: ids
                        })
                    }}
                    onSelectedFoldersUpdated={(ids) => {
                        this.setState({
                            confirmedFolders: ids
                        })
                    }}
                    onClosed={() => {
                        this.setState({
                            selectingFiles: false
                        })
                    }}
                    switchMode={(mode) => {
                        if (mode === "picker") {
                            this.setState({
                                selectingFiles: true
                            })
                        }
                    }}
                    disableFiles={this.props.alreadySelectedFiles}
                />
                <div
                    className="layout horizontal center-justified complete-offer-options"
                    style={{ marginTop: '25px' }}
                >
                    <Button onClick={this.props.onCancel} className="btn-flat">
                        Cancel
                    </Button>
                    <SimpleTooltip
                        id="complete-offer"
                        text={this.isConfirmOfferDisabled() && !this.state.completeWithoutSelection ? 'Please select an engagement method for each worker' : ''}
                    >
                        <Button
                            id="confirm-offer-btn"
                            key="create-offer-confirm-button"
                            bsStyle="success"
                            className="m-l-xs"
                            disabled={this.isConfirmOfferDisabled() && !this.state.completeWithoutSelection}
                            onClick={this.onCompleteOffer}
                        >
                            Complete offer
                        </Button>
                    </SimpleTooltip>
                </div>
                <div style={{ padding: '0 3px 3px 3px' }}>
                    <DismissableInfo
                        id="ConfirmOffer.showDisclaimer"
                        maxHeight={140}
                        title="Your obligations"
                        body="For the avoidance of doubt updatedge facilitates availability and offers. We do not engage or employ end-users contacts and do not assess the right to work, references, suitability, or do any checks, nor do we invoice or pay for assignments. These obligations remain the end-user’s responsibility and or the agency through which end-users engage any contacts or temporary workers as per their normal terms of engagement for temporary workers."
                    />
                </div>
                <UserAgencySelector
                    show={this.state.showAgencySelector}
                    autoAddOnOpen={true}
                    onHide={() => this.setState({ showAgencySelector: false })}
                    onConfirm={this.handleAgencySelectorConfirm}
                />
            </Wrapper>
        );
    }

    getCombinedAgencies = (): AgencyMutuality[] => {
        const recipient = this.props.recipients.find(
            (r) => r.id === this.state.selectedItemId
        );

        if (!recipient) return [];

        const hirerAgencies = this.state.preferredAgencies || [];
        const recipientAgencies = recipient.preferredAgencies || [];

        const getOrganisationName = ({ organisationName }) => organisationName;
        const hirerAgenciesOrganisationNames = hirerAgencies.map(
            getOrganisationName
        );
        const recipientAgenciesOrganisationNames = recipientAgencies.map(
            getOrganisationName
        );

        const agencies: AgencyMutuality[] = [
            ...hirerAgencies.map((agency) => ({
                ...agency,
                isHirers: hirerAgenciesOrganisationNames.includes(
                    agency.organisationName
                ),
                isWorkers: recipientAgenciesOrganisationNames.includes(
                    agency.organisationName
                )
            })),

            ...recipientAgencies.map((agency) => ({
                ...agency,
                isHirers: hirerAgenciesOrganisationNames.includes(
                    agency.organisationName
                ),
                isWorkers: recipientAgenciesOrganisationNames.includes(
                    agency.organisationName
                )
            }))
        ];

        return agencies
            .sort((a, b) => {
                const name1 = a.organisationName || '';
                const name2 = b.organisationName || '';
                return name1.localeCompare(name2);
            })
            .filter((agency, i, array) => {
                const firstInstanceIndex = array.findIndex(
                    (a) => a.organisationName === agency.organisationName
                );

                const firstSelectableIndex = array.findIndex(
                    (a) =>
                        a.organisationName === agency.organisationName &&
                        !!a.userContactId
                );

                if (firstSelectableIndex === -1)
                    return i === firstInstanceIndex;

                return i === firstSelectableIndex;
            });
    };

    handleAgencySelectForSelectedItem = (agency: UserThirdParty) => {
        const selectedItem = this.getSelectedItem()!;
        const isExistingSelectedAgency =
            selectedItem.agencyUserContactId === agency.userContactId;

        const newItem = {
            ...selectedItem,
            engageDirectly: false,
            agencyUserContactId: isExistingSelectedAgency
                ? null
                : agency.userContactId || null
        };

        this.setState({
            items: this.state.items.map((i) =>
                i.id === this.state.selectedItemId ? newItem : i
            )
        });

        Analytics.trackEvent('confirm-offer-agency-selected');
    };

    handleEngageDirectlyToggle = () => {
        const selectedItem = this.getSelectedItem()!;
        const newItem = {
            ...selectedItem!,
            engageDirectly: !selectedItem.engageDirectly,
            engageAgency: false,
            agencyId: undefined,
            agencyUserContactId: undefined
        };

        this.setState({
            items: this.state.items.map((i) =>
                i.id === this.state.selectedItemId ? newItem : i
            )
        });

        Analytics.trackEvent('confirm-offer-engage-directly-toggled');
    };

    handleEngageAgencyToggle = () => {
        const selectedItem = this.getSelectedItem()!;
        const newItem = {
            ...selectedItem!,
            engageDirectly: false,
            engageAgency: !selectedItem.engageAgency,
            agencyId: undefined
        };

        this.setState({
            items: this.state.items.map((i) =>
                i.id === this.state.selectedItemId ? newItem : i
            )
        });

        Analytics.trackEvent('confirm-offer-engage-agency-changed');
    };

    handleAgencySelectorConfirm = (agencies: UserThirdParty[]) => {
        this.setState({
            showAgencySelector: false,
            preferredAgencies: agencies
        });

        Analytics.trackEvent('confirm-offer-agency-selector-confirmed');
    };

    get missingPrimaryContact(): boolean {
        return this.state.items.some(
            (i) =>
                !i.engageDirectly &&
                !!i.agencyUserContactId &&
                !this.state.preferredAgencies.some(
                    (pa) =>
                        pa.userContactId == i.agencyUserContactId &&
                        !!pa.primaryContactName
                )
        );
    }

    get engagementMethodsSet(): boolean {
        return this.state.items.every(
            (i) => !!i.agencyUserContactId || i.engageDirectly
        );
    }

    private isConfirmOfferDisabled = () =>
        !this.engagementMethodsSet || this.missingPrimaryContact;

    private onCompleteOffer = async () => {
        if (this.state.confirmedFiles.length !== 0 || this.state.confirmedFiles.length !== 0) {
            await OfferApi.attachFilesAndFolders(this.props.offerId, this.state.confirmedFiles, this.state.confirmedFolders, true).catch(e => {})
        }
        
        const { onConfirm } = this.props;
        const { items } = this.state;
        const rejectUnsuccesful = true;
        const payload = items.map(
            ({
                id: recipientId,
                engageDirectly,
                agencyUserContactId,
                engageAgency
            }) => ({
                recipientId,
                engageDirectly,
                agencyUserContactId,
                engageAgency
            })
        );

        onConfirm(payload, rejectUnsuccesful, this.state.confirmedFiles.length + this.state.confirmedFolders.length);
    };

    private getSelectedItem = () => {
        const { items, selectedItemId } = this.state;
        return items.find(({ id }) => id === selectedItemId);
    };

    private renderRowBadge(props: AgencyMutuality) {
        const {
            isHirers,
            isWorkers,
            organisationName,
            email,
            userContactId
        } = props;

        // console.log('AgencyMutuality:', props);

        if (isHirers && !email && !userContactId)
            return (
                <SimpleTooltip
                    id="agency-missing-contact"
                    text="You do not have a contact at this agency"
                >
                    <AgencyBadge className="missing-contact">
                        Missing contact
                    </AgencyBadge>
                </SimpleTooltip>
            );

        if (isHirers && isWorkers)
            return (
                <SimpleTooltip
                    id="agency-is-mutu"
                    text={`${
                        this.getSelectedItem()!.recipientName
                    } has also currently listed ${organisationName} as an agency/third-party`}
                >
                    <AgencyBadge className="mutual">Mutual agency</AgencyBadge>
                </SimpleTooltip>
            );

        if (isHirers && !isWorkers)
            return (
                <SimpleTooltip
                    id="agency-is-hirers-only"
                    text={`${
                        this.getSelectedItem()!.recipientName
                    } has not currently listed this agency/third-party`}
                >
                    <AgencyBadge className="hirer-only">
                        Contact missing agency
                    </AgencyBadge>
                </SimpleTooltip>
            );

        if (!isHirers && isWorkers)
            return (
                <SimpleTooltip
                    id="agency-is-workers-only"
                    text={`Your contact has listed this agency/third-party, however, they are not in your organization's profile as an agency/third-party. Please add them to the organization profile and return to this page, or select an alternative for this confirmation`}
                >
                    <AgencyBadge className="worker-only">
                        Missing agency
                    </AgencyBadge>
                </SimpleTooltip>
            );
    }
}

const Wrapper = styled.div`
    table {
        margin-bottom: 0;

        tbody > tr {
            border-top: 1px solid #ddd;

            &:last-child {
                border-bottom: 1px solid #ddd;
            }

            &:not(.selected):hover {
                background: #f0f8fc;
            }

            &.selected {
                background: #def;
            }

            td {
                vertical-align: middle !important;
            }
        }
    }

    .complete-offer-options {
        position: absolute;
        bottom: 0;
        left: 0;
        width: calc(100% - 30px);
        background: gainsboro;
        margin: 15px;
        padding: 10px;
        border-radius: 14px;
        z-index: 2;
    }
`;

const AgencyBadge = styled.span`
    margin-right: 5px;
    padding: 3px 8px;
    color: white;
    font-size: 12px;
    border-radius: 10px;

    &.missing-contact {
        background: ${theme.colours.red};
    }

    &.mutual {
        background: ${theme.colours.green};
    }

    &.hirer-only {
        background: #ffb606;
    }

    &.worker-only {
        background: ${theme.colours.red};
    }
`;

const WarningTip = styled.i`
    width: 50px;
    margin-right: 10px;
    padding-left: 25px;
    color: orangered;
    font-size: 20px;
    text-align: center;
`;

const Link = styled.span`
    color: blue;
    cursor: pointer;
    opacity: 0.75;

    &:hover {
        text-decoration: underline;
        opacity: 1;
    }
`;

const NoDirectEngagement = (
    <div className="layout horizontal center">
        <WarningTip
            className="fa fa-exclamation-triangle m-r"
            style={{ paddingLeft: '5px' }}
        />
        <div>
            This worker does not allow direct engagement.
            <br />
            Please select an agency to engage with instead.
        </div>
    </div>
);
