/* eslint-disable */
import React from 'react';
import styled from 'styled-components';
import { connect, ConnectedProps } from 'react-redux';
import { toast, ToastType } from 'react-toastify';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';

import { Subscribe } from '../../unstated-fork/unstated';
import { ApplicationState } from '../../store';
import * as ProfileState from '../../store/Profile';
import * as Notification from './../../store/Notifications';
import { ProfileImage } from '../ui-components/ProfileImage';
import Analytics from '../../services/analytics.service';
import { supportedImageFormats } from '../../constants';
import { ProfileVerificationContainer } from '../../state-containers/profile-verification.container';
import theme from '../../css/theme';
import ImageUnsuitableModal from './ImageUnsuitableModal';
import InternalTracker from '../../InternalTracker';
import history from '../../history';
import * as EventsState from '../../store/Events';
import ProfileApi from '../../api/profile/Profile';
import VerifiedTooltip from '../ui-components/VerifiedTooltip';
import Utilities from '../../Utilities';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { CircularProgress } from '@mui/material';
import Dialog from '../ui-components/Dialog';

type ProfileEditorProps = ConnectedProps<typeof connector> & {
    emailAddress: string;
};

/** Subset of state fields mapped onto props via connect */
type EditableProfileProps = {
    firstName: string;
    lastName: string;
    headline: string;
    betaFeatureOptIn: boolean;
    profileImageUrl: string;
    phoneNumber: string;
    phoneNumberVerified: boolean;
    loaded: boolean;
    userSetProfileImage: boolean;
    staleData?: boolean;
} & typeof EventsState.actionCreators;

// Defines state that matches the props def
interface ModifiedProfileState extends EditableProfileProps {
    loaded: boolean;
    newProfileImage?: File;
    newProfileImagePreviewUrl?: string;
    showVerification?: boolean;
    showUnsuitablePhoto?: boolean;
    showUploadInput?: boolean;
}

/** Component to enable the user to edit profile properties */
class UserProfileEditor extends React.Component<
    ProfileEditorProps,
    ModifiedProfileState
> {
    state: ModifiedProfileState = {
        firstName: this.props.firstName,
        lastName: this.props.lastName,
        betaFeatureOptIn: this.props.betaFeatureOptIn,
        phoneNumber: this.props.phoneNumber,
        phoneNumberBeforeChange: this.props.phoneNumber,
        phoneNumberChanged: false,
        phoneNumberVerified: this.props.phoneNumberVerified,
        loaded: this.props.loaded,
        profileImageUrl: this.props.profileImageUrl,
        newProfileImage: undefined,
        newProfileImagePreviewUrl: '',
        typedPhoneNumber: false,
        staleData: this.props.staleData
    };

    constructor(props) {
        super(props);
        this.handleFieldContentChange = this.handleFieldContentChange.bind(
            this
        );
        this.saveChanges = this.saveChanges.bind(this);

        // if already retrieved, use props values
        if (this.props.loaded)
            this.state = { ...this.createDraftStateFromProps() };
        else {
            // otherwise initialise default empty fields
            this.state = {
                firstName: '',
                lastName: '',
                headline: '',
                loaded: false,
                betaFeatureOptIn: false,
                phoneNumber: '',
                phoneNumberBeforeChange: '',
                profileImageUrl: '',
                newProfileImage: undefined,
                newProfileImagePreviewUrl: '',
            };
        }
    }

    /** Copies  */
    private createDraftStateFromProps() {
        return {
            firstName: this.props.firstName,
            lastName: this.props.lastName,
            headline: this.props.headline,
            betaFeatureOptIn: this.props.betaFeatureOptIn,
            phoneNumber: this.props.phoneNumber,
            phoneNumberBeforeChange: this.props.phoneNumber,
            phoneNumberVerified: this.props.phoneNumberVerified,
            loaded: this.props.loaded,
            profileImageUrl: '',
            newProfileImage: undefined,
            newProfileImagePreviewUrl: '',
            staleData: this.props.staleData
        };
    }

    /** Fires when props change and updates local draft state */
    public componentDidUpdate(prevProps) {
        if ((!prevProps.loaded && this.props.loaded) || (prevProps.staleData && !this.props.staleData)) {
            this.setState(this.createDraftStateFromProps());
        }
    }

    componentDidMount() {
        this.initSMSVerificationHub();
        console.log((window as any).dispatchRefreshUserProfile);
        if ((window as any).dispatchRefreshUserProfile) {
            (window as any).dispatchRefreshUserProfile();
        }
    }

    handleselectedFile(files: FileList | null) {
        if (files !== null) {
            if (0 > supportedImageFormats.indexOf(files[0].type)) {
                Notification.actionCreators.display(
                    ToastType.ERROR,
                    'Please select a jpg, png or gif file.'
                );
            } else {
                this.setState({
                    newProfileImage: files[0],
                    newProfileImagePreviewUrl: URL.createObjectURL(files[0]!)
                })

                InternalTracker.trackEvent('Profile Image Updated');
            }
        }
    }

    async updateProfile() {
        let phoneNumberFormatted = this.state.phoneNumber;
        // The plugin doesn't add + by default
        if (!phoneNumberFormatted.startsWith("+")) {
            phoneNumberFormatted = "+" + phoneNumberFormatted
        }
        // Removing 0 local prefix
        if (phoneNumberFormatted.startsWith("+440")) {
            phoneNumberFormatted = phoneNumberFormatted.replace("+440", "+44");
        }
        await this.props.updateProfile({
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            betaFeatureOptIn: this.state.betaFeatureOptIn,
            phoneNumber: this.state.phoneNumber,
            headline: this.state.headline
        })

        Utilities.upsertLocalUser({
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            betaFeatureOptIn: this.state.betaFeatureOptIn,
            phoneNumber: phoneNumberFormatted,
            headline: this.state.headline
        })

        InternalTracker.trackEvent('Profile Updated');
        Analytics.trackEvent('user-profile-saved');
    }

    handleVerificationClick = () => {
        this.setState({
            showVerification: true
        });
    };

    handleVerificationHide = () => {
        this.setState({
            showVerification: false
        });
    };

    // updates the user's profile
    async saveChanges() {
        const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') || '{}') : null;

        if (
            !this.props.userSetProfileImage &&
            !this.state.newProfileImage &&
            !this.props.profileImageUrl
        ) {
            toast.error(
                'Please set a profile image before saving your profile.'
            );
            return;
        }

        if (!this.state.firstName) {
            toast.error(
                'Please provide a first name before saving your profile.'
            );
            return;
        }

        if (!this.state.headline) {
            toast.error(
                'Please provide your headline before saving your profile.'
            );
            return;
        }

        if (!this.state.lastName) {
            toast.error(
                'Please provide a last name before saving your profile.'
            );
            return;
        }

        if (!this.state.phoneNumber || this.state.phoneNumber.length < 8) {
            toast.error(
                'Please provide your phone number before saving your profile.'
            );
            return;
        }

        if (this.state.newProfileImage) {
            if ((window as any).Cypress) {
                const fil = Utilities.datURLtoFile("data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wBDAAUDBAQEAwUEBAQFBQUGBwwIBwcHBw8LCwkMEQ8SEhEPERETFhwXExQaFRERGCEYGh0dHx8fExciJCIeJBweHx7/2wBDAQUFBQcGBw4ICA4eFBEUHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh7/wAARCADGAP8DASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAUI/8QAGxABAAAHAAAAAAAAAAAAAAAAAAEDBQY2dLL/xAAWAQEBAQAAAAAAAAAAAAAAAAAAAgP/xAAaEQEAAgMBAAAAAAAAAAAAAAAAAQMCMnEz/9oADAMBAAIRAxEAPwDXVr4zS9OTxBRTrXxml6cniCi0u9MuyivSOADNYAAAAAAAAAAAAAAAAAAAAAAAAAAACda+M0vTk8QUU618ZpenJ4gotLvTLsor0jgAzWAAAAAAAAAAAAAAAAAAAAAAAAAAAAnWvjNL05PEFFOtfGaXpyeIKLS70y7KK9I4AM1gAAAAAAAAAAAAAAAAAAAAAAAAAAAJ1r4zS9OTxBRTrXxml6cniCi0u9MuyivSOADNYAAAAAAAAAAAAAAAAAAAAAAAAAAACda+M0vTk8QUU618ZpenJ4gotLvTLsor0jgAzWAAAAAAAAAAAAAAAAAAAAAAAAAAAAnWvjNL05PEFFOtfGaXpyeIKLS70y7KK9I4AM1gAAAAAAAAAAAAAAAAAAAAAAAAAAAJ1r4zS9OTxBRTrXxml6cniCi0u9MuyivSOADNYAAAAAAAAAAAAAAAAAAAAAAAAAAACda+M0vTk8QUU618ZpenJ4gotLvTLsor0jgAzWAAAAAAAAAAAAAAAAAAAAAAAAAAAAnWvjNL05PEFFOtfGaXpyeIKLS70y7KK9I4AM1gAAAAAAAAAAAAAAAAAAAAAAAAAAAJ1r4zS9OTxBRTrXxml6cniCi0u9MuyivSOADNYAAAAAAAAAAAAAAAAAAAAAAAAAAACda+M0vTk8QUU618ZpenJ4gotLvTLsor0jgAzWAAAAAAAAAAAAAAAAAAAAAAAAAAAAnWvjNL05PEFFOtfGaXpyeIKLS70y7KK9I4AM1gAAAAAAAAAAAAAAAAAAAAAAAAAAAJ1r4zS9OTxBRBpd6ZdlFekcAGawAAAAAAAAAAAAAAAAAAAAAAAAAAAH//2Q==", "1.jpeg");
                await this.props.updateProfileImage(fil, "xz");
            } else {
                const started = false;

                let finished = false;

                const res = await new Promise((resolve) => {
                    try {
                        Utilities.resizeAndRotateImage(
                            this.state.newProfileImage as Blob
                        ).then(async (newProfileImage) => {
                            if (newProfileImage) {
                                const res = await this.props.updateProfileImage(newProfileImage, localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user") || "{}").id : null);
                                resolve(res);
                            } else {
                                toast.error(
                                    "This doesn't appear to be a valid image file, please choose another one"
                                );
                                resolve(null);
                            }
                        }).catch(data => {
                            toast.error(
                                "This doesn't appear to be a valid image file, please choose another one"
                            );
                            resolve(null);
                        })
                    } catch (e) {
                        toast.error(
                            "This doesn't appear to be a valid image file, please choose another one"
                        );
                        resolve(null);
                    }
                })

            }
        }

        setTimeout(() => {

            (window as any).onUserProfileSave = async() => {
                // Needs to reverify if finalized profile
                if (this.state.phoneNumberChanged && !user.awaitingFinalisation) {
                    await ProfileApi.sendSMSVerificationCode(this.state.phoneNumber);
                    localStorage.setItem("lastSentVerificationSMS", new Date().toString())
                    this.initSMSVerificationHub();
                    this.setState({
                        phoneNumberVerified: false
                    })
                }
            }
            
            this.updateProfile().then(() => {

                Utilities.refreshImageCache();
                this.setState(
                    {
                        profileImageUrl: ProfileApi.getProfileImageUrl(
                            this.props.id
                        )
                    },
                    () => {
                        if (this.props.refreshOverlayMenu) {
                            this.props.refreshOverlayMenu();
                        }
                    }
                );
                // TODO back to onboarding?
            });
        }, (window as any).Cypress ? 3000 : 0)

        Analytics.trackEvent('user-profile-image-saved');
    }

    initSMSVerificationHub() {
        (window as any).onSignalRPhoneNumberVerifiedUserProfile = () => {
            this.setState({
                phoneNumberVerified: true
            })
        }
    }

    /** Fires when the user an input element's  field content and updates local draft state */
    handleFieldContentChange(e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) {
        this.setState({ ...this.state, [e.target.name]: e.target.value });
    }

    public render() {
        return (
            <Wrapper>
                <Subscribe to={[ProfileVerificationContainer]}>
                    {(verification: ProfileVerificationContainer) => (
                        <div
                            className="form-horizontal"
                            style={{ textAlign: 'left' }}
                        >
                            { (this.state.staleData) &&
                                <div className="nonblocking-loading-wrapper">
                                    <CircularProgress style={{ width: 24, height: 24 }} />
                                    Fetching Latest
                                </div>
                            }
                            <div className="form-group">
                                <label className="col-sm-12 col-xs-12 control-label">
                                    Profile Photo
                                </label>
                                <Dialog 
                                    body="Close up of your head and top of your shoulders so that your face takes up 70–80% of a sharp and clear photograph." 
                                    type='info'
                                    style={{
                                        margin: '8px 15px',
                                        width: 'calc(100% - 30px)'
                                    }}
                                />
                                <div className="col-sm-12 col-xs-12">
                                    <ProfileImage
                                        selectable={false}
                                        type={'Organisation'}
                                        url={
                                            this.state
                                                .newProfileImagePreviewUrl ||
                                            `${
                                                this.props.profileImageUrl
                                            }?updated_at=${(window as any).clientLastReload}`
                                        }
                                        size={72}
                                        onSelect={() => {
                                            this.setState({
                                                newProfileImagePreviewUrl: '',
                                                showUploadInput: true
                                            }, () => {
                                                if (process.env.JEST_WORKER_ID === undefined) {
                                                    setTimeout(() => {
                                                        let el = document.getElementById('userProfileImageFile');
                                                        if (el) {
                                                            el.click();
                                                        }
                                                    }, 200);
                                                }
                                            });
                                        }}
                                    />
                                    {!verification.state.verified && (
                                        <span className="required-overlay">
                                            * required
                                        </span>
                                    )}
                                </div>
                                <div className="col-sm-12 col-xs-12">
                                    {(!this.props.profileImageUrl || this.state.showUploadInput || (window as any).Cypress) &&
                                        <input
                                            type="file"
                                            name="userProfileImageFile"
                                            id="userProfileImageFile"
                                            className="form-control"
                                            style={{ marginTop: '0.5rem' }}
                                            // disabled={verification.state.verified}
                                            onChange={(e) =>
                                                this.handleselectedFile(
                                                    e.target.files
                                                )
                                            }
                                        />
                                    }
                                    { ((this.state.newProfileImagePreviewUrl || this.props.profileImageUrl) && !this.state.showUploadInput) &&
                                        <button
                                            className="btn"
                                            onClick={() => {
                                                this.setState({
                                                    newProfileImagePreviewUrl: '',
                                                    showUploadInput: true
                                                }, () => {
                                                    if (process.env.JEST_WORKER_ID === undefined) {
                                                        setTimeout(() => {
                                                            let el = document.getElementById('userProfileImageFile');
                                                            if (el) {
                                                                el.click();
                                                            }
                                                        }, 200);
                                                    }
                                                });
                                            }}
                                            style={{
                                                display: 'block',
                                                marginTop: '12px',
                                                background: 'whitesmoke'
                                            }}
                                        >
                                            Replace Photo
                                        </button>
                                    }
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="col-sm-12 col-xs-12 control-label">
                                    First Name
                                </label>
                                <div className="col-sm-12 col-xs-12">
                                    <input
                                        type="text"
                                        name="firstName"
                                        onChange={this.handleFieldContentChange}
                                        value={this.state.firstName === "QUICK" ? "" : this.state.firstName}
                                        className="form-control"
                                        disabled={verification.state.verified}
                                        onBlur={() => {
                                            InternalTracker.trackEvent('First Name Updated');
                                        }}
                                    />
                                    {verification.state.verified ? (
                                        <VerifiedTooltip
                                            text={
                                                'Your profile has been verified with this first name. Please contact us via our Support page if you wish to change your first name.'
                                            }
                                        />
                                    ) : (
                                        <span className="required-overlay">
                                            * required
                                        </span>
                                    )}
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="col-sm-12 col-xs-12 control-label">
                                    Last Name
                                </label>
                                <div className="col-sm-12 col-xs-12">
                                    <input
                                        type="text"
                                        name="lastName"
                                        onChange={this.handleFieldContentChange}
                                        value={this.state.lastName === "SIGNUP" ? "" : this.state.lastName}
                                        className="form-control"
                                        disabled={verification.state.verified}
                                        onBlur={() => {
                                            InternalTracker.trackEvent('Last Name Updated');
                                        }}
                                    />
                                    {verification.state.verified ? (
                                        <VerifiedTooltip
                                            text={
                                                'Your profile has been verified with this last name.'
                                            }
                                        />
                                    ) : (
                                        <span className="required-overlay">
                                            * required
                                        </span>
                                    )}
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="col-sm-12 col-xs-12 control-label">
                                    Headline
                                </label>
                                <div className="col-sm-12 col-xs-12">
                                    <textarea
                                        name="headline"
                                        placeholder="Describe your role to contacts"
                                        onChange={this.handleFieldContentChange}
                                        value={this.state.headline}
                                        className="form-control no-border-color"
                                        rows={3}
                                        onBlur={() => {
                                            InternalTracker.trackEvent('Headline Updated');
                                        }}
                                        style={ this.state.headline ? {} : { border: '3px solid ' + theme.colours.red2 } }
                                    />
                                    {!verification.state.verified &&
                                        <span className="required-overlay">
                                            * required
                                        </span>
                                    }
                                </div>
                            </div>
                            <div className="form-group">
                                <label
                                    className="col-sm-12 col-xs-12 control-label"
                                    htmlFor="emailAddress"
                                >
                                    Email Address
                                </label>
                                <div className="col-sm-12 col-xs-12">
                                    {/*<div className="input-group">*/}
                                    <input
                                        id="emailAddress"
                                        type="text"
                                        name="emailAddress"
                                        value={this.props.emailAddress}
                                        className="form-control"
                                        disabled
                                        onBlur={() => {
                                            InternalTracker.trackEvent('Email Updated');
                                        }}
                                    />
                                    {verification.state.verified ? (
                                        <VerifiedTooltip
                                            text={
                                                'Your profile has been verified with this email. Please contact us via our Support page if you wish to change your email.'
                                            }
                                        />
                                    ) : (
                                        <span className="required-overlay">
                                            * required
                                        </span>
                                    )}
                                    <button
                                        className="btn btn-default"
                                        type="button"
                                        style={{ marginTop: '0.5rem' }}
                                        onClick={() =>
                                            history.push(
                                                '/settings/manage-emails'
                                            )
                                        }
                                    >
                                        Manage Emails
                                    </button>
                                </div>
                                <label className="col-sm-12 col-xs-12 control-label">
                                    <span>
                                        (this is only visible to you, but people can still add you as a contact if they know your email)
                                    </span>
                                </label>
                            </div>
                            <div className="form-group">
                                <label className="col-sm-12 col-xs-12 control-label">
                                    <span>Direct Mobile Number (nobody sees other than Updatedge)</span>
                                </label>
                                
                                <div className="col-sm-12 col-xs-12">
                                    <div 
                                        className='telephone-number-wrapper'
                                        style={ this.state && this.state.phoneNumber && this.state.phoneNumber.length > 2 ? {} : { border: '3px solid ' + theme.colours.red2, borderRadius: 6 } }
                                    >
                                        <PhoneInput
                                            preferredCountries={["US", "UK"]}
                                            enableLongNumbers={true}
                                            value={this.state ? ((!this.state.phoneNumber && !this.state.typedPhoneNumber) ? "+44" : !this.state.phoneNumber ? "" : this.state.phoneNumber) : "+44"}
                                            onChange={(phone) => {
                                                this.setState({
                                                    phoneNumber: phone,
                                                    typedPhoneNumber: true,
                                                    phoneNumberChanged: true
                                                })
                                            }}
                                            onBlur={() => {
                                                InternalTracker.trackEvent('Phone Number Updated');
                                            }}
                                        />                                        </div>
                                    { (!this.state.phoneNumber) &&
                                        <span className="required-overlay">
                                            * required - a verification SMS will be sent
                                        </span>
                                    }
                                    { (this.state.phoneNumber && this.state.phoneNumberVerified) &&
                                        <VerifiedTooltip
                                            text={
                                                'You verified this nuber'
                                            }
                                        />
                                    }
                                    { (this.state.phoneNumber && this.state.phoneNumberBeforeChange && !this.state.phoneNumberVerified) &&
                                        <span className="verified-overlay">
                                            Not verified, we have sent an SMS. <button onClick={() => {
                                                InternalTracker.trackEvent('Phone Number Verification SMS Sent Again');
                                                ProfileApi.sendSMSVerificationCode(this.state.phoneNumber);
                                                localStorage.setItem("lastSentVerificationSMS", new Date().toString())
                                                this.initSMSVerificationHub();
                                                Notification.actionCreators.display(
                                                    ToastType.SUCCESS,
                                                    'Verification SMS Sent'
                                                );
                                            }}>Send again?</button>
                                        </span>
                                    }
                                </div>
                                <label className="col-sm-12 col-xs-12 control-label">
                                    <span>(this is only visible to you)</span>
                                </label>
                            </div>
                            <div className="form-group">
                                <div className="col-sm-12 col-xs-12">
                                    <button
                                        className="btn btn-success"
                                        type="submit"
                                        style={{ width: '100%', margin: 0 }}
                                        onClick={() => this.saveChanges()}
                                    >
                                        Save Changes
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </Subscribe>
                <ImageUnsuitableModal
                    show={!!this.state.showUnsuitablePhoto}
                    onHide={() => this.setState({ showUnsuitablePhoto: false })}
                />
            </Wrapper>
        );
    }
}

export const ShieldWrapper = styled.div`
    .fa-shield {
        margin-right: 5px;
        color: ${theme.colours.red};
        font-size: 20px;

        &.checked {
            color: ${theme.colours.green};

            + span {
                color: ${theme.colours.green} !important;
            }
        }

        + span {
            color: ${theme.colours.red};
            font-weight: 600;
        }
    }
`;

const Wrapper = styled.div`
    margin-top: 52px;

    input[disabled] {
        cursor: default;
    }

    .required-overlay {
        position: absolute;
        top: -28px;
        right: 25px;
        font-style: italic;
        opacity: 0.7;
        color: firebrick;
    }

    .verified-overlay {
        position: absolute;
        top: -28px;
        right: 25px;
        font-style: italic;
        opacity: 0.7;
    }

    label {
        padding-bottom: 4px;

        span {
            font-weight: 500;
        }
    }

    .telephone-number-wrapper .form-control {
        width: 100%;
    }
`;

const connector = connect(
    (state: ApplicationState) => state.profile.authenticatedUserProfile,
    { ...ProfileState.actionCreators, ...EventsState.actionCreators }
);

export default connector(UserProfileEditor);
