/* eslint-disable */
import React from 'react';
import styled from 'styled-components';
import { Table, Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { ToastType, ToastContainer } from 'react-toastify';
import * as Notifications from '../../store/Notifications';

import AppConfig from '../../components/config/Config';
import VerificationApi from '../../api/verification/Verification';
import OrganisationApi from '../../api/organisation/Organisation';
import CompanyApi from '../../api/company/Company';
import { ExternalOrganisationLogoRecord } from '../../api/organisation/ResponseTypes';
import { AsyncOverlay } from '../../components/ui-components/AsyncOverlay';
import { AgencySuggestionDto, CompanyRoles } from '../../api/company/ResponseTypes';
import { ProfileImage } from '../../components/ui-components/ProfileImage';
import ProfileApi from '../../api/profile/Profile';
import { UserType } from '../../state-containers/user-type.container';
import theme from '../../css/theme';
import Utilities from 'src/Utilities';

interface State {
    loading: boolean;
    results: AgencySuggestionDto[];
    newValues: string;
}

export default class ExternalOrgLogos extends React.Component<{}, State> {
    state = {
        loading: true,
        results: [],
        newValues: '',
    } as State;

    componentDidMount() {
        this.reload();
    }

    async insertNew() {
        if (this.state.newValues) {
            let newValuesArr = JSON.parse("[" + (this.state.newValues.substr(0, this.state.newValues.length - 2)) + "]").filter(v => v.domain && v.name && v.org && v.email);
            this.setState({ loading: true })
            CompanyApi
                .pushAgencySuggestion(newValuesArr)
                .then(data => {
                    Notifications.actionCreators.display(ToastType.SUCCESS, "Saved");
                    this.reload();
                })
                .catch(error => {
                    Notifications.actionCreators.display(ToastType.ERROR, "Failed to save");
                })
        }
    }

    async reload() {
        this.setState({ loading: true })
        const results = await CompanyApi.getAllAgencySuggestion();
        console.log(results, "<<<<<<<<<<<")
        this.setState({
            results: results,
            loading: false,
        })
    }

    // save(i) {
    //     let logo = this.state.results[i];
    //     // @ts-ignore
    //     logo.approved = logo.approved === true || logo.approved === "on";
    //     if (logo.newLogoUrl && !Utilities.isValidURL(logo.newLogoUrl)) {
    //         alert("Invalid image url");
    //         return;
    //     }
    //     this.setState({
    //         loading: true
    //     })

    //     OrganisationApi
    //         .updateExternalOrganisationLogo(logo)
    //         .then(data => {
    //             Notifications.actionCreators.display(ToastType.SUCCESS, "Saved");
    //             this.setState({
    //                 loading: false
    //             })
    //             this.reload();
    //         })
    //         .catch(data => { 
    //             Notifications.actionCreators.display(ToastType.ERROR, "Failed to Save");
    //             this.setState({
    //                 loading: false
    //             })
    //         })
    // }
    
    render() {
        return (
            <Wrapper>
                <ToastContainer position="top-center" />
                <h3>Agency Suggestions</h3>

                <h4>Insert New</h4>
                <input name="new-values" value={this.state.newValues} onChange={({ target: { name, value } }) => { this.setState({ newValues: value }) }} />
                <button onClick={() => {this.insertNew()}}>Insert New</button>

                <h4>Existing Records</h4>

                <Table condensed striped responsive bordered>
                    <thead>
                        <tr>
                            <th>Domain</th>
                            <th>Contact</th>
                            <th>Email</th>
                            <th>Org</th>
                        </tr>
                    </thead>
                    <tbody>
                        { this.state.results.map((res, i) => {
                            return (
                                <tr>
                                    <td>{res.domain}</td>
                                    <td>{res.name}</td>
                                    <td>{res.email}</td>
                                    <td>{res.org}</td>
                                </tr>
                            )
                        }) }
                    </tbody>
                </Table>
                <AsyncOverlay show={this.state.loading} />
            </Wrapper>
        );
    }

}

const Wrapper = styled.div`

    button:not(.option-btn) {
        background: ${theme.colours.blue2};
        border: none;
        padding: 12px;
        marginBottom: 20px;
        color: white;
    }

    select {
        padding: 12px;
        margin-left: 20px;
    }
 
    table {

        img {
            width: 64px;
            height: 64px;
            border-radius: 100%;
        }

        input:not([type="checkbox"]) {
            width: 80px;
            max-width: 100%;
        }
    }

`;
