import * as unstated from '../unstated-fork/unstated';

export default class StateManager {
    static containers = new Map<string, any>();

    static hook = () => {
        (unstated as any).__SUPER_SECRET_CONTAINER_DEBUG_HOOK__((container) => {
            StateManager.containers.set(container.constructor.name, container);
        });
    };

    static get<T>(container: Function): T {
        return StateManager.containers.get(container.name);
    }
}
