/* eslint-disable */
import React from 'react';
import '../../css/InlineSelect.css';

interface InlineSelectOption {
    id?: any,
    label: string,
    icon?: any,
}

interface InlineSelectProps {
    onSelect: (string) => void;
    selected: string;
    options: any;
    id?: string;
    oneLine?: boolean;
    hideUnselectedOptions?: boolean;
}

const InlineSelect: React.FC<InlineSelectProps> = ({ onSelect, selected, options, id, hideUnselectedOptions, oneLine}) => {

    // const [drawerTitle, setDrawerTitle] = useState <string> ("Editing 1 event");  // Title of the drawer indicating what is being edited

    return (
        <div className="inline-select" id={id} data-one-line={oneLine ? "true" : "false"}>
            { options.map((item, i) => {
                let opt = item as InlineSelectOption;
                if (hideUnselectedOptions && !((typeof opt.id === "object") ? opt.id.indexOf(selected) !== -1 : opt.id === selected.toString())) {
                    return null;
                }
                return (
                    <div className='inline-select-option' data-id={typeof opt.id === "object" ? opt.id[0] : opt.id} key={i} onClick={() => { onSelect(typeof opt.id === "object" ? opt.id[0] : opt.id) }} data-selected={ (typeof opt.id === "object") ? opt.id.indexOf(selected) !== -1 : opt.id === selected.toString() }>
                        { (opt.icon) && opt.icon }
                        <label>{opt.label}</label>
                    </div>
                )
            }) }
        </div>
    );
    
};

export default InlineSelect;
