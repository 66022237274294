import React from 'react';

function SvgAddcontact(props) {
    return (
        <svg height="1em" viewBox="0 0 512 512" width="1em" {...props}>
            <path
                d="M512 256c0 141.387-114.613 256-256 256-37.742 0-73.582-8.172-105.836-22.832h-.012a255.222 255.222 0 01-28.222-15.047 256.11 256.11 0 01-19.864-13.562C40.07 413.844 0 339.602 0 256 0 114.613 114.613 0 256 0s256 114.613 256 256zm0 0"
                fill="#ffe152"
            />
            <path
                d="M512 256c0 141.387-114.613 256-256 256-37.742 0-73.582-8.172-105.836-22.832h-.012a255.222 255.222 0 01-28.222-15.047 256.11 256.11 0 01-19.864-13.562C40.07 413.844 0 339.602 0 256 0 114.613 114.613 0 256 0s256 114.613 256 256zm0 0"
                fill="#ffe152"
            />
            <path
                d="M382.121 449.574v29.25a254.035 254.035 0 01-83.906 29.703 256.011 256.011 0 01-19.563 2.477c-4.105.367-8.242.629-12.414.785-3.394.145-6.812.207-10.238.207-7.648 0-15.203-.332-22.676-.992a254.505 254.505 0 01-103.445-32.18v-29.25c0-44.008 29.887-81.039 70.48-91.898a94.185 94.185 0 0116.875-2.914c2.57-.211 5.16-.313 7.786-.313h61.953c6.925 0 13.675.73 20.175 2.14a79.904 79.904 0 014.387 1.055c.031.012.055.012.086.024a94.681 94.681 0 0129.54 13.707c24.753 17.18 40.96 45.797 40.96 78.2zm0 0"
                fill="#3d4159"
            />
            <path
                d="M311.852 356.59l-.23 1.078-32.97 153.336c-7.46.66-15.023.992-22.652.992-7.648 0-15.203-.332-22.676-.992L200.36 357.676l-.23-1.086zm0 0"
                fill="#66baed"
            />
            <path
                d="M281.535 388.773l-25.539 40.961-25.539-40.96"
                fill="#f74f4f"
            />
            <path
                d="M279.574 510.914A255.808 255.808 0 01256 512c-7.953 0-15.82-.367-23.582-1.078L256 419.078zm0 0"
                fill="#f74f4f"
            />
            <path
                d="M255.988 393.395l-42.828 23.8-13.031-60.601 26.613-15.676M255.988 393.395l42.832 23.8 13.028-60.601-26.614-15.676"
                fill="#2ba1cf"
            />
            <path
                d="M341.16 366.152l-27.691 72.223h13.586l-28.84 70.152a256.011 256.011 0 01-19.563 2.477c-4.105.367-8.242.629-12.414.785l45.614-160.422zm0 0M170.508 366.152l27.687 72.223h-13.582l28.84 70.152a255.662 255.662 0 0019.559 2.477c4.105.367 8.246.629 12.414.785l-45.297-159.367zm0 0"
                fill="#2a2f4f"
            />
            <path
                d="M289.938 317.8v41.657c0 15.25-10.055 28.14-23.907 32.422a33.821 33.821 0 01-10.043 1.516c-18.746 0-33.937-15.192-33.937-33.938v-41.656zm0 0"
                fill="#eab599"
            />
            <path
                d="M289.938 356.82v2.637c0 15.25-10.055 28.14-23.907 32.422-12.496-3.84-21.89-14.707-23.625-28 2.266.137 4.547.207 6.836.207 6.422 0 12.719-.508 18.848-1.516a115.622 115.622 0 0021.847-5.75zm0 0M162.887 249.535c0 15.711-11.48 28.742-26.512 31.16a31.64 31.64 0 01-5.055.403c-17.437 0-31.566-14.125-31.566-31.563s14.129-31.566 31.566-31.566c1.723 0 3.414.14 5.055.406 15.031 2.418 26.512 15.445 26.512 31.16zm0 0"
                fill="#f6caae"
            />
            <path
                d="M162.887 249.535c0 15.711-11.48 28.742-26.512 31.16a117.374 117.374 0 01-4.188-31.16c0-10.785 1.458-21.234 4.188-31.16 15.031 2.418 26.512 15.445 26.512 31.16zm0 0"
                fill="#eab599"
            />
            <path
                d="M142.602 249.535c0 6.23-5.051 11.281-11.282 11.281-6.23 0-11.28-5.05-11.28-11.28 0-6.231 5.05-11.282 11.28-11.282 6.23 0 11.282 5.05 11.282 11.281zm0 0"
                fill="#eab599"
            />
            <path
                d="M349.102 249.535c0 15.711 11.476 28.742 26.507 31.16 1.645.266 3.332.403 5.059.403 17.437 0 31.562-14.125 31.562-31.563s-14.125-31.566-31.562-31.566c-1.727 0-3.414.14-5.059.406-15.03 2.418-26.507 15.445-26.507 31.16zm0 0"
                fill="#f6caae"
            />
            <g fill="#eab599">
                <path d="M349.102 249.535c0 15.711 11.476 28.742 26.507 31.16a117.376 117.376 0 004.192-31.16c0-10.785-1.457-21.234-4.192-31.16-15.03 2.418-26.507 15.445-26.507 31.16zm0 0" />
                <path d="M373.438 188.04v44.722c0 58.441-42.696 106.925-98.598 115.922l-.574.093c-5.946.95-12.047 1.422-18.278 1.422-64.855 0-117.433-52.582-117.433-117.437v-44.723c0-13.656 2.328-26.781 6.632-38.973 16.063-45.707 59.614-78.472 110.801-78.472H256c6.219 0 12.328.48 18.285 1.422 56.176 8.777 99.152 57.382 99.152 116.023zm0 0" />
                <path d="M391.95 249.535c0 6.23-5.052 11.281-11.282 11.281s-11.281-5.05-11.281-11.28c0-6.231 5.05-11.282 11.281-11.282s11.281 5.05 11.281 11.281zm0 0" />
            </g>
            <path
                d="M373.438 188.04v44.722c0 58.441-42.696 106.925-98.598 115.922l-.574.093c-56.176-8.777-99.141-57.386-99.141-116.015v-44.723c0-58.64 42.977-107.246 99.16-116.023 56.176 8.777 99.152 57.382 99.152 116.023zm0 0"
                fill="#f6caae"
            />
            <path
                d="M230.984 225.922c0 7.36-5.964 13.32-13.324 13.32-7.355 0-13.32-5.96-13.32-13.32s5.965-13.324 13.32-13.324c7.36 0 13.324 5.964 13.324 13.324zm0 0M307.652 225.922c0 7.36-5.964 13.32-13.324 13.32-7.355 0-13.32-5.96-13.32-13.32s5.965-13.324 13.32-13.324c7.36 0 13.324 5.964 13.324 13.324zm0 0"
                fill="#3d4159"
            />
            <path
                d="M228.266 223.57a5.706 5.706 0 11-11.414 0 5.706 5.706 0 1111.414 0zm0 0M305.59 223.57a5.71 5.71 0 01-11.418 0 5.71 5.71 0 0111.418 0zm0 0"
                fill="#fff"
            />
            <path
                d="M269.316 254.242h-26.644a5.746 5.746 0 01-5.746-5.75 5.746 5.746 0 015.746-5.746h26.644a5.746 5.746 0 015.746 5.746c0 3.176-2.57 5.75-5.746 5.75zm0 0M255.996 290.602c-9.836 0-19.668-2.465-28.633-7.391a5.224 5.224 0 115.032-9.16c14.777 8.125 32.421 8.125 47.199 0a5.224 5.224 0 115.031 9.16c-8.96 4.926-18.797 7.39-28.629 7.39zm0 0"
                fill="#eab599"
            />
            <path
                d="M373.438 147.453v45.813h-21.86V157.39l-157.781-29.32-33.383-6.208v71.403h-21.86v-45.813c0-52.223 42.329-94.555 94.544-94.555h45.797c1.242 0 2.484.024 3.73.086 50.477 1.942 90.813 43.489 90.813 94.47zm0 0"
                fill="#2a2f4f"
            />
            <path
                d="M373.438 147.453v45.813h-21.86V157.39l-157.781-29.32c8.695-41.755 44.922-73.395 88.828-75.087 50.477 1.942 90.813 43.489 90.813 94.47zm0 0"
                fill="#3d4159"
            />
            <path
                d="M155.352 401.605v31.543c0 5.391-3.329 10.008-8.047 11.899a12.692 12.692 0 01-4.778.926c-7.078 0-12.82-5.746-12.82-12.825v-31.543c0-7.082 5.742-12.82 12.82-12.82a12.764 12.764 0 019.067 3.754 12.793 12.793 0 013.758 9.066zm0 0"
                fill="#eab599"
            />
            <path
                d="M155.352 401.605v55.82c0 5.391-3.329-14.269-8.047-12.378-4.715-1.89-8.043-6.508-8.043-11.899v-31.543c0-5.39 3.328-10.007 8.043-11.902a12.744 12.744 0 014.289 2.836 12.793 12.793 0 013.758 9.066zm0 0"
                fill="#f6caae"
            />
            <path
                d="M155.355 443.602v29.746a26.578 26.578 0 01-5.19 15.82 255.528 255.528 0 01-48.099-28.61v-16.956c0-14.711 11.934-26.645 26.645-26.645a26.537 26.537 0 0118.84 7.805c4.816 4.828 7.804 11.484 7.804 18.84zm0 0"
                fill="#eab599"
            />
            <path
                d="M155.355 443.602v29.746a26.578 26.578 0 01-5.19 15.82h-.013a255.222 255.222 0 01-28.222-15.047c-.012-.262-.012-.512-.012-.773v-29.746c0-11.204 6.918-20.793 16.719-24.735a26.537 26.537 0 018.914 5.895c4.816 4.828 7.804 11.484 7.804 18.84zm0 0"
                fill="#f6caae"
            />
            <path
                d="M512 92.66c0 51.176-41.484 92.66-92.66 92.66-51.176 0-92.66-41.484-92.66-92.66C326.68 41.484 368.164 0 419.34 0 470.516 0 512 41.484 512 92.66zm0 0"
                fill="#3fb777"
            />
            <path d="M399.867 43.09h38.945v99.14h-38.945zm0 0" fill="#f2f0ec" />
            <path d="M369.77 73.188h99.14v38.945h-99.14zm0 0" fill="#f2f0ec" />
        </svg>
    );
}

export default SvgAddcontact;
