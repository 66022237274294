/* eslint-disable */
import React from 'react';
import styled from 'styled-components';
import { Table, Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { toast } from 'react-toastify';

import AdminApi from '../../api/admin/Admin';
import CompanyApi from '../../api/company/Company';
import { UserVerificationPendingResult } from '../../api/verification/ResponseTypes';
import { AsyncOverlay } from '../../components/ui-components/AsyncOverlay';
import { CompanyRoles } from '../../api/company/ResponseTypes';
import { ProfileImage } from '../../components/ui-components/ProfileImage';
import ProfileApi from '../../api/profile/Profile';
import OrganisationApi from '../../api/organisation/Organisation';
import { UserType } from '../../state-containers/user-type.container';
import theme from '../../css/theme';
import { RatingRecord } from 'src/api/ratings/RequestTypes';

interface State {
    loading: boolean;
    results: RatingRecord[];
    highlighted: number | null;
}

export default class Moderation extends React.Component<{}, State> {
    state = {
        loading: true,
        results: [],
        highlighted: null
    } as State;

    reload(overwrite?: boolean) {
        this.setState({
            loading: true
        }, () => { 
            AdminApi.getReportedRatings().then((results) => {
                this.setState({
                    results: results,
                    loading: false
                }, () => {
                    if (window.location.href.includes('?id=')) {
                        let id = window.location.href.split('?id=')[1];
                        this.setState({
                            highlighted: parseInt(id)
                        })
                        const el = document.querySelector(`.rating-row[data-id="${id}"]`);
            
                        if (el) {
                            el.scrollIntoView();
                            setTimeout(() => {
                                this.setState({
                                    highlighted: null
                                })
                            }, 3000)
                        }   
                    }
                });
            });
        })

    }

    componentDidMount() {
        this.reload();
    }

    render() {
        return (
            <Wrapper>
                <h3>Ratings</h3>
                <div style={{
                    marginBottom: 20
                }}>
                    <button onClick={() => { this.componentDidMount(); }}>Reload Data</button>
                </div>
                <Table condensed striped responsive bordered>
                    <thead>
                        <tr>
                            <th style={{ width: 85 }}>Rater Name</th>
                            <th style={{ width: 85 }}>Rated Org</th>
                            <th style={{ width: 85 }}>Rated Name</th>
                            <th style={{ width: 85 }}>Reporter Name</th>
                            <th>Review</th>
                            <th style={{ width: 60 }}>Stars</th>
                            <th style={{ width: 72 }}>Options</th>
                        </tr>
                    </thead>
                    {this.renderResults(this.state.results)}
                </Table>
                <AsyncOverlay show={this.state.loading} />
            </Wrapper>
        );
    }

    moderate(i: number, ratingId: number, approve: boolean, publicComment: string) {
        let res = this.state.results;
        res.splice(i, 1);
        this.setState({
            results: res
        })
        
        AdminApi
            .moderateRating(ratingId, approve, publicComment)
            .then(data => {
                toast.success("Rating updated")
            }).catch(e => {
                toast.error("Failed to update rating");
            })
    }

    renderResults(results) {
        if (results) {
            return (
                <tbody>
                    { results.map((item, i) => {
                        return (
                            <tr
                                className='rating-row' data-id={item.id}
                                style={{
                                    background: this.state.highlighted === item.id ? '#ffea52' : ''
                                }}
                            >
                                <td style={{ width: 85, textAlign: 'center'}}> 
                                    { (item.raterUserId) &&
                                        <img src={ProfileApi.getProfileImageUrl(item.raterUserId)} style={{ width: 30, height: 30, borderRadius: 15, display: 'block', margin: 'auto' }} />
                                    }
                                    <span>{item.raterFirstName} {item.raterLastName}</span>
                                </td>
                                <td style={{ width: 85, textAlign: 'center' }}>
                                    { (item.recipientOrganisationId) &&
                                        <img src={CompanyApi.getCompanyProfileImageUrl(item.recipientOrganisationId)} style={{ width: 30, height: 30, borderRadius: 15, display: 'block', margin: 'auto' }} />
                                    }
                                    <span>{item.recipientOrganisationName}</span>
                                </td>
                                <td style={{ width: 85, textAlign: 'center' }}>
                                    { (item.recipientUserId) &&
                                        <img src={ProfileApi.getProfileImageUrl(item.recipientUserId)} style={{ width: 30, height: 30, borderRadius: 15, display: 'block', margin: 'auto' }} />
                                    }
                                    <span>{item.recipientFirstName} {item.recipientLastName}</span>
                                </td>
                                <td style={{ width: 85, textAlign: 'center' }}>
                                    { (item.reportedByUserId) &&
                                        <img src={ProfileApi.getProfileImageUrl(item.reportedByUserId)} style={{ width: 30, height: 30, borderRadius: 15, display: 'block', margin: 'auto' }} />
                                    }
                                    <span>{item.reportedByUserFirstName} {item.reportedByUserLastName}</span>
                                </td>
                                <td>
                                    <textarea
                                        name="headline"
                                        placeholder=""
                                        onChange={(e) => {
                                            let res = this.state.results;
                                            res[i].publicComment = e.target.value;
                                            this.setState({
                                                results: res
                                            })
                                        }}
                                        value={item.publicComment}
                                        className="form-control"
                                        rows={3}
                                        style={{
                                            resize: "none"
                                        }}
                                    />
                                </td>
                                <td style={{ width: 60 }}>{item.stars}</td>
                                <td
                                    className="options"
                                    style={{ width: 72 }}
                                >
                                    <button 
                                        className="option-btn"
                                        onClick={() => {
                                            this.moderate(i, item.id, true, item.publicComment)
                                        }}
                                        style={{ background: '#28df99' }}
                                    >
                                        <i className="fas fa-thumbs-up"></i>
                                    </button>
                                    <button
                                        className="option-btn"
                                        onClick={() => {
                                            this.moderate(i, item.id, false, item.publicComment)
                                        }}
                                        style={{ background: '#DD2E1B' }}
                                    >
                                        <i className="fas fa-thumbs-down"></i>
                                    </button>
                                </td>
                            </tr>
                        )
                    }) }
                </tbody>
            )
        }
        return null;
    }

}

const Wrapper = styled.div`

    .options {

        button {
            width: 85%;
            height: 33px;
            border: none;
    
            &:first-child {
                margin-bottom: 8px;
            }
        }
    }
`;
