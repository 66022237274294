/* eslint-disable */
import { Container } from '../unstated-fork/unstated';
import ProfileApi from '../api/profile/Profile';
import SignalRService from '../services/signalr.service';
import { UserDto } from '../api/user/ResponseTypes';
import { UserThirdParty } from '../api/profile/ResponseTypes';
import { UserProfileDto } from '../api/profile/RequestTypes';

type ProfileVerificationState = {
    loaded: boolean;
    verified: boolean;
    submitted: boolean;
};

const SubmittedKey = 'ProfileVerification.Submitted';

export class ProfileVerificationContainer extends Container<
    ProfileVerificationState
> {
    state = {
        loaded: false,
        verified: false,
        submitted: false
    };

    constructor() {
        super();
        this.getVerificationState();

        /**
         * We never explicitly close this socket
         * Do we need to?
         *
         * TODO: Research performance impact here
         */
        const socket = SignalRService.create('activation');
        socket.on('AccountVerified', this.getVerificationState);
    }

    getVerificationState = () => {
        let localUser: UserProfileDto = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') || '')  : null;

        if (localUser && localUser.verified) {
            this.setState({
                loaded: true,
                verified: true,
                submitted: true
            });
        } else {
            ProfileApi.isUserVerified().then((verified) => {
                this.setState({
                    loaded: true,
                    verified: verified,
                    submitted: localStorage.getItem(SubmittedKey) === '1'
                });
            });
        }
    };

    tryVerify = async () => {
        const verified = await ProfileApi.tryVerifyUser();

        localStorage.setItem(SubmittedKey, '1');

        this.setState({
            verified,
            submitted: true
        });
    };
}
