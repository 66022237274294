import React from 'react';
import styled from 'styled-components';
import { Modal, Button } from 'react-bootstrap';

import { ProfileImage } from '../../components/ui-components/ProfileImage';
import TimelineEvents from '../../components/events/timeline-events';
import theme from '../../css/theme';
import ProfileApi from '../../api/profile/Profile';
import CompanyApi from '../../api/company/Company';
import { PendingEventSet } from '../../api/events/ResponseTypes';
import { AsyncOverlay } from '../../components/ui-components/AsyncOverlay';
import EventApi from '../../api/events/Events';

interface Props {
    eventSet: PendingEventSet | undefined;
    onClose: () => void;
    onConfirm: () => void;
}

interface State {
    saving: boolean;
}

export default class PendingEventsModal extends React.Component<Props, State> {
    state = {
        saving: false
    } as State;

    handleConfirm = () => {
        this.setState({
            saving: true
        });

        EventApi.acceptPendingEventSet(this.props.eventSet!.id).then(() => {
            this.props.onConfirm();
        });
    };

    render() {
        if (!this.props.eventSet) {
            return <></>;
        }

        const creatorImageUrl = ProfileApi.getProfileImageUrl(
            this.props.eventSet.metadata.createdById
        );
        const companyImageUrl = CompanyApi.getOrganisationProfileImageUrl(
            this.props.eventSet.metadata.createdByOrganisationId
        );

        const { metadata } = this.props.eventSet;

        return (
            <Modal
                show={!!this.props.eventSet}
                onHide={this.props.onClose}
                dialogClassName="modal-dialog-centered"
                onEntering={() => {
                    this.setState({ saving: false });
                }}
            >
                <Modal.Body>
                    <ModalContent className="layout vertical">
                        <h4>{this.props.eventSet.pendingEvents[0].title}</h4>
                        <div className="layout horizontal justified">
                            <label>Hirer</label>
                            <div
                                className="layout horizontal center"
                                style={{
                                    color: this.props.eventSet.metadata
                                        .createdByIsVerified
                                        ? theme.colours.green
                                        : theme.colours.red
                                }}
                            >
                                <span style={{ fontWeight: 'bold' }}>
                                    {this.props.eventSet.metadata
                                        .createdByIsVerified
                                        ? 'Verified'
                                        : 'Unverified'}{' '}
                                    hirer
                                </span>
                                <i
                                    className="fa fa-shield m-l-xs"
                                    style={{ fontSize: '20px' }}
                                />
                            </div>
                        </div>
                        <div className="layout horizontal center justified">
                            <div>
                                <span>{metadata.createdByFullName}</span>
                                <br />
                                {metadata.createdByOrganisationName}
                            </div>
                            <div>
                                <ProfileImage
                                    selectable={false}
                                    url={creatorImageUrl}
                                    size={54}
                                />
                                <ProfileImage
                                    selectable={false}
                                    url={companyImageUrl}
                                    size={54}
                                />
                            </div>
                        </div>
                        <TimelineEvents
                            title={`${metadata.createdByOrganisationName} - ${this.props.eventSet.pendingEvents[0].title}`}
                            events={this.props.eventSet.pendingEvents}
                            maxHeight="calc(100vh - 350px)"
                        />
                        <AsyncOverlay
                            show={this.state.saving}
                            text="Saving.."
                        />
                    </ModalContent>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        autoFocus
                        style={{ float: 'left' }}
                        onClick={this.props.onClose}
                    >
                        Close
                    </Button>
                    {this.props.eventSet.pendingEvents.some(
                        (ev) => !ev.addedToSchedule
                    ) && (
                        <Button
                            bsStyle="primary"
                            onClick={this.handleConfirm}
                            disabled={this.props.eventSet.accepted}
                        >
                            Add to schedule
                        </Button>
                    )}
                </Modal.Footer>
            </Modal>
        );
    }
}

const ModalContent = styled.div`
    position: relative;
    height: auto;

    label {
        margin: 10px 0 5px 0;
    }
`;
