/* eslint-disable */
export async function bulkPut(values) {
    if (!window.localIndexedDb) return null;
    await window.localIndexedDb.syncCache.bulkPut(values);
};

export async function getAll() {
    if (!window.localIndexedDb) return [];
    let rows = await window.localIndexedDb.syncCache.toArray();
    return rows;
}