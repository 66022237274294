/* eslint-disable */
import React from 'react';
import '../../css/People.css';
import CompanyApi from '../../api/company/Company';
import Utilities from 'src/Utilities';
import ChatBotApi from 'src/api/chatbot/ChatBot';
import { Badge, Checkbox, FormControlLabel, ToggleButtonGroup, ToggleButton, CircularProgress } from '@mui/material';
import TimePresetSlider from '../availability/TimePresetSlider';
import theme from 'src/css/theme';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { SearchParams, State } from './People';
import FullScreenLoader from '../ui-components/FullScreenLoader';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';

class PeopleFilter extends React.Component<
    State & {
        openFilterDropdown: (dropdown: "sort" | "timepreset" | "locations" | "attributes" | "representations" | null) => void;
        filter: (searchParams: SearchParams) => void;
        setView: (view: "collapsed" | "hovered" | "expanded") => void;
        setSkillsFilter: (filter: string) => void;
        setSkillsSearchType: (type: "AND" | "OR") => void;
        setRepresentationsFilter: (filter: string) => void;
        setRepresentationsSearchType: (type: "AND" | "OR") => void;
        onDayCountChange: (days: number) => void;
        updateFilters: boolean;
    },
    {
        searchParams: SearchParams;
        loadingMessage: string | null;
        aiPromptModal: boolean;
        aiPromptModalText: string;
    }
> {
    datesRef: React.RefObject<HTMLDivElement> = React.createRef();
    peopleRef: React.RefObject<HTMLDivElement> = React.createRef();
    scrollChecking: boolean = false;
    inputDebounce: any = null;
    startTime: number = 0;
    state = {
        searchParams: {
            ...this.props.searchParams
        },
        loadingMessage: null,
        aiPromptModal: false,
        aiPromptModalText: ""
    }

    componentWillUpdate() {
        this.startTime = performance.now();
    }

    componentDidUpdate(prevProps: any) {
        const endTime = performance.now();
        // console.log('[Filter] Render time:', endTime - this.startTime);

        if (!prevProps.updateFilters && this.props.updateFilters) {
            console.log("Update PRops Filters")
            this.setState({
                searchParams: {
                    ...this.props.searchParams
                }
            });
        }
    }

    componentDidMount(): void {

    }

    getFiltersWithAI = async (text: string) => {
        this.setState({
            loadingMessage: "Applying Filters"
        })

        const lowerCased = text.toLowerCase();
        let agencies = this.props.userRepresentations.filter(a => lowerCased.includes(a.name.toLowerCase())).map(a => a.name.toLowerCase()); 
        let skillNames: string[] = [];
        let subsectorNames: string[] = [];
        let sectorNames: string[] = [];
        const presets = this.props.timePresets.filter(a => a).map(p => p.name!.toLowerCase());
        for (let i = 0; i < this.props.userAttributes.length; i++) {
            const attr = this.props.userAttributes[i];
            skillNames.push(attr.skillName.toLowerCase());
            if (attr.subSectorName) {
                subsectorNames.push(attr.subSectorName.toLowerCase());
            }
            if (attr.sectorName) {
                sectorNames.push(attr.sectorName.toLowerCase());
            }
        }
        let teams = this.props.teams.filter(t => lowerCased.includes(t.name.toLowerCase())).map(t => t.name.toLowerCase());
        let groups = [...new Set(this.props.groups.map(g => g.name.toLowerCase()) .filter(g => !g.startsWith("everyone sharing with")))];

        skillNames = [...new Set(skillNames)]
        subsectorNames = [...new Set(subsectorNames)]
        sectorNames = [...new Set(sectorNames)]
        console.log("__ REQ", {
            presets,
            skillNames,
            subsectorNames,
            sectorNames,
            agencies,
            teams,
            groups
        });

        const res = await ChatBotApi.getAvailabilityFilters({
            presets,
            skills: skillNames,
            subsectors: subsectorNames,
            sectors: sectorNames,
            agencies,
            teams,
            groups,
            text: lowerCased
        })

        console.log("___RES", res);

        let filters = this.state.searchParams;

        // aplying skills without sector and subsector
        if (res.skills && res.skills.length) {
            console.log(res.skills, this.props.userAttributes.map(a => a.skillName.toLowerCase()), "<<<<");
            filters.attributeIds = res.skills.map((skill: string) => {
                const skillName = skill.toLowerCase().trim();
                const skillMatch = this.props.userAttributes.find(attr => attr.skillName.toLowerCase().trim() === skillName);
                if (skillMatch) {
                    console.log("matched skills", skillMatch)
                    const compoundId = `${skillMatch.sectorId}-${skillMatch.subSectorId}-${skillMatch.skillId}`;
                    return compoundId;
                }
                return null;
            }).filter((id: string | null) => id !== null) as string[];
        } else if (res.subSectors && res.subSectors.length) {
            filters.attributeIds = res.subSectors.map((subSector: string) => {
                const subSectorName = subSector.toLowerCase();
                const subSectorMatches = this.props.userAttributes.filter(attr => attr.subSectorName && attr.subSectorName.toLowerCase() === subSectorName);
                if (subSectorMatches.length) {
                    console.log("matched subsector", subSectorMatches)
                    const compoundIds = subSectorMatches.map(subSectorMatch => `${subSectorMatch.sectorId}-${subSectorMatch.subSectorId}-${subSectorMatch.skillId}`);
                    return compoundIds;
                }
                return null;
            }).flat().filter(id => id !== null) as string[];
        } else if (res.sectors && res.sectors.length) {
            filters.attributeIds = res.sectors.map((sector: string) => {
                const sectorName = sector.toLowerCase();
                const sectorMatches = this.props.userAttributes.filter(attr => attr.sectorName && attr.sectorName.toLowerCase() === sectorName);
                if (sectorMatches.length) {
                    console.log("matched sector", sectorMatches)
                    const compoundIds = sectorMatches.map(sectorMatch => `${sectorMatch.sectorId}-${sectorMatch.subSectorId}-${sectorMatch.skillId}`);
                    return compoundIds;
                }
            }).flat().filter(id => id !== null) as string[];
        }

        // Applying preset or custom time
        if (res.preset) {
            filters.timePreset = this.props.timePresets.find(p => p.name!.toLowerCase() === res.preset.toLowerCase()) || this.props.timePresets[0];
            filters.customTimePreset = null;
        } else if (res.times && res.times.length) {
            const times = res.times.map((time: string) => {
                // split on : and -
                const parts = time.split(/:|-/);
                return {
                    startHour: parseInt(parts[0]),
                    startMinute: parseInt(parts[1]),
                    endHour: parseInt(parts[2]),
                    endMinute: parseInt(parts[3])
                }
            })
            filters.customTimePreset = times[0];
        }

        // applying representing agencies
        filters.representingAgencies = agencies.map((agency: string) => {
            const agencyMatch = this.props.userRepresentations.find(a => a.name.toLowerCase() === agency.toLowerCase());
            if (agencyMatch) {
                console.log("Matched agency", agencyMatch)
                return agencyMatch.id;
            }
            return null;
        }).filter((id: string | null) => id !== null) as string[];

        if (res.group) {
            // applying group
            const group = this.props.groups.find(g => g.name.toLowerCase() === res.group.toLowerCase());
            if (group) {
                filters.groupId = group.id;
                filters.teamId = group.teamId;
            }
        } else if (res.team) {
            // applying team
            const team = this.props.teams.find(t => t.name.toLowerCase() === res.team.toLowerCase());
            if (team) {
                filters.teamId = team.id;
                filters.groupId = team.id; // default everyone group has same id as team
            }
        } else {
            filters.teamId = 1;
            filters.groupId = 1;
        }

        // applying sort order
        // applying most available days
        if (res.dates && res.dates.length) {
            filters.sortType = "most-available";
            filters.mostAvailbleDates = res.dates;
        } else {
            filters.sortType = res.sortOrder ? (res.sortOrder === "MOST-AVAILABLE" ? "most-available" : res.sortOrder === "DISTANCE" ? "distance" : res.sortOrder === "ALPHABETICAL" ? "name" : res.sortOrder === "LAST-UPDATED" ? "last-updated" : res.sortOrder === "MY-RATING" ? "own-rating" : "overall-rating") : "last-updated";
            filters.mostAvailbleDates = [];
        }

        // no option to filter for name, would need to make this AND or OR as well instead of single sting entty
        filters.name = "";
        filters.globalSearch = "";

        this.props.setSkillsSearchType("OR") // todo maybe ask or imply
        this.props.setRepresentationsSearchType("OR") // todo maybe ask or imply

        // filters.teamId = teams.length ? teams[0].teamId : 1;
        // filters.groupId = teams.length ? teams[0].id : 1;

        console.log("__ new filters from ai: ", filters);

        toast.success("Filters Applied");

        this.setState({
            searchParams: filters,
            loadingMessage: null,
        }, () => {
            this.props.filter(this.state.searchParams);
        });
    }

    public render() {
        const numberOfAvailabilityDays = this.props.view === "expanded" ? this.props.expandedDaysToDisplay : this.props.view === "hovered" ? 6 : 0;
        const startDay = new Date();
        const dayHashRange = [...Array(numberOfAvailabilityDays).keys()].map((i) => {
            return Utilities.formatDate(Utilities.dateAdd(startDay, "day", i), "YYYY-MM-DD");
        });

        return (
            <div className='filters'>
                { (this.props.view === "collapsed") &&
                    <button onClick={() => {
                        this.props.setView("expanded");
                    }}>
                        <i className="fas fa-search"></i>
                    </button>
                }
                { (this.props.view === "hovered" || this.props.view === "expanded") &&
                    <React.Fragment>
                        <button onClick={() => {
                            this.props.setView(this.props.view === "expanded" ? "collapsed" : "expanded");
                        }}>
                            <i className={"fas " + (this.props.view === "hovered" ? "fa-arrows-alt-h" : "fa-times")}></i>
                        </button>
                        <button onClick={() => {
                            if (this.props.view !== "expanded") {
                                this.props.setView("expanded");
                            }
                            this.setState({
                                aiPromptModal: true,
                            })
                        }}>
                            <i className="fa-solid fa-wand-magic-sparkles"></i>
                        </button>
                        <div className="filter timepreset">
                            <h3>
                                <i className="fas fa-clock"></i>
                                <span>Preset / Period</span>
                            </h3>
                            <div className='simple-select'>
                                <select 
                                    onClick={(e) => {
                                        if (this.state.searchParams.customTimePreset) {
                                            e.preventDefault();
                                            // @ts-ignore
                                            e.target.blur();
                                            this.props.openFilterDropdown("timepreset");
                                        }
                                    }}
                                    value={this.state.searchParams.customTimePreset ? 0 : this.state.searchParams.timePreset.id}
                                    onChange={(e) => {
                                        const preset = this.props.timePresets.find(p => p.id === e.target.value);
                                        if (preset) {
                                            this.setState({
                                                searchParams: {
                                                    ...this.state.searchParams,
                                                    timePreset: preset,
                                                    customTimePreset: null
                                                },
                                            }, () => {
                                                this.props.openFilterDropdown(null);
                                                this.props.filter(this.state.searchParams);
                                            })
                                        } else {
                                            this.setState({
                                                searchParams: {
                                                    ...this.state.searchParams,
                                                    customTimePreset: {
                                                        startHour: 4,
                                                        endHour: 22,
                                                        startMinute: 0,
                                                        endMinute: 0
                                                    }
                                                }
                                            }, () => {
                                                this.props.openFilterDropdown("timepreset");
                                                this.props.filter(this.state.searchParams);
                                            });
                                        }
                                    }}
                                >
                                    {this.props.timePresets.map((preset, i) => {
                                        return (
                                            <option value={preset.id}>
                                                {(preset.startHour === 0 ? "00" : preset.startHour < 10 ? ("0" + preset.startHour) : preset.startHour)}:
                                                {(preset.startMinute === 0 ? "00" : preset.startMinute < 10 ? ("0" + preset.startMinute) : preset.startMinute)} - 
                                                {(preset.endHour === 0 ? "00" : preset.endHour < 10 ? ("0" + preset.endHour) : preset.endHour)}:
                                                {(preset.endMinute === 0 ? "00" : preset.endMinute < 10 ? ("0" + preset.endMinute) : preset.endMinute)}{' '}
                                                [{preset.name}]
                                            </option>
                                        )
                                    }) }
                                    <option value={0}>
                                        { (this.state.searchParams.customTimePreset) &&
                                            (this.state.searchParams.customTimePreset.startHour === 0 ? "00" : this.state.searchParams.customTimePreset.startHour < 10 ? ("0" + this.state.searchParams.customTimePreset.startHour) : this.state.searchParams.customTimePreset.startHour) + ":" + 
                                            (this.state.searchParams.customTimePreset.startMinute === 0 ? "00" : this.state.searchParams.customTimePreset.startMinute < 10 ? ("0" + this.state.searchParams.customTimePreset.startMinute) : this.state.searchParams.customTimePreset.startMinute) + "-" +
                                            (this.state.searchParams.customTimePreset.endHour === 0 ? "00" : this.state.searchParams.customTimePreset.endHour < 10 ? ("0" + this.state.searchParams.customTimePreset.endHour) : this.state.searchParams.customTimePreset.endHour) + ":" + 
                                            (this.state.searchParams.customTimePreset.endMinute === 0 ? "00" : this.state.searchParams.customTimePreset.endMinute < 10 ? ("0" + this.state.searchParams.customTimePreset.endMinute) : this.state.searchParams.customTimePreset.endMinute) + " "
                                        }
                                        [Custom]
                                    </option>
                                </select>
                            </div>
                            { (this.props.filterDropdown === "timepreset" && this.state.searchParams.customTimePreset) &&
                                <div className='filter-dropdown'>
                                    <div className='header'>
                                        <h3>Set the required start, and end time by adjusting the below slider</h3>
                                    </div>
                                    <TimePresetSlider
                                        compactDrag={true}
                                        overnight={ this.state.searchParams.customTimePreset.startHour > this.state.searchParams.customTimePreset.endHour}
                                        values={[this.state.searchParams.customTimePreset.startHour + this.state.searchParams.customTimePreset.startMinute / 60, this.state.searchParams.customTimePreset.endHour + this.state.searchParams.customTimePreset.endMinute / 60]}
                                        onFinalChange={(values) => {
                                            let customPreset: any = { };
                                            customPreset!.startHour = Math.floor(values[0]);
                                            customPreset!.startMinute = Utilities.getDecimal(values[0]) * 60;
                                            customPreset!.endHour = Math.floor(values[1]);
                                            customPreset!.endMinute = Utilities.getDecimal(values[1]) * 60;

                                            customPreset.startMinute = Math.round(customPreset.startMinute);
                                            customPreset.endMinute = Math.round(customPreset.endMinute);

                                            customPreset!.startTime = 
                                                (customPreset.startHour < 10 ? "0" + customPreset.startHour : customPreset.startHour) + 
                                                ":" +
                                                (customPreset.startMinute < 10 ? "0" + customPreset.startMinute : customPreset.startMinute);
                                            customPreset!.endTime = 
                                                (customPreset.endHour < 10 ? "0" + customPreset.endHour : customPreset.endHour) + 
                                                ":" +
                                                (customPreset.endMinute < 10 ? "0" + customPreset.endMinute : customPreset.endMinute);

                                            this.setState({
                                                searchParams: {
                                                    ...this.state.searchParams,
                                                    customTimePreset: customPreset
                                                }
                                            }, () => {
                                                this.props.filter(this.state.searchParams);
                                            })
                                        }}
                                        onChange={(values) => {

                                        }}
                                    />
                                    <div className='buttons' style={{ display: "flex" }}>
                                        <button className='reset' style={{ background: theme.colours.red2 }} onClick={() => {
                                            this.setState({
                                                searchParams: {
                                                    ...this.state.searchParams,
                                                    customTimePreset: null
                                                },
                                            }, () => {
                                                this.props.openFilterDropdown(null);
                                                this.props.filter(this.state.searchParams);
                                            })
                                        }}>Back to Presets</button>
                                        <button onClick={() => {
                                            this.props.openFilterDropdown(null);
                                        }}>Apply & Close</button>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="filter timepreset">
                            <h3>
                                <i className="fas fa-infinity"></i>
                                <span>Global Search</span>
                            </h3>
                            <div className="simple-input">
                                <input
                                    type="text"
                                    value={this.state.searchParams.globalSearch}
                                    id="search-by-all-input"
                                    onChange={({ target: { name, value } }) => {
                                        let searchParams = this.state.searchParams;
                                        searchParams.globalSearch = value;
                                        this.setState({
                                            searchParams: searchParams
                                        })
                                        clearInterval(this.inputDebounce);
                                        this.inputDebounce = setTimeout(() => { this.props.filter(this.state.searchParams); }, 500);
                                    }}
                                    placeholder="Search in all Fields"
                                />
                            </div>
                        </div>
                        <div className="filter timepreset">
                            <h3>
                                <i className="fas fa-infinity"></i>
                                <span>Order By</span>
                            </h3>
                            <div className='simple-select'>
                                <select
                                    value={this.state.searchParams.sortType}
                                    onChange={(e) => {
                                        this.setState({
                                            searchParams: {
                                                ...this.state.searchParams,
                                                sortType: e.target.value as "distance" | "overall-rating" | "own-rating" | "most-available" | "last-updated" | "name",
                                                mostAvailbleDates: e.target.value !== "most-available" ? [] : [0, 1, 2, 3, 4, 5, 6].map((i) => {
                                                    return Utilities.formatDate(Utilities.dateAdd(new Date(), "day", i), "YYYY-MM-DD");
                                                })
                                            }
                                        }, () => {
                                            this.props.filter(this.state.searchParams);
                                        });
                                    }}
                                >
                                    {[
                                        { id: "last-updated", name: "Last Updated" },
                                        { id: "name", name: "Name" },
                                        { id: "overall-rating", name: "Overall Rating" },
                                        { id: "own-rating", name: "My Rating" },
                                        { id: "distance", name: "Distance" },
                                        { id: "most-available", name: "Most Available" }
                                    ].map((sort, i) => {
                                        return (
                                            <option value={sort.id}>
                                                {sort.name}
                                            </option>
                                        )
                                    }) }
                                </select>
                            </div>
                        </div>
                        <div className="filter timepreset">
                            <h3>
                                <i className="fas fa-signature"></i>
                                <span>Name</span>
                            </h3>
                            <div className="simple-input">
                                <input
                                    type="text"
                                    value={this.state.searchParams.name}
                                    id="search-by-all-input"
                                    onChange={({ target: { name, value } }) => {
                                        let searchParams = this.state.searchParams;
                                        searchParams.name = value;
                                        this.setState({
                                            searchParams: searchParams
                                        })
                                        clearInterval(this.inputDebounce);
                                        this.inputDebounce = setTimeout(() => { this.props.filter(this.state.searchParams); }, 500);
                                    }}
                                    placeholder="Search by Name"
                                />
                            </div>
                        </div>
                        <div className="filter timepreset">
                            <h3>
                                <i className="fas fa-users"></i>
                                <span>Teams</span>
                            </h3>
                            <div className='simple-select'>
                                <select 
                                    onClick={(e) => {
                                        this.props.openFilterDropdown(null);
                                    }}
                                    value={this.state.searchParams.teamId}
                                    onChange={(e) => {
                                        this.setState({
                                            searchParams: {
                                                ...this.state.searchParams,
                                                teamId: parseInt(e.target.value),
                                                groupId: parseInt(e.target.value) // deault everyone group has same id as team
                                            }
                                        }, () => {
                                            this.props.filter(this.state.searchParams);
                                        })
                                    }}
                                >
                                    {this.props.teams.map((team, i) => {
                                        return (
                                            <option value={team.id}>
                                                {team.name}
                                            </option>
                                        )
                                    }) }
                                </select>
                            </div>
                        </div>
                        <div className="filter timepreset">
                            <h3>
                                <i className="far fa-folder"></i>
                                <span>Groups</span>
                            </h3>
                            <div className='simple-select'>
                                <select 
                                    onClick={(e) => {
                                        this.props.openFilterDropdown(null);
                                    }}
                                    value={this.state.searchParams.groupId}
                                    onChange={(e) => {
                                        this.setState({
                                            searchParams: {
                                                ...this.state.searchParams,
                                                groupId: parseInt(e.target.value) 
                                            }
                                        }, () => {
                                            this.props.filter(this.state.searchParams);
                                        })
                                    }}
                                >
                                    {this.props.groups.filter(g => g.teamId === this.state.searchParams.teamId).map((group, i) => {
                                        return (
                                            <option value={group.id}>
                                                {group.name}
                                            </option>
                                        )
                                    }) }
                                </select>
                            </div>
                        </div>
                        <div className="filter timepreset" onClick={() => {
                            if (this.props.filterDropdown !== "attributes") {
                                this.props.openFilterDropdown("attributes");
                            }
                        }}>
                            <div className='head' onClick={() => {
                                if (this.props.filterDropdown === "attributes") {
                                    this.props.openFilterDropdown(null);
                                }
                            }}>
                                <h3>
                                    <i className="fas fa-tag"></i>
                                    <span>Sectors, Skills</span>
                                </h3>
                                <div className="simple-tags">
                                    { this.state.searchParams.attributeIds.length === 0 ? "No Skills Selected" : (this.state.searchParams.attributeIds.length > 1 ? (this.state.searchParams.attributeIds.length + " Skills Selected") : "1 Skill Selected") }
                                </div>
                                </div>
                            { (this.props.filterDropdown === "attributes") &&
                                <div className='filter-dropdown'>
                                    <div className='header'>
                                        <h3>Select/Search Sectors, Subsectors, Skills</h3>
                                        <div className='sub-filters'>
                                            <input
                                                type="text"
                                                value={this.props.skillsFilter}
                                                id="search-by-all-input"
                                                onChange={({ target: { name, value } }) => {
                                                    this.props.setSkillsFilter(value.toLowerCase());
                                                }}
                                                placeholder="Search Skills"
                                            />
                                            <ToggleButtonGroup
                                                color="primary"
                                                value={this.props.skillsSearchType}
                                                exclusive
                                                onChange={(e, newType) => {
                                                    this.props.setSkillsSearchType(newType as "AND" | "OR");
                                                }}
                                            >
                                                <ToggleButton value="AND">AND</ToggleButton>
                                                <ToggleButton value="OR">OR</ToggleButton>
                                            </ToggleButtonGroup>
                                        </div>
                                    </div>
                                    <div className='attributes-wrapper'>
                                        { [... new Set(this.props.userAttributes.map(attr => attr.sectorId))].map((sectorId) => {
                                            const sectorName = this.props.userAttributes.find(attr => attr.sectorId === sectorId)?.sectorName;
                                            const anySkillsMatch = !this.props.skillsFilter || this.props.userAttributes.filter(attr => attr.sectorId === sectorId).some(attr => attr.skillName.toLowerCase().includes(this.props.skillsFilter));
                                        
                                            if (!anySkillsMatch) {
                                                return null;
                                            }

                                            return (
                                                <React.Fragment>
                                                    <h4>{sectorName || "General Sectors"}</h4>
                                                    { [...new Set(this.props.userAttributes.filter(attr => attr.sectorId === sectorId).map(s => s.subSectorId))].map((subSectorId) => {
                                                        const subSector = this.props.userAttributes.find(attr => attr.subSectorId === subSectorId);
                                                        if (!subSector) {
                                                            return null;
                                                        }

                                                        const anySkillsMatch = !this.props.skillsFilter || this.props.userAttributes.filter(attr => attr.subSectorId === subSectorId).some(attr => attr.skillName.toLowerCase().includes(this.props.skillsFilter));
                                                        if (!anySkillsMatch) {
                                                            return null;
                                                        }

                                                        return (
                                                            <React.Fragment>
                                                                <h5>{subSector.subSectorName || "General Sub-sectors"}</h5>
                                                                <div className='skills'>
                                                                    { [...new Set(this.props.userAttributes.filter(attr => attr.subSectorId === subSector.subSectorId).map(ss => ss.skillId))].map((skillId) => {
                                                                        const skill = this.props.userAttributes.find(attr => attr.skillId === skillId);
                                                                        if (!skill) {
                                                                            return null;
                                                                        }

                                                                        const match = !this.props.skillsFilter || skill.skillName.toLowerCase().includes(this.props.skillsFilter);
                                                                        if (!match) {
                                                                            return null;
                                                                        }
                                                                        
                                                                        const compoundId = `${sectorId}-${subSectorId}-${skillId}`;
                                                                        return (
                                                                            <div className='skill'>
                                                                                <FormControlLabel
                                                                                    label={<span>
                                                                                        <span>{skill.skillName}</span>
                                                                                        <Badge badgeContent={skill.userIds.length} color="primary"></Badge>
                                                                                    </span>}
                                                                                    control={
                                                                                        <Checkbox
                                                                                            checked={this.state.searchParams.attributeIds.includes(compoundId)}
                                                                                            sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                                                                                            onChange={(e) => {
                                                                                                let searchParams = this.state.searchParams;
                                                                                                if (e.target.checked) {
                                                                                                    searchParams.attributeIds.push(compoundId);
                                                                                                } else {
                                                                                                    searchParams.attributeIds = searchParams.attributeIds.filter(id => id !== compoundId);
                                                                                                }
                                                                                                this.setState({
                                                                                                    searchParams: searchParams
                                                                                                })
                                                                                            }}
                                                                                        />
                                                                                    }
                                                                                />
                                                                            </div>
                                                                        )
                                                                    }) }
                                                                </div>
                                                            </React.Fragment>
                                                        )
                                                    }) }
                                                </React.Fragment>
                                            )
                                        }) }
                                    </div>
                                    <div className='buttons'>
                                        <button onClick={() => {
                                            this.props.openFilterDropdown(null);
                                            this.props.filter(this.state.searchParams);
                                            // TODO NEED TO DETECT OUTCLICKS 
                                        }}>Apply & Close</button>
                                    </div>
                                </div>
                            }
                        </div>

                        <div className="filter timepreset" onClick={(e) => {
                            e.stopPropagation();
                            if (this.props.filterDropdown !== "representations") {
                                this.props.openFilterDropdown("representations");
                            }
                        }}>
                            <div className='head' onClick={() => {
                                if (this.props.filterDropdown === "representations") {
                                    this.props.openFilterDropdown(null);
                                }
                            }}>
                                <h3>
                                    <i className="fas fa-clock"></i>
                                    <span>Representing Agencies</span>
                                </h3>
                                <div className="simple-tags">
                                    { this.state.searchParams.representingAgencies.length === 0 ? "None Selected" : (this.state.searchParams.representingAgencies.length > 1 ? (this.state.searchParams.representingAgencies.length + " Selected") : "1 Selected") }
                                </div>
                            </div>
                            { (this.props.filterDropdown === "representations") &&
                                <div className='filter-dropdown'>
                                    <div className='header'>
                                        <h3>Select Represeting Agencies</h3>
                                        <div className='sub-filters'>
                                            <input
                                                type="text"
                                                value={this.props.representationsFilter}
                                                id="search-by-all-input"
                                                onChange={({ target: { name, value } }) => {
                                                    this.props.setRepresentationsFilter(value.toLowerCase());
                                                }}
                                                placeholder="Search Agencies"
                                            />
                                            <ToggleButtonGroup
                                                color="primary"
                                                value={this.props.representationsSearchType}
                                                exclusive
                                                onChange={(e, newType) => {
                                                    this.props.setRepresentationsSearchType(newType as "AND" | "OR");
                                                }}
                                            >
                                                <ToggleButton value="AND">AND</ToggleButton>
                                                <ToggleButton value="OR">OR</ToggleButton>
                                            </ToggleButtonGroup>
                                        </div>
                                    </div>
                                    <div className='representations-wrapper'>
                                        { this.props.userRepresentations.map((agency) => {
                                            const orgNameMatches = !this.props.representationsFilter || agency.name.toLowerCase().includes(this.props.representationsFilter);
                                            if (!orgNameMatches || !agency.id) {
                                                return null;
                                            }
                                            return (
                                                <div className='representing-agency' data-id={agency.id}>
                                                    <FormControlLabel
                                                        label={<span>
                                                            <LazyLoadImage
                                                                height={36}
                                                                src={CompanyApi.getOrganisationProfileImageUrl(agency.id)}
                                                                width={36}
                                                            />
                                                            <span>{agency.name}</span>
                                                            <Badge badgeContent={agency.userIds.length} color="primary"></Badge>
                                                        </span>}
                                                        control={
                                                            <Checkbox
                                                                checked={this.state.searchParams.representingAgencies.includes(agency.id)}
                                                                sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                                                                onChange={(e) => {
                                                                    let searchParams = this.state.searchParams;
                                                                    if (e.target.checked) {
                                                                        searchParams.representingAgencies.push(agency.id);
                                                                    } else {
                                                                        searchParams.representingAgencies = searchParams.representingAgencies.filter(id => id !== agency.id);
                                                                    }
                                                                    this.setState({
                                                                        searchParams: searchParams
                                                                    })
                                                                }}
                                                            />
                                                        }
                                                    />
                                                </div>
                                            )
                                        }) }
                                    </div>
                                    <div className='buttons'>
                                        <button onClick={() => {
                                            this.props.openFilterDropdown(null);
                                            this.props.filter(this.state.searchParams);
                                        }}>Apply & Close</button>
                                    </div>
                                </div>
                            }
                        </div>

                        <div className="filter timepreset">
                            <h3>
                                <i className="fas fa-route"></i>
                                <span>Show Distance from</span>
                            </h3>
                            <div className='simple-select'>
                                <select 
                                    onClick={(e) => {
                                        this.props.openFilterDropdown(null);
                                    }}
                                    value={this.state.searchParams.locationId}
                                    onChange={(e) => {
                                        this.setState({
                                            searchParams: {
                                                ...this.state.searchParams,
                                                locationId: e.target.value
                                            }
                                        }, () => {
                                            this.props.filter(this.state.searchParams);
                                        })
                                    }}
                                >
                                    {this.props.locations.map((location, i) => {
                                        return (
                                            <option value={location.id}>
                                                {location.locationFriendlyAddress}
                                                {location.locationPlaceName ? " -" + location.locationPlaceName + "" : ""}
                                                {location.postCode ? " -" + location.postCode + "" : ""}
                                            </option>
                                        )
                                    }) }
                                </select>
                            </div>
                        </div>

                        <div className="filter timepreset">
                            <h3>
                                <i className="fas fa-route"></i>
                                <span>Days to show</span>
                            </h3>
                            <div className='simple-select'>
                                <select 
                                    onClick={(e) => {
                                        this.props.openFilterDropdown(null);
                                    }}
                                    value={this.props.expandedDaysToDisplay}
                                    onChange={(e) => {
                                        this.props.onDayCountChange(parseInt(e.target.value));
                                    }}
                                >
                                    {[15, 20, 30, 45, 60, 75, 90].map((days, i) => {
                                        return (
                                            <option value={days}>
                                                {days} Days
                                            </option>
                                        )
                                    }) }
                                </select>
                            </div>
                        </div>
                    </React.Fragment>
                }
                <FullScreenLoader view="chat" loadingMessage={this.state.loadingMessage} />
                <Modal
                    show={this.state.aiPromptModal}
                    onHide={() => { 
                        this.setState({ 
                            aiPromptModal: false
                        });
                    }}
                    className="base-modal ai-modal"
                >
                    <Modal.Body>
                        <h2>Use natural language to filter</h2>
                        <p>Most Available <mark>{this.props.userAttributes.length ? this.props.userAttributes[0].skillName : "Math A Levels" }</mark> contacts for <mark>Tomorrow</mark></p>
                        <p><mark>My highest rated</mark> <mark>{this.props.userAttributes.filter(s => s.subSectorName).length ? this.props.userAttributes.find(s => s.subSectorName)?.subSectorName : "Primary" }</mark> contacts' availability between <mark>8am and 2pm</mark></p>
                        { (this.props.userRepresentations.length > 1) &&
                            <p>Only contacts represented by <mark>{this.props.userRepresentations[0].name}</mark> between <mark>{Utilities.formatDate(Utilities.dateAdd(new Date(), "day", 3), "dth of m")}</mark> and <mark>{Utilities.formatDate(Utilities.dateAdd(new Date(), "day", 6), "dth of m")}</mark></p>
                        }
                        <textarea
                            rows={3}
                            placeholder='Describe your requirements in natural language'
                            value={this.state.aiPromptModalText || ""}
                            onChange={e => {
                                // @ts-ignore
                                const enter = e.key === "Enter";
                                if (enter) {
                                    this.getFiltersWithAI(e.target.value.trim());
                                } else {
                                    this.setState({ 
                                        aiPromptModalText: e.target.value
                                    });
                                }
                            }}
                        />
                        <div className='options'>
                            <button 
                                onClick={() => {
                                    this.setState({ 
                                        aiPromptModal: false
                                    });
                                }}
                            >
                                Cancel
                            </button>
                            <button
                                className='submit'
                                onClick={() => {
                                    this.setState({ 
                                        aiPromptModal: false
                                    });

                                    if (this.state.aiPromptModalText.trim()) {
                                        this.getFiltersWithAI(this.state.aiPromptModalText.trim());
                                    }
                                }}
                            >
                                Search
                            </button>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

export default PeopleFilter;