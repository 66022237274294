/* eslint-disable */
import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Link } from 'react-router-dom';

import { Subscribe } from '../../unstated-fork/unstated';
import Analytics from '../../services/analytics.service';
import theme from '../../css/theme';
import TeamsContainer from '../../state-containers/teams.container';
import SimpleTooltip from '../../components/ui-components/SimpleTooltip';
import { ReleaseNotesVersionServiceProvider } from '../../services/release-notes-version.service';
import QuestionCircle from '../ui-components/QuestionCircle';
import TaskList from '../dashboard/TaskList';
import history from '../../history';
import WhatsNew from '../../pages/WhatsNew';
import LookupsAPI from 'src/api/lookups/Lookups';
import ProfileAPI from 'src/api/profile/Profile';
import NotificationsPanel from '../layout/NotificationsPanel';
import DashboardContainer from '../../state-containers/dashboard.container';
import ReleaseNotesVersionService from '../../services/release-notes-version.service';
import Utilities from 'src/Utilities';

interface Props {
    onMobileMenuClick: () => void;
    isWorker?: boolean;
}

interface State {
    nextTodo: string;
    whatsNewModal: boolean;
    notificationsPanel: boolean;
}

class Header extends React.Component<Props, State> {
    state = {
        nextTodo: '',
        whatsNewModal: ReleaseNotesVersionService.isReadVersionOutdated() && !localStorage.getItem("justCreatedOrg"),
        whatsNewModelVersion: ReleaseNotesVersionService.getLastReleaseNotesVersion(),
        notificationsPanel: false
    };

    private track = () => {
        Analytics.trackEvent('ideas-clicked');
    };

    componentDidMount() {
        this.reloadLocalSectors();
        if (this.state.whatsNewModal) {
            ProfileAPI.updatePortalNewsReadVersion(this.state.whatsNewModelVersion)
            Utilities.updateDashboardLocalStorage("portalUpdatesVersion", this.state.whatsNewModelVersion)
        }
    }

    reloadLocalSectors() {
        if (!localStorage.getItem("sectors")) {
            LookupsAPI.industries("").then((industries) => {
                if (industries.data) {
                    localStorage.setItem("sectors", JSON.stringify(industries.data))
                }
            });
        }
    }

    toggleNotifications = () => {
        this.setState({
            notificationsPanel: !this.state.notificationsPanel
            // notificationsCount: 
        })
    }

    public render() {
        return (
            <Subscribe to={[DashboardContainer]}>
                {(dashboard: DashboardContainer) => (
                    <Content id="header">
                        <div className="color-line" />
                        <div id="logo">
                            <img style={{ width: 40 }} src="/img/branding/updatedge-trans-48.png" />
                        </div>
                        <nav role="navigation">
                            <div
                                className="header-link hide-menu"
                                onClick={this.props.onMobileMenuClick}
                            >
                                <i className="fa fa-bars" style={{ color: 'White' }} />
                            </div>
                            <div className="full-head layout horizontal justified">
                                <TeamSelector isWorker={this.props.isWorker} />
                                <ul className="nav navbar-nav no-borders">
                                    {(
                                        (this.state.nextTodo === 'Create organization' && window.location.href.indexOf("/organisation") === -1 ) ||
                                        (this.state.nextTodo === 'Time Presets' && window.location.href.indexOf("/settings/stpresets") === -1 ) ||
                                        (this.state.nextTodo === 'Complete your Profile' && window.location.href.indexOf("/profile") === -1 ) ||
                                        (this.state.nextTodo === 'Invite Colleagues' && window.location.href.indexOf("/organisation/members") === -1 ) ||
                                        (this.state.nextTodo === 'Invite your contacts' && window.location.href.indexOf("/invite") === -1 ) ||
                                        (this.state.nextTodo === 'Add your agencies' && window.location.href.indexOf("/agencies") === -1 )
                                    ) && (
                                        <li className="dropdown guide-instruction">
                                            <a
                                                onClick={() => {
                                                    if (this.state.nextTodo === 'Create organization') {
                                                        history.push(`/organisation`);
                                                    } else if (this.state.nextTodo === 'Time Presets') {
                                                        history.push(`/settings/stpresets`);
                                                    } else if (this.state.nextTodo === 'Invite Colleagues') {
                                                        history.push(`/organisation/members`);
                                                    } else if (this.state.nextTodo === 'Invite your contacts') {
                                                        history.push(`/invite`);
                                                    } else if (this.state.nextTodo === 'Add your agencies') {
                                                        history.push(`/agencies`);
                                                    } else if (this.state.nextTodo === 'Complete your Profile') {
                                                        history.push(`/profile`);
                                                    }
                                                }}
                                            >
                                                <div className="text-center text-success">
                                                    <i className="fas fa-list"></i>
                                                </div>
                                                <div className="navbar-nav-btn-text text-success">
                                                    Finish Account Setup
                                                </div>
                                            </a>
                                        </li>
                                    )}
                                    {/* <li className="dropdown">
                                        <a
                                            onClick={this.track}
                                            href="https://feedback.userreport.com/45e76aa9-3a3d-4b54-9824-54296a239606/#ideas/popular"
                                            target="_new"
                                        >
                                            <div className="text-center text-success">
                                                <i className="far fa-bell"></i>
                                            </div>
                                            <div className="navbar-nav-btn-text text-success">
                                                Ideas, Suggestions or Bugs?
                                            </div>
                                        </a>
                                    </li> */}
                                    <li className="dropdown">
                                        <Link id="whats-new-btn" to="#" onClick={() => {
                                        this.setState({
                                                whatsNewModal: true
                                        }) 
                                        }}>
                                            <ReleaseNotesVersionServiceProvider
                                                render={({
                                                    newReleaseNotesAvailable
                                                }) =>
                                                    newReleaseNotesAvailable ? (
                                                        <Shake>
                                                            <div className="text-center text-danger">
                                                                <i className="fas fa-bullhorn"></i>
                                                            </div>
                                                            <div className="navbar-nav-btn-text text-danger">
                                                                New Update Released
                                                            </div>
                                                        </Shake>
                                                    ) : (
                                                        <>
                                                            <div className="text-center text-success">
                                                                <i className="fas fa-bullhorn"></i>
                                                            </div>
                                                            <div className="navbar-nav-btn-text text-success">
                                                                View Latest Updates
                                                            </div>
                                                        </>
                                                    )
                                                }
                                            />
                                        </Link>
                                    </li>
                                    <li className="dropdown">
                                        <a
                                            onClick={this.toggleNotifications}
                                            target="_new"
                                        >
                                            <div className="text-center text-success">
                                                <i className="far fa-bell"></i>
                                            </div>
                                            <div className="navbar-nav-btn-text text-success">
                                                Notifications
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="small-logo">
                                <img src="/img/branding/updatedge-trans-48.png" />
                                {/* <span className="text-primary">updatedge</span> */}
                            </div>
                        </nav>
                        <TaskList
                            view="fullscreen"
                            lastNextTitle={this.state.nextTodo}
                            isBasicProfileSetUp={(nextTitle) => {
                                this.setState({ nextTodo: nextTitle || "" })
                            }}
                        />
                        {this.state.whatsNewModal && (
                            <WhatsNew
                                modal={true}
                                onClose={() => {
                                    this.setState({
                                        whatsNewModal: false
                                    })
                                }}
                            />
                        )}
                        <NotificationsPanel
                            show={this.state.notificationsPanel}
                            setShow={(show) => {
                                this.setState({
                                    notificationsPanel: show
                                })
                            }}
                        />
                    </Content>
                )}
            </Subscribe>
        );
    }
}

export default Header;

const TeamSelector = ({ isWorker }: { isWorker: Props['isWorker'] }) => (
    <Subscribe to={[TeamsContainer]}>
        {(teamsContainer: TeamsContainer) => {
            if (isWorker) return null;

            return (
                <TeamSelectorWrapper className="layout horizontal center">
                    <span>Team: </span>
                    <select
                        id="team-selector"
                        className="form-control"
                        value={
                            teamsContainer.state.teams.some(
                                (t) => t.id == teamsContainer.state.active
                            )
                                ? teamsContainer.state.active
                                : 0
                        }
                        onChange={(ev) =>
                            teamsContainer.setTeamActive(+ev.target.value)
                        }
                        style={{
                            padding: 6
                        }}
                    >
                        <option 
                            value="0"
                        >
                            {teamsContainer.state.teams.length === 0
                                ? 'Not in any teams'
                                : (teamsContainer.state.active)
                                    ? 'Just me'
                                    : 'Select from ' +
                                        teamsContainer.state.teams.length +
                                        ' teams'}
                        </option>
                        {teamsContainer.state.teams.map((t) => {
                            const disabled = t.teamMembers.map(member => member.contactId).indexOf(teamsContainer.state.contactId) === -1;
                            return (
                                <option 
                                    key={t.id} 
                                    value={t.id}
                                    disabled={disabled}
                                >
                                    {disabled && "You are not a part of "} {t.name} 
                                </option>
                            )
                        }) }
                    </select>
                    <SimpleTooltip
                        id="team-info"
                        placement="bottom"
                        text={TeamTooltip}
                    >
                        <QuestionCircle />
                    </SimpleTooltip>
                </TeamSelectorWrapper>
            );
        }}
    </Subscribe>
);

const shake = keyframes`
    1%, 9% {
        transform: translate3d(-1px, 0, 0);
    }

    2%, 8% {
        transform: translate3d(2px, 0, 0);
    }

    3%, 5%, 7% {
        transform: translate3d(-4px, 0, 0);
    }

    4%, 6% {
        transform: translate3d(4px, 0, 0);
    }

    10%, 100% {
        transform: translate3d(0, 0, 0);
    }
`;

const Shake = styled.div`
    animation: ${shake} 10s cubic-bezier(0.36, 0.07, 0.19, 0.97) infinite both;
`;

const TeamTooltip = (
    <>
        A <strong>Team</strong> lets you share contacts, availability, and
        offers with your colleagues
        <br />
        <br />
        <small>
            You will be able to see the contacts of any colleague in your
            selected team, as well as send offers to them and confirm offers
            that your contacts apply for
        </small>
        <br />
        <br />
        <small>Configure Teams from the "Organization" menu</small>
    </>
);

const Content = styled.div`
    .header-link {
        @media print {
            display: none !important;
        }
    }

    a {
        padding: 0 12px;
    }

    i {
        font-size: 24px;
        margin-bottom: 4px;
        margin-left: 4px;
        @media print {
            display: none !important;
        }
    }

    @media print {
        .full-head {
            display: none !important;
        }
    }

    @media (max-width: 1200px) {

        .dropdown {
            max-width: 96px;

            i {
                font-size: 14px;
            }
            
            a {
                padding: 5px;
            }

            .navbar-nav-btn-text {
                font-size: 0.36em;
                line-height: 1em;
                text-align: center;
            }
            
        }
        
    }

    @media (max-width: 768px) {

        nav {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .nav {
            display: none !important;
        }

        .full-head {
            flex-grow: 1;

            &>span {
                padding: 10px 0;
                width: 100%;

                span {
                    color: white;
                }

                i {
                    color: white;
                    margin: 0 24px 0 8px;
                }

                select {
                    border: none;
                }

            }

            .nav {
                display: flex;
                background: white;
                justify-content: center;
                margin: 0;
                padding-bottom: 10px;
    
                li {
                    max-width: unset;
    
                    a {
                        padding: 5px !important;
                        max-width: 96px;
                    }
    
                }
    
            }

        }
    
    }

    // @media (max-width: 768px) {
    //     .full-head {
    //         display: none;
    //     }

    //     .small-head {
    //         position: absolute;
    //         top: 55px;
    //         display: block;
    //         width: 100%;
    //         padding-right: 10px;
    //         background: white;
    //         border-bottom: 0.5px solid ${theme.colours.updatedge};

    //         select {
    //             width: 100%;
    //         }
    //     }
    // }

    // @media (min-width: 769px) {
    //     .small-head {
    //         display: none;
    //     }
    // }
`;

const TeamSelectorWrapper = styled.span`
    > span {
        margin: 0 10px 0 15px;
        color: ${theme.colours.updatedge};
        font-weight: 600;
        font-size: 14px;
        cursor: none;
        @media print {
            display: none !important;
        }
    }

    > select {
        color: ${theme.colours.updatedge};
        border-color: ${theme.colours.updatedge} !important;
        @media print {
            display: none !important;
        }
    }
`;
