/* eslint-disable */

import AppConfig from '../../components/config/Config';
import { TimesheetResponseType } from './ResponseTypes';
import afetch from './../AuthenticatedFetch';

const apiUrls = {
    sendFeedback(token: string) {
        return `${AppConfig.Settings.api.externalUri}/token/timesheet/response/${token}`;
    }
};

const apiCalls = {
    sendFeedback: (token: string, responseType: TimesheetResponseType, feedback?: string, agencyHirerId?: string, contactIds?: string[]) => {
        return afetch.request<{ comment: string; responseTypeId: TimesheetResponseType; agencyHirerId?: string, contactIds?: string[] }, null>(
            apiUrls.sendFeedback(token),
            'POST',
            { responseTypeId: responseType, comment: feedback || "", agencyHirerId: agencyHirerId, contactIds: contactIds },
            'Unable to get rota'
        );
    }
};

export default apiCalls;
