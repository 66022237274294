/* eslint-disable */
// TODONOW
import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import PubSub from 'pubsub-js';
import { toast } from 'react-toastify';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import DemoRes from '../demo/offers.json';

import history from '../history';
import ViewFavouriteOffer from '../components/offers/ViewFavouriteOffer';
import ViewOffer from '../components/offers/ViewOffer';
import OfferList from '../components/offers/OfferList';
import CreateOfferUtilsProvider, {
    CreateOfferUtilsProviderReturnValue
} from '../components/offers/CreateOfferUtilsProvider';
import theme from '../css/theme';
import OfferApi from '../api/offers/Offers';
import {
    OfferInfoDto,
    OfferResponseTypes,
    OfferConfirmationResponseTypes,
    OfferRecipientDto,
    OfferRecipientDtoChange,
    CreateOfferDto,
    FavouriteOfferDTO,
    OfferEventDeletedDto
} from '../api/offers/ResponseTypes';
import {
    getOfferStatus,
    isOfferResolved,
    getOfferWorkersByResponseType
} from './OffersUtils';
import { minDuration, PubSubTopics, DateFormats } from '../constants';
import { OfferRecipientAgency, OfferType, SortableOffersColumns } from '../api/offers/RequestTypes';
import Analytics from '../services/analytics.service';
import InternalTracker from '../InternalTracker';
import withWindowDimensions, {
    WindowDimensionProps
} from '../components/util/withWindowDimensions';
import {
    OFFER_MODAL_BREAKPOINT
} from '../components/offers/breakpoints';
import CreateOffer from '../components/offers/CreateOffer';
import * as EventsState from '../store/Events';
import { Spinner } from '../components/ui-components/Spinner';
import { debounce } from 'ts-debounce';
import Dialog from 'src/components/ui-components/Dialog';
import Utilities from 'src/Utilities';
import { getValue, setValue } from 'src/db/KeyValueCache';
import { CircularProgress } from '@mui/material';
import SimplePrompt from 'src/components/ui-components/SimplePrompt';
import SubscriptionsApi from 'src/api/subscriptions/Subscriptions';

export interface EnrichedFavouriteOffer extends FavouriteOfferDTO {
    isFavourite: boolean;
}

export enum OfferListTab {
    Resolved = 'Resolved',
    Upcoming = 'Upcoming',
    Favourite = 'Favourite'
}

export enum OfferStatus {
    Confirmed,
    Withdrawn,
    Historic,
    Waiting,
    Ready,
    Declined,
    Favourite,
    Unknown,
    Deleted,
    ConfirmedAlterations,
    ConfirmedDeletions,
    NotRequested
}

export enum OfferAgencyStatus {
    NotRequested,
    NotSent,
    Pending,
    Helping,
    NotHelping,
    Historic,
    NotNeeded
}

type Props = RouteComponentProps<{ offerId: string }> &
    WindowDimensionProps & {
        organisationId: ApplicationState['profile']['authenticatedUserProfile']['organisationId'];
        organisationName: ApplicationState['profile']['authenticatedUserProfile']['organisationName'];
        organisationIsAgency: ApplicationState['profile']['authenticatedUserProfile']['organisationIsAgency'];
    } & {
        createOfferUtilsData: CreateOfferUtilsProviderReturnValue['data'];
        createOfferUtilsFuncs: CreateOfferUtilsProviderReturnValue['functions'];
    } & typeof EventsState.actionCreators;

interface State {
    // loading: boolean;
    loadingUpcoming: boolean,
    loadingResolved: boolean,
    loadingFavorites: boolean,
    tab: OfferListTab;
    offersUpcoming: OfferListWithPagingParams;
    offersResolved: OfferListWithPagingParams;
    favouriteOffers: EnrichedFavouriteOffer[];
    selectedOfferId: OfferInfoDto['id'] | EnrichedFavouriteOffer['id'] | null;
    selectedOffer?: OfferInfoDto,
    selectedOfferVersion: number,
    creating: boolean;
    selectedRecipients: OfferRecipientDto[];
    searchKeyword: string;
    showingDemoRes: boolean;
    staleData: boolean;
    showScheduleTrial: boolean;
    activatingTrial: boolean;
}

interface Paging {
    hasMorePages?: boolean,
    page?: number,
    totalPages?: number,
    totalRows?: number,
}

export interface OfferListWithPagingParams extends Paging {
    data: OfferInfoDto[]
}

export interface FavoriteOfferListWithPagingParams extends Paging {
    data: EnrichedFavouriteOffer[]
}

const OFFERS_PER_PAGE = 20;

export class Offers extends React.Component<Props, State> {
    state = {
        // loading: false,
        tab: OfferListTab.Upcoming,
        loadingUpcoming: true,
        loadingResolved: true,
        loadingFavorites: true,
        offersUpcoming: { data: [] },
        offersResolved: { data: [] },
        favouriteOffers: [],
        selectedOfferId: null,
        selectedRecipients: [],
        creating: false,
        selectedOfferVersion: 0,
        searchKeyword: "",
        showingDemoRes: false,
        staleData: false,
        showScheduleTrial: false,
        activatingTrial: false
    } as State;

    private teamUpdatedSub?: string;
    private searchTimeout?: ReturnType<typeof setTimeout>;
    private lastUrl?: string;

    async componentDidMount() {
        if (document.getElementsByClassName("content") && document.getElementsByClassName("content")[0]) {
            (document.getElementsByClassName("content")[0] as HTMLElement).style.maxWidth = '1280px';
        }

        const offerId = this.props.match?.params?.offerId || this.props.idFromModal || '';

        await this.reload(offerId);

        Analytics.trackEvent('offers-page-loaded');

        (window as any).onSignalRUserOfferUpdated = (update: OfferRecipientDtoChange) => {
            this.handleUserOfferUpdated(update);
        }

        (window as any).onSignalRUserOfferEventDeleted = (update: OfferEventDeletedDto) => {
            this.handleUserOfferEventDeleted(update);
        }

        this.teamUpdatedSub = PubSub.subscribe(
            PubSubTopics.teamIdUpdated,
            async () => {
                await this.reload(
                    this.state.selectedOfferId
                        ? this.state.selectedOfferId
                        : undefined
                );
            }
        );

        (window as any).loadOfferIntoModal = (offerId: string) => {
            this.setState({
                selectedOfferId: offerId
            })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const newUrl = window.location.href;
        if (newUrl.indexOf("#create") !== -1 && (!this.lastUrl || this.lastUrl?.indexOf("#create") === -1)) {
            this.startInstantOffer();
        } else if (newUrl.indexOf("#create") === -1 && (this.lastUrl?.indexOf("#create") !== -1)) {
            this.setState({
                creating: false
            })
        }
        if (newUrl.indexOf("#Resolved") !== -1 && (!this.lastUrl || this.lastUrl?.indexOf("Resolved") === -1)) {
            this.scrollToSection("Resolved")
        }
        this.lastUrl = window.location.href;
    }


    private scrollToSection(section: string) {
        const sectionEl = document.querySelector("h2[name='" + section + "']");
        const offersWrapper = document.querySelector('#offers-wrapper>div');
        console.log(sectionEl?.parentElement?.parentElement?.parentElement, offersWrapper)
        if (sectionEl && offersWrapper) {
            sectionEl.parentElement?.parentElement?.parentElement?.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'start'
            });
            // @ts-ignore
            console.log(sectionEl?.parentElement?.parentElement?.parentElement.offsetTop)
            // @ts-ignore
            window.scrollTo(0, sectionEl?.parentElement?.parentElement?.parentElement?.offsetTop);
        }
    }

    componentWillUnmount() {
        PubSub.unsubscribe(this.teamUpdatedSub);
        (window as any).loadOfferIntoModal = null;
    }

    shouldRenderSelected = () => {
        return (
            this.state.selectedOfferId != null ||
            this.state.creating
        );
    };

    startChat = (recipient) => {
        this.props.setOverlayMenuSelectedItem(1);
        this.props.startChat(recipient.recipientUserId);
    };

    selectOffer = (offer: OfferInfoDto /* | EnrichedFavouriteOffer*/) => {
        // let recipient: OfferRecipientDto | undefined;

        // if (
        //     offer.recipients.length == 1 &&
        //     this.checkOfferType(offer) === 'offer'
        // ) {
        //     const first = (offer as OfferInfoDto).recipients[0];

        //     if (
        //         first.confirmation !=
        //             OfferConfirmationResponseTypes.Confirmed &&
        //         first.confirmation !=
        //             OfferConfirmationResponseTypes.Unsuccessful &&
        //         first.response === OfferResponseTypes.Yes
        //     ) {
        //         // Pre-select the recipient if there is only 1 and they have applied
        //         recipient = first;
        //     }
        // }

        this.setState((prevState) => ({
            selectedOfferId: prevState.selectedOfferId === offer.id ? null : offer.id,
            selectedOffer: offer,
            // selectedRecipients: recipient ? [recipient] : [],
            // selectedOfferVersion: 0,
        }));

        InternalTracker.trackEvent("", { category: 'Offers', action: 'Offer Clicked' });
        Analytics.trackEvent('offers-page-offer-clicked');
    };

    isOfferToday = ({ events }: OfferInfoDto) => {
        const offerStart = events[0].start;
        const offerEnd = events[events.length - 1].end;
        return moment().isBetween(offerStart, offerEnd);
    };

    handleCreated = (offer: CreateOfferDto, successCb?: Function) => {

        OfferApi.createOffer(offer).then((offerId) => {
            (window as any).lastCreatedOfferId = offerId;
            this.setState({ creating: false });
            this.reload();
            toast.success('Offer sent!');
            history.push("/offers");
            if (successCb) successCb();
        });

        InternalTracker.trackEvent("", { category: 'Offers', action: 'Offer Sent' });
        Analytics.trackEvent('offers-page-offer-sent');
    };

    handleWithdrawWorker = (recipient: OfferRecipientDto) => {

        if (
            !this.state.selectedOfferId /* || !this.state.selectedOfferId.hasOwnProperty('isFavourite') */
        ) {
            return;
        }

        const offer = this.getSelectedOffer()! as OfferInfoDto;

        const newRecipient = {
            ...recipient,
            confirmation: OfferConfirmationResponseTypes.Rejected
        };

        const newOffer = {
            ...offer,
            recipients: offer.recipients.map((r) =>
                r.id === recipient.id ? newRecipient : r
            )
        };

        this.setState({
            selectedRecipients: this.state.selectedRecipients.map((r) =>
                r.id === recipient.id ? newRecipient : r
            ),
            selectedOfferId: newOffer.id,
            offersResolved: {
                ...this.state.offersResolved,
                data: this.state.offersResolved.data.map((o) => o.id === offer.id ? newOffer : o)
            },
            offersUpcoming: {
                ...this.state.offersUpcoming,
                data: this.state.offersUpcoming.data.map((o) => o.id === offer.id ? newOffer : o)
            },
            selectedOfferVersion: 0
        })

        OfferApi.withdrawOfferWorker(recipient.id);
        InternalTracker.trackEvent("", {
            category: 'Offers',
            action: 'Offer Worker Withdrawn'
        });
        Analytics.trackEvent('offers-page-offer-worker-withdrawn');
    };

    handleDeleteOffer = (id?: string) => {

        const offerId = id || this.state.selectedOfferId;
        const deletedOffer = this.state.offersResolved.data.find((o) => o.id === offerId) || this.state.offersUpcoming.data.find((o) => o.id === offerId)

        if (offerId) {
            (window as any).lastDeletedOfferId = offerId;
            OfferApi.deleteOffer(offerId).then((ok) => {
                this.setState({
                    selectedOfferId: null,
                    offersResolved: {
                        ...this.state.offersResolved,
                        data: this.state.offersResolved.data.filter((o) => o.id !== offerId)
                    },
                    offersUpcoming: {
                        ...this.state.offersUpcoming,
                        data: this.state.offersUpcoming.data.filter((o) => o.id !== offerId)
                    }
                })
                toast.success(deletedOffer ? "'" + deletedOffer.title + "' has been deleted" : 'Offer deleted');
            });
        }

    };

    handleDeleteFavouriteOffer = (id?: string) => {

        localStorage.removeItem("favoritesCache");
        const offerId = id || this.state.selectedOfferId;

        if (offerId) {
            OfferApi.deleteFavourite(offerId).then(() => {
                toast.success('Favourite deleted');
                this.setState(() => ({
                    selectedOfferId: null,
                    favouriteOffers: this.state.favouriteOffers.filter(
                        (o) => o.id !== offerId
                    )
                }))
            });
        }

    };
    
    handleWithdrawOffer = (offer: OfferInfoDto, hardReload?: boolean) => {
        if (hardReload) {
            this.reload();
        } else {
            const newOffer = { ...offer, withdrawn: true };

            this.setState({
                offersResolved: {
                    ...this.state.offersResolved,
                    data: this.state.offersResolved.data.map((o) =>
                        o.id === newOffer.id ? newOffer : o
                    )
                },
                offersUpcoming: {
                    ...this.state.offersUpcoming,
                    data: this.state.offersUpcoming.data.map((o) =>
                        o.id === newOffer.id ? newOffer : o
                    )
                },
                selectedOfferId: null,
                tab: OfferListTab.Resolved
            })
        }
    };

    handleViewCancel = () => {
        this.setState({ selectedOfferId: null });
        if (this.props.onClose) {
            this.props.onClose();
        }
        Analytics.trackEvent('offers-page-cancel');
    };

    handleConfirmationComplete = (
        recipientAgencies: OfferRecipientAgency[],
        rejectUnsuccessful: boolean
    ) => {
        const recipients = this.state.selectedRecipients;
        const offer = this.getSelectedOffer() as OfferInfoDto;

        const recipientIds = recipientAgencies.map((r) => r.recipientId);
        const newRecipients = offer.recipients.map((r) => {
            if (recipientIds.indexOf(r.id) >= 0)
                return {
                    ...r,
                    confirmation: OfferConfirmationResponseTypes.Confirmed
                };
            else if (rejectUnsuccessful && r.response == OfferResponseTypes.Yes)
                return {
                    ...r,
                    confirmation: OfferConfirmationResponseTypes.Unsuccessful
                };
            else return r;
        });

        const newOffer = {
            ...offer,
            complete: true,
            recipients: newRecipients
        };

        this.setState({
            offersResolved: {
                ...this.state.offersResolved,
                data: this.state.offersResolved.data.map((o) =>
                    o.id === newOffer.id ? newOffer : o
                )
            },
            offersUpcoming: {
                ...this.state.offersUpcoming,
                data: this.state.offersUpcoming.data.map((o) =>
                    o.id === newOffer.id ? newOffer : o
                )
            },
            tab: OfferListTab.Upcoming
        })
    };

    handleUserOfferEventDeleted = (offerEventDeleted: OfferEventDeletedDto) => {
        const thisOffer = this.state.offersUpcoming.data.find(
            (offer) => offer.id == offerEventDeleted.offerId
        ) || this.state.offersResolved.data.find(
            (offer) => offer.id == offerEventDeleted.offerId
        );

        if (!thisOffer) return;

        const thisRecipient = thisOffer.recipients.find(
            (r) => r.recipientUserId == offerEventDeleted.workerId
        );

        if (!thisRecipient) return;

        this.reload(offerEventDeleted.offerId);
    };

    handleUserOfferUpdated = (update: OfferRecipientDtoChange, tryI = 0) => {
        const findRecipientInOffer = (offer: OfferInfoDto) =>
            offer.recipients.find(({ id }) => id === update.id);

        const thisOfferResolved = this.state.offersResolved.data.find(o => o.id === update.offerId);
        const thisOfferUpcoming = this.state.offersUpcoming.data.find(o => o.id === update.offerId);
        const thisOffer = thisOfferResolved || thisOfferUpcoming;

        console.log("NEW update type " + update.updateType + " for offer " + update.offerId + " with try " + tryI, update)

        if (update.updateType === "creation") {
            if ((window as any).lastCreatedOfferId !== update.offerId && tryI > 5) {
                this.reload();
            } else if (tryI <= 5) {
                setTimeout(() => { this.handleUserOfferUpdated(update, tryI + 1) }, 1000);
            }
            return;
        }

        if (update.updateType === "confirmation") {
            if ((window as any).lastConfirmedOfferId !== update.offerId) {
                this.reload();
            }
            return;
        }

        if (update.updateType === "withdrawal") {
            if ((window as any).lastWithdrawnOfferId !== update.offerId) {
                this.reload();
            }
            return;
        }

        if (update.updateType === "deletion") {
            if ((window as any).lastDeletedOfferId !== update.offerId) {
                this.reload();
            }
            return;
        }

        if (update.updateType === "agency-response" || update.updateType === "agency-request" || update.updateType === "agency-cancelled") {
            if (thisOffer) {
                if (update.updateType === "agency-response") {
                    thisOffer.agencyResponses = thisOffer.agencyResponses.map(ar => {
                        if (update.agencyResponse && ar.userContactId === update.agencyResponse.UserContactId) {
                            ar.responseType = update.agencyResponse.ResponseType;
                        }
                        return ar;
                    })
                } else if (update.updateType === "agency-cancelled") {
                    thisOffer.agencyResponses = thisOffer.agencyResponses.map(ar => {
                        if (update.agencyResponse && ar.userContactId === update.agencyResponse.UserContactId) {
                            ar.cancelled = true;
                        }
                        return ar;
                    })
                } else {
                    thisOffer.agencyResponses = thisOffer.agencyResponses.map(ar => {
                        if (update.agencyResponse && ar.userContactId === update.agencyResponse.UserContactId) {
                            ar.emailSent = true;
                        }
                        return ar;
                    })
                }
                if (thisOfferResolved) {
                    this.setState({
                        offersResolved: {
                            ...this.state.offersResolved,
                            data: this.state.offersResolved.data.map(o => o.id === thisOffer.id ? thisOffer : o)
                        }
                    })
                } else {
                    this.setState({
                        offersUpcoming: {
                            ...this.state.offersUpcoming,
                            data: this.state.offersUpcoming.data.map(o => o.id === thisOffer.id ? thisOffer : o)
                        }
                    })
                }
            }
            return;
        }

        const updatedOffer = this.state.offersResolved.data.find(findRecipientInOffer)! || this.state.offersUpcoming.data.find(findRecipientInOffer)!;

        if (!updatedOffer) {
            return null;
        }

        const updatedOfferRecipient = findRecipientInOffer(updatedOffer)!;
        
        if (!updatedOfferRecipient) {
            return null;
        }

        const responseHasChanged = updatedOfferRecipient.response !== update.response;

        const updateOfferId = update.offerId || updatedOffer.id;
        if (updateOfferId && this.state.selectedOfferId === updateOfferId) {
            this.setState({
                selectedOfferVersion: this.state.selectedOfferVersion + 1
            })
        }

        this.setState((prevState) => ({
            offersUpcoming: {
                ...prevState.offersUpcoming,
                data: prevState.offersUpcoming.data.map((o) => {
                    return {
                        ...o,
                        recipients: o.recipients.map((r) => {
                            if (r.id == update.id) {
                                return { ...r, ...update };
                            }
    
                            return r;
                        })
                    };
                })
            },
            offersResolved: {
                ...prevState.offersResolved,
                data: prevState.offersResolved.data.map((o) => {
                    return {
                        ...o,
                        recipients: o.recipients.map((r) => {
                            if (r.id == update.id) {
                                return { ...r, ...update };
                            }
    
                            return r;
                        })
                    };
                })
            },
            selectedRecipients:
                responseHasChanged &&
                updatedOffer.recipients.length === 1 &&
                update.response === OfferResponseTypes.Yes &&
                this.state.selectedOfferId === updatedOffer.id
                    ? [updatedOfferRecipient]
                    : prevState.selectedRecipients
        }))
    };

    getOfferList = async(type: "upcoming" | "resolved", page: number, keywords?: string) => {
        this.setState({
            loadingResolved: type === "upcoming" ? this.state.loadingResolved : true,
            loadingUpcoming: type === "resolved" ? this.state.loadingUpcoming : true,
        })

        OfferApi.getOfferListWithParams({
            rowsPerPage: OFFERS_PER_PAGE,
            orderByColumn: type === "upcoming" ? SortableOffersColumns.FirstEventStart : SortableOffersColumns.DateDiffFromNowInMins,
            offerType: type === "upcoming" ?  OfferType.Upcoming : OfferType.Resolved,
            sortDirection: type === "upcoming" ? "asc" : "desc",
            keyword: keywords || undefined,
            page: page
        }).then(offers => {

            if ((type === "upcoming" || type === "resolved") && page === 1) {
                setValue("offers-" + type, offers);
            }

            if (offers.data.length) {
                localStorage.setItem("hasOffers", "true");
            }

            let newState = {
                offersUpcoming: type === "upcoming" ? ( page === 1 ? { ...offers.paging, data: offers.data } : { ...offers.paging, data: this.state.offersUpcoming.data.concat(offers.data) } ) : this.state.offersUpcoming,
                offersResolved: type === "resolved" ? ( page === 1 ? { ...offers.paging, data: offers.data } : { ...offers.paging, data: this.state.offersResolved.data.concat(offers.data) } ) : this.state.offersResolved,
                loadingResolved: type === "upcoming" ? this.state.loadingResolved : false,
                loadingUpcoming: type === "resolved" ? this.state.loadingUpcoming : false,
                showingDemoRes: false,
                staleData: false
            };
            this.setState(newState, () => {
                if (!this.state.loadingResolved && !this.state.loadingUpcoming && !localStorage.getItem("offer-demo-dismiss") && this.state.offersResolved.data.length === 0 && !keywords && this.state.offersUpcoming.data.length === 0) {
                    const now = new Date();
                    const allowedDaysUpcoming = [Utilities.dateAdd(now, "day", 1), Utilities.dateAdd(now, "day", 2), Utilities.dateAdd(now, "day", 3), Utilities.dateAdd(now, "day", 4), Utilities.dateAdd(now, "day", 5), Utilities.dateAdd(now, "day", 6), Utilities.dateAdd(now, "day", 7), Utilities.dateAdd(now, "day", 8), Utilities.dateAdd(now, "day", 9), Utilities.dateAdd(now, "day", 10)];
                    const allowedDaysResolved = [Utilities.dateSub(now, "day", 1), Utilities.dateSub(now, "day", 2), Utilities.dateSub(now, "day", 3), Utilities.dateSub(now, "day", 4), Utilities.dateSub(now, "day", 5), Utilities.dateSub(now, "day", 6), Utilities.dateSub(now, "day", 7), Utilities.dateSub(now, "day", 8), Utilities.dateSub(now, "day", 9), Utilities.dateSub(now, "day", 10)]

                    let correctedToTodayResolved = DemoRes.resolved;
                    correctedToTodayResolved.data.map(offer => {
                        // @ts-ignore
                        offer.events = offer.events.map(item => {
                            let start = allowedDaysUpcoming[Utilities.randomIntFromInterval(0, allowedDaysUpcoming.length-1)];
                            let end = start;
                            start.setHours(Utilities.randomIntFromInterval(0, 1) === 0 ? 8 : Utilities.randomIntFromInterval(0, 1) === 0 ? 10 : 12);
                            start.setMinutes(Utilities.randomIntFromInterval(0, 1) === 0 ? 30 : 0);
                            end.setHours(Utilities.randomIntFromInterval(0, 1) === 0 ? 14 : Utilities.randomIntFromInterval(0, 1) === 0 ? 16 : 18);
                            end.setMinutes(Utilities.randomIntFromInterval(0, 1) === 0 ? 30 : 0);
                            return {
                                start: start.toISOString(),
                                end: end.toISOString(),
                                intervalMinutes: item.intervalMinutes
                            }
                        })
                    })

                    let correctedToTodayUpcoming = DemoRes.upcoming;
                    correctedToTodayUpcoming.data.map(offer => {
                        // @ts-ignore
                        offer.events = offer.events.map(item => {
                            let start = allowedDaysUpcoming[Utilities.randomIntFromInterval(0, allowedDaysUpcoming.length-1)];
                            let end = start;
                            start.setHours(Utilities.randomIntFromInterval(0, 1) === 0 ? 8 : Utilities.randomIntFromInterval(0, 1) === 0 ? 10 : 12);
                            start.setMinutes(Utilities.randomIntFromInterval(0, 1) === 0 ? 30 : 0);
                            end.setHours(Utilities.randomIntFromInterval(0, 1) === 0 ? 14 : Utilities.randomIntFromInterval(0, 1) === 0 ? 16 : 18);
                            end.setMinutes(Utilities.randomIntFromInterval(0, 1) === 0 ? 30 : 0);
                            return {
                                start: start.toISOString(),
                                end: end.toISOString(),
                                intervalMinutes: item.intervalMinutes
                            }
                        })
                    })

                    this.setState({
                        showingDemoRes: true,
                        // @ts-ignore
                        offersResolved: correctedToTodayResolved,
                        // @ts-ignore
                        offersUpcoming: correctedToTodayUpcoming
                    })
                }
            })
        })
    }

    handleKeywordFilterChange = (keywords) => {
        if (keywords !== this.state.searchKeyword) {
            this.setState({
                searchKeyword: keywords
            })
            // @ts-ignore
            clearTimeout(this.searchTimeout);
            this.searchTimeout = setTimeout(() => {
                this.reload(undefined, 'offer');
            }, 700)
        }
    }

    reloadFavorites = () => {
        console.log("@@@@ RELOADING FAVS @@@@@")
        if (!this.state.searchKeyword) {
            OfferApi.getFavourites().then((favourites) => {
                this.setState({
                    favouriteOffers: favourites.map(this.enrichFavouriteOffer).reverse(),
                    loadingFavorites: false
                })
                localStorage.setItem("favoritesCache", JSON.stringify(favourites))
            })    
        }
    }

    reload = async (
        selectId?: string,
        selectType: 'offer' | 'favourite' = 'offer'
    ) => {

        this.setState({
            offersUpcoming: { data: [] },
            offersResolved: { data: [] }
        })

        InternalTracker.trackEvent("", {
            category: 'Offers',
            action: 'Offer List Loaded'
        });

        const staleUpcomingOffers = await getValue("offers-upcoming");
        const staleResolvedOffers = await getValue("offers-resolved");

        if (staleUpcomingOffers) {
            if (staleUpcomingOffers.data.length) {
                localStorage.setItem("hasOffers", "true");
            }
            this.setState({
                offersUpcoming: { ...staleUpcomingOffers.paging, data: staleUpcomingOffers.data },
                loadingUpcoming: false,
                showingDemoRes: false,
                staleData: true
            })
        }

        if (staleResolvedOffers) {
            if (staleResolvedOffers.data.length) {
                localStorage.setItem("hasOffers", "true");
            }
            this.setState({
                offersResolved: { ...staleResolvedOffers.paging, data: staleResolvedOffers.data },
                loadingResolved: false,
                showingDemoRes: false,
                staleData: true
            })
        }

        this.getOfferList("upcoming", 1, this.state.searchKeyword || undefined)

        this.getOfferList("resolved", 1, this.state.searchKeyword || undefined)

        if (!localStorage.getItem("favoritesCache")) {
            this.reloadFavorites();
        } else {
            this.setState({
                favouriteOffers: (JSON.parse(localStorage.getItem("favoritesCache") || "{}")).map(this.enrichFavouriteOffer).reverse(),
                loadingFavorites: false
            })
            setTimeout(() => {
                this.reloadFavorites();
            }, 5000)
        }

        this.setState({
            tab:
                selectType === 'favourite'
                    ? OfferListTab.Favourite
                    : OfferListTab.Upcoming,
        })

        if (selectId) {
            const selected = await OfferApi.getOfferById(selectId);
            if (!selected) return;
            this.selectOffer(selected);
        }
    };

    private getTabConfig = () => {
        return {
            [OfferListTab.Upcoming]: {
                offers: this.state.offersUpcoming,
                title: `Upcoming Offers`,
                tab: OfferListTab.Upcoming,
                loading: this.state.loadingUpcoming,
                color: theme.colours.green2,
                icon: 'fas fa-inbox'
            },
            [OfferListTab.Favourite]: {
                offers: { data: this.state.favouriteOffers },
                title: `Favourites`,
                tab: OfferListTab.Favourite,
                loading: this.state.loadingFavorites,
                color: theme.colours.amber,
                icon: 'fas fa-star'
            },
            [OfferListTab.Resolved]: {
                offers: this.state.offersResolved,
                title: `Resolved Offers`,
                tab: OfferListTab.Resolved,
                loading: this.state.loadingResolved,
                color: theme.colours.grey,
                icon: 'fas fa-calendar-minus'
            }
        };
    };

    loadMore = (type: OfferListTab) => {
        const tabConfig = this.getTabConfig();
        console.log(tabConfig, type)
        // @ts-ignore
        const nextPage = tabConfig[type]?.offers?.page + 1;
        this.getOfferList(type.toLowerCase() === "upcoming" ? "upcoming" : "resolved", nextPage, this.state.searchKeyword || undefined)
    }

    private startInstantOffer = () => {
        InternalTracker.trackEvent("", {
            category: 'Offers',
            action: 'Instant Offer Started'
        });

        this.setState({
            creating: true,
            selectedOfferId: null
        });
    };

    handleAddWorkersToOffer = (offer: OfferInfoDto) => {
        this.reload(offer.id);
    };

    handleFavouriteOffer = async (offer: OfferInfoDto) => {
        localStorage.removeItem("favoritesCache");
        this.setState({
            selectedOfferId: null
        })
        this.reload();
    };

    handleConvertFavouriteToOffer = () => {
        if (!this.state.selectedOfferId) return;
        const favouriteOffer = this.getSelectedOffer() as EnrichedFavouriteOffer;
        history.push("/offers#create/worker/" + favouriteOffer.recipients.map(({ recipientUserId }) => recipientUserId).join(","));

        const presets = this.props.createOfferUtilsData.presets;
        const matchingPreset = presets ? presets.find(preset => {
            return (
                preset.startHour === Number(favouriteOffer.startTime.split(':')[0]) &&
                preset.startMinute === Number(favouriteOffer.startTime.split(':')[1]) &&
                preset.endHour === Number(favouriteOffer.endTime.split(':')[0]) &&
                preset.endMinute === Number(favouriteOffer.endTime.split(':')[1])
            )
        }) : null;
        console.log(matchingPreset, presets)

        this.setState({ selectedOfferId: null, creating: true }, () => {
            this.props.createOfferUtilsFuncs.updateOfferFormData({
                title: favouriteOffer.title,
                description: favouriteOffer.description,
                placeId: favouriteOffer.locationPlaceId,
                workerIds: favouriteOffer.recipients.map(
                    ({ recipientUserId }) => recipientUserId
                ),
                periods: [
                    this.props.createOfferUtilsFuncs.createOfferPeriod({
                        id: matchingPreset ? matchingPreset.id : "9a6ff76c-7df5-4e00-a3d2-407a9154189f",
                        startHour: Number(favouriteOffer.startTime.split(':')[0]),
                        startMinute: Number(favouriteOffer.startTime.split(':')[1]),
                        endHour: Number(favouriteOffer.endTime.split(':')[0]),
                        endMinute: Number(favouriteOffer.endTime.split(':')[1])
                    })
                ]
            });
        });
    };

    private checkOfferType = (offer: OfferInfoDto | EnrichedFavouriteOffer) => {
        const offerType = offer.hasOwnProperty('isFavourite')
            ? 'favourite'
            : 'offer';

        return offerType;
    };

    private checkSelectedOfferType = () => {
        const offer = this.getSelectedOffer();
        if (!offer) return null;

        return this.checkOfferType(offer);
    };

    private enrichFavouriteOffer = (offer) => ({
        ...offer,
        isFavourite: true
    });

    private getSelectedOffer = () => {
        return (
            this.state.offersUpcoming.data.find(
                ({ id }) => id == this.state.selectedOfferId
            ) ||
            this.state.offersResolved.data.find(
                ({ id }) => id == this.state.selectedOfferId
            ) ||
            this.state.favouriteOffers.find(
                ({ id }) => id === this.state.selectedOfferId
            ) ||
            this.state.selectedOffer ||
            null
        );
    };

    fetchAvailableContacts = (listId: number) =>
        this.props.createOfferUtilsFuncs.fetchAvailableContacts(
            listId,
            (this.getSelectedOffer()! as OfferInfoDto).events.map(
                ({ start, end }) => ({
                    start,
                    end
                })
            )
        );

    fetchContactAvailability = (listId: number) => {
        this.fetchAvailableContacts(listId);
    };

    render() {
        const tabConfig = this.getTabConfig();
        const { offers } = tabConfig[this.state.tab];
        const {
            createOfferUtilsData: data,
            createOfferUtilsFuncs: functions
        } = this.props;
        const allOffersList = this.state.offersUpcoming.data.concat(this.state.offersResolved.data);

        return (
            <Wrapper 
                id="offers-wrapper"
                data-only-offer={this.props.idFromModal !== undefined}
            >
                { (this.state.staleData) &&
                    <div className={"nonblocking-loading-wrapper"} style={{ top: 20, right: 20 }}>
                        <CircularProgress style={{ width: 24, height: 24 }} />
                        <span>
                            Fetching Latest
                        </span>
                    </div>
                }
                {(() => {
                    if (!this.shouldRenderSelected()) return null;

                    if (this.state.selectedOfferId || (this.state.creating))
                    return (
                        <SelectedContainer
                            className={`flex ${
                                this.state.selectedOfferId ? 'child-active' : ''
                            }`}
                            style={{
                                overflow: 'auto',
                                zIndex: 4
                            }}
                        >
                            { this.state.selectedOfferId && this.checkSelectedOfferType() === 'favourite' &&
                                <ViewFavouriteOffer
                                    offer={
                                        this.getSelectedOffer() as EnrichedFavouriteOffer
                                    }
                                    handleViewCancel={
                                        this.handleViewCancel
                                    }
                                    handleDeleteFavouriteOffer={() => {
                                        this.handleDeleteFavouriteOffer();
                                    }}
                                    handleConvertFavouriteToOffer={
                                        this
                                            .handleConvertFavouriteToOffer
                                    }
                                />
                            }
                            { this.state.selectedOfferId && this.checkSelectedOfferType() !== 'favourite' &&
                                <ViewOffer
                                    offerIdToLoad={
                                        (this.getSelectedOffer() as OfferInfoDto).id
                                    }
                                    startChat={this.startChat.bind(this)}
                                    lists={data.lists}
                                    contacts={data.contacts}
                                    handleDeleteOffer={() => {
                                        this.handleDeleteOffer();
                                    }}
                                    handleAddWorkersToOffer={
                                        this.handleAddWorkersToOffer
                                    }
                                    handleViewCancel={this.handleViewCancel}
                                    fetchContactAvailability={
                                        this.fetchContactAvailability
                                    }
                                    handleWithdrawOffer={
                                        this.handleWithdrawOffer
                                    }
                                    handleWithdrawWorker={
                                        this.handleWithdrawWorker
                                    }
                                    handleConfirmationComplete={
                                        this.handleConfirmationComplete
                                    }
                                    handleFavouriteOffer={this.handleFavouriteOffer}
                                    offerVersion={this.state.selectedOfferVersion}
                                />
                            }
                            { (!this.state.selectedOfferId && this.state.creating) &&
                                <CreateOffer
                                    show={!this.state.selectedOfferId && this.state.creating}
                                    onCancel={() => {
                                        history.push("/offers");
                                        // this.setState({
                                        //     creating: false
                                        // });
                                    }}
                                    onReset={() => {
                                        this.props.createOfferUtilsFuncs.reset();
                                    }}
                                    onCreate={this.handleCreated}
                                    organisationName={
                                        this.props.organisationName
                                    }
                                    windowWidth={this.props.windowWidth}
                                    createOfferUtilsData={data}
                                    createOfferUtilsFuncs={functions}
                                    previousTitles={[...new Set(allOffersList.filter(item => item.title).map(item => item.title || ""))]}
                                    previousDescriptions={[...new Set(allOffersList.filter(item => item.description).map(item => item.description || ""))]}
                                    showScheduleTrial={() => {
                                        this.setState({
                                            showScheduleTrial: true
                                        })
                                    }}
                                />
                            }

                            { !this.state.selectedOfferId && this.props.windowWidth < 900 &&
                                <div
                                    className="mask"
                                    onClick={() =>
                                        this.setState({
                                            selectedOfferId: null,
                                            creating: false
                                        })
                                    }
                                />   
                            }
                        </SelectedContainer>
                    );
                })()}
                { (this.state.showScheduleTrial) &&
                    <SimplePrompt
                        id="rota"
                        title='Some events in this new offer are not shown on the schedule'
                        body="Enable the 14 days free trial to see all events on the schedule"
                        buttons={[
                            {
                                text: "Enable Trial",
                                loading: this.state.activatingTrial,
                                onClick: () => {
                                    this.setState({
                                        activatingTrial: true
                                    })
                                    SubscriptionsApi.createCheckoutSession({
                                        items: [
                                            {
                                                priceId: window.location.href.indexOf("portal.updatedge.com") !== -1 ? 3 : 9,
                                                unitsPurchased: 1
                                            }
                                        ]
                                    }).then((sessionId) => {
                                        if ((window as any).dispatchRefreshUserProfile) {
                                            (window as any).onUserGetRedirectTo = "/schedule";
                                            (window as any).dispatchRefreshUserProfile();
                                        }
                                    })
                                }
                            },
                            {
                                text: "Later",
                                onClick: () => {
                                    localStorage.setItem("scheduleTrialPromoDialogueHidden", "true");
                                    this.setState({
                                        showScheduleTrial: false
                                    })
                                }
                            }
                        ]}
                    />
                }
                {!this.props.idFromModal &&
                    <OfferList
                        offersPerPage={OFFERS_PER_PAGE}
                        offers={offers}
                        startInstantOffer={this.startInstantOffer}
                        tabConfig={tabConfig}
                        selectedTab={this.state.tab}
                        loadMore={this.loadMore}
                        selectedOffer={this.getSelectedOffer()}
                        selectOffer={this.selectOffer}
                        windowWidth={this.props.windowWidth}
                        isOfferToday={this.isOfferToday}
                        handleViewCancel={this.handleViewCancel}
                        startChat={this.startChat}
                        reloadList={this.reload}
                        handleDeleteOffer={this.handleDeleteOffer}
                        handleDeleteFavouriteOffer={this.handleDeleteFavouriteOffer}
                        handleKeywordFilterChange={this.handleKeywordFilterChange}
                        showingDemoRes={this.state.showingDemoRes}
                        staleData={this.state.staleData}
                    />
                }
            </Wrapper>
        );
    }
}

export const WrappedOffers = (props) => (
    <CreateOfferUtilsProvider hasOrganisation={props.organistionId != null}>
        {({ data, functions }) => (
            <Offers
                createOfferUtilsData={data}
                createOfferUtilsFuncs={functions}
                // @ts-ignore
                {...props}
            />
        )}
    </CreateOfferUtilsProvider>
);

export default connect(
    ({
        profile: {
            authenticatedUserProfile: { organisationId, organisationName, organisationIsAgency }
        }
    }: ApplicationState) => ({
        organisationId,
        organisationName,
        organisationIsAgency
    }),
    { ...EventsState.actionCreators }
)(withWindowDimensions(WrappedOffers));

const Wrapper = styled.div`
    height: 100%;

    @media (max-width: ${OFFER_MODAL_BREAKPOINT}) {
        height: calc(100vh - 95px);
    }
`;

const SelectedContainer = styled.div`
    margin-left: 20px;
    position: absolute;
    top: 0;
    left: 0;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    margin: 0;
    background-color: rgba(0,0,0,0.3);

    &.child-active {
        display: block;
    }
`;
