/* eslint-disable */
import React from 'react';
import moment from 'moment';
import styled, { keyframes, css } from 'styled-components';
import {
    Button,
    Glyphicon,
    DropdownButton,
    MenuItem,
    Image,
    Modal
} from 'react-bootstrap';
import { connect } from 'react-redux';
import { Rnd } from 'react-rnd';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import AuthenticatedFetch from '../../api/AuthenticatedFetch';
import UnauthenticatedFetch from 'src/api/UnauthenticatedFetch';
import AppConfig from '../../components/config/Config';
import { Spinner } from '../../components/ui-components/Spinner';
import SvgFile from '../../components/svg/file';
import { ProfileImage } from '../../components/ui-components/ProfileImage';
import ProfileApi from '../../api/profile/Profile';
import theme from '../../css/theme';
import 'emoji-mart/css/emoji-mart.css';
import EmojiButton from '../../components/ui-components/EmojiButton';
import { KeyCodes } from '../../constants';
import Hyperlink from '../../components/ui-components/Hyperlink';
import SimpleTooltip from '../../components/ui-components/SimpleTooltip';
import QuestionCircle from '../../components/ui-components/QuestionCircle';
// import { Client } from '../../defs/twilio/client';
import { UserDescriptor } from '../../defs/twilio/userdescriptor';
import { Member } from '../../defs/twilio/member';
// import { Message } from '../../defs/twilio/message';
import { Channel } from '../../defs/twilio/channel';
import { User } from '../../defs/twilio/user';
import InternalTracker from '../../InternalTracker';
import * as EventsState from '../../store/Events';
import withWindowDimensions from '../../components/util/withWindowDimensions';
import VideoRecorder from 'react-video-recorder'
import VideoRenderActions from './video-recorder-components/video-render-actions';
import '../../css/Conversation.css';

import Utilities from '../../Utilities';

import { toast, ToastType } from 'react-toastify';
import * as Notifications from '../../store/Notifications';
import { Conversation, Media, Message, Participant, Client, MediaCategory } from '@twilio/conversations';
import FullScreenLoader from '../ui-components/FullScreenLoader';
import history from '../../history';
import { saveChannels, getChannelIdByUserId } from '../../db/ChannelIdCache';
import { saveThreads, getAllThreads } from '../../db/ChatThreadsCache';
import { getValue, setValue, deleteValue } from 'src/db/KeyValueCache';
import { Setting } from 'src/api/settings/ResponseTypes';
import { Badge, ToggleButton, ToggleButtonGroup } from '@mui/material';

enum Status {
    Loading = 0,
    Ready = 1,
    Error = 2,
    ContactsLoading = 3,
    ContactsSelecting = 4
}

interface LoadedConversation {
    conversation: Conversation,
    participantsAll: Participant[],
    participants: {
        id: string,
        name: string,
    }[],
    messages: Message[],
    dateUpdated: Date,
    diffBetweenLastAndNow: number,
    paginate?: () => void,
    lastReadMessageIndex: number | null,
    hasUnread: boolean,
    hasPhoto?: boolean,
}

interface LastMessageCacheEntry {
    body: string,
    date: Date,
    index: number
}

interface State {
    status: Status;
    channels: ChannelData[];
    contacts: ChatContact[];
    activeChannelId?: string;
    text: string;
    mobileMenuShown?: boolean; // Whether the menu is shown <768px
    receivingAvailabilityFromUserIds: string[];
    ownTwilioIdentity?: string;
    disableBeep: boolean;
    keywords: string;
    currentContacts: string[],
    view: "current" | "archived" | "unread"
    conversations: LoadedConversation[],
    blockingLoadingMessage: string | null,
    lastMessages: {
        [key: string]: LastMessageCacheEntry
    },
    tick: number,
    alreadyFocusedIntoUrlContact: string,
    staleData: boolean,
    impersonating: boolean,
    sendMessageOnEnter: boolean,
    pendingFileSend: Blob | null,
    videoRecorderOpen: boolean,
    errorHelpModal: "NotAllowedError" | "NotReadableError" | null,
    openFile: {
        url: string,
        type: "image" | "video"
    } | null
}

interface ChannelData {
    id: string;
    contact: UserDescriptor;
    member?: Member;
    messages: Message[];
    channel: Channel;
}

interface ChatContact {
    userContactId: string;
    contactName: string;
    notificationStatusTypeId: number;
    deleted: string;
    otherUsersContactId: string;
    otherUsersUserId: string;
    hasPhoto?: boolean;
    sourceUserId?: string;
    reported?: boolean;
}

interface Props {
    styleProps: React.CSSProperties;
    setChatRef: any;
    updateNotificationBadge?: any;
    visible?: boolean;
    windowWidth: number;
}

interface MessageMedia {
    category: string;
    contentType: string;
    filename: string;
    sid: string;
    size: number
}

enum MediaState {
    Loading = 1,
    Loaded = 2,
    Errored = 3
}

interface MediaUrls {
    [key: string]: {
        state: MediaState,
        expiry?: number,
        url?: string
    }
}

const ADMINS = [
    {
        "id": "9c8b042a-97c2-4569-ba93-1d3c1ff83655",
        "name": "Steve Whitaker"
    },
    {
        "id": "f7c5027b-4a3e-4264-bd8c-6442006358e7",
        "name": "Mark Payne"
    }
]

class ConversationComponent extends React.Component<Props, State> {
    private self = '';
    private chatPanel: React.RefObject<HTMLDivElement>;
    private preventPagination = false;
    private client: Client | null = null;
    private newMessageMedia: MessageMedia[] = []; // Uploaded medias to be attached to the text message
    private mediaUrls: MediaUrls = {};
    private preventThreadImagePagination: boolean = false;
    private tickInterval: any = null;
    private onlineStatuses: { [key: string]: boolean } = {};

    constructor(props) {
        super(props);

        this.chatPanel = React.createRef();
        this.preventPagination = false;

        this.state = {
            status: Status.Loading,
            channels: [],
            contacts: [],
            receivingAvailabilityFromUserIds: [],
            text: '',
            disableBeep: false,
            keywords: '',
            currentContacts: [],
            view: "current",
            conversations: [],
            blockingLoadingMessage: null,
            lastMessages: localStorage.getItem('chat-last-messages') ? JSON.parse(localStorage.getItem('chat-last-messages') || "{}") : {},
            tick: 0,
            alreadyFocusedIntoUrlContact: "",
            staleData: true,
            impersonating: false,
            sendMessageOnEnter: true,
            pendingFileSend: null,
            videoRecorderOpen: false,
            openFile: null,
            errorHelpModal: null
        };
        
        setTimeout(() => { this.loadSettings(); }, 1000)
        setTimeout(() => { this.loadSettings(); }, 5000)
    }

    async loadSettings() {
        const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')!) : null;
        if (user) {
            this.setState({
                // @ts-ignore
                sendMessageOnEnter: !user.settings?.find(item => item.settingId === Setting.Chat_NewLineOnChatEnter) || user.settings?.find(item => item.settingId === Setting.Chat_NewLineOnChatEnter).value === "false"
            }, () => {
                // console.log("Send messages on enter: ", this.state.sendMessageOnEnter)
            })
        }
    }

    async handleFileUploaded(msg: Message) {
        if (msg && msg.attachedMedia && msg.attachedMedia[0]) {
            this.newMessageMedia.push({
                category: msg.attachedMedia[0].category,
                contentType: msg.attachedMedia[0].contentType,
                filename: msg.attachedMedia[0].filename,
                sid: msg.attachedMedia[0].sid,
                size: msg.attachedMedia[0].size,
            } as MessageMedia)
            this.loadFile(this.newMessageMedia[this.newMessageMedia.length-1], true);
        }
    }

    async cacheLastMessages(conversations: Conversation[]) {

        let newCaches = this.state.lastMessages;
        for (let i = 0; i < conversations.length; i++) {
            const convo = conversations[i];
            const currentCache = newCaches[convo.sid];
            if (convo.lastMessage && (!currentCache || currentCache.index !== convo.lastMessage.index)) {

                let latestMessages = await convo.getMessages(5);
                if (latestMessages && latestMessages.items[0]) {
                    latestMessages.items = latestMessages.items.reverse();
                    
                    let msg = latestMessages[0];
                    let mainMessageBody = "No Messages";
                    
                    let i = 0;
                    
                    // Rolling back to previous messages if the last one is an unset file
                    while (i < latestMessages.items.length) {
                      msg = latestMessages.items[i];
                      if (msg) {
                        mainMessageBody = msg.body ? (msg.body.indexOf("contentType") !== -1 ? "Files Received" : msg.body) : "No Messages";
                        // i++;
                        if (mainMessageBody !== "No Messages") {
                          break;
                        } else {
                          latestMessages.items.splice(0, 1);
                        }
                      }
                    }       
      
                    newCaches[convo.sid] = {
                        body: mainMessageBody,
                        date: msg.dateCreated,
                        index: msg.index
                    }
                  }
            }
        }
        this.setState({
            lastMessages: newCaches
        })
        localStorage.setItem("chat-last-messages", JSON.stringify(newCaches));
    }

    async updateNewMessagesCounter() {
        // console.log("__ updating badge new chat ", this.state.conversations)
        const el = document.getElementById("new-chats-count");
        let newChats = 0;
        if (el) {
            for (let i = 0; i < this.state.conversations.length; i++) {
                if (this.state.conversations[i].hasUnread) {
                    newChats++
                    // console.log("___  New chat found: " + (this.state.conversations[i].participants[0] ? this.state.conversations[i].participants[0].name : "UNDEFINED"), this.state.conversations[i])
                } 
            }
            // console.log(this.state.conversations.length, newChats, "New chat counts")
            if (newChats > 0) {
                el.style.display = "inline-block";
                el.innerHTML = newChats.toString();
            } else {
                el.style.display = "none";
            }
        }
    }

    async handleNewChannels(conversations: Conversation[], contacts?, ownTwilioIdentity?, hasUnreadOverride?: boolean) {
        let newLoadedConversations: LoadedConversation[] = [];
        contacts = contacts || this.state.contacts;
        ownTwilioIdentity = ownTwilioIdentity || this.state.ownTwilioIdentity;

        for (let i = 0; i < conversations.length; i++) {
            const conversation = conversations[i];

            let participants = await conversation.getParticipants();
            let participantsExcludingCurrentUser = participants.filter(item => item.identity !== ownTwilioIdentity);

            if (participantsExcludingCurrentUser.length !== 0) {
                this.client?.getUser(participantsExcludingCurrentUser[0].identity)
                .then(user => {
                    this.handleOnlineStatusChange(user.identity, user.isOnline)
                    user.on('updated', (data) => {
                    if(data.updateReasons.includes('reachabilityOnline')){
                        this.handleOnlineStatusChange(data.user.identity, data.user.isOnline)
                    }
                    });
                })
            }

            const contactName = contacts.find(cont => cont.otherUsersUserId === (participantsExcludingCurrentUser[0] ? participantsExcludingCurrentUser[0].identity : "a"))?.contactName || "Unknown User";

            // if (contactName === "iwoejfoi joi" || conversation.sid === "CHc5a333bf586343f88b7138c91f7a804f") {
            //     console.log("___ initing with: ", 
            //         {
            //             sum: hasUnreadOverride !== undefined ? hasUnreadOverride : ((conversation && conversation.lastMessage && conversation.lastMessage.index !== null && conversation.lastMessage.index !== undefined && conversation.lastMessage.index !== conversation.lastReadMessageIndex)) ? true : false,
            //             override: hasUnreadOverride,
            //             original: (conversation && conversation.lastMessage && conversation.lastMessage.index !== null && conversation.lastMessage.index !== undefined && conversation.lastMessage.index !== conversation.lastReadMessageIndex) ? true : false,
            //             lastMessage: conversation.lastMessage,
            //             lastReadIndex: conversation.lastReadMessageIndex
            //         }
            //     )
            // }

            newLoadedConversations.push({
                hasUnread: hasUnreadOverride !== undefined ? hasUnreadOverride : ((conversation && conversation.lastMessage && conversation.lastMessage.index !== null && conversation.lastMessage.index !== undefined && conversation.lastMessage.index !== conversation.lastReadMessageIndex)) ? true : false,
                conversation: conversation,
                participantsAll: participants,
                participants: participantsExcludingCurrentUser.map(item => { return { id: item.identity, name: contactName } }),
                lastReadMessageIndex: conversation.lastReadMessageIndex,
                messages: [],
                diffBetweenLastAndNow: Utilities.differenceBetweenDatesSeconds(new Date(conversation.lastMessage ? conversation.lastMessage.dateCreated : conversation.dateUpdated), new Date()),
                dateUpdated: new Date(conversation.lastMessage ? conversation.lastMessage.dateCreated : conversation.dateUpdated)
            })
        }

        setTimeout(() => {
            this.cacheLastMessages(newLoadedConversations.sort(Utilities.dynamicSort("diffBetweenLastAndNow")).map(item => item.conversation));
        }, 1000)

        this.setState({
            status: Status.Ready,
            conversations: [
                ...this.state.conversations.filter(convo => newLoadedConversations.map(item => item.conversation.sid).indexOf(convo.conversation.sid) === -1),
                ...newLoadedConversations.map(item => {
                    item.messages = this.state.conversations.find(cItem => cItem.conversation.sid === item.conversation.sid)?.messages || [];
                    return item;
                })
            ].sort(Utilities.dynamicSort("diffBetweenLastAndNow")),
            staleData: false
        }, async () => {
            const deobjectifiedConversations = this.state.conversations.map(ConversationComponent.removeCircularRefsFromConversations);
            saveThreads(deobjectifiedConversations);
            saveChannels(this.state.conversations.filter(item => item.conversation && item.participants && item.participants[0]).map(item => { return { channelId: item.conversation.sid, userId: item.participants[0].id } }))
            this.focusChatFromUrl();
            this.updateNewMessagesCounter();
            let alreadyContactedUserIds = this.state.conversations.filter(item => this.state.contacts.find(c => item.participants[0] && c.otherUsersUserId === item.participants[0].id)?.hasPhoto).map(item => item.participants[0].id);
            const notYetContacted = ((await this.getContacts()) || []);
            const notYetContactedRealUsers = notYetContacted.filter(c => c.contactName.indexOf("(Demo)") === -1);
            const notYetContactedDemoUsers = notYetContacted.filter(c => c.contactName.indexOf("(Demo)") !== -1);
            alreadyContactedUserIds = alreadyContactedUserIds.concat(notYetContactedRealUsers.map(c => c.otherUsersUserId))
            alreadyContactedUserIds = alreadyContactedUserIds.concat(notYetContactedDemoUsers.map(c => c.otherUsersUserId))
            alreadyContactedUserIds = [...new Set(alreadyContactedUserIds)];
            localStorage.setItem("chat-covers", JSON.stringify(alreadyContactedUserIds.slice(0, 3)))
        });
    }

    static removeCircularRefsFromConversations(originalObj) {
        let obj = Object.assign({}, originalObj)

        return {
            sid: obj.conversation.sid,
            conversation: {
                sid: obj.conversation.sid,
            },
            messages: [],
            diffBetweenLastAndNow: obj.diffBetweenLastAndNow,
            hasUnread: obj.hasUnread,
            lastReadMessageIndex: obj.lastReadMessageIndex,
            participants: obj.participants && obj.participants[0] ? [{
                id: obj.participants[0].id,
                name: obj.participants[0].name
            }] : [],
            participantsAll: obj.participantsAll.map(item => {
                return {
                    id: item.identity,
                    dateUpdated: item.dateUpdated
                }   
            }),
            stale: true
        }
    }

    async getFreshToken() {
        if (window.location.href.indexOf("/external/chat") !== -1) {
            const urlParams = new URLSearchParams(window.location.search);
            const apiKey = urlParams.get('apiKey');
            const ueUserId = urlParams.get('ueUserId');
            const init = await UnauthenticatedFetch.request<null,{ token: string; self: string }>(`${AppConfig.Settings.api.externalUri}/chat/external/${apiKey}/${ueUserId}/token?api-version=1.0`, 'GET', null)
            localStorage.setItem("twillio-token", JSON.stringify({ ...init, timestamp: new Date().getTime() }));
            localStorage.setItem("twillio-token-type", "external");
            return init;
        } else {
            const init = (await AuthenticatedFetch.request<null,{ token: string; self: string }>(`${AppConfig.Settings.api.externalUri}/chat/token`, 'GET', null))
            localStorage.setItem("twillio-token", JSON.stringify({ ...init, timestamp: new Date().getTime() }));
            return init;
        }
    }

    async getToken() {
        let token = localStorage.getItem("twillio-token") ? JSON.parse(localStorage.getItem("twillio-token") || "") : null;
        let tokenRefreshedSinceSeconds = token ? Utilities.differenceBetweenDatesSeconds(new Date(), token.timestamp) : null;
        if (!tokenRefreshedSinceSeconds || tokenRefreshedSinceSeconds > (3600 * 4)) {
            let freshToken = await this.getFreshToken();
            return freshToken;
        } else {
            this.getFreshToken();
            return JSON.parse(localStorage.getItem("twillio-token") || "");
        }
    }

    async getFreshContacts() {
        const urlParams = new URLSearchParams(window.location.search);
        const apiKey = urlParams.get('apiKey');
        const ueUserId = urlParams.get('ueUserId');

        const contacts = window.location.href.indexOf("/external/chat") !== -1 ?
            (await UnauthenticatedFetch.request<null, ChatContact[]>(`${AppConfig.Settings.api.externalUri}/chat/external/${apiKey}/${ueUserId}/contacts?api-version=1.0`, 'GET', null)) :
            (await AuthenticatedFetch.request<null, ChatContact[]>(`${AppConfig.Settings.api.externalUri}/chat/contacts/portal`, 'GET', null, ''));
        let hashes: string[] = [];
        for (let i = 0; i < contacts.length; i++) {
            const contact = contacts[i];
            if (hashes.indexOf(contact.otherUsersUserId) !== -1) {
                contacts.splice(i, 1);
                i++;
            } else {
                hashes.push(contact.otherUsersUserId);
            }
        }
        setValue("chat-contacts", contacts)
        return contacts;
    }

    async getContacts() {
        const staleContacts = await getValue("chat-contacts");
        if (staleContacts) {
            this.getFreshContacts();
            return staleContacts;
        } else {
            let contacts = this.getFreshContacts();
            return contacts;
        }
    }

    async focusChatFromUrl(userIdA?: string) {
        if (window.location.href.indexOf("/chat") !== -1 || userIdA) {
            const external = window.location.href.indexOf("/external/chat") !== -1;
            const urlParams = new URLSearchParams(window.location.search);

            setTimeout( async () => {
                const userId = external ? urlParams.get('targetUserId') : userIdA ? userIdA : window.location.href.split("/chat/")[1]
                if (userId && this.state.alreadyFocusedIntoUrlContact !== userId) {
                    const initialChannelId = await this.handleInitiateChannel(userId);
                    // @ts-ignore
                    const channel = await this.client.getConversationBySid(initialChannelId);
                    // @ts-ignore
                    this.handleNewChannels([channel]);

                    let openThreadAttemptCount = 0;
                    let openThreadInterval = setInterval(() => {
                        const el: HTMLElement | null = document.querySelector("*[data-channel-id='" + initialChannelId + "'");
                        if (el) {
                            el.click();
                            el.scrollIntoView();
                            clearInterval(openThreadInterval);
                            this.setState({
                                alreadyFocusedIntoUrlContact: userId
                            })
                        }
                        openThreadAttemptCount++;
                        if (openThreadAttemptCount > 20) {
                            clearInterval(openThreadInterval);
                        }
                    }, 100)
                }
            }, 300)
        }
    }

    async loadInitialMessages() {
        this.newMessageMedia = [];

        if (!this.activeChannel) {
            return;
        }

        if (this.activeChannel?.paginate) {
            this.scrollChatPanelToEnd();
        } else {
            this.setState({
                blockingLoadingMessage: "Loading Messages"
            })

            let latestMessages = await this.activeChannel?.conversation.getMessages(1000);
            // console.log(latestMessages, " <<<<");
            let currentThread = this.state.conversations.find(item => item.conversation.sid === this.state.activeChannelId);
            currentThread!.hasUnread = false;
            // console.log("____ Marking as read from initial messages load: ", currentThread)
            currentThread!.messages = latestMessages?.items || [];
            currentThread!.paginate = async () => { if (latestMessages?.hasPrevPage) {
                this.setState({
                    blockingLoadingMessage: "Loading messages"
                })
                // @ts-ignore
                let moreMessages = await latestMessages.prevPage().catch(e => console.error);

                this.setState({
                    blockingLoadingMessage: null
                })

                for (let i = 0; i < this.state.conversations.length; i++) {
                    const conversation = this.state.conversations[i];
                    if (conversation.conversation.sid === this.activeChannel?.conversation.sid) {
                        let newConversations = this.state.conversations
                        // @ts-ignore
                        newConversations[i].messages = moreMessages.items.concat(newConversations[i].messages);
                        this.setState({
                            conversations: newConversations.sort(Utilities.dynamicSort("diffBetweenLastAndNow"))
                        }, () => {
                            this.updateNewMessagesCounter();
                        })
                        break;
                    }
                }
            }}

            this.setState({
                blockingLoadingMessage: ""
            })

            let newConversations = this.state.conversations.filter(item => item.conversation.sid !== this.state.activeChannelId)
            if (newConversations && currentThread) {
                newConversations.push(currentThread);

                this.setState({
                    conversations: newConversations.sort(Utilities.dynamicSort("diffBetweenLastAndNow"))
                }, () => {
                    this.scrollChatPanelToEnd();
                    this.updateNewMessagesCounter();
                })
            }
        }
    }

    onFocus() {
        if (!Utilities.isSameDate(new Date(), (window as any).lastDateFocused)) {
            window.location.reload();
        }
    }

    async componentDidMount() {
        if (localStorage.getItem("impersonatedAccessToken")) {
            this.setState({
                impersonating: true
            })
            return;
        }

        window.addEventListener("focus", this.onFocus.bind(this));

        (window as any).openChatForUser = (userId: string) => {
            this.focusChatFromUrl(userId);
        }

        // Removing token if switched between external/internal
        if (window.location.href.indexOf("/external/chat") !== -1 || localStorage.getItem("twillio-token-type") === "external") {
            await deleteValue("chat-contacts");
            localStorage.removeItem("chat-contacts");
            localStorage.removeItem("twillio-token");
            localStorage.removeItem("twillio-token-type");
        }

        clearInterval(this.tickInterval);
        this.tickInterval = setInterval(() => {
            this.setState({
                tick: Utilities.randomIntFromInterval(0, 1000)
            })
        }, 1000)

        const staleConversations = await getAllThreads();

        if (window.location.pathname.startsWith("/external")) {
            const urlParams = new URLSearchParams(window.location.search);

            InternalTracker.init({
                emailAddress: urlParams.get('ueUserId'),
                id: urlParams.get('ueUserId'),
            })

            InternalTracker.trackEvent("External Chat Opened");
        }

        this.setState({
            contacts: [],
            conversations: staleConversations && staleConversations.length ? staleConversations.sort(Utilities.dynamicSort("diffBetweenLastAndNow")) : [],
            staleData: staleConversations && staleConversations.length ? true : false,
        }, async () => {

            if (this.state.staleData) {
                this.setState({
                    status: Status.Ready
                })
            }
        
            try {
                const init = await this.getToken();

                const contacts = await this.getContacts();

                const client = new Client(init.token);
                // @ts-ignore
                this.client = client;
                client.on('stateChanged', async (state) => {
                    if (state === 'initialized') {
                        let allConvos: Conversation[] = [];
                        let convos = await client.getSubscribedConversations();
                        allConvos = allConvos.concat(convos.items);
                        while (convos.hasNextPage) {
                            convos = await convos.nextPage();
                            allConvos = allConvos.concat(convos.items);
                        }

                        this.handleNewChannels(allConvos, contacts, init.self);
                        client.on('messageAdded', m => this.handleReceive(m));

                        if (this.state.activeChannelId) {
                            setTimeout(() => {
                                // had an active channel that failed to load, so restarted client
                                this.loadInitialMessages();
                            }, 1000)
                        }
                    }
                })

                client.on("connectionError", async (e) => {
                    console.error("Chat connection error", e);
                    if (this.client) {
                        this.client.removeAllListeners();
                        this.client.shutdown();
                        setTimeout(() => {
                            this.client = null;
                            this.componentDidMount();
                        }, 4000)
                    }
                })

                const urlParams = new URLSearchParams(window.location.search);

                this.setState({
                    contacts: contacts,
                    currentContacts: contacts.filter(item => !item.deleted && item.notificationStatusTypeId !== 4).map(item => item.otherUsersUserId),
                    ownTwilioIdentity: window.location.href.indexOf("/external/chat") !== -1 ? urlParams.get('ueUserId') : init.self
                });

            } catch (ex) {
                console.log("Chat failed to be inited", ex)
                this.setState({
                    status: Status.Error
                }, () => {
                    setTimeout(() => {
                        this.componentDidMount();
                    }, 1000)
                });
            }
        })
        this.props.setChatRef(this);
    }

    componentDidUpdate(_, prevState: State) {
        if (prevState.activeChannelId !== this.state.activeChannelId) {
            this.scrollChatPanelToEnd();
        }
    }

    render() {

        const DOM_CONTENT = <Wrapper
            style={{
                borderRadius: '16px',
                overflow: 'hidden',
                backgroundColor: 'white',
                ...this.props.styleProps
            }}
            id="chat-wrapper"
            data-full-screen={window.location.href.indexOf("/chat") !== -1}
        >
            <span className='dragger dragger-btn'>
                <i className="fas fa-grip-vertical "></i>
                <span>Drag to Move</span>
            </span>
            <i 
                className="fas fa-times-circle chat-close-btn"
                onClick={() => {
                    if (this.state.openFile) {
                        this.setState({
                            openFile: null
                        })
                    } else if (this.state.videoRecorderOpen) {
                        this.setState({
                            videoRecorderOpen: false
                        })
                    } else {
                        const closeBtnEl = document.getElementById("chat-btn");
                        if (closeBtnEl) {
                            closeBtnEl.click();
                        }
                        this.setState({
                            activeChannelId: undefined
                        })
                    }
                }}
            />
            <div
                className="layout vertical"
                style={{
                    borderRadius: '5px',
                    background: 'none',
                    position: 'relative',
                }}
            >
                <div
                    className="row"
                    style={{
                        borderRadius: '5px'
                    }}
                >
                    <div
                        className="hpanel pageHeader"
                        style={{
                            marginBottom: 0,
                        }}
                    >
                        <div
                            className="panel-body"
                            style={{
                                border: 'none',
                                paddingLeft: '4rem',
                                marginBottom: '3px',
                                borderBottom: '1px solid #ddd',
                                display: 'none'
                            }}
                        >
                            <div className="glyph pull-right">
                                <Glyphicon
                                    glyph="cloud"
                                    bsClass="glyphicon-6x text-primary"
                                />
                            </div>
                            <h2 className="font-light m-b-xs">Chat</h2>
                            <small>
                                Chat with your contacts. Start the
                                conversation.
                            </small>
                        </div>
                    </div>
                </div>
                {(() => {
                    switch (this.state.status) {
                        case Status.Loading:
                            return this.renderLoading();
                        case Status.Error:
                            return this.renderError();
                        default:
                            return this.renderChat();
                    }
                })()}
            </div>
            { (this.state.openFile) &&
                <div className='document-viewer'>
                    { (this.state.openFile.type === "image") &&
                        <div className='img'>
                            <img src={this.state.openFile.url} />
                        </div>
                    }
                    { (this.state.openFile.type === "video") &&
                        <div className='video'>
                            <video controls autoPlay>
                                <source src={this.state.openFile.url} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    }
                </div>
            }
            { (this.state.videoRecorderOpen) &&
                <div className='video-recorder-wrapper'>
                    <VideoRecorder
                        chunkSize={250}
                        constraints={{
                            audio: true,
                            video: true
                        }}
                        countdownTime={0}
                        isFlipped={true}
                        mimeType={window.MediaRecorder.isTypeSupported("video/mp4") ? "video/mp4" : "video/webm"}
                        onError={(e) => {
                            let knownError: "NotAllowedError" | "NotReadableError" | null = null;
                            if (typeof e === "object") {
                                console.log(e, e.name, "<< Error")
                                if (e.name === "NotAllowedError") {
                                    knownError = "NotAllowedError";
                                } else if (e.name === "NotReadableError") {
                                    knownError = "NotReadableError";
                                }
                            }

                            console.log(e);
                            InternalTracker.trackEvent("Chat Video Record Error", {
                                error: knownError || "Unknown"
                            })

                            if (knownError) {
                                this.setState({
                                    errorHelpModal: knownError
                                })
                            } else {
                                toast.error("Unknown error while recording video")
                            }

                            this.setState({
                                videoRecorderOpen: false
                            })
                        }}
                        onRecordingComplete={(file) => {
                            InternalTracker.trackEvent("Chat Video Record Ended");
                            this.setState({
                                pendingFileSend: file
                            })
                        }}
                        onPauseRecording={() => {
                            // send
                            console.log("onPauseRecording()")
                            if (this.state.pendingFileSend) {
                                InternalTracker.trackEvent("Chat Video Record Processed");
                                // convert from blob to file
                                const blobType = this.state.pendingFileSend.type;
                                const file = new File([this.state.pendingFileSend], "Video." + (blobType === "video/mp4" ? "mp4" : "webm"), { type: blobType });
                                this.handleSendFile(file);
                                this.setState({
                                    videoRecorderOpen: false
                                })
                            }
                        }}
                        onResumeRecording={() => {
                            console.log("onResumeRecording()")
                            // close
                            this.setState({
                                videoRecorderOpen: false
                            })
                        }}
                        renderActions={VideoRenderActions}
                        isOnInitially={true}
                        showReplayControls={true}
                        timeLimit={60000}
                    />
                </div>
            }
            <Modal
                show={this.state.errorHelpModal !== null}
                onHide={() => { 
                    this.setState({ errorHelpModal: null })
                }}
                className="video-error-help-modal base-modal"
            >
                <Modal.Body style={{
                    background: "white",
                    borderRadius: 12
                }}>
                    <h2>Failed to start recording</h2>
                    <p>
                        { this.state.errorHelpModal === "NotAllowedError" ? 
                        "You have denied access to your camera and microphone. Please enable access and try again." :
                            this.state.errorHelpModal === "NotReadableError" ?
                            "Your microphone or camera is most probably used by another application. Please close the other application and try again." : ""
                        }
                    </p>

                    { (
                        this.state.errorHelpModal === "NotAllowedError" &&
                        (navigator.userAgent.includes("Chrome") || navigator.userAgent.includes("Edg"))
                    ) ?
                        <div className='resolution'>
                            Copy the below link and paste it in your browser's address bar to open the settings page. Make sure both microphone and camera access is granted
                            <code>
                                {navigator.userAgent.includes("Chrome") ? "chrome://settings/content/siteDetails?site=" + window.location.host : "edge://settings/content/siteDetails?site=" + window.location.host}
                            </code>
                        </div>
                        :
                        <div className='resolution'>
                            Please change your privacy settings in your browser settings.
                        </div>
                    }
                    <div className='options'>
                        <button 
                            onClick={() => {
                                this.setState({
                                    errorHelpModal: null
                                })
                            }}
                        >
                            Cancel
                        </button>
                        <button 
                            onClick={() => {
                                this.setState({
                                    errorHelpModal: null
                                }, () => {
                                    this.setState({
                                        videoRecorderOpen: true
                                    });
                                })
                            }}
                            className='submit'
                            style={{ background: theme.colours.blue2 }}
                        >
                            Try Again
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
            <FullScreenLoader view="chat" loadingMessage={this.state.blockingLoadingMessage || ""} />
        </Wrapper>

        const desktopSize = this.props.windowWidth > 760;

        if (!desktopSize || window.location.href.indexOf('chat') !== -1) {
            return DOM_CONTENT
        } else {
            return (
                <Rnd
                    default={{
                        x: 60,
                        y: 125,
                        width: 740,
                        height: 600,
                    }}
                    minWidth={740}
                    minHeight={400}
                    dragHandleClassName={"dragger"}
                >
                    {DOM_CONTENT}
                </Rnd>
            )
        } 

    }

    renderLoading() {
        if (this.state.impersonating) {
            return (
                <div
                    className="layout vertical center-center flex"
                    style={{ background: 'white', borderRadius: '5px' }}
                >
                    <div>
                        Chat is not available while impersonating
                    </div>
                </div>
            )
        }

        return (
            <div
                className="layout vertical center-center flex"
                style={{ background: 'white', borderRadius: '5px' }}
            >
                <Spinner
                    text={
                        <>
                            Loading...
                            <br />
                            <br />
                            <SimpleTooltip
                                id="chat-loading-helper"
                                text="If the chat interface does not appear within a few
                            seconds, you may be blocked from using this feature
                            by your IT administrator. Please speak to them about
                            getting the service unblocked."
                            >
                                <div>
                                    Having trouble?
                                    <QuestionCircle />
                                </div>
                            </SimpleTooltip>
                        </>
                    }
                />
            </div>
        );
    }

    renderChat() {
        const active = this.activeChannel;

        return (
            <div
                className="layout horizontal flex"
                style={{
                    position: 'relative',
                    borderRadius: '5px',
                    background: 'none'
                }}
            >
                <Conversations
                    className="layout vertical"
                    style={{
                        background: 'white',
                        borderRadius: '5px'
                    }}
                    mobileShown={
                        !!this.state.mobileMenuShown ||
                        !this.state.activeChannelId
                    }
                >
                    {(() => {
                        switch (this.state.status) {
                            case Status.ContactsLoading:
                                return this.renderContactsLoading();
                            default:
                                return this.renderConversations();
                        }
                    })()}
                </Conversations>
                <div
                    className="layout vertical flex"
                    style={{
                        background: 'white',
                        width: '100%',
                        marginLeft: this.props.windowWidth > 760 ? '3px' : 0,
                        borderLeft: this.props.windowWidth > 760 ? '1px solid #bbb' : 'none'
                    }}
                >
                    {active && this.state.status == Status.Ready
                        ? this.renderActiveConversation()
                        : this.renderNoneSelected()}
                </div>
            </div>
        );
    }

    renderError() {
        return (
            <div className="flex layout vertical center-center">
                <SvgFile style={{ fontSize: '40px' }} />
                <h4>Chat failed to load</h4>
                <Button onClick={() => location.reload()}>Reload</Button>
            </div>
        );
    }

    renderNoneSelected() {
        return (
            <div className="layout vertical center-center flex">
                <i
                    className="fa fa-comments"
                    style={{ fontSize: '50px' }}
                />
                <h4
                    style={{
                        padding: '0 24px',
                        lineHeight: '1.52em',
                        textAlign: 'center'
                    }}
                >
                    Start the conversation with your contacts by
                    selecting a contact on the left, or clicking on "New
                    Conversation"
                </h4>
            </div>
        );
    }

    renderConversations() {
        const currentThreadsCount = this.state.conversations.filter(item => item.participants[0] && this.state.currentContacts.indexOf(item.participants[0].id) !== -1).length;
        const archivedThreadsCount = this.state.conversations.filter(item => item.participants[0] && this.state.currentContacts.indexOf(item.participants[0].id) === -1).length;
        const unreadThreadsCount = this.state.conversations.filter(item => item.hasUnread).length;

        return (
            <>
                <h2
                    style={{
                        fontWeight: 600,
                        padding: '6px 12px 2px 12px',
                        fontSize: '1.52em'
                    }}
                >
                    My Chats
                    <ToggleButtonGroup
                        color="primary"
                        value={this.state.view}
                        exclusive
                        style={{
                            width: '100%',
                            marginTop: 8
                        }}
                        onChange={(e, value) => {
                            this.setState({
                                view: value as "current" | "unread" | "archived"
                            })
                        }}
                        aria-label="Filter"
                        >
                        <ToggleButton style={{ padding: '8px 6px', width: '33.3333%', justifyContent: 'left' }} value="current">
                            Current
                            <Badge style={{ marginLeft: 14 }} badgeContent={currentThreadsCount} color="primary"></Badge>
                        </ToggleButton>
                        <ToggleButton style={{ padding: '8px 6px', width: '33.3333%', justifyContent: 'left' }} value="unread">
                            Unread
                            <Badge style={{ marginLeft: 14 }} badgeContent={unreadThreadsCount} color="primary"></Badge>
                        </ToggleButton>
                        <ToggleButton style={{ padding: '8px 6px', width: '33.3333%', justifyContent: 'left' }} value="archived">
                            Archived
                            <Badge style={{ marginLeft: 14 }} badgeContent={archivedThreadsCount} color="primary"></Badge>
                        </ToggleButton>
                    </ToggleButtonGroup>
                </h2>
                <input
                    type="text"
                    className="form-control m-l-xs m-r-xs"
                    placeholder="Search Contacts"
                    value={this.state.keywords}
                    style={{
                        margin: "0 10px 8px 10px",
                        width: "calc(100% - 20px)"
                    }}
                    onChange={(ev) => {
                        InternalTracker.trackEvent("Chat Contacts Searched", {
                            keywords: ev.target.value
                        })
                        this.setState({ keywords: ev.target.value })
                    }}
                /> 
                <div className="menu-overflow flex">
                    { this.state.conversations
                        .map((c) => {
                            if (!c.participants.length) {
                                return null;
                            }

                            const contact = c.participants[0];
                            const contactExtended = this.state.contacts.find(item => item.otherUsersUserId === contact.id);
                            const contactName = contact.name;

                            // Threads with non matching name
                            if (this.state.keywords && contactName.toLowerCase().indexOf(this.state.keywords.toLowerCase()) === -1)
                                return null;

                            if (window.location.href.indexOf("/external/chat") === -1) {
                                if (this.state.view === "unread" && !c.hasUnread) {
                                    return null;
                                }
                                // Not showing no-longer sharing contacts in current view
                                if (this.state.currentContacts.indexOf(contact.id) === -1 && this.state.view !== "archived")
                                    return null;

                                // Not showing currently sharing contacts in archived view
                                if (this.state.currentContacts.indexOf(contact.id) !== -1 && this.state.view === "archived")
                                return null;    
                            }                     
                                
                            const imageUrl = ProfileApi.getProfileImageUrl(contact.id);
                            const lastMessage = this.state.lastMessages[c.conversation.sid];
                            const lastMessageBody = lastMessage ? ( (lastMessage?.body && lastMessage?.body.indexOf("£££") !== -1) ? lastMessage?.body.split("£££")[1] : (lastMessage?.body && lastMessage?.body.indexOf("$$$") !== -1) ? lastMessage?.body.split("$$$")[1] : lastMessage.body ) : null;
                            const isRead = !c.hasUnread

                            // if (contactName === "iwoejfoi joi") {
                            //     console.log("___ Read :", isRead + " | ", lastMessage)
                            // }
                            
                            return (
                                <ConversationHeader
                                    key={`channel-header-${c.conversation.sid}`}
                                    className="layout horizontal center chat-contact"
                                    data-name={contactName}
                                    active={
                                        this.activeChannel &&
                                        c.conversation.sid == this.activeChannel.conversation.sid
                                    }
                                    data-channel-id={c.conversation.sid}
                                    onClick={() => {
                                        this.openExistingThread(c.conversation.sid);
                                    }}
                                    isOnline={false /* contact.online*/}
                                    style={{
                                        position: 'relative'
                                    }}
                                >
                                    { this.onlineStatuses[contact.id] && <span className="online-dot" /> }
                                    { (this.props.visible) &&
                                        <LazyLoadImage
                                            height={50}
                                            src={imageUrl}
                                            width={50}
                                        />
                                    }
                                    <div className="flex">
                                        <h5 
                                            className="overflow-ellipsis"
                                            data-report-blurred={contactExtended && contactExtended.reported}
                                            data-user-id={contact.id}
                                        >
                                            {contactName}
                                            <span>
                                                {this.state.receivingAvailabilityFromUserIds.indexOf(
                                                    contactName
                                                ) !== -1
                                                    ? ''
                                                    : ''}
                                            </span>
                                        </h5>
                                        <div className="overflow-ellipsis">
                                            {!isRead &&
                                                <span
                                                    style={{
                                                        background: '#6eb9f3',
                                                        color: 'white',
                                                        padding: '2px 7px',
                                                        marginRight: 6,
                                                        borderRadius: 8,
                                                        display: 'inline-block'
                                                    }}
                                                >NEW</span>
                                            }
                                            {lastMessageBody || "-"}
                                        </div>
                                    </div>
                                    {c.dateUpdated && (
                                        <span>
                                            {Utilities.formatDate(c.dateUpdated, "d mms (YYYY)")}
                                            <br />
                                            {moment(
                                                c.dateUpdated
                                            ).format('HH:mm')}
                                        </span>
                                    )}
                                </ConversationHeader>
                            );
                    }) }
                    { this.state.currentContacts.filter(cc => this.state.conversations.map(conversation => conversation.participants[0] ? conversation.participants[0].id : null).indexOf(cc) === -1 ).map((cc) => {
                        const contact = this.state.contacts.find(c => c.otherUsersUserId === cc);
                        if (!contact) {
                            return null;
                        }

                        if (this.state.view === "unread") {
                            return null;
                        }

                        // Contacts with non matching name
                        if (this.state.keywords && contact.contactName.toLowerCase().indexOf(this.state.keywords.toLowerCase()) === -1)
                            return null;

                        // Not showing in archived view 
                        if (this.state.view === "archived") {
                            return null;
                        }

                        if (contact.otherUsersUserId === contact.sourceUserId) {
                            return null;
                        }
                        
                        return (
                            <ConversationHeader
                                key={`uninited-channel-header-${contact.otherUsersContactId}`}
                                className="layout horizontal center chat-contact"
                                data-name={contact.contactName}
                                onClick={() => {
                                    this.handleStartChat(contact.otherUsersUserId)
                                }}
                                style={{
                                    position: 'relative'
                                }}
                            >
                                { (this.props.visible) &&
                                    <LazyLoadImage
                                        height={50}
                                        src={ProfileApi.getProfileImageUrl(contact.otherUsersUserId)}
                                        width={50}
                                    />
                                }
                                <div className="flex">
                                    <h5
                                        className="overflow-ellipsis"
                                        data-report-blurred={contact.reported}
                                        data-user-id={contact.otherUsersUserId}
                                    >
                                        {contact.contactName}
                                    </h5>
                                    <div>
                                        Never messaged - Click to start a chat
                                    </div>
                                </div>
                            </ConversationHeader>
                        )
                    }) }
                </div>
            </>
        );
    }

    renderContactsLoading() {
        return (
            <div className="layout vertical center-center flex">
                <h4>Loading contacts...</h4>
                <Spinner />
            </div>
        );
    }

    renderActiveConversation() {
        const active = this.activeChannel!;
        const contactSingle = active.participants[0];
        const contactRecord = this.state.contacts.find(c => contactSingle && c.otherUsersUserId === contactSingle.id);

        const blocked = contactRecord?.notificationStatusTypeId === 4;
        const deleted = contactRecord?.deleted;
        const canMessage = (active && !blocked && !deleted) || window.location.href.indexOf("/external/chat") !== -1;

        if (!contactRecord) {
            return this.renderLoading();
        }

        return (
            <>
                <ChatHeader className="layout horizontal center justified">
                    <span
                        onClick={() => {
                            this.setState({ mobileMenuShown: true });
                        }}
                    >
                        <i className="fa fa-chevron-left m-r-xs" />
                    </span>
                    { this.onlineStatuses[contactSingle.id] && <span className="online-dot open-dot" /> }
                    <h4
                        data-report-blurred={contactRecord && contactRecord.reported}
                        data-user-id={contactSingle.id}
                    >
                        {contactSingle.name}
                    </h4>
                </ChatHeader>
                <ChatPanel
                    ref={this.chatPanel}
                    className="flex layout vertical"
                    onScroll={(e) => {
                        // @ts-ignore
                        if (e.target.scrollTop < 200 && !this.preventPagination) {
                            this.preventPagination = true;
                            if (this.activeChannel && this.activeChannel.paginate)
                                this.activeChannel.paginate();
                            setTimeout(() => {
                                this.preventPagination = false;
                            }, 1000)
                        }
                        this.onActiveThreadScroll();
                    }}
                >
                    {active && active.messages.length ? (
                        this.renderActiveMessages(active)
                    ) : (
                        <div className="layout vertical center-center flex">
                            <i
                                className="fas fa-comment-dots"
                                style={{ fontSize: '50px' }}
                            />
                            <h4>Start your conversation below</h4>
                        </div>
                    )}
                </ChatPanel>
                { canMessage ?
                    <div
                        className="layout horizontal center justified new-message-wrapper"
                        style={{ padding: '10px' }}
                    >
                        { (this.newMessageMedia && this.newMessageMedia.length !== 0) &&
                            <div className='file-list clearfix'>
                            { this.newMessageMedia.map((media, mediaI) => {
                                const isVideo = (media.filename.toLowerCase().endsWith(".mp4") || media.filename.toLowerCase().endsWith(".mov") || media.filename.toLowerCase().endsWith(".webm"));
                                const isPhoto = (media.filename.toLowerCase().endsWith(".jpg") || media.filename.toLowerCase().endsWith(".png") || media.filename.toLowerCase().endsWith(".jpeg"));
                                return (
                                    <div
                                        className='body-file'
                                        data-sid={media.sid}
                                        data-contenttype={media.contentType}
                                        data-category={media.category}
                                        data-size={media.size}
                                        data-filename={media.filename}
                                        onClick={() => {
                                        }}
                                    >
                                        <i
                                            className="fas fa-times-circle remove-file-btn"
                                            onClick={() => {
                                                this.newMessageMedia.splice(mediaI, 1);
                                            }}
                                        />
                                        { (this.mediaUrls[media.sid] && this.mediaUrls[media.sid].url && media.contentType.startsWith("image/")) &&
                                            <img src={this.mediaUrls[media.sid].url} />
                                        }
                                        <div>
                                            <i className={"fas " + (isVideo ? "fa-video" : isPhoto ? "fa-image" : "fa-paperclip")} />
                                            <span>{media.filename || "Unnamed File"}</span>
                                        </div>
                                    </div>
                                )
                            }) }
                            </div>
                        }
                        <EmojiButton
                            onSelected={(emoji) =>
                                this.setState({
                                    text: (this.state.text || '') + emoji
                                })
                            }
                        />
                        <i 
                            className="fas fa-paperclip attach-image-btn"
                            onClick={ async () => {
                                if ((window as any).Cypress) {
                                    const fil = Utilities.datURLtoFile("data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wBDAAUDBAQEAwUEBAQFBQUGBwwIBwcHBw8LCwkMEQ8SEhEPERETFhwXExQaFRERGCEYGh0dHx8fExciJCIeJBweHx7/2wBDAQUFBQcGBw4ICA4eFBEUHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh7/wAARCADGAP8DASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAUI/8QAGxABAAAHAAAAAAAAAAAAAAAAAAEDBQY2dLL/xAAWAQEBAQAAAAAAAAAAAAAAAAAAAgP/xAAaEQEAAgMBAAAAAAAAAAAAAAAAAQMCMnEz/9oADAMBAAIRAxEAPwDXVr4zS9OTxBRTrXxml6cniCi0u9MuyivSOADNYAAAAAAAAAAAAAAAAAAAAAAAAAAACda+M0vTk8QUU618ZpenJ4gotLvTLsor0jgAzWAAAAAAAAAAAAAAAAAAAAAAAAAAAAnWvjNL05PEFFOtfGaXpyeIKLS70y7KK9I4AM1gAAAAAAAAAAAAAAAAAAAAAAAAAAAJ1r4zS9OTxBRTrXxml6cniCi0u9MuyivSOADNYAAAAAAAAAAAAAAAAAAAAAAAAAAACda+M0vTk8QUU618ZpenJ4gotLvTLsor0jgAzWAAAAAAAAAAAAAAAAAAAAAAAAAAAAnWvjNL05PEFFOtfGaXpyeIKLS70y7KK9I4AM1gAAAAAAAAAAAAAAAAAAAAAAAAAAAJ1r4zS9OTxBRTrXxml6cniCi0u9MuyivSOADNYAAAAAAAAAAAAAAAAAAAAAAAAAAACda+M0vTk8QUU618ZpenJ4gotLvTLsor0jgAzWAAAAAAAAAAAAAAAAAAAAAAAAAAAAnWvjNL05PEFFOtfGaXpyeIKLS70y7KK9I4AM1gAAAAAAAAAAAAAAAAAAAAAAAAAAAJ1r4zS9OTxBRTrXxml6cniCi0u9MuyivSOADNYAAAAAAAAAAAAAAAAAAAAAAAAAAACda+M0vTk8QUU618ZpenJ4gotLvTLsor0jgAzWAAAAAAAAAAAAAAAAAAAAAAAAAAAAnWvjNL05PEFFOtfGaXpyeIKLS70y7KK9I4AM1gAAAAAAAAAAAAAAAAAAAAAAAAAAAJ1r4zS9OTxBRBpd6ZdlFekcAGawAAAAAAAAAAAAAAAAAAAAAAAAAAAH//2Q==", "1.jpeg");
                                    this.handleSendFile(fil);
                                } else {
                                    let inputEl = document.getElementById("chat-file-input");
                                    if (inputEl) {
                                        inputEl.click();
                                    }
                                }
                            }}
                        ></i>
                        <i 
                            className="fas fa-video attach-video-btn"
                            onClick={ async () => {
                                InternalTracker.trackEvent("Chat Video Record Opened")
                                this.setState({
                                    videoRecorderOpen: true
                                })
                            }}
                        ></i>
                        <input 
                            id="chat-file-input" 
                            type="file" 
                            style={{
                                display: 'none'
                            }}
                            onChange={(e) => {
                                this.handleSendFile(e.target.files ? e.target.files[0] : null);
                            }}
                        ></input>
                        <textarea
                            placeholder="Type your new message here"
                            id="new-message-input"
                            value={this.state.text}
                            onKeyUp={(e) => {
                                let newMessage = (e.target as HTMLInputElement).value;
                                if (newMessage.length !== 0) {
                                    // @ts-ignore
                                    e.target.style.height = e.target.scrollHeight + 'px';
                                }
                            }}
                            onChange={(ev) => { this.setState({ text: ev.target.value.trimLeft() }) }}
                            onKeyDown={(ev) => {
                                if (ev.keyCode == KeyCodes.ENTER) {
                                    if (this.state.sendMessageOnEnter) {
                                        this.handleSend()   
                                    } else {
                                        // add new line
                                        ev.preventDefault();
                                        this.setState({ text: this.state.text + "\n" })
                                    }
                                }
                            }}
                            style={{
                                height: 30,
                                width: "100%",
                                resize: "none",
                                margin: "0 8px",
                                padding: "6px",
                                border: "1px solid #dbdbdb",
                                borderRadius: 8
                            }}
                        />
                        <SendButton
                            className="fa fa-paper-plane"
                            id="new-message-send-btn"
                            disabled={!this.isMessageSendable()}
                            onClick={this.handleSend}
                        />
                    </div>
                    :
                    <div className='new-message-cannot-send' onClick={() => {
                        history.push("/invite#email")
                    }}>
                        <p>No longer a contact</p>
                        <button>Reinvite</button>
                    </div>
                }
            </>
        );
    }

    renderActiveMessages(conversation: LoadedConversation) {
        return conversation.messages.map((m) => {

            const mine = m.author == this.state.ownTwilioIdentity;
            const imageUrl = ProfileApi.getProfileImageUrl(m.author);
            const authorExtendedContact = this.state.contacts.find(item => item.otherUsersUserId === m.author);
            // @ts-ignore
            const isRead = conversation.lastReadMessageIndex >= m.index

            let medias = m.body && m.body.indexOf("£££") !== -1 ? JSON.parse(m.body.split("£££")[0]) as MessageMedia[] : [];
            let message = (m.body && m.body.indexOf("£££") !== -1) ? m.body.split("£££")[1] : (m.body && m.body.indexOf("$$$") !== -1) ? m.body.split("$$$")[1] : m.body;

            if (m && m.attachedMedia && m.attachedMedia[0]?.filename) {
                return null;
            }

            return (
                <ChatMessage key={m.sid} mine={mine} isRead={isRead}>
                    {!mine && (
                        <span className="m-r">
                            { (this.props.visible) &&
                                <LazyLoadImage
                                    height={35}
                                    src={imageUrl}
                                    width={35}
                                />
                            }
                        </span>
                    )}
                    <div className="bubble" data-msg={message}>
                        { (medias && medias.length !== 0) &&
                            <div className='file-list clearfix'>
                                { medias.map(media => {
                                    const isVideo = (media.filename.toLowerCase().endsWith(".mp4") || media.filename.toLowerCase().endsWith(".mov") || media.filename.toLowerCase().endsWith(".webm"));
                                    const isPhoto = (media.filename.toLowerCase().endsWith(".jpg") || media.filename.toLowerCase().endsWith(".png") || media.filename.toLowerCase().endsWith(".jpeg"));
                                    
                                    return (
                                        <div
                                            className='body-file'
                                            data-sid={media.sid}
                                            data-contenttype={media.contentType}
                                            data-category={media.category}
                                            data-size={media.size}
                                            data-filename={media.filename}
                                            onClick={() => {
                                                this.loadFile(media)
                                            }}
                                        >
                                            { (this.mediaUrls[media.sid] && this.mediaUrls[media.sid].url && media.contentType.startsWith("image/")) &&
                                                <img src={this.mediaUrls[media.sid].url} />
                                            }
                                            <div>
                                                <i className={"fas " + (isVideo ? "fa-video" : isPhoto ? "fa-image" : "fa-paperclip")} />
                                                <span>{isVideo ? "Video" : isPhoto ? "Image" : (media.filename || "Unnamed File")}</span>
                                            </div>
                                        </div>
                                    )
                                }) }
                            </div>
                        }
                        <div className='meta'>
                            { message &&
                                <span className="body">
                                    {message}
                                    {message.indexOf("Hi there! Thanks for starting a conversation, but i'm just a demo bot!") !== -1 &&
                                        <span className='contact-options'>
                                            { ADMINS.map(admin => {
                                                return (
                                                    <button onClick={() => {
                                                        this.handleStartChat(admin.id)
                                                    }}>
                                                        { (this.props.visible) &&
                                                            <Image
                                                                src={ProfileApi.getProfileImageUrl(
                                                                    admin.id
                                                                )}
                                                                circle
                                                                alt={admin.name}
                                                            />   
                                                        }
                                                        <span>{admin.name}</span>
                                                    </button>
                                                )
                                            }) }
                                        </span>
                                    }
                                </span>
                            }
                            <span className="timestamp">
                                {moment(m.dateUpdated).format('DD MMM - HH:mm')}
                                {mine && (
                                    <i
                                        className="fa fa-check"
                                        title={
                                            isRead
                                                ? 'Message has been seen'
                                                : 'Message has not yet been seen'
                                        }
                                    />
                                )}
                            </span>
                        </div>
                    </div>
                    {mine && (
                        <span className="m-l">
                            { (this.props.visible) &&
                                <LazyLoadImage
                                    height={35}
                                    src={imageUrl}
                                    width={35}
                                />
                            }
                        </span>
                    )}
                </ChatMessage>
            );
        });
    }

    handleOnlineStatusChange = (userId: string, online: boolean) => {
        let newOnlineStatuses = this.onlineStatuses;
        newOnlineStatuses[userId] = online;
        this.onlineStatuses = newOnlineStatuses;
    }    

    handleReceive = async (msg: Message) => {
        let newConversationGroup: LoadedConversation | null = null;

        let newConversations = Object.assign([], this.state.conversations) as LoadedConversation[];
        for (let i = 0; i < newConversations.length; i++) {
            const conversation = newConversations[i];
            if (conversation.conversation.sid === msg.conversation.sid) {
                newConversations[i].hasUnread = true;
                newConversations[i].messages.push(msg);
                newConversations[i].diffBetweenLastAndNow = Utilities.differenceBetweenDatesSeconds(new Date(msg.dateCreated), new Date());
                // console.log("____ mark as unread " + msg.body + " " + newConversations[i].diffBetweenLastAndNow)
                newConversationGroup = newConversations[i];
                const lastMessageIsFromMe = newConversationGroup.messages && newConversationGroup.messages[newConversationGroup.messages.length - 1].author === this.state.ownTwilioIdentity;
                this.handleNewChannels([newConversationGroup.conversation], undefined, undefined, !lastMessageIsFromMe);
            } else {
                newConversations[i].diffBetweenLastAndNow = Utilities.differenceBetweenDatesSeconds(new Date(newConversations[i].dateUpdated), new Date());
            }
        }

        this.setState({
            conversations: newConversations.sort(Utilities.dynamicSort("diffBetweenLastAndNow"))
        }, () => {
            // Not sure why this works, but this is the only way the order will be triggered by diffBetweenLastAndNow - best guess some underlaying Twillio objects keeping this from getting updated
            setTimeout(() => {
                this.setState({
                    conversations: newConversations.sort(Utilities.dynamicSort("diffBetweenLastAndNow"))
                })
            }, 300)
            if (newConversationGroup) {
                this.cacheLastMessages([newConversationGroup.conversation]);
            }
            this.updateNewMessagesCounter();
        })

        if (!newConversationGroup) {
            // @ts-ignore
            const newChannel = await this.client.getConversationBySid(msg.conversation.sid);
            const contacts = await this.getFreshContacts();
            this.setState({
                contacts: contacts,
                currentContacts: contacts.map(item => item.otherUsersUserId)
            });
            if (newChannel) {
                this.handleNewChannels([newChannel], contacts);
            }
        }

        let senderId = msg.author;
        let senderName = senderId ? this.state.contacts.find(cont => cont.otherUsersUserId === senderId || cont.otherUsersContactId === senderId)?.contactName || "Unknown User" : "";

        if (!msg.body && msg.attachedMedia && msg.attachedMedia[0]) {
            if (this.state.ownTwilioIdentity === senderId) {
                this.handleFileUploaded(msg)
            }
        } else {
            if (this.state.ownTwilioIdentity !== senderId) {
                Utilities.beep();
                if (senderName === "Unknown User") {
                    Notifications.actionCreators.display(ToastType.INFO, "New Message");
                    setTimeout(() => {
                        window.location.reload();
                    }, 1500)
                } else {
                    Notifications.actionCreators.display(ToastType.INFO, senderName + " says: " + (msg.body && msg.body.indexOf("contentType") !== -1 ? "New File" : (msg.body || "-")));
                }
            }
    
            if (newConversationGroup?.conversation.sid.trim() == this.state.activeChannelId?.trim()) {
                this.scrollChatPanelToEnd();
            }
        }
    };

    handleMemberUpdate = (member: Member) => {
        this.setState({
            channels: this.state.channels.map((c) => {
                if (c.member && c.member.identity == member.identity) {
                    return { ...c, member };
                }

                return c;
            })
        });
    };

    handleUserUpdate = (user: User) => {
        this.setState({
            channels: this.state.channels.map((c) => {
                if (
                    c.contact.identity == user.identity &&
                    c.contact.online != user.online
                ) {
                    return {
                        ...c,
                        contact: {
                            ...c.contact,
                            online: user.online
                        } as UserDescriptor
                    };
                }

                return c;
            })
        });
    };

    handleSendFile = async (file) => {
        if (!file)
            return;

        this.setState({
            blockingLoadingMessage: "Uploading File"
        })

        await this.activeChannel?.conversation.sendMessage({contentType: file.type, media: file, filename: file.name});

        this.setState({
            blockingLoadingMessage: null
        })
        
        InternalTracker.trackEvent("File Sent via Chat");
    }

    loadFile = (media: MessageMedia, loadForPreview?: boolean) => {
        if (!loadForPreview) {
            this.setState({
                blockingLoadingMessage: "Preparing File"
            })
        }

        if (media) {
            const customBuiltMedia = new Media({
                category: media.category as MediaCategory,
                contentType: media.contentType,
                filename: media.filename,
                sid: media.sid,
                size: media.size
            // @ts-ignore
            }, this.client.services);

            const isVideo = (media.filename.toLowerCase().endsWith(".mp4") || media.filename.toLowerCase().endsWith(".mov") || media.filename.toLowerCase().endsWith(".webm"));
            const isPhoto = (media.filename.toLowerCase().endsWith(".jpg") || media.filename.toLowerCase().endsWith(".png") || media.filename.toLowerCase().endsWith(".jpeg"));

            if (customBuiltMedia) {
                customBuiltMedia
                    .getContentTemporaryUrl()
                    .then((url) => {
                        
                        if (loadForPreview) {
                            this.mediaUrls[media.sid] = {
                                state: MediaState.Loaded,
                                url: url
                            }
                        } else {
                            InternalTracker.trackEvent("File Opened via Chat", {
                                type: isVideo ? "video" : isPhoto ? "image" : "file",
                            });
                            
                            if (isVideo || isPhoto) {
                                this.setState({
                                    openFile: {
                                        url: url,
                                        type: isVideo ? "video" : "image"
                                    }
                                })
                            } else {
                                window.open(url);
                            }
                            this.setState({
                                blockingLoadingMessage: null
                            })
                        }
                    })
                    .catch((e) => console.error);
            }
        }
    }

    onActiveThreadScroll = () => {
        if (!this.preventThreadImagePagination) {
          this.preventThreadImagePagination = true;
          setTimeout(() => {
            this.preventThreadImagePagination = false;
          }, 400)
          let visiblePhotos = document.querySelectorAll(".body-file[data-contenttype*='image']")
          visiblePhotos.forEach(photo => {
            if (Utilities.isElementInViewport(photo)) {
              const photoId = photo.getAttribute("data-sid");
              // @ts-ignore
              if (!this.mediaUrls[photoId]) {
                this.loadFile({
                  sid: photoId || "",
                  contentType: photo.getAttribute("data-contenttype") || "",
                  category: photo.getAttribute("data-category") || "",
                  size: parseInt(photo.getAttribute("data-size") || "0"),
                  filename: photo.getAttribute("data-filename") || "",
                }, true)
              }
            }
          });
        }
    }

    handleSend = () => {
        if (!this.isMessageSendable()) return;

        let text = this.state.text!;

        let newMessageInput = document.querySelector("#new-message-input") as HTMLInputElement;
        if (newMessageInput) {
            // @ts-ignore
            newMessageInput.style.height = 30 + "px";
            newMessageInput = document.querySelector("#new-message-input") as HTMLInputElement;
        }

        if (this.newMessageMedia.length !== 0) {
            text = JSON.stringify(this.newMessageMedia.map(item => item as MessageMedia)) + "£££" + text;
            this.newMessageMedia = [];
        }

        this.activeChannel?.conversation.sendMessage(text);

        this.setState({
            text: ''
        });

        InternalTracker.trackEvent("Chat Message Sent");

        AuthenticatedFetch.request(
            `${AppConfig.Settings.api.baseUri}/api/chat/notify/${
                this.activeChannel!.participants[0].id
            }`,
            'POST',
            [text],
            ''
        );
    };

    public async openExistingThread(sId: string, staleDataRecheck?: boolean) {

        if (this.state.staleData) {
            this.mediaUrls = {};
            this.setState({
                status: Status.Loading
            });
            setTimeout(() => {
                this.openExistingThread(sId, true);
            }, 200);
            return;
        }

        if (staleDataRecheck) {
            this.setState({
                status: Status.Ready
            });
        }

        this.preventPagination = true;
        InternalTracker.trackEvent("Chat Conversation Selected", {
            conversationId: sId
        })
        this.setState({
            activeChannelId: sId,
            mobileMenuShown: false
        }, () => {
            this.loadInitialMessages();
        })
    }

    public async handleStartChat(userId: string, staleDataRecheck?: boolean) {

        if (this.state.staleData) {
            this.setState({
                status: Status.Loading
            });
            setTimeout(() => {
                this.handleStartChat(userId, true);
            }, 200);
            return;
        }

        if (staleDataRecheck) {
            this.setState({
                status: Status.Ready
            });
        }

        InternalTracker.trackEvent("Chat Thread Inited", {
            id: userId
        })

        const existingChannel = this.state.channels.find(
            (c) => c.contact.identity == userId
        );

        if (existingChannel) {

            this.setState({
                activeChannelId: existingChannel.id,
                mobileMenuShown: false,
                status: Status.Ready
            }, () => {
                this.loadInitialMessages();
            })
        } else {
            this.setState({
                status: Status.Loading
            });

            // // Initial channel via API
            const channelId = await this.handleInitiateChannel(userId);
            // @ts-ignore
            const channel = await this.client.getConversationBySid(channelId);
            // @ts-ignore
            this.handleNewChannels([channel]);

            setTimeout(() => {
                this.setState({
                    activeChannelId: channelId,
                    status: Status.Ready
                }, () => {
                    this.loadInitialMessages();
                })
            }, 400)
        }
    }

    handleInitiateChannel = async (userId: string) => {
        const urlParams = new URLSearchParams(window.location.search);
        const apiKey = urlParams.get('apiKey');
        const ueUserId = urlParams.get('ueUserId');

        const localChannelId = await getChannelIdByUserId(userId);
        if (localChannelId) {
            return localChannelId;
        }
        
        const channelId = window.location.href.indexOf("/external/chat") !== -1 ?
            (await UnauthenticatedFetch.request<null, string>(`${AppConfig.Settings.api.externalUri}/chat/external/${apiKey}/${ueUserId}/channel/${userId}?api-version=1.0`, 'GET', null)) :
            (await AuthenticatedFetch.request<null, string>(`${AppConfig.Settings.api.externalUri}/chat/channel/${userId}`, 'GET', null));

        return channelId;
    };

    scrollChatPanelToEnd = () => {
        if (this.chatPanel.current) {
            this.chatPanel.current!.scrollTop = this.chatPanel.current!.scrollHeight;

            const active = this.activeChannel;

            if (active && active.messages.length) {
                const lastIndex =
                    active.messages[active.messages.length - 1].index;

                if (
                    active.conversation.lastReadMessageIndex === null ||
                    (active.conversation.lastReadMessageIndex || 0) < lastIndex
                ) {
                    // active.conversation.updateLastReadMessageIndex(lastIndex).catch(e => console.error);
                    // todo remove
                    // console.log("____ Mark as read " + active.conversation.sid + " " + lastIndex)
                    active.conversation.setAllMessagesRead().catch(e => console.error);
                    active.hasUnread = false;
                    active.lastReadMessageIndex = lastIndex;
                    if (active.messages[active.messages.length - 1].author !== this.state.ownTwilioIdentity) {
                        // this.props.updateNotificationBadge(1, true)
                    }
                }
            }
            setTimeout(() => {
                this.preventPagination = false;
                this.onActiveThreadScroll();
            }, 1000)
        }
    };

    get activeChannel() {
        return this.state.conversations.find(
            (c) => c.conversation.sid == this.state.activeChannelId
        );
    }

    private isMessageSendable = () => this.state.text.trim() !== '' || this.newMessageMedia.length !== 0;
}

export default withWindowDimensions(connect(null, {
    setChatRef: EventsState.actionCreators.setChatRef
})(ConversationComponent)) as any;

const Wrapper = styled.div`
    height: 60rem;
    width: 100%;
    color: rgb(106, 108, 111);
    z-index: 1000;
    position: realtive; 

    .chat-close-btn {
        z-index: 3;
        font-size: 28px;
        position: absolute;
        top: -15px;
        right: 10px;
        padding: initial;
        color: ${theme.colours.red2};
        background-color: white;
        border-radius: 100%;
        box-shadow: ${theme.colours.boxShadow};
        cursor: pointer;
    }

    .dragger-btn {
        z-index: 3;
        border-radius: 52px;
        box-shadow: ${theme.colours.boxShadow};
        cursor: pointer;
        font-size: 16px;
        line-height: 16px;
        background-color: whitesmoke;
        position: absolute;
        top: -14px;
        right: 56px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 6px 10px;
    
        i {

        }

        span {
            margin-left: 10px;
        }
    }

    .online-dot {
        background-color: ${theme.colours.green2};
        right: auto;
        left: 8px;
        top: 8px;
        width: 22px;
        height: 22px;
        border-radius: 100%;
        z-index: 1;
        box-shadow: ${theme.colours.boxShadow};
        border: 3px solid white;
        position: absolute;

        &.open-dot {
            visibility: visible;
            top: 18px;
            left: 40px;
        }
    }

    ${() =>
        location.pathname.startsWith('/mobile/') &&
        css`
            height: calc(100vh - 40px) !important;
        `}

    > div {
        width: 100%;
        height: 100%;
        margin: 0 auto;
        background: white;
        //box-shadow: 0 0 20px 0 #17333f33;

        @media (max-width: 1250px) {
            width: 100%;
        }
    }

    .attach-image-btn, .attach-video-btn {
        font-size: 19px;
        cursor: pointer;
        margin-left: 3px;
    }

    .attach-video-btn {
        margin-left: 8px;
    }

    .file-list {

        &>.body-file {
            display: inline-flex;
            margin: 0 10px 10px 0;
            border-radius: 10px;
            background-color: ${theme.colours.blue2}
            color: white;
            align-items: center;
            justify-content: center;
            width: 72px;
            height: 72px;
            padding: 5px;
            overflow: hidden;
            position: relative;
            float: left;

            .remove-file-btn {
                position: absolute;
                top: 5px;
                right: 5px;
                z-index: 10;
                font-size: 24px;
                color: red;
                background: white;
                border-radius: 100%;
                padding: 2px;
            }

            img {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                z-index: 2;
                object-fit: cover;
                background-color: ${theme.colours.blue2}
            }

            &>div {
                text-align: center;
                position: relative;
                z-index: 1;
                
                span {
                    word-break: break-word;
                    font-size: 12px;
                    line-height: 14px;
                    text-align: center !important;
                    display: block;
                }

                ion-icon {
                    font-size: 20px;
                }
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .new-message-wrapper, .new-message-cannot-send {
        position: relative;

        .file-list {
            position: absolute;
            top: -96px;
            width: 100%;
            left: 0;
            padding: 10px 10px 0 10px;
            background: whitesmoke;
            z-index: 2;
        }

        &.new-message-cannot-send {
            padding: 15px;
            background-color: whitesmoke;
            text-align: center;

            button {
                width: 100%;
                border-radius: 8px;
                font-size: 16px;
                padding: 12px;
                margin-top: 16px;
                background-color: ${theme.colours.blue2};
                color: white;
                border: none;
            }

            p {
                margin: 0;
                font-size: 16px;
                line-height: 16px;
                font-weight: 700;
            }
        }
    }
`;

const ConversationsAnimation = keyframes`
    0% {
        z-index: 2;
        transform: rotateY(-70deg);
        transform-origin: left;
        opacity: 0;
    }
    100% {
        z-index: 2;
        transform: rotateY(0);
        transform-origin: left;
        opacity: 1;
    }
`;

const Conversations = styled.div`
    width: 280px;

    h4 {
        font-weight: 600;
        line-height: 40px;
        text-align: center;
        cursor: default;
    }

    .menu-overflow {
        overflow-y: auto;
    }

    @media (max-width: 768px) {
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
        background: white;
        transform: rotateY(-70deg);
        transform-origin: left;
        opacity: 0;

        ${(props) =>
            props.mobileShown == true &&
            css`
                animation: ${ConversationsAnimation} 1s
                    cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
            `}
    }
`;

const ConversationHeader = styled.div`
    padding: 10px;
    border-bottom: 0.5px solid #eaeaea;
    cursor: pointer;
    position-relative;

    > div.flex {
        margin: 0 10px;

        > * {
            max-width: 175px;
        }
    }

    > span {
        font-size: 10.5px;
        text-align: right;
    }

    img {
        width: 50px;
        height: 50px;
        border-radius: 100%;
        box-shadow: ${(props) =>
            props.isOnline ? '0 0 5px 4px limegreen' : 'none'};
    }

    .fa-comment-slash {
        margin: 0 5px;
        font-size: 23px;
    }

    ${(props) =>
        props.active
            ? `
        background: #0F05;

        h5 {
            font-weight: 600;
        }
    `
            : `
        &:hover {
            background: #0F01;
        }
    `};

    ${(props) =>
        props.disabled &&
        css`
            cursor: not-allowed;

            &:hover {
                background: #fdd;
            }
        `}
`;

const ChatHeader = styled.div`
    padding: 10px;
    border-bottom: 1px solid #dedede;
    position: relative;

    > h4 {
        margin-right: 5px;
        font-weight: 600;
    }

    > span {
        visibility: hidden;

        @media (max-width: 768px) {
            padding: 5px;
            font-size: 18px;
            visibility: visible;
            cursor: pointer;
        }
    }
`;

const ChatPanel = styled.div`
    margin-top: 5px;
    overflow-y: auto;
    scroll-behavior: smooth;

    > div:first-child {
        margin-top: auto;
    }
`;

const ChatMessage = styled.div`
    display: flex;
    flex-shrink: 0;
    margin: 5px 10px;

    &>span>img {
        border-radius: 100%;
    }

    .bubble {
        position: relative;
        max-width: calc(100% - 30px - 50px);
        padding: 8px;
        border-radius: 9px;

        ${(props) =>
            props.mine
                ? `
            background: lightblue;
            border-top-right-radius: 0;
            margin-left: auto;
        `
                : `
            background: lightgreen;
            border-top-left-radius: 0;
            margin-right: auto;
        `}

        .meta {
            display: flex;
        
            .body {
                white-space: break-spaces;
                word-break: break-word;

                .contact-options {
                    display: flex;
                    margin-top: 12px;
                    margin-bottom: 8px;
            
                    &>button {
                        flex-basis: calc(50% - 6px);
                        display: inline-flex;
                        border-radius: 12px;
                        background-color: white;
                        align-items: center;
                        justify-content: center;
                        border: none;
                        padding: 8px;
            
                        &:first-child {
                            margin-right: 12px;
                        }
            
                        span {
                            flex-basis: calc(100% - 40px);
                            margin-left: 8px;
                            word-break: break-word;
                            text-align: left;
                        }
            
                        img {
                            flex-basis: 40px;
                            height: 40px;
                        }
                    }
                }
            }

            > .timestamp {
                display: inline-flex;
                color: #999;
                font-size: 9px;
                text-align: right;
                transition: all 0.5s ease;
                margin-left: 8px;
                align-items: center;

                > .fa-check {
                    margin: -1px 0 0 3px;
                    color: ${(props) => (props.isRead ? '#66F' : '#AAA')};
                    font-size: 12px;
                }
            }
        }

        .body-file {
            background: rgba(0,0,0,0.1);
            cursor: pointer;

            i {
                margin-right: 5px;
            }
        }

        ::after {
            position: absolute;
            top: 0px;
            content: '';

            ${(props) =>
                props.mine
                    ? `
                left: unset;
                right: -9px;
                border-left: none;
                border-right: 10px solid transparent;
                border-top: 10px solid lightblue;
            `
                    : `
                left: -9px;
                right: unset;
                border-left: 10px solid transparent;
                border-right: none;
                border-top: 10px solid lightgreen;
            `}
        }
    }
`;

const AvailabilityTag = styled.span`
    position: relative;
    top: -2px;
    margin-right: 10px;
    padding: 1px 6px 3px 6px;
    color: white;
    font-weight: 400;
    font-size: 11px;
    background: ${(props) => (props.isOnline ? 'limegreen' : '#AAA')};
    border-radius: 10px;
`;

const SendButton = styled.i`
    margin: 5px;
    color: ${theme.colours.green};
    font-size: 22px;
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    opacity: ${(props) => (props.disabled ? '.5' : '1')};
`;
