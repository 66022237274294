import React from 'react';
import TimeKeeper from 'react-timekeeper';
import styled from 'styled-components';

export class ClockTime {
    hour = 0;
    minute = 0;

    constructor(hour: number, minute: number) {
        this.hour = hour;
        this.minute = minute;
    }

    toString() {
        return `${('0' + this.hour).slice(-2)}:${('0' + this.minute).slice(
            -2
        )}`;
    }
}

interface ClockPickerProps {
    time: ClockTime;
    onChange: (time: ClockTime) => void;
    disabled?: boolean;
}

interface ClockPickerState {
    open: boolean;
    hour: number;
    minute: number;
    offsetTop: number;
}

export default class ClockPicker extends React.Component<
    ClockPickerProps,
    ClockPickerState
> {
    element: React.RefObject<HTMLDivElement>;

    constructor(props) {
        super(props);

        this.state = {
            open: false,
            hour: 0,
            minute: 0,
            offsetTop: 0
        };

        this.element = React.createRef();

        document.onclick = this.handleDocumentClick;
    }

    static getDerivedStateFromProps(
        nextProps: ClockPickerProps,
        prevState: ClockPickerState
    ) {
        if (prevState.open) {
            return null;
        } else {
            return {
                hour: nextProps.time.hour,
                minute: nextProps.time.minute
            };
        }
    }

    handleOpen = () => {
        if (this.props.disabled) return;

        // The clicked input element
        const element = this.element.current!;

        // How much to offset the picker top by
        const offsetTop = element.getBoundingClientRect().top;

        // Watch for click outside popup to trigger close
        document.onclick = this.handleDocumentClick;

        this.setState({
            open: true,
            offsetTop: offsetTop
        });
    };

    handleClose = () => {
        // Remove the event listener for closing on click outside
        document.onclick = null;

        this.setState({
            open: false
        });

        this.props.onChange(new ClockTime(this.state.hour, this.state.minute));
    };

    handleChange = (value) => {
        this.setState({
            //display: value.formatted24,
            hour: value.hour24,
            minute: value.minute
        });
    };

    handleDocumentClick = (ev: MouseEvent) => {
        // If the user clicks outside of the time picker popup
        if (
            ev.target &&
            !(ev.target as HTMLElement).closest('.react-timekeeper')
        ) {
            // Then close it
            this.handleClose();
        }
    };

    render() {
        const time = new ClockTime(this.state.hour, this.state.minute);

        const picker = (
            <TimeKeeper
                time={time.toString()}
                onChange={this.handleChange}
                onDoneClick={this.handleClose}
                config={{
                    useCoarseMinutes: true
                }}
            />
        );

        return (
            <div
                className="clock-picker"
                style={{ position: 'relative' }}
                ref={this.element}
            >
                <PickerInput
                    type="text"
                    value={time.toString()}
                    onChange={() => {}}
                    onClick={this.handleOpen}
                    className={`form-control ${
                        this.props.disabled ? 'disabled' : ''
                    }`}
                    readOnly
                />
                <PickerContainer style={{ top: this.state.offsetTop + 'px' }}>
                    {this.state.open ? picker : null}
                </PickerContainer>
            </div>
        );
    }
}

const PickerInput = styled.input`
    width: 70px !important;
    background: white !important;
    cursor: pointer;

    &.disabled {
        background-color: #efefef !important;
        cursor: default;
    }
`;

const PickerContainer = styled.div`
    position: fixed;
    z-index: 1;
    transform: translate(calc(-50% + 30px), calc(-50% + 10px));
`;
