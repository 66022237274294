import React from 'react';

interface ExpanderProps {
    children: React.ReactChild;
    expanded: boolean;
    style?: React.CSSProperties;
    className?: string;
}

interface ExpanderState {
    overflow: boolean;
}

class Expander extends React.Component<ExpanderProps, ExpanderState> {
    render() {
        return (
            <div
                className={this.props.className}
                style={{
                    overflow: this.props.expanded ? 'visible' : 'hidden',
                    maxHeight: this.props.expanded ? '500px' : '0',
                    transition: 'max-height 1s ease',
                    ...this.props.style
                }}
            >
                {this.props.children}
            </div>
        );
    }
}

export default Expander;
