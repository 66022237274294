/* eslint-disable */
import { LookupItem } from '../lookups/ResponseTypes';
import { UserDto } from '../user/ResponseTypes';

export interface ExternalOrganisationLogoRecord {
    justApproved?: boolean;
    url: string;
    name: string;
    postcode: string;
    approved: boolean;
    newLogoUrl: string;
}

export interface UserOrgSuggestionDTO {
    "domain": string,
    "clearbitLogo": string,
    "clearbitOrgName": null,
    "googleMapsOrgName": string
    "googleMapsOrgAddress": {
        "route": string,
        "postal_town": string,
        "administrative_area_level_2": string,
        "administrative_area_level_1": string,
        "country": string,
        "postal_code": string,
        "formatted_address": string,
        "placeId": string,
        "address_components": google.maps.GeocoderAddressComponent[]
    },
    "faviconLogo": string,
    "sectors": [],
    "subSectors": [],
    "metaserviceSchoolDetails": object
    "userType": string,
    "firstName": string,
    "lastName": string,
    "metaserviceLogo"?: string
}

export interface LogoSuggestionDTO {
    logoUrl: string;
}

export interface MergedOrganisationDTO {
    id?: string,
    email?: string;
    adminUserId?: string;
    name: string;
    postcode: string;
    internal: boolean,
    external: boolean
}

export interface OrganisationDTO {
    locationComponents?: google.maps.GeocoderAddressComponent[];
    id: string;
    name: string;
    imageUrl: string;
    apiBaseUrl: string;
    deleted: boolean;
    locationPlaceId: string;
    locationPlaceName: string;
    locationFullName: string;
    verified: boolean;
    industries: LookupItem[];
    isAgency: boolean;
    isHirer: boolean;
    smsCredit?: number;
    alreadyAskedToJoined?: boolean;
    data?: any;
}

export interface APIImplementersDTO {
    organisationId: string;
    organisationName: string;
}

export interface OrganisationTeamDTO {
    id: number;
    organisationId: string | null;
    name: string;
    size: number;
    subscriptionId: string;
    userId: string;
    teamMembers: OrganisationTeamMember[];
    mine: boolean;
}

export interface OrganisationTeamMember {
    id: number;
    teamId: number;
    contactId: string;
}

export interface OrganisationUser extends UserDto {
    organisationMembershipStatus?: OrganisationMemberStatus
}

export enum OrganisationMemberStatus {
    Unknown = 0,
    Invited = 1,
    Joined = 2,
    Suggested = 3
}