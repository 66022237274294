/* eslint-disable */
import React from 'react';

function SvgRota(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="262" height="262" viewBox="0 0 262 262">
            <defs>
                <linearGradient id="linear-gradient" x1="0.5" y1="1" x2="0.5" gradientUnits="objectBoundingBox">
                <stop offset="0" stop-color="gray" stop-opacity="0.251"/>
                <stop offset="0.54" stop-color="gray" stop-opacity="0.122"/>
                <stop offset="1" stop-color="gray" stop-opacity="0.102"/>
                </linearGradient>
                <linearGradient id="linear-gradient-4" x1="0.5" y1="1" x2="0.5" y2="0"/>
                <linearGradient id="linear-gradient-13" x1="0.5" y1="1" x2="0.5" y2="0" />
                <linearGradient id="linear-gradient-15" x1="-2.26" y1="1" x2="-2.26" y2="0" />
            </defs>
            <g id="Group_1715" data-name="Group 1715" transform="translate(-501 -384)">
                <rect id="Rectangle_4438" data-name="Rectangle 4438" width="262" height="262" rx="131" transform="translate(501 384)" fill="#f5f5f5"/>
                <g id="Group_1667" data-name="Group 1667" transform="translate(-3874 3582.183)">
                <g id="Group_1481" data-name="Group 1481" transform="translate(4421.351 -3094.259)">
                    <g id="undraw_schedule_pnbk" transform="translate(0 0)">
                    <path id="Path_112" data-name="Path 112" d="M972.46,688.789a4.754,4.754,0,0,0-4.28-.409s.656,1.264,3.353,1.144Z" transform="translate(-818.62 -587.849)" fill="#6c63ff"/>
                    <path id="Path_113" data-name="Path 113" d="M987.446,692.87a4.756,4.756,0,0,1-.985,4.185s-.987-1.029,0-3.544Z" transform="translate(-833.343 -591.853)" fill="#6c63ff"/>
                    <path id="Path_114" data-name="Path 114" d="M986.181,671.843s-.159-2.12-4.941-1.417a2.283,2.283,0,0,0,1.656,2.015A2.76,2.76,0,0,0,986.181,671.843Z" transform="translate(-829.396 -573.216)" fill="#6c63ff"/>
                    <path id="Path_115" data-name="Path 115" d="M1004.563,679.11s2.024.651.221,5.137a2.291,2.291,0,0,1-1.574-2.083A2.757,2.757,0,0,1,1004.563,679.11Z" transform="translate(-847.485 -580.499)" fill="#6c63ff"/>
                    <path id="Path_116" data-name="Path 116" d="M1004.851,647.055s.273-3.486-4.616-3.127c0,0-.35,3.148,3.752,3.965Z" transform="translate(-845.062 -551.449)" fill="#6c63ff"/>
                    <path id="Path_117" data-name="Path 117" d="M1018.917,625.319s-.672-3.949-5.557-2.772c0,0-.019,3.416,4.757,3.715Z" transform="translate(-855.898 -533.648)" fill="#6c63ff"/>
                    <path id="Path_118" data-name="Path 118" d="M1030.493,602.439s-1.121-3.848-5.963-2.7c0,0,1.151,3.46,5.641,3.407Z" transform="translate(-865.114 -514.837)" fill="#6c63ff"/>
                    <path id="Path_119" data-name="Path 119" d="M1044.212,579.206s-1.1-3.993-5.772-2.8c0,0,1.56,3.794,5.464,3.475Z" transform="translate(-876.591 -495.576)" fill="#6c63ff"/>
                    <path id="Path_120" data-name="Path 120" d="M1055.2,553.214s-.843-4.876-5.2-3.8c0,0,1.266,4.443,4.88,4.513Z" transform="translate(-886.13 -473.358)" fill="#6c63ff"/>
                    <path id="Path_121" data-name="Path 121" d="M1061.872,528.086s-1.011-4.923-5.422-3.5c0,0,1.73,4.651,5.111,4.17Z" transform="translate(-891.451 -452.79)" fill="#6c63ff"/>
                    <path id="Path_130" data-name="Path 130" d="M1023.682,661.737s3.467-.455,3.362,4.446c0,0-3.133.509-4.154-3.542Z" transform="translate(-863.761 -566.156)" fill="#6c63ff"/>
                    <path id="Path_131" data-name="Path 131" d="M1042.289,638.68s4.007-.014,3.673,5c0,0-3.362.6-4.472-4.056Z" transform="translate(-879.108 -547.141)" fill="#6c63ff"/>
                    <path id="Path_132" data-name="Path 132" d="M1057.33,616.466s3.636-1.705,5.964,2.7c0,0-3.36,1.422-6.284-1.985Z" transform="translate(-891.913 -528.544)" fill="#6c63ff"/>
                    <path id="Path_148" data-name="Path 148" d="M282.638,115.31H114.577a1.5,1.5,0,0,0-1.5,1.5v4.362H284.136v-4.362a1.5,1.5,0,0,0-1.5-1.5Z" transform="translate(-113.08 -115.31)" fill="#3f3d56"/>
                    <path id="Path_149" data-name="Path 149" d="M113.08,148.81v98.163a3.019,3.019,0,0,0,3.019,3.019H281.117a3.019,3.019,0,0,0,3.019-3.019V148.81Z" transform="translate(-113.08 -142.951)" fill="#fff"/>
                    <g id="Group_1455" data-name="Group 1455" transform="translate(6.559 11.804)" opacity="0.3">
                        <rect id="Rectangle_4102" data-name="Rectangle 4102" width="158.113" height="89.201" fill="none" stroke="#3f3d56" stroke-miterlimit="10" stroke-width="1"/>
                        <rect id="Rectangle_4103" data-name="Rectangle 4103" width="116.835" height="89.201" transform="translate(20.639)" fill="none" stroke="#3f3d56" stroke-miterlimit="10" stroke-width="1"/>
                        <rect id="Rectangle_4104" data-name="Rectangle 4104" width="75.558" height="89.201" transform="translate(41.277)" fill="none" stroke="#3f3d56" stroke-miterlimit="10" stroke-width="1"/>
                        <rect id="Rectangle_4105" data-name="Rectangle 4105" width="34.281" height="89.201" transform="translate(61.916)" fill="none" stroke="#3f3d56" stroke-miterlimit="10" stroke-width="1"/>
                        <line id="Line_10" data-name="Line 10" y2="89.201" transform="translate(79.056)" fill="none" stroke="#3f3d56" stroke-miterlimit="10" stroke-width="1"/>
                        <rect id="Rectangle_4106" data-name="Rectangle 4106" width="158.113" height="63.315" transform="translate(0 12.943)" fill="none" stroke="#3f3d56" stroke-miterlimit="10" stroke-width="1"/>
                        <rect id="Rectangle_4107" data-name="Rectangle 4107" width="158.113" height="37.779" transform="translate(0 25.711)" fill="none" stroke="#3f3d56" stroke-miterlimit="10" stroke-width="1"/>
                        <rect id="Rectangle_4108" data-name="Rectangle 4108" width="158.113" height="12.243" transform="translate(0 38.479)" fill="none" stroke="#3f3d56" stroke-miterlimit="10" stroke-width="1"/>
                    </g>
                    <rect id="Rectangle_4109" data-name="Rectangle 4109" width="12.418" height="4.547" transform="translate(31.308 16.789)" fill="#3f3d56" opacity="0.1"/>
                    <rect id="Rectangle_4110" data-name="Rectangle 4110" width="9.27" height="4.547" transform="translate(11.893 29.207)" fill="#3f3d56" opacity="0.1"/>
                    <rect id="Rectangle_4440" data-name="Rectangle 4440" width="9.27" height="4.547" transform="translate(11.893 41.343)" fill="#3f3d56" opacity="0.1"/>
                    <rect id="Rectangle_4111" data-name="Rectangle 4111" width="12.418" height="4.547" transform="translate(51.422 16.789)" fill="#3f3d56" opacity="0.1"/>
                    <rect id="Rectangle_4112" data-name="Rectangle 4112" width="12.418" height="4.547" transform="translate(70.311 16.789)" fill="#3f3d56" opacity="0.1"/>
                    <rect id="Rectangle_4113" data-name="Rectangle 4113" width="12.418" height="4.547" transform="translate(87.802 16.789)" fill="#3f3d56" opacity="0.1"/>
                    <rect id="Rectangle_4114" data-name="Rectangle 4114" width="12.418" height="4.547" transform="translate(107.391 16.789)" fill="#3f3d56" opacity="0.1"/>
                    <rect id="Rectangle_4115" data-name="Rectangle 4115" width="12.418" height="4.547" transform="translate(127.33 16.789)" fill="#3f3d56" opacity="0.1"/>
                    <rect id="Rectangle_4116" data-name="Rectangle 4116" width="12.418" height="4.547" transform="translate(148.493 16.789)" fill="#3f3d56" opacity="0.1"/>
                    <rect id="Rectangle_4117" data-name="Rectangle 4117" width="8.068" height="7.811" transform="translate(55.885 41.442)" fill="url(#linear-gradient)"/>
                    <rect id="Rectangle_4118" data-name="Rectangle 4118" width="7.79" height="7.542" transform="translate(56.025 41.576)" fill="#50d890"/>
                    <rect id="Rectangle_4171" data-name="Rectangle 4171" width="7.79" height="7.542" transform="translate(93.336 91.606)" fill="#ffa259"/>
                    <rect id="Rectangle_4119" data-name="Rectangle 4119" width="8.068" height="7.811" transform="translate(72.508 39.749)" fill="url(#linear-gradient)"/>
                    <rect id="Rectangle_4120" data-name="Rectangle 4120" width="7.79" height="7.542" transform="translate(72.648 39.883)" fill="#ffa259"/>
                    <rect id="Rectangle_4121" data-name="Rectangle 4121" width="8.068" height="7.811" transform="translate(104.515 66.334)" fill="url(#linear-gradient)"/>
                    <rect id="Rectangle_4122" data-name="Rectangle 4122" width="7.79" height="7.542" transform="translate(104.655 66.469)" fill="#ffa259"/>
                    <rect id="Rectangle_4123" data-name="Rectangle 4123" width="8.068" height="7.811" transform="translate(33.459 27.521)" fill="url(#linear-gradient-4)"/>
                    <rect id="Rectangle_4124" data-name="Rectangle 4124" width="7.79" height="7.542" transform="translate(33.597 27.656)" fill="#ffa259"/>
                    <rect id="Rectangle_4125" data-name="Rectangle 4125" width="8.068" height="7.811" transform="translate(49.491 38.715)" fill="url(#linear-gradient-4)"/>
                    <rect id="Rectangle_4126" data-name="Rectangle 4126" width="7.79" height="7.542" transform="translate(49.629 38.85)" fill="#fb5b5a"/>
                    <rect id="Rectangle_4172" data-name="Rectangle 4172" width="7.79" height="7.542" transform="translate(86.939 88.88)" fill="#fb5b5a"/>
                    <rect id="Rectangle_4127" data-name="Rectangle 4127" width="8.068" height="7.811" transform="translate(113.953 66.278)" fill="url(#linear-gradient)"/>
                    <rect id="Rectangle_4128" data-name="Rectangle 4128" width="7.79" height="7.542" transform="translate(114.093 66.413)" fill="#50d890"/>
                    <rect id="Rectangle_4129" data-name="Rectangle 4129" width="8.068" height="7.811" transform="translate(128.12 27.624)" fill="url(#linear-gradient)"/>
                    <rect id="Rectangle_4130" data-name="Rectangle 4130" width="7.79" height="7.542" transform="translate(128.26 27.759)" fill="#50d890"/>
                    <rect id="Rectangle_4131" data-name="Rectangle 4131" width="8.068" height="7.811" transform="translate(127.246 52.461)" fill="url(#linear-gradient)"/>
                    <rect id="Rectangle_4132" data-name="Rectangle 4132" width="7.79" height="7.542" transform="translate(127.386 52.595)" fill="#3070e8"/>
                    <rect id="Rectangle_4170" data-name="Rectangle 4170" width="7.79" height="7.542" transform="translate(50.221 77.186)" fill="#50d890"/>
                    <rect id="Rectangle_4443" data-name="Rectangle 4443" width="7.79" height="7.542" transform="translate(146.296 77.186)" fill="#ffa259"/>
                    <rect id="Rectangle_4133" data-name="Rectangle 4133" width="8.068" height="7.811" transform="translate(133.367 54.21)" fill="url(#linear-gradient)"/>
                    <rect id="Rectangle_4134" data-name="Rectangle 4134" width="7.79" height="7.542" transform="translate(133.507 54.344)" fill="#50d890"/>
                    <rect id="Rectangle_4169" data-name="Rectangle 4169" width="7.79" height="7.542" transform="translate(56.342 78.935)" fill="#50d890"/>
                    <rect id="Rectangle_4442" data-name="Rectangle 4442" width="7.79" height="7.542" transform="translate(152.418 78.935)" fill="#fb5b5a"/>
                    <rect id="Rectangle_4135" data-name="Rectangle 4135" width="8.068" height="7.811" transform="translate(107.559 63.551)" fill="url(#linear-gradient-4)"/>
                    <rect id="Rectangle_4136" data-name="Rectangle 4136" width="7.79" height="7.542" transform="translate(107.697 63.686)" fill="#fb5b5a"/>
                    <rect id="Rectangle_4137" data-name="Rectangle 4137" width="8.068" height="7.811" transform="translate(108.433 39.939)" fill="url(#linear-gradient-4)"/>
                    <rect id="Rectangle_4138" data-name="Rectangle 4138" width="7.79" height="7.542" transform="translate(108.571 40.074)" fill="#fb5b5a"/>
                    <rect id="Rectangle_4140" data-name="Rectangle 4140" width="9.27" height="4.547" transform="translate(11.893 54.218)" fill="#3f3d56" opacity="0.1"/>
                    <rect id="Rectangle_4141" data-name="Rectangle 4141" width="9.27" height="4.547" transform="translate(11.893 66.986)" fill="#3f3d56" opacity="0.1"/>
                    <rect id="Rectangle_4142" data-name="Rectangle 4142" width="9.27" height="4.547" transform="translate(11.893 79.754)" fill="#3f3d56" opacity="0.1"/>
                    <rect id="Rectangle_4143" data-name="Rectangle 4143" width="9.27" height="4.547" transform="translate(11.893 92.522)" fill="#3f3d56" opacity="0.1"/>
                    <rect id="Rectangle_4144" data-name="Rectangle 4144" width="8.068" height="7.811" transform="translate(30.699 52.461)" fill="url(#linear-gradient)"/>
                    <rect id="Rectangle_4145" data-name="Rectangle 4145" width="7.79" height="7.542" transform="translate(30.839 52.595)" fill="#50d890"/>
                    <rect id="Rectangle_4441" data-name="Rectangle 4441" width="7.79" height="7.542" transform="translate(30.839 77.878)" fill="#3070e8"/>
                    </g>
                </g>
                </g>
                <g id="Group_1701" data-name="Group 1701" transform="translate(615.021 428.134)">
                <g id="Group_1706" data-name="Group 1706" transform="translate(0 0)" opacity="0.5">
                    <path id="Path_6555" data-name="Path 6555" d="M533.876,273.425a17.192,17.192,0,1,0-26.784,14.27l.01.076a5.884,5.884,0,0,0,1.95,1.241l.022-.076c.8.691,4.194,1.8,8.09,1.787h.084a15.79,15.79,0,0,0,7.5-1.709s.02-.046.052-.13a6.125,6.125,0,0,0,1.621-1.111l.027-.2A17.174,17.174,0,0,0,533.876,273.425Z" transform="translate(-499.49 -256.234)" fill="url(#linear-gradient-13)"/>
                </g>
                <ellipse id="Ellipse_247" data-name="Ellipse 247" cx="16.676" cy="16.677" rx="16.676" ry="16.677" transform="translate(0.518 0.517)" fill="#fff"/>
                <path id="Path_6558" data-name="Path 6558" d="M548.411,398.92s-3.973.325-2.923,7.565a5.708,5.708,0,0,0,1.891,1.2l2.235-7.565Z" transform="translate(-537.588 -374.87)" fill="#fda57d"/>
                <path id="Path_6559" data-name="Path 6559" d="M634.264,398.92s3.973.325,2.923,7.565a5.708,5.708,0,0,1-1.891,1.2l-2.235-7.565Z" transform="translate(-610.546 -374.87)" fill="#fda57d"/>
                <path id="Path_6560" data-name="Path 6560" d="M550.3,328.54s-1.891,5.5-2.923,4.986c0,0,9.945,8.6,18.74,0a26.2,26.2,0,0,0-3.782-4.986Z" transform="translate(-539.308 -316.353)" fill="#333"/>
                <ellipse id="Ellipse_251" data-name="Ellipse 251" cx="7.393" cy="7.393" rx="7.393" ry="7.393" transform="translate(9.983 4.279)" fill="#333"/>
                <path id="Path_6561" data-name="Path 6561" d="M568.879,398.92H555.985l-1.2,5.158s-.706,2.388.169,3.438,9.093,3.438,15.129.169a6.727,6.727,0,0,0,.169-4.3c-.688-2.407-.169-.169-.169-.169Z" transform="translate(-545.238 -374.87)" opacity="0.1"/>
                <path id="Path_6562" data-name="Path 6562" d="M569.9,398.92H557l-1.2,5.158s-.706,2.388.169,3.438,9.093,3.438,15.129.169a6.727,6.727,0,0,0,.169-4.3c-.688-2.407-.169-.169-.169-.169Z" transform="translate(-546.086 -374.87)" opacity="0.1"/>
                <path id="Path_6563" data-name="Path 6563" d="M569.329,398.92H556.435l-1.2,5.158s-.706,2.388.169,3.438,9.093,3.438,15.129.169a6.726,6.726,0,0,0,.169-4.3c-.688-2.407-.169-.169-.169-.169Z" transform="translate(-545.612 -374.87)" fill="#6c63ff"/>
                <path id="Path_6564" data-name="Path 6564" d="M460,310.55h5.158v4.121a2.579,2.579,0,0,1-2.579,2.579h0A2.579,2.579,0,0,1,460,314.671Z" transform="translate(-445.23 -290.778)" opacity="0.1"/>
                <path id="Path_6565" data-name="Path 6565" d="M460,309.41h5.158v4.126a2.579,2.579,0,0,1-2.579,2.579h0A2.579,2.579,0,0,1,460,313.536V309.41Z" transform="translate(-445.23 -289.83)" fill="#fda57d"/>
                <path id="Path_6566" data-name="Path 6566" d="M587.22,373.76a7.416,7.416,0,0,0,5.158.013v-.634H587.22Z" transform="translate(-572.433 -353.435)" opacity="0.1"/>
                <ellipse id="Ellipse_252" data-name="Ellipse 252" cx="7.393" cy="7.393" rx="7.393" ry="7.393" transform="translate(9.983 5.883)" fill="#fda57d"/>
                <path id="Path_6567" data-name="Path 6567" d="M560.64,291.968h14.1s-1.2-5.692-6.533-5.33S560.64,291.968,560.64,291.968Z" transform="translate(-550.333 -281.5)" fill="#333"/>
                <ellipse id="Ellipse_253" data-name="Ellipse 253" cx="0.688" cy="1.289" rx="0.688" ry="1.289" transform="translate(9.447 11.672)" fill="#fda57d"/>
                <ellipse id="Ellipse_254" data-name="Ellipse 254" cx="0.688" cy="1.289" rx="0.688" ry="1.289" transform="translate(23.889 11.672)" fill="#fda57d"/>
                <path id="Path_6568" data-name="Path 6568" d="M560.64,293.108h14.1s-1.2-5.692-6.533-5.33S560.64,293.108,560.64,293.108Z" transform="translate(-550.333 -282.447)" opacity="0.1"/>
                </g>
                <g id="Group_1702" data-name="Group 1702" transform="translate(668.406 428.234)">
                <g id="Group_1702-2" data-name="Group 1702" transform="translate(0 0)" opacity="0.5">
                    <path id="Path_6551" data-name="Path 6551" d="M826.876,149.425a17.192,17.192,0,1,0-26.784,14.27l.01.076a5.884,5.884,0,0,0,1.95,1.241l.022-.076c.8.691,4.194,1.8,8.09,1.787h.084a15.79,15.79,0,0,0,7.5-1.709s.02-.046.052-.13a6.126,6.126,0,0,0,1.621-1.111l.027-.2A17.174,17.174,0,0,0,826.876,149.425Z" transform="translate(-792.49 -132.234)" fill="url(#linear-gradient-13)"/>
                </g>
                <ellipse id="Ellipse_243" data-name="Ellipse 243" cx="16.676" cy="16.677" rx="16.676" ry="16.677" transform="translate(0.518 0.517)" fill="#fff"/>
                <path id="Path_6569" data-name="Path 6569" d="M836.411,272.92s-3.973.325-2.923,7.565a5.709,5.709,0,0,0,1.891,1.2l2.235-7.565Z" transform="translate(-826.431 -249.207)" fill="#4d8af0"/>
                <path id="Path_6570" data-name="Path 6570" d="M922.263,272.92s3.973.325,2.923,7.565a5.709,5.709,0,0,1-1.891,1.2l-2.235-7.565Z" transform="translate(-899.389 -249.207)" fill="#4d8af0"/>
                <ellipse id="Ellipse_255" data-name="Ellipse 255" cx="7.393" cy="7.393" rx="7.393" ry="7.393" transform="translate(9.141 3.942)" fill="#333"/>
                <path id="Path_6571" data-name="Path 6571" d="M856.879,272.92H843.985l-1.2,5.158s-.706,2.388.169,3.438,9.093,3.438,15.129.169a6.727,6.727,0,0,0,.169-4.3c-.688-2.407-.169-.169-.169-.169Z" transform="translate(-834.081 -249.207)" fill="#4d8af0"/>
                <path id="Path_6572" data-name="Path 6572" d="M857.9,272.92H845l-1.2,5.158s-.706,2.388.169,3.438,9.093,3.438,15.129.169a6.727,6.727,0,0,0,.169-4.3c-.688-2.407-.169-.169-.169-.169Z" transform="translate(-834.929 -249.207)" fill="#4d8af0"/>
                <path id="Path_6573" data-name="Path 6573" d="M857.329,272.92H844.435l-1.2,5.158s-.706,2.388.169,3.438,9.093,3.438,15.129.169a6.727,6.727,0,0,0,.169-4.3c-.688-2.407-.169-.169-.169-.169Z" transform="translate(-834.455 -249.207)" fill="#4d8af0"/>
                <path id="Path_6574" data-name="Path 6574" d="M748,184.55h5.158v4.121a2.579,2.579,0,0,1-2.579,2.579h0A2.579,2.579,0,0,1,748,188.671Z" transform="translate(-734.072 -165.115)" opacity="0.1"/>
                <path id="Path_6575" data-name="Path 6575" d="M748,183.41h5.158v4.126a2.579,2.579,0,0,1-2.579,2.579h0A2.579,2.579,0,0,1,748,187.536Z" transform="translate(-734.072 -164.167)" fill="#fdb797"/>
                <path id="Path_6576" data-name="Path 6576" d="M875.22,247.76a7.416,7.416,0,0,0,5.158.013v-.634H875.22Z" transform="translate(-861.276 -227.772)" opacity="0.1"/>
                <ellipse id="Ellipse_256" data-name="Ellipse 256" cx="7.393" cy="7.393" rx="7.393" ry="7.393" transform="translate(9.141 5.546)" fill="#fdb797"/>
                <path id="Path_6577" data-name="Path 6577" d="M848.64,167.108h14.1s-1.2-5.692-6.533-5.33S848.64,167.108,848.64,167.108Z" transform="translate(-839.176 -156.785)" opacity="0.1"/>
                <path id="Path_6578" data-name="Path 6578" d="M848.64,165.968h14.1s-1.2-5.692-6.533-5.33S848.64,165.968,848.64,165.968Z" transform="translate(-839.176 -155.837)" fill="#333"/>
                <ellipse id="Ellipse_257" data-name="Ellipse 257" cx="0.688" cy="1.289" rx="0.688" ry="1.289" transform="translate(8.605 11.334)" fill="#fdb797"/>
                <ellipse id="Ellipse_258" data-name="Ellipse 258" cx="0.688" cy="1.289" rx="0.688" ry="1.289" transform="translate(23.046 11.334)" fill="#fdb797"/>
                <path id="Path_6579" data-name="Path 6579" d="M854.05,149.7a2.619,2.619,0,0,1,.951-1.409,4.9,4.9,0,0,1,1.205-.516,9.2,9.2,0,0,0,1.751-.843,11.242,11.242,0,0,1,1.906-1.107,6.324,6.324,0,0,1,3.737.113,3.144,3.144,0,0,1,.873.325,3.311,3.311,0,0,1,.775.75l1.945,2.35a3.723,3.723,0,0,1,.484.674,2.342,2.342,0,0,1,.221.769,1.773,1.773,0,0,1-1.866,2.13,6.2,6.2,0,0,1-1.913-.346,37.853,37.853,0,0,0-6.144-1.385,17.33,17.33,0,0,0-3.127-.1c-.327.022-.76.169-.959-.169C853.717,150.655,853.961,149.98,854.05,149.7Z" transform="translate(-843.49 -143.33)" fill="#333"/>
                </g>
                <g id="Group_1703" data-name="Group 1703" transform="translate(562.132 428.234)">
                <g id="Group_1704" data-name="Group 1704" transform="translate(0 0)" opacity="0.5">
                    <path id="Path_6553" data-name="Path 6553" d="M203.5,149.425a17.192,17.192,0,1,1,26.784,14.27l-.01.076a5.884,5.884,0,0,1-1.95,1.241l-.022-.076c-.8.691-4.194,1.8-8.09,1.787h-.084a15.79,15.79,0,0,1-7.5-1.709s-.02-.046-.052-.13a6.125,6.125,0,0,1-1.621-1.111l-.027-.2A17.174,17.174,0,0,1,203.5,149.425Z" transform="translate(-203.5 -132.234)" fill="url(#linear-gradient-15)"/>
                </g>
                <ellipse id="Ellipse_245" data-name="Ellipse 245" cx="16.676" cy="16.677" rx="16.676" ry="16.677" transform="translate(0.516 0.517)" fill="#fff"/>
                <path id="Path_6580" data-name="Path 6580" d="M251.411,271.92s-3.973.325-2.923,7.565a5.709,5.709,0,0,0,1.891,1.2l2.235-7.565Z" transform="translate(-240.758 -248.376)" fill="#be7c5e"/>
                <path id="Path_6581" data-name="Path 6581" d="M337.263,271.92s3.973.325,2.923,7.565a5.708,5.708,0,0,1-1.891,1.2l-2.235-7.565Z" transform="translate(-313.717 -248.376)" fill="#be7c5e"/>
                <path id="Path_6582" data-name="Path 6582" d="M262.387,201.54s-.305.89-.738,1.92c-.946,2.254.133,10.907,2.4,11.829a12.864,12.864,0,0,0,9.348.433c3.015-1.1,3.944-10.956,1.814-13.348-.249-.281-.506-.561-.784-.833Z" transform="translate(-251.563 -189.858)" fill="#333"/>
                <ellipse id="Ellipse_259" data-name="Ellipse 259" cx="7.393" cy="7.393" rx="7.393" ry="7.393" transform="translate(9.813 3.773)" fill="#333"/>
                <path id="Path_6583" data-name="Path 6583" d="M271.879,271.92H258.985l-1.2,5.158s-.706,2.388.169,3.438,9.093,3.438,15.129.169a6.727,6.727,0,0,0,.169-4.3c-.688-2.407-.169-.169-.169-.169Z" transform="translate(-248.408 -248.376)" opacity="0.1"/>
                <path id="Path_6584" data-name="Path 6584" d="M272.9,271.92H260l-1.2,5.158s-.706,2.388.169,3.438,9.093,3.438,15.129.169a6.727,6.727,0,0,0,.169-4.3c-.688-2.407-.169-.169-.169-.169Z" transform="translate(-249.256 -248.376)" opacity="0.1"/>
                <path id="Path_6585" data-name="Path 6585" d="M272.329,271.92H259.435l-1.2,5.158s-.706,2.388.169,3.438,9.093,3.438,15.129.169a6.727,6.727,0,0,0,.169-4.3c-.688-2.407-.169-.169-.169-.169Z" transform="translate(-248.783 -248.376)" fill="#f55f44"/>
                <path id="Path_6586" data-name="Path 6586" d="M163,183.55h5.158v4.121a2.579,2.579,0,0,1-2.579,2.579h0A2.579,2.579,0,0,1,163,187.671Z" transform="translate(-148.4 -164.283)" opacity="0.1"/>
                <path id="Path_6587" data-name="Path 6587" d="M163,182.41h5.158v4.126a2.579,2.579,0,0,1-2.579,2.579h0A2.579,2.579,0,0,1,163,186.536Z" transform="translate(-148.4 -163.336)" fill="#be7c5e"/>
                <path id="Path_6588" data-name="Path 6588" d="M290.22,246.76a7.416,7.416,0,0,0,5.158.013v-.634H290.22Z" transform="translate(-275.603 -226.941)" opacity="0.1"/>
                <ellipse id="Ellipse_260" data-name="Ellipse 260" cx="7.393" cy="7.393" rx="7.393" ry="7.393" transform="translate(9.813 5.378)" fill="#be7c5e"/>
                <path id="Path_6589" data-name="Path 6589" d="M263.64,164.968h14.1s-1.2-5.692-6.533-5.33S263.64,164.968,263.64,164.968Z" transform="translate(-253.503 -155.005)" fill="#333"/>
                <ellipse id="Ellipse_261" data-name="Ellipse 261" cx="0.688" cy="1.289" rx="0.688" ry="1.289" transform="translate(9.277 11.166)" fill="#be7c5e"/>
                <ellipse id="Ellipse_262" data-name="Ellipse 262" cx="0.688" cy="1.289" rx="0.688" ry="1.289" transform="translate(23.719 11.166)" fill="#be7c5e"/>
                <path id="Path_6590" data-name="Path 6590" d="M263.64,166.108h14.1s-1.2-5.692-6.533-5.33S263.64,166.108,263.64,166.108Z" transform="translate(-253.503 -155.953)" opacity="0.1"/>
                </g>
                <rect id="Rectangle_4456" data-name="Rectangle 4456" width="7.79" height="7.542" transform="translate(612.006 448.393)" fill="#ffa259"/>
                <rect id="Rectangle_4463" data-name="Rectangle 4463" width="7.79" height="7.542" transform="translate(700.006 441.393)" fill="#ffa259"/>
                <rect id="Rectangle_4457" data-name="Rectangle 4457" width="7.79" height="7.542" transform="translate(590.611 447.684)" fill="#50d890"/>
                <rect id="Rectangle_4460" data-name="Rectangle 4460" width="7.79" height="7.542" transform="translate(645.611 432.684)" fill="#50d890"/>
                <rect id="Rectangle_4458" data-name="Rectangle 4458" width="7.79" height="7.542" transform="translate(590.736 432.52)" fill="#3070e8"/>
                <rect id="Rectangle_4461" data-name="Rectangle 4461" width="7.79" height="7.542" transform="translate(664.736 432.52)" fill="#3070e8"/>
                <rect id="Rectangle_4459" data-name="Rectangle 4459" width="7.79" height="7.542" transform="translate(557.922 447.998)" fill="#fb5b5a"/>
                <rect id="Rectangle_4462" data-name="Rectangle 4462" width="7.79" height="7.542" transform="translate(665.922 446.998)" fill="#fb5b5a"/>
            </g>
        </svg>
    );
}

export default SvgRota;
