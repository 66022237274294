/* eslint-disable */
import * as React from 'react';
import { StorageKeys } from '../../constants';
import Utilities from 'src/Utilities';

class Logos extends React.Component<{}, {}> {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        localStorage.removeItem("user");
        localStorage.removeItem(StorageKeys.teamId);
        // @ts-ignore
        const impersonatedAccessToken = /access_token=([^&]+)/.exec(window.location.href)[1];
        localStorage.setItem("impersonatedAccessToken", impersonatedAccessToken || "");
        localStorage.setItem("impersonatedAccessTokenExpired", Utilities.dateAdd(new Date(), "minute", 55).toString());
        window.location.href = "/";
    }

    render() {
        return <div>Impersonating...</div>;
    }

}

export default Logos;