/* eslint-disable */
import React from 'react';
import UserApi from '../api/user/User';
import { toast } from 'react-toastify';
import '../css/DeleteAccountExternal.css';

interface State {
    email: string;
}

export class DeleteAccountExternal extends React.Component<{}, State> {

    state: State = {
        email: "",
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
      
    }

    submitEmail() {
        UserApi.deleteUnauthenticated(this.state.email).then(() => {
            toast.success("Request to delete account has been sent");
        }).catch(e => {
            toast.error("Failed to send request");
        })
    }

    render() {
        return (
            <div 
                className="external-account-delete-wrapper"
            >
                <h1 style={{
                    marginTop: "20px"
                    
                }}>Updatedge</h1>
                <h2>Enter your Email below to request your account and data to be deleted</h2>
                <input 
                    type="email" 
                    placeholder="Email"
                    onChange={(e) => this.setState({ email: e.target.value })}
                />
                <button 
                    onClick={() => this.submitEmail()}
                    className='main'
                >
                    Delete My Data
                </button>
            </div>
        )
    }
}

export default DeleteAccountExternal;