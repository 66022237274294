/* eslint-disable */
import React from 'react';
import ReactStars from "react-rating-stars-component";
import './RatingPreview.css';
import ProfileApi from '../../api/profile/Profile';
import CompanyApi from '../../api/company/Company';
import OrganisationApi from '../../api/organisation/Organisation';
import { Rating } from '../../api/offers/ResponseTypes';
import Utilities from '../../Utilities';
import RatingsApi from '../../api/ratings/Rating';
import { toast } from 'react-toastify';
import InternalTracker from 'src/InternalTracker';

type Props = {
    rating: Rating;
    hideRatee?: boolean;
    highlighted?: boolean;
    notOwn?: boolean;
    onLoading?: (boolean) => void;
    refresh?: () => void;
    showApprove?: boolean;
    clickableRecipientContactId?: string
    clickableRaterContactId?: string
}

interface State {
    raterImageFailedToLoad: boolean;
    recipientImageFailedToLoad: boolean;
}

class RatingPreview extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            raterImageFailedToLoad: false,
            recipientImageFailedToLoad: false
        }
    }

    report(id) {
        InternalTracker.trackEvent("Rating Reported", {
            id: id
        })
        if (this.props.onLoading) this.props.onLoading(true);
        RatingsApi
            .reportRating(id)
            .then(data => {
                console.log(data);
                toast.success(`Review Reported`);
                if (this.props.onLoading) this.props.onLoading(false);
                if (this.props.refresh) this.props.refresh();
            })
            .catch(e => {
                console.log(e);
                if (this.props.onLoading) this.props.onLoading(false);
            })
    }


    approve(id) {
        InternalTracker.trackEvent("Rating Approved", {
            id: id
        })
        if (this.props.onLoading) this.props.onLoading(true);
        RatingsApi
            .approveRating(id)
            .then(data => {
                toast.success(`Review Approved`);
                if (this.props.onLoading) this.props.onLoading(false);
                if (this.props.refresh) this.props.refresh();
            })
            .catch(e => {
                console.log(e);
                if (this.props.onLoading) this.props.onLoading(false);
            })
    }

    hide(id) {
        InternalTracker.trackEvent("Rating Hidden", {
            id: id
        })
        if (this.props.onLoading) this.props.onLoading(true);
        RatingsApi
            .hideRating(id)
            .then(data => {
                toast.success(`Review Approved`);
                if (this.props.onLoading) this.props.onLoading(false);
                if (this.props.refresh) this.props.refresh();
            })
            .catch(e => {
                console.log(e);
                if (this.props.onLoading) this.props.onLoading(false);
            })
    }

    render() {
        let rating = this.props.rating;
        const showRateBackBtn = (window.location.href.indexOf("/rating/") !== -1 && rating.raterContactId);

        if (rating.publicComment) {
            rating.publicComment = rating.publicComment.trim();
        }

        return (
            <div 
                className='rating-preview' 
                data-id={rating.id}
                data-highlighted={this.props.highlighted ? "true" : "false"} 
                data-hide-ratee={this.props.hideRatee ? "true" : "false"}
            >
                <div className='header'>
                    <div className='profile'>
                        <div 
                            className="rater-profile" 
                            data-report-blurred={rating.raterUserReported}
                            data-user-id={rating.raterUserId}
                            style={ this.props.clickableRaterContactId ? { textDecoration: 'underline', cursor: "pointer" } : { } }
                            onClick={() => {
                                if (this.props.clickableRaterContactId) {
                                    InternalTracker.trackEvent("Viewed Rating -> Redirect to Contact", {
                                        id: rating.id
                                    });
                                    window.open("/contact/" + this.props.clickableRaterContactId);
                                }
                            }}
                        >
                            <img 
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null;
                                    this.setState({
                                        raterImageFailedToLoad: true
                                    })
                                }}
                                src={
                                    this.state.raterImageFailedToLoad ?
                                    "https://test-images.updatedge.com/profile/noprofile.png" :
                                        (rating.raterExternalOrgName && rating.raterExternalOrgDomain) ?
                                        OrganisationApi.getExtOrgPicture(rating.raterExternalOrgDomain) :
                                            (!rating.raterContactId && rating.providerOrgName && rating.providerOrgId && rating.raterOrgId && rating.raterOrganisationName) ?
                                            CompanyApi.getOrganisationProfileImageUrl(rating.raterOrgId) :
                                                ProfileApi.getProfileImageUrl(rating.raterUserId || "undefined") 
                                }
                            />
                            { (rating.raterContactId && rating.raterOrgId) &&
                                <img className="secondary" src={CompanyApi.getOrganisationProfileImageUrl(rating.raterOrgId)} />
                            }
                            { (rating.providerOrgName && rating.providerOrgId) &&
                                <img className="secondary" src={CompanyApi.getOrganisationProfileImageUrl(rating.providerOrgId)} />
                            }
                            <h2>
                            {(rating.raterExternalOrgName && rating.raterExternalOrgDomain) ?
                                <React.Fragment>
                                    <span>{rating.raterExternalOrgName}</span>
                                    { (rating.providerOrgName) &&
                                        <span>care of {rating.providerOrgName}</span>
                                    }
                                </React.Fragment> :    
                            (
                                !rating.raterInternalContactId && 
                                rating.providerOrgName && 
                                rating.providerOrgId && 
                                rating.raterOrganisationName &&
                                [5,6,7,8,9,10,11,12,13,14,15,16].includes(rating.ratingDirection)
                            ) ?
                                <React.Fragment>
                                    <span>{rating.raterOrganisationName}</span>
                                    <span>care of {rating.providerOrgName}</span>
                                </React.Fragment> :
                            (rating.raterFirstName && rating.raterLastName) ? 
                                <React.Fragment>
                                    <span>{rating.raterFirstName} {rating.raterLastName}</span>
                                    { (rating.raterOrganisationName) &&
                                        <span>from {rating.raterOrganisationName}</span>
                                    }
                                </React.Fragment> :
                                <span>{rating.raterExternalContactName || "Anonymous"}</span>
                            }
                            {(
                                [1, 5].indexOf(rating.ratingDirection) !== -1 && 
                                rating.raterOrganisationName && !rating.raterFirstName &&
                                !(
                                    !rating.raterInternalContactId && 
                                    rating.providerOrgName && 
                                    rating.providerOrgId && 
                                    rating.raterOrganisationName &&
                                    [5,6,7,8,9,10,11,12,13,14,15,16].includes(rating.ratingDirection)
                                )
                            ) &&
                                <span>{rating.raterOrganisationName}</span>
                            }
                            </h2>
                        </div>
                        <div className='rated'>
                            rated
                        </div>
                        <div 
                            className="recipient-profile"
                            data-report-blurred={rating.recipientUserReported}
                            data-user-id={rating.recipientUserId}
                            style={ this.props.clickableRecipientContactId ? { textDecoration: 'underline', cursor: "pointer" } : { } }
                            onClick={() => {
                                if (this.props.clickableRecipientContactId) {
                                    InternalTracker.trackEvent("Viewed Rating -> Redirect to Contact", {
                                        id: rating.id
                                    });
                                    window.open("/contact/" + this.props.clickableRecipientContactId);
                                }
                            
                            }}
                        >
                            <img src={
                                this.state.raterImageFailedToLoad ? "https://test-images.updatedge.com/profile/noprofile.png" :
                                rating.recipientExternalOrgName && rating.recipientExternalOrgDomain ? OrganisationApi.getExtOrgPicture(rating.recipientExternalOrgDomain) :
                                (!rating.recipientContactId && rating.providerOrgName && rating.providerOrgId && rating.recipientOrgId && rating.recipientOrganisationName) ? CompanyApi.getOrganisationProfileImageUrl(rating.recipientOrgId) :
                                rating.recipientUserId ? ProfileApi.getProfileImageUrl(rating.recipientUserId) :
                                rating.recipientOrgId ? CompanyApi.getOrganisationProfileImageUrl(rating.recipientOrgId) :
                                ProfileApi.getProfileImageUrl("undefined")
                            } />
                            { (rating.recipientContactId && rating.recipientOrgId) &&
                                <img className="secondary" src={CompanyApi.getOrganisationProfileImageUrl(rating.recipientOrgId)} />
                            }
                            { (rating.providerOrgName && rating.providerOrgId) &&
                                <img className="secondary" src={CompanyApi.getOrganisationProfileImageUrl(rating.providerOrgId)} />
                            }
                            <h2>
                                { (rating.recipientFirstName && rating.recipientLastName || rating.recipientExternalContactName) && rating.ratingDirection !== 1 &&
                                <React.Fragment>
                                    { (rating.recipientFirstName) &&
                                        <span>{rating.recipientFirstName}</span>
                                    }
                                    { (rating.recipientLastName) &&
                                        <span>{rating.recipientLastName}</span>
                                    }
                                    { (rating.recipientExternalContactName) &&
                                        <span>{rating.recipientExternalContactName}</span>
                                    }
                                </React.Fragment>
                                }
                                { (
                                        !rating.raterInternalContactId && 
                                        rating.providerOrgName && 
                                        rating.providerOrgId && 
                                        (rating.recipientExternalOrgName || rating.recipientOrganisationName) &&
                                        [2, 3, 4].includes(rating.ratingDirection)
                                ) ?
                                    <React.Fragment>
                                        <span>{rating.raterOrganisationName}</span>
                                        <span>care of {rating.providerOrgName}</span>
                                    </React.Fragment> :
                                    <React.Fragment>
                                        <span>{rating.recipientOrganisationName || rating.recipientExternalOrgName || ((rating.recipientFirstName && rating.recipientLastName || rating.recipientExternalContactName) ? "" : "Anonymous")}</span>
                                        { (rating.ratingDirection === 1 && rating.providerOrgName) ?
                                            <span>care of {rating.providerOrgName}</span> : null
                                        }
                                    </React.Fragment>
                                }
                            </h2>
                        </div>
                    </div>
                    <div className='react-stars-wrapper'>
                        <ReactStars
                            value={rating.stars}
                            count={5}
                            edit={false}
                            size={24}
                            activeColor="#ffd700"
                        />
                    </div>
                </div>
                <div className='body' data-published={((rating.recipientApproved || this.props.notOwn) ? "true" : "false")}>
                    { (rating.publicComment) &&
                        <p> <i className="fas fa-comment-alt" style={{ marginRight: 6 }}></i> {rating.publicComment} <span>({Utilities.formatDate(new Date(rating.updatedAt || ""), "d mms YYYY")})</span></p>
                    }
                    { (rating.privateComment) &&
                        <p> <i className="fas fa-lock" style={{ marginRight: 6 }}></i>{rating.privateComment} <span>({Utilities.formatDate(new Date(rating.updatedAt || ""), "d mms YYYY")})</span></p>
                    }
                    { (!rating.publicComment && !rating.privateComment) &&
                        <p><span>{Utilities.formatDate(new Date(rating.updatedAt || ""), "d mms YYYY")}</span></p>
                    }
                    { (!rating.recipientApproved && this.props.showApprove && rating.publicComment) &&
                        <label>Unpublished, please approve below</label>
                    }
                    {/* { showRateBackBtn || (!rating.recipientApproved && this.props.showApprove) && */}
                        <div className='options'>
                            { showRateBackBtn &&
                                <a href={"/contact/" + rating.raterContactId} target="_blank">Rate Back</a>
                            }
                            { (!rating.recipientApproved && this.props.showApprove) &&
                                <button
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        this.approve(rating.id);
                                    }}
                                >Approve</button>
                            }
                            { (!rating.recipientApproved && this.props.showApprove) &&
                                <button 
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        this.hide(rating.id);
                                    }}
                                >Hide</button>
                            }
                            { (rating.publicComment) &&
                                <button 
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        if (window.confirm("Are you sure you want to report this review?")) {
                                            this.report(rating.id);
                                        }
                                    }}
                                >Report</button>
                            }
                        </div>
                    {/* } */}
                </div>
            </div>
        )
    }
}

export default RatingPreview;