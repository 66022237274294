export interface UserSetting {
    id: number;
    settingId: number;
    userId: string;
    value: string;
    setting: Setting;
}

export enum Setting {
    Alerts_Instant = 1,
    Alerts_Summary = 2,
    Alerts_SummaryFrequency = 3,
    Alerts_ChangePeriod = 4,
    Alerts_ChangePeriodCustomValue = 5,
    Alerts_ChangePeriodCustomUnit = 6,
    Company_NameConfirmed = 7,
    Alerts_HaveBeenViewed = 8,
    Dashboard_WizardDismissed = 9,
    Dashboard_AgenciesSkipped = 10,
    ProgressGuide_Completed = 11,
    Chats_HaveBeenViewed = 12,
    Lists_HaveBeenViewed = 13,
    Schedule_HaveBeenViewed = 14,
    AvailabilityPrinting_HaveBeenViewed = 15,
    TimePresets_Default = 16,
    Offers_ContinueOnWeekdays = 17,
    EventType_Default = 18,
    Offers_DefaultType = 19,
    Offers_DefaultAgencies = 20,
    Offers_DefaultMutualAgenciesAlertType = 21,
    Unit_Distance = 26,
    Files_HideGuide = 28,
    Availability_HideGuide = 41,
    Homepage = 42,
    Chat_NewLineOnChatEnter = 50
}
