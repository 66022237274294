/* eslint-disable */
/**
 * REDUX Store definition for contact management
 */

import { ToastType } from 'react-toastify';

import { AppThunkAction } from '..';
import * as ContactStoreActions from './Actions';
import ContactsApi from '../../api/contacts/Contacts';
import * as Notifications from '../Notifications';
import Analytics from '../../services/analytics.service';
import * as ContactModels from './Models';
import { KnownAction } from './Actions';
import { StorageKeys } from 'src/constants';
import { getValue, setValue } from 'src/db/KeyValueCache';

/**
 * Retrieves a list of contact groups for the authenticated user
 * @param dispatch Dispatch object
 */
function getContactListsTask(dispatch) {
    if (window.location.pathname.startsWith('/external')) return null;

    getValue("contactGroups").then(cachedContactGroups => {
        if (cachedContactGroups) {
            dispatch({
                type: 'CONTACTS_STORE.LISTS.REQUEST.SUCCESS',
                lists: JSON.parse(cachedContactGroups)
            } as ContactStoreActions.ContactListsRequestSuccessfulAction);
        }
    })

    return ContactsApi.getGroups().then((listsDto) => {
        // map to models
        const contactLists = listsDto.map((groupDto) => {
            return {
                loaded: true,
                count: groupDto.memberCount,
                ...groupDto
            } as ContactModels.ContactList;
        });

        setValue("contactGroups", JSON.stringify(contactLists));

        (window as any).contactLists = contactLists;
        // dispatch list request success handler
        dispatch({
            type: 'CONTACTS_STORE.LISTS.REQUEST.SUCCESS',
            lists: contactLists
        } as ContactStoreActions.ContactListsRequestSuccessfulAction);

        if (localStorage.getItem("autoLoadGroupOnRefresh")) {
            setTimeout(() => {
                // @ts-ignore;
                const listId = parseInt(localStorage.getItem("autoLoadGroupOnRefresh"));
                localStorage.removeItem("autoLoadGroupOnRefresh");
                const el = document.querySelector(".group-item[data-id='" + listId + "']") as HTMLElement;
                console.log("Auto select: " , el, listId)
                if (el) {
                    el.click();
                }
            }, 250)
        }

        // dispatch request for contacts for selected list
        getListContactsTask(contactLists[0].id, dispatch);
    });
}

/**
 *
 * @param id The id of the group in which contacts should be loaded
 * @param dispatch Dispatch object
 */
function getListContactsTask(id: number, dispatch) {
    dispatch({
        type: 'CONTACTS_STORE.LIST.CONTACTS.REQUEST.INIT',
        listId: id
    });
    dispatch({
        type: 'CONTACTS_STORE.LIST.CONTACTS.REQUEST.SUCCESS',
        contacts: []
    } as ContactStoreActions.ContactsInListRequestSuccessfulAction);
    return;
    ContactsApi.getContactsInGroup(id).then((group) => {
        dispatch({
            type: 'CONTACTS_STORE.LIST.CONTACTS.REQUEST.INIT',
            listId: id
        });
        dispatch({
            type: 'CONTACTS_STORE.LIST.CONTACTS.REQUEST.SUCCESS',
            contacts: group.members.map(member => {
                return {
                    
                    fullName: member.firstName + " " + member.lastName,
                    id: member.userId
                }
            })
        } as ContactStoreActions.ContactsInListRequestSuccessfulAction);
    });
}

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    /**
     * Starts the new group creation process
     */
    newListStart: (): AppThunkAction<ContactStoreActions.KnownAction> => (
        dispatch
    ) => {
        dispatch({ type: 'CONTACTS_STORE.LIST.CREATE.INIT' });
        setTimeout(() => {
            const autoFocusInput = document.getElementById("create-new-group-input");
            if (autoFocusInput) {
                autoFocusInput.focus();
            }
        }, 150)
        Analytics.trackEvent('list-creating');
    },

    /**
     * Submits the new group being created
     * @param name The name of the new group to be created
     */
    newListSubmit: (name: string): AppThunkAction<KnownAction> => (
        dispatch,
        getState
    ) => {
        ContactsApi.createNewGroup(name).then((newId) => {
            // dispatch({ type: 'CONTACTS_STORE.LIST.CREATE.SUBMIT' });
            Notifications.actionCreators.display(
                ToastType.SUCCESS,
                'New list created successfully.'
            );
            getContactListsTask(dispatch);
            localStorage.setItem("autoLoadGroupOnRefresh", newId.toString())
            // localStorage.setItem(StorageKeys.teamId, '0')
            // window.location.href = "/lists/" + newId
        });

        Analytics.trackEvent('list-created');
    },

    /**
     *
     * @param contactList The locally updated contact list
     * @param dispatch Dispatch object
     */
    updateState: (
        contactLists: ContactModels.ContactList
    ): AppThunkAction<KnownAction> => (dispatch) => {
        // @ts-ignore
        dispatch({
            type: 'CONTACTS_STORE.LISTS.REQUEST.SUCCESS',
            lists: contactLists
        } as ContactStoreActions.ContactListsRequestSuccessfulAction);
    },

    /**
     * Adds a contact to a group
     * @param listId The list to add the contact to
     * @param userId The id of the user to add to the list
     */
    addContactToList: (
        listId: number,
        userId: string
    ): AppThunkAction<KnownAction> => (dispatch) => {
        if ((window as any).contactLists) {
            const updatedList = (window as any).contactLists.map((list) => {
                if (list.id === listId) {
                    list.count++;
                    const user = (window as any).contactLists[0].members.find(m => m.userId === userId);
                    if (user) {
                        list.members.push({
                            firstName: user.firstName,
                            lastName: user.lastName,
                            userId: userId
                        })
                    }
                }
                return list;
            })
            setValue("contactGroups", JSON.stringify(updatedList));
            dispatch({
                type: 'CONTACTS_STORE.LISTS.REQUEST.SUCCESS',
                lists: updatedList
            } as ContactStoreActions.ContactListsRequestSuccessfulAction);
        }
        ContactsApi.addContactToGroup(listId, userId).then(() => {
            Notifications.actionCreators.display(
                ToastType.SUCCESS,
                'Contact added to list successfully.'
            );
        });

        Analytics.trackEvent('list-contact-add');
    },

    reloadContactList: (
        listId: number
    ): AppThunkAction<KnownAction> => (dispatch) => {

            dispatch({ type: 'CONTACTS_STORE.LIST.CONTACT.ADD.SUCCESS' });
            dispatch({
                type: 'CONTACTS_STORE.LIST.CONTACTS.REQUEST.INIT',
                listId: listId
            });
            getContactListsTask(dispatch);

        Analytics.trackEvent('list-contact-add');
    },

    /**
     * Requests contact lists from the API
     */
    getLists: (): AppThunkAction<KnownAction> => (dispatch) => {
        getContactListsTask(dispatch);
    },

    /**
     * Requests contacts in a given list
     * @param id The Id of the list to retrieve contacts for
     */
    getContactsInList: (id: number): AppThunkAction<KnownAction> => (
        dispatch
    ) => {
        getListContactsTask(id, dispatch);
    },

    /**
     * Removes a group
     * @param id The id of the group to remove
     */
    removeList: (id: number): AppThunkAction<KnownAction> => (dispatch) => {
        ContactsApi.removeGroup(id).then(() => {
            Notifications.actionCreators.display(
                ToastType.SUCCESS,
                'Group removed successfully.'
            );
            getContactListsTask(dispatch);
        });

        Analytics.trackEvent('list-remove');
    },

    /**
     * Removes a contact from a list
     * @param listId The list to remove the contact from
     * @param userId User id to remove
     */
    removeContactFromList: (listId: number, userId: string) => (dispatch) => {
        const updatedList = (window as any).contactLists.map((list) => {
            if (list.id === listId) {
                list.count--;
                const user = (window as any).contactLists[0].members.find(m => m.userId === userId);
                if (user) {
                    list.members = list.members.filter(m => m.userId !== userId);
                }
                return list;
            }
            return list;
        })
        if ((window as any).contactLists) {
            dispatch({
                type: 'CONTACTS_STORE.LISTS.REQUEST.SUCCESS',
                lists: updatedList
            } as ContactStoreActions.ContactListsRequestSuccessfulAction);
        }
        setValue("contactGroups", JSON.stringify(updatedList));
        ContactsApi.removeContactFromGroup(listId, userId).then(() => {
            Notifications.actionCreators.display(
                ToastType.SUCCESS,
                'Contact removed from list successfully.'
            );
        });

        Analytics.trackEvent('list-contact-remove');
    }
};
