import React from 'react';
import styled from 'styled-components';

import Analytics from '../../services/analytics.service';

interface StarRatingProps {
    value: number;
    editable?: boolean;
    starCount?: number;
    onChange?: (value: number) => void;
}

interface StarRatingState {
    value: number;
}

export class StarRating extends React.Component<
    StarRatingProps,
    StarRatingState
> {
    static defaultProps: Partial<StarRatingProps> = {
        starCount: 5,
        editable: false
    };

    constructor(props: StarRatingProps) {
        super(props);

        if (this.props.editable === true && !this.props.onChange) {
            throw 'Editable StarRatings must pass onChange event';
        }

        this.state = {
            value: this.props.value
        };
    }

    render() {
        const stars: JSX.Element[] = [];

        for (let i = 1; i <= this.props.starCount!; i++) {
            const met = this.state.value >= i;

            const events = this.props.editable
                ? {
                      onClick: () => this.handleStarClick(i),
                      onMouseEnter: () => this.handleStarMouseEnter(i),
                      onMouseLeave: () => this.handleStarMouseLeave()
                  }
                : {};

            stars.push(
                met ? (
                    <Star key={'star-' + i} {...events} />
                ) : (
                    <OpenStar key={'star-' + i} {...events} />
                )
            );
        }

        return (
            <Stars className={this.props.editable ? 'editable' : ''}>
                {stars}
            </Stars>
        );
    }

    static getDerivedStateFromProps({ value }) {
        return { value };
    }

    handleStarClick = (value: number) => {
        this.props.onChange!(value);

        Analytics.trackEvent('star-rating-changed');
    };

    handleStarMouseEnter = (value: number) => {
        this.setState({
            value: value
        });
    };

    handleStarMouseLeave = () => {
        this.setState({
            value: this.props.value
        });
    };
}

export const Star = styled.i.attrs({
    className: 'fa fa-star'
})`
    color: #ffb400;
`;

export const OpenStar = styled.i.attrs({
    className: 'fa fa-star-o'
})`
    color: #ffb400;
`;

const Stars = styled.span`
    display: inline;
    white-space: nowrap;

    &.editable {
        > .fa {
            cursor: pointer;
        }
    }
`;
