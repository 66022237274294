/* eslint-disable */
import { ToastType } from 'react-toastify';
import fetch from 'cross-fetch';

import AuthApi from '../components/auth/Auth';
import * as Notifications from '../store/Notifications';
import { StorageKeys } from '../constants';
import Analytics from '../services/analytics.service';
import { ErrorResponse } from './BaseResponseTypes';

export type HTTP_VERB = 'GET' | 'PUT' | 'PATCH' | 'DELETE' | 'POST';

export type ApiVersion = '1.0';

const DEFAULT_API_VERSION: ApiVersion = '1.0';

function appendApiVersion(_, url: string, apiVersion?: ApiVersion) {
    return url.includes('?')
        ? `${url}&api-version=${apiVersion}`
        : `${url}?api-version=${apiVersion}`;
}

async function request<TReq, TResp = boolean>(
    url: string,
    verb: HTTP_VERB,
    requestObject: TReq | null,
    uiFailureMessage?: string,
    apiVersion: ApiVersion = DEFAULT_API_VERSION,
    returnApiError = false,
    displayApiError = false,
    displayApiErrorAndReturnApiError = false
): Promise<TResp> {
    const path = appendApiVersion`${url}${apiVersion}`;

    const req = {
        method: verb.toString(),
        headers: {
            'Content-Type': 'application/json',
        },
        ...(verb == 'GET'
            ? {}
            : {
                  body: JSON.stringify(requestObject)
              })
    };

    const response = await fetch(path, req);

    const data = (await response.json().catch(() => response.ok)) as Promise<
        TResp
    >;

    if (response.ok) return data;

    Analytics.trackFailedRequest({
        url,
        verb,
        requestObject
    });

    const apiError = displayApiError ? data : null;

    /** Passes the API data back to the caller for manual error handling */
    if (returnApiError) return Promise.reject(data);

    // @ts-ignore
    Notifications.actionCreators.display(ToastType.ERROR, (data && data.error ? data.error : data) || uiFailureMessage || 'The request could not be performed.');

    if (displayApiErrorAndReturnApiError) {
        return Promise.reject(data);
    }

    return Promise.reject('API request failed - notification was displayed');
}

const UnauthenticatedFetch = {
    request
};

export default UnauthenticatedFetch
