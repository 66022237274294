import styled from 'styled-components';

import theme from '../../css/theme';

const Tag = styled.span`
    float: ${({ float }) => float || 'right'};
    margin-right: -10px;
    padding: 1px 6px 3px 6px;
    color: white;
    font-weight: normal;
    font-size: 10px;
    text-align: center;
    background: ${({ background }) => background || theme.colours.updatedge};
    border-radius: 10px;
    white-space: nowrap;
`;

export default Tag;
