/* eslint-disable */
import React from 'react';
import { RouteComponentProps, Redirect } from 'react-router';
import { RegularExpressions } from 'src/constants';
import Utilities from '../Utilities';
import JwtDecode from 'jwt-decode';

export default class PostLogin extends React.Component<
    RouteComponentProps<{}>,
    {
        pass: boolean
    }
> {

    constructor(props) {
        super(props)
        this.state = {
            pass: false
        }
    }

   componentDidMount() {

       if (location.pathname.startsWith("/redirectotp")) {
            const tokenResult = RegularExpressions.accessTokenQueryParam.exec(
                window.location.href
            );
            // @ts-ignore
            if (tokenResult && tokenResult[0]) {
                const token = tokenResult[0].toString().split("access_token=")[1]
                const accessTokenDecoded = JwtDecode(token);
                let redirectToOrg = false;
                if (accessTokenDecoded.organisationMatched && accessTokenDecoded.newUser && !localStorage.getItem("doneOrgRedirect")) {
                    redirectToOrg = true;
                    localStorage.setItem("doneOrgRedirect", "true")
                }
                localStorage.setItem("access_token_otp", token); 
                if (redirectToOrg) {
                    window.location.href = "/organisation"
                } else {
                    window.location.href = "/"
                }
            }
       } else {
           this.setState({
               pass: true
           })
       }
   }
   
    render() {
        if (this.state.pass)
            return <Redirect to="/" />;
        return null;
    }
}
