/* eslint-disable */
export async function getAllThreads() {
    if (!window.localIndexedDb) return null;
    let rows = await window.localIndexedDb.chatThreadMessagtes.toArray();
    return rows;
};

export async function saveThreads(threads) {
    if (!window.localIndexedDb) return [];
    const delRes = await window.localIndexedDb.chatThreadMessagtes.clear();
    const res = await window.localIndexedDb.chatThreadMessagtes.bulkPut(threads);
    return res;
}