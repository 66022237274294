/* eslint-disable */
import afetch from '../AuthenticatedFetch';
import AppConfig from '../../components/config/Config';
import { UserFile, UserFolder } from '../../store/contacts/Models';
import { AllFilesResponse } from './ResponseTypes';

export default {
    generateUploadLink(file: UserFile) {
        return afetch.request<UserFile, UserFile>(
            `${AppConfig.Settings.api.externalUri}/file/upload-request`,
            'POST',
            file,
            'Unable to get users'
        );
    },
    requestFile: (fileId: number) => {
        return afetch.request<null>(
            `${AppConfig.Settings.api.externalUri}/file/${fileId}/request`,
            'PUT',
            null,
            'Unable to request file'
        );
    },
    requestFileFolder: (folderId: number) => {
        return afetch.request<null>(
            `${AppConfig.Settings.api.externalUri}/filefolder/${folderId}/request`,
            'PUT',
            null,
            'Unable to request folder'
        );
    },
    markFileUplaoded(id: number, hasPreview: boolean) {
        return afetch.request<{
            hasPreview: boolean
        }, boolean>(
            `${AppConfig.Settings.api.externalUri}/file/${id}/mark-uploaded`,
            'PUT',
            {
                hasPreview: hasPreview
            },
            'Unable to save file'
        );
    },
    editFolder(id: number, folder: UserFolder) {
        return afetch.request<UserFolder, boolean>(
            `${AppConfig.Settings.api.externalUri}/filefolder/${id}`,
            'PUT',
            folder,
            'Unable to save to save folder'
        );
    },
    updateFileMetadata(file: UserFile) {
        return afetch.request<UserFile, boolean>(
            `${AppConfig.Settings.api.externalUri}/file/${file.id}`,
            'PUT',
            file,
            'Unable to get users'
        );
    },
    deleteFile(id: number) {
        return afetch.request<null, boolean>(
            `${AppConfig.Settings.api.externalUri}/file/${id}`,
            'DELETE',
            null,
            'Unable to get users'
        );
    },
    deleteFolder(id: number) {
        return afetch.request<null, boolean>(
            `${AppConfig.Settings.api.externalUri}/filefolder/${id}`,
            'DELETE',
            null,
            'Unable to get users'
        );
    },
    getAll() {
        return afetch.request<null, AllFilesResponse>(
            `${AppConfig.Settings.api.externalUri}/file`,
            'GET',
            null,
            'Unable to get files'
        );
    },
    shareFile(fileId: number, userId: string) {
        return afetch.request<null, boolean>(
            `${AppConfig.Settings.api.externalUri}/file/${fileId}/share/${userId}`,
            'PUT',
            null,
            'Unable to get users'
        );
    },
    rejectFileRequest(fileId: number, userId: string) {
        return afetch.request<null, boolean>(
            `${AppConfig.Settings.api.externalUri}/file/${fileId}/request/${userId}/reject`,
            'PUT',
            null,
            'Unable to reject request'
        );
    },
    rejectFolderRequest(folderId: number, userId: string) {
        return afetch.request<null, boolean>(
            `${AppConfig.Settings.api.externalUri}/filefolder/${folderId}/request/${userId}/reject`,
            'PUT',
            null,
            'Unable to reject request'
        );
    },
    removeFileShare(fileId: number, userId: string) {
        return afetch.request<null, boolean>(
            `${AppConfig.Settings.api.externalUri}/file/${fileId}/share/${userId}`,
            'DELETE',
            null,
            'Unable to get users'
        );
    },
    shareFolder(folderId: number, userId: string) {
        return afetch.request<null, boolean>(
            `${AppConfig.Settings.api.externalUri}/filefolder/${folderId}/share/${userId}`,
            'PUT',
            null,
            'Unable to get users'
        );
    },
    removeFolderShare(folderId: number, userId: string) {
        return afetch.request<null, boolean>(
            `${AppConfig.Settings.api.externalUri}/filefolder/${folderId}/share/${userId}`,
            'DELETE',
            null,
            'Unable to get users'
        );
    },
    fetchLinkPreview(url: string) {
        return afetch.request<null, { base64: string | null }>(
            `${AppConfig.Settings.api.nodeFunctionsUri}/GetLinkPreview?url=${url}&json=true&${AppConfig.Settings.api.nodeFunctionsUriSecret}`,
            'GET',
            null,
            'Failed to fetch link preview'
        );
    }
};