import React from 'react';
import styled from 'styled-components';
import { Modal, Button } from 'react-bootstrap';
import moment from 'moment';
import { toast } from 'react-toastify';

import {
    UserOfferDto,
    OfferConfirmationResponseTypes,
    OfferResponseTypes
} from '../../api/offers/ResponseTypes';
import ProfileApi from '../../api/profile/Profile';
import CompanyApi from '../../api/company/Company';
import { ProfileImage } from '../../components/ui-components/ProfileImage';
import theme from '../../css/theme';
import { DateFormats } from '../../constants';
import Analytics from '../../services/analytics.service';
import WorkerEngagementSelector from '../../components/mobile/WorkerEngagementSelector';
import MobileOfferEventsPreview from '../../components/offers/MobileOfferEventsPreview';
import OfferApi from '../../api/offers/Offers';
import LocationMap from '../../components/ui-components/LocationMap';

interface Props {
    onChange: (offer: UserOfferDto) => void;
}

interface State {
    offer: UserOfferDto | undefined;
    showOffer: boolean;
    showAgencySelector: boolean;
    showEventPreview: boolean;
}

export default class MobileOfferModal extends React.Component<Props, State> {
    state = {
        offer: undefined,
        showOffer: false,
        showAgencySelector: false,
        showEventPreview: false
    } as State;

    open = (offer: UserOfferDto) => {
        this.setState({
            offer: offer,
            showOffer: true,
            showEventPreview:
                offer.complete &&
                offer.confirmation ==
                    OfferConfirmationResponseTypes.Confirmed &&
                !offer.addedToSchedule
        });
    };

    render() {
        if (!this.state.offer) {
            return <></>;
        }

        const creatorImageUrl = ProfileApi.getProfileImageUrl(
            this.state.offer.metadata.createdById
        );
        const companyImageUrl = CompanyApi.getOrganisationProfileImageUrl(
            this.state.offer.metadata.createdByOrganisationId
        );

        return (
            <>
                <Modal
                    show={this.state.showOffer && !this.state.showEventPreview}
                    onHide={this.handleClose}
                    dialogClassName="modal-dialog-centered"
                >
                    <Modal.Body style={{ overflow: 'auto' }}>
                        <ModalContent
                            className={
                                'layout vertical ' +
                                (this.state.offer.locationPlaceId
                                    ? ''
                                    : '')
                            }
                        >
                            <h4
                                style={{
                                    textAlign: 'center',
                                    marginBottom: '0'
                                }}
                            >
                                {this.state.offer.title}
                            </h4>
                            <div className="layout horizontal justified">
                                <label>Hirer</label>
                                <div
                                    className="layout horizontal center"
                                    style={{
                                        color: this.state.offer.metadata
                                            .createdByIsVerified
                                            ? theme.colours.green
                                            : theme.colours.red
                                    }}
                                >
                                    <span style={{ fontWeight: 'bold' }}>
                                        {this.state.offer.metadata
                                            .createdByIsVerified
                                            ? 'Verified'
                                            : 'Unverified'}{' '}
                                        hirer
                                    </span>
                                    <i
                                        className="fa fa-shield m-l-xs"
                                        style={{ fontSize: '20px' }}
                                    />
                                </div>
                            </div>
                            <div className="layout horizontal center justified">
                                <div>
                                    <span>
                                        {
                                            this.state.offer.metadata
                                                .createdByFullName
                                        }
                                    </span>
                                    <br />
                                    {
                                        this.state.offer.metadata
                                            .createdByOrganisationName
                                    }
                                </div>
                                <div>
                                    <ProfileImage
                                        selectable={false}
                                        url={creatorImageUrl}
                                        size={54}
                                    />
                                    <ProfileImage
                                        selectable={false}
                                        url={companyImageUrl}
                                        size={54}
                                    />
                                </div>
                            </div>
                            {this.state.offer.description && (
                                <>
                                    <label>Additional information</label>
                                    <span>{this.state.offer.description}</span>
                                </>
                            )}
                            <label>Vacancy times</label>
                            {this.state.offer.events.map((ev) => (
                                <span
                                    key={`ev-${this.state.offer!.id}-${moment(
                                        ev.start
                                    ).format()}`}
                                >
                                    <span>
                                        {moment(ev.start).format(
                                            DateFormats.ShortDateWithDay
                                        )}
                                    </span>
                                    &nbsp;&nbsp;-&nbsp;&nbsp;
                                    <span>
                                        {moment(ev.start).format(
                                            DateFormats.Time24Hour
                                        )}
                                    </span>
                                    &nbsp;-&nbsp;
                                    <span>
                                        {moment(ev.end).format(
                                            DateFormats.Time24Hour
                                        )}
                                    </span>
                                </span>
                            ))}
                            {this.state.offer.locationPlaceId &&
                                this.state.offer.locationPlaceId.length && (
                                    <>
                                        <label>Location</label>
                                        <div className="layout vertical">
                                            <LocationMap
                                                placeId={
                                                    this.state.offer
                                                        .locationPlaceId
                                                }
                                                placeName={
                                                    this.state.offer
                                                        .locationPlaceName
                                                }
                                                showDirections={true}
                                                explicitHeight={180}
                                            />
                                        </div>
                                    </>
                                )}
                            {this.renderInfo()}
                            {this.state.offer.address &&
                                this.state.offer.address.county &&
                                    <>
                                        {!this.state.offer.locationPlaceId && (
                                            <label>Location</label>
                                        )}
                                        <div
                                            className="address"
                                            style={
                                                (this.state.offer.locationPlaceId) ? 
                                                    { marginTop: 12 } : 
                                                    {}
                                            }
                                        >
                                            <p>
                                                { this.state.offer.address.address1 }
                                                { this.state.offer.address.address2 && (', ' + this.state.offer.address.address2 ) }
                                                { this.state.offer.address.address3 && (', ' + this.state.offer.address.address3 ) }
                                                { this.state.offer.address.town && (', ' + this.state.offer.address.town ) }
                                                { this.state.offer.address.county && (', ' + this.state.offer.address.county ) }
                                                { this.state.offer.address.postcode && (', ' + this.state.offer.address.postcode ) }
                                            </p>
                                        </div>
                                    </>
                            }
                        </ModalContent>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            autoFocus
                            style={{ float: 'left' }}
                            onClick={this.handleClose}
                        >
                            Close
                        </Button>
                        {!this.hideActions && (
                            <>
                                <Button
                                    bsStyle="danger"
                                    disabled={this.hideActions}
                                    onClick={this.handleReject}
                                >
                                    Reject
                                </Button>
                                <Button
                                    bsStyle="success"
                                    disabled={this.hideActions}
                                    onClick={this.handleApply}
                                >
                                    Apply
                                </Button>
                            </>
                        )}
                        {!this.state.offer.addedToSchedule &&
                            this.state.offer.confirmation ==
                                OfferConfirmationResponseTypes.Confirmed && (
                                <Button
                                    bsStyle="primary"
                                    onClick={this.handleShowEventPreview}
                                >
                                    Add to schedule
                                </Button>
                            )}
                        {(this.state.offer.confirmation ==
                            OfferConfirmationResponseTypes.Rejected ||
                            this.state.offer.withdrawn) && (
                            <Button
                                bsStyle="primary"
                                onClick={this.handleAcknowledgeWithdrawn}
                            >
                                Acknowledge
                            </Button>
                        )}
                    </Modal.Footer>
                </Modal>
                <WorkerEngagementSelector
                    show={this.state.showAgencySelector}
                    onCancel={this.handleAgencySelectorHide}
                    onConfirm={this.handleAgencySelectorConfirm}
                />
                <MobileOfferEventsPreview
                    show={this.state.showEventPreview}
                    offer={this.state.offer}
                    onHide={this.handleEventPreviewHide}
                />
            </>
        );
    }

    renderInfo() {
        if (!this.state.offer) return null;

        if (
            this.state.offer.confirmation ==
            OfferConfirmationResponseTypes.Confirmed
        ) {
            return (
                <span
                    style={{
                        marginTop: '13px',
                        textAlign: 'center',
                        color: '#62cb31'
                    }}
                >
                    This offer has been confirmed
                </span>
            );
        } else if (
            this.state.offer.confirmation ==
                OfferConfirmationResponseTypes.Rejected ||
            this.state.offer.withdrawn
        ) {
            return (
                <span
                    style={{
                        marginTop: '13px',
                        textAlign: 'center',
                        color: '#DB8B00'
                    }}
                >
                    This offer has been withdrawn by the hirer
                </span>
            );
        } else if (
            this.state.offer.complete &&
            this.state.offer.confirmation == OfferConfirmationResponseTypes.Unsuccessful
        ) {
            return (
                <span
                    style={{
                        marginTop: '13px',
                        textAlign: 'center',
                        color: '#DB8B00'
                    }}
                >
                    You have been unsuccessful with this offer
                </span>
            );
        } else if (this.state.offer.response !== OfferResponseTypes.Pending) {
            return (
                <span
                    style={{
                        marginTop: '13px',
                        textAlign: 'center',
                        color: '#DB8B00'
                    }}
                >
                    You have already responded to this offer
                </span>
            );
        }
    }

    get hideActions(): boolean {
        const offer = this.state.offer;
        if (offer == null) return true;

        return (
            offer.withdrawn ||
            offer.confirmation != OfferConfirmationResponseTypes.NotSpecified ||
            offer.complete
        );
    }

    handleClose = () => {
        this.setState({
            offer: undefined,
            showOffer: false
        });

        Analytics.trackEvent('mobile-offers-offer-closed');
    };

    handleAcknowledgeWithdrawn = () => {
        if (this.state.offer) {
            OfferApi.acknowledgeOfferWithdrawn(this.state.offer.id);

            this.props.onChange({
                ...this.state.offer!,
                withdrawnAcknowledged: true
            });
        }

        this.handleClose();
    };

    handleReject = () => {
        if (this.state.offer) {
            const id = this.state.offer.id;

            this.setState({
                offer: undefined,
                showOffer: false
            });

            OfferApi.rejectOffer(id);

            toast.warn('Rejected offer', { autoClose: 2500 });

            Analytics.trackEvent('mobile-offers-offer-rejected');

            this.props.onChange({
                ...this.state.offer,
                response: OfferResponseTypes.No
            });
        }
    };

    handleApply = () => {
        //When skipWorkerEngagement == true, don't showAgencySelector, but callback fires to complete applying for offer
        //When !skipWorkerEngagement, showAgencySelector, and skip prematurely applying for offer.

        //This is to stop workers being presented with an alternate agency selector when an agency has sent the offer.
        this.setState(
            {
                showOffer: false,
                showAgencySelector: !this.state.offer!.skipWorkerEngagement
            },
            () => {
                if (this.state.offer!.skipWorkerEngagement) {
                    this.handleAgencySelectorConfirm(false);
                }
            }
        );

        Analytics.trackEvent('mobile-offers-offer-apply-clicked');
    };

    handleShowEventPreview = () => {
        this.setState({
            showEventPreview: true
        });

        Analytics.trackEvent('mobile-offers-event-preview-open');
    };

    handleEventPreviewHide = (added?: boolean) => {
        const offer = { ...this.state.offer!, addedToSchedule: !!added };

        if (added !== undefined) {
            this.props.onChange(offer);
        }

        this.setState({
            showEventPreview: false,
            offer: offer
        });

        Analytics.trackEvent('mobile-offers-event-preview-close');

        this.props.onChange(offer);
    };

    handleAgencySelectorHide = () => {
        this.setState({
            showAgencySelector: false,
            offer: undefined
        });

        Analytics.trackEvent('mobile-offers-offer-engagement-closed');
    };

    handleAgencySelectorConfirm = (engageDirectly: boolean) => {
        if (this.state.offer) {
            const id = this.state.offer.id;

            this.props.onChange({
                ...this.state.offer,
                response: OfferResponseTypes.Yes
            });

            OfferApi.acceptOffer(id, engageDirectly).then(() => {
                this.setState({
                    offer: undefined,
                    showOffer: false,
                    showAgencySelector: false
                });
                toast.success('Applied for offer!', { autoClose: 2500 });
                Analytics.trackEvent('mobile-offers-offer-applied');
            });
        }
    };
}

const ModalContent = styled.div`
    position: relative;
    height: auto;

    label {
        margin: 10px 0 5px 0;
    }

    .address p {
        margin-bottom: 6px;
    }

    &.fill-height {
        height: calc(100vh - 180px) !important;
    }

    @media only screen and (max-width: 350px) {
        &.fill-height {
            height: calc(100vh - 100px) !important;
        }
    }
`;
