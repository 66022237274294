/* eslint-disable */
import React from 'react';
import { ContactAvailabilitySummaryModel, Skill } from '../../store/Availability';
import { StringDayIntervalDTO } from '../../api/availability/RequestTypes';
import moment from 'moment';

import { DateFormats, isToday } from '../../constants';
import SignalRService from '../../services/signalr.service';
import AvailabilityApi from '../../api/availability/Availability';
import TimePresetApi from '../../api/timepresets/TimePresets';
import ContactApi from '../../api/contacts/Contacts';
import LocationsApi from '../../api/locations/Locations';
import { ContactListDto } from '../../api/contacts/ResponseTypes';
import { TimePresetDto } from '../../api/timepresets/RequestTypes';
import { Location } from '../../api/locations/ResponseTypes';
import { ClockTime } from '../ui-components/ClockPicker';
import { ALL_DAY_PRESET_NAME } from '../availability/SearchTimePresetEditor';
import { Period } from './CreateOffer';
import { AgencyNotificationConfig } from '../../api/dashboard/ResponseTypes';
import Utilities from 'src/Utilities';
import { toast } from 'react-toastify';
import { setValue } from 'src/db/KeyValueCache';

interface CreateOfferUtilsProviderProps {
    hasOrganisation: boolean;
    children: (
        injectedProps: CreateOfferUtilsProviderReturnValue
    ) => JSX.Element | null;
}

export type CreateOfferUtilsProviderReturnValue = ReturnType<
    CreateOfferUtilsProvider['getProvidedUtils']
>;

interface AgencyOrgRelation {
    organisationId: string;
    organisationName: string;
    contactId: number;
}

interface CreateOfferUtilsProviderState {
    contacts: ContactAvailabilitySummaryModel[];
    presets: TimePresetDto[];
    lists: ContactListDto[];
    locations: Location[];
    isLocationsSubscriptionActive: boolean;
    additionalLocationsUsed: number;
    hasLoaded: boolean;
    hasLoadedContacts: boolean;
    isLoadingContacts: boolean;
    initialOffer: Record<string, any>;
    defaultLocationName: string;
    skills?: Skill[];
    offer: {
        title: string;
        description: string;
        placeId: string;
        workerIds: string[];
        periods: Period[];
        agencyNotifications?: AgencyNotificationConfig;
        mutualAgencyContacts: AgencyOrgRelation[];
        agencyOnly?: boolean;
        agencyDirectNotifications?: number[] | null;
    };
    selectedFolders: number[];
    selectedFiles: number[];
}

const LOCATIONS_REQUEST_FALLBACK = {
    locations: [] as Location[],
    additionalLocationsCapacity: 0,
    additionalLocationsUsed: 0,
    isSubscriptionActive: false
};

export const DEFAULT_CREATE_OFFER_UTILS_PROVIDER_STATE: CreateOfferUtilsProviderState = {
    hasLoaded: false,
    hasLoadedContacts: false,
    isLoadingContacts: false,
    contacts: [] as ContactAvailabilitySummaryModel[],
    presets: [] as TimePresetDto[],
    lists: [] as ContactListDto[],
    locations: [] as Location[],
    isLocationsSubscriptionActive: false,
    additionalLocationsUsed: 0,
    initialOffer: {},
    offer: {
        title: '',
        description: '',
        placeId: '',
        workerIds: [] as string[],
        periods: [] as Period[],
        mutualAgencyContacts: [],
        agencyDirectNotifications: []
    },
    defaultLocationName: '',
    selectedFolders: [],
    selectedFiles: []
};

export const DEFAULT_CREATE_OFFER_UTILS: CreateOfferUtilsProviderReturnValue = {
    data: DEFAULT_CREATE_OFFER_UTILS_PROVIDER_STATE,
    functions: {
        fetchAvailableContacts: () => new Promise((resolve) => resolve()),
        getAllDayPreset: () => undefined,
        updateOfferFormData: () => null,
        createOfferPeriod: () => ({} as Period),
        reset: () => null
    }
};

export const LAST_PRESET = 'CreateOffer.LastPreset';

export const CustomPreset: Pick<TimePresetDto, 'id' | 'name'> = {
    id: '9a6ff76c-7df5-4e00-a3d2-407a9154189f',
    name: 'Custom'
};

class CreateOfferUtilsProvider extends React.Component<
    CreateOfferUtilsProviderProps,
    CreateOfferUtilsProviderState
> {
    private hubConnection: any | undefined;

    state: CreateOfferUtilsProviderState = DEFAULT_CREATE_OFFER_UTILS_PROVIDER_STATE;

    async componentDidMount() {
        if (this.props.hasOrganisation) {
            /**
             * TODO: Look into storing locations in shared state
             * It shouldn't be the CreateOffer components responsibility to subscribe to this event
             */
            this.hubConnection = SignalRService.create('subscriptions');
            this.hubConnection!.on('LocationsModified', this.getOfferLocations);
        }

        const getPresets = TimePresetApi.getPresets();
        const getLists = ContactApi.getGroups();
        const getLocations = this.getOfferLocations();

        const [
            presets,
            lists,
            { locations, isSubscriptionActive, additionalLocationsUsed }
        ] = await Promise.all([getPresets, getLists, getLocations]);

        if (locations && locations.length) {
            localStorage.setItem("locationCount", locations.length.toString());
        }

        setValue("time-presets", JSON.stringify(presets));

        const { initialOffer, defaultLocationName } = this.getInitialOffer({
            presets,
            locations
        });

        this.setState({
            presets,
            lists: lists,
            locations,
            additionalLocationsUsed,
            initialOffer,
            defaultLocationName,
            isLocationsSubscriptionActive: isSubscriptionActive,
            hasLoaded: true
        });
    }

    componentWillUnmount() {
        if (this.hubConnection) {
            this.hubConnection.stop();
        }
    }

    fetchAvailableContacts = async (
        listId: number,
        intervals: StringDayIntervalDTO[],
        skills: Skill[]
    ) => {
        this.setState({
            contacts: [],
            isLoadingContacts: true
        });

        if (intervals.length === 0) {
            return this.setState({
                contacts: [],
                isLoadingContacts: false,
                hasLoadedContacts: true
            });
        }

        const contacts = await AvailabilityApi.getAvailabilityForListByIntervals(
            listId,
            intervals,
            skills
        ).then(contacts => {
            this.setState({
                contacts,
                isLoadingContacts: false,
                hasLoadedContacts: true
            });
        }).catch(() => {
            this.setState({
                contacts: [],
                isLoadingContacts: false,
                hasLoadedContacts: true
            });
            toast.error("Failed to load contacts");
        });
    };

    getAllDayPreset = (presets = this.state.presets) =>
        presets.find((p) => p.name == ALL_DAY_PRESET_NAME);

    getOfferLocations = () =>
        LocationsApi.getAll()
            .then(({ data }) => data)
            .catch(() => LOCATIONS_REQUEST_FALLBACK);

    getInitialOffer = ({ locations, presets }) => {
        const initialPlaceId = locations[0] ? locations[0].locationPlaceId : '';
            // locations.length === 1 ? 
                // locations[0].locationPlaceId // : // TODO restore or last used
            //    localStorage.getItem("lastOfferLocation") ?
            //    localStorage.getItem("lastOfferLocation") : 
            //    '';

        const defaultLocationName =
            locations.length === 1 ? locations[0].locationPlaceName : null;

        const initialPeriod = this.getInitialOfferPeriod(presets);

        const initialOffer = {
            title: '',
            description: '',
            placeId: initialPlaceId,
            periods: [initialPeriod],
            workerIds: [],
            agencyNotifications: {
                alertType: 0,
                defaultTimeout: 10,
                agencies: []
            },
            mutualAgencyContacts: [],
            agencyDirectNotifications: [],
            deadline: "Off",
            agencyOnly: false
        };

        return { initialOffer, defaultLocationName };
    };

    private updateOfferFormData = (
        newOfferData:
            | Partial<CreateOfferUtilsProviderState['offer']>
            | ((
                  prevOffer: Readonly<CreateOfferUtilsProviderState['offer']>
              ) => Partial<CreateOfferUtilsProviderState['offer']>),
        callback?: () => void
    ) => {
        this.setState(
            (prevState) => ({
                offer: {
                    ...prevState.offer,
                    ...(typeof newOfferData === 'function'
                        ? newOfferData(prevState.offer)
                        : newOfferData)
                }
            }),
            callback
        );
    };

    getProvidedUtils = () => ({
        data: this.state,
        functions: {
            fetchAvailableContacts: this.fetchAvailableContacts,
            getAllDayPreset: this.getAllDayPreset,
            updateOfferFormData: this.updateOfferFormData,
            createOfferPeriod: this.createOfferPeriod,
            reset: this.reset
        }
    });

    render() {
        const { children } = this.props;
        if (children == null) return children;
        return children(this.getProvidedUtils());
    }

    private getOfferStartDate = (): string => {
        const date = moment().startOf('day');
        if (localStorage.getItem("offersContinueOnWeekdays")) {
            if (date.isoWeekday() === 5) {
                date.add(3, 'day');
            } else if (date.isoWeekday() === 6) {
                date.add(2, 'day');
            } else if (date.isoWeekday() === 7) {
                date.add(1, 'day');
            } /* else if (moment().hour() > 10) {
                date.add(1, 'day');
            } */
        } /* else if (moment().hour() > 10) {
            date.add(1, 'day');
        }*/
        return date.format(DateFormats.ShortDateWithDay);
    };

    private getInitialOfferPeriod = (presets: TimePresetDto[]): Period => {
        const lastPreset = localStorage.getItem(LAST_PRESET);
        const initialPreset =
            presets.find((p) => lastPreset != null && lastPreset == p.id) ||
            this.getAllDayPreset(presets) ||
            presets[0];

        return this.createOfferPeriod(initialPreset);
    };

    private createOfferPeriod = (
        preset: Pick<
            TimePresetDto,
            'startHour' | 'startMinute' | 'endHour' | 'endMinute' | 'id'
        >,
        date = this.getOfferStartDate()
    ): Period => {
        const now = new Date();
        let customStart = new ClockTime(preset.startHour, preset.startMinute);
        // let limit = Utilities.dateAdd(now, "minute", 30);
        // let limitClockTime = new ClockTime(limit.getHours(), limit.getMinutes());

        const presetStartTimePassed =
            isToday(moment(date, DateFormats.ShortDateWithDay).toDate()) &&
            customStart.toString() < moment().format('HH:mm');

        if (presetStartTimePassed) {
            // customStart = new ClockTime(limitClockTime.hour, limitClockTime.minute);
        }

        const timePresetId = // presetStartTimePassed
            // ? CustomPreset.id
            /*: */ preset.id // || CustomPreset.id;

        let finalDate = presetStartTimePassed ? moment(Utilities.dateAdd(new Date(date), "day", 1)).format(DateFormats.ShortDateWithDay) : date;
        const dateOverride = Utilities.getParameterByName("date") || null; // YYYY-MM-DD
        if (dateOverride) {
            console.log("Override: " + finalDate + "->" + moment(dateOverride).format(DateFormats.ShortDateWithDay))
            finalDate = moment(dateOverride).format(DateFormats.ShortDateWithDay);
            
        }

        const initialPeriod = {
            date: finalDate,
            timePreset: timePresetId,
            customStart: new ClockTime(preset.startHour, preset.startMinute), // customStart,
            customEnd: new ClockTime(preset.endHour, preset.endMinute)
        };

        return initialPeriod;
    };

    private reset = () => {
        this.setState((prevState) => ({
            offer: prevState.initialOffer as typeof DEFAULT_CREATE_OFFER_UTILS_PROVIDER_STATE['offer']
        }));
    };
}

export default CreateOfferUtilsProvider;
