import React from 'react';
import styled from 'styled-components';

import theme from '../../css/theme';
import SvgTeamwork from '../svg/teamwork';
import SvgQuestion from '../svg/question';
import SvgOffer from '../svg/offer';
import SvgProtection from '../svg/protection';
import SvgPlace from '../svg/place';
import SvgInternet from '../svg/internet';
import Tag from '../ui-components/Tag';

interface Icon {
    title: string;
    text: string | JSX.Element;
    icon: React.FC;
}

interface MarketingProps {
    headline?: string;
    subtext?: string;
    icons?: Icon[];
}

const Marketing = ({ headline, subtext, icons }: MarketingProps) => (
    <Content>
        <div>
            {headline && <Headline>{headline}</Headline>}
            {subtext && <Subtext>{subtext}</Subtext>}
        </div>
        {icons && icons.length > 0 && (
            <div className="layout horizontal center-justified wrap">
                {icons.map(({ title, text, icon: Icon }) => (
                    <div key={title}>
                        <Icon />
                        <div>{title}</div>
                        <small>{text} </small>
                    </div>
                ))}
            </div>
        )}
    </Content>
);

const orgMarketingConfig: MarketingProps = {
    icons: [
        {
            title: 'Organization Branding',
            text: '',
            icon: SvgInternet
        },
        {
            title: 'Locations',
            text: '',
            icon: SvgPlace
        },
        {
            title: 'Manage Members',
            text: '',
            icon: SvgTeamwork
        },
        {
            title: 'Create Teams',
            text: '',
            icon: SvgProtection
        }
    ]
};

export const FreeTrialText = ({
    totalTrialDays,
    isTrialActive
}: {
    totalTrialDays?: number;
    isTrialActive?: boolean;
}) => {
    if (totalTrialDays && isTrialActive)
        return (
            <>
                This will be included as part of your existing{' '}
                <strong>free</strong> trial for this feature
            </>
        );

    if (totalTrialDays)
        return (
            <>
                Enjoy a <strong>free</strong> {totalTrialDays} day trial without
                commitment
            </>
        );

    return (
        <>
            Enjoy a <strong>free</strong> trial without commitment
        </>
    );
};

const teamsMarketingConfig: MarketingProps = {
    headline: 'Work better together, with Teams',
    subtext: 'Create a team and get more tools to work better together',
    icons: [
        {
            title: 'Share Contacts',
            text:
                'No longer do you each have to add the same contact to your network',
            icon: SvgTeamwork
        },
        {
            title: 'Share Availability',
            text: `Quickly view the availability of each other's contacts from your own account`,
            icon: SvgOffer
        },
        {
            title: 'Share Offers',
            text:
                'Manage offers as a team to work more efficiently, cope with absence, etc.',
            icon: SvgQuestion
        }
        // {
        //     title: 'Free Trial',
        //     text: <FreeTrialText />,
        //     icon: SvgProtection
        // }
    ]
};

const locationsMarketingConfig: MarketingProps = {
    headline: 'Manage Locations',
    subtext: 'Add multiple locations to customise your offers',
    icons: [
        {
            title: 'Offer Locations',
            text: 'Send offers from all your organizations locations',
            icon: SvgPlace
        },
        {
            title: 'Team Locations',
            text: (
                <>
                    Assign locations to teams for easier management
                    <br />
                    <Tag float="none">Coming Soon</Tag>
                </>
            ),
            icon: SvgInternet
        }
    ]
};

const OrganisationMarketing = () => <Marketing {...orgMarketingConfig} />;

const TeamsMarketing = () => <Marketing {...teamsMarketingConfig} />;

const LocationsMarketing = () => <Marketing {...locationsMarketingConfig} />;

const Content = styled.div`
    > div:first-child {
        margin-bottom: 10px;

        + div {
            > div {
                width: 168px;
                margin: 10px;
                text-align: center;

                svg {
                    width: 75px;
                    height: 75px;

                    + div {
                        color: ${theme.colours.updatedge};
                        font-size: 16px;
                    }
                }
            }
        }
    }
`;

const Headline = styled.h5`
    color: ${theme.colours.updatedge};
    font-weight: 600;
    font-size: 24px;
    text-align: center;
`;

const Subtext = styled.div`
    margin: 15px 0 0 0;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
`;

export { OrganisationMarketing, TeamsMarketing, LocationsMarketing };
