import React from 'react';
import styled from 'styled-components';
import { Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import SvgTeamwork from '../svg/teamwork';

interface Props {
    onHide: () => any;
    onAccepted: () => any;
}

export class CompletedGuide extends React.Component<Props, {}> {
    render() {
        return (
            <>
                <Modal.Header
                    style={{ borderRadius: '5px' }}
                    className="layout horizontal center-justified"
                >
                    <Modal.Title style={{ fontSize: '28px' }}>
                        {
                            <div
                                style={{
                                    color: 'green',
                                    width: '100%!important',
                                    textAlign: 'center'
                                }}
                            >{`Yipee! `}</div>
                        }
                        {`We've discovered  `}
                        {<span style={{ color: 'green' }}>{1}</span>}
                        {` contacts you may know!`}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="layout horizontal center-justified wrap">
                        <SvgTeamwork
                            style={{
                                width: '20%',
                                height: 'auto'
                            }}
                        />
                    </div>
                    <Headline style={{ marginTop: '2rem' }}>
                        {`Confirm your organization details to invite them`}
                    </Headline>
                </Modal.Body>
                <Modal.Footer style={{ borderRadius: '5px' }}>
                    <Link to="/organisation">
                        <Button
                            bsStyle="success"
                            className="m-r-xs"
                            onClick={() => {
                                this.props.onAccepted();
                            }}
                        >
                            Let's go!
                        </Button>
                    </Link>
                </Modal.Footer>
            </>
        );
    }
}

const Headline = styled.h5`
    font-weight: 400;
    font-size: 18px;
    text-align: center;
`;
