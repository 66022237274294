/* eslint-disable */
import React from 'react';
import styled from 'styled-components';
import { Modal, Button } from 'react-bootstrap';

import { Subscribe } from '../../unstated-fork/unstated';
import { AsyncOverlay } from '../ui-components/AsyncOverlay';
import theme from '../../css/theme';
import { ProfileVerificationContainer } from '../../state-containers/profile-verification.container';
import InternalTracker from 'src/InternalTracker';

interface Props {
    show: boolean;
    onClose: (set: boolean) => void;
}

interface State {
    verifying: boolean;
}

export default class ProfileVerification extends React.Component<Props, State> {
    state = {
        verifying: false
    } as State;

    handleClose = (verified: boolean) => {
        this.props.onClose(verified);
    };

    handleVerifyClick = async (verification: ProfileVerificationContainer) => {
        InternalTracker.trackEvent('Profile Verification Attempted');
        this.setState({
            verifying: true
        });

        await verification.tryVerify();

        this.setState({
            verifying: false
        });
    };

    render() {
        return (
            <Subscribe to={[ProfileVerificationContainer]}>
                {(verification: ProfileVerificationContainer) => (
                    <Modal
                        show={this.props.show}
                        onHide={() =>
                            this.handleClose(verification.state.verified)
                        }
                    >
                        <Modal.Body>
                            {this.state.verifying ? (
                                <div style={{ height: '300px' }}>
                                    <AsyncOverlay
                                        show={this.state.verifying}
                                        text="Verifying your profile..."
                                    />
                                </div>
                            ) : (
                                <ResultContainer className="layout vertical center-center">
                                    {this.renderStatus(verification)}
                                </ResultContainer>
                            )}
                            <div className="layout horizontal end-justified">
                                {!verification.state.verified &&
                                    !verification.state.submitted && (
                                        <Button
                                            bsStyle="primary"
                                            className="m-r-xs"
                                            onClick={() =>
                                                this.handleVerifyClick(
                                                    verification
                                                )
                                            }
                                        >
                                            Verify profile
                                        </Button>
                                    )}
                                <Button
                                    onClick={() =>
                                        this.handleClose(
                                            verification.state.verified
                                        )
                                    }
                                    disabled={this.state.verifying}
                                >
                                    Close
                                </Button>
                            </div>
                        </Modal.Body>
                    </Modal>
                )}
            </Subscribe>
        );
    }

    renderStatus(verification: ProfileVerificationContainer) {
        if (!verification.state.verified && verification.state.submitted) {
            return (
                <div>
                    <i className="fa fa-clock" />
                    <h4>A verification request has been submitted</h4>
                    <div>You can expect a response within 48 hours</div>
                    <div className="m-t-lg" style={{ textAlign: 'left' }}>
                        <strong>Please note:</strong>
                        <br />
                        We will need to call you if we are unable to verify your
                        profile. Please include your direct telephone number on
                        your profile.
                        <br />
                        Please contact{' '}
                        <a href="mailto:support@updatedge.com">
                            support@updatedge.com
                        </a>{' '}
                        if verification is urgent.
                    </div>
                </div>
            );
        } else if (verification.state.verified) {
            return (
                <div>
                    <i className="fa fa-shield verified" />
                    <h4>Profile successfully verified!</h4>
                    <div className="m-t-lg">
                        You will now appear as{' '}
                        <span style={{ fontStyle: 'italic' }}>verified</span> on
                        invitations and offers you send to contacts
                    </div>
                    <div>
                        Please contact us at{' '}
                        <a href="mailto:support@updatedge.com">
                            support@updatedge.com
                        </a>{' '}
                        if you require a change to your profile name or
                        organization name.
                    </div>
                </div>
            );
        } else {
            // Not verified and haven't submitted yet
            return (
                <div>
                    <i className="fa fa-shield" />
                    <h4 style={{ marginBottom: '20px' }}>
                        Verify your profile
                    </h4>
                    <div>
                        New users appear as unverified to contacts and have
                        limited access until verified.
                    </div>
                    <div>
                        Your contacts need to know it is you connecting with
                        them.
                    </div>
                    <div>
                        <strong>Please note:</strong>
                        <br />
                        updatedge will attempt to verify your profile through
                        trusted third-parties.
                        <br />
                        We may need to call you. Please include your direct
                        telephone number in your profile.
                        <br />
                        Once verified your profile name and organization name
                        can be changed by contacting{' '}
                        <a href="mailto:support@updatedge.com">
                            support@updatedge.com
                        </a>
                    </div>
                </div>
            );
        }
    }
}

const ResultContainer = styled.div`
    height: 300px;
    text-align: center;

    > div {
        width: 100%;
    }

    .fa-clock,
    .fa-shield {
        font-size: 40px;
    }

    .fa-shield:not(.verified) ~ div {
        margin-top: 10px;
        text-align: left;
    }

    .fa-check-circle {
        margin-right: 5px;
        margin-left: 5px;
        font-size: 20px;

        + span {
            display: inline-block;
            width: 80px;
            text-align: left;
        }
    }

    .fa-shield,
    .fa-check-circle {
        opacity: 0.3;

        &.verified {
            color: ${theme.colours.green};
            opacity: 1;
        }
    }
`;
