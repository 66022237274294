// TODONOW
/* eslint-disable */
import React from 'react';
import { toast } from 'react-toastify';
import { ContactResult } from '../../api/invites/ResponseTypes';

import InviteAPI from '../../api/invites/Invites';
import InternalTracker from '../../InternalTracker';
import { SearchOrganisationModal } from './SearchOrganisationModal';
import { SearchContactModal, ContactSearchType } from './SearchContactModal';

interface ContactSearchProps {
    onInvited?: () => void;
}

interface ContactSearchState {
    showOrganisationModal: boolean;
    showContactModal: boolean;
    contactSearchType: ContactSearchType;
    selectedOrganisationIds: string[];
}

class ContactSearch extends React.Component<
    ContactSearchProps,
    ContactSearchState
> {
    constructor(props) {
        super(props);

        this.state = {
            showOrganisationModal: false,
            showContactModal: false,
            contactSearchType: ContactSearchType.Internal,
            selectedOrganisationIds: []
        };
    }

    private handleOrganisationClick = () => {
        this.setState({
            showOrganisationModal: true
        });
        InternalTracker.trackEvent("", {
            category: 'Invite',
            action: 'Organisation Search Started'
        });
    };

    private handleOrganisationClose = () => {
        this.setState({
            showOrganisationModal: false,
        });
    };

    private handleContactClick = () => {
        this.setState({
            contactSearchType: ContactSearchType.Internal,
            showContactModal: true
        });
    };

    private handleContactClose = () => {
        this.setState({
            showContactModal: false
        });
    };

    private handleOrganisationSelected = (companyIds: string[]) => {
        this.setState({
            contactSearchType: ContactSearchType.External,
            selectedOrganisationIds: companyIds,
            showOrganisationModal: false,
            showContactModal: true
        });
    };

    private handleContactSelected = (contacts: ContactResult[]) => {
        InviteAPI.inviteContacts(contacts).then((successful) => {
            if (successful) {
                toast.success(
                    `Successfully invited ${contacts.length} contact${
                        contacts.length > 1 ? 's' : ''
                    }`
                );

                if (this.props.onInvited) {
                    this.props.onInvited();
                }
            } else {
                toast.error('Unable to invite contacts');
            }
        });
    };

    render() {
        return (
            <div onClick={() => {
                if (!this.state.showOrganisationModal) {
                    this.handleOrganisationClick()
                }
            }}>
                <img src="/img/invite-agency.png" />
                <p>
                    Invite contacts via agencies
                </p>
                <SearchOrganisationModal
                    show={this.state.showOrganisationModal}
                    onShowChanged={this.handleOrganisationClose}
                    onSelected={this.handleOrganisationSelected}
                    multi={true}
                />
                <SearchContactModal
                    show={this.state.showContactModal}
                    onShowChanged={this.handleContactClose}
                    onSelected={this.handleContactSelected}
                    multi={true}
                    organisationIds={this.state.selectedOrganisationIds}
                    contactSearchType={this.state.contactSearchType}
                />
            </div>
        );
    }
}

export default ContactSearch;
