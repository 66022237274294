import React from 'react';

const SvgTeamwork = (props) => (
    <svg height="1em" viewBox="0 0 512.001 512" width="1em" {...props}>
        <path
            d="M512 256c0 102.535-60.281 190.996-147.34 231.86-27.031 12.695-56.644 20.792-87.844 23.3-6.867.555-13.804.836-20.816.836-8.23 0-16.395-.387-24.438-1.16a252.785 252.785 0 01-30.269-4.7 254.333 254.333 0 01-57.48-19.968c-54.38-26.55-97.95-71.805-122.336-127.383C7.664 327.325.004 292.56.004 256 .004 114.613 114.617 0 256 0c141.387 0 256 114.613 256 256zm0 0"
            fill="#3fb777"
        />
        <path
            d="M489.023 330.102v32.046c-25.222 55.286-69.539 100.02-124.511 125.786a254.02 254.02 0 01-30.887 12.07 251.442 251.442 0 01-36.902 8.766c-.055.007-.118.02-.168.02V330.101c0-38.086 29.328-69.329 66.652-72.336a69.613 69.613 0 015.934-.243h47.273c5.59 0 11.035.618 16.258 1.82 32.266 7.376 56.351 36.266 56.351 70.759zm0 0"
            fill="#bfd5de"
        />
        <path
            d="M489.023 330.102v32.046c-25.222 55.286-69.539 100.02-124.511 125.786a254.02 254.02 0 01-30.887 12.07V330.102c0-40.082 32.508-72.579 72.602-72.579h10.195c40.105 0 72.601 32.497 72.601 72.579zm0 0"
            fill="#dde8ed"
        />
        <path
            d="M321.738 177.465c0 11.988-8.761 21.93-20.23 23.777-1.25.203-2.543.309-3.856.309-13.308 0-24.086-10.781-24.086-24.086 0-13.305 10.778-24.086 24.086-24.086 1.313 0 2.606.105 3.856.309 11.469 1.847 20.23 11.789 20.23 23.777zm0 0"
            fill="#d3a489"
        />
        <path d="M412.273 283.71l-19.488 31.255-19.484-31.254" fill="#f74f4f" />
        <path
            d="M419.004 403.262l-26.215 33.09-26.227-33.09 26.231-96.43zm0 0"
            fill="#f74f4f"
        />
        <path
            d="M321.738 177.465c0 11.988-8.761 21.93-20.23 23.777a89.612 89.612 0 01-3.196-23.777c0-8.227 1.114-16.2 3.196-23.778 11.469 1.848 20.23 11.79 20.23 23.778zm0 0"
            fill="#bf8e75"
        />
        <path
            d="M306.262 177.465a8.61 8.61 0 01-17.219 0 8.61 8.61 0 0117.219 0zm0 0"
            fill="#bf8e75"
        />
        <path
            d="M392.781 287.234l-32.683 18.164-9.942-46.242 20.309-11.96M392.781 287.234l32.68 18.164 9.941-46.242-20.304-11.96"
            fill="#bfd5de"
        />
        <path
            d="M463.828 177.465c0 11.988 8.758 21.93 20.227 23.777 1.254.203 2.547.309 3.86.309 13.308 0 24.085-10.781 24.085-24.086 0-13.305-10.777-24.086-24.086-24.086-1.312 0-2.605.105-3.86.309-11.468 1.847-20.226 11.789-20.226 23.777zm0 0"
            fill="#d3a489"
        />
        <g fill="#bf8e75">
            <path d="M463.828 177.465c0 11.988 8.758 21.93 20.227 23.777a89.454 89.454 0 003.199-23.777c0-8.227-1.113-16.2-3.2-23.778-11.468 1.848-20.226 11.79-20.226 23.778zm0 0" />
            <path d="M496.523 177.465a8.61 8.61 0 01-17.218 0 8.61 8.61 0 0117.218 0zm0 0" />
            <path d="M482.398 164.664c0 44.594-32.578 81.59-75.23 88.457a88.597 88.597 0 01-14.383 1.156c-49.496 0-89.617-40.117-89.617-89.613 0-23.418 8.137-58.574 22.84-74.539 16.379-17.777 40.699-15.078 66.777-15.078 4.899 0 9.703.39 14.383 1.16 42.652 6.867 75.23 43.86 75.23 88.457zm0 0" />
            <path d="M418.684 229.555v31.785c0 11.637-7.672 21.472-18.243 24.738a25.844 25.844 0 01-7.66 1.156c-14.304 0-25.898-11.59-25.898-25.894v-31.785zm0 0" />
        </g>
        <path
            d="M418.684 259.328v2.012c0 11.637-7.672 21.473-18.243 24.742-9.535-2.934-16.703-11.223-18.027-21.367a88.56 88.56 0 0019.602-1 88.107 88.107 0 0016.668-4.387zm0 0M482.398 164.664c0 44.594-32.578 81.59-75.23 88.457-42.656-6.867-75.234-43.863-75.234-88.457 0-27.46 12.351-52.039 31.812-68.48 12.129-10.258 27.035-17.34 43.422-19.98 39.766 6.401 70.781 39 74.789 79.523.293 2.941.441 5.925.441 8.937zm0 0"
            fill="#d3a489"
        />
        <path
            d="M392.785 208.8c-7.504 0-15.008-1.882-21.848-5.64a3.986 3.986 0 113.84-6.988c11.278 6.2 24.739 6.2 36.016 0a3.99 3.99 0 015.414 1.574 3.989 3.989 0 01-1.574 5.414c-6.84 3.758-14.344 5.64-21.848 5.64zm0 0"
            fill="#bf8e75"
        />
        <path
            d="M373.7 159.445c0 5.618-4.552 10.168-10.165 10.168a10.166 10.166 0 01-10.168-10.168c0-5.613 4.551-10.164 10.168-10.164 5.613 0 10.164 4.551 10.164 10.164zm0 0M432.2 159.445c0 5.618-4.552 10.168-10.165 10.168-5.613 0-10.168-4.55-10.168-10.168 0-5.613 4.555-10.164 10.168-10.164 5.613 0 10.164 4.551 10.164 10.164zm0 0"
            fill="#3d4159"
        />
        <path
            d="M371.629 157.652a4.358 4.358 0 01-8.715 0 4.358 4.358 0 018.715 0zm0 0M430.629 157.652a4.358 4.358 0 01-8.715 0 4.358 4.358 0 018.715 0zm0 0"
            fill="#fff"
        />
        <path
            d="M402.95 185.043h-20.333c-2.422 0-4.383-1.965-4.383-4.387s1.961-4.383 4.383-4.383h20.332a4.384 4.384 0 110 8.77zm0 0"
            fill="#bf8e75"
        />
        <path d="M422.29 327.723h39.065v11.96H422.29zm0 0" fill="#bfd5de" />
        <path
            d="M387.54 49.445c27.147 1.942 48.944 23.871 50.323 51.477l.258 5.183h15.617l8.41 55.93 20.372-6.305-2.735-54.808c-1.445-28.996-25.43-51.723-54.453-51.617zm0 0"
            fill="#2a2f4f"
        />
        <path
            d="M478.059 27.816c21.617 21.618 21.617 56.668 0 78.286-21.618 21.617-56.668 21.617-78.286 0zm0 0"
            fill="#3d4159"
        />
        <path
            d="M399.777 106.102h-67.941l-8.414 55.933-20.371-6.305 2.734-54.808c1.442-28.992 25.426-51.727 54.453-51.617l98.121.37 12.973 23.348"
            fill="#3d4159"
        />
        <path
            d="M398.027 49.445c-27.144 1.942-48.941 23.871-50.324 51.477l-.258 5.183h-15.613l-8.414 55.93-20.371-6.305 2.738-54.808c1.442-28.996 25.426-51.723 54.45-51.617zm0 0"
            fill="#2a2f4f"
        />
        <path
            d="M201.293 332.246v173.89c-80.781-17.585-147.32-73.3-179.816-147.35v-26.54c0-31.379 21.304-57.785 50.246-65.516a53.468 53.468 0 013.21-.785 66.114 66.114 0 018.817-1.293c1.828-.148 3.68-.23 5.55-.23h44.169c4.941 0 9.746.52 14.386 1.523.274.055.532.118.793.18.786.176 1.559.363 2.332.574a.384.384 0 00.063.02 67.335 67.335 0 0121.055 9.77c17.648 12.245 29.195 32.652 29.195 55.757zm0 0"
            fill="#3d4159"
        />
        <path
            d="M151.207 265.95l-.164.769-21.637 100.601c-1.117.098-2.242.184-3.367.262-1.582.102-3.176.2-4.777.27-3.274.156-6.563.234-9.867.234-3.395 0-6.762-.078-10.114-.242-1.515-.07-3.031-.156-4.547-.27a237.36 237.36 0 01-3.382-.25L71.727 266.727l-.165-.778zm0 0"
            fill="#66baed"
        />
        <path
            d="M129.594 288.895l-18.207 29.203-18.207-29.203"
            fill="#dde8ed"
        />
        <path
            d="M126.04 367.582c-1.583.102-11.34 51.703-14.65 51.703-3.394 0-13.14-51.598-14.656-51.71l14.657-57.079zm0 0"
            fill="#dde8ed"
        />
        <path
            d="M111.379 292.188l-30.531 16.968-9.29-43.203 18.973-11.176M111.379 292.188l30.535 16.968 9.29-43.203-18.974-11.176"
            fill="#2ba1cf"
        />
        <path
            d="M152.36 324.25h9.683L120.488 431.8l-8.18 21.18-9.07-22.535 8.348-28.664L150.98 266.7l.23-.754 20.888 6.825zm0 0"
            fill="#2a2f4f"
        />
        <path
            d="M120.488 431.8l-8.18 21.18-9.07-22.535L60.492 324.25h9.696L50.44 272.77l20.895-6.825.223.754 40.027 135.082zm0 0"
            fill="#2a2f4f"
        />
        <path
            d="M135.582 238.297v29.7c0 10.87-7.168 20.058-17.043 23.108a24.063 24.063 0 01-7.156 1.082c-13.363 0-24.195-10.828-24.195-24.195v-29.695zm0 0"
            fill="#eab599"
        />
        <path
            d="M135.582 266.113v1.883c0 10.871-7.168 20.059-17.043 23.11-8.906-2.739-15.605-10.485-16.844-19.961a82.772 82.772 0 0018.313-.934 82.11 82.11 0 0015.574-4.098zm0 0M45.004 189.629c0 11.203-8.18 20.492-18.895 22.215a22.38 22.38 0 01-3.605.289C10.07 212.133 0 202.063 0 189.629c0-12.43 10.07-22.504 22.504-22.504 1.226 0 2.433.102 3.605.29 10.715 1.726 18.895 11.01 18.895 22.214zm0 0"
            fill="#f6caae"
        />
        <path
            d="M45.004 189.629c0 11.203-8.18 20.492-18.895 22.215-1.949-7.078-2.988-14.528-2.988-22.215s1.04-15.137 2.988-22.215c10.715 1.727 18.895 11.012 18.895 22.215zm0 0"
            fill="#eab599"
        />
        <path
            d="M30.547 189.629a8.044 8.044 0 11-16.088-.002 8.044 8.044 0 0116.088.002zm0 0"
            fill="#eab599"
        />
        <path
            d="M177.762 189.629c0 11.203 8.183 20.492 18.898 22.215 1.172.191 2.375.289 3.606.289 12.43 0 22.5-10.07 22.5-22.504 0-12.43-10.07-22.504-22.5-22.504-1.23 0-2.434.102-3.606.29-10.715 1.726-18.898 11.01-18.898 22.214zm0 0"
            fill="#f6caae"
        />
        <path
            d="M177.762 189.629c0 11.203 8.183 20.492 18.898 22.215 1.945-7.078 2.985-14.528 2.985-22.215s-1.04-15.137-2.985-22.215c-10.715 1.727-18.898 11.012-18.898 22.215zm0 0"
            fill="#eab599"
        />
        <path
            d="M195.11 145.79v31.882c0 41.664-30.438 76.226-70.29 82.64-.132.024-.277.043-.41.067a82.238 82.238 0 01-13.027 1.016c-46.238 0-83.723-37.485-83.723-83.723v-31.883c0-9.738 1.66-19.094 4.73-27.785 11.45-32.582 42.497-55.945 78.993-55.945h.004c4.433 0 8.793.343 13.039 1.011 40.047 6.262 70.683 40.914 70.683 82.72zm0 0"
            fill="#eab599"
        />
        <path
            d="M208.305 189.629a8.041 8.041 0 11-16.082 0 8.041 8.041 0 1116.082 0zm0 0"
            fill="#eab599"
        />
        <path
            d="M195.11 145.79v31.882c0 41.664-30.438 76.226-70.29 82.64-.132.024-.277.043-.41.067-40.047-6.258-70.676-40.91-70.676-82.707v-31.883c0-41.805 30.637-76.457 70.692-82.719 40.047 6.262 70.683 40.914 70.683 82.72zm0 0"
            fill="#f6caae"
        />
        <path
            d="M93.555 172.797c0 5.242-4.254 9.496-9.5 9.496-5.243 0-9.496-4.254-9.496-9.496 0-5.246 4.254-9.5 9.496-9.5 5.246 0 9.5 4.254 9.5 9.5zm0 0M148.21 172.797c0 5.242-4.25 9.496-9.495 9.496s-9.5-4.254-9.5-9.496c0-5.246 4.254-9.5 9.5-9.5s9.496 4.254 9.496 9.5zm0 0"
            fill="#3d4159"
        />
        <path
            d="M91.617 171.121a4.067 4.067 0 01-4.07 4.066 4.07 4.07 0 114.07-4.066zm0 0M146.742 171.121c0 2.246-1.824 4.066-4.07 4.066a4.07 4.07 0 114.07-4.066zm0 0"
            fill="#fff"
        />
        <path
            d="M120.883 192.984h-18.996a4.097 4.097 0 110-8.195h18.996c2.262 0 4.097 1.836 4.097 4.098s-1.835 4.097-4.097 4.097zm0 0M111.383 218.906c-7.008 0-14.02-1.758-20.41-5.27a3.726 3.726 0 01-1.47-5.058 3.722 3.722 0 015.06-1.469c10.535 5.79 23.113 5.79 33.648 0a3.717 3.717 0 015.055 1.47 3.723 3.723 0 01-1.47 5.058c-6.39 3.511-13.401 5.27-20.413 5.27zm0 0"
            fill="#eab599"
        />
        <path
            d="M195.11 116.855v32.66h-15.583v-25.577L67.043 103.035l-23.8-4.426v50.907H27.66v-32.66c0-37.231 30.176-67.407 67.399-67.407h32.648c.887 0 1.773.012 2.66.059 35.988 1.383 64.742 31.004 64.742 67.347zm0 0"
            fill="#e8c934"
        />
        <path
            d="M195.11 116.855v32.66h-15.583v-25.577L67.043 103.035c6.2-29.765 32.023-52.32 63.324-53.527 35.988 1.383 64.742 31.004 64.742 67.347zm0 0"
            fill="#ffe152"
        />
        <path
            d="M364.66 470.172v17.687c-32.976 15.485-69.808 24.137-108.66 24.137-18.773 0-37.082-2.016-54.707-5.86a254.602 254.602 0 01-57.48-19.969v-15.995c0-38.535 26.164-70.961 61.71-80.469a80.02 80.02 0 013.93-.95 83.731 83.731 0 0110.844-1.6 81.858 81.858 0 016.812-.27h54.243c6.058 0 11.972.637 17.668 1.87.324.063.66.134.972.208a62.6 62.6 0 012.863.723c.032 0 .051 0 .07.007A82.688 82.688 0 01328.79 401.7c21.68 15.035 35.871 40.102 35.871 68.473zm0 0"
            fill="#e8c934"
        />
        <path
            d="M364.66 470.172v17.687c-32.976 15.485-69.808 24.137-108.66 24.137-18.773 0-37.082-2.016-54.707-5.86a253.906 253.906 0 01-20.91-5.495v-30.47c0-38.534 26.164-70.96 61.71-80.468a80.02 80.02 0 013.93-.95 83.731 83.731 0 0110.844-1.6c2.25-.188 4.516-.27 6.813-.27h17.672c6.058 0 11.972.637 17.668 1.87.324.063.66.134.972.208a62.6 62.6 0 012.863.723c.032 0 .051 0 .07.007A82.688 82.688 0 01328.79 401.7c21.68 15.035 35.871 40.102 35.871 68.473zm0 0"
            fill="#ffe152"
        />
        <path
            d="M303.137 388.754l-.211.937-26.11 121.47c-6.867.554-13.804.835-20.816.835-8.23 0-16.395-.387-24.438-1.16l-26.039-121.133-.21-.95zm0 0"
            fill="#f2f0ec"
        />
        <path
            d="M254.223 420.977l-37.496 20.84-11.41-53.063 23.3-13.723M254.223 420.977l37.5 20.84 11.406-53.063-23.297-13.723"
            fill="#d8d5d0"
        />
        <path
            d="M142.266 366.797V241.18c0-12.766 2.129-25.27 6.328-37.168 15.722-44.739 58.176-74.809 105.636-74.809 5.88 0 11.747.457 17.446 1.36 26.078 4.074 50 17.382 67.347 37.476a111.958 111.958 0 0127.184 73.14v125.618"
            fill="#f74f4f"
        />
        <path
            d="M188.324 246.086c0 44.434 26.016 95.836 40.305 120.71h-86.367V241.18c0-12.762 2.129-25.266 6.328-37.168 15.625-44.48 57.691-74.461 104.824-74.797-34.426.86-65.09 56.086-65.09 116.87zm0 0"
            fill="#dd3033"
        />
        <path
            d="M283.953 354.793v36.469c0 13.351-8.805 24.636-20.93 28.386a29.723 29.723 0 01-8.793 1.329c-16.41 0-29.714-13.305-29.714-29.715v-36.469zm0 0"
            fill="#eab599"
        />
        <path
            d="M283.953 388.953v2.309c0 13.351-8.805 24.636-20.93 28.386-10.941-3.363-19.168-12.875-20.687-24.515 1.984.12 3.98.18 5.988.18 5.621 0 11.133-.446 16.504-1.325a101.319 101.319 0 0019.125-5.035zm0 0M172.715 295.02c0 13.757-10.05 25.167-23.207 27.285-1.442.23-2.918.351-4.43.351-15.266 0-27.637-12.367-27.637-27.636 0-15.266 12.371-27.633 27.637-27.633 1.512 0 2.988.12 4.43.351 13.156 2.117 23.207 13.524 23.207 27.282zm0 0"
            fill="#f6caae"
        />
        <path
            d="M172.715 295.02c0 13.757-10.05 25.167-23.207 27.285a102.766 102.766 0 01-3.668-27.285c0-9.442 1.277-18.586 3.668-27.282 13.156 2.117 23.207 13.524 23.207 27.282zm0 0"
            fill="#eab599"
        />
        <path
            d="M154.957 295.02a9.878 9.878 0 01-9.879 9.878c-5.453 0-9.875-4.421-9.875-9.878 0-5.454 4.422-9.875 9.875-9.875 5.457 0 9.879 4.421 9.879 9.875zm0 0"
            fill="#eab599"
        />
        <path
            d="M325.195 259.734v94.996c-14.683 14.016-33.511 23.723-54.46 27.098l-.505.082a101.28 101.28 0 01-16 1.246c-56.785 0-102.82-46.035-102.82-102.82V241.18c0-5.012.36-9.946 1.055-14.766 24.46 17.594 59.539 15.856 81.887-5.27 7.402-6.988 12.543-15.312 15.453-24.14 1.617 15.46 8.691 30.508 21.222 42.355 14.922 14.09 34.637 20.887 54.168 20.375zm0 0"
            fill="#eab599"
        />
        <path
            d="M325.195 259.734v94.996c-14.683 14.016-33.511 23.723-54.46 27.098l-.505.082c-49.18-7.683-86.796-50.242-86.796-101.574V241.18c0-1.043.015-2.075.054-3.11 18.133 1.856 36.957-3.777 50.864-16.925 7.402-6.989 12.543-15.313 15.453-24.141 1.62 15.46 8.691 30.508 21.222 42.355 14.922 14.09 34.637 20.887 54.168 20.375zm0 0"
            fill="#f6caae"
        />
        <path
            d="M309.453 271.652c0-9.433-7.644-17.078-17.074-17.078-9.434 0-17.078 7.645-17.078 17.078M233.18 271.652c0-9.433-7.645-17.078-17.078-17.078-9.43 0-17.075 7.645-17.075 17.078"
            fill="#eab599"
        />
        <path
            d="M227.766 278.922c0 6.441-5.223 11.664-11.664 11.664-6.442 0-11.665-5.223-11.665-11.664s5.223-11.664 11.665-11.664c6.441 0 11.664 5.222 11.664 11.664zm0 0M304.043 278.922c0 6.441-5.223 11.664-11.664 11.664-6.445 0-11.664-5.223-11.664-11.664s5.219-11.664 11.664-11.664c6.441 0 11.664 5.222 11.664 11.664zm0 0"
            fill="#3d4159"
        />
        <path
            d="M265.902 308.29h-23.328a5.03 5.03 0 110-10.063h23.328a5.03 5.03 0 015.032 5.03 5.028 5.028 0 01-5.032 5.032zm0 0M254.238 340.125c-8.61 0-17.218-2.16-25.066-6.473a4.57 4.57 0 01-1.805-6.21 4.57 4.57 0 016.211-1.805c12.938 7.113 28.387 7.113 41.324 0 2.211-1.219 4.993-.41 6.211 1.804s.41 4.993-1.804 6.211c-7.848 4.317-16.457 6.473-25.07 6.473zm0 0"
            fill="#eab599"
        />
        <path
            d="M225.387 276.863c0 2.762-2.235 4.996-4.996 4.996s-4.996-2.234-4.996-4.996 2.234-4.996 4.996-4.996 4.996 2.235 4.996 4.996zm0 0M302.234 276.863c0 2.762-2.234 4.996-4.996 4.996s-4.996-2.234-4.996-4.996 2.235-4.996 4.996-4.996 4.996 2.235 4.996 4.996zm0 0"
            fill="#fff"
        />
        <path
            d="M324.223 307.332c0 7.074-5.735 12.809-12.809 12.809-7.074 0-12.809-5.735-12.809-12.809s5.735-12.809 12.81-12.809c7.073 0 12.808 5.735 12.808 12.809zm0 0M209.867 307.332c0 7.074-5.734 12.809-12.808 12.809-7.075 0-12.809-5.735-12.809-12.809s5.734-12.809 12.809-12.809c7.074 0 12.808 5.735 12.808 12.809zm0 0"
            fill="#eab599"
        />
    </svg>
);

export default SvgTeamwork;
