/* eslint-disable */
import afetch from '../AuthenticatedFetch';
import AppConfig from '../../components/config/Config';
import { PendingEventSet } from '../events/ResponseTypes';
import { NudgeListResponse, OutstandingNotifications, UserNotification } from './ResponseTypes';

// api urls
const apiUrls = {
    nudgeContact(id: string) {
        return `${AppConfig.Settings.api.baseUri}/publicapi/notification/nudge/contact/${id}`;
    },
    nudgeList(id: number) {
        return `${AppConfig.Settings.api.baseUri}/publicapi/notification/nudge/list/${id}`;
    },
    outstanding() {
        return `${AppConfig.Settings.api.baseUri}/publicapi/notification/outstanding/detailed`;
    },
    getAssignmentConfirmations() {
        return `${AppConfig.Settings.api.baseUri}/publicapi/notification/outstanding/assignmentconfirmations`;
    },
    getNotifications(since: string) {
        return `${AppConfig.Settings.api.externalUri}/notification?since=${since}`;
    },
    getNotificationsSinceId(since: number) {
        return `${AppConfig.Settings.api.externalUri}/notification/since/${since}`;
    }
};

// api functions
const apiCalls = {
    nudgeContact: (contactId: string) => {
        return afetch.request<null>(
            apiUrls.nudgeContact(contactId),
            'GET',
            null,
            'Unable to nudge contact'
        );
    },
    nudgeList: (listId: number) => {
        return afetch.request<null, NudgeListResponse>(
            apiUrls.nudgeList(listId),
            'GET',
            null,
            'Unable to nudge list'
        );
    },
    getOutstanding: () => {
        return afetch.request<null, OutstandingNotifications>(
            apiUrls.outstanding(),
            'GET',
            null,
            'Unable to get outstanding notifications'
        );
    },
    getAssignmentConfirmations: () => {
        return afetch.request<null, PendingEventSet[]>(
            apiUrls.getAssignmentConfirmations(),
            'GET',
            null,
            'Unable to get assignment confirmations'
        );
    },
    getNotifications: (since: string) => {
        return afetch.request<null, UserNotification[]>(
            apiUrls.getNotifications(since),
            'GET',
            null,
            'Unable to get notifications'
        );
    },
    getNotificationsSinceId: (since: number) => {
        return afetch.request<null, UserNotification[]>(
            apiUrls.getNotificationsSinceId(since),
            'GET',
            null,
            'Unable to get notifications'
        );
    }   
};

export default apiCalls;
