import React from 'react';
import { ReactNode } from 'react';

import theme from '../../css/theme';

interface Props {
    body: string | ReactNode;
    type: string;
    onClick?: () => void;
    style?: React.CSSProperties;
    buttonText?: string;
}

interface State {

}

export default class Dialog extends React.Component<Props, State> {

    render() {
        return (
            <div
                className="help-dialog"
                style={{
                    margin: '8px 0', 
                    backgroundColor: (this.props.type === "info") ? theme.colours.updatedge2 : (this.props.type === "error") ? '#DD2E1B' : (this.props.type === "warning") ? '#FF8E00' : (this.props.type === "warning-2") ? theme.colours.amber : '#333',
                    cursor: this.props.onClick ? 'cursor' : 'initial',
                    ...this.props.style
                }}
                onClick={() => {
                    if (this.props.onClick) {
                        this.props.onClick();
                    }
                }}
            >
                { (this.props.type === "action" || this.props.type === "warning-2") && <i className="fas fa-hand-pointer" />  } 
                { (this.props.type === "info") && <i className="fas fa-info-circle" /> } 
                { (this.props.type === "error") && <i className="fas fa-exclamation-circle" /> } 
                {this.props.body}
                { (this.props.buttonText) &&
                    <button
                        style={{
                            display: "block",
                            marginLeft: 12,
                            color: "#333",
                            border: "none",
                            padding: "6px 12px",
                            borderRadius: 8
                        }}
                    >{this.props.buttonText}</button>
                }
            </div>
        );
    }
}
