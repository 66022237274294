/* eslint-disable */
import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { connect, ConnectedProps } from 'react-redux';

import { ApplicationState } from '../../store';
import * as ProfileStore from '../../store/Profile';
import { Feature } from '../../../src/constants';

import { Organisation } from '../../pages/Organisation';

import history from '../../history';

import SvgMap from '../svg/map';
import SvgRota from '../svg/rota';
import SvgTeams from '../svg/teams';

import '../../css/PremiumBanner.css'
import SubscriptionsApi from 'src/api/subscriptions/Subscriptions';

interface Props {
    // onNavigate?: () => void;
    type: Feature;
    style?: React.CSSProperties;
    setTab?: Organisation['setTab'];
    size?: string;
    defaultLocation?: string;
    prominent?: boolean;
    bodyTextOverride?: string;
    hideButton? : boolean;
}

interface State {
    hideButton: boolean;
}

class PremiumBanner extends React.Component<
    Props & ConnectedProps<typeof connector>,
    State
> {
    state = {
        hideButton: false
    };

    public render() {
        const user = this.props.authenticatedUserProfile 

        if (this.props.type === Feature.Locations) {
            return (
                <div className={"premium-banner" + (this.props.prominent ? " guide-instruction" : "")} style={this.props.style || {}} data-size={this.props.size || "big"} onClick={() => {
                    history.push("/organisation/subscriptions/locations");
                    if (window.location.pathname.startsWith("/organisation") && this.props.setTab) {
                        this.props.setTab("subscriptions")
                    }
                }}>
                    <div>
                        <div className="icon">
                            <SvgMap />
                        </div>
                        <div className="side">
                            <p>You can select alternative locations for different offers{this.props.defaultLocation && ' instead of just your primary "' + this.props.defaultLocation + '" location'}. Try free for 14 days</p>
                            <button id="activate-free-trial-btn" className="styled filled" onClick={() => { 

                            }}>Activate Free Trial</button>
                        </div>
                    </div>
                </div>
            );
        }

        if (this.props.type === Feature.Rota) {
            return (
                <div className={"premium-banner" + (this.props.prominent ? " guide-instruction" : "")} style={this.props.style || {}} data-size={this.props.size || "big"}>
                    <div>
                        <div className="icon">
                            <SvgRota />
                        </div>
                        <div className="side">
                            <p>{this.props.bodyTextOverride || "To see your past and future weeks please activate your free 14 day trial"}</p>
                            { (!this.props.hideButton || this.state.hideButton) &&
                                <button id="activate-free-trial-btn" className="styled filled" onClick={() => {
                                    this.setState({
                                        hideButton: true
                                    })
                                    SubscriptionsApi.createCheckoutSession({
                                        items: [
                                            {
                                                priceId: window.location.href.indexOf("portal.updatedge.com") !== -1 ? 3 : 9,
                                                unitsPurchased: 1
                                            }
                                        ]
                                    }).then((sessionId) => {
                                        if ((window as any).dispatchRefreshUserProfile) {
                                            (window as any).onUserGetRedirectTo = "/schedule";
                                            (window as any).dispatchRefreshUserProfile();
                                        }
                                    })
                                }}>Activate Free Trial</button>
                            }
                        </div>
                    </div>
                </div>
            );
        }

        if (this.props.type === Feature.Teams && user && user.subscriptionNotifications && user.subscriptionNotifications.subscriptions.filter(item => item.featureName === "Teams").length === 0 ) {
            return (
                <div className={"premium-banner" + (this.props.prominent ? " guide-instruction" : "")} style={this.props.style || {}} data-size={this.props.size || "big"}>
                    <div>
                        <div className="icon" style={{ padding: 6 }}>
                            <SvgTeams />
                        </div>
                        <div className="side">
                            <p>Create more teams and add more than 3 colleagues with premium.</p>
                            <button id="activate-free-trial-btn" className="styled filled" onClick={() => {
                                history.push("/organisation/subscriptions/teams");
                                if (window.location.pathname.startsWith("/organisation") && this.props.setTab) {
                                    this.props.setTab("subscriptions")
                                }
                            }}>Activate Free Trial</button>
                        </div>
                    </div>
                </div>
            );
        }

        return null;

    }   
}

const connector = connect(
    (state: ApplicationState) => state.profile,
    ProfileStore.actionCreators
);

export default connector(PremiumBanner);