/* eslint-disable */
import React from 'react';
import styled from 'styled-components';
import { Table, Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { toast } from 'react-toastify';

import AdminApi from '../../api/admin/Admin';
import CompanyApi from '../../api/company/Company';
import { UserVerificationPendingResult } from '../../api/verification/ResponseTypes';
import { AsyncOverlay } from '../../components/ui-components/AsyncOverlay';
import { CompanyRoles } from '../../api/company/ResponseTypes';
import { ProfileImage } from '../../components/ui-components/ProfileImage';
import ProfileApi from '../../api/profile/Profile';
import OrganisationApi from '../../api/organisation/Organisation';
import { UserType } from '../../state-containers/user-type.container';
import theme from '../../css/theme';
import { ReportWithUserDetails } from 'src/api/report/ResponseTypes';

interface State {
    loading: boolean;
    results: ReportWithUserDetails[];
    highlighted: number | null;
}

export default class Reports extends React.Component<{}, State> {
    state = {
        loading: true,
        results: [],
        highlighted: null
    } as State;

    reload() {
        this.setState({
            loading: true
        }, () => { 
            AdminApi.getReports().then((results) => {
                this.setState({
                    results: results,
                    loading: false
                }, () => {
                    if (window.location.href.includes('?id=')) {
                        let id = window.location.href.split('?id=')[1];
                        this.setState({
                            highlighted: parseInt(id)
                        })
                        const el = document.querySelector(`.report-row[data-id="${id}"]`);
            
                        if (el) {
                            el.scrollIntoView();
                            setTimeout(() => {
                                this.setState({
                                    highlighted: null
                                })
                            }, 3000)
                        }   
                    }
                });
            });
        })

    }

    componentDidMount() {
        this.reload();
    }

    render() {
        return (
            <Wrapper>
                <h3>Reports</h3>
                <div style={{
                    marginBottom: 20
                }}>
                    <button onClick={() => { this.componentDidMount(); }}>Reload Data</button>
                </div>
                <Table condensed striped responsive bordered>
                    <thead>
                        <tr>
                            <th style={{ width: 85 }}>Reporter</th>
                            <th style={{ width: 85 }}>Reported</th>
                            <th style={{ width: 'calc(100% - 200px - 85px - 85px)'  }}>Reason</th>
                            <th style={{ width: 120 }}>Options</th>
                        </tr>
                    </thead>
                    {this.renderResults(this.state.results)}
                </Table>
                <AsyncOverlay show={this.state.loading} />
            </Wrapper>
        );
    }

    setOutcome(id: number, outcome: number) {
        this.setState({ loading: true })
        AdminApi
            .setReportOutcome(id, outcome)
            .then(data => {
                this.setState({
                    loading: false,
                })
                if (outcome !== 3) {
                    this.setState({
                        results: this.state.results.filter(r => r.id !== id)
                    })
                } else {
                    this.setState({
                        results: this.state.results.map(r => {
                            if (r.id === id) {
                                r.reportedUserReported = true;
                            }
                            return r;
                        })
                    })
                }
                toast.success("Outcome set")
            }).catch(e => {
                this.setState({ loading: false })
                toast.error("Failed to set outcome");
            })
    }

    renderResults(results) {
        if (results) {
            return (
                <tbody>
                    { results.map((item, i) => {
                        return (
                            <tr
                                className='report-row' data-id={item.id}
                                style={{
                                    background: this.state.highlighted === item.id ? '#ffea52' : ''
                                }}
                            >
                                <td style={{ width: 85, textAlign: 'center'}}> 
                                    { (item.reporterUserId) &&
                                        <img src={ProfileApi.getProfileImageUrl(item.reporterUserId)} style={{ width: 30, height: 30, borderRadius: 15, display: 'block', margin: 'auto' }} />
                                    }
                                    <span>{item.reporterUserFirstName} {item.reporterUserLastName}</span>
                                </td>
                                <td style={{ width: 85, textAlign: 'center' }}>
                                    { (item.reportedUserId) &&
                                        <img src={ProfileApi.getProfileImageUrl(item.reportedUserId)} style={{ width: 30, height: 30, borderRadius: 15, display: 'block', margin: 'auto' }} />
                                    }
                                    <span>{item.reportedUserFirstName} {item.reportedUserLastName}</span>
                                </td>
                                <td className='reason' style={{ width: 'calc(100% - 200px - 85px - 85px)'  }}>
                                    <span>[{item.reportEntityName}] {item.reportReasonName}</span>
                                    <span>{item.notes}</span>
                                    { (item.reportedUserReported) &&
                                        <label style={{ background: theme.colours.amber, padding: "2px 4px", marginTop: 4 }}>Blurred and Muted</label>
                                    }
                                </td>
                                <td
                                    className="options"
                                    style={{ width: 200 }}
                                >
                                    <button 
                                        className="option-btn"
                                        onClick={() => {
                                            window.open(`/contact/${item.reportedContactId}`)
                                        }}
                                        style={{ background: theme.colours.updatedge2 }}
                                    >
                                        Open
                                    </button>
                                    { (!item.reportedUserReported) &&
                                        <button
                                            className="option-btn"
                                            onClick={() => {
                                                this.setOutcome(item.id, 1)
                                            }}
                                            style={{ background: theme.colours.green2 }}
                                        >
                                            No Issue
                                        </button>
                                    }
                                    { (!item.reportedUserReported) &&
                                        <button
                                            className="option-btn"
                                            onClick={() => {
                                                this.setOutcome(item.id, 3)
                                            }}
                                            style={{ background: theme.colours.amber }}
                                        >
                                            Mute & Blur
                                        </button>
                                    }
                                    <button
                                        className="option-btn"
                                        onClick={() => {
                                            this.setOutcome(item.id, 2)
                                        }}
                                        style={{ background: theme.colours.green }}
                                    >
                                        Fixed Issue
                                    </button>
                                    <button
                                        className="option-btn"
                                        onClick={() => {
                                            
                                        }}
                                        style={{ background: theme.colours.red2 }}
                                    >
                                        Delete User (TODO)
                                    </button>
                                </td>
                            </tr>
                        )
                    }) }
                </tbody>
            )
        }
        return null;
    }

}

const Wrapper = styled.div`

    .reason {

        span {
            display: block;

            &:first-child {
                font-weight: 700;
                margin-bottom: 4px;
            }
        }
    }   

    .options {

        button {
            border: none;
            margin: 4px;
            color: white;
        }
    }
`;
