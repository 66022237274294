/* eslint-disable */
import React from 'react';
import { RouteComponentProps } from 'react-router';
import SvgConfirmed from '../components/svg/Confirmed';
import FullScreenLoader from '../components/ui-components/FullScreenLoader';
import OfferApi from '../api/offers/Offers';
import styled from 'styled-components';

interface State {
    loading: boolean,
    success: boolean
}

export class AgencyResponseConfirmed extends React.Component<
    RouteComponentProps<{}>,
    State
> {

    state: State = {
        loading: true,
        success: false
    }

    async componentDidMount() {

        // @ts-ignore
        let res = await OfferApi.respondToHelpRequest(this.props.match.params.token, this.props.match.params.response).catch(e => {
            console.log(e);
        });
        
        if (res) {
            this.setState({ success: true })
        }

        this.setState({ loading: false })

    }

    public render() {

        if (this.state.loading) {
            return (
                <FullScreenLoader loadingMessage="Loading" />
            )
        }

        return (
            <Wrapper>
                { (this.state.success) &&
                    <div className="panel panel-default">
                        <div className="panel-body">
                            <div className="text-center">
                                <div style={{
                                    width: 240,
                                    margin: '0 auto 20px auto'
                                }}>
                                    <SvgConfirmed />
                                </div>
                                <h2 style={{ color: 'black' }}>We have got your response</h2>
                                <p style={{ color: 'black' }}>We will now notify your contact about your decision</p>
                            </div>
                        </div>
                    </div>
                }
            </Wrapper>
        );
    }
}

const Wrapper = styled.div`

    .panel.panel-default {
        width: 450px;
        max-width: 100%;
        padding: 20px;
        margin: 0 auto;
    }

    svg {
        width: 100%;
        height: auto;
    }
`;
