/* eslint-disable */
/* eslint-disable @typescript-eslint/ban-ts-ignore */
import React from 'react';
import { connect } from 'react-redux';
import { toast, ToastType } from 'react-toastify';
import { Button, Modal } from 'react-bootstrap';
import styled from 'styled-components';

import OrganisationAPI from '../../api/organisation/Organisation';
import CompanyApi from '../../api/company/Company';
import { AsyncOverlay } from '../ui-components/AsyncOverlay';
import LocationPicker from '../profile/LocationPicker';
import Analytics from '../../services/analytics.service';
import ClearBit from '../../services/clearbit.service';
import { LookupItem } from '../../api/lookups/ResponseTypes';
import IndustrySelector from '../profile/IndustrySelector';
import { OrganisationDTO } from '../../api/organisation/ResponseTypes';
import { ShieldWrapper } from '../profile/UserProfileEditor';
import OrganisationImageSuggestions from './OrganisationImageSuggestions';
import { actionCreators as ProfileActionCreators } from '../../store/Profile';
import { supportedImageFormats } from '../../constants';
import * as Notification from '../../store/Notifications';
import UtilsAPI from '../../api/Utils';
import Utilities from '../../Utilities';
import ProfileVerification from '../profile/ProfileVerification';
import { ProfileImage } from '../ui-components/ProfileImage';
import { Subscribe } from '../../unstated-fork/unstated';
import { ProfileVerificationContainer } from '../../state-containers/profile-verification.container';
import VerifiedTooltip from '../ui-components/VerifiedTooltip';

interface Props {
    organisation: OrganisationDTO;
    updateProfileState: typeof ProfileActionCreators['updateProfileState'];
    updateOrganisationProfileImage: typeof ProfileActionCreators['updateOrganisationProfileImage'];
    updateOrganisation: typeof ProfileActionCreators['updateOrganisation'];
    updateOrganisationLocation: typeof ProfileActionCreators['updateOrganisationLocation'];
    onClose?: Function;
    // handleLocationPicked: (
    //     locationPlaceId: string,
    //     locationPlaceName: string,
    //     locationFullName: string,
    //     locationComponents: google.maps.places.PlaceResult['address_components']
    // ) => any;
}

interface State {
    organisation: OrganisationDTO;
    showLocationPicker: boolean;
    showApiInfo: boolean;
    showImageSuggestions: boolean;
    showVerification: boolean;
    testingApi: boolean;
    organisationSecret: string;
    newCompanyProfileImage: File | null;
    newCompanyProfileImagePreviewUrl: string | null;
    loadingMessage: string | null;
}

class OrganisationProfileEditor extends React.Component<Props, State> {
    state: State = {
        showApiInfo: false,
        testingApi: false,
        showLocationPicker: false,
        showImageSuggestions: false,
        showVerification: false,
        organisation: {
            locationComponents: undefined,
            id: '',
            name: '',
            imageUrl: '',
            locationPlaceId: '',
            locationPlaceName: '',
            locationFullName: '',
            apiBaseUrl: '',
            deleted: false,
            verified: false,
            isHirer: false,
            isAgency: false,
            industries: []
        },
        organisationSecret: '',
        newCompanyProfileImage: null,
        newCompanyProfileImagePreviewUrl: '',
        loadingMessage: null
    };

    componentDidMount() {
        // setTimeout(() => {
        //     debugger
        // }, 6000)
        this.getUpdatedOrganisation();
    }

    copyOrganisationToFormData = () => {
        //console.log('Organisation:', this.props.organisation);
        this.setState({ organisation: { ...this.props.organisation } });
    };

    saveChanges = async () => {

        let organisation = this.state.organisation;

        if (organisation.industries.length === 0) {
            toast.error('Please select at least one sector');
            return;
        }

        if (organisation.industries.find(i => i === undefined || i.subSectors === undefined || i.subSectors.length === 0)) {
            toast.error('Please select at least one subsector for each sector');
            return;
        }

        if (this.state.newCompanyProfileImage) {
            let file: File | null = await Utilities.resizeAndRotateImage(
                this.state.newCompanyProfileImage as Blob
            );

            if (file !== null) {
                this.props.updateOrganisationProfileImage(file, async () => {
                    Utilities.refreshImageCache();
                    this.props.updateOrganisation(
                        // @ts-ignore
                        this.state.organisation,
                        this.getUpdatedOrganisation.bind(this)
                    );
                });
            }
        } else {
            this.props.updateOrganisation(
                // @ts-ignore
                this.state.organisation,
                this.getUpdatedOrganisation.bind(this)
            );
        }

        Analytics.trackEvent('company-profile-saved');
    };

    getUpdatedOrganisation = () => {

        OrganisationAPI.getOrganisation(this.props.organisation.id!).then(
            (org) =>
                this.setState({
                    organisation: org.data
                })
        );

    };

    resetChanges = () => {
        this.copyOrganisationToFormData();
        if (this.props.onClose)
            this.props.onClose();
    };

    handleOrganistionFieldChange = ({
        target: { name, value }
    }: React.ChangeEvent<HTMLInputElement>) => {
        this.setState((prevState) => ({
            ...prevState,
            organisation: {
                ...prevState.organisation,
                [name]: value
            }
        }));
    };

    handleVerificationClick = () => {
        this.setState({
            showVerification: true
        });
    };

    handleVerificationHide = () => {
        this.setState({
            showVerification: false
        });
    };

    private handleImageSuggestClick = (verified: boolean) => {
        if (!verified) {
            this.setState({ showImageSuggestions: true });
        }
    };

    private handleImageSuggestClose = () => {
        this.setState({ showImageSuggestions: false });
    };

    private handleImageSuggestConfirm = async (logoUrl: string | null) => {
        if (!logoUrl) {
            this.handleImageSuggestClose();
            return;
        }
        UtilsAPI.getFileFromUrl(logoUrl, 'filename')
            .then((file) => {
                if (file) {
                    this.handleSelectedFile(file!);
                }
            })
            .then(() => this.handleImageSuggestClose());
    };

    private handleLocationClick = () => {
        this.setState({ showLocationPicker: true });
        Analytics.trackEvent('company-profile-location-opened');
    };

    private handleLocationClose = () => {
        this.setState({ showLocationPicker: false });
        Analytics.trackEvent('company-profile-location-closed');
    };

    private handleLocationChange = (
        placeId: string,
        placeName: string,
        placeComponents: google.maps.places.PlaceResult['address_components']
    ) => {
        let placeFull = '';
        placeComponents.forEach((place, i) => {
            placeFull += place.long_name;
            placeFull += i !== placeComponents.length - 1 ? ', ' : '';
        });
        this.setState((prevState) => ({
            ...prevState,
            organisation: {
                ...prevState.organisation,
                locationPlaceName: placeName,
                locationPlaceId: placeId,
                locationFullName: placeFull
            }
        }));
        this.handleLocationPicked(
            placeId,
            placeName,
            placeFull,
            placeComponents
        );
        Analytics.trackEvent('company-profile-location-changed');
    };

    handleLocationPicked = (
        locationPlaceId: string,
        locationPlaceName: string,
        locationFullName: string,
        locationComponents: google.maps.places.PlaceResult['address_components']
    ) => {
        this.setState((prevState) => ({
            ...prevState,
            organisation: {
                ...prevState.organisation,
                locationPlaceId,
                locationPlaceName,
                locationFullName,
                locationComponents
            }
        }));
    };

    private handleApiInfoClick = () => {
        this.setState({ showApiInfo: true });
        Analytics.trackEvent('company-profile-api-info-opened');
    };

    private handleApiInfoClose = () => {
        this.setState({ showApiInfo: false });
        Analytics.trackEvent('company-profile-api-info-closed');
    };

    private handleApiInfoConfirm = () => {
        this.setState({ testingApi: true });

        OrganisationAPI.updateApiInfo({
            id: this.props.organisation.id,
            apiBaseUrl: this.state.organisation.apiBaseUrl,
            apiSecret: this.state.organisationSecret
        })
            .then(() => {
                this.setState({
                    testingApi: false,
                    organisationSecret: ''
                });
                toast.success('Saved API details');
                this.handleApiInfoClose();
                Analytics.trackEvent('company-profile-api-info-saved');
            })
            .catch(() => {
                this.setState({ testingApi: false });
                Analytics.trackEvent('company-profile-api-info-unreachable');
                this.handleApiInfoClose();
            });
    };

    handleIndustrySelected = (industry: LookupItem) => {
        // industry.subSectors = [];
        this.setState((prevState) => ({
            ...prevState,
            organisation: {
                ...prevState.organisation,
                industries: [...prevState.organisation.industries, industry]
            }
        }));

        Analytics.trackEvent('company-profile-industry-selected', {
            industry: industry.value
        });
    };

    handleIndustryRemoved = (id: number) => {
        this.setState((prevState) => ({
            ...prevState,
            organisation: {
                ...prevState.organisation,
                industries: prevState.organisation.industries.filter(
                    (industry) => id !== industry.id
                )
            }
        }));

        Analytics.trackEvent('company-profile-industry-removed', {
            industry: id
        });
    };

    toggleCheckboxValue = ({
        target: { name }
    }: React.ChangeEvent<HTMLInputElement>) => {
        this.setState((prevState) => ({
            ...prevState,
            organisation: {
                ...prevState.organisation,
                [name]: !prevState.organisation[name]
            }
        }));
    };

    handleSelectedFile(file: File | null) {
        if (file !== null) {
            if (0 > supportedImageFormats.indexOf(file.type)) {
                Notification.actionCreators.display(
                    ToastType.ERROR,
                    'Please select a jpg, png or gif file.'
                );
            } else {
                this.setState((prevState) => {
                    return {
                        ...prevState,
                        newCompanyProfileImage: file!,
                        newCompanyProfileImagePreviewUrl: URL.createObjectURL(
                            file!
                        )
                    };
                });
            }
        }
    }

    handleSelectedFiles(files: FileList | null) {
        if (files !== null) {
            if (0 > supportedImageFormats.indexOf(files[0].type)) {
                Notification.actionCreators.display(
                    ToastType.ERROR,
                    'Please select a jpg, png or gif file.'
                );
            } else {
                this.setState((prevState) => {
                    return {
                        ...prevState,
                        newCompanyProfileImage: files[0]!,
                        newCompanyProfileImagePreviewUrl: URL.createObjectURL(
                            files[0]!
                        )
                    };
                });
            }
        }
    }

    decache(url) {
        return `${url}?v=${Date.now()}`;
    }

    render() {

        return (
            <Wrapper>
                <div className="form-horizontal col-md-12">
                    <div className="form-group">
                        <label
                            className="col-sm-3 control-label"
                            htmlFor="organisation-name"
                        >
                            Organization Name
                        </label>
                        <div className="col-sm-7">
                            <div className="row">
                                <ManagedFieldInput
                                    aria-label="organisationName"
                                    id="organisation-name"
                                    type="text"
                                    name="name"
                                    onChange={
                                        this
                                            .handleOrganistionFieldChange
                                    }
                                    value={this.state.organisation.name}
                                    className="form-control"
                                    disabled={
                                        this.state.organisation.verified
                                    }
                                />
                                {this.state.organisation.verified && (
                                    <VerifiedTooltip
                                        text={
                                            'Your organization has been verified with this name. Please contact us via our Support page if you wish to change your name.'
                                        }
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="col-sm-3 control-label">
                            Organization Logo
                        </label>
                        <div className="col-sm-7">
                            <ProfileImage
                                onSelect={() =>
                                    this.handleImageSuggestClick(
                                        this.state.organisation.verified
                                    )
                                }
                                type="Organisation"
                                url={
                                    this.state
                                        .newCompanyProfileImagePreviewUrl ||
                                    CompanyApi.getOrganisationProfileImageUrl(
                                        this.state.organisation.id
                                    ) ||
                                    ''
                                }
                                size={72}
                                selectable={
                                    !this.state.organisation.verified
                                }
                            />
                            {!this.state.organisation.verified && (
                                <ManagedFieldButton
                                    bsStyle="primary"
                                    onClick={() =>
                                        this.handleImageSuggestClick(
                                            this.state.organisation.verified
                                        )
                                    }
                                >
                                    Change
                                </ManagedFieldButton>
                            )}
                            {this.state.organisation.verified && (
                                <VerifiedTooltip
                                    text={
                                        'Your organization has been verified with this logo. Please contact us via our Support page if you wish to change your logo.'
                                    }
                                />
                            )}
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="col-sm-3 control-label">
                            Location address (delivery of services)
                        </label>
                        <div className="col-sm-7">
                            <ManagedFieldInput
                                onClick={this.handleLocationClick}
                                aria-label="LocationInput"
                                type="text"
                                id="locationFullName"
                                name="locationFullName"
                                readOnly={true}
                                value={
                                    this.state.organisation
                                        .locationFullName ||
                                    this.state.organisation
                                        .locationPlaceName
                                }
                                className="form-control"
                                disabled={this.state.organisation.verified}
                            />

                            {this.state.organisation.verified ? (
                                this.state.organisation.verified && (
                                    <VerifiedTooltip
                                        text={
                                            'Your organization has been verified with this location. Please contact us via our Support page if you wish to change your location.'
                                        }
                                    />
                                )
                            ) : (
                                <ManagedFieldButton
                                    bsStyle="primary"
                                    onClick={this.handleLocationClick}
                                    disabled={
                                        this.state.organisation.verified
                                    }
                                    id="change-location-btn"
                                >
                                    Change
                                </ManagedFieldButton>
                            )}
                            <input
                                aria-label="locationDetails"
                                id="locationDetails"
                                className="form-control"
                                placeholder='Extra Information E.g. Warehouse 3, Door 8, Floor 4'
                                value={this.state.organisation.locationPlaceName}
                                style={{
                                    marginTop: 8
                                }}
                                onChange={({
                                    target: { value }
                                }) => {
                                    this.setState(
                                        ({
                                            organisation
                                        }) => ({
                                            organisation: {
                                                ...organisation,
                                                locationPlaceName: value
                                            }
                                        })
                                    );
                                }}
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="col-sm-3 control-label">
                            API Base URL
                        </label>
                        <div className="col-sm-7">
                            <ManagedFieldInput
                                onClick={this.handleApiInfoClick}
                                aria-label="ApiBaseUrlInput"
                                type="text"
                                name="apiBaseUrl"
                                readOnly={true}
                                value={
                                    this.state.organisation.apiBaseUrl
                                }
                                className="form-control"
                                disabled={this.state.organisation.verified}
                            />
                            {this.state.organisation.verified ? (
                                <VerifiedTooltip
                                    text={
                                        'Your organization has been verified with this API Base URL. Please contact us via our Support page if you wish to change your API Base URL.'
                                    }
                                />
                            ) : (
                                <ManagedFieldButton
                                    bsStyle="primary"
                                    onClick={this.handleApiInfoClick}
                                    disabled={
                                        this.state.organisation.verified
                                    }
                                    aria-label="ChangeApiBaseUrl"
                                >
                                    Change
                                </ManagedFieldButton>
                            )}
                        </div>
                    </div>
                    <div className="form-group">
                        <label
                            className="col-sm-3 control-label"
                            htmlFor="industry-select"
                        >
                            Industries / Sectors
                        </label>
                        <div className="col-sm-6">
                            <IndustrySelector
                                aria-label="IndustrySelector"
                                enabled={true /*!this.state.organisation.verified*/}
                                industries={
                                    this.state.organisation.industries
                                }
                                onSelected={this.handleIndustrySelected}
                                onRemoved={this.handleIndustryRemoved}
                                searchPlacement="auto"
                                enableSuggestion={true /*!this.state.organisation.verified*/}
                            />
                        </div>
                    </div>
                    {this.state.organisation.industries &&
                        this.state.organisation.industries.map(
                            (sector, i) => {
                                if (sector)
                                    if (!sector.subSectors)
                                        sector.subSectors = [];
                                    return (
                                        <div 
                                            className="form-group"
                                            key={sector.id}
                                        >
                                            <label
                                                className="col-sm-3 control-label"
                                                htmlFor="industry-select"
                                            >
                                                
                                            </label>
                                            <div className="col-sm-6">
                                                <label>Subsectors for {sector.name || sector.value}</label>
                                                <IndustrySelector
                                                    enabled={true /* !this.state.organisation.verified */}
                                                    enableSuggestion={true /* !this.state.organisation.verified */}
                                                    sectorId={sector.id}
                                                    sectorName={sector.name || sector.value}
                                                    industries={
                                                        sector.subSectors ? sector.subSectors.map(item => { return { id: item.id, value: item.name || item.value || "", name: item.name || item.value || "" } }) : []
                                                    }
                                                    onSelected={(item) => {
                                                        let industries = this.state.organisation.industries;
                                                        if (industries && industries[i]) {
                                                            if (!industries[i].subSectors) 
                                                                industries[i].subSectors = [];
                                                            // @ts-ignore
                                                            industries[i].subSectors.push({
                                                                name: item.value || item.name || "",
                                                                value: item.value || item.name || "",
                                                                id: item.id
                                                            });
                                                            this.setState(
                                                                (prevState) => ({
                                                                    ...prevState,
                                                                    organisation: {
                                                                        ...prevState.organisation,
                                                                        industries
                                                                    }
                                                                })
                                                            );
                                                        }
                                                    }}
                                                    onRemoved={(id) => {
                                                        let industries = this.state.organisation.industries;
                                                        // @ts-ignore
                                                        industries[i].subSectors = industries[i].subSectors.filter(item => item.id !== id);
                                                        this.setState(
                                                            (prevState) => ({
                                                                ...prevState,
                                                                organisation: {
                                                                    ...prevState.organisation,
                                                                    industries
                                                                }
                                                            })
                                                        );
                                                    }}
                                                    searchPlacement="auto"
                                                />
                                            </div>
                                        </div>
                                    )
                                return null;
                    })}
                    <div className="form-group">
                        <div className="col-sm-7 col-sm-offset-3">
                            <input
                                aria-label="isAgencyCheck"
                                type="checkbox"
                                id="isAgency"
                                name="isAgency"
                                onChange={this.toggleCheckboxValue}
                                checked={
                                    this.state.organisation.isAgency
                                }
                                disabled={this.state.organisation.verified}
                            />
                            <label
                                className="m-l-xs"
                                htmlFor="isAgency"
                            >
                                My organization is an agency
                            </label>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="col-sm-7 col-sm-offset-3">
                            <input
                                aria-label="isHirerCheck"
                                type="checkbox"
                                id="isHirer"
                                name="isHirer"
                                onChange={this.toggleCheckboxValue}
                                checked={
                                    this.state.organisation.isHirer
                                }
                                disabled={this.state.organisation.verified}
                            />
                            <label className="m-l-xs" htmlFor="isHirer">
                                My organization is a hirer
                            </label>
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="col-sm-3 control-label">
                            Verification Status
                        </label>
                        <ShieldWrapper
                            className="col-sm-7 layout horizontal center"
                            style={{ paddingTop: '10px' }}
                        >
                            <i
                                className={
                                    'fa fa-shield ' +
                                    (this.state.organisation.verified
                                        ? 'checked'
                                        : '')
                                }
                            />
                            <span>
                                {this.state.organisation.verified
                                    ? 'Verified'
                                    : 'Unverified'}
                            </span>
                            {!this.state.organisation.verified && (
                                <Button
                                    bsStyle="primary"
                                    bsSize="xs"
                                    className="m-l"
                                    id="verify-now-btn"
                                    onClick={
                                        this.handleVerificationClick
                                    }
                                >
                                    Verify now
                                </Button>
                            )}
                        </ShieldWrapper>
                    </div>
                    {/* {this.state.organisation.verified && ( */}
                        <div className="form-group">
                            <div className="col-sm-8 col-sm-offset-3">
                                <button
                                    aria-label="CancelButton"
                                    className="btn btn-default"
                                    type="submit"
                                    onClick={this.resetChanges}
                                >
                                    Cancel
                                </button>
                                <button
                                    className="btn btn-success m-l-xs"
                                    type="submit"
                                    onClick={() => this.saveChanges()}
                                >
                                    Save Changes
                                </button>
                            </div>
                        </div>
                    {/* )} */}
                </div>
                <Modal
                    show={this.state.showApiInfo}
                    onHide={this.handleApiInfoClose}
                >
                    <Modal.Header style={{ padding: '15px 20px' }}>
                        <Modal.Title>
                            Set API connection info
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <label
                                className="control-label"
                                htmlFor="apiBaseUrl"
                            >
                                API Base URL
                            </label>
                            <input
                                id="apiBaseUrl"
                                type="text"
                                className="form-control"
                                name="apiBaseUrl"
                                value={
                                    this.state.organisation.apiBaseUrl
                                }
                                onChange={
                                    this.handleOrganistionFieldChange
                                }
                            />
                        </div>
                        <div style={{ marginTop: '10px' }}>
                            <label
                                className="control-label"
                                htmlFor="apiSecret"
                            >
                                API Secret
                            </label>
                            <input
                                id="apiSecret"
                                type="text"
                                className="form-control"
                                name="newCompanyApiSecret"
                                placeholder="< secret is hidden >"
                                value={this.state.organisationSecret}
                                onChange={({
                                    target: {
                                        value: organisationSecret
                                    }
                                }) => {
                                    this.setState({
                                        organisationSecret
                                    });
                                }}
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            bsStyle="primary"
                            onClick={this.handleApiInfoConfirm}
                            disabled={this.state.testingApi}
                        >
                            Confirm
                        </Button>
                        <Button
                            onClick={this.handleApiInfoClose}
                            disabled={this.state.testingApi}
                        >
                            Cancel
                        </Button>
                    </Modal.Footer>
                    <AsyncOverlay
                        show={this.state.testingApi}
                        text="Verifying..."
                    />
                </Modal>
                <ProfileVerification
                    show={this.state.showVerification}
                    onClose={this.handleVerificationHide}
                />
                <LocationPicker
                    allowFriendlyName={false}
                    show={this.state.showLocationPicker}
                    onHide={this.handleLocationClose}
                    onChange={this.handleLocationChange}
                    placeId={/*this.state.organisation.locationPlaceId*/ null}
                />
                <OrganisationImageSuggestions
                    organisationName={this.state.organisation.name}
                    show={this.state.showImageSuggestions}
                    onHide={this.handleImageSuggestClose.bind(this)}
                    onConfirm={this.handleImageSuggestConfirm.bind(
                        this
                    )}
                    handleSelectedFiles={this.handleSelectedFiles.bind(
                        this
                    )}
                    currentImageUrl={
                        this.state.newCompanyProfileImagePreviewUrl ||
                        CompanyApi.getOrganisationProfileImageUrl(
                            this.state.organisation.id
                        )
                    }
                />
                {/* { (this.state.organisation.verified) &&
                    <a 
                        target="_blank" 
                        href={"mailto:support@updatedge.com?body=Hi, I would like to make some changes to my account.\nn&subject=Organisation change request [" + this.state.organisation.name + "]"}
                        className='main make-changes-btn'
                        style={{
                            width: '100%',
                            textAlign: 'center'
                        }}
                    >
                        Need to make changes?
                    </a>
                } */}
            </Wrapper>
        );
    }
}

const Wrapper = styled.div`
    margin-top: 30px;
    input[disabled] {
        cursor: default;
    }
    .verified-overlay {
        position: absolute;
        top: 7px;
        right: 25px;
        font-style: italic;
        opacity: 0.7;
    }
`;

const ManagedFieldInput = styled.input`
    display: inline-block;
    width: calc(100% - 90px);
    &[type='file'] {
        display: inline-block;
    }
`;

const ManagedFieldButton = styled(Button)`
    width: 80px;
    margin-top: -5px;
    margin-left: 10px;
`;

export default connect(null, {
    updateProfileState: ProfileActionCreators.updateProfileState,
    updateOrganisationProfileImage:
        ProfileActionCreators.updateOrganisationProfileImage,
    updateOrganisation: ProfileActionCreators.updateOrganisation,
    updateOrganisationLocation: ProfileActionCreators.updateOrganisationLocation
    // @ts-ignore
})((props) => <OrganisationProfileEditor {...props} />) as any;
