/* eslint-disable */
import { SubSector } from 'src/api/contacts/ResponseTypes';

import { AppThunkAction } from '..';
import OrganisationApi from '../../api/organisation/Organisation';
import { KnownAction } from './Actions';

export const actionCreators = {
    /**
     * Requests a contact
     * @param id Contact id
     */
    getRepresentedBy: (): AppThunkAction<KnownAction> => (dispatch) => {
        if (window.location.pathname.startsWith("/external")) {
            dispatch({
                type: 'REPRESENTEDBY_STORE.REQUEST.SUCCESS',
                data: []
            });
        } else {
            const listId = "0"
            dispatch({ type: 'REPRESENTEDBY_STORE.REQUEST', listId: listId });
            OrganisationApi.getRepresentedByOrganisations(listId)
                .then((c) => {
                    if (c) {
                        dispatch({
                            type: 'REPRESENTEDBY_STORE.REQUEST.SUCCESS',
                            data: c
                        });
                    }
                })
                .catch((e) => {
                });
        }
    }
};
