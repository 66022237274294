import React from 'react';

function SvgCall(props) {
    return (
        <svg height="1em" viewBox="0 0 512 512" width="1em" {...props}>
            <path
                d="M512 256c0 141.387-114.613 256-256 256-42.863 0-83.258-10.531-118.742-29.14a257.361 257.361 0 01-34.45-21.735 253.681 253.681 0 01-16.417-13.375C33.406 400.855 0 332.328 0 256 0 114.613 114.613 0 256 0s256 114.613 256 256zm0 0"
                fill="#ffe152"
            />
            <path
                d="M382.121 449.574v29.25a254.035 254.035 0 01-83.906 29.703 256.011 256.011 0 01-19.563 2.477c-4.105.367-8.242.629-12.414.785-3.394.145-6.812.207-10.238.207-7.648 0-15.203-.332-22.676-.992a254.505 254.505 0 01-103.445-32.18v-29.25c0-44.008 29.887-81.039 70.48-91.898a94.185 94.185 0 0116.875-2.914c2.57-.211 5.16-.313 7.786-.313h61.953c6.925 0 13.675.73 20.175 2.14a79.904 79.904 0 014.387 1.055c.031.012.055.012.086.024a94.681 94.681 0 0129.54 13.707c24.753 17.18 40.96 45.797 40.96 78.2zm0 0"
                fill="#3d4159"
            />
            <path
                d="M311.852 356.59l-.23 1.078-32.97 153.336c-7.46.66-15.023.992-22.652.992-7.648 0-15.203-.332-22.676-.992L200.36 357.676l-.23-1.086zm0 0"
                fill="#f2f0ec"
            />
            <path
                d="M281.535 388.773l-25.539 40.961-25.539-40.96"
                fill="#f74f4f"
            />
            <path
                d="M279.574 510.914A255.808 255.808 0 01256 512c-7.953 0-15.82-.367-23.582-1.078L256 419.078zm0 0"
                fill="#f74f4f"
            />
            <path
                d="M255.988 393.395l-42.828 23.8-13.031-60.601 26.613-15.676M255.988 393.395l42.832 23.8 13.028-60.601-26.614-15.676"
                fill="#d8d5d0"
            />
            <path
                d="M341.16 366.152l-27.691 72.223h13.586l-28.84 70.152a256.011 256.011 0 01-19.563 2.477c-4.105.367-8.242.629-12.414.785l45.614-160.422zm0 0M170.508 366.152l27.687 72.223h-13.582l28.84 70.152a255.662 255.662 0 0019.559 2.477c4.105.367 8.246.629 12.414.785l-45.297-159.367zm0 0"
                fill="#2a2f4f"
            />
            <path
                d="M289.938 317.8v41.657c0 15.25-10.055 28.14-23.907 32.422a33.821 33.821 0 01-10.043 1.516c-18.746 0-33.937-15.192-33.937-33.938v-41.656zm0 0"
                fill="#eab599"
            />
            <path
                d="M289.938 356.82v2.637c0 15.25-10.055 28.14-23.907 32.422-12.496-3.84-21.89-14.707-23.625-28 2.266.137 4.547.207 6.836.207 6.422 0 12.719-.508 18.848-1.516a115.622 115.622 0 0021.847-5.75zm0 0M162.887 249.535c0 15.711-11.48 28.742-26.512 31.16a31.64 31.64 0 01-5.055.403c-17.437 0-31.566-14.125-31.566-31.563s14.129-31.566 31.566-31.566c1.723 0 3.414.14 5.055.406 15.031 2.418 26.512 15.445 26.512 31.16zm0 0"
                fill="#f6caae"
            />
            <path
                d="M162.887 249.535c0 15.711-11.48 28.742-26.512 31.16a117.374 117.374 0 01-4.188-31.16c0-10.785 1.458-21.234 4.188-31.16 15.031 2.418 26.512 15.445 26.512 31.16zm0 0"
                fill="#eab599"
            />
            <path
                d="M142.602 249.535c0 6.23-5.051 11.281-11.282 11.281-6.23 0-11.28-5.05-11.28-11.28 0-6.231 5.05-11.282 11.28-11.282 6.23 0 11.282 5.05 11.282 11.281zm0 0"
                fill="#eab599"
            />
            <path
                d="M349.102 249.535c0 15.711 11.476 28.742 26.507 31.16 1.645.266 3.332.403 5.059.403 17.437 0 31.562-14.125 31.562-31.563s-14.125-31.566-31.562-31.566c-1.727 0-3.414.14-5.059.406-15.03 2.418-26.507 15.445-26.507 31.16zm0 0"
                fill="#f6caae"
            />
            <path
                d="M349.102 249.535c0 15.711 11.476 28.742 26.507 31.16a117.376 117.376 0 004.192-31.16c0-10.785-1.457-21.234-4.192-31.16-15.03 2.418-26.507 15.445-26.507 31.16zm0 0"
                fill="#eab599"
            />
            <path
                d="M373.438 188.04v44.722c0 58.441-42.696 106.925-98.598 115.922l-.574.093c-5.946.95-12.047 1.422-18.278 1.422-64.855 0-117.433-52.582-117.433-117.437v-44.723c0-13.656 2.328-26.781 6.632-38.973 16.063-45.707 59.614-78.472 110.801-78.472H256c6.219 0 12.328.48 18.285 1.422 56.176 8.777 99.152 57.382 99.152 116.023zm0 0"
                fill="#eab599"
            />
            <path
                d="M391.95 249.535c0 6.23-5.052 11.281-11.282 11.281s-11.281-5.05-11.281-11.28c0-6.231 5.05-11.282 11.281-11.282s11.281 5.05 11.281 11.281zm0 0"
                fill="#eab599"
            />
            <path
                d="M373.438 188.04v44.722c0 58.441-42.696 106.925-98.598 115.922l-.574.093c-56.176-8.777-99.141-57.386-99.141-116.015v-44.723c0-58.64 42.977-107.246 99.16-116.023 56.176 8.777 99.152 57.382 99.152 116.023zm0 0"
                fill="#f6caae"
            />
            <path
                d="M230.984 225.922c0 7.36-5.964 13.32-13.324 13.32-7.355 0-13.32-5.96-13.32-13.32s5.965-13.324 13.32-13.324c7.36 0 13.324 5.964 13.324 13.324zm0 0M307.652 225.922c0 7.36-5.964 13.32-13.324 13.32-7.355 0-13.32-5.96-13.32-13.32s5.965-13.324 13.32-13.324c7.36 0 13.324 5.964 13.324 13.324zm0 0"
                fill="#3d4159"
            />
            <path
                d="M228.266 223.57a5.706 5.706 0 11-11.414 0 5.706 5.706 0 1111.414 0zm0 0M305.59 223.57a5.71 5.71 0 01-11.418 0 5.71 5.71 0 0111.418 0zm0 0"
                fill="#fff"
            />
            <path
                d="M269.316 254.242h-26.644a5.746 5.746 0 01-5.746-5.75 5.746 5.746 0 015.746-5.746h26.644a5.746 5.746 0 015.746 5.746c0 3.176-2.57 5.75-5.746 5.75zm0 0M255.996 290.602c-9.836 0-19.668-2.465-28.633-7.391a5.224 5.224 0 115.032-9.16c14.777 8.125 32.421 8.125 47.199 0a5.224 5.224 0 115.031 9.16c-8.96 4.926-18.797 7.39-28.629 7.39zm0 0"
                fill="#eab599"
            />
            <path
                d="M373.438 147.453v45.813h-21.86V157.39l-157.781-29.32-33.383-6.208v71.403h-21.86v-45.813c0-52.223 42.329-94.555 94.544-94.555h45.797c1.242 0 2.484.024 3.73.086 50.477 1.942 90.813 43.489 90.813 94.47zm0 0"
                fill="#2a2f4f"
            />
            <path
                d="M373.438 147.453v45.813h-21.86V157.39l-157.781-29.32c8.695-41.755 44.922-73.395 88.828-75.087 50.477 1.942 90.813 43.489 90.813 94.47zm0 0"
                fill="#3d4159"
            />
            <path
                d="M228.57 383.645l-10.511 13.667-28.704 7.66-16.949 4.512-13.226-12.191c-.368-.348-.743-.68-1.098-1.035-37.281-34.797-54.898-62.153-71.137-110.5-.168-.469-.324-.95-.48-1.43l-5.633-17.086 11.14-13.562 18.848-22.957 16.774-3.918 2.601 6.613 7.325 18.633 10.605 26.98-13.805 12.602-9.32 8.504c4.273 13.699 25.047 45.965 35.746 55.535l11.598-4.961 17.18-7.379 20.175 20.848 13.93 14.375.012-.008zm0 0"
                fill="#66baed"
            />
            <path
                d="M189.527 343.336l-17.183 7.375 45.719 46.598 10.507-13.66zm0 0M148.129 279.031l-13.824 12.59-23.493-60.91 16.786-3.906zm0 0"
                fill="#f2f0ec"
            />
            <path
                d="M189.355 404.973l-16.949 4.511-13.226-12.191c-.368-.348-.743-.68-1.098-1.035-37.281-34.797-54.898-62.153-71.137-110.5-.168-.469-.324-.95-.48-1.43l-5.633-17.086 11.14-13.562s3.551 92.558 97.376 151.28c0 0 .007 0 .007.013zm0 0"
                fill="#2ba1cf"
            />
            <path
                d="M157.805 344.129l-15.887 15.883a21.573 21.573 0 01-15.328 6.347 21.558 21.558 0 01-15.332-6.343c-8.469-8.47-8.469-22.196 0-30.664l15.883-15.887c8.468-8.465 22.195-8.465 30.664 0a21.734 21.734 0 013.78 5.086c4.415 8.207 3.142 18.652-3.78 25.578zm0 0"
                fill="#eab599"
            />
            <path
                d="M157.805 344.129l-15.887 15.883a21.573 21.573 0 01-15.328 6.347 21.558 21.558 0 01-10.25-2.562c-4.41-8.207-3.14-18.656 3.781-25.578l15.887-15.887c6.922-6.922 17.37-8.195 25.578-3.781 4.414 8.207 3.14 18.652-3.781 25.578zm0 0"
                fill="#f6caae"
            />
            <path
                d="M137.258 482.86a257.361 257.361 0 01-34.45-21.735 253.681 253.681 0 01-16.417-13.375 247.343 247.343 0 0115.875-94.531c4.586 1.734 9.183 3.469 13.77 5.203 9.905 3.738 19.812 7.492 29.726 11.23a201.042 201.042 0 00-8.504 113.207zm0 0"
                fill="#2a2f4f"
            />
            <path
                d="M137.258 482.86a257.361 257.361 0 01-34.45-21.735 247.484 247.484 0 0113.227-102.703c9.906 3.738 19.813 7.492 29.727 11.23a201.042 201.042 0 00-8.504 113.207zm0 0"
                fill="#3d4159"
            />
            <path d="M294.809 17h190.539v143.227h-190.54zm0 0" fill="#f2f0ec" />
            <path
                d="M391.605 151.273l-20.46 48.594 89.515-51.148zm0 0"
                fill="#f2f0ec"
            />
            <g fill="#3d4159">
                <path d="M367.871 88.613c0 7.414-6.012 13.426-13.426 13.426-7.418 0-13.43-6.012-13.43-13.426 0-7.414 6.012-13.425 13.43-13.425 7.414 0 13.426 6.011 13.426 13.425zm0 0M403.504 88.613c0 7.414-6.012 13.426-13.426 13.426s-13.426-6.012-13.426-13.426c0-7.414 6.012-13.425 13.426-13.425s13.426 6.011 13.426 13.425zm0 0M439.14 88.613c0 7.414-6.011 13.426-13.43 13.426-7.413 0-13.425-6.012-13.425-13.426 0-7.414 6.012-13.425 13.426-13.425 7.418 0 13.43 6.011 13.43 13.425zm0 0" />
            </g>
        </svg>
    );
}

export default SvgCall;
