/* eslint-disable */
import afetch from '../AuthenticatedFetch';
import AppConfig from '../../components/config/Config';
import { OrganisationSyncResponse, OrgContactGridAIQueryRequest, OrgContactGridAIQueryResponse } from './ResponseTypes';
import UnauthenticatedFetch from 'src/api/UnauthenticatedFetch';

const apiUrls = {
    sync(lastSynced: number) {
        return `${AppConfig.Settings.api.externalUri}/organisationsync?lastSyncTimestamp=${lastSynced}`;
    },
    aiFilterQuery() {
        return `${AppConfig.Settings.api.externalUri}/organisationsync/ai-query`;
    }
};

const apiCalls = {
    sync(lastSynced: number) {
        return afetch.request<null, OrganisationSyncResponse>(
            apiUrls.sync(lastSynced),
            'GET',
            null,
            'Unable to get latest updates'
        );
    },
    aiFilterQuery(prompt: string, teams: string[], agencies: string[]) {
        return afetch.request<OrgContactGridAIQueryRequest, OrgContactGridAIQueryResponse>(
            apiUrls.aiFilterQuery(),
            'POST',
            { prompt, availableTeams: teams, availableRepresentingAgencies: agencies },
            'Unable to get filters'
        );
    }
};

export default apiCalls;
