import styled from 'styled-components';

export default styled.span`
    width: fit-content;
    padding: 0;
    color: ${({ color }) => color || '#00e'};
    font-style: italic;
    background: none;
    border: none;
    cursor: pointer;
    opacity: 0.7;

    &:hover {
        opacity: 1;
    }
`;
