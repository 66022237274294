/* eslint-disable */
import * as React from 'react';
import styled from 'styled-components';
import { useEffect, useRef } from 'react';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { ProfileImage } from '../../components/ui-components/ProfileImage';
import CompanyApi from '../../api/company/Company';
import OrganisationApi from '../../api/organisation/Organisation';
// import { AsyncOverlay } from '../../components/ui-components/AsyncOverlay';
import { OrganisationDTO } from '../../api/organisation/ResponseTypes';
import { Organisation } from '../Organisation';
import IndustrySelector from '../../components/profile/IndustrySelector';
import LocationPicker from '../../components/profile/LocationPicker';
import VerificationAPI from '../../api/verification/Verification';

interface propsType {
    organisation: OrganisationDTO;
    handleClick: any;
    editingId: string;
    setEditingId: (id: string) => void;
    setIsAddingLocation: (to: boolean) => void;
    refresh: () => void;
}

const GridComponent = (props: propsType) => {
    const {
        organisation,
        handleClick,
        editingId,
        setEditingId,
        refresh
    } = props;
    const myRef = useRef(null);
    const [currentOrgImage, setCurrentOrgImage] = React.useState('');
    const [isAddingLocation, setIsAddingLocation] = React.useState(false);
    const [newOrganisation, setNewOrganisation] = React.useState<
        OrganisationDTO
    >({
        apiBaseUrl: '',
        deleted: false,
        id: '',
        imageUrl: '',
        industries: [],
        isAgency: false,
        isHirer: false,
        locationComponents: [],
        locationFullName: '',
        locationPlaceId: '',
        locationPlaceName: '',
        name: '',
        verified: false
    });

    useEffect(() => {
        setNewOrganisation(organisation);
    }, [organisation]);

    useEffect(() => {
        setCurrentOrgImage(
            `${CompanyApi.getOrganisationProfileImageUrl(
                newOrganisation.id
            )}?updated_at=${(window as any).clientLastReload}`
        );
    }, [newOrganisation.id, props.editingId]);

    const setLocation = (
        placeId: string,
        placeName: string,
        components: google.maps.GeocoderAddressComponent[]
    ) => {
        setNewOrganisation({
            ...newOrganisation,
            locationPlaceId: placeId,
            locationPlaceName: placeName,
            locationFullName: placeName,
            locationComponents: components
        });
    };

    return (
        <Card title={name} ref={myRef}>
            <div>
                <ProfileImage
                    type={'Organisation'}
                    style={{
                        marginRight: '0!important',
                        cursor:
                            editingId == newOrganisation.id
                                ? 'pointer!important'
                                : 'normal!important'
                    }}
                    size={150}
                    url={`${currentOrgImage}`}
                    onSelect={() => {
                        if (editingId == newOrganisation.id) {
                            handleClick(newOrganisation.id);
                        }
                    }}
                    selectable={editingId == newOrganisation.id}
                />
                <label htmlFor="name">Name</label>
                <input
                    disabled={editingId !== newOrganisation.id}
                    className="form-control"
                    value={newOrganisation.name}
                    onChange={(e) => {
                        setNewOrganisation({
                            ...newOrganisation,
                            name: e.target.value
                        });
                    }}
                />
                <label htmlFor="isAgency">Is Agency</label>
                <input
                    aria-label="isAgencyCheck"
                    type="checkbox"
                    id="isAgency"
                    name="isAgency"
                    onChange={(e) => {
                        setNewOrganisation({
                            ...newOrganisation,
                            isAgency: !newOrganisation.isAgency
                        });
                    }}
                    checked={newOrganisation.isAgency}
                    disabled={editingId !== newOrganisation.id}
                />
                <label htmlFor="isAgency">Is Hirer</label>
                <input
                    aria-label="isAgencyCheck"
                    type="checkbox"
                    id="isAgency"
                    name="isAgency"
                    onChange={(e) => {
                        setNewOrganisation({
                            ...newOrganisation,
                            isHirer: !newOrganisation.isHirer
                        });
                    }}
                    checked={newOrganisation.isHirer}
                    disabled={editingId !== newOrganisation.id}
                />
                <label htmlFor="locationButton">Location</label>
                <Button
                    disabled={editingId !== newOrganisation.id}
                    bsStyle="success"
                    bsSize="medium"
                    style={{ padding: '2px 6px' }}
                    onClick={() => {
                        setIsAddingLocation(true);
                    }}
                >
                    Set Location
                </Button>
                <label htmlFor="locationPlaceName">Location Place Name</label>
                <input
                    disabled={editingId !== newOrganisation.id}
                    className="form-control"
                    value={newOrganisation.locationPlaceName}
                    onChange={(e) => {
                        setNewOrganisation({
                            ...newOrganisation,
                            locationPlaceName: e.target.value
                        });
                    }}
                />
                <label htmlFor="locationFullName">Location Full Name</label>
                <input
                    disabled={editingId !== newOrganisation.id}
                    className="form-control"
                    value={newOrganisation.locationFullName}
                    onChange={(e) => {
                        setNewOrganisation({
                            ...newOrganisation,
                            locationFullName: e.target.value
                        });
                    }}
                />
                <label htmlFor="industries">Industries</label>
                <IndustrySelector
                    aria-label="IndustrySelector"
                    enabled={editingId == newOrganisation.id}
                    industries={
                        newOrganisation.industries
                            ? newOrganisation.industries
                            : []
                    }
                    searchPlacement="auto"
                    onRemoved={(i) =>
                        setNewOrganisation({
                            ...newOrganisation,
                            industries: newOrganisation.industries.filter(
                                (industry) => industry.id !== i
                            )
                        })
                    }
                    onSelected={(i) => {
                        setNewOrganisation({
                            ...newOrganisation,
                            industries: [...newOrganisation.industries, i]
                        });
                    }}
                />
                <label htmlFor="apiBaseUrl">API Base URL</label>
                <input
                    disabled={editingId !== newOrganisation.id}
                    className="form-control"
                    value={newOrganisation.apiBaseUrl}
                    onChange={(e) => {
                        setNewOrganisation({
                            ...newOrganisation,
                            apiBaseUrl: e.target.value
                        });
                    }}
                />
                <label htmlFor="isVerified">Verified</label>
                <input
                    aria-label="isVerifiedCheck"
                    type="checkbox"
                    id="isVerified"
                    name="isVerified"
                    onChange={async (e) => {
                        setNewOrganisation({
                            ...newOrganisation,
                            verified: !newOrganisation.verified
                        });
                        if (!newOrganisation.verified) {
                            await VerificationAPI.verifyOrganisation(
                                organisation.id
                            );
                        } else {
                            await VerificationAPI.unverifyOrganisation(
                                organisation.id
                            );
                        }
                    }}
                    checked={newOrganisation.verified}
                    disabled={editingId !== newOrganisation.id}
                />
            </div>
            <span id="controls">
                <Button
                    aria-label="edit button"
                    bsStyle="primary"
                    onClick={async () => {
                        if (editingId == newOrganisation.id) {
                            const toastId = toast.info(
                                'Updating organisation...'
                            );
                            setEditingId('');
                            if (
                                organisation.verified !==
                                newOrganisation.verified
                            ) {
                                if (newOrganisation.verified) {
                                    await VerificationAPI.verifyOrganisation(
                                        newOrganisation.id
                                    );
                                } else if (!newOrganisation.verified) {
                                    await VerificationAPI.unverifyOrganisation(
                                        newOrganisation.id
                                    );
                                }
                            }

                            // @ts-ignore
                            OrganisationApi.updateOrganisation(newOrganisation)
                                .then((r) => {
                                    toast.dismiss(toastId);
                                    toast.success(
                                        'Successfully updated organisation!'
                                    );
                                    refresh();
                                })
                                .catch((r) => {
                                    toast.dismiss(toastId);
                                    refresh();
                                });
                        } else {
                            setEditingId(newOrganisation.id);
                        }
                    }}
                >
                    {editingId == newOrganisation.id ? (
                        <i className="fas fa-lock-open" />
                    ) : (
                        <i className="fas fa-lock" />
                    )}
                </Button>
                {/*<Button*/}
                {/*    disabled*/}
                {/*    aria-label="save button"*/}
                {/*    bsStyle="primary"*/}
                {/*    // onClick={() => setIsEditing(!isEditing)}*/}
                {/*>*/}
                {/*    <i className="fas fa-save" />*/}
                {/*</Button>*/}
                {/*<Button*/}
                {/*    aria-label="save button"*/}
                {/*    bsStyle="primary"*/}
                {/*    onClick={() => setIsEditing(!isEditing)}*/}
                {/*>*/}
                {/*    <i className="fas fa-times" />*/}
                {/*</Button>*/}
                {/*<Button*/}
                {/*    disabled*/}
                {/*    aria-label="verify"*/}
                {/*    bsStyle="primary"*/}
                {/*    onClick={() => {*/}
                {/*        console.log('clicked verify');*/}
                {/*    }}*/}
                {/*>*/}
                {/*    {organisation.verified ? (*/}
                {/*        <i className="fas fa-user-check" />*/}
                {/*    ) : (*/}
                {/*        <i className="fas fa-user-times" />*/}
                {/*    )}*/}
                {/*</Button>*/}
            </span>
            {/*<AsyncOverlay show={editingId == newOrganisation.id} />*/}
            {isAddingLocation && (
                <LocationPicker
                    show={isAddingLocation}
                    onHide={() => {
                        setIsAddingLocation(false);
                    }}
                    onChange={setLocation}
                    placeId={null}
                    allowFriendlyName={true}
                />
            )}
        </Card>
    );
};

export default GridComponent;

const Card = styled.div`
    height: 900px;
    width: 400px;
    margin: 10px 10px 3rem;
    padding: 10px;
    text-align: center;
    box-shadow: 0 0 15px 0 rgba(67, 67, 67, 0.3);
    position: relative;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    > div {
        > label {
            margin-top: 1rem;
            width: 100%;
            text-align: left;
        }
        > input {
            width: 100% !important;
            margin-left: 0 !important;
            text-align: left;
        }
        > input[type='checkbox'] {
            width: min-content !important;
            -ms-transform: scale(2); /* IE */
            -moz-transform: scale(2); /* FF */
            -webkit-transform: scale(2); /* Safari and Chrome */
            -o-transform: scale(2); /* Opera */
            transform: scale(2);
        }
    }

    > #controls {
        width: 100% !important;
        display: flex;
        justify-content: space-between;
        > button {
            width: 8rem;
            font-size: smaller;
        }
    }

    > img {
        height: 80px;
        width: 80px;
    }

    > div.info {
        font-weight: 600;
        margin-top: 15px;

        > span {
            color: #666;
            font-weight: 400;
            display: block;
        }
    }
`;
