/* eslint-disable */
import React from 'react';
import withWindowDimensions from '../components/util/withWindowDimensions';
import '../css/Ratings.css';
import RatingApi from '../api/ratings/Rating';
import { Rating, RatingDirection } from '../api/offers/ResponseTypes';
import RatingPreview from '../components/rating/RatingPreview';
import FullScreenLoader from 'src/components/ui-components/FullScreenLoader';
import Utilities from 'src/Utilities';
import InternalTracker from 'src/InternalTracker';
import { getValue, setValue } from 'src/db/KeyValueCache';
import { CircularProgress } from '@mui/material';

type Props = { 
    windowWidth: number 
};

interface State {
    organisationId: null | string;
    userId: null | string;
    contactId: null | string;
    activeFilter: string;
    ratings: Rating[];
    loading: boolean;
    highlightRatingId: string | null;
    stale: boolean;
}

export class Ratings extends React.Component<
    Props,
    State
> {
    state: State = {
        organisationId: null,
        userId: null,
        contactId: null,
        activeFilter: "all",
        ratings: [],
        loading: true,
        highlightRatingId: null,
        stale: false
    };

    componentDidMount() {
        this.loadRatings();
        let user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user") || "{}") : null;
        if (user) {
            this.setState({
                organisationId: user.organisationId || null,
                userId: user.id || null,
                contactId: user.contactId || null
            })
        } else {
            setTimeout(() => {
                this.componentDidMount();
            }, 500)
        }
        (window as any).onSignalRUserRatingUpdated = () => {
            this.loadRatings();
        }
    }

    componentWillUnmount(): void {
        (window as any).onSignalRUserRatingUpdated = null;
    }

    async loadRatings() {
        InternalTracker.trackEvent("Ratings Reloaded")
        const staleRatings = await getValue("ratings");
        if (staleRatings && staleRatings.length) {
            this.setState({
                ratings: staleRatings.filter(r => !r.recipientHidden),
                loading: false,
                stale: true
            })
        }
        let ratings = await RatingApi.getAllMyRatings();
        setValue("ratings", ratings);

        this.setState({
            ratings: ratings.filter(r => !r.recipientHidden),
            loading: false,
            stale: false
        }, () => {
            if (window.location.href.indexOf("/rating/") !== -1) {
                const ratingId = window.location.href.split("/rating/")[1];

                const myRatingsReceived = this.state.userId ? this.state.ratings.filter(r => r.recipientUserId === this.state.userId) : [];
                const oragnisationRatingsReceived = this.state.organisationId ? this.state.ratings.filter(r => !r.recipientUserId) : [];

                if (!myRatingsReceived.find(rating => rating.id === parseInt(ratingId)) && !oragnisationRatingsReceived.find(rating => rating.id === parseInt(ratingId))) {
                    alert("Rating not found");
                    return;
                }

                this.setState({
                    highlightRatingId: ratingId,
                    activeFilter: oragnisationRatingsReceived.find(rating => rating.id === parseInt(ratingId)) ? "organisation-ratings-received" : "my-ratings-received"
                }, () => {
                
                })
            }
        })
    }

    render() {

        const myRatingsReceived = this.state.userId ? this.state.ratings.filter(r => 
            r.recipientContactId === this.state.contactId && 
            (r.ratingDirection === RatingDirection.Worker_OrganisationsHirer || r.ratingDirection === RatingDirection.Worker_AgencysConsultant)
        ) : [];
        const myRatingsGiven = this.state.userId ? this.state.ratings.filter(r => 
            r.raterContactId === this.state.contactId && 
            (r.ratingDirection === RatingDirection.OrganisationsHirer_Worker || r.ratingDirection === RatingDirection.AgencysConsultant_Worker)
        ) : [];
        const colleaguesRatingsReceived = this.state.organisationId ? this.state.ratings.filter(r => 
            r.recipientContactId !== this.state.contactId &&
            (r.ratingDirection === RatingDirection.Worker_OrganisationsHirer || r.ratingDirection === RatingDirection.Worker_AgencysConsultant)    
        ) : [];
        const colleaguesRatingsGiven = this.state.organisationId ? this.state.ratings.filter(r => 
            r.raterContactId !== this.state.contactId &&
            (r.ratingDirection === RatingDirection.OrganisationsHirer_Worker || r.ratingDirection === RatingDirection.AgencysConsultant_Worker)
        ) : [];
        const oragnisationRatingsReceived = this.state.organisationId ? this.state.ratings.filter(r => 
            r.ratingDirection === RatingDirection.Worker_HirersOrganisation || r.ratingDirection === RatingDirection.Worker_Agency
        ) : [];
        const organisationRatingsGiven = this.state.organisationId ? this.state.ratings.filter(r =>
            r.ratingDirection === RatingDirection.HirersOrganisation_Worker || r.ratingDirection === RatingDirection.Agency_Worker
        ) : [];

        let filters = [
            {
                "id": "all",
                "label": "All",
                "ratings": this.state.ratings
            },
            {
                id: "my-ratings-received",
                label: "Received by Me",
                ratings: myRatingsReceived,
                summary: [
                    { type: "string", text: "You received" },
                    { type: "number", text: (myRatingsReceived).length },
                    { type: "string", text: "ratings, the average of " + (myRatingsReceived.length > 1 ? "these" : "this") + " is" },
                    { type: "number", text: Utilities.toFixedNumber(myRatingsReceived.map(r => r.stars).reduce((prev, current) => prev + current, 0) / (myRatingsReceived.length), 2) }
                ]
            },
            {
                id: "my-ratings-given",
                label: "Given by Me",
                ratings: myRatingsGiven,
                summary: [
                    { type: "string", text: "You gave" },
                    { type: "number", text: (myRatingsGiven).length },
                    { type: "string", text: "ratings, the average of " + (myRatingsGiven.length > 1 ? "these" : "this") + " is" },
                    { type: "number", text: Utilities.toFixedNumber(myRatingsGiven.map(r => r.stars).reduce((prev, current) => prev + current, 0) / (myRatingsGiven.length)) }
                ]
            },
            {
                id: "colleagues-ratings-received",
                label: "Received by Colleagues",
                ratings: colleaguesRatingsReceived,
                summary: [
                    { type: "string", text: "Your colleagues received" },
                    { type: "number", text: (colleaguesRatingsReceived).length },
                    { type: "string", text: "ratings, the average of " + (colleaguesRatingsReceived.length > 1 ? "these" : "this") + " is" },
                    { type: "number", text: Utilities.toFixedNumber(colleaguesRatingsReceived.map(r => r.stars).reduce((prev, current) => prev + current, 0) / (colleaguesRatingsReceived.length), 2) }
                ]
            },
            {
                id: "colleagues-ratings-given",
                label: "Given by Colleagues",
                ratings: colleaguesRatingsGiven,
                summary: [
                    { type: "string", text: "You colleagues gave" },
                    { type: "number", text: (colleaguesRatingsGiven).length },
                    { type: "string", text: "ratings, the average of " + (colleaguesRatingsGiven.length > 1 ? "these" : "this") + " is" },
                    { type: "number", text: Utilities.toFixedNumber(colleaguesRatingsGiven.map(r => r.stars).reduce((prev, current) => prev + current, 0) / (colleaguesRatingsGiven.length), 2) }
                ]
            },
            {
                id: "organisation-ratings-received",
                label: "Received by my Organization",
                ratings: oragnisationRatingsReceived,
                summary: [
                    { type: "string", text: "Your organization received" },
                    { type: "number", text: (oragnisationRatingsReceived).length },
                    { type: "string", text: "ratings, the average of " + (oragnisationRatingsReceived.length > 1 ? "these" : "this") + " is" },
                    { type: "number", text: Utilities.toFixedNumber(oragnisationRatingsReceived.map(r => r.stars).reduce((prev, current) => prev + current, 0) / (oragnisationRatingsReceived.length), 2) }
                ]
            },
            {
                id: "organisation-ratings-given",
                label: "Given by my Organization",
                ratings: organisationRatingsGiven,
                summary: [
                    { type: "string", text: "Your organization gave" },
                    { type: "number", text: (organisationRatingsGiven).length },
                    { type: "string", text: "ratings, the average of " + (organisationRatingsGiven.length > 1 ? "these" : "this") + " is" },
                    { type: "number", text: Utilities.toFixedNumber(organisationRatingsGiven.map(r => r.stars).reduce((prev, current) => prev + current, 0) / (organisationRatingsGiven.length), 2) }
                ]
            }
        ];

        let selectedFilterRatings = filters.find(f => f.id === this.state.activeFilter)?.ratings || [];
        let selectedFilterSummary = filters.find(f => f.id === this.state.activeFilter)?.summary || [];

        const givenRatings = JSON.parse(JSON.stringify(myRatingsGiven)).concat(JSON.parse(JSON.stringify(colleaguesRatingsGiven))).concat(JSON.parse(JSON.stringify(organisationRatingsGiven)));
        const receivedRatings = JSON.parse(JSON.stringify(myRatingsReceived)).concat(JSON.parse(JSON.stringify(colleaguesRatingsReceived))).concat(JSON.parse(JSON.stringify(oragnisationRatingsReceived)));

        return (
            <div className='ratings-page'>
                { (this.state.stale) &&
                    <div className="nonblocking-loading-wrapper">
                        <CircularProgress style={{ width: 24, height: 24 }} />
                        Fetching Latest
                    </div>
                }
                <div className='categories'>
                    { filters.map(filter => {
                        return (
                            <div
                                className='category'
                                data-selected={this.state.activeFilter === filter.id}
                                onClick={() => {
                                    InternalTracker.trackEvent("Ratings Filtered", {
                                        filter: filter.id
                                    })
                                    this.setState({
                                        activeFilter: filter.id
                                    })
                                }}
                            >
                                {filter.label} 
                                <div className='badge'>
                                    {filter.ratings.length}
                                </div>
                            </div>
                        )
                    }) }
                </div>
                { (selectedFilterSummary.length !== 0 && !this.state.highlightRatingId && selectedFilterRatings.length !== 0) &&
                    <div className='stats'>
                        <div className='summary'>
                            { selectedFilterSummary.map(summaryPiece => {
                                if (summaryPiece.type === "string") {
                                    return (
                                        <span>
                                            {summaryPiece.text}
                                        </span>
                                    )
                                } else {
                                    return (
                                        <strong>
                                            {summaryPiece.text}
                                        </strong>
                                    )
                                }
                            }) }
                        </div>
                    </div>
                }
                <div className='rating-list'>
                    { selectedFilterRatings.map(rating => {
                        if (this.state.highlightRatingId && (rating.id + "") !== this.state.highlightRatingId) 
                            return null;

                        console.log("Rendering rating", rating);

                        return (
                            <RatingPreview
                                showApprove={this.state.activeFilter === "organisation-ratings-received" || this.state.activeFilter === "my-ratings-received"}
                                notOwn={!(this.state.activeFilter === "organisation-ratings-received" || this.state.activeFilter === "my-ratings-received")}
                                key={rating.id + "-" + rating.stars + "-" + this.state.activeFilter}
                                rating={rating}
                                highlighted={(rating.id + "") === this.state.highlightRatingId}
                                onLoading={(loading) => {
                                    this.setState({
                                        loading: loading
                                    })
                                }}
                                clickableRecipientContactId={(givenRatings.map(r => r.id).includes(rating.id) && rating.recipientUserId) ? rating.recipientContactId : undefined}
                                clickableRaterContactId={(receivedRatings.map(r => r.id).includes(rating.id) && rating.raterUserId) ? rating.raterContactId : undefined}
                                refresh={() => {
                                    this.loadRatings();
                                }}
                            />
                        )
                    }) }
                    { (this.state.highlightRatingId) &&
                        <button onClick={() => {
                            InternalTracker.trackEvent("Ratings Show All");
                            this.setState({
                                highlightRatingId: null
                            })
                        }}>Show all Ratings</button>
                    }
                </div>
                { (this.state.loading) &&
                    <FullScreenLoader
                        loadingMessage="Loading Ratings..."
                    />
                }
            </div>
        );
    }
}

export default withWindowDimensions(Ratings);
