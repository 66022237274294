/* eslint-disable */
import React from 'react';
import moment from 'moment';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import OfferApi from '../../api/offers/Offers';
import {
    UserOfferDto,
    OfferResponseTypes,
    OfferConfirmationResponseTypes
} from '../../api/offers/ResponseTypes';
import { DateFormats } from '../../constants';
import { ProfileImage } from '../../components/ui-components/ProfileImage';
import CompanyApi from '../../api/company/Company';
import Analytics from '../../services/analytics.service';
import Splash from '../../components/ui-components/Splash';
import MobileOfferModal from '../../pages/mobile/mobileoffersmodal';
import NotificationApi from '../../api/notifications/Notifications';
import { PendingEventSet } from '../../api/events/ResponseTypes';
import { ObjectMetadataDto } from '../../api/BaseResponseTypes';
import PendingEventsModal from '../../pages/mobile/pendingeventsmodal';

interface State {
    loading: boolean;
    loadingOffer: boolean;
    offers: UserOfferDto[];
    eventSets: PendingEventSet[];
    selectedOffer: UserOfferDto | undefined;
    selectedEventSet: PendingEventSet | undefined;
    showOffer: boolean;
    showAgencySelector: boolean;
    showEventPreview: boolean;
}

interface UpcomingItem {
    isOffer: boolean;
    offer: UserOfferDto | undefined;
    eventSet: PendingEventSet | undefined;
}

export default class MobileOffers extends React.Component<
    RouteComponentProps<{ offerId: string }>,
    State
> {
    state = {
        loading: true,
        loadingOffer: false,
        offers: [],
        eventSets: [],
        selectedOffer: undefined,
        selectedEventSet: undefined,
        showOffer: false,
        showAgencySelector: false,
        showEventPreview: false
    } as State;

    offerModal: React.RefObject<MobileOfferModal>;

    constructor(props) {
        super(props);

        this.offerModal = React.createRef();
    }

    async componentDidMount() {
        const offerId = this.props.match.params.offerId;

        const getOffers = OfferApi.getUserOffers();
        const getEventSets = NotificationApi.getAssignmentConfirmations();

        const [offers, eventSets] = await Promise.all([
            getOffers,
            getEventSets
        ]);

        const offer = offers.find((o) => o.id == offerId);
        const showEventPreview = !!(
            offer &&
            offer.complete &&
            offer.confirmation == OfferConfirmationResponseTypes.Confirmed &&
            !offer.addedToSchedule
        );

        console.log("OFFER: ", offers, offer);

        this.setState({
            loading: false,
            offers: offers,
            eventSets,
            selectedOffer: offer,
            showOffer: !!offer && !showEventPreview,
            showEventPreview: showEventPreview
        });

        Analytics.trackEvent('mobile-offers-loaded');
    }

    render() {
        if (this.state.loading) {
            return (
                <div>
                    <Splash />
                </div>
            );
        } else if (this.state.offers.length) {
            return (
                <div
                    className="layout vertical"
                    style={{ height: 'calc(100vh - 30px)' }}
                >
                    <h4
                        style={{
                            textAlign: 'center',
                            margin: '0 10px',
                            paddingBottom: '10px',
                            fontWeight: 600
                        }}
                    >
                        Upcoming Offers
                    </h4>
                    <div
                        className="flex"
                        style={{ overflowY: 'auto', overflowX: 'hidden' }}
                    >
                        {this.renderBody()}
                    </div>
                    <MobileOfferModal
                        ref={this.offerModal}
                        onChange={this.handleChange}
                    />
                    <PendingEventsModal
                        eventSet={this.state.selectedEventSet}
                        onClose={this.handleEventSetClose}
                        onConfirm={this.handleEventSetConfirm}
                    />
                </div>
            );
        } else {
            return (
                <>
                    <h4
                        style={{
                            textAlign: 'center',
                            margin: '0 10px',
                            paddingBottom: '10px',
                            fontWeight: 600
                        }}
                    >
                        Upcoming Offers
                    </h4>
                    <NoOffers>
                        <div>You have no upcoming offers</div>
                        <i className="fa fa-briefcase" />
                        <div>
                            Let your contacts know that they can send you offers
                            directly via updatedge
                        </div>
                    </NoOffers>
                </>
            );
        }
    }

    renderBody() {
        const offers = this.state.offers.map((o) => {
            return { isOffer: true, offer: o } as UpcomingItem;
        });
        const assignments = this.state.eventSets.map((e) => {
            return { isOffer: false, eventSet: e } as UpcomingItem;
        });

        const upcoming = offers.concat(assignments).sort((a, b) => {
            const aStart = moment(
                a.isOffer
                    ? a.offer!.events[0].start
                    : a.eventSet!.pendingEvents[0].start
            );
            const bStart = moment(
                b.isOffer
                    ? b.offer!.events[0].start
                    : b.eventSet!.pendingEvents[0].start
            );

            return aStart < bStart ? -1 : aStart > bStart ? 1 : 0;
        });

        return upcoming.map((upcoming) => {
            if (upcoming.isOffer) {
                const offer = upcoming.offer!;
                const event = offer.events[0];
                return this.renderCard(
                    offer.id,
                    offer.title,
                    event.start,
                    offer.metadata,
                    this.renderResponse(offer),
                    () => this.offerModal.current!.open(offer)
                );
            } else {
                const eventSet = upcoming.eventSet!;
                const event = eventSet.pendingEvents[0];
                const head = event.addedToSchedule ? (
                    <span className="response accepted">
                        assignment confirmation
                    </span>
                ) : (
                    <span className="response pending">
                        assignment confirmation (pending)
                    </span>
                );
                return this.renderCard(
                    eventSet.id,
                    event.title,
                    event.start,
                    eventSet.metadata,
                    head,
                    () => this.setState({ selectedEventSet: eventSet })
                );
            }
        });
    }

    renderCard = (
        id: string,
        title: string,
        start: string,
        metadata: ObjectMetadataDto,
        head: JSX.Element | string,
        onClick: () => void
    ) => {
        const companyImage = CompanyApi.getOrganisationProfileImageUrl(
            metadata.createdByOrganisationId
        );

        return (
            <Card key={id}>
                <div className="card-header">{head}</div>
                <div
                    className="details layout horizontal center"
                    onClick={() => onClick()}
                >
                    <ProfileImage
                        selectable={false}
                        url={companyImage}
                        size={54}
                    />
                    <div
                        className="layout vertical flex"
                        style={{ margin: '0 15px' }}
                    >
                        <span style={{ fontSize: '14px', fontWeight: 600 }}>
                            {title}
                        </span>
                        <span style={{ fontSize: '11px', marginTop: '5px' }}>
                            <span
                                style={{ fontWeight: 600, marginRight: '5px' }}
                            >
                                Starts
                            </span>
                            {moment(start).format(
                                DateFormats.ShortDateWithDayAndTime
                            )}
                        </span>
                    </div>
                    <div>
                        <i className="fas fa-arrow-right" />
                    </div>
                </div>
                <div className="card-footer layout horizontal">
                    <span className="flex">{metadata.createdByFullName}</span>
                    <span style={{ margin: '0 2px' }}>|</span>
                    <span className="flex">
                        {metadata.createdByOrganisationName}
                    </span>
                </div>
            </Card>
        );
    };

    renderResponse(offer: UserOfferDto) {
        if (offer.withdrawn) {
            return <span className="response rejected">withdrawn</span>;
        } else if (
            offer.confirmation == OfferConfirmationResponseTypes.Confirmed
        ) {
            return <span className="response accepted">confirmed</span>;
        } else if (
            offer.complete &&
            +offer.confirmation != OfferConfirmationResponseTypes.Confirmed
        )
            return <span className="response rejected">unsuccessful</span>;

        switch (offer.response) {
            case OfferResponseTypes.Yes:
                return <span className="response applied">applied</span>;
            case OfferResponseTypes.No:
                return <span className="response rejected">rejected</span>;
            default:
            case OfferResponseTypes.Pending:
                return <span className="response pending">new</span>;
        }
    }

    handleChange = (offer: UserOfferDto) => {
        this.setState({
            offers: this.state.offers.map((o) =>
                o.id === offer.id ? offer : o
            )
        });

        Analytics.trackEvent('mobile-offers-actioned-offer');
    };

    handleEventSetClose = () => {
        this.setState({
            selectedEventSet: undefined
        });
    };

    handleEventSetConfirm = () => {
        const eventSet = this.state.selectedEventSet!;

        this.setState({
            eventSets: this.state.eventSets.map((e) =>
                e.id === eventSet.id
                    ? {
                          ...eventSet,
                          pendingEvents: eventSet.pendingEvents.map((ev) => {
                              return { ...ev, addedToSchedule: true };
                          })
                      }
                    : e
            ),
            selectedEventSet: undefined
        });

        toast.success('Added to schedule!', { autoClose: 2500 });

        Analytics.trackEvent('mobile-offers-event-set-confirmed');
    };
}

const Card = styled.div`
    margin: 10px 5px;
    color: black;
    background: #eaeaea;
    border-radius: 7px;

    .card-header {
        font-weight: 600;
        font-size: 12px;
        letter-spacing: 0.7px;
        text-align: center;
        border-bottom: 1px solid #aaa;

        > span {
            display: block;
            width: 100%;
            padding: 3px 0;
            border-top-left-radius: 7px;
            border-top-right-radius: 7px;

            &.pending {
                background: #b3dcff;
            }

            &.accepted {
                background: #c2fcd1;
            }

            &.rejected {
                background: #fecece;
            }

            &.applied {
                background: #fef3c5;
            }
        }
    }

    > .details {
        padding: 10px;
        cursor: pointer;

        .fa-arrow-right {
            color: #444;
            font-size: 30px;
        }
    }

    > .card-footer {
        padding: 3px 0;
        border-top: 1px solid #aaa;

        > span:first-child {
            text-align: right;
        }
    }
`;

const NoOffers = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    width: 100%;
    text-align: center;
    transform: translate(-50%, -50%);

    > i {
        margin: 25px 0;
        font-size: 70px;
        opacity: 0.85;
    }

    > div {
        font-size: 16px;
        text-align: center;
    }
`;
