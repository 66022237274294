/* eslint-disable */
import React, { useEffect, useState } from 'react';
import LinearProgress from '@mui/material/LinearProgress';

export interface SpinnerProps {
    text?: React.ReactChild;
    hide?: boolean;
    noText?: boolean;
    view?: string;
}

export interface SpinnerState {
    dummyProgress: number;
}

let progressInterval;
const SECONDS_FOR_ONE_CONATCT = 0.03;

export class Spinner extends React.Component<SpinnerProps, SpinnerState> {

    constructor(props) {
        super(props);
        this.state = {
            dummyProgress: 0
        } as SpinnerState;

    }

    componentWillReceiveProps() {
        if (this.props.view === "availability" && localStorage.getItem("last-availability-search-order") === "2") {
            let lastResultCount = localStorage.getItem("last-availability-search-result-count");
            this.startDummyProgressIncrease(lastResultCount);
        }
    }

    componentWillUnmount() {
        clearInterval(progressInterval)
    }

    startDummyProgressIncrease(expectedResults) {
        console.log("startDummyProgressIncrease() ___")
        this.setState({ dummyProgress: 0 });
        clearInterval(progressInterval);
        
        expectedResults = 1020

        // Show dummy progress bar if more than 100 results
        if (expectedResults > 100) {
            progressInterval = setInterval(() => {
                console.log(this.state.dummyProgress + 5 + "__")
                this.setState({ dummyProgress: this.state.dummyProgress + 5 });
                if (this.state.dummyProgress + 5 >= 100) {
                    this.setState({ dummyProgress: 0 })
                    clearInterval(progressInterval)
                }
            }, expectedResults * SECONDS_FOR_ONE_CONATCT / 20 * 1000)
        }
    }

    render() {

        if (this.props.hide) 
            return <React.Fragment />;

        if (this.state.dummyProgress > 0)
            return (
                <div className="row" data-testid="loading-spinner" data-view={this.props.view}>
                    <LinearProgress 
                        variant="determinate"
                        value={this.state.dummyProgress}
                        style={{
                            height: 40
                        }}
                    />
                    {!this.props.noText && (
                        <div>
                            {this.props.text === undefined ? 'Loading...' : this.props.text}
                        </div>
                    )}
                </div>
            )

        return (
            <div className="row" data-testid="loading-spinner" data-view={this.props.view}>
                <div className="col-md-12 text-center">
                    <div className="spinnerinline spinner-m">
                        <div className="rect1" />
                        <div className="rect2" />
                        <div className="rect3" />
                        <div className="rect4" />
                        <div className="rect5" />
                    </div>
                    {!this.props.noText && (
                        <div>
                            {this.props.text === undefined ? 'Loading...' : this.props.text}
                        </div>
                    )}
                </div>
            </div>
        );
    }
};
