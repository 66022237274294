import AppConfig from '../../components/config/Config';
import { DashboardData } from './ResponseTypes';
import afetch from './../AuthenticatedFetch';

const DashboardAPI = {
    get() {
        const url = `${AppConfig.Settings.api.baseUri}/publicapi/dashboard`;
        return afetch.request<null, DashboardData>(
            url,
            'GET',
            null,
            'Unable to get dashboard data'
        );
    }
};

export default DashboardAPI;
