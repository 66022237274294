/**
 * REDUX Reducer for Contact Management
 * For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
 */

import update from 'immutability-helper';
import { Reducer, Action } from 'redux';

import { exhaustiveCheck } from '..';
import { defaultState, ContactAndListManagementState } from './States';
import { KnownAction } from './Actions';

export const reducer: Reducer<ContactAndListManagementState | undefined> = (
    state: ContactAndListManagementState | undefined,
    incomingAction: Action
) => {
    if (state == undefined) return defaultState;

    /** Limit incoming actions to known defined actions */
    const action = incomingAction as KnownAction;

    switch (action.type) {
        /** Pre requesting groups*/
        case 'CONTACTS_STORE.LISTS.REQUEST.INIT':
            return update(state, {
                listMgtState: {
                    lists: { $set: [] },
                    loaded: { $set: false }
                }
            });

        /** Lists request successful */
        case 'CONTACTS_STORE.LISTS.REQUEST.SUCCESS':
            return update(state, {
                listMgtState: {
                    lists: { $set: action.lists },
                    loaded: { $set: true }
                }
            });

        /** Pre requesting contacts */
        case 'CONTACTS_STORE.LIST.CONTACTS.REQUEST.INIT':
            const selectedList = state.listMgtState.lists.filter((l) => {
                return l.id === action.listId;
            })[0];

            return update(state, {
                contactMgtState: {
                    contacts: { $set: [] },
                    loaded: { $set: false }
                },
                // mark the list as selected
                listMgtState: {
                    // find the matching list
                    selected: { $set: selectedList }
                }
            });

        /***
         * Contact request successful.
         */
        case 'CONTACTS_STORE.LIST.CONTACTS.REQUEST.SUCCESS':
            return update(state, {
                contactMgtState: {
                    contacts: { $set: action.contacts },
                    loaded: { $set: true }
                }
            });

        /** New group creation start */
        case 'CONTACTS_STORE.LIST.CREATE.INIT':
            return update(state, {
                listMgtState: {
                    creating: { $set: true }
                }
            });

        /** Group creation submission */
        case 'CONTACTS_STORE.LIST.CREATE.SUBMIT':
            return update(state, {
                listMgtState: {
                    creating: { $set: false }
                }
            });

        /** Contact was added to group successfully */
        case 'CONTACTS_STORE.LIST.CONTACT.ADD.SUCCESS':
            return state;

        default:
            exhaustiveCheck(action);
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || defaultState;
};
