import React from 'react';
import styled from 'styled-components';
import moment, { Moment } from 'moment';
import DayPicker from 'react-day-picker';

import 'react-day-picker/lib/style.css';
import theme from '../../css/theme';
import Analytics from '../../services/analytics.service';

interface Props {
    show: boolean;
    onCancel: () => void;
    onConfirm: (from: Moment, to: Moment) => void;
}

interface State {
    from: Date | null;
    to: Date | null;
    enteredTo: Date | null;
    left: string;
    top: string;
}

export default class DateRangePicker extends React.Component<Props, State> {
    element: React.RefObject<HTMLDivElement>;

    state = {
        from: null,
        to: null,
        enteredTo: null,
        left: 'unset',
        top: 'unset'
    } as State;

    constructor(props: Props) {
        super(props);

        this.element = React.createRef();
    }

    componentWillUnmount() {
        document.onclick = null;
    }

    componentDidUpdate(prevProps: Props) {
        if (!prevProps.show && this.props.show) {
            // Opening
            this.setState({
                from: null,
                to: null,
                enteredTo: null
            });

            Analytics.trackEvent('date-range-picker-opened');

            document.onclick = (ev: MouseEvent) => {
                // If the user clicks outside of the date range picker
                if (
                    ev.target &&
                    !(ev.target as HTMLElement).closest('.date-range-picker')
                ) {
                    // Then close it
                    this.props.onCancel();

                    // Stop listening
                    this.unsubscribe();

                    Analytics.trackEvent(
                        'date-range-picker-closed-by-click-outside'
                    );
                }
            };

            const element = this.element.current!;
            const rect = element.getBoundingClientRect();

            // Check whether the element will be off the screen to the right
            if (rect.right > window.innerWidth) {
                // If so, position it as far right as available
                this.setState({
                    left: `${window.innerWidth - rect.width}px`
                });
            }

            // Check whether the element will be off the screen to the bottom
            if (rect.bottom > window.innerHeight) {
                // If so, position it as low as available
                this.setState({
                    top: `${window.innerHeight - rect.height - 26}px`
                });
            }
        }
    }

    unsubscribe = () => {
        document.onclick = null;
    };

    render() {
        if (!this.props.show) {
            return <></>;
        }

        const { from, to, enteredTo } = this.state as any;
        const modifiers = { start: from, end: to };
        const disabledDays = { before: from || new Date() };
        const selectedDays = [from, { from, to: enteredTo }];

        return (
            <Wrapper
                ref={this.element}
                className="date-range-picker"
                style={{ left: this.state.left, top: this.state.top }}
            >
                <DayPicker
                    className="Range"
                    fromMonth={from}
                    firstDayOfWeek={1}
                    selectedDays={selectedDays}
                    disabledDays={disabledDays}
                    modifiers={modifiers}
                    onDayClick={this.handleDayClick}
                    onDayMouseEnter={this.handleDayMouseEnter}
                />
                <div className="actions layout horizontal center-center">
                    <div
                        onClick={this.handleConfirm}
                        className={
                            this.state.from && this.state.to ? 'enabled' : ''
                        }
                    >
                        <span>Confirm</span>
                        <i className="fa fa-check" />
                    </div>
                </div>
            </Wrapper>
        );
    }

    handleConfirm = () => {
        const { from, to } = this.state;

        if (from && to) {
            this.props.onConfirm(moment(from), moment(to));
            this.unsubscribe();

            Analytics.trackEvent('date-range-picker-confirmed');
        }
    };

    handleDayClick = (day: Date) => {
        // No action if clicked before current date
        if (day < new Date()) return;

        const { from, to } = this.state as any;

        if (from && to && day >= from && day <= to) {
            // Clear selection
            this.setState({
                from: null,
                to: null,
                enteredTo: null
            });
        } else if (this.isSelectingFirstDay(from, to, day)) {
            // Select range start
            this.setState({
                from: day,
                to: null,
                enteredTo: null
            });
        } else {
            // Select range end
            this.setState({
                to: day,
                enteredTo: day
            });
        }
    };

    handleDayMouseEnter = (day: Date) => {
        const { from, to } = this.state as any;
        if (!this.isSelectingFirstDay(from, to, day)) {
            this.setState({
                enteredTo: day
            });
        }
    };

    isSelectingFirstDay = (from: Date, to: Date, day: Date) => {
        const isBeforeFirstDay = from && day < from;
        const isRangeSelected = from && to;
        return !from || isBeforeFirstDay || isRangeSelected;
    };
}

const Wrapper = styled.div`
    position: fixed;
    z-index: 1;
    background: white;
    border-radius: 5px;
    box-shadow: 0px 0px 10px 0px #444;

    .DayPicker-wrapper:focus {
        outline: 0 !important;
    }

    .DayPicker-Day {
        :focus {
            outline: 0 !important;
        }

        &.DayPicker-Day--selected {
            border-radius: 0 !important;

            &.DayPicker-Day--start {
                border-top-left-radius: 50% !important;
                border-bottom-left-radius: 50% !important;
            }

            &.DayPicker-Day--end {
                border-top-right-radius: 50% !important;
                border-bottom-right-radius: 50% !important;
            }
        }
    }

    .actions > div {
        margin: -10px 0 5px 0;
        padding: 5px;
        opacity: 0.4;

        &.enabled {
            color: ${theme.colours.green};
            cursor: pointer;
            opacity: 1;
        }

        > span {
            margin-right: 2px;
            font-size: 11px;
        }
    }
`;
