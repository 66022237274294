/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';
import PubSub from 'pubsub-js';
import ContactsApi from '../../api/contacts/Contacts';
import styled from 'styled-components';
import { Button, Modal } from 'react-bootstrap';
import { Subscribe } from '../../unstated-fork/unstated';
import TeamsContainer from '../../state-containers/teams.container';
import { toast } from 'react-toastify';

import * as ContactActionCreators from '../../store/contacts/ActionCreators';
import * as ContactStates from '../../store/contacts/States';
import { ApplicationState } from '../../store';
import { nameofFactory, PubSubTopics } from '../../constants';
import { Spinner } from '../ui-components/Spinner';
import InternalTracker from '../../InternalTracker';

import PersonCircle from '../../components/svg/person-circle';
import PeopleCircle from '../../components/svg/people-circle';
import * as ContactModels from '../../store/contacts/Models';

export type ContactListsProps = ContactStates.ContactAndListManagementState &
    typeof ContactActionCreators.actionCreators;

interface LocalState {
    newGroupName: string;
    editedSharingListId: number | null;
    addingOrRemovingTeamIdFromList: number | null;
    autoSelectedGroupFromUrl: boolean;
}

const nameof = nameofFactory<LocalState>();

export class ContactLists extends React.Component<
    ContactListsProps,
    LocalState
> {
    private teamUpdatedSub?: string;

    constructor(props) {
        super(props);
        this.state = {
            newGroupName: '',
            editedSharingListId: null,
            addingOrRemovingTeamIdFromList: null,
            autoSelectedGroupFromUrl: false
        };
        this.handleFieldContentChange = this.handleFieldContentChange.bind(
            this
        );
        this.handleFieldKeyDown = this.handleFieldKeyDown.bind(this);
    }

    componentDidMount() {
        this.refresh();

        this.teamUpdatedSub = PubSub.subscribe(PubSubTopics.teamIdUpdated, () =>
            this.refresh()
        );
    }

    componentWillUnmount() {
        PubSub.unsubscribe(this.teamUpdatedSub);
    }

    componentWillReceiveProps() {
        let groupIdFromUrl = window.location.href.split("/lists/")[1];

        if (groupIdFromUrl && this.props.listMgtState.loaded && !this.state.autoSelectedGroupFromUrl) {
            this.props.getContactsInList(
                parseInt(groupIdFromUrl)
            )
            this.setState({
                autoSelectedGroupFromUrl: true
            })
        }
    }

    refresh() {
        this.props.getLists();
    }

    /** Fires when the user presses a key down on the input field */
    handleFieldKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.key === 'Enter') this.submitList();
    }

    /** Fires when the user updates an input element's field content and updates local draft state */
    handleFieldContentChange(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ ...this.state, [e.target.name]: e.target.value });
    }

    createNewGroupRow() {
        return (
            <div
                className="new-list"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginTop: 12
                }}
            >
                <div
                    style={{
                        flexBasis: 'calc(100% - 72px)'
                    }}
                >
                    <input
                        type="text"
                        className={
                            'form-control' +
                            (!this.state.newGroupName ? ' pulse no-size' : '')
                        }
                        value={this.state.newGroupName}
                        name={nameof('newGroupName')}
                        onChange={this.handleFieldContentChange}
                        onKeyDown={this.handleFieldKeyDown}
                        placeholder="Enter new group name"
                        id="create-new-group-input"
                    />
                </div>
                <div>
                    <Button
                        bsSize="small"
                        bsStyle="success"
                        onClick={this.submitList}
                        className={
                            this.state.newGroupName ? ' pulse no-size' : ''
                        }
                        id="create-new-group-save-btn"
                    >
                        Create
                    </Button>
                </div>
            </div>
        );
    }

    isGroupSelected(id: number | undefined) {
        return this.props.listMgtState.selected.id === id ? 'selected' : '';
    }

    submitList = () => {
        const isDuplicate = this.props.listMgtState.lists.find(l => l.name.toLowerCase() === this.state.newGroupName.toLowerCase());
        if (isDuplicate) {
            toast.error("A group with that name already exists");
            return;
        }
        this.props.newListSubmit(this.state.newGroupName);
        this.setState({ newGroupName: '' });
        this.props.listMgtState.creating = false;
        InternalTracker.trackEvent("", {
            category: 'Lists',
            action: 'List Created'
        });
    };

    removeList = (id) => {
        this.props.removeList(id);
        InternalTracker.trackEvent("", {
            category: 'Lists',
            action: 'List Removed'
        });
    };

    finalizeEditState = (teamId?: number, teamName?: string, listId?: number, added?: boolean, lists?: ContactModels.ContactList[]) => {
        // this.props.getLists();

        if (teamId && listId && lists && teamName) {
            toast.success("Sharing preferences updated");

            let oldLists = JSON.parse(JSON.stringify(lists));

            for (let i = 0; i < oldLists.length; i++) {
                if (oldLists[i].id === listId) {
                    let teamRelationships = oldLists[i].teamRelationships;
                    if (added) {
                        teamRelationships.push({
                            id: teamId + '',
                            name: teamName, // TODO NAME
                            count: 0
                        });
                    } else {
                        for (let j = 0; j < teamRelationships.length; j++) {
                            if (teamRelationships[j].id === (teamId + "")) {
                                teamRelationships.splice(j, 1);
                                break;
                            }
                        }   
                    }
                    oldLists[i].teamRelationships = teamRelationships;
                    break;
                }
            }
    
            this.props.updateState(oldLists);
        } else {
            toast.error("Failed to update sharing preferences")
        }

        this.setState({
            addingOrRemovingTeamIdFromList: null
        })
    }

    renderSharingModal = (list: ContactModels.ContactList, lists: ContactModels.ContactList[]) => {
        return (
            <Subscribe to={[TeamsContainer]}>
                {(teamsContainer: TeamsContainer) => {
                    return (
                        <Modal
                            show={true}
                            onHide={() => {
                                this.setState({
                                    editedSharingListId: null
                                })
                            }}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    Share '{list.name}' group
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <SharingModalWrapper>
                                    <p>
                                        Tap on the below teams to share / deshare this group. Everyone in the selected teams will have access to the contacts in this group.
                                    </p>
                                    <div className="teams">
                                        {teamsContainer.state.teams.map(team => {
                                            // @ts-ignore
                                            let isSharing = list.teamRelationships?.map(item => item.id).indexOf(team.id.toString()) !== -1;
                                            let isLoading = team.id === this.state.addingOrRemovingTeamIdFromList;
                                            return (
                                                <div 
                                                    className="team" 
                                                    data-name={team.name}
                                                    data-sharing={isSharing}
                                                    onClick={ async () => {
                                                        if (!this.state.addingOrRemovingTeamIdFromList) {
                                                            this.setState({
                                                                addingOrRemovingTeamIdFromList: team.id
                                                            }, () => {
                                                                if (isSharing) {
                                                                    ContactsApi
                                                                        .removeGroupFromTeam(list.id, team.id)
                                                                        .then(() => { this.finalizeEditState(team.id, team.name, list.id, false, lists) })
                                                                        .catch(() => { this.finalizeEditState(); })
                                                                } else {
                                                                    ContactsApi
                                                                        .addGroupToTeam(list.id, team.id)
                                                                        .then(() => { this.finalizeEditState(team.id, team.name, list.id, true, lists) })
                                                                        .catch(() => { this.finalizeEditState(); })
                                                                }
                                                            });
                                                        }
                                                    }}
                                                    style={{
                                                        opacity: !this.state.addingOrRemovingTeamIdFromList || this.state.addingOrRemovingTeamIdFromList === list.id ? 1 : 0.4
                                                    }}
                                                >
                                                    <span>{team.name}</span>
                                                    { (isSharing && !isLoading) &&
                                                        <i className="fas fa-check-square"></i>
                                                    }
                                                    { (!isSharing && !isLoading) &&
                                                        <i className="far fa-square"></i>
                                                    }
                                                    { (isLoading) &&
                                                        <i className="fa fa-circle-o-notch fa-spin"></i>
                                                    }
                                                </div>
                                            )
                                        })}
                                        <div 
                                            className="team"
                                            onClick={() => {
                                                window.open("/support/organisation-groups")
                                            }}
                                        >
                                            <span>Organization-wide</span>
                                            <i className="fas fa-at"></i>
                                        </div>
                                    </div>
                                </SharingModalWrapper>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button 
                                    onClick={() => {
                                        this.setState({
                                            editedSharingListId: null
                                        })
                                        this.refresh();
                                        InternalTracker.trackEvent("", {
                                            category: 'Lists',
                                            action: 'Contact List Team Sharing Updated'
                                        });
                                    }}
                                    id="save-organisation-sharing-prefs-btn"
                                >
                                    Finish Editing
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    )
                }}
            </Subscribe>
        )
    }

    /**
     * Renders the list
     */
    render() {
        const listState = this.props.listMgtState;
        const editedList = this.state.editedSharingListId ? listState?.lists.find(list => list.id === this.state.editedSharingListId) : null;

        return (

            <Wrapper>
                <Spinner text="Loading Lists" hide={listState.loaded} />
                {editedList &&
                    this.renderSharingModal(editedList, listState.lists)
                }
                {listState.loaded && (
                    <table
                        className="table table-striped table-hover"
                        style={{
                            marginBottom: 0
                        }}
                    >
                        <thead>
                            <tr>
                                <th>Name</th>
                                {/* <th>Contacts</th> */}
                                <th>Sharing</th>
                            </tr>
                        </thead>
                        <tbody>
                            {listState.lists.map((list, i) => {
                                return (
                                    <tr
                                        key={list.id}
                                        className={this.isGroupSelected(
                                            list.id
                                        ) + " group-item"}
                                        onClick={ async () => {
                                            this.props.getContactsInList(
                                                list.id
                                            )
                                            // this.props.listMgtState.selected = list;
                                        }}
                                        style={{
                                            cursor: 'pointer'
                                        }}
                                        data-name={list.name}
                                        data-id={list.id}
                                        data-share-count={list.teamRelationships?.length}
                                    >
                                        <td className="align-middle" colSpan={i === 0 ? 2 : 1}>
                                            {list.name} ({list.count})
                                        </td>
                                        { (i !== 0) &&
                                        <td className="align-middle options">
                                            { (list.id !== 0) &&
                                                <Button
                                                    bsStyle="secondary"
                                                    onClick={() => {
                                                        this.setState({
                                                            editedSharingListId: list.id
                                                        })
                                                    }}
                                                    data-group-sharing-id={list.id}
                                                >
                                                    { (list.teamRelationships?.length === 0) ?
                                                        <PersonCircle /> :
                                                        <PeopleCircle />
                                                    }
                                                    { (list.teamRelationships?.length === 0) ?
                                                        <span>Just Me</span> :
                                                        <span>{list.teamRelationships?.length} Teams</span>
                                                    }
                                                    <i className="fas fa-pen"></i>
                                                </Button>
                                            }
                                        </td>
                                        }
                                        <td className="align-middle options">
                                            { (list.id !== 0) &&
                                                <Button
                                                    bsStyle="danger"
                                                    onClick={() => {
                                                        this.removeList(list.id)
                                                    }}
                                                >
                                                    <i className="fas fa-trash-alt"></i>
                                                </Button>
                                            }
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                )}
                {this.props.listMgtState.creating && this.createNewGroupRow()}
            </Wrapper>
        );
    }
}

const SharingModalWrapper = styled.div`
    .teams {

        .team {
            padding: 8px;
            border-bottom: 1px solid #ddd;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;

            &[data-sharing="true"] {
                background-color: #ddd;
            }

            i {
                font-size: 20px;
            }

            &:last-child {
                border-bottom: 0;
            }
        }
    }
`;

const Wrapper = styled.div`
    overflow: auto;

    td, th {
        &:nth-child(1) {
            width: calc(100% - 52px - 120px);
            word-break: break-all;
        }

        // &:nth-child(2) {
        //     width: 74px;
        // }

        &:nth-child(2) {
            width: 120px;
        }

        &:last-child {
            width: 44px;
            padding-left: 8px;
        }
    }

    .options {
        text-align: right;

        button {
            border: none;
            display: inline-flex;
            align-items: center;

            &.btn-secondary {
                padding: 5px;
                width: 104px;
                display: inline-flex;
                align-items: center;
                justify-content: space-between;

                span {
                    padding: 0 4px;
                    font-size: 0.9em;
                }

                svg {
                    width: 18px;
                    height: 18px;
                }
            }

            &:not(.btn-secondary) {
                padding: 8px;
            }
        }
    }
`;

export default connect(
    (state: ApplicationState) =>
        state.contactManagement as ContactStates.ContactAndListManagementState,
    ContactActionCreators.actionCreators
)(ContactLists);
