import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { RouteComponentProps } from 'react-router';
import { Button } from 'react-bootstrap';

import EventApi from '../../api/events/Events';
import Splash from '../../components/ui-components/Splash';
import { PendingEventSet } from '../../api/events/ResponseTypes';
import { DateFormats } from '../../constants';
import PendingEventsModal from '../../pages/mobile/pendingeventsmodal';

interface State {
    loading: boolean;
    eventSets: PendingEventSet[];
    selected: PendingEventSet | undefined;
}

export default class PendingEvents extends React.Component<
    RouteComponentProps<{ eventSetId: string }>,
    State
> {
    state = {
        loading: true,
        eventSets: [],
        selected: undefined
    } as State;

    async componentDidMount() {
        const eventSetId = this.props.match.params.eventSetId;

        const eventSets = await EventApi.getPendingEventSets();

        this.setState({
            eventSets,
            selected: eventSets.find((s) => s.id === eventSetId),
            loading: false
        });
    }

    handleOpen = (eventSet: PendingEventSet) => {
        this.setState({
            selected: eventSet
        });
    };

    handleClose = () => {
        this.setState({
            selected: undefined
        });
    };

    handleConfirm = () => {
        const eventSet = this.state.selected!;
        this.setState({
            loading: false,
            eventSets: this.state.eventSets.map((ev) =>
                ev.id === eventSet.id ? { ...eventSet, accepted: true } : ev
            ),
            selected: undefined
        });
    };

    render() {
        if (this.state.loading) {
            return (
                <div>
                    <Splash />
                </div>
            );
        } else if (this.state.eventSets.length) {
            return (
                <Wrapper className="layout vertical">
                    <h5>
                        <span>
                            Open each row to view the pending events and add
                            them to your schedule
                        </span>
                    </h5>
                    <div className="flex">{this.renderBody()}</div>
                    <PendingEventsModal
                        eventSet={this.state.selected}
                        onClose={this.handleClose}
                        onConfirm={this.handleConfirm}
                    />
                </Wrapper>
            );
        } else {
            return (
                <h5 style={{ marginTop: '70px', textAlign: 'center' }}>
                    You do not have any pending events
                </h5>
            );
        }
    }

    renderBody() {
        return this.state.eventSets.map((eventSet) => (
            <Card key={eventSet.id}>
                <div className="header m-b-xs">
                    {eventSet.pendingEvents[0].title}
                </div>
                <div className="layout horizontal">
                    <div className="vertical layout">
                        <div className="header m-b-xs">Name:</div>
                        <div className="header m-b-xs">Organization:</div>
                        <div className="header m-b-xs">Start:</div>
                        <div className="header m-b-xs">Status:</div>
                    </div>
                    <div
                        className="vertical layout flex"
                        style={{ overflow: 'hidden' }}
                    >
                        <div className="m-b-xs overflow-ellipsis">
                            {eventSet.metadata.createdByFullName}
                        </div>
                        <div className="m-b-xs overflow-ellipsis">
                            {eventSet.metadata.createdByOrganisationName}
                        </div>
                        <div className="m-b-xs overflow-ellipsis">
                            {moment(eventSet.pendingEvents[0].start).format(
                                DateFormats.ShortDateWithDay
                            )}
                        </div>
                        <div className="m-b-xs">
                            {eventSet.accepted ? (
                                <span className="response accepted">
                                    Added to schedule
                                </span>
                            ) : (
                                <span className="response">Pending</span>
                            )}
                        </div>
                    </div>
                    <div>
                        <Button
                            bsStyle="primary"
                            onClick={() => this.handleOpen(eventSet)}
                        >
                            Open
                        </Button>
                    </div>
                </div>
            </Card>
        ));
    }
}

const Wrapper = styled.div`
    height: calc(100vh - 100px);

    > h5 {
        margin: 0 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid lightgray;

        > span {
            display: block;
            margin: 5px 0;
            line-height: 20px;
            text-align: center;
        }
    }

    > div.flex {
        overflow-x: hidden;
        overflow-y: auto;
    }
`;

const Card = styled.div`
    margin: 5px 10px 0px;
    padding-top: 5px;
    padding-bottom: 10px;
    border-bottom: 1px solid lightgray;

    > span {
        display: inline-block;
        margin: 8px 0 8px;
        font-size: 11px;
        font-style: italic;
    }

    > div.header:first-child {
        width: 100%;
        font-size: 14px;
        text-align: center;
    }

    .header {
        display: inline-block;
        margin-right: 20px;
        font-weight: bold;
    }

    .response {
        color: #ffb606;

        &.accepted {
            color: #62cb31;
        }
    }

    button {
        margin-top: 20px;
    }
`;
