/* eslint-disable */
import React from 'react';

import history from '../../history';
import TaskListStage1 from '../svg/TaskListStage1';
import TaskListStage2 from '../svg/TaskListStage2';
import TaskListStage3 from '../svg/TaskListStage3';
import TaskListStage4 from '../svg/TaskListStage4';
import SettingsApi from '../../api/settings/Settings';
import ProfileApi from '../../api/profile/Profile';
import CompanyAPI from '../../api/company/Company';
import { Setting } from '../../api/settings/ResponseTypes';
import DashboardContainer from '../../state-containers/dashboard.container';
import OrganisationContainer from '../../state-containers/organisation.container';
import {
    UserType,
    UserTypeContainer
} from '../../state-containers/user-type.container';
import ShareModal from '../ui-components/ShareModal';

import { ApplicationState } from '../../store';
import { connect } from 'react-redux';

import '../../css/TaskList.css';
import TeamsContainer from '../../state-containers/teams.container';

import * as ProfileState from '../../store/Profile';
import * as EventsState from '../../store/Events';
import { Subscribe } from '../../unstated-fork/unstated';
import InternalTracker from 'src/InternalTracker';
import { ConfigItem } from '../layout/Navigation';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import Utilities from 'src/Utilities';
import theme from 'src/css/theme';

type Stage = {
    title: string;
    logo: any;
    tasks: TaskItem[];
};

type NextStep = {
    completed: string;
    next: string;
    btn: string;
    hideFlag: string;
    onClick: () => void;
}

type TaskItem = {
    complete: boolean;
    notActuallyComplete?: boolean;
    title: string;
    details: string;
    icon: string;
    link?: string;
    onClick?: () => void;
    comingSoon?: boolean;
    next?: boolean;
    loadImageIfComplete?: string;
};

interface Props {
    dashboardContainer?: DashboardContainer;
    organisationContainer?: OrganisationContainer;
    teamsContainer?: TeamsContainer;
    userType?: UserTypeContainer;
    userProfileState: ProfileState.UserProfileModel;
    view?: string;
    isBasicProfileSetUp?: (string?) => void;
    lastNextTitle?: string;
}

const BASE_SHORTCUTS: ConfigItem[] = [
    {
        to: '/offers',
        id: 'menuOffersOption',
        icon: 'fa fa-briefcase',
        label: 'View Offers'
    },
    {
        to: '/offers#create',
        id: 'menuOffersCreateOption',
        icon: 'fas fa-plus',
        label: 'Send Offer',
        sub: true
    },
    {
        to: '/availability',
        id: 'menuAvailabilityOption',
        icon: 'fa fa-calendar-check',
        label: 'Availability',
    },
    {
        to: '/invite',
        id: 'menuInviteOption',
        icon: 'fas fa-envelope-open-text',
        label: 'Invite',
        sub: true
    },
    {
        to: '/schedule',
        id: 'menuSchedule',
        icon: 'fa fa-calendar-alt',
        label: 'Schedule',
        sub: true
    },
    {
        onClick: () => {
            if ((window as any).openNotificationsPanel) {
                (window as any).openNotificationsPanel();
            }
        },
        to: "",
        id: 'menuNotificationsOption',
        icon: 'far fa-bell',
        label: 'Notifications'
    }
    // {
    //     to: '/ratings',
    //     id: 'menuRatings',
    //     icon: 'fas fa-star-half-alt',
    //     label: 'Ratings',
    // },
    // {
    //     to: '/lists',
    //     id: 'menuListsOption',
    //     icon: 'fa fa-list',
    //     label: 'Groups',
    //     sub: true
    // },
    // {
    //     to: '/files',
    //     id: 'menuFilesOption',
    //     icon: 'fas fa-file',
    //     label: 'Files',
    //     hidden: this.state ? !this.state.showFiles : true
    // },
    // {
    //     to: '/profile',
    //     id: 'menuProfileOption',
    //     icon: 'far fa-address-card',
    //     label: 'Profile'
    // },
    // {
    //     to: '/agencies',
    //     id: 'menuAgenciesOption',
    //     icon: 'fa fa-briefcase',
    //     label: 'My Agencies',
    //     sub: true
    // },
    // {
    //     to: '/organisation',
    //     id: 'menuOrganisationOption',
    //     icon: 'fa fa-sitemap',
    //     label: 'Organisation'
    // },
    // {
    //     to: '/organisation/members',
    //     id: 'menuOrganisationColleagueOption',
    //     icon: 'fas fa-users',
    //     label: 'Colleagues',
    //     sub: true
    // },
    // {
    //     to: '/organisation/locations',
    //     id: 'menuOrganisationLocationOption',
    //     icon: 'fas fa-location-arrow',
    //     label: 'Locations',
    //     sub: true,
    //     onlyOrgAdmin: true
    // },
    // {
    //     to: '/organisation/subscriptions',
    //     id: 'menuOrganisationSubscriptionOption',
    //     icon: 'fas fa-wallet',
    //     label: 'Subscriptions',
    //     sub: true,
    //     onlyOrgAdmin: true
    // },
    // {
    //     to: '/support',
    //     id: 'menuSupportOption',
    //     icon: 'fa fa-question',
    //     label: 'Support'
    // },
    // {
    //     to: '/whats-new',
    //     id: 'menuNewsOption',
    //     icon: 'fa-solid fa-newspaper',
    //     label: 'What\' new',
    //     hideOnDesktop: true
    // },
    // {
    //     to: '/settings',
    //     id: 'menuSettingsOption',
    //     icon: 'fa fa-cog',
    //     label: 'Settings'
    // },
    // {
    //     to: '/settings#presets',
    //     id: 'menuSettingsPresetsOption',
    //     icon: 'fas fa-clock',
    //     label: 'Time Presets',
    //     sub: true
    // },
    // {
    //     to: '/settings#skipweekends',
    //     id: 'menuSettingsSkipWeekendsOption',
    //     icon: 'fas fa-forward',
    //     label: 'Skip Weekends',
    //     sub: true
    // }
]

interface State {
    loading: boolean;
    shareModal: boolean;
    hidden: boolean;
    hideCompletedTasks: boolean;
    freshOrgDataLoaded: boolean;
}

class TaskList extends React.Component<Props, State> {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            shareModal: false,
            hidden: false,
            hideCompletedTasks: true,
            freshOrgDataLoaded: false
        } as State;

    }

    componentDidMount(): void {
        (window as any).showNextTaskIfAny = () => {
            this.setState({
                freshOrgDataLoaded: true
            })
        }
    }

    render() {

        const { dashboardContainer: container } = this.props;
        const user = this.props.userProfileState; 
        const organisationContainer = this.props.organisationContainer;
        const { teamsContainer } = this.props;

        let stages: Stage[] = [];

        if (container && container.state && container.state.data && container.state.loaded && organisationContainer?.state.loaded) {

            stages = [
                {
                    title: 'Lets Do The Basics',
                    logo: TaskListStage1,
                    tasks: [
                        {
                            title: 'Verify Email Address',
                            details: 'Email verified',
                            icon: 'fas fa-at',
                            complete: true
                        },
                        {
                            title: /*container.state.data!.profilePhotoExists*/ (user.phoneNumber && user.headline) ? (user.firstName + " " + user.lastName) : 'Complete your Profile',
                            details: /*container.state.data!.profilePhotoExists*/ (user.phoneNumber && user.headline) ? 'Click to edit Profile' : 'Enter your mobile phone number so we can verify you (nobody else sees this) and picture so contacts can easily recognise you.',
                            link: '/profile',
                            icon: 'fas fa-user',
                            complete: (user.phoneNumber !== "" && user.headline !== "") || false, /* container.state.data!.profilePhotoExists*/
                            notActuallyComplete: !container.state.data!.profilePhotoExists,
                            loadImageIfComplete: "profile"
                        },
                        {
                            title: container!.hasOrganisation ? (user.organisationName || "") : 'Create organization',
                            details: container!.hasOrganisation ? 'Click to edit Organization' : 'Enter your organization’s details so your workers can easily find you',
                            link: '/organisation',
                            icon: 'fas fa-sitemap',
                            complete: container!.hasOrganisation,
                            loadImageIfComplete: "org"
                        },
                        {
                            title: 'Time Presets',
                            details: 'Set different time presets to suit your organizations working patterns or shifts, e.g. "Morning only" 06:30am to 10:30am. Use time presets to search for available contacts.',
                            link: '/settings/stpresets',
                            icon: 'fas fa-clock',
                            complete: container.state.data!.timePresetsSet
                        },
                        {
                            title: 'Invite Colleagues',
                            details: 'Invite colleagues to share contacts, availability, and offers with.',
                            link: '/organisation/members',
                            icon: 'fas fa-users',
                            complete: container.state.data?.members ? container.state.data!.members.length > 1 : false
                        },
                        {
                            title: 'Invite your contacts',
                            details: (container.state.data!.contactsReceiving || !!container.state.data!.contactsPending) 
                                ? ('You have ' + (container.state.data!.contactsReceiving + container.state.data!.contactsPending) + ' contacts' 
                                    + ( (container.state.data!.contactsPending) ? (" (" + (container.state.data!.contactsPending) + " pending)" ) : '')) + 
                                    ', add any contacts you know from any agencies or direct for more choice.'
                                : 'Add workers to share their availability with you',
                            link: '/invite',
                            icon: 'fas fa-address-book',
                            complete: !!container.state.data!.contactsReceiving || !!container.state.data!.contactsPending
                        }
                    ]
                },
                {
                    title: 'Start Hiring',
                    logo: TaskListStage2,
                    tasks: [
                        {
                            title: 'Add your agencies',
                            details: container.state.data!.agencies !== 0 ? 'You have ' + container.state.data!.agencies + ' agencies. Click to add more' : 'Add any agencies your organization deals with, they do not need to use updatedge. Agency contact details are used when confirming bookings with workers & mutual agencies you chose, to email assignment confirmation requests.  Agencies manage the safeguarding, right to work, reference checks, bill and pay etc.',
                            link: '/agencies',
                            icon: 'fas fa-building',
                            complete: container.state.data!.agencies !== 0
                        },
                        {
                            title: 'Send offers',
                            details: !!container.state.data!.offers ? 'You have sent ' + container.state.data!.offers + ' offers' : 'Tell us what you need, when you need, and who you need, that’s it',
                            link: '/offers',
                            icon: 'fas fa-briefcase',
                            complete: !!container.state.data!.offers
                        },
                        {
                            title: 'Chat with contacts',
                            details: 'Use instant chat with your contacts when you want to check something.',
                            onClick: () => {
                                document.getElementById("chat-btn")?.click();
                                SettingsApi.update(Setting.Chats_HaveBeenViewed, 'true');
                                container.handleDataUpdate({ chatsViewed: true });
                            },
                            icon: 'fas fa-comments',
                            complete: container.state.data!.chatsViewed
                        },
                        {
                            title: 'Confirm offers',
                            details: 'Let the work begin by confirming offers',
                            link: '/offers',
                            icon: 'fas fa-user-check',
                            complete: container.state.data?.futureOffers?.applied === 0
                        },
                        {
                            title: 'Give ratings & reviews',
                            details: 'Help your contacts, let them know how they are doing. Add public reviews for all to see. Add private reviews only you see, e.g. £245 from agency N, £230 from agency Y',
                            onClick: () => {
                                window.open("/availability")
                                // alert("This feature is not quite ready yet, pleas check back later");
                            },
                            icon: 'fas fa-star',
                            complete: user.ratingsGiven && user.ratingsGiven.length !== 0
                        }
                    ]
                },
                {
                    title: 'Discover all Features',
                    logo: TaskListStage3,
                    tasks: [
                        {
                            title: 'Add lists',
                            details: 'Group your contacts into different lists based on their skills or qualifications to search availability by lists',
                            onClick: () => {
                                history.push(`/lists`);
                                SettingsApi.update(Setting.Lists_HaveBeenViewed, 'true');
                                container.handleDataUpdate({ listsViewed: true });
                            },
                            icon: 'fa fa-list',
                            complete: container.state.data!.listsViewed
                        },
                        {
                            title: 'Schedule',
                            details: 'Easily see your historical and upcoming events in daily, weekly, monthly and agenda view with all your offers and contacts',
                            onClick: () => {
                                history.push(`/schedule`);
                                SettingsApi.update(Setting.Schedule_HaveBeenViewed, 'true');
                                container.handleDataUpdate({ scheduleViewed: true });
                            },
                            icon: 'fas fa-calendar-alt',
                            complete: container.state.data!.scheduleViewed
                        },
                        {
                            title: 'Availability Printing',
                            details: 'Print out availability for more convenience',
                            onClick: () => {
                                history.push(`/availability/print`);
                                SettingsApi.update(Setting.AvailabilityPrinting_HaveBeenViewed, 'true');
                                container.handleDataUpdate({ availabilityPrintingViewed: true });
                            },
                            icon: 'fas fa-print',
                            complete: container.state.data!.availabilityPrintingViewed
                        },
                        {
                            title: 'Availability Alerts',
                            details: 'Adjust when you want to get notifications',
                            onClick: () => {
                                history.push(`/settings/alerts`);
                                SettingsApi.update(Setting.Alerts_HaveBeenViewed, 'true');
                                container.handleDataUpdate({ alertsSet: true });
                            },
                            icon: 'fas fa-bell',
                            complete: container.state.data!.alertsSet
                        },
                        {
                            title: 'Get your first rating',
                            details: 'Encourage your contacts to rate & review your organization',
                            onClick: () => {
                                // alert("This feature is not quite ready yet, pleas check back later");
                            },
                            icon: 'fas fa-star',
                            complete: user.ratings && user.ratings.length !== 0
                        },
                        {
                            title: 'Share with friends',
                            details: 'Get rewards in the future for each person you refer who starts using updatedge',
                            onClick: () => {
                                this.setState({ shareModal: true })
                            },
                            icon: 'fas fa-share-alt',
                            complete: false
                        }
                    ]
                },
                {
                    title: 'Grow Bigger',
                    logo: TaskListStage4,
                    tasks: [
                        {
                            title: 'Add more locations',
                            details: 'Attach different locations for offers if you have multiple locations',
                            link: '/organisation/locations',
                            icon: 'fas fa-compass',
                            complete: false
                        },
                        {
                            title: 'Invite more contacts',
                            details: 'Invite more contacts to share their latest availability with your organization for greater choice.',
                            link: '/invite',
                            icon: 'fas fa-address-book',
                            complete: false
                        },
                        {
                            title: 'Create new teams',
                            details: 'Set up different teams for colleagues in other departments to share contacts offers and schedules.',
                            link: '/organisation/teams',
                            icon: 'fas fa-users',
                            complete: false,
                        },
                        {
                            title: 'Discover Contacts',
                            details: 'Search for and add new contacts to your network.',
                            icon: 'fas fa-globe-americas',
                            complete: false,
                            comingSoon: true,
                            onClick: () => {
                                alert("This feature is not quite ready yet, pleas check back later");
                            },
                        },
                        {
                            title: 'Give us Feedback',
                            details: 'Let us know about any issues you are having, or if you have any feature requests to add for you',
                            icon: 'fas fa-comment-alt',
                            complete: false,
                            link: 'support'
                        }
                    ]
                }
            ]
    
            if (organisationContainer?.state?.organisation ? (!organisationContainer?.state?.organisation?.isHirer) : (this.props?.userType?.state?.type === UserType.Agency)) {
                stages[1].tasks.splice(0, 1);
            }

        } else {

        }

        let foundNext = false;
        let nextTitle = "";

        for (let stageKey in stages) {
            let stage = stages[stageKey];
            for (let i = 0; i < stage.tasks.length; i++) {
                let task = stage.tasks[i];
                if (!task.complete && !task.comingSoon) {
                    stages[stageKey].tasks[i].next = true;
                    nextTitle = stages[stageKey].tasks[i].title
                    foundNext = true;
                    break;
                }
            }
            if (foundNext) {
                break;
            }
        }

        let nextStep: NextStep | null = null;
        let orgSetupFirst = false; // localStorage.getItem("orgSetupFirst") || "true";

        if (nextTitle === "Complete your Profile" && window.location.href.indexOf("/organisation") !== -1 && user.organisationName && !localStorage.getItem("hide:pprofile")) {
            nextStep = {
                completed: "Your organization profile has been set up",
                next: "Set up your personal profile next",
                btn: "Set Up Now",
                hideFlag: "hide:pprofile",
                onClick: () => { history.push("/profile"); } 
            }
        } else if (nextTitle === "Create organization" && window.location.href.indexOf("/profile") !== -1 && !localStorage.getItem("hide:createOrg")) {
            nextStep = {
                completed: "Your profile has been completed",
                next: "Set up your organization next, so your clients can recognise you better",
                btn: "Set up Now",
                hideFlag: "hide:createOrg",
                onClick: () => { history.push("/organisation"); } 
            }
        } else if (nextTitle === "Time Presets" && (window.location.href.indexOf("/organisation") !== -1 || window.location.href.indexOf("/profile") !== -1) && !localStorage.getItem("hide:timepresets")) {
            nextStep = {
                completed: orgSetupFirst ? "Your personal profile has been set up" : "Your organization profile has been set up",
                next: "Set up your time presets, to send offers faster",
                btn: "Set Up Now",
                hideFlag: "hide:timepresets",
                onClick: () => { history.push("/settings/stpresets"); } 
            }
        } else if (nextTitle === "Invite Colleagues" && window.location.href.indexOf("/settings/stpresets") !== -1 && !localStorage.getItem("hide:invite")) {
            nextStep = {
                completed: "Your time presets have been saved",
                next: "Send invites to your colleagues, so you can share offers, contacts, and locations",
                btn: "Invite Now",
                hideFlag: "hide:invite",
                onClick: () => { history.push("/organisation/members"); } 
            }
        } else if (nextTitle === "Invite your contacts" && window.location.href.indexOf("/organisation") !== -1 && !localStorage.getItem("hide:contact")) {
            nextStep = {
                completed: "Invite has been sent to your colleague",
                next: "Now let's invite your contacts, so they can share their availability with you",
                btn: "Invite Now",
                hideFlag: "hide:contact",
                onClick: () => { history.push("/invite"); } 
            }
        } else if (nextTitle === "Add your agencies" && window.location.href.indexOf("/invite") !== -1 && !localStorage.getItem("hide:agencies")) {
            nextStep = {
                completed: "Your contact has been invited to share their availability",
                next: "Now let's set up the agencies you work through",
                btn: "Add Now",
                hideFlag: "hide:agencies",
                onClick: () => { history.push("/agencies"); } 
            }
        } else if (nextTitle === "Send offers" && window.location.href.indexOf("/agencies") !== -1 && !localStorage.getItem("hide:offers")) {
            nextStep = {
                completed: "Your agency has been added",
                next: "You can start sending offers, once your contacts have accepted their invites",
                btn: "Send Offers Now",
                hideFlag: "hide:offers",
                onClick: () => { history.push("/offers"); } 
            }
        }

        if (this.props.isBasicProfileSetUp && nextTitle !== this.props.lastNextTitle) {
            this.props.isBasicProfileSetUp(nextTitle)
        }

        if (this.props.view === "fullscreen") {
            if (nextStep && !this.state.hidden && (!window.location.pathname.startsWith("/organisation") || this.state.freshOrgDataLoaded)) {
                return (
                    <div className="task-list-next">
                        <div>
                            <div className="done">
                                <img src="/img/done.png" />
                                <p>{nextStep.completed}</p>
                            </div>
                            <div className="down">
                                <img src="/img/down-arrow.png" />
                            </div>
                            <div className="next">
                                <img src="/img/next.png" />
                                <p>{nextStep.next}</p>
                            </div>
                            <div className="options">
                                <button onClick={() => { 
                                    nextStep?.onClick(); 
                                    if (nextStep !== null) {
                                        this.setState({ hidden: true }, () => {
                                            setTimeout(() => {
                                                this.setState({ hidden: false })
                                            }, 500);
                                        })
                                    }
                                }}>{nextStep.btn}</button>
                                <button onClick={() => { 
                                    if (nextStep !== null) {
                                        localStorage.setItem(nextStep.hideFlag, "true");
                                        this.setState({ hidden: true }, () => {
                                            setTimeout(() => {
                                                this.setState({ hidden: false })
                                            }, 500);
                                        })
                                    }
                                }}>Later</button>
                            </div>
                        </div>
                    </div>
                )
            } else {
                return null;
            }
        }

        return (
            <div className="hpanel">
            <div className="task-list panel-body">
                { document.documentElement.clientWidth < 550 && 
                    <React.Fragment>
                        <main>
                            <div className='shortcuts'>
                                { BASE_SHORTCUTS.map(link => {
                                    return (
                                        <NavLink
                                            to={link.to || "#"}
                                            onClick={() => {
                                                if (link.onClick) {
                                                    link.onClick();
                                                }
                                            }}
                                        >
                                            <i className={link.icon} />
                                            <p>{link.label}</p>
                                        </NavLink>
                                    )
                                }) }
                            </div>
                        </main>
                    </React.Fragment>
                }
                <header>
                    <div>
                        <h1>Welcome to Updatedge</h1>
                        <p>Find all features from this page. Please contact us if you get stuck, or have feedback. We really want to hear from you ....even if you don't like something, just tell us, so we can fix it!</p>
                        <a href="mailto:support@updatedge.com">Get Support</a>
                        { (user && user.awaitingFinalisation) &&
                            <button 
                                onClick={() => {
                                    if (window.confirm("Are you sure?")) {
                                        ProfileApi.finaliseUser().then(() => {
                                            toast.success("Profile Finalised");
                                            const logoutBtn = document.getElementById("logout-btn");
                                            if (logoutBtn) {
                                                logoutBtn.click();
                                            }
                                        }).catch((err) => {
                                            toast.error("Failed to finalise profile");
                                        })
                                    }
                                }}
                                style={{
                                    backgroundColor: theme.colours.red2
                                }}
                            >
                                Finalise Profile
                            </button>
                        }
                        <button onClick={() => {
                            this.setState({
                                hideCompletedTasks: !this.state.hideCompletedTasks
                            })
                        }}>{ !this.state.hideCompletedTasks ? "Hide" : "Show" } Completed Tasks</button>
                    </div>
                </header>
                <main>
                    <div className="stages">
                        {stages.map((stage, i) => {
                            return (
                                <div className="stage" key={i}>
                                    <div className="head">
                                        <div>
                                            {i === 0 ? (
                                                <TaskListStage1 />
                                            ) : i === 1 ? (
                                                <TaskListStage2 />
                                            ) : i === 2 ? (
                                                <TaskListStage3 />
                                            ) : i === 3 ? (
                                                <TaskListStage4 />
                                            ) : null}
                                            <h2>{stage.title}</h2>
                                            <label>{i+1}</label>
                                        </div>
                                    </div>
                                    <div className="tasks">
                                        {stage.tasks.map((task, j) => {
                                            if (task.complete && this.state.hideCompletedTasks) {
                                                return null;
                                            }
                                            
                                            return (
                                                <div
                                                    key={j}
                                                    className={"task" + ((task.next) ? ' pulse no-size' : '')}
                                                    onClick={() => {
                                                        InternalTracker.trackEvent("Tasks Item Clicked - " + task.title);
                                                        if (task.link) {
                                                            history.push(task.link);
                                                        } else if (task.onClick) {
                                                            task.onClick();
                                                        }
                                                    }}
                                                >
                                                    {container && task.complete && task.loadImageIfComplete ?
                                                        <div>
                                                            <div className="img">
                                                                <img src={task.loadImageIfComplete === "profile" ? ProfileApi.getProfileImageUrl(container.state.data?.userId || "") : CompanyAPI.getOrganisationProfileImageUrl(container.state.data?.companyId || '') } />
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="icon">
                                                            <i
                                                                className={
                                                                    task.icon
                                                                }
                                                            />
                                                        </div>
                                                    }
                                                    <div className="details">
                                                        <h3>{task.title}</h3>
                                                        <p>{task.details}</p>
                                                    </div>
                                                    {task.complete && !task.notActuallyComplete && (
                                                        <i className="fas fa-check-circle complete" />
                                                    )}
                                                    {task.comingSoon && (
                                                        <span className="coming-soon">Coming Soon</span>
                                                    )}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            )
                        }) }
                    </div>
                </main>
                <ShareModal
                    title="Share with friends"
                    show={this.state.shareModal}
                    onHide={() => { this.setState({ shareModal: false }) }}
                    link='https://updatedge.com'
                    quote='Check out Updatedge at updatedge.com. I use it because ...'
                />
            </div>
            </div>
        );
    }

}

// const connector = connect(
//     (state: ApplicationState) => state.profile,
//     ProfileStore.actionCreators
// );

// export default connector(TaskList);

export default connect(
    (state: ApplicationState) => {
        return {
            userProfileState: state.profile.authenticatedUserProfile
        };
    },
    { ...ProfileState.actionCreators, ...EventsState.actionCreators }
)((props) => (
    <Subscribe to={[DashboardContainer, UserTypeContainer, TeamsContainer, OrganisationContainer]}>
        {(
            dashboard: DashboardContainer,
            userType: UserTypeContainer,
            teams: TeamsContainer,
            organisation: OrganisationContainer,
        ) => (
            <TaskList
                // @ts-ignore
                {...props}
                dashboardContainer={dashboard}
                teamsContainer={teams}
                userType={userType}
                organisationContainer={organisation}
            />
        )}
    </Subscribe>
)) as any;