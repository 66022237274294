/* eslint-disable */
/* eslint-disable @typescript-eslint/ban-ts-ignore */
import React from 'react';
import { ToastType } from 'react-toastify';
import * as Notifications from '../store/Notifications';

import { ApplicationState } from '../store';
import history from '../history';

import 'react-big-calendar/lib/css/react-big-calendar.css';

import ProfileApi from '../api/profile/Profile';
import AuthApi from '../components/auth/Auth';
import AvailabilityAPI from '../api/availability/Availability';

import '../css/schedule.css';
import '../css/patterns.css';
import Utilities from '../Utilities';

import { connect, ConnectedProps } from 'react-redux';

import * as ProfileStore from '../store/Profile';

import '../css/AvailabilityPrint.css';
import FullScreenLoader from '../components/ui-components/FullScreenLoader';

import domtoimage from 'dom-to-image';

interface AvailabilityData {
    events: AvailabilityDay,
    profile: Profile
}

interface AvailabilityDay {
    [key: string]: Event[]
}

export interface Event {
    start: string;
    end: string;
    deleted?: boolean;
    id: string;
    eventTypeId: number;
    overnight?: boolean;
    background?: boolean;
}

interface Profile {
    id: string;
    firstName: string;
    lastName: string;
    verified: boolean;
    lastTimelineUpdateAt: string;
    maxAvailabilityDate?: string;
}

interface Contact {
    profile: Profile,
    events: Event[]
}

interface State {
    data: AvailabilityData[] | null;
    startHour: number| null;
    endHour: number | null;
    startDate: Date | null;
    endDate: Date | null;
    originalTimePreset: string | null;
    generatedBase64: string | null;
    mode: null | 'single' | 'multi';
    singleName: null | string;
    singleId: null | string;
    loading: null | string;
    base64ProfileImage: null | string;
}

interface AvailabilityPrintPdfLocalConfig {
    contacts: string[];
    contactsSelected: string[];
    days: number;
    endHour: number;
    startHour: number;
    start: string;
    originalTimePreset: string;
}

export class AvailabilityPrint extends React.Component<ConnectedProps<typeof connector>,State> {

    state: State = {
        data: null,
        startHour: null,
        endHour: null,
        startDate: null,
        endDate: null,
        originalTimePreset: null,
        generatedBase64: null,
        mode: null,
        singleName: null,
        singleId: null,
        loading: null,
        base64ProfileImage: null
    };

    constructor(props) {
        super(props);
    }

    getDayStart = (dateStr: string, hour: number, end?: boolean) => {
        let date: Date = new Date(dateStr);
        date.setHours(hour);
        date.setMinutes(end ? 59 : 0);
        date.setSeconds(end ? 59 : 0);
        return date;
    }

    async componentDidMount() {

        let token;

        // Worker generating single-page png
        if (window.location.href.indexOf("/external/") !== -1) {

            token = AuthApi.getDecodedToken();
            if (!token || !token.oid) {
                alert("Invalid Token " + token + " " + (token ? JSON.stringify(token) : " cannot decide"));
                return;
            }

            localStorage.setItem("availability-print-pdf", JSON.stringify({
                contacts: [token.oid],
                contactsSelected: [token.oid],
                start: Utilities.formatDate(new Date(), "YYYY-MM-DD"),
                days: 7,
                startHour: 0,
                endHour: 23,
                originalTimePreset: null
            }))

        }

        if (localStorage.getItem('availability-print-pdf')) {

            this.setState({
                loading: "Generating PDF..."
            })

            let config: AvailabilityPrintPdfLocalConfig = JSON.parse(localStorage.getItem('availability-print-pdf') || '');
            const end: string = Utilities.formatDate(Utilities.dateAdd(new Date(config.start), "day", config.days), "YYYY-MM-DD");
            let data = await AvailabilityAPI.getEventsFromContacts(config.contactsSelected, config.start, end).catch(e => {
                // window.close();
                console.error(e)
            });

            console.log(data);

            if (data) {

                for (let i = 0; i < data.length; i++) {

                    const element = data[i];
                    // @ts-ignore
                    let compiled = await Utilities.generateEvents(new Date(config.start), new Date(end), element.events || [], data[i].profile.maxAvailabilityDate);
                    
                    for (let key in compiled) {
                        let dayEvents: Event[] = compiled[key];
                        for (let j = 0; j < dayEvents.length; j++) {
                            const element = dayEvents[j];
                            let start = new Date(element.start);
                            let end = new Date(element.end);
                            let dayStartRange = start < end ? this.getDayStart(element.start, config.startHour) : this.getDayStart(element.end, config.startHour, true);
                            let dayEndRange = start < end ? this.getDayStart(element.start, config.endHour, true) : this.getDayStart(element.end, config.startHour);

                            // console.log(dayStartRange + " - > " + dayEndRange);

                            if (config.startHour < config.endHour) {
                                if (end < dayStartRange || start > dayEndRange) {
                                    console.log("  @@ Do not include");
                                    compiled[key].splice(j, 1);
                                    j--;
                                } else {
                                    if (start < dayStartRange) {
                                        console.log("  @@ Start is smaller than day start range");
                                        compiled[key][j].start = dayStartRange.toISOString();
                                    }
                                    if (end > dayEndRange) {
                                        console.log("  @@ End is bigger than day end range" + end + " > " + dayEndRange);
                                        compiled[key][j].end = dayEndRange.toISOString();
                                    }
                                } 
                            } else {
                                // if (start < dayStartRange || end > dayEndRange) {
                                //     console.log("  @@ Do not include");
                                //     compiled[key].splice(j, 1);
                                //     j--;
                                // } else if (start < dayStartRange) {
                                //     console.log("  @@ Start is smaller than day start range");
                                //     compiled[key][j].start = dayStartRange.toISOString();
                                // } else if (end > dayEndRange) {
                                //     console.log("  @@ End is bigger than day end range" + end + " > " + dayEndRange);
                                //     compiled[key][j].end = dayEndRange.toISOString();
                                // } 
                            }

                            // console.log("Event: " + Utilities.formatDate(start, "HH:MM") + " - " + Utilities.formatDate(end, "HH:MM") + " ===> " + Utilities.formatDate(compiled[key][j].start, "HH:MM") + " - " + Utilities.formatDate(compiled[key][j].end, "HH:MM"))
                            
                        }
    
                        let inferredType: null | 1 | 4 = null;
    
                        if (data[i].profile.maxAvailabilityDate) {
                            let lastAvailableDate = new Date(data[i].profile.maxAvailabilityDate);
                            let currentDay = new Date(key);
                            if (Utilities.areDatesAreOnSameDay(currentDay, lastAvailableDate)) {
                                console.log(data[i].profile.firstName + "  ==== Last available is on this day, must do spitting");
                                inferredType = 1;
                            } else {
                                if (lastAvailableDate < currentDay) {
                                    console.log(data[i].profile.firstName + "  ==== Last available smaller than current, so available");
                                    inferredType = 4;
                                } else {
                                    console.log(data[i].profile.firstName + "  ==== Last available bigger than current, so busy");
                                    inferredType = 1
                                }
                            } 
                        } else {
                            console.log(data[i].profile.firstName + "  ==== No last available, so free");
                            inferredType  = 4
                        }
                        
                        if (inferredType) {
                            compiled[key].push({
                                start: config.startHour < config.endHour ? this.getDayStart(key, config.startHour).toISOString() : this.getDayStart(key, config.endHour).toISOString(),
                                end: config.startHour < config.endHour ? this.getDayStart(key, config.endHour, true).toISOString() : this.getDayStart(key, config.startHour, true).toISOString(),
                                eventTypeId: inferredType,
                                background: true,
                                id: Utilities.randomStr(12)
                            })
                        }
                        
                    }
    
                    data[i].events = compiled
                }
    
                let startDate = new Date(config.start);
                let endDate = new Date(end);

                this.setState({ 
                    data: data as AvailabilityData[], 
                    endHour: config.endHour, 
                    startHour: config.startHour, 
                    startDate: startDate, 
                    endDate: endDate,
                    originalTimePreset: config.originalTimePreset,
                    mode: window.location.href.indexOf("/external/") !== -1 ? 'single' : 'multi',
                    singleName: token ? token.name : "",
                    singleId: token ? token.oid : "",
                    loading: token ? "Generating PDF..." : null
                }, () => {
                    setTimeout(() => {
                        // window.print();
                        // window.onfocus = function() {
                        //     window.close();
                        // }
                        this.print();
                    }, 700);
                })

                if (token.oid) {
                    this.getBase64ProfileImage();
                }

            }

        } else {

            history.push("/");

        }

    }

    getBase64ProfileImage = async () => {
        let image = await ProfileApi.getBase64ProfileImage(this.state.singleId || "");
        if (image) {
            this.setState({ base64ProfileImage: image || "" });
        }
    }

    print = () => {
        if (this.state.mode === "single") {
            // this.captureView();
        } else {
            window.print();
        }
    }

    px2inch = (px) => {
        let div = document.createElement('div');
        div.setAttribute("id", "inch2px");
        div.setAttribute("style", "width:1mm;height:1mm;visibility:hidden;");
        document.body.appendChild(div);
        let pixels = 0;
        if (document.getElementById('inch2px')) {
            pixels = document.getElementById('inch2px')!.offsetWidth;
            document.getElementById('inch2px')!.remove();
        }

        return px / pixels;
    }

    captureView = async () => {

        const id = "print-view";

        var node = document.getElementById(id);
        // await domtoimage.toPng(node, { quality: 2 }).catch(e => {});

        setTimeout( async () => {

            domtoimage.toPng(node, { quality: 2 })
                .then(async(base64) => {
                    console.log(base64)

                    this.setState({ 
                        loading: "Sending Email" 
                    })
    
                    // @ts-ignore
                    let contactIds: string[] = this.props.match.params.userIds.split(",");
                    console.log(contactIds);
    
                    base64 = base64.split(',')[1];
                    let res = await AvailabilityAPI.postAvailabilityImage(contactIds, base64);
    
                    this.setState({
                        loading: null
                    })
    
                    if (res) {
                        Notifications.actionCreators.display(ToastType.SUCCESS, "Email Sent");
                    } else {
                        Notifications.actionCreators.display(ToastType.SUCCESS, "We couldn't send the email, please try again later");
                    }

                })
                .catch((error) => {
                    console.error('oops, something went wrong!', error);
                });

        }, 200)

        return;

        // let input = document.getElementById('print-view')

        // if (input) {

        //     let width = input.offsetWidth;
        //     let height = input.offsetHeight;

        //     console.log(input, width, height);
            
        //     html2canvas(input, {
        //         width: width,
        //         height: height,
        //         scale: 1.5
        //     }).then( async (canvas) => {
        //         let base64 = canvas.toDataURL('image/jpeg', 0.85);
        //         console.log(base64)
        //         return;

        //         this.setState({ 
        //             loading: "Sending Email" 
        //         })

        //         // @ts-ignore
        //         let contactIds: string[] = this.props.match.params.userIds.split(",");
        //         console.log(contactIds);

        //         base64 = base64.split(',')[1];
        //         let res = await AvailabilityAPI.postAvailabilityImage(contactIds, base64);

        //         this.setState({
        //             loading: null
        //         })

        //         if (res) {
        //             Notifications.actionCreators.display(ToastType.SUCCESS, "Email Sent");
        //         } else {
        //             Notifications.actionCreators.display(ToastType.SUCCESS, "We couldn't send the email, please try again later");
        //         }
                
        //         // setTimeout(() => {
        //         //     window.close();
        //         // }, 2000);
        //     });

        // }

    }

    renderEvent = (event: Event, printDateWidth, noHours) => {

        const BACKGROUND_WIDTH = 24;
        const images = [
            '',
            'unavailable',
            'unavailable',
            'offersconsidered',
            'available'
        ]

        const secondLength = printDateWidth / noHours / 3600;
        const eventLength = Utilities.differenceBetweenDatesSeconds(new Date(event.start), new Date(event.end)) * secondLength;
        let dayStart = new Date(event.start);
        dayStart.setHours(this.state.startHour || 0);
        dayStart.setMinutes(0);
        dayStart.setSeconds(0);

        if (!isFinite(eventLength)) {
            return null;
        }
        
        const eventStartPoint = Utilities.differenceBetweenDatesSeconds(new Date(event.start), dayStart) * secondLength;
        const backgroundIterator = new Array(Math.ceil(eventLength / BACKGROUND_WIDTH)).fill(2);

        return (
            <div 
                className="event"
                style={{
                    left: eventStartPoint,
                    width: eventLength,
                    zIndex: event.background ? 1 : 2
                }}
            >
                { backgroundIterator.map((value, i) => {
                    return (
                        <img
                            src={'/img/' + (images[event.eventTypeId] + '-pattern.svg') } 
                            className="svg"
                            key={i}
                            style={{
                                left: i*BACKGROUND_WIDTH,
                                width: BACKGROUND_WIDTH
                            }}
                        />
                    )
                }) }
            </div>
        )
    }

    renderHour = (label, printDateWidth, noHours, i) => {
        const hourLength = printDateWidth / noHours;
        const quarterLength = hourLength / 4;
        return (
            <div className="hour" style={{ width: hourLength, left: i*hourLength }}>
                { (i === 0) &&
                    <span style={{ left: 0, height: 15, borderRight: '3px solid black' }}></span>
                }
                <span style={{ left: quarterLength, visibility: noHours > 12 ? 'hidden' : 'visible' }}></span>
                <span style={{ left: quarterLength * 2, visibility: noHours > 12 ? 'hidden' : 'visible' }}></span>
                <label>
                    {label}
                    {noHours > 12 ? "" : ":00"}
                </label>
                <span style={{ left: quarterLength * 3, visibility: noHours > 12 ? 'hidden' : 'visible' }}></span>
                <span style={{ left: quarterLength * 4 }}></span>
            </div>
        )
    }

    render() {

        const printDateWidth = this.state.mode === 'multi' ? 558 : 538;

        let hoursArray: number[] = [];

        if (this.state.startHour !== null && this.state.endHour !== null) {
            if (this.state.startHour < this.state.endHour) {
                let current = this.state.startHour;
                while (current <= this.state.endHour) {
                    hoursArray.push(current);
                    current++;
                }
            } else {
                let current = this.state.endHour;
                while (current >= this.state.startHour || current <= this.state.endHour) {
                    hoursArray.push(current);
                    current--;
                    if (current === -1) {
                        current = 23;
                    }
                }
                hoursArray.reverse();
            }
        }

        return (
            <div className="page" data-page="availability-print">
                { (this.state.loading) &&
                    <FullScreenLoader 
                        loadingMessage={this.state.loading} 
                        fullBlock={true}
                    />
                }
                { (this.state.data && this.state.data[0] && (this.state.loading || this.state.mode === "multi")) &&
                    <main 
                        id="print-view" 
                        data-mode={this.state.mode}
                        style={{
                            background: 'white'
                        }}
                    >
                        { (this.state.mode === "multi") &&
                            <h1>Availability for {this.state.data.length} contacts between {Utilities.formatDate(this.state.startDate || new Date(), "d mm")} and {Utilities.formatDate(Utilities.dateSub(this.state.endDate || new Date(), "day", 1) || new Date(), "d mm")} ({this.state.originalTimePreset}) </h1>
                        }
                        {/* { (this.state.generatedBase64) &&
                            <img src={this.state.generatedBase64} />
                        } */}
                        { (this.state.mode === "single") &&
                            <div className="profile-header">
                                { (this.state.base64ProfileImage) &&
                                    <img src={this.state.base64ProfileImage} onLoad={() => {
                                        setTimeout(() => {
                                            this.captureView();
                                        })
                                    }} />
                                }
                                <div>
                                    <p>{this.state.singleName}</p>
                                    <p>My availability between {Utilities.formatDate(this.state.startDate || new Date(), "d mm")} and {Utilities.formatDate(Utilities.dateSub(this.state.endDate || new Date(), "day", 1) || new Date(), "d mm")}</p>
                                </div>
                            </div>
                        }
                        <div className="info">
                            <div>
                                <img src={'/img/unavailable-pattern.svg'} />
                                <label>Unavailable</label>
                            </div>
                            <div>
                                <img src={'/img/offersconsidered-pattern.svg'} />
                                <label>Offers Considered</label>
                            </div>
                            <div>
                                <img src={'/img/available-pattern.svg'} />
                                <label>Available</label>
                            </div>
                        </div>
                        { Object.keys(this.state.data[0].events).map((dateHash, i) => {
                            let date = new Date(dateHash);
                            return (
                                <div className="day">
                                    { (this.state.mode === "multi") &&
                                        <h2>{Utilities.formatDate(date, "D, d mm")}</h2>
                                    }
                                    <div className="contacts">
                                        { this.state.data!.map((contact, j) => {
                                            return (
                                                <div className="contact">
                                                    { (this.state.mode === "multi") ?
                                                        <div className="profile">
                                                            <img src={ProfileApi.getProfileImageUrl(contact.profile.id)} />
                                                            <div>
                                                                <p>{contact.profile.firstName}</p>
                                                                <p>{contact.profile.lastName}</p>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="date-single">
                                                            <p>{Utilities.formatDate(date, "DD, d mm")}</p>
                                                        </div>
                                                    }
                                                    <div className="dates">
                                                        <div className="date">
                                                            { contact.events[dateHash].map((event) => {
                                                                return (
                                                                    this.renderEvent(event, printDateWidth, hoursArray.length)
                                                                )
                                                            }) }
                                                        </div>
                                                        <div className="hours">
                                                            { hoursArray.map((hour, i) => {
                                                                return (
                                                                    this.renderHour(hour, printDateWidth, hoursArray.length, i)
                                                                )
                                                            }) }
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }) }
                                    </div>
                                </div>
                            )
                        }) }
                    </main>
                }
            </div>
        );
    }
}

const connector = connect(
    (state: ApplicationState) => state.profile,
    ProfileStore.actionCreators
);

export default connector(AvailabilityPrint);