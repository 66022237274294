/* eslint-disable */
import React from 'react';
import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';
import { RouteComponentProps } from 'react-router';
import { Button } from 'react-bootstrap';

import { JwtValidationStatus } from '../../api/token/ResponseTypes';
import TokenApi from '../../api/token/Token';
import Splash from '../ui-components/Splash';
import theme from '../../css/theme';
import { AuthFlowLayout } from './AuthFlowLayout';
import AuthFlowButton from '../ui-components/AuthFlowButton';
import Auth from '../../components/auth/Auth';
import Analytics from '../../services/analytics.service';
import UECookieConsent from './UECookieConsent';
import { Footer } from './Footer';
import FeatureList from '../../pages/FeatureList';

export interface State {
    loading: boolean;
    jwtValidationStatus: JwtValidationStatus;
    showBanner: boolean;
    isUser: boolean;
}

export const EphemeralAccessTokenStorageKey = 'EphemeralAccess.token';

export class EphemeralAccessLayout extends React.Component<
    RouteComponentProps<any>,
    {}
> {
    state = {
        loading: true,
        jwtValidationStatus: JwtValidationStatus.Unknown,
        showBanner: false,
        isUser: false
    } as State;

    componentDidMount() {
        // Check whether they are already authenticated with the Portal and redirect to internal page if so
        // Don't go to internal, just signed-up user might not be receiving availabiity from contacts yet
        // this.checkPortalAuthenticated();

        // Read token from local storage
        const token = localStorage.getItem(EphemeralAccessTokenStorageKey);

        if (token) {
            const isUserResult = /[\\?&]user=([^&#]*)/.exec(
                this.props.location.search
            );

            if (window.location.href.indexOf("/external/availability/pdf/") !== -1) {
                this.setState({
                    jwtValidationStatus: JwtValidationStatus.Success,
                    loading: false,
                })
            } else {
                // Validate the JWT
                TokenApi.validate(token).then((status) => {
                    this.setState({
                        jwtValidationStatus: status,
                        loading: false,
                        isUser:
                            isUserResult &&
                            decodeURIComponent(isUserResult[1]) === 'true'
                    });

                    setTimeout(() => {
                        this.setState({
                            showBanner: true
                        });
                    }, 1000);

                    Analytics.trackEvent('external-access-opened', {
                        validationStatus: status.toString()
                    });
                });
            }

        } else {
            this.setState({
                loading: false,
                jwtValidationStatus: JwtValidationStatus.Invalid
            });

            Analytics.trackEvent('external-access-missing-token');
        }
    }

    get portalPath() {
        // Internal path omits the /external prefix
        return window.location.pathname.replace('/external/', '/');
    }

    handleBannerClick = () => {
        Analytics.trackEvent('external-access-banner-clicked');
        let redirectPath = this.portalPath;
        const searchParams = new URLSearchParams(this.props.location.search);
        if (searchParams.has('invitationId'))
            redirectPath = `/invitations/${searchParams.get('invitationId')}`;
        this.props.history.push(redirectPath);
    };

    checkPortalAuthenticated = () => {
        try {
            const portalAuth = Auth.getPortalAuth();

            if (!portalAuth) return;

            // Verify that the current access token is valid
            const token = Auth.getDecodedToken();

            // Get whether the current token has expired
            const hasExpired =
                new Date(
                    token.exp * 1000 /* unix epoch seconds to milliseconds */
                ) < new Date();

            if (!hasExpired) {
                Analytics.trackEvent('external-access-already-authed');

                // Redirect to the internal calendar page
                window.location.replace(this.portalPath);
            } else {
                Analytics.trackEvent('external-access-auth-expired');
            }
        } catch {
            Analytics.trackEvent('external-access-failed-auth');
            // Failure to determine whether portal user
            // Don't error, just continue with existing unauthenticated page
        }
    };

    render() {
        if (window.location.href.indexOf('/external/contact/') !== -1)
            return <FeatureList>{this.props.children}</FeatureList>;

        if (this.state.loading) {
            return <Splash />;
        }

        switch (this.state.jwtValidationStatus) {
            case JwtValidationStatus.Success:
                return this.renderChildren();
            case JwtValidationStatus.Expired:
                return this.renderExpired();
            default:
            case JwtValidationStatus.Invalid:
                // Should never show so doesn't have to be pretty
                return (
                    <h4 style={{ textAlign: 'center' }}>
                        Invalid access token
                    </h4>
                );
        }
    }

    renderChildren() {
        return (
            <Wrapper className="layout">
                <ToastContainer position="top-center" />
                <div
                    className={
                        'banner ' + (this.state.showBanner ? 'show' : '')
                    }
                    onClick={this.handleBannerClick}
                >
                    <div className="layout horizontal center-center">
                        <img
                            src="/img/branding/updateedge_90.png"
                            alt="Updatedge Logo"
                        />
                        <h3>Welcome to updatedge</h3>
                    </div>
                    {this.state.isUser ? (
                        <h4>
                            Sign in to your account to view the availability of
                            all your contacts and send them offers
                        </h4>
                    ) : (
                        <h4>
                            Sign up for a <strong>free</strong> account to gain
                            permanent access to the availability of all your
                            contacts and send them offers
                        </h4>
                    )}
                    <div>
                        <Button onClick={this.handleBannerClick}>
                            <strong>
                                Sign {this.state.isUser ? 'in' : 'up'} now!
                            </strong>
                        </Button>
                    </div>
                </div>
                <div>
                    <div className="main-content">{this.props.children}</div>
                    <Footer />
                </div>
                <UECookieConsent />
            </Wrapper>
        );
    }

    renderExpired() {
        return (
            <AuthFlowLayout>
                <ExpiredContent>
                    <h4>That link has expired</h4>
                    {this.state.isUser ? (
                        <div>
                            Sign in to your updatedge account to view the
                            availability of all your contacts and send them
                            offers
                        </div>
                    ) : (
                        <div>
                            Sign up for a <strong>free</strong> updatedge
                            account to view the availability of all your
                            contacts and send them offers!
                        </div>
                    )}
                    <AuthFlowButton onClick={this.handleBannerClick}>
                        Sign {this.state.isUser ? 'in' : 'up'} now!
                    </AuthFlowButton>
                </ExpiredContent>
            </AuthFlowLayout>
        );
    }
}

const Wrapper = styled.div`
    .banner {
        width: 100%;
        height: 0px;
        overflow: hidden;
        color: white;
        text-align: center;
        background: ${theme.colours.updatedge};
        cursor: pointer;
        transition: height 0.75s ease;
        transition-delay: 0.35s &.show {
            height: 125px;
        }

        &.show {
            height: 105px;
        }

        img {
            width: 50px;
            height: 50px;
        }

        h4 {
            margin-top: 0;
        }
    }

    /* Override styles on existing components which are re-used in external pages */
    #calendar {
        height: calc(100vh - 275px) !important;
    }
    .btn-nudge {
        display: none;
    }

    .main-content {
        padding: 5px 10px;
        overflow: hidden;
    }

    .contact-profile {
        .pageHeader {
            margin-bottom: 15px;

            .panel-body {
                padding: 0 10px;
                border: 0;

                > div {
                    display: inline-block;
                    float: none !important;
                    vertical-align: middle;

                    &:last-child {
                        margin-left: 15px;
                    }
                }
            }
        }
    }

    .contact-calendar {
        .panel {
            border: 0;

            .panel-heading {
                display: none;
            }

            .panel-body {
                padding: 0 10px;

                .rbc-toolbar {
                    font-size: 14px;

                    .rbc-btn-group button {
                        padding-right: 7px;
                        padding-left: 7px;
                    }
                }

                .rbc-agenda-time-cell {
                    padding: 5px 10px;
                }

                .rbc-agenda-event-cell {
                    .pull-right {
                        display: none;
                    }
                }
            }
        }
    }

    .footer {
        text-align: center;

        > span {
            float: none !important;
        }

        .rights-reserved {
            display: none;
        }
    }

    @media (max-width: 649px) {
        .banner {
            img {
                width: 30px;
                height: 30px;
            }

            h3 {
                margin: 0;
                font-size: 21px;
            }

            h4 {
                font-size: 3.7vw;
            }

            button {
                height: 26px;
                padding: 2px 5px 5px 5px;
                font-size: 14px;
            }
        }

        .main-content {
            padding-top: 20px !important;
        }
    }
`;

const ExpiredContent = styled.div`
    > h4 {
        margin: 25px 0 30px 0;
        text-align: center;
    }

    > div {
        margin: 30px 0;
    }
`;
