/* eslint-disable */
import React from 'react';
import styled from 'styled-components';
import { History, UnregisterCallback } from 'history';
import { connect } from 'react-redux';

import { Subscribe } from '../unstated-fork/unstated';
import { Spinner } from '../components/ui-components/Spinner';
import { ApplicationState } from '../store';
import * as ProfileState from '../store/Profile';
import DashboardContainer from '../state-containers/dashboard.container';
import {
    UserTypeContainer,
    UserType
} from '../state-containers/user-type.container';
import UserTypeModal from '../components/dashboard/UserTypeModal';
import Dashboard from '../components/dashboard/dashboard';
import { getTaskItems, TaskItem } from '../components/overlayMenu/OverlayMenu';
import SettingsAPI from '../api/settings/Settings';
import { Setting } from '../api/settings/ResponseTypes';

type Props = {
    history: History;
    userProfileState: ProfileState.UserProfileModel;
    dashboardContainer: DashboardContainer;
    userType: UserTypeContainer;
} & typeof ProfileState.actionCreators;

type State = {
    tasks: TaskItem[];
};

class Home extends React.Component<Props, State> {
    private unlisten?: UnregisterCallback;

    componentDidMount() {
        this.setState({ tasks: [] });
        if (this.propsInjected(this.props)) {
            this.reload();
        }

        this.unlisten = this.props.history.listen((location, _) => {
            if (
                location.pathname === '/' &&
                !this.props.dashboardContainer!.state.data!.dismissed
            ) {
                this.props.dashboardContainer!.toggleDismissed();
            }
        });

        this.redirectToHomePage();
    }

    redirectToHomePage = () => {
        const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')!) : null;
        if (user) {
            if (user.settings.find(setting => setting.settingId === Setting.Homepage) && user.settings.find(setting => setting.settingId === Setting.Homepage).value) {
                const redirectTo = user.settings.find((setting => setting.settingId === Setting.Homepage)).value;
                if (redirectTo  && redirectTo !== "null") {
                    if (localStorage.getItem("noRedirectToHome")) {
                        localStorage.removeItem("noRedirectToHome");
                    } else {
                        this.props.history.push("/" + redirectTo);
                    }
                }
            }
        } else {
            setTimeout(() => {
                this.redirectToHomePage();
            }, 250);
        }
    }

    componentWillUnmount() {
        this.unlisten!();
    }

    componentDidUpdate(prevProps: Props) {
        if (
            this.propsInjected(prevProps) == false &&
            this.propsInjected(this.props) == true
        ) {
            this.reload();
        }
    }

    propsInjected = (props: Props) =>
        !!Object.keys(props.userProfileState).length;

    reload() {
        const { dashboardContainer: dashboard, userType } = this.props;
        this.props.dashboardContainer!.reload(this.props).then(() => {
            const taskItems = getTaskItems(dashboard, userType);
            this.setState({ tasks: taskItems }, () => {
                console.log(this.state);
            });
            if (
                taskItems.length !== 0 &&
                taskItems.filter((task) => !task.completed).length === 0
            ) {
                SettingsAPI.update(Setting.ProgressGuide_Completed, 'true');
            }
        });
    }

    render() {
        const { dashboardContainer: dashboard, userType } = this.props;

        return (
            <>
                {(() => {
                    if (
                        !dashboard.state.loaded ||
                        !userType.state.loaded ||
                        !dashboard.state.data
                    ) {
                        return (
                            <SpinnerBox>
                                <Spinner hide={false} />
                            </SpinnerBox>
                        );
                    }

                    if (
                        userType.state.type == UserType.Worker ||
                        userType.state.type == UserType.Unknown
                    ) {
                        return null;
                    }

                    return (
                        <Dashboard
                            dashboardContainer={dashboard}
                            userType={userType}
                            tasks={this.state ? this.state.tasks : []}
                        />
                    );
                })()}
                <UserTypeModal />
            </>
        );
    }
}

const SpinnerBox = styled.div`
    height: calc(100vh - 150px);

    > div:first-child {
        position: relative;
        top: calc(50% - 30px);
        transform: translateY(-50%);
    }
`;

export default connect((state: ApplicationState) => {
    return {
        userProfileState: state.profile.authenticatedUserProfile
    };
}, ProfileState.actionCreators)((props) => (
    <Subscribe to={[DashboardContainer, UserTypeContainer]}>
        {(dashboard: DashboardContainer, userType: UserTypeContainer) => (
            <Home
                // @ts-ignore
                {...props}
                dashboardContainer={dashboard}
                userType={userType}
            />
        )}
    </Subscribe>
)) as any;
