import React from 'react';

import { Setting, UserSetting } from '../../api/settings/ResponseTypes';
import * as EventsState from '../../store/Events';
import SettingsApi from '../../api/settings/Settings';

type SettingsState = {
    loaded: boolean;
    userSettings: UserSetting[];
};

type SettingsProps = {
    render: (props) => JSX.Element;
    refreshOverlayMenu: typeof EventsState.actionCreators['refreshOverlayMenu'];
};

export default class SettingsProvider extends React.Component<
    SettingsProps,
    SettingsState
> {
    state = {
        loaded: false,
        userSettings: []
    } as SettingsState;

    ensureLoaded = async () => {
        if (!this.state.loaded) {
            const userSettings = await SettingsApi.get();

            this.setState({
                userSettings,
                loaded: true
            });
        }
    };

    getSetting = (setting: Setting) => {
        // Get requested setting
        const userSetting = this.state.userSettings.find(
            (s) => s.setting == setting
        );

        // Return value or undefined
        return userSetting ? userSetting.value : undefined;
    };

    getSettingAsBool = (setting: Setting) => {
        return this.getSetting(setting) == 'true';
    };

    updateSetting = async (setting: Setting, value: string) => {
        this.setState({
            userSettings: this.state.userSettings.map((s) =>
                s.setting == setting ? { ...s, value } : s
            )
        });

        await SettingsApi.update(setting, value);
        this.props.refreshOverlayMenu();
    };

    getPropsToPass = () => ({
        ensureLoaded: this.ensureLoaded,
        updateSetting: this.updateSetting,
        getSettingAsBool: this.getSettingAsBool,
        getSetting: this.getSetting,
        state: this.state
    });

    render() {
        return this.props.render(this.getPropsToPass());
    }
}
