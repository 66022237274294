import React from 'react';
import styled from 'styled-components';

import theme from '../../css/theme';

const OverlayStyles = styled.div`
    > div {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        color: ${theme.colours.updatedge};

        &.mask {
            background: #fffa;
            filter: 3px;
        }

        &.overlay {
            font-weight: bold;
            font-size: 14px;

            > ul {
                margin-top: 10px;
                padding: 0;
                font-weight: 600;
                text-align: left;

                > li {
                    list-style: none;
                }
            }
        }
    }
`;

const Overlay = ({ children }) => (
    <OverlayStyles>
        <div className="mask" />
        <div className="overlay layout vertical center-center">{children}</div>
    </OverlayStyles>
);

export default Overlay;
