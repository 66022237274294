/* eslint-disable */
import fetch from 'cross-fetch';

export type ClearBitCompany = { name: string; domain: string; logo: string, notes: string };

class ClearBit {
    static AUTOCOMPLETE_URL = 'https://autocomplete.clearbit.com/v1';
    static LOGO_URL = 'https://logo.clearbit.com';

    static async searchCompanies(term: string) {
        const endpoint = `${ClearBit.AUTOCOMPLETE_URL}/companies/suggest?query=${term}`;
        const res = await fetch(endpoint);
        const data = (await res.json()) as Promise<ClearBitCompany[]>;
        return data;
    }

    static async searchCompaniesByDomain(domain: string) {
        const endpoint = `${ClearBit.AUTOCOMPLETE_URL}/companies/domain/${domain}`;
        const res = await fetch(endpoint);
        const data = (await res.json()) as Promise<ClearBitCompany[]>;
        return data;
    }

    static getLogoUrl(domain: ClearBitCompany['domain']) {
        return `${ClearBit.LOGO_URL}/${domain}`;
    }

    static async downloadLogoFromDomain(domain: ClearBitCompany['domain']) {
        const url = this.getLogoUrl(domain);
        return ClearBit.downloadLogoFromURL(url);
    }

    static async downloadLogoFromURL(url: string) {
        const res = await fetch(url);
        const data = await res.blob();
        return data;
    }
}

export default ClearBit;
