import { createBrowserHistory } from 'history';

const getBaseUrl = () => {
    const els = document.getElementsByTagName('base');
    if (els.length === 0) return '';
    return els[0].getAttribute('href')!;
};

const history = createBrowserHistory({ basename: getBaseUrl() });

export function getFromLocationState(key: string) {
    const state = (history.location.state || {}) as Record<string, any>;
    return state[key];
}

export function removeFromLocationState(key: string) {
    const { [key]: _discard, ...state } = (history.location.state ||
        {}) as Record<string, any>;

    return history.replace({
        ...history.location,
        state
    });
}

export default history;
