/* eslint-disable */
import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { connect, ConnectedProps } from 'react-redux';
import history from '../../history';

import Profile from './NavProfile';
import SimpleTooltip from '../../components/ui-components/SimpleTooltip';
import { ApplicationState } from '../../store';
import * as ProfileStore from '../../store/Profile';
import { CompanyRoles } from '../../api/company/ResponseTypes';
import { UserType } from '../../state-containers/user-type.container';

interface Props {
    onNavigate?: () => void;
}

export interface ConfigItem {
    to: string;
    id: string;
    icon: string;
    label: string;
    badge?: string;
    badgeTooltip?: string;
    sub?: boolean;
    divider?: boolean;
    onlyOrgAdmin?: boolean;
    hideOnDesktop?: boolean;
    hidden?: boolean;
    onClick?: () => void;
}

interface State {
    config: ConfigItem[];
    showFiles: boolean,
    showReferrals: boolean,
}

class Navigation extends React.Component<
    Props & ConnectedProps<typeof connector>,
    State
> {
    state = {
        showFiles: false,
        showReferrals: false,
        config: [
            {
                to: '/availability',
                id: 'menuAvailabilityOption',
                icon: 'fa fa-calendar-check',
                label: 'Availability',
            },
            {
                to: '/offers',
                id: 'menuOffersOption',
                icon: 'fa fa-briefcase',
                label: 'Offers'
            },
            {
                to: '/offers#create',
                id: 'menuOffersCreateOption',
                icon: 'fas fa-plus',
                label: 'Create',
                sub: true
            },
            {
                to: '/schedule',
                id: 'menuSchedule',
                icon: 'fa fa-calendar-alt',
                label: 'Schedule',
                sub: true
            },
            {
                to: '/invite',
                id: 'menuInviteOption',
                icon: 'fas fa-envelope-open-text',
                label: 'Invite',
                divider: true,
            },
            {
                onClick: () => {
                    localStorage.setItem("noRedirectToHome", "true");
                    window.location.href = "/";
                },
                id: 'menuHomeOption',
                icon: 'fas fa-home',
                label: 'Setup Guide'
            },
            {
                to: '/files',
                id: 'menuFilesOption',
                icon: 'fas fa-file',
                label: 'Files'
            },
            {
                to: '/ratings',
                id: 'menuRatings',
                icon: 'fas fa-star-half-alt',
                label: 'Ratings',
            },
            {
                to: '/referrals',
                id: 'menuReferrals',
                icon: 'fas fa-share',
                label: 'Referrals',
            },
            {
                to: '/lists',
                id: 'menuListsOption',
                icon: 'fa fa-list',
                label: 'Groups'
            },
            {
                to: '/agencies',
                id: 'menuAgenciesOption',
                icon: 'fa fa-briefcase',
                label: 'My Agencies'
            },
            {
                to: '/screenings',
                id: 'menuScreeningsOption',
                icon: 'fas fa-id-card-alt',
                label: 'Screening'
            },
            {
                to: '/organisation/members',
                id: 'menuOrganisationColleagueOption',
                icon: 'fas fa-users',
                label: 'My Colleagues',
                divider: true,
            },
            {
                to: '/profile',
                id: 'menuProfileOption',
                icon: 'far fa-address-card',
                label: 'Profile'
            },
            {
                to: '/organisation',
                id: 'menuOrganisationOption',
                icon: 'fa fa-sitemap',
                label: 'Organisation'
            },
            {
                to: '/organisation/locations',
                id: 'menuOrganisationLocationOption',
                icon: 'fas fa-location-arrow',
                label: 'Locations',
                onlyOrgAdmin: true
            },
            {
                to: '/organisation/subscriptions',
                id: 'menuOrganisationSubscriptionOption',
                icon: 'fas fa-wallet',
                label: 'Subscriptions',
                onlyOrgAdmin: true
            },
            {
                onClick: () => {
                    if ((window as any).openNotificationsPanel) {
                        (window as any).openNotificationsPanel();
                    }
                },
                id: 'menuNotificationsOption',
                icon: 'far fa-bell',
                label: 'Notifications',
                hideOnDesktop: true
            },
            {
                to: '/support',
                id: 'menuSupportOption',
                icon: 'fa fa-question',
                label: 'Support'
            },
            {
                to: '/whats-new',
                id: 'menuNewsOption',
                icon: 'fa-solid fa-newspaper',
                label: 'What\' new',
                hideOnDesktop: true
            },
            {
                to: '/settings',
                id: 'menuSettingsOption',
                icon: 'fa fa-cog',
                label: 'Settings'
            },
            {
                to: '/settings#presets',
                id: 'menuSettingsPresetsOption',
                icon: 'fas fa-clock',
                label: 'Time Presets'
            },
            // {
            //     to: 'https://feedback.userreport.com/45e76aa9-3a3d-4b54-9824-54296a239606/#ideas/popular',
            //     id: 'menuIdeasOption',
            //     icon: 'fas fa-bullhorn',
            //     label: 'Ideas & Suggestions',
            //     hideOnDesktop: true
            // },
        ] as ConfigItem[]
    };

    componentDidMount() {
       (window as any).onFeatureEnabledFiles = function() {
        // @ts-ignore
        this.setState({ showFiles: true })
        let el = document.querySelector('li[data-id="menuFilesOption"]');
        if (el && el !== null) {
            el.setAttribute("data-hidden", "false");
        }
       }.bind(this);
       (window as any).onFeatureEnabledReferrals = function() {
        // @ts-ignore
        this.setState({ showReferrals: true })
        let el = document.querySelector('li[data-id="menuReferrals"]');
        if (el && el !== null) {
            el.setAttribute("data-hidden", "false");
        }
       }.bind(this);
    }

    public render() {
        let linksToShow: ConfigItem[] = Object.assign([], this.state.config);
        if ( (this.props.authenticatedUserProfile.organisationId ? (!this.props.authenticatedUserProfile.organisationIsHirer) : this.props.authenticatedUserProfile.userType === UserType.Agency )) {
            linksToShow = linksToShow.filter((link) => link.id !== "menuAgenciesOption");
        } else {
            linksToShow = linksToShow.filter((link) => link.id !== "menuScreeningsOption");
        }
        let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string) : null;
        return (
            <aside id="menu">
                <div id="navigation">
                    <Profile />
                    <ul className="nav" id="side-menu">
                        {this.state.config && linksToShow.map(
                            ({ to, id, icon, label, badge, badgeTooltip, sub, onlyOrgAdmin, hideOnDesktop, onClick, hidden, divider }) => {
                                if ((onlyOrgAdmin && this.props.authenticatedUserProfile.role !== CompanyRoles.Admin) || hidden)
                                    return null;
                                return (
                                    <li 
                                        key={id}
                                        data-id={id}
                                        data-desktop-hide={hideOnDesktop ? 'true' : 'false'}
                                        data-hidden={"false"}
                                        style={ divider ? { borderBottom: '12px solid #e9e9e9' } : {} }
                                    >
                                        <NavLink
                                            to={to || "#"}
                                            onClick={() => {
                                                if (this.props.onNavigate) {
                                                    this.props.onNavigate();
                                                }
                                                if (onClick) {
                                                    onClick();
                                                }
                                            }}
                                            style={sub ? { paddingLeft: 40 } : {}}
                                        >
                                            <div id={id} className="nav-link">
                                                <MenuIcon className={icon} />
                                                {label.toUpperCase()}
                                                {badge && badgeTooltip && (
                                                    <SimpleTooltip
                                                        id="chat-loading-helper"
                                                        text={badgeTooltip || ''}
                                                    >
                                                        <span className="badge-nav">
                                                            {badge}
                                                        </span>
                                                    </SimpleTooltip>
                                                )}
                                                {badge && !badgeTooltip && (
                                                    <span className="badge-nav">
                                                        {badge}
                                                    </span>
                                                )}
                                                { ( label === 'Organization' &&
                                                    (this.props.authenticatedUserProfile.subscriptionNotifications?.missingOrExpiredCard && this.props.authenticatedUserProfile.subscriptionNotifications.daysUntilNextSubscriptionExpiry && this.props.authenticatedUserProfile.subscriptionNotifications?.daysUntilNextSubscriptionExpiry < 7 ) ) && (
                                                        <SimpleTooltip
                                                            id="chat-loading-helper"
                                                            text={"You have one or more expiring services in " + this.props.authenticatedUserProfile.subscriptionNotifications.daysUntilNextSubscriptionExpiry + " day(s), and no card details entered. Please add credit / debit card to keep enjoying all features or adjust your subscriptions."}
                                                        >
                                                            <span className="badge-nav">
                                                                !
                                                            </span>
                                                        </SimpleTooltip>
                                                    ) 
                                                }
                                            </div>
                                        </NavLink>
                                    </li>
                                )
                            }
                        )}
                    </ul>
                </div>
            </aside>
        );
    }
}

const MenuIcon = styled.i`
    width: 20px;
    margin: 0 10px 0 -10px;
    color: #2a629e;
    font-size: 18px;
    text-align: center;
    vertical-align: middle;
`;

const connector = connect(
    (state: ApplicationState) => state.profile,
    ProfileStore.actionCreators
);

export default connector(Navigation);