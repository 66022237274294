import { Link } from 'react-router-dom';
import React from 'react';

import SimpleTooltip from './SimpleTooltip';
import QuestionCircle from './QuestionCircle';

const verifiedTooltip = (props: { text: string }) => {
    return (
        <Link to="/support">
            <SimpleTooltip id="team-info" placement="top" text={props.text}>
                <span className="verified-overlay">
                    verified
                    <QuestionCircle />
                </span>
            </SimpleTooltip>
        </Link>
    );
};

export default verifiedTooltip;
