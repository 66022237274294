import React from 'react';
import styled from 'styled-components';
import ReactInputRange, { InputRangeProps } from 'react-input-range';
import 'react-input-range/lib/css/index.css';

import theme from '../../css/theme';

const InputRange = (props: InputRangeProps) => (
    <Wrapper>
        {/* @ts-ignore */}
        <ReactInputRange {...props} />
    </Wrapper>
);

const Wrapper = styled.div`
    padding: 0 30px;

    .input-range {
        margin: 30px 0 35px 0;
        .input-range__label {
            top: -35px;
            color: ${theme.colours.updatedge};
            font-weight: 600;
            font-size: 12px;

            &--min,
            &--max {
                display: none;
            }
        }
    }
`;

export default InputRange;
