import React from 'react';
import styled from 'styled-components';

interface props {
    completed: boolean;
}

export default class Icon extends React.Component<props, {}> {
    public render() {
        return (
            <Wrapper
                style={{
                    color: this.props.completed ? '#333' : '#333',
                    opacity: this.props.completed ? 0.85 : 1,
                    transition: 'opacity 2s linear, color 2s linear'
                }}
            >
                <small>{this.props.children}</small>
            </Wrapper>
        );
    }
}

const Wrapper = styled.div`
    margin-top: 12px;
    font-size: 16px;
    line-height: 16px;
    min-height: 36px;
    flex-basis: 100%;
`;
