/* eslint-disable */
import React from 'react';
import ReactStars from "react-rating-stars-component";
import './RatingListOfWorker.css';
import ProfileApi from '../../api/profile/Profile';
import { ProfileImage } from '../ui-components/ProfileImage';
import RatingApi from '../../api/ratings/Rating';
import { ToastType } from 'react-toastify';
import * as Notifications from '../../store/Notifications';
import Dialog from '../ui-components/Dialog';
import { Modal } from 'react-bootstrap';
import { Rating } from 'src/api/offers/ResponseTypes';
import RatingPreview from './RatingPreview';
import theme from 'src/css/theme';
import Utilities from 'src/Utilities';

type Props = {
    ratings: Rating[];
    avgRating?: string;
    totalRatings?: number | null;
}

interface State {
    showMore: boolean
}

class RatingListOfWorker extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            showMore: false
        };
    }

    render() {
        if (this.props.ratings.length === 0) {
            return null;
        }

        return (
            <div className='rating-list-worker'>
                <h2>
                    All Ratings
                    { (this.props.avgRating && this.props.totalRatings) &&
                        <div className='stars-wrapper avg-rating'>
                            <i className="fas fa-star"></i>
                            <span>{this.props.avgRating} from {this.props.totalRatings} ratings</span>
                        </div>
                    }
                </h2>
                <div className='ratings'>
                    { this.props.ratings.map(rating => {
                        rating.updatedAtDate = new Date(rating.updatedAt || "");
                        return rating;
                    }).sort(Utilities.dynamicSort("updatedAtDate")).reverse().map((rating, i) => {
                        if (!this.state.showMore && i >= 5)
                            return null;
                        return (
                            <RatingPreview
                                refresh={() => {
                                    setTimeout(() => {
                                        window.location.reload();
                                    }, 1500)
                                }}
                                rating={rating}
                                hideRatee={true}
                            />
                        )
                    }) }    
                </div>
                { (!this.state.showMore && this.props.ratings.length > 5) &&
                    <div
                        style={{
                            textAlign: "right"
                        }}
                    >
                        <button 
                            onClick={() => {
                                this.setState({
                                    showMore: true
                                })
                            }}
                            style={{
                                border: "none",
                                backgroundColor: theme.colours.blue2,
                                color: "white",
                                borderRadius: 5,
                                padding: "6px 12px",
                                fontSize: 15
                            }}
                        >
                            Show More
                        </button>
                    </div>
                }
            </div>
        )
    }
}

export default RatingListOfWorker;