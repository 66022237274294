import React from 'react';
import styled from 'styled-components';

const StyledButton = styled.button`
    width: 100%;
    padding: 15px;
    color: white;
    font-weight: 300;
    font-size: 14px;
    line-height: normal;
    text-transform: uppercase;
    background: #4caf50;
    border: 0;
    outline: 0;
    cursor: pointer;

    &[disabled] {
        cursor: not-allowed;
        opacity: 0.4;
    }
`;

const AuthFlowButton = (
    props: React.ButtonHTMLAttributes<HTMLButtonElement>
) => {
    return <StyledButton type="button" {...props} />;
};

export default AuthFlowButton;
