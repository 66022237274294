/* eslint-disable */
import React from 'react';
import { ReactTinyLink } from 'react-tiny-link';
import { ToastType } from 'react-toastify';

import * as Notifications from './../../store/Notifications';
import OrganisationAPI from '../../api/organisation/Organisation';

import '../../css/OrganisationAutoCompleteSuggestions.css';
import InternalTracker from 'src/InternalTracker';
import history from 'src/history';
import { Button } from 'react-bootstrap';

interface Props {
    onAutocomplete: (object) => void;
    profileFilledOut?: boolean;
}

export interface AutocompleteSuggestion {
    metadata?: {
        urn?: number | null;
        lA_Code?: number | null;
        lA_Name?: string | null;
        doEEstablishmentNumber?: string | null;
        establishmentName?: string | null;
        typeOfEstablishment?: string | null;
        establishmentTypeGroup?: string | null;
        establishmentStatus?: string | null;
        closeDate?: string | null;
        phaseOfEducation_Code?: number | null;
        phaseOfEducation_Name?: string | null;
        dioceseCode?: string | null;
        dioceseName?: string | null;
        schoolCapacity?: number | null;
        approxNumberOfPupils?: number | null;
        trustSchoolFlag?: string | null;
        trustsCode?: number | null;
        trustsName?: string | null;
        schoolSponsorFlag?: string | null;
        schoolSponsorsName?: string | null;
        federationFlag?: string | null;
        federations_Code?: string | null;
        federations_Name?: string | null;
        ukprn?: number | null;
        feheIdentifier?: string | null;
        furtherEducationType?: string | null;
        street?: string | null;
        locality?: string | null;
        address3?: string | null;
        town?: string | null;
        county?: string | null;
        postcode?: string | null;
        website?: string | null;
        telephoneNum?: string | null;
        headTitle?: string | null;
        headFirstName?: string | null;
        headLastName?: string | null;
        headPreferredJobTitle?: string | null;
        gor?: string | null;
        districtAdministrative?: string | null;
        administrativeWard?: string | null;
        easting?: number | null;
        northing?: number | null;
        rscRegion?: string | null;
        mainEmail?: string | null;
        lastUpdatedOn?: string | null;
    };
    updatedgeUser?: boolean | null;
    invitedUser?: boolean | null;
    phoneNumber?: string | null;
    sectors?: Sector[];
    organisationName?: string | null;
    organizationName?: string | null;
    locations?: Location[];
    logoUrl?: string | null;
    emails?: string[];
    website?: string | null;
}

export interface Sector {
    name: string;
    value?: string;
    id: number;
    subSectors: SubSector[];
    addedByUserId?: string;
    deleted?: boolean;
    colour?: string;
    icon?: string;
    workerCount?: number;
}

export interface SubSector {
    name: string;
    value?: string;
    id: number;
    industryId?: number;
    addedByUserId?: string;
    deleted?: boolean;
    workerCount?: number;
}

export interface Location {
    type: LocationType;
    data: {
        norting?: number | null;
        easting?: number | null;
        addressStr?: string | null;
        lat?: number | null;
        lon?: number | null;
        id?: string | null;
        name?: string | null;
        words?: string[];
        city?: string | null;
        country?: string | null;
        street?: string | null;
        houseNumber?: string | null;
        county?: string | null;
        postcode?: string | null;
        fullAddress?: string | null;
    };
}

export enum LocationType {
    NORTHING_EASTING = 1,
    LAT_LON = 2,
    FULL_ADDRESS = 3,
    ADDRESS_COMPONENTS = 4,
    GOOGLE_PLACES = 5,
    WHAT_THREE_WORDS = 6,
    GOOGLE_ADDRESS_COMPONENTS = 7
}

export default class OrganisationAutoCompleteSuggestions extends React.Component<
    Props,
    {
        results?: [AutocompleteSuggestion];
        imageOverride?: string | null;
    }
> {
    async componentDidMount() {
        let res = localStorage.getItem("org-autocomplete") ? JSON.parse(localStorage.getItem("org-autocomplete") || "") : null;
        if (res && res.organisationName) {
            // @ts-ignore
            res.metadata = JSON.parse(res.metadata)[0];
            res.organizationName = res.organisationName;
            this.setState({
                results: [res]
            }, () => {
                this.props.onAutocomplete(res);
            });
        }
    }

    public render() {

        if (!this.state || !this.state.results) {
            return (
                <div>
                    <hr />
                </div>
            );
        }

        return (
            <div className="autocomplete-results guide-instruction">
                <div className="autocomplete-header">
                    <h2>Is this your organization?</h2>
                    <p>
                        We have auto-filled some information for you, if it looks corrent, click 'Save' at the bottom of the page to save it, if not, edit the incorrect details first.
                    </p>
                </div>
            </div>
        )
    }

}
