/* eslint-disable */
import { History } from 'history';

import { groupBy } from '../constants';
import { Container } from '../unstated-fork/unstated';
import { DashboardData } from '../api/dashboard/ResponseTypes';
import DashboardApi from '../api/dashboard/Dashboard';
import SettingsApi from '../api/settings/Settings';
import UserApi from '../api/user/User';
import ProfileApi from '../api/profile/Profile';
import OrganisationApi from '../api/organisation/Organisation';
import { CompanyRoles } from '../api/company/ResponseTypes';
import * as ProfileState from '../store/Profile';
import { Setting } from '../api/settings/ResponseTypes';
import { UserTypeContainer } from './user-type.container';
import StateManager from '../state-containers/state-manager';
// import { getWizardItemsForStage } from '../components/dashboard/onboarding';

export enum Stage {
    Bronze = 1,
    Silver,
    Gold
}

export interface DashboardState {
    loaded: boolean;
    loading: boolean;
    failed: boolean;
    data?: DashboardData;
    viewedStage: Stage;
}

type DashboardProps = {
    history: History;
    userProfileState: ProfileState.UserProfileModel;
} & typeof ProfileState.actionCreators;

export default class DashboardContainer extends Container<DashboardState> {
    constructor() {
        super();
        this.state = {
            loaded: false,
            failed: false,
            data: {}
        } as DashboardState;

        if (!this.state.loaded && !this.state.loading) {
            this.reload();
        }
    }   

    props?: DashboardProps;
    userType?: UserTypeContainer;
    loading?: boolean;
    failed?: boolean;

    reload = async (props?: DashboardProps) => {
        this.props = props;

        // TODO check why not triggering update
        if (this.loading) {
            return new Promise<void>((resolve, reject) => {
                let intv = setInterval(() => {
                    if (this.state.loaded) {
                        clearInterval(intv);
                        resolve();
                    }
                }, 2000);
            })
        }

        this.loading = true;
        this.failed = false;

        await this.setState(
            {
                loaded: false,
                loading: true,
                failed: false,
            },
            async () => {
                const members = await UserApi.getAll().catch(() => {});
                if (!this.userType) {
                    this.userType = StateManager.get<UserTypeContainer>(
                        UserTypeContainer
                    );
                }

                if (!localStorage.getItem("org-autocomplete") && !(window as any).Cypress) {
                    OrganisationApi.getOrganisationAutocomplete().then(data => {
                        if (data && data.organisationName) {
                            localStorage.setItem("org-autocomplete", JSON.stringify(data));
                            if (window.location.pathname.startsWith("/organisation")) {
                                window.location.reload();
                            }
                        } else {
                            localStorage.setItem("org-autocomplete", "");
                        }
                    }).catch(e => {})
                }

                await DashboardApi.get().then(async (data) => {
                    let agencyDataRaw = await ProfileApi.getThirdParties();
                    const agencyData = groupBy(
                        agencyDataRaw.data,
                        (item) => item.organisationName
                    );
                    await this.setState({
                        data: {
                            ...data,
                            // @ts-ignore
                            members: members,
                            agencies: agencyData.size,
                            agencyList: agencyDataRaw.data,
                        },
                        viewedStage: this.stage,
                        loaded: true,
                        loading: false
                    });
                    if (data.offersContinueOnWeekdays) {
                        localStorage.setItem("offersContinueOnWeekdays", "true")
                    } else {
                        localStorage.removeItem("offersContinueOnWeekdays");
                    }
                    localStorage.setItem("distance", data.unitDistance === "km" ? "km" : "mi")
                    
                    localStorage.setItem(
                        'dashboard',
                        JSON.stringify(this.state.data)
                    );
                }).catch(e => {
                    this.failed = true;
                    this.setState({
                        failed: true,
                    })
                }) 
            }
        );

        this.loading = false;
    };

    get isAdmin() {
        return (
            this.props &&
            this.props!.userProfileState.role == CompanyRoles.Admin
        );
    }

    get hasOrganisation(): boolean {
        const localUser = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')!) : null;
        return (localUser && localUser.organisationId) || (this.props &&
            this.props.userProfileState &&
            this.props.userProfileState.loaded &&
            this.props.userProfileState.organisationId &&
            this.props.userProfileState.organisationName
        ) as boolean;
    }

    get userProfileStateLoaded() {
        return this.props && this.props.userProfileState && this.props.userProfileState.loaded;
    }

    get shouldDisplayWizard() {
        return !this.state.data!.dismissed;
    }

    get stage(): Stage {
        if (this.completePercentageBronze < 100) {
            return Stage.Bronze;
        }

        if (this.completePercentageSilver < 100) {
            return Stage.Silver;
        }

        return Stage.Gold;
    }

    get viewedStage(): Stage {
        return this.state.viewedStage;
    }

    get totalContacts() {
        return (
            this.state.data!.contactsReceiving +
            this.state.data!.contactsPending
        );
    }

    get completePercentageBronze() {
        return this.getCompletePercentage(Stage.Bronze);
    }

    get completePercentageSilver() {
        return this.getCompletePercentage(Stage.Silver);
    }

    get completePercentageGold() {
        return this.getCompletePercentage(Stage.Gold);
    }

    getCompletePercentage = (stage: Stage) => {
        // const items = getWizardItemsForStage(stage, this, this.userType!);
        // const total = items.length;
        // const completed = items.filter(
        //     ({ complete, exclude }) => complete || exclude
        // ).length;
        // if (total === 0) return 0;
        // //debugger;
        // return Math.ceil((completed / total) * 100);
        return 0;
    };

    get isViewedStageComplete() {
        const stage = this.viewedStage;
        const percentages = {
            [Stage.Bronze]: this.getCompletePercentage(Stage.Bronze),
            [Stage.Silver]: this.getCompletePercentage(Stage.Silver),
            [Stage.Gold]: this.getCompletePercentage(Stage.Gold)
        };
        //debugger;
        return percentages[stage] === 100;
    }

    handleDataUpdate = (update: Partial<DashboardData>) => {
        return this.setState({
            data: {
                ...this.state.data!,
                ...update
            }
        });
    };

    toggleDismissed = () => {};

    navigateViewedStageForwards = () => {
        if (this.getCompletePercentage(this.state.viewedStage) < 100) return;
        const nextStage =
            this.viewedStage === Stage.Bronze ? Stage.Silver : Stage.Gold;
        this.setState({ viewedStage: nextStage });
    };

    navigateViewedStageBackwards = () => {
        if (this.state.viewedStage === Stage.Bronze) return;
        const prevStage =
            this.state.viewedStage === Stage.Silver
                ? Stage.Bronze
                : Stage.Silver;
        this.setState({ viewedStage: prevStage });
    };
}
