export interface CompanyContactDto {
    id: string;
    name: string;
}

export interface CompanyMemberDTO {
    self: boolean;
    userId: string | null;
    contactId: string;
    name: string;
    email: string;
    roleId: CompanyRoles;
    deleted: boolean;
}

export enum CompanyRoles {
    Normal = 1,
    Admin = 2
}

export interface CompanyDTO {
    id: string;
    name: string;
    domain: string;
    logoUrl: string;
}

export interface AgencySuggestionDto {
    domain: string;
    name: string;
    email: string;
    org: string;
    notes: string;
}

export interface TimeSheetMetaDto {
    agencyId?: string;
    externalHirerId?: string;
    agencyName?: string;
    otpLink?: string;
    agencyWebsite?: string;
    organisationDomain?: string;
    organisation: {
        organisationName?: string;
        website?: string;
    };
}
