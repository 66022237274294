/* eslint-disable */
import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import styled from 'styled-components';

import Analytics from '../../services/analytics.service';

interface Props {
    id: string;
    text: string | React.ReactNode;
    placement?: 'top' | 'right' | 'bottom' | 'left';
    delay?: number;
    hide?: boolean;
    alwaysShow?: boolean;
}

/**
 * When a disabled button is wrapped inside a tooltip the tooltip does not show on hover
 * Wrapping the button in this component will resolve that
 */
const DisabledElementTooltipWrapper = styled.div`
    display: inline-block;
    cursor: not-allowed;
    button:disabled {
        pointer-events: none;
    }
`;

export default class SimpleTooltip extends React.Component<Props, {}> {
    handleEntered = () => {
        Analytics.trackEvent('simple-tooltip-hovered', { id: this.props.id });
    };

    render() {
        if (this.props.hide) return this.props.children;

        const popover = (
            <Popover id={this.props.id} style={{ textAlign: 'center', color: this.props.alwaysShow ? "black" : undefined }}>
                {this.props.text}
            </Popover>
        );
        
        if (this.props.alwaysShow) {
            return popover;
        }

        return (
            <OverlayTrigger
                trigger={['hover', 'focus']}
                placement={this.props.placement || 'top'}
                overlay={popover}
                onEntered={this.handleEntered}
                delay={this.props.delay || 0}
            >
                {this.shouldUseDisabledElementTooltipWrapped() ? (
                    <DisabledElementTooltipWrapper>
                        {this.props.children}
                    </DisabledElementTooltipWrapper>
                ) : (
                    this.props.children
                )}
            </OverlayTrigger>
        );
    }

    private shouldUseDisabledElementTooltipWrapped = () => {
        const el = React.Children.toArray(this.props.children)[0];

        return (
            el &&
            typeof el !== 'string' &&
            typeof el !== 'number' &&
            'props' in el &&
            el.props.disabled
        );
    };
}
