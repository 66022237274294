/* eslint-disable */
import React from 'react';
import styled from 'styled-components';
import Toggle from 'react-toggle';
import { connect } from 'react-redux';

import { RadioButton } from '../../components/ui-components/RadioButton';
import { Setting } from '../../api/settings/ResponseTypes';
import { Spinner } from '../../components/ui-components/Spinner';
import * as EventsState from '../../store/Events';
import { Subscribe } from '../../unstated-fork/unstated';
import DashboardContainer from '../../state-containers/dashboard.container';
import { UserTypeContainer } from '../../state-containers/user-type.container';
import SettingsProvider from './SettingProvider';

type Props = {
    settings: ReturnType<SettingsProvider['getPropsToPass']>;
    refreshOverlayMenu: typeof EventsState.actionCreators['refreshOverlayMenu'];
};

// TODO add tracking once this is finalized
class ManageAlerts extends React.Component<Props, {}> {
    async componentDidMount() {
        await this.props.settings.ensureLoaded();
    }

    render() {
        const { settings } = this.props;
        const summary = settings.getSettingAsBool(Setting.Alerts_Summary);
        const changePeriod = settings.getSetting(Setting.Alerts_ChangePeriod);
        const changePeriodCustomValue = settings.getSetting(
            Setting.Alerts_ChangePeriodCustomValue
        );
        const summaryFrequency = settings.getSetting(
            Setting.Alerts_SummaryFrequency
        );

        return (
            <div className="row" data-section="alerts">
                <div className="hpanel">
                    <div className="panel-body">
                        <h2>Manage Alerts</h2>
                        <small>
                            Configure when you receive alerts about changes in your
                            contacts' availability
                        </small>
                        {!settings.state.loaded ? (
                            LoadSpinner
                        ) : (
                            <Card>
                                <div className="layout horizontal center">
                                    <Toggle
                                        checked={
                                            !settings.getSettingAsBool(
                                                Setting.Alerts_Instant
                                            ) && !summary
                                        }
                                        onChange={this.handleToggleNone.bind(this)}
                                    />
                                    <h4>No alerts</h4>
                                </div>
                                <div className="subtext m-b">
                                    Do not receive any email alerts when your contacts
                                    change their availability
                                </div>
                                <div className="layout horizontal center">
                                    <Toggle
                                        checked={settings.getSettingAsBool(
                                            Setting.Alerts_Instant
                                        )}
                                        onChange={this.handleToggleInstant.bind(this)}
                                    />
                                    <h4>Instant availability alerts</h4>
                                </div>
                                <div className="subtext m-b-sm">
                                    Receive instant email alerts as your contacts change
                                    their availability
                                </div>
                                <div className="options m-b">
                                    <div>
                                        Only include contacts who change their
                                        availability in the following rolling period:
                                    </div>
                                    <div className="radios m-t-sm">
                                        <RadioButton
                                            value={'1'}
                                            checkedValue={changePeriod}
                                            label="5 days"
                                            onChange={(value) =>
                                                settings.updateSetting(
                                                    Setting.Alerts_ChangePeriod,
                                                    value
                                                )
                                            }
                                        />
                                        <RadioButton
                                            value={'2'}
                                            checkedValue={changePeriod}
                                            label="Week"
                                            onChange={(value) =>
                                                settings.updateSetting(
                                                    Setting.Alerts_ChangePeriod,
                                                    value
                                                )
                                            }
                                        />
                                        <RadioButton
                                            value={'3'}
                                            checkedValue={changePeriod}
                                            label="Fortnight"
                                            onChange={(value) =>
                                                settings.updateSetting(
                                                    Setting.Alerts_ChangePeriod,
                                                    value
                                                )
                                            }
                                        />
                                        <RadioButton
                                            value={'4'}
                                            checkedValue={changePeriod}
                                            label="Month"
                                            onChange={(value) =>
                                                settings.updateSetting(
                                                    Setting.Alerts_ChangePeriod,
                                                    value
                                                )
                                            }
                                        />
                                        <RadioButton
                                            value={'0'}
                                            checkedValue={changePeriod}
                                            label="Any time"
                                            onChange={(value) =>
                                                settings.updateSetting(
                                                    Setting.Alerts_ChangePeriod,
                                                    value
                                                )
                                            }
                                        />
                                        <div
                                            className="layout horizontal center"
                                            style={{ height: '20px' }}
                                        >
                                            <RadioButton
                                                value={'5'}
                                                checkedValue={changePeriod}
                                                label="Custom"
                                                onChange={(value) =>
                                                    settings.updateSetting(
                                                        Setting.Alerts_ChangePeriod,
                                                        value
                                                    )
                                                }
                                                style={{ margin: '0 5px' }}
                                            />
                                            {changePeriod == '5' && (
                                                <>
                                                    <input
                                                        type="number"
                                                        min="1"
                                                        step="1"
                                                        value={changePeriodCustomValue}
                                                        onChange={(ev) =>
                                                            settings.updateSetting(
                                                                Setting.Alerts_ChangePeriodCustomValue,
                                                                ev.target.value
                                                            )
                                                        }
                                                        style={{
                                                            width: '40px',
                                                            height: '22px',
                                                            marginLeft: '10px',
                                                            paddingLeft: '5px',
                                                            border:
                                                                '1px solid lightgray',
                                                            borderRight: 0,
                                                            cursor: 'pointer'
                                                        }}
                                                    />
                                                    <select
                                                        value={settings.getSetting(
                                                            Setting.Alerts_ChangePeriodCustomUnit
                                                        )}
                                                        onChange={(ev) =>
                                                            settings.updateSetting(
                                                                Setting.Alerts_ChangePeriodCustomUnit,
                                                                ev.target.value
                                                            )
                                                        }
                                                        style={{
                                                            width: '70px',
                                                            height: '22px',
                                                            border:
                                                                '1px solid lightgray',
                                                            borderLeft: '0',
                                                            cursor: 'pointer'
                                                        }}
                                                    >
                                                        <option value="1">{`Day${
                                                            changePeriodCustomValue !=
                                                            '1'
                                                                ? 's'
                                                                : ''
                                                        }`}</option>
                                                        <option value="2">{`Week${
                                                            changePeriodCustomValue !=
                                                            '1'
                                                                ? 's'
                                                                : ''
                                                        }`}</option>
                                                        <option value="3">{`Month${
                                                            changePeriodCustomValue !=
                                                            '1'
                                                                ? 's'
                                                                : ''
                                                        }`}</option>
                                                    </select>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    {!settings.getSettingAsBool(
                                        Setting.Alerts_Instant
                                    ) &&
                                        !summary && <div className="options-mask" />}
                                </div>
                                <div className="layout horizontal center">
                                    <Toggle
                                        checked={summary}
                                        onChange={() => this.handleToggleSummary()}
                                    />
                                    <h4>Summary of availability changes</h4>
                                </div>
                                <div className="subtext m-b-sm">
                                    Periodically receive a summary of which contacts
                                    have changed their availability within the period
                                    specified above
                                </div>
                                { (summary) &&
                                    <div className='options'>
                                         <div className="m-b-sm">
                                            Send me a summary every...
                                        </div>
                                        <div className="radios">
                                            <RadioButton
                                                value={'1'}
                                                checkedValue={summaryFrequency}
                                                label="Hour"
                                                onChange={(value) =>
                                                    settings.updateSetting(
                                                        Setting.Alerts_SummaryFrequency,
                                                        value
                                                    )
                                                }
                                            />
                                            <RadioButton
                                                value={'2'}
                                                checkedValue={summaryFrequency}
                                                label="Day (8am)"
                                                onChange={(value) =>
                                                    settings.updateSetting(
                                                        Setting.Alerts_SummaryFrequency,
                                                        value
                                                    )
                                                }
                                            />
                                            <RadioButton
                                                value={'3'}
                                                checkedValue={summaryFrequency}
                                                label="Week (Mondays 8am)"
                                                onChange={(value) =>
                                                    settings.updateSetting(
                                                        Setting.Alerts_SummaryFrequency,
                                                        value
                                                    )
                                                }
                                            />
                                        </div>
                                        </div>
                                }
                                <div
                                    className={`options expander ${
                                        summary ? 'expanded' : ''
                                    }`}
                                >
                                   
                                </div>
                            </Card>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    handleToggleNone = async () => {
        const instant = this.props.settings.getSettingAsBool(
            Setting.Alerts_Instant
        );
        const summary = this.props.settings.getSettingAsBool(
            Setting.Alerts_Summary
        );

        if (instant || summary) {
            await this.props.settings.updateSetting(
                Setting.Alerts_Instant,
                false.toString()
            );
            await this.props.settings.updateSetting(
                Setting.Alerts_Summary,
                false.toString()
            );
        } else {
            await this.props.settings.updateSetting(
                Setting.Alerts_Instant,
                true.toString()
            );
        }
        this.props.refreshOverlayMenu();
    };

    handleToggleInstant = () => {
        this.props.settings.updateSetting(
            Setting.Alerts_Instant,
            (!this.props.settings.getSettingAsBool(
                Setting.Alerts_Instant
            )).toString()
        );
        this.props.refreshOverlayMenu();
    };

    handleToggleSummary = () => {
        this.props.settings.updateSetting(
            Setting.Alerts_Summary,
            (!this.props.settings.getSettingAsBool(
                Setting.Alerts_Summary
            )).toString()
        );
        this.props.refreshOverlayMenu();
    };
}

const ConnectedManageAlerts = (props) => (
    <SettingsProvider
        // @ts-ignore
        {...props}
        render={(Aprops) => (
            <ManageAlerts
                refreshOverlayMenu={props.refreshOverlayMenu}
                settings={Aprops}
            />
        )}
    />
);

export default connect(null, {
    refreshOverlayMenu: EventsState.actionCreators.refreshOverlayMenu
})((props) => (
    <Subscribe to={[DashboardContainer, UserTypeContainer]}>
        {(dashboard: DashboardContainer, userType: UserTypeContainer) => (
            <ConnectedManageAlerts
                // @ts-ignore
                {...props}
                dashboardContainer={dashboard}
                userType={userType}
            />
        )}
    </Subscribe>
)) as any;

const Card = styled.div`
    font-size: 13.5px;
    background: white;

    > div.horizontal > h4 {
        margin-left: 15px;
    }

    > div.subtext {
        @media (min-width: 480px) {
            padding-left: 66px;
        }
    }

    div.options {
        position: relative;

        @media (min-width: 480px) {
            padding-left: 66px;
        }
        @media (max-width: 479px) {
            .radios {
                display: flex;
                flex-direction: column;
                align-items: center;

                > div {
                    width: 100px;
                    margin: 5px 0 0 0;

                    > div.layout {
                        margin: 0 !important;
                    }
                }
            }
        }

        input:focus,
        select:focus {
            outline: 0;
        }
    }

    div.options-mask {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: white;
        opacity: 0.5;
    }

    .expander {
        max-height: 0;
        overflow: hidden;
        transition: max-height 1s ease-in-out;

        &.expanded {
            max-height: 110px;
        }
    }
`;

const LoadSpinner = (
    <Card
        style={{ minHeight: '300px' }}
        className="layout horizontal center-center"
    >
        <Spinner />
    </Card>
);
