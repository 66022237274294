/* eslint-disable */
import React from 'react';
import '../../css/NotificationsPanel.css';
import NotificationAPI from '../../api/notifications/Notifications';
import ProfileApi from '../../api/profile/Profile'; 
import Utilities from 'src/Utilities';
import { UserNotification } from 'src/api/notifications/ResponseTypes';
import md5 from 'md5';
import { getValue, setValue } from 'src/db/KeyValueCache';

interface Props {
    show: boolean;
    setShow: (show: boolean) => void,
}

interface UserNotificationEnriched extends UserNotification {
    seen?: boolean;
    headerTitle?: string;
    headerDate?: Date
}

interface State {
    notifications: UserNotificationEnriched[];
    notificationsLastSeen: Date | null;
    notificationsLastId: number;
    minimal: boolean;
    muted: boolean;
    highlightedNotifications: number[];
    activeNotifications: number[]
}

let notificationCloseTimeout: any = null;
let notificationsLoading = false;
let notificationLoadAgainInterval: any = null;

export default class NotificationsPanel extends React.Component<Props, State> {

    constructor(props) {
        super(props);
        this.state = {
            notifications: [],
            notificationsLastSeen: null,
            notificationsLastId: localStorage.getItem("notificationsLastId") ? parseInt(localStorage.getItem("notificationsLastId") || "0") : 0,
            minimal: false,
            muted: localStorage.getItem("notificationsMuted") === "true",
            highlightedNotifications: [],
            activeNotifications: [],
        } as State;
    }

    reload = async (openOnNewInFull?: boolean, openOnNewInMinimal?: boolean, fromQueue?: boolean) => {
        if (notificationsLoading) {
            return;
        }

        notificationsLoading = true;

        const since = localStorage.getItem("notificationsLastPulled") || (new Date().toISOString());
        const existingNotifications = (await getValue("notifications")) || [];
        // localStorage.getItem("notificationsCache") ? JSON.parse(localStorage.getItem("notificationsCache") || "") : [];

        if (!this.props.show) {
            this.setState({
                notifications: existingNotifications
            })
        }

        let getSinceId = this.state.notificationsLastId;
        const newNotifications = await NotificationAPI.getNotificationsSinceId(getSinceId);
        const newLastNotificationsId = newNotifications.length ? newNotifications[0].id : this.state.notificationsLastId;
        this.setState({
            notificationsLastId: newLastNotificationsId
        })

        localStorage.setItem("notificationsLastId", newLastNotificationsId.toString());

        let newNotificationIds: number[] = [];

        if (!newNotifications || typeof newNotifications !== "object") {
            notificationsLoading = false;
            return;
        }

        const secondsSinceEpoch = Math.round(new Date().getTime() / 1000)

        let allNotifications: UserNotification[] = existingNotifications;
        let previousNotificationsCount = allNotifications.length;
        for (let i = 0; i < newNotifications.length; i++) {
            const element = newNotifications[i];
            if (!allNotifications.find(n => n.id === element.id)) {
                allNotifications.push(element);
                newNotificationIds.push(element.id)
            }
        }

        allNotifications.sort(Utilities.dynamicSort("createdAt"))

        let lastOccurencePerHash = {};

        for (let i = 0; i < allNotifications.length; i++) {
            // const dateSliceLimit = Math.round(new Date(allNotifications[i].createdAt).getTime() / 1000 / 60 / 5);
            if (allNotifications[i].meta) {
                allNotifications[i].metaObj = JSON.parse(allNotifications[i].meta)
            }
            const hashStr = 
                allNotifications[i].entityType + "_" + 
                allNotifications[i].entityId + "_" + 
                // @ts-ignore
                (allNotifications[i].metaObj ? allNotifications[i].metaObj?.recipientUserId : "0") + "_" + 
                // @ts-ignore
                (allNotifications[i].metaObj ? allNotifications[i].metaObj?.AgencyContactFullName : "0") + "_" +
                // @ts-ignore
                (allNotifications[i].metaObj ? (allNotifications[i].metaObj?.Response || "9") : "0") + "_";
            const hash = md5(hashStr)
            const lastOccurenceHash = lastOccurencePerHash[hash];
            // Availability Update
            if (lastOccurenceHash && allNotifications[i].entityType === "EventsUpdate") {
                if (Utilities.dateAdd(new Date(lastOccurenceHash), "minute", 5) > new Date(allNotifications[i].createdAt)) {
                    allNotifications.splice(i, 1);
                    i--;
                } 
            }
            // Offer view by recipient
            if (lastOccurenceHash && allNotifications[i].entityType === "OfferUpdate" && !allNotifications[i].metaObj?.response) {
                if (Utilities.dateAdd(new Date(lastOccurenceHash), "minute", 5) > new Date(allNotifications[i].createdAt)) {
                    allNotifications.splice(i, 1);
                    i--;
                } 
            }
            lastOccurencePerHash[hash] = new Date(allNotifications[i].createdAt)
        }

        allNotifications.reverse()

        // let allNotifications: UserNotification[] = notifications.concat(existingNotifications);
        localStorage.setItem("notificationsLastPulled", (new Date().toISOString()));
        // localStorage.setItem("notificationsCache", JSON.stringify(allNotifications));
        setValue("notifications", allNotifications);

        let notificationsWithHeaders: any[] = [];
        let lastDay: null | Date = null;
        for (let i = 0; i < allNotifications.length; i++) {
            if (allNotifications[i].message) {
                const notifDate = new Date(allNotifications[i].createdAt);
                if (!lastDay || !Utilities.isSameDay(notifDate, lastDay)) {
                    lastDay = new Date(notifDate);
                    notificationsWithHeaders.push({
                        headerTitle: Utilities.formatDate(notifDate, "ds d mms (YYYY)"),
                        headerDate: new Date(notifDate)
                    })
                }
                if (allNotifications[i].meta) {
                    allNotifications[i].metaObj = JSON.parse(allNotifications[i].meta)
                }
                notificationsWithHeaders.push(allNotifications[i])
            }
        }

        this.setState({
            notifications: notificationsWithHeaders
        })

        if (openOnNewInFull || openOnNewInMinimal || this.props.show) {
            const lastSeenNotifications = localStorage.getItem("notificationsLastSeen") ? new Date(localStorage.getItem("notificationsLastSeen") || "") : null;
    
            if (
                (lastSeenNotifications && allNotifications.find(notif => new Date(notif.createdAt) > lastSeenNotifications)) ||
                this.props.show
            ) {
                this.setShow(true);
                this.setState({
                    notificationsLastSeen: localStorage.getItem("notificationsLastSeen") ? new Date(localStorage.getItem("notificationsLastSeen") || "") : new Date(),
                    minimal: openOnNewInMinimal || false
                }, () => {
                    const newActiveNotifications = this.state.activeNotifications.concat(newNotificationIds);
                    this.setState({
                        highlightedNotifications: this.state.highlightedNotifications.concat(newNotificationIds),
                        activeNotifications: newActiveNotifications
                    })
                })
            } else {
                this.setState({
                    notificationsLastSeen: null
                })
            }

            if (openOnNewInMinimal && previousNotificationsCount < allNotifications.length && !this.state.muted && !fromQueue) {
                Utilities.beep();
            }

            // todo look into push notifications for web

            if (openOnNewInMinimal) {
                clearTimeout(notificationCloseTimeout);
                if (document.hasFocus()) {
                    this.scheduleMinimalClose(newNotificationIds);
                } else {
                    (window as any).pendingNewNotificationIdsInMinimal = newNotificationIds;
                }
            }

            setTimeout(() => {
                let highlightedNotifications = this.state.highlightedNotifications;
                for (let k = 0; k < newNotificationIds.length; k++) {
                    highlightedNotifications.splice(highlightedNotifications.indexOf(newNotificationIds[k]), 1);
                }
                this.setState({
                    highlightedNotifications: highlightedNotifications
                })
            }, 1000)
        }

        notificationsLoading = false;

        if (newNotifications.length && getSinceId !== 0) {
            this.reload(openOnNewInFull, openOnNewInMinimal, true);
        }
    }

    scheduleMinimalClose = (newNotificationIds) => {
        notificationCloseTimeout = setTimeout(() => {
            this.setShow(false)
            let activeNotifications = this.state.activeNotifications;
            for (let k = 0; k < newNotificationIds.length; k++) {
                activeNotifications.splice(activeNotifications.indexOf(newNotificationIds[k]), 1);
            }
            this.setState({
                minimal: false,
                activeNotifications: activeNotifications
            })
        }, 6000)
    }

    componentDidMount = () => {
        this.reload(true);
        (window as any).reloadNotificationOnNew = async () => {
            this.reload(false, true);
        }
        (window as any).openNotificationsPanel = async () => {
            this.setShow(true);
        }
        window.onfocus = () => {  
            if ((window as any).pendingNewNotificationIdsInMinimal) {
                this.scheduleMinimalClose((window as any).pendingNewNotificationIdsInMinimal);
                (window as any).pendingNewNotificationIdsInMinimal = null;
            }
        }          
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        (window as any).notificationsPanelOpen = this.props.show;
    }

    setShow(open: boolean) {
        if (!open) {
            localStorage.setItem("notificationsLastSeen", (new Date()).toISOString())
        }
        this.props.setShow(open);
    }

    render() {
        if (!this.props || !this.props.show) {
            return null;
        }

        return (
            <div className='notifications-panel-wrapper' data-minimal={this.state.minimal ? "true" : "false"}>
                <div className='close-wrapper' onClick={() => {
                    this.setState({
                        notificationsLastSeen: null
                    })
                    this.setShow(false);
                }}>
                    <div className='close-button'>
                        <i className="fas fa-times-circle"></i>
                        {/* <p>Close</p> */}
                    </div>
                </div>
                <div className='close-button-mobile' onClick={() => {
                    this.setState({
                        notificationsLastSeen: null
                    })
                    this.setShow(false);  
                }}>
                    <i className="fas fa-times-circle"></i>
                </div>
                <div className='notifications-wrapper'>
                    { (this.state.notificationsLastSeen) &&
                        <h2>
                            New Notifications
                            <i 
                                className="fas fa-times-circle"
                                onClick={() => {
                                    this.setState({
                                        notificationsLastSeen: null
                                    })
                                    this.setShow(false);
                                }}
                            ></i>
                        </h2>
                    }
                    { (this.state.minimal) &&
                        <div className='options'>
                            <button onClick={() => {
                                this.setState({
                                    muted: !this.state.muted
                                }, () => {
                                    localStorage.setItem("notificationsMuted", this.state.muted ? "true" : "false")
                                })
                            }}>
                                <i className={this.state.muted ? "fas fa-volume-up" : "fas fa-volume-mute"}></i>
                                <span>{this.state.muted ? "Unmute" : "Mute"}</span>
                            </button>
                            <button onClick={() => {
                                this.setState({
                                    minimal: false,
                                    notificationsLastSeen: null
                                })
                                clearTimeout(notificationCloseTimeout);
                            }}>
                                <i className="fas fa-expand-alt"></i>
                                <span>Show All</span>
                            </button>
                            <button onClick={() => {
                                this.setState({
                                    minimal: false
                                })
                                clearTimeout(notificationCloseTimeout);
                                this.setShow(false)
                            }}>
                                <i className="fas fa-times"></i>
                                <span>{"Close"}</span>
                            </button>
                        </div>
                    }
                    <div className='notifications-list'>
                        { this.state.notifications.map(notification => {
                            if (notification.headerTitle) {
                                if (this.state.notificationsLastSeen && notification.headerDate && this.state.notificationsLastSeen > notification.headerDate)
                                    return null;

                                return (
                                    <div>
                                        <h2>{notification.headerTitle}</h2>
                                    </div>
                                )
                            } else {
                                if (this.state.activeNotifications.indexOf(notification.id) === -1 && this.state.notificationsLastSeen && this.state.notificationsLastSeen > new Date(notification.createdAt))
                                    return null;

                                return (
                                    <div
                                        data-highlighted={this.state.highlightedNotifications.indexOf(notification.id) !== -1}
                                        className='notification' 
                                        data-id={notification.id}
                                        onClick={(e) => {
                                            if (notification.entityType === "ContactUpdate" || notification.entityType === "EventsUpdate") {
                                                window.open("/contact/" + notification.metaObj?.ContactId)
                                            } else if (notification.entityType === "OfferUpdate") {
                                                if ((window as any).loadOfferIntoModal) {
                                                    (window as any).loadOfferIntoModal(notification.metaObj?.OfferId || notification.metaObj?.offerId);
                                                    this.setState({
                                                        notificationsLastSeen: null
                                                    })
                                                    this.setShow(false);
                                                } else {
                                                    window.open("/offers/view/" + (notification.metaObj?.OfferId || notification.metaObj?.offerId))
                                                }
                                            } else if (notification.entityType === "FileShare") {
                                                window.open("/contact/" + notification.metaObj?.ContactId + "#file-" + notification.entityId)
                                            } else if (notification.entityType === "FolderShare") {
                                                window.open("/contact/" + notification.metaObj?.ContactId + "#folder-" + notification.entityId)
                                            } else if (notification.entityType === "Referral") {
                                                window.open("/agencies")
                                            } else if (notification.entityType === "ReferralOutcome") {
                                                // Nothing to do
                                            }
                                        }}
                                    >
                                        <div className='invoker'>
                                            <img src={ProfileApi.getProfileImageUrl(notification?.metaObj?.UserId || notification?.metaObj?.userId || notification?.metaObj?.AgencyContactUserId || "undefined")} />
                                            <div className={'icon ' + 
                                                (
                                                    (notification.entityType === "ContactUpdate" && notification.metaObj?.ActionTaken === 2) || 
                                                    (notification.entityType === "OfferUpdate" && notification.metaObj?.response === 2) ||
                                                    (notification.entityType === "OfferUpdate" && notification.metaObj?.AgencyContactFullName && notification.metaObj?.Response === 2)
                                                    ? 'red' : 
                                                        (
                                                            (notification.entityType === "OfferUpdate" && notification.metaObj?.AgencyContactFullName && !notification.metaObj?.Response)
                                                        ) ? 'blue' : 
                                                            ''
                                                )}>
                                                { (notification.entityType === "EventsUpdate") &&
                                                    <i className="fas fa-calendar-plus"></i>
                                                }
                                                { (notification.entityType === "ContactUpdate" && notification.metaObj?.ActionTaken === 1) &&
                                                    <i className="fas fa-user-check"></i>
                                                }
                                                { (notification.entityType === "ContactUpdate" && notification.metaObj?.ActionTaken === 2) &&
                                                    <i className="fas fa-user-alt-slash"></i>
                                                }
                                                { (notification.entityType === "OfferUpdate" && notification.metaObj?.response === 1) &&
                                                    <i className="fas fa-calendar-check"></i>
                                                }
                                                { (notification.entityType === "OfferUpdate" && !notification.metaObj?.AgencyContactFullName && !notification.metaObj?.response) &&
                                                    <i className="fas fa-eye"></i>
                                                }
                                                {/* { (notification.entityType === "OfferUpdate" && !notification.metaObj?.AgencyContactFullName && !notification.metaObj?.response) &&
                                                    <i className="fas fa-paper-plane"></i>
                                                } */}
                                                { (notification.entityType === "OfferUpdate" && !notification.metaObj?.AgencyContactFullName && notification.metaObj?.response === 2) &&
                                                    <i className="fas fa-calendar-times"></i>
                                                }
                                                { (notification.entityType === "OfferUpdate" && notification.metaObj?.AgencyContactFullName && !notification.metaObj?.Response) &&
                                                    <i className="fas fa-paper-plane"></i>
                                                }
                                                { (notification.entityType === "OfferUpdate" && notification.metaObj?.AgencyContactFullName && notification.metaObj?.Response === 2) &&
                                                    <i className="fas fa-times"></i>
                                                }
                                                { (notification.entityType === "OfferUpdate" && notification.metaObj?.AgencyContactFullName && notification.metaObj?.Response === 1) &&
                                                    <i className="fas fa-check"></i>
                                                }
                                                { (notification.entityType === "FileShare") &&
                                                    <i className="fas fa-file"></i>
                                                }
                                                { (notification.entityType === "FolderShare") &&
                                                    <i className="fas fa-folder"></i>
                                                }
                                                { (notification.entityType === "Referral" || notification.entityType === "ReferralOutcome") &&
                                                    <i className="fas fa-user-check"></i>
                                                }
                                            </div>
                                        </div>
                                        <div className='details'>
                                            <label>{Utilities.formatDate(new Date(notification.createdAt), "HH:MM")}</label>
                                            {notification.message}
                                        </div>
                                    </div>
                                )
                            }
                        }) }
                        { (this.state.notificationsLastSeen && !this.state.minimal) &&
                            <button onClick={() => {
                                this.setState({
                                    notificationsLastSeen: null
                                })
                            }}>Show All Notifications</button>
                        }
                    </div>
                </div>
            </div>
        );
    }
}
