/* eslint-disable */
import { Moment } from 'moment';
import { UserFile } from 'src/store/contacts/Models';

import { ObjectMetadataDto } from '../BaseResponseTypes';
import { AgencyNotificationConfig } from '../dashboard/ResponseTypes';
import { UserThirdParty } from '../profile/ResponseTypes';
import { TimesheetResponseType } from '../timesheets/ResponseTypes';

export interface MutualAgencyDto {
    userContactId: number;
    agencyContactFullName: string;
    agencyName: string;
    agencyOrganisationId: string;
    workerId: string;
    workerFullName: string;
    timeout?: number
    hirerAgencyContactDomain?: string;
}

export interface CreateOfferDto {
    title: string;
    sendSMS: boolean;
    description: string;
    locationPlaceId?: string;
    recipients: string[];
    deadline: Date;
    events: PendingEventDto[];
    correctedAgencyNotifications?: AgencyNotificationConfig;
}

export interface WorkerRating {
    stars: number,
    publicComment: string,
    privateComment: string,
    changed?: boolean;
}

export interface RotaDto {
    hasMoreContacts: boolean;
    dates: RotaDay[];
    totalHours?: number
    totalGrossPay?: number
    totalGrossCharge?: number
    currency?: string;
    workers?: {
        totalGrossCharge: number | null;
        firstDate: string
        firstHour: number
        firstName: string
        id: string
        lastDate: string
        lastHour: number
        lastName: string
        totalHours: number,
        externalId: string,
        rating?: WorkerRating,
        providerOrgRating?: number
    }[];
    hirers?: RotaHirerWithDates[],
    groups: {
        id: string;
        title: string;
        hours: number;
    }[];
    endOfRangeDate?: string;
    firstDate?: string;
    firstHour?: number;
    lastDate?: string;
    lastHour?: number;
    startOfRangeDate?: string;
    agencyContactEmail?: string;
    terminology?: Terminology[];
    responseTypeId?: TimesheetResponseType;
    ratings?: Rating[];
    workerFirstName?: string;
    workerLastName?: string;
    workerExternalId?: string;
    workerId?: string;
    workerHeadline?: string;
    workerImageUrl?: string;
    agencyId?: string;
    agencyName?: string;
}

export interface Rating {
    id: number,
    recipientContactId: string,
    ratingType: number,
    ratingDirection: RatingDirection,
    stars: number,
    publicComment: string,
    privateComment: string,
    published: true,
    providerOrgId: string,
    raterExternalId: string,
    recipientExternalId: string,
    raterContactId: string,
    raterUserId?: string,
    raterFirstName?: string,
    raterLastName?: string,
    raterExternalContactName? : string,
    recipientUserId?: string,
    recipientFirstName?: string,
    recipientLastName?: string,
    recipientOrganisationName?: string,
    recipientExternalContactName? : string,
    raterOrganisationName?: string,
    recipientOrgId?: string,
    raterOrgId?: string,
    updatedAt?: string,
    updatedAtDate?: Date
    raterExternalOrgName?: string,
    raterExternalOrgDomain?: string,
    providerOrgName?: string,
    raterInternalContactId: string,
    recipientApproved: boolean,
    moderatorApproved?: boolean,
    recipientHidden: boolean,
    recipientExternalOrgDomain?: string,
    recipientExternalOrgName?: string,
    recipientExternalOrgId: string;
    recipientUserReported?: boolean;
    raterUserReported?: boolean;
}

export enum RatingDirection {
    Worker_HirersOrganisation = 1,
    Worker_OrganisationsHirer = 2,
    Worker_Agency = 3,
    Worker_AgencysConsultant = 4,
    HirersOrganisation_Worker = 5,
    HirersOrganisation_Agency = 6,
    HirersOrganisation_AgencysConsultant = 7,
    OrganisationsHirer_Worker = 8,
    OrganisationsHirer_Agency = 9,
    OrganisationsHirer_AgencysConsultant = 10,
    Agency_HirersOrganisation = 11,
    Agency_Worker = 12,
    Agency_OrganisationsHirer = 13,
    AgencysConsultant_HirersOrganisation = 14,
    AgencysConsultant_Worker = 15,
    AgencysConsultant_OrganisationsHirer = 16
}

export interface Terminology {
    minHours: number;
    maxHours: number;
    name: string;
    color?: string;
    merge?: boolean;
}

export interface RotaDay {
    date: string;
    events: RotaEvent[];
}

interface RotaEvent {
    title: string;
    description: string;
    locationId: string;
    offerId: string;
    start: string;
    end: string;
    workers: RotaWorkerDto[];
    hirers: RotaHirerDto[];
    offer: RotaOfferDto;
}

interface RotaWorkerDto {
    id: string;
    firstName: string;
    lastName: string;
    offerConfirmationResponse: OfferConfirmationResponseTypes
}

export interface RotaHirerDto {
    organisationDomain: string
    organisationExternalId: string
    organisationId: string
    organisationName: string
    totalHours: number
    totalGrossPay: number
    totalGrossCharge: number
    providerOrgRating?: number
    rating: WorkerRating
}

export interface RotaHirerWithDates extends RotaHirerDto {
    firstDate: string
    firstHour: number
    lastDate: string
    lastHour: number
}

interface RotaOfferDto {
    statusId: number;
    title: string;
    externalHirerName?: string;
    externalSubjectId?: string;
    externalSubjectName: string;
}

export interface PendingEventDto {
    title: string;
    notes: string;
    eventType: number;
    start: string;
    end: string;
    repeatTypeId: number;
    repeatUntil?: Moment;
    repeatForever: boolean;
    repeatType: string;
}

interface BaseOfferDto {
    id: string;
    title: string;
    description: string;
    createdAt: string;
    withdrawn: boolean;
    complete: boolean;
    locationPlaceId?: string;
    locationPlaceName?: string;
    locationFriendlyAddress?: string;
    locationFriendlyName?: string;
    files?: UserFile[];
}

export interface UserOfferDto extends BaseOfferDto {
    response: OfferResponseTypes;
    respondedOn: string;
    confirmation: OfferConfirmationResponseTypes;
    events: PendingEventDto[];
    addedToSchedule: boolean;
    addedToScheduleDeclined: boolean;
    skipWorkerEngagement: boolean;
    withdrawnAcknowledged: boolean;
    metadata: ObjectMetadataDto;
    address?: FullAddress;
}

export interface FullAddress {
    address1: string;
    address2?: string;
    address3?: string;
    town: string;
    county: string;
    postcode: string;
}

export interface OfferInfoDto extends BaseOfferDto {
    isFavourite: boolean;
    isHistoric: boolean;
    events: PendingEventDto[];
    recipients: OfferRecipientDto[];
    locationPlaceId?: string;
    favourite?: boolean;
    agencyResponses: OfferAgencyResponses[];
    externalHirerId?: string
}

export interface OfferInfoDtoWithPaging {
    data: OfferInfoDto[],
    paging: {
        hasMorePages: boolean,
        page: number,
        totalPages: number,
        totalRows: number,
    }
}

export interface OfferAgencyResponses {
    organisationId: string;
    organisationName: string;
    organisationDomain?: string;
    userContactName: string;
    userContactId: number;
    userId?: string;
    responseType: OfferAgencyResponsesType;
    scheduledAt?: string;
    emailSent?: boolean;
    cancelled?: boolean;
    respondedAt?: string;
}

export enum OfferAgencyResponsesType {
    NoResponse = 0,
    Yes = 1,
    No = 2
}

export interface EventStateDto {
    id: number;
    start: string;
    end: string;
    deleted: boolean;
}

export interface OfferEventDeletedDto {
    offerId: string;
    offerTitle: string;
    workerId: string;
}

export interface OfferRecipientDto {
    id: number;
    recipientUserId: string;
    recipientContactId: string;
    recipientName: string;
    reported?: boolean;
    confirmation: OfferConfirmationResponseTypes;
    response: OfferResponseTypes;
    eventStates?: EventStateDto[];
    respondedOn: string;
    preferredAgencies: UserThirdParty[];
    allowDirectEngagement: boolean;
    smsStatus?: SmsStatusTypes;
    emailStatus?: EmailDeliveryStatusTypes;
    sendSMS?: boolean;
    respondByDeadline?: string;
    firstOpenedAt?: string;
    autoDeclined: boolean;
}

export interface OfferRecipientDtoChange extends OfferRecipientDto {
    offerId?: string;
    offerTitle?: string;
    updateType?: string;
    agencyResponse?: {
        ResponseType: OfferAgencyResponsesType,
        UserContactId: number,
    }
}

export enum SmsStatusTypes {
    Unknown = 0,
    OK = 1,
    MessageQueued = 2,
    MessageSent = 3,
    MessageDelivered = 4,
    ErrorWithRequest = 5,
    UnableToDeliver = 6,
    RoutingError = 7,
    MessageExpired = 8,

    LoginInvalid = 50,
    AuthParameterMissing = 51,
    AuthParameterInvalid = 52,
    ConnectionNotSecure = 53,
    InsufficientCredit = 54,
    InvalidNumber = 55,
    InvalidRequest = 56,
    NoTo = 57,
    DailyAllowanceExceeded = 58,
    MonthlyAllowanceExceeded = 59,
    EnternalError = 60,
    GatewayError = 61,

    NoLongerRequired = 90,
    
    InitialSendFailed = 100
}

export enum EmailDeliveryStatusTypes {
    Unknown = 0,
    Delivered = 1,
    Bounced = 2,
    Dropped = 3,
    Deferred = 4,
    Unsubscribed = 5,
    Spam = 6
}

export interface FavouriteOfferDTO {
    id: string;
    title: string;
    description: string;
    locationPlaceId?: string;
    locationPlaceName?: string;
    locationFriendlyAddress?: string;
    locationFriendlyName?: string;
    recipients: FavouriteOfferRecipientDTO[];
    startTime: string;
    endTime: string;
}

interface FavouriteOfferRecipientDTO {
    id: number;
    recipientUserId: string;
    recipientName: string;
    reported?: boolean;
    preferredAgencies: UserThirdParty[];
    allowDirectEngagement: boolean;
}

export enum OfferResponseTypes {
    Pending = 0,
    Yes = 1,
    No = 2
}

export enum OfferConfirmationResponseTypes {
    NotSpecified = 0,
    Confirmed = 1,
    Rejected = 2,
    Unsuccessful = 3
}
