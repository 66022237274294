/* eslint-disable */
import React from 'react';
import InternalTracker from 'src/InternalTracker';
import SubscriptionsApi from 'src/api/subscriptions/Subscriptions';
import FullScreenLoader from 'src/components/ui-components/FullScreenLoader';

export default class Settings extends React.Component<{}, {
    status: "loading" | "success" | "error"
}> {
    constructor(props) {
        super(props);
        this.state = {
            status: "loading"
        };
    }

    async componentDidMount() {
        const redirectTo = "/organisation/subscriptions/sms";

        InternalTracker.trackEvent("Payment Completed");

        if (localStorage.getItem("pendingPurchaseId")) {
            const res = await SubscriptionsApi.redeemPurchase(localStorage.getItem("pendingPurchaseId") || "").catch(e => {
                this.setState({
                    status: "error"
                })
                setTimeout(() => {
                    window.location.href = redirectTo
                }, 2000);
                localStorage.removeItem("pendingPurchaseId");
            })
            if (res) {
                // this.setState({
                //     status: "success"
                // })
                // setTimeout(() => {
                    localStorage.setItem("successMessage", "subscriptions-sms|" + localStorage.getItem("pendingPurchaseQuantity") + " SMS credit has been added to your account");
                    window.location.href = redirectTo
                // }, 2000);
                localStorage.removeItem("pendingPurchaseId");
            }
        } else {
            localStorage.removeItem("pendingPurchaseId");
            window.close();
        }
    }

    render() {
        return (
            <div className='payment-completed'>
                <FullScreenLoader 
                    success={this.state.status === "success"} 
                    loadingMessage={this.state.status === "error" ? null : this.state.status === "success" ? "Purchase Completed" : "Finalizing your Purchase" }
                />
            </div>
        );
    }
}
