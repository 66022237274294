/* eslint-disable */
import React from 'react';
import { Route, RouteProps } from 'react-router-dom';

import Analytics from './services/analytics.service';
import InternalTracker from './InternalTracker';

interface TrackedRouteProps extends RouteProps {
    title?: string;
}

export class TrackedRoute extends React.Component<
    TrackedRouteProps,
    { referrer?: string }
> {
    state = {
        referrer: document.referrer
    };

    componentDidMount() {
        this.onRouteChange();
    }

    componentDidUpdate(prevProps: TrackedRouteProps) {
        if (prevProps.path !== this.props.path) {
            const { pathname, search } = prevProps.location || {};
            const referrer =
                window.location.protocol +
                '//' +
                window.location.host +
                '/' +
                pathname +
                search;

            this.setState({ referrer });
            this.onRouteChange();
        }
    }

    private onRouteChange = () => {
        this.setTitle();
        this.track();
    };

    private setTitle = () => {
        const titleProp = (this.props.title || 'Portal | updatedge').trim();
        const title = titleProp.endsWith(' | updatedge')
            ? titleProp
            : `${titleProp} | updatedge`;
        document.title = title;
    };

    private track = () => {
        const { path } = this.props;
        // if (path && Analytics !== undefined && Matomo !== undefined) {
        //     // Analytics.trackPageView(Array.isArray(path) ? path[0] : path);
        //     // Matomo.track({ data: ['deleteCustomVariables', 'page'] });
        //     // Matomo.track({ data: ['setGenerationTimeMs', 0] });
        //     // Matomo.track({ data: ['setReferrerUrl', this.state.referrer] });
        //     // Matomo.trackPageView({});
        // }
    };

    render() {
        return <Route {...this.props} />;
    }
}
