import React from 'react';

import Tasks from './Tasks';
import { TaskItem } from '../overlayMenu/OverlayMenu';

interface State {}
interface Props {
    styleProps: React.CSSProperties;
    tasks: TaskItem[];
    dashboardReloader: () => void;
}

export class ModalProgressGuide extends React.Component<Props, State> {
    state = {} as State;
    private TasksRef: typeof Tasks | null | undefined;

    public onClose() {
        if (this.TasksRef) {
            this.TasksRef.onClose();
        }
    }
    ////////////////////////////////
    //////////////////////////////// Render:
    ////////////////////////////////
    public render() {
        return (
            <div
                style={{
                    ...this.props.styleProps,
                    borderRadius: '5px'
                }}
            >
                <div
                    className="row"
                    style={{
                        // height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        borderRadius: '5px',
                        position: 'absolute',
                        bottom: 0,
                        left: 0
                    }}
                >
                    {/* <div
                        className="hpanel pageHeader"
                        style={{ marginBottom: 0, borderRadius: '5px' }}
                        onClick={() => this.onClose()}
                    >
                        <div
                            className="panel-body"
                            style={{
                                borderRadius: '5px',
                                border: 'none',
                                backgroundColor: 'white',
                                paddingLeft: '4rem'
                            }}
                        >
                            <div className="glyph pull-right">
                                <Glyphicon
                                    glyph="cloud"
                                    bsClass="glyphicon-6x text-primary"
                                />
                            </div>
                            <h2 className="font-light m-b-xs">Guide</h2>
                            <small>Complete each step for best value.</small>
                        </div>
                    </div> */}
                    <Tasks
                        taskItems={this.props.tasks}
                        // ref={(node) => (this.TasksRef = node)}
                        childRef={(ref) => (this.TasksRef = ref)}
                        onClose={this.onClose}
                        dashboardReloader={this.props.dashboardReloader}
                        section="overlay"
                    />
                </div>
            </div>
        );
    }
}
