import React from 'react';

function SvgAssignment(props) {
    return (
        <svg height="1em" viewBox="0 0 512 512" width="1em" {...props}>
            <path
                d="M512 256c0 65.902-24.91 126.004-65.828 171.375C399.316 479.335 331.472 512 256 512s-143.316-32.664-190.172-84.625C24.91 382.004 0 321.902 0 256S24.91 129.996 65.828 84.625a255.576 255.576 0 0130.219-28.504 247.622 247.622 0 0110.23-7.793c23.22-16.773 49.352-29.738 77.5-37.992C206.68 3.606 230.91 0 256 0s49.32 3.605 72.223 10.336c46.027 13.5 86.675 39.59 117.949 74.289C487.09 129.996 512 190.098 512 256zm0 0"
                fill="#66baed"
            />
            <path
                d="M402.914 10.336H109.098c-23.899 0-43.27 19.371-43.27 43.258v373.781C112.684 479.335 180.528 512 256 512s143.316-32.664 190.172-84.625V53.594c0-23.887-19.363-43.258-43.258-43.258zM113.027 88.418c-11.39 0-20.617-9.227-20.617-20.613 0-4.336 1.34-8.371 3.637-11.684a20.57 20.57 0 0110.23-7.793 20.32 20.32 0 016.75-1.14c11.387 0 20.614 9.226 20.614 20.617 0 11.386-9.227 20.613-20.614 20.613zm0 0"
                fill="#dde8ed"
            />
            <path
                d="M389.715 195.594c0 73.847-59.867 133.71-133.715 133.71s-133.71-59.863-133.71-133.71S182.151 61.879 256 61.879s133.715 59.867 133.715 133.715zm0 0"
                fill="#fcf9f4"
            />
            <path
                d="M120.84 68.332c-.082 1.297-2.223 32.152-9.485 67.219-10.011 48.36-24.535 79.765-43.168 93.37-7.218 5.278-14.992 7.923-23.195 7.923-3.262 0-6.582-.418-9.969-1.254-10.238-2.52-14.46-8.934-16.207-13.867-6.332-17.942 8.602-49.946 47.012-100.688v26.512c-33.773 47.742-33.992 63.976-32.234 68.96.5 1.403 1.504 2.958 5.18 3.868 7.316 1.809 13.91.46 20.18-4.105 10.573-7.735 25.597-28.56 37.05-83.895 7.055-34.035 9.183-64.797 9.207-65.098.293-4.328 4.031-7.586 8.336-7.293a7.829 7.829 0 017.293 8.348zm0 0"
                fill="#f74f4f"
            />
            <path
                d="M113.723 380.613H398.27v20.371H113.723zm0 0M113.723 432.855H398.27v20.375H113.723zm0 0"
                fill="#3d4159"
            />
            <path
                d="M196.906 222.203c0 10.113-7.386 18.496-17.058 20.055a20.285 20.285 0 01-3.254.258c-11.227 0-20.317-9.09-20.317-20.317 0-11.222 9.09-20.316 20.317-20.316 1.11 0 2.195.09 3.254.262 9.672 1.558 17.058 9.945 17.058 20.058zm0 0"
                fill="#f6caae"
            />
            <path
                d="M196.906 222.203c0 10.113-7.386 18.496-17.058 20.055a75.53 75.53 0 01-2.696-20.055c0-6.941.938-13.668 2.696-20.055 9.672 1.555 17.058 9.942 17.058 20.055zm0 0"
                fill="#eab599"
            />
            <path
                d="M183.852 222.203a7.259 7.259 0 01-7.258 7.258 7.26 7.26 0 110-14.52 7.26 7.26 0 017.258 7.262zm0 0"
                fill="#eab599"
            />
            <path
                d="M316.758 222.203c0 10.113 7.387 18.496 17.062 20.055 1.059.172 2.145.258 3.254.258 11.223 0 20.317-9.09 20.317-20.317 0-11.222-9.094-20.316-20.317-20.316-1.11 0-2.195.09-3.254.262-9.675 1.558-17.062 9.945-17.062 20.058zm0 0"
                fill="#f6caae"
            />
            <g fill="#eab599">
                <path d="M316.758 222.203c0 10.113 7.387 18.496 17.062 20.055a75.53 75.53 0 002.696-20.055c0-6.941-.938-13.668-2.696-20.055-9.675 1.555-17.062 9.942-17.062 20.055zm0 0" />
                <path d="M332.422 182.625v28.781c0 37.614-27.48 68.82-63.457 74.61l-.371.058a74.295 74.295 0 01-11.762.914c-41.742 0-75.586-33.84-75.586-75.582v-28.785c0-8.789 1.5-17.234 4.27-25.082 10.34-29.418 38.367-50.508 71.316-50.508h.004c4 0 7.937.309 11.77.914 36.156 5.653 63.816 36.938 63.816 74.68zm0 0" />
                <path d="M344.336 222.203a7.26 7.26 0 01-7.262 7.258 7.262 7.262 0 01-7.262-7.258 7.263 7.263 0 0114.524 0zm0 0" />
            </g>
            <path
                d="M332.422 182.625v28.781c0 37.614-27.48 68.82-63.457 74.61l-.371.058c-36.157-5.648-63.809-36.933-63.809-74.668v-28.781c0-37.742 27.66-69.027 63.82-74.676 36.157 5.649 63.817 36.934 63.817 74.676zm0 0"
                fill="#f6caae"
            />
            <path
                d="M240.734 207.004a8.573 8.573 0 01-8.574 8.574c-4.734 0-8.574-3.84-8.574-8.574s3.84-8.574 8.574-8.574a8.573 8.573 0 018.574 8.574zm0 0M290.082 207.004a8.576 8.576 0 01-8.574 8.574c-4.738 0-8.578-3.84-8.578-8.574s3.84-8.574 8.578-8.574a8.576 8.576 0 018.574 8.574zm0 0"
                fill="#3d4159"
            />
            <path
                d="M238.988 205.492a3.673 3.673 0 11-7.347-.003 3.673 3.673 0 017.347.003zm0 0M288.754 205.492a3.673 3.673 0 11-7.347-.003 3.673 3.673 0 017.347.003zm0 0"
                fill="#fff"
            />
            <path
                d="M265.41 225.23h-17.152a3.7 3.7 0 010-7.398h17.152c2.04 0 3.695 1.656 3.695 3.7s-1.656 3.698-3.695 3.698zm0 0M256.832 248.633c-6.328 0-12.656-1.586-18.426-4.758a3.362 3.362 0 113.239-5.895c9.511 5.23 20.867 5.23 30.378 0a3.362 3.362 0 113.239 5.895c-5.77 3.172-12.098 4.758-18.43 4.758zm0 0"
                fill="#eab599"
            />
            <path
                d="M332.422 156.5v29.484h-14.07v-23.09l-101.547-18.867-21.489-3.996v45.953H181.25V156.5c0-33.613 27.242-60.855 60.848-60.855h29.476c.801 0 1.602.015 2.399.054 32.488 1.25 58.449 27.989 58.449 60.801zm0 0"
                fill="#dd3033"
            />
            <path
                d="M332.422 156.5v29.484h-14.07v-23.09l-101.547-18.867c5.593-26.875 28.91-47.238 57.168-48.328 32.488 1.25 58.449 27.989 58.449 60.801zm0 0"
                fill="#f74f4f"
            />
        </svg>
    );
}

export default SvgAssignment;
