/* eslint-disable */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as React from 'react';
import styled from 'styled-components';
import { ToastType } from 'react-toastify';
import * as Notifications from '../../store/Notifications';

import Splash from '../../components/ui-components/Splash';
import theme from '../../css/theme';
import AppConfig from '../../components/config/Config';
import AdminAPI from '../../api/admin/Admin';
import { CompiledUser, Offer } from '../../api/admin/ResponseTypes';
import DataTable, { createTheme } from 'react-data-table-component';
import Utilities from '../../Utilities';
import { Modal } from 'react-bootstrap';
import UserApi from '../../api/user/User';
import { ToastContainer } from 'react-toastify';

interface State {
    loading: boolean;
    users?: null | CompiledUser[];
    selectedUser?: null | CompiledUser
}

class Users extends React.Component<{}, State> {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            users: null,
            selectedUser: null
        };
    }

    async componentDidMount() {
        await this.refresh();
        let contentDOM = document.querySelector<HTMLElement>('.content');
        if (contentDOM) {
            contentDOM.style.maxWidth = '100%';
        }
    }
    
    async refresh() {
        let [
            users,
            userSettings,
            organisations,
            offers,
            workers,
            timepresets,
            membersCount,
            lastActivities,
            userLists
        ] = await Promise.all([
            AdminAPI.getAllUsers(),
            AdminAPI.getAllUserSettings(),
            AdminAPI.getAllOrganisations(),
            AdminAPI.getAllOffersCount(),
            AdminAPI.getAllWorkersCount(),
            AdminAPI.getAllTimePresets(),
            AdminAPI.getAllMembersCount(),
            AdminAPI.getLastActive(),
            AdminAPI.getAllLists()
        ]);

        console.log(lastActivities, "@@ @");
        // @ts-ignore
        users = users.data; userSettings = userSettings.data; organisations = organisations.data;

        users.map((user, k) => {
            console.log("MAP " + k)
            user.name = user.firstName + ' ' + user.lastName;
            if (user.organisationId) {
                user.organisation = organisations.find(
                    (org) => org.id === user.organisationId
                );
            }
            // @ts-ignore
            user.settings = userSettings.filter(setting => setting.UserId === user.id);
            user.profileCompleted = user.phoneNumber ? '✅' : '❌';
            user.organisationCompleted = user.organisationName ? user.organisationName : '❌';
            user.typeStr = user.userTypeId === 2 ? "Hirer" : user.userTypeId === 3 ? "Agency" : user.userTypeId === 1 ? "Worker" : "-"

            let offersCreatedRecord = offers.data.find(offer => offer.CreatedByUserId === user.id && offer.type === 'total');
            user.offersCreated = offersCreatedRecord ? offersCreatedRecord.count : 0;
            let offersConfirmedRecord = offers.data.find(offer => offer.CreatedByUserId === user.id && offer.type === 'complete');
            user.offersConfirmed = offersConfirmedRecord ? offersConfirmedRecord.count : 0;
            let offersWithdrawnRecord = offers.data.find(offer => offer.CreatedByUserId === user.id && offer.type === 'withdrawn');
            user.offersWithdrawn = offersWithdrawnRecord ? offersWithdrawnRecord.count : 0;

            let workersInvitedRecord = workers.data.find(offer => offer.UserId === user.id && offer.type === 'total');
            user.workersInvited = workersInvitedRecord ? workersInvitedRecord.count : 0;
            let workersInviteAcceptedRecord = workers.data.find(offer => offer.UserId === user.id && offer.type === 'accepted');
            user.workersInvitAccepted = workersInviteAcceptedRecord ? workersInviteAcceptedRecord.count : 0;
            let workersInvitePendingRecord = workers.data.find(offer => offer.UserId === user.id && offer.type === 'pending');
            user.workersInvitePending = workersInvitePendingRecord ? workersInvitePendingRecord.count : 0;
            let workersInviteRejected = workers.data.find(offer => offer.UserId === user.id && offer.type === 'rejected');
            user.workersInviteRejected = workersInviteRejected ? workersInviteRejected.count : 0;

            user.signupDate = Utilities.formatDate(new Date(user.createdAt || ''), "YYYY-MM-DD")
    
            // @ts-ignore
            let diffInMs: number = new Date() - new Date(user.createdAt || "");
            user.daysSinceSignup = Math.ceil(diffInMs / (1000 * 60 * 60 * 24)) - 1;

            if (lastActivities && lastActivities.data) {
                user.lastActiveDate = Utilities.formatDate(new Date( lastActivities.data.find(activity => activity.userId === user.id)?.lastPageViewOn || "" ), "YYYY-MM-DD");
            }
    
            // @ts-ignore
            diffInMs = user.lastActiveDate ? new Date() - new Date(user.lastActiveDate || "") : 1000000000;
            user.daysSinceLastActive = Math.ceil(diffInMs / (1000 * 60 * 60 * 24)) - 1;

            if (user.settings) {
                user.printingViewed = user.settings.find(setting => setting.SettingId === 15) ? "✅" : "❌";
                user.scheduleViewed = user.settings.find(setting => setting.SettingId === 14) ? "✅" : "❌";
                user.alertsViewed = user.settings.find(setting => setting.SettingId === 8) ? "✅" : "❌";
            }

            user.timePresetsAdded =
                timepresets.data.find((preset) => preset.UserId === user.id)
                    ?.count || 0;

            user.membersInvited =
                membersCount.data.find(
                    (preset) => preset.OrganisationId === user.organisationId
                )?.count || 0;

            user.listAdded =
                userLists.data.find(
                    (list) => list.OwnerUserId === user.id
                )?.count || 0;

            user.agenciesAdded = "-"

            return user;
        });

        console.log(users, '@@')

        this.setState({
            loading: false,
            users: users.filter((user) => user.email)
        })
    }

    impersonate(email) {
        // @ts-ignore
        let ownAccessToken = JSON.parse(localStorage.getItem("hello")).b2cSignInAndUpPolicy.access_token;
        window.location.href =
            'https://' + AppConfig.Settings.authentication.tenantName + '.b2clogin.com/' + AppConfig.Settings.authentication.tenantName + '.onmicrosoft.com/oauth2/v2.0/authorize?targetEmail=' +
            email +
            '&p=B2C_1A_Impersonation&client_id=' + 
            AppConfig.Settings.authentication.appId + 
            '&nonce=defaultNonce&redirect_uri=' +
            (window.location.hostname.indexOf('https') === -1
                ? 'https://' + window.location.hostname
                : window.location.hostname) +
            '/redirectimp&scope=openid%20https://' + 
            AppConfig.Settings.authentication.tenantName + 
            '.onmicrosoft.com%2Fmobapi%2Fuser_impersonation&response_type=id_token%20token&prompt=login&authUserToken=' +
            ownAccessToken;
    }

    activate(email) {
        UserApi.activate(email).then(() => {
            Notifications.actionCreators.display(ToastType.SUCCESS, "Account activated");
        })
    }

    render() {

        if (this.state.loading) {
            return <Splash />;
        }

        return (
            <Wrapper>
                <div className="layout horizontal center justified">
                    <DataTable
                        columns={[
                            {
                                name: 'Name',
                                selector: 'name',
                                sortable: true,
                                width: '160px'
                            },
                            {
                                name: 'Email',
                                selector: 'email',
                                sortable: true,
                                width: '280px'
                            },
                            {
                                name: 'Type',
                                selector: 'typeStr',
                                sortable: true,
                                width: '80px'
                            },
                            {
                                name: 'Signup date',
                                selector: 'signupDate',
                                sortable: true,
                                width: '120px'
                            },
                            {
                                name: 'Days From Signup',
                                selector: 'daysSinceSignup',
                                sortable: true,
                                width: '80px'
                            },
                            {
                                name: 'Last Activity',
                                selector: 'lastActiveDate',
                                sortable: true,
                                width: '120px'
                            },
                            {
                                name: 'Days From Last Active',
                                selector: 'daysSinceLastActive',
                                sortable: true,
                                width: '80px'
                            },
                            {
                                name: '🧍 Profile Completed',
                                selector: 'profileCompleted',
                                sortable: true,
                                width: '80px',
                                compact: true
                            },
                            {
                                name: '🏢 Organization',
                                selector: 'organisationCompleted',
                                sortable: true,
                                width: '200px',
                                compact: true
                            },
                            {
                                name: '💼 Total Offers',
                                selector: 'offersCreated',
                                sortable: true,
                                width: '80px',
                                compact: true
                            },
                            {
                                name: '💼✅ Offers Confirmed',
                                selector: 'offersConfirmed',
                                sortable: true,
                                width: '80px',
                                compact: true
                            },
                            {
                                name: '💼❌ Offers Withdrawn',
                                selector: 'offersWithdrawn',
                                sortable: true,
                                width: '80px',
                                compact: true
                            },
                            {
                                name: '👷 Total invites',
                                selector: 'workersInvited',
                                sortable: true,
                                width: '80px',
                                compact: true
                            },
                            {
                                name: '👷✅ Invites accepted',
                                selector: 'workersInvitAccepted',
                                sortable: true,
                                width: '80px',
                                compact: true
                            },
                            {
                                name: '👷⌛ Invites pending ',
                                selector: 'workersInvitePending',
                                sortable: true,
                                width: '80px',
                                compact: true
                            },
                            {
                                name: '👷❌ Invites rejected',
                                selector: 'workersInviteRejected',
                                sortable: true,
                                width: '80px',
                                compact: true
                            },
                            {
                                name: '📅 Schedule viewed',
                                selector: 'scheduleViewed',
                                sortable: true,
                                width: '80px',
                                compact: true
                            },
                            {
                                name: '🖨️ Printing viewed',
                                selector: 'printingViewed',
                                sortable: true,
                                width: '80px',
                                compact: true
                            },
                            {
                                name: '🔔 Alerts viewed',
                                selector: 'alertsViewed',
                                sortable: true,
                                width: '80px',
                                compact: true
                            },

                            {
                                name: '⏱️ Time presets',
                                selector: 'timePresetsAdded',
                                sortable: true,
                                width: '80px',
                                compact: true
                            },
                            {
                                name: '👥 Org members',
                                selector: 'membersInvited',
                                sortable: true,
                                width: '80px',
                                compact: true
                            },
                            // {
                            //     name: '👩‍💼',
                            //     selector: 'agenciesAdded',
                            //     sortable: true
                            // },
                            {
                                name: '🔢 Lists',
                                selector: 'listAdded',
                                sortable: true,
                                width: '80px',
                                compact: true
                            }
                        ]}
                        data={this.state.users || []}
                        onRowClicked={(data) => {
                            console.log("DATA: ", data);
                            this.setState({
                                selectedUser: data
                            })
                            // this.impersonate(data.email)
                        }}
                    />
                </div>
                <Modal
                    show={this.state.selectedUser !== null}
                    onHide={() => { this.setState({ selectedUser: null }) }}
                >
                    <div
                        style={{
                            padding: 20
                        }}
                    >
                        <h2>{this.state.selectedUser?.firstName} {this.state.selectedUser?.lastName}</h2>
                        <button
                            onClick={(e) => {
                                this.impersonate(this.state.selectedUser?.email);
                            }}
                        >Impersonate</button>
                        <button
                            onClick={(e) => {
                                this.activate(this.state.selectedUser?.email);
                            }}
                        >Activate</button>
                        <div>
                            <h3>User Type: {this.state.selectedUser?.userTypeId === 2 ? "Hirer" : this.state.selectedUser?.userTypeId === 3 ? "Agency" : this.state.selectedUser?.userTypeId === 1 ? "Worker" : "-"}</h3>
                            <select
                                className="form-control"
                                value={this.state.selectedUser?.userTypeId}
                                onChange={(ev) => {
                                    if (this.state.selectedUser) {
                                        AdminAPI.updateUserType(this.state.selectedUser.id, parseInt(ev.target.value))
                                        this.setState({
                                            selectedUser: {
                                                ...this.state.selectedUser,
                                                userTypeId: parseInt(ev.target.value)
                                            }
                                        })
                                    }
                                }}
                            >
                                { [
                                    { id: 1, value: "Worker" },
                                    { id: 2, value: "Hirer" },
                                    { id: 3, value: "Agency" }
                                ].map(type => {
                                    return (
                                        <option
                                            key={type.id}
                                            value={type.id}
                                        >
                                            {type.value}
                                        </option>
                                    )
                                }) }
                            </select>
                        </div>
                    </div>
                </Modal>
                <ToastContainer position="top-center" />
            </Wrapper>
        );
    }

}

export default Users;

const Wrapper = styled.div`
    color: ${theme.colours.updatedge};

    input {
        margin-left: 20px;
        width: 150px;
    }

    .rdt_TableHeader {
        display: none !important;
    }

`;