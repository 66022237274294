import React from 'react';
import styled from 'styled-components';

interface BackgroundProps {
    show: boolean;
    text?: string;
}

export class AsyncOverlay extends React.Component<BackgroundProps> {
    renderBackground() {
        return (
            <Wrapper>
                <div className="background" />
                <div className="background-container">
                    <div className="background-content">
                        <div>
                            {this.props.text ? (
                                <div>{this.props.text}</div>
                            ) : null}
                            {this.props.children}
                            <i className="fa fa-spinner fa-spin" />
                        </div>
                    </div>
                </div>
            </Wrapper>
        );
    }

    render() {
        return this.props.show ? this.renderBackground() : null;
    }
}

const Wrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;

    .background {
        position: absolute;
        width: 100%;
        height: 100%;
        background: black;
        opacity: 0.73;
    }

    .background-container {
        height: 100%;

        .background-content {
            position: absolute;
            top: 50%;
            left: 50%;
            color: white;
            font-size: 16px;
            text-align: center;
            transform: translate(-50%, -50%);

            .fa-spinner {
                margin-top: 10px;
                font-size: 22px;
            }
        }
    }
`;
