import React from 'react';
import { Modal } from 'react-bootstrap';

//import { SignalrEventOverlays } from './SignalrEventOverlays';

interface Props {
    onHide: () => any;
    show: boolean;
}

export class EventOverlay extends React.Component<Props> {
    render() {
        return (
            <Modal show={this.props.show} onHide={this.props.onHide}>
                {this.props.children}
            </Modal>
        );
    }
}
