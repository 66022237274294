import React from 'react';
import moment from 'moment';
import styled from 'styled-components';

import InviteApi from '../../api/invites/Invites';
import {
    InviteResult,
    NotificationStatusType
} from '../../api/invites/ResponseTypes';
import { AsyncOverlay } from '../ui-components/AsyncOverlay';
import Analytics from '../../services/analytics.service';
import { minDuration } from '../../constants';

interface IncomingInvitationsState {
    invitations: InviteResult[];
    loading: boolean;
}

class IncomingInvitations extends React.Component<
    {},
    IncomingInvitationsState
> {
    constructor(props) {
        super(props);

        this.state = {
            invitations: [],
            loading: true
        };

        InviteApi.getIncomingInvitations().then((invitations) => {
            this.setState({
                invitations: invitations,
                loading: false
            });
        });
    }

    handleAccepted = async (inv: InviteResult) => {
        this.setStatus(inv, NotificationStatusType.NotSpecified);

        // UX: Ensure async-indicator shows for a minimum of .5s, looks better than flash
        await minDuration(InviteApi.acceptInvitation(inv.id), 500);

        this.setStatus(inv, NotificationStatusType.Receiving);

        Analytics.trackEvent('invitations-incoming-accepted');
    };

    handleRejected = async (inv: InviteResult) => {
        this.setStatus(inv, NotificationStatusType.NotSpecified);

        // UX: Ensure async-indicator shows for a minimum of .5s, looks better than flash
        await minDuration(InviteApi.rejectInvitation(inv.id), 500);

        this.setStatus(inv, NotificationStatusType.Rejected);

        Analytics.trackEvent('invitations-incoming-rejected');
    };

    setStatus = (inv: InviteResult, status: NotificationStatusType) => {
        this.setState({
            invitations: this.state.invitations.map((item) => {
                return item.id == inv.id ? { ...item, status } : item;
            })
        });
    };

    render() {
        if (this.state.loading) {
            return (
                <div>
                    <AsyncOverlay show={this.state.loading} />
                </div>
            );
        } else if (this.state.invitations && this.state.invitations.length) {
            return (
                <div>
                    <h5 style={{ padding: '0 10px 5px' }}>
                        <span>
                            The following people have requested to view your
                            availability.
                        </span>
                        <br />
                        <span
                            style={{ display: 'block', margin: '15px 0 5px' }}
                        >
                            Tap the tick to accept and share your availability.
                        </span>
                    </h5>
                    {this.renderBody()}
                </div>
            );
        } else {
            return (
                <h5 style={{ marginTop: '70px', textAlign: 'center' }}>
                    You have no outstanding share requests
                </h5>
            );
        }
    }

    renderBody() {
        return this.state.invitations.map((inv) => (
            <Card key={inv.id}>
                <span>{moment(inv.created).format('ddd DD-MMM-YY kk:mm')}</span>
                <div className="horizontal layout">
                    <div className="vertical layout">
                        <div className="header m-b-xs">Name:</div>
                        <div className="header m-b-xs">Organization:</div>
                    </div>
                    <div
                        className="vertical layout flex"
                        style={{ overflow: 'hidden' }}
                    >
                        <div className="m-b-xs overflow-ellipsis">
                            {inv.name}
                        </div>
                        <div className="m-b-xs overflow-ellipsis">
                            {inv.organisationName}
                        </div>
                    </div>
                    <div>{this.renderStatusControls(inv)}</div>
                </div>
            </Card>
        ));
    }

    renderStatusControls = (inv: InviteResult) => {
        let element: JSX.Element;

        switch (inv.status) {
            case NotificationStatusType.NotSpecified:
                element = (
                    <span>
                        <i className="fa fa-spinner fa-spin" />
                    </span>
                );
                break;
            case NotificationStatusType.Receiving:
                element = (
                    <span className="accepted status-text">Accepted</span>
                );
                break;
            case NotificationStatusType.Rejected:
                element = (
                    <span className="rejected status-text">Rejected</span>
                );
                break;
            default:
                element = (
                    <span>
                        <i
                            className="fa fa-check accepted"
                            onClick={() => this.handleAccepted(inv)}
                        />
                        <i
                            className="fa fa-times rejected"
                            onClick={() => this.handleRejected(inv)}
                        />
                    </span>
                );
                break;
        }

        return element;
    };
}

const Card = styled.div`
    margin: 5px 10px 10px;
    border-top: 1px solid gray;

    > span {
        display: inline-block;
        margin: 8px 0 8px;
        font-size: 11px;
        font-style: italic;
    }

    .header {
        display: inline-block;
        margin-right: 15px;
        font-weight: bold;
    }

    .btn {
        margin-left: 5px;
    }

    .fa {
        margin: 0 5px 0 5px;
        font-size: 28px;
        cursor: pointer;
    }

    .status-text {
        font-weight: bold;
        font-size: 14px;
    }

    .accepted {
        color: #62cb31;
    }

    .rejected {
        color: #e74c3c;
    }
`;

export default IncomingInvitations;
