/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import moment from 'moment';

import { Offers, OfferStatus } from '../../pages/Offers';
import theme from '../../css/theme';
import Device, { Resolutions } from '../../css/device';
import { DateFormats } from '../../constants';
import LocationMap from '../ui-components/LocationMap';
import WorkerSelector from '../offers/WorkerSelector';
import Expander from '../ui-components/Expander';
import {
    EventStateDto,
    OfferConfirmationResponseTypes,
    OfferInfoDto,
    OfferRecipientDto,
    OfferResponseTypes,
    SmsStatusTypes,
    EmailDeliveryStatusTypes
} from '../../api/offers/ResponseTypes';
import { Spinner } from '../ui-components/Spinner';
import ConfirmOffer from './ConfirmOffer';
import SimpleTooltip from '../ui-components/SimpleTooltip';
import CreateOfferUtilsProvider, { CreateOfferUtilsProviderReturnValue } from './CreateOfferUtilsProvider';
import OfferCard from './OfferCard';
import OfferWarning from './OfferWarning';
import Dialog from '../ui-components/Dialog'
import { ProfileImage } from '../ui-components/ProfileImage';
import ProfileApi from '../../api/profile/Profile';
import OrganisationApi from '../../api/organisation/Organisation';
import CompanyApi from '../../api/company/Company';
import OfferApi from '../../api/offers/Offers'
import Utilities from '../../Utilities';
import Analytics from '../../services/analytics.service';
import { OfferRecipientAgency } from '../../api/offers/RequestTypes';
import InternalTracker from '../../InternalTracker';
import { toast } from 'react-toastify';
import '../../css/Offer.css';
import DemoResDetails from '../../demo/offerdetails.json'
import '../../css/Files.css';

import {
    getOfferStatus,
    isOfferResolved,
    getOfferWorkersByResponseType,
    getOfferWorkersByConfirmationType,
    withdrawOffer,
    favoriteOffer
} from '../../pages/OffersUtils';
import { FileListItem } from 'src/pages/Files';
import { FileType, UserFile, UserFileWithFilterType } from 'src/store/contacts/Models';
import DocumentViewer from '../../pages/DocumentViewer';

interface Props {
    lists: CreateOfferUtilsProviderReturnValue['data']['lists'];
    // contacts: CreateOfferUtilsProviderReturnValue['data']['contacts'];
    handleViewCancel?: Offers['handleViewCancel']; // Close
    handleConfirmationComplete?: Offers['handleConfirmationComplete']; // Called when the offer confirmation has finished
    handleWithdrawWorker?: Offers['handleWithdrawWorker'];
    handleDeleteOffer?: Offers['handleDeleteOffer'];
    handleWithdrawOffer?: Offers['handleWithdrawOffer'];
    handleAddWorkersToOffer?: Offers['handleAddWorkersToOffer'];
    handleFavouriteOffer?: Offers['handleFavouriteOffer'];
    startChat?: Offers['startChat'];
    offerIdToLoad?: string;
    offerVersion?: number;
    createOfferUtilsData: CreateOfferUtilsProviderReturnValue['data'];
    createOfferUtilsFuncs: CreateOfferUtilsProviderReturnValue['functions'];
}

export enum changeType {
    delete,
    generic
}

export type change = {
    type: changeType;
    es: EventStateDto | null;
};

export interface EditOfferData {
    listId: number,
    workerIds: string[],
    deadline: string
}

export enum UserFriendlySmsStatusTypes {
    Sent = 1,
    Delivered = 2,
    Failed = 3,
    ToBeSent = 10,
    WillNotBeSent = 11,
    NoLongerNeeded = 12
}

export enum UserFriendlyEmailStatusTypes {
    Sent = 1,
    Delivered = 2,
    Failed = 3,
}

const DEADLINE_PRESETS = [
    "Off",
    // "Custom Time",
    (window as any).Cypress ? "in 1 minute" : "in 10 minutes",
    "in 15 minutes",
    "in 30 minutes",
    "in 1 hour",
    "in 90 minutes",
    "in 2 hours",
    "in 4 hours",
    "in 6 hours",
    "in 8 hours",
    "in 12 hours",
    "in 1 day",
    "in 2 days",
    "in 3 days",
    "in 4 days",
    "in 5 days",
    "in 6 days",
    "in 1 week"
];

let deadlineUpdateInterval;

const ViewOffer = ({
    handleViewCancel,
    handleWithdrawWorker,
    handleDeleteOffer,
    handleWithdrawOffer,
    handleConfirmationComplete,
    handleAddWorkersToOffer,
    handleFavouriteOffer,
    startChat,
    offerVersion,
    lists,
    offerIdToLoad,
    createOfferUtilsData,
    createOfferUtilsFuncs,
}: Props) => {
    const [offerLoadedInternally, setOfferLoadedInternally] = useState<OfferInfoDto | null>(null);
    const [showConfirmation, setShowConfirmation] = useState<boolean>(false); // Show offer confirmation view
    const [autoComplete, setAutoComplete] = useState<boolean>(false); // Whether to autocomplete offer without shwing the confirmation view
    const [selectedRecipients, setSelectedRecipients] = useState<OfferRecipientDto[]>([]);
    const [showMoreWorkersToAddList, setShowMoreWorkersToAddList] = useState<boolean>(false);
    const [editOfferData, setEditOfferData] = useState<EditOfferData>({ listId: 0, workerIds: [], deadline: 'Off'});
    const [showDeadlineCountdown, setShowDeadlineCountdown] = useState<boolean>(true);
    const [openedFileType, setOpenedFileType] = useState <FileType | null> (null);
    const [openedFileName, setOpenedFileName] = useState <string> ("");
    const [openedFileId, setOpenedFileId] = useState <number | null> (null);
    const [openedFileUri, setOpenedFileUri] = useState <string | null> (null);
    const [favoritingOffer, setFavoritingOffer] = useState<boolean>(false);

    useEffect(() => {
        if (offerIdToLoad) {
            InternalTracker.trackEvent("", {
                category: 'Offers',
                action: 'Offer Clicked',
                customDimensions: [{ id: "REPLACE", value: offerIdToLoad }]
            });

            if (offerIdToLoad !== "demo") {
                OfferApi
                    .getOfferById(offerIdToLoad)
                    .then(offer => {
                        if (offer?.id) {
                            const offerRecipientsAccepted = offer.recipients.filter(r => r.response === 1);
                            if (offerRecipientsAccepted.length === 1) {
                                setTimeout(() => {
                                    const recipientDom: HTMLElement | null = document.querySelector(".selectable[data-id='" + offerRecipientsAccepted[0].id + "']")
                                    if (recipientDom !== null) {
                                        recipientDom.click();
                                    }
                                }, 300)
                            }
                            setOfferLoadedInternally(offer);
                        }
                    }).catch((e) => {
                        toast.error("Failed to load offer");
                        if (handleViewCancel)
                            handleViewCancel();
                    })
            } else {

                const now = new Date();
                const allowedDays = [Utilities.dateAdd(now, "day", 1), Utilities.dateAdd(now, "day", 2), Utilities.dateAdd(now, "day", 3), Utilities.dateAdd(now, "day", 4), Utilities.dateAdd(now, "day", 5), Utilities.dateAdd(now, "day", 6), Utilities.dateAdd(now, "day", 7), Utilities.dateAdd(now, "day", 8), Utilities.dateAdd(now, "day", 9), Utilities.dateAdd(now, "day", 10)];

                DemoResDetails.events = DemoResDetails.events.map(e => {
                    let start = allowedDays[Utilities.randomIntFromInterval(0, allowedDays.length-1)];
                    let end = start;
                    start.setHours(Utilities.randomIntFromInterval(0, 1) === 0 ? 8 : Utilities.randomIntFromInterval(0, 1) === 0 ? 10 : 12);
                    start.setMinutes(Utilities.randomIntFromInterval(0, 1) === 0 ? 30 : 0);
                    end.setHours(Utilities.randomIntFromInterval(0, 1) === 0 ? 14 : Utilities.randomIntFromInterval(0, 1) === 0 ? 16 : 18);
                    end.setMinutes(Utilities.randomIntFromInterval(0, 1) === 0 ? 30 : 0);
                    return {
                        start: start.toISOString(),
                        end: end.toISOString(),
                        intervalMinutes: e.intervalMinutes
                    }
                })
                // @ts-ignore
                setOfferLoadedInternally(DemoResDetails)
            }

            setShowDeadlineCountdown(true);
            clearInterval(deadlineUpdateInterval);
            deadlineUpdateInterval = setInterval(() => {
                setShowDeadlineCountdown(false);
                setTimeout(() => {
                    setShowDeadlineCountdown(true)
                }, 100)
            }, 60000)
        }
    }, [offerIdToLoad]);

    useEffect(() => {
        if (offerVersion) {
            OfferApi
                .getOfferById(offerIdToLoad || "")
                .then(offer => {
                    if (offer?.id) {
                        setOfferLoadedInternally(offer);
                    }
                }).catch(e => console.error)
        }
    }, [offerVersion]);
    
    if (!offerLoadedInternally) {
        return (
            <OfferCard>
                <Spinner />
            </OfferCard>
        );
    }

    const offer = offerLoadedInternally;
    const withDeletions = offer.recipients.some((r) => {
        return (
            r.confirmation == OfferConfirmationResponseTypes.Confirmed &&
            r.eventStates &&
            r.eventStates.length > 0 &&
            r.eventStates.some((es) => es.deleted)
        );
    });
    const offerStatus = getOfferStatus(offer, withDeletions);
    const appliedCount = offer.recipients.filter(
        (r) =>
            r.confirmation != OfferConfirmationResponseTypes.Confirmed &&
            r.confirmation != OfferConfirmationResponseTypes.Unsuccessful &&
            r.response === OfferResponseTypes.Yes
    ).length;

    const handleCompleteOffer = () => {
        (window as any).lastConfirmedOfferId = offer.id;
        setShowConfirmation(true)

        let user = JSON.parse(localStorage.getItem("user") || "{}");
        if (user?.organisationIsAgency) {
            setAutoComplete(true);
        }

        Analytics.trackEvent('offers-page-complete-offer-clicked');
    }

    const handleWorkerClick = (recipient: OfferRecipientDto, currentSelectedRecipients: OfferRecipientDto[]) => {
        setSelectedRecipients(
            currentSelectedRecipients.indexOf(recipient) >= 0
                ? currentSelectedRecipients.filter(
                    (r) => r.id !== recipient.id
                ) // Unselect
                : currentSelectedRecipients.concat(recipient)
        )

        Analytics.trackEvent('offers-page-offer-worker-selected');
    }

    const withdrawThisOffer = async (offer: OfferInfoDto, confirm?: boolean) => {
        if (confirm) {
            handleWithdrawOffer && handleWithdrawOffer(offer);
            let offerW = await withdrawOffer(offer);
            handleWithdrawOffer && handleWithdrawOffer(offer, true);
            toast.success('Offer withdrawn');
        } else {
            if (window.confirm("Are you sure you want to withdraw this offer from all workers?")) {
                withdrawThisOffer(offer, true);
            }
        }
    }

    const onFileClick = (file: UserFile) => {
        if (
            ["docx", "doc", "jpg", "jpeg", "png", "pdf", "ppt", "pptx", "xls", "xlsx"].indexOf(file.fileFormat || "") !== -1 ||
            file.fileType === FileType.VideoLink || 
            file.fileType === FileType.Link
        ) {
            setOpenedFileType(file.fileType || null)
            setOpenedFileName(file.fileName || "");
            setOpenedFileId(file.id || null);
            setOpenedFileUri(file.accessLink || null);                                                             
        } else {
            window.open(file.accessLink)
        }
    }

    const renderRows = (
        recipients: OfferRecipientDto[],
        status: string,
        icon: string,
        color: string,
        offer: OfferInfoDto
    ) => {

        return (
            recipients.length > 0 && (
                <>
                    {recipients.map((r, i) => {
                        const smsStatus =  
                            !r.sendSMS ?
                            UserFriendlySmsStatusTypes.WillNotBeSent :
                                r.smsStatus === SmsStatusTypes.MessageSent ?
                                UserFriendlySmsStatusTypes.Sent :
                                    r.smsStatus === SmsStatusTypes.MessageDelivered ?
                                    UserFriendlySmsStatusTypes.Delivered :
                                        (r.smsStatus === SmsStatusTypes.NoLongerRequired || (r.sendSMS && r.firstOpenedAt) ) ?
                                        UserFriendlySmsStatusTypes.NoLongerNeeded :
                                            r.smsStatus === SmsStatusTypes.MessageQueued || (r.sendSMS && r.smsStatus === SmsStatusTypes.Unknown) ?
                                            UserFriendlySmsStatusTypes.ToBeSent :
                                                UserFriendlySmsStatusTypes.Failed
                        const emailStatus =
                            r.emailStatus === EmailDeliveryStatusTypes.Unknown ?
                            UserFriendlyEmailStatusTypes.Sent :
                                r.emailStatus === EmailDeliveryStatusTypes.Delivered ?
                                UserFriendlyEmailStatusTypes.Delivered :
                                    UserFriendlyEmailStatusTypes.Failed
                        const selectable =
                            offerStatus === OfferStatus.Ready &&
                            r.response == OfferResponseTypes.Yes &&
                            r.confirmation ==
                                OfferConfirmationResponseTypes.NotSpecified;
                        const hasChanges =
                            r.confirmation ==
                                OfferConfirmationResponseTypes.Confirmed &&
                            r.eventStates &&
                            r.eventStates.length > 0 &&
                            r.eventStates.filter((es) => es.deleted).length > 0;

                        let changes: change[] = [];
                        if (hasChanges) {
                            r.eventStates!.forEach((es) => {
                                if (es.deleted) {
                                    changes.push({
                                        type: changeType.delete,
                                        es: es
                                    } as change);
                                }
                            });
                        }

                        const emaiStatusStr = emailStatus === UserFriendlyEmailStatusTypes.Sent ? 'Sent' : emailStatus === UserFriendlyEmailStatusTypes.Delivered ? 'Delivered' : 'Failed';
                        // TODO not sent because opened
                        const smsStatusStr = smsStatus === UserFriendlySmsStatusTypes.Sent ? 'Sent' : smsStatus === UserFriendlySmsStatusTypes.Delivered ? 'Delivered' : smsStatus === UserFriendlySmsStatusTypes.ToBeSent ? 'Scheduled' : smsStatus === UserFriendlySmsStatusTypes.WillNotBeSent ? 'Won\'t be sent' : 'Unknown';

                        return (
                            <tr
                                key={r.id}
                                className={`contact-status ${selectable ? 'selectable' : ''} ${
                                    selectedRecipients && selectedRecipients.indexOf(r) >= 0
                                        ? 'selected'
                                        : ''
                                }`}
                                style={{
                                    cursor: selectable ? 'pointer' : 'default'
                                }}
                                onClick={() =>
                                    selectable && handleWorkerClick(r, selectedRecipients)
                                }
                                data-name={r.recipientName}
                                data-id={r.id}
                            >
                                <td
                                    style={{
                                        display: 'inline-flex',
                                        alignItems: 'center'
                                    }}
                                >
                                    <ProfileImage 
                                        size={28}
                                        url={ProfileApi.getProfileImageUrl(r.recipientUserId)}
                                        selectable={false}
                                        style={{
                                            padding: 0,
                                            border: 0,
                                            marginRight: 6
                                        }}
                                        data-report-blurred={r.reported}
                                        data-user-id={r.recipientUserId}
                                    />
                                    <span
                                        style={{
                                            display: 'inline-block'
                                        }}
                                    >
                                        <span 
                                            data-report-blurred={r.reported}
                                            data-user-id={r.recipientUserId}
                                        >
                                            {r.recipientName.split(" ")[0]}<br />
                                            {r.recipientName.split(" ")[1]}<br />
                                        </span>
                                        { hasChanges && " cancelled " + ( changes.length === 1 ? "an event" : changes.length + " events" ) }
                                    </span>
                                </td>

                                { (!offer.externalHirerId) &&
                                    <td className='status-circle-cell sms-status' data-status={smsStatus}>
                                        <div 
                                            className='status-circle' 
                                            style={ 
                                                smsStatus === UserFriendlySmsStatusTypes.Sent ? 
                                                { background: theme.colours.blue2 } : 
                                                    smsStatus === UserFriendlySmsStatusTypes.Delivered ? 
                                                    { background: theme.colours.green2 } : 
                                                        smsStatus === UserFriendlySmsStatusTypes.WillNotBeSent ? 
                                                        { background: theme.colours.amber } : 
                                                            smsStatus === UserFriendlySmsStatusTypes.NoLongerNeeded ? 
                                                            { background: theme.colours.green2 } : 
                                                                smsStatus === UserFriendlySmsStatusTypes.ToBeSent ? 
                                                                { background: theme.colours.blue2 } : 
                                                                    { background: theme.colours.red2 }
                                            }>
                                            <SimpleTooltip
                                                id={"tooltip-" + r.id + "-sms"}
                                                text={
                                                    smsStatus === UserFriendlySmsStatusTypes.Sent ? 
                                                    "The SMS has been sent, it will be delievered when your contact has signal" : 
                                                        smsStatus === UserFriendlySmsStatusTypes.Delivered ? 
                                                        "The message has been received by the contact's handset" : 
                                                            smsStatus === UserFriendlySmsStatusTypes.WillNotBeSent ? 
                                                            "Recipient opted-out, or not was not selected for SMS alert by you" : 
                                                                smsStatus === UserFriendlySmsStatusTypes.NoLongerNeeded ? 
                                                                "The offer was viewed by the recipient before the SMS is sent, no credit was used" :
                                                                    smsStatus === UserFriendlySmsStatusTypes.ToBeSent ? 
                                                                    "The SMS will be sent within 2 minutes if not opened before by the recipient" : 
                                                                        "We failed to send an SMS"
                                                }
                                            >
                                                <i className={
                                                    smsStatus === UserFriendlySmsStatusTypes.Sent ?
                                                    "fa-solid fa-check" : 
                                                        smsStatus === UserFriendlySmsStatusTypes.Delivered ?
                                                        "fa-solid fa-check-double" :
                                                            smsStatus === UserFriendlySmsStatusTypes.WillNotBeSent ? 
                                                            "fa-solid fa-comment-slash" : 
                                                                smsStatus === UserFriendlySmsStatusTypes.NoLongerNeeded ? 
                                                                "fa-solid fa-forward" :
                                                                    smsStatus === UserFriendlySmsStatusTypes.ToBeSent ? 
                                                                    "fa-solid fa-stopwatch-20" :
                                                                        "fa-solid fa-xmark" 
                                                } />
                                            </SimpleTooltip>
                                        </div> 
                                    </td>
                                }
                                { (!offer.externalHirerId) &&
                                    <td className='status-circle-cell email-status' data-status={emailStatus}>
                                        <div 
                                            className='status-circle' 
                                            style={ 
                                                emailStatus === UserFriendlyEmailStatusTypes.Sent ? 
                                                { background: theme.colours.blue2 } : 
                                                    emailStatus === UserFriendlyEmailStatusTypes.Delivered ? 
                                                    { background: theme.colours.green2 } : 
                                                        { background: theme.colours.red2 }}>
                                            <SimpleTooltip
                                                id={"tooltip-" + r.id + "-sms"}
                                                text={
                                                    emailStatus === UserFriendlyEmailStatusTypes.Sent ? 
                                                    "The email will be sent in a few moments" : 
                                                        emailStatus === UserFriendlyEmailStatusTypes.Delivered ? 
                                                        "The message has been received by the recipient's email provider" : 
                                                            "We failed to send an Email, due to bounce, or being reported as spam"
                                                }
                                            >
                                                <i className={
                                                    emailStatus === UserFriendlyEmailStatusTypes.Sent ?
                                                    "fa-solid fa-check" : 
                                                        emailStatus === UserFriendlyEmailStatusTypes.Delivered ?
                                                        "fa-solid fa-check-double" :
                                                            "fa-solid fa-xmark" 
                                                } />
                                            </SimpleTooltip>
                                        </div>
                                    </td>
                                }
                                { (!offer.externalHirerId) &&
                                    <td className='status-circle-cell opened-status' data-status={r.firstOpenedAt ? "1" : "0"}>
                                        <div 
                                            className='status-circle' 
                                            style={
                                                r.firstOpenedAt ? 
                                                { background: theme.colours.green2 } :
                                                    { background: theme.colours.grey }
                                            }
                                        >
                                            <SimpleTooltip
                                                id={"tooltip-" + r.id + "-sms"}
                                                text={
                                                    r.firstOpenedAt ? 
                                                    "The offer has been opened by the recipient" : 
                                                        "The offer hasn't been opened yet by the recipient"
                                                }
                                            >
                                                <i className={
                                                    r.firstOpenedAt ? 
                                                    "fa-solid fa-eye" : 
                                                        "fa-solid fa-eye-slash"
                                                } />
                                            </SimpleTooltip>
                                        </div>
                                    </td>
                                }
                                <td className='status-circle-cell response-status' data-status={status} data-status-autodeclined={r.autoDeclined ? "1" : "0"}>
                                    <div 
                                        className='status-circle' 
                                        style={{ background: color }}
                                    >
                                        <SimpleTooltip
                                            id={"tooltip-" + r.id + "-sms"}
                                            text={status === "Declined" && r.autoDeclined ? "Auto Declined" : status}
                                        >
                                            <i className={(status === "Declined" && r.autoDeclined) ? "fa-solid fa-calendar-xmark" : ("fa-solid " + icon) } />
                                        </SimpleTooltip>
                                    </div>
                                </td>
                                { (r.respondByDeadline && r.response === 0 && r.confirmation !== 2 && new Date(r.respondByDeadline) > new Date() && showDeadlineCountdown && !offer.externalHirerId) ?
                                    <SimpleTooltip
                                        id={"tooltip-" + r.id + "-countdown"}
                                        text={"Time left until offer is auto-declined: " + Utilities.toHoursAndMinutes(Utilities.differenceBetweenDatesMinutes(new Date(r.respondByDeadline || ""), new Date()), true) + " " + Utilities.toHoursAndMinutes(Utilities.differenceBetweenDatesMinutes(new Date(r.respondByDeadline || ""), new Date()), false, true)}
                                    >
                                        <td className='status-circle-cell countdown' data-status={Utilities.differenceBetweenDatesMinutes(new Date(), new Date(r.respondByDeadline || ""))}>
                                            {Utilities.toHoursAndMinutes(Utilities.differenceBetweenDatesMinutes(new Date(r.respondByDeadline || ""), new Date()), false, false, true)}<br />
                                            {Utilities.toHoursAndMinutes(Utilities.differenceBetweenDatesMinutes(new Date(r.respondByDeadline || ""), new Date()), true)}<br />
                                            {Utilities.toHoursAndMinutes(Utilities.differenceBetweenDatesMinutes(new Date(r.respondByDeadline || ""), new Date()), false, true)}<br />
                                        </td>
                                    </SimpleTooltip>
                                    :
                                    <td className='status-circle-cell countdown' data-status="-">
                                        -
                                    </td>
                                }
                                <td 
                                    style={{
                                        width: '20px',
                                        verticalAlign: "middle"
                                    }}
                                >
                                    {hasChanges && (
                                        <span style={{ whiteSpace: 'nowrap' }}>
                                            {changes.map((change, i) => {
                                                return (
                                                    <OfferWarning
                                                        key={`change-warning-${i}`}
                                                        handleViewCancel={
                                                            handleViewCancel || (() => {  })
                                                        }
                                                        startChat={
                                                            startChat || (() => {  })
                                                        }
                                                        recipient={r}
                                                        change={change}
                                                    />
                                                );
                                            })}
                                        </span>
                                    )}
                                    {selectable && (
                                        <SimpleTooltip
                                            id="popover-confirm-worker"
                                            text="Click to select this worker and complete the offer to them"
                                            placement="right"
                                        >
                                            <i
                                                className="fa fa-check fa-hover"
                                                style={{ fontSize: '18px', color: theme.colours.green2 }}
                                                onClick={() => {
                                                    handleWorkerClick(r, selectedRecipients)
                                                }}
                                            />
                                        </SimpleTooltip>
                                    )}
                                    {[
                                        OfferStatus.Ready,
                                        OfferStatus.Waiting
                                    ].includes(offerStatus) &&
                                        r.confirmation ==
                                            OfferConfirmationResponseTypes.NotSpecified &&
                                        r.response ==
                                            OfferResponseTypes.Pending && (
                                            <SimpleTooltip
                                                id="popover-withdraw-worker"
                                                text="Withdraw offer from individual worker"
                                                placement="right"
                                            >
                                                <i
                                                    className="fa fa-trash fa-hover"
                                                    style={{ fontSize: '18px', color: theme.colours.red2 }}
                                                    onClick={() => {
                                                        handleWithdrawWorker ? handleWithdrawWorker(r) : (() => {})
                                                        const newOffer = {
                                                            ...offer,
                                                            recipients: offer.recipients.filter((r2) =>
                                                                r2.id !== r.id
                                                            )
                                                        };
                                                        toast.success("Offer withdrawn from " + r.recipientName)
                                                        setOfferLoadedInternally(newOffer);
                                                    }}
                                                />
                                            </SimpleTooltip>
                                        )}
                                </td>
                            </tr>
                        );
                    })}
                </>
            )
        );
    };

    const renderEvents = () => {
        const anyOvernight = offer.events.some(
            (ev) => moment(ev.start).dayOfYear() !== moment(ev.end).dayOfYear()
        );

        return offer.events.map((ev) => {
            const isOvernight =
                moment(ev.start).dayOfYear() !== moment(ev.end).dayOfYear();
            return (
                <span
                    key={`ev-${offer.id}-${moment(ev.start).format()}`}
                    className="vacancy-period"
                >
                    <span>
                        {moment(ev.start).format(DateFormats.ShortDateWithDay)}
                    </span>
                    {` - `}
                    <span>
                        {moment(ev.start).format(DateFormats.Time24Hour)}
                    </span>
                    {'-'}
                    <span>{moment(ev.end).format(DateFormats.Time24Hour)}</span>
                    <span> (
                        {
                            Utilities.toHoursMinutes(
                                new Date(ev.start) < new Date(ev.end)
                                    ? Utilities.differenceBetweenDatesSeconds(new Date(ev.start), new Date(ev.end))
                                    : Utilities.differenceBetweenDatesSeconds(new Date(ev.start), new Date(Utilities.dateAdd(new Date(ev.end), "day", 1)))
                            )
                        }    
                    )</span>
                    <SimpleTooltip
                        id="vacancy-period-overnight"
                        text="This vacancy time is overnight and ends the following day"
                    >
                        <i
                            className="fas fa-moon m-l-xs"
                            style={{
                                fontSize: '14px',
                                display: anyOvernight ? 'inline-block' : 'none',
                                visibility: isOvernight ? 'visible' : 'hidden'
                            }}
                        />
                    </SimpleTooltip>
                </span>
            );
        });
    };

    const renderWorkers = () => {
        const noLongerConfirmed = offer.recipients.filter(
            (r) =>
                r.confirmation == OfferConfirmationResponseTypes.Confirmed &&
                r.eventStates &&
                r.eventStates.length > 0 &&
                r.eventStates.some((es) => es.deleted)
        );

        const completed = offer.recipients.filter((r) => {
            return (
                r.confirmation == OfferConfirmationResponseTypes.Confirmed &&
                r.eventStates &&
                !r.eventStates.some((es) => es.deleted)
            );
        });

        const accepted = offer.recipients.filter(
            (r) =>
                r.confirmation != OfferConfirmationResponseTypes.Confirmed &&
                r.confirmation != OfferConfirmationResponseTypes.Unsuccessful &&
                r.response === OfferResponseTypes.Yes
        );

        const unsuccessful = offer.recipients.filter(
            (r) =>
                (
                    (
                        r.confirmation === OfferConfirmationResponseTypes.Unsuccessful &&
                        r.response !== OfferResponseTypes.No
                    ) ||
                    r.confirmation === OfferConfirmationResponseTypes.Rejected
                ) &&
                !r.autoDeclined
        );

        const rejected = getOfferWorkersByResponseType(
            offer,
            OfferResponseTypes.No
        );

        const pending = offer.recipients.filter(
            (r) =>
                r.confirmation == OfferConfirmationResponseTypes.NotSpecified &&
                r.response === OfferResponseTypes.Pending
        );

        return (
            <>
                <div
                    style={{
                        maxHeight: `calc(100vh - ${
                            Device.isSmallerThan(Resolutions.MobileMedium)
                                ? '410'
                                : '570'
                        }px)`,
                        overflowY: 'auto',
                        margin: '20px 0 0',
                        minHeight: '50px',
                        boxShadow: 'inset 0px 11px 8px -10px #ccc, inset 0px -11px 8px -10px #ccc'
                    }}
                >
                    <Table condensed>
                        <tbody className='candidate-wrapper'>
                            <tr>
                                <td
                                    style={{
                                        textAlign: "left",
                                        fontWeight: 600
                                    }}
                                >
                                    Candidates
                                </td>
                                { (!offer.externalHirerId) &&
                                    <SimpleTooltip
                                        id={"tooltip-sms-status-info"}
                                        text={"SMS Delivery Status"}
                                    >
                                        <td>
                                            <i className="fa-solid fa-comment-sms"></i>
                                        </td>
                                    </SimpleTooltip>
                                }
                                { (!offer.externalHirerId) &&
                                    <SimpleTooltip
                                        id={"tooltip-email-status-info"}
                                        text={"Email Delivery Status"}
                                    >
                                        <td>
                                            <i className="fa-solid fa-at"></i>
                                        </td>
                                    </SimpleTooltip>
                                }
                                { (!offer.externalHirerId) &&
                                    <SimpleTooltip
                                        id={"tooltip-read-status-info"}
                                        text={"Offer Read Receipt"}
                                    >
                                        <td>
                                            <i className="fa-solid fa-eye"></i>
                                        </td>
                                    </SimpleTooltip>
                                }
                                <SimpleTooltip
                                    id={"tooltip-response-status-info"}
                                    text={"Offer Response"}
                                >
                                    <td>
                                        <i className="fa-solid fa-inbox"></i>
                                    </td>
                                </SimpleTooltip>
                                { (!offer.externalHirerId) &&
                                    <SimpleTooltip
                                        id={"tooltip-countdown-status-info"}
                                        text={"Time left until offer is auto-declined"}
                                    >
                                        <td>
                                            <i className="fa-solid fa-stopwatch-20"></i>
                                        </td>
                                    </SimpleTooltip>
                                }
                            </tr>
                            {renderRows(completed, 'Confirmed', 'fa-check-double', theme.colours.green2, offer)}
                            {renderRows(noLongerConfirmed, 'Deleted confirmed event(s)', 'fa-times', theme.colours.red2, offer)}
                            {renderRows(accepted, 'Applied', 'fa-check', theme.colours.green2, offer)}
                            {renderRows(unsuccessful, 'Unsuccessful', 'fa-times', theme.colours.red2, offer )}
                            {renderRows(rejected, 'Declined', 'fa-times', theme.colours.red2, offer)}
                            {renderRows(pending, 'Pending', 'fa-clock', theme.colours.blue2, offer)}
                        </tbody>
                    </Table>
                    { (offer.agencyResponses && offer.agencyResponses.length > 0) &&
                        <Table condensed style={{
                            marginTop: 10
                        }}>
                            <tbody className='candidate-wrapper'>
                                <tr>
                                    <td
                                        style={{
                                            textAlign: "left",
                                            fontWeight: 600
                                        }}
                                    >
                                        Agencies
                                    </td>
                                    <SimpleTooltip
                                        id={"tooltip-email-status-info"}
                                        text={"Email Delivery Status"}
                                    >
                                        <td>
                                            <i className="fa-solid fa-at"></i>
                                        </td>
                                    </SimpleTooltip>
                                    <SimpleTooltip
                                        id={"tooltip-response-status-info"}
                                        text={"Help Response"}
                                    >
                                        <td>
                                            <i className="fa-solid fa-inbox"></i>
                                        </td>
                                    </SimpleTooltip>
                                </tr>
                                { offer.agencyResponses.map((ar) => {
                                    return (
                                        <tr
                                            key={ar.userContactId}
                                            className={`agency-status`}
                                            data-name={ar.organisationName || ar.organisationDomain}
                                        >
                                            <td
                                                style={{
                                                    display: 'inline-flex',
                                                    alignItems: 'center'
                                                }}
                                            >
                                                <ProfileImage 
                                                    size={28}
                                                    url={ar.organisationId ? CompanyApi.getOrganisationProfileImageUrl(ar.organisationId) : ar.organisationDomain ? OrganisationApi.getExtOrgPicture(ar.organisationDomain) : ""}
                                                    selectable={false}
                                                    style={{
                                                        padding: 0,
                                                        border: 0,
                                                        marginRight: 6
                                                    }}
                                                />
                                                <span
                                                    style={{
                                                        display: 'inline-block'
                                                    }}
                                                >
                                                    {ar.userContactName + ' '}from
                                                    {' ' + ar.organisationName}
                                                </span>
                                            </td>
                                            <td className='status-circle-cell email-status' data-status={ar.emailSent}>
                                                <div 
                                                    className='status-circle' 
                                                    style={{
                                                        background: ar.emailSent ? theme.colours.green2 : theme.colours.grey
                                                    }}
                                                >
                                                    <SimpleTooltip
                                                        id={"tooltip-" + ar.userContactId + "-email"}
                                                        text={
                                                            ar.cancelled ? "Email has been cancelled, as someone has applied for the offer" :
                                                            ar.emailSent ? "The help email has been received by the recipient's email provider" : 
                                                            ("The email is scheduled to be sent in " + Utilities.toHHMM(Utilities.differenceBetweenDatesSeconds(Utilities.dateAdd(new Date(ar.scheduledAt || ""), "minute", 1), new Date())))
                                                        }
                                                    >
                                                        <i className={
                                                            ar.cancelled ? "fa-solid fa-xmark" :
                                                            ar.emailSent ? "fa-solid fa-check-double" :
                                                            "fa-solid fa-clock"
                                                        } />
                                                    </SimpleTooltip>
                                                </div>
                                            </td>
                                            <td className='status-circle-cell response-status'>
                                                <div 
                                                    className='status-circle' 
                                                    style={{ background: ar.responseType === 1 ? theme.colours.blue2 : ar.responseType === 2 ? theme.colours.red2 : theme.colours.grey }}
                                                >
                                                    <SimpleTooltip
                                                        id={"tooltip-" + ar.userContactId + "-response"}
                                                        text={
                                                            ar.responseType === 1 ? "The agency agreed to help" :
                                                            ar.responseType === 2 ? "The agency cannot help for this offer" :
                                                            "The agency has not responded yet"
                                                        }
                                                    >
                                                        <i className={
                                                            ar.responseType === 1 ? "fa-solid fa-check" :
                                                            ar.responseType === 2 ? "fa-solid fa-times" :
                                                            "fa-solid fa-clock"
                                                        } />
                                                    </SimpleTooltip>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                } )}
                            </tbody>
                        </Table>
                    }
                </div>
            </>
        );
    };

    const getOfferCardStatusClass = () => {
        if (
            offerStatus === OfferStatus.Ready ||
            offerStatus === OfferStatus.Waiting
        )
            return 'pending';
        if (offerStatus === OfferStatus.Withdrawn) return 'withdrawn';
        if (offerStatus === OfferStatus.Historic) return 'historic';
        if (offerStatus === OfferStatus.ConfirmedDeletions)
            return 'noLongerConfirmed';
        if (offerStatus === OfferStatus.Confirmed && offer.externalHirerId) return 'agency-confirmed';
        if (offerStatus === OfferStatus.Confirmed) return 'confirmed';

        return 'pending';
    };

    const toggleAlternativeWorkerToOffer = (contactUserId: string) => {
        setEditOfferData({
            ...editOfferData,
            workerIds: editOfferData.workerIds.includes(contactUserId)
                ? editOfferData.workerIds.filter(
                      (id) => id !== contactUserId
                  )
                : [...editOfferData.workerIds, contactUserId]
        })
    }

    const addWorkersToOffer = (
        offer: OfferInfoDto,
        editOfferData: EditOfferData
    ) => {
        console.log("ADDING!!!!!", editOfferData)
    
        if (editOfferData.workerIds.length === 0) {
            toast.error(
                'Please select at least one worker to add to the offer'
            );
            return;
        }

        const deadlineDate = editOfferData.deadline === "Off" ? null : getFormattedDeadline(editOfferData.deadline)

        OfferApi.addOfferWorkers(offer.id, editOfferData.workerIds, deadlineDate).then(() => {
            setEditOfferData({ listId: 0, workerIds: [], deadline: "Off" })
            toast.success('Offer updated');
            handleAddWorkersToOffer && handleAddWorkersToOffer(offer)
        });

    }

    const getFormattedDeadline = (selectedDeadline) => selectedDeadline && selectedDeadline.startsWith("in") ?
        Utilities.dateAdd(new Date(), selectedDeadline.split(" ")[2].replace("s", ""), selectedDeadline.split(" ")[1]) :
        new Date(selectedDeadline);

    const finishCompleteOffer = (
        recipientAgencies: OfferRecipientAgency[],
        rejectUnsuccessful: boolean,
        selectedRecipients: OfferRecipientDto[],
        offer: OfferInfoDto,
        filesCount: number = 0
    ) => {

        OfferApi.completeOffer(offer.id, recipientAgencies, rejectUnsuccessful, filesCount).then(() => {
            OfferApi.scheduleRateRequest(offer.id)
        })   

        setSelectedRecipients([]);
        handleViewCancel && handleViewCancel();
        handleConfirmationComplete && handleConfirmationComplete(recipientAgencies, rejectUnsuccessful)

        toast.success('Offer confirmed!');

        InternalTracker.trackEvent("", {
            category: 'Offers',
            action: 'Offer Completed'
        });

        Analytics.trackEvent('offers-page-offer-sent');
    };

    const canOfferBeFavourited = offer.events.length === 1;
    const canOfferBeCompleted = selectedRecipients && selectedRecipients.length !== 0;

    return (
        <OfferCard
            className={`masked layout vertical ${
                showConfirmation ? 'confirmation' : ''
            } ${getOfferCardStatusClass()}`}
        >
            <i className="fas fa-times fa-hover" onClick={handleViewCancel} />
            <h4>{offer.title}</h4>
            <div className="first">
                <div className="layout vertical">
                    { (!showMoreWorkersToAddList) &&
                        <div className="details">
                            <div>
                                {offer.locationPlaceId && offer.locationPlaceId.length && (
                                    <div
                                        className="layout vertical location-wrapper"
                                        style={{
                                            height: Device.isSmallerThan(
                                                Resolutions.MobileMedium
                                            )
                                                ? '120px'
                                                : '260px',
                                            overflow: 'hidden'
                                        }}
                                    >
                                        <LocationMap
                                            placeId={offer.locationPlaceId}
                                            friendlyName={""}
                                            placeName={""}
                                        />
                                    </div>
                                )}
                            </div>
                            <div>
                                {offer.locationPlaceId && offer.locationPlaceId.length && ( (offer.locationFriendlyName && offer.locationFriendlyName.trim()) || offer.locationPlaceName && offer.locationPlaceName.trim() ) && (
                                    <div className='location-details'>
                                        <h5>Location</h5>
                                        <p>{offer.locationFriendlyName} ({offer.locationPlaceName})</p>
                                        <button onClick={() => { window.open('https://www.google.com/maps/dir/?api=1&destination=' + offer.locationPlaceName, '_system'); }}>
                                            Open on Google Maps
                                        </button>
                                    </div>
                                )}
                                <div>
                                    <h5>Events</h5>
                                    {renderEvents()}
                                </div>
                                {offer.description &&
                                    <div>
                                        <h5>More Details</h5>
                                        <p className="details">{offer.description || "This offer doesn't have any additional details"}</p>
                                    </div>
                                }
                                { (offer.files && offer.files.filter(file => !file.onlyConfirmedContacts).length > 0) &&
                                    <div>
                                        <h5>Files sent with offer</h5>
                                        <div className='files-list'>
                                            { offer.files.filter(file => !file.onlyConfirmedContacts).map((file) => {
                                                return (
                                                    <FileListItem 
                                                        file={file as UserFileWithFilterType}
                                                        onClick={() => {
                                                            onFileClick(file);
                                                        }}
                                                    />
                                                )
                                            }) }
                                        </div>
                                    </div>      
                                }
                                { (offer.files && offer.files.filter(file => file.onlyConfirmedContacts).length > 0) &&
                                    <div>
                                        <h5>Files for Confirmed Contacts</h5>
                                        <div className='files-list'>
                                            { offer.files.filter(file => file.onlyConfirmedContacts).map((file) => {
                                                return (
                                                    <FileListItem 
                                                        file={file as UserFileWithFilterType}
                                                        onClick={() => {
                                                            onFileClick(file);
                                                        }}
                                                    />
                                                )
                                            }) }
                                        </div>
                                    </div>      
                                }
                            </div>
                        </div>
                    }
                    { (openedFileUri) &&
                        <DocumentViewer
                            uri={openedFileUri}
                            type={openedFileType}
                            name={openedFileName}
                            id={openedFileId}
                            close={() => {
                                setOpenedFileType(null)
                                setOpenedFileName("")
                                setOpenedFileId(null)
                                setOpenedFileUri(null)
                            }}
                        />
                    }
                    { (!showMoreWorkersToAddList) &&
                        <div className="worker-existing">
                            {renderWorkers()}
                        </div>
                    }
                    <Expander expanded={showMoreWorkersToAddList || false}>
                        <>
                            <div className="worker-selector-wrapper">
                                <WorkerSelector
                                    listId={editOfferData ? editOfferData.listId : 0}
                                    handleListChange={(listId) => {
                                        setEditOfferData({
                                            ...editOfferData,
                                            listId: listId
                                        })

                                        createOfferUtilsFuncs.fetchAvailableContacts(
                                            listId,
                                            offer.events.map(
                                                ({ start, end }) => ({
                                                    start,
                                                    end
                                                })
                                            ),
                                            []
                                        )
                                    }}
                                    lists={lists}
                                    selectWorker={(contactUserId: string) => {
                                        toggleAlternativeWorkerToOffer(contactUserId)
                                    }}
                                    selectedWorkerIds={editOfferData ? editOfferData.workerIds : []}
                                    contacts={createOfferUtilsData.contacts.filter(
                                        ({ contact }) =>
                                            !offer.recipients
                                                .map((r) => r.recipientUserId)
                                                .includes(contact.userId)
                                    )}
                                    offerPeriods={[]}
                                    isLoadingContacts={false}
                                    skills={[]}
                                    setSkills={(skills) => {}}
                                    hideExpand={true}
                                    hideAvailability={true}
                                />
                            </div>
                            <div className='deadline-wrapper'>
                                <h5>Offer Response Deadline</h5>
                                <select 
                                    value={editOfferData.deadline}
                                    onChange={(ev) => {
                                        const value = ev.target.value;
                                        setEditOfferData({
                                            ...editOfferData,
                                            deadline: value === "Custom Time" ? Utilities.formatDate(Utilities.dateAdd(new Date(), "day", 1), "") : value,
                                        })
                                    }}
                                >
                                    { DEADLINE_PRESETS.map((period, i) => {
                                        return (
                                            <option key={period} value={i === 0 && editOfferData.deadline && !editOfferData.deadline.startsWith("in") ? editOfferData.deadline : period}>{period}</option>
                                        )
                                    })}
                                </select>

                                {/* { selectedDeadline && !selectedDeadline.startsWith("in") && selectedDeadline !== "Off" &&
                                    <div className='date-time-picker-wrapper'>
                                        <DateTimePicker 
                                            onChange={(value) => {
                                                this.updateOfferFormData(
                                                    (prevOffer) => {
                                                        return {
                                                            ...prevOffer,
                                                            deadline: new Date(value)
                                                        }
                                                    });
                                            }}
                                            value={new Date(this.getOfferFormData().deadline)}
                                        />
                                    </div>
                                } */}

                                { (editOfferData.deadline && editOfferData.deadline !== "Off") &&
                                    <p>
                                        Offers to contacts, who don’t respond by { Utilities.formatDate(new Date(getFormattedDeadline(editOfferData.deadline)), "ds DD MMM YYYY") } ({ Utilities.formatDate(new Date(getFormattedDeadline(editOfferData.deadline)), "HH:MM") }) will be auto declined.
                                        This avoids uncertainty and encourages contacts to reply to offers promptly for greater choice.
                                    </p>
                                }
                            </div>
                            <div
                                className="options options-two"
                                style={{
                                    marginTop: -10
                                }}
                            >
                                <SimpleTooltip
                                    id="addMoreWorkers-tooltip"
                                    text={
                                        showMoreWorkersToAddList
                                            ? 'Finish adding more contacts.'
                                            : 'Send this offer to more contacts.'
                                    }
                                >
                                    <Button
                                        bsStyle="danger"
                                        onClick={() => {
                                            setShowMoreWorkersToAddList(false);
                                        }}
                                    >
                                        <i className="fas fa-user-times" />{' '}
                                        <span>Cancel adding contacts</span>
                                    </Button>
                                </SimpleTooltip>
                                <Button
                                    onClick={() => {
                                        addWorkersToOffer(offer, editOfferData)
                                    }}
                                    bsStyle="success"
                                    disabled={
                                        editOfferData && editOfferData.workerIds.length === 0
                                    }
                                >
                                    <i className="fas fa-check"></i>
                                    <span>Offer to alternatives</span>
                                </Button>
                            </div>
                        </>
                    </Expander>

                    { offerIdToLoad !== "demo" &&
                        <Expander expanded={!showMoreWorkersToAddList}>
                            <>
                                {[
                                    OfferStatus.Ready,
                                    OfferStatus.Waiting,
                                    OfferStatus.Confirmed,
                                    OfferStatus.ConfirmedDeletions
                                ].includes(offerStatus) && (
                                    <>
                                        {offer.recipients.length != 1 &&
                                            (offerStatus === OfferStatus.Ready ||
                                                offerStatus ===
                                                    OfferStatus.Waiting) && (
                                                <Dialog
                                                    type="info"
                                                    body={appliedCount > 0
                                                        ? 'Select contacts above who have applied for this offer to confirm'
                                                        : 'No contacts have applied for this offer yet'}
                                                />
                                            )}
                                    </>
                                )}
                            </>
                        </Expander>
                    }

                    { ((!showMoreWorkersToAddList) && offerIdToLoad !== "demo" && !offer.externalHirerId) &&
                        <div 
                            className={"options" + ((isOfferResolved(offer)) ? " options-two" : "")}
                            style={{
                                marginTop: 5
                            }}
                        >
                            {[
                                OfferStatus.Ready,
                                OfferStatus.Waiting,
                                OfferStatus.Declined,
                                OfferStatus.ConfirmedDeletions,
                                OfferStatus.Unknown
                            ].includes(offerStatus) && (
                                <React.Fragment>
                                    <Button
                                        bsStyle="primary"
                                        bsSize="small"
                                        style={{
                                            backgroundColor:
                                                'blue!important'
                                        }}
                                        onClick={() => {
                                            setShowMoreWorkersToAddList(true);
                                            createOfferUtilsFuncs.fetchAvailableContacts(
                                                0,
                                                offer.events.map(
                                                    ({ start, end }) => ({
                                                        start,
                                                        end
                                                    })
                                                ),
                                                []
                                            )
                                        }}
                                    >
                                        <i className="fas fa-user-plus" />{' '}
                                        <span>Offer to alternatives</span>
                                    </Button>
                                    <Button
                                        bsStyle="danger"
                                        onClick={() => {
                                            withdrawThisOffer(offer);
                                        }}
                                    >
                                        <i className="fas fa-ban"></i>
                                        <span>Withdraw offer</span>
                                    </Button>
                                    {(offerStatus === OfferStatus.Ready ||
                                        offerStatus ===
                                            OfferStatus.Waiting) && (
                                        <SimpleTooltip
                                            id="complete-offer-tooltip"
                                            hide={canOfferBeCompleted}
                                            text={!appliedCount ? "No Applications yet" : !canOfferBeCompleted ? "Please select at least one contact who has applied to complete the offer" : "Tap to Confirm Offer"}
                                        >
                                            <Button
                                                bsStyle="success"
                                                className="m-l-xs"
                                                onClick={handleCompleteOffer}
                                                disabled={
                                                    !canOfferBeCompleted
                                                }
                                            >
                                                <i className="fas fa-check-circle"></i>
                                                <span>Complete offer</span>
                                            </Button>
                                        </SimpleTooltip>
                                    )}
                                </React.Fragment>
                            )}

                            {isOfferResolved(offer) && (
                                <Button
                                    bsStyle="danger"
                                    onClick={() => {
                                        handleDeleteOffer && handleDeleteOffer();
                                        handleViewCancel && handleViewCancel();
                                    }}
                                >
                                    <i className="fas fa-ban"></i>
                                    <span>Delete offer</span>
                                </Button>
                            )}

                            {offerStatus === OfferStatus.Confirmed &&
                                <Button
                                    bsStyle="danger"
                                    onClick={() => {
                                        withdrawThisOffer(offer)
                                    }}
                                >
                                    <i className="fas fa-ban"></i>
                                    <span>Withdraw offer</span>
                                </Button>
                            }

                            <SimpleTooltip
                                id="convert-to-favourite-tooltip"
                                text={
                                    canOfferBeFavourited
                                        ? 'Saving this offer as a favourite will allow you to easily re-use this offer again in the future'
                                        : 'This offer is too complex to be favourited, please contact us if you would like to be able to favourite this offer.'
                                }
                            >
                                <Button
                                    bsStyle="warning"
                                    bsSize="small"
                                    onClick={ async () => {{
                                        if (favoritingOffer) return;
                                        setFavoritingOffer(true);
                                        await favoriteOffer(offer);
                                        handleFavouriteOffer && handleFavouriteOffer(offer);
                                        setFavoritingOffer(false);
                                    }}}
                                    disabled={!canOfferBeFavourited}
                                >
                                    <i className="fas fa-star"></i>
                                    <span>{ favoritingOffer ? "Saving..." : "Save to favourites" }</span>
                                </Button>
                            </SimpleTooltip>

                        </div>
                    }
                </div>
            </div>

            {/* Condition is inside class to allow for animation */}
            <div className="second">
                {showConfirmation && (
                    <ConfirmOffer
                        alreadySelectedFiles={offer.files ? offer.files.map(file => file.id || 0) : []}
                        offerId={offer.id}
                        recipients={selectedRecipients || []}
                        showUnsuccessful={offer.recipients.some(
                            (r) =>
                                r.response == OfferResponseTypes.Yes &&
                                (selectedRecipients || []).indexOf(r) >= 0
                        )}
                        onCancel={handleViewCancel || (() => {})}
                        onConfirm={(recipientAgencies, rejectUnsuccessful, filesCount) => {
                            finishCompleteOffer(recipientAgencies, rejectUnsuccessful, selectedRecipients, offer, filesCount);
                        }}
                        autoComplete={autoComplete}
                    />
                )}
            </div>
        </OfferCard>
    );
};

export default (props) => (
    <CreateOfferUtilsProvider hasOrganisation={props.organistionId != null}>
        {({ data, functions }) => (
            <ViewOffer
                createOfferUtilsData={data}
                createOfferUtilsFuncs={functions}
                // @ts-ignore
                {...props}
            />
        )}
    </CreateOfferUtilsProvider>
);