/* eslint-disable */
import { ContactWithRating } from 'src/components/rating/WorkerRater';
import AppConfig from '../../components/config/Config';
import afetch from '../AuthenticatedFetch';
import { Rating } from '../offers/ResponseTypes';
import { ReportPostDto } from './RequestTypes';
import AuthenticatedFetch from '../AuthenticatedFetch';

const ReportApi = {
    submit(entityId: number, typeId: number, notes: string, contactId: string) {
        return AuthenticatedFetch.request<ReportPostDto, null>(
            `${AppConfig.Settings.api.externalUri}/report`,
            'POST',
            {
                entityId: entityId,
                typeId: typeId,
                notes: notes,
                contactId: contactId
            },
            'Unable to submit report'
        );
    }
};

export default ReportApi;
