/* eslint-disable */
import afetch from '../AuthenticatedFetch';
import AppConfig from '../../components/config/Config';
import {
    CreateCheckoutSessionRequest,
    UpdateSubscriptionItemRequest,
    BillingPortalSessionRequest
} from './RequestTypes';
import {
    GetFeatureResponse,
    SubscriptionsResponse,
    BillingPortalSessionRequestResponse
} from './ResponseTypes';
import { Feature, SubscriptionRelationshipType } from '../../constants';

const SubscriptionsApi = {
    getFeature({
        featureId,
        subRelationshipId = SubscriptionRelationshipType.Organisation
    }: {
        featureId: Feature;
        subRelationshipId?: SubscriptionRelationshipType;
    }) {
        const url = `${AppConfig.Settings.api.externalUri}/subscriptions/prices/${subRelationshipId}/${featureId}`;
        return afetch.request<null, GetFeatureResponse>(
            url,
            'GET',
            null,
            'Unable to get price for feature'
        );
    },
    getFeatures() {
        const url = `${AppConfig.Settings.api.externalUri}/subscriptions/prices/${SubscriptionRelationshipType.Organisation}`;
        return afetch.request<null, GetFeatureResponse>(
            url,
            'GET',
            null,
            'Unable to get price for features'
        );
    },
    getTeamsFeature() {
        return this.getFeature({ featureId: Feature.Teams });
    },
    getRotaFeature() {
        return this.getFeature({ featureId: Feature.Rota });
    },
    getLocationsFeature() {
        return this.getFeature({ featureId: Feature.Locations });
    },

    getAllFeatureSubscriptions() {
        const url = `${AppConfig.Settings.api.externalUri}/subscriptions`;
        return afetch.request<null, SubscriptionsResponse>(
            url,
            'GET',
            null,
            'Unable to get subscriptions'
        );
    },

    updateSubscriptionItem(
        itemId: SubscriptionsResponse[0]['items'][0]['id'],
        payload: UpdateSubscriptionItemRequest
    ) {
        const url = `${AppConfig.Settings.api.externalUri}/subscriptions/${itemId}`;
        return afetch.request<UpdateSubscriptionItemRequest, null>(
            url,
            'PUT',
            payload,
            'Unable to update subscription',
            undefined,
            true
        );
    },

    createCheckoutSession(payload: CreateCheckoutSessionRequest) {
        const url = `${AppConfig.Settings.api.externalUri}/subscriptions/createCheckoutSession`;
        return afetch.request<CreateCheckoutSessionRequest, string>(
            url,
            'POST',
            payload,
            'Unable to create checkout session',
            undefined,
            true
        );
    },

    activateFeature(featureId: Feature) {
        const url = `${AppConfig.Settings.api.externalUri}/subscriptions/${featureId}/activate`;
        return afetch.request<null, null>(
            url,
            'PUT',
            null,
            'Unable to activate feature.'
        );
    },

    cancelFeature(featureId: Feature) {
        const url = `${AppConfig.Settings.api.externalUri}/subscriptions/${featureId}/cancel`;
        return afetch.request<null, null>(
            url,
            'PUT',
            null,
            'Unable to cancel feature.'
        );
    },

    getCustomerPortalUrl() {
        const url = `${AppConfig.Settings.api.externalUri}/subscriptions/create-billing-portal-session`;
        return afetch.request<
            BillingPortalSessionRequest,
            BillingPortalSessionRequestResponse
        >(
            url,
            'POST',
            {
                paymentProviderId: 1,
                subscriptionRelationshipType: 2,
                returnUrl: '/organisation/subscriptions'
            } as BillingPortalSessionRequest,
            'Unable create checkout session.'
        );
    },

    createCheckoutSessionForSMSCreditPurchase(quantity: number) {
        const url = `${AppConfig.Settings.api.nodeFunctionsUri}/payment/create?${AppConfig.Settings.api.nodeFunctionsUriSecret}`;
        return afetch.request<
            {
                product: "smsCredit",
                quantity: number
            },
            {
                "id": string,
                "url": string,
                "product": "smsCredit",
                "quantity": number
            }
        >(
            url,
            'POST',
            {
                product: "smsCredit",
                quantity: quantity
            },
            'Unable create checkout session.'
        );
    },

    redeemPurchase(purchaseId: string) {
        const url = `${AppConfig.Settings.api.nodeFunctionsUri}/payment/${purchaseId}/verify?${AppConfig.Settings.api.nodeFunctionsUriSecret}`;
        return afetch.request<
            null,
            string
        >(
            url,
            'GET',
            null,
            'Unable create verify purchase. Please contact us if this keeps happening',
            undefined,
            false,
            false,
            true
        );
    },
};

export default SubscriptionsApi;
