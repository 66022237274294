import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

import Global from './../../api/Global';

/** Defines the page footer */
export class Footer extends React.Component<{}, {}> {
    public render() {
        return (
            <Foot className="footer">
                <span className="pull-right">
                    <a href="https://ukliveue.blob.core.windows.net/policies/PortalEULA.htm">
                        Terms of Use |
                    </a>
                    <a href="https://ukliveue.blob.core.windows.net/policies/PortalPrivacy.htm">
                        Privacy Policy |
                    </a>
                    &copy; Copyright updatedge Ltd 2017-
                    {moment().format('YYYY')}
                    <span className="rights-reserved">
                        {' '}
                        (All Rights Reserved)
                    </span>{' '}
                    | {Global.getVersion()}
                </span>
            </Foot>
        );
    }
}

const Foot = styled.footer`
    @media (max-width: 930px) {
        .rights-reserved {
            display: none;
        }

        a {
            display: none;
        }
    }

    @media (max-width: 768px) {
        display: none;
    }

    a {
        margin: 0 5px;
        color: #6a6c6f;
    }
`;
