import React from 'react';

import ProfileAgencies from '../components/profile/ProfileAgencies';

const MyAgencies = () => (
    <div>
        <div className="row">
            <div className="hpanel">
                <div className="panel-body">
                    <h2>Your Agencies</h2>
                    <small style={{ marginBottom: 12 }}>
                        Add agencies you work with. When confirming orders to
                        contacts, an assignment request is emailed to the prime
                        contact at the agency you select for that assignment.
                    </small>
                    <ProfileAgencies />
                </div>
            </div>
        </div>
    </div>
);

export default MyAgencies;
