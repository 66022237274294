/** REDUX State model definitions for contact management */

import * as BaseModels from '../BaseModels';
import * as ContactModels from './Models';

/**
 * Default state for REDUX state tree
 */
export const defaultState: ContactAndListManagementState = {
    listMgtState: {
        lists: [],
        creating: false,
        selected: {
            count: 0,
            id: 0,
            loaded: false,
            name: '',
            contactGroupName: ''
        },
        loaded: false
    },
    contactMgtState: {
        contacts: [],
        selected: {
            count: 0,
            id: 0,
            loaded: false,
            name: '',
            contactGroupName: ''
        },
        loaded: false
    }
};

/** Stores state when using contact management  */
export interface ContactAndListManagementState {
    /** Contact lists */
    listMgtState: ListManagementState;

    /** Contacts*/
    contactMgtState: ContactManagementState;
}

/** Defines the state model for contact and group collections */
export interface ListManagementState
    extends BaseModels.Creatable,
        BaseModels.Loadable,
        BaseModels.SingleItemSelectableCollection<ContactModels.ContactList> {
    /** Contact groups in this group */
    lists: ContactModels.ContactList[];
}

interface ContactManagementState
    extends BaseModels.SingleItemSelectableCollection<
            ContactModels.ContactList
        >,
        BaseModels.Loadable {
    contacts: Pick<
        ContactModels.Contact,
        'id' | 'fullName' | 'profileImageUrl'
    >[];
}
