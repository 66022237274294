export enum Resolutions {
    MobileSmall = 320,
    MobileMedium = 375,
    MobileLarge = 425,
    Tablet = 768,
    Laptop = 1024,
    Desktop = 1440
}

const Device = {
    window: {
        width: window.innerWidth,
        height: window.innerHeight
    },
    isSmallerThan: (width: Resolutions) => window.innerWidth < width,
    isLargerThan: (width: Resolutions) => window.innerWidth > width
};

export default Device;
