/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import BigCalendar, { View } from 'react-big-calendar';
import styled from 'styled-components';
import { bindActionCreators } from 'redux';
import { toast } from 'react-toastify';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import * as ContactAvailabilityStore from '../../store/Availability';
import { ApplicationState } from '../../store';
import { ContactProfileState } from '../../store/contactprofile/States';
import InviteApi from '../../api/invites/Invites';
import { AsyncOverlay } from '../ui-components/AsyncOverlay';
import Analytics from '../../services/analytics.service';
import EventApi from '../../api/events/Events';
import SimpleTooltip from '../../components/ui-components/SimpleTooltip';
import InternalTracker from 'src/InternalTracker';
import Utilities from 'src/Utilities';

interface UserCalendarProps {
    availabilityState: ContactAvailabilityStore.ContactAvailabilityState;
    availabilityActionCreators: typeof ContactAvailabilityStore.actionCreators;
    contactProfileState: ContactProfileState;
}

interface InferredUnavailableDate {
    start: string;
    end: string;
    length: string;
    startsBefore: boolean;
    endsAfter: boolean;
}

interface State {
    allowed: boolean;
    invited: boolean;
    view: View;
    date: Date;
    fetching: boolean;
    hoveredDate: Date | undefined;
    inferredUnavailableDates: InferredUnavailableDate[];
}

interface CalendarEvent {
    title: string;
    isAllDay: boolean;
    event: ContactAvailabilityStore.TimelineEventModel;
}

const DayEntry: React.SFC<object> = (props) => {
    const calEvent = props as CalendarEvent;

    // return (
    //     <div>
    //         {calEvent.event.title}
    //     </div>
    // )

    let iconFileName: string;

    switch (calEvent.event.eventTypeId) {
        case 1:
            iconFileName = 'Working_x16.png';
            break;
        case 2:
            iconFileName = 'PrivateNonMoveable_x16.png';
            break;
        case 3:
            iconFileName = 'PrivateMoveable_x16.png';
            break;
        case 4:
            iconFileName = 'AvailableNow.png';
            break;
        default:
            iconFileName = '';
            break;
    }

    const iconUrl = '/img/eventtypes/' + iconFileName;

    const content = (
        <div style={{ fontSize: '11px' }}>
            <img src={iconUrl} alt={calEvent.event.eventType} />
            &nbsp;
            {calEvent.event.repeat && (
                <i className="fa fa-repeat text-primary " />
            )}
            &nbsp;
            {moment(calEvent.event.start).format('HH:mm')} -{' '}
            {moment(calEvent.event.end).format('HH:mm')}
            &nbsp;{calEvent.event.eventType && typeof calEvent.event.eventType === "string" ? (calEvent.event.eventType.replace("AvailableNow", "Available Now")) : ""}
        </div>
    );

    return (
        <div>
            { (calEvent.event.id === "" && calEvent.event.title === "Send Offer") ?
                <div 
                    style={{ fontSize: '11px', textAlign: 'center', fontWeight: 700 }}
                    onClick={() => {
                        // @ts-ignore
                        const contactId = calEvent.event.userId;
                        InternalTracker.trackEvent("Offer Creation Started", {
                            source: "contactpage",
                            contactId: contactId
                        })
                        
                        if (window.location.pathname.startsWith("/external")) {
                            window.location.href = "/";
                        } else {
                            window.open("/offers#create/worker/" + contactId + "?date=" + Utilities.formatDate(calEvent.event.start.toDate(), "YYYY-MM-DD"));
                        }
                    }}
                >
                    <i style={{ marginRight: 8 }} className='fas fa-briefcase-medical' />
                    <span>Send Offer</span>
                </div> 
                : (calEvent && calEvent.event && calEvent.event.title && calEvent.event.title.startsWith("Inferred Unavailability")) ?
                    <React.Fragment>
                        {/* @ts-ignore */}
                        <SimpleTooltip id="event-tooltip" text={calEvent.event.title.indexOf("Start") !== -1 ? (<div>Unavailability is inferred from {Utilities.formatDate(calEvent.event.start, "HH:MM")} due to this worker's next <span><img src="/img/eventtypes/AvailableNow.png" alt="AvailableNow" />Available Now</span> event.</div>) : (<div>Unavailability is inferred until {Utilities.formatDate(calEvent.event.end, "HH:MM")} due to this worker's next <span><img src="/img/eventtypes/AvailableNow.png" alt="AvailableNow" />Available Now</span> event.</div>) }>
                            <div style={{ fontSize: '11px' }}>
                                <i className="fas fa-exclamation-triangle"></i>
                                &nbsp;
                                {calEvent.event.title}
                            </div>
                        </SimpleTooltip>
                    </React.Fragment>
                :
                <SimpleTooltip id="event-tooltip" text={content}>
                    {content}
                </SimpleTooltip>
            }
        </div>
    );
};

class Calendar extends React.Component<UserCalendarProps, State> {
    state = {
        allowed: true,
        invited: false,
        view: 'month' as View,
        date: new Date(),
        fetching: false,
        hoveredDate: undefined,
        inferredUnavailableDates: [] as InferredUnavailableDate[]
    };

    constructor(props) {
        super(props);

        this.bindScopes(['onNavigate']);

        BigCalendar.momentLocalizer(moment);
    }

    /**
     * Fires when the component is added to the screen
     */
    componentDidMount() {
        moment.updateLocale('en', {
            week: {
                dow: 1, // Monday is the first day of the week.
                doy: 4 // The week that contains Jan 4th is the first week of the year.
            }
        });

        if (this.props.contactProfileState.contact.userId) {
            this.getEvents();
        }
    }

    /**
     * Fires when the component's state updates
     */
    componentDidUpdate(prevProps: UserCalendarProps) {
        if (
            prevProps.contactProfileState.contact.userId !==
            this.props.contactProfileState.contact.userId
        ) {
            this.getEvents();
        }
    }

    private getInferredUnavailableDates = async () => {
        const inferredUnavailableDates = await EventApi.getInferredUnavailableDates(
            this.props.contactProfileState.contact.id,
            this.firstVisibleDate().toISOString(),
            this.lastVisibleDate().toISOString()
        );

        if (inferredUnavailableDates.length !== 0 && inferredUnavailableDates[0].timeframes) {
            console.log("About to set just now")
            this.setState({ 
                inferredUnavailableDates: inferredUnavailableDates[0].timeframes
            })
        }
    }

    private getEvents = () => {
        if (!this.props.contactProfileState.contact.userId) return;

        // Show async indicator on UI
        this.setState({
            fetching: true
        });

        InternalTracker.trackEvent("Calendar Events Filter", {
            userId: this.props.contactProfileState.contact.userId,
            startDate: this.firstVisibleDate().toISOString(),
            endDate: this.lastVisibleDate().toISOString()
        })

        // Fetch events
        this.props.availabilityActionCreators
            .getCalendarEvents(
                this.props.contactProfileState.contact.userId,
                this.firstVisibleDate(),
                this.lastVisibleDate()
            )
            .then(() => {
                // Events retrieved without error - allowed to view calendar
                this.setState({
                    allowed: true,
                    fetching: false
                });

                this.getInferredUnavailableDates();
            })
            .catch((errors) => {
                // Error - no consent - not allowed to view calendar
                if (errors.no_consent) {
                    this.setState({
                        allowed: false,
                        fetching: false
                    });
                } else {
                    this.getInferredUnavailableDates();
                }
            });
    };

    // find the start of the week in which the first day of the month falls
    private firstVisibleDate() {
        if (this.state.view === 'agenda') {
            // Agenda view runs from given date
            return moment(this.state.date).startOf('day');
        }

        return moment(this.state.date)
            .startOf('month')
            .startOf('week');
    }

    // find the end of the last day of the week in the month in which the last day of the month falls
    private lastVisibleDate() {
        if (this.state.view === 'agenda') {
            // Agenda view runs for 30 days
            return moment(this.state.date)
                .endOf('day')
                .add(30, 'day');
        }

        return moment(this.state.date)
            .endOf('month')
            .endOf('week');
    }

    /**
     * If a user has two events set to the same time but one in BST and one in GMT this ensures they display as the same time
     *
     * For example without this modification if today is GMT and tomorrow is BST
     * When I create an event between 08:00-17:00 for both days in the mobile app
     * The calendar would show one as 08:00-17:00 and the other for 09:00-18:00
     * This ensures they both show as 08:00-17:00
     *
     * This is a naive solution and will probably not work outside of the UK
     * It will therefore need reconsidering if we globalise
     */
    private adjustTimeZone = (date: Date): Date => {
        const result = new Date(
            date.getUTCFullYear(),
            date.getUTCMonth(),
            date.getUTCDate(),
            date.getUTCHours(),
            date.getUTCMinutes(),
            date.getUTCSeconds()
        );

        result.setTime(result.getTime() - date.getTimezoneOffset() * 60 * 1000);
        return result;
    };

    /**
     * Fires when the user changes the month in the calendar
     */
    onNavigate(newDate: Date) {
        // Store new date then trigger fetch
        this.setState(
            {
                date: newDate
            },
            this.getEvents
        );
    }

    handleInviteToShare = () => {
        const contact = this.props.contactProfileState.contact;

        // Send availability share request
        InviteApi.inviteContacts([
            {
                id: contact.id,
                isExternal: false,
                email: contact.email,
                name: contact.fullName
            }
        ]);

        // UI feedback
        this.setState({
            invited: true
        });
        toast.success(`Share request sent to ${contact.fullName}`);
    };

    handleViewChange = (view: View) => {
        const changed = this.state.view !== view;

        this.setState({ view });

        InternalTracker.trackEvent("Calendar View Changed", {
            view
        })

        if (changed) {
            // Reload events if view changed
            this.getEvents();
        }
    };

    render() {
        const hoveredDate = this.state.hoveredDate ? moment(this.state.hoveredDate).format('YYYY-MM-DD') : "";
        const eventsToRender = (this.props.availabilityState.calendarEvents.events.map(
            (event) => ({
                ...event,
                start: this.adjustTimeZone(
                    moment(event.start).toDate()
                ),
                end: this.adjustTimeZone(
                    moment(event.end).toDate()
                )
            })
        /* @ts-ignore */
        )).concat(this.state.inferredUnavailableDates.map(inua => {
            return [
                inua.endsAfter ? null : {
                    id: "",
                    title: "Inferred Unavailability End",
                    userId: this.props.contactProfileState.contact.id,
                    eventTypeId: 4,
                    eventType: 4,
                    repeat: 0,
                    repeatType: 0,
                    start: this.adjustTimeZone(moment(inua.end).startOf('day').toDate()),
                    end: this.adjustTimeZone(moment(inua.end).endOf('day').toDate()),
                    allDay: true,
                    status: 1,
                    workerId: "new"
                }
            ].filter(a => a)
        // @ts-ignore
        }).flat()).concat(hoveredDate ? [{ id: "", userId: this.props.contactProfileState.contact.id, title: "Send Offer", start: moment(hoveredDate).startOf('day'), end: moment(hoveredDate).endOf('day'), eventTypeId: 4, eventType: 4, repeat: 0, repeatType: 0 }] as ContactAvailabilityStore.TimelineEventModel[] : [])
        
        return (
            <CalendarContainer>
                {!this.state.allowed && (
                    <div className="not-allowed layout vertical center-center">
                        <div className="strike" />
                        <div className="message">
                            This contact is not sharing their availability with
                            you
                        </div>
                        <div className="invite">
                            {this.state.invited ? (
                                <span className="success">
                                    You have invited{' '}
                                    {
                                        this.props.contactProfileState.contact
                                            .firstName
                                    }{' '}
                                    to share their availability with you!
                                </span>
                            ) : (
                                <>
                                    <span>Want to view it?</span>
                                    <span
                                        className="link"
                                        onClick={this.handleInviteToShare}
                                    >
                                        Invite them to share...
                                    </span>
                                </>
                            )}
                        </div>
                    </div>
                )}

                <div id="calendar" data-view={this.state.view}>
                    <BigCalendar
                        eventPropGetter={this.eventStyleGetter}
                        dayPropGetter={this.dayPropGetter}
                        // @ts-ignore
                        events={eventsToRender}
                        components={{
                            event: DayEntry,
                            /* @ts-ignore */
                            dateCellWrapper: ({ value, children }) => {
                                const inferredStartDate = this.state.inferredUnavailableDates.length !== 0 && this.state.inferredUnavailableDates.find(
                                    (i) => Utilities.isSameDay(value, new Date(i.start))
                                );
                                const inferredEndDate = this.state.inferredUnavailableDates.length !== 0 && this.state.inferredUnavailableDates.find(
                                    (i) => Utilities.isSameDay(value, new Date(i.end))
                                );
                                /* @ts-ignore */
                                return (<div 
                                    className='date-cell-wrapper'
                                    onMouseOver={() =>{
                                        this.setState({
                                            hoveredDate: moment(value).toDate()
                                        })
                                    }}
                                    style={{
                                        height: '100%',
                                        width: '100%',
                                        position: 'relative',
                                        borderRight: '1px solid #DDD'
                                    }}
                                >
                                    { (inferredStartDate || inferredEndDate) ?
                                        <SimpleTooltip
                                            id="inferred-availability-tooltip"
                                            text="MORE"
                                        >
                                            {children}
                                        </SimpleTooltip>
                                        :
                                        children
                                    }
                                </div>)
                            }
                        }}
                        views={['month', 'week', 'day']}
                        drilldownView="week"
                        popup={true}
                        view={this.state.view}
                        onView={this.handleViewChange}
                        onNavigate={this.onNavigate}
                    />
                    <AsyncOverlay
                        show={
                            this.state.view === 'agenda' && this.state.fetching
                        }
                    />
                    {!this.props.availabilityState.calendarEvents.events
                        .length &&
                        this.state.view === 'agenda' &&
                        !this.state.fetching && (
                            <div className="no-events">
                                There are no events for the given date range
                            </div>
                        )}
                </div>
            </CalendarContainer>
        );
    }

    // binds scopes to "this" correctly
    bindScopes(keys) {
        for (const key of keys) {
            this[key] = this[key].bind(this);
        }
    }

    eventStyleGetter(event, start, end, isSelected) {
        let bgColor = '';
        let colour = 'white';
        switch (event.eventTypeId) {
            case 1:
                bgColor = 'red';
                break;
            case 2:
                bgColor = 'red';
                break;
            case 3:
                bgColor = 'orange';
                break;
            case 4:
                bgColor = 'rgb(235, 235, 0)';
                colour = 'black';
                break;
            case 10:
                bgColor = 'rgba(0,0,0,0.08)';
                colour = 'black';
                break;
        }
        const style = {
            backgroundColor: bgColor,
            color: colour
        };
        return { style: style };
    }

    dayPropGetter = (date: Date) => {
        const inferredUnavailable = this.state.inferredUnavailableDates.length !== 0 && this.state.inferredUnavailableDates.find(
            (i) => (moment(date) >= moment(i.start) && moment(date) <= moment(i.end)) || (Utilities.isSameDay(date, new Date(i.start)) || Utilities.isSameDay(date, new Date(i.end)))
        );

        return {
            className:
                inferredUnavailable
                    ? 'inferred-unavailability'
                    : ''
        };
    };
}

const inferredUnavailabilityStripes =
    'repeating-linear-gradient(-45deg, rgba(255, 200, 200, .4), rgba(255, 200, 200, .4) 15px, rgba(254, 220, 220, .4) 15px, rgba(254, 220, 220, .4) 30px)';

const CalendarContainer = styled.div`
    position: relative;

    > #calendar {
        position: relative;

        .rbc-event-label {
            display: none;
        }

        &[data-view="month"] {
            height: calc(100vh - 360px);
        }

        .no-events {
            position: absolute;
            top: 80px;
            width: 100%;
            font-weight: bold;
            text-align: center;
        }

        .rbc-agenda-view {
            > .rbc-agenda-table:first-child {
                position: fixed;
                margin-top: -2px;
            }

            > .rbc-agenda-content {
                padding-top: 20px;
            }
        }

        .rbc-agenda-table tr {
            color: black !important;
            background-color: white !important;
        }

        .inferred-unavailability {
            height: 100%;
            background: ${inferredUnavailabilityStripes} !important;
        }

        @media (min-width: 580px) {
            .rbc-toolbar-label {
                font-weight: 600;
                font-size: 20px;
            }
        }

        > .legend {
            position: absolute;
            top: -40px;
            right: -5px;
            padding: 5px;
            background: white;
            cursor: help;

            > span:first-child {
                display: inline-block;
                width: 50px;
                height: 20px;
                margin-right: 5px;
                background: ${inferredUnavailabilityStripes};
            }

            > span:last-child {
                font-weight: 600;
            }
        }
    }

    > .not-allowed {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;

        > .strike {
            position: absolute;
            width: 100%;
            height: 2px;
            background: black;
        }

        > .message {
            z-index: 1;
            padding: 0 20px;
            padding-bottom: 5px;
            font-weight: bold;
            font-size: 24px;
            background: white;
        }

        > .invite {
            padding-top: 5px;
            font-size: 18px;

            > .success {
                color: #07bc0c;
            }

            > .link {
                padding-left: 5px;
                color: #00e;
                font-weight: bold;
                font-style: italic;
                cursor: pointer;
                opacity: 0.7;

                &:hover {
                    text-decoration: underline;
                    opacity: 1;
                }
            }
        }

        + #calendar {
            opacity: 0.3;
        }
    }
`;

export default connect(
    (state: ApplicationState) => ({
        availabilityState: state.contactAvailability,
        contactProfileState: state.contactProfile
    }),
    (dispatch) => ({
        availabilityActionCreators: bindActionCreators(
            ContactAvailabilityStore.actionCreators,
            dispatch
        )
    })
)(Calendar);
