/* eslint-disable */
import afetch from '../AuthenticatedFetch';
import AppConfig from '../../components/config/Config';
import { CreateUserDto, UpdateUserDto } from './RequestTypes';
import { UserDto } from './ResponseTypes';
import UnauthenticatedFetch from 'src/api/UnauthenticatedFetch';

const apiUrls = {
    getAll() {
        return `${AppConfig.Settings.api.externalUri}/users`;
    },
    create() {
        return `${AppConfig.Settings.api.externalUri}/users`;
    },
    update() {
        return `${AppConfig.Settings.api.externalUri}/users`;
    },
    delete(id: string) {
        return `${AppConfig.Settings.api.externalUri}/users/${id}`;
    },
    getById(id: string) {
        return `${AppConfig.Settings.api.externalUri}/users/byid/${id}`;
    },
    getByEmail(email: string) {
        return `${AppConfig.Settings.api.externalUri}/users/byemail/${email}`;
    },
    activate(email: string) {
        return `${AppConfig.Settings.api.externalUri}/userprofile/activate?email=${email}`;
    },
    confirmAvailabilityNoAuth(token: string) {
        return `${AppConfig.Settings.api.externalUri}/userprofile/confirmAvailability?token=${token}`;
    }
};

const apiCalls = {
    confirmAvailabilityNoAuth(token: string) {
        return afetch.request<null, null>(
            apiUrls.confirmAvailabilityNoAuth(token),
            'POST',
            null,
            'Unable to confirm availability'
        );
    },
    getAll() {
        return afetch.request<null, UserDto[] | true>(
            apiUrls.getAll(),
            'GET',
            null,
            'Unable to get users'
        );
    },
    create(payload: CreateUserDto) {
        return afetch.request<CreateUserDto, string>(
            apiUrls.create(),
            'POST',
            payload,
            'Unable to create user'
        );
    },
    update(payload: UpdateUserDto) {
        return afetch.request<UpdateUserDto, string>(
            apiUrls.update(),
            'PUT',
            payload,
            'Unable to update user'
        );
    },
    delete(id: string) {
        return afetch.request<null, null>(
            apiUrls.delete(id),
            'DELETE',
            null,
            'Unable to delete user'
        );
    },
    deleteUnauthenticated(email: string) {
        return UnauthenticatedFetch.request<null, null>(`${AppConfig.Settings.api.externalUri}/users/unauthenticated?email=${email}&api-version=1.0`, 'DELETE', null);
    },
    getById(id: string) {
        return afetch.request<null, UserDto>(
            apiUrls.getById(id),
            'GET',
            null,
            'Unable to get user'
        );
    },
    getByEmail(email: string) {
        return afetch.request<null, UserDto>(
            apiUrls.getByEmail(email),
            'GET',
            null,
            'Unable to get user'
        );
    },
    activate(email: string) {
        return afetch.request<null, boolean>(
            apiUrls.activate(email),
            'GET',
            null,
            'Unable to activate user'
        );
    }
};

export default apiCalls;
