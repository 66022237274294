import React from 'react';
import { withRouter } from 'react-router-dom';

class App extends React.Component {
  
  componentWillMount() {
    // @ts-ignore
    this.unlisten = this.props.history.listen((location, action) => {
        setTimeout(() => {
            if (document.getElementsByClassName("content") && document.getElementsByClassName("content")[0]) {
                document.getElementsByClassName("content")[0].scrollTop = 0;
            }
        })
    });
  }
  componentWillUnmount() {
     // @ts-ignore
      this.unlisten();
  }
  render() {
     return (
         <div>{this.props.children}</div>
      );
  }
}
// @ts-ignore
export default withRouter(App);