/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { RouteComponentProps } from 'react-router-dom';

import { default as AvailabilityGrid } from '../components/availability/AvailabilityGrid';
import AvailabilityGridToolbar, {
    sortOption
} from '../components/availability/AvailabilityGridToolbar';
import * as AvailabilityStore from './../store/Availability';
import theme from 'src/css/theme';
import InternalTracker from 'src/InternalTracker';
import Joyride from 'react-joyride';
import SettingsAPI from 'src/api/settings/Settings';
import { Setting } from 'src/api/settings/ResponseTypes';
import UserApi from '../api/user/User';
import { EphemeralAccessTokenStorageKey } from 'src/components/layout/EphemeralAccessLayout';
import { toast } from 'react-toastify';
import AppConfig from '../components/config/Config';
import Utilities from 'src/Utilities';
import SimpleTooltip from '../components/ui-components/SimpleTooltip';

export class Availability extends React.Component<RouteComponentProps, {
    joyride: boolean | string,
    user: any | null,
    setAsHomePage: boolean,
    showFilters: boolean
}> {
    private AvailabilityPrinter: (() => void) | undefined;
    private SortByChange: ((so: sortOption) => void) | undefined;

    constructor(props) {
        super(props);

        if (localStorage.getItem("availabilityFiltersHidden") === null && window.window.innerWidth < 651) {
            localStorage.setItem("availabilityFiltersHidden", "true");
        }

        this.state = {
            joyride: "",
            user: null,
            setAsHomePage: false,
            showFilters: localStorage.getItem("availabilityFiltersHidden") === "true" ? false : true
        }
    }

    setAvailabilityPrinter(availabilityPrinter: () => void) {
        this.AvailabilityPrinter = availabilityPrinter;
    }

    onPrint() {
        InternalTracker.trackEvent("Availability Print v1")
        this.AvailabilityPrinter!();
    }

    onPrintV2() {
        InternalTracker.trackEvent("Availability Print v2")
        window.open("/availability/pdf");
    }

    componentDidMount() {
        if (window.location.pathname.startsWith("/external") && document.querySelector("#react-app>div")) {
            // @ts-ignore
            document.querySelector("#react-app>div")!.style.paddingTop = "0px";
        } 

        const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user") || "{}") : null;
        const showOnboarding =
            !localStorage.getItem("availabilityJoyrideDismissed") && 
            !window.location.pathname.startsWith("/external") &&
            !(user && user.settings.find((setting: any) => setting.settingId === 41 && setting.value === "true"));

        this.setState({
            user,
            setAsHomePage: user && user.settings && user.settings.find(setting => setting.settingId === Setting.Homepage && setting.value === "availability")
        })

        if (showOnboarding) {
            setTimeout(() => {
                this.setState({
                    joyride: "availability",
                })
            }, 1000)
        }
    }

    render() {
        const userIsAgency = localStorage.getItem("user") && JSON.parse(localStorage.getItem("user") || "").organisationIsAgency

        return (
            <Wrapper>
                <div className="row" id="availability-wrapper" data-time-sheet={window.location.pathname.startsWith("/external")}>
                    <div className="hpanel">
                        <div 
                            className="panel panel-body"
                            style={{
                                maxWidth: 1280,
                                padding: window.location.pathname.startsWith("/external") ? 0 : 20,
                                border: window.location.pathname.startsWith("/external") ? 'none' : '1px solid #eaeaea',
                                marginBottom: 60
                            }}
                        >
                            { (!window.location.pathname.startsWith("/external")) &&
                                <div
                                    className='flex'
                                >
                                    <h2
                                        className="font-light m-b-xs"
                                        style={{
                                            margin: 0
                                        }}
                                    >
                                        <span style={{ display: 'block' }}>Availability</span>
                                        { (!this.state.setAsHomePage) &&
                                            <button
                                                style={{
                                                    marginTop: 12,
                                                    padding: "6px 12px",
                                                    backgroundColor: theme.colours.blue2,
                                                    color: 'white',
                                                    border: 'none',
                                                    borderRadius: 52,
                                                    fontWeight: 500,
                                                    fontSize: 14,
                                                    position: 'relative',
                                                    top: '-4px'
                                                }}
                                                onClick={() => {
                                                    if (this.state.user) {
                                                        this.setState({
                                                            setAsHomePage: true
                                                        })
                                                        
                                                        SettingsAPI.update(Setting.Homepage, "availability").then(() => {
                                                            toast.success("Homepage updated to Availability");
                                                            let userSettings = this.state.user.settings || [];
                                                            userSettings = userSettings.filter(setting => setting.settingId !== Setting.Homepage);
                                                            userSettings.push({
                                                                settingId: Setting.Homepage,
                                                                value: "availability"
                                                            })
                                                            localStorage.setItem("user", JSON.stringify({
                                                                ...this.state.user,
                                                                settings: userSettings
                                                            }))
                                                        }).catch(() => {
                                                            toast.error("Failed to update homepage");
                                                        })
                                                    }
                                                }}
                                            >Set as Homepage</button>
                                        }
                                    </h2>
                                    {/* <small>
                                        View the availability of your contacts within a
                                        specified time period.
                                    </small> */}
                                    <div className='toggle-filters-wrapper'>
                                        <SimpleTooltip id="toggle-filters-tooltip" text="Toggle filter visibility to view more workers - useful on screens with reduced height.">
                                            <div 
                                                className='toggle-filters'
                                                onClick={() => {
                                                    this.setState({
                                                        showFilters: !this.state.showFilters
                                                    })
                                                    localStorage.setItem("availabilityFiltersHidden", this.state.showFilters ? "true" : "false");
                                                }}
                                                data-selected={this.state.showFilters}
                                            >
                                                <i className="fas fa-search"></i>
                                                <label>Search & Filters</label>
                                            </div>
                                        </SimpleTooltip>
                                    </div>
                                </div>
                            }
                            <div
                                className="toolbar-heading"
                                style={ window.location.pathname.startsWith("/external") ? { padding: '20px 20px 0 20px' } : {} }
                            >
                                <div
                                    className="panel-tools"
                                    style={{ float: 'none' }}
                                >
                                    <AvailabilityGridToolbar
                                        key={this.props.location.key}
                                        onPrint={this.onPrint.bind(this)}
                                        onPrintV2={this.onPrintV2.bind(this)}
                                        joyrideShowing={this.state.joyride !== ""}
                                        showFilters={this.state.showFilters}
                                    />
                                </div>
                            </div>
                            <div
                                className="panel-body"
                                id="availabilityGridTodayTomorrow"
                                data-timesheets={window.location.pathname.startsWith("/external") ? "true" : "false"}
                                style={{ 
                                    minHeight: 150,
                                    padding: 0,
                                    overflow: 'hidden',
                                    borderRadius: 10,
                                    maxWidth: 'unset',
                                    marginBottom: 0,
                                    marginTop: window.location.pathname.startsWith("/external") ? "0" : "16px",
                                    border: window.location.pathname.startsWith("/external") ? 'none' : '1px solid #eaeaea'
                                }}
                            >
                                <AvailabilityGrid
                                    setAvailabilityPrinter={this.setAvailabilityPrinter.bind(
                                        this
                                    )}
                                    supportsDaySelection={true}
                                    supportsUserSelection={true}
                                    key={this.props.location.key}
                                    joyrideShowing={this.state.joyride !== ""}
                                />
                            </div>
                            { (!userIsAgency) &&
                                <div id="get-more-contacts" style={{
                                    display: 'none'
                                }}>
                                    <span>Need more People?</span>
                                    <div className='profiles'>
                                        <img src="/img/avatars/1.jpg" alt="worker-1" />
                                        <img src="/img/avatars/11.jpg" alt="worker-3" />
                                        <img src="/img/avatars/3.jpg" alt="worker-5" />
                                        <img src="/img/avatars/13.jpg" alt="worker-6" />
                                        <img src="/img/avatars/14.jpg" alt="worker-7" />
                                    </div>
                                    <button onClick={() => {
                                        this.props.history.push("/invite");
                                    }}>Explore New Workers</button>
                                </div>
                            }
                            <div id="show-more-contacts" style={{
                                display: 'none',
                                padding: "20px 0 6px",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                                <p 
                                    id="availability-pagination-state"
                                    style={{
                                        margin: '0 12px 0 0'
                                    }}
                                ></p>
                                <button 
                                    onClick={() => {
                                        const el = document.getElementById('show-more-contacts-btn');
                                        if (el) el.click();
                                    }}
                                    style={{
                                        padding: "4px 12px 6px 12px",
                                        borderRadius: 52,
                                        backgroundColor: theme.colours.blue2,
                                        color: 'white',
                                        border: 'none'
                                    }}
                                >Show more contacts</button>
                            </div>
                        </div>
                    </div>
                    { (window.location.href.includes("/worker")) &&
                        <div className='availability-confirm-options'>
                            <button onClick={() => {
                                UserApi.confirmAvailabilityNoAuth(localStorage.getItem(EphemeralAccessTokenStorageKey) || "").then(() => {
                                    toast.success("Availability Confirmed!");
                                    const avaialbilityConfidenceCol = document.querySelector("td.overallMatch") as HTMLElement;
                                    if (avaialbilityConfidenceCol) {
                                        avaialbilityConfidenceCol.innerText = "100%";
                                    }
                                }).catch(() => {
                                    toast.error("Failed to confirm availability");
                                })
                            }}>Confirm Availability</button>
                            <button onClick={() => {
                                window.open(AppConfig.Settings.pwaUri, "_blank");
                            }}>Update Availability</button>
                        </div>
                    }
                </div>
                { (this.state.joyride !== "") &&
                    <Joyride
                        run={this.state.joyride !== ""}
                        callback={(data) => {
                            if (data.action === "reset" || data.action === "close") {
                                this.setState({
                                    joyride: ""
                                })
                                localStorage.setItem("availabilityJoyrideDismissed", "true");
                                SettingsAPI.update(Setting.Availability_HideGuide, "true") 
                            }
                        }}
                        locale={{
                            last: "Finish"
                        }}
                        continuous={true}
                        steps={[
                            {
                                target: '.filters.clearfix',
                                content: <div>
                                    <h1>Availability</h1>
                                    <p>Search by name, skills, & times</p>
                                </div>,
                                disableBeacon: true
                            },
                            {
                                target: document.documentElement.clientWidth < 1101 ?  '.contact .dropdown-toggle' : '.single-contact-options',
                                content: <div>
                                    <h1>Interaction options</h1>
                                    <p>Send Offers, Open Chat, Nudge for availability, See detailed availability, Remove</p>
                                </div>,
                                disableBeacon: true
                            }
                        ]}
                    />
                }
            </Wrapper>
        );
    }
}

const Wrapper = styled.div`

    .flex {
        display: flex;
        align-item: center;
        justify-content: space-between;
    }

    .toggle-filters-wrapper {

        .toggle-filters {
            background: whitesmoke;
            border-radius: 52px;
            padding: 8px 10px 8px 6px;
            cursor: pointer;
            display: inline-flex;
            align-items: center;
            justify-content: center;

            &[data-selected="true"] {
                background: gainsboro;
            }

            i {
                font-size: 24px;
                margin: 0 10px 0 5px;
            }

            label {
                margin: 0;
            }
        }
    }

    #get-more-contacts {
        background: gainsboro;
        padding: 18px 10px 12px 10px;
        display: flex;
        margin-top: -8px;
        align-items: center;
        justify-content: center;
        border-radius: 0 0 12px 12px;

        &>span {

        }

        .profiles {
            margin: 0 12px;
            text-align: center;

            img {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                margin-right: 5px;
            }
        }

        button {
            display: inline-block;
            background: ${theme.colours.blue2};
            color: white;
            border: none;
            padding: 6px 12px;
            border-radius: 52px;
        }

        @media (max-width: 650px) {
            display: block !important;
            text-align: center;
            font-weight: 700;
            margin-left: -20px;
            margin-right: -20px;

            .profiles {
                margin: 12px 12px;
            }
        }
    }

    @media (max-width: 650px) {
        margin: -20px;
        padding-top: 20px;
        background: white;

        .hpanel {
            margin-bottom: 0;

            .panel-body {
                border: none;
                // border-radius: 0;
                padding: 0;
                text-align: center;
            }
        }

        #availabilityGridTodayTomorrow {
            padding: 0;
        }

        .row:last-child .panel {
            margin-bottom: 0;
        }
    }
`;

export default connect(null, AvailabilityStore.actionCreators)(Availability);
