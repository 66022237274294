/* eslint-disable */
import React from 'react';
import Schedule from './Schedule';
import Availability from './Availability';
import theme from 'src/css/theme';
import styled from 'styled-components';

interface State {
    isCollapsed: boolean;
    workerCount: number
}

export class TimeSheets extends React.Component<{}, State> {

    state: State = {
        isCollapsed: window.location.pathname.startsWith("/external/timesheet") && !window.location.href.includes("/worker"),
        workerCount: 0,
    };

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Wrapper>
                <div 
                    className="timesheet-wrapper"
                    data-type={window.location.href.includes("/worker") ? "worker" : "hirer"}
                    style={{
                        padding: '0 10px'
                    }}
                >
                    <div 
                        className="availability-collapse-wrapper"
                        style={{
                            maxHeight: this.state.isCollapsed ? (document.documentElement.clientWidth < 400 ? '660px' : document.documentElement.clientWidth < 650 ? '560px' : '460px') : 'unset',
                            position: 'relative',
                            marginBottom: this.state.isCollapsed ? 56 : 32
                        }}
                    >
                        <div
                            style={{
                                maxHeight: this.state.isCollapsed ? (document.documentElement.clientWidth < 400 ? '660px' : document.documentElement.clientWidth < 650 ? '560px' : '460px') : 'unset',
                                overflow: 'hidden'
                            }}
                        >
                            <Schedule 
                                {...this.props}
                                onDataLoad={(workersCount) => {
                                    this.setState({
                                        workerCount: workersCount || 0
                                    })
                                }}
                            />
                        </div>
                        { (this.state.isCollapsed) &&
                            <div 
                                className="expand-btn"
                                style={{
                                    position: 'absolute',
                                    cursor: 'pointer',
                                    left: '50%',
                                    bottom: -26,
                                    transform: 'translate(-50%, 0)',
                                    padding: '8px 20px',
                                    borderRadius: 52,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    backgroundColor: theme.colours.blue2,
                                    border: '5px solid white',
                                    boxShadow: 'rgb(204, 204, 204) 0px 0px 4px 0px',
                                    zIndex: 20
                                }}
                                onClick={() => {
                                    this.setState({
                                        isCollapsed: false
                                    })
                                }}
                            >
                                <i
                                    className="fas fa-arrow-down"
                                    style={{
                                        fontSize: '2em',
                                        color: 'white'
                                    }}
                                />
                                <span
                                    style={{
                                        color: 'white',
                                        marginLeft: 12,
                                        fontSize: '1.1em',
                                        whiteSpace: 'nowrap'
                                    }}
                                >
                                    { this.state.workerCount < 3 ? "Show More" : ("Show " + (this.state.workerCount-2) + " More") }
                                </span>
                            </div>
                        }
                    </div>
                    { /* @ts-ignore */ }
                    <Availability {...this.props} />
                </div>
            </Wrapper>
        )
    }
}

const Wrapper = styled.div`

    .timesheet-wrapper {
        
        &>div {
            margin: 0 0 12px 0;
            padding: 0;

            &:last-child {
                overflow: hidden;
            }
       }
    }

    .timesheet-wrapper[data-type="hirer"], .timesheet-wrapper[data-type="worker"] {

        &>div {
            border-radius: 20px !important;
        }
    }

    .timesheet-wrapper[data-type="worker"] {

        .panel-body {
            border-radius: 0 !important;
        }

        .panel-body, .hpanel {
            margin-bottom: 0 !important;
        }

        table .contact > td:first-child {
            display: table-cell !important;
        }
    }
`

export default TimeSheets;