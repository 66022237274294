/* eslint-disable */
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import history from '../../history';
import '../../css/People.css';
import CompanyApi from '../../api/company/Company';
import OrganisationApi from '../../api/organisation/Organisation';
import OrganisationSyncApi from '../../api/organisationsync/OrganisationSync';
import { OrganisationSyncItemType } from '../../api/organisationsync/ResponseTypes';

import { ApplicationState } from '../../store';
import * as ProfileStore from '../../store/Profile';

import { bulkPut, getAll } from 'src/db/SyncCache';
import Utilities from 'src/Utilities';
import { UserQualification } from 'src/api/contacts/ResponseTypes';
import ProfileApi from 'src/api/profile/Profile';
import { ContactAvailabilityState } from 'src/store/Availability';
import { deleteValue, getValue, setValue } from 'src/db/KeyValueCache';
import { Badge, Checkbox, FormControlLabel, ToggleButtonGroup, ToggleButton, CircularProgress } from '@mui/material';
import { VerificationTypeId } from 'src/pages/ExternalVerification';
import ContactProfileModal from '../../pages/ContactProfile';
import SimpleTooltip from '../ui-components/SimpleTooltip';
import ReactStars from "react-rating-stars-component";
import moment from 'moment';
import TimePresetSlider from '../availability/TimePresetSlider';
import theme from 'src/css/theme';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { State, OrganisationSyncNormalizedItem, MergedUserProfileState, CachedOrgLocationToUserDistances, CachedUserAttribute, MiniAvailabilityGridDaySlot, CachedUserRepresentingAgencies, CachedTeamWithMembers, CachedLocation, CachedOrganisationSharingRelationship, EntitledGroupsWithMembers, SearchParams } from './People';

class PeopleGrid extends React.Component<
    State & {
        sync: () => void;
        clearFilters: () => void;
        openContact: (contactId: string) => void;
        filter: (searchParams: SearchParams) => void;
        updateFilters: boolean;
    },
    {
        searchParams: SearchParams;
    }
> {
    datesRef: React.RefObject<HTMLDivElement> = React.createRef();
    peopleRef: React.RefObject<HTMLDivElement> = React.createRef();
    scrollChecking: boolean = false;
    inputDebounce: any = null;
    startTime: number = 0;
    state = {
        searchParams: {
            ...this.props.searchParams
        }
    }

    componentWillUpdate() {
        this.startTime = performance.now();
    }

    componentDidUpdate(prevProps: any) {
        const endTime = performance.now();
        // console.log('[Grid] Render time:', endTime - this.startTime);

        if (!prevProps.updateFilters && this.props.updateFilters) {
            console.log("Update PRops Grid")
            this.setState({
                searchParams: {
                    ...this.props.searchParams
                }
            });
        }
    }

    public render() {
        const numberOfAvailabilityDays = this.props.view === "expanded" ? this.props.expandedDaysToDisplay : this.props.view === "hovered" ? 6 : 0;
        const startDay = new Date();
        const dayHashRange = [...Array(numberOfAvailabilityDays).keys()].map((i) => {
            return Utilities.formatDate(Utilities.dateAdd(startDay, "day", i), "YYYY-MM-DD");
        });

        return (
            <div 
                className='people people-wrapper'
                id="people-wrapper"
            >
                { (this.props.view === "expanded" || this.props.view === "hovered") &&
                    <div 
                        className='dates'
                        style={{
                            width: (numberOfAvailabilityDays * 25 + 170 + 240) + "px"
                        }}
                        ref={this.datesRef}
                    >
                        <div className='sync-status' onClick={() => {
                            if (!this.props.syncing) {
                                this.props.sync();
                            }
                        }}>
                            { (this.props.syncing) ?
                                <span>
                                    <CircularProgress thickness={6} size={20} />
                                    <span>Fetching Updates</span>
                                </span>
                                :
                                <span>
                                    <span>Last Synced {Utilities.timeSince(this.props.lastSynced*1000)}</span>
                                </span>
                            }
                        </div>
                        { [...Array(numberOfAvailabilityDays).keys()].map((i) => {
                            const date = Utilities.dateAdd(startDay, "day", i);
                            return (
                                <div 
                                    className='date'
                                    data-weekend={date.getDay() === 0 || date.getDay() === 6}
                                    data-selected={this.props.searchParams.mostAvailbleDates.includes(Utilities.formatDate(date, "YYYY-MM-DD"))}
                                    onClick={() => {
                                        let selectedDays = this.props.searchParams.mostAvailbleDates;
                                        if (selectedDays.includes(Utilities.formatDate(date, "YYYY-MM-DD"))) {
                                            selectedDays = selectedDays.filter(d => d !== Utilities.formatDate(date, "YYYY-MM-DD"));
                                        } else {
                                            selectedDays.push(Utilities.formatDate(date, "YYYY-MM-DD"));
                                        }
                                        // console.log("___ selectedDays", selectedDays);
                                        if (!selectedDays.length) {
                                            this.setState({
                                                searchParams: {
                                                    ...this.props.searchParams,
                                                    sortType: "last-updated",
                                                    mostAvailbleDates: []
                                                }
                                            }, () => {
                                                this.props.filter(this.state.searchParams)
                                            })
                                        } else {
                                            this.setState({
                                                searchParams: {
                                                    ...this.props.searchParams,
                                                    mostAvailbleDates: selectedDays,
                                                    sortType: "most-available"
                                                }
                                            }, () => {
                                                this.props.filter(this.state.searchParams)
                                            })
                                        }
                                    }}
                                >
                                    <span>{Utilities.formatDate(date, "d")}</span>
                                    <span>{Utilities.formatDate(date, "ms")}</span>
                                </div>
                            )
                        }) }
                    </div>
                }
                { this.props.sortedDisplayedUsers.map(userId => {
                    const user = this.props.userProfiles[userId];

                    return (
                        <div className='person' key={userId}>
                            <div className='profile'>
                                <div className='scrolling-user-profile' onClick={() => {
                                    this.props.openContact(user.contactId);
                                }}>
                                    <div className='verified-badge' data-verified={user.verified}>
                                        <i className="fas fa-shield-alt"></i>
                                    </div>
                                    <div className='profile-image'>
                                        {/* <img src={ProfileApi.getProfileImageUrl(user.id)} /> */}
                                        <LazyLoadImage
                                            height={44}
                                            src={ProfileApi.getProfileImageUrl(user.id)}
                                            width={44}
                                        />
                                    </div>
                                    { (this.props.view !== "collapsed") &&
                                        <div className='meta'>
                                            <p>{user.firstName} {user.lastName}</p>
                                            <div className='options'>
                                                <button>
                                                    <i className="fas fa-briefcase"></i>
                                                </button>
                                                <button>
                                                    <i className="fas fa-user"></i>
                                                </button>
                                                <button>
                                                    <i className="fas fa-comment"></i>
                                                </button>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className='user-profile'>
                                    <div className='row skills'>
                                        <SimpleTooltip
                                            placement='bottom'
                                            id={user.id + "-headline-tooltip"}
                                            text={
                                                <div className={'skills-tooltip-' + user.id}>
                                                    <div className="skills-tooltip">
                                                        {user.attributes.length > 0 ? [...new Set(user.attributes.map(attr => attr.skillName))].map((skillName) => {
                                                            return (
                                                                <span>{skillName}</span>
                                                            )
                                                        }) : <span>No Skills</span>}
                                                    </div>
                                                </div>
                                            }>
                                            <div className='skills'>
                                                {user.attributes.length > 0 ? [...new Set(user.attributes.map(attr => attr.skillName))].map((skillName) => {
                                                    return (
                                                        <span>{skillName}</span>
                                                    )
                                                }) : <span>No Skills</span>}
                                            </div>
                                        </SimpleTooltip>
                                    </div>
                                    <div className='row others'>
                                        <SimpleTooltip
                                            placement='bottom'
                                            id={user.id + "-verifications"}
                                            text={
                                                <div className={'last-updated-tooltip' + user.id}>
                                                    <div style={{ fontWeight: 700 }}>Last Confirmed Availability</div>
                                                    <div>{Utilities.formatDate(new Date(user.lastUpdatedTs || 0), "YYYY MMM DD HH:MM")}</div>
                                                    <div>({Utilities.timeSince(user.lastUpdatedTs)})</div>
                                                </div>
                                            }>
                                                <span className='last-updated'>
                                                    <i className="fas fa-clock"></i>
                                                    <span>{Utilities.timeSince(user.lastUpdatedTs, true)}</span>
                                                </span>
                                        </SimpleTooltip>
                                        <SimpleTooltip
                                            placement='bottom'
                                            id={user.id + "-verifications"}
                                            text={
                                                <div className={'last-updated-tooltip' + user.id}>
                                                    <div style={{ fontWeight: 700 }}>Distance from you</div>
                                                    <div>{user.milesDistance !== 1000000 ? (user.milesDistance + "mi") : "-"}</div>
                                                    <div style={{ fontWeight: 700 }}>Max Distance willing to travel</div>
                                                    <div>{user.maxDistance ? (user.maxDistance + "mi") : "-"}</div>
                                                </div>
                                            }>
                                            <span className='distance'>
                                                <i className="fas fa-location-arrow"></i>
                                                <span>{user.milesDistance !== 1000000 ? (user.milesDistance + "mi") : "-"}</span>
                                            </span>
                                        </SimpleTooltip>
                                        {/* { (user.headline) &&
                                            <SimpleTooltip
                                                placement='bottom'
                                                id={user.id + "-headline-tooltip"}
                                                text={
                                                    <div className={'headline-tooltip' + user.id}>
                                                        {user.headline}
                                                    </div>
                                                }>
                                                <span className='headline'>
                                                    <i className="fa-solid fa-file-lines"></i>
                                                </span>
                                            </SimpleTooltip>
                                        } */}
                                        <SimpleTooltip
                                            placement='bottom'
                                            id={user.id + "-rating"}
                                            text={
                                                <div className={'rating-tooltip' + user.id}>
                                                    { (user.ownRating) ?
                                                        <React.Fragment>
                                                            <div style={{ fontWeight: 700 }}>Your Rating</div>
                                                            <div>
                                                                {user.ownRating.stars} Stars
                                                            </div>
                                                            <div style={{ fontWeight: 700 }}>Public Comment</div>
                                                            <div>{user.ownRating.publicComment || "-"}</div>
                                                            <div style={{ fontWeight: 700 }}>Private Comment</div>
                                                            <div>{user.ownRating.privateComment + "-"}</div>
                                                        </React.Fragment>
                                                        :
                                                        <div>
                                                            You haven't left a rating yet
                                                        </div>
                                                    }
                                                </div>
                                            }>
                                                <span className='own-rating'>
                                                    <i className="fas fa-star"></i>
                                                    <span>{user.ownRating ? user.ownRating.stars : "-"}</span>
                                                </span>
                                        </SimpleTooltip>
                                        <SimpleTooltip
                                            placement='bottom'
                                            id={user.id + "-rating"}
                                            text={
                                                <div className={'rating-tooltip' + user.id}>
                                                    { (user.totalRatings) ?
                                                        <React.Fragment>
                                                            <div style={{ fontWeight: 700 }}>Overall Rating</div>
                                                            <div>{user.avgRating}</div>
                                                            <div style={{ fontWeight: 700 }}>Total Ratings</div>
                                                            <div>{user.totalRatings}</div>
                                                        </React.Fragment>
                                                        :
                                                        <div>
                                                            No ratings yet
                                                        </div>
                                                    }
                                                </div>
                                            }>
                                                <span className='global-rating'>
                                                    <i className="fas fa-star"></i>
                                                    <span>{user.totalRatings ? (user.avgRating + " (" + user.totalRatings + ")") : "-"}</span>
                                                </span>
                                        </SimpleTooltip>
                                        { (this.props.organisationIsAgency) ?
                                            <span></span>
                                            :
                                            (user.verifications && user.verifications.find(v => v.organisationId)) ?
                                                <SimpleTooltip
                                                    placement='bottom'
                                                    id={user.id + "-verifications"}
                                                    text={
                                                        <div className='verification-tooltip'>
                                                            {
                                                                [{
                                                                    id: VerificationTypeId.Identity,
                                                                    label: "Profile Approved By",
                                                                }, {
                                                                    id: VerificationTypeId.Representation,
                                                                    label: "Represented By"
                                                                }, {
                                                                    id: VerificationTypeId.Representation,
                                                                    type: "past",
                                                                    label: "Previously Represented By"
                                                                }, {
                                                                    id: VerificationTypeId.Vetting,
                                                                    label: "Vetted By"
                                                                }, {
                                                                    id: VerificationTypeId.Suspension,
                                                                    label: "Temporarily Suspended By"
                                                                }].map((v) => {
                                                                    // @ts-ignore
                                                                    let verifications = user.verifications ? user.verifications.filter(ve => {
                                                                        return ve.organisationId &&
                                                                        ve.typeId === v.id &&
                                                                        (v.type === "past" ? (ve.end && (moment(ve.end).add(6, "months") > moment())) : !ve.end)
                                                                    }) : []

                                                                    // if any active of this type, then cannot be past even if there are ended in the past
                                                                    if (v.type === "past" && (user.verifications || []).find(ve => ve.typeId === v.id && !ve.end)) {
                                                                        return null;
                                                                    }

                                                                    if (!verifications?.length) {
                                                                        return null;
                                                                    }

                                                                    return (
                                                                        <div className="verification-type">
                                                                            <span>{v.label}</span>
                                                                            <div>
                                                                                { verifications && [...new Set(verifications.filter(ve => ve.organisationId).map(ve => ve.organisationId))].map(ve => {
                                                                                    const orgId = ve
                                                                                    const orgName = verifications?.find(v => 
                                                                                        (orgId && v.organisationId === orgId))?.organisationName

                                                                                    return (
                                                                                        <div>
                                                                                            <LazyLoadImage
                                                                                                height={22}
                                                                                                src={CompanyApi.getOrganisationProfileImageUrl(orgId)}
                                                                                                width={22}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }) }
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    }
                                                >
                                                    <div className='verifications'>
                                                        { [...new Set(
                                                            user.verifications.filter(v =>
                                                                (v.organisationId) && 
                                                                (v.typeId === VerificationTypeId.Representation || v.typeId === VerificationTypeId.Suspension)
                                                            ).map(v => (v.organisationId || "null")))
                                                        ].map((key) => {
                                                            const orgId = key
                                                            const orgName = user.verifications?.find(v => (orgId && v.organisationId === orgId))?.organisationName

                                                            const imageUrl = orgId ? CompanyApi.getOrganisationProfileImageUrl(orgId) : null
                                                            const suspended = user.verifications?.find(v => ((orgId && v.organisationId === orgId)) && v.typeId === VerificationTypeId.Suspension)
                                                            const activeRepresentation = user.verifications?.find(v => ((orgId && v.organisationId === orgId)) && v.typeId === VerificationTypeId.Representation && !v.endedAt)
                                                            const coolDownPeriod = !suspended && !activeRepresentation && user.verifications?.find(v => ((orgId && v.organisationId === orgId)) && v.typeId === VerificationTypeId.Representation && v.endedAt && moment(v.end).add(6, "months") > moment()) // there was a representation, where end date is not more than 6 months away from today

                                                            if ((imageUrl) && (suspended || activeRepresentation || coolDownPeriod)) {
                                                                return (
                                                                    <div className='verification-status-with-org'>
                                                                        <LazyLoadImage
                                                                            height={10}
                                                                            src={imageUrl}
                                                                            width={10}
                                                                        />
                                                                        <div className='status' data-status={suspended ? 4 : activeRepresentation ? 3 : 10}>
                                                                            <i className={suspended ? "fas fa-exclamation" : activeRepresentation ? "fa fa-check" : "fas fa-hourglass" } />
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                            return null;
                                                        }) }
                                                    </div>
                                                </SimpleTooltip>
                                            : null
                                        }
                                    </div>
                                    <div className='row headline'>
                                        <SimpleTooltip
                                            placement='bottom'
                                            id={user.id + "-headline-tooltip"}
                                            text={
                                                <div className={'headline-tooltip' + user.id}>
                                                    {user.headline || "No Headline"}
                                                </div>
                                            }>
                                            <span className='headline'>
                                                {user.headline || "No Headline"}
                                            </span>
                                        </SimpleTooltip>
                                    </div>
                                </div>
                                { (this.props.view !== "collapsed") &&
                                    <div className='availability'>
                                        { (dayHashRange.map((dayHash) => {
                                            const weekend = new Date(dayHash).getDay() === 0 || new Date(dayHash).getDay() === 6;

                                            if (!user.compiledAvailability[dayHash]) {
                                                return (
                                                    <div className='day' data-weekend={weekend}></div>
                                                )
                                            }

                                            return (
                                                <div className='day' data-weekend={weekend}>
                                                    { user.compiledAvailability[dayHash].map((slot) => {
                                                        return (
                                                            <div 
                                                                className='slot'
                                                                style={{
                                                                    top: slot.top,
                                                                    height: slot.height
                                                                }}
                                                            >
                                                                { (slot.height > 18) &&
                                                                    <i className="fas fa-check"></i>
                                                                }
                                                            </div>
                                                        )
                                                    }) }
                                                </div>
                                            )
                                        })) }
                                    </div>
                                }
                            </div>
                        </div>
                    )
                }) }
                { (
                    this.props.searchParams.globalSearch || 
                    this.props.searchParams.attributeIds.length > 0 || 
                    this.props.searchParams.name || 
                    this.props.searchParams.representingAgencies.length > 0 ||
                    this.props.searchParams.sortType !== "last-updated"
                ) &&
                    <div className='clear-filters'>
                        <button onClick={() => {
                            this.props.clearFilters();
                        }}>Clear Filters</button>
                    </div>

                }
            </div>
        );
    }
}

export default PeopleGrid;