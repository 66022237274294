import React from 'react';

function SvgFile(props) {
    return (
        <svg height="1em" viewBox="-48 0 512 512" width="1em" {...props}>
            <path
                d="M408 120h-80c-17.672 0-32-14.328-32-32V8zm0 0"
                fill="#e93d5b"
            />
            <path
                d="M408 120v352c0 17.672-14.328 32-32 32H40c-17.672 0-32-14.328-32-32V40C8 22.328 22.328 8 40 8h256v80c0 17.672 14.328 32 32 32zm0 0"
                fill="#fa5c66"
            />
            <g fill="#231f20">
                <path d="M413.656 114.344l-112-112A8.001 8.001 0 00296 0H40C17.918.027.027 17.918 0 40v432c.027 22.082 17.918 39.973 40 40h336c22.082-.027 39.973-17.918 40-40V120a8.001 8.001 0 00-2.344-5.656zM400 472c0 13.254-10.746 24-24 24H40c-13.254 0-24-10.746-24-24V40c0-13.254 10.746-24 24-24h252.688L400 123.313zm0 0" />
                <path d="M154.344 277.656a8 8 0 0011.312-11.312L131.312 232l34.344-34.344a8 8 0 00-11.312-11.312L120 220.688l-34.344-34.344a8 8 0 00-11.312 11.312L108.688 232l-34.344 34.344a8 8 0 1011.312 11.312L120 243.312zm0 0M341.656 186.344a8.004 8.004 0 00-11.312 0L296 220.688l-34.344-34.344a8 8 0 00-11.312 11.312L284.688 232l-34.344 34.344a8 8 0 1011.312 11.312L296 243.312l34.344 34.344a8 8 0 0011.312-11.312L307.312 232l34.344-34.344a8.004 8.004 0 000-11.312zm0 0M208 312c-61.828.066-111.934 50.172-112 112a8 8 0 0016 0c0-53.02 42.98-96 96-96s96 42.98 96 96a8 8 0 0016 0c-.066-61.828-50.172-111.934-112-112zm0 0M288 48v40c.027 22.082 17.918 39.973 40 40h40a8 8 0 000-16h-40c-13.254 0-24-10.746-24-24V48a8 8 0 00-16 0zm0 0" />
            </g>
        </svg>
    );
}

export default SvgFile;
