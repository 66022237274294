/* eslint-disable */
import React from 'react';
import { toast } from 'react-toastify';
import { Button } from 'react-bootstrap';

import Feedback from './../api/support/Feedback';
import Analytics from '../services/analytics.service';
import InternalTracker from '../InternalTracker';
import theme from '../css/theme';

interface SupportState {
    comments: string;
    promptSend: boolean;
}

export default class Settings extends React.Component<{}, SupportState> {
    constructor(props) {
        super(props);
        this.state = {
            comments: '',
            promptSend: false
        };

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(event: any) {
        event.preventDefault();
        const target = event.target;
        const value =
            target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        const upState = this.state;
        upState[name] = value;
        this.setState(upState);
    }

    sendFeedback() {
        Feedback.sendFeedback(this.state.comments);

        toast.success(
            <div>
                Thank you for submitting a support request
                <br />
                We will get back to you shortly
            </div>
        );

        this.setState({ comments: '' });
        InternalTracker.trackEvent("Support Message Sent");
        Analytics.trackEvent('feedback-submitted');
    }

    componentDidMount() {
        let supportPrefillTextId = window.location.href.split("/support/")[1];
        if (supportPrefillTextId === 'organisation-groups') {
            this.setState({
                comments: 'Hi, I would like to enquiry about Organisation-wide groups.',
                promptSend: true
            })
        } else if (supportPrefillTextId === 'sms-credit') {
            this.setState({
                comments: 'Hi, I would like to buy more SMS credits',
                promptSend: true
            })
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="hpanel">
                            <div className="panel-body">
                                <div className="row">
                                    <h3
                                        style={{
                                            fontWeight: 700,
                                            margin: '0 0 20px 0',
                                            fontSize: '1.6em'
                                        }}
                                    >Contact Support - Select the subject and describe in detail</h3>
                                    <div 
                                        className="suggestions"
                                    >
                                        { [
                                            {
                                                label: "Issue / Bug",
                                                text: "Something is not quite right with "
                                            },
                                            {
                                                label: "Feature Request",
                                                text: "Is it possible to have the option to "
                                            },
                                            {
                                                label: "Demo Request",
                                                text: "Can we schedule a call to go over the features?"
                                            },
                                            {
                                                label: "Incorrect Data",
                                                text: "Some data displayed does not seem completely accurate"
                                            },
                                        ].map(item => {
                                            return (
                                                <span 
                                                    onClick={() => {
                                                        this.setState({
                                                            comments: item.text
                                                        })
                                                    }}
                                                    key={item.label}
                                                >
                                                    {item.label}
                                                </span>
                                            )
                                        }) }
                                    </div>
                                    <textarea
                                        className="form-control"
                                        name="comments"
                                        rows={8}
                                        onChange={this.handleInputChange}
                                        value={this.state.comments}
                                        placeholder="Describe in detail"
                                        style={{
                                            resize: 'none'
                                        }}
                                    />
                                    <Button
                                        style={{
                                            background: theme.colours.blue2,
                                            display: 'block',
                                            width: '100%',
                                            color: 'white',
                                            borderRadius: 6,
                                            marginTop: 12,
                                            padding: 10
                                        }}
                                        onClick={() => this.sendFeedback()}
                                        className={this.state.promptSend ? "pulse no-size" : ""}
                                    >
                                        Send
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
