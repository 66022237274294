/* eslint-disable */
const UtilsAPI = {
    getFileFromUrl: async (url: string, name: string): Promise<File | void> => {
        return fetch(url)
            .then((res) => res.blob())
            .then((blob: Blob) => {
                let b: any = blob;
                b.lastModifiedDate = new Date();
                b.name = name;
                return b as File;
            })
            .then((f: File) => {
                return f;
            });
    },
    /**
     * Formats a date to a given string format
     * @param dateObj date to format
     * @param format format of the date
     * @returns formatted date
     */
    formatDate: (dateObj: Date, format: string): string => {

        var str = new Date();
        const now: Date = new Date();

        if (dateObj !== undefined) {
            str = new Date(dateObj);
        }
        var dateStr = "";

        var months;
        // var week;
        var days;
        var years;
        var hours;
        var minutes;
        var seconds;
        var milliseconds;

        const DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const DAYS_SHORT = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        const MONTHS = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];
        const MONTHS_SHORT = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ];

        switch (format) {

            case "mms YYYY":
                dateStr = MONTHS_SHORT[str.getMonth()] + " " + str.getFullYear();
                break;

            case "YYYY-MM-DD":
                months = (str.getMonth()+1);
                days = (str.getDate());
                years = str.getFullYear();
                if (months < 10) months = "0" + months
                if (days < 10) days = "0" + days
                dateStr = years + "-" + months + "-" + days;
                break;

            case "YYYY-MM":
                months = (str.getMonth()+1);
                days = (str.getDate());
                years = str.getFullYear();
                if (months < 10) months = "0" + months
                dateStr = years + "-" + months;
                break;

            case "D":
                days = (str.getDate());
                dateStr = days;
                break;

            case "d":
                dateStr = DAYS[str.getDay()];
                break;

            case "ds":
                dateStr = DAYS_SHORT[str.getDay()];
                break;

            case "YYYY mm":
                dateStr = str.getFullYear() + " " + MONTHS[str.getMonth()];
                break;

            case "d mms, YYYY":
                dateStr = str.getDate() + " " + MONTHS_SHORT[str.getMonth()] + ", " + str.getFullYear();
                break; 

            case "YYYY mms d":
                dateStr = str.getFullYear() + " " + MONTHS_SHORT[str.getMonth()] + " " + str.getDate();
                break; 

            case "d mms YYYY":
                dateStr = str.getDate() + " " + MONTHS_SHORT[str.getMonth()] + " " + str.getFullYear();
                break; 

            case "HH:MM d mms (YYYY)":
                hours = str.getHours()
                minutes = str.getMinutes()
                if (hours < 10) hours = "0" + hours;
                if (minutes < 10) minutes = "0" + minutes
                dateStr = hours + ":" + minutes;
                if (now.getFullYear() === str.getFullYear()) {
                    dateStr += " " + str.getDate() + " " + MONTHS_SHORT[str.getMonth()];
                } else {
                    dateStr += " " + str.getDate() + " " + MONTHS_SHORT[str.getMonth()] + " " + str.getFullYear();
                }
                break; 

            case "d mms (YYYY)":
                if (now.getFullYear() === str.getFullYear()) {
                    dateStr = str.getDate() + " " + MONTHS_SHORT[str.getMonth()];
                } else {
                    dateStr = str.getDate() + " " + MONTHS_SHORT[str.getMonth()] + " " + str.getFullYear();
                }
                break; 

            case "mm d, YYYY":
                dateStr = MONTHS[str.getMonth()] + " " + str.getDate() + ", " + str.getFullYear();
                break; 

            case "mm YYYY":
                dateStr = MONTHS[str.getMonth()] + " " + str.getFullYear();
                break;

            case "HH:MM":
                hours = str.getHours()
                minutes = str.getMinutes()
                if (hours < 10) hours = "0" + hours;
                if (minutes < 10) minutes = "0" + minutes
                dateStr = hours + ":" + minutes;
                break;

            case "YYYY-MM-DDTHH:MM:SS":
              //2020-11-16T15:00:00
              months = (str.getMonth()+1);
              if (months < 10) months = "0" + months
              years = str.getFullYear();
              if (years < 10) years = "0" + years
              days = (str.getDate());
              if (days < 10) days = "0" + days
              hours = str.getHours()
              if (hours < 10) hours = "0" + hours
              minutes = str.getMinutes()
              if (minutes < 10) minutes = "0" + minutes
              seconds = str.getSeconds()
              if (seconds < 10) seconds = "0" + seconds
              dateStr = years + "-" + months + "-" + days + "T" + hours + ":" + minutes + ":" + seconds;
              break;

            case "YYYY-MM-DDTHH:mm":
                months = (str.getMonth()+1);
                if (months < 10) months = "0" + months
                years = str.getFullYear();
                if (years < 10) years = "0" + years
                days = (str.getDate());
                if (days < 10) days = "0" + days
                hours = str.getHours()
                if (hours < 10) hours = "0" + hours
                minutes = str.getMinutes()
                if (minutes < 10) minutes = "0" + minutes
                dateStr = years + "-" + months + "-" + days + "T" + hours + ":" + minutes;
                break;

            default:
                months = (str.getMonth()+1);
                if (months < 10) months = "0" + months
                years = str.getFullYear();
                if (years < 10) years = "0" + years
                days = (str.getDate());
                if (days < 10) days = "0" + days
                hours = str.getHours()
                if (hours < 10) hours = "0" + hours
                minutes = str.getMinutes()
                if (minutes < 10) minutes = "0" + minutes
                seconds = str.getSeconds()
                if (seconds < 10) seconds = "0" + seconds
                milliseconds = str.getMilliseconds();
                if (milliseconds < 10) milliseconds = "00" + milliseconds
                else if (milliseconds < 100) milliseconds = "0" + milliseconds
                dateStr = years + "-" + months + "-" + days + " " + hours + ":" + minutes + ":" + seconds + "." + milliseconds
                break;

        }

        return dateStr;

    }
};

export default UtilsAPI;
