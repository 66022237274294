/* eslint-disable */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as React from 'react';
import styled from 'styled-components';

import Splash from '../../components/ui-components/Splash';
import theme from '../../css/theme';
import AppConfig from '../../components/config/Config';
import AdminAPI from '../../api/admin/Admin';
import { CompiledUser, Offer } from '../../api/admin/ResponseTypes';
import DataTable, { createTheme } from 'react-data-table-component';
import Utilities from '../../Utilities';
import { Modal } from 'react-bootstrap';

import { Sector, SubSector } from '../../components/organisation/OrganisationAutoCompleteSuggestions';
var stringSimilarity = require("string-similarity");

interface State {

}

const LINKS = [
    { name: "[Old] Verify Users", link: "/internal/verifyusers" },
    { name: "Reports", link: "/internal/reports" },
    { name: "Moderation", link: "/internal/moderation" },
    { name: "External Org Logos", link: "/internal/externalorglogos" },
    { name: "Logos", link: "/internal/logos" },
    { name: "Users & Impersonation", link: "/internal/users" },
    { name: "Sectors & Skills", link: "/internal/sectors" },
    { name: "Agency Suggestions", link: "/internal/agency-suggestions" },
    { name: "Ratings", link: "/internal/ratings" },
    { name: "Files", link: "/internal/files" },
    { name: "Sign up on behalf of", link: window.location.host === "portal.updatedge.com" ?
        "https://upedge.b2clogin.com/upedge.onmicrosoft.com/B2C_1A_ONBEHALFOF/oauth2/v2.0/authorize?&client_id=c6899b80-ead4-4080-b65b-8d3c0dac1e87&response_type=id_token%20token&redirect_uri=https%3A%2F%2Fportal.updatedge.com%2Fredirect&state=%7B%22client_id%22%3A%22c6899b80-ead4-4080-b65b-8d3c0dac1e87%22%2C%22network%22%3A%22b2cSignInAndUpPolicy%22%2C%22display%22%3A%22page%22%2C%22callback%22%3A%22_hellojs_68aof0j6%22%2C%22state%22%3A%22%22%2C%22redirect_uri%22%3A%22https%3A%2F%2Fportal.updatedge.com%2Fredirect%22%2C%22scope%22%3A%22openid%2Chttps%3A%2F%2Fupedge.onmicrosoft.com%2Fmobapi%2Freadwrite%22%2C%22page_uri%22%3A%22https%3A%2F%2Fportal.updatedge.com%2F%22%7D&scope=openid%20https://upedge.onmicrosoft.com/mobapi/readwrite" :
        "https://upedgedev.b2clogin.com/upedgedev.onmicrosoft.com/B2C_1A_ONBEHALFOF/oauth2/v2.0/authorize?&client_id=0e1749d8-8b6d-4a6f-ac86-9ba4001c5007&response_type=id_token%20token&redirect_uri=https%3A%2F%2Ftest-portal.updatedge.com%2Fredirect&state=%7B%22client_id%22%3A%220e1749d8-8b6d-4a6f-ac86-9ba4001c5007%22%2C%22network%22%3A%22b2cSignInAndUpPolicy%22%2C%22display%22%3A%22page%22%2C%22callback%22%3A%22_hellojs_ro39dzx%22%2C%22state%22%3A%22%22%2C%22redirect_uri%22%3A%22https%3A%2F%2Ftest-portal.updatedge.com%2Fredirect%22%2C%22scope%22%3A%22openid%2Chttps%3A%2F%2Fupedgedev.onmicrosoft.com%2Fmobapi%2Fuser_impersonation%22%2C%22page_uri%22%3A%22https%3A%2F%2Ftest-portal.updatedge.com%2F%22%7D&scope=openid%20https://upedgedev.onmicrosoft.com/mobapi/user_impersonation"
    }
]

class Sectors extends React.Component<{}, State> {

    constructor(props) {
        super(props);

        this.state = {
          
        };
    }

    render() {

        return (
            <div>
                <section>
                    { LINKS.map(link => {
                        return (
                            <h2
                                style={{
                                    marginBottom: 20
                                }}
                            >
                                <a href={link.link} target={"_blank"}>{link.name}</a>
                            </h2>
                        )
                    }) }
                </section>
            </div>
        );
    }

}

export default Sectors;