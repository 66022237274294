import React from 'react';
import styled from 'styled-components';
import { ProgressBar } from 'react-bootstrap';

import theme from '../../css/theme';
import ProfileApi from '../../api/profile/Profile';
import { UserReputation } from '../../api/profile/RequestTypes';
import Splash from '../../components/ui-components/Splash';
import Analytics from '../../services/analytics.service';

interface State {
    reputation: UserReputation | null;
}

export default class Reputation extends React.Component<{}, State> {
    state = {
        reputation: null
    } as State;

    async componentDidMount() {
        const reputation = await ProfileApi.getReputation();

        this.setState({ reputation });

        Analytics.trackEvent('mobile-reputation-loaded');
    }

    render() {
        if (!this.state.reputation) {
            return <Splash />;
        }

        const rep = this.state.reputation;
        const percentBelowContactAverage =
            (10 /* Bluff that 10 is average */ - rep.contacts) * 10;

        return (
            <Wrapper>
                <h3>Build Your Network</h3>
                <div className="layout horizontal center-justified m-t m-b">
                    <ProgressBar
                        now={this.reputationPercentage}
                        bsStyle="success"
                    />
                </div>
                {this.renderRow(
                    'Verified profile',
                    'Identity verified through a trusted third-party',
                    !rep.verified
                )}
                {this.renderRow(
                    'Profile photo',
                    "Add your photo so your contacts can see it's you",
                    !rep.hasPhoto
                )}
                {this.renderRow(
                    `${rep.contacts} contact${rep.contacts !== 1 ? 's' : ''}`,
                    <div>
                        {percentBelowContactAverage > 0 && (
                            <>
                                Currently {percentBelowContactAverage}% below
                                the average
                                <br />
                            </>
                        )}
                        Share your availability with more contacts
                    </div>,
                    rep.contacts === 0,
                    rep.contacts < 10
                )}
                {this.renderRow(
                    `${rep.shares} share${
                        rep.shares !== 1 ? 's' : ''
                    } this week`,
                    'Keep your contacts up-to-date with your availability',
                    rep.shares === 0,
                    rep.shares < 3
                )}
                {this.renderRow(
                    `${rep.offers} offer${
                        rep.offers !== 1 ? 's' : ''
                    } this week`,
                    'Ask contacts to send you offers directly via updatedge',
                    rep.offers === 0,
                    rep.offers < 5
                )}
            </Wrapper>
        );
    }

    renderRow = (
        title: string,
        body: string | JSX.Element,
        fail: boolean,
        warn?: boolean
    ) => {
        return (
            <div
                className={`rep-row ${
                    !fail && !warn ? 'rep-row-complete' : ''
                } layout vertical center-center`}
            >
                <div className="layout horizontal center-center">
                    {this.renderIcon(fail, warn)}
                    <h4>{title}</h4>
                </div>
                <div>{body}</div>
            </div>
        );
    };

    renderIcon = (fail: boolean, warn?: boolean) => {
        const icon = fail
            ? 'times'
            : warn
            ? 'exclamation-triangle'
            : 'check-circle';

        return <i className={`fa fa-${icon} m-r-sm`} />;
    };

    get reputationPercentage() {
        const rep = this.state.reputation!;

        let perc = 0;

        if (rep.hasPhoto) perc += 30;
        if (rep.verified) perc += 30;
        if (rep.contacts > 2) perc += 10;
        if (rep.contacts > 10) perc += 10;
        if (rep.shares > 2) perc += 10;
        if (rep.offers > 0) perc += 10;

        return perc;
    }
}

const Wrapper = styled.div`
    div.progress {
        width: 100%;
        max-width: 800px;
        margin: 15px 20px 15px 20px;
        background-color: lightgrey;

        .progress-bar {
            color: white;
            background: ${theme.colours.green};
            border-right: 4px solid ${theme.colours.green};
        }
    }

    h3 {
        margin-top: 25px;
        font-weight: bold;
        text-align: center;
    }

    h4 {
        width: 165px;
        margin: 0 0 0 5px;
        text-align: center;
    }

    .rep-row {
        margin-top: 30px;

        &-complete {
            div:last-child {
                text-decoration: line-through;
                opacity: 0.7;
            }
        }

        > div:first-child {
            padding-right: 10px;
        }

        > div:last-child {
            margin-top: 3px;
            font-size: 12px;
            font-style: italic;
            text-align: center;
        }
    }

    .fa {
        font-size: 25px;

        &.fa-check-circle {
            color: ${theme.colours.green};
        }

        &.fa-exclamation-triangle {
            color: orange;
        }

        &.fa-times {
            color: ${theme.colours.red};
        }
    }
`;
