// TODONOW
/* eslint-disable */
import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { debounce } from 'ts-debounce';
import {
    APIImplementersDTO,
    OrganisationDTO
} from '../../api/organisation/ResponseTypes';
import styled from 'styled-components';

import OrganisationAPI from '../../api/organisation/Organisation';
import CompanyAPI from '../../api/company/Company';
import { ProfileImage } from '../ui-components/ProfileImage';
import Analytics from '../../services/analytics.service';
import InternalTracker from '../../InternalTracker';

interface SearchOrganisationModalProps {
    show: boolean;
    multi?: boolean;
    onShowChanged: (show: boolean) => void;
    onSelected: (ids: string[]) => void;
}

interface SearchOrganisationModalState {
    results: APIImplementersDTO[];
    selectedIds: string[];
    searching: boolean;
}

export class SearchOrganisationModal extends React.Component<
    SearchOrganisationModalProps,
    SearchOrganisationModalState
> {
    constructor(props: SearchOrganisationModalProps) {
        super(props);

        this.state = {
            results: [],
            selectedIds: [],
            searching: false
        };
    }

    private handleOpen = () => {
        this.setState({
            results: [],
            selectedIds: []
        });

        this.search();

        Analytics.trackEvent('invite-search-by-organisation-opened');
    };

    private handleSearchChange = debounce(this.search, 700);

    private search(search?: string) {
        this.setState({
            searching: true
        });

        OrganisationAPI.getAPIImplementersUserLookup().then((organisations) => {
            this.setState({
                results: organisations || [],
                searching: false
            });
        });

        // OrganisationAPI.getAll(search || '', 'agency').then((organisations) => {
        //     this.setState({
        //         results: organisations?.data || [],
        //         searching: false
        //     });
        // });

        if (search) {
            Analytics.trackEvent('invite-search-by-organisation-searched', {
                searchValue: search
            });
        }
    }

    private handleClose = () => {
        this.props.onShowChanged(false);

        Analytics.trackEvent('invite-search-by-organisation-closed');
    };

    private continue = () => {
        this.props.onSelected(this.state.selectedIds);
        this.handleClose();

        Analytics.trackEvent('invite-search-by-organisation-continue');
    };

    private handleRowClick(id: string) {
        let ids: string[];

        if (this.isSelected(id)) {
            ids = this.state.selectedIds.filter((s) => s !== id);
        } else if (this.props.multi) {
            ids = this.state.selectedIds.concat(id);
        } else {
            ids = [id];
        }

        this.setState({
            selectedIds: ids
        });

        InternalTracker.trackEvent("", {
            category: 'Invite',
            action: 'Organisation Clicked'
        });
    }

    private isSelected(id: string) {
        return this.state.selectedIds.indexOf(id) > -1;
    }

    render() {
        return (
            <div>
                <Modal
                    show={this.props.show}
                    onHide={this.handleClose}
                    onEnter={this.handleOpen}
                    dialogClassName="modal-dialog-compact"
                >
                    <Modal.Header>
                        <Modal.Title>Search organizations</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h5 className="m-b">
                            Please select the organization(s) to search for
                            contacts (Please note: we are unable to look for
                            contacts if your email address was created on a
                            public email service such as Outlook, Hotmail,
                            Gmail, etc.).
                        </h5>
                        {/* <SearchInput
                            type="text"
                            className="form-control"
                            placeholder="Filter..."
                            onChange={(e) =>
                                this.handleSearchChange(e.target.value)
                            }
                        /> */}
                        <ListContainer>{this.renderList()}</ListContainer>
                    </Modal.Body>
                    <Modal.Footer>
                        <CannotFindMessage>
                            <span>
                                Is the organization you're looking for not
                                listed?
                            </span>
                            <br />
                            <a href="/support" target="_blank">
                                Click here and let us know!
                            </a>
                        </CannotFindMessage>
                        <Button
                            onClick={this.continue}
                            disabled={!this.state.selectedIds.length}
                            bsStyle="primary"
                        >
                            Continue
                        </Button>
                        <Button onClick={this.handleClose}>Cancel</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }

    renderList() {
        if (this.state.searching) {
            return (
                <OverlayDiv>
                    <i className="fa fa-spinner fa-spin" />
                </OverlayDiv>
            );
        } else if (this.state.results.length) {
            return this.state.results.map((result) => this.renderRow(result));
        } else {
            return <span>No organizations found</span>;
        }
    }

    renderRow(result: APIImplementersDTO) {
        const companyImage = CompanyAPI.getOrganisationProfileImageUrl(
            result.organisationId
        );

        return (
            <ResultRow
                key={result.organisationId}
                onClick={() => this.handleRowClick(result.organisationId)}
                className={
                    'layout horizontal center ' +
                    (this.isSelected(result.organisationId) ? 'selected' : '')
                }
            >
                <ProfileImage
                    url={companyImage}
                    size={48}
                    type="Organisation"
                    selectable={false}
                />
                <span>{result.organisationName}</span>
            </ResultRow>
        );
    }
}

const ListContainer = styled.div`
    max-height: calc(100vh - 330px);
    padding: 10px 10px 0 10px;
    overflow: auto;
`;

const ResultRow = styled.div`
    margin: 5px;
    cursor: pointer;

    &:hover {
        background-color: rgba(0, 0, 0, 0.075);
    }

    &.selected {
        background-color: #b8daff;
    }

    > span {
        margin-left: 10px;
        font-size: 15px;
    }
`;

const SearchInput = styled.input`
    background: transparent;

    &:focus {
        background: white;
    }
`;

const OverlayDiv = styled.div`
    padding-top: 20px;
    font-size: 28px;
    text-align: center;
`;

const CannotFindMessage = styled.div`
    float: left;
    text-align: left;
`;
