/* eslint-disable */
import React from 'react';
import { Button, Table } from 'react-bootstrap';

import Device, { Resolutions } from '../../css/device';
import { FavouriteOfferDTO } from '../../api/offers/ResponseTypes';
import OfferCard from './OfferCard';
import { Offers } from '../../pages/Offers';
import LocationMap from '../ui-components/LocationMap';

interface Props {
    offer: FavouriteOfferDTO;
    handleViewCancel: Offers['handleViewCancel'];
    handleDeleteFavouriteOffer: Offers['handleDeleteFavouriteOffer'];
    handleConvertFavouriteToOffer: Offers['handleConvertFavouriteToOffer'];
}

const ViewFavouriteOffer = ({
    offer,
    handleViewCancel,
    handleDeleteFavouriteOffer,
    handleConvertFavouriteToOffer
}: Props) => {
    const renderWorkers = () => {
        return (
            <>
                <div
                    style={{
                        maxHeight: `calc(100vh - ${
                            Device.isSmallerThan(Resolutions.MobileMedium)
                                ? '410'
                                : '570'
                        }px)`,
                        overflowY: 'auto',
                        margin: '20px 0 0'
                    }}
                >
                    {(() => {
                        return (
                            <Table condensed>
                                <tbody>
                                    {offer.recipients.map((x, i) => (
                                        <tr key={i}>
                                            <td
                                                style={{
                                                    textAlign: 'center'
                                                }}
                                            >
                                                {x.recipientName}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        );
                    })()}
                </div>
            </>
        );
    };

    return (
        <OfferCard className="masked layout vertical favourite">
            <i className="fas fa-times fa-hover" onClick={handleViewCancel} />
            <h4>{offer.title}</h4>
            <div className="first">
                <div className="layout vertical">
                    {offer.description && (
                        <>
                            <span
                                style={{
                                    marginTop: '15px',
                                    textAlign: 'center'
                                }}
                            >
                                {offer.description}
                            </span>
                        </>
                    )}
                    <div style={{ marginTop: '20px' }}>
                        <span 
                            className="vacancy-period"
                            style={{
                                textAlign: "center"
                            }}
                        >
                            <span>{offer.startTime}</span>
                            {':'}
                            <span>{offer.endTime}</span>
                        </span>
                    </div>

                    {renderWorkers()}

                    {offer.locationPlaceId && offer.locationPlaceId.length && (
                        <div
                            className="layout vertical"
                            style={{
                                height: Device.isSmallerThan(
                                    Resolutions.MobileMedium
                                )
                                    ? '120px'
                                    : '200px',
                                overflow: 'hidden'
                            }}
                        >
                            <LocationMap
                                placeId={offer.locationPlaceId}
                                friendlyName={offer.locationFriendlyName}
                                placeName={offer.locationPlaceName}
                            />
                        </div>
                    )}
                </div>
            </div>

            <div className="options options-two">
                <Button
                    bsStyle="danger"
                    className="m-r-xs"
                    onClick={() => {
                        handleDeleteFavouriteOffer();
                    }}
                >
                    <i className="fas fa-ban"></i>
                    Delete Favourite
                </Button>
                <Button
                    bsStyle="success"
                    onClick={handleConvertFavouriteToOffer}
                >
                    <i className="fas fa-briefcase-medical"></i>
                    Convert to Offer
                </Button>
            </div>
        </OfferCard>
    );
};

export default ViewFavouriteOffer;
