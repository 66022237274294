/* eslint-disable */
import React from 'react';
import styled, { css } from 'styled-components';
import { Table, Button, Modal } from 'react-bootstrap';
import { SetOrganisationTeamMembersRequest } from '../../api/organisation/RequestTypes';

import { Subscribe } from '../../unstated-fork/unstated';
import { CompanyRoles } from '../../api/company/ResponseTypes';
import TeamsContainer from '../../state-containers/teams.container';
import theme from '../../css/theme';
import SvgBox from '../svg/box';
import Hyperlink from '../ui-components/Hyperlink';
import { ProfileImage } from '../ui-components/ProfileImage';
import ProfileApi from '../../api/profile/Profile';
import Initial from '../ui-components/Initial';
import TeamsSubscription from './TeamsSubscription';
import { TeamsMarketing } from './Marketing';
import SimpleTooltip from '../ui-components/SimpleTooltip';
import { UserDto } from '../../api/user/ResponseTypes';
import { OrganisationTeamDTO } from '../../api/organisation/ResponseTypes';
import { Organisation } from '../../pages/Organisation';
import { toast } from 'react-toastify';
import InternalTracker from 'src/InternalTracker';

interface Props {
    setTab: Organisation['setTab'];
    organisationMembers: UserDto[];
    self: UserDto;
    container: TeamsContainer;
}

interface State {
    showSelector: boolean;
    tempMemberContactIds: UserDto['contactId'][];
    showNewTeam: boolean;
    selectedTeamId: OrganisationTeamDTO['id'] | null;
}

class Teams extends React.Component<Props, State> {
    state = {
        showSelector: false,
        tempMemberContactIds: [],
        showNewTeam: false,
        selectedTeamId: null
    } as State;

    componentDidUpdate(_, prevState: State) {
        if (this.state.selectedTeamId === prevState.selectedTeamId) return;
        const { teamMembers } = this.props.container.state.teams.find(
            ({ id }) => this.state.selectedTeamId === id
        )!;

        const tempMemberContactIds = teamMembers.map(
            ({ contactId }) => contactId
        );

        this.setState({
            tempMemberContactIds
        });
    }

    render() {
        const container = this.props.container;
        const selectedTeam =
            container.state.teams.find(
                ({ id }) => id === this.state.selectedTeamId
            ) || null;

        return (
            <>
                <TeamsMarketing />
                <hr />
                <Content>
                    <h3>Your Teams</h3>
                    <div
                        className="layout horizontal center-justified flex"
                        style={{ flexFlow: 'row wrap' }}
                    >
                        <div style={{ width: '100%' }}>
                            <div style={{ textAlign: 'right' }}>
                                <Button
                                    bsStyle="success"
                                    bsSize="sm"
                                    style={{ padding: '2px 6px' }}
                                    onClick={() =>
                                        this.props.setTab('subscriptions')
                                    }
                                >
                                    <i className="fa fa-plus m-r-xs" />
                                    Add New Team
                                </Button>
                            </div>
                            <Table striped hover className="teams-table">
                                <thead>
                                    <tr>
                                        <th>Teams</th>
                                        <th
                                            style={{
                                                textAlign: 'center'
                                            }}
                                        >
                                            Members
                                        </th>
                                        <th
                                            style={{
                                                textAlign: 'center',
                                                whiteSpace: 'nowrap'
                                            }}
                                        >
                                            Team Leader
                                        </th>
                                        <th />
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.renderTeams(container.state.teams)}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </Content>
                {this.renderMembersModal(selectedTeam, container)}
                {this.renderNewTeam(container)}
            </>
        );
    }

    renderTeams(teams: OrganisationTeamDTO[]) {
        return teams
            .filter(
                (t) =>
                    this.self!.roleId == CompanyRoles.Admin ||
                    t.userId == this.self!.id
            )
            .map((t) => (
                <tr
                    key={`team-${t.id}`}
                    data-testid={`team-${t.id}`}
                    className={`${
                        t.id == this.state.selectedTeamId ? 'selected' : ''
                    }`}
                    onClick={() => {
                        this.setState({ selectedTeamId: t.id }, () => {
                            this.handleSelectClick(t);
                        });
                    }}
                >
                    <td>
                        <span className="mobile">Name: </span>
                        {t.name}
                    </td>
                    <td
                        style={{ textAlign: 'center' }}
                    >
                        <span className="mobile">Members: </span>
                        {`${t.teamMembers.length}/${t.size}`}
                    </td>
                    {this.props.organisationMembers.find((m) => m.id == t?.userId) ?
                        <td style={{ textAlign: 'center' }}>
                            <span className="mobile">Leader: </span>
                            {
                                this.props.organisationMembers.find(
                                    (m) => m.id == t?.userId
                                )!.firstName
                            }{' '}
                            {
                                this.props.organisationMembers.find(
                                    (m) => m.id == t?.userId
                                )!.lastName
                            }
                        </td>
                        :
                        <td>
                            <span className="mobile">Leader: </span>
                            -
                        </td>
                    }
                    <td>
                        {this.self!.roleId == CompanyRoles.Admin && (
                            <React.Fragment>
                                <button>Manage Colleague</button>
                                <button
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        this.handleConfigure();
                                    }}
                                >
                                    Manage Subscription
                                </button>
                            </React.Fragment>
                        )}
                    </td>
                </tr>
            ));
    }

    renderMembers(contactIds: string[]) {
        return (
            <Table>
                <tbody>
                    {this.props.organisationMembers
                        .filter((m) => ~contactIds.indexOf(m.contactId))
                        .map((m) => (
                            <tr
                                className="member-row"
                                data-testid={`teammember-${m.contactId}`}
                                key={`teammember-${m.contactId}`}
                            >
                                <td>{this.renderMemberImage(m)}</td>
                                <td>
                                    {m.firstName} {m.lastName}
                                </td>
                            </tr>
                        ))}
                </tbody>
            </Table>
        );
    }

    renderMembersModal(
        selectedTeam: OrganisationTeamDTO | null,
        container: TeamsContainer
    ) {
        if (selectedTeam == null || !this.state.showSelector) return null;
        const team = selectedTeam;
        const isFull =
            this.state.tempMemberContactIds.length == selectedTeam.size;

        return (
            <Modal
                show={this.state.showSelector}
                onHide={this.handleModalHide}
                bsSize="small"
                dialogClassName="modal-dialog-centered"
            >
                <Modal.Body>
                    <SelectorContent full={isFull}>
                        {isFull && (
                            <div className="full">
                                This team is full -{' '}
                                <Hyperlink
                                    as="button"
                                    onClick={() => {
                                        this.handleModalHide();
                                        this.handleConfigure();
                                    }}
                                >
                                    increase size?
                                </Hyperlink>
                            </div>
                        )}
                        <h4>Manage Members</h4>
                        <h5>{`${this.state.tempMemberContactIds.length} / ${team.size} members. Select colleagues to add in ${team.name} include, or deselect to remove them`}</h5>
                        <div>
                            <Table striped hover>
                                <tbody>
                                    {this.props.organisationMembers
                                        .filter((m) => !m.deleted)
                                        .map((m) => (
                                            <tr
                                                data-testid={`selector-${m.contactId}`}
                                                key={`selector-${m.contactId}`}
                                                className={
                                                    ~this.state.tempMemberContactIds.indexOf(
                                                        m.contactId
                                                    )
                                                        ? 'selected'
                                                        : ''
                                                }
                                                onClick={() =>
                                                    this.handleMemberToggle(
                                                        m.contactId,
                                                        isFull
                                                    )
                                                }
                                            >
                                                <td>
                                                    {this.renderMemberImage(m)}
                                                </td>
                                                <td
                                                    style={{
                                                        textAlign: 'left'
                                                    }}
                                                >
                                                    {m.id
                                                        ? m.firstName +
                                                          ' ' +
                                                          m.lastName
                                                        : m.email}
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>
                            </Table>
                        </div>
                        <div style={{ margin: '10px 0', fontStyle: 'italic' }}>
                            Team member not listed?
                            <br />
                            Invite them from the "Members" tab.
                        </div>
                        <Button
                            bsStyle="success"
                            onClick={() => this.handleConfirm(container)}
                        >
                            Confirm
                        </Button>
                    </SelectorContent>
                </Modal.Body>
            </Modal>
        );
    }

    renderMemberImage = (member: UserDto) => {
        return member.id ? (
            <ProfileImage
                selectable={false}
                size={32}
                url={ProfileApi.getProfileImageUrl(member.id)}
            />
        ) : (
            <Initial from={member.email.substr(0, 1)} />
        );
    };

    renderNewTeam = (container: TeamsContainer) => {
        const onHide = () => {
            this.setState({
                showNewTeam: false
            });
        };

        return (
            <Modal
                show={this.state.showNewTeam}
                bsSize="lg"
                onHide={onHide}
                dialogClassName="modal-dialog-centered"
            >
                <Modal.Body>
                    <TeamsSubscription
                        isFirstTeam={!container.state.teams.length}
                        members={this.props.organisationMembers}
                        self={this.self!}
                        onNoCheckoutSessionId={onHide}
                    />
                </Modal.Body>
            </Modal>
        );
    };

    handleModalHide = () => {
        this.setState({
            showSelector: false,
            tempMemberContactIds: []
        });
    };

    handleSelectClick = (team: OrganisationTeamDTO) => {
        this.setState({
            showSelector: true,
            tempMemberContactIds: team.teamMembers.map(
                ({ contactId }) => contactId
            )
        });
    };

    handleMemberToggle = (id: string, isFull: boolean) => {
        if (~this.state.tempMemberContactIds.indexOf(id)) {
            this.setState({
                tempMemberContactIds: this.state.tempMemberContactIds.filter(
                    (m) => m != id
                )
            });
        } else if (!isFull) {
            this.setState({
                tempMemberContactIds: this.state.tempMemberContactIds.concat(id)
            });
        }
    };

    handleConfirm = (container: TeamsContainer) => {
        InternalTracker.trackEvent("", {
            category: 'Teams',
            action: 'Team Members Updated'
        });

        const { tempMemberContactIds } = this.state;
        const teamId = this.state.selectedTeamId!;
        const team = container.state.teams.find(({ id }) => id === teamId)!;
        const payload: SetOrganisationTeamMembersRequest = {
            id: teamId,
            userId: team.userId,
            teamMembers: this.props.organisationMembers
                .filter(({ contactId }) =>
                    tempMemberContactIds.includes(contactId)
                )
                .map(({ contactId }) => ({ contactId, teamId }))
        };

        container
            .setTeamMembers(this.state.selectedTeamId!, payload)
            .then(() => {
                this.handleModalHide();
                // toast.success('Team members set');
            })
            .catch((e) => {
                toast.error(
                    e && e.errors && e.errors.id && e.errors.id[0]
                        ? e.errors.id[0]
                        : 'Failed to update team members'
                );
            });
    };

    handleConfigure = () => {
        this.props.setTab('subscriptions');
    };

    get self() {
        return this.props.self;
    }
}

const StatefulTeams = (props: Omit<Props, 'container'>) => (
    <Subscribe to={[TeamsContainer]}>
        {(container: TeamsContainer) => (
            // @ts-ignore
            <Teams container={container} {...props} />
        )}
    </Subscribe>
);

export default StatefulTeams;

export const Empty = () => (
    <div className="layout vertical center-center m-t">
        <SvgBox />
    </div>
);

/**
 * Ideally this would be its own styled-component following atomic design principles
 * However the h4 where this styling is used is nested within the Content styled-component
 * I have extracted the styles below to allow for this style to be re-used elsewhere
 * without having to replace every child h4 instance
 */
export const H4CSS = css`
    color: ${theme.colours.updatedge};
    font-weight: 600;
    text-align: center;
`;

export const Content = styled.div`
    > div > div {
        // width: 300px;
        margin: 0 30px;
    }

    .teams-table {
        tbody > tr {
            cursor: pointer;
        }

        td {
            position: relative;

            .mobile {
                display: none;
            }
        }

        tbody > tr.not-clickable {
            cursor: initial;
        }

        td:first-child {
            width: 100%;
        }

        td:last-child {
            min-width: 294px;

            button {
                background: none;
                border: 1px solid #717171;
                border-radius: 4px;

                &:first-child {
                    margin-right: 8px;
                }
            }
        }

        th:last-child,
        td:last-child {
            text-align: center;
        }
    }

    h3 {
        margin-bottom: 30px;
        color: ${theme.colours.updatedge};
        font-weight: 600;
        text-align: center;
    }

    h4 {
        ${H4CSS}
        margin-bottom: 15px;
    }

    svg {
        width: 60px;
        height: 60px;
    }

    .member-row {
        border-bottom: 1px solid ${theme.colours.rowHover};

        > td {
            text-align: left;
            vertical-align: middle;
        }
    }

    .team-manager {
        margin: -10px 0 15px 0;
        color: ${theme.colours.updatedge};
        font-weight: 600;
    }

    @media (max-width: 900px) {
        .teams-table {
            thead {
                display: none;
            }

            tr {
                display: flex;
                flex-wrap: wrap;
                & > * {
                    flex-basis: 100%;
                }

                td {
                    border-top: none;
                    text-align: left !important;

                    &:last-child {
                        border-bottom: 1px solid #ddd;
                        padding-top: 0;
                    }

                    button {
                        display: block;
                        margin: 8px auto !important;
                    }

                    .mobile {
                        display: inline-block;
                        margin-right: 4px;
                        color: #222;
                        font-weight: 600;
                    }
                }
            }
        }
    }
`;

const SelectorContent = styled.div`
    margin: -20px -30px;
    text-align: center;

    > h4 {
        color: ${theme.colours.updatedge};
        font-weight: 600;
    }

    > h5 {
        margin: 10px 0;
    }

    > div {
        max-height: 350px;
        overflow-x: hidden;
        overflow-y: auto;
    }

    > .btn {
        width: 100%;
    }

    tr {
        cursor: pointer;

        &:not(.selected) {
            cursor: ${(props) => (props.full ? 'not-allowed' : 'pointer')};
        }

        > td {
            text-align: left;
            vertical-align: middle !important;
        }

        img {
            width: 32px;
            height: 32px;
        }
    }

    .full {
        width: 100%;
        height: 30px;
        font-weight: 600;
        font-size: 15px;
        line-height: 30px;
        text-align: center;
        background: ${theme.colours.orange};

        > span {
            opacity: 0.9;
        }
    }
`;
