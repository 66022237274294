// TODONOW
/* eslint-disable */
import React from 'react';
import Select from 'react-select';
import { PortalContactSearchContactDto, SubSector } from 'src/api/contacts/ResponseTypes';
import { LookupItem } from 'src/api/lookups/ResponseTypes';
import OrganisationAPI from '../../api/organisation/Organisation';
import ContactApi from '../../api/contacts/Contacts';
import ProfileApi from '../../api/profile/Profile';
import CompanyApi from '../../api/company/Company';
import Utilities from '../../Utilities';
import { Spinner } from '../ui-components/Spinner';

import { PortalContactSearchDto } from '../../api/contacts/ResponseTypes';
import { 
    Image,
    DropdownButton,
    MenuItem,
    Modal
} from 'react-bootstrap';
import WarningText from '../ui-components/WarningText';
import Dialog from '../ui-components/Dialog';
import { toast } from 'react-toastify';
import theme from 'src/css/theme';
import SimpleTooltip from '../ui-components/SimpleTooltip';
import InternalTracker from '../../InternalTracker';

interface State {
    visible: boolean;
    orgSectors: LookupItem[];
    orgSubSectors: SubSector[];
    selectedSector: number | null;
    selectedSubSector: number | null;
    proximity: number;
    dataState: DataState;
    contacts: PortalContactSearchContactDto[];
    agencies: string[],
    totalContacts: number;
    orgIsHirer: boolean;
    orgIsVerified: boolean;
    page: number,
    connectingLoading: null | string,
    initialLoad: boolean,
    userEmail: string | null,
    hidden: boolean
}

const MILE_OPTIONS = [
    { number: 20},
    { number: 30},
    { number: 40},
    { number: 50},
    { number: 100},
    { number: 200},
    { number: 300},
    { number: 400},
    { number: 500},
];

enum DataState {
    LOADING = 1,
    LOADED = 2,
    ERROR = 3,
    NORESULTS = 4
}

const RESULTS_PER_PAGE = 10;

export default class DiscoverNewContacts extends React.Component<{
    onContactInvite: () => void
}, State> {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            orgSectors: [],
            orgSubSectors: [],
            selectedSector: null,
            selectedSubSector: null,
            proximity: 500,
            dataState: DataState.LOADING,
            contacts: [],
            agencies: [],
            totalContacts: 0,
            orgIsHirer: false,
            orgIsVerified: false,
            page: 0,
            connectingLoading: null,
            initialLoad: true,
            userEmail: null,
            hidden: false,
        }
    }

    async componentDidMount(noRefresh: boolean = false) {
        if ((window as any).Cypress) {
            setTimeout(() => {
                const verifiedState = document.getElementById("verify-status")?.innerHTML === "Verified";
                this.setState({
                    visible: true,
                    orgSectors: [{id: 227, subSectors: [{id: 326, value: "Secondary"}], value: "Education"}],
                    orgSubSectors: [{id: 326, value: "Secondary"}],
                    orgIsVerified: verifiedState,
                }, () => {
                    this.setState({
                        selectedSector: 227,
                        selectedSubSector: 326,
                    }, () => {
                        this.getContacts();
                    })
                })
            }, 8000)
        } else {
            const user = JSON.parse(localStorage.getItem("user")!)
            const org = JSON.parse(localStorage.getItem("organisation")!)
            if (user && org && org.industries && org.isHirer) {
                this.setState({
                    orgSectors: org.industries,
                    orgSubSectors: org.industries.map(i => i.subSectors).filter(s => !!s).flat(),
                    orgIsHirer: org.isHirer,
                    orgIsVerified: org.verified,
                    visible: true,
                    userEmail: user.emailAddress,
                }, () => {
                    this.setState({
                        selectedSector: this.state.orgSectors.length !== 0 ? this.state.orgSectors[0].id : 0,
                        selectedSubSector: this.state.orgSubSectors.length !== 0 ? this.state.orgSubSectors[0].id : 0
                    }, () => {
                        this.getContacts();
                    })
                })
            }
    
            if (user && user.organisationId && !noRefresh) {
                const organisation = await OrganisationAPI.getOrganisation(user.organisationId);
                if (organisation?.data) {
                    localStorage.setItem("organisation", JSON.stringify(organisation?.data))
                    this.componentDidMount(true);
                }
            }
        }

        (window as any).onSignalRAccountAndOrgVerifiedVerifiedUserProfile = () => {
            this.componentDidMount();
        }
    }

    async getContacts() {
        this.setState({
            dataState: DataState.LOADING
        })

        const contacts = await ContactApi.searchWorkerContacts(
            this.state.proximity * 1609, 
            this.state.selectedSector || 0, 
            this.state.selectedSubSector ? (this.state.orgSubSectors.find(oss => oss.id === this.state.selectedSubSector)!.value || "") : "",
            this.state.hidden,
        );

        InternalTracker.trackEvent("", {
            category: 'Discover Contacts',
            action: 'Search',
            customDimensions: [{ id: "REPLACE", value: 
                (this.state.proximity * 1609) + " | " + 
                (this.state.selectedSector !== null ? (this.state.orgSectors.find(s => s.id === this.state.selectedSector)?.value || this.state.orgSectors.find(s => s.id === this.state.selectedSector)?.name) : "") + " | " + 
                (this.state.selectedSubSector !== null ? (this.state.orgSubSectors.find(s => s.id === this.state.selectedSubSector)?.value || this.state.orgSubSectors.find(s => s.id === this.state.selectedSubSector)?.name) : "")
            }]
        });

        if (contacts) {
            if (contacts.list.length === 0 && this.state.selectedSubSector && this.state.initialLoad) {
                this.setState({
                    selectedSubSector: 0
                }, () => {
                    InternalTracker.trackEvent("", {
                        category: 'Discover Contacts',
                        action: 'Auto fallback to whole sector search'
                    });
                    this.getContacts();
                })
            }
            
            this.setState({
                dataState: contacts.list.length !== 0 ? DataState.LOADED : DataState.NORESULTS,
                contacts: contacts.list,
                totalContacts: contacts.count,
                agencies: contacts.agencies,
                initialLoad: false
            })
        } else {
            this.setState({
                dataState: DataState.ERROR
            })
        }
    }

    async inviteAll() {
        let uninvitedVisibleContacts = this.state.contacts.filter((c, i) => !c.alreadyInvited && (!((i >= ((this.state.page+1) * RESULTS_PER_PAGE)))));
        InternalTracker.trackEvent("", {
            category: 'Discover Contacts',
            action: 'Invting all visible contacts',
            customDimensions: [{ id: "REPLACE", value: uninvitedVisibleContacts.length.toString() }]
        });
        for (let i = 0; i < uninvitedVisibleContacts.length; i++) {
            const contact = uninvitedVisibleContacts[i];
            const res = await this.inviteContact(contact);
            if (!res) {
                break;
            }
        }
    }
    
    inviteContact(contact) {
        return new Promise((resolve, reject) => {
            if (this.state.orgIsVerified) {
                if (!this.state.connectingLoading) {
                    this.setState({ connectingLoading: contact.id })
                    ContactApi.inviteContact('', '', contact.id).then(() => {
                        InternalTracker.trackEvent("", {
                            category: 'Discover Contacts',
                            action: 'Invitation Sent',
                            customDimensions: [{ id: "REPLACE", value: contact.id}]
                        });
                        toast.success("Invitation sent!")
                        this.setState({ connectingLoading: null })
                        this.setState({
                            contacts: this.state.contacts.map(c => {
                                if (c.id === contact.id) {
                                    c.alreadyInvited = true;
                                }
                                return c;
                            })
                        })
                        if (this.props.onContactInvite) {
                            this.props.onContactInvite();
                        }
                    }).then(data => {
                        resolve(true);
                    }).catch((e) => {
                        if (e && e.errors && e.errors.toWorkerId && e.errors.toWorkerId[0]) {
                            toast.error(e.errors.toWorkerId[0])
                            InternalTracker.trackEvent("", {
                                category: 'Discover Contacts',
                                action: 'Failed to sent invitation - limit exceeded'
                            });
                        } else {
                            toast.error("Error sending invitation")
                        }
                        this.setState({ connectingLoading: null })
                        reject(e.response.data || "Error sending invitation");
                    })
                }
            }  else {
                toast.error("You must be verified to connect with other new users. You can still invite your existing contacts by email.")
            }
        })
    }

    render() {
        if (!this.state.visible) {
            return null
        }

        const hasMoreResults = this.state.totalContacts > (this.state.page + 1) * RESULTS_PER_PAGE;

        return (
            <div className="discover-contacts-wrapper">
               <h4>Discover contacts by proximity:</h4>
               { (!this.state.orgIsVerified) &&
                    <Dialog
                        body={
                            <div>
                                You can now invite contacts you know <button 
                                    onClick={() => {
                                        if (document.getElementById("invite-by-email-btn")) {
                                            document.getElementById("invite-by-email-btn")?.click();
                                        }
                                    }}
                                    style={{
                                        background: "white",
                                        color: "black",
                                        borderRadius: 6,
                                        border: "none"
                                    }}
                                >by email</button>, before your organization details are verified. 
                                Verification  can take 1-2 hours, when you can connect with the listed skilled people.  
                            </div>
                        }
                        type="info"
                        style={{
                            marginTop: 15,
                            marginBottom: 15
                        }}
                    />
               }
               <div className='filters'>
                    <div>
                        Search for workers within
                        <Select                      
                            formatOptionLabel={(item) => (
                                <div>
                                    <div>{item.number} miles</div>
                                </div>
                            )}
                            id='miles-select'
                            value={MILE_OPTIONS.find(s => s.number === this.state.proximity)}
                            onChange={(option) => {
                                this.setState({ 
                                    proximity: option?.number || 50 
                                }, () => {
                                    this.getContacts();
                                })
                            }}
                            inputId="miles"
                            placeholder="Select Miles"
                            options={MILE_OPTIONS}
                        />
                    </div>
                    <div>
                        in
                        <Select                      
                            formatOptionLabel={(item) => (
                                <div>
                                    <div> {item.name || item.value} </div>
                                </div>
                            )}
                            id='sector-select'
                            value={this.state.orgSectors.find(s => s.id === this.state.selectedSector)}
                            onChange={(option) => {
                                this.setState({ 
                                    selectedSector: option?.id || 0,
                                    selectedSubSector: 0
                                }, () => {
                                    this.getContacts();
                                })
                            }}
                            inputId="sectors"
                            placeholder="Select Sector"
                            options={/*[{ name: "All", id: 0 } as LookupItem].concat*/(this.state.orgSectors as LookupItem[])}
                        />
                        <span></span>
                        specialized in
                        { (this.state.orgSectors) &&
                            <Select                      
                                formatOptionLabel={(item) => (
                                    <div>
                                        <div> {item.name || item.value} </div>
                                    </div>
                                )}
                                id='sub-sector-select'
                                value={this.state.orgSectors.find(s => s.id === this.state.selectedSector)?.subSectors?.find(s => s.id === this.state.selectedSubSector)}
                                onChange={(option) => {
                                    this.setState({ 
                                        selectedSubSector: option?.id || 0 
                                    }, () => {
                                        this.getContacts();
                                    })
                                }}
                                inputId="sub-sectors"
                                placeholder="Select Subsector"
                                options={[{ name: "All", id: 0 } as LookupItem].concat(this.state.orgSubSectors as LookupItem[])}
                            />
                        }
                    </div>
               </div>
               <div className='result-count'>
                    { this.state.dataState === DataState.LOADED &&  
                        <div>
                            <p>Showing {((this.state.page+1) * RESULTS_PER_PAGE) > this.state.totalContacts ? this.state.totalContacts : ((this.state.page+1) * RESULTS_PER_PAGE)} of {this.state.totalContacts} contacts</p>
                            { this.state.agencies.length > 0 &&
                                <React.Fragment>
                                    <p>Contacts are represented by the following agencies:</p>
                                    <div className='agencies clearfix'>
                                        { this.state.agencies.map((agency, agencyI) => {
                                            const name = agency.split("::")[0];
                                            const domain = agency.split("::")[1];
                                            return (
                                                <div style={{
                                                    display: "inline-flex",
                                                    alignItems: "center",
                                                    margin: '0 6px 6px 0',
                                                    background: theme.colours.blue2,
                                                    color: 'white',
                                                    borderRadius: 52,
                                                    overflow: 'hidden',
                                                    float: 'left'
                                                }}>
                                                    { (domain) &&
                                                        <img 
                                                            id={agencyI.toString() + "-agencyimg"}
                                                            src={OrganisationAPI.getExtOrgPicture(domain)}
                                                            style={{
                                                                width: 24,
                                                                height: 24,
                                                                marginRight: 4,
                                                                borderRadius: '100%'
                                                            }}
                                                            onError={() => {
                                                                const el = document.getElementById(agencyI.toString() + "-agencyimg");
                                                                if (el) {
                                                                    el.style.display = "none";
                                                                }
                                                                
                                                            }}
                                                        />   
                                                    }
                                                    <span style={{
                                                        padding: '0px 10px 0 4px',
                                                        height: 24,
                                                        display: 'inline-block',
                                                        lineHeight: '23px'
                                                    }}>{name}</span>
                                                </div>
                                            )
                                        }) }
                                    </div>
                                </React.Fragment>
                            }
                        </div>
                    }
               </div>
               <div className='contacts'>
                    { (this.state.dataState === DataState.LOADED || this.state.dataState === DataState.LOADING) && this.state.contacts.map((contact, contactI) => {
                        if (contactI >= ((this.state.page+1) * RESULTS_PER_PAGE)) {
                            return null;
                        }
                        return (
                            <div className='contact' data-name={contact.firstName + ' ' + contact.lastName}>
                                <div className='image'>
                                    <Image
                                        src={ProfileApi.getProfileImageUrl(contact.id)}
                                        circle
                                        alt={contact.firstName + ' ' + contact.lastName}
                                        width={50}
                                        data-report-blurred={contact.reported}
                                        data-user-id={contact.id}
                                    />
                                </div>
                                <div className='meta'>
                                    <div 
                                        className='name' 
                                        data-report-blurred={contact.reported}
                                    >
                                            {contact.firstName + ' ' + contact.lastName}
                                    </div>
                                    { (contact.headline) &&
                                        <div 
                                            className='headline' 
                                            data-report-blurred={contact.reported}
                                            data-user-id={contact.id}
                                        >
                                            {contact.headline}
                                        </div>
                                    }
                                    <div className='tags'>
                                        { (contact.representingOrganisations && contact.representingOrganisations.length) ?
                                            <React.Fragment>
                                                { contact.representingOrganisations.map((org, orgI) => {
                                                    return (
                                                        <SimpleTooltip
                                                            id={org.organisationId + "-rep"}
                                                            text={"Represented, Verified and Introduced* by " + org.organisationName}
                                                        >
                                                            <img
                                                                className='rep-img' 
                                                                src={CompanyApi.getOrganisationProfileImageUrl(org.organisationId)}
                                                            />                                        
                                                        </SimpleTooltip>
                                                    )
                                                })

                                                }
                                            </React.Fragment>
                                        : null }
                                        { (contact.ratingCount && contact.avgRating) ?
                                            <span style={{ backgroundColor: theme.colours.yellow, color: '#333' }}>
                                                <i className="fas fa-star"></i>
                                                <span>{contact.avgRating} from {contact.ratingCount} ratings</span>
                                            </span> : null
                                        }
                                        <span style={{ backgroundColor: theme.colours.blue2 }}>
                                            <i className="fas fa-map-marker-alt"></i>
                                            <span>{Math.ceil(Utilities.metersToMiles(contact.distanceInMeters))} miles away</span>
                                        </span>
                                        { contact.skills.map((tag, tagI) => {
                                            return (
                                                <span data-report-blurred={contact.reported} data-user-id={contact.id} key={tagI}>
                                                    {tag}
                                                </span>
                                            )
                                        }) }
                                    </div>
                                </div>
                                <div className='actions'>
                                    <SimpleTooltip text="Connect with existing network user" id="connect-tooltip">
                                        <button 
                                            onClick={() => {
                                                if (contact.alreadyInvited) {
                                                    return;
                                                }
                                                this.inviteContact(contact);
                                            }}
                                            style={ contact.alreadyInvited ? { opacity: 0.6 } : {} }
                                        >{this.state.connectingLoading === contact.id ? "Connecting..." : contact.alreadyInvited ? "Already Invited" : "Connect"}</button>
                                    </SimpleTooltip>
                                    <SimpleTooltip text={this.state.hidden ? "Unhide Suggestion" : "Hide Suggestion"} id="hide-suggestions-tooltip">
                                        <button 
                                            onClick={() => {
                                                const newContactList = this.state.contacts.filter(c => c.id !== contact.id);
                                                this.setState({
                                                    contacts: newContactList
                                                })
                                                if (this.state.hidden) {
                                                    ContactApi.unhideContact(contact.contactId).then(() => {
                                                        toast.success("Contact unhidden")
                                                    }).catch(() => {
                                                        toast.error("Error unhiding contact")
                                                    })
                                                } else {
                                                    ContactApi.hideContact(contact.contactId).then(() => {
                                                        toast.success("Contact hidden")
                                                    }).catch(() => {
                                                        toast.error("Error hiding contact")
                                                    })
                                                }
                                            }}
                                            style={{ marginLeft: 8, backgroundColor: !this.state.hidden ? theme.colours.red2 : undefined }}
                                        >
                                            { (this.state.hidden) ?
                                                <i className="fas fa-trash-restore"></i>
                                                :
                                                <i className="fas fa-trash"></i>
                                            }
                                        </button>
                                    </SimpleTooltip>
                                </div>
                            </div>
                        )
                    }) }
                    { this.state.dataState === DataState.LOADING && 
                        <div className='state'>
                            <Spinner noText={true} />
                        </div> 
                    }
                    
                    <div className='show-more'>
                        { (this.state.dataState !== DataState.LOADING) &&
                            <button
                                className='main'
                                style={{
                                    backgroundColor: !this.state.hidden ? theme.colours.red2 : theme.colours.grey
                                }}
                                onClick={() => {
                                    this.setState({
                                        hidden: !this.state.hidden
                                    }, () => {
                                        this.getContacts()
                                    })
                                }}
                            >{this.state.hidden ? "Show Non-hidden Contacts" : "Show Hidden Contacts"}</button>   
                        }
                        { (hasMoreResults && this.state.dataState !== DataState.LOADING) ?
                            <button 
                                onClick={() => {
                                    this.setState({
                                        dataState: DataState.LOADING
                                    })
                                    InternalTracker.trackEvent("", {
                                        category: 'Discover Contacts',
                                        action: 'Show More'
                                    });
                                    setTimeout(() => {
                                        this.setState({
                                            page: this.state.page + 1,
                                            dataState: DataState.LOADED
                                        })
                                    }, Utilities.randomIntFromInterval(400, 1000))
                                }}
                                className='main'
                            >
                                Show More
                            </button> : null
                        }
                        { (this.state.dataState !== DataState.LOADING && this.state.dataState !== DataState.NORESULTS && this.state.contacts.filter((c, i) => !c.alreadyInvited && (!((i >= ((this.state.page+1) * RESULTS_PER_PAGE))))).length) ?
                            <button className='main' onClick={() => {
                                this.inviteAll()
                            }}>
                                Connect to {this.state.contacts.filter((c, i) => !c.alreadyInvited && (!((i >= ((this.state.page+1) * RESULTS_PER_PAGE))))).length} visible Contacts
                            </button> : null
                        }
                    </div>
                    { this.state.dataState === DataState.ERROR && <div className='state'>Error</div> }
                    { this.state.dataState === DataState.NORESULTS ?
                        <div className='state'>
                            No Results – Expand your search, or invite by email
                            <div className='opts'>
                                <button onClick={() => {
                                    if (document.getElementById("invite-by-email-btn")) {
                                        document.getElementById("invite-by-email-btn")?.click();
                                    }
                                }}>
                                    Invite by email
                                </button>
                                { (MILE_OPTIONS.find(o => o.number > this.state.proximity)) ? (MILE_OPTIONS.filter(o => o.number > this.state.proximity)).map((altProximity, i) => {
                                    if (i > 0) return null;
                                    return (
                                        <button onClick={() => {
                                            InternalTracker.trackEvent("", {
                                                category: 'Discover Contacts',
                                                action: 'Increased radius',
                                                customDimensions: [{ id: "REPLACE", value: altProximity.number.toString() }]
                                            });
                                            this.setState({
                                                proximity: altProximity.number
                                            }, () => {
                                                this.getContacts();
                                            })
                                        }}>
                                            Increase radius to {altProximity.number} miles
                                        </button>
                                    )
                                }) : null }
                                { (this.state.selectedSubSector) ?
                                    <button onClick={() => {
                                        InternalTracker.trackEvent("", {
                                            category: 'Discover Contacts',
                                            action: 'Search expanded to all subsectors'
                                        });
                                        this.setState({
                                            selectedSubSector: 0
                                        }, () => {
                                            this.getContacts();
                                        })
                                    }}>
                                        Search all {this.state.orgSectors.find(s => s.id === this.state.selectedSector)?.value} subsectors
                                    </button>
                                : null }
                                <button onClick={() => {
                                    InternalTracker.trackEvent("", {
                                        category: 'Discover Contacts',
                                        action: 'Demo Availability Cicked'
                                    });
                                    window.open("/availability", "_blank");
                                }}>
                                    Show Demo Worker's Availability
                                </button>
                            </div>
                        </div> : null
                    }
               </div>
            </div>
        )
    }
}
