// TODONOW
/* eslint-disable */
/* eslint-disable @typescript-eslint/ban-ts-ignore */
import React from 'react';
import styled from 'styled-components';
import {
    Modal,
    Button,
    Popover,
    OverlayTrigger,
    Table
} from 'react-bootstrap';
import { toast, ToastType } from 'react-toastify';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';

import { Link } from 'react-router-dom';

import { Subscribe } from '../unstated-fork/unstated';
import Locations from '../components/organisation/Locations';
import ProfileApi from '../api/profile/Profile';
import { LocationsResponse } from '../api/locations/ResponseTypes';
import CompanyApi from '../api/company/Company';
import UserApi from '../api/user/User';
import OrganisationAPI from '../api/organisation/Organisation';
import { SetOrganisationTeamMembersRequest } from '../api/organisation/RequestTypes';
import { OrganisationDTO, OrganisationMemberStatus, OrganisationTeamDTO, OrganisationUser } from '../api/organisation/ResponseTypes';
import { CompanyRoles } from '../api/company/ResponseTypes';
import { ProfileImage } from '../components/ui-components/ProfileImage';
import {
    PopoverSelect,
    GREY_LIGHTER
} from '../components/ui-components/PopoverSelect';
import theme from '../css/theme';
import SimpleTooltip from '../components/ui-components/SimpleTooltip';
import { RegularExpressions, supportedImageFormats } from '../constants';
import {
    TEAM_CREATED_SUCCESS_MESSAGE
} from '../components/organisation/TeamsSubscription';
import TeamsContainer from '../state-containers/teams.container';
import Initial from '../components/ui-components/Initial';
import { Spinner } from '../components/ui-components/Spinner';
import ManageSubscriptionsV2 from '../components/organisation/ManageSubscriptionsV2';
import OrganisationProfileEditor from '../components/organisation/OrganisationProfileEditor';
import { ApplicationState } from '../store';
import InternalTracker from '../InternalTracker';
import { actionCreators as ProfileActionCreators } from '../store/Profile';
import { UserDto } from '../api/user/ResponseTypes';
import IndustrySelector from '../components/profile/IndustrySelector';
import { LookupItem } from '../api/lookups/ResponseTypes';
import AuthApi from '../components/auth/Auth';
import { ProfileVerificationContainer } from '../state-containers/profile-verification.container';
import { SubscriptionNotificationsDto, UserProfileDto } from '../api/profile/RequestTypes';
import history, {
    getFromLocationState,
    removeFromLocationState
} from '../history';
import InlineOrganisationImageSuggestions from '../components/organisation/InlineOrganisationImageSuggestions';
import * as Notification from '../store/Notifications';
import UtilsAPI from '../api/Utils';
import LocationsApi from '../api/locations/Locations';
import * as EventsState from '../store/Events';
import LocationPicker from '../components/profile/LocationPicker';
import Analytics from '../services/analytics.service';
import OrganisationImageSuggestions from '../components/organisation/OrganisationImageSuggestions';
import * as Notifications from '../store/Notifications';
import OrganisationAutoCompleteSuggestions from "../components/organisation/OrganisationAutoCompleteSuggestions";
import { AutocompleteSuggestion, LocationType, Sector } from "../components/organisation/OrganisationAutoCompleteSuggestions";
import FullScreenLoader from '../components/ui-components/FullScreenLoader';
import Hyperlink from '../components/ui-components/Hyperlink';
import { ShieldWrapper } from '../components/profile/UserProfileEditor';

import Utilities from '../Utilities';

import SvgMap from '../components/svg/map';
import SvgRota from '../components/svg/rota';
import SvgTeams from '../components/svg/teams';
import '../css/Organisations.css';

import { getValue, setValue } from '../db/KeyValueCache';
import { CircularProgress } from '@mui/material';
import VerificationAPI from 'src/api/verification/Verification';

enum Mode {
    Active,
    Archived
}

export interface Props extends RouteComponentProps<{}> {
    teamsContainer: TeamsContainer | null;
    organisationName: string | null;
    subscriptionNotifications: SubscriptionNotificationsDto | null;
    organisationId: string | null;
    updateProfileState: typeof ProfileActionCreators['updateProfileState'];
    userIsVerified: boolean;
    profileHasLoaded: boolean;
    userEmail: string;
    userId: string;
    imageUrl?: string;
    updateOrganisationProfileImage: typeof ProfileActionCreators['updateOrganisationProfileImage'];
    profileImageUrl: string | null;
    userSetProfileImage: boolean;
    phoneNumber: string;
    headline: string;
    firstName: string;
    lastName: string;
    refreshOverlayMenu: typeof EventsState.actionCreators['refreshOverlayMenu'];
    updateOrganisationLocation: typeof ProfileActionCreators['updateOrganisationLocation'];
    handleLocationPicked: (
        locationPlaceId: string,
        locationPlaceName: string,
        locationFullName: string,
        locationComponents: google.maps.places.PlaceResult['address_components']
    ) => any;
}

type OrganisationTab =
    | 'new'
    | 'members'
    | 'teams'
    | 'subscriptions'
    | 'profile'
    | 'suggest'
    | 'locations';

interface State {
    loaded: boolean;
    mode: Mode;
    members: UserDto[];
    memberRoleEdit?: UserDto;
    showInviteModal: boolean;
    showImageSuggestions: boolean;
    inviteEmail: string;
    inviteEmailInvalid: boolean;
    tab: OrganisationTab;
    swapContactsFrom?: UserDto;
    swapContactsToUserId?: string;
    organisation: OrganisationDTO | null;
    showLocationPicker: boolean;
    newCompanyProfileImage: File | null;
    newCompanyProfileImagePreviewUrl: string | null;
    savingNewOrg: boolean | null;
    overrideImageSearch?: string | null;
    loadingMessage: string | null;
    hintedElement: string | null;
    editingDetails: boolean;
    selectedTeamId: OrganisationTeamDTO['id'] | null;
    tempMemberContactIds: UserDto['contactId'][];
    selectedMemberForPhotoUpload?: UserDto | null;
    newOrganisationFormData: {
        metadata?: AutocompleteSuggestion;
        name: string;
        imageUrl: string | null;
        industries: LookupItem[];
        subSectors: Sector[];
        isHirer: boolean;
        isAgency: boolean;
        locationPlaceId: string;
        locationPlaceName: string;
        locationFullName: string;
        locationComponents:
            | google.maps.places.PlaceResult['address_components']
            | null;
    };
    recommendations: string[];
    staleData: boolean;
}

export class Organisation extends React.Component<Props, State> {
    private locationsRef: React.RefObject<typeof Locations>;
    constructor(props) {
        super(props);

        this.state = {
            savingNewOrg: false,
            loaded: false,
            mode: Mode.Active,
            members: [],
            memberRoleEdit: undefined,
            showInviteModal: false,
            showLocationPicker: false,
            showImageSuggestions: false,
            inviteEmail: '',
            inviteEmailInvalid: false,
            overrideImageSearch: null,
            // @ts-ignore
            tab: this.hasOrganisation() ? 'profile' : 'new',
            organisation: null,
            newOrganisationFormData: {
                name: '',
                imageUrl: this.props.imageUrl ? this.props.imageUrl : '',
                industries: [],
                subSectors: [],
                isHirer: false,
                isAgency: false,
                locationPlaceId: '',
                locationPlaceName: '',
                locationFullName: '',
                locationComponents: null
            },
            newCompanyProfileImage: null,
            newCompanyProfileImagePreviewUrl: '',
            loadingMessage: null,
            hintedElement: null,
            editingDetails: false,
            selectedTeamId: null,
            tempMemberContactIds: [],
            recommendations: [],
            staleData: false
        };

        this.locationsRef = React.createRef();
    }

    componentDidMount() {

        if (localStorage.getItem("justCreatedOrg")) {
            localStorage.removeItem("justCreatedOrg");
            window.location.reload();
        }

        if (this.props.profileHasLoaded) {
            this.loadOrganisation();

            if (!this.hasOrganisation()) {
                let user = localStorage.getItem("user") !== undefined ? JSON.parse(localStorage.getItem("user") || "") : null;
                this.setState({
                    newOrganisationFormData: {
                        ...this.state.newOrganisationFormData,
                        isAgency: user && user.userType === 3,
                        isHirer: user && user.userType === 2
                    }
                })
            }

        }

        if (~this.props.location.pathname.indexOf('/teams/success')) {
            InternalTracker.trackEvent("", {
                category: 'Teams',
                action: 'Create Team Success Redirect Visited'
            });
            history.replace('/organisation', {
                initialTab: 'members'
            });
            toast.success(TEAM_CREATED_SUCCESS_MESSAGE);
        }

        if (~this.props.location.pathname.indexOf('/locations/success')) {
            InternalTracker.trackEvent("", {
                category: 'Locations',
                action: 'Create Location Success Redirect Visited'
            });
            history.replace('/organisation', {
                initialTab: 'locations'
            });
            toast.success('Location capacity added!');
        }

        if (~this.props.location.pathname.indexOf('/teams/cancel')) {
            history.replace('/organisation', {
                initialTab: 'members'
            });
            toast.error(
                'Payment error. Your purchase may not have been completed.'
            );
        }

        if (~this.props.location.pathname.indexOf('/locations/cancel')) {
            history.replace('/organisation', {
                initialTab: 'locations'
            });
            toast.error(
                'Payment error. Your purchase may not have been completed.'
            );
        }

        if (this.props.location.pathname === '/organisation/teams') {
            this.setTab('teams');
        } else if (this.props.location.pathname === '/organisation/profile') {
            this.setTab('profile');
        } else if (this.props.location.pathname === '/organisation/members') {
            this.setTab('members');
        } else if (this.props.location.pathname === '/organisation/locations') {
            this.setTab('locations');
        } else if (this.props.location.pathname.startsWith('/organisation/subscriptions/sms')) {
            this.setTab('subscriptions', 'sms');
            this.setState({
                hintedElement: 'sms-credit'
            })
        } else if (this.props.location.pathname.startsWith('/organisation/subscriptions')) {
            this.setTab('subscriptions');
        } else if (this.props.location.pathname.startsWith('/organisation/suggestdd')) {
            this.setTab('suggest');
        }

    }

    componentDidUpdate(prevProps) {
        if (
            this.state.tab === 'new' &&
            this.hasLoaded() &&
            this.hasOrganisation()
        ) {
            this.setState({ tab: this.getInitialTab() });
            this.scrollToSection(this.getInitialTab())
        }

        if (this.props.location.pathname !== prevProps.location.pathname) {
            if (this.props.location.pathname === '/organisation/members') {
                this.setTab('members');
            } else if (this.props.location.pathname === '/organisation/locations') {
                this.setTab('locations');
            } else if (this.props.location.pathname.startsWith('/organisation/subscriptions/sms')) {
                this.setTab('subscriptions', 'sms');
            } else if (this.props.location.pathname.startsWith('/organisation/subscriptions')) {
                this.setTab('subscriptions');
            } else if (this.props.location.pathname.startsWith('/organisation/suggestdd')) {
                this.setTab('suggest');
            } else {
                this.setTab('profile')
            }
        }

        if (
            (prevProps.organisationId == null && this.hasOrganisation()) ||
            (!prevProps.profileHasLoaded && this.props.profileHasLoaded)
        ) {
            this.loadOrganisation();
        }
    }

    handleMemberToggle = (id: string, isFull: boolean) => {
        if (~this.state.tempMemberContactIds.indexOf(id)) {
            this.setState({
                tempMemberContactIds: this.state.tempMemberContactIds.filter(
                    (m) => m != id
                )
            });
        } else if (!isFull) {
            this.setState({
                tempMemberContactIds: this.state.tempMemberContactIds.concat(id)
            });
        }
    };

    renderMembersModal(
        selectedTeam: OrganisationTeamDTO | null,
        container: TeamsContainer
    ) {
        if (selectedTeam == null || !this.state.selectedTeamId) return null;
        const team = selectedTeam;
        const isFull =
            this.state.tempMemberContactIds.length == selectedTeam.size;

        return (
            <Modal
                show={true}
                onHide={() => {
                    this.handleMembersHide();
                }}
                bsSize="small"
                dialogClassName="modal-dialog-centered"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Manage Members
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <SelectorContent full={isFull}>
                        {isFull && (
                            <div className="full">
                                This team is full -{' '}
                                <Hyperlink
                                    as="button"
                                    onClick={() => {
                                        // this.handleModalHide();
                                        // this.handleConfigure();
                                    }}
                                >
                                    increase size?
                                </Hyperlink>
                            </div>
                        )}
                        <h5>{`${this.state.tempMemberContactIds.length} / ${team.size} members. Select colleagues to add in ${team.name} include, or deselect to remove them`}</h5>
                        <div>
                            <Table striped hover>
                                <tbody>
                                    {this.state.members
                                        .filter((m) => !m.deleted)
                                        .map((m) => (
                                            <tr
                                                data-testid={`selector-${m.contactId}`}
                                                key={`selector-${m.contactId}`}
                                                className={
                                                    ~this.state.tempMemberContactIds.indexOf(
                                                        m.contactId
                                                    )
                                                        ? 'selected team-member'
                                                        : 'team-member'
                                                }
                                                data-name={m.firstName + " " + m.lastName}
                                                onClick={() => {
                                                    return this.handleMemberToggle(
                                                        m.contactId,
                                                        isFull
                                                    )
                                                }}
                                            >
                                                <td>
                                                    {this.renderMemberImage(m)}
                                                </td>
                                                <td
                                                    style={{
                                                        textAlign: 'left'
                                                    }}
                                                >
                                                    {m.id
                                                        ? m.firstName +
                                                          ' ' +
                                                          m.lastName
                                                        : m.email}
                                                </td>
                                            </tr>
                                        ))}
                                    <tr onClick={() => {
                                        this.handleMembersHide();
                                        let el = document.getElementById("invite-colleague-btn");
                                        if (el) {
                                            el.click();
                                        }
                                    }}>
                                        <td>
                                            <i className="fas fa-plus-square"></i>
                                        </td>
                                        <td>Invite new colleague</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                        <Button
                            bsStyle="success"
                            onClick={() => {
                                this.handleMembersConfirm(container)
                            }}
                            id="confirm-team-member-edit-btn"
                        >
                            Confirm
                        </Button>
                    </SelectorContent>
                </Modal.Body>
            </Modal>
        );
    }

    handleMembersHide = () => {
        this.setState({
            selectedTeamId: null,
            tempMemberContactIds: []
        });
    };

    handleMembersConfirm = (container: TeamsContainer) => {
        const { tempMemberContactIds } = this.state;
        const teamId = this.state.selectedTeamId!;
        const team = container.state.teams.find(({ id }) => id === teamId)!;
        const payload: SetOrganisationTeamMembersRequest = {
            id: teamId,
            userId: team.userId,
            teamMembers: this.state.members
                .filter(({ contactId }) =>
                    tempMemberContactIds.includes(contactId)
                )
                .map(({ contactId }) => ({ contactId, teamId }))
        };

        container
            .setTeamMembers(this.state.selectedTeamId!, payload)
            .then(() => {
                this.handleMembersHide();
                container.getTeams();
            })
            .catch((e) => {
                toast.error(
                    e && e.errors && e.errors.id && e.errors.id[0]
                        ? e.errors.id[0]
                        : 'Failed to update team members'
                );
            });
    };

    handleSelectedFile(file: File | null) {
        if (file !== null) {
            if (0 > supportedImageFormats.indexOf(file.type)) {
                Notification.actionCreators.display(
                    ToastType.ERROR,
                    'Please select a jpg, png or gif file.'
                );
            } else {
                this.setState((prevState) => {
                    return {
                        ...prevState,
                        newCompanyProfileImage: file!,
                        newCompanyProfileImagePreviewUrl: URL.createObjectURL(
                            file!
                        )
                    };
                });
            }
        }
    }

    handleSelectedFiles(files: FileList | null) {
        if (files !== null) {
            if (0 > supportedImageFormats.indexOf(files[0].type)) {
                Notification.actionCreators.display(
                    ToastType.ERROR,
                    'Please select a jpg, png or gif file.'
                );
            } else {
                this.setState((prevState) => {
                    return {
                        ...prevState,
                        newCompanyProfileImage: files[0]!,
                        newCompanyProfileImagePreviewUrl: URL.createObjectURL(
                            files[0]!
                        )
                    };
                });
            }
        }
    }

    loadOrganisation = async () => {
        let localUser: UserProfileDto = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user") || "{}") : null;
        this.setState({ loaded: false });

        let members: UserDto[] | boolean | null = null;
        let organisation: OrganisationDTO | null = null;
        let locations: LocationsResponse | null = null;
        let recommendations: string[] = [];

        [members, organisation, locations] = await Promise.all([
            getValue("members"),
            getValue("organisation"),
            getValue("locations")
        ]);

        if (!members || (typeof members === 'object' && members.length < 2)) {
            recommendations.push("members");
        }

        if (this.props.teamsContainer && this.props.teamsContainer.state.teams.length < 2) {
            recommendations.push("teams");
        }

        if (localUser && !localUser.subscriptionNotifications?.subscriptions.find(item => item.featureName === "Rota") && !localStorage.getItem("rotaSubscriptionSaved")) {
            recommendations.push("rota");
        }

        if (locations && locations?.data?.locations.length < 2) {
            recommendations.push("locations");
        }

        this.setState({
            members: Array.isArray(members) ? members : [],
            organisation,
            recommendations
        });

        if (members && organisation && locations) {
            this.setState({
                staleData: true,
            });
        }   

        if (this.hasOrganisation()) {
            [members, { data: organisation }, locations] = await Promise.all([
                UserApi.getAll(),
                OrganisationAPI.getOrganisation(this.props.organisationId!),
                LocationsApi.getAll()
            ]);
            localStorage.setItem("organisation", JSON.stringify(organisation))

            setValue("organisation", organisation);
            setValue("locations", locations);
            setValue("members", members);
        }

        recommendations = [];

        if (!members || (typeof members === 'object' && members.length < 2)) {
            recommendations.push("members");
        }

        if (this.props.teamsContainer && this.props.teamsContainer.state.teams.length < 2) {
            recommendations.push("teams");
        }

        if (localUser && !localUser.subscriptionNotifications?.subscriptions.find(item => item.featureName === "Rota") && !localStorage.getItem("rotaSubscriptionSaved")) {
            recommendations.push("rota");
        }

        if (locations && locations?.data?.locations.length < 2) {
            recommendations.push("locations");
        }

        this.setState({
            members: Array.isArray(members) ? members : [],
            recommendations: recommendations,
            organisation,
            loaded: true,
            staleData: false,
        }, () => {
            if ((window as any).showNextTaskIfAny) {
                (window as any).showNextTaskIfAny();
            }
        });

        if (!this.isAdmin && this.hasOrganisation()) {
            this.setState({ tab: "members" })
            this.scrollToSection("members")
        }

    };

    private handleImageSuggestClick = () => {
        this.setState({ showImageSuggestions: true });
    };

    private handleImageSuggestClose = () => {
        this.setState({ showImageSuggestions: false });
    };

    private handleImageSuggestConfirm = async (logoUrl: string | null) => {
        if (!logoUrl) {
            this.handleImageSuggestClose();
            return;
        }
        UtilsAPI.getFileFromUrl(logoUrl, 'filename')
            .then((file) => {
                if (file) {
                    this.handleSelectedFile(file!);
                }
            })
            .then(() => this.handleImageSuggestClose());
    };

    startImageSearchInterval = async(container: TeamsContainer) => {
        let i = 0;
        // this.setState({ loadingMessage: "Finding your logo" });
        let interval = setInterval(() => {
            let suggestions = document.querySelectorAll(".logo-suggestion img");
            if (suggestions && suggestions.length !== 0 && (suggestions[0] as HTMLElement)) {
                // @ts-ignore
                (suggestions[0] as HTMLElement).click();
                // setTimeout(() => {
                //     this.createOrganisation(container)
                // }, 100)
                clearInterval(interval);
                // this.setState({ loadingMessage: null });
            } else {
                i++
                if (i > 20) {
                    this.setState({
                        overrideImageSearch: null
                    })
                    clearInterval(interval);
                    // this.setState({ loadingMessage: null });
                    // toast.error('We couldn\'t find your logo, please upload manually.');
                }
            }
        }, 200);
    }

    render() {
        const activeMembers = this.state.members.filter((m) => !m.deleted);
        const archivedMembers = this.state.members.filter((m) => m.deleted);

        return (
            <Subscribe to={[TeamsContainer]}>
                {(teamsState: TeamsContainer) => (
                    <Wrapper
                        isAdmin={this.isAdmin}
                        style={{ backgroundColor: 'transparent' }}
                    >
                        <FullScreenLoader
                            loadingMessage={this.state.savingNewOrg ? "Creating Organization" : this.state.loadingMessage}
                        />
                        {/* <Tabs
                            activeKey={this.state.tab}
                            onSelect={(tab) => {
                                this.setState({ tab });
                            }}
                            id="organisation-tabs"
                            style={{
                                backgroundColor: 'white',
                                maxWidth: 860,
                                margin: 'auto',
                                display: 'block',
                                marginBottom: 86,
                                overflow: 'auto',
                                borderRadius: 20
                            }}
                        > */}
                            {!this.hasLoaded() && (
                                <div
                                    className="layout horizontal center-center"
                                    style={{ height: '100%' }}
                                >
                                    <Spinner />
                                </div>
                            )}
                            {!this.hasOrganisation() && this.hasLoaded() && (
                                <section 
                                    className='main'
                                    style={{
                                        overflow: 'auto'
                                    }}
                                >
                                    <div>
                                        <div className="main-page-header">
                                            <h2
                                                style={{
                                                    textAlign: 'center'
                                                }}
                                            >
                                                Set up your Organization
                                            </h2>
                                            <p
                                                style={{
                                                    textAlign: 'center',
                                                    maxWidth: '500px',
                                                    margin: '0 auto 20px auto'
                                                }}
                                            >
                                                So you can share contact’s availability, offers, locations, schedules & reporting between colleagues
                                            </p>
                                        </div>

                                        <div className="features">
                                            <div>
                                                <SvgTeams />
                                                <h3>Teams</h3>
                                                <p>Create Teams and invite colleagues to share contact availability, and offers</p>
                                            </div>
                                            <div>
                                                <SvgRota />
                                                <h3>Rota</h3>
                                                <p>Consolidated view of future & past staffing rotas, for all contacts & events</p>
                                            </div>
                                            <div>
                                                <SvgMap />
                                                <h3>Locations</h3>
                                                <p>Add different locations for offers for staff at alternative locations</p>
                                            </div>
                                        </div>

                                        {!this.hasOrganisation() && this.hasLoaded() && (
                                            <OrganisationAutoCompleteSuggestions
                                                profileFilledOut={this.profileFilledOut}
                                                onAutocomplete={(autoFillOrg: AutocompleteSuggestion) => {
                                                    let newFormData = this.state.newOrganisationFormData;
                                                    newFormData.isHirer = true;
                                                    if (autoFillOrg.sectors && autoFillOrg.sectors.length !== 0) {
                                                        newFormData.industries = autoFillOrg.sectors.map(item => { return { id: item.id, value: item.name || item.value || "" } })
                                                    }
                                                    if (autoFillOrg.sectors && autoFillOrg.sectors.length !== 0) {
                                                        newFormData.subSectors = Object.assign(autoFillOrg.sectors, {});
                                                    }
                                                    if (autoFillOrg.organizationName) {
                                                        newFormData.name = autoFillOrg.organizationName;
                                                    }

                                                    if (
                                                        autoFillOrg.locations && 
                                                        autoFillOrg.locations.find(location => location.type === LocationType.GOOGLE_PLACES) &&
                                                        autoFillOrg.locations.find(location => location.type === LocationType.FULL_ADDRESS) && 
                                                        autoFillOrg.locations.find(location => location.type === LocationType.GOOGLE_ADDRESS_COMPONENTS)
                                                    ) {
                                                        let locationGoogle = autoFillOrg.locations.find(location => location.type === LocationType.GOOGLE_PLACES);
                                                        let locationFullAddress = autoFillOrg.locations.find(location => location.type === LocationType.FULL_ADDRESS);
                                                        let locationComponents = autoFillOrg.locations.find(location => location.type === LocationType.GOOGLE_ADDRESS_COMPONENTS);
                                                        // @ts-ignore
                                                        newFormData.locationPlaceId = locationGoogle?.data?.id;
                                                        newFormData.locationPlaceName = "";
                                                        // @ts-ignore
                                                        newFormData.locationFullName = locationFullAddress?.data.fullAddress;
                                                        // @ts-ignore
                                                        newFormData.locationComponents = locationComponents?.data;
                                                    }
                                                    newFormData.metadata = autoFillOrg;
                                                    this.setState({
                                                        newOrganisationFormData: newFormData,
                                                        overrideImageSearch: autoFillOrg.website ? autoFillOrg.website.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "").split('/')[0] : autoFillOrg.organizationName
                                                    }, () => {
                                                        if (autoFillOrg.logoUrl) {
                                                            UtilsAPI.getFileFromUrl(
                                                                autoFillOrg.logoUrl,
                                                                'filename'
                                                            ).then(
                                                                (file) => {
                                                                    if (
                                                                        file
                                                                    ) {
                                                                        this.handleSelectedFile(
                                                                            file!
                                                                        );
                                                                    }
                                                                }
                                                            );
                                                        } else {
                                                            this.startImageSearchInterval(teamsState);
                                                        }
                                                    })
                                                }}
                                            />
                                        )}

                                        <div
                                            className="form-horizontal col-xs-12"
                                            style={{
                                                position: 'relative',
                                                marginTop: 10
                                            }}
                                        >
                                            {!this.profileFilledOut && !localStorage.getItem("org-autocomplete") && (
                                                <Link
                                                    to="/profile"
                                                    style={{
                                                        color: 'white',
                                                        position: 'absolute',
                                                        height: '100%',
                                                        width: '100%',
                                                        zIndex: 5,
                                                        top: 0,
                                                        left: 0,
                                                        opacity: 0.88,
                                                    }}
                                                >
                                                    <div
                                                        id="profileWarning"
                                                        style={{
                                                            borderRadius: '4px',
                                                            backgroundColor: '#f8d7da',
                                                            border: '#f5c6cb 1px solid',
                                                            padding: '12px 16px',
                                                            color: '#721c24',
                                                            height: "100%",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            textAlign: "center",
                                                            transform: "none",
                                                            fontSize: '1.2em'
                                                        }}
                                                    >
                                                        <div>
                                                            Please complete your{' '}
                                                            <strong>profile</strong> before
                                                            starting your organization
                                                            profile by uploading a profile
                                                            picture and a entering your
                                                            number
                                                            <button
                                                                className="styled filled"
                                                                style={{
                                                                    display: "block",
                                                                    margin: "16px auto 0 auto"
                                                                }}
                                                            >Complete Profile</button>
                                                        </div>
                                                    </div>
                                                </Link>
                                            )}
                                            <div className="form-group">
                                                <label
                                                    className="col-sm-3 control-label"
                                                    htmlFor="organisationName"
                                                >
                                                    Organization Name
                                                </label>
                                                <div className="col-sm-6">
                                                    <input
                                                        aria-label="organisationName"
                                                        id="organisationName"
                                                        className="form-control"
                                                        value={
                                                            this.state
                                                                .newOrganisationFormData
                                                                .name
                                                        }
                                                        onChange={({
                                                            target: { value }
                                                        }) => {
                                                            this.setState(
                                                                ({
                                                                    newOrganisationFormData
                                                                }) => ({
                                                                    newOrganisationFormData: {
                                                                        ...newOrganisationFormData,
                                                                        name: value
                                                                    }
                                                                })
                                                            );
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-sm-3 required-overlay">
                                                    * required
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className="col-sm-3 control-label">
                                                    Organisation Logo
                                                </label>
                                                <div className="col-sm-6">
                                                    {this.state
                                                        .newCompanyProfileImagePreviewUrl && (
                                                        <div className="row">
                                                            <ProfileImage
                                                                onSelect={() =>
                                                                    this.handleImageSuggestClick()
                                                                }
                                                                type="Organisation"
                                                                url={
                                                                    this.state
                                                                        .newCompanyProfileImagePreviewUrl ||
                                                                    ''
                                                                }
                                                                size={72}
                                                                selectable={true}
                                                                style={{
                                                                    display:
                                                                        'inline-flex',
                                                                    padding: '4px'
                                                                }}
                                                            />
                                                            <button
                                                                className="btn"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        newCompanyProfileImagePreviewUrl:
                                                                            ''
                                                                    });
                                                                }}
                                                                style={{
                                                                    display:
                                                                        'block',
                                                                    marginTop:
                                                                        '12px'
                                                                }}
                                                            >
                                                                Replace Logo
                                                            </button>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="col-sm-3 required-overlay">
                                                    * required
                                                </div>
                                            </div>
                                            {!this.state
                                                .newCompanyProfileImagePreviewUrl && (
                                                <div className="form-group">
                                                    <label
                                                        className="col-sm-3 control-label control-label-right"
                                                        style={{ fontWeight: 500 }}
                                                    >
                                                        Select a Suggestion
                                                    </label>
                                                    <div
                                                        className="col-sm-6"
                                                    >
                                                        {!this.state
                                                            .newOrganisationFormData
                                                            .name && (
                                                            <p
                                                                style={{
                                                                    fontWeight: 400,
                                                                    margin:
                                                                        '6px 0 0 0'
                                                                }}
                                                            >
                                                                Enter your
                                                                organization name to
                                                                see logo suggestions
                                                            </p>
                                                        )}
                                                        {this.state
                                                            .newOrganisationFormData
                                                            .name && (
                                                            <InlineOrganisationImageSuggestions
                                                                organisationName={this.state.overrideImageSearch || this.state.newOrganisationFormData.name}
                                                                onSelect={(url) => {
                                                                    UtilsAPI.getFileFromUrl(
                                                                        url,
                                                                        'filename'
                                                                    ).then(
                                                                        (file) => {
                                                                            if (
                                                                                file
                                                                            ) {
                                                                                this.handleSelectedFile(
                                                                                    file!
                                                                                );
                                                                            }
                                                                        }
                                                                    );
                                                                }}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                            {!this.state
                                                .newCompanyProfileImagePreviewUrl && (
                                                <div className="form-group center-aligned align-middle align-items-center vcenter-item">
                                                    <label
                                                        className="col-sm-3 control-label control-label-right"
                                                        htmlFor="organisationProfileImageFile"
                                                        style={{ fontWeight: 500 }}
                                                    >
                                                        <strong>or</strong> Upload
                                                        New Image
                                                    </label>
                                                    <div
                                                        className="col-sm-6"
                                                        style={{ margin: 'auto' }}
                                                    >
                                                        <div
                                                            style={{
                                                                display: 'none'
                                                            }}
                                                        >
                                                            <ManagedFieldInput
                                                                aria-label="uploadNewImage"
                                                                type="file"
                                                                name="organisationProfileImageFile"
                                                                id="organisationProfileImageFile"
                                                                className="form-control"
                                                                onClick={(e) => {
                                                                    e.target.value =
                                                                        '';
                                                                }}
                                                                onChange={(e) => {
                                                                    this.handleSelectedFiles(
                                                                        e.target
                                                                            .files
                                                                    );
                                                                }}
                                                                refresh={() => {
                                                                    this.loadOrganisation();
                                                                }}
                                                            />
                                                        </div>
                                                        <button
                                                            className="btn"
                                                            onClick={() => {
                                                                let el: HTMLElement = document.getElementById(
                                                                    'organisationProfileImageFile'
                                                                ) as HTMLElement;
                                                                if (el) el.click();
                                                            }}
                                                            style={{ background: 'whitesmoke' }}
                                                        >
                                                            Select from your device
                                                        </button>
                                                    </div>
                                                </div>
                                            )}
                                            <div className="form-group">
                                                <label className="col-sm-3 control-label">
                                                    Location address (delivery of services)
                                                </label>
                                                <div
                                                    className="col-sm-6"
                                                    style={{ marginTop: 7 }}
                                                >
                                                    <ManagedFieldInput
                                                        onClick={
                                                            this.handleLocationClick
                                                        }
                                                        aria-label="LocationInput"
                                                        type="text"
                                                        name="locationFullName"
                                                        readOnly={true}
                                                        id="organisationLocationManaged"
                                                        style={{
                                                            marginBottom: 8,
                                                            display: this.state
                                                                .newOrganisationFormData
                                                                .locationFullName
                                                                ? 'block'
                                                                : 'none'
                                                        }}
                                                        value={
                                                            this.state
                                                                .newOrganisationFormData
                                                                .locationFullName //TODO: MAY BE ORG NOT NEW ORG
                                                        }
                                                        className="form-control"
                                                    />
                                                    <input
                                                        aria-label="locationDetails"
                                                        id="locationDetails"
                                                        className="form-control"
                                                        placeholder='Extra Information E.g. Warehouse 3, Door 8, Floor 4'
                                                        value={
                                                            this.state
                                                                .newOrganisationFormData
                                                                .locationPlaceName
                                                        }
                                                        style={{
                                                            marginBottom: 8
                                                        }}
                                                        onChange={({
                                                            target: { value }
                                                        }) => {
                                                            this.setState(
                                                                ({
                                                                    newOrganisationFormData
                                                                }) => ({
                                                                    newOrganisationFormData: {
                                                                        ...newOrganisationFormData,
                                                                        locationPlaceName: value
                                                                    }
                                                                })
                                                            );
                                                        }}
                                                    />
                                                    <button
                                                        className="btn"
                                                        onClick={() => {
                                                            let el: HTMLElement = document.getElementById(
                                                                'organisationLocationManaged'
                                                            ) as HTMLElement;
                                                            if (el) el.click();
                                                        }}
                                                        style={{ background: 'whitesmoke' }}
                                                    >
                                                        {this.state
                                                            .newOrganisationFormData
                                                            .locationFullName
                                                            ? 'Replace Location'
                                                            : 'Pick Location'}
                                                    </button>
                                                </div>
                                                <div className="col-sm-3 required-overlay">
                                                    * required
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label
                                                    className="col-sm-3 control-label"
                                                    htmlFor="industry-select"
                                                >
                                                    Industries / Sectors
                                                </label>
                                                <div className="col-sm-6">
                                                    <IndustrySelector
                                                        enabled={true}
                                                        enableSuggestion={true}
                                                        industries={
                                                            this.state
                                                                .newOrganisationFormData
                                                                .industries
                                                        }
                                                        onSelected={(item) => {
                                                            this.setState(
                                                                (prevState) => ({
                                                                    ...prevState,
                                                                    newOrganisationFormData: {
                                                                        ...prevState.newOrganisationFormData,
                                                                        industries: [
                                                                            ...prevState
                                                                                .newOrganisationFormData
                                                                                .industries,
                                                                            item
                                                                        ],
                                                                        subSectors: [
                                                                            ...prevState.newOrganisationFormData.subSectors,
                                                                            {
                                                                                id: item.id,
                                                                                name: item.value,
                                                                                value: item.value,
                                                                                subSectors: []
                                                                            }
                                                                        ]
                                                                    }
                                                                })
                                                            );
                                                        }}
                                                        onRemoved={(id) => {
                                                            this.setState(
                                                                (prevState) => ({
                                                                    ...prevState,
                                                                    newOrganisationFormData: {
                                                                        ...prevState.newOrganisationFormData,
                                                                        industries: prevState.newOrganisationFormData.industries.filter(
                                                                            (
                                                                                industry
                                                                            ) =>
                                                                                id !=
                                                                                industry.id
                                                                        ),
                                                                        subSectors: prevState.newOrganisationFormData.subSectors.filter(item => item.id !== id)
                                                                    }
                                                                })
                                                            );
                                                        }}
                                                        searchPlacement="auto"
                                                    />
                                                </div>
                                                <div className="col-sm-3 required-overlay">
                                                    * required
                                                </div>
                                            </div>

                                            {this.state.newOrganisationFormData.subSectors && this.state.newOrganisationFormData.subSectors.map((sector, i) => {
                                                return (
                                                    <div
                                                        className="form-group"
                                                        key={sector.id}
                                                    >
                                                        <label
                                                            className="col-sm-3 control-label"
                                                            htmlFor="industry-select"
                                                        >

                                                        </label>
                                                        <div className="col-sm-6">
                                                            <label>Subsectors for {sector.name || sector.value}</label>
                                                            <IndustrySelector
                                                                enableSuggestion={true}
                                                                enabled={true}
                                                                sectorId={sector.id}
                                                                sectorName={sector.name || sector.value}
                                                                industries={
                                                                    sector.subSectors.map(item => { return { id: item.id, value: item.name || item.value || "", name: item.name || item.value || "" } })
                                                                }
                                                                onSelected={(item) => {
                                                                    let subSectors = this.state.newOrganisationFormData.subSectors;
                                                                    subSectors[i].subSectors.push({
                                                                        name: item.value || item.name || "",
                                                                        value: item.value || item.name || "",
                                                                        id: item.id
                                                                    });
                                                                    this.setState(
                                                                        (prevState) => ({
                                                                            ...prevState,
                                                                            newOrganisationFormData: {
                                                                                ...prevState.newOrganisationFormData,
                                                                                subSectors
                                                                            }
                                                                        })
                                                                    );
                                                                }}
                                                                onRemoved={(id) => {
                                                                    let subSectors = this.state.newOrganisationFormData.subSectors;
                                                                    subSectors[i].subSectors = subSectors[i].subSectors.filter(item => item.id !== id);
                                                                    this.setState(
                                                                        (prevState) => ({
                                                                            ...prevState,
                                                                            newOrganisationFormData: {
                                                                                ...prevState.newOrganisationFormData,
                                                                                subSectors
                                                                            }
                                                                        })
                                                                    );
                                                                }}
                                                                searchPlacement="auto"
                                                            />
                                                        </div>
                                                        <div className="col-sm-3 required-overlay">
                                                            <button
                                                                className="btn"
                                                                style={{
                                                                    color: 'black'
                                                                }}
                                                                onClick={() => {
                                                                    window.open('mailto:?body=Proposed subsector for ' + (sector.name || sector.value) + ':&subject=New Subsector')
                                                                }}
                                                            >
                                                                Suggest New
                                                            </button>
                                                        </div>
                                                    </div>
                                                )
                                            })}


                                            <div className="form-group">
                                                <label
                                                    className="col-sm-3 control-label"
                                                    htmlFor="industry-select"
                                                >
                                                    Organisation Type
                                                </label>
                                                <div className="col-sm-6">
                                                    <div className="checkbox-group">
                                                        <input
                                                            type="checkbox"
                                                            id="isAgency"
                                                            name="isAgency"
                                                            onChange={() => {
                                                                this.setState(
                                                                    ({
                                                                        newOrganisationFormData
                                                                    }) => ({
                                                                        newOrganisationFormData: {
                                                                            ...newOrganisationFormData,
                                                                            isAgency: !newOrganisationFormData.isAgency
                                                                        }
                                                                    })
                                                                );
                                                            }}
                                                            checked={
                                                                this.state
                                                                    .newOrganisationFormData
                                                                    .isAgency
                                                            }
                                                        />
                                                        <label
                                                            className="m-l-xs"
                                                            htmlFor="isAgency"
                                                        >
                                                            We are an Agency
                                                        </label>
                                                    </div>
                                                    <div className="checkbox-group">
                                                        <input
                                                            type="checkbox"
                                                            id="isHirer"
                                                            name="isHirer"
                                                            onChange={() => {
                                                                this.setState(
                                                                    ({
                                                                        newOrganisationFormData
                                                                    }) => ({
                                                                        newOrganisationFormData: {
                                                                            ...newOrganisationFormData,
                                                                            isHirer: !newOrganisationFormData.isHirer
                                                                        }
                                                                    })
                                                                );
                                                            }}
                                                            checked={
                                                                this.state
                                                                    .newOrganisationFormData
                                                                    .isHirer
                                                            }
                                                        />
                                                        <label
                                                            className="m-l-xs"
                                                            htmlFor="isHirer"
                                                        >
                                                            We are Hirers
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3 required-overlay">
                                                    * at least one is required
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <div className="col-sm-8 col-sm-offset-3">
                                                    <Button
                                                        aria-label="CreateButton"
                                                        bsStyle="success"
                                                        onClick={() => this.createOrganisation(teamsState) }
                                                    >
                                                        {this.state.savingNewOrg
                                                            ? 'Please wait...'
                                                            : 'Create Organization'}
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            )}

                            {this.hasOrganisation() && this.hasLoaded() && !this.state.editingDetails && (
                                <section className="main" data-section="profile" style={{ position: 'relative' }}>
                                    { (this.state.staleData) &&
                                        <div className="nonblocking-loading-wrapper">
                                            <CircularProgress style={{ width: 24, height: 24 }} />
                                            Fetching Latest
                                        </div>
                                    }
                                    <div
                                        className="org-hero"
                                        onClick={() => {
                                            if (this.isAdmin)
                                                this.setState({ editingDetails: true })
                                        }}
                                    >
                                        <div className="left">
                                            <ProfileImage
                                                type="Organisation"
                                                url={CompanyApi.getOrganisationProfileImageUrl(this.state.organisation?.id || '')}
                                                size={96}
                                                selectable={false}
                                                style={{
                                                    border: 'none',
                                                    borderRadius: '100%',
                                                    padding: 0
                                                }}
                                            />
                                            <div>
                                                <h2>{this.state.organisation?.name}</h2>
                                                <p>{
                                                    (this.state.organisation?.isHirer && this.state.organisation?.isAgency) ? "Hirer & Agency"
                                                    : this.state.organisation?.isHirer ? "Hirer"
                                                    : "Agency"
                                                }</p>
                                                { (this.isAdmin) &&
                                                    <div className="verification">
                                                        <ShieldWrapper
                                                            className="layout horizontal center"
                                                            style={{ padding: '0' }}
                                                        >
                                                            <i
                                                                className={
                                                                    'fa fa-shield ' +
                                                                    (this.state.organisation?.verified
                                                                        ? 'checked'
                                                                        : '')
                                                                }
                                                            />
                                                            <span>
                                                                {this.state.organisation?.verified
                                                                    ? 'Verified'
                                                                    : 'Unverified'}
                                                            </span>
                                                            {!this.state.organisation?.verified && (
                                                                <Button
                                                                    bsStyle="primary"
                                                                    bsSize="xs"
                                                                    className="m-l"
                                                                    onClick={() => {
                                                                        setTimeout(() => {
                                                                            let el = document.getElementById("verify-now-btn");
                                                                            if (el)
                                                                                el.click();
                                                                        }, 300)
                                                                    }}
                                                                >
                                                                    Verify now
                                                                </Button>
                                                            )}
                                                        </ShieldWrapper>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div className="right">
                                            { this.isAdmin && !this.state.staleData &&
                                                <button>Edit Details</button>
                                            }
                                        </div>
                                    </div>
                                </section>
                            )}
    
                            {this.isAdmin && this.hasOrganisation() && this.hasLoaded() && this.state.editingDetails && (
                                    <section data-section="profile" className="main clearfix">
                                        <div className="section-header">
                                            <h2>Edit Organization Details</h2>
                                        </div>
                                        <OrganisationProfileEditor
                                            organisation={
                                                this.state.organisation!
                                            }
                                            handleLocationPicked={
                                                this.handleLocationPicked
                                            }
                                            onClose={() => {
                                                this.setState({
                                                    editingDetails: false
                                                })
                                            }}
                                        />
                                    </section>
                                )}

                            {this.hasOrganisation() && this.hasLoaded() && this.isAdmin && this.state.recommendations.length !== 0 && (
                                <section className="main">
                                    <div className="section-header">
                                        <h2>Recommendations</h2>
                                    </div>
                                    <div className="section-content">
                                        <div className="recommendations">
                                            { (this.state.recommendations.indexOf("members") !== -1) &&
                                                <div className="card" onClick={() => {
                                                    this.setState({ hintedElement: 'invite-colleague-btn' })
                                                    let el = document.querySelector('section[data-section="members"]');
                                                    if (el) {
                                                        el.scrollIntoView({
                                                            behavior: 'smooth',
                                                            block: 'start'
                                                        });
                                                    }
                                                }}>
                                                    <div className="card-header">
                                                        <div style={{ background: theme.colours.blue2 }}>
                                                            <i className='fas fa-users' />
                                                        </div>
                                                        <div>
                                                            <h4>Invite colleagues</h4>
                                                            <p>It only takes 10 seconds</p>
                                                        </div>
                                                    </div>
                                                    <div className="card-content">
                                                        <p>To share contact’s availability, offers, reports & rotas</p>
                                                    </div>
                                                </div>
                                            }
                                            { (this.state.recommendations.indexOf("locations") !== -1) &&
                                                <div className="card" onClick={() => {
                                                    this.setState({ hintedElement: 'try-locations-btn' })
                                                    let el = document.querySelector('section[data-section="locations"]');
                                                    if (el) {
                                                        el.scrollIntoView({
                                                            behavior: 'smooth',
                                                            block: 'start'
                                                        });
                                                    }
                                                }}>
                                                    <div className="card-header">
                                                        <div style={{ background: theme.colours.green2 }}>
                                                            <i className="fas fa-location-arrow" />
                                                        </div>
                                                        <div>
                                                            <h4>Add more locations</h4>
                                                            <p>It only takes 30 seconds</p>
                                                        </div>
                                                    </div>
                                                    <div className="card-content">
                                                        <p>For offers at alternative locations to your main.</p>
                                                    </div>
                                                </div>
                                            }
                                            { (this.state.recommendations.indexOf("rota") !== -1) &&
                                                <div className="card" onClick={() => {
                                                    this.setState({ hintedElement: 'rota-subscription' })
                                                    let el = document.querySelector('.subscription[data-type="rota"]');
                                                    if (el) {
                                                        el.scrollIntoView({
                                                            behavior: 'smooth',
                                                            block: 'start'
                                                        });
                                                    }
                                                }}>
                                                    <div className="card-header">
                                                        <div style={{ background: theme.colours.orange }}>
                                                            <i className="far fa-calendar" />
                                                        </div>
                                                        <div>
                                                            <h4>Try Pro Schedule</h4>
                                                            <p>It only takes 15 seconds</p>
                                                        </div>
                                                    </div>
                                                    <div className="card-content">
                                                        <p>Extend the free 7 day view to any, month, week, day, past & future events.</p>
                                                    </div>
                                                </div>
                                            }
                                            { (this.state.recommendations.indexOf("teams") !== -1) &&
                                                <div className="card" onClick={() => {
                                                    let el = document.getElementById('add-new-team-btn');
                                                    if (el) {
                                                        el.click();
                                                        el.scrollIntoView({
                                                            behavior: 'smooth',
                                                            block: 'start'
                                                        });
                                                    }
                                                }}>
                                                    <div className="card-header">
                                                        <div style={{ background: theme.colours.blue2 }}>
                                                            <i className="far fa-calendar" />
                                                        </div>
                                                        <div>
                                                            <h4>Create Teams</h4>
                                                            <p>It only takes 15 seconds</p>
                                                        </div>
                                                    </div>
                                                    <div className="card-content">
                                                        <p>Add colleagues into separate Teams to share relevant grouped contacts</p>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </section>
                            )}

                            {this.hasOrganisation() && this.hasLoaded() && (
                                <section data-section="members" className="main">
                                    <div className="section-header">
                                        <h2>Teams & Colleagues</h2>
                                        { this.isAdmin &&
                                            <p>Create Teams to share only relevant contact’s availability & offers between colleagues within specified Teams</p>
                                        }
                                        { !this.isAdmin &&
                                            <p>Your colleagues are listed below, to invite more please contact a colleague who has admin rights as shown below</p>
                                        }
                                        { this.isAdmin &&
                                            <div className="options">
                                                <button
                                                    onClick={() => {
                                                        if ((!this.props.userIsVerified || !this.state.organisation!.verified) && activeMembers.length > 2) {
                                                            alert("You can send up to 2 invitiations with an unverified organization account.")
                                                        } else {
                                                            this.setState({
                                                                showInviteModal: true
                                                            })
                                                        }
                                                        this.setState({
                                                            hintedElement: null
                                                        })
                                                    }}
                                                    id="invite-colleague-btn"
                                                    className={this.state.hintedElement === 'invite-colleague-btn' ? 'pulse no-size' : ''}
                                                >
                                                    Invite Colleague</button>
                                                <button onClick={() => {
                                                    let el = document.getElementById('add-new-team-btn');
                                                    if (el) {
                                                        el.click();
                                                        
                                                        setTimeout(() => {
                                                            let elNewSub = document.querySelector('.subscription[data-type="team"][data-team-id="0"]');
                                                            if (elNewSub) {
                                                                elNewSub.scrollIntoView({
                                                                    behavior: 'smooth',
                                                                    block: 'start'
                                                                });
                                                            }
                                                        }, 300)

                                                    }
                                                }}>Create a new team</button>
                                            </div>
                                        }
                                    </div>
                                    <div className="section-content">
                                        { this.state.members.find(member => (member as OrganisationUser).organisationMembershipStatus === OrganisationMemberStatus.Suggested) &&
                                            <div className="group no-group">
                                                <h3>Colleague Suggestions</h3>
                                                { this.renderMembers(teamsState, this.state.members.filter(member => (member as OrganisationUser).organisationMembershipStatus === OrganisationMemberStatus.Suggested), true) }
                                            </div>
                                        }
                                        { this.state.members.find(member => teamsState.state.teams.filter(team => team.teamMembers.map(teamMember => teamMember.contactId).indexOf(member.contactId) !== -1).length === 0) &&
                                            <div className="group no-group">
                                                <h3>Allocate these colleagues into relevant Teams</h3>
                                                { this.state.members.map((member) => {
                                                    let membersIn = teamsState.state.teams.filter(team => team.teamMembers.map(teamMember => teamMember.contactId).indexOf(member.contactId) !== -1);
                                                    if (membersIn.length !== 0) {
                                                        return null;
                                                    }
                                                    return this.renderMembers(teamsState, [member]);
                                                }) }
                                            </div>
                                        }
                                        { teamsState.state.teams.map(team => {
                                            return (
                                                <div key={team.id} data-name={team.name} className="group">
                                                    <h3>{team.name}</h3>
                                                    { (this.isAdmin) &&
                                                        <div className="options">
                                                            <button 
                                                                onClick={() => {
                                                                    this.setState({ 
                                                                        selectedTeamId: team.id,
                                                                        tempMemberContactIds: team.teamMembers.map(
                                                                            ({ contactId }) => contactId
                                                                        )
                                                                    })
                                                                }}
                                                                className="edit-members-btn"
                                                            >Add / remove members</button>
                                                            <button onClick={() => {
                                                                this.setState({ hintedElement: 'team-subscription-' + team.id })
                                                                let el = document.querySelector('.subscription[data-type="team"][data-team-id="' + team.id + '"]');
                                                                if (el) {
                                                                    el.scrollIntoView({
                                                                        behavior: 'smooth',
                                                                        block: 'start'
                                                                    });
                                                                }
                                                            }}>Adjust Capacity / Details</button>
                                                        </div>
                                                    }
                                                    {/* @ts-ignore */}
                                                    { this.renderMembers(teamsState, team.teamMembers.map(teamMember => {
                                                        return this.state.members.find(orgMember => orgMember.contactId === teamMember.contactId)
                                                    })) }
                                                </div>
                                            )
                                        }) }
                                        <input
                                            type="file"
                                            name="userProfileImageFileOrg"
                                            id="userProfileImageFileOrg"
                                            className="form-control"
                                            style={{ marginTop: '0.5rem', display: 'none' }}
                                            onChange={(e) =>
                                                this.handleselectedPhoto(
                                                    e.target.files
                                                )
                                            }
                                        />
                                    </div>
                                </section>
                            )}

                            {this.hasOrganisation() && this.hasLoaded() && this.isAdmin && (
                                <Locations
                                    isAdmin={this.isAdmin}
                                    navigateToSubscriptions={() => {
                                        this.setTab('subscriptions');
                                    }}
                                    setTab={this.setTab}
                                    hintedElement={this.state.hintedElement}
                                    bringToSubscriptions={() => {
                                        this.setState({ hintedElement: 'location-subscription' })
                                        let el = document.querySelector('.subscription[data-type="locations"]');
                                        if (el) {
                                            el.scrollIntoView({
                                                behavior: 'smooth',
                                                block: 'start'
                                            });
                                        }
                                    }}
                                    onCreate={() => {
                                        this.loadOrganisation();
                                    }}
                                />
                            )}












               

                        {this.isAdmin &&
                            this.hasOrganisation() &&
                            this.hasLoaded() && (
                                <ManageSubscriptionsV2 
                                    url={window.location.pathname} 
                                    hintedElement={this.state.hintedElement}
                                    resetHintedElement={() => { this.setState({ hintedElement: null }) }}
                                    updateHintedElement={(newHintedElement) => { 
                                        this.setState({ hintedElement: newHintedElement }) 
                                        if (newHintedElement === "team-subscription-0") {
                                            let el = document.querySelector('.subscription[data-type="team"][data-team-id="0"]');
                                            if (el) {
                                                el.scrollIntoView({
                                                    behavior: 'smooth',
                                                    block: 'start'
                                                });
                                            }
                                        }
                                    }}
                                    onCreate={() => {
                                        this.loadOrganisation();
                                        teamsState.getTeams();
                                    }}
                                />
                            )}

                        <LocationPicker
                            allowFriendlyName={false}
                            show={this.state.showLocationPicker}
                            onHide={this.handleLocationClose}
                            onChange={this.handleLocationChange}
                            placeId={
                                /*this.state.newOrganisationFormData
                                    .locationPlaceId*/null
                            }
                        />
                        <OrganisationImageSuggestions
                            organisationName={
                                this.state.newOrganisationFormData.name
                            }
                            show={this.state.showImageSuggestions}
                            onHide={this.handleImageSuggestClose.bind(this)}
                            onConfirm={this.handleImageSuggestConfirm.bind(
                                this
                            )}
                            handleSelectedFiles={this.handleSelectedFiles.bind(
                                this
                            )}
                            currentImageUrl={
                                this.state.newCompanyProfileImagePreviewUrl ||
                                ''
                            }
                        />
                        {this.renderRoleModal()}
                        {this.renderInviteModal(teamsState)}
                        {this.renderSwapModal()}
                        {this.renderMembersModal(teamsState.state.teams.find(
                            ({ id }) => id === this.state.selectedTeamId
                        ) || null, teamsState)}
                    </Wrapper>
                )}
            </Subscribe>
        );
    }

    renderMembers(container: TeamsContainer, members: UserDto[], renderPending?: boolean) {
        if (!members || members.length === 0) {
            return (
                <div>
                    There are no members in this team. { this.isAdmin && 'Use the "Add / remove members" button to manage members.' }
                </div>
            )
        }
        return members.map((m) => {
            if (!m) return null;
            if (!renderPending && (m as OrganisationUser).organisationMembershipStatus === 3) return null;
            return (
            <MemberRow key={m.email} className="layout horizontal center member-row">
                {this.renderMemberImage(m)}
                <div className="member-name-wrapper layout vertical center-justified flex">
                    <span className="member-name" data-name={m.firstName + " " + m.lastName}>
                        {m.firstName} {m.lastName}
                    </span>
                    {m.id ? <div>{m.email}</div> : <div>{m.email} (Pending)</div>}
                </div>
                {m.deleted ? (
                    <>
                        {this.isAdmin && (
                            <SimpleTooltip
                                id="activate-member"
                                text={this.renderActivePopover(
                                    `${m.firstName} ${m.lastName}`,
                                    true
                                )}
                            >
                                <i
                                    className="fa fa-user-plus fa-hover"
                                    onClick={() =>
                                        this.handleMemberActiveStatus(m, true)
                                    }
                                />
                            </SimpleTooltip>
                        )}
                    </>
                ) : (
                    <>
                        {/* { this.renderTeamsPopover(container, m)} */}
                        { (this.isAdmin && (m as OrganisationUser).organisationMembershipStatus !== OrganisationMemberStatus.Suggested) && <PopoverSelect
                            onClick={() => {
                                if (this.isAdmin)
                                    this.setState({ memberRoleEdit: m });
                            }}
                            hoverable={this.isAdmin}
                        >
                            <span>{CompanyRoles[m.roleId]}</span>
                            {this.isAdmin && (
                                <i className="fas fa-ellipsis-h" />
                            )}
                        </PopoverSelect>}
                        {this.isAdmin && (m as OrganisationUser).organisationMembershipStatus === OrganisationMemberStatus.Suggested && (
                            <React.Fragment>
                                <button
                                    style={{
                                        background: '#62cb31',
                                        color: 'white'
                                    }}
                                    onClick={ async () => {
                                        await OrganisationAPI.respondToInviteSuggestion(this.state.organisation?.id || "", m.contactId, false);
                                        Notification.actionCreators.display(
                                            ToastType.SUCCESS,
                                            'Invite Accepted'
                                        );
                                        this.loadOrganisation();
                                    }}
                                >
                                    Accept
                                </button>
                                <button
                                    style={{
                                        background: '#e74c3c',
                                        color: 'white'
                                    }}
                                    onClick={ async () => {
                                        await OrganisationAPI.respondToInviteSuggestion(this.state.organisation?.id || "", m.contactId, true);
                                        Notification.actionCreators.display(
                                            ToastType.SUCCESS,
                                            'Invite Rejected'
                                        );
                                        this.loadOrganisation();
                                    }}
                                >Reject</button>
                            </React.Fragment>
                        )}
                        {this.isAdmin && (m as OrganisationUser).organisationMembershipStatus !== OrganisationMemberStatus.Suggested && (
                            <React.Fragment>
                                <SimpleTooltip
                                    id="archive-member"
                                    text={this.renderActivePopover(
                                        `${m.firstName} ${m.lastName}`,
                                        false
                                    )}
                                >
                                    <button
                                        disabled={this.memberIsSelf(m)}
                                        aria-label="archive-button"
                                        className="archive-button"
                                        onClick={() =>
                                            this.handleMemberActiveStatus(m, false)
                                        }
                                    >
                                        <i
                                            className="fa fa-user-times"
                                            aria-label="fa-user-times"
                                        />
                                        Delete
                                    </button>
                                </SimpleTooltip>
                                { (m.id) &&
                                    <SimpleTooltip
                                        id="upload-photo"
                                        text={<p>Upload a profile picture for {m.firstName} {m.lastName}</p>}
                                    >
                                        <button
                                            disabled={this.memberIsSelf(m)}
                                            aria-label="upload-photo"
                                            className="upload-photo"
                                            onClick={() =>
                                                this.handleProfilePictureUpload(m)
                                            }
                                        >
                                            <i
                                                className="fas fa-images"
                                                aria-label="fa-user-times"
                                            />
                                            Update Image
                                        </button>
                                    </SimpleTooltip>
                                }
                            </React.Fragment>
                        )}
                    </>
                )}
            </MemberRow>
        )});
    }

    renderMemberImage(member: UserDto) {
        return member.id ? (
            <ProfileImage
                selectable={false}
                size={40}
                url={ProfileApi.getProfileImageUrl(member.id)}
                style={{
                    padding: 0,
                    border: 'none'
                }}
            />
        ) : (
            <Initial from={member.email.substr(0, 1)} />
        );
    }

    handleProfilePictureUpload = async(
        member: UserDto
    ) => {
        this.setState({
            selectedMemberForPhotoUpload: member
        })
        document.getElementById("userProfileImageFileOrg")?.click();   
    }

    async handleselectedPhoto(files: FileList | null) {
        if (files !== null) {
            if (0 > supportedImageFormats.indexOf(files[0].type)) {
                Notification.actionCreators.display(
                    ToastType.ERROR,
                    'Please select a jpg, png or gif file.'
                );
            } else {
                const newProfileImage: File | null = await Utilities.resizeAndRotateImage(
                    files[0] as Blob
                );

                if (newProfileImage !== null) {
                    ProfileApi
                        .updateProfileImageOnBehalfOf(newProfileImage, this.state.selectedMemberForPhotoUpload!.id)
                        .then(() => {
                            Utilities.refreshImageCache();
                            this.loadOrganisation();
                        }).catch(e => {
                            Notification.actionCreators.display(
                                ToastType.ERROR,
                                'Failed to upload Profile Picture'
                            );
                        });
                }

                Analytics.trackEvent('user-profile-image-selected');
            }
        }
    }

    renderRoleModal() {
        const onHide = () => {
            this.setState({ memberRoleEdit: undefined });
        };

        const onChange = async (roleId: CompanyRoles) => {
            const email = this.state.memberRoleEdit!.email;

            InternalTracker.trackEvent("", {
                category: 'Teams',
                action: 'Member Role Changed'
            });

            this.setState({
                members: this.state.members.map((m) => {
                    if (m.email == email) {
                        return { ...m, roleId };
                    }

                    return m;
                }),
                memberRoleEdit: undefined
            });

            await CompanyApi.setRole(email, roleId);
        };

        return (
            <Modal
                show={!!this.state.memberRoleEdit}
                onHide={onHide}
                bsSize="small"
                dialogClassName="modal-dialog-centered"
            >
                <Modal.Body>
                    {this.state.memberRoleEdit && (
                        <RolePopoverContent>
                            <div>
                                <span>Change Role</span>
                                <i
                                    className="fas fa-times fa-hover"
                                    style={{
                                        position: 'absolute',
                                        right: '8px',
                                        top: '7px'
                                    }}
                                    onClick={onHide}
                                />
                            </div>
                            {this.memberIsSelf(this.state.memberRoleEdit) ? (
                                <div className="own">
                                    You cannot change your own role
                                </div>
                            ) : (
                                <>
                                    <div
                                        className="option"
                                        onClick={() =>
                                            onChange(CompanyRoles.Normal)
                                        }
                                    >
                                        <div>
                                            <span>Normal</span>
                                            {this.state.memberRoleEdit.roleId ==
                                                CompanyRoles.Normal && (
                                                <i className="fas fa-check" />
                                            )}
                                        </div>
                                        <p>
                                            Can view the availability of their
                                            contacts and send offers
                                        </p>
                                    </div>
                                    <div
                                        className="option"
                                        onClick={() =>
                                            onChange(CompanyRoles.Admin)
                                        }
                                    >
                                        <div>
                                            <span>Admin</span>
                                            {this.state.memberRoleEdit.roleId ==
                                                CompanyRoles.Admin && (
                                                <i className="fas fa-check" />
                                            )}
                                        </div>
                                        <p>
                                            Can also edit organization profile,
                                            and manage members and teams
                                        </p>
                                    </div>
                                </>
                            )}
                        </RolePopoverContent>
                    )}
                </Modal.Body>
            </Modal>
        );
    }

    renderInviteModal(teamsState: TeamsContainer) {
        let valid = RegularExpressions.emailAddress.test(
            this.state.inviteEmail
        );

        let currentUser = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user") || "") : {};
        if (currentUser && currentUser.emailAddress === this.state.inviteEmail) {
            valid = false;
        }

        return (
            <Modal
                show={this.state.showInviteModal}
                onHide={this.closeInviteModal}
                bsSize="sm"
                dialogClassName="modal-dialog-centered"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Invite Colleague</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <InviteModalContent>
                        <div>
                            <label htmlFor="inviteEmail">
                                Invite a colleague to join your organization
                                and start receiving availability and sending
                                offers
                            </label>
                            <input
                                id="inviteEmail"
                                className="form-control"
                                type="email"
                                value={this.state.inviteEmail}
                                onChange={(ev) =>
                                    this.setState({
                                        inviteEmail: ev.target.value
                                    })
                                }
                                placeholder={`e.g. colleague@updatedge.com`}
                            />
                            {this.state.inviteEmail && !valid && (
                                <div className="invalid">
                                    Please enter a valid email
                                </div>
                            )}
                            <div
                                style={{
                                    textAlign: 'center',
                                    marginTop: '5px'
                                }}
                            >
                                <Button
                                    bsSize="sm"
                                    bsStyle="success"
                                    disabled={!this.state.inviteEmail || !valid}
                                    onClick={() => this.inviteMemberToOrganisation(teamsState) }
                                >
                                    Send Invite
                                </Button>
                            </div>
                        </div>
                    </InviteModalContent>
                </Modal.Body>
            </Modal>
        );
    }

    renderSwapModal = () => {
        const onHide = () => {
            this.setState({
                swapContactsFrom: undefined,
                swapContactsToUserId: undefined
            });
        };

        return (
            <Modal
                show={!!this.state.swapContactsFrom}
                onHide={onHide}
                bsSize="sm"
                dialogClassName="modal-dialog-centered"
            >
                <Modal.Body>
                    {this.state.swapContactsFrom && (
                        <SwapModalContent>
                            <h4>
                                "{this.state.swapContactsFrom.firstName}{' '}
                                {this.state.swapContactsFrom.lastName}" cannot
                                be archived as they have contacts.
                            </h4>
                            <span>
                                Select a member to swap their contacts to:
                            </span>
                            <div>
                                <Table striped hover>
                                    <tbody>
                                        {this.state.members
                                            .filter(
                                                (m) =>
                                                    !m.deleted &&
                                                    m.id &&
                                                    m.id !=
                                                        this.state
                                                            .swapContactsFrom!
                                                            .id
                                            )
                                            .map((m) => (
                                                <tr
                                                    data-testid={`swap-${m.id}`}
                                                    key={`swap-${m.id}`}
                                                    className={
                                                        this.state
                                                            .swapContactsToUserId ==
                                                        m.id
                                                            ? 'selected'
                                                            : ''
                                                    }
                                                    onClick={() =>
                                                        this.setState({
                                                            swapContactsToUserId:
                                                                this.state
                                                                    .swapContactsToUserId ==
                                                                m.id
                                                                    ? undefined
                                                                    : m.id!
                                                        })
                                                    }
                                                >
                                                    <td>
                                                        {this.renderMemberImage(
                                                            m
                                                        )}
                                                    </td>
                                                    <td
                                                        style={{
                                                            textAlign: 'left'
                                                        }}
                                                    >
                                                        {m.firstName}{' '}
                                                        {m.lastName}
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </Table>
                            </div>
                            <Button
                                bsStyle="success"
                                disabled={!this.state.swapContactsToUserId}
                                onClick={() =>
                                    this.handleMemberActiveStatus(
                                        this.state.swapContactsFrom!,
                                        false,
                                        this.state.swapContactsToUserId
                                    )
                                }
                            >
                                Swap Contacts
                            </Button>
                        </SwapModalContent>
                    )}
                </Modal.Body>
            </Modal>
        );
    };

    renderActivePopover = (name: string, activate: boolean) => (
        <div>
            {activate ? 'Activate' : 'Archive'} {name}
            <br />
            <div
                style={{
                    textAlign: 'left',
                    fontSize: '10.5px',
                    marginTop: '5px'
                }}
            >
                {activate
                    ? 'They will be able to use updatedge within this organization.'
                    : 'They will no longer be able to use updatedge.'}
                <br />
                <strong
                    style={{
                        marginTop: '5px',
                        display: 'block',
                        width: '100%',
                        textAlign: 'center'
                    }}
                >
                    Are you certain?
                </strong>
            </div>
        </div>
    );

    renderTeamsPopover = (container: TeamsContainer, member: UserDto) => {
        const allTeams = container.state.teams;
        const membersTeams = allTeams.filter(
            (team) =>
                ~team.teamMembers
                    .map(({ contactId }) => contactId)
                    .indexOf(member.contactId)
        );

        const popover = (
            <Popover id="teams-popover">
                <TeamsPopoverContent>
                    <h4>Teams</h4>
                    {membersTeams.map((t) => (
                        <div key={t.id}>"{t.name}"</div>
                    ))}
                    {!membersTeams.length && (
                        <div style={{ fontStyle: 'italic' }}>
                            Not in any teams
                        </div>
                    )}
                </TeamsPopoverContent>
            </Popover>
        );

        return (
            <OverlayTrigger
                trigger="click"
                placement="top"
                overlay={popover}
                rootClose={true}
            >
                <PopoverSelect style={{ marginRight: '10px' }} hoverable={true}>
                    <span style={{ width: '75px' }}>
                        In {membersTeams.length} team
                        {membersTeams.length != 1 ? 's' : ''}
                    </span>
                    <i className="fas fa-ellipsis-h" />
                </PopoverSelect>
            </OverlayTrigger>
        );
    };

    getInitialTab = (): OrganisationTab => {
        if (!this.hasOrganisation()) {
            return 'new';
        }

        const locationStateTab = getFromLocationState('initialTab');
        removeFromLocationState('initialTab');

        /**
         * Manually checking here ensures type-safety
         * The cost here is that logic has to be added for each tab
         * TODO: Potentially replace OrganisationTab with enum
         * to see if we can get best of both worlds
         */
        if (locationStateTab === 'locations') return 'locations';
        if (locationStateTab === 'teams') return 'teams';

        return 'profile';
    };

    setTab = (tab: OrganisationTab, subsection?: string) => {
        this.setState({ tab });
        this.scrollToSection(tab, subsection);
    };

    private scrollToSection(tab: OrganisationTab, subsection?: string) {
        let i = 0;
        let interv = setInterval(() => {
            i++;
            let section = document.querySelector("section[data-section='" + tab + "']" + (subsection ? (" #" + tab + "-" + subsection) : ""));
            console.log("SCROLL TO ", section)
            if (section) {
                clearInterval(interv);
                setTimeout(() => {
                    // @ts-ignore
                    section.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                        inline: 'start'
                    });
                }, 300)
            } else if (i === 20) {
                clearInterval(interv);
            }
        }, 200);
    }

    private handleLocationClick = () => {
        this.setState({ showLocationPicker: true });
        Analytics.trackEvent('company-profile-location-opened');
    };

    private handleLocationClose = () => {
        this.setState({ showLocationPicker: false });
        Analytics.trackEvent('company-profile-location-closed');
    };

    private handleLocationChange = (
        placeId: string,
        placeName: string,
        placeComponents: google.maps.places.PlaceResult['address_components'],
        friendlyName,
        friendlyAddress
    ) => {
        this.handleLocationPicked(
            placeId,
            friendlyName,
            friendlyAddress,
            placeComponents
        );
        Analytics.trackEvent('company-profile-location-changed');
    };

    handleLocationPicked = (
        locationPlaceId: string,
        locationPlaceName: string,
        locationFullName: string,
        locationComponents: google.maps.places.PlaceResult['address_components']
    ) => {
        this.setState({
            newOrganisationFormData: {
                ...this.state.newOrganisationFormData,
                locationPlaceId: locationPlaceId,
                locationPlaceName: locationPlaceName,
                locationFullName: locationFullName,
                locationComponents: locationComponents
            }
        })
    };

    handleMemberActiveStatus = async (
        member: UserDto,
        active: boolean,
        swapWith?: string | undefined
    ) => {
        if (swapWith) {
            let success = true;
            await VerificationAPI.swapContacts(member.id, swapWith).catch(e => {
                success = false;
            })
            if (!success) {
                this.setState({
                    swapContactsFrom: member,
                    swapContactsToUserId: undefined
                });
                return;
            }
        }

        const success = await CompanyApi.setMemberActive(
            member.email,
            active,
            swapWith
        );

        if (success && active) {
            toast.success('Organization member added');
            InternalTracker.trackEvent("", {
                category: 'Teams',
                action: 'Member Added'
            });
        }

        if (success && !active) {
            toast.success('Organization member removed');
            InternalTracker.trackEvent("", {
                category: 'Teams',
                action: 'Member Removed'
            });
        }

        if (!active && !success) {
            this.setState({
                swapContactsFrom: member,
                swapContactsToUserId: undefined
            });
            this.loadOrganisation();
            return;
        }

        this.setState({
            members: this.state.members.map((m) => {
                if (m.email == member.email) {
                    return { ...m, deleted: !active };
                }

                return m;
            }),
            swapContactsFrom: undefined,
            swapContactsToUserId: undefined
        }, () => {
            this.loadOrganisation();
        });
    };

    public getFileFromUrl(
        url: string,
        organisationId: string
    ): Promise<File | void> {
        return fetch(url)
            .then((res) => res.blob())
            .then((blob: Blob) => {
                let b: any = blob;
                b.lastModifiedDate = new Date();
                b.name = organisationId;
                return b as File;
            })
            .then((f: File) => {
                return f;
            })
            .catch((e: Error) => {
                Notification.actionCreators.display(
                    ToastType.ERROR,
                    'Could not get file associated with image URL.'
                );
            });
    }

    private createOrganisation = async (container: TeamsContainer) => {
        let organisation = Object.assign(this.state.newOrganisationFormData, {});

        // if (!this.props.userSetProfileImage || !this.props.phoneNumber) {
        //     toast.error(
        //         'Please set a profile image and phone number on the profile page before creating an organisation.'
        //     );
        //     return;
        // }

        // if (!this.props.phoneNumber) {
        //     toast.error('Please set a phone number before creating an organization.');
        //     return;
        // }

        // if (!this.props.headline) {
        //     toast.error('Please set a headline before creating an organization.');
        //     return;
        // }

        if (organisation.name.trim() === '') {
            toast.error('Please enter an organization name.');
            return;
        }

        if (organisation.industries.length === 0) {
            toast.error('Please select a sector.');
            return;
        }
        if (!organisation.locationPlaceId) {
            toast.error('Please remember to select a location.');
            return;
        }
        if (
            !organisation.imageUrl &&
            !this.state.newCompanyProfileImagePreviewUrl
        ) {
            toast.error('Please remember to add a logo.');
            return;
        }

        if (
            !this.state.newOrganisationFormData.isHirer &&
            !this.state.newOrganisationFormData.isAgency
        ) {
            toast.error("Please select whether you're a hirer or an agency.");
            return;
        }

        if (this.state.newCompanyProfileImage!.size > 10000000) {
            toast.error('Please select an image less than 10mb.');
            return;
        }

        if (this.state.savingNewOrg) {
            return;
        }

        InternalTracker.trackEvent("", {
            category: 'Organisation',
            action: 'Organisation Created'
        });

        this.setState({ savingNewOrg: true });

        await OrganisationAPI.createOrganisation({
            ...organisation,
            industries: organisation.subSectors
        }).then(
            (organisationId) => {
                if (this.state.newCompanyProfileImage != null) {
                    this.props.updateOrganisationProfileImage(
                        this.state.newCompanyProfileImage!,
                        () => {
                            this.updateProfileState(
                                organisation,
                                organisationId
                            );
                            this.props.updateOrganisationLocation(
                                this.state.newOrganisationFormData
                                    .locationPlaceId,
                                this.state.newOrganisationFormData
                                    .locationFullName,
                                this.state.newOrganisationFormData
                                    .locationPlaceName,
                                this.state.newOrganisationFormData
                                    .locationComponents!
                            );
                        }
                    );
                    Analytics.trackEvent('company-profile-photo-saved');
                    container.getTeams();
                    this.setState({ tab: "members" });
                    this.scrollToSection("members")
                } else {
                    this.updateProfileState(organisation, organisationId);
                    container.getTeams();
                    this.setState({ tab: "members" })
                    this.scrollToSection("members")
                }
            }
        ).catch(e => {
            if (e.title === 'Duplicate Resource') {
                Notifications.actionCreators.display(ToastType.ERROR, "This organization name already exists");
            } else {
                Notifications.actionCreators.display(ToastType.ERROR, "Unable to create organization");
            }
        });

        this.setState({ savingNewOrg: false });
    };

    private updateProfileState(organisation, organisationId) {
        AuthApi.refresh();
        this.props.updateProfileState({
            authenticatedUserProfile: {
                organisationId,
                organisationName: organisation.name
            }
        });
        toast.success('Organization created!');
        if (this.props.refreshOverlayMenu) {
            this.props.refreshOverlayMenu();
        }
    }

    private inviteMemberToOrganisation = async (container: TeamsContainer) => {
        const email = this.state.inviteEmail;

        this.closeInviteModal();

        try {
            await OrganisationAPI.inviteMember(email);
            toast.success('Invitation Sent');
            this.loadOrganisation();
            container.getTeams();

            InternalTracker.trackEvent("", {
                category: 'Organisation',
                action: 'Organisation Invite Sent'
            });
        } catch (error) {
            // Swallow error - afetch handles error notification
        }
    };

    private closeInviteModal = () => {
        this.setState({
            showInviteModal: false,
            inviteEmail: ''
        });
    };

    memberIsSelf = ({ email }: UserDto) => email === this.props.userEmail;

    get profileFilledOut(): boolean {
        return !!(
            this.props.phoneNumber &&
            this.props.headline
            // this.props.firstName &&
            // this.props.lastName &&
            // this.props.userSetProfileImage &&
            // this.props.userEmail
        );
    }

    get self(): UserDto {
        return (
            this.state.members.find(this.memberIsSelf) || {
                id: '',
                email: '',
                contactId: '',
                firstName: '',
                lastName: '',
                deleted: false,
                roleId: CompanyRoles.Normal
            }
        );
    }

    get isAdmin() {
        return this.self && this.self.roleId == CompanyRoles.Admin;
    }

    get hasOrganisationPicture() {
        return (
            (this.state.organisation?.imageUrl !== null &&
                this.state.organisation?.imageUrl !== '') ||
            (this.state.newOrganisationFormData.imageUrl !== null &&
                this.state.newOrganisationFormData.imageUrl !== '')
        );
    }

    get hasOrganisationName() {
        return (
            (this.props.organisationName !== null &&
                this.props.organisationName !== '') ||
            (this.state.newOrganisationFormData.name !== null &&
                this.state.newOrganisationFormData.name !== '')
        );
    }

    get hasOrganisationLocation() {
        return (
            (this.state.organisation?.locationPlaceId !== null &&
                this.state.organisation?.locationPlaceId !== '' &&
                this.state.organisation?.locationPlaceId !== undefined &&
                this.state.organisation?.locationPlaceName !== null &&
                this.state.organisation?.locationPlaceName !== '' &&
                this.state.organisation?.locationPlaceName !== undefined &&
                this.state.organisation?.locationFullName !== null &&
                this.state.organisation?.locationFullName !== '' &&
                this.state.organisation?.locationFullName !== undefined) ||
            (this.state.newOrganisationFormData.locationPlaceId !== null &&
                this.state.newOrganisationFormData.locationPlaceId !== '' &&
                this.state.newOrganisationFormData.locationPlaceId !==
                    undefined &&
                this.state.newOrganisationFormData.locationFullName !== null &&
                this.state.newOrganisationFormData.locationFullName !== '' &&
                this.state.newOrganisationFormData.locationFullName !==
                    undefined &&
                this.state.newOrganisationFormData.locationPlaceName !== null &&
                this.state.newOrganisationFormData.locationPlaceName !== '' &&
                this.state.newOrganisationFormData.locationPlaceName !==
                    undefined)
        );
    }

    get hasOrganisationIndustry() {
        return (
            this.state.organisation?.industries !== null &&
            this.state.organisation?.industries !== undefined &&
            this.state.organisation?.industries.length > 0
        );
    }

    private hasOrganisation = () => {
        return this.props.organisationId != null;
    };

    private hasLoaded = () => {
        const hasLoaded = (this.state.loaded && this.props.profileHasLoaded) || this.state.staleData;
        return hasLoaded;
    };
}

const ManagedFieldInput = styled.input`
    display: inline-block;
    //width: calc(100% - 90px);
    &[type='file'] {
        display: inline-block;
    }
`;

// const ManagedFieldButton = styled(Button)`
//     width: 80px;
//     margin-top: -5px;
//     margin-left: 10px;
// `;

const RolePopoverContent = styled.div`
    margin: -20px -30px -13px -30px;

    > div:first-child {
        position: relative;
        margin: 8px;
        padding: 5px 0;
        text-align: center;
        border-bottom: 1px solid #eaeaea;

        > span {
            font-weight: 400;
            font-size: 14px;
        }
    }

    > div.option {
        padding: 6px 12px;
        cursor: pointer;

        :hover {
            background: ${GREY_LIGHTER};
        }

        > div {
            font-size: 13px;

            > .fa-check {
                position: relative;
                top: -2px;
                margin-left: 8px;
                font-size: 10px;
            }
        }

        > p {
            margin: 4px 0 0 0;
            font-size: 12px;
        }
    }

    > div.own {
        margin: 20px 0;
        color: #d00;
        font-weight: 600;
        text-align: center;
    }
`;

const InviteModalContent = styled.div`

    > div:first-child {
        text-align: center;

        > label {
            margin-bottom: 10px;
            font-weight: 400;
        }

        button {
            margin-top: 5px;
            width: 100%;
        }

        + div {
            padding: 6px 12px;
            font-size: 13px;

            input {
                margin: 15px 0;
            }

            .invalid {
                margin: 15px 0;
                color: #d00;
                font-size: 10px;
                text-align: center;
            }
        }
    }
`;

const Wrapper = styled.div`
    width: 100%;
    background: white;
    overflow: scroll;
    border-radius: 20px;

    .member-row {

        button {
            background: whitesmoke;
            padding: 4px 10px;
            margin-left: 8px;
            border-radius: 4px;
            border: none;
        }

    }

    .features {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        margin: 32px 0;
        
        &>div {
            text-align: center;
            flex-basis: calc(33.3333% - 20px);

            svg {
                width: 100%;
                height: auto;
                margin-bottom: 12px;
                max-width: 220px;
            }

            h3 {
                color: black;
                font-size: 1.5em;
                font-weight: 600;
            }

            p {
                font-size: 1.2em;
                line-height: 1.44em;
                color: #333;
                margin: 0;
            }

        }

    }

    #profileWarning {
        transition: transform 0.25s linear, filter 0.25s linear;
        &:hover {
            transform: scale(1.05);
            filter: drop-shadow(0 0 0.25rem rgba(106, 108, 111, 0.35));
            //z-index: 1000;
        }
    }

    .required-overlay {
        font-style: italic;
        opacity: 0.7;
        color: firebrick;
    }

    .tab-content {
        padding: 20px;

        > .tab-pane {
            height: 0;

            &.active {
                height: calc(100vh - 260px);
            }
        }
    }

    > h4 {
        margin: 0;
        padding: 10px;
        color: ${theme.colours.updatedge};
        font-size: 24px;
        text-align: center;
        background: #f1f3f6;
    }

    .nav.nav-tabs {
        background: #f1f3f6;

        > li {
            display: inline-block;
            float: none;

            :not(.active) {
                > a {
                    background: #e6e6e6;
                    cursor: pointer;

                    &:hover {
                        background: #fafafa;
                    }
                }
            }
        }
    }

    .tab-header {
        margin-bottom: 10px;

        > div:first-child > span {
            display: inline-block;
            padding-top: 2px;
            color: ${theme.colours.updatedge};
            font-weight: 600;
            font-size: 16px;
            cursor: pointer;
            opacity: 0.5;

            &.selected {
                opacity: 1;
            }
        }
    }

    .flex {
        overflow-y: auto;
    }
`;

const MemberRow = styled.div`
    width: 100%;
    padding: 10px 0;
    border-bottom: 1px solid #eee;

    &:hover {
        background: ${theme.colours.rowHover};
    }

    > div:first-child {
        margin-right: 8px;
    }

    .member-name {
        color: ${theme.colours.updatedge};
        font-weight: 700;
        font-size: 14px;

        + div {
            font-size: 11px;
        }
    }

    .archive-button, .select-button, .upload-photo {
        background: none;
        border: none;
        &:hover {
            cursor: pointer;
        }
        &:disabled {
            cursor: not-allowed;
        }
    }

    .upload-photo {
        margin-left: 12px;
    }

    .fa-user-plus,
    .fa-images,
    .fa-user-times {
        margin: 0 5px 0 0;
        font-size: 18px;
    }
`;

const TeamsPopoverContent = styled.div`
    margin-bottom: 10px;

    > h4 {
        margin: 5px 0 15px;
        color: ${theme.colours.updatedge};
        text-align: center;
    }

    > div:not(:last-child) {
        padding: 5px 15px;
        font-weight: 500;
        border-top: 1px solid ${theme.colours.rowHover};
    }

    /*> div:last-child {
        margin-top: 15px;
        text-align: center;
    }*/
`;

const SwapModalContent = styled.div`
    margin: -20px -30px;

    h4 {
        padding: 0 10px;
        color: ${theme.colours.updatedge};
        text-align: center;
    }

    > div {
        max-width: 350px;
        overflow-x: hidden;
        overflow-y: auto;
    }

    > span {
        display: block;
        margin-bottom: 10px;
        text-align: center;
    }

    .btn {
        width: 100%;
    }

    tr {
        cursor: pointer;

        > td {
            text-align: left;
            vertical-align: middle !important;

            &:first-child {
                padding: 4px 4px 4px 25px;
            }
        }

        img {
            width: 32px;
            height: 32px;
        }
    }
`;

const SelectorContent = styled.div`
    text-align: center;

    > h4 {
        color: ${theme.colours.updatedge};
        font-weight: 600;
    }

    > h5 {
        margin: 10px 0;
    }

    > div {
        max-height: 350px;
        overflow-x: hidden;
        overflow-y: auto;
    }

    > .btn {
        width: 100%;
    }

    tr {
        cursor: pointer;

        &:not(.selected) {
            cursor: ${(props) => (props.full ? 'not-allowed' : 'pointer')};
        }

        > td {
            text-align: left;
            vertical-align: middle !important;
        }

        img {
            width: 32px;
            height: 32px;
        }
    }

    .full {
        width: 100%;
        height: 30px;
        font-weight: 600;
        font-size: 15px;
        line-height: 30px;
        text-align: center;
        background: ${theme.colours.orange};

        > span {
            opacity: 0.9;
        }
    }
`;

export default connect(
    (state: ApplicationState) => {
        return {
            organisationName:
                state.profile.authenticatedUserProfile.organisationName,
            organisationId:
                state.profile.authenticatedUserProfile.organisationId,
            profileHasLoaded: state.profile.authenticatedUserProfile.loaded,
            userEmail: state.profile.authenticatedUserProfile.emailAddress,
            userId: state.profile.authenticatedUserProfile.id,
            profileImageUrl:
                state.profile.authenticatedUserProfile.profileImageUrl,
            userSetProfileImage:
                state.profile.authenticatedUserProfile.userSetProfileImage,
            phoneNumber: state.profile.authenticatedUserProfile.phoneNumber,
            headline: state.profile.authenticatedUserProfile.headline,
            firstName: state.profile.authenticatedUserProfile.firstName,
            lastName: state.profile.authenticatedUserProfile.lastName,
            subscriptionNotifications: state.profile.authenticatedUserProfile.subscriptionNotifications
        };
    },
    {
        refreshOverlayMenu: EventsState.actionCreators.refreshOverlayMenu,
        updateProfileState: ProfileActionCreators.updateProfileState,
        updateOrganisationProfileImage:
            ProfileActionCreators.updateOrganisationProfileImage,
        updateOrganisation: ProfileActionCreators.updateOrganisation,
        updateOrganisationLocation:
            ProfileActionCreators.updateOrganisationLocation
    }
)((props) => (
    <Subscribe to={[ProfileVerificationContainer, TeamsContainer]}>
        {(container: ProfileVerificationContainer, teamsContainer: TeamsContainer) => (
            <Organisation
                // @ts-ignore
                {...props}
                userIsVerified={container.state.verified}
                teamsContainer={teamsContainer}
            />
        )}
    </Subscribe>
)) as any;
