/* eslint-disable */
import React from 'react';

function SvgFile(props) {
    return (
      <svg id="undraw_icons_wdp4" xmlns="http://www.w3.org/2000/svg" width="270.755" height="175.645" viewBox="0 0 270.755 175.645">
        <g id="Group_1866" data-name="Group 1866" transform="translate(181.954 33.339)">
          <path id="Path_8655" data-name="Path 8655" d="M676.168,330.218h16.1a3.562,3.562,0,0,0,3.558-3.558v-16.1A3.562,3.562,0,0,0,692.271,307h-16.1a3.562,3.562,0,0,0-3.558,3.558v16.1A3.562,3.562,0,0,0,676.168,330.218Zm16.1-22.469a2.812,2.812,0,0,1,2.809,2.809v16.1a2.812,2.812,0,0,1-2.809,2.809h-16.1a2.812,2.812,0,0,1-2.809-2.809v-16.1a2.812,2.812,0,0,1,2.809-2.809Z" transform="translate(-672.61 -307)" fill="#3f3d56"/>
          <path id="Path_8656" data-name="Path 8656" d="M697.981,327.241a3.37,3.37,0,1,0-3.37-3.37A3.37,3.37,0,0,0,697.981,327.241Z" transform="translate(-686.372 -315.445)" fill="#50d890"/>
          <path id="Path_8657" data-name="Path 8657" d="M692.165,346.117h6.73a1.4,1.4,0,0,0,1.472-1.311,4.87,4.87,0,0,0-9.675,0A1.4,1.4,0,0,0,692.165,346.117Z" transform="translate(-683.921 -327.954)" fill="#50d890"/>
        </g>
        <g id="Group_1867" data-name="Group 1867" transform="translate(181.954)">
          <path id="Path_8655-2" data-name="Path 8655" d="M676.168,330.218h16.1a3.562,3.562,0,0,0,3.558-3.558v-16.1A3.562,3.562,0,0,0,692.271,307h-16.1a3.562,3.562,0,0,0-3.558,3.558v16.1A3.562,3.562,0,0,0,676.168,330.218Zm16.1-22.469a2.812,2.812,0,0,1,2.809,2.809v16.1a2.812,2.812,0,0,1-2.809,2.809h-16.1a2.812,2.812,0,0,1-2.809-2.809v-16.1a2.812,2.812,0,0,1,2.809-2.809Z" transform="translate(-672.61 -307)" fill="#3f3d56"/>
          <path id="Path_8656-2" data-name="Path 8656" d="M697.981,327.241a3.37,3.37,0,1,0-3.37-3.37A3.37,3.37,0,0,0,697.981,327.241Z" transform="translate(-686.372 -315.445)" fill="#ffa000"/>
          <path id="Path_8657-2" data-name="Path 8657" d="M692.165,346.117h6.73a1.4,1.4,0,0,0,1.472-1.311,4.87,4.87,0,0,0-9.675,0A1.4,1.4,0,0,0,692.165,346.117Z" transform="translate(-683.921 -327.954)" fill="#ffa000"/>
        </g>
        <g id="Group_1868" data-name="Group 1868" transform="translate(145.584 11.113)">
          <path id="Path_8655-3" data-name="Path 8655" d="M676.168,330.218h16.1a3.562,3.562,0,0,0,3.558-3.558v-16.1A3.562,3.562,0,0,0,692.271,307h-16.1a3.562,3.562,0,0,0-3.558,3.558v16.1A3.562,3.562,0,0,0,676.168,330.218Zm16.1-22.469a2.812,2.812,0,0,1,2.809,2.809v16.1a2.812,2.812,0,0,1-2.809,2.809h-16.1a2.812,2.812,0,0,1-2.809-2.809v-16.1a2.812,2.812,0,0,1,2.809-2.809Z" transform="translate(-672.61 -307)" fill="#3f3d56"/>
          <path id="Path_8656-3" data-name="Path 8656" d="M697.981,327.241a3.37,3.37,0,1,0-3.37-3.37A3.37,3.37,0,0,0,697.981,327.241Z" transform="translate(-686.372 -315.445)" fill="#50d890"/>
          <path id="Path_8657-3" data-name="Path 8657" d="M692.165,346.117h6.73a1.4,1.4,0,0,0,1.472-1.311,4.87,4.87,0,0,0-9.675,0A1.4,1.4,0,0,0,692.165,346.117Z" transform="translate(-683.921 -327.954)" fill="#50d890"/>
        </g>
        <g id="Group_1869" data-name="Group 1869" transform="translate(145.584 46.473)">
          <path id="Path_8655-4" data-name="Path 8655" d="M676.168,330.218h16.1a3.562,3.562,0,0,0,3.558-3.558v-16.1A3.562,3.562,0,0,0,692.271,307h-16.1a3.562,3.562,0,0,0-3.558,3.558v16.1A3.562,3.562,0,0,0,676.168,330.218Zm16.1-22.469a2.812,2.812,0,0,1,2.809,2.809v16.1a2.812,2.812,0,0,1-2.809,2.809h-16.1a2.812,2.812,0,0,1-2.809-2.809v-16.1a2.812,2.812,0,0,1,2.809-2.809Z" transform="translate(-672.61 -307)" fill="#3f3d56"/>
          <path id="Path_8656-4" data-name="Path 8656" d="M697.981,327.241a3.37,3.37,0,1,0-3.37-3.37A3.37,3.37,0,0,0,697.981,327.241Z" transform="translate(-686.372 -315.445)" fill="#50d890"/>
          <path id="Path_8657-4" data-name="Path 8657" d="M692.165,346.117h6.73a1.4,1.4,0,0,0,1.472-1.311,4.87,4.87,0,0,0-9.675,0A1.4,1.4,0,0,0,692.165,346.117Z" transform="translate(-683.921 -327.954)" fill="#50d890"/>
        </g>
        <path id="Path_8643" data-name="Path 8643" d="M418.168,330.218h16.1a3.562,3.562,0,0,0,3.558-3.558v-16.1A3.562,3.562,0,0,0,434.271,307h-16.1a3.562,3.562,0,0,0-3.558,3.558v16.1A3.562,3.562,0,0,0,418.168,330.218Zm16.1-22.469a2.812,2.812,0,0,1,2.809,2.809v16.1a2.812,2.812,0,0,1-2.809,2.809h-16.1a2.812,2.812,0,0,1-2.809-2.809v-16.1a2.812,2.812,0,0,1,2.809-2.809Z" transform="translate(-233.025 -273.661)" fill="#3f3d56"/>
        <ellipse id="Ellipse_291" data-name="Ellipse 291" cx="8.678" cy="8.678" rx="8.678" ry="8.678" transform="translate(224.192 41.592)" fill="#ffb8b8"/>
        <path id="Path_8645" data-name="Path 8645" d="M289.153,429.027q.164,0,.329-.014a3.887,3.887,0,0,0,3.489-4.634,3.654,3.654,0,0,0-.1-.369l12.807-15.147a3.019,3.019,0,0,0,.21-3.6l-.023-.028-11.528-11.367a3.332,3.332,0,1,0-4.636,4.788l8.349,7.938-9.141,14.67a3.7,3.7,0,0,0-.516.063,3.887,3.887,0,0,0,.753,7.7Z" transform="translate(-53.15 -327.411)" fill="#ffb8b8"/>
        <path id="Path_8646" data-name="Path 8646" d="M145.718,433.8h-4.332l-2.06-16.707h6.393Z" transform="translate(72.86 -262.756)" fill="#ffb8b8"/>
        <path id="Path_8647" data-name="Path 8647" d="M388.845,670.924h-8.53a5.436,5.436,0,0,0-5.436,5.436v.176h13.966Z" transform="translate(-169.162 -501.3)" fill="#2f2e41"/>
        <path id="Path_8648" data-name="Path 8648" d="M38.406,421.732l-3.722,2.216-10.318-13.3,5.493-3.27Z" transform="translate(223.224 -256.682)" fill="#ffb8b8"/>
        <path id="Path_8649" data-name="Path 8649" d="M264.581,653.956l-7.329,4.364h0a5.436,5.436,0,0,0-1.89,7.452l.09.152,12-7.145Z" transform="translate(-2.725 -490.686)" fill="#2f2e41"/>
        <path id="Path_8650" data-name="Path 8650" d="M271.968,525.881h4.85a1.587,1.587,0,0,0,1.581-1.422l4.89-45.964a.53.53,0,0,1,1.01-.162l8.821,19.533,15.617,25.466a1.6,1.6,0,0,0,2,.624l6.239-2.745a1.59,1.59,0,0,0,.725-2.271L303.34,494.9l-4.4-20.254c1.385-8.784-4.552-21.842-4.612-21.974l-.029-.064-16.353-6.392-.082.1c-6.449,21.164-10.379,43.939-7.477,78.106A1.6,1.6,0,0,0,271.968,525.881Z" transform="translate(-58.744 -360.743)" fill="#2f2e41"/>
        <path id="Path_8651" data-name="Path 8651" d="M329.323,415.344l.076-.16c.036-.077,3.673-7.746,6.476-15.943a12.09,12.09,0,0,0-.838-9.689,12.222,12.222,0,0,0-7.794-5.993h0a12.237,12.237,0,0,0-14.2,7.329c-2.223,5.56-1.515,11.495-.848,16.162l.013.093.085.041Z" transform="translate(-93.113 -321.337)" fill="#6c63ff"/>
        <path id="Path_8652" data-name="Path 8652" d="M330.966,326.9h13.779v-6.006c-3.024-1.2-5.984-2.223-7.773,0a6.006,6.006,0,0,0-6.006,6.006Z" transform="translate(-108.617 -281.529)" fill="#2f2e41"/>
        <path id="Path_8653" data-name="Path 8653" d="M311.319,320.252c8.237,0,10.543,10.325,10.543,16.15,0,3.249-1.469,4.41-3.778,4.8l-.815-4.348-1.91,4.535c-.649,0-1.33-.009-2.036-.022l-.647-1.333-1.444,1.309c-5.782.009-10.456.852-10.456-4.944C300.776,330.577,302.8,320.252,311.319,320.252Z" transform="translate(-74.428 -281.95)" fill="#2f2e41"/>
        <path id="Path_8654" data-name="Path 8654" d="M345.171,347.739q-.079.144-.145.3a3.887,3.887,0,0,0,2.394,5.283,3.631,3.631,0,0,0,.37.093l7.153,18.5A3.019,3.019,0,0,0,358,373.823l.036-.007,15.5-4.667a3.332,3.332,0,1,0-1.98-6.364l-10.969,3.522L352.1,351.252a3.709,3.709,0,0,0,.191-.483,3.887,3.887,0,0,0-7.118-3.03Z" transform="translate(-144.895 -297.882)" fill="#ffb8b8"/>
        <g id="Group_1865" data-name="Group 1865" transform="translate(84.968 33.339)">
          <path id="Path_8655-5" data-name="Path 8655" d="M676.168,330.218h16.1a3.562,3.562,0,0,0,3.558-3.558v-16.1A3.562,3.562,0,0,0,692.271,307h-16.1a3.562,3.562,0,0,0-3.558,3.558v16.1A3.562,3.562,0,0,0,676.168,330.218Zm16.1-22.469a2.812,2.812,0,0,1,2.809,2.809v16.1a2.812,2.812,0,0,1-2.809,2.809h-16.1a2.812,2.812,0,0,1-2.809-2.809v-16.1a2.812,2.812,0,0,1,2.809-2.809Z" transform="translate(-672.61 -307)" fill="#3f3d56"/>
          <path id="Path_8656-5" data-name="Path 8656" d="M697.981,327.241a3.37,3.37,0,1,0-3.37-3.37A3.37,3.37,0,0,0,697.981,327.241Z" transform="translate(-686.372 -315.445)" fill="#fb5b5a"/>
          <path id="Path_8657-5" data-name="Path 8657" d="M692.165,346.117h6.73a1.4,1.4,0,0,0,1.472-1.311,4.87,4.87,0,0,0-9.675,0A1.4,1.4,0,0,0,692.165,346.117Z" transform="translate(-683.921 -327.954)" fill="#fb5b5a"/>
        </g>
        <path id="Path_8658" data-name="Path 8658" d="M582.966,431.949h4.591l2.184-17.709h-6.776Z" transform="translate(-537.3 -260.975)" fill="#a0616a"/>
        <path id="Path_8659" data-name="Path 8659" d="M574.008,458.025h-8.854V463.6h14.429a5.575,5.575,0,0,0-5.575-5.575Z" transform="translate(-520.471 -288.363)" fill="#2f2e41"/>
        <path id="Path_8660" data-name="Path 8660" d="M645.573,405.773l3.392,3.094,13.549-11.61-5.006-4.567Z" transform="translate(-633.519 -247.495)" fill="#a0616a"/>
        <path id="Path_8661" data-name="Path 8661" d="M5.575,5.575h8.854V0H0A5.575,5.575,0,0,0,5.575,5.575Z" transform="matrix(-0.739, -0.674, 0.674, -0.739, 19.117, 170.49)" fill="#2f2e41"/>
        <path id="Path_8662" data-name="Path 8662" d="M845.845,552.472a1.675,1.675,0,0,0,1.664-1.444l4-27.671a16.061,16.061,0,0,0,.056-4.165l-3.9-33.32-24.9,2.091,3.758,31.571-14.218,17.728a1.681,1.681,0,0,0,.208,2.325l3.58,3.118a1.685,1.685,0,0,0,2.388-.176l13.472-15.765a21.171,21.171,0,0,0,4.389-8.449l1.923-7.5a.562.562,0,0,1,1.075-.044l2.544,7.349-1.609,32.367a1.689,1.689,0,0,0,1.59,1.766l3.884.216Z" transform="translate(-795.665 -385.548)" fill="#2f2e41"/>
        <ellipse id="Ellipse_292" data-name="Ellipse 292" cx="9.198" cy="9.198" rx="9.198" ry="9.198" transform="translate(27.145 36.904)" fill="#a0616a"/>
        <path id="Path_8663" data-name="Path 8663" d="M738.521,360.735a3.766,3.766,0,0,1-5.543,1.617l-12.06,7.306.937-6.179,10.208-6.6a3.786,3.786,0,0,1,6.458,3.852Z" transform="translate(-648.755 -303.939)" fill="#a0616a"/>
        <path id="Path_8664" data-name="Path 8664" d="M835.251,421.269h0a30.125,30.125,0,0,0,15.11-4.5,1.869,1.869,0,0,0,.813-2.271l-4.782-12.885,1.088-15.182a13.176,13.176,0,0,0-6.559-12.489,11.831,11.831,0,0,0-10.321-.611,12.723,12.723,0,0,0-7.331,8.355l-1.535,5.34a32.764,32.764,0,0,0,.613,19.886l4.447,12.486.115.069A17.561,17.561,0,0,0,835.251,421.269Z" transform="translate(-798.481 -314.584)" fill="#ccc"/>
        <path id="Path_8665" data-name="Path 8665" d="M784.938,370.6a1.871,1.871,0,0,0-.02-2.1l-2.331-3.366a1.875,1.875,0,0,0-2.4-.6L762.9,373.527l-19.069-1.912a5.3,5.3,0,1,0-.582,10.566l17.782.18a10.937,10.937,0,0,0,5.359-1.344l17.887-9.809A1.879,1.879,0,0,0,784.938,370.6Z" transform="translate(-701.557 -309.521)" fill="#ccc"/>
        <path id="Path_8666" data-name="Path 8666" d="M873.868,494.547a3.766,3.766,0,0,1-.173-5.772L869.2,476.411l6.194,1.315,3.821,11.537a3.786,3.786,0,0,1-5.351,5.284Z" transform="translate(-845.452 -379.63)" fill="#a0616a"/>
        <path id="Path_8667" data-name="Path 8667" d="M875.094,425.8a1.858,1.858,0,0,0,.643-.114l3.844-1.41a1.876,1.876,0,0,0,1.18-2.177L876.4,403.113l6.639-17.978a5.3,5.3,0,1,0-10.081-3.217l-4.641,17.167a10.938,10.938,0,0,0-.045,5.524l5,19.778a1.872,1.872,0,0,0,1.815,1.413Z" transform="translate(-848.326 -318.075)" fill="#ccc"/>
        <path id="Path_8668" data-name="Path 8668" d="M853.735,326.053c-.466-1.935-.7-3.022-1.159-4.958a3.1,3.1,0,0,1,1.8-3.231,10.434,10.434,0,0,1,3.839-.8,6.717,6.717,0,0,0,3.7-1.2A4.366,4.366,0,0,0,863,310.968a5.563,5.563,0,0,0-4-3.2,7.94,7.94,0,0,0-5.141.791,3.621,3.621,0,0,0-3.944-1.828,9.766,9.766,0,0,0-4.121,2.129c-2.323,1.754-4.707,3.88-5.223,6.772-.5,2.792.918,5.61,2.813,7.7a17.034,17.034,0,0,0,9.843,5.341A3.486,3.486,0,0,0,853.735,326.053Z" transform="translate(-818.001 -273.461)"/>
        <path id="Path_8669" data-name="Path 8669" d="M767.875,685.749h71.9a.374.374,0,0,0,0-.749h-71.9a.374.374,0,1,0,0,.749Z" transform="translate(-767.5 -510.104)" fill="#ccc"/>
        <path id="Path_8670" data-name="Path 8670" d="M238.874,685.749h71.9a.374.374,0,1,0,0-.749h-71.9a.374.374,0,0,0,0,.749Z" transform="translate(-40.396 -510.104)" fill="#ccc"/>
        <g id="Group_1870" data-name="Group 1870" transform="translate(84.968 64.658)">
          <path id="Path_8655-6" data-name="Path 8655" d="M676.168,330.218h16.1a3.562,3.562,0,0,0,3.558-3.558v-16.1A3.562,3.562,0,0,0,692.271,307h-16.1a3.562,3.562,0,0,0-3.558,3.558v16.1A3.562,3.562,0,0,0,676.168,330.218Zm16.1-22.469a2.812,2.812,0,0,1,2.809,2.809v16.1a2.812,2.812,0,0,1-2.809,2.809h-16.1a2.812,2.812,0,0,1-2.809-2.809v-16.1a2.812,2.812,0,0,1,2.809-2.809Z" transform="translate(-672.61 -307)" fill="#3f3d56"/>
          <path id="Path_8656-6" data-name="Path 8656" d="M697.981,327.241a3.37,3.37,0,1,0-3.37-3.37A3.37,3.37,0,0,0,697.981,327.241Z" transform="translate(-686.372 -315.445)" fill="#fb5b5a"/>
          <path id="Path_8657-6" data-name="Path 8657" d="M692.165,346.117h6.73a1.4,1.4,0,0,0,1.472-1.311,4.87,4.87,0,0,0-9.675,0A1.4,1.4,0,0,0,692.165,346.117Z" transform="translate(-683.921 -327.954)" fill="#fb5b5a"/>
        </g>
        <rect id="Rectangle_4756" data-name="Rectangle 4756" width="254" height="59" rx="29.5" transform="translate(8.025 106.609)" fill="#5683d8"/>
        <g id="Group_1889" data-name="Group 1889" transform="translate(-24 1)">
          <rect id="Rectangle_4757" data-name="Rectangle 4757" width="10" height="10" rx="5" transform="translate(124.025 130.609)" fill="#fff"/>
          <rect id="Rectangle_4758" data-name="Rectangle 4758" width="10" height="10" rx="5" transform="translate(101.025 130.609)" fill="#fff"/>
          <path id="pencil-alt-solid" d="M22.2,6.4,20.148,8.455a.535.535,0,0,1-.757,0L14.446,3.511a.535.535,0,0,1,0-.757L16.5.7a2.143,2.143,0,0,1,3.025,0L22.2,3.377A2.135,2.135,0,0,1,22.2,6.4ZM12.682,4.517l-11.7,11.7L.04,21.627a1.07,1.07,0,0,0,1.238,1.238l5.412-.949,11.7-11.7a.535.535,0,0,0,0-.757L13.444,4.517a.54.54,0,0,0-.762,0Zm-7.132,10.7a.621.621,0,0,1,0-.882l6.86-6.86a.624.624,0,1,1,.882.882l-6.86,6.86a.621.621,0,0,1-.882,0ZM3.943,18.959H6.081v1.617l-2.873.5L1.822,19.694l.5-2.873H3.943Z" transform="translate(59.001 123.993)" fill="#fff"/>
          <path id="bell-solid" d="M10.736,24.541A3.067,3.067,0,0,0,13.8,21.473H7.67A3.067,3.067,0,0,0,10.736,24.541ZM21.06,17.365c-.926-1-2.659-2.492-2.659-7.4a7.569,7.569,0,0,0-6.132-7.437v-1a1.533,1.533,0,1,0-3.066,0v1A7.569,7.569,0,0,0,3.071,9.97c0,4.9-1.733,6.4-2.659,7.4A1.5,1.5,0,0,0,0,18.406,1.535,1.535,0,0,0,1.538,19.94h18.4a1.535,1.535,0,0,0,1.539-1.534A1.5,1.5,0,0,0,21.06,17.365Z" transform="translate(151.026 123.068)" fill="#fff"/>
        </g>
      </svg>
    );
}

export default SvgFile;