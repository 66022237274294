import moment, { Moment } from 'moment';
import Utilities from './Utilities';

/** Default short date format */
export const DateFormats = {
    ShortDate: 'DD MMM YYYY',
    ShortDateWithDay: 'ddd, DD MMM YYYY',
    Time24Hour: 'HH:mm',
    ShortDateWithDayAndTime: 'ddd, DD MMM YY - HH:mm'
};

const formatDateDifference = (lastUpdated) => {
    const now = Date.now();
    const diffTime = Math.abs(now - lastUpdated);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return `${diffDays} days ago`;
};

/**
 * Determines how far in past a point in time was in days
 * @param date Date to compare to now
 */
export function fromNowDays(date: Moment) {
    if (!date.isValid()) return 'never';

    // if (Math.abs(moment().diff(date)) < 25000) {
    //     // handles extremely recent update, 25 seconds ago
    //     return 'just now';
    // }
    return formatDateDifference(moment(date, moment.ISO_8601));
}
/**
 * Determines how far in past a point in time was (arbitrarily)
 * @param date Date to compare to now
 */
export function fromNow(date: Moment) {
    if (!date.isValid()) return 'never';

    if (Math.abs(moment().diff(date)) < 25000) {
        // handles extremely recent update, 25 seconds ago
        return 'just now';
    }
    return moment(date, moment.ISO_8601).fromNow();
}

/**
 * Capitalises the first letter of a string
 * @param input Input string to capitalise
 */
export function capitaliseFirstLetter(input: string) {
    return input.charAt(0).toUpperCase() + input.slice(1);
}

/**
 * Returns the inputted string with the last character removed
 * Can be used as a (very) naive solution to convert plural to singular
 * @param input Input string to return a modified version of
 */
export function stripLastCharacter(input: string) {
    return input.slice(0, -1);
}

/*** C#-like nameof operator */
export const nameofFactory = <T>() => (name: keyof T) => name;

/** Constant regular expressions */
export const RegularExpressions = {
    emailAddress: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    password: /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)([A-Za-z\d*\.!@$%^(){}[\]:;&gt;,.?/~_+-=|\]]){8,16}$/,
    tokenQueryParam: /[\\?&]token=([^&#]*)/,
    accessTokenQueryParam: /\#access_token=([^&#]*)/
};

/** Group items by a given property */
export function groupBy<T>(list: T[], prop: (item: T) => any) {
    const map = new Map();
    list.forEach((l) => {
        const key = prop(l);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [l]);
        } else {
            collection.push(l);
        }
    });
    return map;
}

/** Sort items by a given property */
export function sortByProp<T>(list: T[], prop: (item: T) => any): T[] {
    return list.sort((a, b) => {
        const valA = prop(a);
        const valB = prop(b);
        return valA > valB ? 1 : valB > valA ? -1 : 0;
    });
}

/** Keyboard event keyCodes */
export const KeyCodes = {
    ENTER: 13
};

export const supportedImageFormats = [
    'image/jpg',
    'image/jpeg',
    'image/gif',
    'image/png'
];

export const minDuration = async <T extends {}>(
    promise: Promise<T>,
    duration: number
) => {
    // When this function started
    const start = new Date().getTime();

    // Await the promise and get result
    const result = await promise;

    // Return a new promise which resolves after the minimum duration specified
    return new Promise<T>((resolve) => {
        // Elapsed time so far
        const elapsed = new Date().getTime() - start;

        // Resolve the promise after remaining duration
        // (if remaining duration negative then will resolve immediately)
        setTimeout(() => {
            resolve(result);
        }, duration - elapsed);
    });
};

declare let crypto: any;

export const newGuid = () => {
    // ES6 crypto API available

    if (
        typeof crypto != 'undefined' &&
        typeof crypto.getRandomValues == 'function' &&
        !!crypto
    ) {
        const foo: any = [1e7];

        return (foo + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
            (
                c! ^
                (crypto!.getRandomValues(new Uint8Array(1))[0] &
                    (15 >> (c / 4)))
            ).toString(16)
        );
    }
    // Fallback to Math.random
    else {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
            const r = (Math.random() * 16) | 0,
                v = c == 'x' ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
    }
};

export function simpleSearch<T extends Record<string, any>, K extends keyof T>(
    term: string,
    items: T[],
    fields: K[]
): T[] {
    return items.filter((item) => {
        const values = fields
            .map((field) => item[field])
            .filter((value) => typeof value === 'string');

        return values.some(
            (value) =>
                value
                    .toLowerCase()
                    .trim()
                    .indexOf(term.toLowerCase().trim()) !== -1
        );
    });
}

export const isToday = (date: Date) => moment(date).isSame(new Date(), 'day');

export const formatLocationName = ({
    locationFriendlyName,
    locationPlaceName
}: {
    locationFriendlyName: string | undefined;
    locationPlaceName: string;
}) => {
    if (locationPlaceName && locationPlaceName.split(", ")[1]) {
        const firstPart = locationPlaceName.split(", ")[0].replace(" Road", " Rd").replace(" Street", " St").replace(" Avenue", " Ave").replace(" Close", " Cl").replace(" Lane", " Ln");
        const secondPart = locationPlaceName.split(", ")[1].replace(" Road", " Rd").replace(" Street", " St").replace(" Avenue", " Ave").replace(" Close", " Cl").replace(" Lane", " Ln");
        if (firstPart && secondPart && Utilities.wordsSimilarity(firstPart, secondPart) > 0.85) {
            locationPlaceName = locationPlaceName.replace(
                firstPart + ", " + secondPart,
                firstPart
            );
        }
    }
    if(locationFriendlyName && locationPlaceName) {
        if (locationPlaceName.includes(locationFriendlyName)) {
            return locationPlaceName;
        }
        return locationFriendlyName + " " + locationPlaceName;
    }
    if (!locationFriendlyName) return locationPlaceName;
    if (locationPlaceName.includes(locationFriendlyName))
        return locationPlaceName;
    return `${locationFriendlyName}, ${locationPlaceName}`;
};

export const StorageKeys = {
    teamId: 'ue_team_id'
};

export const PubSubTopics = {
    teamIdUpdated: 'team_id_updated'
};

export enum Feature {
    Teams = 1,
    Locations = 2,
    Rota = 3
}

export enum SubscriptionRelationshipType {
    Individual = 1,
    Organisation = 2
}

export enum PaymentProvider {
    Stripe = 1
}

export enum SubscriptionType {
    Active = 1,
    Trialling = 2,
    Cancelled = 3
}
