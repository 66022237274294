import React from 'react';

const SvgInterview = (props) => (
    <svg height="1em" viewBox="0 0 512 512" width="1em" {...props}>
        <path
            d="M511.867 256.066c0 23.16-3.082 45.598-8.847 66.942a252.225 252.225 0 01-5.872 18.8 253.73 253.73 0 01-8.253 20.372C448.575 450.566 359.425 512 255.934 512 152.44 512 63.293 450.566 22.973 362.18a253.73 253.73 0 01-8.254-20.371 252.225 252.225 0 01-5.871-18.801C3.082 301.664 0 279.227 0 256.066 0 114.72 114.586.133 255.934.133s255.933 114.586 255.933 255.933zm0 0"
            fill="#3fb777"
        />
        <path
            d="M488.895 302.207v32.04C463.68 389.52 419.375 434.237 364.418 460a253.25 253.25 0 01-30.879 12.066 251.45 251.45 0 01-36.894 8.762.868.868 0 01-.168.024V302.207c0-38.074 29.32-69.309 66.636-72.316a69.547 69.547 0 015.934-.243h47.258c5.59 0 11.03.618 16.254 1.817 32.257 7.375 56.336 36.262 56.336 70.742zm0 0"
            fill="#bfd5de"
        />
        <path
            d="M488.895 302.207v32.04C463.68 389.52 419.375 434.237 364.418 460a253.25 253.25 0 01-30.879 12.066V302.207c0-40.07 32.5-72.559 72.582-72.559h10.195c40.09 0 72.579 32.489 72.579 72.559zm0 0"
            fill="#dde8ed"
        />
        <path
            d="M321.652 149.61c0 11.988-8.754 21.925-20.222 23.769-1.25.203-2.54.309-3.856.309-13.304 0-24.082-10.778-24.082-24.079s10.778-24.078 24.082-24.078c1.317 0 2.606.106 3.856.309 11.465 1.844 20.222 11.781 20.222 23.77zm0 0"
            fill="#d3a489"
        />
        <path d="M412.164 255.828l-19.48 31.246-19.48-31.246" fill="#f74f4f" />
        <path
            d="M418.895 375.348l-26.211 33.082-26.22-33.082 26.223-96.403zm0 0"
            fill="#f74f4f"
        />
        <path
            d="M321.652 149.61c0 11.988-8.754 21.925-20.222 23.769-2.082-7.574-3.196-15.543-3.196-23.77s1.114-16.195 3.196-23.77c11.465 1.845 20.222 11.782 20.222 23.77zm0 0"
            fill="#bf8e75"
        />
        <path
            d="M306.18 149.61a8.604 8.604 0 01-8.606 8.605c-4.754 0-8.605-3.852-8.605-8.606s3.851-8.605 8.605-8.605a8.604 8.604 0 018.606 8.605zm0 0"
            fill="#bf8e75"
        />
        <path
            d="M392.676 259.352l-32.672 18.16-9.938-46.23 20.301-11.958M392.676 259.352l32.676 18.16 9.937-46.23-20.3-11.958"
            fill="#bfd5de"
        />
        <path
            d="M463.707 149.61c0 11.988 8.758 21.925 20.223 23.769 1.254.203 2.543.309 3.86.309 13.3 0 24.077-10.778 24.077-24.079s-10.777-24.078-24.078-24.078c-1.316 0-2.605.106-3.86.309-11.464 1.844-20.222 11.781-20.222 23.77zm0 0"
            fill="#d3a489"
        />
        <g fill="#bf8e75">
            <path d="M463.707 149.61c0 11.988 8.758 21.925 20.223 23.769 2.086-7.574 3.195-15.543 3.195-23.77s-1.11-16.195-3.195-23.77c-11.465 1.845-20.223 11.782-20.223 23.77zm0 0" />
            <path d="M496.395 149.61a8.606 8.606 0 11-17.213-.002 8.606 8.606 0 0117.213.001zm0 0" />
            <path d="M482.273 136.813c0 44.582-32.57 81.566-75.21 88.433-4.68.77-9.481 1.156-14.383 1.156-49.48 0-89.59-40.109-89.59-89.59 0-23.414 8.133-58.558 22.832-74.519 16.375-17.773 40.687-15.074 66.758-15.074 4.902 0 9.703.39 14.382 1.156 42.641 6.867 75.211 43.852 75.211 88.438zm0 0" />
            <path d="M418.574 201.688v31.777c0 11.633-7.672 21.465-18.234 24.73a25.85 25.85 0 01-7.664 1.157c-14.297 0-25.887-11.59-25.887-25.887v-31.778zm0 0" />
        </g>
        <path
            d="M418.574 231.453v2.012c0 11.633-7.672 21.469-18.234 24.734-9.535-2.933-16.703-11.222-18.024-21.363a88.989 88.989 0 0019.594-.996 88.362 88.362 0 0016.664-4.387zm0 0M482.273 136.813c0 44.582-32.57 81.566-75.21 88.433-42.645-6.867-75.215-43.851-75.215-88.434 0-27.453 12.347-52.027 31.804-68.46a89.116 89.116 0 0143.41-19.977c39.754 6.398 70.762 38.988 74.77 79.504.293 2.937.441 5.922.441 8.933zm0 0"
            fill="#d3a489"
        />
        <path
            d="M392.68 180.938c-7.5 0-15.004-1.883-21.84-5.641a3.988 3.988 0 01-1.574-5.414 3.991 3.991 0 015.414-1.57c11.273 6.195 24.734 6.195 36.004 0a3.991 3.991 0 015.414 1.57 3.988 3.988 0 01-1.575 5.414c-6.836 3.758-14.34 5.64-21.843 5.64zm0 0"
            fill="#bf8e75"
        />
        <path
            d="M373.602 131.598c0 5.609-4.551 10.16-10.164 10.16s-10.165-4.551-10.165-10.16c0-5.614 4.551-10.164 10.164-10.164s10.165 4.55 10.165 10.164zm0 0M432.09 131.598c0 5.609-4.55 10.16-10.164 10.16s-10.164-4.551-10.164-10.16c0-5.614 4.55-10.164 10.164-10.164s10.164 4.55 10.164 10.164zm0 0"
            fill="#3d4159"
        />
        <path
            d="M371.531 129.8a4.355 4.355 0 11-4.355-4.355 4.357 4.357 0 014.355 4.356zm0 0M430.516 129.8c0 2.407-1.95 4.356-4.356 4.356a4.355 4.355 0 110-8.71 4.357 4.357 0 014.356 4.355zm0 0"
            fill="#fff"
        />
        <path
            d="M402.844 157.184H382.52c-2.422 0-4.387-1.961-4.387-4.383s1.965-4.383 4.387-4.383h20.324a4.384 4.384 0 110 8.766zm0 0"
            fill="#bf8e75"
        />
        <path d="M422.18 299.828h39.058v11.957H422.18zm0 0" fill="#bfd5de" />
        <path
            d="M387.441 21.625c27.137 1.938 48.93 23.863 50.309 51.46l.258 5.18h15.61l8.413 55.919 20.364-6.305-2.735-54.793c-1.445-28.988-25.422-51.711-54.437-51.602zm0 0"
            fill="#2a2f4f"
        />
        <path
            d="M477.934 0c21.613 21.613 21.613 56.652 0 78.266-21.61 21.609-56.653 21.609-78.262 0zm0 0"
            fill="#3d4159"
        />
        <path
            d="M399.672 78.266H331.75l-8.414 55.918-20.363-6.305 2.73-54.793c1.445-28.984 25.422-51.711 54.442-51.602l98.093.368 12.969 23.343"
            fill="#3d4159"
        />
        <path
            d="M397.922 21.625c-27.137 1.938-48.93 23.863-50.309 51.46l-.258 5.18h-15.609l-8.414 55.919-20.363-6.305 2.734-54.793c1.445-28.988 25.422-51.711 54.438-51.602zm0 0"
            fill="#2a2f4f"
        />
        <path
            d="M497.148 341.809c-21.562 60.64-65.332 110.754-121.52 140.523a252.97 252.97 0 01-53.265 20.945 251.292 251.292 0 01-29.054 6.008A256.421 256.421 0 01255.934 512c-2.329 0-4.66-.031-6.977-.094a255.034 255.034 0 01-30.629-2.664 253.617 253.617 0 01-79.14-25.363 255.932 255.932 0 01-49.79-33.469 253.78 253.78 0 01-10.09-9.133A252.626 252.626 0 0159.985 420.7c-2.363-2.8-4.66-5.652-6.894-8.554a255.164 255.164 0 01-38.371-70.336zm0 0"
            fill="#a35418"
        />
        <path
            d="M503.02 323.008a252.225 252.225 0 01-5.872 18.8 253.73 253.73 0 01-8.253 20.372H22.973a253.73 253.73 0 01-8.254-20.371 252.225 252.225 0 01-5.871-18.801zm0 0"
            fill="#cc7a3b"
        />
        <path
            d="M204.004 311.922v40.36c0 14.773-9.742 27.261-23.16 31.41a32.797 32.797 0 01-9.73 1.468c-18.16 0-32.88-14.719-32.88-32.879v-40.36zm0 0"
            fill="#eab599"
        />
        <path d="M128.488 336.438h88.446v26.46h-88.446zm0 0" fill="#2ba1cf" />
        <path
            d="M204.004 349.723v2.558c0 14.774-9.742 27.262-23.16 31.41-12.106-3.722-21.211-14.25-22.89-27.128a112.955 112.955 0 0024.887-1.266 112.042 112.042 0 0021.163-5.574zm0 0"
            fill="#f6caae"
        />
        <path
            d="M295.023 186.203v139.008l-181.566 18.574-66.238 6.77V186.203c0-14.125 2.347-27.965 6.996-41.129C69.32 102.098 106.719 71.355 150.687 64a125.312 125.312 0 0117.415-1.66c.71-.02 1.41-.031 2.109-.031.305-.012.598-.012.898-.012 6.508 0 13.008.504 19.305 1.496 28.863 4.512 55.336 19.242 74.524 41.473 19.398 22.468 30.085 51.214 30.085 80.937zm0 0"
            fill="#3d4159"
        />
        <path
            d="M98.184 191.633c0 45.629 2.3 119.715 15.273 152.152l-66.238 6.77V186.203c0-14.125 2.347-27.965 6.996-41.129 17.07-48.554 62.594-81.492 113.887-82.734.71-.02 1.41-.031 2.109-.031-38.098.949-72.027 62.062-72.027 129.324zm0 0"
            fill="#2a2f4f"
        />
        <path
            d="M293.309 439.598v19.574c-36.489 17.133-77.25 26.71-120.235 26.71-11.469 0-22.773-.675-33.886-2.003a255.932 255.932 0 01-49.79-33.469 256.39 256.39 0 01-36.308-38.265c9.348-30.004 33.605-53.434 64.129-61.59a93.991 93.991 0 014.344-1.059 94.31 94.31 0 0112.003-1.766c2.485-.207 4.993-.3 7.532-.3h60.023c6.707 0 13.258.71 19.555 2.066.355.074.734.16 1.078.242 1.066.239 2.121.5 3.164.793a.2.2 0 01.086.012 91.567 91.567 0 0128.61 13.285c23.995 16.645 39.695 44.379 39.695 75.77zm0 0"
            fill="#2ba1cf"
        />
        <path
            d="M293.309 439.598v69.687A256.421 256.421 0 01255.934 512c-63.598 0-121.774-23.191-166.536-61.59v-10.812c0-42.641 28.95-78.528 68.29-89.043a93.991 93.991 0 014.343-1.059 94.31 94.31 0 0112.004-1.766c2.485-.207 4.992-.3 7.531-.3h19.555c6.707 0 13.258.71 19.555 2.066.355.074.734.16 1.078.242 1.066.239 2.121.5 3.164.793a.2.2 0 01.086.012 91.567 91.567 0 0128.61 13.285c23.995 16.645 39.695 44.379 39.695 75.77zm0 0"
            fill="#66baed"
        />
        <path
            d="M170.21 62.309s-22.917 28.289-22.917 86.097c0 0-16.559-41.449 3.394-84.406a125.312 125.312 0 0117.415-1.66c.71-.02 1.41-.031 2.109-.031zm0 0"
            fill="#2a2f4f"
        />
        <path
            d="M375.629 426.426v55.906a251.978 251.978 0 01-31.246 13.957 248.424 248.424 0 01-22.02 6.988v-76.851zm0 0"
            fill="#66baed"
        />
        <path
            d="M344.383 426.426v69.863a248.424 248.424 0 01-22.02 6.988v-76.851zm0 0"
            fill="#2ba1cf"
        />
        <path
            d="M425.164 411.027c0 8.504-6.894 15.399-15.398 15.399h-87.403v-15.399c0-8.504 6.895-15.398 15.395-15.398h72.008c4.25 0 8.105 1.723 10.886 4.512a15.573 15.573 0 013.414 5.183 15.221 15.221 0 011.098 5.703zm0 0"
            fill="#eab599"
        />
        <path
            d="M424.066 405.324c-2.265 5.68-7.812 9.692-14.3 9.692h-87.403v-3.989c0-8.504 6.895-15.398 15.395-15.398h72.008c4.25 0 8.105 1.723 10.886 4.512a15.573 15.573 0 013.414 5.183zm0 0"
            fill="#f6caae"
        />
        <path
            d="M248.957 454.066v57.84a255.034 255.034 0 01-30.629-2.664 253.617 253.617 0 01-79.14-25.363 255.932 255.932 0 01-49.79-33.469 253.78 253.78 0 01-10.09-9.133c5.63-21.078 24.852-36.593 47.708-36.593h72.558c27.278 0 49.383 22.105 49.383 49.382zm0 0"
            fill="#854f89"
        />
        <path
            d="M218.328 454.066v55.176a253.617 253.617 0 01-79.14-25.363 255.932 255.932 0 01-49.79-33.469 253.78 253.78 0 01-10.09-9.133A252.626 252.626 0 0159.985 420.7c9.024-9.84 21.989-16.015 36.395-16.015h72.57c27.274 0 49.38 22.105 49.38 49.382zm0 0"
            fill="#713775"
        />
    </svg>
);

export default SvgInterview;
