/** REDUX State model definitions for contact profile view*/

import { Contact } from './../contacts/Models';

/**
 * Default state for REDUX state tree
 */
export const defaultState: ContactProfileState = {
    contact: {
        firstName: '',
        lastName: '',
        fullName: '',
        id: '',
        email: '',
        companyId: '',
        companyName: '',
        notificationStatusTypeId: 0,
        profileImageUrl: '',
        headline: '',
        userId: '',
        verified: false,
        representedByOrganisations: []
    }
};

/** Stores state for contact profile */
export interface ContactProfileState {
    contact: Contact;
}
