import styled from 'styled-components';

const SmallInput = styled.input`
    width: 120px;
    height: 24px;
    margin-left: 5px;
    padding: 5px;
    color: #555;
    font-weight: normal;
    border: 1px solid #eaeaea;
    border-radius: 4px;
`;

export const SmallInputVariant = styled.input.attrs({
    className: 'form-control form-control-sm'
})`
    max-width: 200px;
    height: 28px;
    padding: 5px;
    font-weight: normal;
    font-size: 12px;
`;

export default SmallInput;
