/* eslint-disable */
import React from 'react';
import FullScreenLoader from 'src/components/ui-components/FullScreenLoader';
type Props = {
   
}

type State = {
    loading: boolean;
};

export default class Ratings extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true
        };
    }

    render() {
        let accessTokenRegular = localStorage.getItem("hello") ?  JSON.parse(localStorage.getItem("hello") || "{}").b2cSignInAndUpPolicy.access_token : null;
        let impersonatedAccessToken = localStorage.getItem("impersonatedAccessToken");
        const accessToken = impersonatedAccessToken || accessTokenRegular;
        
        if (!accessToken) {
            return null;
        }

        return (
            <div className='hpanel pageHeader' style={{ height: '100%' }}>
                <div 
                    className='panel-body' 
                    style={{ 
                        height: '100%', 
                        padding: 0, 
                        borderRadius: 16, 
                        overflow: 'hidden' 
                    }}>
                    <div 
                        className='referral-wrapper'
                        style={{ height: '100%' }}
                    >
                        <FullScreenLoader noLoadingMessage={this.state.loading} />
                        <iframe 
                            src={"https://" + (window.location.href.indexOf("test-portal.updatedge.com") !== -1 ? "test-my.updatedge.com" : window.location.href.indexOf("portal.updatedge.com") !== -1 ? "my.updatedge.com" : "test-my.updatedge.com" ) + "?iframe=true&access_token=" + accessToken + "&redirect_to=referrals/add"} 
                            width={'100%'} 
                            height={'100%'} 
                            frameBorder={0}
                            style={{
                                visibility: this.state.loading ? 'hidden' : 'visible'
                            }}
                            onLoad={() => {
                                setTimeout(() => {
                                    this.setState({ loading: false });
                                }, 300)
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    }
}