/* eslint-disable */
import React from 'react';
import styled from 'styled-components';
import { Table, Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { toast } from 'react-toastify';

import AdminApi from '../../api/admin/Admin';
import CompanyApi from '../../api/company/Company';
import { UserVerificationPendingResult } from '../../api/verification/ResponseTypes';
import { AsyncOverlay } from '../../components/ui-components/AsyncOverlay';
import { CompanyRoles } from '../../api/company/ResponseTypes';
import { ProfileImage } from '../../components/ui-components/ProfileImage';
import ProfileApi from '../../api/profile/Profile';
import { UserType } from '../../state-containers/user-type.container';
import theme from '../../css/theme';
import { RatingRecord } from 'src/api/ratings/RequestTypes';
import { UserFile } from 'src/store/contacts/Models';

interface State {
    loading: boolean;
    results: UserFile[];
    highlighted: number | null;
}

export default class Moderation extends React.Component<{}, State> {
    state = {
        loading: true,
        results: [],
        highlighted: null
    } as State;

    reload(overwrite?: boolean) {
        this.setState({
            loading: true,
            highlighted: null
        }, () => { 
            AdminApi.getReportedFiles().then((results) => {
                this.setState({
                    results: results,
                    loading: false
                });
                const idFromUrl = window.location.hash.split("#")[1];
                if (idFromUrl && results.find(r => r.id === parseInt(idFromUrl))) {
                    this.setState({
                        highlighted: parseInt(idFromUrl)
                    })
                }
            });
        })
    }

    componentDidMount() {
        this.reload();
    }

    render() {
        return (
            <Wrapper>
                <h3>Files</h3>
                <div style={{
                    marginBottom: 20
                }}>
                    <button onClick={() => { this.componentDidMount(); }}>Reload Data</button>
                </div>
                <Table condensed striped responsive bordered>
                    <thead>
                        <tr>
                            <th style={{ width: 40 }}>Id</th>
                            <th style={{ width: 100 }}>File Name</th>
                            <th style={{ width: 100 }}>File Uploader</th>
                            <th style={{ width: 100 }}>Reporter Name</th>
                            <th style={{ width: 72 }}>Options</th>
                        </tr>
                    </thead>
                    {this.renderResults(this.state.results)}
                </Table>
                <AsyncOverlay show={this.state.loading} />
            </Wrapper>
        );
    }

    renderResults(results) {
        if (results) {
            return (
                <tbody>
                    { results.map((item, i) => {
                        if (this.state.highlighted && this.state.highlighted !== item.id) {
                            return null;
                        }
                        
                        return (
                            <tr>                              
                                <td>{item.id}</td>
                                <td style={{ width: 100 }}>{item.fileName}</td>
                                <td style={{ width: 100 }}>{item.userName}</td>
                                <td style={{ width: 100 }}>{item.reporterUserName}</td>
                                <td
                                    className="options"
                                    style={{ width: 72 }}
                                >
                                    <button onClick={() => {
                                        window.open(item.accessLink, '_blank')
                                    }}>
                                        <i className="fas fa-download"></i>
                                    </button>
                                    <button 
                                        className="option-btn"
                                        onClick={() => {
                                            this.setState({
                                                loading: true,
                                            })
                                            AdminApi.approveFile(item.id).then(() => {
                                                this.reload();
                                            }).catch(e => {
                                                toast.error("Failed to approve file");
                                            })
                                        }}
                                        style={{ background: '#28df99' }}
                                    >
                                        <i className="fas fa-thumbs-up"></i>
                                    </button>
                                    <button
                                        className="option-btn"
                                        onClick={() => {
                                            this.setState({
                                                loading: true,
                                            })
                                            AdminApi.deleteFile(item.id).then(() => {
                                                this.reload();
                                            }).catch(e => {
                                                toast.error("Failed to delete file");
                                            })
                                        }}
                                        style={{ background: '#DD2E1B' }}
                                    >
                                        <i className="fas fa-trash-alt"></i>
                                    </button>
                                </td>
                            </tr>
                        )
                    }) }
                </tbody>
            )
        }
        return null;
    }

}

const Wrapper = styled.div`

    .options {

        button {
            border: none;
            padding: 6px 10px;
        }
    }
`;
