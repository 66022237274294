import React from 'react';
import styled from 'styled-components';

export default function Initial(props: { from: string }) {
    const initial = props.from.substr(0, 1);

    return <Icon initial={initial}>{initial}</Icon>;
}

const Icon = styled.div`
    width: 32px;
    height: 32px;
    color: white;
    font-weight: 600;
    font-size: 16px;
    line-height: 30px;
    text-align: center;
    background: ${(props) => GetInitialBackground(props.initial)};
    border-radius: 50%;
`;

const GetInitialBackground = (initial: string) => {
    // Each letter steps a 26th of the possible colour range (26 letters)
    const step = 255 / ('Z'.charCodeAt(0) - '0'.charCodeAt(0));
    // The 3 values are spread evenly across colour range
    const offset = 255 / 3;

    // Get char code for initial
    const char = initial.toUpperCase().charCodeAt(0) - '0'.charCodeAt(0);

    const r = char * step;
    let g = char * step + offset;
    let b = char * step + 2 * offset;

    if (g > 255) g -= 255;
    if (b > 255) b -= 255;

    return `rgb(${r}, ${g}, ${b})`;
};
