/* eslint-disable */
import React from 'react';

function SvgFile(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="235.913" height="153.042" viewBox="0 0 235.913 153.042">
          <g id="undraw_icons_wdp4" transform="translate(0 -1.844)">
            <g id="Group_1866" data-name="Group 1866" transform="translate(57.143 30.893)">
              <path id="Path_8655" data-name="Path 8655" d="M689.741,327.23H675.71a3.1,3.1,0,0,1-3.1-3.1V310.1a3.1,3.1,0,0,1,3.1-3.1h14.031a3.1,3.1,0,0,1,3.1,3.1v14.031A3.1,3.1,0,0,1,689.741,327.23ZM675.71,307.653a2.45,2.45,0,0,0-2.447,2.447v14.031a2.45,2.45,0,0,0,2.447,2.447h14.031a2.45,2.45,0,0,0,2.447-2.447V310.1a2.45,2.45,0,0,0-2.447-2.447Z" transform="translate(-672.61 -307)" fill="#d1d1d1"/>
              <path id="Path_8656" data-name="Path 8656" d="M697.547,326.373a2.937,2.937,0,1,1,2.937-2.937A2.937,2.937,0,0,1,697.547,326.373Z" transform="translate(-687.432 -316.095)" fill="#d1d1d1"/>
              <path id="Path_8657" data-name="Path 8657" d="M697.84,345.394h-5.864a1.22,1.22,0,0,1-1.283-1.142,4.243,4.243,0,0,1,8.43,0A1.22,1.22,0,0,1,697.84,345.394Z" transform="translate(-684.793 -329.569)" fill="#d1d1d1"/>
            </g>
            <g id="Group_1867" data-name="Group 1867" transform="translate(57.143 1.844)">
              <path id="Path_8655-2" data-name="Path 8655" d="M689.741,327.23H675.71a3.1,3.1,0,0,1-3.1-3.1V310.1a3.1,3.1,0,0,1,3.1-3.1h14.031a3.1,3.1,0,0,1,3.1,3.1v14.031A3.1,3.1,0,0,1,689.741,327.23ZM675.71,307.653a2.45,2.45,0,0,0-2.447,2.447v14.031a2.45,2.45,0,0,0,2.447,2.447h14.031a2.45,2.45,0,0,0,2.447-2.447V310.1a2.45,2.45,0,0,0-2.447-2.447Z" transform="translate(-672.61 -307)" fill="#d1d1d1"/>
              <path id="Path_8656-2" data-name="Path 8656" d="M697.547,326.373a2.937,2.937,0,1,1,2.937-2.937A2.937,2.937,0,0,1,697.547,326.373Z" transform="translate(-687.432 -316.095)" fill="#d1d1d1"/>
              <path id="Path_8657-2" data-name="Path 8657" d="M697.84,345.394h-5.864a1.22,1.22,0,0,1-1.283-1.142,4.243,4.243,0,0,1,8.43,0A1.22,1.22,0,0,1,697.84,345.394Z" transform="translate(-684.793 -329.569)" fill="#d1d1d1"/>
            </g>
            <g id="Group_1868" data-name="Group 1868" transform="translate(88.833 11.527)">
              <path id="Path_8655-3" data-name="Path 8655" d="M689.741,327.23H675.71a3.1,3.1,0,0,1-3.1-3.1V310.1a3.1,3.1,0,0,1,3.1-3.1h14.031a3.1,3.1,0,0,1,3.1,3.1v14.031A3.1,3.1,0,0,1,689.741,327.23ZM675.71,307.653a2.45,2.45,0,0,0-2.447,2.447v14.031a2.45,2.45,0,0,0,2.447,2.447h14.031a2.45,2.45,0,0,0,2.447-2.447V310.1a2.45,2.45,0,0,0-2.447-2.447Z" transform="translate(-672.61 -307)" fill="#d1d1d1"/>
              <path id="Path_8656-3" data-name="Path 8656" d="M697.547,326.373a2.937,2.937,0,1,1,2.937-2.937A2.937,2.937,0,0,1,697.547,326.373Z" transform="translate(-687.432 -316.095)" fill="#d1d1d1"/>
              <path id="Path_8657-3" data-name="Path 8657" d="M697.84,345.394h-5.864a1.22,1.22,0,0,1-1.283-1.142,4.243,4.243,0,0,1,8.43,0A1.22,1.22,0,0,1,697.84,345.394Z" transform="translate(-684.793 -329.569)" fill="#d1d1d1"/>
            </g>
            <g id="Group_1869" data-name="Group 1869" transform="translate(88.833 42.336)">
              <path id="Path_8655-4" data-name="Path 8655" d="M689.741,327.23H675.71a3.1,3.1,0,0,1-3.1-3.1V310.1a3.1,3.1,0,0,1,3.1-3.1h14.031a3.1,3.1,0,0,1,3.1,3.1v14.031A3.1,3.1,0,0,1,689.741,327.23ZM675.71,307.653a2.45,2.45,0,0,0-2.447,2.447v14.031a2.45,2.45,0,0,0,2.447,2.447h14.031a2.45,2.45,0,0,0,2.447-2.447V310.1a2.45,2.45,0,0,0-2.447-2.447Z" transform="translate(-672.61 -307)" fill="#d1d1d1"/>
              <path id="Path_8656-4" data-name="Path 8656" d="M697.547,326.373a2.937,2.937,0,1,1,2.937-2.937A2.937,2.937,0,0,1,697.547,326.373Z" transform="translate(-687.432 -316.095)" fill="#d1d1d1"/>
              <path id="Path_8657-4" data-name="Path 8657" d="M697.84,345.394h-5.864a1.22,1.22,0,0,1-1.283-1.142,4.243,4.243,0,0,1,8.43,0A1.22,1.22,0,0,1,697.84,345.394Z" transform="translate(-684.793 -329.569)" fill="#d1d1d1"/>
            </g>
            <path id="Path_8643" data-name="Path 8643" d="M431.741,327.23H417.71a3.1,3.1,0,0,1-3.1-3.1V310.1a3.1,3.1,0,0,1,3.1-3.1h14.031a3.1,3.1,0,0,1,3.1,3.1v14.031A3.1,3.1,0,0,1,431.741,327.23ZM417.71,307.653a2.45,2.45,0,0,0-2.447,2.447v14.031a2.45,2.45,0,0,0,2.447,2.447h14.031a2.45,2.45,0,0,0,2.447-2.447V310.1a2.45,2.45,0,0,0-2.447-2.447Z" transform="translate(-357.146 -276.107)" fill="#d3d3d3"/>
            <circle id="Ellipse_291" data-name="Ellipse 291" cx="7.561" cy="7.561" r="7.561" transform="translate(25.449 38.084)" fill="#eaeaea"/>
            <path id="Path_8645" data-name="Path 8645" d="M300.291,424.382q-.143,0-.287-.013a3.387,3.387,0,0,1-3.04-4.038,3.182,3.182,0,0,1,.083-.322l-11.159-13.2a2.63,2.63,0,0,1-.183-3.135l.02-.025,10.045-9.9a2.9,2.9,0,1,1,4.039,4.172l-7.275,6.916,7.964,12.783a3.221,3.221,0,0,1,.449.055,3.387,3.387,0,0,1-.656,6.708Z" transform="translate(-270.011 -333.998)" fill="#eaeaea"/>
            <path id="Path_8646" data-name="Path 8646" d="M139.327,431.645H143.1l1.8-14.557h-5.57Z" transform="translate(-93.864 -280.772)" fill="#eaeaea"/>
            <path id="Path_8647" data-name="Path 8647" d="M374.878,670.924h7.433a4.737,4.737,0,0,1,4.737,4.737v.154H374.878Z" transform="translate(-330.378 -521.284)" fill="#c7c7c7"/>
            <path id="Path_8648" data-name="Path 8648" d="M24.366,419.885l3.243,1.931,8.99-11.589-4.786-2.85Z" transform="translate(-16.415 -274.23)" fill="#eaeaea"/>
            <path id="Path_8649" data-name="Path 8649" d="M257.1,653.955l6.386,3.8h0a4.737,4.737,0,0,1,1.646,6.493l-.079.132L254.6,658.158Z" transform="translate(-249.344 -509.852)" fill="#c7c7c7"/>
            <path id="Path_8650" data-name="Path 8650" d="M309.453,515.63h-4.226a1.383,1.383,0,0,1-1.378-1.239l-4.26-40.049a.462.462,0,0,0-.88-.141l-7.686,17.019-13.608,22.189a1.392,1.392,0,0,1-1.739.544l-5.436-2.392a1.386,1.386,0,0,1-.631-1.979l12.509-20.946,3.836-17.648c-1.207-7.653,3.966-19.032,4.019-19.146l.026-.056,14.249-5.569.071.087c5.619,18.44,9.043,38.285,6.515,68.055A1.4,1.4,0,0,1,309.453,515.63Z" transform="translate(-259.326 -369.899)" fill="#c7c7c7"/>
            <path id="Path_8651" data-name="Path 8651" d="M317.8,411.21l-.066-.139c-.032-.067-3.2-6.749-5.642-13.891a10.534,10.534,0,0,1,.73-8.442,10.649,10.649,0,0,1,6.791-5.222h0a10.662,10.662,0,0,1,12.376,6.386c1.937,4.845,1.32,10.016.739,14.082l-.012.081-.074.035Z" transform="translate(-287.701 -327.457)" fill="#7c7c7c"/>
            <path id="Path_8652" data-name="Path 8652" d="M342.972,325.955H330.966v-5.233c2.635-1.047,5.214-1.937,6.772,0a5.233,5.233,0,0,1,5.233,5.233Z" transform="translate(-300.794 -284.582)" fill="#c7c7c7"/>
            <path id="Path_8653" data-name="Path 8653" d="M309.962,320.252c-7.177,0-9.186,9-9.186,14.072,0,2.83,1.28,3.843,3.292,4.185l.71-3.789,1.664,3.952c.565,0,1.159-.008,1.774-.02l.564-1.162,1.258,1.141c5.038.008,9.11.742,9.11-4.308C319.148,329.248,317.386,320.252,309.962,320.252Z" transform="translate(-280.455 -285.035)" fill="#c7c7c7"/>
            <path id="Path_8654" data-name="Path 8654" d="M371.452,347.48q.069.125.126.258a3.387,3.387,0,0,1-2.086,4.6,3.167,3.167,0,0,1-.322.081l-6.232,16.121a2.63,2.63,0,0,1-2.664,1.664l-.031-.006-13.508-4.066a2.9,2.9,0,1,1,1.725-5.545l9.557,3.069,7.4-13.118a3.23,3.23,0,0,1-.167-.421,3.387,3.387,0,0,1,6.2-2.64Z" transform="translate(-310.043 -302.194)" fill="#eaeaea"/>
            <g id="Group_1865" data-name="Group 1865" transform="translate(141.649 30.893)">
              <path id="Path_8655-5" data-name="Path 8655" d="M689.741,327.23H675.71a3.1,3.1,0,0,1-3.1-3.1V310.1a3.1,3.1,0,0,1,3.1-3.1h14.031a3.1,3.1,0,0,1,3.1,3.1v14.031A3.1,3.1,0,0,1,689.741,327.23ZM675.71,307.653a2.45,2.45,0,0,0-2.447,2.447v14.031a2.45,2.45,0,0,0,2.447,2.447h14.031a2.45,2.45,0,0,0,2.447-2.447V310.1a2.45,2.45,0,0,0-2.447-2.447Z" transform="translate(-672.61 -307)" fill="#3f3d56"/>
              <path id="Path_8656-5" data-name="Path 8656" d="M697.547,326.373a2.937,2.937,0,1,1,2.937-2.937A2.937,2.937,0,0,1,697.547,326.373Z" transform="translate(-687.432 -316.095)" fill="#fb5b5a"/>
              <path id="Path_8657-5" data-name="Path 8657" d="M697.84,345.394h-5.864a1.22,1.22,0,0,1-1.283-1.142,4.243,4.243,0,0,1,8.43,0A1.22,1.22,0,0,1,697.84,345.394Z" transform="translate(-684.793 -329.569)" fill="#fb5b5a"/>
            </g>
            <path id="Path_8658" data-name="Path 8658" d="M588.868,429.67h-4l-1.9-15.43h5.9Z" transform="translate(-392.745 -278.854)" fill="#a0616a"/>
            <path id="Path_8659" data-name="Path 8659" d="M570.011,458.025h7.715v4.858H565.154a4.857,4.857,0,0,1,4.857-4.858Z" transform="translate(-380.745 -308.352)" fill="#2f2e41"/>
            <path id="Path_8660" data-name="Path 8660" d="M660.334,404.089l-2.956,2.7-11.805-10.116,4.362-3.979Z" transform="translate(-434.924 -264.335)" fill="#a0616a"/>
            <path id="Path_8661" data-name="Path 8661" d="M4.858,0h7.715V4.858H0A4.858,4.858,0,0,1,4.858,0Z" transform="matrix(0.739, -0.674, 0.674, 0.739, 215.983, 146.806)" fill="#2f2e41"/>
            <path id="Path_8662" data-name="Path 8662" d="M817.013,543.9a1.46,1.46,0,0,1-1.45-1.258l-3.486-24.11a14,14,0,0,1-.049-3.629l3.4-29.032,21.7,1.822L833.849,515.2l12.388,15.447a1.465,1.465,0,0,1-.181,2.026l-3.119,2.717a1.468,1.468,0,0,1-2.081-.153L829.118,521.5a18.447,18.447,0,0,1-3.824-7.362l-1.676-6.536a.489.489,0,0,0-.937-.038l-2.217,6.4,1.4,28.2a1.472,1.472,0,0,1-1.385,1.539l-3.385.188Z" transform="translate(-624.823 -396.614)" fill="#2f2e41"/>
            <circle id="Ellipse_292" data-name="Ellipse 292" cx="8.014" cy="8.014" r="8.014" transform="translate(196.233 33.999)" fill="#a0616a"/>
            <path id="Path_8663" data-name="Path 8663" d="M721.2,360.049a3.281,3.281,0,0,0,4.83,1.409l10.508,6.366-.817-5.384-8.894-5.748a3.3,3.3,0,0,0-5.627,3.356Z" transform="translate(-563.506 -308.718)" fill="#a0616a"/>
            <path id="Path_8664" data-name="Path 8664" d="M834.455,414.984h0a26.248,26.248,0,0,1-13.165-3.925,1.629,1.629,0,0,1-.708-1.979l4.166-11.227-.948-13.228a11.48,11.48,0,0,1,5.715-10.882,10.309,10.309,0,0,1,8.993-.532,11.086,11.086,0,0,1,6.387,7.28l1.338,4.653a28.548,28.548,0,0,1-.534,17.327l-3.875,10.879-.1.06A15.3,15.3,0,0,1,834.455,414.984Z" transform="translate(-630.58 -320.184)" fill="#ccc"/>
            <path id="Path_8665" data-name="Path 8665" d="M738.281,369.8a1.63,1.63,0,0,1,.017-1.832l2.031-2.933a1.634,1.634,0,0,1,2.094-.519l15.059,7.831,16.615-1.666a4.615,4.615,0,1,1,.507,9.206l-15.494.157a9.53,9.53,0,0,1-4.669-1.171l-15.586-8.547A1.638,1.638,0,0,1,738.281,369.8Z" transform="translate(-575.02 -314.73)" fill="#ccc"/>
            <path id="Path_8666" data-name="Path 8666" d="M874.56,492.213a3.281,3.281,0,0,0,.151-5.029l3.913-10.773-5.4,1.145-3.33,10.053a3.3,3.3,0,0,0,4.662,4.6Z" transform="translate(-663.406 -390.24)" fill="#a0616a"/>
            <path id="Path_8667" data-name="Path 8667" d="M875.147,419.651a1.62,1.62,0,0,1-.56-.1l-3.349-1.229a1.634,1.634,0,0,1-1.028-1.9l3.8-16.543-5.785-15.664a4.615,4.615,0,1,1,8.783-2.8l4.044,14.958a9.531,9.531,0,0,1,.039,4.814l-4.359,17.233a1.631,1.631,0,0,1-1.582,1.231Z" transform="translate(-662.557 -323.943)" fill="#ccc"/>
            <path id="Path_8668" data-name="Path 8668" d="M848.828,323.56c.406-1.686.607-2.633,1.01-4.32a2.7,2.7,0,0,0-1.565-2.815,9.091,9.091,0,0,0-3.345-.7,5.852,5.852,0,0,1-3.226-1.045,3.8,3.8,0,0,1-.948-4.263,4.847,4.847,0,0,1,3.484-2.785,6.918,6.918,0,0,1,4.479.69,3.155,3.155,0,0,1,3.436-1.593,8.509,8.509,0,0,1,3.591,1.855c2.024,1.528,4.1,3.381,4.551,5.9.434,2.433-.8,4.888-2.451,6.707a14.842,14.842,0,0,1-8.576,4.654A3.038,3.038,0,0,1,848.828,323.56Z" transform="translate(-644.051 -275.892)"/>
            <path id="Path_8669" data-name="Path 8669" d="M830.475,685.653H767.826a.326.326,0,0,1,0-.653h62.649a.326.326,0,1,1,0,.653Z" transform="translate(-594.889 -530.767)" fill="#ccc"/>
            <path id="Path_8670" data-name="Path 8670" d="M301.475,685.653H238.826a.326.326,0,1,1,0-.653h62.649a.326.326,0,1,1,0,.653Z" transform="translate(-238.5 -530.767)" fill="#ccc"/>
            <g id="Group_1870" data-name="Group 1870" transform="translate(141.649 58.181)">
              <path id="Path_8655-6" data-name="Path 8655" d="M689.741,327.23H675.71a3.1,3.1,0,0,1-3.1-3.1V310.1a3.1,3.1,0,0,1,3.1-3.1h14.031a3.1,3.1,0,0,1,3.1,3.1v14.031A3.1,3.1,0,0,1,689.741,327.23ZM675.71,307.653a2.45,2.45,0,0,0-2.447,2.447v14.031a2.45,2.45,0,0,0,2.447,2.447h14.031a2.45,2.45,0,0,0,2.447-2.447V310.1a2.45,2.45,0,0,0-2.447-2.447Z" transform="translate(-672.61 -307)" fill="#3f3d56"/>
              <path id="Path_8656-6" data-name="Path 8656" d="M697.547,326.373a2.937,2.937,0,1,1,2.937-2.937A2.937,2.937,0,0,1,697.547,326.373Z" transform="translate(-687.432 -316.095)" fill="#fb5b5a"/>
              <path id="Path_8657-6" data-name="Path 8657" d="M697.84,345.394h-5.864a1.22,1.22,0,0,1-1.283-1.142,4.243,4.243,0,0,1,8.43,0A1.22,1.22,0,0,1,697.84,345.394Z" transform="translate(-684.793 -329.569)" fill="#fb5b5a"/>
            </g>
          </g>
        </svg>
    );
}

export default SvgFile;