/* eslint-disable */
import React from 'react';
import styled, { css } from 'styled-components';
import { Button, Table, Popover, OverlayTrigger, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';

import useSocket from '../components/util/useSocket';
import { getFirstError } from '../api/AuthenticatedFetch';
import EmailsApi from '../api/emails/Emails';
import { ContactEmailDTO } from '../api/emails/ResponseTypes';
import { actionCreators as profileActionCreators } from '../store/Profile';
import InternalTracker from 'src/InternalTracker';

const getStatusText = ({
    verified,
    primaryEmail
}: Pick<ContactEmailDTO, 'verified' | 'primaryEmail'>) => {
    if (primaryEmail) return 'Primary';
    if (verified) return 'Verified';
    return 'Unverified';
};

const RemoveEmailConfirmationModal = ({ close, onConfirm, children }) => (
    <Modal show={true} onHide={close}>
        <Modal.Header closeButton>
            <Modal.Title>Remove Email</Modal.Title>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
        <Modal.Footer>
            <Button onClick={close}>Cancel</Button>
            <Button onClick={onConfirm} bsStyle="danger">
                Remove Email
            </Button>
        </Modal.Footer>
    </Modal>
);

const ManageEmails = () => {
    const {
        emailList,
        requestEmailList,
        setPrimaryEmail,
        addNewEmail,
        removeEmail,
        emailIdToDelete,
        closeDeletionProcess,
        resendVerification
    } = useEmailManagement();

    const makeRequest = React.useCallback(requestEmailList, []);

    React.useEffect(() => {
        makeRequest();
    }, [makeRequest]);

    return (
        <Wrapper isMobile={document.body.classList.contains('mobile-page')}>
            <div className="row" data-section="close-account">
                <div className="hpanel">
                    <div className="panel-body">
                        {emailIdToDelete != null && (
                            <RemoveEmailConfirmationModal
                                close={closeDeletionProcess}
                                onConfirm={() => {
                                    removeEmail(emailIdToDelete, true);
                                }}
                            >
                                Please confirm you wish to remove the email '
                                {
                                    emailList.find((email) => email.id === emailIdToDelete)
                                        ?.email
                                }
                                ' from your account.
                            </RemoveEmailConfirmationModal>
                        )}

                        <h2 className="font-light m-b-xs">Manage Secondary Emails</h2>
                        <small>
                            People can share their availability with you with all the secondary/alternative emails you add here.
                        </small>

                        <Button
                            bsStyle="success"
                            bsSize="medium"
                            style={{
                                margin: '10px 0 16px 0'
                            }}
                            onClick={() => {
                                let email = process.env.JEST_WORKER_ID !== undefined ? "ue-dummy@mailinator.com" : window.prompt("Enter email address");
                                InternalTracker.trackEvent("Alternative Email Added");
                                addNewEmail(email);
                            }}
                            data-testid="add-new-email"
                        >
                            <i className="fa fa-plus" /> Add Secondary Email
                        </Button>
                            <Table style={{ marginBottom: 0 }}>
                                <thead>
                                    <tr>
                                        <th>Email Address</th>
                                        <th>Status</th>
                                        <th />
                                    </tr>
                                </thead>
                                <tbody>
                                    {emailList.map(
                                        ({
                                            email,
                                            verified,
                                            primaryEmail,
                                            id
                                        }) => (
                                            <tr
                                                data-testid="email-row"
                                                key={id}
                                            >
                                                <td>{email}</td>
                                                <td>
                                                    <StatusText
                                                        verified={verified}
                                                        primary={
                                                            primaryEmail
                                                        }
                                                    >
                                                        {getStatusText({
                                                            verified,
                                                            primaryEmail
                                                        })}
                                                    </StatusText>
                                                </td>
                                                <td 
                                                    style={{
                                                        textAlign: 'right'
                                                    }}
                                                >
                                                    {!primaryEmail &&
                                                        verified && (
                                                            <>
                                                                <OverlayTrigger
                                                                    trigger={[
                                                                        'hover',
                                                                        'focus'
                                                                    ]}
                                                                    placement="right"
                                                                    overlay={
                                                                        <Popover id="make-primary-popover">
                                                                            Make
                                                                            this
                                                                            email
                                                                            your
                                                                            primary
                                                                            email
                                                                        </Popover>
                                                                    }
                                                                >
                                                                    <TableButton
                                                                        className="fa fa-level-up"
                                                                        role="button"
                                                                        onClick={() => {
                                                                            setPrimaryEmail(
                                                                                id
                                                                            );
                                                                        }}
                                                                        disabled={
                                                                            !verified
                                                                        }
                                                                        aria-label="Make Primary"
                                                                    />
                                                                </OverlayTrigger>
                                                            </>
                                                        )}

                                                    {!primaryEmail &&
                                                        !verified && (
                                                            <OverlayTrigger
                                                                trigger={[
                                                                    'hover',
                                                                    'focus'
                                                                ]}
                                                                placement="right"
                                                                overlay={
                                                                    <Popover id="resend-popover">
                                                                        Resend
                                                                        the
                                                                        verification
                                                                        email
                                                                        for
                                                                        this
                                                                        email
                                                                        address
                                                                    </Popover>
                                                                }
                                                            >
                                                                <TableButton
                                                                    className="fa fa-paper-plane"
                                                                    role="button"
                                                                    style={{
                                                                        marginLeft: 0
                                                                    }}
                                                                    onClick={() =>
                                                                        resendVerification(
                                                                            email
                                                                        )
                                                                    }
                                                                    aria-label="Resend Verification Email"
                                                                />
                                                            </OverlayTrigger>
                                                        )}

                                                    {!primaryEmail && (
                                                        <OverlayTrigger
                                                            trigger={[
                                                                'hover',
                                                                'focus'
                                                            ]}
                                                            placement="right"
                                                            overlay={
                                                                <Popover id="delete-popover">
                                                                    Remove
                                                                    this
                                                                    email
                                                                    from
                                                                    your
                                                                    account
                                                                </Popover>
                                                            }
                                                        >
                                                            <TableButton
                                                                className="fa fa-trash"
                                                                role="button"
                                                                style={{
                                                                    marginLeft: 10
                                                                }}
                                                                onClick={() => {
                                                                    InternalTracker.trackEvent("Alternative Email Removed");
                                                                    removeEmail(
                                                                        id
                                                                    )
                                                                }}
                                                                aria-label="Remove Email"
                                                            />
                                                        </OverlayTrigger>
                                                    )}
                                                </td>
                                            </tr>
                                        )
                                    )}
                                </tbody>
                            </Table>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
};

const useEmailManagement = () => {
    const [emailList, setEmailList] = React.useState<ContactEmailDTO[]>([]);
    const [newEmail, setNewEmail] = React.useState('');
    const dispatch = useDispatch();

    const [emailIdToDelete, setEmailIdToDelete] = React.useState<
        ContactEmailDTO['id'] | null
    >(null);

    useSocket('activation', 'EmailsUpdated', () => requestEmailList());
    
    const requestEmailList = async () => {
        const { results: emails } = await EmailsApi.getAccountEmails();
        setEmailList(emails);
    };

    const setPrimaryEmail = (id: ContactEmailDTO['id']) => {
        const { verified } = emailList.find((item) => item.id === id)!;

        if (!verified) {
            const message =
                'Please verify this email before setting it as your primary email';
            toast.error(message);
            return;
        }

        InternalTracker.trackEvent("Email Set as Default");

        EmailsApi.updatePrimaryEmail(id).then(() => {
            toast.success('Primary email updated');
            requestEmailList();
            // TODO: Potentially update claim here rather than userId providing claim also requests user new profile data
            dispatch(profileActionCreators.getUserProfile());
        });
    };

    const addNewEmail = (newEmail) => {
        EmailsApi.addEmail(newEmail)
            .then(() => {
                setNewEmail('');
                toast.success(`Verification email sent to ${newEmail}`);
                requestEmailList();
            })
            .catch((errorResponse) => {
                const errorMessage =
                    getFirstError(errorResponse) ||
                    'Unable to add email to account';
                toast.error(errorMessage);
            });
    };

    const resendVerification = (emailAddress: ContactEmailDTO['email']) => {
        InternalTracker.trackEvent("Email Verification Resent");
        EmailsApi.addEmail(emailAddress).then(() => {
            toast.success(`Verification email sent to ${emailAddress}`);
        });
    };

    const guardedRemoveEmail = (
        id: ContactEmailDTO['id'],
        skipCheck = false
    ) => {
        const { verified } = emailList.find((email) => email.id === id)!;
        if (!verified || skipCheck) return removeEmail(id);
        setEmailIdToDelete(id);
    };

    const removeEmail = (id: ContactEmailDTO['id']) => {
        setEmailIdToDelete(null);
        EmailsApi.removeEmail(id).then(() => {
            toast.success('Email address removed from account');
            requestEmailList();
        });
    };

    return {
        emailList: emailList.sort((email1, email2) => {
            if (email1.primaryEmail) return -1;
            if (email2.primaryEmail) return 1;
            if (email1.verified && !email2.verified) return -1;
            if (!email1.verified && email2.verified) return 1;
            return email1.email.localeCompare(email2.email);
        }),
        requestEmailList,
        setPrimaryEmail,
        addNewEmail,
        resendVerification,
        removeEmail: guardedRemoveEmail,
        emailIdToDelete,
        closeDeletionProcess: () => setEmailIdToDelete(null)
    };
};

const Wrapper = styled.div`
    ${({ isMobile }) =>
        isMobile &&
        css`
            text-align: center;

            .title-row {
                small {
                    display: none;
                }
            }

            .panel,
            .panel-body {
                background: none;
                border: none;
            }

            table {
                margin: 0 auto;
                text-align: left;
            }
        `}

    @media screen and (max-width: 480px) {
        table td {
            vertical-align: middle !important;
        }

        table td:first-of-type {
            max-width: 140px;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .table-responsive {
        border: none;
    }

    td:first-of-type,
    th:first-of-type {
        padding-left: 0;
    }
`;

const TableButton = styled.i`
    width: 15px;
    height: 15px;
    margin-left: 25px;
    font-size: 15px;
    text-align: center;
    cursor: pointer;
    opacity: 0.7;

    &:hover {
        opacity: 1;
    }
`;

const StatusText = styled.span`
    color: ${({ verified }) => (verified ? 'green' : 'red')};
    font-weight: ${({ primary }) => (primary ? 'bold' : 'normal')};
`;

export default ManageEmails;
