/* eslint-disable */
import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import styled from 'styled-components';
import { ToastType } from 'react-toastify';

import InlineOrganisationImageSuggestions from './InlineOrganisationImageSuggestions';
import { ProfileImage } from '../ui-components/ProfileImage';
import { supportedImageFormats } from '../../constants';
import * as Notification from '../../store/Notifications';

interface Props {
    show: boolean;
    onHide: Function;
    onConfirm: (logoUrl: string | null) => void;
    organisationName: string;
    currentImageUrl: string;
    handleSelectedFiles: (files: FileList | null) => void;
}

const OrganisationImageSuggestions = ({
    organisationName,
    show,
    onHide,
    onConfirm,
    currentImageUrl,
    handleSelectedFiles
}: Props) => {
    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header>
                <Modal.Title>Organization Profile Image</Modal.Title>
            </Modal.Header>
            <Modal.Body className="">
                <div className="form-group">
                    <div className="row">
                        <label
                            className="col-sm-2 control-label"
                            style={{
                                textAlign: 'right',
                                paddingTop: '7px',
                                paddingLeft: 0,
                                paddingRight: 0
                            }}
                        >
                            Organization Logo
                        </label>
                        <div className="col-sm-3">
                            <ProfileImage
                                type="Organisation"
                                url={currentImageUrl}
                                size={72}
                                selectable={false}
                            />
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <label
                            className="col-sm-2 control-label"
                            style={{
                                textAlign: 'right',
                                paddingTop: '7px',
                                paddingLeft: 0,
                                paddingRight: 0
                            }}
                        >
                            Suggestions
                        </label>
                        <div className="col-sm-7">
                            <InlineOrganisationImageSuggestions
                                organisationName={organisationName}
                                onSelect={(url: string) => {
                                    onConfirm(url);
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <label
                            className="col-sm-2 control-label"
                            style={{
                                textAlign: 'right',
                                paddingTop: '7px',
                                paddingLeft: 0,
                                paddingRight: 0
                            }}
                        >
                            Upload
                        </label>
                        <div className="col-sm-7">
                            <ManagedFieldInput
                                type="file"
                                name="organisationProfileImageFile"
                                id="organisationProfileImageFile"
                                className="form-control"
                                onClick={(e) => {
                                    e.target.value = '';
                                }}
                                onChange={(e) => {
                                    handleSelectedFiles(e.target.files);
                                    onHide();
                                }}
                            />
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <ModalFooter>
                <a className="pull-left" href="https://clearbit.com">
                    Logos provided by Clearbit
                </a>
                <div>
                    <Button
                        onClick={() => {
                            //setLogoUrl(null);
                            onHide();
                        }}
                    >
                        Cancel
                    </Button>
                    {/*<Button*/}
                    {/*    onClick={() => {*/}
                    {/*        onConfirm(logoUrl);*/}
                    {/*        //setLogoUrl(null);*/}
                    {/*    }}*/}
                    {/*    bsStyle="primary"*/}
                    {/*>*/}
                    {/*    Confirm*/}
                    {/*</Button>*/}
                </div>
            </ModalFooter>
        </Modal>
    );
};

const ManagedFieldInput = styled.input`
    display: inline-block;
    &[type='file'] {
        display: inline-block;
    }
`;

const ModalFooter = styled(Modal.Footer)`
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:before,
    &:after {
        display: none;
    }
`;

export default OrganisationImageSuggestions;
