/* eslint-disable */
import afetch from '../AuthenticatedFetch';
import AppConfig from '../../components/config/Config';

// api functions
const apiCalls = {
    reportFile(fileId: number) {
        return afetch.request<null, null>(
            `${AppConfig.Settings.api.externalUri}/file/${fileId}/report`,
            'PUT',
            null,
            'Unable to report file'
        );
    }
};

export default apiCalls;
