import React from 'react';
import { Modal } from 'react-bootstrap';

interface Props {
    show: boolean;
    onHide: () => void;
    requireFace?: boolean;
}

export default class ImageUnsuitableModal extends React.Component<Props, {}> {
    render() {
        return (
            <Modal show={this.props.show} onHide={this.props.onHide}>
                <Modal.Body>
                    <div style={{ textAlign: 'center' }}>
                        <h3>Image unsuitable</h3>
                        <div style={{ margin: '25px 0' }}>
                            {UnsuitableMessage(this.props.requireFace)}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

export const UnsuitableMessage = (requireFace?: boolean) => {
    return `That image is not suitable - ${
        requireFace ? 'either it does not contain just one face or' : ''
    } it has been deemed ${
        requireFace ? 'otherwise' : ''
    } inappropriate to use for a profile photo. Please try another.`;
};
