/* eslint-disable */
import { UserProfileDto } from '../api/profile/RequestTypes';

import { Container } from '../unstated-fork/unstated';
import ProfileApi from '../api/profile/Profile';

export enum UserType {
    Unknown = 0,
    Worker = 1,
    Hirer = 2,
    Agency = 3
}

type UserTypeState = {
    loaded: boolean;
    type: UserType;
};

export class UserTypeContainer extends Container<UserTypeState> {
    state = {
        loaded: false,
        type: UserType.Unknown
    };

    constructor() {
        super();

        let localUser: UserProfileDto = localStorage.getItem('user')
            ? JSON.parse(localStorage.getItem('user') || '')
            : null;

        if (
            localUser &&
            localUser.userType !== undefined &&
            localUser.userType !== null &&
            localUser.userType !== 0
        ) {
            // We have the type locally at this point, no need to request it every time the page reloads
            this.setState({
                loaded: true,
                type: localUser.userType as UserType
            });
        } else {
            ProfileApi.getType().then((type) => {
                this.setState({
                    loaded: true,
                    type: type as UserType
                });
            });
        }
    }

    setType = async (type: UserType) => {
        this.setState({ type });
        await ProfileApi.setType(type);
    };

    reload = async () => {
        ProfileApi.getType().then((type) => {
            this.setState({
                loaded: true,
                type: type as UserType
            });
        });
    }
}
