/* eslint-disable */
import React from 'react';
import withWindowDimensions from '../components/util/withWindowDimensions';
import '../css/Screenings.css';
import VerificationApi from '../api/verification/Verification';
import ProfileApi from '../api/profile/Profile';
import FullScreenLoader from 'src/components/ui-components/FullScreenLoader';
import { WorkerVerificationResult } from 'src/api/verification/ResponseTypes';
import { StickyTable, Row, Cell } from 'react-sticky-table';
import { VerificationTypeId } from './ExternalVerification';
import moment from 'moment';
import theme from 'src/css/theme';
import '../css/DefaultFilters.css';
import Utilities from 'src/Utilities';
import { CircularProgress } from '@mui/material';
import { getValue, setValue } from 'src/db/KeyValueCache';
import SimpleTooltip from 'src/components/ui-components/SimpleTooltip';

type Props = { 
    windowWidth: number 
};

interface State {
    state: "active" | "inactive" | "all";
    loading: boolean,
    nameFilter: string,
    verificationsByWorker: {
        [workerId: string]: WorkerVerificationResult[]
    },
    searchParamsName?: string,
    searchType: VerificationTypeId | null,
    searchStatus: "active" | "inactive" | "all"
}

export class Screenings extends React.Component<
    Props,
    State
> {
    state: State = {
        state: "active",
        loading: true,
        nameFilter: "",
        verificationsByWorker: {},
        searchParamsName: "",
        searchType: null,
        searchStatus: "all"
    };

    async componentDidMount() {
        const cacheValue = await getValue("screenings");
        if (cacheValue) {
            this.setState({
                verificationsByWorker: JSON.parse(cacheValue)
            });
        } else {
            this.loadScreenings();
        }
        this.loadScreenings();
    }

    componentWillUnmount(): void {
        
    }

    async loadScreenings() {
        const res = await VerificationApi.getAll();
        console.log("Screenings", res);
        if (res.data) {
            this.setState({
                loading: false,
                verificationsByWorker: res.data.reduce((acc, verification) => {
                    if (!acc[verification.verifiedUserId]) {
                        acc[verification.verifiedUserId] = [];
                    }
                    acc[verification.verifiedUserId].push(verification);
                    return acc;
                }, {})
            });
            setValue("screenings", JSON.stringify(this.state.verificationsByWorker));
        }
    }

    render() {

        return (
            <div className='screenings-page'>
                { (this.state.loading) &&
                    <div className="nonblocking-loading-wrapper">
                        <CircularProgress style={{ width: 24, height: 24 }} />
                        Loading
                    </div>
                }
                <div className='default-filters col-3'>
                    <div className="filter skills">
                        <h3>
                            <i className="fas fa-user"></i>
                            <span>Name</span>
                        </h3>
                        <div className="simple-input">
                            <input
                                type="text"
                                value={this.state.searchParamsName}
                                id="search-by-name-input"
                                onChange={({ target: { name, value } }) => {
                                    this.setState({
                                        searchParamsName: value
                                    })
                                }}
                                placeholder="Search by Name"
                            />
                        </div>
                    </div>

                    <div className="filter skills">
                        <h3>
                            <i className="fas fa-shapes"></i>
                            <span>Type</span>
                        </h3>
                        <div className='simple-select'>
                            <select 
                                defaultValue={this.state.searchType?.toString()}
                                onChange={(e) => {
                                    this.setState({
                                        searchType: e.target.value === "null" ? null : parseInt(e.target.value)
                                    })
                                }}
                            >
                                {[
                                    { id: "null", name: "All" },
                                    { id: VerificationTypeId.Rejected, name: "Rejected" },
                                    { id: VerificationTypeId.Identity, name: "Verification & Suitability" },
                                    { id: VerificationTypeId.Representation, name: "Representation" },
                                    { id: VerificationTypeId.Vetting, name: "Safeguarding" },
                                    { id: VerificationTypeId.Suspension, name: "Issue" }
                                ].map((item, i) => {
                                    return (
                                        <option value={item.id === null ? "null" : item.id}>
                                            {item.name}
                                        </option>
                                    )
                                }) }
                            </select>
                        </div>
                    </div>

                    <div className="filter skills">
                        <h3>
                            <i className="fas fa-toggle-on"></i>
                            <span>Status</span>
                        </h3>
                        <div className='simple-select'>
                            <select 
                                defaultValue={this.state.searchStatus?.toString()}
                                onChange={(e) => {
                                    this.setState({
                                        searchStatus: e.target.value as "all" | "active" | "inactive"
                                    })
                                }}
                            >
                                {[
                                    { id: "all", name: "All" },
                                    { id: "active", name: "Active" },
                                    { id: "inactive", name: "Inactive" }
                                ].map((item, i) => {
                                    return (
                                        <option value={item.id}>
                                            {item.name}
                                        </option>
                                    )
                                }) }
                            </select>
                        </div>
                    </div>
                </div>
                <div className='legend'>
                    <div>
                        <div style={{ background: "#c7dcc1" }}></div>
                        <label>Active</label>
                    </div>
                    <div>
                        <div style={{ background: "#8e8e8e", opacity: 0.6 }}></div>
                        <label>Past</label>
                    </div>
                </div>
                <div className='screenings'>
                    <StickyTable
                        style={{  }}
                    >
                        <Row>
                            <Cell>
                                Contact
                            </Cell>
                            <Cell>
                                Type
                            </Cell>
                            <Cell>
                                Start Date
                            </Cell>
                            <Cell>
                                End Date
                            </Cell>
                        </Row>
                        { (Object.keys(this.state.verificationsByWorker).map(workerId => {
                            const verifications = this.state.verificationsByWorker[workerId].sort(Utilities.dynamicSort("start"));

                            if (this.state.searchParamsName && !verifications[0].verifiedUserName.toLowerCase().includes(this.state.searchParamsName.toLowerCase())) {
                                return null;
                            }

                            return (
                                <React.Fragment>
                                    { verifications.sort((a, b) => {
                                        // sort by active first (no endDate), then start date desc, then end date desc
                                        if (a.end && !b.end) {
                                            return 1;
                                        } else if (!a.end && b.end) {
                                            return -1;
                                        } else if (a.end && b.end) {
                                            return new Date(b.end).getTime() - new Date(a.end).getTime();
                                        } else {
                                            return new Date(b.start).getTime() - new Date(a.start).getTime();
                                        }
                                    }).map(verification => {
                                        const active = !verification.end;

                                        if (this.state.searchType !== null && verification.typeId !== this.state.searchType) {
                                            return null;
                                        }

                                        if (
                                            (this.state.searchStatus === "active" && verification.end) ||
                                            (this.state.searchStatus === "inactive" && !verification.end)
                                        ) {
                                            return null;
                                        }

                                        return (
                                            <Row 
                                                data-type="data"
                                                style={{ 
                                                    opacity: active ? 1 : 0.7,
                                                    background: active ? "#c7dcc1" : "whitesmoke",
                                                }}
                                            >
                                                <Cell>
                                                    <span>
                                                        <img src={ProfileApi.getProfileImageUrl(workerId)} alt="Profile" style={{ width: 24, height: 24, borderRadius: 12 }} />
                                                        <span>{verification.verifiedUserName}</span>
                                                    </span>
                                                </Cell>
                                                <Cell>
                                                    <span>
                                                        <i 
                                                            data-active={active ? "true" : "false"} 
                                                            className={"fas fa-" + (verification.typeId === VerificationTypeId.Rejected ? "ban" : verification.typeId === VerificationTypeId.Identity ? "id-badge" : verification.typeId === VerificationTypeId.Representation ? "handshake" : verification.typeId === VerificationTypeId.Suspension ? "exclamation-circle" : "clipboard-check" )}
                                                            style={{
                                                                color: !active ? theme.colours.grey : verification.typeId === VerificationTypeId.Suspension ? theme.colours.red2 : theme.colours.green2
                                                            }}
                                                        ></i>
                                                        <span
                                                            style={{
                                                                color: !active ? undefined : verification.typeId === VerificationTypeId.Suspension ? theme.colours.red2 : undefined
                                                            }}
                                                        >
                                                            {
                                                                (verification.typeId === VerificationTypeId.Identity ? ("Approved & Suitable Profile") : "") +
                                                                (verification.typeId === VerificationTypeId.Representation ? ("Represented") : "") + 
                                                                (verification.typeId === VerificationTypeId.Suspension ? ("Active Issue") : "") +
                                                                (verification.typeId === VerificationTypeId.Vetting ? ("Compliance Requirements Met") : "") + 
                                                                (verification.typeId === VerificationTypeId.Rejected ? ("Rejected Verification") : "")
                                                            }
                                                            { (verification.typeId === VerificationTypeId.Rejected) &&
                                                                <SimpleTooltip
                                                                    id={"verification-help"}
                                                                    text={"The verification request has been previously rejected by marking as unsuitable or unknown. Your contact can re-request it in the future"}
                                                                >
                                                                    <i 
                                                                        className="fas fa-question-circle"
                                                                        style={{
                                                                            marginLeft: 6
                                                                        }}
                                                                    ></i>
                                                                </SimpleTooltip>
                                                            }
                                                        </span>
                                                    </span>
                                                </Cell>
                                                <Cell>
                                                    <span>
                                                        <span>{moment(verification.start).format("DD MMM YYYY HH:MM")} by </span>
                                                        <img src={ProfileApi.getProfileImageUrl(verification.verifyingUserId)} alt="Profile" style={{ width: 24, height: 24, borderRadius: 12 }} />
                                                        <span>{verification.verifyingUserName}</span>
                                                    </span>
                                                </Cell>
                                                <Cell>
                                                    { (verification.end) &&
                                                        <span>
                                                            <span>{moment(verification.end).format("DD MMM YYYY HH:MM")} by </span>
                                                            <img src={ProfileApi.getProfileImageUrl(verification.endedByUserId)} alt="Profile" style={{ width: 24, height: 24, borderRadius: 12 }} />
                                                            <span>{verification.endedByUserName}</span>
                                                        </span>
                                                    }
                                                </Cell>
                                            </Row>
                                        )
                                    }) }
                                </React.Fragment>
                            )
                        }) ) }
                    </StickyTable>
                </div>
            </div>
        );
    }
}

export default withWindowDimensions(Screenings);
