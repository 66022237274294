/* eslint-disable */
import React from 'react';
import '../../css/SimplePrompt.css'
import SvgRota from '../svg/rota';

interface Props {
    id?: string;
    title?: string;
    body?: string;
    buttons: Array<{
        text: string;
        onClick: () => void;
        loading?: boolean;
    }>;
}

export default class SimplePrompt extends React.Component<Props, {}> {
    render() {
       return (
            <div className="simple-prompt">
                <div>
                    { (this.props.id === "rota") &&
                        <div className='image'>
                            <SvgRota />
                        </div>
                    }
                    { (this.props.title !== undefined) &&
                        <h2>{this.props.title}</h2>
                    }
                    { (this.props.body !== undefined) &&
                        <p>{this.props.body}</p>
                    }
                    <div className="options">
                        { this.props.buttons?.map((button, index) =>
                            <button 
                                data-disabled={button.loading} 
                                key={index} 
                                onClick={() => {
                                    if (!button.loading) {
                                        button.onClick();
                                    }
                                }}
                            >
                                {
                                    button.loading ? 
                                    <i className="fa fa-spinner fa-spin" />
                                    :
                                    button.text
                                }
                            </button>
                        ) }
                    </div>
                </div>
            </div>
       )
    };
}
