/* eslint-disable */
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Glyphicon } from 'react-bootstrap';
import { ProfileVerificationContainer } from '../state-containers/profile-verification.container';
import UserProfileEditor from '../components/profile/UserProfileEditor';
import { Subscribe } from '../unstated-fork/unstated';
import styled from 'styled-components';
import theme from '../css/theme';

export type Props = RouteComponentProps<{}>;

export default class Profile extends React.Component<Props, {}> {
    public render() {
        return (
            <div>
                <div className="row">
                    <div className="hpanel">
                        <div className="panel-body">
                            <h2>
                                Your Personal Profile
                            </h2>
                            <small>Update your personal profile here.</small>
                            <Subscribe to={[ProfileVerificationContainer]}>
                                {(
                                    verification: ProfileVerificationContainer
                                ) => (
                                    <div>
                                        <ShieldWrapper
                                            className="col-sm-9 col-xs-9 layout horizontal center"
                                            style={{
                                                paddingTop: '10px',
                                                paddingLeft: 0
                                            }}
                                        >
                                            <i
                                                className={
                                                    'fa fa-shield ' +
                                                    (verification.state.verified
                                                        ? 'checked'
                                                        : '')
                                                }
                                            />
                                            <span>
                                                {verification.state.verified
                                                    ? 'Verified Profile'
                                                    : 'Unverified Profile'}
                                            </span>
                                        </ShieldWrapper>
                                    </div>
                                )}
                            </Subscribe>
                            <UserProfileEditor />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export const ShieldWrapper = styled.div`
    .fa-shield {
        margin-right: 5px;
        color: ${theme.colours.red};
        font-size: 20px;

        &.checked {
            color: ${theme.colours.green};

            + span {
                color: ${theme.colours.green} !important;
            }
        }

        + span {
            color: ${theme.colours.red};
            font-weight: 600;
        }
    }
`;