/* eslint-disable */

import React from 'react';
import Utilities from '../Utilities';
import Cookies from 'universal-cookie';

// import { version } from '../release-notes-version.json';
// import notes from '../release-notes.md';

const version = 8;

const notes = `
## March 2024
 
We've improved the layout of the availability page to ensure it displays correctly on devices with smaller screens. 

Additionally, you'll now notice a highlight notification whenever a worker begins sharing their profile with you, even if it doesn't match your current filter settings.

## November 2023

We made it easier to see who has applied and been confirmed at a glance in your offers.

We made some improvements for managing time presets.

We merged all your existing threads, and contacts into one place, so you can start conversations faster.

## October 2023

We added representing agencies for workers on the availability, offer, and calendar pages.

We made it easier to invite new workers that match your requirements without the need for their email ([Invite](/invite)).

We enabled schedule printing ([Schedule](/schedule)).
`;

const COOKIE_NAME = 'ue_release_version_read';

/**
 * Works like the 'spaceship operator'
 * Returns 1 if value a is greater
 * Returns -1 if value b is greater
 * Returns 0 if the values are the same
 */
const compareVersions = (a: string, b: string) => {
    if (a === b) return 0;
    const [a1, a2, a3, a4] = a.split('.').map(Number);
    const [b1, b2, b3, b4] = b.split('.').map(Number);

    if (
        a1 > b1 ||
        (a1 === b1 && a2 > b2) ||
        (a1 === b1 && a2 === b2 && a3 > b3) ||
        (a1 === b1 && a2 === b2 && a3 === b3 && a4 > b4)
    )
        return 1;

    return -1;
};

class ReleaseNotesVersionService {
    private static context = new Cookies();

    static getContext() {
        return ReleaseNotesVersionService.context;
    }

    static getReleaseNotesVersion() {
        return version;
    }

    static updateReadReleaseNotesVersion() {
        ReleaseNotesVersionService.getContext().set(COOKIE_NAME, version, {
            expires: Utilities.dateAdd(new Date(), 'year', 1)
        });
    }

    static isReadVersionOutdated() {
        if ((window as any).Cypress) {
            return false;
        }
        const user = localStorage.getItem('dashboard') ? JSON.parse(localStorage.getItem('dashboard') || "{}") : {}; //  ReleaseNotesVersionService.getReadReleaseNotesVersion();
        const readVersion = user?.portalUpdatesVersion || 0;
        return readVersion < version;
    }

    static getReadReleaseNotesVersion() {
        const user = localStorage.getItem('dashboard') ? JSON.parse(localStorage.getItem('dashboard') || "{}") : {}; //  ReleaseNotesVersionService.getReadReleaseNotesVersion();
        const readVersion = user?.portalUpdatesVersion || 0;
        return readVersion;
    }

    static getLastReleaseNotesVersion() {
        return version;
    }

    static subscribe(callback) {
        ReleaseNotesVersionService.getContext().addChangeListener(callback);
    }

    static unsubscribe(callback) {
        ReleaseNotesVersionService.getContext().removeChangeListener(callback);
    }

    static getReleaseNotes() {
        return notes;
    }
}

export class ReleaseNotesVersionServiceProvider extends React.Component<
    { render: Function },
    { newReleaseNotesAvailable: boolean }
> {
    constructor(props) {
        super(props);
        this.state = {
            newReleaseNotesAvailable: ReleaseNotesVersionService.isReadVersionOutdated()
        };
        this.callback = this.callback.bind(this);
    }

    callback({ name }) {
        if (name !== COOKIE_NAME) return;
        const newReleaseNotesAvailable = ReleaseNotesVersionService.isReadVersionOutdated();
        this.setState({ newReleaseNotesAvailable });
    }

    componentDidMount() {
        ReleaseNotesVersionService.subscribe(this.callback);
    }

    componentWillUnmount() {
        ReleaseNotesVersionService.unsubscribe(this.callback);
    }

    render() {
        const { newReleaseNotesAvailable } = this.state;
        return this.props.render({ newReleaseNotesAvailable });
    }
}

export default ReleaseNotesVersionService;