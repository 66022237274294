import React from 'react';
import styled from 'styled-components';

interface Props {
    prefix: string;
    value: string;
    onChange: (value: string) => void;
    inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
}

export default class PrefixedInput extends React.Component<Props, {}> {
    render() {
        return (
            <>
                <Prefix>{this.props.prefix}</Prefix>
                <Input
                    type="text"
                    className="form-control"
                    value={this.props.value}
                    onChange={(ev) => this.props.onChange(ev.target.value)}
                    {...this.props.inputProps}
                />
            </>
        );
    }
}

const Prefix = styled.span`
    display: block;
    height: 34px;
    padding-left: 12px;
    font-size: 14px;
    line-height: 34px;
    white-space: nowrap;
    background: white;
    border: 1px solid #eaeaea;
    border-right: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
`;

const Input = styled.input`
    padding-left: 5px;
    border-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
`;
