import React from 'react';

const SvgProtection = (props) => (
    <svg height="1em" viewBox="0 0 512 512" width="1em" {...props}>
        <path
            d="M512 256c0 40.293-9.309 78.387-25.883 112.297-26.789 54.793-72.566 98.605-128.762 122.855a253.27 253.27 0 01-34.648 12.07 254.546 254.546 0 01-53.352 8.422c-4.421.243-8.87.356-13.355.356-9.52 0-18.922-.523-28.18-1.547a253.007 253.007 0 01-38.547-7.242 250.76 250.76 0 01-34.628-12.066c-38.168-16.48-71.536-41.965-97.407-73.793a256.45 256.45 0 01-32.945-52.368C8.715 331.922 0 294.977 0 256 0 114.613 114.613 0 256 0s256 114.613 256 256zm0 0"
            fill="#854f89"
        />
        <path
            d="M227.82 362.414v148.04a253.007 253.007 0 01-38.547-7.243 250.76 250.76 0 01-34.628-12.066c-38.168-16.48-71.536-41.965-97.407-73.793a256.45 256.45 0 01-32.945-52.368v-2.57c0-35.52 24.117-65.402 56.863-74.148a60.797 60.797 0 013.637-.875c3.25-.723 6.582-1.204 9.988-1.485 2.07-.156 4.16-.254 6.27-.254h50c5.59 0 11.035.586 16.277 1.739.305.05.598.125.902.195.875.187 1.766.41 2.633.648.032.012.051.012.07.024a76.127 76.127 0 0123.837 11.062c19.976 13.856 33.05 36.938 33.05 63.094zm0 0"
            fill="#dd3033"
        />
        <path
            d="M227.82 362.414v148.04a253.007 253.007 0 01-38.547-7.243 250.76 250.76 0 01-34.628-12.066c-38.168-16.48-71.536-41.965-97.407-73.793v-54.938c0-35.52 24.117-65.402 56.864-74.148a60.797 60.797 0 013.636-.875c3.25-.723 6.582-1.204 9.989-1.485 2.07-.156 4.16-.254 6.27-.254h17.054c5.59 0 11.035.586 16.277 1.739.305.05.598.125.902.195.875.187 1.766.41 2.633.648.032.012.051.012.07.024a76.127 76.127 0 0123.837 11.062c19.976 13.856 33.05 36.938 33.05 63.094zm0 0"
            fill="#f74f4f"
        />
        <path
            d="M171.125 287.383l-.184.867-22.964 106.809c-2.207 10.261-11.235 17.707-21.73 17.714h-.388c-10.496-.007-19.523-7.453-21.73-17.714L81.168 288.258l-.184-.875zm0 0"
            fill="#f2f0ec"
        />
        <path
            d="M126.055 317.078l-34.559 19.207-10.512-48.902 21.473-12.645M126.055 317.078l34.558 19.207 10.512-48.902-21.473-12.645"
            fill="#d8d5d0"
        />
        <path
            d="M22.875 267.148V151.387c0-11.766 1.965-23.29 5.836-34.254C43.195 75.898 82.32 48.19 126.063 48.19c5.417 0 10.824.418 16.074 1.25 24.031 3.754 46.078 16.02 62.066 34.54a103.192 103.192 0 0125.05 67.406v120.988"
            fill="#cc7a3b"
        />
        <path
            d="M65.32 155.902c0 40.95 23.977 88.325 37.149 111.246H22.87V151.387c0-11.762 1.965-23.29 5.836-34.258 14.398-40.992 53.164-68.617 96.602-68.93-31.727.793-59.989 51.692-59.989 107.703zm0 0"
            fill="#a35418"
        />
        <path
            d="M153.453 256.086v33.61c0 12.304-8.113 22.706-19.289 26.16a27.354 27.354 0 01-8.101 1.222c-15.126 0-27.383-12.258-27.383-27.383v-33.613h54.773zm0 0"
            fill="#eab599"
        />
        <path
            d="M153.453 287.566v2.13c0 12.304-8.113 22.706-19.289 26.16-10.082-3.102-17.664-11.868-19.062-22.594a93.808 93.808 0 0020.723-1.055 93.369 93.369 0 0017.628-4.64zm0 0M50.938 201c0 12.68-9.262 23.191-21.391 25.145a25.461 25.461 0 01-4.078.328C11.399 226.473 0 215.07 0 201c0-14.066 11.398-25.465 25.469-25.465 1.39 0 2.754.11 4.078.324 12.129 1.953 21.39 12.465 21.39 25.141zm0 0"
            fill="#f6caae"
        />
        <path
            d="M50.938 201c0 12.68-9.262 23.191-21.391 25.145A94.7 94.7 0 0126.168 201c0-8.7 1.18-17.129 3.379-25.14 12.129 1.952 21.39 12.464 21.39 25.14zm0 0"
            fill="#eab599"
        />
        <path
            d="M34.57 201c0 5.031-4.074 9.105-9.101 9.105s-9.106-4.074-9.106-9.105c0-5.027 4.078-9.102 9.106-9.102s9.101 4.075 9.101 9.102zm0 0"
            fill="#eab599"
        />
        <path
            d="M191.457 168.484v87.543c-13.531 12.918-30.879 21.864-50.187 24.973-.153.027-.313.05-.465.078a93.486 93.486 0 01-14.746 1.145c-52.329 0-94.754-42.422-94.754-94.754v-36.082c0-4.621.328-9.168.968-13.61 22.547 16.211 54.872 14.61 75.465-4.855 6.82-6.442 11.559-14.113 14.242-22.25 1.493 14.246 8.008 28.117 19.56 39.035 13.75 12.984 31.917 19.246 49.917 18.777zm0 0"
            fill="#eab599"
        />
        <path
            d="M191.457 168.484v87.543c-13.531 12.918-30.879 21.864-50.187 24.973-.153.027-.313.05-.465.078-45.325-7.082-79.993-46.305-79.993-93.61v-36.085c0-.961.016-1.914.051-2.863 16.711 1.71 34.059-3.485 46.875-15.598 6.82-6.442 11.559-14.113 14.242-22.25 1.493 14.246 8.008 28.117 19.56 39.035 13.75 12.984 31.917 19.246 49.917 18.777zm0 0"
            fill="#f6caae"
        />
        <path
            d="M176.953 179.465c0-8.692-7.047-15.738-15.738-15.738s-15.738 7.046-15.738 15.738M106.66 179.465c0-8.692-7.047-15.738-15.738-15.738s-15.734 7.046-15.734 15.738"
            fill="#eab599"
        />
        <path
            d="M101.672 186.164c0 5.938-4.813 10.75-10.75 10.75s-10.75-4.812-10.75-10.75c0-5.934 4.812-10.746 10.75-10.746s10.75 4.812 10.75 10.746zm0 0M171.965 186.164c0 5.938-4.813 10.75-10.75 10.75s-10.75-4.812-10.75-10.75c0-5.934 4.812-10.746 10.75-10.746s10.75 4.812 10.75 10.746zm0 0"
            fill="#3d4159"
        />
        <path
            d="M136.816 213.23h-21.5a4.637 4.637 0 010-9.273h21.5c2.563 0 4.637 2.074 4.637 4.637s-2.074 4.636-4.637 4.636zm0 0M126.066 242.566c-7.933 0-15.867-1.988-23.101-5.96a4.217 4.217 0 014.063-7.39c11.921 6.554 26.16 6.554 38.081-.001a4.217 4.217 0 014.063 7.39c-7.234 3.973-15.168 5.961-23.106 5.961zm0 0"
            fill="#eab599"
        />
        <path
            d="M99.48 184.27a4.606 4.606 0 11-9.212-.002 4.606 4.606 0 019.212.002zm0 0M170.3 184.27a4.607 4.607 0 01-9.215 0 4.607 4.607 0 019.216 0zm0 0"
            fill="#fff"
        />
        <path
            d="M190.559 212.348c0 6.52-5.282 11.804-11.801 11.804-6.52 0-11.805-5.285-11.805-11.804 0-6.52 5.285-11.801 11.805-11.801s11.8 5.281 11.8 11.8zm0 0M85.176 212.348c0 6.52-5.285 11.804-11.805 11.804s-11.8-5.285-11.8-11.804c0-6.52 5.28-11.801 11.8-11.801 6.52 0 11.805 5.281 11.805 11.8zm0 0"
            fill="#eab599"
        />
        <path
            d="M486.117 368.266v.03c-26.789 54.794-72.566 98.606-128.762 122.856a253.27 253.27 0 01-34.648 12.07 254.546 254.546 0 01-53.352 8.422V368.267c0-42.895 33.028-78.079 75.063-81.473a79.208 79.208 0 016.687-.262h53.239c6.3 0 12.426.692 18.308 2.04 36.34 8.316 63.465 40.855 63.465 79.695zm0 0"
            fill="#bfd5de"
        />
        <path
            d="M486.117 368.266v.03c-26.789 54.794-72.566 98.606-128.762 122.856a253.27 253.27 0 01-34.648 12.07 252.127 252.127 0 01-11.598 2.833v-137.79c0-45.14 36.614-81.734 81.762-81.734h11.484c45.172 0 81.762 36.594 81.762 81.735zm0 0"
            fill="#dde8ed"
        />
        <path
            d="M297.715 196.36c0 13.503-9.863 24.699-22.781 26.777a26.95 26.95 0 01-4.348.347c-14.984 0-27.125-12.14-27.125-27.125s12.14-27.125 27.125-27.125c1.484 0 2.934.118 4.348.348 12.918 2.078 22.78 13.273 22.78 26.777zm0 0"
            fill="#d3a489"
        />
        <path d="M399.68 316.02l-21.946 35.203-21.949-35.203" fill="#f74f4f" />
        <path
            d="M407.262 440.215l-29.528 37.265-29.535-37.265 29.54-108.606zm0 0"
            fill="#f74f4f"
        />
        <path
            d="M297.715 196.36c0 13.503-9.863 24.699-22.781 26.777-2.348-8.532-3.602-17.512-3.602-26.778s1.254-18.246 3.602-26.777c12.918 2.078 22.78 13.273 22.78 26.777zm0 0"
            fill="#bf8e75"
        />
        <path
            d="M280.285 196.36c0 5.355-4.344 9.695-9.7 9.695-5.35 0-9.694-4.34-9.694-9.696s4.343-9.695 9.695-9.695c5.355 0 9.7 4.34 9.7 9.695zm0 0"
            fill="#bf8e75"
        />
        <path
            d="M377.727 319.992l-36.809 20.453-11.195-52.082 22.87-13.468M377.727 319.992l36.808 20.453 11.195-52.082-22.87-13.468"
            fill="#bfd5de"
        />
        <path
            d="M457.746 196.36c0 13.503 9.863 24.699 22.781 26.777 1.41.23 2.864.347 4.348.347 14.984 0 27.125-12.14 27.125-27.125s-12.14-27.125-27.125-27.125c-1.484 0-2.934.118-4.348.348-12.918 2.078-22.78 13.273-22.78 26.777zm0 0"
            fill="#d3a489"
        />
        <g fill="#bf8e75">
            <path d="M457.746 196.36c0 13.503 9.863 24.699 22.781 26.777 2.348-8.532 3.602-17.512 3.602-26.778s-1.254-18.246-3.602-26.777c-12.918 2.078-22.78 13.273-22.78 26.777zm0 0" />
            <path d="M494.57 196.36c0 5.355-4.343 9.695-9.695 9.695-5.355 0-9.695-4.34-9.695-9.696s4.34-9.695 9.695-9.695c5.352 0 9.695 4.34 9.695 9.695zm0 0" />
            <path d="M478.66 181.941c0 50.227-36.691 91.891-84.73 99.625a99.686 99.686 0 01-16.196 1.305c-55.746 0-100.93-45.183-100.93-100.93 0-26.375 9.16-65.968 25.723-83.949 18.442-20.02 45.836-16.98 75.203-16.98 5.52 0 10.93.437 16.2 1.304 48.039 7.735 84.73 49.399 84.73 99.625zm0 0" />
            <path d="M406.902 255.027v35.797c0 13.106-8.64 24.184-20.543 27.863a29.173 29.173 0 01-8.632 1.301c-16.11 0-29.164-13.054-29.164-29.164v-35.797zm0 0" />
        </g>
        <path
            d="M406.902 288.559v2.265c0 13.106-8.64 24.184-20.543 27.864-10.742-3.301-18.812-12.637-20.304-24.063 1.945.117 3.906.176 5.875.176 5.52 0 10.93-.438 16.199-1.301a99.31 99.31 0 0018.773-4.941zm0 0M478.66 181.941c0 50.227-36.691 91.891-84.73 99.625-48.04-7.734-84.73-49.398-84.73-99.625 0-30.925 13.913-58.609 35.827-77.125 13.664-11.554 30.45-19.527 48.903-22.503 44.789 7.21 79.718 43.921 84.234 89.566.328 3.309.496 6.672.496 10.062zm0 0"
            fill="#d3a489"
        />
        <path
            d="M377.73 231.652c-8.449 0-16.902-2.12-24.605-6.355a4.49 4.49 0 114.328-7.867c12.7 6.98 27.86 6.98 40.559 0a4.49 4.49 0 014.328 7.867c-7.707 4.234-16.156 6.355-24.61 6.355zm0 0"
            fill="#bf8e75"
        />
        <path
            d="M356.238 176.066c0 6.325-5.129 11.45-11.449 11.45-6.324 0-11.45-5.125-11.45-11.45 0-6.324 5.126-11.449 11.45-11.449 6.32 0 11.45 5.125 11.45 11.45zm0 0M422.125 176.066c0 6.325-5.125 11.45-11.45 11.45s-11.448-5.125-11.448-11.45c0-6.324 5.125-11.449 11.449-11.449s11.449 5.125 11.449 11.45zm0 0"
            fill="#3d4159"
        />
        <path
            d="M353.902 174.047a4.907 4.907 0 11-9.81-.002 4.907 4.907 0 019.81.002zm0 0M420.352 174.047a4.905 4.905 0 11-9.809 0 4.904 4.904 0 119.809 0zm0 0"
            fill="#fff"
        />
        <path
            d="M389.18 204.895H366.28a4.939 4.939 0 01-4.937-4.942 4.938 4.938 0 014.937-4.937h22.899a4.94 4.94 0 110 9.879zm0 0"
            fill="#bf8e75"
        />
        <path d="M410.96 365.59h44v13.469h-44zm0 0" fill="#bfd5de" />
        <path
            d="M371.828 52.18c30.574 2.183 55.121 26.883 56.676 57.972l.289 5.836h17.586l9.476 62.992 22.946-7.101-3.082-61.727c-1.63-32.656-28.64-58.254-61.328-58.132zm0 0"
            fill="#2a2f4f"
        />
        <path
            d="M473.773 27.816c24.348 24.348 24.348 63.82 0 88.168-24.347 24.348-63.82 24.348-88.168 0zm0 0"
            fill="#3d4159"
        />
        <path
            d="M385.605 115.984H309.09l-9.477 62.996-22.941-7.101 3.074-61.727c1.629-32.652 28.64-58.257 61.332-58.132l110.508.414 14.61 26.296"
            fill="#3d4159"
        />
        <path
            d="M383.637 52.18c-30.574 2.183-55.121 26.883-56.68 57.972l-.289 5.836h-17.586l-9.477 62.992-22.94-7.101 3.081-61.727c1.625-32.656 28.64-58.254 61.328-58.132zm0 0"
            fill="#2a2f4f"
        />
        <path
            d="M357.355 491.152C326.281 504.57 292.008 512 256 512c-23.082 0-45.453-3.05-66.727-8.79a250.76 250.76 0 01-34.628-12.065c-55.336-86.477-54.188-194.239-54.188-194.239L256 239.73l155.531 57.176s1.153 107.774-54.176 194.246zm0 0"
            fill="#3fb777"
        />
        <path
            d="M256 239.73V512c-23.082 0-45.453-3.05-66.727-8.79a250.76 250.76 0 01-34.628-12.065c-55.336-86.477-54.188-194.239-54.188-194.239zm0 0"
            fill="#2da365"
        />
        <path
            d="M390.133 311.305c-1.16 19.363-4.985 56.257-17.48 96.308-10.462 33.524-24.891 62.696-42.895 86.696a209.762 209.762 0 01-7.051 8.914C301.441 508.949 279.082 512 256 512s-45.453-3.05-66.727-8.79a212.408 212.408 0 01-7.043-8.901c-17.992-24-32.421-53.172-42.894-86.696-12.496-40.05-16.309-76.933-17.48-96.308L256 261.996zm0 0"
            fill="#ffe152"
        />
        <path
            d="M256 261.996V512c-23.082 0-45.453-3.05-66.727-8.79a212.408 212.408 0 01-7.043-8.901c-17.992-24-32.421-53.172-42.894-86.696-12.496-40.05-16.309-76.933-17.48-96.308zm0 0"
            fill="#e8c934"
        />
        <path
            d="M334.387 335.664l-76.442 134.238-1.945-2.75-56.695-80.027 25.578-18.121 30.187 42.601.93-1.628 51.148-89.82zm0 0"
            fill="#3fb777"
        />
        <path
            d="M256 409.977v57.175l-56.695-80.027 25.578-18.121 30.187 42.601zm0 0"
            fill="#2da365"
        />
    </svg>
);

export default SvgProtection;
