/* eslint-disable @typescript-eslint/ban-ts-ignore */
import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import Task from './Task/Index';
import { ApplicationState } from '../../store';
import { Subscribe } from '../../unstated-fork/unstated';
import DashboardContainer from '../../state-containers/dashboard.container';
import { UserTypeContainer } from '../../state-containers/user-type.container';
import * as ProfileState from '../../store/Profile';
import { TaskItem } from '../overlayMenu/OverlayMenu';
import history from '../../history';

type Props = {
    // history: History;
    userProfileState: ProfileState.UserProfileModel;
    dashboardContainer: DashboardContainer;
    userType: UserTypeContainer;
    taskItems: Array<TaskItem>;
    childRef: any;
    dashboardReloader: () => void;
    section: string;
} & typeof ProfileState.actionCreators;

interface State {
    Selected: number | null;
}

export enum Popovers {
    verifyEmail,
    createOrganisation,
    inviteContacts,
    setDefaultTimePresets,
    verifyIdentity,
    detailPreferredAgencies,
    sendInstantOffer,
    setAvailabilityAlerts,
    createTeams,
    setPhoto,
    createList,
    startChatting
}

class Tasks extends React.Component<Props, State> {
    componentDidMount() {
        const { childRef } = this.props;
        childRef(this);
    }
    componentWillUnmount() {
        const { childRef } = this.props;
        childRef(undefined);
    }
    ////////////////////////////////
    //////////////////////////////// State:
    ////////////////////////////////
    state = {
        Selected: null
    } as State;

    handleClick(e, which, url) {
        // e.stopPropagation();
        // this.setState({ Selected: which });
        history.push(url);
    }

    handlePrevious(e) {
        e.stopPropagation();
        this.setState((prevState) => {
            return {
                ...prevState,
                Selected: prevState.Selected! - 1
            };
        });
    }

    handleNext(e) {
        e.stopPropagation();
        this.setState((prevState) => {
            return {
                ...prevState,
                Selected: prevState.Selected! + 1
            };
        });
    }

    public render() {
        return (
            <TasksWrapper onClick={() => this.onClose()} data-section={this.props.section}>
                <div
                    className="progress"
                    style={{
                        height: 'auto',
                        background: 'transparent',
                        margin: 0,
                        display: 'inline-flex',
                        alignItems: 'center'
                    }}
                >
                    <div>
                        <h3
                            style={{
                                margin: '0 0 8px 0',
                                fontWeight: 700,
                                color: '#333',
                                fontSize: '2.4em'
                            }}
                        >
                            { this.props.taskItems && (Math.ceil(this.props.taskItems.filter(item => item.completed).length/8*100) + "%") }
                        </h3>
                        <p
                            style={{
                                margin: '0',
                                fontWeight: 500,
                                color: '#333',
                                fontSize: '1.2em'
                            }}
                        >
                            completed
                        </p>
                    </div>
                    {/* <h5
                        style={{
                            margin: '0',
                            fontWeight: 400,
                            color: '#444',
                            fontSize: '0.9em'
                        }}
                    >
                        ~5 mins to finish
                    </h5> */}
                </div>
                {/* <TaskLine /> */}
                <div className="tasks">
                    {this.props.taskItems.map((task, index) => {
                        let lastNotCompleted = this.props.taskItems.map(item => item.completed ? true : false).indexOf(false);
                        return (
                            <Task
                                selected={index == this.state.Selected}
                                onClick={(e) => this.handleClick(e, index, task.url)}
                                key={index}
                                iconName={task.iconName}
                                description={task.description}
                                subtitle={task.subtitle}
                                popover={task.popover}
                                completed={task.completed}
                                index={index}
                                pulse={lastNotCompleted === index}
                                first={index == 0}
                                last={index == this.props.taskItems.length - 1}
                                handlePrevious={this.handlePrevious.bind(this)}
                                handleNext={this.handleNext.bind(this)}
                                dashboardContainer={this.props.dashboardContainer}
                                dashboardReloader={this.props.dashboardReloader}
                            />
                        )
                    })}
                </div>
                <a rel="noreferrer" href="mailto:support@updatedge.com" target="_blank" className="support">
                    <i className="fas fa-question-circle" />
                    <p>Get Help</p>
                </a>
            </TasksWrapper>
        );
    }

    onClose() {
        this.setState({ Selected: null });
    }
}

export default connect((state: ApplicationState) => {
    return {
        userProfileState: state.profile.authenticatedUserProfile
    };
}, ProfileState.actionCreators)((props) => (
    <Subscribe to={[DashboardContainer, UserTypeContainer]}>
        {(dashboard: DashboardContainer, userType: UserTypeContainer) => (
            <Tasks
                // @ts-ignore
                {...props}
                dashboardContainer={dashboard}
                userType={userType}
            />
        )}
    </Subscribe>
)) as any;

// const TaskLine = styled.div`
//     margin-top: calc(1rem + 3px);
//     position: absolute;
//     left: 6rem;
//     right: 6rem;
//     top: 50%;
//     height: 4px;
//     background-color: rgba(106, 108, 111, 0.35);
// `;

const TasksWrapper = styled.div`
    border-radius: 5px;
    text-align: center;
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 15px;
    width: 100%;
    height: 100%;
    background-color: white;
    display: flex;
    align-iterms: center;

    .progress {
        margin-right: 10px;
    }

    .tasks {

    }

    .support p {
        color: white;
    }

    a {
        background-color: #4D80E4;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 18px 10px;
        border-radius: 12px;
        color: white;
        flex-wrap: wrap;
        margin-left: 10px;

        p, i {
            flex-basis: 100%
        }

        p {
            margin-bottom: 0;
            font-size: 1.1em;
        }

        i {
            font-size: 24px;
        }

    }

    &[data-section="overlay"] {
        display: flex;
        flex-wrap: wrap;

        .progress {
            order: 1;
            display: flex-wrap: no-wrap;
            align-items: flex-end;

            div {
                display: flex;
                align-items: baseline;
            }

            h3 {
                margin: 0 8px 0 0 !important;
            }
    
        }

        .support {
            order: 2;
            flex-wrap: nowrap;
            white-space: nowrap;
            padding: 10px;

            p {
                margin-left: 10px;
            }

        }

        .tasks {
            order: 3;
            flex-basis: 100%;
            margin-top: 24px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;

            &>div {
                margin-bottom: 12px;
                flex-basis: unset;
            }

        }        
    }

    @media (max-width: 1280px) {
        display: flex;
        flex-wrap: wrap;

        .progress {
            order: 1;
            display: flex-wrap: no-wrap;
            align-items: flex-end;

            div {
                display: flex;
                align-items: baseline;
            }

            h3 {
                margin: 0 8px 0 0 !important;
            }
    
        }

        .support {
            order: 2;
            flex-wrap: nowrap;
            white-space: nowrap;
            padding: 10px;

            p {
                margin-left: 10px;
            }

        }

        .tasks {
            order: 3;
            flex-basis: 100%;
            margin-top: 24px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;

            &>div {
                margin-bottom: 12px;
                flex-basis: unset;
            }

        }

    }

    @media (max-width: 1060px) {

        .tasks {
            flex-wrap: wrap;

            &>div {
                flex-basis: 25% !important;
                max-width: unset !important;
            }

        }

    }

    @media (max-width: 480px) {

        .tasks {

            &>div {
                flex-basis: 33.33333% !important;
            }

        }

    }

    @media (max-width: 380px) {

        .tasks {

            &>div {
                flex-basis: 50% !important;
            }

        }

        .support {
            width: 100%;
            margin: 12px 0 0 0;

            i {
                display: none;
            }

        }

    }

`;
