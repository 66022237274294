import React from 'react';
import styled from 'styled-components';

import theme from '../../css/theme';

interface Props {
    value: any;
    label: string;
    checkedValue: any;
    onChange: (value: any) => void;
    style?: React.CSSProperties;
}

export const RadioButton = (props: Props) => {
    return (
        <Container
            className="layout horizontal center"
            checked={props.value == props.checkedValue}
            onClick={() => props.onChange(props.value)}
            style={props.style}
        >
            <div className="outer">
                <div className="inner" />
            </div>
            <span>{props.label}</span>
        </Container>
    );
};

const Container = styled.div`
    margin: 5px;
    cursor: pointer;

    .outer {
        position: relative;
        width: 20px;
        height: 20px;
        margin-right: 10px;
        border: 2px solid ${theme.colours.updatedge};
        border-radius: 50%;
    }

    .inner {
        position: absolute;
        top: 50%;
        left: 50%;
        width: ${(props) => (props.checked ? '11px' : '0')};
        height: ${(props) => (props.checked ? '11px' : '0')};
        background: ${theme.colours.updatedge};
        border-radius: 50%;
        transform: translate(-50%, -50%);
        transition: height 0.25s ease, width 0.25s ease;
    }

    &:hover .inner {
        width: 11px;
        height: 11px;
    }
`;
