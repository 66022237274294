/* eslint-disable */
import { OrganisationTeamDTO } from '../api/organisation/ResponseTypes';
import { SetOrganisationTeamMembersRequest } from '../api/organisation/RequestTypes';
import { toast } from 'react-toastify';

import { Container } from '../unstated-fork/unstated';
import SignalRService from '../services/signalr.service';
import OrganisationApi from '../api/organisation/Organisation';
import { PubSubTopics, StorageKeys } from '../constants';
import InternalTracker from '../InternalTracker';
import { store } from '../ue-store';

interface TeamsState {
    contactId: string;
    loaded: boolean;
    teams: OrganisationTeamDTO[];
    active: number;
}

export default class TeamsContainer extends Container<TeamsState> {
    state = {
        contactId: '',
        loaded: false,
        teams: [],
        active: 0
    } as TeamsState;

    constructor() {
        super();
        this.getTeams(true);

        /**
         * We never explicitly close this socket
         * Do we need to?
         *
         * TODO: Research performance impact here
         */
        const socket = SignalRService.create('subscriptions');
        socket.on('TeamsModified', this.getTeams);
    }

    getTeams = (isFirstMount = false) => {
        OrganisationApi.getTeams().then(({ data: teams }) => {
            this.setState({
                teams: Array.isArray(teams) ? teams : [],
                loaded: true
            });

            this.updateActiveTeam();

            if (!isFirstMount) return;

            let intVal = setInterval(() => {
                let user: any = localStorage.getItem("user");
                if (user) {
                    user = JSON.parse(user);
                    if (user.contactId && user.contactId !== this.state.contactId) {
                        this.setState({ contactId: user.contactId });
                        if (teams) {
                            const teamsUserPartOf = teams.filter((team: any) => team.teamMembers.some((member: any) => member.contactId === user.contactId));
                            if (teamsUserPartOf.length === 1 && !localStorage.getItem('setInitialTeam')) {
                                this.setTeamActive(teamsUserPartOf[0].id);
                                localStorage.setItem('setInitialTeam', 'true');
                            }
                        }
                    }
                    clearInterval(intVal)
                }
            }, 100)

            store.subscribe(() => {
                const state = store.getState();
                const contactId = state.profile.contactUserProfile.id;
                if (contactId === this.state.contactId) return;
                // this.setState({ contactId });
            });

        });
    };

    updateActiveTeam = () => {
        const activeTeamId = Number(localStorage.getItem(StorageKeys.teamId));
        if (!activeTeamId) return;
        // if (this.isUserTeamMember(activeTeamId)) {
        this.setState({ active: activeTeamId });
        // } else {
        //     localStorage.setItem(StorageKeys.teamId, '0');
        // }
    };

    setTeamActive = (teamId: number) => {
        if (this.state.active == teamId) return;

        // Update state which <select> is bound
        this.setState({ active: teamId });

        // Remember the selection
        localStorage.setItem(StorageKeys.teamId, teamId.toString());

        // Notify other components (Offers, Availability, etc.) that users team team has changed and they should reload
        PubSub.publish(PubSubTopics.teamIdUpdated, teamId);
        InternalTracker.trackEvent("", {
            category: 'Teams',
            action: 'Active Team Changed'
        });

        if (window.location.href.indexOf("/offers") !== -1 || window.location.href.indexOf("/schedule") !== -1) {
            window.location.reload();
        }
    };

    updateTeamInState = (updatedTeam: OrganisationTeamDTO) => {
        this.setState({
            teams: this.state.teams.map((team) => {
                if (team.id === updatedTeam.id) return updatedTeam;
                return team;
            })
        });
    };

    setTeamMembers = async (
        teamId: OrganisationTeamDTO['id'],
        payload: SetOrganisationTeamMembersRequest
    ) => {
        const existingTeam = this.state.teams.find(({ id }) => teamId)!;

        const team = {
            ...existingTeam,
            teamMembers: [
                ...payload.teamMembers.map(({ contactId }) => ({
                    contactId,
                    teamId,
                    id: 0
                }))
            ]
        };

        OrganisationApi.setTeamMembers(teamId, payload)
            .then(() => {
                // toast.success('Team members set');
                this.updateTeamInState(team);
            })
            .catch((e) => {
                toast.error(
                    e && e.errors && e.errors.id && e.errors.id[0]
                        ? e.errors.id[0]
                        : 'Failed to update team members'
                );
            });
    };

    isUserTeamMember = (teamId: OrganisationTeamDTO['id']) =>
        this.MyTeams.map(({ id }) => id).includes(teamId);

    get MyTeams() {
        return this.state.teams.filter(({ mine }) => mine);
    }
}
