import * as Availability from './Availability';
import * as Profile from './Profile';
import * as Events from './Events';
import * as ContactMgtStates from './contacts/States';
import * as ContactMgtReducer from './contacts/Reducer';
import * as ContactProfileStates from './contactprofile/States';
import * as ContactProfileReducer from './contactprofile/Reducer';
import * as TimePresetStates from './timepresets/States';
import * as TimePresetMgtReducer from './timepresets/Reducer';
import * as RepresentedByStates from './representedby/States';
import * as RepresentedByReducer from './representedby/Reducer';
// The top-level state object
export interface ApplicationState {
    profile: Profile.UserProfileState;
    contactAvailability: Availability.ContactAvailabilityState;
    contactManagement: ContactMgtStates.ContactAndListManagementState;
    timePresetManagement: TimePresetStates.TimePresetManagementState;
    contactProfile: ContactProfileStates.ContactProfileState;
    events: Events.EventsState;
    representedBy: RepresentedByStates.RepresentedByState;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    profile: Profile.reducer,
    contactAvailability: Availability.reducer,
    contactManagement: ContactMgtReducer.reducer,
    timePresetManagement: TimePresetMgtReducer.reducer,
    contactProfile: ContactProfileReducer.reducer,
    events: Events.reducer,
    representedBy: RepresentedByReducer.reducer
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (
        dispatch: (action: TAction) => void,
        getState: () => ApplicationState
    ): void;
}

/**
 * Can be called with an action as a default reducer case to ensure all KnownActions are covered
 */
export function exhaustiveCheck(check: never): never {
    return check;
}
