import React from 'react';
import { debounce } from 'ts-debounce';

import { EventOverlay } from './EventOverlay';
import { FoundContacts } from './FoundContacts';
import { InvitedContacts } from './InvitedContacts';
import SignalRService from '../../services/signalr.service';
import { CompletedGuide } from './CompletedGuide';

/*
SignalrEventOverlays receives signalr events and triggers associated overlays to communicate with
user what event has occured.
 */

interface State {
    eventQueue: SignalrEvent[];
    currentEvent: SignalrEvent | null;
}

export enum SignalrEventTypes {
    ContactsFound,
    InvitedContacts,
    CompletedGuide
}

export type SignalrEventParameters =
    | externalContactsFoundDto
    | InvitedContactsDto;

type SignalrEvent = {
    type: SignalrEventTypes;
    parameters: SignalrEventParameters | null | undefined;
};

export type externalContactsFoundDto = {
    total: number;
    names: string[];
};

export type InvitedContactsDto = {
    total: number;
    names: string[];
};

export class EventOverlays extends React.Component<{}, {}> {
    state: State = {
        eventQueue: [],
        currentEvent: null
    };

    private hubConnection: any | undefined;

    componentDidMount() {
        this.hubConnection = SignalRService.create('contact');
        this.hubConnection!.on(
            'ExternalContactsFound',
            this.receiveEvent.bind(this, SignalrEventTypes.ContactsFound)
        );
        this.hubConnection!.on(
            'ExternalContactsInvited',
            this.receiveEvent.bind(this, SignalrEventTypes.InvitedContacts)
        );
        //
    }

    public receiveEvent(
        eventType: SignalrEventTypes,
        params: SignalrEventParameters
    ) {
        if (params.total <= 0) return;
        this.setState(
            (prevState: State) => {
                const newEventQueue = prevState.eventQueue;
                newEventQueue.push({
                    type: eventType,
                    parameters: params
                });
                return {
                    ...prevState,
                    eventQueue: newEventQueue
                };
            },
            () => {
                this.debouncedUpdateCurrentEvent();
            }
        );
    }

    updateCurrentEvent() {
        // If currently presented event is null and there are new events to show,
        // make the event at the front of the queue the current event to show.
        if (
            this.state.currentEvent == null &&
            this.state.eventQueue.length > 0
        ) {
            this.setState({
                currentEvent: this.state.eventQueue.pop()
            });
        }
    }

    debouncedUpdateCurrentEvent = debounce(this.updateCurrentEvent, 2000);

    onHide = () => {
        this.setState({ currentEvent: null }, () => {
            this.debouncedUpdateCurrentEvent();
        });
    };

    onAccepted = () => {
        this.onHide();
    };

    switchComponent() {
        switch (this.state.currentEvent!.type) {
            case SignalrEventTypes.CompletedGuide:
                return (
                    <CompletedGuide
                        onHide={this.onHide.bind(this)}
                        onAccepted={this.onAccepted.bind(this)}
                    />
                );
            case SignalrEventTypes.ContactsFound:
                return (
                    <FoundContacts
                        onHide={this.onHide.bind(this)}
                        onAccepted={this.onAccepted.bind(this)}
                        parameters={this.state.currentEvent!.parameters!}
                    />
                );
            case SignalrEventTypes.InvitedContacts:
                return (
                    <InvitedContacts
                        onHide={this.onHide.bind(this)}
                        onAccepted={this.onAccepted.bind(this)}
                        parameters={this.state.currentEvent!.parameters!}
                    />
                );
            default:
                return null;
        }
    }

    get hasCurrentEvent() {
        return this.state.currentEvent !== null;
    }

    render() {
        return (
            <EventOverlay show={this.hasCurrentEvent} onHide={this.onHide}>
                {this.hasCurrentEvent && this.switchComponent()}
            </EventOverlay>
        );
    }
}
