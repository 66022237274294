/* eslint-disable */
// import * as signalR from '@aspnet/signalr';
const signalR = require('@microsoft/signalr');

import AuthApi from '../components/auth/Auth';
import AppConfig from '../components/config/Config';

export type HubName =
    | 'contact'
    | 'offer'
    | 'activation'
    | 'events'
    | 'subscriptions'
    | 'portal';

export type MethodName =
    | 'UserOfferUpdated'
    | 'UserOfferEventDeleted'
    | 'UserEventsUpdated'
    | 'EventsUpdated'
    | 'EmailsUpdated'
    | 'AccountActivated'
    | 'AccountVerified'
    | 'PhoneNumberVerified'
    | 'OrganisationVerified'
    | 'LocationsModified'
    | 'TeamsModified'
    | 'ContactStatusChanged'
    | 'ExternalContactsFound'
    | 'ExternalContactsInvited'
    | 'AccountAndOrgVerified'
    | 'FileSharedPrivately'
    | 'FileSharedPublicly';

export default class SignalRService {
    static create = (hubName: HubName, params: Record<string, string> = {}) => {
        const auth = AuthApi.getToken() || { accessToken: '' };

        // Convert additional parameters to query string
        const queryParams = Object.keys(params)
            .map((key) => `${key}=${params[key]}`)
            .join('&');

        // Build connection to the requested hub, passing the auth token
        const hub = new signalR.HubConnectionBuilder()
            .withUrl(
                `${AppConfig.Settings.signalR.serviceUrl}/${hubName}?authorization=${auth.accessToken}&${queryParams}`
            ).withAutomaticReconnect()
            .build();

        console.log(`${AppConfig.Settings.signalR.serviceUrl}/${hubName}?authorization=${auth.accessToken}&${queryParams}`);

        // Start connection
        hub.start();

        return hub;
    };
}
