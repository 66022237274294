import styled from 'styled-components';

const QuestionCircle = styled.i.attrs(() => ({
    className: 'fa fa-question-circle m-l-xs'
}))`
    color: #66f;
    cursor: help;
`;

export default QuestionCircle;
