import React from 'react';
import { RouteComponentProps } from 'react-router';

import Analytics from '../../services/analytics.service';

export class MobileHelpVideos extends React.Component<
    RouteComponentProps<{}>,
    {}
> {
    componentDidMount() {
        Analytics.trackEvent('help-videoes-loaded');
    }

    public render() {
        return (
            <div>
                <div
                    className="panel panel-default"
                    style={{ marginTop: '30px' }}
                >
                    <div className="panel-body">
                        <div className="text-center">
                            <p>Get more out of updatedge?</p>
                            <br />
                            <p>
                                <a href="https://www.vimeo.com/channels/updatedgeapp">
                                    <button
                                        className="btn btn-success"
                                        id="goToVimeo"
                                    >
                                        View Help Videos
                                    </button>
                                </a>
                            </p>
                            <br />
                            <p>
                                To return to updatedge, tap{' '}
                                <strong>Back</strong> on your device.
                            </p>
                            <br />
                            <br />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
