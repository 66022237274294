/* eslint-disable */
import AppConfig from '../../components/config/Config';
import {
    UserDto,
    Offer,
    WorkerInviteCount,
    TimePresetCount,
    MemberCount,
    LastActive,
    UserListCount
} from './ResponseTypes';
import afetch from '../AuthenticatedFetch';
import {
    Sector,
    SubSector
} from '../../components/organisation/OrganisationAutoCompleteSuggestions';
import { RatingRecord } from '../ratings/RequestTypes';
import { UserFile } from 'src/store/contacts/Models';
import { ReportWithUserDetails } from '../report/ResponseTypes';

const apiUrls = {
    getAllUsers() {
        return `${AppConfig.Settings.api.externalUri}/admin/users`;
    },
    getAllUserSettings() {
        return `${AppConfig.Settings.api.externalUri}/admin/usersettings`;
    },
    getAllOrganisations() {
        return `${AppConfig.Settings.api.externalUri}/admin/organisations`;
    },
    getAllOffersCount() {
        return `${AppConfig.Settings.api.externalUri}/admin/offers`;
    },
    getAllWorkersCount() {
        return `${AppConfig.Settings.api.externalUri}/admin/workers`;
    },
    getAllTimePresets() {
        return `${AppConfig.Settings.api.externalUri}/admin/timepresets`;
    },
    getAllMembersCount() {
        return `${AppConfig.Settings.api.externalUri}/admin/teammembers`;
    },
    getLastActive() {
        return `${AppConfig.Settings.api.externalUri}/admin/lastactive`;
    },
    getAllLists() {
        return `${AppConfig.Settings.api.externalUri}/admin/lists`;
    },
    getAllUnapprovedSectors() {
        return `${AppConfig.Settings.api.externalUri}/sectors/unapproved`;
    },
    getAllUnapprovedSubSectors() {
        return `${AppConfig.Settings.api.externalUri}/sectors/subsectors/unapproved`;
    },
    approveSector(id: number, newName: string) {
        return `${AppConfig.Settings.api.externalUri}/sectors/${id}/approve?sectorName=${newName}`;
    },
    updateSector(id: number, newName: string, colour: string, icon: string) {
        return `${AppConfig.Settings.api.externalUri}/sectors/${id}?sectorName=${newName}&colour=${colour}&icon=${icon}`;
    },
    createSector(name: string, colour: string, icon: string) {
        return `${AppConfig.Settings.api.externalUri}/sectors/create?name=${name}&colour=${colour}&icon=${icon}`;
    },
    createSubsector(name: string, sectorId: number) {
        return `${AppConfig.Settings.api.externalUri}/sectors/subsector/create?name=${name}&sectorId=${sectorId}`;
    },
    updateSubSector(id: number, name: string, sectorId: number) {
        return `${AppConfig.Settings.api.externalUri}/sectors/subsector/${id}?name=${name}&sectorId=${sectorId}`;
    },
    deleteSector(id: number) {
        return `${AppConfig.Settings.api.externalUri}/sectors/${id}`;
    },
    deleteSubsector(id: number) {
        return `${AppConfig.Settings.api.externalUri}/sectors/subsector/${id}`;
    },
    approveSubSector(id: number, newName: string) {
        return `${AppConfig.Settings.api.externalUri}/sectors/subsector/${id}/approve?sectorName=${newName}`;
    },
    getAllSectors() {
        return `${AppConfig.Settings.api.externalUri}/sectors`;
    },
    getAllSubSectors() {
        return `${AppConfig.Settings.api.externalUri}/sectors/subsectors`;
    },
    mergeSkills() {
        return `${AppConfig.Settings.api.externalUri}/sectors/subsectors/merge`;
    },
    updateUserType(userId: string) {
        return `${AppConfig.Settings.api.externalUri}/userprofile/${userId}/usertypeid`;
    },
    getReportedRatings() {
        return `${AppConfig.Settings.api.externalUri}/rating/admin/reported`;
    },
    getReports() {
        return `${AppConfig.Settings.api.externalUri}/report/all`;
    },
    setReportOutcome(id: number, outcome: number) {
        return `${AppConfig.Settings.api.externalUri}/report/${id}/outcome/${outcome}`;
    },
    getReportedFiles() {
        return `${AppConfig.Settings.api.externalUri}/file/reported`;
    },
    moderateRating(ratingId, approve) {
        return `${AppConfig.Settings.api.externalUri}/rating/${ratingId}/admin/${(approve ? "approve" : "reject")}`;
    }
};

const apiCalls = {
    getAllUsers: () => {
        return afetch.request<null, UserDto[]>(
            apiUrls.getAllUsers(),
            'GET',
            null,
            'Unable to get users'
        );
    },
    moderateRating: (ratingId: number, approve: boolean, publicComment: string) => {
        return afetch.request<RatingRecord, null>(
            apiUrls.moderateRating(ratingId, approve),
            'PUT',
            {
                publicComment: publicComment
            },
            'Unable to update rating'
        );
    },
    getReportedRatings: () => {
        return afetch.request<null, RatingRecord[]>(
            apiUrls.getReportedRatings(),
            'GET',
            null,
            'Unable to get reported ratings'
        );
    },
    getReports: () => {
        return afetch.request<null, ReportWithUserDetails[]>(
            apiUrls.getReports(),
            'GET',
            null,
            'Unable to get reports'
        );
    },
    setReportOutcome: (id: number, outcome: number) => {
        return afetch.request<null, null[]>(
            apiUrls.setReportOutcome(id, outcome),
            'PUT',
            null,
            'Unable to get reports'
        );
    },
    getReportedFiles: () => {
        return afetch.request<null, UserFile[]>(
            apiUrls.getReportedFiles(),
            'GET',
            null,
            'Unable to get reported files'
        );
    },
    deleteFile: (fileId: number) => {
        return afetch.request<null, null>(
            `${AppConfig.Settings.api.externalUri}/file/${fileId}`,
            'DELETE',
            null,
            'Unable to delete file'
        );
    },
    approveFile: (fileId: number) => {
        return afetch.request<null, null>(
            `${AppConfig.Settings.api.externalUri}/file/${fileId}/approve/moderator`,
            'PUT',
            null,
            'Unable to approve file'
        );
    },
    updateUserType: (userId: string, userTypeId: number) => {
        return afetch.request<{
            userTypeId: number
        }, number>(
            apiUrls.updateUserType(userId),
            'PUT',
            {
                userTypeId: userTypeId,
            },
            'Unable to update user type'
        );
    },
    mergeSkills: (skillsToMerge: number[], newSkillIndustryId: number, newSkillName: string) => {
        return afetch.request<{
            skillsToMerge: number[],
            newSkillIndustryId: number,
            newSkillName: string
        }, number>(
            apiUrls.mergeSkills(),
            'PUT',
            {
                skillsToMerge: skillsToMerge,
                newSkillIndustryId: newSkillIndustryId,
                newSkillName: newSkillName
            },
            'Unable to merge skills'
        );
    },
    getAllUserSettings: () => {
        return afetch.request<null, UserDto[]>(
            apiUrls.getAllUserSettings(),
            'GET',
            null,
            'Unable to get users'
        );
    },
    getAllOrganisations: () => {
        return afetch.request<null, UserDto[]>(
            apiUrls.getAllOrganisations(),
            'GET',
            null,
            'Unable to get users'
        );
    },
    getAllOffersCount: () => {
        return afetch.request<null, { data: Offer[] }>(
            apiUrls.getAllOffersCount(),
            'GET',
            null,
            'Unable to get users'
        );
    },
    getAllWorkersCount: () => {
        return afetch.request<null, { data: WorkerInviteCount[] }>(
            apiUrls.getAllWorkersCount(),
            'GET',
            null,
            'Unable to get users'
        );
    },
    getAllTimePresets: () => {
        return afetch.request<null, { data: TimePresetCount[] }>(
            apiUrls.getAllTimePresets(),
            'GET',
            null,
            'Unable to get timepresets'
        );
    },
    getAllMembersCount: () => {
        return afetch.request<null, { data: MemberCount[] }>(
            apiUrls.getAllMembersCount(),
            'GET',
            null,
            'Unable to get member counts'
        );
    },
    getLastActive: () => {
        return afetch.request<null, { data: LastActive[] }>(
            apiUrls.getLastActive(),
            'GET',
            null,
            'Unable to get last active'
        );
    },
    getAllLists: () => {
        return afetch.request<null, { data: UserListCount[] }>(
            apiUrls.getAllLists(),
            'GET',
            null,
            'Unable to get user lists'
        );
    },
    getAllUnapprovedSectors: () => {
        return afetch.request<null, Sector[]>(
            apiUrls.getAllUnapprovedSectors(),
            'GET',
            null,
            'Unable to sectors'
        );
    },
    getAllUnapprovedSubSectors: () => {
        return afetch.request<null, SubSector[]>(
            apiUrls.getAllUnapprovedSubSectors(),
            'GET',
            null,
            'Unable to get subsectors'
        );
    },
    approveSector: (id: number, newName: string) => {
        return afetch.request<null, null>(
            apiUrls.approveSector(id, newName),
            'PUT',
            null,
            'Unable to approve sector'
        );
    },
    updateSubSector: (id: number, name: string, sectorId: number) => {
        return afetch.request<null, null>(
            apiUrls.updateSubSector(id, name, sectorId),
            'PUT',
            null,
            'Unable to approve sector'
        );
    },
    createSubsector: (name: string, sectorId: number) => {
        return afetch.request<null, null>(
            apiUrls.createSubsector(name, sectorId),
            'POST',
            null,
            'Unable to create sector'
        );
    },
    createSector: (name: string, colour: string, icon: string) => {
        return afetch.request<null, null>(
            apiUrls.createSector(name, colour, icon),
            'POST',
            null,
            'Unable to create sector'
        );
    },
    updateSector: (id: number, newName: string, colour: string, icon: string) => {
        return afetch.request<null, null>(
            apiUrls.updateSector(id, newName, colour, icon),
            'PUT',
            null,
            'Unable to approve sector'
        );
    },
    deleteSector: (id: number) => {
        return afetch.request<null, null>(
            apiUrls.deleteSector(id),
            'DELETE',
            null,
            'Unable to delete sector'
        );
    },
    deleteSubsector: (id: number) => {
        return afetch.request<null, null>(
            apiUrls.deleteSubsector(id),
            'DELETE',
            null,
            'Unable to delete skill'
        );
    },
    approveSubSector: (id: number, newName: string) => {
        return afetch.request<null, null>(
            apiUrls.approveSubSector(id, newName),
            'PUT',
            null,
            'Unable to approve sector'
        );
    },
    getAllSectors: () => {
        return afetch.request<null, null>(
            apiUrls.getAllSectors(),
            'GET',
            null,
            'Unable to sectors'
        );
    },
    getAllSubSectors: () => {
        return afetch.request<null, null>(
            apiUrls.getAllSubSectors(),
            'GET',
            null,
            'Unable to subsectors'
        );
    }
};

export default apiCalls;
