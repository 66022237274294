/* eslint-disable */
import React from 'react';

interface State {
    email: string;
    submitted: boolean;
    loading: boolean;
}

export default class PasswordResetRequest extends React.Component<{}, State> {
    render() {
        return (
            <div style={{
                overflow: 'scroll',
                height: '100vh',
                background: '#3573E6'
            }}>
                <img style={{
                    width: "1100px",
                    maxWidth: "100%",
                    margin: "auto",
                    display: "block"
                }} src="https://i.imgur.com/yb0wTwe.png" />
            </div>
        )
    }
}
