/* eslint-disable */
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

import AppConfig from '../components/config/Config';
import Auth from '../components/auth/Auth';
import history from '../history';

export default class Analytics {
    private static appInsights: ApplicationInsights;
    private static userId: string;

    /** Initialise Analytics - Only to be called on boot */
    static init = () => {
        if (
            AppConfig.Settings.appInsights.enabled &&
            AppConfig.Settings.appInsights.instrumentationKey
        ) {
            try {
                // Initialise Application Insights
                const { instrumentationKey } = AppConfig.Settings.appInsights;
                const reactPlugin = new ReactPlugin();
                Analytics.appInsights = new ApplicationInsights({
                    config: {
                        instrumentationKey,
                        extensions: [reactPlugin],
                        extensionConfig: {
                            [reactPlugin.identifier]: { history }
                        }
                    }
                });

                Analytics.appInsights.loadAppInsights();
                Analytics.addUserContext();

                window.onerror = (message, file, line, column, errorObject) => {
                    var stack = errorObject ? errorObject.stack : null;
              
                    if (!stack) {
                        // @ts-ignore
                      var stack = [];
                      // @ts-ignore
                      var f = arguments.callee.caller;
                      while (f) {
                          // @ts-ignore
                        stack.push(f.name);
                        f = f.caller;
                      }
                      // @ts-ignore
                      errorObject['stack'] = stack;
                    }
              
                    Analytics.trackException(message, stack)
                    return false;
                  };
            } catch {
                // If any errors initialising AppInsights or retrieving/decoding the access token
                // do not prevent the site from continuing
            }
        }
    };

    static addUserContext = () => {
        // Get current auth token
        const authToken = Auth.getToken(false);

        // If Bearer auth used (rather than external access JWT)
        if (
            authToken &&
            authToken.tokenType === 'Bearer' &&
            !localStorage.getItem('impersonatedAccessToken')
        ) {
            // Decode the token
            const token = Auth.getDecodedToken();

            if (!!token.oid) {
                // Set user context to enable user tracking between sessions
                Analytics.appInsights.setAuthenticatedUserContext(token.oid);

                // Set user id in all subsequent telemetry
                Analytics.userId = token.oid;
            }
        }
    };

    /** Track a page view */
    static trackPageView = (path: string) => {
        if (
            AppConfig.Settings.appInsights.enabled &&
            Analytics.appInsights !== undefined &&
            !localStorage.getItem('impersonatedAccessToken')
        ) {
            Analytics.appInsights.trackPageView({
                name: path,
                uri: path,
                properties: Analytics.properties
            });
        }
    };

    /** Track a custom event */
    static trackEvent = (name: string, properties?: any) => {
        if (
            AppConfig.Settings.appInsights.enabled &&
            !localStorage.getItem('impersonatedAccessToken')
        ) {
            Analytics.appInsights.trackEvent(
                { name },
                {
                    ...Analytics.properties,
                    ...properties
                }
            );
        }
    };

    static trackException = (message: any, stack: any) => {
        Analytics.appInsights.trackException({ error: { stack: stack, name: message, message: message }, severityLevel: 1 })
    }

    static trackFailedRequest = (properties?: object) => {
        if (
            AppConfig.Settings.appInsights.enabled &&
            !localStorage.getItem('impersonatedAccessToken')
        ) {
            Analytics.appInsights.trackEvent(
                { name: 'failed-afetch-request' },
                {
                    ...Analytics.properties,
                    ...properties
                }
            );
        }
    };

    private static get properties() {
        return {
            userId: Analytics.userId || '<unknown>'
        };
    }
}
