/* eslint-disable */
import React from 'react';
import { FeatureItem } from '../../api/subscriptions/ResponseTypes';
import styled from 'styled-components';

import { Feature } from '../../constants';
import SubscriptionsApi from '../../api/subscriptions/Subscriptions';
import theme from '../../css/theme';

// TODO: Replace with @stripe/stripe-js for types and better fraud protection
function injectStripe() {
    const src = 'https://js.stripe.com/v3/';
    const scriptExists = Array.from(
        document.getElementsByTagName('script')
    ).some((script) => script.src === src);

    if (scriptExists) return;

    const stripeScript = document.createElement('script');
    stripeScript.async = true;
    stripeScript.src = src;
    document.body.appendChild(stripeScript);
}

export const useSubscriptionUtils = (featureId: Feature) => {
    const [feature, setFeature] = React.useState<FeatureItem | null>(null);

    React.useEffect(() => {
        injectStripe();

        SubscriptionsApi.getFeature({ featureId }).then((featureItems) =>
            setFeature(featureItems[0])
        );
    }, [featureId]);

    if (feature == null) return null;

    return {
        ...feature,
        singularPrice: (feature.unitPrice / feature.unitQuantity / 100).toFixed(
            2
        )
    };
};

export const SubscriptionUtilsProvider = ({
    render,
    featureId
}: {
    render: (feature: ReturnType<typeof useSubscriptionUtils>) => JSX.Element;
    featureId: Feature;
}) => {
    const feature = useSubscriptionUtils(featureId);
    return render(feature);
};

export const Content = styled.div`
    position: relative;
    margin: 0 auto;
    text-align: center;

    .split {
        width: 100%;
        max-width: 300px;
        margin: 0 20px;
    }

    > div {
        > h4 {
            margin-bottom: 30px;
            color: ${theme.colours.updatedge};
            font-weight: 600;
        }

        label {
            width: 100%;
            color: ${theme.colours.updatedge};
            text-align: left;

            &:not(:first-child) {
                margin-top: 10px;
            }
        }

        .plan {
            position: relative;
            text-align: left;

            h4 {
                margin: 10px 0;
            }

            .right {
                text-align: right;
            }

            small {
                display: block;
                font-size: 10px;
            }

            strong {
                display: block;
            }

            hr {
                margin: 10px 0;
            }

            .total {
                color: black;

                > span {
                    color: black;
                    font-weight: 700;
                }
            }
        }

        .marketing {
            margin-top: 15px;
            text-align: center;
            strong {
                display: inline-block;
            }
        }

        .btn {
            margin-top: 30px;
        }
    }

    svg {
        display: block;
        width: 75px;
        height: 75px;
        margin: 0 auto;
    }
`;
