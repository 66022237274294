/* eslint-disable */
import React from 'react';

function TaskListStage2(props) {
    return (
<svg width="117.79" height="106.568" viewBox="0 0 117.79 106.568">
  <g id="undraw_Hiring_re_yk5n" transform="translate(0 0)">
    <path id="Path_6613" data-name="Path 6613" d="M302.5,304.9H253.352a2.337,2.337,0,0,1-2.334-2.334v-66.72a2.336,2.336,0,0,1,2.334-2.334H302.5a2.337,2.337,0,0,1,2.334,2.334v66.72A2.337,2.337,0,0,1,302.5,304.9Z" transform="translate(-251.019 -216.764)" fill="#fff"/>
    <path id="Path_6614" data-name="Path 6614" d="M307.394,291.439a9.131,9.131,0,0,1-6.3,8.682l-.165.051c-.237.073-.478.135-.723.188a8.97,8.97,0,0,1-1.345.187q-.295.02-.593.02a9.112,9.112,0,0,1-3.018-.511l-.084-.03a9.129,9.129,0,1,1,12.23-8.587Z" transform="translate(-282.701 -257.326)" fill="#fff"/>
    <circle id="Ellipse_285" data-name="Ellipse 285" cx="4.441" cy="4.441" r="4.441" transform="translate(10.904 31.278)" fill="#6c63ff"/>
    <path id="Path_6615" data-name="Path 6615" d="M337.841,346.186l0-.166a1.373,1.373,0,0,1-.786-.177.51.51,0,0,1-.2-.376.291.291,0,0,1,.1-.24.5.5,0,0,1,.514,0l.137.063-.263-1.924-.164.023.224,1.637a.586.586,0,0,0-.554.08.454.454,0,0,0-.154.373.675.675,0,0,0,.266.5A1.528,1.528,0,0,0,337.841,346.186Z" transform="translate(-322.236 -308.074)" fill="#2f2e41"/>
    <rect id="Rectangle_4501" data-name="Rectangle 4501" width="0.894" height="0.166" transform="translate(13.238 35.451)" fill="#2f2e41"/>
    <rect id="Rectangle_4502" data-name="Rectangle 4502" width="0.894" height="0.166" transform="translate(16.059 35.451)" fill="#2f2e41"/>
    <path id="Path_6616" data-name="Path 6616" d="M329.006,378.013a.812.812,0,0,0,.018-.166.79.79,0,0,0-.788-.788h-1.162a.789.789,0,0,0-.788.788.768.768,0,0,0,.018.167,4.459,4.459,0,0,0-1.229.608c-.073.051-.145.105-.214.163l.084.03a9.111,9.111,0,0,0,3.018.511q.3,0,.593-.02a8.976,8.976,0,0,0,1.345-.187c.245-.053.485-.115.723-.188A4.5,4.5,0,0,0,329.006,378.013Z" transform="translate(-312.399 -336.085)" fill="#2f2e41"/>
    <path id="Path_6617" data-name="Path 6617" d="M347.058,389.35a.61.61,0,0,0-.3.105,8.986,8.986,0,0,0,1.345-.187Z" transform="translate(-330.601 -346.234)" fill="#2f2e41"/>
    <path id="Path_6618" data-name="Path 6618" d="M326.738,311.961l-7.715-2.823a.332.332,0,0,1-.2-.426l.026-.123a3.471,3.471,0,0,1,4.247-2.081l2.374.869a3.253,3.253,0,0,1,1.76,4.2l-.069.187A.332.332,0,0,1,326.738,311.961Z" transform="translate(-307.365 -277.297)" fill="#2f2e41"/>
    <path id="Path_6619" data-name="Path 6619" d="M318.609,319.134c-10.754.4-11.693-.954-8.417-3.079l.7-1.279,7.967,2.9Z" transform="translate(-298.821 -284.313)" fill="#2f2e41"/>
    <ellipse id="Ellipse_286" data-name="Ellipse 286" cx="0.373" cy="0.705" rx="0.373" ry="0.705" transform="translate(16.222 29.829) rotate(-73.27)" fill="#2f2e41"/>
    <path id="Path_6620" data-name="Path 6620" d="M297.05,299.6a9.353,9.353,0,0,1-3.1-.525l-.087-.031a9.377,9.377,0,1,1,6.091.1l-.171.053c-.236.073-.486.138-.741.193a9.251,9.251,0,0,1-1.383.192C297.459,299.6,297.253,299.6,297.05,299.6Zm0-18.257a8.881,8.881,0,0,0-3.017,17.232l.082.029a8.854,8.854,0,0,0,2.935.5c.192,0,.386-.006.577-.019a8.755,8.755,0,0,0,1.309-.182c.242-.052.478-.114.7-.183l.161-.05a8.881,8.881,0,0,0-2.749-17.325Z" transform="translate(-281.486 -256.114)" fill="#ccc"/>
    <path id="Path_6621" data-name="Path 6621" d="M269.607,502.682" transform="translate(-266.47 -440.507)" fill="#f2f2f2"/>
    <path id="Path_6622" data-name="Path 6622" d="M302.5,304.9H253.352a2.337,2.337,0,0,1-2.334-2.334v-66.72a2.336,2.336,0,0,1,2.334-2.334H302.5a2.337,2.337,0,0,1,2.334,2.334v66.72A2.337,2.337,0,0,1,302.5,304.9Zm-49.148-71.114a2.062,2.062,0,0,0-2.059,2.059v66.72a2.062,2.062,0,0,0,2.059,2.059H302.5a2.062,2.062,0,0,0,2.059-2.059v-66.72a2.062,2.062,0,0,0-2.059-2.059Z" transform="translate(-251.019 -216.764)" fill="#ccc"/>
    <path id="Path_6623" data-name="Path 6623" d="M425.776,325.355H411.869a1.1,1.1,0,0,1,0-2.2h13.907a1.1,1.1,0,0,1,0,2.2Z" transform="translate(-383.809 -291.277)" fill="#e6e6e6"/>
    <path id="Path_6624" data-name="Path 6624" d="M430.315,347.357H411.869a1.1,1.1,0,0,1,0-2.2h18.446a1.1,1.1,0,0,1,0,2.2Z" transform="translate(-383.809 -309.567)" fill="#e6e6e6"/>
    <path id="Path_6625" data-name="Path 6625" d="M323.551,431.746H288.914a1.1,1.1,0,0,1,0-2.2h34.637a1.1,1.1,0,1,1,0,2.2Z" transform="translate(-281.604 -379.714)" fill="#e6e6e6"/>
    <path id="Path_6626" data-name="Path 6626" d="M328.09,453.749H288.914a1.1,1.1,0,1,1,0-2.2H328.09a1.1,1.1,0,0,1,0,2.2Z" transform="translate(-281.604 -398.003)" fill="#e6e6e6"/>
    <path id="Path_6627" data-name="Path 6627" d="M323.551,504.32H288.914a1.1,1.1,0,0,1,0-2.2h34.637a1.1,1.1,0,1,1,0,2.2Z" transform="translate(-281.604 -440.039)" fill="#e6e6e6"/>
    <path id="Path_6628" data-name="Path 6628" d="M328.09,526.322H288.914a1.1,1.1,0,1,1,0-2.2H328.09a1.1,1.1,0,0,1,0,2.2Z" transform="translate(-281.604 -458.329)" fill="#e6e6e6"/>
    <path id="Path_6629" data-name="Path 6629" d="M323.551,576.893H288.914a1.1,1.1,0,1,1,0-2.2h34.637a1.1,1.1,0,1,1,0,2.2Z" transform="translate(-281.604 -500.365)" fill="#e6e6e6"/>
    <path id="Path_6630" data-name="Path 6630" d="M328.09,598.9H288.914a1.1,1.1,0,0,1,0-2.2H328.09a1.1,1.1,0,0,1,0,2.2Z" transform="translate(-281.604 -518.654)" fill="#e6e6e6"/>
    <path id="Path_6631" data-name="Path 6631" d="M681.58,205.656H632.432a2.337,2.337,0,0,1-2.334-2.334V136.6a2.337,2.337,0,0,1,2.334-2.334H681.58a2.336,2.336,0,0,1,2.334,2.334v66.72A2.337,2.337,0,0,1,681.58,205.656Z" transform="translate(-566.124 -134.268)" fill="#fff"/>
    <path id="Path_6632" data-name="Path 6632" d="M681.58,205.656H632.432a2.337,2.337,0,0,1-2.334-2.334V136.6a2.337,2.337,0,0,1,2.334-2.334H681.58a2.336,2.336,0,0,1,2.334,2.334v66.72A2.337,2.337,0,0,1,681.58,205.656Zm-49.148-71.114a2.062,2.062,0,0,0-2.059,2.059v66.72a2.062,2.062,0,0,0,2.059,2.059H681.58a2.062,2.062,0,0,0,2.059-2.059V136.6a2.062,2.062,0,0,0-2.059-2.059Z" transform="translate(-566.124 -134.268)" fill="#ccc"/>
    <path id="Path_6633" data-name="Path 6633" d="M686.626,191.659a9.128,9.128,0,1,1-9.128-9.128A9.128,9.128,0,0,1,686.626,191.659Z" transform="translate(-597.936 -174.386)" fill="#fff"/>
    <path id="Path_6634" data-name="Path 6634" d="M703.707,224.311c-.1,5.838-8.784,5.837-8.883,0C694.924,218.473,703.608,218.474,703.707,224.311Z" transform="translate(-619.926 -205.476)" fill="#6c63ff"/>
    <path id="Path_6635" data-name="Path 6635" d="M720.074,242.7l0-.166c1.144.153,1.39-1.265.237-.735l.263-1.924.164.023-.224,1.637C721.724,241.35,721.275,242.863,720.074,242.7Z" transform="translate(-640.911 -222.049)" fill="#2f2e41"/>
    <rect id="Rectangle_4503" data-name="Rectangle 4503" width="0.894" height="0.166" transform="translate(80.636 17.986)" fill="#2f2e41"/>
    <rect id="Rectangle_4504" data-name="Rectangle 4504" width="0.894" height="0.166" transform="translate(77.815 17.986)" fill="#2f2e41"/>
    <path id="Path_6636" data-name="Path 6636" d="M708.81,277.909c.174-1.176-1.2-.944-1.931-.955a.79.79,0,0,0-.77.954,4.318,4.318,0,0,0-1.534.846,9.168,9.168,0,0,0,5.845.065A4.3,4.3,0,0,0,708.81,277.909Z" transform="translate(-628.031 -252.865)" fill="#2f2e41"/>
    <path id="Path_6637" data-name="Path 6637" d="M694.165,205.851c.823-.476,1.821-.111,2.672.089,1.613.5,3.875-.125,4.071-2.047.2-1.787-2.24-2.783-3.022-1l.227-.03c-2.534-3.766-6.942-3.623-6.913,1.554-.009.16.24.159.249,0-.093-3.051,1.926-5.137,4.78-3.176a8.409,8.409,0,0,1,1.669,1.747c.3.333.362-1.1,1.785-.726,1.566.607,1.055,2.849-.345,3.341-1.729.883-3.6-.829-5.3.036a.125.125,0,0,0,.126.215Z" transform="translate(-616.913 -189.116)" fill="#2f2e41"/>
    <path id="Path_6638" data-name="Path 6638" d="M676.347,199.883a9.369,9.369,0,1,1,2.9-.459A9.369,9.369,0,0,1,676.347,199.883Zm0-18.257a8.881,8.881,0,1,0,8.879,8.88A8.881,8.881,0,0,0,676.347,181.626Z" transform="translate(-596.785 -173.232)" fill="#ccc"/>
    <path id="Path_6639" data-name="Path 6639" d="M804.856,226.111H790.949a1.1,1.1,0,0,1,0-2.2h13.907a1.1,1.1,0,1,1,0,2.2Z" transform="translate(-698.914 -208.782)" fill="#e6e6e6"/>
    <path id="Path_6640" data-name="Path 6640" d="M809.395,248.113H790.949a1.1,1.1,0,0,1,0-2.2h18.446a1.1,1.1,0,0,1,0,2.2Z" transform="translate(-698.914 -227.071)" fill="#e6e6e6"/>
    <path id="Path_6641" data-name="Path 6641" d="M702.631,332.5H667.994a1.1,1.1,0,0,1,0-2.2h34.637a1.1,1.1,0,0,1,0,2.2Z" transform="translate(-596.709 -297.219)" fill="#e6e6e6"/>
    <path id="Path_6642" data-name="Path 6642" d="M707.17,354.5H667.994a1.1,1.1,0,1,1,0-2.2H707.17a1.1,1.1,0,1,1,0,2.2Z" transform="translate(-596.709 -315.508)" fill="#e6e6e6"/>
    <path id="Path_6643" data-name="Path 6643" d="M702.631,405.075H667.994a1.1,1.1,0,1,1,0-2.2h34.637a1.1,1.1,0,1,1,0,2.2Z" transform="translate(-596.709 -357.544)" fill="#e6e6e6"/>
    <path id="Path_6644" data-name="Path 6644" d="M707.17,427.078H667.994a1.1,1.1,0,0,1,0-2.2H707.17a1.1,1.1,0,0,1,0,2.2Z" transform="translate(-596.709 -375.833)" fill="#e6e6e6"/>
    <path id="Path_6645" data-name="Path 6645" d="M702.631,477.648H667.994a1.1,1.1,0,0,1,0-2.2h34.637a1.1,1.1,0,1,1,0,2.2Z" transform="translate(-596.71 -417.869)" fill="#e6e6e6"/>
    <path id="Path_6646" data-name="Path 6646" d="M707.17,499.651H667.994a1.1,1.1,0,1,1,0-2.2H707.17a1.1,1.1,0,1,1,0,2.2Z" transform="translate(-596.71 -436.159)" fill="#e6e6e6"/>
    <path id="Path_6647" data-name="Path 6647" d="M505.87,375.673H456.722a2.336,2.336,0,0,1-2.334-2.334v-66.72a2.337,2.337,0,0,1,2.334-2.334H505.87a2.336,2.336,0,0,1,2.334,2.334v66.72A2.336,2.336,0,0,1,505.87,375.673Z" transform="translate(-420.066 -275.593)" fill="#fff"/>
    <path id="Path_6648" data-name="Path 6648" d="M492.506,361.1a9.1,9.1,0,0,0,2.684,6.465c.071.07.144.141.218.21a9.117,9.117,0,0,0,1.449,1.1c.1.061.2.12.3.178a9.079,9.079,0,0,0,1.65.724c.1.032.2.063.3.091a9.166,9.166,0,0,0,5.545-.156l.1-.037a9.129,9.129,0,1,0-12.25-8.58Z" transform="translate(-451.751 -315.231)" fill="#fff"/>
    <path id="Path_6649" data-name="Path 6649" d="M516.847,431.983a2.829,2.829,0,0,1-.731,1.915,2.053,2.053,0,0,1-1.873.715,1.329,1.329,0,0,1-1.111-1.384,1.025,1.025,0,0,1,1.267-.921.87.87,0,0,1,.349,1.466c-.123.1-.3-.075-.176-.176a.622.622,0,0,0-.175-1.031.781.781,0,0,0-1.018.691,1.09,1.09,0,0,0,.946,1.117,1.792,1.792,0,0,0,1.57-.606,2.569,2.569,0,0,0,.7-1.788.125.125,0,0,1,.249,0Z" transform="translate(-468.893 -381.64)" fill="#2f2e41"/>
    <circle id="Ellipse_287" data-name="Ellipse 287" cx="4.441" cy="4.441" r="4.441" transform="translate(45.944 43.043)" fill="#6c63ff"/>
    <path id="Path_6650" data-name="Path 6650" d="M548.208,412.949l0-.166a1.372,1.372,0,0,0,.785-.177.51.51,0,0,0,.2-.376.291.291,0,0,0-.1-.24.5.5,0,0,0-.514,0l-.137.063.263-1.924.164.023-.224,1.637a.586.586,0,0,1,.554.08.454.454,0,0,1,.154.373.675.675,0,0,1-.266.5A1.527,1.527,0,0,1,548.208,412.949Z" transform="translate(-498.05 -363.57)" fill="#2f2e41"/>
    <rect id="Rectangle_4505" data-name="Rectangle 4505" width="0.894" height="0.166" transform="translate(51.631 46.718)" fill="#2f2e41"/>
    <rect id="Rectangle_4506" data-name="Rectangle 4506" width="0.894" height="0.166" transform="translate(48.81 46.718)" fill="#2f2e41"/>
    <path id="Path_6651" data-name="Path 6651" d="M531.622,448.881a9.166,9.166,0,0,0,5.545-.156l.1-.037a4.422,4.422,0,0,0-1.407-.727.708.708,0,0,0,0-.39.785.785,0,0,0-.759-.551h-1.082a.782.782,0,0,0-.758.55.711.711,0,0,0,0,.391,4.425,4.425,0,0,0-1.255.615q-.116.081-.224.169C531.728,448.788,531.674,448.834,531.622,448.881Z" transform="translate(-484.267 -394.238)" fill="#2f2e41"/>
    <path id="Path_6652" data-name="Path 6652" d="M570.193,405.487a1.434,1.434,0,0,0,.847,1.477.686.686,0,0,0,.148.017c.549,0,1-.67,1-1.494a1.835,1.835,0,0,0-.383-1.177.961.961,0,0,0-.293-.238.694.694,0,0,0-.319-.079.728.728,0,0,0-.424.143A1.662,1.662,0,0,0,570.193,405.487Z" transform="translate(-516.328 -358.474)" fill="#3f3d56"/>
    <path id="Path_6653" data-name="Path 6653" d="M517.575,405.487a1.592,1.592,0,0,0,.654,1.4.709.709,0,0,0,.342.09.638.638,0,0,0,.08-.005c.511-.061.916-.7.916-1.489a1.615,1.615,0,0,0-.627-1.387.712.712,0,0,0-.369-.107.7.7,0,0,0-.32.079.966.966,0,0,0-.294.239,1.838,1.838,0,0,0-.383,1.176Z" transform="translate(-472.59 -358.474)" fill="#3f3d56"/>
    <path id="Path_6654" data-name="Path 6654" d="M519.927,386.74a.125.125,0,0,0,.118-.164,4.854,4.854,0,1,1,9.351-.546.124.124,0,1,0,.244.051,5.1,5.1,0,1,0-9.831.574A.124.124,0,0,0,519.927,386.74Z" transform="translate(-474.225 -338.466)" fill="#3f3d56"/>
    <path id="Path_6655" data-name="Path 6655" d="M543.776,398.321a.622.622,0,0,1-.1.771,1.042,1.042,0,0,1-.959.236,2.6,2.6,0,0,0-1.151-.113c-.323.1-.554.394-.89.468a.617.617,0,0,1-.758-.339c-.05-.153.19-.218.24-.066.068.205.359.2.526.144.268-.091.474-.313.737-.419a1.613,1.613,0,0,1,.963,0,1.563,1.563,0,0,0,.937.041c.22-.093.394-.385.236-.6C543.466,398.317,543.682,398.193,543.776,398.321Z" transform="translate(-491.16 -353.714)" fill="#2f2e41"/>
    <path id="Path_6656" data-name="Path 6656" d="M500.414,369.266a9.356,9.356,0,0,1-2.6-.365c-.1-.028-.2-.06-.308-.094a9.355,9.355,0,0,1-1.694-.744c-.1-.057-.2-.117-.31-.183a9.386,9.386,0,0,1-1.488-1.135c-.075-.07-.15-.143-.223-.215a9.376,9.376,0,1,1,9.826,2.171l-.105.038A9.355,9.355,0,0,1,500.414,369.266Zm0-18.257a8.88,8.88,0,0,0-6.269,15.168c.069.068.14.137.212.2a8.888,8.888,0,0,0,1.409,1.075c.1.063.2.119.293.172a8.851,8.851,0,0,0,1.6.7c.1.032.2.062.29.088a8.917,8.917,0,0,0,5.4-.151l.1-.036a8.88,8.88,0,0,0-3.036-17.225Z" transform="translate(-450.532 -314.02)" fill="#ccc"/>
    <path id="Path_6657" data-name="Path 6657" d="M505.87,375.673H456.722a2.336,2.336,0,0,1-2.334-2.334v-66.72a2.337,2.337,0,0,1,2.334-2.334H505.87a2.336,2.336,0,0,1,2.334,2.334v66.72A2.336,2.336,0,0,1,505.87,375.673ZM456.722,304.56a2.062,2.062,0,0,0-2.059,2.059v66.72a2.062,2.062,0,0,0,2.059,2.059H505.87a2.062,2.062,0,0,0,2.059-2.059v-66.72a2.062,2.062,0,0,0-2.059-2.059Z" transform="translate(-420.066 -275.593)" fill="#3f3d56"/>
    <path id="Path_6658" data-name="Path 6658" d="M629.145,396.127H615.238a1.1,1.1,0,0,1,0-2.2h13.907a1.1,1.1,0,0,1,0,2.2Z" transform="translate(-552.857 -350.106)" fill="#6c63ff"/>
    <path id="Path_6659" data-name="Path 6659" d="M633.684,418.13H615.238a1.1,1.1,0,0,1,0-2.2h18.446a1.1,1.1,0,0,1,0,2.2Z" transform="translate(-552.857 -368.395)" fill="#6c63ff"/>
    <path id="Path_6660" data-name="Path 6660" d="M526.92,502.519H492.283a1.1,1.1,0,1,1,0-2.2H526.92a1.1,1.1,0,0,1,0,2.2Z" transform="translate(-450.652 -438.543)" fill="#ccc"/>
    <path id="Path_6661" data-name="Path 6661" d="M531.459,524.522H492.283a1.1,1.1,0,0,1,0-2.2h39.176a1.1,1.1,0,0,1,0,2.2Z" transform="translate(-450.652 -456.832)" fill="#ccc"/>
    <path id="Path_6662" data-name="Path 6662" d="M526.92,575.092H492.283a1.1,1.1,0,1,1,0-2.2H526.92a1.1,1.1,0,0,1,0,2.2Z" transform="translate(-450.652 -498.868)" fill="#ccc"/>
    <path id="Path_6663" data-name="Path 6663" d="M531.459,597.095H492.283a1.1,1.1,0,0,1,0-2.2h39.176a1.1,1.1,0,0,1,0,2.2Z" transform="translate(-450.652 -517.157)" fill="#ccc"/>
    <path id="Path_6664" data-name="Path 6664" d="M526.92,647.665H492.283a1.1,1.1,0,0,1,0-2.2H526.92a1.1,1.1,0,0,1,0,2.2Z" transform="translate(-450.652 -559.193)" fill="#ccc"/>
    <path id="Path_6665" data-name="Path 6665" d="M531.459,669.668H492.283a1.1,1.1,0,0,1,0-2.2h39.176a1.1,1.1,0,0,1,0,2.2Z" transform="translate(-450.652 -577.483)" fill="#ccc"/>
    <path id="Path_6666" data-name="Path 6666" d="M702.67,637.268a10.84,10.84,0,0,0,0,21.68h0a10.84,10.84,0,0,0,0-21.68Z" transform="translate(-617.438 -552.38)" fill="#6c63ff"/>
    <path id="f276b720-7c73-4755-84a7-b208d54eb1fc" d="M728.761,680.659a1.315,1.315,0,0,1-.791-.263l-.014-.011-2.981-2.28a1.325,1.325,0,1,1,1.612-2.1l1.931,1.481,4.563-5.953a1.324,1.324,0,0,1,1.857-.245h0l-.028.039.029-.039a1.326,1.326,0,0,1,.245,1.857l-5.367,7a1.325,1.325,0,0,1-1.054.517Z" transform="translate(-644.557 -580.43)" fill="#fff"/>
  </g>
</svg>

    );
}

export default TaskListStage2;
