import { Reducer } from 'redux';

import { UserProfileState } from './Profile';
import { AppThunkAction } from './index';
import OverlayMenu from '../components/overlayMenu/OverlayMenu';
import Chat from '../components/Chat/Chat';

export interface EventsState {}
export interface refreshModalProgressGuide {
    type: 'REFRESH_MODAL_PROGRESS_GUIDE';
}
export interface setModalProgressRef {
    type: 'SET_MODAL_PROGRESS_REF';
}
export interface setChatRef {
    type: 'SET_CHAT_REF';
}
export interface setEventOverlaysRef {
    type: 'SET_EVENT_OVERLAYS_REF';
}
export interface fireEventOverlays {
    type: 'FIRE_EVENT_OVERLAY';
}
export interface setOverlayMenuSelectedItem {
    type: 'SET_OVERLAY_MENU_SELECTED_ITEM';
    payload: number;
}
export interface startChatWith {
    type: 'STARTING_CHAT_WITH';
    payload: string;
}

type KnownAction =
    | refreshModalProgressGuide
    | setModalProgressRef
    | setEventOverlaysRef
    | fireEventOverlays
    | setOverlayMenuSelectedItem
    | setChatRef
    | startChatWith;

const defaultState = {};

let OverlayMenuRef: typeof OverlayMenu;
let ChatRef: typeof Chat;
//let EventOverlaysRef: typeof EventOverlays;

export const actionCreators = {
    // setEventOverlaysRef: (
    //     ref: typeof EventOverlays | null
    // ): AppThunkAction<KnownAction> => (dispatch) => {
    //     //EventOverlaysRef = ref!;
    //     dispatch({
    //         type: 'SET_EVENT_OVERLAYS_REF'
    //     });
    // },
    fireEventOverlays: (): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({
            type: 'FIRE_EVENT_OVERLAY'
        });
        //EventOverlaysRef.receiveEvent(SignalrEventTypes.CompletedGuide, null);
    },
    setOverlayMenuRef: (
        ref: typeof OverlayMenu | null
    ): AppThunkAction<KnownAction> => (dispatch) => {
        OverlayMenuRef = ref!;
        dispatch({
            type: 'SET_MODAL_PROGRESS_REF'
        });
    },
    setChatRef: (ref: typeof Chat | null): AppThunkAction<KnownAction> => (
        dispatch
    ) => {
        ChatRef = ref!;
        dispatch({
            type: 'SET_CHAT_REF'
        });
    },
    refreshOverlayMenu: (): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({
            type: 'REFRESH_MODAL_PROGRESS_GUIDE'
        });
        OverlayMenuRef.refresh();
    },
    startChat: (userId: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({
            type: 'STARTING_CHAT_WITH',
            payload: userId
        });
        ChatRef.handleStartChat(userId);
    },
    setOverlayMenuSelectedItem: (
        number: number
    ): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({
            type: 'SET_OVERLAY_MENU_SELECTED_ITEM',
            payload: number
        });
        OverlayMenuRef.setSelectedItem(number);
    }
};

export const reducer: Reducer<EventsState | undefined> = (state) => {
    if (state == undefined) return defaultState as EventsState;

    return state || (defaultState as UserProfileState);
};
