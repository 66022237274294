/* eslint-disable */
import React from 'react';
import ProfileApi from 'src/api/profile/Profile';
import FullScreenLoader from 'src/components/ui-components/FullScreenLoader';

export default class Settings extends React.Component<{}, {
    status: "loading" | "success" | "error",
    msg: string
}> {
    constructor(props) {
        super(props);
        this.state = {
            status: "loading",
            msg: ""
        };
    }

    async componentDidMount() {
        this.setState({ 
            msg: "", 
            status: "loading"
        });
        // @ts-ignore
        const { phoneNumber, code } = this.props.match.params
        const res = await ProfileApi.verifyOtpCode(phoneNumber, code).catch(e => {
            this.setState({ status: "error" })
        })
        // @ts-ignore
        this.setState({ status: (res && res.success) ? "success" : "error" })
        // @ts-ignore
        if (!res.success) this.setState({ msg: res.message || 'Invalid OTP Code' })
    }

    render() {
        return (
            <div className='payment-completed'>
                <FullScreenLoader 
                    success={this.state.status === "success"} 
                    error={this.state.status === "error"}
                    loadingMessage={this.state.status === "error" ? this.state.msg : this.state.status === "success" ? "Your number has been Verified" : "Verifying Code" }
                />
            </div>
        );
    }
}
