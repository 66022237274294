import React from 'react';

export const UEText: React.SFC<{}> = () => {
    return (
        <div className="logotext_wrapper">
            <span className="updat">updat</span>
            <span className="edge">edge</span>
        </div>
    );
};
