/* eslint-disable */
import React from 'react';
import { Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { TrackedRoute } from './tracked-route';
import { Layout } from './components/layout/Layout';
import { MobileActionLayout } from './components/layout/MobileActionLayout';
import { AuthenticatedRoute } from './components/auth/AuthenticatedRoute';
import { EphemeralAccessLayout } from './components/layout/EphemeralAccessLayout';
import { AuthFlowLayout } from './components/layout/AuthFlowLayout';
import PostLogin from './pages/PostLogin';
import Profile from './pages/Profile';
import MyAgencies from './pages/MyAgencies';
import ContactManagement from './pages/ContactManagement';
import Availability from './pages/Availability';
import ContactProfile from './pages/ContactProfile';
import Schedule from './pages/Schedule';
import Marketing from './pages/Marketing';
import Settings from './pages/Settings';
import PaymentCompleted from './pages/PaymentCompleted';
import OTPAuth from './pages/OTPAuth';
import Support from './pages/Support';
import ExternalVerification from './pages/ExternalVerification';
import Invite from './pages/Invite';
import { MobileHelpVideos } from './pages/mobile/helpvideos';
import { MobileLoggedOut } from './pages/mobile/loggedout';
import { AgencyResponseConfirmed } from './pages/AgencyResponseConfirmed';
import { ComingSoon } from './pages/mobile/comingsoon';
import Invitations from './pages/mobile/invitations';
import Offers from './pages/Offers';
import MobileOffers from './pages/mobile/mobileoffers';
import EmailConfirmed from './pages/auth/EmailConfirmed';
import ActivationPending from './pages/auth/ActivationPending';
import PasswordResetRequest from './pages/auth/PasswordResetRequest';
import PasswordResetComplete from './pages/auth/PasswordResetComplete';
import Home from './pages/Home';
import Reports from './pages/Reports';
import Referrals from './pages/Referrals';
import Reputation from './pages/mobile/reputation';
import VerifyUsers from './pages/internal/VerifyUsers';
import Moderation from './pages/internal/Moderation';
import InternalRatings from './pages/internal/InternalRating';
import InternalReports from './pages/internal/InternalReports';
import InternalFiles from './pages/internal/InternalFiles';
import Users from './pages/internal/Users';
import Sectors from './pages/internal/Sectors';
import InternalLinks from './pages/internal/InternalLinks';
import Logos from './pages/internal/Logos';
import ExternalOrgLogos from './pages/internal/ExternalOrgLogos';
import AgencySuggestions from './pages/internal/AgencySuggestions';
import PendingEvents from './pages/mobile/pendingevents';
import MobileAvailability from './pages/mobile/mobileavailability';
import ManageEmails from './pages/ManageEmails';
import Organisation from './pages/Organisation';
import Timesheet from './pages/Timesheet';
import ExternalRate from './pages/ExternalRate';
import ConfirmTimePresets from './pages/mobile/ConfirmTimePresets';
import WhatsNew from './pages/WhatsNew';
import AcceptInvite from './pages/auth/AcceptInvite';
import FourZeroFour from './pages/404';
import Chat from './pages/Chat';
import Ratings from './pages/Ratings';
import Screenings from './pages/Screenings';
import Files from './pages/Files';
import AvailabilityPrint from './pages/AvailabilityPrint';
import Impersonation from './pages/internal/Impersonation';
import AppContainer from './AppContainer';
import Conversations from './components/Chat/Conversations';
import DeleteAccountExternal from './pages/DeleteAccountExternal';

const UnauthenticatedPortalRoute = ({ component: Component, ...rest }) => (
    <TrackedRoute
        {...rest}
        render={(props) => (
            <Layout>
                <Component {...props} />
            </Layout>
        )}
    />
);

const UnauthenticatedPortalRouteNL = ({ component: Component, ...rest }) => (
    <TrackedRoute
        {...rest}
        render={(props) => (
            <React.Fragment>
                <ToastContainer position="top-center" />
                <Component {...props} />
            </React.Fragment>
        )}
    />
);

const UnauthenticatedMobileAppRoute = ({ component: Component, ...rest }) => {
    let windowAssignment: any;
    if (rest.windowVar) {
        // Assign a ref of this component to the window with the given variable name
        // (used so the native apps can call the components directly)
        windowAssignment = { ref: (comp) => (window[rest.windowVar] = comp) };
    }
    return (
        <TrackedRoute
            {...rest}
            render={(props) => (
                <MobileActionLayout>
                    <Component {...props} {...windowAssignment} />
                </MobileActionLayout>
            )}
        />
    );
};

const AuthenticatedPortalRoute = ({ component: Component, ...rest }) => (
    <Layout>
        <AuthenticatedRoute component={Component} {...rest} />
    </Layout>
);

const AuthFlowRoute = ({ component: Component, ...rest }) => (
    <TrackedRoute
        {...rest}
        render={(props) => (
            <AuthFlowLayout>
                <Component {...props} />
            </AuthFlowLayout>
        )}
    />
);

const EphemeralAccessRoute = ({ component: Component, ...rest }) => (
    <TrackedRoute
        {...rest}
        render={(props) => (
            <EphemeralAccessLayout {...props}>
                <Component {...props} />
            </EphemeralAccessLayout>
        )}
    />
);

export const unauthenticatedPages = [
    '/activationPending',
    '/requestPasswordReset',
    '/completePasswordReset',
    '/setInitialPassword',
    '/marketing',
    '/otp'
];

export const trulyUnauthenticatedPages = [
    '/requestPasswordReset',
    '/completePasswordReset',
    '/setInitialPassword',
    '/marketing',
    '/confirmEmail',
    '/external/chat',
    '/otp',
    '/external/verification',
]

export const trulyUnauthenticatedPagesWildcard = [
    '/external/offer/agency/assistance',
    '/external/rate',
    '/external/verification',
]

export const routes = (
    <AppContainer>
        <Switch>
            <UnauthenticatedPortalRoute
                exact
                path="/redirect"
                component={PostLogin}
            />
            <UnauthenticatedPortalRoute
                exact
                path="/redirectotp"
                component={PostLogin}
            />
            <UnauthenticatedPortalRoute
                exact
                path="/redirectimp"
                component={Impersonation}
            />
            <AuthFlowRoute
                exact
                path="/confirmEmail"
                component={EmailConfirmed}
            />
            <AuthFlowRoute
                exact
                path="/activationPending"
                component={ActivationPending}
            />
            <AuthFlowRoute
                exact
                path="/requestPasswordReset"
                component={PasswordResetRequest}
            />
            <UnauthenticatedPortalRouteNL
                path="/marketing"
                component={Marketing}
            />
            <AuthFlowRoute
                exact
                path="/completePasswordReset"
                component={PasswordResetComplete}
            />
            <AuthFlowRoute
                exact
                path="/setInitialPassword"
                component={PasswordResetComplete}
            />
            <AuthFlowRoute
                exact
                path="/invitations/:id"
                component={AcceptInvite}
            />
            <UnauthenticatedMobileAppRoute
                exact
                path="/mobile/helpvideos"
                component={MobileHelpVideos}
            />
            <UnauthenticatedMobileAppRoute
                exact
                path="/external/logged-out"
                component={MobileLoggedOut}
            />
            <UnauthenticatedMobileAppRoute
                exact
                path="/external/offer/agency/assistance/:token/:response"
                component={AgencyResponseConfirmed}
            />
            <UnauthenticatedMobileAppRoute
                exact
                path="/mobile/morefeatures"
                component={ComingSoon}
            />
            <UnauthenticatedMobileAppRoute
                exact
                path="/mobile/morefeatures/:id?"
                component={ComingSoon}
            />
            <AuthenticatedPortalRoute
                exact path="/"
                component={Home}
            />
            <AuthenticatedPortalRoute
                exact
                path="/reports"
                component={Reports}
                title="Reports"
            />
            <AuthenticatedPortalRoute
                exact
                path="/referrals"
                component={Referrals}
                title="Referrals"
            />
            <AuthenticatedPortalRoute
                exact
                path="/profile"
                component={Profile}
                title="Profile"
            />
            <AuthenticatedPortalRoute
                exact
                path="/agencies"
                component={MyAgencies}
                title="Agencies"
            />
            <AuthenticatedPortalRoute
                exact
                path="/offers"
                component={Offers}
                title="Offers"
            />
            <AuthenticatedPortalRoute
                exact
                path="/offers/view/:offerId"
                component={Offers}
                title="Offers"
            />
            <AuthenticatedPortalRoute
                exact
                path="/ratings"
                component={Ratings}
                title="Ratings"
            />
            <AuthenticatedPortalRoute
                exact
                path="/screenings"
                component={Screenings}
                title="Screenings"
            />
            <AuthenticatedPortalRoute
                exact
                path="/files"
                component={Files}
                title="Files"
            />
            <AuthenticatedPortalRoute
                exact
                path="/rating/:id"
                component={Ratings}
                title="Ratings"
            />
            <AuthenticatedPortalRoute
                exact
                path="/offers/worker/:workerId"
                component={Offers}
                title="Offers"
            />
            <UnauthenticatedMobileAppRoute
                exact
                path="/external/availability/pdf/:userIds"
                component={AvailabilityPrint}
            />
            <AuthenticatedPortalRoute
                exact
                path="/availability/pdf"
                component={AvailabilityPrint}
                title="Availability"
            />
            <AuthenticatedPortalRoute
                exact
                path="/availability"
                component={Availability}
                title="Availability"
            />
            <AuthenticatedPortalRoute
                exact
                path="/payment/success"
                component={PaymentCompleted}
                title="Payment Completed"
            />
            <UnauthenticatedPortalRouteNL
                exact
                path="/otp/:phoneNumber/:code"
                component={OTPAuth}
                title="OTP Auth"
            />
            <AuthenticatedPortalRoute
                exact
                path="/availability/print"
                component={Availability}
                title="Availability"
            />
            <AuthenticatedPortalRoute
                path="/contact/:contactId"
                component={ContactProfile}
                title="Contact"
            />
            <AuthenticatedPortalRoute
                path="/schedule"
                component={Schedule}
                title="Schedule"
            />
            <AuthenticatedPortalRoute
                exact
                path="/lists"
                component={ContactManagement}
                title="Lists"
            />
            <AuthenticatedPortalRoute
                exact
                path="/lists/:id"
                component={ContactManagement}
                title="Lists"
            />
            <AuthenticatedPortalRoute
                exact
                path="/support"
                component={Support}
                title="Support"
            />
            <AuthenticatedPortalRoute
                exact
                path="/support/:id"
                component={Support}
                title="Support"
            />
            <AuthenticatedPortalRoute
                exact
                path="/settings"
                component={Settings}
                title="Settings"
            />
            <AuthenticatedPortalRoute
                exact
                path="/settings/:section"
                component={Settings}
                title="Settings"
            />
            <AuthenticatedPortalRoute
                exact
                path="/invite"
                component={Invite}
                title="Invite"
            />
            {/* <AuthenticatedPortalRoute
                exact
                path="/settings/stpresets"
                component={SearchTimePresets}
                title="Presets"
            />
            <AuthenticatedPortalRoute
                exact
                path="/settings/alerts"
                component={ManageAlerts}
                title="Alerts"
            />
            <AuthenticatedPortalRoute
                exact
                path="/settings/close-account"
                component={CloseAccount}
                title="Close"
            />
            <AuthenticatedPortalRoute
                exact
                path="/settings/manage-emails"
                component={ManageEmails}
                title="Manage Emails"
            /> */}
            <AuthenticatedPortalRoute
                exact
                path="/whats-new"
                component={WhatsNew}
                title="What's New"
            />
            <UnauthenticatedMobileAppRoute
                exact
                path="/mobile/invitations"
                component={Invitations}
            />
            <UnauthenticatedMobileAppRoute
                exact
                path="/mobile/offer/:offerId?"
                component={MobileOffers}
            />
            <UnauthenticatedMobileAppRoute
                exact
                path="/mobile/reputation"
                component={Reputation}
            />
            <AuthenticatedPortalRoute
                exact
                path="/internal/agency-suggestions"
                component={AgencySuggestions}
            />
            <AuthenticatedPortalRoute
                exact
                path="/internal/verifyusers"
                component={VerifyUsers}
            />
            <AuthenticatedPortalRoute
                exact
                path="/internal/ratings"
                component={InternalRatings}
            />
            <AuthenticatedPortalRoute
                exact
                path="/internal/reports"
                component={InternalReports}
            />
            <AuthenticatedPortalRoute
                exact
                path="/internal/moderation"
                component={Moderation}
            />
            <AuthenticatedPortalRoute
                exact
                path="/internal/logos"
                component={Logos}
            />
            <AuthenticatedPortalRoute
                exact
                path="/internal/users"
                component={Users}
            />
            <AuthenticatedPortalRoute
                exact
                path="/internal/sectors"
                component={Sectors}
            />
            <AuthenticatedPortalRoute
                exact
                path="/internal"
                component={InternalLinks}
            />
            <AuthenticatedPortalRoute
                exact
                path="/internal/externalorglogos"
                component={ExternalOrgLogos}
            />
            <AuthenticatedPortalRoute
                path="/internal/files"
                component={InternalFiles}
            />
            <UnauthenticatedMobileAppRoute
                exact
                path="/mobile/events/pending/:eventSetId?"
                component={PendingEvents}
            />
            <UnauthenticatedMobileAppRoute
                exact
                path="/mobile/availability"
                windowVar="ueAvailableNow"
                component={MobileAvailability}
            />
            <AuthenticatedPortalRoute
                path="/organisation"
                component={Organisation}
                title="Organization"
            />
            <UnauthenticatedMobileAppRoute
                exact
                path="/mobile/confirmpresets"
                windowVar="uePresets"
                component={ConfirmTimePresets}
            />
            <AuthenticatedPortalRoute
                exact
                path="/chat/:userId?"
                component={Conversations}
                title="Chat"
            />
            <UnauthenticatedPortalRouteNL
                exact
                path="/external/chat"
                component={Conversations}
                title="Chat"
            />
            <UnauthenticatedPortalRouteNL
                exact
                path="/external/verification"
                component={ExternalVerification}
                title="Verification"
            />
            {/* <AuthenticatedPortalRoute
                exact
                path="/conversations"
                component={Conversations}
                title="Conversations"
            /> */}
            <EphemeralAccessRoute
                exact
                path="/external/timesheet/contact/:contactId"
                component={ContactProfile}
            />
            <EphemeralAccessRoute
                exact
                path="/external/contact/:contactId"
                component={ContactProfile}
            />
            <UnauthenticatedPortalRouteNL
                path="/external/timesheet/rota"
                component={Timesheet}
                title="Timesheet"
            />
            <UnauthenticatedPortalRouteNL
                path="/external/rate/:contactId"
                component={ExternalRate}
                title="Timesheet"
            />
            <UnauthenticatedPortalRouteNL
                path="/external/delete-account"
                component={DeleteAccountExternal}
                title="Delete Account"
            />
            <UnauthenticatedPortalRouteNL
                path="/external/schedule"
                component={Timesheet}
                title="Schedule"
            />
            <UnauthenticatedPortalRouteNL
                path="/external/timesheet/availability"
                component={Timesheet}
                title="Timesheet"
            />
            <UnauthenticatedMobileAppRoute
                exact
                path="/mobile/chat/:userId?"
                component={Chat}
            />
            <UnauthenticatedMobileAppRoute
                exact
                path="/mobile/manage-emails"
                component={ManageEmails}
            />
            <TrackedRoute component={FourZeroFour} />
        </Switch>
    </AppContainer>
);
