/* eslint-disable */
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Button } from 'react-bootstrap';

import SearchTimePresetEditor from '../../components/availability/SearchTimePresetEditor';
import { ApplicationState } from '../../store';
import { TimePresets } from '../../store/timepresets/Models';
import * as TimePresetsActionCreators from '../../store/timepresets/ActionCreators';
import moment from 'moment';
import InternalTracker from '../../InternalTracker';
import Analytics from '../../services/analytics.service';
import { toast } from 'react-toastify';

const defaultPreset = {
    name: 'Untitled',
    startHour: 9,
    startMinute: 0,
    endHour: 17,
    endMinute: 0,
    $id: null,
    userId: null,
    id: null,
    startTime: null,
    endTime: null,
};

type SearchTimePresetsProps = TimePresets & ConnectedProps<typeof connector>;

class SearchTimePresets extends React.Component<SearchTimePresetsProps, {}> {
    componentDidMount() {
        this.props.getSearchTimePresets();
    }

    render() {
        return (
            <React.Fragment>
                <div className="row" data-section="stpresets">
                    <div className="hpanel">
                        <div className="panel-body" style={{ overflow: 'auto' }}>
                            <h2>Time Presets</h2>
                            <small>
                                Create time presets for different work patterns & shifts e.g. "Morning only" - use these to search for availability & send offers.
                            </small>
                            <small>
                                Edit the time range by clicking on the edit button, and then dragging the start and end time, you can switch between day and overnight mode using the toggle. Once you are done tap the save button.
                            </small>
                            <Button
                                bsStyle="success"
                                bsSize="medium"
                                style={{
                                    margin: '10px 0 16px 0'
                                }}
                                disabled={this.props.editing || this.props.presets.find(p => p.id === "unsaved") ? true : false}
                                onClick={ async () => {
                                    if (this.props.editing || this.props.presets.find(p => p.id === "unsaved")) {
                                        toast.error("Please save your current changes before creating a new preset.")
                                        return null;
                                    }

                                    let newPreset = {
                                        ...JSON.parse(JSON.stringify(defaultPreset)),
                                        justAdded: true,
                                        id: "unsaved",
                                        createdAt: new Date().toISOString()
                                    }

                                    InternalTracker.trackEvent("Time Preset Created")

                                    let allPresets = JSON.parse(JSON.stringify(this.props.presets));
                                    this.props.editLocalList(allPresets.concat(newPreset))

                                    setTimeout(() => {
                                        this.props.editSearchTimePresetStart("unsaved");
                                        setTimeout(() => {
                                            const editedRowInput = document.querySelector('.preset-row[data-editing="true"] input') as HTMLInputElement;
                                            if (editedRowInput) {
                                                editedRowInput.value = "";
                                                editedRowInput.focus();
                                            }
                                        }, 300)
                                    }, 150)
                                }}
                                className={
                                    this.props.presets &&
                                    this.props.presets.length !== 0 &&
                                    this.props.presets.length < 2
                                        ? 'pulse no-size create-preset-btn'
                                        : 'create-preset-btn'
                                }
                            >
                                <i className="fa fa-plus" /> Create New
                            </Button>
                            <SearchTimePresetEditor />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const connector = connect(
    (state: ApplicationState) => state.timePresetManagement.timePresetMgtState,
    TimePresetsActionCreators.actionCreators
);

export default connector(SearchTimePresets);
