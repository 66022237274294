/* eslint-disable */
import fetch from 'cross-fetch';
import React from 'react';
import styled from 'styled-components';

import Analytics from '../../services/analytics.service';
import Hyperlink from '../../components/ui-components/Hyperlink';
import { H4CSS } from '../organisation/Teams';

interface Props {
    placeId: string;
    placeName?: string;
    friendlyName?: string;
    showDirections?: boolean;
    explicitHeight?: number;
}
interface State {
    coords: string | undefined;
}

export default class LocationMap extends React.Component<Props, State> {
    mapElement: React.RefObject<HTMLDivElement>;

    constructor(props) {
        super(props);

        this.mapElement = React.createRef();

        this.state = {
            coords: undefined
        };
    }

    componentDidMount() {
        this.setPlace();
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.placeId !== this.props.placeId) {
            Analytics.trackEvent('mobile-offer-map-component-updated');
            this.setPlace();
        }
    }

    render() {
        let head: JSX.Element | undefined;
        let img: JSX.Element | undefined;

        if (this.state.coords) {
            const showHead =
                this.props.friendlyName ||
                this.props.placeName ||
                this.props.showDirections;

            if (showHead) {
                head = (
                    <div className="m-b-xs layout horizontal justified" style={{ position: 'relative' }}>
                        <LocationPlaceName className="flex">
                            {this.props.friendlyName || this.props.placeName}
                            {this.props.friendlyName && this.props.placeName && (
                                <>
                                    <br />
                                    <span>{this.props.placeName}</span>
                                </>
                            )}
                        </LocationPlaceName>
                        {!!this.props.showDirections ? (
                            <>
                                <Hyperlink
                                    style={{ fontWeight: 600 }}
                                    onClick={this.handleDirectionsClick}
                                >
                                    directions
                                </Hyperlink>
                                <i
                                    className="fas fa-external-link-alt m-l-xs"
                                    style={{
                                        fontSize: '9px',
                                        color: '#00E',
                                        paddingTop: '1px'
                                    }}
                                />
                            </>
                        ) : (
                            <span />
                        )}
                    </div>
                );
            }

            if (this.mapElement.current) {
                const h = this.props.explicitHeight || this.mapElement.current.clientHeight - (showHead ? 15 : 0);
                const w = this.mapElement.current?.clientWidth;
                const url = `https://maps.googleapis.com/maps/api/staticmap?&zoom=15&autoscale=2&size=${w}x${h}&maptype=roadmap&key=AIzaSyB76e89fMUB7oVc1xHygDEzKw5Lt-O30M8&format=png&visual_refresh=true&markers=size:mid%7Ccolor:0xff0000%7Clabel:%7C${this.state.coords}`;

                img = <img style={{ borderRadius: 12 }} src={url} />;
            }
        }

        return (
            <>
                {head}
                <div ref={this.mapElement} className="flex">
                    {img}
                </div>
            </>
        );
    }

    private setPlace = () => {
        this.setState({
            coords: undefined
        });

        fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyB76e89fMUB7oVc1xHygDEzKw5Lt-O30M8&place_id=${this.props.placeId}`
        )
            .then(
                (response) =>
                    response.json() as Promise<{
                        results: {
                            geometry: {
                                location: { lat: string; lng: string };
                            };
                        }[];
                    }>
            )
            .then((geo) => {
                if (geo.results) {
                    const coords = geo.results[0].geometry.location;
                    this.setState({
                        coords: `${coords.lat},${coords.lng}`
                    });
                }
            });
    };

    private handleDirectionsClick = () => {
        window.open(
            `https://www.google.com/maps/dir//${this.state.coords}/`,
            '_blank'
        );
    };
}

const LocationPlaceName = styled.h4`
    position: absolute;
    background: white;
    margin: 15px 0 0 10px;
    padding: 8px;
    font-size: 16px;
`;
