import AppConfig from '../../components/config/Config';
import afetch from './../AuthenticatedFetch';
import { LookupItem } from './ResponseTypes';

const LookupsAPI = {
    industries: (query: string) => {
        const url = `${AppConfig.Settings.api.externalUri}/organisations/sectors?query=${query}`;

        return afetch.request<
            null,
            {
                data: LookupItem[];
            }
        >(url, 'GET', null, 'Unable to fetch industries');
    },
    subSectors: (query: string, sectorId: number) => {
        const url = `${AppConfig.Settings.api.externalUri}/organisations/sectors/subsectors?query=${query}&sectorId=${sectorId}`;

        return afetch.request<null, { data: LookupItem[] }>(
            url,
            'GET',
            null,
            'Unable to fetch industries'
        );
    },
    suggestSector: (text: string) => {
        const url = `${AppConfig.Settings.api.externalUri}/sectors?name=${text}`;

        return afetch.request<null, number>(
            url,
            'POST',
            null,
            'Unable to suggest industry'
        );
    },
    suggestSubSector: (text: string, sectorId: number) => {
        const url = `${AppConfig.Settings.api.externalUri}/sectors/subsector?name=${text}&sectorId=${sectorId}`;

        return afetch.request<null, number>(
            url,
            'POST',
            null,
            'Unable to suggest industry'
        );
    }
};

export default LookupsAPI;
