/* eslint-disable */
/* eslint-disable @typescript-eslint/ban-ts-ignore */
import React from 'react';
import Checkbox from 'react-simple-checkbox';

// import ViewOfferModal from '../components/offers/ViewOffer2';
import { ApplicationState } from '../store';
import SimpleTooltip from '../components/ui-components/SimpleTooltip';
import theme from '../css/theme';

import moment from 'moment';
import BigCalendar from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import ProfileApi from '../api/profile/Profile';
import RatingApi from '../api/ratings/Rating';
import OfferAPI from '../api/offers/Offers';
import OrganisationApi from '../api/organisation/Organisation';
import CompanyApi from '../api/company/Company';
import TimesheetsAPI from '../api/timesheets/Timesheets';
import { ProfileImage } from '../components/ui-components/ProfileImage';

import '../css/schedule.css';
import '../css/patterns.css';
import { AsyncOverlay } from '../components/ui-components/AsyncOverlay';
import Utils from '../api/Utils';
import Utilities from '../Utilities';
import PremiumBanner from '../components/organisation/PremiumBanner';
import { Feature } from '../constants';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Profile from '../store/Profile';

import InternalTracker from '../InternalTracker';

import { OfferConfirmationResponseTypes, RotaDto, RotaHirerDto, RotaHirerWithDates, Terminology } from '../api/offers/ResponseTypes';

import { TimeSheetHeader } from '../components/ui-components/TimeSheetHeader';
import { StickyTable, Row, Cell } from 'react-sticky-table';
import { EphemeralAccessTokenStorageKey } from '../../src/components/layout/EphemeralAccessLayout';
import { TimesheetResponseType } from 'src/api/timesheets/ResponseTypes';
import * as TimePresetsModels from '../store/timepresets/Models';
import * as TimePresetsActionCreators from '../store/timepresets/ActionCreators';
import ViewOffer from '../components/offers/ViewOffer';
import Toggle from 'react-toggle';
import { Setting } from 'src/api/settings/ResponseTypes';
import Joyride from 'react-joyride';

import SettingsApi from '../api/settings/Settings';
import DemoRes from '../demo/rota-bookings.json';
import Dialog from 'src/components/ui-components/Dialog';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { parseDate } from 'react-day-picker/moment';

import { ToastType, toast } from 'react-toastify';
import * as Notifications from '../store/Notifications';

import {
    DateFormats
} from '../constants';
import WorkerRater, { ContactWithRating } from 'src/components/rating/WorkerRater';
import { deleteValue, getValue, setValue } from 'src/db/KeyValueCache';
import { CircularProgress } from '@mui/material';
import domtoimage from 'dom-to-image';
import { PDFDownloadLink, Document, Page, Text, View, StyleSheet, PDFViewer, BlobProvider, pdf, Image, Font, Link } from '@react-pdf/renderer';
import FullScreenLoader from 'src/components/ui-components/FullScreenLoader';

const COLORS = ["#6c85e5", "#2D46B9", "#6ECB63", "#38A3A5", "#055052", "#ff7220", "#F0A500", "#FF5C58", "#610094", "#AE00FB", "#FD6F96", "#E0C097", "#999", "#666", "#333"]

enum OfferResponseType {
    AwaitingResponse = 0,
    Accepted = 1,
    Rejected = 2,
    Cancelled = 3,
}

enum CompiledStatus {
    NoResponses = 0,
    HasResponses = 1,
    AllRejected = 2,
    WithdrawnByHirer = 3,
    Confirmed = 4,
    WorkerRejected = 5,
    Historical = 6
}

enum ShiftStatus {
    HirerConfirmed = 0,
    HirerCancelled = 1,
    ContactNoResponse = 2,
    ContactApplied = 3,
    ContactRejected = 4,
    ContactWithdrew  = 5,
    Uncategorized = 6,
    PendingAgency = 10,
    AgencyAccepted = 11,
    AgencyRejected = 12,
    ConfirmedAgencyBooking = 20
}

const CompiledStatusDetails = {
    0: { title: "No replies", color: "#80675d" },
    1: { title: "Has replies", color: "#3282b8" },
    2: { title: "All workers rejected", color: "#ec46da" },
    3: { title: "Withdrawn by you", color: "#f58634" },
    4: { title: "Confirmed", color: "#16c79a" },
    5: { title: "Withdrawn by worker", color: "#ec4646" },
    6: { title: "Historical", color: "#838383" }
}

const ShiftStatusDetails = {
    0: { title: "Confirmed", color: "#34BE82", solidColor: "#34BE82" },
    1: { title: "Withdrawn", color: "#f95b85", solidColor: "#f95b85" },
    2: { title: "Pending", color: '#548CFF', solidColor: "#548CFF" },
    3: { title: "Applied", color: '#FF7800', solidColor: "#FF7800" },
    4: { title: "Rejected", color: '#ff4343', solidColor: "#ff4343" },
    5: { title: "No Longer Confirmed", color: '#694E4E', solidColor: "#694E4E" },
    6: { title: "Uncategorized", color: '#222', solidColor: "#222" },
    20: { title: "Confirmed Agency Booking", color: "#24855b", solidColor: "#24855b" },
    10: { title: "Requested Agency Assist", color: "#004eed", solidColor: "#004eed" },
    11: { title: "Agency Assisting",  solidColor: "#b35400", color: "#b35400" },
    12: { title: "Agency Cannot Help", solidColor: "#e10000", color: "#e10000" }
}

interface AgencyResponse {
    offerId: string;
    responseType: AgencyResponseType;
    userContactId: number;
    organisationDomain: string;
    organisationId: string;
    organisationName: string;
}

enum AgencyResponseType {
    Pending = 0,
    Accepted = 1,
    Rejected = 2
}

interface ScheduleEventItem {
    agencyResponses: AgencyResponse[];
    offerId: string;
    id;
    title: string;
    description: string;
    locationId: string;
    from: Date;
    to: Date;
    start?: Date;
    end?: Date;
    adjustedLengthMins?: number;
    actualLengthMins?: number;
    workers: {
        id: string;
        externalId?: string;
        firstName: string;
        lastName: string;
        offerConfirmationResponse: OfferConfirmationResponseTypes,
    }[];
    hirers: {
        organisationDomain: string
        organisationExternalId: string
        organisationId: string
        organisationName: string
    }[],
    offer: {
        statusId: number;
        status: CompiledStatus;
        title: string;
        externalHirerName?: string;
        externalSubjectId?: string;
        externalSubjectName: string;
        organisationName?: string;
        organisationId?: string;
    };
    overlapA?: boolean;
    overlapB?: boolean;
    printing: boolean;
}

interface Contact {
    id: string;
    firstName: string;
    lastName: string;
}

interface ScheduleFilters {
    statuses: ShiftStatus[];
    // contacts: string[];
    offerKeywords: string;
    offerNames: string[];
    contactKeywords: string;
    contactNames: string[];
}

interface WorkerOfferMetadata {
    [key: string]: OfferMetadata // UserId
}

interface OfferMetadata {
    [key: string]: { // OfferId
        color: string;
        title: string;
        hours: number;
        solidColor?: string;
    } 
}

interface OfferNameCounts {
    [name: string]: number
}

interface ViewComponents {
    profileImage: boolean,
    profileFirstName: boolean,
    profileLastName: boolean,
    profileHours: boolean,
    eventRange: boolean,
    eventName: boolean,
    eventTypeIcon: boolean,
    eventPadding: number,
    colPadding: number,
    doubleRows?: boolean,
    name: string
}

type PeriodBreakdownType = 'week' | 'month' | 'day'
type WeekBreakdownTypes = 'week' | 'week proportional' | 'week proportional detailed';
type MonthBreakdownTypes = 'month' | 'month proportional' | 'month proportional detailed';

type BreakdownType = 'day' | WeekBreakdownTypes | MonthBreakdownTypes
type DailyBreakdownZoom = 1 | 2 | 3;

const ViewPresetConfig = [
    {
        name: "CONCISE",
        profileImage: true,
        profileFirstName: true,
        profileLastName: true,
        profileHours: true,
        eventRange: true,
        eventName: true,
        eventTypeIcon: true,
        eventPadding: 10,
        colPadding: 5
    },
    {
        name: "COMPACT",
        profileImage: false,
        profileFirstName: true,
        profileLastName: true,
        profileHours: false,
        eventRange: true,
        eventName: false,
        eventTypeIcon: false,
        eventPadding: 0,
        colPadding: 0,
    },
    {
        name: "CONCISE2",
        profileImage: true,
        profileFirstName: true,
        profileLastName: true,
        profileHours: true,
        eventRange: true,
        eventName: true,
        eventTypeIcon: true,
        doubleRows: true,
        eventPadding: 10,
        colPadding: 5
    },
    {
        name: "BOOKING",
        profileImage: true,
        profileFirstName: true,
        profileLastName: true,
        profileHours: true,
        eventRange: true,
        eventName: true,
        eventTypeIcon: false,
        doubleRows: true,
        eventPadding: 5,
        colPadding: 0,
    },
    {
        name: "EMPTY",
        profileImage: false,
        profileFirstName: true,
        profileLastName: true,
        profileHours: false,
        eventRange: false,
        eventName: false,
        eventTypeIcon: false,
        doubleRows: false,
        eventPadding: 0,
        colPadding: 0,
    },
    {
        name: "EMPTYDETAILSOUTSIDE",
        profileImage: true,
        profileFirstName: true,
        profileLastName: true,
        profileHours: true,
        eventRange: false,
        eventName: false,
        eventTypeIcon: false,
        doubleRows: false,
        eventPadding: 0,
        colPadding: 0,
    }
]

enum ViewPresetType {
    CONCISE = 0,
    COMPACT = 1,
    CONCISE2 = 2,
    BOOKING = 3,
    EMPTY = 4,
    EMPTYDETAILSOUTSIDE = 5
}

interface State {
    displayConfirmationModal: boolean;
    loading: boolean;
    flattenedEvents: ScheduleEventItem[];
    contacts: Record<Contact['id'], Contact>;
    filters: ScheduleFilters;
    showMobileFilters: boolean;
    start: Date | null;
    end: Date | null;
    centerDate: Date | undefined;
    agendayDays: number | undefined;
    showProBanner: boolean;
    restrictToMaxHour?: Date;
    restrictToMinHour?: Date;
    timeSheetInited?: boolean;
    mainView: "calendar" | "compact" | "shift";
    viewPreset: ViewPresetType,
    compactViewDrillDownUserId: string | null;
    compactViewDrillDownUserIdInternal: string | null;
    compactViewDrillDownUserReported: boolean | null;
    compactViewDrillDownOrgDomain: string | null;
    compactViewDrillDownOrgName: string | null;
    compactViewDrillDownOrgTotalHours: number | null;
    compactViewDrillDownOrgTotalGrossPay: number | null;
    compactViewDrillDownOrgTotalGrossCharge: number | null;
    compactViewDrillDownOrgId: string | null;
    compactViewDrillDownExternalOrgId: string | null;
    days: string[];
    workersGrouppedByDates: workersGrouppedByDates,
    eventsGrouppedByWorkers: eventsGrouppedByWorkers,
    hirersGrouppedByDates: hirersGrouppedByDates,
    eventsGrouppedByHirers: eventsGrouppedByHirers,
    workers?: RotaDto['workers'],
    hirers?: RotaHirerWithDates[],
    restrictToMinHourNumber: number,
    restrictToMinHourNumberGlobal: number,
    restrictToMaxHourNumber: number,
    restrictToMaxHourNumberGlobal: number,
    compactHourLength: number,
    compactDayLength: number,
    shiftDayLength: number,
    shiftRowWidth: number | null,
    shiftRowWrapperWidth: number | null,
    agencyContactEmail?: string,
    fromTimeLabel?: string,
    toTimeLabel?: string,
    workerOfferMetadata: WorkerOfferMetadata,
    hirerOfferMetadata: WorkerOfferMetadata,
    terminology?: Terminology[],
    feedback?: string,
    alreadyRespondedWith?: TimesheetResponseType,
    timesheetResponseResult?: string,
    breakdown: BreakdownType,
    dailyBreakdownZoom: DailyBreakdownZoom,
    selectedPresetId: string | "none",
    monthWeeks: string[][],
    previousViewData?: {
        contacts: Record<Contact['id'], Contact>;
        flattenedEvents: ScheduleEventItem[];
        days: string[];
        workersGrouppedByDates: workersGrouppedByDates;
        eventsGrouppedByWorkers: eventsGrouppedByWorkers;
        hirersGrouppedByDates: hirersGrouppedByDates;
        eventsGrouppedByHirers: eventsGrouppedByHirers;
        workers: RotaDto['workers'];
        workerOfferMetadata: WorkerOfferMetadata;
        hirerOfferMetadata: WorkerOfferMetadata;
        start: Date,
        end: Date,
        breakdown: BreakdownType,
        viewPreset: ViewPresetType,
        monthWeeks: string[][]
    },
    openedOfferId: string | null;
    breakdownPeriod: PeriodBreakdownType;
    offerNameCounts: OfferNameCounts;
    skipWeekends: boolean;
    showFilters: boolean;
    showingDemoRes: boolean;
    firstLoad: boolean;
    stale: boolean;
    printing: boolean;
    printingProgress: number;
    canScrollLeft: boolean;
    canScrollRight: boolean;
    showDesktopScroller: boolean;
    hideResultsBefore: Date | null;
    hideResultsAfter: Date | null;
    hasPremiumSubscription: boolean;
    hasTriedPremium: boolean;
    initialWeeklyRollingViewStartingWithToday: boolean;
    joyride: null | "rating" | "rating-mandatory";
    type?: "external-timesheet-worker" | "external-timesheet-hirer" | "internal-timesheet-hirer";
    currentWorker?: WorkerMetadata;
    currency: null | string;
    totalHours: number | null;
    totalGrossPay: number | null;
    totalGrossCharge: number | null;
    hoveredOfferId: string | null;
}

export interface WorkerMetadata {
    firstName: string;
    lastName: string;
    id: string;
    externalId: string;
    headline: string;
    imageUrl: string;
}

interface workersGrouppedByDates {
    [key: string]: workersGrouppedByDatesWorkers
}

interface workersGrouppedByDatesWorkers {
    [key: string]: ScheduleEventItem[];
}

interface hirersGrouppedByDates { 
    [key: string]: hirersGrouppedByDatesHirers
}

interface hirersGrouppedByDatesHirers {
    [key: string]: ScheduleEventItem[];
}

interface eventsGrouppedByWorkers {
    [key: string]: {
        dates: ScheduleEventItem[],
        worker: Worker
    }
}

interface eventsGrouppedByHirers {
    [key: string]: {
        dates: ScheduleEventItem[],
        hirer: RotaHirerDto
    }
}

interface Worker {
    organisationName: string;
    id: string;
    organisationId?: string;
    organisationDomain?: string;
    externalId?: string;
    firstName: string;
    lastName: string;
    offerConfirmationResponse: OfferConfirmationResponseTypes;
    submissionCount?: number;
    providerOrgRating?: number;
    rating?: {
        stars: number;
        publicComment: string;
        privateComment: string;
    },
    reported?: boolean;
}

export interface Props {
    timePresets: TimePresetsModels.TimePresets;
    timePresetsActions: typeof TimePresetsActionCreators.actionCreators;
    authenticatedUserProfile: Profile.UserProfileModel;
    onDataLoad: (workersCount: number) => void;
}

let eventMouseLeaveTimeout: any = null;

export class Schedule extends React.Component<Props,State> {

    state: State = {
        displayConfirmationModal: false,
        flattenedEvents: [],
        contacts: {},
        loading: false,
        filters: {
            statuses: window.location.pathname.startsWith("/external/schedule") ? [0] : [],
            contactKeywords: "",
            offerKeywords: "",
            offerNames: [],
            contactNames: []
        },
        viewPreset: ViewPresetType.EMPTYDETAILSOUTSIDE,
        showMobileFilters: false,
        start: null,
        end: null,
        centerDate: new Date(),
        agendayDays: undefined,
        showProBanner: false,
        mainView: window.location.pathname.startsWith("/external/timesheet") ? "compact" : "shift",
        days: [],
        workersGrouppedByDates: {},
        eventsGrouppedByWorkers: {},
        hirersGrouppedByDates: {},
        eventsGrouppedByHirers: {},
        compactViewDrillDownUserId: null,
        compactViewDrillDownUserIdInternal: null,
        compactViewDrillDownUserReported: null,
        compactViewDrillDownOrgDomain: null,
        compactViewDrillDownOrgName: null,
        compactViewDrillDownOrgTotalHours: null,
        compactViewDrillDownOrgTotalGrossPay: null,
        compactViewDrillDownOrgTotalGrossCharge: null,
        compactViewDrillDownOrgId: null,
        compactViewDrillDownExternalOrgId: null,
        restrictToMinHourNumber: 0,
        restrictToMinHourNumberGlobal: 0,
        restrictToMaxHourNumber: 23,
        restrictToMaxHourNumberGlobal: 0,
        compactHourLength: 40,
        compactDayLength: 80,
        workerOfferMetadata: {},
        hirerOfferMetadata: {},
        shiftDayLength: 100,
        breakdown: "week proportional detailed",
        dailyBreakdownZoom: 1,
        selectedPresetId: "none",
        monthWeeks: [],
        openedOfferId: null,
        breakdownPeriod: "week",
        offerNameCounts: {},
        skipWeekends: localStorage.getItem("offersContinueOnWeekdays") === "true",
        showFilters: false,
        showingDemoRes: false,
        firstLoad: true,
        stale: false,
        printing: false,
        shiftRowWidth: null,
        shiftRowWrapperWidth: null,
        printingProgress: 0,
        canScrollLeft: false,
        canScrollRight: false,
        showDesktopScroller: window.ontouchstart === undefined,
        hideResultsBefore: null,
        hideResultsAfter: null,
        hasPremiumSubscription: false,
        hasTriedPremium: false,
        initialWeeklyRollingViewStartingWithToday: false,
        joyride: null,
        currency: null,
        totalHours: null, 
        totalGrossPay: null,
        totalGrossCharge: null,
        hoveredOfferId: null
    };

    constructor(props) {
        super(props);
        BigCalendar.momentLocalizer(moment);
    }

    hasTriedPremiumSubscription() {
        let rotaSubscription = this.props.authenticatedUserProfile.subscriptionNotifications?.subscriptions.filter(item => item.featureName === "Rota")[0];
        
        if (!rotaSubscription) {
            const localuser = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user") || "{}") : null;
            rotaSubscription = localuser?.subscriptionNotifications?.subscriptions.filter(item => item.featureName === "Rota")[0];
        }
       
        return rotaSubscription ? true : false;
    }

    hasPremiumSubscription() {

        if (process.env.JEST_WORKER_ID !== undefined || (window as any).Cypress || window.location.pathname.startsWith("/external/")) {
            return true;
        }

        let rotaSubscription = this.props.authenticatedUserProfile.subscriptionNotifications?.subscriptions.filter(item => item.featureName === "Rota")[0];
        
        if (!rotaSubscription) {
            const localuser = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user") || "{}") : null;
            rotaSubscription = localuser?.subscriptionNotifications?.subscriptions.filter(item => item.featureName === "Rota")[0];
        }
       
        if (rotaSubscription && (rotaSubscription.status === 0 || rotaSubscription.status === 1 || rotaSubscription.status === 2)) {
            InternalTracker.trackEvent("", {
                category: 'Schedule',
                action: 'Schedule Shown Premium Feature'
            });
            return true;
        }

        InternalTracker.trackEvent("", {
            category: 'Schedule',
            action: 'Schedule Not Shown Premium Feature'
        });
        return false;

    }

    handleResize = () => {
        if (this.state.mainView === "calendar" || window.location.pathname.startsWith("/external/timesheet")) {
            let calendarDOM = document.querySelector('.calendar');
            if (calendarDOM) {
                const WRAPPER_PADDING = window.location.pathname.startsWith("/external/timesheet") ? 20 : 0;
                const CLIENT_WIDTH = calendarDOM.clientWidth - WRAPPER_PADDING;
                const PROFILE_WIDTH = 140;
                const DAY_WIDTH = 60;
    
                let compactDayLength = (CLIENT_WIDTH - PROFILE_WIDTH) / this.state.days.length
                let hourLength = (CLIENT_WIDTH - DAY_WIDTH) / (this.state.restrictToMaxHourNumber - this.state.restrictToMinHourNumber + 1);
    
                if (compactDayLength < 100) {
                    compactDayLength = 100;
                }
    
                if (hourLength < 40) {
                    hourLength = 40;
                }
    
                // TODO Specific on worker drilldown
                this.setState({
                    compactDayLength: compactDayLength,
                    compactHourLength: hourLength
                }, () => {
                    console.log(CLIENT_WIDTH, compactDayLength, hourLength)
                    console.log(this.state.compactDayLength, this.state.compactHourLength)
                    this.handleScroll();
                })
            }
        } else if (this.state.mainView === "shift") {
            let shiftDOM = document.querySelector(".shift-view");
            if (shiftDOM) {
                const CLIENT_WIDTH = shiftDOM.clientWidth;
                const PROFILE_WIDTH = 140 + 10;
                
                let shiftDayLength = (CLIENT_WIDTH - PROFILE_WIDTH) / this.state.days.length - 10;
    
                if (shiftDayLength < 180) {
                    shiftDayLength = 180;
                }    

                this.setState({
                    shiftDayLength: shiftDayLength,
                    shiftRowWidth: ((this.state.skipWeekends ? 5 : 7) * shiftDayLength) + PROFILE_WIDTH + 50,
                    shiftRowWrapperWidth: document.querySelector(".print-area")?.clientWidth || null,
                })
            }
        }
    }

    handleScroll = () => {
        this.setState({
            canScrollLeft: (document.querySelector(".shift-view") as HTMLElement)?.scrollLeft !== 0,
            canScrollRight: (document.querySelector(".shift-view") as HTMLElement)?.scrollLeft + 10 < (document.querySelector(".shift-view") as HTMLElement)?.scrollWidth - (document.querySelector(".shift-view") as HTMLElement)?.clientWidth
        })
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
        window.removeEventListener('beforeunload', this.onUnmount, false);
        this.onUnmount();
    }

    onUnmount = () => {
        localStorage.removeItem("timesheet-custom-preset-start");
        localStorage.removeItem("timesheet-custom-preset-end");
    }

    calculateMonthWeeks(start: Date, end: Date) {
        let monthWeeks: string[][] = [];
        let currentDay = new Date(start);
        currentDay.setHours(12);
        let until = Utilities.dateAdd(end, "day", 1);
        let currentWeek: string[] = [];
        while (currentDay < until) {
            currentWeek.push(Utilities.formatDate(currentDay, "YYYY-MM-DD"))
            if (currentDay.getDay() === 0) {
                monthWeeks.push(currentWeek);
                currentWeek = [];
            }
            currentDay = Utilities.dateAdd(currentDay, "day", 1)
        }
        if (currentWeek.length > 0) {
            monthWeeks.push(currentWeek);
        }
        let lastMonthDate = Utilities.dateSub(start, "day", 1);
        while (monthWeeks[0].length < 7) {
            monthWeeks[0].unshift(Utilities.formatDate(lastMonthDate, "YYYY-MM-DD"));
            lastMonthDate = Utilities.dateSub(lastMonthDate, "day", 1);
        }
        let nextMonthDate = Utilities.dateAdd(end, "day", 1);
        while (monthWeeks[monthWeeks.length-1].length < 7) {
            monthWeeks[monthWeeks.length-1].push(Utilities.formatDate(nextMonthDate, "YYYY-MM-DD"));
            nextMonthDate = Utilities.dateAdd(nextMonthDate, "day", 1);
        }

        return monthWeeks;
    }

    componentDidMount() {

        if (document.getElementsByClassName("content") && document.getElementsByClassName("content")[0]) {
            (document.getElementsByClassName("content")[0] as HTMLElement).style.maxWidth = 'initial';
            (document.getElementsByClassName("content")[0] as HTMLElement).style.paddingRight = '0px';
        }

        window.addEventListener('beforeunload', this.onUnmount, false);
        window.addEventListener('resize', this.handleResize);
        this.handleResize();

        if (window.location.pathname.startsWith("/external/timesheet") && document.querySelector("#react-app>div")) {
            // @ts-ignore
            document.querySelector("#react-app>div")!.style.paddingTop = "0px";
        } 

        if (!window.location.pathname.startsWith("/external/timesheet")) {
            this.props.timePresetsActions.getSearchTimePresets();
        }

        moment.updateLocale('en', {
            week: {
                dow: 1, // Monday is the first day of the week.
                doy: 4 // The week that contains Jan 4th is the first week of the year.
            }
        });

        const now = new Date();
        const hasPremiumSubscription = this.hasPremiumSubscription();

        let start = this.state.breakdown.indexOf("week") !== -1 ? (
                hasPremiumSubscription ?
                Utilities.startAndEndOfWeek(process.env.JEST_WORKER_ID === undefined ? new Date() : new Date(2021, 1, 13))[0] :
                new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0)
            ) :
                this.state.breakdown.indexOf("month") !== -1 ? new Date(now.getFullYear(), now.getMonth(), 1) :
                new Date()
        let end = this.state.breakdown.indexOf("week") !== -1 ? (
                hasPremiumSubscription ?
                Utilities.startAndEndOfWeek(process.env.JEST_WORKER_ID === undefined ? new Date() : new Date(2021, 1, 13))[1] :
                Utilities.dateAdd(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999), "day", 7)
            ) :
                this.state.breakdown.indexOf("month") !== -1 ? new Date(now.getFullYear(), now.getMonth() + 1, 0) :
                new Date()

        this.setState({
            end: end,
            start: start,
            monthWeeks: this.state.breakdown.indexOf("month") !== -1 ? this.calculateMonthWeeks(start, end) : [],
            hideResultsBefore: !this.hasPremiumSubscription() ? new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0) : null,
            hideResultsAfter: !this.hasPremiumSubscription() ? Utilities.dateAdd(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999), "day", 7) : null,
            hasPremiumSubscription: hasPremiumSubscription,
            hasTriedPremium: this.hasTriedPremiumSubscription(),
            initialWeeklyRollingViewStartingWithToday: this.state.breakdown.indexOf("week") !== -1 && !hasPremiumSubscription
        }, () => {
            this.reloadCalendar();
        });

    }

    formatDate = (str) => {
        let months = (str.getMonth()+1);
        let days = (str.getDate());
        let years = str.getFullYear();
        if (months < 10) months = "0" + months
        if (days < 10) days = "0" + days
        return years + "-" + months + "-" + days;
    }

    compileData = (res: RotaDto, staleData?: boolean) => {
        let workersGrouppedByDates = {};
        let hirersGrouppedByDates = {};
        let eventsGrouppedByWorkers = {};
        let eventsGrouppedByHirers = {};
        let eventsGrouppedByWorkersInternalIdFirst = {};
        let eventsGrouppedByHirersInternalIdFirst = {};
        let workerOfferMetadata: WorkerOfferMetadata = {};
        let hirerOfferMetadata: WorkerOfferMetadata = {};
        let offerNameCounts: OfferNameCounts = {};
        let smallestTimeMinutes = 0;
        let biggestTimeMinutes = 0;

        let allEvents: ScheduleEventItem[] = [];
        let contacts = {};

        for (let i = 0; i < res.dates.length; i++) {
            let events = res.dates[i].events
            const dateHash = Utilities.formatDate(new Date(res.dates[i].date), "YYYY-MM-DD");

            for (let j = 0; j < events.length; j++) {

                let event = events[j] as unknown as ScheduleEventItem;

                let offerName = event.offer ? (event.offer.externalSubjectName ? event.offer.externalSubjectName : event.offer.title) : event.title;
                event.title = offerName;
                if (event.offer)
                    event.offer.title = offerName

                if (!offerNameCounts[offerName]) {
                    offerNameCounts[offerName] = 0;
                }
                offerNameCounts[offerName]++;

                let startAtMinute = new Date(event.start || "").getHours()*60 + new Date(event.start || "").getMinutes()
                let endAtMinute = new Date(event.end || "").getHours()*60 + new Date(event.end || "").getMinutes()

                if (!smallestTimeMinutes || startAtMinute < smallestTimeMinutes) {
                    smallestTimeMinutes = startAtMinute;
                }

                if (!biggestTimeMinutes || endAtMinute > biggestTimeMinutes) {
                    biggestTimeMinutes = endAtMinute;
                }

                if (event.workers) {

                    for (let j = 0; j < event.workers.length; j++) {

                        const worker = event.workers[j];

                        if (!workersGrouppedByDates[dateHash]) {
                            workersGrouppedByDates[dateHash] = {}
                        }

                        if (!workersGrouppedByDates[dateHash][worker.externalId || worker.id || ""]) {
                            workersGrouppedByDates[dateHash][worker.externalId || worker.id || ""] = []
                        }

                        workersGrouppedByDates[dateHash][worker.externalId || worker.id || ""].push(event);

                        if (!eventsGrouppedByWorkers[worker.externalId || worker.id || ""]) {
                            eventsGrouppedByWorkers[worker.externalId || worker.id || ""] = {
                                dates: [],
                                worker: worker
                            };
                            eventsGrouppedByWorkersInternalIdFirst[worker.id || worker.externalId || ""] = {}
                        }

                        eventsGrouppedByWorkers[worker.externalId || worker.id || ""].dates.push(event);

                        if (!contacts[worker.id]) {
                            contacts[worker.id] = worker;
                        }

                        if (!workerOfferMetadata[worker.externalId || worker.id]) {
                            workerOfferMetadata[worker.externalId || worker.id] = {}
                        }

                        if ((!workerOfferMetadata[worker.externalId || worker.id][event.offerId]) && res.groups) {
                            let offer = res.groups.find(group => group.id === event.offerId);
                            if (offer) {
                                workerOfferMetadata[worker.externalId || worker.id][event.offerId] = {
                                    color: COLORS[Object.keys(workerOfferMetadata[worker.externalId || worker.id]).length] || "#333",
                                    title: offer.title,
                                    hours: offer.hours
                                }
                            }
                        }

                    }
                }

                if (event.agencyResponses) {
                    for (let j = 0; j < event.agencyResponses.length; j++) {
                        const agencyResponse = event.agencyResponses[j];
                        const id = agencyResponse.userContactId;

                        if (!workersGrouppedByDates[dateHash]) {
                            workersGrouppedByDates[dateHash] = {}
                        }

                        if (!workersGrouppedByDates[dateHash][id]) {
                            workersGrouppedByDates[dateHash][id] = []
                        }

                        workersGrouppedByDates[dateHash][id].push(event);

                        if (!eventsGrouppedByWorkers[id]) {
                            eventsGrouppedByWorkers[id] = {
                                dates: [],
                                worker: {
                                    id: id + "",
                                    firstName: agencyResponse.organisationName,
                                    organisationId: agencyResponse.organisationId,
                                    organisationDomain: agencyResponse.organisationDomain,
                                    organisationName: agencyResponse.organisationName,
                                    lastName: "",
                                    offerConfirmationResponse: 0
                                }
                            };
                            eventsGrouppedByWorkersInternalIdFirst[id] = {}
                        }

                        eventsGrouppedByWorkers[id].dates.push(event);

                        if (!contacts[id]) {
                            contacts[id] = {
                                id: id + "",
                                firstName: agencyResponse.organisationName,
                                organisationId: agencyResponse.organisationId,
                                organisationDomain: agencyResponse.organisationDomain,
                                organisationName: agencyResponse.organisationName,
                                lastName: "",
                            };
                        }

                        if (!workerOfferMetadata[id]) {
                            workerOfferMetadata[id] = {}
                        }

                        if ((!workerOfferMetadata[id][event.offerId]) && res.groups) {
                            let offer = res.groups.find(group => group.id === event.offerId);
                            if (offer) {
                                workerOfferMetadata[id][event.offerId] = {
                                    color: COLORS[Object.keys(workerOfferMetadata[id]).length] || "#333",
                                    title: offer.title,
                                    hours: offer.hours
                                }
                            }
                        }
                    }

                    console.log("Agency responses written", eventsGrouppedByWorkers)
                }

                if (event.hirers) {
                    for (let j = 0; j < event.hirers.length; j++) {
                        const hirer = event.hirers[j];
                        const id = hirer.organisationId || hirer.organisationExternalId || hirer.organisationDomain;

                        if (!hirersGrouppedByDates[dateHash]) {
                            hirersGrouppedByDates[dateHash] = {}
                        }

                        if (!hirersGrouppedByDates[dateHash][id]) {
                            hirersGrouppedByDates[dateHash][id] = []
                        }

                        hirersGrouppedByDates[dateHash][id].push(event);

                        if (!eventsGrouppedByHirers[id]) {
                            eventsGrouppedByHirers[id] = {
                                dates: [],
                                hirer: hirer
                            };
                            eventsGrouppedByHirersInternalIdFirst[id] = {}
                        }

                        eventsGrouppedByHirers[id].dates.push(event);

                        if (!contacts[id]) {
                            contacts[id] = hirer;
                        }

                        if (!hirerOfferMetadata[id]) {
                            hirerOfferMetadata[id] = {}
                        }

                        if ((!hirerOfferMetadata[id][event.offerId]) && res.groups) {
                            let offer = res.groups.find(group => group.id === event.offerId);
                            if (offer) {
                                hirerOfferMetadata[id][event.offerId] = {
                                    color: COLORS[Object.keys(hirerOfferMetadata[id]).length] || "#333",
                                    title: offer.title,
                                    hours: offer.hours
                                }
                            }
                        }
                    }
                }

                // @ts-ignore
                event.start = new Date(event.start);
                // @ts-ignore
                event.end = new Date(event.end);
                allEvents.push(event);

            }
        }

        localStorage.setItem("timesheetWorkersForLastPeriod", JSON.stringify(Object.keys(eventsGrouppedByWorkersInternalIdFirst)));

        let days = res.dates.map(item => Utilities.formatDate(new Date(item.date), "YYYY-MM-DD")).sort();

        let firstDate = new Date(days[0]);
        let lastDate = Utilities.dateAdd(new Date(days[days.length-1]), "day", 1);
        days = [];

        while (firstDate < lastDate) {
            days.push(Utilities.formatDate(firstDate, "YYYY-MM-DD"));
            firstDate = Utilities.dateAdd(firstDate, "day", 1);
        }

        let workers = res.workers;
        let hirers = res.hirers;
        if (res.ratings) {
            if (workers) {
                for (let i = 0; i < workers.length; i++) {
                    const worker = workers[i];
                    const rating = res.ratings.find(rating => (rating.recipientUserId && rating.recipientUserId === worker.id) || (rating.recipientExternalId && rating.recipientExternalId === worker.externalId))
                    console.log(worker, rating)
                    if (rating) {
                        worker.rating = {
                            stars: rating.stars,
                            publicComment: rating.publicComment,
                            privateComment: rating.privateComment,
                        }
                    }
                }
            }
            if (hirers) {
                for (let i = 0; i < hirers.length; i++) {
                    const hirer = hirers[i];
                    const rating = res.ratings.find(rating => (rating.recipientOrgId && rating.recipientOrgId === hirer.organisationId) || (rating.recipientExternalOrgId && rating.recipientExternalOrgId === hirer.organisationExternalId))
                    if (rating) {
                        hirer.rating = {
                            stars: rating.stars,
                            publicComment: rating.publicComment,
                            privateComment: rating.privateComment,
                        }
                    }
                }
            }
        }

        this.setState({ 
            loading: false, 
            contacts: contacts, 
            flattenedEvents: allEvents, 
            days: days,
            workersGrouppedByDates: workersGrouppedByDates,
            eventsGrouppedByWorkers: eventsGrouppedByWorkers,
            hirersGrouppedByDates: hirersGrouppedByDates,
            eventsGrouppedByHirers: eventsGrouppedByHirers,
            workers: workers,
            hirers: res.hirers,
            agencyContactEmail: res.agencyContactEmail,
            workerOfferMetadata: workerOfferMetadata,
            hirerOfferMetadata: hirerOfferMetadata,
            terminology: res.terminology?.map((item, i) => {
                item.color = COLORS[i] || "#333";
                return item;
            }),
            alreadyRespondedWith: res.responseTypeId || undefined,
            offerNameCounts: offerNameCounts,
            currentWorker: (res.workerId || res.workerExternalId) ? {
                firstName: res.workerFirstName || "",
                lastName: res.workerLastName || "",
                id: res.workerId || "",
                externalId: res.workerExternalId || "",
                headline: res.workerHeadline || "",
                imageUrl: res.workerImageUrl || "",
            } : undefined,
            totalGrossPay: res.totalGrossPay || null,
            totalGrossCharge: res.totalGrossCharge || null,
            totalHours: res.totalHours || null,
            currency: res.currency || null,
            firstLoad: staleData ? true : false
        }, () => {
            console.log("FIrst load: " + this.state.firstLoad)
        })

        let now = new Date();

        if (window.location.pathname.startsWith("/external/timesheet")) {

            const workersWithoutRating = Object.keys(this.state.eventsGrouppedByWorkers).filter((key, i) => {
                let worker = this.state.eventsGrouppedByWorkers[key];
                // @ts-ignore
                let workerExtended: Worker = this.state.workers?.find(w => (w.externalId && w.externalId === worker.worker.externalId) || (w.id && w.id === worker.worker.id))
                const hasRating = workerExtended?.rating?.stars || workerExtended?.providerOrgRating;
                return (workerExtended && !hasRating);
            }).length;

            this.setState({
                start: new Date(res.firstDate || ""),
                joyride: workersWithoutRating > 0 ? "rating" : null

            }, () => {
                let weekDay = this.state.start?.getDay();
                moment.updateLocale('en', {
                    week: {
                        dow: weekDay || 0,
                        doy: 4
                    }
                });
                this.setState({
                    timeSheetInited: true
                })
            })

            let restrictToMinHour = new Date(Utilities.formatDate(now, "YYYY-MM-DD") + "T" + (res.firstHour ? (res.firstHour < 10 ? "0" + res.firstHour : res.firstHour) : "00") + ':00:00+00:00');
            let restrictToMaxHour = new Date(Utilities.formatDate(now, "YYYY-MM-DD") + "T" + (res.lastHour ? (res.lastHour < 10 ? "0" + res.lastHour : res.lastHour) : "00") + ':00:00+00:00');

            localStorage.setItem("timesheet-custom-preset-start", (smallestTimeMinutes*60).toString());
            localStorage.setItem("timesheet-custom-preset-end", (biggestTimeMinutes*60).toString());

            this.setState({
                restrictToMinHour: restrictToMinHour,
                restrictToMaxHour: restrictToMaxHour,
                restrictToMinHourNumber: restrictToMinHour.getHours(),
                restrictToMaxHourNumber: restrictToMaxHour.getHours(),
                fromTimeLabel: Utilities.toHHMM(smallestTimeMinutes*60),
                toTimeLabel: Utilities.toHHMM(biggestTimeMinutes*60)
            }, () => {
                this.handleResize();
            })

        } else {

            let restrictToMinHour = new Date()
            restrictToMinHour.setHours(0);
            restrictToMinHour.setMinutes(0)
            let restrictToMaxHour = new Date();
            restrictToMaxHour.setHours(23);
            restrictToMaxHour.setMinutes(59)

            this.setState({
                restrictToMinHour: restrictToMinHour,
                restrictToMaxHour: restrictToMaxHour,
            }, () => { })

            this.handleResize();

        }
    }

    reloadCalendar = async (jumpToToday?: boolean) => {

        const now = new Date();
        let start = this.state.breakdown.indexOf("week") !== -1 ? Utilities.startAndEndOfWeek(new Date())[0] :
            this.state.breakdown.indexOf("month") !== -1 ? new Date(now.getFullYear(), now.getMonth(), 1) :
            new Date()
        let end = this.state.breakdown.indexOf("week") !== -1 ? Utilities.startAndEndOfWeek(new Date())[1] :
            this.state.breakdown.indexOf("month") !== -1 ? new Date(now.getFullYear(), now.getMonth() + 1, 0) :
            new Date()

        let haveStaleData = false;
        if (!window.location.pathname.startsWith("/external") && this.state.firstLoad) {
            const staleRes = await getValue('rota');
            if (staleRes) {
                this.compileData(staleRes, true)
                haveStaleData = true;
                this.setState({
                    stale: true,
                    loading: false,
                })
            }
        }
    
        this.setState({
            loading: !haveStaleData,
            start: jumpToToday ? start : this.state.start,
            end: jumpToToday ? end : this.state.end,
        }, async () => {

            InternalTracker.trackEvent('Schedule Query', {
                start: this.formatDate(this.state.start),
                end: this.formatDate(this.state.end),
                breakdown: this.state.breakdown,
                viewPreset: this.state.viewPreset,
                source: 
                    window.location.pathname.startsWith("/external/timesheet") ?
                    "External Timesheet" :
                        window.location.pathname.startsWith("/external/schedule") ?
                        "External Schedule" :
                            "Internal Schedule"
            });

            let res: RotaDto | null = null;
            let type;

            if (window.location.pathname.startsWith("/external/timesheet/rota/worker")) { 
                res = await OfferAPI.getRotaForWorkerTimeSheet(localStorage.getItem(EphemeralAccessTokenStorageKey) || "");
                type = "external-timesheet-worker";
                if (res.agencyId) {
                    localStorage.setItem("timesheet-agency-id", res.agencyId);
                }
                if (res.agencyName) {
                    localStorage.setItem("timesheet-agency-name", res.agencyName);
                }
                if (res.workerExternalId) {
                    localStorage.setItem("timesheet-external-worker-id", res.workerExternalId);
                }
                if (res.workerId) {
                    localStorage.setItem("timesheet-worker-id", res.workerId);
                }
            } else if (window.location.pathname.startsWith("/external/timesheet")) {
                res = await OfferAPI.getRotaForTimeSheet(localStorage.getItem(EphemeralAccessTokenStorageKey) || "");
                type = "external-timesheet-hirer";
            } else if (window.location.pathname.startsWith("/external/schedule")) {
                res = await OfferAPI.getShiftRotaExternal(this.formatDate(this.state.start), this.formatDate(localStorage.getItem('offersContinueOnWeekdays') === 'true' && this.state.breakdown.indexOf("week") !== -1 ? Utilities.dateSub(this.state.end || new Date(), "day", 2) : this.state.end), localStorage.getItem(EphemeralAccessTokenStorageKey) || "");
                type = "external-timesheet-hirer";
            } else {
                console.log("Res: ", res)
                res = await OfferAPI.getShiftRota(this.formatDate(this.state.start), this.formatDate(localStorage.getItem('offersContinueOnWeekdays') === 'true' && this.state.breakdown.indexOf("week") !== -1 ? Utilities.dateSub(this.state.end || new Date(), "day", 2) : this.state.end));
                type = "internal-timesheet-hirer";
            }

            document.body.classList.add(type);

            if (this.props.onDataLoad !== undefined) {
                this.props.onDataLoad(res?.workers?.length || 0);
            }

            this.setState({ type: type });

            let showingDemoRes = false;
            if (res?.workers?.length === 0 && !type.includes('worker') && !localStorage.getItem("rota-demo-dismiss") && this.state.firstLoad && !window.location.pathname.startsWith("/external")) {
                // @ts-ignore
                res = DemoRes;
                showingDemoRes = true;
                this.setState({
                    showingDemoRes: true,
                    start: new Date("2022-02-07"),
                    end: new Date("2022-02-13")
                })
            } else {
                this.setState({
                    showingDemoRes: false
                })
            }

            localStorage.removeItem("timesheetWorkerSelected");

            if (res) {
                this.compileData(res, false);
                if (!window.location.pathname.startsWith("/external") && !showingDemoRes) {
                    setValue("rota", res);
                }
                this.setState({
                    stale: false,
                })
            } else {
                this.setState({ loading: false })
            }

        })

    };

    toggleStatusFilter = (statusId: string) => {
        let filters = this.state.filters;
        let isCheckedPast = filters.statuses.indexOf(parseInt(statusId));
        if (isCheckedPast === -1) {
            filters.statuses.push(parseInt(statusId))
        } else {
            filters.statuses.splice(isCheckedPast, 1);
        }
        InternalTracker.trackEvent('Changed Schedule Status Filter', {
            value: statusId,
            checked: isCheckedPast === -1
        });
        this.setState({ filters: filters }, () => {  })
    }

    print = () => {
        const tableRowElements = document.querySelectorAll('.print-area .sticky-table-row');
        const tableRowsWrapperElement = document.querySelector('.print-area');
        const titleElement = document.querySelector('.DayPickerInput');
        const filterElement = document.querySelector('.shift-filter');

        this.setState({
            printing: true,
            printingProgress: 0
        }, () => {
            setTimeout(async () => {
                InternalTracker.trackEvent('Printed Schedule');
                let allResults: any[] = [];
                let promises: any[] = [];
                promises.push({ promise: this.getNodeAsBase64(titleElement) });
                promises.push({ promise: this.getNodeAsBase64(filterElement) });

                if (tableRowElements.length > 30) {
                    for (let i = 0; i < tableRowElements.length; i++) {
                        const tableRow = tableRowElements[i];
                        // @ts-ignore
                        promises.push({ promise: this.getNodeAsBase64(tableRow), type: tableRow.getAttribute("data-type") });
                        if (promises.length > 5) {
                            const results = await Promise.all(promises.map(item => item.promise));
                            allResults = allResults.concat(results.map((item, i) => {
                                return {
                                    type: promises[i].type,
                                    image: item
                                }
                            }));
                            promises = [];
                            this.setState({
                                printingProgress: i / tableRowElements.length * 100
                            })
                        }
                    }
                } else {
                    promises.push({ promise: this.getNodeAsBase64(tableRowsWrapperElement), type: "date" });
                }

                if (promises.length > 0) {
                    const results = await Promise.all(promises.map(item => item.promise));
                    allResults = allResults.concat(results.map((item, i) => {
                        return {
                            type: promises[i].type,
                            image: item
                        }
                    }));
                    promises = [];
                }

                allResults = allResults.filter(item => item.image);
                
                console.log(allResults);

                let MyDoc = (
                    <Document>
                        <Page size="A4" style={{ padding: 20 }}>
                            { allResults.map((result, i) => {
                                return (
                                    <View>
                                        <Image src={result.image} style={{ width: '100%', marginTop: result.type === "date" ? 15 : 0 }} />
                                    </View>
                                )
                            }) }
                        </Page>
                    </Document>
                );

                const blob = await pdf(MyDoc).toBlob();
                const fileName = "Schedule";

                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = "[Updatedge Schedule] " + ((this.state.breakdown === "day") ? Utilities.formatDate(this.state.start || new Date(), "ds d mms (YYYY)") :
                    this.state.breakdown.indexOf("week") !== -1 ? (Utilities.formatDate(this.state.start || new Date(), "ds d mms (YYYY)") + " - " + Utilities.formatDate(this.state.skipWeekends ? Utilities.dateSub(this.state.end || new Date(), "day", 2) : (this.state.end || new Date()), "ds d mms (YYYY)")) :
                    this.state.breakdown.indexOf("month") !== -1 ? Utilities.formatDate(this.state.start || new Date(), "MMM YYYY") :
                    ""
                ) + ".pdf";
                document.body.append(link);
                link.click();
                link.remove();
                this.setState({
                    printing: false
                })
                setTimeout(() => URL.revokeObjectURL(link.href), 7000);
            })
          })
    }

    getNodeAsBase64 = (node) => {
        return new Promise((resolve, reject) => {
            const scale = 2;
            domtoimage.toPng(node, { 
                quality: 1,
                  width: node.clientWidth * scale,
                  height: node.clientHeight * scale,
                  style: { transform: 'scale('+scale+')', transformOrigin: 'top left'}
              })
                .then(async(dataUrl) => {
                    resolve(dataUrl as string);
                }).catch((error) => {
                    console.error('oops, something went wrong!', error);
                    // reject(error);
                    resolve(null)
                })
        })
    }

    renderDayMarkers = (shiftWidth: number, hours: number[]) => {
        return (
            <div 
                className='day-markers'
                style={{
                    height: 12,
                    width: shiftWidth + 10,
                    zIndex: 0
                }}
            >
                { hours.map((hour, hourI) => {
                    return (
                        <span style={{
                            left: (shiftWidth + 10) * (hourI+1) / hours.length
                        }}>

                        </span>
                    )
                }) }
            </div>
        )
    }

    renderHourMarkers = (minutes) => {
        return (
            <div className='hour-markers'>
                <span className='five' style={{ visibility: (minutes === 5) ? "visible" : "hidden" }}></span>
                <span className='five ten' style={{ visibility: (minutes === 10 || minutes === 5) ? "visible" : "hidden" }}></span>
                <span className='five fifteen' style={{ visibility: (minutes === 5) ? "visible" : "hidden" }}></span>
                <span className='five ten' style={{ visibility: (minutes === 10 || minutes === 15 || minutes === 5) ? "visible" : "hidden" }}></span>
                <span className='five' style={{ visibility: (minutes === 5) ? "visible" : "hidden" }}></span>
                <span className='five ten fifteen thirty' style={{ visibility: (minutes === 5 || minutes === 10) ? "visible" : "hidden" }}></span>
                <span className='five' style={{ visibility: (minutes === 5) ? "visible" : "hidden" }}></span>
                <span className='five ten' style={{ visibility: (minutes === 10 || minutes === 15 || minutes === 5) ? "visible" : "hidden" }}></span>
                <span className='five fifteen' style={{ visibility: (minutes === 5) ? "visible" : "hidden" }}></span>
                <span className='five ten' style={{ visibility: (minutes === 10 || minutes === 5) ? "visible" : "hidden" }}></span>
                <span className='five' style={{ visibility: (minutes === 5) ? "visible" : "hidden" }}></span>
            </div>
        )
    }

    renderShiftHourlyRowHeader = (hours: number[], zoomLevel) => {
        return hours.map((hour, hourI) => {
            let labels = (zoomLevel === 1) ? [hour + ":00"] :
                (zoomLevel === 2) ? [hour + ":00", hour + ":30"] :
                (zoomLevel === 3) ? [hour + ":00", hour + ":15", hour + ":30", hour + ":45"] : []

            return labels.map(label => {
                return (
                    <Cell
                        style={{
                            padding: 0,
                            backgroundColor: this.state.printing ? 'whitesmoke' : undefined,
                        }}
                    >
                        <div
                            style={{
                                width: 52,
                                height: 52,
                                display: 'flex',
                                fontWeight: 700,
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            <span>{label}</span>
                        </div>
                    </Cell>
                )  
            })
        })      
    }

    renderShiftDailyRowHeader = (day: string, breakdown: BreakdownType, hours: number[]) => {
        if (this.state.mainView === 'shift' && this.state.breakdown.startsWith("month") && !Utilities.isSameMonth(new Date(day ), new Date(this.state.start || ""))) {
            return (
                <Cell>
                    <div style={{
                        width: this.state.shiftDayLength,
                        display: 'inline-block',
                        backgroundColor: this.state.printing ? 'whitesmoke' : undefined
                    }}></div>
                </Cell>
            )
        }

        return (
            <Cell>
                <div
                    style={{
                        width: this.state.shiftDayLength,
                        display: 'inline-block',
                        fontWeight: 700,
                        textAlign: 'center',
                        backgroundColor: this.state.printing ? 'whitesmoke' : undefined
                    }}
                >
                    {Utilities.formatDate(new Date(day), "DD, d mm")}
                    { this.state.breakdown.indexOf("proportional") !== -1 && (" (" + hours[0] + "-" + hours[hours.length-1] + ")") }
                </div>
            </Cell>
        )
    }

    renderShiftHourlyRow = (worker: Worker | null, hirer: RotaHirerDto | null, dates: ScheduleEventItem[], k: number, VIEW_COMPONENTS: ViewComponents | null, hours: number[], zoomLevel: number) => {
        if (!VIEW_COMPONENTS)
            return null;

        if (worker === null && hirer === null)
            return null;
            
        const fullName = worker !== null ? (worker.firstName + " " + worker.lastName) : (hirer !== null ? hirer.organisationName : "");

        if (this.state.filters.contactNames.length !== 0 && this.state.filters.contactNames.indexOf(fullName) === -1)
            return null

        let eventsToRender: any[] = []
        let anyMatches = false;

        let events = JSON.parse(JSON.stringify(dates));
        let confirmedSeconds = 0;

        for (let i = 0; i < events.length; i++) {
            const event = events[i];
            const shiftOfferType = this.getShiftOfferType(event, worker, hirer) // Which of the 5 categories it falls under (you declined, you confirmed...)

            if (shiftOfferType === ShiftStatus.HirerConfirmed || shiftOfferType === ShiftStatus.ConfirmedAgencyBooking) {
                confirmedSeconds += event.adjustedLengthMins ? (event.adjustedLengthMins * 60) :
                    new Date(event.start) < new Date(event.end) ? Utilities.differenceBetweenDatesSeconds(new Date(event.start), new Date(event.end))
                    : Utilities.differenceBetweenDatesSeconds(new Date(event.start), new Date(Utilities.dateAdd(new Date(event.end), "day", 1)));
            }

            if (this.state.filters.offerNames.length !== 0 && this.state.filters.offerNames.indexOf(event.title) === -1) {
                events.splice(i, 1);
                i--;
                continue;
            }

            if (this.state.filters.statuses.length !== 0 && this.state.filters.statuses.indexOf(shiftOfferType) === -1) {
                events.splice(i, 1);
                i--;
                continue;
            }
            const PIXELS_PER_MINUTE = (52 + 2) / (zoomLevel === 1 ? 60 : zoomLevel === 2 ? 30 : 15);
            let presetStart = new Date();
            presetStart.setHours(hours[0]);
            let presetEnd = new Date();
            presetEnd.setHours(hours[hours.length - 1]);

            let offSetFromLeft = (new Date(event.start).getHours() * 60 + new Date(event.start).getMinutes()) - (presetStart.getHours() * 60);
            let widthFromLeft = (new Date(event.end).getHours() * 60 + new Date(event.end).getMinutes()) - (new Date(event.start).getHours() * 60 + new Date(event.start).getMinutes())

            eventsToRender.push({
                type: shiftOfferType,
                color: ShiftStatusDetails[shiftOfferType].color,
                solidColor: ShiftStatusDetails[shiftOfferType].solidColor,
                name: event.title,
                offerId: event.offerId,
                period: Utilities.formatDate(event.start, "HH:MM") + "-" + Utilities.formatDate(event.end, "HH:MM"),
                startPeriod: Utilities.formatDate(event.start, "HH:MM"),
                endPeriod: Utilities.formatDate(event.end, "HH:MM"),
                left: offSetFromLeft * PIXELS_PER_MINUTE,
                width: widthFromLeft * PIXELS_PER_MINUTE
            })

            anyMatches = true
        }

        if (!anyMatches)
            return null;

        return (
            <Row 
                style={{
                    position: 'relative'
                }}
                onClick={() => {
                    
                }}
            >
                <Cell 
                    style={{
                        width: 140,
                        minWidth: 140,
                        height: this.state.breakdown === "day" ? 52 : 'initial',
                        zIndex: 3
                    }}
                >
                    {this.renderContact(worker, null, VIEW_COMPONENTS, confirmedSeconds ? Utilities.secondsToHms(confirmedSeconds) : "0 hours", this.state.breakdown)}
                </Cell>
                { hours.map((hour) => {
                    let labels = (zoomLevel === 1) ? [0] :
                        (zoomLevel === 2) ? [0, 1] :
                        (zoomLevel === 3) ? [0, 2, 1, 2] : []

                    return labels.map(hour => {
                        return (
                            <Cell
                                onClick={() => {
                                   
                                }}
                                style={{
                                    minWidth: 52,
                                    width: 52,
                                    height: 52,
                                    padding: 0,
                                    position: 'relative'
                                }}
                            >
                                { ((dates[0] && !this.state.showingDemoRes && this.state.hideResultsBefore && this.state.hideResultsAfter && (this.state.hideResultsBefore > new Date(dates[0].start || "") || this.state.hideResultsAfter < new Date(dates[0].start || "")))) ?
                                    <div 
                                        className='pro-mask'
                                        style={{
                                            width: 54
                                        }}
                                        onClick={() => {
                                            const proBtn = document.querySelector('#activate-free-trial-btn') as HTMLButtonElement;
                                            if (proBtn) {
                                                proBtn.click();
                                            }
                                        }}
                                    >
                                        Upgrade to View
                                    </div>
                                    :
                                    <div 
                                        className="worker-hour"
                                        style={{
                                            minWidth: 52,
                                            width: 52,
                                            position: 'absolute',
                                            top: 0,
                                            left: 0
                                        }}
                                    >
                                        { zoomLevel === 1 ? this.renderHourMarkers(5) : zoomLevel === 2 ? this.renderHourMarkers(10) : this.renderHourMarkers(15) }
                                    </div>
                                }
                            </Cell>
                        )
                    })
                }) }
                <div 
                    className="events"
                    style={{
                        height: 52
                    }}
                >
                    { eventsToRender.map(event => {
                        return this.renderEvent(event, VIEW_COMPONENTS, this.state.breakdown, worker)
                    }) }
                </div>
            </Row>   
        )
    }

    getShiftOfferType = (event: any, worker: Worker | null, hirer: RotaHirerDto | null) => {

        if (worker === null && hirer === null) {
            console.log("No worker or hirer", event)
            return ShiftStatus.Uncategorized;
        }

        const workerItem = worker !== null ? 
            (event.workers.find(w => w.id === worker.id) || event.agencyResponses.find(w => w.userContactId.toString() === worker.id)) : 
            hirer !== null ?  event.hirers.find(h => h.organisationId === hirer.organisationId) : 
            null; // todo does it evne need hirer?
        const offerConfirmationResponse = workerItem.offerConfirmationResponse; // What worker decided
        const offerResponse = workerItem.offerResponse; // What hirer decided
        const offerCompiledStatus = event.offer.statusId // How the overall offer looks
        const agencyResponse = (worker && event.agencyResponses) ? event.agencyResponses.find(w => w.userContactId.toString() === worker.id) : null;

        const type = (offerResponse === OfferResponseType.Cancelled) ? ShiftStatus.ContactWithdrew :
            (offerCompiledStatus === CompiledStatus.Confirmed && offerConfirmationResponse === OfferConfirmationResponseTypes.Confirmed && event.offer.externalHirerName) ? ShiftStatus.ConfirmedAgencyBooking :
            (offerCompiledStatus === CompiledStatus.Confirmed && offerConfirmationResponse === OfferConfirmationResponseTypes.Confirmed) ? ShiftStatus.HirerConfirmed : // Confirmed offer, where this worker was awarded the wo
            (offerCompiledStatus === CompiledStatus.WithdrawnByHirer && offerConfirmationResponse === OfferConfirmationResponseTypes.Confirmed) ? ShiftStatus.HirerCancelled : // Confirmed offer, where this worker was awarded the wo
            (offerResponse === OfferResponseType.AwaitingResponse && offerConfirmationResponse === OfferConfirmationResponseTypes.NotSpecified) ? ShiftStatus.ContactNoResponse : // Worker hasn't replied, and hirer hasn't acted
            (offerResponse === OfferResponseType.Accepted && offerConfirmationResponse === OfferConfirmationResponseTypes.NotSpecified) ? ShiftStatus.ContactApplied : // Worker accepted, and hirer hasn't acted
            (offerResponse === OfferResponseType.Rejected) ? ShiftStatus.ContactRejected : // Worker rejected, and hirer hasn't actedrk
            agencyResponse && agencyResponse.responseType === AgencyResponseType.Accepted ? ShiftStatus.AgencyAccepted : // Agency confirmed
            agencyResponse && agencyResponse.responseType === AgencyResponseType.Rejected ? ShiftStatus.AgencyRejected : // Agency rejected
            agencyResponse && agencyResponse.responseType === AgencyResponseType.Pending ? ShiftStatus.PendingAgency : // Agency withdrawn
            ShiftStatus.Uncategorized

        // console.log("TTTType: " + type, event);
        return type;
    }

    drillDown = (toDay) => {
        InternalTracker.trackEvent('Schedule Drilled Down to Day', {
            value: toDay
        })

        if (this.state.breakdown !== "day") {
            this.switchBreakdown("day", toDay)
        }
    }

    renderShiftDailyRow = (worker: Worker | null, hirer: RotaHirerDto | null, dates: ScheduleEventItem[], k: number, VIEW_COMPONENTS: ViewComponents | null, monthsWeekI: number | undefined, hours: number[], breakdown: BreakdownType) => {
        if (!VIEW_COMPONENTS) {
            return null;
        }

        if (worker === null && hirer === null) {
            return null;
        }

        const fullName = worker !== null ? (worker.firstName + " " + worker.lastName) : hirer !== null ? hirer.organisationName : "";

        if (this.state.filters.contactNames.length !== 0 && this.state.filters.contactNames.indexOf(fullName) === -1)
            return null

        let daysToRender = {};
        let anyMatches = false;

        let DAYS = monthsWeekI !== undefined ? this.state.monthWeeks[monthsWeekI] : this.state.days
        let confirmedSeconds: number = 0;
        if (this.state.skipWeekends) {
            DAYS = [DAYS[0], DAYS[1], DAYS[2], DAYS[3], DAYS[4]]
        }
        for (let j = 0; j < DAYS.length; j++) {
            const day = DAYS[j];
            let events = JSON.parse(JSON.stringify(dates.filter(item => Utilities.formatDate(new Date(item.start || ""), "YYYY-MM-DD") === day)));
            if (worker && parseInt(worker.id) === 6713) {
                console.log("EVENT TO RENDER: ", events, "of ", dates)
            }
            daysToRender[day] = [];

            for (let i = 0; i < events.length; i++) {
                const event = events[i];
                const shiftOfferType = this.getShiftOfferType(event, worker, hirer) // Which of the 5 categories it falls under (you declined, you confirmed...)

                if (shiftOfferType === ShiftStatus.HirerConfirmed || shiftOfferType === ShiftStatus.ConfirmedAgencyBooking) {
                    confirmedSeconds += event.adjustedLengthMins ? (event.adjustedLengthMins * 60) :
                        new Date(event.start) < new Date(event.end)
                        ? Utilities.differenceBetweenDatesSeconds(new Date(event.start), new Date(event.end))
                        : Utilities.differenceBetweenDatesSeconds(new Date(event.start), new Date(Utilities.dateAdd(new Date(event.end), "day", 1)));
                }

                if (this.state.filters.offerNames.length !== 0 && this.state.filters.offerNames.indexOf(event.title) === -1) {
                    events.splice(i, 1);
                    i--;
                    continue;
                }

                if (this.state.filters.statuses.length !== 0 && this.state.filters.statuses.indexOf(shiftOfferType) === -1) {
                    events.splice(i, 1);
                    i--;
                    continue;
                }

                if (breakdown.indexOf("proportional") !== -1 && hours) {
                    const PIXELS_PER_MINUTE = (this.state.shiftDayLength) / 60 / (hours[hours.length - 1] - hours[0] - 1);
                    let presetStart = new Date();
                    presetStart.setHours(hours[0]);
                    let presetEnd = new Date();
                    presetEnd.setHours(hours[hours.length - 1]);

                    let end = new Date(event.end);
                    let start = new Date(event.start);
                    if (end.getHours() < start.getHours()) {
                        end.setHours(23);
                        end.setMinutes(59);
                    }
        
                    event.left = ( (new Date(start).getHours() * 60 + new Date(start).getMinutes()) - (presetStart.getHours() * 60) ) * PIXELS_PER_MINUTE
                    event.width = ( (new Date(end).getHours() * 60 + new Date(end).getMinutes()) - (new Date(start).getHours() * 60 + new Date(start).getMinutes()) ) * PIXELS_PER_MINUTE
                    
                    if (event.left >= this.state.shiftDayLength)
                        continue;
                } else {
                    event.left = undefined
                    event.width = undefined
                }

                daysToRender[day].push({
                    type: shiftOfferType,
                    color: ShiftStatusDetails[shiftOfferType].color,
                    solidColor: ShiftStatusDetails[shiftOfferType].solidColor,
                    name: event.title,
                    offerId: event.offerId,
                    period: Utilities.formatDate(event.start, "HH:MM") + "-" + Utilities.formatDate(event.end, "HH:MM"),
                    startPeriod: Utilities.formatDate(event.start, "HH:MM"),
                    endPeriod: Utilities.formatDate(event.end, "HH:MM"),
                    start: event.start,
                    end: event.end,
                    left: event.left,
                    width: event.width,
                    offer: event.offer
                })

                anyMatches = true
            }
        }

        if (!anyMatches)
            return null;

        let detailRows = breakdown.indexOf("proportional detailed") !== -1 ? [0] : [];

        return (
            <React.Fragment>
                <Row>
                    <Cell 
                        style={{
                            width: 140,
                            minWidth: 140,
                            zIndex: 3,
                            border: detailRows.length !== 0 ? 0 : '2px solid #e5e5e5'
                        }}
                    >
                        {this.renderContact(worker, null, VIEW_COMPONENTS, confirmedSeconds ? Utilities.secondsToHms(confirmedSeconds) : "0 hours", breakdown)}
                    </Cell>
                    { Object.keys(daysToRender).map((dayKey) => {
                        let events = daysToRender[dayKey];
                        return (
                            <Cell
                                style={{
                                    minWidth: this.state.shiftDayLength,
                                    width: this.state.shiftDayLength,
                                    padding: VIEW_COMPONENTS.colPadding,
                                    verticalAlign: breakdown.indexOf("proportional") !== -1 ? 'top' : 'initial',
                                    borderBottom: detailRows.length !== 0 ? 0 : '2px solid #e5e5e5',
                                    position: 'relative'
                                }}
                                onClick={() => {
                                    this.drillDown(dayKey);
                                }}
                            >
                                { (!this.state.showingDemoRes && this.state.hideResultsBefore && this.state.hideResultsAfter && (this.state.hideResultsBefore > new Date(dayKey) || this.state.hideResultsAfter < new Date(dayKey))) ?
                                    <div 
                                        className='pro-mask'
                                        style={{
                                            width: (this.state.shiftDayLength + 10),
                                            height: this.state.breakdown.indexOf("proportional detailed") !== -1 ? "calc(100% + 30px)" : "100%",
                                        }}
                                        onClick={() => {
                                            const proBtn = document.querySelector('#activate-free-trial-btn') as HTMLButtonElement;
                                            if (proBtn) {
                                                proBtn.click();
                                            }
                                        }}
                                    >
                                        Upgrade to View
                                    </div> :  null
                                }
                                <div 
                                    className="worker-day"
                                    style={{
                                        minWidth: (this.state.shiftDayLength + 10),
                                        width: (this.state.shiftDayLength + 10)
                                    }}
                                >
                                    { breakdown.indexOf("proportional") !== -1 && this.renderDayMarkers(this.state.shiftDayLength, hours || []) }
                                    { !((this.state.mainView === 'shift' && this.state.breakdown.startsWith("month") && !Utilities.isSameMonth(new Date(dayKey), new Date(this.state.start || "")))) &&
                                        <div className="events">
                                            { events.map((event) => {
                                                return this.renderEvent(event, VIEW_COMPONENTS, this.state.breakdown, worker)
                                            }) }
                                        </div>
                                    }
                                </div>
                            </Cell>
                        )
                    }) }
                </Row>   
                { detailRows.map(row => {
                    return (
                        <Row style={{
                            
                        }}>
                            <Cell 
                                style={{
                                    width: 140,
                                    minWidth: 140,
                                    zIndex: 3,
                                    borderRight: 0,
                                    backgroundColor: this.state.printing ? 'white' : 'whitesmoke',
                                    border: detailRows.length !== 0 ? 0 : '2px solid #e5e5e5'
                                }}
                            >
                                
                            </Cell>
                        { Object.keys(daysToRender).map((dayKey) => {
                            let events = daysToRender[dayKey];
                            return (
                                <Cell
                                    style={{
                                        minWidth: this.state.shiftDayLength,
                                        width: this.state.shiftDayLength,
                                        padding: VIEW_COMPONENTS.colPadding,
                                        border: 0,
                                        height: 30,
                                        backgroundColor: this.state.printing ? 'white' : 'whitesmoke',
                                        position: 'relative'
                                    }}
                                    onClick={() => {
                                        this.drillDown(dayKey);
                                    }}
                                >
                                    { events.map(event => {

                                        if (event.type === ShiftStatus.Uncategorized) { // Statuses like offer confirmed, but worker is not the one awarded
                                            return null;
                                        }

                                        const correctedLeft = event.left && event.left > 0 ? event.left : 0;

                                        return (
                                            <div 
                                                className='bottom-event-title'
                                                style={{
                                                    left: correctedLeft,
                                                    background: event.color,
                                                    zIndex: event.type === ShiftStatus.HirerConfirmed ? 2 : 1
                                                }}
                                                data-hovered-offer={this.state.hoveredOfferId === event.offerId ? "true" : "false"}
                                                data-non-hovered-offer={this.state.hoveredOfferId !== null && this.state.hoveredOfferId !== event.offerId ? "true" : "false"}
                                                onMouseEnter={() => {
                                                    if (eventMouseLeaveTimeout) {
                                                        clearTimeout(eventMouseLeaveTimeout);
                                                    }
                                                    this.setState({ hoveredOfferId: event.offerId })
                                                }}
                                                onMouseLeave={() => {
                                                    eventMouseLeaveTimeout = setTimeout(() => {
                                                        this.setState({ hoveredOfferId: null })
                                                    }, 400)
                                                }}
                                            >
                                                <span className='handle'></span>
                                                {this.state.hoveredOfferId && ShiftStatusDetails[event.type] ? ShiftStatusDetails[event.type].title : event.name}
                                            </div>
                                        )
                                    }) }
                                </Cell>
                            )
                        }) }
                        </Row>
                    )
                }) }
            </React.Fragment>
        )
    }

    renderContact = (worker: Worker | null, hirer: RotaHirerDto | null, VIEW_COMPONENTS: ViewComponents, headline: string, breakdown: BreakdownType, poppedOut?: boolean) => {
        const isAgency = worker && worker.id.indexOf("-") === -1
        return (
            <React.Fragment>
            {/* <div className='contact-filler' style={{
                height: VIEW_COMPONENTS.profileImage ? "32px" : "unset",
                width: 0
            }}>

            </div> */}
            <div 
                className={"contact" + (poppedOut ? " popped-out" : "")}
                style={{
                    width: 130,
                    minWidth: 130,
                    height: VIEW_COMPONENTS.profileImage ? "32px" : "unset",
                    margin: VIEW_COMPONENTS.profileImage ? "0 0" : "-1px 0 0 0",
                    position: breakdown.indexOf("proportional") !== -1 ? "relative" : "absolute"
                }}
            >
                { (VIEW_COMPONENTS.profileImage) &&
                    <ProfileImage 
                        size={28}
                        url={isAgency ? (worker.organisationId ? CompanyApi.getOrganisationProfileImageUrl(worker.organisationId) : OrganisationApi.getExtOrgPicture(worker.organisationDomain || "")) : worker ? ProfileApi.getProfileImageUrl(worker.id) : hirer ? OrganisationApi.getExtOrgPicture(hirer.organisationDomain) : ""}
                        selectable={false}
                        style={{
                            padding: 0,
                            border: 0,
                            minWidth: 28,
                            filter: (worker && worker.reported) ? "blur(2.5px)" : "none"
                        }}
                    />
                }
                <div>
                    { ((VIEW_COMPONENTS.profileFirstName || VIEW_COMPONENTS.profileLastName) && worker) &&
                        <p
                            data-report-blurred={worker.reported}
                            data-user-id={worker.id}
                            style={{
                                padding: VIEW_COMPONENTS.profileImage ? "0 0 0 6px" : "0"
                            }}
                        >
                            {VIEW_COMPONENTS.profileFirstName && worker.firstName + " "}
                            {VIEW_COMPONENTS.profileLastName && worker.lastName}
                        </p>
                    }
                    { ((VIEW_COMPONENTS.profileFirstName || VIEW_COMPONENTS.profileLastName) && hirer) &&
                        <p
                            style={{
                                padding: VIEW_COMPONENTS.profileImage ? "0 0 0 6px" : "0"
                            }}
                        >
                            {hirer.organisationName}
                        </p>
                    }
                    { (VIEW_COMPONENTS.profileHours) &&
                        <p
                            style={{
                                fontWeight: 600
                            }}
                        >
                            {headline}
                        </p>   
                    }
                </div>
            </div>
            </React.Fragment>
        )
    }

    renderEvent = (event: any, VIEW_COMPONENTS: ViewComponents, breakdown: BreakdownType, worker: Worker | null) => {
        if (event.type === ShiftStatus.Uncategorized) { // Statuses like offer confirmed, but worker is not the one awarded
            console.log("Uncategorized event", event)
            // todo restore return null;
        }

        // console.log("REndering event", event);

        const correctedLeft = event.left && event.left > 0 ? event.left : 0;
        const totalParentWidth = (this.state.shiftDayLength + 10);
        const overflows = event.left + event.width > totalParentWidth && breakdown !== "day";
        const isAgencyBooking = worker && worker.id.indexOf("-") === -1
        const hirer: any = null;

        return (
            <div 
                style={{
                    background: event.color,
                    padding: VIEW_COMPONENTS.eventPadding,
                    flexWrap: VIEW_COMPONENTS.doubleRows ? 'wrap' : 'initial',
                    left: correctedLeft ? correctedLeft : 'initial',
                    width: event.width ? ( (correctedLeft + event.width > totalParentWidth && breakdown !== "day" ) ? totalParentWidth - correctedLeft : event.width) : 'initial',
                    position: event.width ? 'absolute' : 'initial',
                    marginBottom: VIEW_COMPONENTS.name === "COMPACT" ? 0 : 5,
                    marginTop: VIEW_COMPONENTS.name === "EMPTYDETAILSOUTSIDE" ? 7 : 0,
                    borderRadius: VIEW_COMPONENTS.name === "EMPTYDETAILSOUTSIDE" ? 4 : 0,
                    zIndex: event.type === ShiftStatus.HirerConfirmed ? 2 : 1
                }}
                data-overflows={overflows ? "true" : "false"}
                data-hovered-offer={this.state.hoveredOfferId === event.offerId ? "true" : "false"}
                data-non-hovered-offer={this.state.hoveredOfferId !== null && this.state.hoveredOfferId !== event.offerId ? "true" : "false"}
                onMouseEnter={() => {
                    if (eventMouseLeaveTimeout) {
                        clearTimeout(eventMouseLeaveTimeout);
                    }
                    this.setState({ hoveredOfferId: event.offerId })
                }}
                onMouseLeave={() => {
                    eventMouseLeaveTimeout = setTimeout(() => {
                        this.setState({ hoveredOfferId: null })
                    }, 400)
                }}
                onClick={() => {
                    if (breakdown === "day" && !window.location.pathname.startsWith("/external")) {
                        this.setState({ openedOfferId: event.offerId })
                        InternalTracker.trackEvent("Offer Opened", {
                            offerId: event.offerId
                        })
                    }
                }}
                className="event"
                data-truncated={(event.left + event.width > (this.state.shiftDayLength) && breakdown !== "day" ) ? "true" : "false"}
                data-left={event.left}
                data-left-rounded={Math.round(event.left)}
                data-width-rounded={Math.round(event.width)}
                data-width={event.width}
                data-name={event.name}
                data-period={event.period}
            >
                { (VIEW_COMPONENTS.eventTypeIcon) &&
                    <div className='icon' style={{
                        background: event.solidColor
                    }}>
                        {
                            (event.type === ShiftStatus.HirerConfirmed) ? <i className="fas fa-check-square"></i> :
                            (event.type === ShiftStatus.ContactApplied) ? <i className="fas fa-thumbs-up"></i> :
                            (event.type === ShiftStatus.ContactRejected) ? <i className="fas fa-thumbs-down"></i> :
                            (event.type === ShiftStatus.ContactNoResponse) ? <i className="fas fa-hourglass-half"></i> :
                            (event.type === ShiftStatus.HirerCancelled) ? <i className="fas fa-times-circle"></i> :
                            (event.type === ShiftStatus.ContactWithdrew) ? <i className="fas fa-calendar-times"></i> :
                            (event.type === ShiftStatus.AgencyAccepted) ? <i className="fas fa-user-cog"></i> :
                            (event.type === ShiftStatus.AgencyRejected) ? <i className="fas fa-user-times"></i> :
                            (event.type === ShiftStatus.PendingAgency) ? <i className="fas fa-user-clock"></i> :
                            (event.type === ShiftStatus.ConfirmedAgencyBooking) ? <i className="fas fa-user-check"></i> :
                            <i className="fas fa-history"></i>
                        }
                    </div>
                }
                { (VIEW_COMPONENTS.eventRange || VIEW_COMPONENTS.eventName || VIEW_COMPONENTS.name === "EMPTY" || VIEW_COMPONENTS.name === "EMPTYDETAILSOUTSIDE") &&
                    <div 
                        className='side'
                        style={ (!VIEW_COMPONENTS.eventName) ? {
                            flexBasis: "100%"
                        } : (VIEW_COMPONENTS.name === "BOOKING" || VIEW_COMPONENTS.name === "COMPACT") ? {
                            flexBasis: "100%",
                            padding: 0
                        } : { } }
                    >
                        { VIEW_COMPONENTS.name === "EMPTYDETAILSOUTSIDE" &&
                            <span 
                                className='start-time-outer'
                                style={ (correctedLeft === 0) ? { left: 0, top: 0, borderTopLeftRadius: 26 } : { } }
                            >
                                {event.startPeriod}
                            </span>
                        }
                        { VIEW_COMPONENTS.name === "EMPTYDETAILSOUTSIDE" &&
                            <span 
                                className='end-time-outer'
                                style={ overflows ? { right: 0, bottom: 0, borderBottomRightRadius: 26 } : { } }
                            >
                                {event.endPeriod}
                            </span>
                        }
                        <div 
                            className='bottom'
                            style={{
                                justifyContent: VIEW_COMPONENTS.doubleRows ? 'end' : 'space-between'
                            }}
                        >
                            { (VIEW_COMPONENTS.eventRange || VIEW_COMPONENTS.name === "EMPTY" || VIEW_COMPONENTS.name === "EMPTYDETAILSOUTSIDE") &&
                                <span 
                                    className='time'
                                    style={ (!VIEW_COMPONENTS.eventName || VIEW_COMPONENTS.name === "BOOKING") ? {
                                        background: "none",
                                        margin: 0,
                                        textAlign: (VIEW_COMPONENTS.name === "BOOKING") ? "left" : 'center',
                                        width: "100%",
                                        padding: (VIEW_COMPONENTS.name === "BOOKING") ? "0" : '3px 6px 4px 6px'
                                    } : VIEW_COMPONENTS.doubleRows ? {
                                        marginRight: 0
                                    } : { } }
                                >{(VIEW_COMPONENTS.name === "EMPTY" || VIEW_COMPONENTS.name === "EMPTYDETAILSOUTSIDE") ? ('\u00A0' || event.period) : event.period}</span>
                            }
                            { (!VIEW_COMPONENTS.doubleRows && VIEW_COMPONENTS.eventName) &&
                                <span className='name'>{event.name}</span>
                            }
                        </div>
                    </div>
                }
                { (VIEW_COMPONENTS.eventName && VIEW_COMPONENTS.doubleRows) &&
                    <span 
                        className='name name-full'
                        style={VIEW_COMPONENTS.name === "BOOKING" ? {
                            margin: 0
                        } : { }}
                    >{event.name}</span>
                }
                { (VIEW_COMPONENTS.name === "EMPTY") &&
                    <div
                        className="extension"
                        style={{
                            background: event.color
                        }}
                    >
                        <div className="type-str">
                            <span>{event.period}</span>
                        </div>
                        <span>{event.name}</span>
                    </div>
                }
                { (event.offer && event.offer.externalSubjectName && (event.offer.overrideOrganisationDomain ||  event.offer.organisationId)) &&
                    <div className='agency-logo'>
                        <SimpleTooltip text={event.offer.organisationName} id={event.offer.id + "-" + event.offer.organisationId}>
                            <img src={ event.offer.overrideOrganisationDomain ? OrganisationApi.getExtOrgPicture(event.offer.overrideOrganisationDomain) : CompanyApi.getOrganisationProfileImageUrl(event.offer.organisationId)} alt={event.offer.externalSubjectName} />
                        </SimpleTooltip>
                    </div>
                }
                { (worker) &&
                    <div className='worker-agency-logo'>
                        <SimpleTooltip text={isAgencyBooking ? worker.organisationName : (worker.firstName + " " + worker.lastName)} id={event.organisationName + "-" + worker.firstName + "-" + worker.lastName}>
                            <img src={isAgencyBooking ? (worker.organisationId ? CompanyApi.getOrganisationProfileImageUrl(worker.organisationId) : OrganisationApi.getExtOrgPicture(worker.organisationDomain || "")) : worker ? ProfileApi.getProfileImageUrl(worker.id) : hirer ? OrganisationApi.getExtOrgPicture(hirer.organisationDomain) : ""} />
                        </SimpleTooltip>
                    </div>
                }
            </div>
        );
    }

    renderCompactRow = (start: number, end: number, dayLength: number, worker: Worker | null, hirer: RotaHirerDto | null, dates: ScheduleEventItem[], i: number, terminology?: Terminology[], submissionCount?: number) => {
        
        let hours: number[] = [];

        let newStart = start;
        let totalHours = 
            worker !== null ? (this.state.workers?.find(w => w.id === worker.id)?.totalHours || 0) :
            hirer !== null ? ((this.state.hirers?.find(h => (h.organisationId || h.organisationExternalId) === (hirer.organisationId || hirer.organisationExternalId)))?.totalHours || 0) : 
            0;

        let totalGrossPay = hirer !== null ? ((this.state.hirers?.find(h => (h.organisationId || h.organisationExternalId) === (hirer.organisationId || hirer.organisationExternalId)))?.totalGrossPay || 0) : 0;
        let totalGrossCharge = worker !== null ? ((this.state.workers?.find(w => (w.id && w.id === worker.id) || (w.externalId && w.externalId === worker.externalId)))?.totalGrossCharge || 0) : 0;

        while (newStart < end+1) {
            hours.push(newStart);
            newStart++;
        }

        const fullWorkerObj = this.state.workers?.find(w => worker && (w.externalId === worker.externalId || (w.id && w.id === worker.id)));
        const fullHirerObj = this.state.hirers?.find(h => hirer && ((h.organisationId && h.organisationId === hirer.organisationId) || (h.organisationExternalId && h.organisationExternalId === hirer.organisationExternalId)));
        const hasRating = fullWorkerObj?.rating?.stars || fullWorkerObj?.providerOrgRating || fullHirerObj?.rating?.stars || fullHirerObj?.providerOrgRating;
        const skippedRatingTooManyTimes = (submissionCount && submissionCount > 2 && !hasRating);

        return (
            <Row 
                onClick={() => {
                    let ref = worker ? this.state.workers?.find(w => w.externalId === worker.externalId) : hirer ? this.state.hirers?.find(h => (h.organisationId && h.organisationId === hirer.organisationId) || (h.organisationExternalId && h.organisationExternalId === hirer.organisationExternalId)) : null;
                    if (ref) {
                        let now = new Date();
                        let restrictToMinHour = new Date(Utilities.formatDate(now, "YYYY-MM-DD") + "T" + (ref.firstHour ? (ref.firstHour < 10 ? "0" + ref.firstHour : ref.firstHour) : "00") + ':00:00+00:00');
                        let restrictToMaxHour = new Date(Utilities.formatDate(now, "YYYY-MM-DD") + "T" + (ref.lastHour ? (ref.lastHour < 10 ? "0" + ref.lastHour : ref.lastHour) : "00") + ':00:00+00:00');

                        InternalTracker.trackEvent('Schedule Drilled Down to Worker', worker ? {
                            externalUserId: worker.externalId || "",
                            internalUserId: worker.id || "",
                        } : {
                            externalOrgId: hirer?.organisationExternalId || "",
                            internalOrgId: hirer?.organisationId || "",
                        })

                        this.setState({
                            compactViewDrillDownUserId: worker ? (worker.externalId || "") : hirer ? (hirer.organisationExternalId || "") : "", // todo adjust for hirer at other parts
                            compactViewDrillDownUserIdInternal: worker ? worker.id : hirer? hirer.organisationId : "",
                            compactViewDrillDownUserReported: worker ? (worker.reported || false) : false,
                            compactViewDrillDownOrgDomain: hirer ? hirer.organisationDomain : "",
                            compactViewDrillDownOrgName: hirer ? hirer.organisationName : "",
                            compactViewDrillDownOrgTotalHours: fullHirerObj ? fullHirerObj.totalHours : null,
                            compactViewDrillDownOrgTotalGrossPay: fullHirerObj ? fullHirerObj.totalGrossPay : null,
                            compactViewDrillDownOrgTotalGrossCharge: fullWorkerObj ? fullWorkerObj.totalGrossCharge : null,
                            compactViewDrillDownOrgId: hirer ? hirer.organisationId : "",
                            compactViewDrillDownExternalOrgId: hirer ? hirer.organisationExternalId : "",
                            restrictToMinHourNumberGlobal: this.state.restrictToMinHourNumber,
                            restrictToMaxHourNumberGlobal: this.state.restrictToMaxHourNumber,
                            restrictToMinHourNumber: restrictToMinHour.getHours(),
                            restrictToMaxHourNumber: restrictToMaxHour.getHours(),
                        }, () => {
                            localStorage.setItem("timesheetWorkerSelected", worker ? (worker.id || worker.externalId || "") : hirer? (hirer.organisationExternalId || hirer.organisationId || "") : "")
                            this.handleResize();
                        })
                    }
                }}
            >
                <Cell 
                    style={{
                        width: 140
                    }}
                >
                    <SimpleTooltip
                        id="contact-drilldown"
                        text={`Tap for details`}
                    >
                        <div 
                            className="contact"
                            style={{
                                width: 140
                            }}
                        >
                            { (i === 0) &&
                                <span className="pulse-circle"></span>
                            }
                            { (worker && !worker.id) ?
                                <SimpleTooltip
                                    id="contact-not-ue"
                                    text={`Not an Updatedge User, Click to invite`}
                                >
                                    <div 
                                        className="non-ue-image-wrapper"
                                        onClick={() => {
                                            window.location.href = "/";
                                        }}
                                    >
                                        <div className="foreground"></div>
                                        <i className="fas fa-times"></i>
                                        <img
                                            src="/img/branding/updateedge_90.png"
                                            alt="Updatedge Logo"
                                        />
                                    </div>
                                </SimpleTooltip>
                                :
                                <ProfileImage 
                                    size={32}
                                    url={(hirer && hirer.organisationDomain) ? (OrganisationApi.getExtOrgPicture(hirer.organisationDomain)) : worker ? ProfileApi.getProfileImageUrl(worker.id) : ""}
                                    selectable={false}
                                    style={{
                                        padding: 0,
                                        border: 0,
                                        display: ((hirer && hirer.organisationDomain) ? (OrganisationApi.getExtOrgPicture(hirer.organisationDomain)) : worker ? ProfileApi.getProfileImageUrl(worker.id) : "") ? undefined : "none",
                                        filter: (worker && worker.reported) ? "blur(2.5px)" : "none"
                                    }}
                                />
                            }
                            <div style={{ maxWidth: 'calc(100% - 32px)' }}>
                                { (worker) &&
                                    <p 
                                        data-report-blurred={worker.reported}
                                        data-user-id={worker.id}
                                    >
                                        {worker.firstName}
                                        {' '}
                                        {worker.lastName.substr(0, 1)}
                                    </p>
                                }
                                { (hirer) &&
                                    <p>
                                        {hirer.organisationName}
                                    </p>
                                }
                                { skippedRatingTooManyTimes ?
                                    <SimpleTooltip
                                        id="skipped-rating-limit"
                                        text="You have skipped rating this contact 3 times already, please rate them now, before accepting/rejecting the timesheet"
                                    >
                                        <i onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation()
                                            const el = document.getElementById("rating-" + (worker ? worker.id : hirer ? (hirer.organisationId || hirer.organisationExternalId) : ""));
                                            if (el) {
                                                el.click();
                                            }
                                        }} className="fas fa-exclamation-circle"></i>
                                    </SimpleTooltip> : null
                                }
                                <p
                                    style={{
                                        fontWeight: 600
                                    }}
                                >
                                    { totalHours }
                                    { (worker && !worker.id) ? "h" : "h" }
                                    { totalGrossPay ? (' ' + (this.state.currency === "GBP" ? "£" : "£") + totalGrossPay) : null }
                                    { totalGrossCharge ? (' ' + (this.state.currency === "GBP" ? "£" : "£") + totalGrossCharge) : null }
                                    { (worker && !worker.id) && (
                                        <span
                                            style={{
                                                fontSize: '0.86em',
                                                paddingLeft: 4
                                            }}
                                        >
                                            Not UE User
                                        </span>
                                    ) }
                                </p>
                                <div 
                                    className='rating'
                                    onClick={(e) => { e.preventDefault(); e.stopPropagation() }}
                                >
                                    <WorkerRater
                                        showProfile={false}
                                        showSave={false}
                                        hideOnOneStar={true}
                                        mode={"modal"}
                                        id={"rating-" + (worker ? worker.id : hirer ? (hirer.organisationId || hirer.organisationExternalId) : "")}
                                        className={skippedRatingTooManyTimes ? "too-many-rating-skips" : ""}
                                        contacts={ worker ? (this.state.workers?.filter(w => ( (w.id && w.id === worker.id) || (w.externalId && w.externalId === worker.externalId))).map(item => {
                                            return {
                                                userId: item.id || item.externalId,
                                                contactId: item.id || item.externalId, // TODO contact ids needed
                                                stars: item.rating ? item.rating.stars : (item.providerOrgRating || 0),
                                                publicComment: item.rating ? item.rating.publicComment : "",
                                                privateComment: item.rating ? item.rating.privateComment : "",
                                                name: item.firstName + " " + item.lastName
                                            } as ContactWithRating
                                        }) || []) : hirer ? (this.state.hirers?.filter(w => ((w.organisationId && w.organisationId === hirer.organisationId) || (w.organisationExternalId && w.organisationExternalId === hirer.organisationExternalId))).map(item => {
                                            return {
                                                userId: item.organisationId || item.organisationExternalId, // todo org id
                                                contactId: item.organisationId || item.organisationExternalId, // todo org id
                                                stars: item.rating ? item.rating.stars : (item.providerOrgRating || 0),
                                                publicComment: item.rating ? item.rating.publicComment : "",
                                                privateComment: item.rating ? item.rating.privateComment : "",
                                                name: item.organisationName
                                            } as ContactWithRating
                                        }) || [])  : [] }
                                        onSave={(contact, contactI) => {
                                            console.log("onSave", contact);
                                            if (i !== undefined) {
                                                if (this.state.workers && this.state.workers[i]) {
                                                    let ratedWorker = this.state.workers[i]
                                                    RatingApi.postTimesheetRatings(
                                                        localStorage.getItem(EphemeralAccessTokenStorageKey) || "",
                                                        localStorage.getItem("timesheet-agency-id") || "",
                                                        localStorage.getItem("timesheet-school-id") || "",
                                                        localStorage.getItem("timesheet-school") || "",
                                                        [{
                                                            userId: ratedWorker.id || ratedWorker.externalId || "",
                                                            stars: ratedWorker?.rating?.stars || ratedWorker?.providerOrgRating || 0,
                                                            publicComment: ratedWorker.rating?.publicComment || "",
                                                            privateComment: ratedWorker.rating?.privateComment || "",
                                                        }]
                                                    ).then(data => {
                                                        Notifications.actionCreators.display(
                                                            ToastType.SUCCESS,
                                                            "Rating Saved"
                                                        );
                                                    }).catch(e => {
                                                        Notifications.actionCreators.display(
                                                            ToastType.ERROR,
                                                            "Failed to save Rating"
                                                        );
                                                    })
                                                } else if (this.state.hirers && this.state.hirers[i]) {
                                                    let ratedHirer = this.state.hirers[i]
                                                    RatingApi.postWorkerTimesheetRatings(
                                                        localStorage.getItem(EphemeralAccessTokenStorageKey) || "",
                                                        localStorage.getItem("timesheet-agency-id") || "",
                                                        localStorage.getItem("timesheet-external-worker-id") || "",
                                                        localStorage.getItem("timesheet-worker-id") || "",
                                                        [{
                                                            stars: contact.stars || ratedHirer?.rating?.stars || ratedHirer?.providerOrgRating || 0,
                                                            publicComment: contact.publicComment || ratedHirer.rating?.publicComment || "",
                                                            privateComment: contact.privateComment || ratedHirer.rating?.privateComment || "",
                                                            recipientOrgId: ratedHirer.organisationId,
                                                            recipientExternalOrgId: ratedHirer.organisationExternalId,
                                                            recipientExternalOrgName: ratedHirer.organisationName,
                                                            recipientExternalOrgDomain: ratedHirer.organisationDomain
                                                        }]
                                                    ).then(data => {
                                                        Notifications.actionCreators.display(
                                                            ToastType.SUCCESS,
                                                            "Rating Saved"
                                                        );
                                                    }).catch(e => {
                                                        Notifications.actionCreators.display(
                                                            ToastType.ERROR,
                                                            "Failed to save Rating"
                                                        );
                                                    })
                                                }
                                            }
                                            return contact;
                                        }}
                                        onEdit={(contact) => {
                                            let workers = this.state.workers;
                                            if (workers) {
                                                for (let i = 0; i < workers.length; i++) {
                                                    const worker = workers[i];
                                                    const workerId = worker.id || worker.externalId
                                                    if (workerId === contact.userId) {
                                                        if (!worker.rating) {
                                                            worker.rating = {
                                                                stars: 0,
                                                                publicComment: "",
                                                                privateComment: ""
                                                            };
                                                        }
                                                        worker.rating!.stars = contact.stars || 0;
                                                        worker.rating!.publicComment = contact.publicComment || "";
                                                        worker.rating!.privateComment = contact.privateComment || "";
                                                        worker.rating!.changed = true;
                                                        break;
                                                    }
                                                }
                                                this.setState({ workers: workers })
                                            }
                                            return contact;
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </SimpleTooltip>
                </Cell>
                { this.state.days.map(day => {

                    let events = JSON.parse(JSON.stringify(dates.filter(item => Utilities.formatDate(new Date(item.start || ""), "YYYY-MM-DD") === day)));
                    let totalSecondsThisDay = 0;
                    for (let i = 0; i < events.length; i++) {
                        const event = events[i];
                        totalSecondsThisDay += Utilities.differenceBetweenDatesSeconds(new Date(event.start || ""), new Date(event.end || ""));
                    }
                    let totalHoursThisDay = parseFloat((totalSecondsThisDay / 3600).toFixed(2));

                    let terminologyLabel = "";
                    let terminologyColor = "#333";
                    let terminologyMergeIntoOne = false;

                    if (terminology && terminology.length !== 0) {
                        for (let i = 0; i < terminology.length; i++) {
                            const terminologyI = terminology[i];
                            if (totalHoursThisDay <= terminologyI.maxHours && totalHoursThisDay >= terminologyI.minHours) {
                                terminologyLabel = terminologyI.name;
                                terminologyColor = terminologyI.color || "#333"
                                terminologyMergeIntoOne = terminologyI.merge || false;
                                break;
                            }
                        }
                    }

                    if (terminologyMergeIntoOne) {
                        let firstDate: Date | null = null;
                        let lastDate: Date | null = null;
                        for (let i = 0; i < events.length; i++) {
                            const start = new Date(events[i].start || "");
                            const end = new Date(events[i].end || "");
                            if (!firstDate || start < firstDate) {
                                firstDate = start;
                            }
                            if (!lastDate || end > lastDate) {
                                lastDate = end;
                            }
                        }
                        if (firstDate && lastDate) {
                            events = [events[0]];
                            events[0].start = firstDate;
                            events[0].end = lastDate;
                        }
                    }

                    return (
                        <Cell>
                            <SimpleTooltip
                                id="contact-drilldown-2"
                                text={`Tap for details`}
                            >
                                <div className="worker-day">
                                    { (terminologyLabel) &&
                                        <div 
                                            className="terminology-label"
                                            style={{
                                                backgroundColor: terminologyColor
                                            }}
                                        >
                                            {terminologyLabel}
                                        </div>
                                    }
                                    <div className="background">
                                        <div className="separator">

                                        </div>
                                    </div>
                                    <div className="events">
                                        { events.map(event => {
                                            let startTime: Date | Number = new Date(event.start || "");
                                            let leftStart = (dayLength / (end-start)) * ( (startTime.getHours() - start));

                                            let endTime: Date | Number = new Date(event.end || "");
                                            let rightWidth = (dayLength / (end-start)) * ( (endTime.getHours() - start )) - 2

                                            return (
                                                <div 
                                                    className="event"
                                                    style={{
                                                        left: leftStart,
                                                        width: rightWidth - leftStart,
                                                        bottom: terminologyLabel ? 4 : 14,
                                                        // background: terminologyMergeIntoOne ? 'red' : 'yellow'
                                                    }}
                                                >
                                                </div>
                                            )
                                        }) }
                                    </div>
                                </div>
                            </SimpleTooltip>
                        </Cell>
                    )
                }) }
            </Row>
        )
    }

    renderCompactRowDrillDown = (events: ScheduleEventItem[], start: number, end: number, hourLength: number, metadata: OfferMetadata) => {
        
        let hours: number[] = [];
        const noHours = end - start;

        let newStart = start;

        while (newStart < end+1) {
            hours.push(newStart);
            newStart++;
        }

        for (let i = 0; i < events.length; i++) {
            for (let j = i+1; j < events.length; j++) {
                const eventA = events[i];
                const eventB = events[j];
                if (
                    Utilities.isOverlapping(
                        new Date(eventA.start || ""), 
                        new Date(eventA.end || ""),
                        new Date(eventB.start || ""),
                        new Date(eventB.end || "")
                    ) 
                    && !events[i].overlapA 
                    && !events[i].overlapB 
                    && !events[j].overlapA 
                    && !events[j].overlapB
                ) {
                    events[i].overlapA = true;
                    events[j].overlapB = true;
                    break;
                }
            }
        }

        return (
            <div style={{ 
                width: (noHours + 1) * hourLength,
                height: 48
            }}>
                <div className="background">
                    { hours.map(hour => {
                        return (
                            <div 
                                className="hour"
                                style={{
                                    width: hourLength,
                                }}
                            >
                                <label>{hour}:00</label>
                                <div className="separator" style={{ left: hourLength }} />
                                <div className="separator-half" style={{ left: hourLength/2 }} />
                            </div>
                        )
                    }) }
                </div>
                <div className="events">
                    { events.map((event, i) => {

                        let startTime: Date | Number = new Date(event.start || "");
                        let leftStart = (startTime.getHours() * hourLength) + (startTime.getMinutes() / 60 * hourLength) - (start*hourLength)

                        let endTime: Date | Number = new Date(event.end || "");
                        let rightWidth = (endTime.getHours() * hourLength) + (endTime.getMinutes() / 60 * hourLength) - (start*hourLength);

                        return (
                            <div 
                                className="event"
                                style={{
                                    left: leftStart,
                                    width: rightWidth - leftStart,
                                    background: (metadata && metadata[event.offerId || ""]) ? metadata[event.offerId || ""]?.color : theme.colours.blue2,
                                    bottom: event.overlapA ? 2 : event.overlapB ? 23 : 10,
                                }}
                            >
                                <span>{Utilities.formatDate(startTime, "HH:MM")}</span>
                                <span>{Utilities.formatDate(endTime, "HH:MM")}</span>
                            </div>
                        )
                    }) }
                </div>
            </div>
        )
    }

    switchBreakdown(newBreakdown, toDay?: string) {
        if (!this.state.start) {
            return;
        }

        if (this.state.showingDemoRes && !this.state.firstLoad) {
            this.setState({
                firstLoad: true,
            })
        }

        InternalTracker.trackEvent("Switched Schedule Breakdown", {
            type: newBreakdown,
            date: toDay
        })

        let newDateCursor = new Date(this.state.start);

        let start = newBreakdown.indexOf("week") !== -1 ? Utilities.startAndEndOfWeek(newDateCursor)[0] :
            newBreakdown.indexOf("month") !== -1 ? new Date(newDateCursor.getFullYear(), newDateCursor.getMonth(), 1) :
            toDay ? new Date(toDay) :
            newDateCursor;
        let end = newBreakdown.indexOf("week") !== -1 ? Utilities.startAndEndOfWeek(newDateCursor)[1] :
            newBreakdown.indexOf("month") !== -1 ? new Date(newDateCursor.getFullYear(), newDateCursor.getMonth() + 1, 0) :
            toDay ? new Date(toDay) :
            newDateCursor;

        let now = new Date();
        const isCurrentMonth = Utilities.isSameMonth(this.state.start, now)

        if (!toDay && ( (newBreakdown === "day" && isCurrentMonth) || (newBreakdown.indexOf("week") !== -1 && isCurrentMonth) || (newBreakdown.indexOf("month") !== -1 && isCurrentMonth) ) ) {
            start = newBreakdown.indexOf("week") !== -1 ? Utilities.startAndEndOfWeek(now)[0] :
                newBreakdown.indexOf("month") !== -1 ? new Date(now.getFullYear(), now.getMonth(), 1) :
                toDay ? new Date(toDay) :
                now;
            end = newBreakdown.indexOf("week") !== -1 ? Utilities.startAndEndOfWeek(now)[1] :
                newBreakdown.indexOf("month") !== -1 ? new Date(now.getFullYear(), now.getMonth() + 1, 0) :
                toDay ? new Date(toDay) :
                now;
        }

        if (toDay) {
            this.setState({
                previousViewData: {
                    contacts: JSON.parse(JSON.stringify(this.state.contacts)),
                    flattenedEvents: JSON.parse(JSON.stringify(this.state.flattenedEvents)),
                    days: JSON.parse(JSON.stringify(this.state.days)),
                    workersGrouppedByDates: JSON.parse(JSON.stringify(this.state.workersGrouppedByDates)),
                    eventsGrouppedByWorkers: JSON.parse(JSON.stringify(this.state.eventsGrouppedByWorkers)),
                    hirersGrouppedByDates: JSON.parse(JSON.stringify(this.state.hirersGrouppedByDates)),
                    eventsGrouppedByHirers: JSON.parse(JSON.stringify(this.state.eventsGrouppedByHirers)),
                    workers: JSON.parse(JSON.stringify(this.state.workers)),
                    workerOfferMetadata: JSON.parse(JSON.stringify(this.state.workerOfferMetadata)),
                    hirerOfferMetadata: JSON.parse(JSON.stringify(this.state.hirerOfferMetadata)),
                    start: this.state.start || new Date(),
                    end: this.state.end || new Date(),
                    breakdown: this.state.breakdown,
                    viewPreset: this.state.viewPreset,
                    monthWeeks: this.state.monthWeeks
                },
                dailyBreakdownZoom: 1
            })
        } else {
            this.setState({
                previousViewData: undefined
            })
        }

        this.setState({
            initialWeeklyRollingViewStartingWithToday: false,
            breakdown: newBreakdown,
            start: start,
            end: end,
            viewPreset: newBreakdown.indexOf("proportional detailed") !== -1 ? ViewPresetType.EMPTYDETAILSOUTSIDE :
                newBreakdown.indexOf("proportional") !== -1 ? ViewPresetType.EMPTY :
                ViewPresetType.BOOKING,
            monthWeeks: newBreakdown.indexOf("month") !== -1 ? this.calculateMonthWeeks(start, end) : []
        }, () => {
            this.reloadCalendar();
            this.handleResize();
        })
    }

    paginate(toRight: boolean, toDate?: Date) {
        InternalTracker.trackEvent("Paginated Schedule", {
            date: toDate,
        })

        // if (!this.hasPremiumSubscription()) {
        //     const thisWeek = Utilities.startAndEndOfWeek(new Date());
        //     const nextDate = toDate ? toDate : ( toRight ? Utilities.dateAdd(new Date(this.state.start || ""), "day", 1) : Utilities.dateSub(new Date(this.state.start || ""), "day", 1) )
        //     this.setState({
        //         showProBanner: (this.state.breakdown !== "day" || (this.state.breakdown === "day" && (nextDate < thisWeek[0] || nextDate > thisWeek[1])))
        //     })
        // }

        let start: Date | null = null;
        let end: Date | null = null;

        // @ts-ignore
        if (toDate) {
            start = this.state.breakdown.indexOf("week") !== -1 ? Utilities.startAndEndOfWeek(toDate)[0]
                : this.state.breakdown.indexOf("month") !== -1 ? new Date(toDate.getFullYear(), toDate.getMonth(), 1)
                : this.state.breakdown === "day" ?  new Date(toDate)
                : new Date();
            end = this.state.breakdown.indexOf("week") !== -1 ? Utilities.startAndEndOfWeek(toDate)[1]
                : this.state.breakdown.indexOf("month") !== -1 ? new Date(toDate.getFullYear(), toDate.getMonth() + 1, 0)
                : this.state.breakdown === "day" ?  new Date(toDate)
                : new Date();
        } else if (!toRight) {
            start = this.state.initialWeeklyRollingViewStartingWithToday && this.state.breakdown.indexOf("week") !== -1 ? Utilities.startAndEndOfWeek(process.env.JEST_WORKER_ID === undefined ? new Date() : new Date(2021, 1, 13))[0]
                : this.state.breakdown.indexOf("week") !== -1 ? Utilities.dateSub(this.state.start || new Date(), "day", 7)
                : this.state.breakdown.indexOf("month") !== -1 ? Utilities.firstDayInPreviousMonth(this.state.start)
                : this.state.breakdown === "day" ?  Utilities.dateSub(this.state.start || new Date(), "day", 1)
                : new Date();
            end = this.state.initialWeeklyRollingViewStartingWithToday && this.state.breakdown.indexOf("week") !== -1 ? Utilities.startAndEndOfWeek(process.env.JEST_WORKER_ID === undefined ? new Date() : new Date(2021, 1, 13))[1]
                : this.state.breakdown.indexOf("week") !== -1 ? Utilities.dateSub(this.state.end || new Date(), "day", 7)
                : this.state.breakdown.indexOf("month") !== -1 ?  Utilities.lastDayInPreviousMonth(this.state.start)
                : this.state.breakdown === "day" ?  Utilities.dateSub(this.state.start || new Date(), "day", 1)
                : new Date();
        } else {
            start = this.state.initialWeeklyRollingViewStartingWithToday && this.state.breakdown.indexOf("week") !== -1 ? Utilities.startAndEndOfWeek(process.env.JEST_WORKER_ID === undefined ? new Date() : new Date(2021, 1, 13))[0] 
                : this.state.breakdown.indexOf("week") !== -1 ? Utilities.dateAdd(this.state.start || new Date(), "day", 7)
                : this.state.breakdown === "day" ?  Utilities.dateAdd(this.state.start || new Date(), "day", 1)
                : this.state.breakdown.indexOf("month") !== -1 ? Utilities.getFirstDayOfNextMonth(this.state.start)
                : new Date();
            end = this.state.initialWeeklyRollingViewStartingWithToday && this.state.breakdown.indexOf("week") !== -1 ? Utilities.startAndEndOfWeek(process.env.JEST_WORKER_ID === undefined ? new Date() : new Date(2021, 1, 13))[1]
                : this.state.breakdown.indexOf("week") !== -1 ? Utilities.dateAdd(this.state.end || new Date(), "day", 7)
                : this.state.breakdown === "day" ?  Utilities.dateAdd(this.state.start || new Date(), "day", 1)
                : this.state.breakdown.indexOf("month") !== -1 ?  Utilities.getLastDayOfNextMonth(this.state.start)
                : new Date();
        }

        InternalTracker.trackEvent("", {
            category: 'Schedule',
            action: 'Changed Dates',
            customDimensions: [{ id: "REPLACE", value: start + " - " + end }]
        });

        this.setState({
            start: start,
            end: end,
            monthWeeks: this.state.breakdown.indexOf("month") !== -1 ? this.calculateMonthWeeks(start, end) : [],
            initialWeeklyRollingViewStartingWithToday: false
        }, () => {
            this.reloadCalendar();
        })

    }

    submitTimesheetResponse(accuracyDecision) {

        if (this.state.workers?.find(w => {
            const hasRating = w?.rating?.stars || w?.providerOrgRating;
            // @ts-ignore
            return (w.submissionCount > 2 && !hasRating)
        })) {
            this.setState({
                joyride: "rating-mandatory"
            })
            return;
        }

        InternalTracker.trackEvent("", {
            category: 'Timesheet',
            action: accuracyDecision ? 'Timesheet Accepted' : 'Timesheet Amended / Rejected'
        });

        TimesheetsAPI
            .sendFeedback(
                localStorage.getItem(EphemeralAccessTokenStorageKey) || "", 
                accuracyDecision ? TimesheetResponseType.Accept : TimesheetResponseType.Reject, 
                this.state.feedback,
                localStorage.getItem("timesheet-agency-id") + "_" + localStorage.getItem("timesheet-school-id"),
                this.state.workers ? this.state.workers.map(item => (item.externalId || item.id) + "") : []
            )
            .then(data => {
                this.setState({
                    feedback: "",
                    alreadyRespondedWith: accuracyDecision ? TimesheetResponseType.Accept : TimesheetResponseType.Reject,
                    // timesheetResponseResult: accuracyDecision ? "Timesheet Accepted" : "Timesheet Amended / Rejected",
                })
                Notifications.actionCreators.display(
                    ToastType.SUCCESS,
                    accuracyDecision ? "Timesheet Accepted" : "Timesheet Amended / Rejected"
                );
            }).catch(e => {
                this.setState({
                    timesheetResponseResult: accuracyDecision ? "Failed to accept timesheet, please try later" : "Failed to amend / reject timesheet, please try later"
                })
                Notifications.actionCreators.display(
                    ToastType.ERROR,
                    accuracyDecision ? "Failed to accept timesheet, please try later" : "Failed to amend / reject timesheet, please try later"
                );
            })
    }

    render() {

        let fromHour = 7;
        let toHour = 19;
        let HOURS: number[] = [];

        let TIMESHEET_RESPONSE_OPTIONS_DOM = (<div></div>);

        if (window.location.pathname.startsWith("/external/timesheet")) {
            TIMESHEET_RESPONSE_OPTIONS_DOM = (
                <React.Fragment>
                    {
                        (this.state.timesheetResponseResult) ?
                    <div 
                        className="options"
                        style={{
                            backgroundColor: this.state.timesheetResponseResult.indexOf("Failed") !== -1 ? theme.colours.red2 : theme.colours.green2,
                            color: "white",
                            padding: 10,
                            borderRadius: 20,
                            fontSize: "1.1em",
                            fontWeight: 700
                        }}
                    >
                        {this.state.timesheetResponseResult}
                    </div>
                    :
                    <div className="options">
                        <textarea
                            id="timesheet-feedback"
                            name="timesheet-feedback"
                            placeholder={this.state.type === "external-timesheet-worker" ? "We're assuming this is correct unless you tell us otherwise by Amending / Rejecting" : "Enter comments if required or click accept / amend / reject"}
                            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                                this.setState({ feedback: e.target.value });
                            }}
                            value={this.state.feedback}
                            rows={this.state.type === "external-timesheet-worker" ? 5 : 4}
                        />
                        <div className="buttons">
                            { ((!this.state.alreadyRespondedWith || this.state.alreadyRespondedWith === TimesheetResponseType.Reject) && this.state.type !== "external-timesheet-worker") &&
                                <button 
                                    onClick={() => {
                                        this.submitTimesheetResponse(true);

                                        let el = document.querySelector(".expand-btn") as HTMLElement;
                                        if (el) el.click();
                                    }}
                                >
                                    Accept
                                </button>
                            }
                            { (!this.state.alreadyRespondedWith || this.state.alreadyRespondedWith === TimesheetResponseType.Accept) &&
                                <button
                                    className="reject"
                                    onClick={() => {
                                        if (this.state.type === "external-timesheet-worker") {
                                            const feedbackDom = document.querySelector("#timesheet-feedback") as HTMLTextAreaElement;
                                            if (!feedbackDom || !feedbackDom.value) {
                                                toast.error("Please enter a reason for rejecting the timesheet")
                                                return;
                                            }
                                        }
                                        this.submitTimesheetResponse(false)
                                        let el = document.querySelector(".expand-btn") as HTMLElement;
                                        if (el) el.click();
                                    }}
                                >
                                    Amend / Reject
                                </button>
                            }
                        </div>
                    </div>
                    }         
                </React.Fragment>
            )
        }

        if (this.state.selectedPresetId !== "none") {
            let preset = this.props.timePresets.presets.find(preset => preset.id === this.state.selectedPresetId);

            if (preset) {
                fromHour = preset.startHour
                toHour = preset.endHour
            }

            if (fromHour > toHour) {
                fromHour = 0;
                toHour = 23
            }
        }
    
        for (let i = fromHour; i <= toHour; i++) {
            HOURS.push(i);     
        }

        const VIEW_COMPONENTS: ViewComponents | null = typeof this.state.viewPreset == "number" ? ViewPresetConfig[this.state.viewPreset] : null;

        const POWEREDBY_RIBBON = <div 
            className="ribbon ribbon-top-right"
            onClick={() => {
                window.open("/", "_blank");
            }}
        >
            <span>Powered By <br /><strong>Updatedge</strong></span>
        </div>

        return (
            <div 
                className="page" 
                data-page="schedule" 
                data-main-view={this.state.mainView}
            >
                { (window.location.pathname !== "/schedule") &&
                    <TimeSheetHeader worker={this.state.currentWorker} />
                }
                { (!window.location.pathname.startsWith("/external")) &&
                    <header>
                        { (!this.state.hasPremiumSubscription && process.env.JEST_WORKER_ID === undefined) &&
                            <PremiumBanner
                                type={Feature.Rota}
                                prominent={false}
                                hideButton={this.state.hasTriedPremium}
                                bodyTextOverride={
                                    this.state.hasTriedPremium ?
                                    "You can only see a week in the future without a premium subscription (" + Utilities.formatDate(this.state.hideResultsBefore || new Date(), "d mm") + " - " + Utilities.formatDate(this.state.hideResultsAfter || new Date(), "d mm") + ")." :
                                    "You can only see a week in the future without a premium subscription (" + Utilities.formatDate(this.state.hideResultsBefore || new Date(), "d mm") + " - " + Utilities.formatDate(this.state.hideResultsAfter || new Date(), "d mm") + "). You can enable the trial for 14 days to try out the full functionality for free."
                                }
                            />
                        }
                    </header>
                }
                <main>
                    { (this.state.stale) &&
                        <div className="nonblocking-loading-wrapper" style={{ zIndex: 10 }}>
                            <CircularProgress style={{ width: 24, height: 24 }} />
                            Fetching Latest
                        </div>
                    }
                    <div 
                        className="calendar" 
                        data-view="week"
                        data-external-page={window.location.pathname.split("/external/")[1]}
                        style={{
                            flexBasis: (this.state.mainView === "calendar") ? 'calc(100% - 200px)' : '100%',
                            width: this.state.printing ? undefined : (this.state.mainView === "calendar") ? 'calc(100% - 200px)' : '100%'
                        }}
                    >
                        <AsyncOverlay show={this.state.loading} />
                        { this.state.start && !window.location.pathname.startsWith("/external/timesheet") &&
                            <div className='rota-header'>
                            <div 
                                className='row-alt'
                                data-printing={this.state.printing ? "true" : "false"}
                            >
                                <div 
                                    className="date-pagination" 
                                    data-range={
                                        (this.state.breakdown === "day") ? Utilities.formatDate(this.state.start || new Date(), "ds d mms (YYYY)") :
                                        this.state.breakdown.indexOf("week") !== -1 ? (Utilities.formatDate(this.state.start || new Date(), "ds d mms (YYYY)") + " - " + Utilities.formatDate(this.state.skipWeekends ? Utilities.dateSub(this.state.end || new Date(), "day", 2) : (this.state.end || new Date()), "ds d mms (YYYY)")) :
                                        this.state.breakdown.indexOf("month") !== -1 ? Utilities.formatDate(this.state.start || new Date(), "MMM YYYY") :
                                        ""
                                    }
                                >
                                    <i 
                                        className="fas fa-chevron-circle-left"
                                        onClick={() => {
                                            this.paginate(false);
                                        }}
                                    ></i>
                                    <DayPickerInput
                                        value={this.state.start}
                                        format={DateFormats.ShortDate}
                                        formatDate={() => {
                                            return (this.state.breakdown === "day") ? Utilities.formatDate(this.state.start || new Date(), "ds d mms (YYYY)") :
                                            this.state.breakdown.indexOf("week") !== -1 ? (Utilities.formatDate(this.state.start || new Date(), "ds d mms (YYYY)") + " - " + Utilities.formatDate(this.state.skipWeekends ? Utilities.dateSub(this.state.end || new Date(), "day", 2) : (this.state.end || new Date()), "ds d mms (YYYY)")) :
                                            this.state.breakdown.indexOf("month") !== -1 ? Utilities.formatDate(this.state.start || new Date(), "MMM YYYY") :
                                            ""
                                        }}
                                        parseDate={parseDate}
                                        onDayChange={(date) => {
                                            this.paginate(false, date);
                                        }}
                                        inputProps={{
                                            readOnly: 'readOnly'
                                        }}
                                        dayPickerProps={{
                                            firstDayOfWeek: 1
                                        }}
                                    />
                                    <i 
                                        className="fas fa-chevron-circle-right"
                                        onClick={() => {
                                            this.paginate(true);
                                        }}
                                    ></i>
                                </div>
                                <div 
                                    className='select-date'
                                    onClick={() => {
                                        const el = document.querySelector(".date-pagination .DayPickerInput input") as HTMLElement
                                        if (el) {
                                            el.click();
                                        }
                                    }}
                                >
                                    <i className="fas fa-calendar-alt"></i>
                                    <p>Select Date</p>
                                </div>
                                <div 
                                    className='jump-to-today'
                                    onClick={() => {
                                        InternalTracker.trackEvent("Jumped to Today on Schedule");
                                        this.paginate(false, new Date());
                                    }}
                                >
                                   <i className="fas fa-calendar"></i>
                                    <p>Jump to Today</p>
                                </div>
                                <div className="time-presets">
                                    { (this.state.breakdown === "day" || this.state.breakdown.indexOf("proportional") !== -1) &&
                                        <div 
                                            className='input-wrapper'
                                            style={{ marginLeft: 15 }}
                                        >
                                            <select
                                                className="form-control"
                                                value={this.state.selectedPresetId || "none"}
                                                onChange={(ev) => {
                                                    InternalTracker.trackEvent("Changed Schedule Timepreset", {
                                                        value: ev.target.value
                                                    });
                                                    this.setState({
                                                        selectedPresetId: ev.target.value
                                                    })
                                                }}
                                            >
                                                <option key={"none"} value={"none"}>
                                                    Time Range including all Events ({fromHour}:00 - {toHour}:00)
                                                </option>
                                                { this.props.timePresets.presets.map(preset => {
                                                    return (
                                                        <option key={preset.id} value={preset.id}>
                                                            {preset.name} ({preset.startTime} - {preset.endTime})
                                                        </option>
                                                    )
                                                }) }
                                            </select>
                                        </div>
                                    }
                                </div>
                                <div className='weekend-skipping'>
                                    <Toggle
                                        id="Skip-Weekends"
                                        defaultChecked={this.state.skipWeekends || false}
                                        onChange={() => {
                                            let skipWeekends = !this.state.skipWeekends;

                                            SettingsApi.update(
                                                Setting.Offers_ContinueOnWeekdays,
                                                skipWeekends ? 'true' : 'false'
                                            );

                                            InternalTracker.trackEvent("Toggled Skip Weekends on Schedule", {
                                                value: skipWeekends
                                            })
                                         
                                            if (skipWeekends) {
                                                localStorage.setItem("offersContinueOnWeekdays", "true")
                                            } else {
                                                localStorage.removeItem("offersContinueOnWeekdays");
                                            }

                                            this.setState({
                                                skipWeekends: skipWeekends
                                            }, () => {
                                                this.reloadCalendar();
                                            })
                                        }}
                                    />
                                    <span
                                        onClick={() => {
                                            let el = document.querySelector(".react-toggle") as HTMLElement;
                                            if (el) {
                                                el.click();
                                            }
                                        }}
                                    >
                                        Skip Weekends
                                    </span>
                                </div>
                                <div 
                                    className='search-dropdown-toggle'
                                    onClick={() => {
                                        this.setState({
                                            showFilters: true
                                        })
                                        InternalTracker.trackEvent('Clicked Schedule Filters');
                                    }}
                                >
                                    <i className='fas fa-search'></i>
                                    <p>Filters</p>
                                </div>
                                <div 
                                    className='search-dropdown-toggle'
                                    onClick={() => {
                                        this.print();
                                    }}
                                >
                                    <i className='fas fa-print'></i>
                                    <p>Print</p>
                                </div>
                                { (this.state.showFilters) &&
                                    <div className='search-dropdown'>
                                        <h2>
                                            Filter
                                            <i
                                                className="fas fa-times-circle"
                                                onClick={() => {
                                                    this.setState({
                                                        showFilters: false
                                                    })
                                                }}
                                            ></i>
                                        </h2>
                                        <p>Tap on any offers, or contacts to filter</p>
                                        <div className='row-alt' style={{ display: 'flex', whiteSpace: 'nowrap' }} >
                                            <div className='input-wrapper'>
                                                <i className="fas fa-calendar-check"></i>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={this.state.filters.offerKeywords}
                                                    name="keywords"
                                                    onChange={({ target: { name, value } }) => { this.setState({ filters: { ...this.state.filters, offerKeywords: value } }, () => { }) }}
                                                    onBlur={() => {
                                                        InternalTracker.trackEvent("Searched Schedule Offers", {
                                                            value: this.state.filters.offerKeywords
                                                        });
                                                    }}
                                                    placeholder="Search by Offers"
                                                />
                                            </div>
                                            <div className='offers'>
                                                { Object.keys(this.state.offerNameCounts).map(offerName => {
                                                    if (this.state.filters.offerKeywords.trim() && offerName.toLocaleLowerCase().indexOf(this.state.filters.offerKeywords.trim().toLocaleLowerCase()) === -1) {
                                                        return null;
                                                    }
                                                    return (
                                                        <div
                                                            className='offer'
                                                            data-selected={this.state.filters.offerNames.indexOf(offerName) !== -1}
                                                            onClick={() => {
                                                                let newOfferNames = this.state.filters.offerNames;
                                                                if (newOfferNames.indexOf(offerName) !== -1) {
                                                                    newOfferNames.splice(newOfferNames.indexOf(offerName), 1);
                                                                } else {
                                                                    newOfferNames.push(offerName)
                                                                }
                                                                InternalTracker.trackEvent('Updated Schedule Offer Filter', {
                                                                    value: newOfferNames
                                                                })
                                                                this.setState({
                                                                    filters: {
                                                                        ...this.state.filters,
                                                                        offerNames: newOfferNames
                                                                    }
                                                                })
                                                            }}
                                                        >
                                                            {offerName}
                                                        </div>
                                                    )
                                                }) }
                                            </div>
                                        </div>
                                        <div className='row-alt' style={{ display: 'flex', whiteSpace: 'nowrap' }} >
                                            <div className='input-wrapper'>
                                                <i className="fas fa-user"></i>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={this.state.filters.contactKeywords}
                                                    name="keywords"
                                                    onChange={({ target: { name, value } }) => { this.setState({ filters: { ...this.state.filters, contactKeywords: value } }, () => {  }) }}
                                                    placeholder="Search by Contacts"
                                                    onBlur={() => {
                                                        InternalTracker.trackEvent("Searched Schedule Contacts", {
                                                            value: this.state.filters.contactKeywords
                                                        });
                                                    }}
                                                />
                                            </div>
                                            <div className='people'>
                                                { this.state.workers?.map(worker => {
                                                    const fullName = worker.firstName + " " + worker.lastName;
                                                    if (this.state.filters.contactKeywords.trim() && fullName.toLocaleLowerCase().indexOf(this.state.filters.offerKeywords.trim().toLocaleLowerCase()) === -1) {
                                                        return null;
                                                    }
                                                    return (
                                                        <div 
                                                            className='worker'
                                                            data-selected={this.state.filters.contactNames.indexOf(fullName) !== -1}
                                                            onClick={() => {
                                                                let newContactNames = this.state.filters.contactNames;
                                                                if (newContactNames.indexOf(fullName) !== -1) {
                                                                    newContactNames.splice(newContactNames.indexOf(fullName), 1);
                                                                } else {
                                                                    newContactNames.push(fullName)
                                                                }
                                                                InternalTracker.trackEvent('Updated Schedule Contact Filter', {
                                                                    value: newContactNames
                                                                })
                                                                this.setState({
                                                                    filters: {
                                                                        ...this.state.filters,
                                                                        contactNames: newContactNames
                                                                    }
                                                                })
                                                            }}
                                                        >
                                                            <ProfileImage 
                                                                size={32}
                                                                url={ProfileApi.getProfileImageUrl(worker.id)}
                                                                selectable={false}
                                                                style={{
                                                                    padding: 0,
                                                                    border: 0,
                                                                    minWidth: 32
                                                                }}
                                                            />
                                                            <span>{worker.firstName + " " + worker.lastName[0]}</span>
                                                        </div>
                                                    )
                                                }) }
                                            </div>
                                            <button onClick={() => {
                                                InternalTracker.trackEvent('Applied Schedule Filters');
                                                this.setState({
                                                    showFilters: false
                                                })
                                            }}>Apply Filters</button>
                                            <button 
                                                onClick={() => {
                                                    InternalTracker.trackEvent('Cleared Schedule Filters');
                                                    this.setState({
                                                        showFilters: false,
                                                        filters: {
                                                            contactKeywords: "",
                                                            offerKeywords: "",
                                                            offerNames: [],
                                                            contactNames: [],
                                                            statuses: []
                                                        }
                                                    })
                                                }}
                                                className="clear-filters"
                                            >Clear Filters</button>
                                        </div>
                                    </div>
                                }
                            </div>
                            { (!this.state.printing) &&
                                <div className='row-alt'>
                                    <div className="layout-option">
                                        <div className='input-wrapper period'>
                                            <div className='image-select'>
                                                {["day", "week", "month"].map(period => {
                                                    const selected = this.state.breakdown.indexOf(period) !== -1;
                                                    return (
                                                        <div 
                                                            onClick={() => {
                                                                if (!selected) {
                                                                    let previousView = this.state.breakdown.indexOf("proportional detailed") !== -1 ? " proportional detailed" : 
                                                                        this.state.breakdown.indexOf("proportional") !== -1 ? " proportional" : 
                                                                        ""
                                                                    const newView = period === "day" ? period : (this.state.breakdown === "day") ? period + " proportional detailed" : period + previousView;
                                                                    InternalTracker.trackEvent('Switched Breakdown Type', {
                                                                        value: newView
                                                                    })
                                                                    this.switchBreakdown(newView)
                                                                }
                                                            }}
                                                            data-selected={this.state.breakdown.indexOf(period) !== -1}
                                                            data-period={period}
                                                            className="period-selector"
                                                        >
                                                            <label>{Utilities.capitalize(period)}</label>
                                                            <img src={'/img/' + period + '.png'} />
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>

                                    { (this.state.breakdown === "day") &&
                                        <div className="layout-option">
                                            <div className='input-wrapper period' style={{ marginRight: 0 }}>
                                                <div className='image-select'>

                                                    {[1, 2, 3].map(hourlyBreakdown => {
                                                        const selected = hourlyBreakdown === this.state.dailyBreakdownZoom

                                                        return (
                                                            <div 
                                                                data-selected={selected}
                                                                onClick={() => {
                                                                    if (!selected) {
                                                                        InternalTracker.trackEvent('Switched Breakdown Zoom', {
                                                                            value: hourlyBreakdown
                                                                        })
                                                                        this.setState({
                                                                            dailyBreakdownZoom: hourlyBreakdown as DailyBreakdownZoom
                                                                        })
                                                                    }
                                                                }}
                                                            >
                                                                <label>{hourlyBreakdown === 1 ? "Hourly" : hourlyBreakdown === 2 ? "30 Minutes" : "15 Minutes"}</label>
                                                                <img src={'/img/' + (hourlyBreakdown === 1 ? "hour" : hourlyBreakdown === 2 ? "half-hour" : "quarter-hour") + '.png'} />
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    { (this.state.breakdown !== "day") &&
                                        <div className="layout-option">
                                            <div className='input-wrapper period' style={{ marginRight: 0 }}>
                                                <div className='image-select'>

                                                    {["compact-proportional", "cozy-agenda", "detailed-proportional"].map(view => {
                                                        const selected = this.state.breakdown.indexOf('proportional detailed') !== -1 ? "detailed-proportional" :
                                                            this.state.breakdown.indexOf('proportional') !== -1 ? "compact-proportional" :
                                                            "cozy-agenda";

                                                        return (
                                                            <div 
                                                                data-selected={selected === view}
                                                                onClick={() => {
                                                                    if (selected !== view) {
                                                                        let newView = this.state.breakdown.replace(" proportional", "").replace(" detailed", "") +
                                                                            (view === "detailed-proportional" ? " proportional detailed" :
                                                                            view === "compact-proportional" ? " proportional" : 
                                                                            "")
                                                                        this.switchBreakdown(newView)
                                                                    }
                                                                }}
                                                            >
                                                                <label>{view === "compact-proportional" ? "Compact Proportional" : view === "cozy-agenda" ? "Cozy Agenda" : "Detailed Proportional" }</label>
                                                                <img src={'/img/' + view + '.png'} />
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            }
                            </div>
                        }
                        { (this.state.mainView === "shift") &&
                            <div className='filters'>
                                <div className='filter'>
                                    <div 
                                        className='shift-filter'
                                        style={{
                                            width: this.state.printing ? 1200 : undefined
                                        }}
                                    >
                                        { [
                                            "0",
                                            "1",
                                            "2",
                                            "3",
                                            "4",
                                            "5",
                                            "6",
                                            "20",
                                            "10",
                                            "11",
                                            "12",
                                        ].map(statusId => {
                                            let details = ShiftStatusDetails[statusId];
                                            if (statusId === "6")
                                                return null;
                                            return (
                                                <div data-id={statusId} className="offer-item" key={statusId} style={{ background: details.color}} onClick={() => { this.toggleStatusFilter(statusId) }}>
                                                    <div className='icon' style={{
                                                        background: details.solidColor
                                                    }}>
                                                        {
                                                            (parseInt(statusId) === ShiftStatus.HirerConfirmed) ? <i className="fas fa-check-square"></i> :
                                                            (parseInt(statusId) === ShiftStatus.ContactApplied) ? <i className="fas fa-thumbs-up"></i> :
                                                            (parseInt(statusId) === ShiftStatus.ContactRejected) ? <i className="fas fa-thumbs-down"></i> :
                                                            (parseInt(statusId) === ShiftStatus.ContactNoResponse) ? <i className="fas fa-hourglass-half"></i> :
                                                            (parseInt(statusId) === ShiftStatus.HirerCancelled) ? <i className="fas fa-times-circle"></i> :
                                                            (parseInt(statusId) === ShiftStatus.ContactWithdrew) ? <i className="fas fa-calendar-times"></i> :
                                                            (parseInt(statusId) === ShiftStatus.AgencyAccepted) ? <i className="fas fa-user-cog"></i> :
                                                            (parseInt(statusId) === ShiftStatus.AgencyRejected) ? <i className="fas fa-user-times"></i> :
                                                            (parseInt(statusId) === ShiftStatus.PendingAgency) ? <i className="fas fa-user-clock"></i> :
                                                            (parseInt(statusId) === ShiftStatus.ConfirmedAgencyBooking) ? <i className="fas fa-user-check"></i> :
                                                            <i className="fas fa-history"></i>
                                                        }
                                                    </div>
                                                    <div>
                                                        <p>{details.title}</p>
                                                        { (!this.state.printing) &&
                                                            <Checkbox 
                                                                id={"status-" + statusId}
                                                                name={"status-" + statusId}
                                                                color="#fff"
                                                                size={2}
                                                                tickSize={1}
                                                                borderThickness={3}
                                                                checked={this.state.filters.statuses.indexOf(parseInt(statusId)) !== -1}
                                                            />
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        }) }
                                    </div>

                                    { (this.state.filters.contactNames.length !== 0) &&
                                        <div className='people'>
                                            { this.state.workers?.map(worker => {
                                                const fullName = worker.firstName + " " + worker.lastName;
                                                if (this.state.filters.contactNames.indexOf(fullName) === -1)
                                                    return null

                                                return (
                                                    <div 
                                                        className='worker'
                                                        data-selected={this.state.filters.contactNames.indexOf(fullName) !== -1}
                                                        onClick={() => {
                                                            let newContactNames = this.state.filters.contactNames;
                                                            if (newContactNames.indexOf(fullName) !== -1) {
                                                                newContactNames.splice(newContactNames.indexOf(fullName), 1);
                                                            } else {
                                                                newContactNames.push(fullName)
                                                            }
                                                            InternalTracker.trackEvent('Toggled Schedule Contact Filter', {
                                                                values: newContactNames
                                                            })
                                                            this.setState({
                                                                filters: {
                                                                    ...this.state.filters,
                                                                    contactNames: newContactNames
                                                                }
                                                            })
                                                        }}
                                                    >
                                                        <ProfileImage 
                                                            size={32}
                                                            url={ProfileApi.getProfileImageUrl(worker.id)}
                                                            selectable={false}
                                                            style={{
                                                                padding: 0,
                                                                border: 0,
                                                                minWidth: 32
                                                            }}
                                                        />
                                                        <span>{worker.firstName + " " + worker.lastName[0]}</span>
                                                    </div>
                                                )
                                            }) }
                                        </div>   
                                    }

                                    { (this.state.filters.offerNames.length !== 0) &&
                                        <div className='offers'>
                                            { this.state.filters.offerNames.map(offerName => {
                                                // if (this.state.filters.offerNames.indexOf(offerName) === -1)
                                                //     return null

                                                return (
                                                    <div
                                                        className='offer'
                                                        data-selected={this.state.filters.offerNames.indexOf(offerName) !== -1}
                                                        onClick={() => {
                                                            let newOfferNames = this.state.filters.offerNames;
                                                            if (newOfferNames.indexOf(offerName) !== -1) {
                                                                newOfferNames.splice(newOfferNames.indexOf(offerName), 1);
                                                            } else {
                                                                newOfferNames.push(offerName)
                                                            }
                                                            InternalTracker.trackEvent('Toggled Schedule Offer Filter', {
                                                                values: newOfferNames
                                                            })
                                                            this.setState({
                                                                filters: {
                                                                    ...this.state.filters,
                                                                    offerNames: newOfferNames
                                                                }
                                                            })
                                                        }}
                                                    >
                                                        {offerName}
                                                    </div>
                                                )
                                            }) }
                                        </div>
                                    }

                                    { (
                                        this.state.filters.contactNames.length !== 0 ||
                                        this.state.filters.offerNames.length !== 0 ||
                                        this.state.filters.statuses.length !== 0
                                    ) &&
                                        <div className="filter-status">
                                            <p>{this.state.filters.contactNames.length + this.state.filters.offerNames.length + this.state.filters.statuses.length} Filters are applied</p>
                                            <button 
                                                onClick={() => {
                                                    InternalTracker.trackEvent('Cleared Schedule Filters');
                                                    this.setState({
                                                        showFilters: false,
                                                        filters: {
                                                            contactKeywords: "",
                                                            offerKeywords: "",
                                                            offerNames: [],
                                                            contactNames: [],
                                                            statuses: []
                                                        }
                                                    })
                                                }}
                                                className="clear-filters"
                                            >Clear All Filters</button>
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                        { (this.state.showingDemoRes) &&
                            <Dialog
                                body="There are no results to show in this view, so we populated it with some sample data, click here to dismiss"
                                type="info"
                                style={{
                                    marginBottom: 0,
                                    borderRadius: "12px 12px 0 0",
                                    paddingBottom: 5
                                }}
                                onClick={() => {
                                    localStorage.setItem("rota-demo-dismiss", "true");
                                    this.reloadCalendar(true);
                                }}
                                buttonText="Dismiss Example Data"
                            />
                        }
                        <div className='print-area' data-printing={this.state.printing ? "true" : "false"}>
                            { (
                                this.state.showDesktopScroller && 
                                (this.state.breakdown.indexOf('month') !== -1 || this.state.breakdown.indexOf('week') !== -1) &&
                                this.state.shiftRowWrapperWidth && 
                                this.state.shiftRowWidth && 
                                this.state.shiftRowWidth > this.state.shiftRowWrapperWidth
                            ) &&
                                <React.Fragment>
                                    { (this.state.canScrollLeft && !this.state.printing) &&
                                        <div 
                                            className='jump-to' 
                                            data-direction="left"
                                            onClick={() => {
                                                const scrolledElement = document.querySelector(".shift-view") as HTMLElement;
                                                if (scrolledElement) {
                                                    scrolledElement.scrollLeft = 0;
                                                }
                                                this.handleScroll();
                                            }}
                                        >
                                            <i className="fas fa-chevron-left"></i>
                                            <span>Jump to beginning of week</span>
                                        </div>
                                    }
                                    { (this.state.canScrollRight && !this.state.printing) &&
                                        <div 
                                            className='jump-to' 
                                            data-direction="right"
                                            onClick={() => {
                                                const scrolledElement = document.querySelector(".shift-view") as HTMLElement;
                                                if (scrolledElement) {
                                                    scrolledElement.scrollLeft = scrolledElement.scrollLeft + (this.state.shiftRowWrapperWidth || 0);
                                                }
                                                this.handleScroll();
                                            }}
                                        >
                                            <span>Jump to end of week</span>
                                            <i className="fas fa-chevron-right"></i>
                                        </div>
                                    }
                                </React.Fragment>
                            }
                            { (this.state.mainView === "shift") &&
                                <div 
                                    className={ "shift-view " + (this.state.breakdown.indexOf("week") !== -1 ? 'shift-view-week' : this.state.breakdown.indexOf("month") !== -1 ? 'shift-view-month' : 'shift-view-day') }
                                    style={{
                                        maxWidth: (this.state.breakdown === "day" || this.state.printing) ? "unset" : "unset", // "1280px",
                                        border: this.state.showingDemoRes ? ("8px solid " + theme.colours.blue2) : "none"
                                    }}
                                    onScroll={() => {
                                        this.handleScroll();
                                    }}
                                >
                                    <React.Fragment>
                                        { (this.state.breakdown.indexOf("month") === -1) &&
                                            <div 
                                                className="month-weeks"
                                                style={{
                                                    width: this.state.shiftRowWidth && this.state.breakdown.indexOf("week") !== -1 ? this.state.shiftRowWidth : undefined,
                                                }}
                                            >
                                            <StickyTable
                                                style={{
                                                    paddingBottom: 52,
                                                    overflow: 'scroll'
                                                }}
                                            >
                                                <Row 
                                                    data-type="date"
                                                    data-printing={this.state.printing ? "true" : "false"}
                                                    style={{
                                                        backgroundColor: this.state.printing ? 'whitesmoke' : undefined
                                                    }}
                                                >
                                                    <Cell
                                                        style={{
                                                            width: 140,
                                                            minWidth: 140,
                                                            padding: 0,
                                                            backgroundColor: this.state.printing ? 'whitesmoke' : undefined
                                                        }}
                                                    >
                                                        { (this.state.previousViewData) &&
                                                            <button
                                                                className='prev-view-btn'
                                                                onClick={() => {
                                                                    const previousViewData = this.state.previousViewData;
                                                                    if (previousViewData) {
                                                                        this.setState({
                                                                            contacts: JSON.parse(JSON.stringify(previousViewData.contacts)),
                                                                            flattenedEvents: JSON.parse(JSON.stringify(previousViewData.flattenedEvents)),
                                                                            days: JSON.parse(JSON.stringify(previousViewData.days)),
                                                                            workersGrouppedByDates: JSON.parse(JSON.stringify(previousViewData.workersGrouppedByDates)),
                                                                            eventsGrouppedByWorkers: JSON.parse(JSON.stringify(previousViewData.eventsGrouppedByWorkers)),
                                                                            workers: JSON.parse(JSON.stringify(previousViewData.workers)),
                                                                            workerOfferMetadata: JSON.parse(JSON.stringify(previousViewData.workerOfferMetadata)),
                                                                            hirerOfferMetadata: JSON.parse(JSON.stringify(previousViewData.hirerOfferMetadata)),
                                                                            start: previousViewData.start || new Date(),
                                                                            end: previousViewData.end || new Date(),
                                                                            breakdown: previousViewData.breakdown,
                                                                            viewPreset: previousViewData.viewPreset,
                                                                            monthWeeks: previousViewData.monthWeeks,
                                                                            previousViewData: undefined,
                                                                            hoveredOfferId: null,
                                                                        }, () => {
                                                                            this.handleResize();
                                                                        })
                                                                    }
                                                                }}
                                                            >
                                                                <i className="fas fa-chevron-up"></i>
                                                                Back to {this.state.previousViewData.breakdown.indexOf("month") !== -1 ? "Month" : "Week"}
                                                            </button>
                                                        }
                                                    </Cell>
                                                    { (this.state.breakdown.indexOf("week") !== -1) && this.state.days.map(day => {
                                                        return this.renderShiftDailyRowHeader(day, this.state.breakdown, HOURS);
                                                    }) }
                                                    { (this.state.breakdown === "day") &&
                                                        this.renderShiftHourlyRowHeader(HOURS, this.state.dailyBreakdownZoom)
                                                    }
                                                </Row>
                                                { Object.keys(this.state.eventsGrouppedByWorkers).map((key, i) => {
                                                    let worker = this.state.eventsGrouppedByWorkers[key];

                                                    if (this.state.breakdown.indexOf("week") !== -1)
                                                        return this.renderShiftDailyRow(worker.worker, null, worker.dates, i, VIEW_COMPONENTS, undefined, HOURS, this.state.breakdown);

                                                    if (this.state.breakdown === "day")
                                                        return this.renderShiftHourlyRow(worker.worker, null, worker.dates, i, VIEW_COMPONENTS, HOURS, this.state.dailyBreakdownZoom);
                                                    
                                                }) }
                                                { Object.keys(this.state.eventsGrouppedByHirers).map((key, i) => {
                                                    let hirer = this.state.eventsGrouppedByHirers[key];

                                                    if (this.state.breakdown.indexOf("week") !== -1)
                                                        return this.renderShiftDailyRow(null, hirer.hirer, hirer.dates, i, VIEW_COMPONENTS, undefined, HOURS, this.state.breakdown);

                                                    if (this.state.breakdown === "day")
                                                        return this.renderShiftHourlyRow(null, hirer.hirer, hirer.dates, i, VIEW_COMPONENTS, HOURS, this.state.dailyBreakdownZoom);
                                                    
                                                }) }
                                            </StickyTable>
                                            </div>
                                        }
                                        { this.state.breakdown.indexOf("month") !== -1 &&
                                            <div 
                                                className="month-weeks"
                                                style={{
                                                    width: this.state.shiftRowWidth ? this.state.shiftRowWidth : undefined,
                                                }}
                                            >
                                                { this.state.monthWeeks.map((week, weekI) => {
                                                    // Don't render day header if the first week of this month doesn't have any days in the first week on weekdays and skip weekend is enabled
                                                    if (this.state.skipWeekends && weekI === 0 && !Utilities.isSameMonth(new Date(week[5]), new Date(this.state.start || ""))) {
                                                        return null;
                                                    }
                                                    return (
                                                        <StickyTable
                                                            style={{
                                                                paddingBottom: 52
                                                            }}
                                                        >
                                                            <Row 
                                                                data-type="date"
                                                                data-printing={this.state.printing ? "true" : "false"}
                                                                style={{
                                                                    backgroundColor: this.state.printing ? 'whitesmoke' : undefined
                                                                }}
                                                            >
                                                                <Cell
                                                                    style={{
                                                                        width: 140,
                                                                        minWidth: 140,
                                                                        backgroundColor: this.state.printing ? 'whitesmoke' : undefined
                                                                    }}
                                                                >

                                                                </Cell>
                                                                { week.map((day, dayI) => {
                                                                    if (this.state.skipWeekends) {
                                                                        if (dayI > 4) {
                                                                            // Hide the last two days of the week if skipping weekends
                                                                            return null;
                                                                        }
                                                                    }
                                                                    
                                                                    return this.renderShiftDailyRowHeader(day, this.state.breakdown, HOURS);
                                                                }) }
                                                            </Row>
                                                            { Object.keys(this.state.eventsGrouppedByWorkers).map((key, i) => {
                                                                let worker = this.state.eventsGrouppedByWorkers[key];
                                                                return this.renderShiftDailyRow(worker.worker, null, worker.dates.filter(workerEvent => {
                                                                    return (week.indexOf(Utilities.formatDate(new Date(workerEvent.start || ""), "YYYY-MM-DD")) !== -1)
                                                                }), i, VIEW_COMPONENTS, weekI, HOURS, this.state.breakdown);
                                                            }) }
                                                            { Object.keys(this.state.eventsGrouppedByHirers).map((key, i) => {
                                                                let hirer = this.state.eventsGrouppedByHirers[key];
                                                                return this.renderShiftDailyRow(null, hirer.hirer, hirer.dates.filter(hirerEvent => {
                                                                    return (week.indexOf(Utilities.formatDate(new Date(hirerEvent.start || ""), "YYYY-MM-DD")) !== -1)
                                                                }), i, VIEW_COMPONENTS, weekI, HOURS, this.state.breakdown);
                                                            }) }
                                                        </StickyTable>
                                                    )
                                                }) }
                                            </div>
                                        }
                                    </React.Fragment>
                                </div>
                            }
                            { (this.state.mainView === "compact" && !this.state.compactViewDrillDownUserId) &&
                                <div 
                                    className="compact-view-week"
                                    style={{
                                        width: '100%',
                                        overflow: 'auto'
                                    }}
                                >
                                    {POWEREDBY_RIBBON}
                                    <h2>Timesheet from {Utilities.formatDate(new Date(this.state.days[0]), "ds DD MMM YYYY")} to {Utilities.formatDate(new Date(this.state.days[this.state.days.length-1]), "ds DD MMM YYYY")}, from {this.state.fromTimeLabel} to {this.state.toTimeLabel}</h2>
                                    <label
                                        style={{
                                            fontSize: 16,
                                            fontWeight: 700,
                                            margin: 0
                                        }}
                                    >Click row for details</label>

                                    { (this.state.alreadyRespondedWith && !this.state.timesheetResponseResult) &&
                                        <h2
                                            style={{
                                                margin: this.state.type === "external-timesheet-worker" ? "40px 0 12px 0" : "40px 0 -12px 0",
                                                textAlign: "left",
                                                padding: 0
                                            }}
                                        >
                                            You have  
                                            <span style={{ 
                                                background: this.state.alreadyRespondedWith === TimesheetResponseType.Accept ? theme.colours.green2 : theme.colours.red2,
                                                color: "white",
                                                padding: "1px 6px 2px 6px",
                                                margin: '0 4px 0 4px',
                                                display: 'inline-block',
                                                borderRadius: 6
                                            }}>
                                                    {this.state.alreadyRespondedWith === TimesheetResponseType.Accept ? "ACCEPTED" : "REJECTED"}
                                            </span> 
                                            this timesheet. You can change this below.
                                        </h2>
                                    }

                                    { this.state.type !== "external-timesheet-worker" ? TIMESHEET_RESPONSE_OPTIONS_DOM : null}

                                    <div 
                                        className="day"
                                        style={{
                                            width: '100%'
                                        }}
                                    >
                                        <StickyTable>
                                            <Row>
                                                <Cell>
                                                    { this.state.totalGrossPay && this.state.totalHours && 
                                                        <p>
                                                            <span style={{ fontWeight: 700 }}>{this.state.currency === "GBP" ? "£" : "£"}{this.state.totalGrossPay}</span>
                                                            {" "}
                                                            <span>{this.state.totalHours + " hours"}</span>
                                                        </p>
                                                    }
                                                    { this.state.totalGrossCharge && this.state.totalHours &&
                                                        <p>
                                                            <span style={{ fontWeight: 700 }}>{this.state.currency === "GBP" ? "£" : "£"}{this.state.totalGrossCharge}</span>
                                                            {" "}
                                                            <span>{this.state.totalHours + " hours"}</span>
                                                        </p>
                                                    }
                                                </Cell>
                                                { this.state.days.map(day => {
                                                    return (
                                                        <Cell>
                                                            <div
                                                                style={{
                                                                    width: this.state.compactDayLength,
                                                                    display: 'inline-block',
                                                                    fontWeight: 700,
                                                                    textAlign: 'center'
                                                                }}
                                                            >
                                                                {Utilities.formatDate(new Date(day), "DD, d mm")}
                                                            </div>
                                                        </Cell>
                                                    )
                                                }) }
                                            </Row>
                                            { Object.keys(this.state.eventsGrouppedByWorkers).map((key, i) => {
                                                let worker = this.state.eventsGrouppedByWorkers[key];
                                                // @ts-ignore
                                                let workerExtended: Worker = this.state.workers?.find(w => (w.externalId && w.externalId === worker.worker.externalId) || (w.id && w.id === worker.worker.id))
                                                return this.renderCompactRow(
                                                    this.state.restrictToMinHourNumber, 
                                                    this.state.restrictToMaxHourNumber,
                                                    this.state.compactDayLength,
                                                    worker.worker,
                                                    null,
                                                    worker.dates,
                                                    i,
                                                    this.state.terminology,
                                                    workerExtended ? workerExtended.submissionCount : 0
                                                )
                                            }) }
                                            { Object.keys(this.state.eventsGrouppedByHirers).map((key, i) => {
                                                let hirer = this.state.eventsGrouppedByHirers[key];
                                                return this.renderCompactRow(
                                                    this.state.restrictToMinHourNumber, 
                                                    this.state.restrictToMaxHourNumber,
                                                    this.state.compactDayLength,
                                                    null,
                                                    hirer.hirer,
                                                    hirer.dates,
                                                    i,
                                                    this.state.terminology
                                                )
                                            }) }
                                        </StickyTable>
                
                                    </div>

                                    {TIMESHEET_RESPONSE_OPTIONS_DOM}

                                </div>
                            }
                            { (this.state.mainView === "compact" && this.state.compactViewDrillDownUserId) &&
                                <div 
                                    className="compact-view"
                                    style={{
                                        width: '100%',
                                        overflow: 'auto'
                                    }}
                                >
                                    {POWEREDBY_RIBBON}
                                    <div className="header">
                                        <button
                                            onClick={() => {
                                                this.setState({
                                                    compactViewDrillDownUserId: null,
                                                    compactViewDrillDownUserIdInternal: null,
                                                    compactViewDrillDownUserReported: null,
                                                    compactViewDrillDownOrgDomain: null,
                                                    compactViewDrillDownOrgName: null,
                                                    compactViewDrillDownOrgTotalHours: null,
                                                    compactViewDrillDownOrgTotalGrossPay: null,
                                                    compactViewDrillDownOrgTotalGrossCharge: null,
                                                    restrictToMinHourNumber: this.state.restrictToMinHourNumberGlobal,
                                                    restrictToMaxHourNumber: this.state.restrictToMaxHourNumberGlobal,
                                                    compactViewDrillDownOrgId: null,
                                                    compactViewDrillDownExternalOrgId: null,
                                                })
                                                localStorage.removeItem("timesheetWorkerSelected");
                                                InternalTracker.trackEvent("Exited Contact Schedule View");
                                            }}
                                        >
                                            <i className="fas fa-chevron-left" />
                                            <label>Back</label>
                                        </button>
                                        <div className="contact">
                                            { (this.state.compactViewDrillDownUserIdInternal) &&
                                                <ProfileImage 
                                                    size={32}
                                                    url={
                                                        this.state.currentWorker && this.state.compactViewDrillDownOrgDomain ?
                                                        OrganisationApi.getExtOrgPicture(this.state.compactViewDrillDownOrgDomain) :
                                                        ProfileApi.getProfileImageUrl(this.state.compactViewDrillDownUserIdInternal || "")
                                                    }
                                                    selectable={false}
                                                    style={{
                                                        padding: 0,
                                                        border: 0,
                                                        marginRight: 6
                                                    }}
                                                    data-report-blurred={this.state.compactViewDrillDownUserReported}
                                                    data-user-id={this.state.compactViewDrillDownUserIdInternal}
                                                />
                                            }
                                            <div>
                                                { (this.state.compactViewDrillDownOrgName) ?
                                                    <p>{this.state.compactViewDrillDownOrgName}</p> :
                                                    <p 
                                                        data-report-blurred={this.state.compactViewDrillDownUserReported}
                                                        data-user-id={this.state.compactViewDrillDownUserIdInternal}
                                                    >
                                                        {this.state.eventsGrouppedByWorkers[this.state.compactViewDrillDownUserId]?.worker?.firstName}
                                                        {' '}
                                                        {this.state.eventsGrouppedByWorkers[this.state.compactViewDrillDownUserId]?.worker?.lastName.substr(0, 1)}
                                                    </p>
                                                }
                                                <p
                                                    style={{
                                                        fontWeight: 600
                                                    }}
                                                >
                                                     <span>
                                                        { this.state.compactViewDrillDownOrgTotalGrossPay ? <span style={{ fontWeight: 700 }}>{(this.state.currency === "GBP" ? "£" : "£") + this.state.compactViewDrillDownOrgTotalGrossPay}</span> : null }
                                                        { this.state.compactViewDrillDownOrgTotalGrossCharge ? <span style={{ fontWeight: 700 }}>{(this.state.currency === "GBP" ? "£" : "£") + this.state.compactViewDrillDownOrgTotalGrossCharge}</span> : null }
                                                        { this.state.compactViewDrillDownOrgTotalHours ? (' ' + this.state.compactViewDrillDownOrgTotalHours + ' hours') : null }
                                                        { this.state.workers && this.state.workers.find(w => w.externalId === this.state.compactViewDrillDownUserId) ? (' ' + (this.state.workers.find(w => w.externalId === this.state.compactViewDrillDownUserId)?.totalHours || 0) + " hours") : null }
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    { (this.state.workerOfferMetadata[this.state.compactViewDrillDownUserId || this.state.compactViewDrillDownUserIdInternal || ""]) &&
                                        <div className="legend">
                                            { Object.keys(this.state.workerOfferMetadata[this.state.compactViewDrillDownUserId || this.state.compactViewDrillDownUserIdInternal || ""]).map(legendItemKey => {
                                                const legendItem = this.state.workerOfferMetadata[this.state.compactViewDrillDownUserId || this.state.compactViewDrillDownUserIdInternal || ""][legendItemKey]
                                                return (
                                                    <div
                                                        style={{ background: legendItem.color }}
                                                    >
                                                        {legendItem.title}
                                                        <span>{legendItem.hours}h</span>
                                                    </div>
                                                )
                                            }) }
                                        </div>
                                    }
                                    { (this.state.hirerOfferMetadata[this.state.compactViewDrillDownUserId || this.state.compactViewDrillDownUserIdInternal || ""]) &&
                                        <div className="legend">
                                            { Object.keys(this.state.hirerOfferMetadata[this.state.compactViewDrillDownUserId || this.state.compactViewDrillDownUserIdInternal || ""]).map(legendItemKey => {
                                                const legendItem = this.state.hirerOfferMetadata[this.state.compactViewDrillDownUserId || this.state.compactViewDrillDownUserIdInternal || ""][legendItemKey]
                                                return (
                                                    <div
                                                        style={{ background: legendItem.color }}
                                                    >
                                                        {legendItem.title}
                                                        <span>{legendItem.hours}h</span>
                                                    </div>
                                                )
                                            }) }
                                        </div>
                                    }
                                    <div>
                                        <div 
                                            className="day"
                                            style={{
                                                width: '100%'
                                            }}
                                        >
                                            <StickyTable stickyHeaderCount={0}>
                                                { Object.keys(this.state.eventsGrouppedByWorkers).map(day => {
                                                    let worker = this.state.eventsGrouppedByWorkers[day];
                                                    if (worker.worker.externalId === this.state.compactViewDrillDownUserId) {
                                                        return this.state.days.map(currentDay => {
                                                            let eventsOfWorkerForCurrentDay = worker.dates.filter(event => Utilities.formatDate(new Date(event.start || ""), "YYYY-MM-DD") === currentDay);
                                                            if (eventsOfWorkerForCurrentDay.length > 0 || true) {
                                                                return (
                                                                    <Row>
                                                                        <Cell>
                                                                            <div 
                                                                                className="contact date"
                                                                            >
                                                                                <div>
                                                                                    <p>{Utilities.formatDate(new Date(currentDay), "DD")}</p>
                                                                                    <p>{Utilities.formatDate(new Date(currentDay), "d mm")}</p>
                                                                                </div>
                                                                            </div>
                                                                        </Cell>
                                                                        <Cell>
                                                                            {this.renderCompactRowDrillDown(
                                                                                eventsOfWorkerForCurrentDay, 
                                                                                this.state.restrictToMinHourNumber, 
                                                                                this.state.restrictToMaxHourNumber,
                                                                                this.state.compactHourLength,
                                                                                this.state.workers?.length ? 
                                                                                    this.state.workerOfferMetadata[this.state.compactViewDrillDownUserId || this.state.compactViewDrillDownUserIdInternal || ""] :
                                                                                    this.state.hirerOfferMetadata[this.state.compactViewDrillDownUserId || this.state.compactViewDrillDownUserIdInternal || ""]
                                                                            )}
                                                                        </Cell>
                                                                    </Row>
                                                                )
                                                            }
                                                        })
                                                    }
                                                }) }
                                                { Object.keys(this.state.eventsGrouppedByHirers).map(day => {
                                                    let hirer = this.state.eventsGrouppedByHirers[day];
                                                    const hirerId = hirer.hirer.organisationId || hirer.hirer.organisationExternalId
                                                    if (hirerId === this.state.compactViewDrillDownUserId) {
                                                        return this.state.days.map(currentDay => {
                                                            let eventsOfHirerForCurrentDay = hirer.dates.filter(event => Utilities.formatDate(new Date(event.start || ""), "YYYY-MM-DD") === currentDay);
                                                            if (eventsOfHirerForCurrentDay.length > 0 || true) {
                                                                return (
                                                                    <Row>
                                                                        <Cell>
                                                                            <div 
                                                                                className="contact date"
                                                                            >
                                                                                <div>
                                                                                    <p>{Utilities.formatDate(new Date(currentDay), "DD")}</p>
                                                                                    <p>{Utilities.formatDate(new Date(currentDay), "d mm")}</p>
                                                                                </div>
                                                                            </div>
                                                                        </Cell>
                                                                        <Cell>
                                                                            {this.renderCompactRowDrillDown(
                                                                                eventsOfHirerForCurrentDay, 
                                                                                this.state.restrictToMinHourNumber, 
                                                                                this.state.restrictToMaxHourNumber,
                                                                                this.state.compactHourLength,
                                                                                this.state.workers?.length ? 
                                                                                    this.state.workerOfferMetadata[this.state.compactViewDrillDownUserId || this.state.compactViewDrillDownUserIdInternal || ""] :
                                                                                    this.state.hirerOfferMetadata[this.state.compactViewDrillDownUserId || this.state.compactViewDrillDownUserIdInternal || ""]
                                                                            )}
                                                                        </Cell>
                                                                    </Row>
                                                                )
                                                            }
                                                        })
                                                    }
                                                }) }
                                            </StickyTable>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </main>

                { (this.state.openedOfferId) &&
                    <ViewOffer
                        lists={[]}
                        contacts={[]}
                        offerIdToLoad={this.state.openedOfferId || undefined}
                        handleViewCancel={() => {
                            this.setState({
                                openedOfferId: null
                            })
                        }}
                    />
                }

                { (this.state.openedOfferId || this.state.showFilters) &&
                    <div
                        className="masked2"
                        onClick={() =>
                            this.setState({
                                openedOfferId: null,
                                showFilters: false
                            })
                        }
                    />
                }

                { (this.state.printing) &&
                    <FullScreenLoader
                        loadingMessage={"Printing" + (this.state.printingProgress ? (" (" + Math.round(this.state.printingProgress) + "%)") : "" )}
                    />
                }

                { (this.state.joyride) &&
                    <Joyride
                        run={true}
                        callback={(data) => {
                            if (data.action === "reset" || data.action === "close") {
                                this.setState({
                                    joyride: null
                                })
                            }
                        }}
                        locale={{
                            last: "Okay"
                        }}
                        continuous={true}
                        steps={[
                            {
                                target: this.state.joyride === "rating" ? '.worker-rater>div' : this.state.joyride === "rating-mandatory" ? '.worker-rater.too-many-rating-skips>div' : '',
                                content: this.state.joyride === "rating" ? 
                                    (<div>
                                        <h1>Some contacts are missing your rating</h1>
                                    </div>)
                                    :
                                    (<div>
                                        <h1>Some contacts are missing your rating</h1>
                                        <p>There are some contacts you skipped rating more than 3 times, please rate them before submitting your response.</p>    
                                    </div>)
                                ,
                                disableBeacon: true
                            }
                        ]}
                    />
                }
            </div>
        );
    }
}

export default connect(
    (state: ApplicationState) => ({
        timePresets: state.timePresetManagement.timePresetMgtState,
        authenticatedUserProfile: state.profile.authenticatedUserProfile
    }),
    (dispatch) => ({
        timePresetsActions: bindActionCreators(
            TimePresetsActionCreators.actionCreators,
            dispatch
        ),
    })
)(Schedule);