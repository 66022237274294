import styled from 'styled-components';

const WarningTip = styled.i`
    margin-left: 10px;
    color: orangered;
    font-size: 20px;
    cursor: help;
`;

export default WarningTip;
