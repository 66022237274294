/* eslint-disable */
import { SubSector } from 'src/api/contacts/ResponseTypes';

import { AppThunkAction } from '..';
import ContactsApi from '../../api/contacts/Contacts';
import ProfileApi from '../../api/profile/Profile';
import { KnownAction } from './Actions';
import { toast } from 'react-toastify';

export const actionCreators = {
    /**
     * Requests a contact
     * @param id Contact id
     */
    getContact: (id: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'CONTACTPROFILE_STORE.CONTACT.REQUEST', userId: id });
        ContactsApi.getContactByContactId(id)
            .then((c) => {
                let allSkills: SubSector[] = [];
                if (c.industries && c.industries.length !== 0) {
                    for (let i = 0; i < c.industries.length; i++) {
                        const industry = c.industries[i];
                        if (industry.subSectors) {
                            allSkills = allSkills.concat(industry.subSectors);
                        }
                    }
                }
                dispatch({
                    type: 'CONTACTPROFILE_STORE.CONTACT.REQUEST.SUCCESS',
                    contact: {
                        firstName: c.firstName,
                        lastName: c.lastName,
                        lastAvailabilityUpdateOn: c.lastAvailabilityUpdateOn,
                        notificationStatusTypeId: c.notificationStatusTypeId,
                        companyId: c.companyId,
                        companyName: c.companyName,
                        email: c.email,
                        fullName: c.fullName,
                        id: c.id,
                        profileImageUrl: ProfileApi.getProfileImageUrl(
                            c.userId
                        ),
                        userId: c.userId,
                        verified: c.verified,
                        headline: c.headline,
                        industries: c.industries,
                        qualifications: c.qualifications,
                        files: c.files,
                        folders: c.folders,
                        ownRating: c.ownRating,
                        rating: c.rating,
                        ownOrganisationRatings: c.ownOrganisationRatings,
                        externalRatings: c.externalRatings,
                        skills: allSkills,
                        avgRating: c.avgRatingStars,
                        totalRatings: c.totalRatings,
                        representedByOrganisations: c.representedByOrganisations,
                        workerAttributes: c.workerAttributes,
                        verifications: c.verifications,
                        notSharing: c.notSharing,
                        maskedEmailAddress: c.maskedEmailAddress,
                        maskedPhoneNumber: c.maskedPhoneNumber,
                        invitedByOrg: c.invitedByOrg,
                        reported: c.reported
                    }
                });

                if ((window as any).contactProfileStoreRequestSuccess)
                    (window as any).contactProfileStoreRequestSuccess();
            })
            .catch((e) => {
                if (window.location.href.indexOf('/external/contact') !== -1) {
                    alert(
                        "Token expired, log in to see your contact's availability"
                    );
                } else {
                    toast.error("You don't have access to this contact");
                }
            });
    },

    clearContact: (): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({
            type: 'CONTACTPROFILE_STORE.CONTACT.REQUEST.SUCCESS',
            contact: {
                firstName: '',
                lastName: '',
                notificationStatusTypeId: 0,
                companyId: '',
                companyName: '',
                email: '',
                fullName: '',
                id: '',
                profileImageUrl: '',
                userId: '',
                verified: false,
                headline: '',
                industries: [],
                qualifications: [],
                files: [],
                folders: [],
                ownOrganisationRatings: [],
                externalRatings: [],
                skills: [],
                totalRatings: 0,
                representedByOrganisations: [],
                workerAttributes: [],
                verifications: [],
                notSharing: false,
            }
        });
    }
};
