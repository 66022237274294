import { Reducer, Action } from 'redux';
import update from 'immutability-helper';

import { exhaustiveCheck } from '..';
import { ContactProfileState, defaultState } from './States';
import { KnownAction } from './Actions';

export const reducer: Reducer<ContactProfileState | undefined> = (
    state: ContactProfileState | undefined,
    incomingAction: Action
) => {
    if (state == undefined) return defaultState;

    /** Limit incoming actions to known defined actions */
    const action = incomingAction as KnownAction;

    switch (action.type) {
        case 'CONTACTPROFILE_STORE.CONTACT.REQUEST':
            break;

        case 'CONTACTPROFILE_STORE.CONTACT.REQUEST.SUCCESS':
            return update(state, { contact: { $set: action.contact } });

        default:
            exhaustiveCheck(action);
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || defaultState;
};
