import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { UserOfferDto, PendingEventDto } from '../../api/offers/ResponseTypes';
import { AsyncOverlay } from '../ui-components/AsyncOverlay';
import { ProfileImage } from '../ui-components/ProfileImage';
import OfferApi from '../../api/offers/Offers';
import ProfileApi from '../../api/profile/Profile';
import CompanyApi from '../../api/company/Company';
import Analytics from '../../services/analytics.service';
import TimelineEvents from '../../components/events/timeline-events';

interface Props {
    show: boolean;
    offer: UserOfferDto | undefined;
    onHide: (added?: boolean) => void;
}

interface State {
    loading: boolean;
    events: PendingEventDto[];
}

export default class MobileOfferEventsPreview extends React.Component<
    Props,
    State
> {
    state = {
        loading: true,
        events: []
    } as State;

    handleEntering = () => {
        this.setState({
            loading: true,
            events: []
        });

        if (this.props.offer) {
            OfferApi.getOfferEvents(this.props.offer.id).then((events) => {
                this.setState({
                    loading: false,
                    events
                });
            });
        }

        Analytics.trackEvent('offer-mobile-event-preview-open');
    };

    handleConfirm = () => {
        if (this.props.offer) {
            this.setState({
                loading: true
            });

            OfferApi.confirmOfferEvents(this.props.offer.id).then(() => {
                toast.success('Added to schedule!', { autoClose: 2500 });

                this.props.onHide(true);
            });

            Analytics.trackEvent('offer-mobile-event-preview-confirmed');
        }
    };

    handleDecline = () => {
        if (this.props.offer) {
            this.setState({
                loading: true
            });

            OfferApi.declineOfferEvents(this.props.offer.id).then(() => {
                this.props.onHide(false);
            });

            Analytics.trackEvent('offer-mobile-event-preview-declined');
        }
    };

    render() {
        const creatorImageUrl = this.props.offer
            ? ProfileApi.getProfileImageUrl(
                  this.props.offer.metadata.createdById
              )
            : '';
        const companyImageUrl = this.props.offer
            ? CompanyApi.getOrganisationProfileImageUrl(
                  this.props.offer.metadata.createdByOrganisationId
              )
            : '';

        return (
            <Modal
                show={this.props.show}
                onEntering={this.handleEntering}
                onHide={this.props.onHide}
                dialogClassName="modal-dialog-centered"
            >
                <Modal.Body>
                    {' '}
                    {this.props.offer && (
                        <>
                            <h5
                                style={{
                                    textAlign: 'center',
                                    fontWeight: 'bold'
                                }}
                            >
                                Offer confirmed
                            </h5>
                            <div className="layout horizontal justified center m-t m-b">
                                <div>
                                    {this.props.offer.metadata
                                        .createdByOrganisationId && (
                                        <span>
                                            {
                                                this.props.offer.metadata
                                                    .createdByOrganisationName
                                            }
                                        </span>
                                    )}

                                    <br />
                                    <span>{`${this.props.offer.title} `}</span>
                                    {this.props.offer.metadata
                                        .createdByOrganisationIsAgency && (
                                        <span>
                                            {`(${this.props.offer.metadata.createdByOrganisationName})`}
                                        </span>
                                    )}
                                    {this.props.offer.metadata
                                        .createdByOrganisationIsHirer && (
                                        <span>
                                            {` at ${this.props.offer.metadata.createdByOrganisationName}`}
                                        </span>
                                    )}
                                </div>
                                <div>
                                    <ProfileImage
                                        selectable={false}
                                        url={creatorImageUrl}
                                        size={54}
                                    />
                                    <ProfileImage
                                        selectable={false}
                                        url={companyImageUrl}
                                        size={54}
                                    />
                                </div>
                            </div>
                            <TimelineEvents
                                title={`${
                                    this.props.offer.metadata
                                        .createdByOrganisationName
                                } - ${this.props.offer!.title}`}
                                events={this.props.offer.events}
                                maxHeight="calc(100vh - 430px)"
                            />
                            <label className="m-t">
                                Do you want to add these events to your
                                schedule?
                            </label>
                            <div
                                style={{
                                    fontStyle: 'italic',
                                    fontSize: '11px'
                                }}
                            >
                                Adding these events to your schedule will let
                                your other contacts see you're no longer
                                available.
                                <br />
                                <br />
                                It will take a few moments for it to appear.
                            </div>
                        </>
                    )}
                    <AsyncOverlay show={this.state.loading} text="Loading..." />
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.handleDecline}>No</Button>
                    <Button bsStyle="primary" onClick={this.handleConfirm}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
