/* eslint-disable */
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';

import ReleaseNotesVersionService from '../services/release-notes-version.service';

interface WhatsNewProps {
    modal?: boolean;
    onClose?: () => void;
}

export class WhatsNew extends React.Component<WhatsNewProps, {}> {
    componentDidMount() {
        // ReleaseNotesVersionService.updateReadReleaseNotesVersion();
    }

    render() {
        const data = ReleaseNotesVersionService.getReleaseNotes();

        if (this.props.modal && this.props.onClose !== undefined) {
            return (
                <Wrapper>
                    <Modal
                        show={true}
                        className="whats-new"
                        onHide={() => {
                            // @ts-ignore
                            this.props.onClose();
                        }}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>What's new</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {Boolean(data) ? (
                                <ReactMarkdown source={data} />
                            ) : (
                                'No release notes currently available.'
                            )}
                        </Modal.Body>
                    </Modal>
                </Wrapper>
            )
        }

        return (
            <Wrapper>
                <div className="whats-new">
                    <div className="row">
                        <div className="hpanel">
                            <div className="panel-body">
                                <h2 className="font-light m-b-xs">What's New</h2>
                                <small>
                                    Read about the latest changes in the updatedge
                                    portal.
                                </small>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="hpanel">
                            <div className="panel-body">
                                {Boolean(data) ? (
                                    <ReactMarkdown source={data} />
                                ) : (
                                    'No release notes currently available.'
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Wrapper>
        );
    }
}

const Wrapper = styled.div`
    h2 {
        margin: 0 0 12px 0;
        font-weight: 700;
        font-size: 1.6em;
        line-height: 1.8em;
    }
`;

export default WhatsNew;
