import React from 'react';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import moment from 'moment';

import { Offers } from '../../pages/Offers';
import { OfferRecipientDto } from '../../api/offers/ResponseTypes';
import { change, changeType } from './ViewOffer';
import { DateFormats } from '../../constants';
import { ProfileImage } from '../ui-components/ProfileImage';
import ProfileApi from '../../api/profile/Profile';
import history from '../../history';

interface Props {
    handleViewCancel: Offers['handleViewCancel'];
    startChat: Offers['startChat'];
    recipient?: OfferRecipientDto;
    change: change;
}

const OfferWarning = ({
    handleViewCancel,
    startChat,
    recipient,
    change
}: Props) => {
    const renderWarningPopover = (recipient, change: change) => {
        switch (change.type) {
            case changeType.generic:
                return (
                    <Popover
                        id={`popover-positioned-top`}
                        className="modal-content"
                        style={{
                            filter:
                                'drop-shadow(0 0 0.25rem rgba(106, 108, 111, 0.35))'
                        }}
                    >
                        <div className="modal-header" id="modal-header">
                            <h4 className="modal-title" id="modal-title">
                                Warning
                            </h4>
                        </div>
                        <div className="modal-body" id="modal-body">
                            {`One or more recipient has changed an offer event after confirmation.`}
                        </div>
                        <div className="modal-footer" id="modal-footer">
                            <div>
                                {/*<Button*/}
                                {/*    bsStyle="success"*/}
                                {/*    bsSize="sm"*/}
                                {/*    style={{ padding: '2px 6px' }}*/}
                                {/*    type="button"*/}
                                {/*    // onClick={() => {*/}
                                {/*    //     handleViewCancel();*/}
                                {/*    //     startChat(recipient);*/}
                                {/*    // }}*/}
                                {/*>*/}
                                {/*    <i*/}
                                {/*        className="fas fa-comments"*/}
                                {/*        style={{ marginRight: '6px' }}*/}
                                {/*    />*/}
                                {/*    Chat with {recipient.recipientName}*/}
                                {/*</Button>*/}
                            </div>
                        </div>
                    </Popover>
                );
            case changeType.delete:
                return (
                    <Popover
                        id={`popover-positioned-top`}
                        className="modal-content"
                        style={{
                            filter:
                                'drop-shadow(0 0 0.25rem rgba(106, 108, 111, 0.35))'
                        }}
                    >
                        <div className="modal-header" id="modal-header">
                            <div className="modal-title" id="modal-title">
                                Warning - Deletion
                            </div>
                        </div>
                        <div
                            className="modal-body"
                            id="modal-body"
                            style={{
                                display: 'flex',
                                justifyContent: 'space-around',
                                height: '100%'
                            }}
                        >
                            <ProfileImage
                                selectable={true}
                                size={72}
                                type={'Organisation'}
                                onSelect={() => {
                                    history.push(
                                        `/contact/${recipient.recipientContactId}`
                                    );
                                }}
                                url={
                                    ProfileApi.getProfileImageUrl(
                                        recipient.recipientUserId
                                    ) || ''
                                }
                            />
                            <div
                                style={{
                                    height: '100%!important',
                                    width: '1rem'
                                }}
                            />

                            <span
                                style={{
                                    margin: 'auto',
                                    height: '100%!important',
                                    justifyContent: 'center'
                                }}
                            >
                                {`${recipient.recipientName} has deleted confirmed event:`}
                                <br />
                                <span style={{ color: 'firebrick' }}>
                                    <span>
                                        {moment(change.es!.start).format(
                                            DateFormats.ShortDateWithDay
                                        )}
                                    </span>
                                    {` - `}
                                    <span>
                                        {moment(change.es!.start).format(
                                            DateFormats.Time24Hour
                                        )}
                                    </span>
                                    {':'}
                                    <span>
                                        {moment(change.es!.end).format(
                                            DateFormats.Time24Hour
                                        )}
                                    </span>
                                </span>
                            </span>
                        </div>
                        <div className="modal-footer" id="modal-footer">
                            <div>
                                <Button
                                    bsStyle="success"
                                    bsSize="sm"
                                    style={{ padding: '2px 6px' }}
                                    type="button"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        handleViewCancel();
                                        startChat(recipient);
                                    }}
                                >
                                    <i
                                        className="fas fa-comments"
                                        style={{ marginRight: '6px' }}
                                    />
                                    Chat with {recipient.recipientName}
                                </Button>
                            </div>
                        </div>
                    </Popover>
                );
        }
    };

    return (
        <>
            <OverlayTrigger
                trigger={['click', 'focus']}
                placement="bottom"
                rootClose
                overlay={renderWarningPopover(recipient, change)}
            >
                <i
                    className="fas fa-exclamation-triangle fa-hover"
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                    style={{
                        fontSize: '18px',
                        marginRight: '0.333rem',
                        cursor: 'help',
                        color: 'red'
                    }}
                />
            </OverlayTrigger>
        </>
    );
};

export default OfferWarning;
