/* eslint-disable */
// TODONOW
import React from 'react';
import { Glyphicon } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import ContactInvite from '../components/contacts/ContactInvite';
import ContactSearch from '../components/contacts/ContactSearch';
import PrintableInvitation from '../components/invitations/PrintableInvitation';
import { AutoInvitations } from '../components/invitations/AutoInvitations';
import styled from 'styled-components';

import { useSelector } from 'react-redux';
import { ApplicationState } from '../store';
import CompanyAPI from '../api/company/Company';
import ProfileAPI from '../api/profile/Profile';
import '../css/Invite.css';
import DiscoverNewContacts from 'src/components/invitations/DiscoverNewContacts';

export class Home extends React.Component<{}, {
    showInvitations: boolean;
    hideInviteByAgency: boolean;
    showDiscoverContacts?: boolean;
}> {
    autoInvitations: React.RefObject<AutoInvitations>;

    constructor(props) {
        super(props);
        this.autoInvitations = React.createRef();
        this.state = {
            showInvitations: true,
            hideInviteByAgency: false,
            showDiscoverContacts: true
        }
    }

    propagate = () => {
        if (this.autoInvitations.current) {
            this.autoInvitations.current.reload();
        }
        this.setState({
            showDiscoverContacts: false
        }, () => {
            this.setState({
                showDiscoverContacts: true
            })
        })
    };

    registerForPushNotifications = async () => {
        let subscription:any = null;
        try {
            const registration = await navigator.serviceWorker.ready;
            subscription = await registration.pushManager.subscribe({
              userVisibleOnly: true,
              applicationServerKey: this.urlBase64ToUint8Array('BHkzUex6oiCe724oVwpa4btjjecDGRWx324YyuEtkpPL012TDfZn6TXpqxaMzybkWdoDW5qP-vWQyGyKCGK_P7w'),
            });
          } catch (error) {
            console.error('_______ Error subscribing to notifications:', error);
            throw error;
          }

          const rawKey = subscription.getKey('p256dh');
          const rawAuthSecret = subscription.getKey('auth');
  
          const keys = {
            // @ts-ignore
              p256dh: btoa(String.fromCharCode.apply(null, new Uint8Array(rawKey))),
            // @ts-ignore
              auth: btoa(String.fromCharCode.apply(null, new Uint8Array(rawAuthSecret)))
          };
  
          console.log('Subscription endpoint:', subscription.endpoint);
          console.log('p256dh key:', keys.p256dh);
          console.log('auth key:', keys.auth);

          console.log('_______ Subscription:', subscription ? subscription.endpoint : "no sub", subscription);
    };

    urlBase64ToUint8Array(base64String) {
        const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
        const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
        const rawData = window.atob(base64);
        const outputArray = new Uint8Array(rawData.length);
      
        for (let i = 0; i < rawData.length; ++i) {
          outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
    }

    componentDidMount() {
        const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') || '{}') : null;
        if (user && user.userType === 3) {
            this.setState({
                hideInviteByAgency: true
            })
        }
    }

    public render() {
        const OPTIONS_DOM = 
            <div>
                <h3>Select options to invite contacts:</h3>
                <div className="options">
                    <ContactInvite onInvited={this.propagate} autoOpen={window.location.href.indexOf("#email") !== -1} />
                    { (!this.state.hideInviteByAgency) &&
                        <ContactSearch onInvited={this.propagate} />
                    }
                    <PrintableInvitation />
                </div>
            </div>
        return (
            <Wrapper>
                <div className="row">
                    <div className="hpanel pageHeader">
                        <div className="panel-body">
                            <div className="glyph pull-right">
                                <Glyphicon
                                    glyph="cloud"
                                    bsClass="mail glyphicon-6x text-primary"
                                />
                            </div>
                            {/* <button onClick={() => {
                                this.registerForPushNotifications();
                            }}> Regsiter for Push</button> */}
                            <h2>
                                Invite Contacts to:
                            </h2>
                            <ul>
                                <li>Share their availability, skills & qualifications</li>
                                <li>Chat directly</li>
                                <li>Offer work</li>
                                <li>Select preferred applicants</li>
                            </ul>
                            { document.documentElement.clientWidth < 550 ? OPTIONS_DOM : null}
                            { (this.state.showDiscoverContacts) &&
                                <DiscoverNewContacts onContactInvite={() => {
                                    this.setState({
                                        showInvitations: false
                                    }, () => {
                                        this.setState({
                                            showInvitations: true
                                        })
                                    })
                            }} />
                            }
                            { document.documentElement.clientWidth > 550 ? OPTIONS_DOM : null}
                        </div>
                    </div>
                </div>
                { (this.state.showInvitations) &&
                    <div className="row">
                        <div className="hpanel">
                            <div className="panel-body">
                                <AutoInvitations ref={this.autoInvitations} />
                            </div>
                        </div>
                    </div>
                }
            </Wrapper>
        );
    }
}

const Wrapper = styled.div`

    h2 {
        font-weight: 700;
        margin: 0 0 12px 0;
        font-size: 1.6em;
    }

    h3 {
        text-align: center;
        margin: 32px 0 20px 0;
        font-weight: 500;
        font-size: 1.6em;
    }

    small {
        display: block;
        fontSize: 1em;
    }

    .options {
        display: flex;
        justify-content: center;

        & > div {
            flex-basis: 33.33%;
            width: 33.33%;
            text-align: center;
            background-color: whitesmoke;
            border-radius: 12px;
            padding: 12px;
            display: inline-flex;
            align-items: center;
            justidt-content: center;
            flex-wrap: wrap;
            cursor: pointer;

            img {
                width: 100%;
                margin: auto;
                display: block;
                max-width: 200px;
            }

            p {
                margin: 12px 0 0 0;
                font-size: 1.4em;
                font-weight: 600;
            }

            &:not(:last-child) {
                margin-right: 20px;
            }

            &:hover {
                background-color: gainsboro
            }
        }

    }

    @media (max-width: 550px) {

        .options {
            margin-bottom: 15px;

            & > div {
                padding: 10px 8px;
                
                &:first-child, &:nth-child(2) {
                    margin-right: 10px !important;
                }

                p {
                    font-weight: 700;
                    font-size: 13px;
                }
            }
        }

    }

`;

export default Home;
