import React from 'react';

import Splash from '../ui-components/Splash';

class ErrorBoundary extends React.Component {
    state = { hasError: false };

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    render() {
        if (this.state.hasError) {
            return (
                <Splash
                    displaySpinner={false}
                    tagline="An error occurred, please refresh the page."
                />
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
