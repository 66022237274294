import { Reducer, Action } from 'redux';
import update from 'immutability-helper';

import { exhaustiveCheck } from '..';
import { RepresentedByState, defaultState } from './States';
import { KnownAction } from './Actions';

export const reducer: Reducer<RepresentedByState | undefined> = (
    state: RepresentedByState | undefined,
    incomingAction: Action
) => {
    if (state == undefined) return defaultState;

    /** Limit incoming actions to known defined actions */
    const action = incomingAction as KnownAction;

    switch (action.type) {
        case 'REPRESENTEDBY_STORE.REQUEST':
            break;

        case 'REPRESENTEDBY_STORE.REQUEST.SUCCESS':
            return update(state, { data: { $set: action.data } });

        default:
            exhaustiveCheck(action);
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || defaultState;
};
