/* eslint-disable */
import { PendingEventSet, InferredUnavaialbleDateDto } from '../../api/events/ResponseTypes';

import AppConfig from '../../components/config/Config';
import afetch from '../AuthenticatedFetch';

// API urls
const apiCalls = {
    getPendingEventSets() {
        const url = `${AppConfig.Settings.api.baseUri}/publicapi/events/pending`;
        return afetch.request<null, PendingEventSet[]>(
            url,
            'GET',
            null,
            'Unable to get pending events'
        );
    },
    acceptPendingEventSet(id: string) {
        const url = `${AppConfig.Settings.api.baseUri}/publicapi/events/pending/${id}/accept`;
        return afetch.request(
            url,
            'GET',
            null,
            'Unable to accept pending events'
        );
    },
    getUserMaximumAvailabilityDate(userId: string) {
        const url = `${AppConfig.Settings.api.baseUri}/publicapi/events/${userId}/maxAvailabilityDate`;
        return afetch.request<null, string>(
            url,
            'GET',
            null,
            'Unable to get availability date'
        );
    },
    getInferredUnavailableDates(contactId: string, start: string, end: string) {
        const url = `${AppConfig.Settings.api.externalUri}/timeline/inferredUnavailabilityDatesInTimeFrame/contact/${contactId}?start=${start}&end=${end}`;
        return afetch.request<null, InferredUnavaialbleDateDto[]>(
            url,
            'GET',
            null,
            'Unable to get inferred unavailable dates'
        );
    }
};

export default apiCalls;
