/* eslint-disable */
import { ToastType } from 'react-toastify';

import afetch from '../AuthenticatedFetch';
import AppConfig from '../../components/config/Config';
import { NewPrimaryContactDTO } from './RequestTypes';
import { CompanyContactDto, CompanyDTO, TimeSheetMetaDto, AgencySuggestionDto } from './ResponseTypes';
import * as Notifications from './../../store/Notifications';

export interface CompanySearchDTO {
    id?: string | undefined;
    name: string;
    domain: string;
    logo?: string | undefined;
    primaryContact: CompanyContactDto | undefined;
}

const apiUrls = {
    addCompany() {
        return `${AppConfig.Settings.api.baseUri}/publicapi/company/add`;
    },
    getCompanies() {
        return `${AppConfig.Settings.api.baseUri}/publicapi/company`;
    },
    location() {
        return `${AppConfig.Settings.api.baseUri}/publicapi/company/location`;
    },
    getUserCompanyContacts(companyId: string, search: string) {
        return `${AppConfig.Settings.api.baseUri}/publicapi/company/userCompanyContacts/${companyId}?search=${search}`;
    },
    primaryContact(companyId: string) {
        return `${AppConfig.Settings.api.baseUri}/publicapi/company/primaryContact/${companyId}`;
    },
    getCompanyProfileImage(id: string) {
        return `${AppConfig.Settings.api.baseUri}/publicapi/company/image/${id}`;
    },
    getOrganisationProfileImage(id: string) {
        return `${AppConfig.Settings.api.imageCdnUri}/images/organisation/${id.toLowerCase()}.png`;
    },
    getOrganisationProfileImageSuggestionUrl(domain: string) {
        return ''
    },
    getTemporaryOrganisationProfileImage(id: string) {
        return `${AppConfig.Settings.api.baseUri}/publicapi/org/image/temp/${id}`;
    },
    updateOrganisationImage() {
        return `${AppConfig.Settings.api.baseUri}/publicapi/org/image`;
    },
    updateTemporaryOrganisationImage(id: string) {
        return `${AppConfig.Settings.api.baseUri}/publicapi/org/image/temp/${id}`;
    },
    getWorkerTemporaryOrganisationImage(id: string) {
        return `${AppConfig.Settings.api.baseUri}/publicapi/org/image/worker/temp/${id}`;
    },
    updateWorkerTemporaryOrganisationImage(id: string) {
        return `${AppConfig.Settings.api.baseUri}/publicapi/org/image/worker/temp/${id}`;
    },

    memberRole: (email: string, roleId: number) =>
        `${AppConfig.Settings.api.baseUri}/api/company/member/${email}/role/${roleId}/`,
    memberActive: (email: string, active: boolean, swapWithUserId?: string) =>
        `${AppConfig.Settings.api.baseUri}/api/company/member/${email}/active/${active}?swapWithUserId=${swapWithUserId}`,
    getTimeSheetMetadata: (token: string) => {
        return `${AppConfig.Settings.api.externalUri}/organisations/timesheet/${token}`
    },
    getRotaMetadata: (token: string) => {
        return `${AppConfig.Settings.api.externalUri}/organisations/rota/${token}`
    },
    getClearBitSuggestionByDomain: (domainName: string) => {
        return "https://logo.clearbit.com/" + domainName;
    },
    getAgencySuggestion: (domainName: string) => {
        return `${AppConfig.Settings.api.externalUri}/agencysuggestion/${domainName}`
    },
    getAllAgencySuggestion: () => {
        return `${AppConfig.Settings.api.externalUri}/agencysuggestion`
    },
    pushAgencySuggestion: () => {
        return `${AppConfig.Settings.api.externalUri}/agencysuggestion`
    }
};

const apiCalls = {
    pushAgencySuggestion(newValues: AgencySuggestionDto[]) {
        return afetch.request<AgencySuggestionDto[], AgencySuggestionDto[]>(
            apiUrls.pushAgencySuggestion(),
            'POST',
            newValues,
            'Unable to post agency suggestion'
        );
    },
    getAllAgencySuggestion() {
        return afetch.request<null, AgencySuggestionDto[]>(
            apiUrls.getAllAgencySuggestion(),
            'GET',
            null,
            'Unable to get agency suggestion'
        );
    },
    getAgencySuggestion(domainName: string) {
        return afetch.request<null, AgencySuggestionDto[]>(
            apiUrls.getAgencySuggestion(domainName),
            'GET',
            null,
            'Unable to get agency suggestion'
        );
    },
    getClearBitSuggestionByDomain(token: string) {
        return apiUrls.getClearBitSuggestionByDomain(token);
    },
    getTimeSheetMetadata(token: string) {
        return afetch.request<null, TimeSheetMetaDto>(
            apiUrls.getTimeSheetMetadata(token),
            'GET',
            null,
            'Unable to get agency details'
        );
    },
    getRotaMetadata(token: string) {
        return afetch.request<null, TimeSheetMetaDto>(
            apiUrls.getRotaMetadata(token),
            'GET',
            null,
            'Unable to get agency details'
        );
    },
    addCompany(company: CompanySearchDTO) {
        return afetch.request<CompanySearchDTO, CompanySearchDTO>(
            apiUrls.addCompany(),
            'POST',
            company
        );
    },
    getCompanies() {
        return afetch.request<null, CompanySearchDTO[]>(
            apiUrls.getCompanies(),
            'GET',
            null,
            'Unable to get companies'
        );
    },
    getUserCompanyContacts(companyId: string, search: string) {
        return afetch
            .request<null, CompanyContactDto[]>(
                apiUrls.getUserCompanyContacts(companyId, search),
                'GET',
                null,
                'Unable to get user company contacts'
            )
            .then((results) =>
                (results || []).map((r) => {
                    return { ...r, $id: undefined };
                })
            );
    },
    addPrimaryContact(companyId: string, name: string, email: string) {
        return afetch.request<
            NewPrimaryContactDTO,
            CompanyContactDto | undefined
        >(
            apiUrls.primaryContact(companyId),
            'POST',
            { name, email },
            'Unable to add new primary contact'
        );
    },
    setRole(email: string, roleId: number) {
        return afetch.request<null>(
            apiUrls.memberRole(email, roleId),
            'GET',
            null,
            'Unable to set role'
        );
    },
    setMemberActive(email: string, active: boolean, swapWithUserId?: string) {
        return afetch.request<null, boolean>(
            apiUrls.memberActive(email, active, swapWithUserId),
            'GET',
            null,
            'Unable to archive member'
        );
    },
    getAll() {
        const url = `${AppConfig.Settings.api.baseUri}/api/company/internal/list`;
        return afetch.request<null, CompanyDTO[]>(url, 'GET', null);
    },
    getCompanyProfileImageUrl: (id: string) => {
        return apiUrls.getCompanyProfileImage(id);
    },
    getOrganisationProfileImageUrl: (id: string | null) => {
        return id ? apiUrls.getOrganisationProfileImage(id) : '';
    },
    getOrganisationProfileImageSuggestionUrl: (id: string | null) => {
        return id ? apiUrls.getOrganisationProfileImageSuggestionUrl(id) : '';
    },
    getTemporaryOrganisationProfileImageUrl: (id: string) => {
        return apiUrls.getTemporaryOrganisationProfileImage(id);
    },
    getWorkerTemporaryOrganisationImage: (id: string) => {
        return apiUrls.getWorkerTemporaryOrganisationImage(id);
    },
    updateOrganisationProfileImage: (image: File) => {
        const updatingToastId = Notifications.actionCreators.display(
            ToastType.INFO,
            'Uploading organization profile image, please wait...',
            { autoClose: false }
        );
        return afetch
            .uploadFile(
                apiUrls.updateOrganisationImage(),
                image,
                'Failed to upload organization profile image.'
            )
            .catch((e) => {
                Notifications.actionCreators.display(
                    ToastType.ERROR,
                    'Organization profile image failed to upload. Is it too big?'
                );
            })
            .then((r) => {
                Notifications.actionCreators.dismiss(updatingToastId);
                Notifications.actionCreators.display(
                    ToastType.SUCCESS,
                    'Organization profile image updated successfully.'
                );
            });
    },
    updateTemporaryOrganisationImage: (id: string, image: File) => {
        return afetch.uploadFile(
            apiUrls.updateTemporaryOrganisationImage(id),
            image,
            'Failed to upload organization profile image.'
        );
    },
    updateWorkerTemporaryOrganisationImage: (id: string, image: File) => {
        return afetch.uploadFile(
            apiUrls.updateTemporaryOrganisationImage(id),
            image,
            'Failed to upload image.'
        );
    }
};

export default apiCalls;
