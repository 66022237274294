/* eslint-disable */
/// <reference path="../../../node_modules/@types/googlemaps/index.d.ts" />

import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { AsyncOverlay } from '../ui-components/AsyncOverlay';
import IpInfo from '../../api/ipinfo/IpInfo';
import { IpInfoResponseV2 } from '../../api/ipinfo/ResponseTypes';
import Analytics from '../../services/analytics.service';
import LocationMap from '../ui-components/LocationMap';
import Device, { Resolutions } from '../../css/device';
import QuestionCircle from '../ui-components/QuestionCircle';
import SimpleTooltip from '../ui-components/SimpleTooltip';
import InternalTracker from 'src/InternalTracker';
import Utilities from 'src/Utilities';

const INITIAL_STATE = {
    apiLoaded: false,
    mapLoaded: false,
    address: '',
    place: null,
    stage: 'picker' as ('picker' | 'confirm'),
    locationFriendlyName: '',
    locationFriendlyAddress: ''
}

interface Props {
    allowFriendlyName?: boolean;
    show: boolean;
    onHide: () => void;
    onChange: (
        placeId: google.maps.places.PlaceResult['place_id'],
        placeName: google.maps.places.PlaceResult['name'],
        addressComponents: google.maps.places.PlaceResult['address_components'],
        locationFriendlyName?: string,
        locationFriendlyAddress?: string
    ) => void;
    placeId: string | null | undefined;
}

interface State {
    apiLoaded: boolean;
    mapLoaded: boolean;
    address: string;
    place: google.maps.places.PlaceResult | null;
    stage: 'picker' | 'confirm';
    locationFriendlyName: string;
    locationFriendlyAddress: string;
}

const PlaceFields = [
    'address_components',
    'geometry',
    'icon',
    'name',
    'place_id'
];

export default class LocationPicker extends React.Component<Props, State> {
    mapElement: React.RefObject<HTMLDivElement>;
    infoElement: React.RefObject<HTMLDivElement>;
    inputElement: React.RefObject<HTMLInputElement>;

    constructor(props) {
        super(props);

        this.mapElement = React.createRef();
        this.infoElement = React.createRef();
        this.inputElement = React.createRef();

        this.state = {
            ...INITIAL_STATE
        }
    }

    componentDidMount() {
        if (typeof google == 'undefined' || !google) {
            const googleApiScript = document.createElement('script');
            googleApiScript.async = true;
            googleApiScript.defer = true;
            googleApiScript.src =
                'https://maps.googleapis.com/maps/api/js?libraries=places&key=AIzaSyB76e89fMUB7oVc1xHygDEzKw5Lt-O30M8';
            googleApiScript.onload = () => {
                this.setState({
                    apiLoaded: true
                });
            };
            document.body.appendChild(googleApiScript);
        } else {
            this.setState({
                apiLoaded: true
            });
        }
    }

    handleEnter = () => {
        this.setState({
            mapLoaded: false,
            place: null
        });

        IpInfo.get()
            .then((info) => {
                this.initialiseMap(info);
            })
            .catch((e) => {
                //console.log('Location error: ', e);
                this.initialiseMap(null);
            });

        Analytics.trackEvent('location-picker-opened');
        toast.info('Adblocks may interfere with this location selector.');
    };

    initialiseMap = (info: IpInfoResponseV2 | null) => {
        // Get DOM element
        const mapElement = this.mapElement.current!;
        const infoElement = this.infoElement.current!;
        const inputElement = this.inputElement.current!;

        // Create map
        const map = new google.maps.Map(mapElement, {
            center: {
                lat: info ? info.lat : 0,
                lng: info ? info.lon : 0
            },
            zoom: 11,
            fullscreenControl: false,
            rotateControl: false,
            signInControl: false,
            streetViewControl: false,
            mapTypeControl: false
        });

        // Initialise autocomplete on the search input
        const autocomplete = new google.maps.places.Autocomplete(inputElement);
        // Specify fields to return in lookup
        autocomplete['setFields'](PlaceFields);
        // Restrict autocomplete to their current country
        if (info) {
            // autocomplete.setComponentRestrictions({
            //     country: info.country
            // });
        }

        // Listen for Enter key and select first result
        this.setEnterListener();

        // Create the info window for selected address
        const infoWindow = new google.maps.InfoWindow();
        infoWindow.setContent(infoElement);

        // Create map marker, default unselected
        const marker = new google.maps.Marker({
            map: map,
            anchorPoint: new google.maps.Point(0, -29),
            position: new google.maps.LatLng(0, 0),
            visible: false
        });

        const placeChanged = (place: google.maps.places.PlaceResult) => {
            const element = document.querySelector("input[placeholder='Enter a location...']") as HTMLInputElement;
            // element ? element.value : place.name;

            // Hide current selection
            infoWindow.close();
            marker.setVisible(false);

            // Navigate map to chosen location
            if (place.geometry.viewport) {
                map.fitBounds(place.geometry.viewport);
            } else {
                map.setCenter(place.geometry.location);
                map.setZoom(17);
            }

            // Select location
            marker.setPosition(place.geometry.location);
            marker.setVisible(true);

            // Open info window at location
            infoWindow.open(map, marker);

            let gmObj = Utilities.formatGoogleMapsObj(place);
            let friendlyAddress = 
                (place.name ? (place.name + ", ") : "") +
                (gmObj.streetHouse ? (gmObj.streetHouse + ", ") : "") +
                (gmObj.postcode ? (gmObj.postcode + ", ") : "") +
                (gmObj.city ? (gmObj.city + ", ") : "") +
                (gmObj.country ? (gmObj.country + ", ") : "")
            friendlyAddress = friendlyAddress.substring(0, friendlyAddress.length - 2);

            // Add selected place to state
            this.setState({
                place,
                locationFriendlyName: "", // place.name,
                locationFriendlyAddress: friendlyAddress,
                stage: 'confirm'
            });

            Analytics.trackEvent('location-picker-place-changed');
        };

        // Listen for place selected
        autocomplete.addListener('place_changed', () => {
            // Get selected place
            const place = autocomplete.getPlace();
            if (!place.geometry) {
                toast.warn('That location cannot be found'); // Warn if incorrect
                return;
            }

            // Trigger place change updates
            placeChanged(place);
        });

        // If the company already has a placeId set
        if (this.props.placeId && this.props.placeId.length) {
            // Get details from Google place service
            const service = new google.maps.places.PlacesService(map);
            service.getDetails(
                {
                    placeId: this.props.placeId,
                    fields: PlaceFields
                },
                (place) => {
                    // Trigger place change updates
                    placeChanged(place);

                    // Set loaded
                    this.setState({
                        mapLoaded: true
                    });
                }
            );
        } else {
            // Set loaded
            this.setState({
                mapLoaded: true
            });
        }
    };

    handleClose = () => {
        this.props.onHide();

        Analytics.trackEvent('location-picker-closed');
    };

    handleConfirm = () => {
        InternalTracker.trackEvent('Location Selected', {
            id: this.state.place!.place_id,
        })

        this.props.onChange(
            this.state.place!.place_id,
            this.state.locationFriendlyName,
            this.state.place!.address_components,
            this.state.locationFriendlyName,
            this.state.locationFriendlyAddress
        );

        this.setState({
            stage: 'picker'
        })

        this.handleClose();

        Analytics.trackEvent('location-picker-confirmed');
    };

    get placeAddress() {
        let address = '';

        if (this.state.place && this.state.place.address_components) {
            const p = this.state.place;
            address = [
                (p.address_components[0] &&
                    p.address_components[0].short_name) ||
                    '',
                (p.address_components[1] &&
                    p.address_components[1].short_name) ||
                    '',
                (p.address_components[2] &&
                    p.address_components[2].short_name) ||
                    ''
            ].join(' ');
        }

        return address;
    }

    setEnterListener = () => {
        const input = this.inputElement.current! as any;

        // Select first result when hitten enter
        // https://stackoverflow.com/questions/7865446/google-maps-places-api-v3-autocomplete-select-first-option-on-enter#answer-49620828

        /* Store original event listener */
        const _addEventListener = input.addEventListener;

        const addEventListenerWrapper = (type, listener) => {
            if (type === 'keydown') {
                /* Store existing listener function */
                const _listener = listener;
                listener = (event) => {
                    /* Simulate a 'down arrow' keypress if no address has been selected */
                    const suggestionSelected = document.getElementsByClassName(
                        'pac-item-selected'
                    ).length;
                    if (event.key === 'Enter' && !suggestionSelected) {
                        const e = new KeyboardEvent('keydown', {
                            key: 'ArrowDown',
                            code: 'ArrowDown',
                            keyCode: 40
                        } as any);
                        _listener.apply(input, [e]);
                    }
                    _listener.apply(input, [event]);
                };
            }
            _addEventListener.apply(input, [type, listener]);
        };

        input.addEventListener = addEventListenerWrapper;
        input.attachEvent = addEventListenerWrapper;
    };

    renderPickerStage() {
        return (
            <div>
                <h4>Search for a location</h4>
                <div>
                    <Search
                        ref={this.inputElement}
                        type="text"
                        autoFocus
                        placeholder="Enter a location..."
                        className="form-control"
                    />
                </div>
                <Map ref={this.mapElement} />
                <div ref={this.infoElement}>
                    {this.state.place &&
                        this.state.apiLoaded &&
                        this.state.mapLoaded && (
                            <div>
                                <div className="layout horizontal center">
                                    <img
                                        src={this.state.place.icon}
                                        width="32"
                                        height="32"
                                        id="place-icon"
                                    />
                                    <span
                                        id="place-name"
                                        className="m-l"
                                        style={{ fontSize: '16px' }}
                                    >
                                        {this.state.place.name}
                                    </span>
                                </div>
                                <div
                                    id="place-address"
                                    style={{
                                        fontSize: '12px',
                                        marginLeft: '47px'
                                    }}
                                >
                                    {/* {this.placeAddress} */}
                                    {this.state.locationFriendlyName}
                                    ({this.state.locationFriendlyAddress})
                                </div>
                            </div>
                        )}
                </div>
                <AsyncOverlay
                    show={!this.state.apiLoaded || !this.state.mapLoaded}
                    text="Loading..."
                />
            </div>
        );
    }

    renderConfirmStage() {
        return (
            <div style={{ overflow: 'hidden' }}>
                <h4>Confirm Location</h4>
                <button onClick={() => {
                    this.setState({
                        stage: 'picker'
                    }, () => {
                        this.handleEnter()
                    })
                }}>Select another Location</button>
                <div>
                    <label htmlFor="locationFriendlyName">
                        Extra Information{' '}
                        <SimpleTooltip
                            id="friendly-name"
                            placement="right"
                            text="Provide extra details, so contacts will know where to go exactly"
                        >
                            <QuestionCircle />
                        </SimpleTooltip>
                    </label>
                    <input
                        id="locationFriendlyName"
                        className="form-control"
                        value={this.state.locationFriendlyName}
                        placeholder="E.g. Warehouse 3, Door 8, Floor 4"
                        onBlur={() => {
                            InternalTracker.trackEvent('Extra Location Details')
                        }}
                        onChange={(e) => {
                            this.setState({
                                locationFriendlyName: e.target.value
                            })
                        }}
                    />
                    <label htmlFor="locationFriendlyAddress">
                        Location Address{' '}
                        <SimpleTooltip
                            id="friendly-name"
                            placement="right"
                            text="Amend the address if it's not exactly right"
                        >
                            <QuestionCircle />
                        </SimpleTooltip>
                    </label>
                    <input
                        id="locationFriendlyAddress"
                        className="form-control"
                        value={this.state.locationFriendlyAddress}
                        onChange={(e) =>
                            this.setState({
                                locationFriendlyAddress: e.target.value
                            })
                        }
                    />
                </div>
                <div
                    className="layout vertical"
                    style={{
                        height: Device.isSmallerThan(Resolutions.MobileMedium)
                            ? '120px'
                            : '200px',
                        margin: '20px -20px'
                    }}
                >
                    <LocationMap placeId={this.state.place!.place_id} />
                </div>
            </div>
        );
    }

    render() {
        return (
            <Modal
                show={this.props.show}
                onHide={this.handleClose}
                onEnter={this.handleEnter}
            >
                <Modal.Body>
                    {this.state.stage === 'picker' && this.renderPickerStage()}
                    {this.state.stage === 'confirm' &&
                        this.renderConfirmStage()}
                </Modal.Body>
                <Modal.Footer className="horizontal layout end-justified">
                    <Button onClick={this.handleClose} className="m-r-xs">
                        Cancel
                    </Button>
                    {this.state.stage === 'picker' &&
                    this.props.allowFriendlyName ? (
                        <Button
                            onClick={() => {
                                this.setState({ stage: 'confirm' });
                            }}
                            disabled={!this.state.place}
                            bsStyle="primary"
                        >
                            Next
                        </Button>
                    ) : (
                        <Button
                            onClick={this.handleConfirm}
                            disabled={
                                !this.state.locationFriendlyAddress /* !this.state.place ||
                                (this.props.allowFriendlyName &&
                                    !this.state.locationFriendlyName)
                            */}
                            bsStyle="primary"
                        >
                            Confirm
                        </Button>
                    )}
                </Modal.Footer>
            </Modal>
        );
    }
}

const Search = styled.input`
    width: 100%;
    margin: 20px 0 5px 0;
`;

const Map = styled.div`
    width: 100%;
    height: 320px;

    @media (max-height: 550px) {
        height: calc(100vh - 210px);
    }
`;
