/* eslint-disable */
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import { Image } from 'react-bootstrap';
import ProfileAPI from '../../api/profile/Profile';

import { simpleSearch } from '../../constants';
import * as ContactActionCreators from '../../store/contacts/ActionCreators';
import { ApplicationState } from '../../store';
import { Spinner } from '../ui-components/Spinner';
import styled from 'styled-components';
import InternalTracker from '../../InternalTracker';
import ContactsApi from '../../api/contacts/Contacts';

export type ContactListProps = ConnectedProps<typeof connector> & {
    onClose?: () => void
};

interface State {
    filterValue: string;
    loaded: boolean;
    listEveryone: {
        headline: string;
        firstName: string
        lastName: string
        userId: string
    }[] | null;
}

const DEFAULT_LIST_NAME = 'EVERYONE';

export class ContactListModal extends React.Component<ContactListProps, State> {
    constructor(props) {
        super(props);

        this.state = {
            filterValue: '',
            listEveryone: null,
            loaded: false
        };
    }

    addContactToList = (contactId) => {
        this.props.addContactToList(this.props.listMgtState.selected.id, contactId);
        InternalTracker.trackEvent("", {
            category: 'Lists',
            action: 'Contact Added to List'
        });
    };

    removeContactFromList = (contactId) => {
        this.props.removeContactFromList(this.props.listMgtState.selected.id, contactId);
        InternalTracker.trackEvent("", {
            category: 'Lists',
            action: 'Contact Removed from List'
        });
    };

    async loadEveryoneInTeam() {
        let list = await ContactsApi.getContactsInGroup(0);
        this.setState({
            listEveryone: list.members,
            loaded: true
        });
    }

    componentDidMount() {
        this.loadEveryoneInTeam();
    }

    /**
     * Renders the list
     */
    render() {

        const filteredContacts = this.state.listEveryone ? 
            simpleSearch(
                this.state.filterValue,
                this.state.listEveryone,
                ['firstName', 'lastName']
            )
            : null;

        const alreadyAddedIds = this.props.contactMgtState.contacts.map(item => item.id);

        return (
            <Wrapper>

                <Modal
                    show={true}
                    className="whats-new"
                    onHide={() => {
                        if (this.props.onClose)
                            this.props.onClose();
                    }}
                >
                    <Modal.Body>

                        <Spinner
                            text="Loading Contacts"
                            hide={this.state.loaded}
                        />
                        {this.state.loaded && (
                            <>
                                <h2
                                    style={{
                                        fontWeight: 700,
                                        margin: "0px 0px 12px 0",
                                        fontSize: '1.6em'
                                    }}
                                >
                                    Add contacts to {this.props.listMgtState.selected.name}
                                </h2>
                                <input
                                    placeholder="Search..."
                                    value={this.state.filterValue}
                                    onChange={(ev) =>
                                        this.setState({
                                            filterValue: ev.target.value
                                        })
                                    }
                                    style={{
                                        width: '100%',
                                        background: 'whitesmoke',
                                        padding: '12px 16px',
                                        borderRadius: 8,
                                        border: '1px solid #d4d4d4',
                                        marginBottom: 20
                                    }}
                                />
                                { filteredContacts &&
                                    <div style={{
                                        maxHeight: 290,
                                        overflow: 'auto'
                                    }}>
                                        <table 
                                            className="table table-striped"
                                            style={{
                                                marginBottom: 0
                                            }}
                                        >
                                            <tbody>
                                                {filteredContacts.filter(c => this.props.listMgtState.lists.find(l => l.id === this.props.listMgtState.selected.id)?.members?.map(m => m.userId).indexOf(c.userId) === -1).map((contact) => (
                                                    <tr key={contact.userId} data-name={contact.firstName + " " + contact.lastName} className="team-group-worker-item">
                                                        <td className="align-middle col-md-1" style={{ border: 0 }}>
                                                            <Image
                                                                src={
                                                                    ProfileAPI.getProfileImageUrl(contact.userId)
                                                                }
                                                                circle
                                                                alt={contact.firstName + " " + contact.lastName}
                                                                style={{
                                                                    height: '48px',
                                                                    width: '48px'
                                                                }}
                                                            />
                                                        </td>
                                                        <td className="align-middle col-md-8" style={{ border: 0 }}>
                                                            {contact.firstName + " " + contact.lastName}
                                                            <span>{contact.headline}</span>
                                                        </td>
                                                        <td className="align-right col-md-3" style={{ border: 0, textAlign: 'right', verticalAlign: 'middle' }}>
                                                            { (alreadyAddedIds.indexOf(contact.userId) === -1) ?
                                                                <Button
                                                                    onClick={(e) => {
                                                                        this.addContactToList(contact.userId)
                                                                    }}
                                                                    className="add-btn"
                                                                >
                                                                    <i className="fas fa-user-plus" style={{ marginRight: 6 }}></i>
                                                                    Add
                                                                </Button>
                                                                :
                                                                <Button
                                                                    onClick={(e) => {
                                                                        this.removeContactFromList(contact.userId)
                                                                    }}
                                                                    className="remove-btn"
                                                                >
                                                                    <i className="fa fa-times" style={{ marginRight: 6 }} />
                                                                    Remove
                                                                </Button>
                                                            }
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>   
                                }
                            </>
                        )}

                    </Modal.Body>
                    <Modal.Footer>
                        <Button 
                            onClick={() => {
                                if (this.props.onClose)
                                    this.props.onClose();
                            }}
                            id="finish-team-group-worker-editing-btn"
                        >
                            Finish Editing
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Wrapper>
        );
    }
}

const Wrapper = styled.div`

`

const connector = connect(
    (state: ApplicationState) => state.contactManagement,
    ContactActionCreators.actionCreators
);

export default connector(ContactListModal);
