import React from 'react';

import AuthFlowButton from '../../components/ui-components/AuthFlowButton';
import { RegularExpressions, KeyCodes } from '../../constants';
import ProfileApi from '../../api/profile/Profile';
import Analytics from '../../services/analytics.service';

interface State {
    email: string;
    submitted: boolean;
    loading: boolean;
}

export default class PasswordResetRequest extends React.Component<{}, State> {
    state = {
        email: '',
        submitted: false,
        loading: false
    } as State;

    getTrimmedEmail = () => this.state.email.trim();

    handleConfirm = () => {
        this.setState({
            loading: true
        });

        ProfileApi.requestPasswordReset(this.getTrimmedEmail()).then(() => {
            this.setState({
                loading: false,
                submitted: true
            });

            Analytics.trackEvent('password-reset-requested');
        });
    };

    handleDetectReturnKey = (ev: React.KeyboardEvent) => {
        if (this.isValid && ev.keyCode == KeyCodes.ENTER) {
            this.handleConfirm();
        }
    };

    get isValid() {
        return RegularExpressions.emailAddress.test(this.getTrimmedEmail());
    }

    render() {
        if (this.state.submitted) {
            return this.renderSubmitted();
        } else {
            return this.renderNotSubmitted();
        }
    }

    renderNotSubmitted() {
        return (
            <div>
                <h3 style={{ textAlign: 'center', marginBottom: '20px' }}>
                    Password reset
                </h3>
                <label htmlFor="email" style={{ fontWeight: 'normal' }}>
                    Please enter the email address for your updatedge account
                </label>
                <input
                    id="email"
                    type="text"
                    autoFocus
                    className="form-control"
                    placeholder="Email address..."
                    value={this.state.email}
                    onChange={(ev) => this.setState({ email: ev.target.value })}
                    onKeyUp={this.handleDetectReturnKey}
                    style={{ margin: '20px 0' }}
                />
                <AuthFlowButton
                    onClick={this.handleConfirm}
                    disabled={!this.state.email || !this.isValid}
                >
                    Submit
                </AuthFlowButton>
            </div>
        );
    }

    renderSubmitted() {
        return (
            <div>
                <h3 style={{ textAlign: 'center' }}>Check your email!</h3>
                <div style={{ marginTop: '25px' }}>
                    We have sent you an email with a link to reset your
                    password.
                </div>
            </div>
        );
    }
}
