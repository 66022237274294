/* eslint-disable */
import afetch from '../AuthenticatedFetch';
import AppConfig from '../../components/config/Config';
import { PagedResponseDto, SingleResponseDto } from '../BaseResponseTypes';
import * as ResponseTypes from './ResponseTypes';
import { ContactInviteDto } from './RequestTypes';
import { EphemeralAccessTokenStorageKey } from '../../components/layout/EphemeralAccessLayout';
import { Contact } from 'src/store/contacts/Models';

// API urls
const apiUrls = {
    getContact(id: string) {
        if (window.location.pathname.startsWith("/external/")) {
            const token = localStorage.getItem(EphemeralAccessTokenStorageKey);
            return `${AppConfig.Settings.api.baseUri}/external/contact/${id}?token=${token}`;
        } else {
            return `${AppConfig.Settings.api.baseUri}/publicapi/contact/${id}`;
        }
    },
    getContactByContactId(id: string) {
        return `${AppConfig.Settings.api.externalUri}/contact/${id}`;
    },
    getAllContacts(
        relationship: ResponseTypes.RelationshipToUser,
        perPage: number,
        currentPage: number
    ) {
        return `${AppConfig.Settings.api.baseUri}/publicapi/contacts?rel=${relationship}&pageSize=${perPage}&page=${currentPage}`;
    },
    getAllContactsNotInGroup(
        listId: number,
        perPage: number,
        currentPage: number
    ) {
        return `${AppConfig.Settings.api.baseUri}/api/list/${listId}/potentialmembers?perPage=${perPage}&currentPage=${currentPage}`;
    },
    getGroups() {
        return `${AppConfig.Settings.api.externalUri}/workers/groups?loadMembers=true`;
    },
    getContactsInGroup(groupId: number) {
        return `${AppConfig.Settings.api.externalUri}/workers/group/${groupId === 0 ? "everyone" : groupId}?withMembers=true`;
    },
    createNewGroup() {
        return `${AppConfig.Settings.api.externalUri}/workers/group`;
    },
    addContactToGroup(groupId: number) {
        return `${AppConfig.Settings.api.externalUri}/workers/group/${groupId}/member`;
    },
    addGroupToTeam(groupId: number) {
        return `${AppConfig.Settings.api.externalUri}/workers/group/${groupId}/teams`;
    },
    addGroupToOrganisation(groupId: number) {
        return `${AppConfig.Settings.api.externalUri}/workers/group/${groupId}/organisations`;
    },
    removeGroupFromTeam(groupId: number) {
        return `${AppConfig.Settings.api.externalUri}/workers/group/${groupId}/teams`;
    },
    removeGroupFromOrganisation(groupId: number) {
        return `${AppConfig.Settings.api.externalUri}/workers/group/${groupId}/organisations`;
    },
    removeGroup(id: number) {
        return `${AppConfig.Settings.api.externalUri}/workers/group/${id}?force=true`;
    },
    removeContactFromGroup(groupId: number) {
        return `${AppConfig.Settings.api.externalUri}/workers/group/${groupId}/member`;
    },
    sendInvitation(fromUserId: string, toWorkerEmail?: string, toWorkerId?: string) {
        return `${AppConfig.Settings.api.externalUri}/invite/worker` + (toWorkerEmail ? `?toWorkerEmail=${toWorkerEmail}` : `?toWorkerId=${toWorkerId}`);
    },
    getAll() {
        return `${AppConfig.Settings.api.externalUri}/contacts/org`;
    },
    searchWorkerContacts() {
        return `${AppConfig.Settings.api.externalUri}/contact/search/portal`;
    },
    hideContact(contactId) {
        return `${AppConfig.Settings.api.externalUri}/contact/${contactId}/hide`;
    },
    unhideContact(contactId) {
        return `${AppConfig.Settings.api.externalUri}/contact/${contactId}/show`;
    }
};

const apiCalls = {
    getAll(): Promise<Contact[]> {
    return afetch
        .request<null, Contact[]>(
            apiUrls.getAll(),
            'GET',
            null,
            'Failed to retrieve contacts'
        )
        .then((response) => {
            // @ts-ignore
            return response.data;
        })
    },

    searchWorkerContacts(proximityInMeters, sectorId: number, subsectorKeyword: string, hidden: boolean = false): Promise<ResponseTypes.PortalContactSearchDto> {
        return afetch
            .request<{
                proximityMeters: number,
                sectorId: number,
                subsectorId: number,
                subsectorKeyword: string,
                hidden: boolean
            }, ResponseTypes.PortalContactSearchDto>(
                apiUrls.searchWorkerContacts(),
                'POST',
                {
                    proximityMeters: proximityInMeters,
                    sectorId: sectorId || 0,
                    subsectorId: 0,
                    subsectorKeyword: subsectorKeyword || "",
                    hidden: hidden
                },
                'Failed to retrieve contact'
            )
            .then((response) => {
                return response;
            });
    },
    getContactByContactId(id: string): Promise<ResponseTypes.ContactDto> {
        return afetch
            .request<null, SingleResponseDto<ResponseTypes.ContactDto>>(
                apiUrls.getContactByContactId(id),
                'GET',
                null,
                'Failed to retrieve contact'
            )
            .then((response) => {
                return response.results;
            });
    },
    getContact(id: string): Promise<ResponseTypes.ContactDto> {
        return afetch
            .request<null, SingleResponseDto<ResponseTypes.ContactDto>>(
                apiUrls.getContact(id),
                'GET',
                null,
                'Failed to retrieve contact'
            )
            .then((response) => {
                return response.results;
            });
    },
    getContacts(
        relationship: ResponseTypes.RelationshipToUser,
        perPage: number,
        currentPage: number
    ) {
        return afetch
            .request<null, PagedResponseDto<ResponseTypes.ContactDto>>(
                apiUrls.getAllContacts(relationship, perPage, currentPage),
                'GET',
                null,
                `Failed to retrieve your ${relationship} contacts`
            )
            .then((contacts) => {
                return contacts;
            });
    },
    hideContact: (id: string) => {
        return afetch.request<null>(
            apiUrls.hideContact(id),
            'PUT',
            null,
            'Failed to hide contact'
        );
    },
    unhideContact: (id: string) => {
        return afetch.request<null>(
            apiUrls.unhideContact(id),
            'PUT',
            null,
            'Failed to unhide contact'
        );
    },
    /**
     * Retrieves all contacts sharing their availability with this user.
     * @listId - Id of the list to retrieve contacts from.
     * @perPage - Number of contacts to return per page.
     * @currentPage - Current page number
     */
    getContactsNotInGroup: (
        listId: number,
        perPage: number,
        currentPage: number
    ) => {
        return afetch
            .request<null, ResponseTypes.PotentialGroupContactResponseDto[]>(
                apiUrls.getAllContactsNotInGroup(listId, perPage, currentPage),
                'GET',
                null,
                'Unable to retrieve contacts'
            )
            .then((contacts) => {
                return contacts;
            });
    },
    getGroups: async () => {
        return afetch.request<
            null,
            ResponseTypes.ContactListDto[]
        >(apiUrls.getGroups(), 'GET', null, 'Unable to retrieve lists');
    },
    getContactsInGroup: async (id: number) => {
        const contacts = await afetch.request<
            null,
            ResponseTypes.SenderDto
        >(
            apiUrls.getContactsInGroup(id),
            'GET',
            null,
            'Unable to retrieve contacts for group'
        );

        return contacts;
    },
    createNewGroup: (name: string) => {
        return afetch.request<{ name: string }, number>(
            apiUrls.createNewGroup(),
            'POST',
            { name: name },
            'Unable to create new group'
        );
    },
    addContactToGroup: (groupId: number, userId: string) => {
        return afetch.request<{ userIds: string[] }, number>(
            apiUrls.addContactToGroup(groupId),
            'POST',
            { userIds: [userId] },
            'Unable to add contact to group'
        );
    },
    addGroupToTeam: (groupId: number, teamId: number) => {
        return afetch.request<{ teamIds: number[] }, number>(
            apiUrls.addGroupToTeam(groupId),
            'POST',
            { teamIds: [teamId] },
            'Unable to add group to team'
        );
    },
    addGroupToOrganisation: (groupId: number, orgId: string) => {
        return afetch.request<{ organisationIds: string[] }, number>(
            apiUrls.addGroupToOrganisation(groupId),
            'POST',
            { organisationIds: [orgId] },
            'Unable to add group to organization'
        );
    },
    removeGroupFromTeam: (groupId: number, teamId: number) => {
        return afetch.request<{ teamIds: number[] }, number>(
            apiUrls.removeGroupFromTeam(groupId),
            'DELETE',
            { teamIds: [teamId] },
            'Unable to remove group from team'
        );
    },
    removeGroupFromOrganisation: (groupId: number, orgId: string) => {
        return afetch.request<{ organisationIds: string[] }, number>(
            apiUrls.removeGroupFromOrganisation(groupId),
            'DELETE',
            { organisationIds: [orgId] },
            'Unable to remove group from organization'
        );
    },
    removeGroup: (id: number) => {
        return afetch.request<null>(
            apiUrls.removeGroup(id),
            'DELETE',
            null,
            'Unable to remove group'
        );
    },
    removeContactFromGroup: (groupId: number, userId: string) => {
        return afetch.request<{ userIds: string[] }, null>(
            apiUrls.removeContactFromGroup(groupId),
            'DELETE',
            { userIds: [userId] },
            'Unable to remove contact from group'
        );
    },
    /**
     * Sends a contact invitation
     * @param email The email address to which the invitation should be sent
     */
    inviteContact(fromUserId: string, toWorkerEmail?: string, toWorkerId?: string) {
        return afetch.request<null>(
            apiUrls.sendInvitation(fromUserId, toWorkerEmail, toWorkerId),
            'GET',
            null,
            'Failed to send invite',
            undefined,
            true,
            true
        );
    }
};

export default apiCalls;
