import React from 'react';
import styled from 'styled-components';
import { Table, Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { toast } from 'react-toastify';

import VerificationApi from '../../api/verification/Verification';
import CompanyApi from '../../api/company/Company';
import { UserVerificationResult } from '../../api/verification/ResponseTypes';
import { AsyncOverlay } from '../../components/ui-components/AsyncOverlay';
import { CompanyRoles } from '../../api/company/ResponseTypes';

interface State {
    loading: boolean;
    results: UserVerificationResult[];
    search: string;
    hovered: UserVerificationResult | undefined;
}

export default class VerifyUsers extends React.Component<{}, State> {
    state = {
        loading: true,
        results: [],
        search: '',
        hovered: undefined
    } as State;

    componentDidMount() {
        VerificationApi.unverified().then((results) => {
            this.setState({
                results,
                loading: false
            });
        });
    }

    render() {
        return (
            <Wrapper>
                <h3>Unverified Users</h3>
                <div className="layout horizontal end-justified">
                    <input
                        className="form-control m-b-xs"
                        placeholder="Search..."
                        value={this.state.search}
                        onChange={(ev) =>
                            this.setState({ search: ev.target.value })
                        }
                    />
                </div>
                <Table condensed striped responsive bordered>
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Email</th>
                            <th>Internal Name</th>
                            <th>External Name</th>
                            <th>Match</th>
                            <th>Name Verified</th>
                            <th>Internal Company</th>
                            <th>External Company</th>
                            <th>Match</th>
                            <th>Company Verified</th>
                            <th>Role</th>
                            <th />
                        </tr>
                    </thead>
                    <tbody>{this.renderResults()}</tbody>
                </Table>
                <AsyncOverlay show={this.state.loading} />
            </Wrapper>
        );
    }

    renderResults() {
        return this.state.results
            .filter(
                (r) =>
                    !this.state.search ||
                    this.match(r.internalName) ||
                    this.match(r.externalName) ||
                    this.match(r.email) ||
                    this.match(r.id)
            )
            .map((r) => {
                const popover = (
                    <Popover id="set-role">
                        <div
                            className="layout horizontal center-center"
                            style={{
                                minHeight: '50px',
                                cursor: 'pointer',
                                padding: '5px 20px'
                            }}
                            onClick={() =>
                                this.handleSetRole(r.email, CompanyRoles.Admin)
                            }
                        >
                            Set as Admin
                        </div>
                        <div
                            className="layout horizontal center-center"
                            style={{
                                minHeight: '50px',
                                cursor: 'pointer',
                                padding: '5px 20px'
                            }}
                            onClick={() =>
                                this.handleSetRole(r.email, CompanyRoles.Normal)
                            }
                        >
                            Set as Normal
                        </div>
                    </Popover>
                );

                return (
                    <tr
                        key={r.id}
                        className={this.state.hovered === r ? 'hovered' : ''}
                    >
                        <td>{r.id}</td>
                        <td>{r.email}</td>
                        <td>{r.internalName}</td>
                        <td>{r.externalName}</td>
                        <td
                            className={
                                r.nameMatch > 80
                                    ? 'success'
                                    : r.nameMatch
                                    ? 'warning'
                                    : 'danger'
                            }
                        >
                            {r.nameMatch}%
                        </td>
                        <td
                            style={{
                                fontWeight: r.nameVerified ? 'bold' : 'initial'
                            }}
                        >
                            {r.nameVerified ? 'Yes' : 'No'}
                        </td>
                        <td>{r.internalCompany}</td>
                        <td>{r.externalCompany}</td>
                        <td
                            className={
                                r.companyMatch > 80
                                    ? 'success'
                                    : r.companyMatch
                                    ? 'warning'
                                    : 'danger'
                            }
                        >
                            {r.companyMatch}%
                        </td>
                        <td
                            style={{
                                fontWeight: r.companyVerified
                                    ? 'bold'
                                    : 'initial'
                            }}
                        >
                            {r.companyVerified ? 'Yes' : 'No'}
                        </td>
                        <td>
                            <OverlayTrigger
                                trigger="click"
                                placement="left"
                                overlay={popover}
                                rootClose={true}
                            >
                                <span style={{ cursor: 'pointer' }}>
                                    {CompanyRoles[r.roleId]}
                                </span>
                            </OverlayTrigger>
                        </td>
                        <td>
                            {(!r.nameVerified || !r.companyVerified) && (
                                <Button
                                    bsStyle="primary"
                                    bsSize="xs"
                                    onMouseEnter={() =>
                                        this.setState({ hovered: r })
                                    }
                                    onMouseLeave={() =>
                                        this.setState({ hovered: undefined })
                                    }
                                    onClick={() => this.handleVerifyClick(r)}
                                >
                                    Verify
                                </Button>
                            )}
                        </td>
                    </tr>
                );
            });
    }

    match = (val: string) => {
        return (
            (val || '')
                .toLowerCase()
                .indexOf(this.state.search.toLowerCase()) >= 0
        );
    };

    handleVerifyClick = async (row: UserVerificationResult) => {
        this.setState({
            loading: true
        });

        await VerificationApi.verifyUser(row.id);

        this.setState({
            results: this.state.results.map((r) =>
                r.id === row.id
                    ? { ...r, nameVerified: true, companyVerified: true }
                    : r
            ),
            loading: false
        });

        toast.success(`Verified ${row.internalName}`);
    };

    handleSetRole = async (email: string, roleId: CompanyRoles) => {
        await CompanyApi.setRole(email, roleId);

        this.setState({
            results: this.state.results.map((r) =>
                r.email == email ? { ...r, roleId: roleId } : r
            )
        });
    };
}

const Wrapper = styled.div`
    > h3 {
        margin-bottom: 5px;
        font-weight: 600;
        text-align: center;
    }

    input {
        width: 200px;
    }

    tr.hovered {
        background: #c9d9ea !important;

        button {
            font-weight: bold;
        }
    }

    td {
        vertical-align: middle !important;

        &:last-child {
            width: 75px;
            text-align: center;

            button {
                background: #3f5872 !important;
                border: 0;
                outline: 0 !important;
            }
        }
    }

    td.success,
    td.warning,
    td.danger {
        color: #6a6c6f;
        text-align: center;
        vertical-align: middle;

        + td {
            text-align: center;
            vertical-align: middle;
        }
    }
`;
