import afetch from '../AuthenticatedFetch';
import AppConfig from '../../components/config/Config';
import {
    ContactResult,
    InviteResult,
    OutgoingInvitationResult,
    InvitationDetailResult
} from './ResponseTypes';

const apiUrls = {
    searchInternalContacts(search: string) {
        return `${AppConfig.Settings.api.baseUri}/publicapi/invitations/search/contacts/internal?search=${search}`;
    },
    searchExternalContacts(search: string) {
        return `${AppConfig.Settings.api.externalUri}/invite/search/contacts/external?search=${search}`;
    },
    inviteContacts() {
        return `${AppConfig.Settings.api.baseUri}/publicapi/invitations/contacts`;
    },
    getIncomingInvitations() {
        return `${AppConfig.Settings.api.baseUri}/publicapi/invitations/incoming`;
    },
    getOutgoingInvitations() {
        return `${AppConfig.Settings.api.baseUri}/publicapi/invitations/outgoing`;
    },
    acceptInvitation(id: string) {
        return `${AppConfig.Settings.api.baseUri}/publicapi/invitations/accept/${id}`;
    },
    rejectInvitation(id: string) {
        return `${AppConfig.Settings.api.baseUri}/publicapi/invitations/reject/${id}`;
    },
    cancelInvitation(id: string) {
        return `${AppConfig.Settings.api.baseUri}/publicapi/invitations/cancel/${id}`;
    },
    getInvitationDetail(id: string) {
        return `${AppConfig.Settings.api.baseUri}/publicapi/invitations/${id}`;
    }
};

const apiCalls = {
    searchInternalContacts: (search?: string) => {
        return afetch.request<null, ContactResult[]>(
            apiUrls.searchInternalContacts(search || ''),
            'POST',
            null,
            'Unable to search contacts'
        );
    },
    searchExternalContacts: (organisationIds?: string[], search?: string) => {
        return afetch.request<
            string[],
            { data: ContactResult[]; errors: Array<unknown> }
        >(
            apiUrls.searchExternalContacts(search || ''),
            'POST',
            organisationIds || []
        );
    },
    inviteContacts: (contacts: ContactResult[]) => {
        return afetch.request<ContactResult[]>(
            apiUrls.inviteContacts(),
            'POST',
            contacts,
            'Unable to invite contacts'
        );
    },
    getIncomingInvitations() {
        return afetch.request<null, InviteResult[]>(
            apiUrls.getIncomingInvitations(),
            'GET',
            null,
            'Unable to get invitations'
        );
    },
    getOutgoingInvitations() {
        return afetch.request<null, OutgoingInvitationResult[]>(
            apiUrls.getOutgoingInvitations(),
            'GET',
            null,
            'Unable to get invitations'
        );
    },
    acceptInvitation(id: string) {
        return afetch.request<null>(
            apiUrls.acceptInvitation(id),
            'GET',
            null,
            'Unable to accept invitation'
        );
    },
    rejectInvitation(id: string) {
        return afetch.request<null>(
            apiUrls.rejectInvitation(id),
            'GET',
            null,
            'Unable to reject invitation'
        );
    },
    cancelInvitation(id: string) {
        return afetch.request<null>(
            apiUrls.cancelInvitation(id),
            'GET',
            null,
            'Unable to cancel invitation'
        );
    },
    getInvitationDetail(id: string) {
        return afetch.request<null, InvitationDetailResult>(
            apiUrls.getInvitationDetail(id),
            'GET',
            null,
            'Unable to get the invitation'
        );
    }
};

export default apiCalls;
