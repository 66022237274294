/* eslint-disable */
import React from 'react';
import '../css/Files.css';
import FileApi from '../api/file/File';
import SettingApi from '../api/settings/Settings';
import ContactApi from '../api/contacts/Contacts';
import ProfileApi from '../api/profile/Profile';
import FullScreenLoader from 'src/components/ui-components/FullScreenLoader';
import Utilities from 'src/Utilities';
import { FileAccess, FileType, UserFileWithFilterType, UserFolderWithFilterType, UserFile, ServerConfig, Contact, UserFolder, ExplicitelySharedFile, ExplicitelySharedFolder, UserFileRequest, UserFileFolderRequest } from 'src/store/contacts/Models';
import { Modal, Button } from 'react-bootstrap';
import InlineSelect from 'src/components/ui-components/InlineSelect';
import { isValidUrl } from "is-youtube-url";
import { toast } from 'react-toastify';
import { ProfileImage } from 'src/components/ui-components/ProfileImage';
import DocumentViewer from './DocumentViewer';
import WorkerSelector from 'src/components/offers/WorkerSelector';
import { ContactAvailabilitySummaryModel } from 'src/store/Availability';
import Joyride from 'react-joyride';
import InternalTracker from 'src/InternalTracker';
import Highlighter from "react-highlight-words";
import { Setting } from 'src/api/settings/ResponseTypes';
import { getValue, setValue } from 'src/db/KeyValueCache';
import { CircularProgress } from '@mui/material';
const mime = require('mime-types');
const axios = require('axios');

type Props = {
    mode: "picker" | "show-selected" | "hidden" | undefined,
    noSelectedFilesText?: string,
    page?: string;
    onSelectedFilesUpdated: (fileIds: number[]) => void,
    onSelectedFoldersUpdated: (folderIds: number[]) => void,
    switchMode?: (mode: "picker" | "show-selected") => void,
    onClosed: () => void,
    disableFiles?: number[],
    addContentText?: string
};

const NoUserSelectedFilter = {
    userId: "",
    name: "All Users"
}

interface UserDetail {
    reported?: boolean;
    name: string;
    userId: string;
}

interface ShareUpdate {
    action: 'add' | 'remove',
    userId: string
  }

interface State {
    organisationId: null | string;
    userId: null | string;
    activeFilter: "sharedByCompany" | "sharedToCompany"
    files: UserFileWithFilterType[];
    folders: UserFolderWithFilterType[];
    explicitelySharedFiles: {
        fileId: number,
        userId: string
    }[];
    explicitelySharedFolders: {
        folderId: number,
        userId: string
    }[];
    loading: string | null;
    highlightFileId: string | null;
    fileToUpload: File | null;
    editedFileId: number | null;
    editedFolderId: number | null;
    fileCategorySuggestionsQuery: string;
    editedFile: UserFile | null,
    editedFolder: UserFolder | null,
    config: ServerConfig | null,
    userDetails: UserDetail[],
    openedFileUri?: string;
    openedFileName?: string;
    noAccessFileId?: number;
    openedFileId?: number | null;
    openedFileType: FileType | null;
    contacts: Contact[] | null;
    shareUpdates: ShareUpdate[];
    searchQuery: string;
    showFileActionsMobile: boolean;
    joyride: string;
    sharingUserIds: string[],
    orgSharingUserIds: string[],
    showContactsFoldersFilter: boolean;

    filterUserId: string;
    selectedFolderId: number | null;
    searchQueryUsers: string;
    selectedFiles: number[];
    selectedFolders: number[];
    collapsedUserIds: string[];
    fileRequest: UserFileRequest[];
    folderRequests: UserFileFolderRequest[];
    ownFileSuggestions: string[];
    stale: boolean;
}

export class Files extends React.Component<
    Props,
    State
> {
    state: State = {
        organisationId: null,
        userId: null,
        activeFilter: "sharedByCompany",
        files: [],
        folders: [],
        userDetails: [],
        loading: null,
        highlightFileId: null,
        fileToUpload: null,
        editedFile: null,
        editedFileId: null,
        editedFolder: null,
        editedFolderId: null,
        fileCategorySuggestionsQuery: "",
        config: null,
        openedFileUri: "",
        openedFileType: null,
        openedFileName: "",
        explicitelySharedFiles: [],
        explicitelySharedFolders: [],
        contacts: null,
        shareUpdates: [],
        searchQuery: "",
        selectedFolderId: null,
        filterUserId: "",
        showFileActionsMobile: false,
        joyride: "",
        sharingUserIds: [],
        orgSharingUserIds: [],
        showContactsFoldersFilter: false,
        searchQueryUsers: "",
        selectedFiles: [],
        selectedFolders: [],
        collapsedUserIds: localStorage.getItem("collapsedFileUserIds") ? JSON.parse(localStorage.getItem("collapsedFileUserIds") || "[]") : [],
        fileRequest: [],
        folderRequests: [],
        ownFileSuggestions: [],
        stale: false
    };

    componentDidMount() {
        let user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user") || "{}") : null;
        if (user) {
            this.loadFiles();
            this.loadContacts();
            this.setState({
                organisationId: user.organisationId || null,
                userId: user.id || null,
                config: user.config || null
            })
        } else {
            setTimeout(() => {
                this.componentDidMount();
            }, 500)
        }

        (window as any).onSignalRFileShared = (fileId: number) => {
            this.loadFiles(undefined, true);
            this.loadContacts();
        }

        const urlHash = window.location.href.split("files#")[1];
        if (urlHash) {
            this.loadFile(parseInt(urlHash))
        }
    }

    loadFile = (id?: number, attemptCount?: number) => {
        const fileMeta = this.state.files.find(f => f.id === id);
        console.log(fileMeta, id)
        if (!fileMeta) {
            setTimeout(() => {
                if (attemptCount && attemptCount > 15) {
                    return;
                }
                this.loadFile(id, attemptCount ? attemptCount + 1 : 1)
            }, 500);
            return;
        }

        if (
            ["docx", "doc", "jpg", "jpeg", "png", "pdf", "ppt", "pptx", "xls", "xlsx"].indexOf(fileMeta.fileFormat || "") !== -1 ||
            fileMeta.fileType === FileType.VideoLink || 
            fileMeta.fileType === FileType.Link
        ) {
            this.setState({
                openedFileUri: fileMeta.accessLink,
                openedFileName: fileMeta.fileName,
                openedFileId: fileMeta.id || 0,
                openedFileType: fileMeta.fileType || null
            })                                                                    
        } else {
            window.open(fileMeta.accessLink)
        }
    }

    toggleFileShare = (userId, folder) => {
        if (folder) {
            let newExplicitPrivateAccesses: ExplicitelySharedFolder[] = this.state.explicitelySharedFolders;
            let newShareUpdates = this.state.shareUpdates;
            let alreadySharing = newExplicitPrivateAccesses.find(epa => epa.folderId === this.state.editedFolderId && epa.userId === userId);
            newShareUpdates = newShareUpdates.filter(update => update.userId !== userId);

            InternalTracker.trackEvent((alreadySharing ? "Removed" : "Added") + " Folder Share", {
                userId: userId,
                folderId: this.state.editedFolderId
            })
    
            if (alreadySharing) {
                newExplicitPrivateAccesses = newExplicitPrivateAccesses.filter(epa => !((epa.fileId === this.state.editedFileId || epa.folderId === this.state.editedFolderId) && epa.userId === userId));
            } else {
                newExplicitPrivateAccesses.push({
                    userId: userId,
                    folderId: this.state.editedFolderId || 0
                })
            }
            newShareUpdates.push({
              userId: userId,
              action: alreadySharing ? 'remove' : 'add'
            })
            this.setState({
                explicitelySharedFolders: newExplicitPrivateAccesses,
                shareUpdates: newShareUpdates
            })
        } else {
            let newExplicitPrivateAccesses: ExplicitelySharedFile[] = this.state.explicitelySharedFiles;
            let newShareUpdates = this.state.shareUpdates;
            let alreadySharing = newExplicitPrivateAccesses.find(epa => epa.fileId === this.state.editedFileId && epa.userId === userId);
            newShareUpdates = newShareUpdates.filter(update => update.userId !== userId);

            InternalTracker.trackEvent((alreadySharing ? "Removed" : "Added") + " File Share", {
                userId: userId,
                file: this.state.editedFileId
            })
    
            if (alreadySharing) {
                newExplicitPrivateAccesses = newExplicitPrivateAccesses.filter(epa => !((epa.fileId === this.state.editedFileId || epa.folderId === this.state.editedFolderId) && epa.userId === userId));
            } else {
                newExplicitPrivateAccesses.push({
                    userId: userId,
                    fileId: this.state.editedFileId || 0
                })
            }
            newShareUpdates.push({
              userId: userId,
              action: alreadySharing ? 'remove' : 'add'
            })
    
            this.setState({
                explicitelySharedFiles: newExplicitPrivateAccesses,
                shareUpdates: newShareUpdates
            })
        }
    }

    fileMatchesFilter = (file: UserFileWithFilterType) => {
        if (file.accessType === "sharedToCompany" && !file.moderatorApproved) {
            return null;
        }

        if (file.accessType !== this.state.activeFilter || (this.state.searchQuery && file.fileName?.toLowerCase().indexOf(this.state.searchQuery) === -1)) {
            return null;
        }

        if (this.state.filterUserId && file.userId !== this.state.filterUserId) {
            return null;
        }

        if (this.state.selectedFolderId !== 0 && this.state.selectedFolderId !== null && file.folderId !== this.state.selectedFolderId) {
            return null;
        }

        if (this.state.selectedFolderId === 0 && file.folderId !== 0) {
            return null;
        }

        return true;
    }

    async loadContacts() {
        const contacts = await ContactApi.getAll().catch(e => {
            toast.error("Failed to load contacts")
        });

        if (contacts) {
            this.setState({
                contacts: contacts
            })
        }
    }

    async loadFiles(onLoad?: Function, silent?: boolean) {
        const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user") || "{}") : null;

        this.setState({
            loading: silent ? null : "Loading Files",
            activeFilter: silent ? this.state.activeFilter : "sharedByCompany",
            searchQuery: silent ? this.state.searchQuery : "",
            filterUserId: silent ? this.state.filterUserId : ""
        })
       
        const staleFiles = await getValue("files");
        if (staleFiles) {
            this.setState({
                ...staleFiles,
                stale: true
            })
        }

        const files = await FileApi.getAll().catch(e => {
            toast.error("Failed to load files")
        });

        if (files) {
            const ownFiles = files.ownFiles.filter(file => file.userId === this.state.userId).map(item => { item.accessType = "sharedByCompany"; return item }).concat(
                files.ownFiles.filter(file => file.userId !== this.state.userId).map(item => { item.accessType = "sharedByCompany"; return item })            )

            const allFiles = files.ownFiles.filter(file => file.userId === this.state.userId).map(item => { item.accessType = "sharedByCompany"; return item }).concat(
                files.ownFiles.filter(file => file.userId !== this.state.userId).map(item => { item.accessType = "sharedByCompany"; return item }),
                files.receivedFiles.filter(file => file.userId !== this.state.userId).map(item => { item.accessType = "sharedToCompany"; return item }),
            )

            const ownFolders = files.ownFolders.filter(folder => folder.userId === this.state.userId).map(item => { item.accessType = "sharedByCompany"; return item }).concat(
                files.ownFolders.filter(folder => folder.userId !== this.state.userId).map(item => { item.accessType = "sharedByCompany"; return item })
            )

            const allFolders = files.ownFolders.filter(folder => folder.userId === this.state.userId).map(item => { item.accessType = "sharedByCompany"; return item }).concat(
                files.ownFolders.filter(folder => folder.userId !== this.state.userId).map(item => { item.accessType = "sharedByCompany"; return item }),
                files.receivedFolders.filter(folder => folder.userId !== this.state.userId).map(item => { item.accessType = "sharedToCompany"; return item }),
            )

            let ownFileSuggestions = [...new Set(ownFiles.filter(file => file.fileCategoryName).map(file => file.fileCategoryName))] as string[];

            const doesntHaveOwnFiles = ownFiles.length === 0 && ownFolders.length === 0;

            const showOnboarding =
                !localStorage.getItem("filesJoyrideDismissed") && 
                !(user && user.settings.find((setting: any) => setting.settingId === 28 && setting.value === "true")) &&
                !(window as any).Cypress ? "files" : ""

            if (showOnboarding && !doesntHaveOwnFiles) {
                localStorage.setItem("filesJoyrideDismissed", "true");
                SettingApi.update(Setting.Files_HideGuide, "true") 
            }   

            const sharingUserIds = [...new Set(files.receivedFolders.map(item => item.userId || "").concat(files.receivedFiles.map(item => item.userId || "")))];
            const orgSharingUserIds = [...new Set(files.ownFolders.map(item => item.userId || "").concat(files.ownFiles.map(item => item.userId || "")))];

            this.setState({
                files: allFiles,
                folders: allFolders,
                sharingUserIds: sharingUserIds,
                orgSharingUserIds: orgSharingUserIds,
                explicitelySharedFiles: files.explicitelySharedFiles,
                explicitelySharedFolders: files.explicitelySharedFolders,
                userDetails: files.userDetails,
                loading: null,
                joyride: showOnboarding && doesntHaveOwnFiles ? "files" : "",
                fileRequest: files.fileRequests,
                folderRequests: files.folderRequests,
                ownFileSuggestions: ownFileSuggestions,
                stale: false
            }, () => {
                setValue("files", {
                    files: this.state.files,
                    folders: this.state.folders,
                    sharingUserIds: this.state.sharingUserIds,
                    orgSharingUserIds: this.state.orgSharingUserIds,
                    explicitelySharedFiles: this.state.explicitelySharedFiles,
                    explicitelySharedFolders: this.state.explicitelySharedFolders,
                    userDetails: this.state.userDetails,
                    fileRequest: this.state.fileRequest,
                    folderRequests: this.state.folderRequests,
                    ownFileSuggestions: this.state.ownFileSuggestions
                })
                if (onLoad) {
                    onLoad(this.state.files)
                }
            })
        } else {
            this.setState({
                loading: null
            })
        }
    }

    triggerFolderCollapse(userId) {
        InternalTracker.trackEvent("Folder Collapse");
        const collapsed = this.state.collapsedUserIds.indexOf(userId) !== -1;
        if (collapsed) {
            this.setState({
                collapsedUserIds: this.state.collapsedUserIds.filter(id => id !== userId)
            }, () => {
                localStorage.setItem("collapsedFileUserIds", JSON.stringify(this.state.collapsedUserIds))
            })
        } else {
            this.setState({
                collapsedUserIds: this.state.collapsedUserIds.concat([userId])
            }, () => {
                localStorage.setItem("collapsedFileUserIds", JSON.stringify(this.state.collapsedUserIds))
            })
        }
    }

    render() {

        let filters = [
            {
                id: "sharedByCompany",
                label: this.state.organisationId ? "My Organisation's Files" : "My Files"
            },
            {
                id: "sharedToCompany",
                label: "Files from Contacts"
            }
        ];

        const editedFile = this.state.editedFile;
        const editedFolder = this.state.editedFolder;
        let editedFileExplicitPrivateAccess = editedFile && editedFile.access === FileAccess.Private ? this.state.explicitelySharedFiles.filter(esf => esf.fileId === editedFile.id) : []
        let editedFolderExplicitPrivateAccess = editedFolder && editedFolder.access === FileAccess.Private ? this.state.explicitelySharedFolders.filter(esf => esf.folderId === editedFolder.id) : []
        let editedFilesFolderAccess: FileAccess | null = 
            editedFile && 
            editedFile.folderId && 
            this.state.folders && 
            this.state.folders.find(folder => folder.id === editedFile.folderId) ? 
                this.state.folders.find(folder => folder.id === editedFile.folderId)!.access : 
                null;

        let noOwnFiles = this.state.files.filter(f => f.accessType === "sharedByCompany").length === 0 && this.state.activeFilter === "sharedByCompany";
        let userIdList = this.state.activeFilter === "sharedByCompany" ? this.state.orgSharingUserIds : this.state.sharingUserIds;
        
        const DOM_CONTENT = 
            <div className='files-page' data-mode={this.props.mode || "page"} data-page={this.props.page}>
            { (
                this.state.files.filter(f => f.accessType === "sharedByCompany").length !== 0 || 
                this.state.folders.filter(f => f.accessType === "sharedByCompany").length !== 0
            ) && 
                <div className='file-actions'>
                    <button 
                        id="add-file-btn"
                        className={this.props.mode === "picker" ? "secondary" : ""}
                        style={ this.state.showFileActionsMobile ? { display: 'block' } : { } }
                        onClick={() => {
                            InternalTracker.trackEvent("File Adding Started");
                            let files: UserFile[] = [] // TODO FILES OF User user.files;
                            files.unshift()
                            this.setState({
                                editedFile: {
                                    id: 0,
                                    access: localStorage.getItem("lastFileAccessType") ? parseInt(localStorage.getItem("lastFileAccessType") || "0") : FileAccess.Public,
                                    displayOrder: 1,
                                    folderId: this.state.selectedFolderId || 0
                                },
                                showFileActionsMobile: false,
                                editedFileId: 0,
                                shareUpdates: []
                            })
                        }}
                    >
                        Add Content
                    </button>
                    <button 
                        id="add-folder-btn"
                        className={this.props.mode === "picker" ? "secondary" : ""}
                        style={ this.state.showFileActionsMobile ? { display: 'block' } : { } }
                        onClick={() => {
                            InternalTracker.trackEvent("Folder Adding Started");
                            let files: UserFile[] = []
                            files.unshift()
                            this.setState({
                                editedFolder: {
                                    id: 0,
                                    access: localStorage.getItem("lastFileAccessType") ? parseInt(localStorage.getItem("lastFileAccessType") || "0") : FileAccess.Public,
                                },
                                showFileActionsMobile: false,
                                editedFolderId: 0,
                                shareUpdates: []
                            })
                        }}
                    >
                        Create Folder
                    </button>
                    <button className='toggle' onClick={() => {
                        this.setState({
                            showFileActionsMobile: !this.state.showFileActionsMobile
                        })
                    }}>
                        <i className={ !this.state.showFileActionsMobile ? 'fas fa-plus' : 'fas fa-times' } />
                        <span>Add File/Folder</span>
                    </button>
                    { (this.props.mode === "picker") &&
                        <button 
                            id="add-save-selection-btn"
                            onClick={() => {
                                InternalTracker.trackEvent("File-Folder Selection Saved");
                                this.props.onClosed();
                            }}
                        >
                            <i className='fas fa-check' />
                            Save Selection ({this.state.selectedFiles.length + this.state.selectedFolders.length})
                        </button>
                    }
                    { (this.state.filterUserId !== '' || this.state.searchQueryUsers !== "" || this.state.searchQuery !== '' || this.state.selectedFolderId !== null) && this.props.mode === undefined &&
                        <button className='clear-filters-btn' onClick={() => {
                            InternalTracker.trackEvent("File Filters Cleared");
                            this.setState({
                                filterUserId: '',
                                searchQueryUsers: '',
                                searchQuery: '',
                                selectedFolderId: null
                            })
                        }}>
                            Clear Filters
                        </button>
                    }
                </div>
            }
            <div className='files-header'>
                <div className='categories'>
                    { filters.map(filter => {
                        return (
                            <div
                                className='category'
                                data-selected={this.state.activeFilter === filter.id}
                                onClick={() => {
                                    InternalTracker.trackEvent("File Filters Changed", {
                                        type: "category",
                                        value: filter.id
                                    });
                                    this.setState({
                                        // @ts-ignore
                                        activeFilter: filter.id,
                                        searchQueryUsers: '',
                                        filterUserId: '',
                                        selectedFolderId: null,
                                    })
                                }}
                            >
                                {filter.label}
                                <span className='badge'>
                                    {this.state.files.filter(item => item.accessType === filter.id && (!this.state.searchQuery || (this.state.searchQuery && item.fileName?.toLowerCase().indexOf(this.state.searchQuery) !== -1 ))).length}
                                </span>
                            </div>
                        )
                    }) }
                    <div className='category contacts-folders-filter-toggle-btn' onClick={() => {
                        this.setState({
                            showContactsFoldersFilter: true
                        })
                    }}>
                        Folders & Contacts
                        <i className="fas fa-chevron-down"></i>
                    </div>
                </div>
                <div className='filters'>
                    <input
                        placeholder="Search in Files"
                        value={this.state.searchQuery} 
                        onChange={({ target: { name, value } }) => {
                            this.setState({ searchQuery: value.toLowerCase() })
                            InternalTracker.trackEvent("File Filters Changed", {
                                type: "keywords",
                                value: value.toLowerCase()
                            });
                        }}
                    />
                    {/* { (this.props.mode === "picker") &&
                        <button id="add-file-btn" onClick={() => {
                            let files: UserFile[] = []
                            files.unshift()
                            this.setState({
                                editedFile: {
                                    id: 0,
                                    access: FileAccess.Public,
                                    displayOrder: 1
                                },
                                showFileActionsMobile: false,
                                editedFileId: 0,
                                shareUpdates: []
                            })
                        }}>
                            Add File/Link
                        </button>
                    } */}
                </div>
            </div>
            { (this.state.activeFilter === "sharedByCompany" && (this.state.fileRequest.length !== 0 || this.state.folderRequests.length !== 0)) &&
                <div className='requests'>
                    <h2>File & Folder Requests</h2>
                    <div className='requests-list'>
                        { this.state.folderRequests.map(request => {
                            return (
                                <div className='request'>
                                    <FileListItem 
                                        mode='request'
                                        showAccess={false}
                                        folder={true}
                                        hidePreview={true}
                                        file={{
                                            id: request.folderId,
                                            fileName: request.name,
                                        } as UserFileWithFilterType} 
                                        owner={{
                                            name: request.userName,
                                            userId: request.userId
                                        } as UserDetail} 
                                        onClick={() => {
                                            
                                        }}
                                        onAllowAccess={() => {
                                            this.setState({
                                                loading: "Sharing Folder"
                                            })
                                            InternalTracker.trackEvent("Folder Request Accepted", {
                                                folderId: request.folderId
                                            })
                                            FileApi
                                                .shareFolder(request.folderId || 0, request.userId)
                                                .then(() => {
                                                    this.setState({ loading: null })
                                                    toast.success("Folder Shared")
                                                    this.loadFiles()
                                                }).catch(err => {
                                                    this.setState({ loading: null })
                                                    toast.success("Error Sharing Folder")
                                                    this.loadFiles()
                                                })
                                        }}
                                        onRemoveAccess={() => {
                                            InternalTracker.trackEvent("Folder Request Rejected", {
                                                folderId: request.folderId
                                            })
                                            FileApi
                                                .rejectFolderRequest(request.folderId || 0, request.userId)
                                                .then(() => {
                                                    this.setState({ loading: null })
                                                    toast.success("Folder Request Rejected")
                                                    this.loadFiles()
                                                }).catch(err => {
                                                    this.setState({ loading: null })
                                                    toast.success("Error Rejecting Folder Request")
                                                    this.loadFiles()
                                                })
                                        }}
                                    />
                                </div>
                            )
                        }) }
                        { this.state.fileRequest.map(request => {
                            const fileMeta = this.state.files.find(f => f.id === request.fileId);
                            return (
                                <div className='request'>
                                    <FileListItem 
                                        mode='request'
                                        showAccess={false}
                                        file={{
                                            id: request.fileId,
                                            fileName: request.fileName,
                                            fileType: fileMeta?.fileType,
                                            previewAccessLink: fileMeta?.previewAccessLink,
                                        } as UserFileWithFilterType} 
                                        owner={{
                                            name: request.userName,
                                            userId: request.userId
                                        } as UserDetail} 
                                        onClick={() => {
                                            if (!fileMeta || !fileMeta.accessLink) {
                                                return;
                                            }

                                            if (
                                                ["docx", "doc", "jpg", "jpeg", "png", "pdf", "ppt", "pptx", "xls", "xlsx"].indexOf(fileMeta.fileFormat || "") !== -1 ||
                                                fileMeta.fileType === FileType.VideoLink || 
                                                fileMeta.fileType === FileType.Link
                                            ) {
                                                this.setState({
                                                    openedFileUri: fileMeta.accessLink,
                                                    openedFileName: fileMeta.fileName,
                                                    openedFileId: fileMeta.id || 0,
                                                    openedFileType: fileMeta.fileType || null
                                                })                                                                    
                                            } else {
                                                window.open(fileMeta.accessLink)
                                            }
                                        }}
                                        onAllowAccess={() => {
                                            this.setState({
                                                loading: "Sharing File"
                                            })
                                            InternalTracker.trackEvent("File Request Accepted", {
                                                fileId: request.fileId
                                            })
                                            FileApi
                                                .shareFile(request.fileId || 0, request.userId)
                                                .then(() => {
                                                    this.setState({ loading: null })
                                                    toast.success("File Shared")
                                                    this.loadFiles()
                                                }).catch(err => {
                                                    this.setState({ loading: null })
                                                    toast.success("Error Sharing File")
                                                    this.loadFiles()
                                                })
                                        }}
                                        onRemoveAccess={() => {
                                            InternalTracker.trackEvent("File Request Rejected", {
                                                fileId: request.fileId
                                            })
                                            FileApi
                                                .rejectFileRequest(request.fileId || 0, request.userId)
                                                .then(() => {
                                                    this.setState({ loading: null })
                                                    toast.success("File Request Rejected")
                                                    this.loadFiles()
                                                }).catch(err => {
                                                    this.setState({ loading: null })
                                                    toast.success("Error Rejecting File Request")
                                                    this.loadFiles()
                                                })
                                        }}
                                    />
                                </div>
                            )
                        }) }
                    </div>
                </div>
            }
            <div className='file-manager'>
                { (this.state.stale) &&
                    <div className="nonblocking-loading-wrapper">
                        <CircularProgress style={{ width: 24, height: 24 }} />
                        Fetching Latest
                    </div>
                }
                <div className='folders-side' data-show={this.state.showContactsFoldersFilter}>
                    <div className='mobile-header'>
                        <i className="fas fa-times-circle" onClick={() => {
                            this.setState({
                                showContactsFoldersFilter: false
                            })
                        }}></i>
                    </div>
                    <h2>Folders</h2>
                    { (userIdList.filter(userId => this.state.userDetails.find(user => user.userId === userId)).length > 1) &&
                        <input
                            placeholder="Search for People"
                            value={this.state.searchQueryUsers} 
                            onChange={({ target: { name, value } }) => {
                                InternalTracker.trackEvent("File Folder/People Filter", {
                                    keywords: value.toLowerCase()
                                })
                                this.setState({ searchQueryUsers: value.toLowerCase() })
                            }}
                        />
                    }
                    { userIdList.map(userId => {
                        const owner = this.state.userDetails.find(user => user.userId === userId);

                        if (!owner) {
                            return null;
                        }

                        if (this.state.searchQueryUsers && this.state.searchQueryUsers !== '' && owner.name.toLowerCase().indexOf(this.state.searchQueryUsers) === -1) {
                            return null;
                        }

                        const collapsed = this.state.collapsedUserIds.indexOf(owner.userId) !== -1;

                        return (
                            <div className='user folder-user'>
                                <div 
                                    className='owner'
                                    data-active={this.state.filterUserId === owner.userId}
                                    onClick={() => {
                                        InternalTracker.trackEvent("File Filters Changed", {
                                            type: "owner-folder",
                                            userId: owner.userId,
                                            folderId: null,
                                        });
                                        this.setState({
                                            filterUserId: owner.userId,
                                            selectedFolderId: null,
                                            showContactsFoldersFilter: false
                                        })
                                    }}
                                >
                                    <ProfileImage
                                        selectable={false}
                                        size={36}
                                        url={ProfileApi.getProfileImageUrl(owner.userId)}
                                        style={{
                                            border: 'none',
                                            padding: 0,
                                            borderRadius: '100%'
                                        }}
                                        data-report-blurred={owner.reported}
                                        data-user-id={owner.userId}
                                    />
                                    <span 
                                        data-report-blurred={owner.reported}
                                        data-user-id={owner.userId}
                                    >
                                        {owner.name}
                                    </span>
                                    <i 
                                        className={collapsed ? "fas fa-chevron-down" : "fas fa-chevron-up"}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            this.triggerFolderCollapse(owner.userId)
                                        }}
                                    />
                                </div>
                                { (!collapsed) &&
                                    <div className='folders-list'>
                                        { /* @ts-ignore */ }
                                        { [{ name: "Files not in Folders", id: 0, userId: owner.userId, access: 0 }].concat(this.state.folders).map(folder => {
                                            if (folder.userId !== owner.userId) {
                                                return null;
                                            }

                                            return (
                                                <div
                                                    className='folder'
                                                    data-name={folder.name}
                                                    data-access={folder.access}
                                                    data-active={this.state.selectedFolderId === folder.id && this.state.filterUserId === owner.userId}
                                                    onClick={() => {
                                                        InternalTracker.trackEvent("File Filters Changed", {
                                                            type: "owner-folder",
                                                            userId: owner.userId,
                                                            folderId: folder.id,
                                                        });
                                                        
                                                        this.setState({
                                                            selectedFolderId: folder.id,
                                                            filterUserId: owner.userId,
                                                            showContactsFoldersFilter: false
                                                        })
                                                    }}
                                                >
                                                    <i className='fas fa-folder' />
                                                    <label className='file-name'>{folder.name}</label>
                                                    <i className={ folder.access === 0 ? "fas fa-globe" : folder.access === 1 ? "fas fa-users" : folder.access === 2 ? "fas fa-question-circle" : "fas fa-lock" } />
                                                </div>
                                            )
                                            // }
                                        }) }
                                    </div>
                                }
                            </div>
                        ) 
                    }) }
                    { (!this.state.selectedFolderId && !this.state.searchQuery && !this.state.filterUserId) &&
                        <React.Fragment>
                            { (this.state.folders.filter(f => f.accessType === "sharedByCompany").length === 0 && this.state.activeFilter === "sharedByCompany") &&
                                <div className='no-folders onboarding'>
                                    <h2>You have no folders yet</h2>
                                    <p>Folders allow you to organize your files, for easier sharability</p>
                                    <button onClick={() => {
                                        InternalTracker.trackEvent("Folder Adding Started");
                                        this.setState({
                                            editedFolder: {
                                                id: 0,
                                                access: FileAccess.Public
                                            },
                                            editedFolderId: 0,
                                            shareUpdates: []
                                        })
                                    }}>Create Folder</button>
                                </div>
                            }
                        </React.Fragment>
                    }
                </div>
                <div className='files-side' data-hide={this.state.showContactsFoldersFilter ? "true" : "false"}>
                    { (this.state.selectedFolderId && (this.state.activeFilter === "sharedByCompany")) ?
                        <div className='in-folder-header'>
                                <div className='folder-actions'>
                                    <button onClick={() => {
                                        InternalTracker.trackEvent("Folder Editing Started", {
                                            folderId: this.state.selectedFolderId
                                        });
                                        this.setState({
                                            editedFolderId: this.state.selectedFolderId || 0,
                                            editedFolder: this.state.folders.find(folder => folder.id === this.state.selectedFolderId) || null,
                                            shareUpdates: [],
                                        })
                                    }}>
                                        <i 
                                            className="fas fa-edit" 
                                        />
                                        Edit Folder Details
                                    </button>
                                    <button onClick={() => {
                                        InternalTracker.trackEvent("Folder Share Started", {
                                            folderId: this.state.selectedFolderId
                                        });
                                        this.setState({
                                            editedFolderId: this.state.selectedFolderId || 0,
                                            editedFolder: this.state.folders.find(folder => folder.id === this.state.selectedFolderId) || null,
                                            shareUpdates: [],
                                        })
                                    }}>
                                        <i 
                                            className="fas fa-users" 
                                        />
                                        Share Folder
                                    </button>
                                </div>
                        </div>
                    : null }
                    { (this.state.files.filter(f => f.accessType === "sharedByCompany").length === 0 && this.state.folders.filter(f => f.accessType === "sharedByCompany").length === 0 && this.props.mode !== "show-selected" && this.state.activeFilter === "sharedByCompany") &&
                        <div className='no-files onboarding'>
                            <h2>You have no files/links yet</h2>
                            <p>Adding files allows you to share them with your contacts</p>
                            <button id="add-file-btn" onClick={() => {
                                InternalTracker.trackEvent("File Adding Started", {
                                    folderId: this.state.selectedFolderId
                                });
                                let files: UserFile[] = []
                                files.unshift()
                                this.setState({
                                    editedFile: {
                                        id: 0,
                                        access: FileAccess.Public,
                                        displayOrder: 1
                                    },
                                    showFileActionsMobile: false,
                                    editedFileId: 0,
                                    shareUpdates: []
                                })
                            }}>{this.props.addContentText || "Add File/Link" }</button>
                            <button 
                                id="add-folder-btn"
                                className={this.props.mode === "picker" ? "secondary" : ""}
                                style={ this.state.showFileActionsMobile ? { display: 'block', marginLeft: 10 } : { marginLeft: 10 } }
                                onClick={() => {
                                    InternalTracker.trackEvent("Folder Adding Started");
                                    let files: UserFile[] = []
                                    files.unshift()
                                    this.setState({
                                        editedFolder: {
                                            id: 0,
                                            access: localStorage.getItem("lastFileAccessType") ? parseInt(localStorage.getItem("lastFileAccessType") || "0") : FileAccess.Public,
                                        },
                                        showFileActionsMobile: false,
                                        editedFolderId: 0,
                                        shareUpdates: []
                                    })
                                }}
                            >
                                Create Folder
                            </button>
                        </div>
                    }
                    { (this.props.mode === "show-selected" && this.state.selectedFiles.length === 0 && this.state.selectedFolders.length === 0) &&
                        <div className='no-files onboarding'>
                            { (this.props.noSelectedFilesText) &&
                                <p>{this.props.noSelectedFilesText}</p>
                            }
                            <button id="add-file-btn" onClick={() => {
                                if (this.props.switchMode) {
                                    this.props.switchMode("picker")
                                }
                            }}>{this.props.addContentText || "Add File/Link" }</button>
                        </div>
                    }
                    { (this.state.filterUserId !== '' || this.state.searchQuery !== '' || this.state.selectedFolderId !== null) && this.props.mode === undefined && this.state.files.filter(file => this.fileMatchesFilter(file)).length === 0 &&
                        <div className='no-files onboarding'>
                            <h2>No files matching the filter</h2>
                            <p>Change or clear your filters</p>
                            <button onClick={() => {
                                InternalTracker.trackEvent("File Filters Cleared");
                                this.setState({
                                    filterUserId: '',
                                    searchQueryUsers: '',
                                    searchQuery: '',
                                    selectedFolderId: null
                                })
                            }} className='clear-filters-btn'>
                                Clear Filters
                            </button>
                        </div>
                    }
                    { (this.props.mode === "picker" || this.props.mode === "show-selected") &&
                        <div className='files-list files-list-indented'>
                            { this.state.folders.filter(folder => folder.accessType === "sharedByCompany").map(folder => {
                                const owner = this.state.userDetails.find(user => user.userId === folder.userId);
                                if (
                                    this.props.mode === "show-selected" && 
                                    this.state.selectedFolders.indexOf(folder.id) === -1 &&
                                    this.state.selectedFiles.map(fileId => this.state.files.find(file => file.id === fileId)).filter(file => file && file.folderId === folder.id).length === 0
                                ) {
                                    return null;
                                }
                                return (
                                    <div className='inline-folder'>
                                        <div
                                            className='folder-wrapper'
                                            data-selected={this.props.mode === "picker" && this.state.selectedFolders.indexOf(folder.id) !== -1}
                                            data-name={folder.name}
                                            onClick={() => {
                                                if (this.props.mode === "show-selected" && this.state.selectedFiles.indexOf(folder.id || 0) !== -1) {
                                                    return;
                                                }
                                                const location = this.state.selectedFolders.indexOf(folder.id);
                                                const hasFiles = this.state.files.filter(file => this.fileMatchesFilter(file)).filter(file => file.folderId === folder.id).length > 0;
                                                
                                                InternalTracker.trackEvent("Folder " + (location === -1 ? "Selected" : "Deselected"), {
                                                    folderId: folder.id
                                                })

                                                if (location === -1) {
                                                    if (!hasFiles) {
                                                        toast.error("The selected folder is empty");
                                                        return;
                                                    }
                                                    this.setState({
                                                        selectedFolders: [...this.state.selectedFolders, folder.id]
                                                    }, () => {
                                                        console.log(this.state.selectedFolders, "@@@@Folder")
                                                        this.props.onSelectedFoldersUpdated(this.state.selectedFolders)
                                                    })
                                                } else {
                                                    this.setState({
                                                        selectedFolders: this.state.selectedFolders.filter(f => f !== folder.id)
                                                    }, () => {
                                                        console.log(this.state.selectedFolders, "@@@@Folder")
                                                        this.props.onSelectedFoldersUpdated(this.state.selectedFolders)
                                                    })
                                                }
                                            }}
                                        >
                                            <div className='folder'>
                                                <div className='preview'>
                                                    <div>
                                                        <div className='file-format'>
                                                            <i className="fas fa-folder"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='details'>
                                                <label className='file-name'>
                                                    <i className={ folder.access === 0 ? "fas fa-globe" : folder.access === 1 ? "fas fa-users" : folder.access === 2 ? "fas fa-question-circle" : "fas fa-lock" } />
                                                    {folder.name}
                                                </label>
                                                { (owner && this.props.mode !== "show-selected") &&
                                                    <div className='owner'>
                                                        <ProfileImage
                                                            selectable={false}
                                                            size={28}
                                                            url={ProfileApi.getProfileImageUrl(owner.userId)}
                                                            style={{
                                                                border: 'none',
                                                                padding: 0,
                                                                borderRadius: '100%'
                                                            }}
                                                            data-report-blurred={owner.reported}
                                                            data-user-id={owner.userId}
                                                        />
                                                        <span 
                                                            data-report-blurred={owner.reported}
                                                            data-user-id={owner.userId}
                                                        >
                                                            {owner.name}
                                                        </span>
                                                    </div>
                                                }
                                                { (this.state.selectedFolders?.indexOf(folder.id) !== -1) &&
                                                    <div className='options-alt'>
                                                        <button onClick={() => {
                                                             
                                                        }}>
                                                            <i className='fas fa-trash-alt'></i>
                                                        </button>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div className='file-list'>
                                            { this.state.files.filter(file => this.fileMatchesFilter(file)).filter(file => file.folderId === folder.id).map(file => {
                                                const owner = this.state.userDetails.find(user => user.userId === file.userId);
                                                return (
                                                    <FileListItem 
                                                        hideOwner={this.props.mode === "show-selected"}
                                                        showAccess={true}
                                                        mode={this.props.mode}
                                                        file={file} 
                                                        disabled={this.props.mode === "picker" && this.props.disableFiles && this.props.disableFiles.indexOf(file.id || 0) !== -1}
                                                        activeFilter={this.state.activeFilter} 
                                                        owner={owner} 
                                                        selected={this.state.selectedFiles.indexOf(file.id || 0) !== -1}
                                                        showDeleteBtn={this.state.selectedFiles.indexOf(file.id || 0) !== -1}
                                                        onClick={() => {
                                                            if (this.props.mode === "show-selected" && this.state.selectedFiles.indexOf(file.id || 0) === -1) {
                                                                return;
                                                            }
                                                            const location = this.state.selectedFiles.indexOf(file.id || 0);
                                                            InternalTracker.trackEvent("File " + (location === -1 ? "Selected" : "Deselected"), {
                                                                fileId: file.id
                                                            })
                                                            if (location === -1) {
                                                                this.setState({
                                                                    selectedFiles: [...this.state.selectedFiles, file.id || 0]
                                                                }, () => {
                                                                    this.props.onSelectedFilesUpdated(this.state.selectedFiles)
                                                                    console.log(this.state.selectedFiles, "@@@@File")
                                                                })
                                                            } else {
                                                                this.setState({
                                                                    selectedFiles: this.state.selectedFiles.filter(f => f !== file.id || 0)
                                                                }, () => {
                                                                    this.props.onSelectedFilesUpdated(this.state.selectedFiles)
                                                                    console.log(this.state.selectedFiles, "@@@@File")
                                                                })
                                                            }
                                                        }}
                                                    />
                                                );
                                            }) }
                                        </div>
                                    </div>
                                )
                            }) }
                            { this.state.files.filter(file => this.fileMatchesFilter(file)).filter(file => file.folderId === 0).map(file => {
                                const owner = this.state.userDetails.find(user => user.userId === file.userId);
                                if (this.props.mode === "show-selected" && this.state.selectedFiles.indexOf(file.id || 0) === -1) {
                                    return null;
                                }
                                return (
                                    <FileListItem 
                                        hideOwner={this.props.mode === "show-selected"}
                                        mode={this.props.mode}
                                        showAccess={true}
                                        disabled={this.props.mode === "picker" && this.props.disableFiles && this.props.disableFiles.indexOf(file.id || 0) !== -1}
                                        file={file} 
                                        activeFilter={this.state.activeFilter} 
                                        owner={owner}
                                        selected={this.state.selectedFiles.indexOf(file.id || 0) !== -1}
                                        showDeleteBtn={this.state.selectedFiles.indexOf(file.id || 0) !== -1}
                                        onClick={() => {
                                            if (this.props.mode === "show-selected" && this.state.selectedFiles.indexOf(file.id || 0) === -1) {
                                                return;
                                            }
                                            const location = this.state.selectedFiles.indexOf(file.id || 0);
                                            InternalTracker.trackEvent("File " + (location === -1 ? "Selected" : "Deselected"), {
                                                fileId: file.id
                                            })
                                            if (location === -1) {
                                                this.setState({
                                                    selectedFiles: [...this.state.selectedFiles, file.id || 0]
                                                }, () => {
                                                    this.props.onSelectedFilesUpdated(this.state.selectedFiles)
                                                })
                                            } else {
                                                this.setState({
                                                    selectedFiles: this.state.selectedFiles.filter(f => f !== file.id || 0)
                                                }, () => {
                                                    this.props.onSelectedFilesUpdated(this.state.selectedFiles)
                                                })
                                            }
                                        }}
                                    />
                                )
                            }) }
                        </div>
                    }
                    { (this.props.mode === undefined) &&
                        <div className='files-list'>
                            { this.state.files.filter(file => this.fileMatchesFilter(file)).map(file => {
                                const owner = this.state.userDetails.find(user => user.userId === file.userId);
                                return (
                                    <FileListItem 
                                        hideOwner={this.props.mode === "show-selected"}
                                        mode={this.props.mode}
                                        file={file}
                                        disabled={this.props.mode === "picker" && this.props.disableFiles && this.props.disableFiles.indexOf(file.id || 0) !== -1}
                                        showAccess={true}
                                        activeFilter={this.state.activeFilter} 
                                        owner={owner}
                                        searchedFor={this.state.searchQuery}
                                        onClick={() => {
                                            if (this.state.activeFilter === "sharedToCompany") {

                                                if (file.accessType === "sharedToCompany" && !file.virusScanned) {
                                                    toast.error("This file has not been virus scanned yet. Please try again later");
                                                    return;
                                                }
                                                
                                                if (!file.accessLink) {
                                                    this.setState({
                                                        noAccessFileId: file.id
                                                    })
                                                } else {
                                                    if (
                                                        ["docx", "doc", "jpg", "jpeg", "png", "pdf", "ppt", "pptx", "xls", "xlsx"].indexOf(file.fileFormat || "") !== -1 ||
                                                        file.fileType === FileType.VideoLink || 
                                                        file.fileType === FileType.Link
                                                    ) {
                                                        this.setState({
                                                            openedFileUri: file.accessLink,
                                                            openedFileName: file.fileName,
                                                            openedFileId: file.id || 0,
                                                            openedFileType: file.fileType || null
                                                        })
                                                    } else {
                                                        InternalTracker.trackEvent("Opened File Link", {
                                                            fileId: file.id
                                                        })
                                                        window.open(file.accessLink)
                                                    }
                                                }
                                            } else {
                                                InternalTracker.trackEvent("File Editing Started", {
                                                    fileId: file.id
                                                });
                                                this.setState({
                                                    editedFileId: file.id || 0,
                                                    editedFile: file,
                                                    shareUpdates: [],
                                                })
                                            }
                                        }}
                                    />
                                )
                            }) }
                        </div>
                    }
                </div>
            </div>
            { (this.state.loading && this.props.mode !== "show-selected") &&
                <FullScreenLoader
                    loadingMessage={this.state.loading}
                />
            }
            <input 
                id="files-file-input" 
                type="file" 
                style={{
                    display: 'none'
                }}
                onChange={ async (e) => {
                    console.log(e, e.target);
                    const files = e.target.files;
                    const file = files && files[0] ? files[0] : null;
                    if (file && file.name && editedFile) {
                        editedFile.fileName = file.name.split(".").shift();
                        // @ts-ignore
                        editedFile.fileFormat = file.name.split(".").pop().toLowerCase();
                        if (editedFile.fileFormat === "png" || editedFile.fileFormat === "jpg" || editedFile.fileFormat === "jpeg") {
                            editedFile.thumbnailImg = (await Utilities.resizeAndRotateImageV2(file as Blob)) || undefined
                        }
                        this.setState({
                            fileToUpload: file
                        })
                    }
                }}
            ></input>
            <Modal
                show={this.state.editedFileId !== null || this.state.editedFolderId !== null}
                data-mode={window.innerWidth > 850 ? "desktop" : "mobile"}
                id="file-edit-modal"
                onHide={() => {
                    this.setState({
                        editedFileId: null,
                        fileToUpload: null,
                        shareUpdates: [],
                        editedFolderId: null,
                        editedFile: null,
                        editedFolder: null
                    })
                }}
            >
                { this.state.editedFileId !== null &&
                    <Modal.Header closeButton>
                        { !editedFile?.fileType && "Add documents, links, and videos to share with your contacts" }
                        { editedFile?.fileType && editedFile.id === 0 && "Add New File" }
                        { editedFile?.fileType && editedFile.id !== 0 && "Edit File" }
                    </Modal.Header>
                }
                { this.state.editedFolderId !== null &&
                    <Modal.Header closeButton>
                        { (editedFolder!.id === 0) ? "Create New Folder" : "Edit Folder" }
                    </Modal.Header>
                }
                <Modal.Body>
                <div className='files'>
                    { ((editedFile && editedFile.fileType) || (editedFolder)) &&
                        <div className='file-edited'>
                            <React.Fragment>
                                <label>Access Type</label>
                                <InlineSelect
                                    hideUnselectedOptions={editedFilesFolderAccess !== null}
                                    id="file-access-selector"
                                    selected={editedFilesFolderAccess !== null ? editedFilesFolderAccess.toString() : editedFile ? editedFile.access.toString() : editedFolder ? editedFolder.access.toString() : "2"}
                                    options={[
                                        { id: "0" /*FileAccess.Public.toString()*/, icon: <i className="fas fa-globe" />, label: "Public" },
                                        { id: "1" /* FileAccess.PublicToContacts.toString() */, icon: <i className="fas fa-users" />, label: "Contacts Only" },
                                        { id: "2" /* FileAccess.Private.toString() */, icon: <i className="fas fa-question-circle" />, label: "On Request" },
                                        { id: "3" /* FileAccess.Private.toString() */, icon: <i className="fas fa-lock" />, label: "Private" },
                                    ]}
                                    onSelect={(newAccess) => {
                                        console.log(newAccess, editedFile, editedFolder)
                                        InternalTracker.trackEvent((editedFolder ? "Folder" : "File") + " Access Changed", {
                                            id: editedFolder ? editedFolder!.id : editedFile!.id,
                                            access: newAccess
                                        })
                                        localStorage.setItem("lastFileAccessType", newAccess);
                                        if (editedFile) {
                                            let newEdited = this.state.editedFile;
                                            newEdited!.access = newAccess === "0" ? FileAccess.Public : newAccess === "1" ? FileAccess.PublicToContacts : newAccess === "2" ? FileAccess.OnRequest : FileAccess.Private
                                            this.setState({ editedFile: newEdited })
                                        } else if (editedFolder) {
                                            let newEdited = this.state.editedFolder;
                                            newEdited!.access = newAccess === "0" ? FileAccess.Public : newAccess === "1" ? FileAccess.PublicToContacts : newAccess === "2" ? FileAccess.OnRequest : FileAccess.Private
                                            this.setState({ editedFolder: newEdited })
                                        }
                                    }}
                                />
                                <div className='file-access-warn'>
                                    <i className="fas fa-exclamation-triangle" />
                                    <p>
                                        { 
                                            editedFolder ? (
                                                editedFolder.access === FileAccess.Public ?
                                                "This folder's content is accessible to all Updatedge users" :
                                                editedFolder.access === FileAccess.PublicToContacts ?
                                                    "This folder's content is accessible by your contacts only, but the name of the files are visible to everyone. Your colleagues' contacts will also have access to this file." :
                                                        editedFolder.access === FileAccess.OnRequest ?
                                                        "This folder's content is accessible by your selected contacts only, but the name of the files are visible to everyone" :
                                                        "This folder's content is accessible by your selected contacts only"
                                            ) : editedFilesFolderAccess !== null ? ( editedFilesFolderAccess === FileAccess.Public ?
                                                "This file is accessible by all Updatedge users due to the folder's access type" :
                                                    editedFilesFolderAccess === FileAccess.PublicToContacts ?
                                                    "This file is accessible by your contacts only, due to the folder's access type. The name of the file is visible to everyone. Your colleagues' contacts will also have access to this file." :
                                                        editedFilesFolderAccess === FileAccess.OnRequest ?
                                                        "This file is accessible by your selected contacts only due to the folder's access type, but the name of the file is visible to everyone" :
                                                        "This file is accessible by your selected contacts only due to the folder's access type"
                                            ) : editedFile ? ( editedFile.access === FileAccess.Public ?
                                                "This file is accessible to all Updatedge users" :
                                                    editedFile.access === FileAccess.PublicToContacts ?
                                                    "This file is accessible by your contacts only, but the name of the file is visible to everyone. Your colleagues' contacts will also have access to this file." :
                                                        editedFile.access === FileAccess.OnRequest ?
                                                        "This file is accessible by your selected contacts only, but the name of the file is visible to everyone" :
                                                        "This file is accessible by your selected contacts only"
                                            ) : ""
                                        }
                                    </p>
                                </div>
                            </React.Fragment>
                            { ((editedFile && (editedFile.access === 2 || editedFile.access === 3) && !editedFile.folderId) || (editedFolder && (editedFolder.access === 2 || editedFolder.access === 3))) &&
                                <div className="worker-selector-wrapper">
                                    <div className='line'></div>
                                    <p>Select contacts to share this { editedFile ? "file" : "folder" } with</p>
                                    <WorkerSelector
                                        isLoadingContacts={this.state.contacts === null}
                                        hideFilters={true}
                                        showToggleAll={false}
                                        skills={[]}
                                        // @ts-ignore
                                        contacts={this.state.contacts?.map(c => { return { contact: c } }) as ContactAvailabilitySummaryModel || []}
                                        lists={[]}
                                        listId={0}
                                        selectWorker={(userId) => {
                                            this.toggleFileShare(userId, editedFolder ? true : false);
                                        }}
                                        selectedWorkerIds={editedFile ? editedFileExplicitPrivateAccess.map(epa => epa.userId) : editedFolderExplicitPrivateAccess.map(epa => epa.userId)}
                                        showWorkerList={true}
                                        hideAvailability={true}
                                    />
                                </div>
                            }
                            { editedFile && (editedFile.fileType === FileType.TextDocument || editedFile.fileType === FileType.Image) && editedFile.id === 0 &&
                                <button className='main' onClick={() => {
                                    let inputEl = document.getElementById("files-file-input");
                                    if (inputEl) {
                                        inputEl.click();
                                    }
                                }}>
                                    { (this.state.fileToUpload) ? 
                                        ("Replace File (" + this.state.fileToUpload.name + ")") :
                                        "Select File"
                                    }
                                </button>
                            }
                            { ( editedFile && (editedFile.fileType === FileType.VideoLink || editedFile.fileType === FileType.Link) ) &&
                                <React.Fragment>
                                    <label>URL / Link</label>
                                    <input
                                        placeholder={editedFile.fileType === FileType.VideoLink ? "YouTube Link" : "Link"}
                                        value={editedFile.link} 
                                        readOnly={editedFile.id !== 0}
                                        onChange={ async ({ target: { name, value } }) => {
                                            // let previewLinkUrl = value.startsWith("http") ? value : "https://" + value
                                            editedFile.link = value // previewLinkUrl;
                                            this.setState({ editedFile: editedFile })
                                        }}
                                        onBlur={ async () => {
                                            if (editedFile.link) {
                                                InternalTracker.trackEvent("File Link Updated", {
                                                    id: editedFile.id,
                                                })
                                                let previewLinkUrl = editedFile.link.startsWith("http") ? editedFile.link : "https://" + editedFile.link
                                                editedFile.link = previewLinkUrl;
                                                this.setState({ editedFile: editedFile })

                                                if (previewLinkUrl) {
                                                    const base64Preview = await FileApi.fetchLinkPreview(previewLinkUrl);
                                                    if (base64Preview && typeof base64Preview.base64 === "string") {
                                                        console.log("@@@ Fetched to : " + base64Preview.base64);
                                                        var resized = await Utilities.resizeAndRotateImage(base64Preview.base64)
                                                        if (resized) {
                                                            editedFile.thumbnailImg = resized
                                                            this.setState({ editedFile: editedFile })
                                                        }
                                                    }
                                                }
                                            }
                                        }}
                                    />
                                </React.Fragment>
                            }
                            <label>{editedFile ? "File Name" : "Folder Name"}</label>
                            <input
                                placeholder={editedFile ? "File Name" : "Folder Name"}
                                value={editedFile ? editedFile.fileName : editedFolder ? editedFolder.name : ""}
                                onChange={({ target: { name, value } }) => {
                                    InternalTracker.trackEvent("File Name Updated", {
                                        id: editedFile ? editedFile.id : editedFolder ? editedFolder.id : 0
                                    })
                                    if (editedFolder) {
                                        editedFolder.name = value;
                                        this.setState({ editedFolder: editedFolder })
                                    } else if (editedFile) {
                                        editedFile.fileName = value;
                                        this.setState({ editedFile: editedFile })
                                    }
                                }}
                            />
                            { (editedFile && (editedFile.fileType === FileType.TextDocument || editedFile.fileType === FileType.Image || editedFile.fileType === FileType.Link)) &&
                                <React.Fragment>
                                    <label>Category</label>
                                    <input
                                        placeholder="Create or Select Category"
                                        value={editedFile.fileCategoryName} 
                                        onChange={({ target: { name, value } }) => {
                                            if (value && Utilities.startsWithLowerCase(value)) {
                                                editedFile.fileCategoryName = Utilities.capitalize(value)
                                            } else {
                                                editedFile.fileCategoryName = value
                                            }
                                            this.setState({
                                                editedFile: editedFile,
                                                fileCategorySuggestionsQuery: value
                                            })
                                        }}
                                        onBlur={() => {
                                            InternalTracker.trackEvent("File Category Updated", {
                                                id: editedFile.id,
                                                category: editedFile.fileCategoryName
                                            })
                                            this.setState({
                                                fileCategorySuggestionsQuery: ""
                                            })
                                        }}
                                    />
                                </React.Fragment>
                            }
                            { (editedFile && (editedFile.fileType === FileType.TextDocument || editedFile.fileType === FileType.Image || editedFile.fileType === FileType.Link)) &&
                                <div
                                    className="section-content-2"
                                    style={{
                                        padding: "0 0 0 0",
                                        marginBottom: -4
                                    }}
                                >
                                    <div className="suggestions">
                                        { /*(this.state.config?.fileCategorySuggestions) && this.state.config.fileCategorySuggestions*/
                                            [
                                                { name: "contact numbers" }, 
                                                { name: "timetable" }, 
                                                { name: "parking" }, 
                                                { name: "site map" }, 
                                                { name: "behaviour policy" }
                                            ].concat(this.state.ownFileSuggestions.map(cat => { return { name: cat } })).map(suggestionItem => {
                                            if (this.state.fileCategorySuggestionsQuery && suggestionItem.name.indexOf(this.state.fileCategorySuggestionsQuery) === -1) return null;
                                            return (
                                                <div key={suggestionItem.name} onClick={() => {
                                                    let newEdited = this.state.editedFile;
                                                    newEdited!.fileCategoryName = Utilities.capitalizeFirstLetter(suggestionItem.name);
                                                    InternalTracker.trackEvent("File Category Suggestion Picked", {
                                                        id: newEdited!.id,
                                                        category: newEdited!.fileCategoryName
                                                    })
                                                    this.setState({
                                                        editedFile: newEdited,
                                                        fileCategorySuggestionsQuery: ""
                                                    })
                                                }}>
                                                    {Utilities.capitalize(suggestionItem.name)}
                                                </div>
                                            )
                                        }) }
                                    </div>
                                </div>
                            }
                            { (editedFile || editedFolder) &&
                                <React.Fragment>
                                    <label>Description</label>
                                    <textarea
                                        rows={2}
                                        placeholder="Description" 
                                        value={editedFile || editedFolder ? (editedFile ? editedFile.description : editedFolder?.description) : ""}
                                        onChange={({ target: { name, value } }) => {
                                            InternalTracker.trackEvent((editedFolder ? "Folder" : "File") + " Description Chabged", {
                                                id: editedFolder ? editedFolder!.id : editedFile!.id,
                                            })
                                            if (editedFile) {
                                                editedFile.description = value;
                                                this.setState({ editedFile: editedFile })
                                            } else if (editedFolder) {
                                                editedFolder.description = value;
                                                this.setState({ editedFolder: editedFolder })
                                            }
                                        }}
                                    />
                                </React.Fragment>
                            }
                            { (editedFile) &&
                                <React.Fragment>
                                    <label>Select Folder to add to</label>
                                    <select
                                        placeholder="Select Folder"
                                        value={editedFile.folderId?.toString() || "0"} 
                                        onChange={({ target: { name, value } }) => {
                                            console.log(value)
                                            editedFile.folderId = parseInt(value);
                                            InternalTracker.trackEvent("File Folder Changed", {
                                                fileId: editedFile.id,
                                                folderId: editedFile.folderId
                                            })
                                            this.setState({
                                                editedFile: editedFile
                                            })
                                        }}
                                    >
                                        <option value={0}>No Folder</option>
                                        { this.state.folders.map(folder => {
                                            if (folder.accessType === "sharedToCompany") {
                                                return null;
                                            }
                                            return (
                                                <option key={folder.id} value={folder.id}>
                                                    {folder.name}
                                                </option>
                                            )
                                        }) }
                                    </select>
                                </React.Fragment>
                            }
                            { ((editedFile !== null && editedFile.id !== 0) || (editedFolder !== null && editedFolder.id !== 0)) &&
                                <div className="options">
                                    <button
                                        id="delete-file-btn"
                                        onClick={ async () => {
                                            if (editedFolder) {
                                                this.setState({
                                                    loading: "Deleting Folder..."
                                                })
                                                InternalTracker.trackEvent("Folder Deleted", {
                                                    id: editedFolder.id
                                                })
                                                FileApi.deleteFolder(editedFolder.id).then(data => {
                                                    toast.success("Folder Deleted")
                                                    this.setState({ selectedFolderId: null, editedFile: null, editedFileId: null, fileToUpload: null, editedFolderId: null, editedFolder: null, shareUpdates: [] }, () => { this.loadFiles() })
                                                }).catch(e => {
                                                    toast.error(e?.response?.data || "Failed to delete folder");
                                                    this.setState({ selectedFolderId: null, editedFile: null, editedFileId: null, fileToUpload: null, editedFolderId: null, editedFolder: null, shareUpdates: [] }, () => { this.loadFiles() })
                                                })
                                            } else if (editedFile) {
                                                this.setState({
                                                    loading: "Deleting File..."
                                                })
                                                InternalTracker.trackEvent("File Deleted", {
                                                    id: editedFile.id
                                                })
                                                FileApi.deleteFile((editedFile ? editedFile.id : editedFolder ? (editedFolder as UserFolder).id : 0) || 0).then(data => {
                                                    toast.success("File Deleted")
                                                    this.setState({ editedFile: null, editedFileId: null, fileToUpload: null, editedFolderId: null, editedFolder: null, shareUpdates: [] }, () => { this.loadFiles() })
                                                }).catch(e => {
                                                    toast.error(e?.response?.data || "Failed to delete file");
                                                    this.setState({ editedFile: null, editedFileId: null, fileToUpload: null, editedFolderId: null, editedFolder: null, shareUpdates: [] }, () => { this.loadFiles() })
                                                })
                                            }
                                        }}
                                        className="main delete destructive"
                                    >
                                        Delete
                                    </button>
                                    { (editedFile) &&
                                        <button 
                                            className="main save-progress-btn" 
                                            onClick={ async () => {
                                                const now = new Date().getTime();
                                                if (editedFile.accessLink && editedFile.accessLinkExpiry) {
                                                    InternalTracker.trackEvent("File Downloaded as View", {
                                                        id: editedFile.id
                                                    })
                                                    if (editedFile.accessLinkExpiry < now) {
                                                        window.open(editedFile.accessLink);
                                                    } else {
                                                        this.loadFiles((files) => {
                                                            window.open(editedFile.accessLink);
                                                        });
                                                    }
                                                }
                                            }}
                                        >
                                            View
                                        </button>
                                    }
                                    <button 
                                        className="main save-progress-btn" 
                                        id="update-file-btn"
                                        onClick={ async () => {
                                            InternalTracker.trackEvent((editedFile ? "File" : "Folder") + " Saved", {
                                                id: editedFile ? editedFile.id : editedFolder ? editedFolder.id : 0
                                            })

                                            this.setState({
                                                loading: "Updating Details..."
                                            })

                                            if (this.state.shareUpdates.length !== 0) {
                                                if (this.state.editedFile) {
                                                    await Promise.all(this.state.shareUpdates.map(update => {
                                                        if (update.action === "add") {
                                                            return FileApi.shareFile(this.state.editedFileId || 0, update.userId);
                                                        } else {
                                                            return FileApi.removeFileShare(this.state.editedFileId || 0, update.userId);
                                                        }
                                                    })).then(d => {
                                                        toast.success("File shares updated")
                                                    }).catch( async (e) => {
                                                        toast.success("Failed to update file shares")
                                                    })
                                                } else {
                                                    await Promise.all(this.state.shareUpdates.map(update => {
                                                        if (update.action === "add") {
                                                            return FileApi.shareFolder(this.state.editedFolderId || 0, update.userId);
                                                        } else {
                                                            return FileApi.removeFolderShare(this.state.editedFolderId || 0, update.userId);
                                                        }
                                                    })).then(d => {
                                                        toast.success("Folder shares updated")
                                                    }).catch( async (e) => {
                                                        toast.success("Failed to update folder shares")
                                                    })
                                                }
                                            }

                                            if (editedFile) {

                                                // TODO validation

                                                await FileApi.updateFileMetadata(editedFile).catch(e => {
                                                    toast.error(e?.response?.data || "Failed to update file");
                                                })

                                            } else if (editedFolder) {

                                                if (!editedFolder.name) {
                                                    toast.error("Enter a name for this file"); return;
                                                }

                                                await FileApi.editFolder(editedFolder.id, editedFolder).catch(e => {
                                                    toast.error(e?.response?.data || "Failed to update folder");
                                                })
                                            }

                                            this.setState({
                                                loading: null,
                                                editedFile: null,
                                                editedFileId: null,
                                                fileToUpload: null,
                                                editedFolder: null,
                                                editedFolderId: null,
                                            }, () => {
                                                this.loadFiles();
                                            })
                                        }}
                                    >
                                        Save
                                    </button>
                                </div>
                            }
                            { ((editedFile !== null && editedFile.fileType && editedFile.id === 0) || (editedFolder !== null && editedFolder.id === 0)) &&
                                <div className="options">
                                    <button
                                        onClick={() => {
                                            InternalTracker.trackEvent((editedFile ? "File" : "Folder") + " Discarded", {
                                                id: editedFile ? editedFile.id : editedFolder ? editedFolder.id : 0
                                            })
                                            this.setState({
                                                editedFile: null,
                                                editedFileId: null,
                                                fileToUpload: null,
                                                editedFolder: null,
                                                editedFolderId: null,
                                                shareUpdates: []
                                            })
                                        }}
                                        className="main destructive"
                                    >
                                        Discard
                                    </button>
                                    <button 
                                        className="main save-progress-btn" 
                                        id="start-file-upload-btn"
                                        onClick={ async () => {

                                            const userFileRef = editedFile;

                                            InternalTracker.trackEvent((editedFile ? "File" : "Folder") + " Saved", {
                                                id: editedFile ? editedFile.id : editedFolder ? editedFolder.id : 0,
                                                type: editedFile ? editedFile.fileType : editedFolder ? "folder" : "unknown"
                                            })

                                            if (editedFile && userFileRef) {

                                                var allowedUploadFormats = this.state?.config?.allowedFileTypesToUpload || [];

                                                if (!userFileRef.fileName) {
                                                    toast.error("Enter a name for this file"); return;
                                                } else if (editedFile.fileType === FileType.TextDocument || editedFile.fileType === FileType.Image) {
                                                    if (!this.state.fileToUpload) {
                                                        toast.error("Select a file to upload"); return;
                                                    } else if (!editedFile.fileFormat) {
                                                        toast.error("Invalid File"); return;
                                                    } else if (allowedUploadFormats.indexOf(editedFile.fileFormat) === -1) {
                                                        toast.error("Uploading " + editedFile.fileFormat + " is not allowed"); return;
                                                    } else if (!userFileRef.fileCategoryName) {
                                                        toast.error("Enter a category for this file"); return;
                                                    }
                                                } else if (editedFile.fileType === FileType.VideoLink || editedFile.fileType === FileType.Link) {
                                                    if (!Utilities.isValidHttpUrl(editedFile.link)) {
                                                        toast.error("Invalid Link"); return;
                                                    } else if (editedFile.fileType === FileType.Link && !userFileRef.fileCategoryName) {
                                                        toast.error("Enter a category for this link"); return;
                                                    } else if (editedFile.fileType === FileType.VideoLink && !isValidUrl(editedFile.link)) {
                                                        toast.error("Invalid YouTube link"); return;
                                                    }
                                                }

                                                try {
                                                    this.setState({
                                                        loading: editedFile.fileType === FileType.VideoLink || editedFile.fileType === FileType.Link ? "Saving Link..." : "Uploading File..."
                                                    })

                                                    const fileRef: UserFile = (await FileApi.generateUploadLink(userFileRef)) as any;

                                                    if (fileRef && fileRef.accessLink && fileRef.id) {

                                                        let data, uploadRes;

                                                        if (this.state.fileToUpload) {
                                                            data = new File([this.state.fileToUpload], fileRef.id + "." + userFileRef.fileFormat);
                                                    
                                                            uploadRes = await axios({
                                                                method: 'put',
                                                                url: fileRef.accessLink,
                                                                headers: { 
                                                                'x-ms-blob-type': 'BlockBlob', 
                                                                'Content-Type': mime.lookup(data.name)
                                                                },
                                                                data: data
                                                            });
                                                        }

                                                        let uploadResPreview: any = null;
                                                        
                                                        if (userFileRef.thumbnailImg && fileRef.previewAccessLink) {
                                                            uploadResPreview = await axios({
                                                                method: 'put',
                                                                url: fileRef.previewAccessLink,
                                                                headers: { 
                                                                'x-ms-blob-type': 'BlockBlob', 
                                                                'Content-Type': data ? mime.lookup(data.name) : 'image/png'
                                                                },
                                                                data: userFileRef.thumbnailImg
                                                            })
                                                        }
                                                        
                                                        if (uploadRes || uploadResPreview) {
                                                            await FileApi.markFileUplaoded(fileRef.id, uploadResPreview !== null && uploadResPreview !== undefined);
                                                            toast.success(fileRef.fileType === FileType.Link ? "Link Saved" : "File Uploaded");
                                                        }
                                                    }
                                                    this.loadFiles();
                                                    this.setState({
                                                        editedFile: null,
                                                        editedFileId: null,
                                                        fileToUpload: null,
                                                        editedFolder: null,
                                                        editedFolderId: null,
                                                        shareUpdates: []
                                                    })
                                                } catch (e) {
                                                    this.setState({
                                                        loading: null
                                                    })
                                                    // @ts-ignore
                                                    if (e && e.response && e.response.data) toast.error(e.response.data);
                                                }
                                            } else if (editedFolder && this.state.editedFolderId !== null) {
                                                if (!editedFolder.name) {
                                                    toast.error("Enter a name for this file"); return;
                                                }

                                                try {
                                                    this.setState({
                                                        loading: "Saving Folder..."
                                                    })

                                                    await FileApi.editFolder(this.state.editedFolderId, editedFolder);

                                                    toast.success("Folder Updated");

                                                    this.loadFiles();

                                                    this.setState({
                                                        editedFolder: null,
                                                        editedFolderId: null,
                                                        shareUpdates: []
                                                    })
                                                } catch (e) {
                                                    this.setState({
                                                        loading: null
                                                    })
                                                    // @ts-ignore
                                                    if (e && e.response && e.response.data) toast.error(e.response.data);
                                                }
                                            }

                                        }}
                                    >
                                        { editedFolder ? "Save" : editedFile ? (editedFile.fileType === FileType.VideoLink || editedFile.fileType === FileType.Link ? "Add" : "Save" ) : "" }
                                    </button>
                                </div>
                            }
                            </div> 
                        }
                        { (editedFile && !editedFile.fileType) &&
                            <div className='file-type-selector'>
                                { [
                                    {
                                        name: FileType.TextDocument,
                                        id: "document",
                                        label: "Upload documents & images",
                                        description: "Class name list, school map, safety protocols, etc.",
                                        logo: '/img/TypeDocument.png'
                                    },
                                    {
                                        name: FileType.Link,
                                        id: "link",
                                        label: "Add links to websites",
                                        description: "School website, attandance form, etc",
                                        logo: '/img/TypeLink.png'
                                    },
                                    {
                                        name: FileType.VideoLink,
                                        id: "youtube",
                                        label: "Add video links",
                                        description: "Tell your or your school's story in a video",
                                        logo: '/img/TypeVideoLink.png'
                                    },
                                ].map(type => {
                                    return (
                                        <div 
                                            data-type={type.id}
                                            className='type'
                                            onClick={() => {
                                                editedFile.fileType = type.name;
                                                InternalTracker.trackEvent("File Type Changed", {
                                                    type: type.name
                                                })
                                                this.setState({
                                                    editedFile: editedFile
                                                })
                                            }}
                                        >
                                            <div className='text'>
                                                <div>
                                                    <p>{type.label}</p>
                                                    <p>{type.description}</p>
                                                </div>
                                            </div>
                                            <div className='img'>
                                                <img src={type.logo} />
                                            </div>
                                        </div>
                                    )
                                }) }
                            </div>
                        }
                    </div>
                </Modal.Body>
            </Modal>
            <Modal
                show={this.state.noAccessFileId !== undefined}
                onHide={() => { 
                    this.setState({ 
                        noAccessFileId: undefined,
                        editedFile: null,
                        editedFolderId: null,
                        editedFileId: null,
                        fileToUpload: null,
                        editedFolder: null
                    })
                } }
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        You don't have access to this private file.
                    </Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <Button onClick={() => { this.setState({ noAccessFileId: undefined }) }} >
                        Dismiss
                    </Button>
                    <Button
                        onClick={() => {
                            InternalTracker.trackEvent("File Requested", {
                                fileId: this.state.noAccessFileId
                            })
                            FileApi
                                .requestFile(this.state.noAccessFileId || 0)
                                .then(() => {
                                    toast.success("File Requested")
                                })
                                .catch(() => {
                                    toast.success("Failed to request file")
                                })
                            this.setState({
                                noAccessFileId: undefined
                            })
                        }}
                    >
                        Request Access to File
                    </Button>
                    { (this.state.selectedFolderId) &&
                        <Button
                            onClick={() => {
                                InternalTracker.trackEvent("Folder Requested", {
                                    folderId: this.state.selectedFolderId
                                })
                                FileApi
                                    .requestFileFolder(this.state.selectedFolderId || 0)
                                    .then(() => {
                                        toast.success("Folder Requested")
                                    })
                                    .catch(() => {
                                        toast.success("Failed to request folder")
                                    })
                                this.setState({
                                    noAccessFileId: undefined
                                })
                            }}
                        >
                            Request Access to Folder
                        </Button>
                    }
                </Modal.Footer>
            </Modal>
            { (this.state.openedFileUri) &&
                <DocumentViewer
                    id={this.state.openedFileId || 0}
                    name={this.state.openedFileName || ""}
                    uri={this.state.openedFileUri}
                    type={this.state.openedFileType}
                    close={() => {
                        this.setState({
                            openedFileUri: undefined
                        })
                    }}
                />
            }
            { (this.state.joyride !== "") &&
                <Joyride
                    run={this.state.joyride !== ""}
                    callback={(data) => {
                        if (data.action === "reset" || data.action === "close") {
                            this.setState({
                                joyride: ""
                            })
                            localStorage.setItem("filesJoyrideDismissed", "true");
                            SettingApi.update(Setting.Files_HideGuide, "true") 
                        }
                    }}
                    locale={{
                        last: "Finish"
                    }}
                    continuous={true}
                    steps={[
                        {
                            target: '.category:nth-of-type(1)',
                            content: <div>
                                <h1>Files</h1>
                                <p>This is the place where you can add files that can be shared with others</p>
                                <p>You have complete control whether to share a file publicly or just with contacts who are sharing their availability with you</p>
                            </div>,
                            disableBeacon: true,
                        },
                        {
                            target: '.category:nth-of-type(2)',
                            content: <div>
                                <h1>Contacts also Share Files with you</h1>
                                <p>You can see all available files, and files that you can request access for from your contacts</p>
                            </div>,
                            disableBeacon: true,
                        }
                    ]}
                />
            }
            </div>

        if (this.props.mode === "picker") {
            return (
                <Modal
                    show={true}
                    onHide={() => {
                        this.props.onClosed();
                    }}
                >
                    <Modal.Header closeButton>
                        Select Files / Folders to attach to this offer
                    </Modal.Header>
                    <Modal.Body>
                    {DOM_CONTENT}
                    </Modal.Body>
                </Modal>
            )
        }

        return DOM_CONTENT;
    }
}

export class FileListItem extends React.Component<
    {
        file: UserFileWithFilterType,
        activeFilter?: "sharedByCompany" | "sharedToCompany",
        owner?: UserDetail,
        onClick: () => void,
        onAllowAccess?: () => void,
        onRemoveAccess?: () => void,
        selected?: boolean,
        mode?: "picker" | "show-selected" | 'request' | "hidden" | undefined,
        showAccess?: boolean,
        disabled?: boolean,
        showDeleteBtn?: boolean,
        hideOwner?: boolean,
        hidePreview?: boolean,
        folder?: boolean,
        searchedFor?: string
    },
    {}
> {
    render() {
        const file = this.props.file;
        const owner = this.props.owner
        return (
                <div
                    className='file-wrapper'
                    data-name={file.fileName}
                    data-mode={this.props.mode || undefined}
                    data-disabled={this.props.disabled ? "true" : "false"}
                    data-has-access={file.accessLink ? "true" : "false"}
                    data-access={file.access || "0"}
                    data-selected={this.props.selected && this.props.mode === "picker" ? "true" : "false"}
                    onClick={() => {
                        if (!this.props.disabled) {
                            this.props.onClick()
                        }
                    }}
                >
                    { (this.props.folder) &&
                        <div className='folder'>
                            <div className='preview'>
                                <div>
                                    <div className='file-format'>
                                        <i className="fas fa-folder"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    { (!this.props.hidePreview) &&
                        <div 
                            className='file' 
                            style={ file.previewAccessLink ? { backgroundImage: 'url(' + file.previewAccessLink + ')' } : {} }
                        >
                            <div
                                className='preview' 
                                style={ file.previewAccessLink ? { opacity: 0 } : {} }
                            >
                                <div style={ !file.previewAccessLink ? { display: 'block' } : {} }>
                                    <div className='file-format'>
                                        { (file.fileType === FileType.TextDocument || file.fileType === FileType.Image) &&
                                            <i className="fas fa-file"></i>
                                        }
                                        { (file.fileType === FileType.VideoLink) &&
                                            <i className="fas fa-video"></i>
                                        }
                                        { (file.fileType === FileType.Link) &&
                                            <i className="fas fa-globe"></i>
                                        }
                                    </div>
                                </div>
                            </div>    
                        </div>
                    }
                    <div className='details'>
                        <label className='file-name'>
                            { (this.props.showAccess) &&
                                <i className={ file.access === 0 ? "fas fa-globe" : file.access === 1 ? "fas fa-users" : file.access === 2 ? "fas fa-question-circle" : "fas fa-lock" } />
                            }
                            { this.props.searchedFor ?
                                <Highlighter
                                    highlightClassName="highlighted"
                                    searchWords={[this.props.searchedFor]}
                                    autoEscape={true}
                                    textToHighlight={file.fileName}
                                />
                                :
                                file.fileName
                            }
                            {file.fileFormat} 
                            <span className='file-category'>{file.fileCategoryName}</span>
                            { this.props.disabled &&
                                " - Already attached to this offer"
                            }
                        </label>
                        { (owner && !this.props.hideOwner) &&
                            <div className='owner'>
                                <ProfileImage
                                    selectable={false}
                                    size={28}
                                    url={ProfileApi.getProfileImageUrl(owner.userId)}
                                    style={{
                                        border: 'none',
                                        padding: 0,
                                        borderRadius: '100%'
                                    }}
                                    data-report-blurred={owner.reported}
                                    data-user-id={owner.userId}
                                />
                                <span
                                    data-report-blurred={owner.reported}
                                    data-user-id={owner.userId}
                                >{
                                    owner.name}
                                </span>
                            </div>
                        }
                        { (this.props.showDeleteBtn) &&
                            <div className='options-alt'>
                                <button>
                                    <i className='fas fa-trash-alt'></i>
                                </button>
                            </div>
                        }
                    </div>
                    { (this.props.mode === "request") &&
                        <div className='options'>
                            <button onClick={() => {
                                if (this.props.onAllowAccess)
                                this.props.onAllowAccess()
                            }}>Approve</button>
                            <button onClick={() => {
                                if (this.props.onRemoveAccess)
                                    this.props.onRemoveAccess()
                            }}>Decline</button>
                        </div>
                    }
                </div>
        )
    }
}

export default Files;
