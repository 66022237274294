import React from 'react';
import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';

export class AuthFlowLayout extends React.Component<{}, {}> {
    public render() {
        return (
            <Container>
                <ToastContainer position="top-center" />
                <div className="main-content">
                    <div style={{ textAlign: 'center' }}>
                        <img
                            src="../img/branding/updateedge_60.png"
                            alt="Updatedge Logo"
                        />
                    </div>
                    <div className="children">{this.props.children}</div>
                </div>
            </Container>
        );
    }
}

const Container = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background: url('/app-static/assets/background.jpg') 50% 50%;
    background-size: cover;

    .main-content {
        width: 320px;
        margin: 8% auto;
        padding: 25px;
        background: white;
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2),
            0 5px 5px 0 rgba(0, 0, 0, 0.24);

        .logo-text {
            margin-left: 15px;

            &-big {
                color: #518a40;
                font-weight: 700;
                font-size: 32px;
            }
        }

        .children {
            position: relative;
            min-height: 200px;
            padding-top: 20px;
        }
    }
`;
