/* eslint-disable */
import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import {
    Image,
    OverlayTrigger,
    Popover,
    Tab,
    Table,
    Tabs
} from 'react-bootstrap';
import { Link } from 'react-router-dom';

import history from '../../history';
import InviteApi from '../../api/invites/Invites';
import ProfileApi from '../../api/profile/Profile';
import CompanyApi from '../../api/company/Company';
import {
    NotificationStatusType,
    OutgoingInvitationResult
} from '../../api/invites/ResponseTypes';
import { AsyncOverlay } from '../ui-components/AsyncOverlay';
import Analytics from '../../services/analytics.service';
import InternalTracker from '../../InternalTracker';
import { ProfileImage } from '../ui-components/ProfileImage';
import { StarRating } from '../ui-components/StarRating';
import WorkerRater, { ContactWithRating } from '../../components/rating/WorkerRater';
import RatingApi from '../../api/ratings/Rating';

interface RateModal {
    contactId: string;
    stars: number, 
    userId: string,
    publicComment: string,
    privateComment: string,
    name: string,
}

interface AutoInvitationsState {
    invitations: OutgoingInvitationResult[];
    loading: boolean;
    pastInvitationsShowing: boolean;
    now: Date;
    rateModal: RateModal | null;
}

export class AutoInvitations extends React.Component<
    any,
    AutoInvitationsState
> {
    constructor(props) {
        super(props);

        this.state = {
            invitations: [],
            pastInvitationsShowing: false,
            loading: false,
            now: new Date(),
            rateModal: null
        };

        this.reload();
    }

    private handleDelete = (id: string) => {
        InviteApi.cancelInvitation(id).then(() => {
            this.setState(
                {
                    invitations: this.state.invitations.map((item) => {
                        return item.id == id
                            ? {
                                  ...item,
                                  status: item.status
                              }
                            : item;
                    })
                },
                () => {
                    this.reload();
                }
            );
        });
        InternalTracker.trackEvent("Pending Invitation Deleted");
    };

    private selectedProfile = (inv: OutgoingInvitationResult) => {
        if (
            !inv.contactIsExternal &&
            inv.status == NotificationStatusType.Receiving
        ) {
            history.push(`/contact/${inv.contactId}`);
        }
    };

    public reload = () => {
        InviteApi.getOutgoingInvitations().then((invitations) => {
            this.setState({
                invitations
            });
        });
    };

    render() {
        if (this.state.loading) {
            return (
                <div>
                    <AsyncOverlay show={this.state.loading} />
                </div>
            );
        }

        return (
            <>
                <h2>Sent Invitations</h2>
                <Table
                    className="table"
                    //condensed
                    striped
                    responsive
                    style={{
                        width: '100%',
                        overflowY: 'scroll',
                        marginBottom: 0
                    }}
                >
                    {this.renderHeadings()}
                    {(this.currentInvitations.length > 0 || this.state.pastInvitationsShowing) && (
                        <>
                            <tbody>
                                {this.renderInvitations(
                                    this.currentInvitations
                                )}
                                {this.state.pastInvitationsShowing && this.renderInvitations(
                                    this.pastInvitations
                                )}
                            </tbody>
                        </>
                    )}
                </Table>
                {this.pastInvitations.length !== 0 && !this.state.pastInvitationsShowing &&
                    <button
                        style={{
                            border: 'none',
                            padding: '8px 16px',
                            marginTop: 12,
                            borderRadius: 52,
                            background: 'whitesmoke'
                        }}
                        onClick={() => {
                            this.setState({
                                pastInvitationsShowing: true
                            })
                        }}
                    >
                        Show {this.pastInvitations.length} older invitations
                    </button>
                }
                { (this.state.rateModal) &&
                    <WorkerRater
                        contacts={[this.state.rateModal]}
                        onEdit={ async (contact) => {
                            console.log(contact);
                            const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user") || "") : null;
                            const orgId = user.organisationId;
                            const orgIsAgency = user.organisationIsAgency;

                            if (!orgId && window.location.href.indexOf("/external/timesheet") === -1) {
                                alert("Ratings are saved on organization level, please create an organization before rating");
                                return;
                            }

                            RatingApi.postWorkerRatingByHirerOrg(orgId, [contact], orgIsAgency).then(data => {
                                console.log(data)
                            }).catch(e => {
                                console.log(e)
                            })
                            return contact;
                        }}
                        showProfile={false}
                        showSave={false}
                        extendedHeightWithoutFocus={false}
                        mode="modal"
                    />
                }
            </>
        );
    }

    renderHeadings() {
        return (
            <thead>
                <tr style={{ fontWeight: 'lighter' }}>
                    <th style={{ fontWeight: 'lighter' }}>Accepted</th>
                    <th style={{ fontWeight: 'lighter' }}>Availability</th>
                    <th style={{ fontWeight: 'lighter' }}>Details</th>
                    <th style={{ fontWeight: 'lighter' }}>Ratings</th>
                    <th style={{ whiteSpace: 'nowrap', fontWeight: 'lighter' }}>
                        Invite Date
                    </th>
                    <th style={{ whiteSpace: 'nowrap', fontWeight: 'lighter' }}>
                        Invite Type
                    </th>
                </tr>
            </thead>
        );
    }

    hasInvitations() {
        return this.state.invitations.length > 0;
    }

    get currentInvitations() {
        return this.state.invitations.filter((inv) => {
            return (
                moment(new Date(inv.created)).diff(this.state.now, 'days') > -14
            );
        });
    }

    get pastInvitations() {
        return this.state.invitations.filter((inv) => {
            return (
                moment(new Date(inv.created)).diff(this.state.now, 'days') <=
                -14
            );
        });
    }

    hasRejected() {
        return (
            this.state.invitations.filter(
                (inv) => inv.status == NotificationStatusType.Rejected
            ).length > 0
        );
    }

    hasCompleted() {
        return (
            this.state.invitations.filter(
                (inv) => inv.status == NotificationStatusType.Receiving
            ).length > 0
        );
    }

    hasPending() {
        return (
            this.state.invitations.filter(
                (inv) => inv.status == NotificationStatusType.Pending
            ).length > 0
        );
    }

    capitaliseFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    statusPopover(status: NotificationStatusType, name: string) {
        return (
            <Popover id="status-popover">
                {status == NotificationStatusType.Pending ? (
                    <div>
                        <span>
                            {`${this.capitaliseFirstLetter(
                                name
                            )} hasn't accepted your invitation yet. They probably just need more time! We'll remind ${this.capitaliseFirstLetter(
                                name
                            )} several times - you don't need to take any further action at this stage.`}
                        </span>
                    </div>
                ) : null}
                {status == NotificationStatusType.Receiving ? (
                    <div>
                        <span>
                            {`${this.capitaliseFirstLetter(
                                name
                            )} has accepted your invitation.`}
                        </span>
                    </div>
                ) : null}
                {status == NotificationStatusType.Rejected ? (
                    <div>
                        <span>{`${this.capitaliseFirstLetter(
                            name
                        )} has rejected your invitation. Sorry about that!`}</span>
                    </div>
                ) : null}
            </Popover>
        );
    }

    availabilityPopover(sharingAvailability: boolean, name: string) {
        return (
            <Popover id="availability-popover">
                <div>
                    <span>
                        {`${this.capitaliseFirstLetter(name)} is ${
                            !sharingAvailability ? `not` : ``
                        } sharing availability with you.`}
                    </span>
                </div>
            </Popover>
        );
    }

    deletePopover = (
        <Popover id="delete-popover">Delete the invitation</Popover>
    );

    autoPopover = (autoinvited: boolean) => (
        <Popover id="auto-popover">
            {autoinvited
                ? 'This contact was automatically invited.'
                : 'This contact was manually invited.'}
        </Popover>
    );

    hasProfileImageUrl = (inv: OutgoingInvitationResult) => {
        if (inv.contactIsExternal) {
            if (inv.ratings && inv.ratings.length > 0) {
                const ratingProfileImages = inv.ratings
                    .filter((rating) => {
                        return rating.profileImageUrl != null;
                    })
                    .map((rating) => {
                        return rating.profileImageUrl;
                    });
                if (ratingProfileImages.length > 0) {
                    return ratingProfileImages[0];
                } else {
                    return '';
                }
            } else {
                return '';
            }
        } else {
            return ProfileApi.getProfileImageUrl(inv.receivingUserId);
        }
    };

    renderInvitations(which: OutgoingInvitationResult[]) {
        return which.map((inv, inviteIndex) => (
            <Row key={'inv-out-' + inv.id} className="invited-contact" data-email={inv.name} data-stars={inv.platformRatings[0] ? inv.platformRatings[0].stars : "0"}>
                <td className="align-middle">
                    <OverlayTrigger
                        trigger={['hover']}
                        placement="right"
                        overlay={this.statusPopover(inv.status, inv.name)}
                    >
                        {this.renderStatus(inv)}
                    </OverlayTrigger>
                </td>

                <td className="align-middle">
                    <OverlayTrigger
                        trigger={['hover']}
                        placement="right"
                        overlay={this.availabilityPopover(
                            inv.sharingAvailability,
                            inv.name
                        )}
                    >
                        {this.renderAvailability(inv)}
                    </OverlayTrigger>
                </td>
                <td className="align-middle">
                    <div className="profile" onClick={() => {
                        if (inv.sharingAvailability)
                            window.open(`/contact/${inv.contactId}`)
                    }}>
                        <ProfileImage
                            selectable={inv.sharingAvailability}
                            onSelect={() => this.selectedProfile(inv)}
                            url={this.hasProfileImageUrl(inv)!}
                            type="Organisation"
                            size={36}
                        />
                        <div>
                            <p>{inv.name}</p>
                            <p>{inv.email}</p>
                        </div>
                    </div>
                </td>
                <td className="align-middle">
                    <WorkerRater
                        showProfile={false}
                        showSave={false}
                        hideOnOneStar={false}
                        mode={"modal"}
                        contacts={[ inv.platformRatings && inv.platformRatings[0] ? {
                            contactId: inv.contactId,
                            stars: inv.platformRatings[0].stars,
                            publicComment: inv.platformRatings[0].publicComment,
                            privateComment: inv.platformRatings[0].privateComment,
                            userId: "",
                            name: inv.name
                        } : {
                            contactId: inv.contactId,
                            stars: inv.ratings && inv.ratings.length !== 0 ? inv.ratings[0].rating : 0,
                            publicComment: "",
                            privateComment: "",
                            userId: "",
                            name: inv.name
                        } as ContactWithRating ]}
                        onEdit={(contact) => {
                            return contact;
                        }}
                        onClick={() => {
                            if (!inv.contactId) {
                                alert("This is an old contact, who cannot be rated anymore.")
                                window.location.reload();
                            } else if (inv.status === 2) {
                                window.location.href = "/contact/" + inv.contactId;
                            }
                        }}
                    />
                </td>
                <td className="align-middle">
                    {moment(inv.created).format('DD-MMM-YY')}
                </td>
                <td
                    className="align-middle"
                    style={{
                        fontWeight: 600,
                        color: inv.autoinvited ? 'Blue' : 'Red'
                    }}
                >
                    <OverlayTrigger
                        trigger={['hover']}
                        placement="left"
                        overlay={this.autoPopover(inv.autoinvited)}
                        rootClose
                    >
                        <span>{inv.autoinvited ? 'Automatic' : 'Manual'}</span>
                    </OverlayTrigger>
                </td>
                <td className="align-middle">
                    {inv.status == NotificationStatusType.Pending ? (
                        <OverlayTrigger
                            trigger={['hover', 'focus']}
                            placement="right"
                            overlay={this.deletePopover}
                        >
                            <i
                                className="fa fa-trash-o"
                                title=""
                                onClick={() => this.handleDelete(inv.id)}
                            />
                        </OverlayTrigger>
                    ) : null}
                </td>
            </Row>
        ));
    }

    renderAvailability(inv: OutgoingInvitationResult) {
        return inv.sharingAvailability ? (
            <span
                role="img"
                aria-label="connected"
                style={{
                    fontSize: 'large',
                    cursor: 'pointer',
                    color: '#62cb31'
                }}
                onClick={() => this.selectedProfile(inv)}
            >
                ✓
            </span>
        ) : (
            <span
                aria-label="disconnected"
                style={{
                    fontSize: 'medium',
                    color: 'red'
                }}
            >
                ✘
            </span>
        );
    }

    renderStatus(inv: OutgoingInvitationResult) {
        let element: JSX.Element;

        switch (inv.status) {
            case NotificationStatusType.Pending:
                element = (
                    <span
                        role="img"
                        aria-label="pending"
                        className="pending"
                        style={{ fontSize: 'small' }}
                    >
                        ⏳
                    </span>
                );
                break;
            case NotificationStatusType.Failed:
            case NotificationStatusType.NotSpecified:
            case NotificationStatusType.Rejected:
                element = (
                    <span className="failed" style={{ fontSize: 'large' }}>
                        ✗
                    </span>
                );
                break;
            case NotificationStatusType.Receiving:
                element = (
                    <span className="accepted" style={{ fontSize: 'large' }}>
                        ✓
                    </span>
                );
                break;
            default:
                element = <span>{NotificationStatusType[inv.status]}</span>;
                break;
        }

        return element;
    }
}

const Row = styled.tr`
    > td {
        padding: 8px !important;
    }

    .react-stars {
        min-width: 72px;
    }

    .profile {
        display: flex;
        align-items: center;
        justify-content: left;

        div:not(.thumbnail) {
            padding-left: 12px;

            p {
                margin: 0;

                &:last-child {
                    margin-top: 4px;
                }
            }
        }
    }

    span.failed {
        color: #e74c3c;
    }

    span.cancelled {
        color: #ffb606;
    }

    span.accepted {
        color: #62cb31;
    }

    .fa-trash-o {
        margin-left: 25px;
        font-size: 20px;
        cursor: pointer;
        opacity: 0.7;

        &:hover {
            opacity: 1;
        }
    }
`;
