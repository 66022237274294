/* eslint-disable */
import React from 'react';
import styled from 'styled-components';
import moment, { Moment } from 'moment';
import InfiniteScroll from 'react-infinite-scroller';

import AvailabilityApi from '../../api/availability/Availability';
import EventApi from '../../api/events/Events';
import { TimelineEventDto } from '../../api/availability/ResponseTypes';
import { sortByProp } from '../../constants';

interface Props {
    userId: string;
    userName: string;
}

interface State {
    date: Moment;
    days: number;
    current: Moment;
    events: TimelineEventDto[];
    maxAvailabilityDate: string | undefined;
}

const PAGE_SIZE = 50;

export default class CalendarList extends React.Component<Props, State> {
    private scroller: React.RefObject<HTMLDivElement>;
    private loaded = false;
    private fetchedRanges: string[] = [];
    private autoScrollTimeout?: any;

    constructor(props: Props) {
        super(props);

        this.state = this.getNewState();

        this.scroller = React.createRef();
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.userId != this.props.userId) {
            this.fetchedRanges = [];
            this.loaded = false;
            this.setState(this.getNewState());
        }

        // If first render
        if (!this.loaded) {
            // Navigate to today
            this.loaded = this.handleTodayClick();
        }
    }

    render() {
        return (
            <Wrapper className="layout vertical">
                <DayHeader>
                    <div className="month-row">
                        <i
                            className="fa fa-angle-left"
                            onClick={() => this.handleMonthChange(-1)}
                        />
                        <span>{this.state.current.format('MMMM YYYY')}</span>
                        <i
                            className="fa fa-angle-right"
                            onClick={() => this.handleMonthChange(1)}
                        />
                    </div>
                    <div>
                        <DayLetter>M</DayLetter>
                        <DayLetter>T</DayLetter>
                        <DayLetter>W</DayLetter>
                        <DayLetter>T</DayLetter>
                        <DayLetter>F</DayLetter>
                        <DayLetter>S</DayLetter>
                        <DayLetter>S</DayLetter>
                    </div>
                </DayHeader>
                <DayNumbers
                    onTouchStart={this.handleDateSwipeStart}
                    onTouchMove={this.handleDateSwipeMove}
                    onTouchEnd={this.handleDateSwipeEnd}
                >
                    {this.renderDayNumbers()}
                </DayNumbers>
                {this.renderNavigateToday()}
                <Scroller
                    ref={this.scroller}
                    onScroll={this.handleScroll}
                    className="flex"
                >
                    <InfiniteScroll
                        pageStart={0}
                        loadMore={this.getEvents}
                        hasMore={true}
                        loader={LOADER}
                        useWindow={false}
                    >
                        <div key="ifinite-scroll-child">
                            {this.renderItems()}
                        </div>
                    </InfiniteScroll>
                </Scroller>
            </Wrapper>
        );
    }

    renderDayNumbers() {
        const days: React.ReactNode[] = [];

        for (let i = -7; i < 14; i++) {
            const date = this.state.current
                .clone()
                .startOf('isoWeek')
                .add(i, 'days');
            const dateFormatted = date.format(ISO_FORMAT);

            days.push(
                <DayNumber
                    key={`day-${dateFormatted}`}
                    current={this.state.current.isSame(date)}
                    onClick={() => this.navigateToDate(dateFormatted)}
                    index={i}
                >
                    <span>{date.format('D')}</span>
                </DayNumber>
            );
        }

        return days;
    }

    renderItems() {
        const items: JSX.Element[] = [];

        const d = this.state.date.clone();

        for (let i = 0; i <= this.state.days; i++) {
            const dateFormatted = d.format(ISO_FORMAT);

            items.push(
                <CalendarDate
                    key={`row-${dateFormatted}`}
                    current={d.isSame(this.state.current, 'day')}
                    data-date={dateFormatted}
                >
                    <label>
                        {/* {d.isSame(moment(), 'day') ? 'Today - ' : ''}
                        {d.isSame(moment().add(1, 'days'), 'day')
                            ? 'Tomorrow - '
                            : ''} */}
                        <span>{d.format('ddd, D MMM')}</span>
                    </label>
                    <div>{this.renderEvents(d)}</div>
                </CalendarDate>
            );

            d.add(1, 'days');
        }

        return items;
    }

    renderEvents(d: Moment) {
        const events = this.state.events.filter((ev) =>
            moment(ev.start).isSame(d, 'day')
        );

        if (!events.length) {
            const max = this.state.maxAvailabilityDate;

            if (max && moment(d) < moment(max).endOf('day')) {
                return (
                    <UnavailableEvent
                        key={`unavailable-${moment(d).format(ISO_FORMAT)}`}
                    >
                        Unavailable
                    </UnavailableEvent>
                );
            } else {
                return (
                    <AvailableEvent
                        key={`available-${moment(d).format(ISO_FORMAT)}`}
                    >
                        Available
                    </AvailableEvent>
                );
            }
        }

        return sortByProp(events, (ev) => ev.start).filter(ev => typeof ev === "object").map((ev) => {
            const icon = `/icons/${ev.eventTypeId === 1 ? 'Briefcase' : ev.eventTypeId === 2 ? 'PadlockClosed' : ev.eventTypeId === 3 ? 'PadlockOpen' : 'Wave' }.svg`;
            const start = moment(ev.start);
            const end = moment(ev.end);

            return (
                <Event key={ev.id}>
                    <span>
                        {ev.repeat && <i className="fa fa-repeat" />}
                        {!end.isSame(start, 'day') && (
                            <i className="fas fa-moon" />
                        )}
                        <img src={icon} />
                    </span>
                    <span>
                        {start.format('HH:mm')}-{end.format('HH:mm')}
                    </span>
                    <span>{ev.eventTypeId === 1 ? "Working" : ev.eventTypeId === 2 ? "Private" : ev.eventTypeId === 3 ? "Private - Open to Offers" : "Available"}</span>
                </Event>
            );
        });
    }

    renderNavigateToday() {
        const diffToday = this.state.current.diff(moment(), 'days');

        const prev = diffToday >= 7;
        const next = diffToday < -7;

        return (
            <NavigateToday show={prev || next} onClick={this.handleTodayClick}>
                <span>GO TO TODAY</span>
                <i className={`fa ${next ? 'fa-angle-down' : 'fa-angle-up'}`} />
            </NavigateToday>
        );
    }

    getNewState = () => {
        return {
            date: moment()
                .startOf('isoWeek')
                .add(-14, 'days'),
            days: 0,
            current: moment().startOf('day'),
            events: [] as TimelineEventDto[],
            maxAvailabilityDate: undefined
        };
    };

    getEvents = async (page?: number, refreshAll?: boolean) => {
        if (!this.props.userId) return;

        // Start and end range for event query
        let start: Moment;
        let end: Moment;

        if (refreshAll) {
            // Reload all
            start = this.state.date.clone();
            end = start.clone().add(this.state.days, 'days');
        } else {
            // Next page
            start = this.state.date.clone().add(this.state.days, 'days');
            end = start.clone().add(PAGE_SIZE, 'days');
        }

        // Key for this range
        const key = `${start.format(ISO_FORMAT)}-${end.format(ISO_FORMAT)}`;

        // If this range hasn't already been queried (can be called multiple times due to the way InfiniteScroll renders)
        if (!~this.fetchedRanges.indexOf(key) || refreshAll) {
            // Don't fetch this range again
            this.fetchedRanges.push(key);

            // Query events
            const response = await AvailabilityApi.getEventsForUserBetweenDatesIncNew(
                this.props.userId,
                start,
                end
            );

            // Set data
            this.setState({
                days: this.state.days + PAGE_SIZE,
                // @ts-ignore
                events: refreshAll
                    ? response
                    // @ts-ignore
                    : this.state.events.concat(response).filter(ev => ev !== true),
                maxAvailabilityDate: await this.getMaxAvailabilityDate()
            });
        }
    };

    getMaxAvailabilityDate = async () => {
        if (this.state.maxAvailabilityDate == undefined) {
            const maxAvailabilityDate = await EventApi.getUserMaximumAvailabilityDate(
                this.props.userId
            );

            return maxAvailabilityDate;
        }

        return this.state.maxAvailabilityDate;
    };

    handleScroll = (ev) => {
        const scrollEvent = { ...ev };

        clearTimeout(this.autoScrollTimeout);

        // Wait 40s for scrolling to stop before actioning
        this.autoScrollTimeout = setTimeout(() => {
            this.handleScrollAction(scrollEvent);
        }, 40);
    };

    handleScrollAction = (ev) => {
        // Calculate current date offset
        const dateOffset =
            ev.currentTarget.scrollTop + ev.currentTarget.offsetTop //- 50;

        // Get date rows
        const dateRows = Array.from<HTMLDivElement>(
            ev.currentTarget.querySelectorAll('div[data-date]')
        );

        // Find target date row
        const target = dateRows.filter((ele) => ele.offsetTop > dateOffset)[0];

        if (target) {
            // Get the date for the target row
            const date = moment(target.getAttribute('data-date')!, ISO_FORMAT);

            if (
                date &&
                date.isValid() &&
                !this.state.current.isSame(date, 'day')
            ) {
                // Set as current
                this.setState({
                    current: date
                });
            }
        }
    };

    navigateToDate = (date: string): boolean => {
        const scroller = this.scroller.current;

        if (scroller) {
            // Get target event date row
            const target = scroller.querySelector<HTMLDivElement>(
                `[data-date="${date}"]`
            );
            if (target) {
                // Scroll to that row
                scroller.scrollTop = target.offsetTop - scroller.offsetTop;

                // Set as current date
                this.setState({
                    current: moment(date, ISO_FORMAT)
                });

                return true;
            }
        }

        return false;
    };

    swipeInfo?: {
        start: { x: number; y: number };
        end?: { x: number; y: number };
    };

    handleDateSwipeStart = (ev: TouchEvent) => {
        this.swipeInfo = {
            start: { x: ev.touches[0].screenX, y: ev.touches[0].screenY }
        };
    };

    handleDateSwipeMove = (ev: TouchEvent) => {
        if (this.swipeInfo) {
            this.swipeInfo.end = {
                x: ev.touches[0].screenX,
                y: ev.touches[0].screenY
            };
        }
    };

    handleDateSwipeEnd = (ev: TouchEvent) => {
        if (this.swipeInfo && this.swipeInfo.end) {
            const { start, end } = this.swipeInfo;

            // How far swiped vertically
            const dY = Math.abs(start.y - end.y);

            // Only trigger swipe if less than 40px tranvelled vertically
            if (dY < 40) {
                // How far swiped horizontally
                const dX = start.x - end.x;

                if (dX > 60) {
                    // Swipe left
                    this.navigateToDate(
                        this.state.current
                            .clone()
                            .startOf('isoWeek')
                            .add(7, 'days')
                            .format(ISO_FORMAT)
                    );
                } else if (dX < -60) {
                    // Swipe right
                    this.navigateToDate(
                        this.state.current
                            .clone()
                            .startOf('isoWeek')
                            .add(-7, 'days')
                            .format(ISO_FORMAT)
                    );
                }
                // else didn't swipe enough
            }
        }

        this.swipeInfo = undefined;
    };

    handleTodayClick = (): boolean => {
        // Scroll to today
        return this.navigateToDate(moment().format(ISO_FORMAT));
    };

    handleMonthChange = (delta: -1 | 1) => {
        this.navigateToDate(
            this.state.current
                .clone()
                .add(delta, 'months')
                .startOf('month')
                .format(ISO_FORMAT)
        );
    };
}

const ISO_FORMAT = 'YYYY-MM-DD';
const SEVENTH = 100 / 7;
const BLUE = '#44F';
const GREY = '#EBEBEB';
const LOADER = (
    <div key="infinite-scroll-loader" className="loader">
        Loading ...
    </div>
);

const Wrapper = styled.div`
    height: calc(
        100vh - ${~window.location.href.indexOf('/external/') ? '185' : '135'}px
    );

    * {
        user-select: none;
    }
`;

const Scroller = styled.div`
    scroll-behavior: smooth;
    padding: 0 15px;
    overflow-y: auto;
    color: #888;
    font-size: 3.5vw;
    background: white;
`;

const CalendarDate = styled.div`
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid grey;

    > label {
        width: 100%;
        padding: 5px 0 !important;
        color: ${(props) => (props.current ? BLUE : 'inherit')};
        font-weight: ${(props) => (props.current ? 700 : 600)};
        flex-basis: 90px;

        span {
            display: block;
        }
    }

    > div {
        padding-left: 10px;
        flex-basis: calc(100% - 90px);
    }
`;

const Event = styled.div`
    position: relative;
    padding: 5px 0;
    color: #000;
    display: flex;
    align-items: center;

    > span:first-child {
        margin-right: 10px;

        img {
            height: 20px;
        }
    }

    > span:nth-child(2) {
        margin-right: 10px;
    }

    > span:nth-child(3) {
        
    }

    > span:last-child {

        i {
            position: relative;
            top: 2px;
            margin-right: 5px;
            color: green;
        }
    }
`;

const UnavailableEvent = styled.span`
    display: block;
    padding: 5px 0;
    color: rgba(220, 100, 100);
    font-size: 14px;
`;

const AvailableEvent = styled.span`
    display: block;
    padding: 5px 0;
    color: rgba(100, 220, 100);
    font-size: 14px;
`;

const DayHeader = styled.div`
    .month-row {
        position: relative;

        > span {
            display: block;
            margin-bottom: -1px;
            padding: 10px;
            color: white;
            font-size: 4vw;
            letter-spacing: 0.7px;
            text-align: center;
            background: ${BLUE};
        }

        > i {
            position: absolute;
            top: 5px;
            color: white;
            font-size: 32px;

            &:first-child {
                left: 10px;
            }

            &:last-child {
                right: 10px;
            }
        }
    }
`;

const DayLetter = styled.span`
    display: inline-block;
    width: ${SEVENTH}%;
    padding: 10px 0;
    color: white;
    font-size: 2.8vw;
    text-align: center;
    background: ${BLUE};
`;

const DayNumbers = styled.div`
    position: relative;
    width: 100vw;
    height: ${SEVENTH}vw;
    background: white;
    border-bottom: 1px solid ${GREY};
`;

const DayNumber = styled.div`
    position: absolute;
    left: ${(props) => props.index * SEVENTH}vw;
    display: inline-block;
    width: ${SEVENTH}vw;
    height: ${SEVENTH}vw;
    font-size: 3.5vw;
    text-align: center;
    transition: 0.3s left ease;

    > span {
        position: absolute;
        top: 50%;
        width: 30px;
        height: 30px;
        color: ${(props) => (props.current ? 'white' : '#666')};
        line-height: 30px;
        background: ${(props) => (props.current ? BLUE : 'white')};
        border-radius: 50%;
        transform: translate(-50%, -50%);
    }
`;

const NavigateToday = styled.div`
    height: ${(props) => (props.show ? '25px' : '0')};
    overflow: hidden;
    color: white;
    line-height: 25px;
    text-align: center;
    background: #33a;
    transition: 0.3s height ease;

    > span {
        margin-right: 5px;
        letter-spacing: 0.7px;
    }

    > i {
        position: relative;
        top: 2px;
        color: orange;
        font-size: 20px;
    }
`;
