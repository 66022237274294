/* eslint-disable */
/* eslint-disable @typescript-eslint/ban-ts-ignore */
import React from 'react';
import styled from 'styled-components';
import moment, { now } from 'moment';
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { Badge } from 'react-bootstrap';
import { Range } from 'react-range';

import AppConfig from '../../components/config/Config';

import OrganisationAPI from '../../api/organisation/Organisation';
import SubscriptionsApi from '../../api/subscriptions/Subscriptions';
import {
    SubscriptionsResponse,
    FeatureSubscriptionItem,
    FeatureSubscription,
} from '../../api/subscriptions/ResponseTypes';
import { UpdateSubscriptionItemRequest } from '../../api/subscriptions/RequestTypes';
import { Spinner } from '../ui-components/Spinner';
import theme from '../../css/theme';

import '../../css/input-range.css';
import '../../css/subscriptions.css';

import {
    SubscriptionType,
    SubscriptionRelationshipType,
    capitaliseFirstLetter,
    Feature
} from '../../constants';
import { FeatureItem } from '../../api/subscriptions/ResponseTypes';
import { OrganisationUser } from '../../api/organisation/ResponseTypes';
import { AsyncOverlay } from '../ui-components/AsyncOverlay';
import { locationsInputRangeSharedConfig } from './LocationsSubscription';
// import InputRange from '../ui-components/InputRange';
import SignalRService from '../../services/signalr.service';

import InputRange from 'react-input-range';
import Toggle from 'react-toggle'

import SvgMap from '../svg/map';
import SvgRota from '../svg/rota';
import SvgTeams from '../svg/teams';
import Utilities from '../../Utilities';

import { ApplicationState } from '../../store';
import * as ProfileStore from '../../store/Profile';
import { connect, ConnectedProps } from 'react-redux';
import InternalTracker from '../../InternalTracker';

import '../../css/Organisations.css';
import FullScreenLoader from '../ui-components/FullScreenLoader';
import { actionCreators as profileActionCreators } from '../../store/Profile';

const SMS_CREDIT_INCREMENTS = 150;
const SMS_CREDIT_PRICE = 0.07;

interface State {
    loaded: boolean;
    fullscreenLoadingMessage: null | string;
    subscriptions: SubscriptionsResponse;
    manageFeatureId: Feature | null;
    manageItemId: SubscriptionsResponse[0]['items'][0]['id'] | null;
    itemToManageOption: any;
    updatingFeatureUnitsPurchased: number;
    isUpdatingFeature: boolean;
    hubConnection: any | null;

    locationPriceDefinition: FeatureItem | null;
    teamsPriceDefinition: FeatureItem | null;
    rotaPriceDefinition: FeatureItem | null;

    locationsCountNewSubscription: number;
    rotaChecked: boolean;

    changedSubscriptions: number[];
    newSubscriptions: number[];
    subscriptionUpdating: boolean;

    members: OrganisationUser[] | null;
    guide: "locations" | "rota" | "teams" | null;
    justCreatedRotaSubscription: boolean;
    smsCreditQuantity: number;
    successMessageInline?: {
        element: string;
        message: string;
    }
}

class ManageSubscriptions extends React.Component<{
    url?: string;
    hintedElement?: string | null;
    resetHintedElement?: Function;
    updateHintedElement?: Function;
    refresh?: Function;
    onCreate?: Function;
} & ConnectedProps<typeof connector>, State> {
    state = {
        loaded: false,
        subscriptions: [],
        manageFeatureId: null,
        manageItemId: null,
        itemToManageOption: null,
        isUpdatingFeature: false,
        updatingFeatureUnitsPurchased: 0,
        hubConnection: null,
        locationsCountNewSubscription: 0,
        rotaChecked: false,
        locationPriceDefinition: null,
        teamsPriceDefinition: null,
        rotaPriceDefinition: null,
        changedSubscriptions: [],
        newSubscriptions: [],
        subscriptionUpdating: false,
        members: [],
        guide: null,
        justCreatedRotaSubscription: false,
        smsCreditQuantity: SMS_CREDIT_INCREMENTS,
        fullscreenLoadingMessage: null
    } as State;

    _isMounted = false;

    componentDidUpdate() {
        if (this._isMounted && (this.state.guide !== (window.location.pathname.endsWith("/rota") ? "rota" : window.location.pathname.endsWith("/teams") ? "teams" : window.location.pathname.endsWith("/locations") ? "locations" : null)) ) {
            this.setState({ guide: window.location.pathname.endsWith("/rota") ? "rota" : window.location.pathname.endsWith("/teams") ? "teams" : window.location.pathname.endsWith("/locations") ? "locations" : null })
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this.getSubscriptions();
        this.getSubscriptionDefinitions();

        if (process.env.JEST_WORKER_ID === undefined) {
            this.getTeamMembers();
            const hub = SignalRService.create('subscriptions');
            hub.on('LocationsModified', this.getSubscriptions);
            hub.on('TeamsModified', this.getSubscriptions);   
        }

        if (localStorage.getItem("successMessage")) {
            const msg = localStorage.getItem("successMessage")?.split("|");
            if (msg) {
                this.setState({
                    successMessageInline: {
                        element: msg[0],
                        message: msg[1]
                    }
                })

                localStorage.removeItem("successMessage");
            }
            profileActionCreators.getUserProfile();
        }

        if (location.href.indexOf("/subscriptions/rota") !== -1 && !(window as any).justCreatedRotaSubscription) {
            (window as any).justCreatedRotaSubscription = true;
            this.saveNewSubscription(3, 1, 9)
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        if (this.state.hubConnection) {
            this.state.hubConnection.stop();
        }
    }

    getTeamMembers = () => {
        OrganisationAPI.getUsers(this.props.authenticatedUserProfile.organisationId || "", "").then((res) =>
            this.setState({ members: res ? res.data : null })
        );
    };

    getSubscriptionDefinitions = async () => {
        const features = await SubscriptionsApi.getFeatures().catch(() => []);

        if (features) {
            for (let i = 0; i < features.length; i++) {
                const feature = features[i];
                if (feature.featureType === Feature.Locations) {
                    this.setState({ locationPriceDefinition: feature });
                } else if (feature.featureType === Feature.Teams) {
                    this.setState({ teamsPriceDefinition: feature });
                } else if (feature.featureType === Feature.Rota) {
                    this.setState({ rotaPriceDefinition: feature });
                }

            }
        }
    };

    getSubscriptions = async () => {

        this.setState({ loaded: false, changedSubscriptions: [], newSubscriptions: [] });
        const subscriptions = await SubscriptionsApi.getAllFeatureSubscriptions().catch(
            () => []
        );

        this.setState({
            subscriptions: subscriptions.filter(
                ({ subscriptionRelationshipType }) =>
                    subscriptionRelationshipType ===
                    SubscriptionRelationshipType.Organisation
            ).map(item => {
                item.items = item.items as FeatureSubscriptionItem[];
                return item;
            }),
            loaded: true,
            subscriptionUpdating: false
        })
    };

    formatTotalPrice = () => {
        return '£' + this.getTotalPrice() + ' / month';
    }

    getTotalPrice = () => {
        let priceSum = 0;
        let subs = JSON.parse(JSON.stringify(this.state.subscriptions));
        for (let i = 0; i < subs.length; i++) {
            for (let j = 0; j < subs[i].items.length; j++) {
                const item = subs[i].items[j];
                if (subs[i].featureId === Feature.Teams && item.unitsPurchased > 0) {
                    item.unitsPurchased--;
                }
                priceSum += item.unitPrice * item.unitsPurchased;
            }
        }
        return (priceSum / 100).toFixed(2)
    }

    priceFormatter = (unitsPurchased, unitPrice, unitQuantity) => {
        return '£' + ( (unitsPurchased * unitPrice) / 100 ).toFixed(2) + ' / month';
    }

    basePriceFormatter = (unitPrice) => {
        return '£' + ( (unitPrice) / 100 ).toFixed(2);
    }

    addRotaSubscription = () => {

        let newSubscriptions: SubscriptionsResponse = JSON.parse(JSON.stringify(this.state.subscriptions));
        let newId: number = Utilities.randomIntFromInterval(8000000, 9000000);

        let subscription: FeatureSubscription = {
            title: "New",
            subscriptionRelationshipType: 2,
            subscribed: 1,
            featureId: Feature.Rota,
            paymentProviderId: 1,
            renews: "",
            expires: null,
            trial: 1,
            items: [],
        }

        let subscriptionItem: FeatureSubscriptionItem = {
            id: newId, 
            unitsPurchased: 1,
            capacityUsed: 0,
            unitQuantity: 1,
            unitLabel: "rota",
            billingFrequency:"monthly",
            unitPrice: this.state.teamsPriceDefinition?.unitPrice || 0,
        }

        let subscriptionGroupExists = false;

        for (let i = 0; i < newSubscriptions.length; i++) {
            if (newSubscriptions[i].featureId === Feature.Rota) {
                newSubscriptions[i].items.unshift(subscriptionItem)
                subscriptionGroupExists = true;
                break;
            }
        }

        if (!subscriptionGroupExists) {
            subscription.renews = Utilities.dateAdd(new Date(), "week", 2).toISOString();
            subscription.items.push(subscriptionItem);
            newSubscriptions.push(subscription);
        }

        this.setState({
            subscriptions: newSubscriptions,
            justCreatedRotaSubscription: true
        });
        this.addToChangedSubscriptions(newId);
        this.addToNewSubscriptions(newId);
        localStorage.setItem("rotaSubscriptionSaved", "true");

    }

    addNewTeam = () => {

        InternalTracker.trackEvent("", {
            category: 'Teams',
            action: 'Add new Team Subscription Clicked'
        });

        let newSubscriptions: SubscriptionsResponse = JSON.parse(JSON.stringify(this.state.subscriptions));
        let newId: number = Utilities.randomIntFromInterval(8000000, 9000000);

        let subscription: FeatureSubscription = {
            title: "New",
            subscriptionRelationshipType: 2,
            subscribed: 1,
            featureId: Feature.Teams,
            paymentProviderId: 1,
            renews: "-",
            expires: null,
            trial: 1,
            items: [],
        }

        let subscriptionItem: FeatureSubscriptionItem = {
            id: newId, 
            unitsPurchased: 1,
            capacityUsed: 0,
            productOptions : { 
                team: {
                    id: 0,
                    name: "",
                    leaderUserId: ""
                }
            },
            unitQuantity: this.state.teamsPriceDefinition?.unitQuantity || 0,
            unitLabel: "users",
            billingFrequency:"monthly",
            unitPrice: this.state.teamsPriceDefinition?.unitPrice || 0,
        }

        let subscriptionGroupExists = false;

        for (let i = 0; i < newSubscriptions.length; i++) {
            if (newSubscriptions[i].featureId === Feature.Teams) {
                newSubscriptions[i].items.push(subscriptionItem)
                subscriptionGroupExists = true;
                break;
            }
        }

        if (!subscriptionGroupExists) {
            subscription.renews = Utilities.dateAdd(new Date(), "week", 2).toISOString();
            subscription.items.push(subscriptionItem);
            newSubscriptions.push(subscription);
        }

        this.setState({ subscriptions: newSubscriptions });
        this.addToChangedSubscriptions(newId);
        this.addToNewSubscriptions(newId);
        if (this.props.updateHintedElement)
            this.props.updateHintedElement("team-subscription-0");

        setTimeout(() => {
            let elNewSub = document.querySelector('.subscription[data-type="team"][data-team-id="0"]');
            if (elNewSub) {
                elNewSub.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start'
                });
            }
        }, 300)

    }

    addToChangedSubscriptions(newId) {
        if (this.state.changedSubscriptions.indexOf(newId) === -1)
            this.setState({ changedSubscriptions: [...this.state.changedSubscriptions, newId] })
    }

    addToNewSubscriptions(newId) {
        if (this.state.newSubscriptions.indexOf(newId) === -1)
            this.setState({ newSubscriptions: [...this.state.newSubscriptions, newId] })
    }

    editSubscription(type: Feature, id: number, quantity: number, isNew: boolean, priceId: number, name?: string, leaderUserId?: string, teamId?: number) {

        if (this.state.subscriptionUpdating)
            return;
    
        if (isNew) {
            this.saveNewSubscription(type, quantity, priceId, name, leaderUserId);
        } else {
            this.updateSubscription(type, quantity, id, name, leaderUserId, teamId)
        }

    }

    updateSubscription = async(type: Feature, quanity: number, id: number, name?: string, leaderUserId?: string, teamId?: number) => {
        
        this.setState({ subscriptionUpdating: true })

        let body: UpdateSubscriptionItemRequest = {
            unitsPurchased: quanity,
        };

        if (type === Feature.Teams) {

            if (!leaderUserId || leaderUserId === "0") {
                toast.error('Please select a team leader');
                this.setState({ subscriptionUpdating: false })
                return;
            }

            if (!name || name === "New Team") {
                toast.error('Please enter a name');
                this.setState({ subscriptionUpdating: false })
                return;
            }

            body.productOptions = {
                team: {
                    name: name || "",
                    leaderUserId: leaderUserId || this.props.authenticatedUserProfile.id
                }
            }

            if (teamId) {
                body.productOptions.team.id = teamId
            }

        }

        SubscriptionsApi.updateSubscriptionItem(id, body)
            .then(() => {

                InternalTracker.trackEvent("", {
                    category: 'Subscription',
                    action: 'Subscription Updated',
                    customDimensions: [
                        {
                            id: "REPLACE",
                            value: type.toString()
                        },
                        {
                            id: "REPLACE",
                            value: quanity.toString()
                        }
                    ]
                });

                toast.success('Subscription updated');
                this.getSubscriptions();
                if (this.props.refresh)
                    this.props.refresh();

            })
            .catch( async (e) => { 
                if (e === "No valid payment method on file.") {
                    toast.error(e.detail || "Payment card required")
                    this.setState({ subscriptionUpdating: false })
                    let url = await SubscriptionsApi.getCustomerPortalUrl();
                    window.location.href = url.url;
                } else {
                    toast.error(e || "Failed to update subscription")
                    this.setState({ subscriptionUpdating: false })
                    this.getSubscriptions();
                }
            })

    }

    saveNewSubscription = async(type: Feature, quanity: number, priceId: number, name?: string, leaderUserId?: string) => {

        this.setState({ subscriptionUpdating: true })

        const { publicApiKey } = AppConfig.Settings.stripe;

        let sessionPayload;

        if (type === Feature.Locations || type === Feature.Rota) {

            if (type === Feature.Locations) {
                if (quanity === 0) {
                    toast.error('Please select the number of locations');
                    this.setState({ subscriptionUpdating: false })
                    return;
                } else {
                    InternalTracker.trackEvent("", {
                        category: 'Locations',
                        action: 'Location Quantity Updated',
                        customDimensions: [{ id: "REPLACE", value: quanity.toString() }]
                    });
                }
            }

            sessionPayload = {
                items: [
                    {
                        priceId: priceId,
                        unitsPurchased: quanity
                    }
                ]
            }

        } else if (type === Feature.Teams) {

            if (!leaderUserId || leaderUserId === "0") {
                toast.error('Please select a team leader');
                this.setState({ subscriptionUpdating: false })
                return;
            }

            if (!name || name === "New Team") {
                toast.error('Please enter a name');
                this.setState({ subscriptionUpdating: false })
                return;
            }

            if (quanity === 0) {
                toast.error('Please select the number of members');
                this.setState({ subscriptionUpdating: false })
                return;
            }

            sessionPayload = {
                items: [
                    {
                        priceId: priceId,
                        unitsPurchased: quanity,
                        productOptions: {
                            team: {
                                name: name,
                                leaderUserId: leaderUserId || this.props.authenticatedUserProfile.id
                            }
                        }
                    }
                ]
            }
        } else {
            this.setState({ subscriptionUpdating: false })
            alert("Unknown feature");
            return;
        }

        SubscriptionsApi.createCheckoutSession(sessionPayload).then(sessionId => {

            InternalTracker.trackEvent("", {
                category: 'Subscription',
                action: 'Subscription Started',
                customDimensions: [
                    {
                        id: "REPLACE",
                        value: type.toString()
                    },
                    {
                        id: "REPLACE",
                        value: quanity.toString()
                    }
                ]
            });

            if ((window as any).dispatchRefreshUserProfile && type === Feature.Rota) {
                (window as any).onUserGetRedirectTo = "/schedule";
                (window as any).dispatchRefreshUserProfile();
            }

            if (sessionId === '') {
                toast.success('Subscription created');
                this.getSubscriptions();
                if (this.props.onCreate)
                    this.props.onCreate();
            } else {
                toast.success('Subscription created');
                this.getSubscriptions();
                if (this.props.onCreate)
                    this.props.onCreate();
                // TODO: Replace with @stripe/stripe-js for types and better fraud protection
                const Stripe = (window as any).Stripe;
                if (Stripe == null) return;
                const stripe = Stripe(publicApiKey);
                stripe
                    .redirectToCheckout({ sessionId })
                    .then((result) => {
                        toast.error(result.error.message)
                    });
            }

            if (this.props.refresh)
                this.props.refresh();
            
        }).catch( async e => {
            if (e === "No valid payment method on file.") {
                let url = await SubscriptionsApi.getCustomerPortalUrl();
                window.location.href = url.url;
            }
            toast.error(e.detail || "Failed to update subscription")
            this.getSubscriptions();
            if (this.props.onCreate)
                this.props.onCreate();
        });

    }

    willRenew(status: string, hasValidPaymentMethod: boolean, renews: string, expires: string, unitsPurchased: number): string {
        let str: string = "Renews on " + Utilities.formatDate(new Date(renews || ""), "DD MMM YYYY");
        if (status === "canceled") {
            str = "Canceled"
        } else if (!hasValidPaymentMethod) {
            str = "Expires on " + Utilities.formatDate(new Date(expires || ""), "DD MMM YYYY");;
        }
        return str.indexOf("NaN") !== -1 ? "" : str;
    }

    render() {

        if (!this.state.loaded || !this.state.members || !this.state.locationPriceDefinition || !this.state.teamsPriceDefinition || !this.state.rotaPriceDefinition || !this.state.subscriptions) {
            return (
                <div
                    className="layout horizontal center-center"
                    style={{ height: '100%' }}
                >
                    <Spinner />
                </div>
            );
        }

        const { subscriptions } = this.state;

        let locationSubscription: FeatureSubscriptionItem | null = subscriptions.filter(item => item.featureId === Feature.Locations)[0] ? subscriptions.filter(item => item.featureId === Feature.Locations)[0].items[0] : null;
        let locationSubscriptionMeta = subscriptions.filter(item => item.featureId === Feature.Locations)[0] ? subscriptions.filter(item => item.featureId === Feature.Locations)[0] : null;
        let locationRenewsLabel = locationSubscriptionMeta ? this.willRenew(locationSubscriptionMeta.status || "", locationSubscriptionMeta.hasValidPaymentMethod || false, locationSubscriptionMeta.renews || "", locationSubscriptionMeta.expires || "", locationSubscription?.unitsPurchased || 0) : "";
        let locationQuantityPriceLabel = this.state.locationPriceDefinition ? (this.state.locationPriceDefinition.unitQuantity + " for " + this.basePriceFormatter(this.state.locationPriceDefinition.unitPrice) + "/month")   : ""; 

        let rotaSubsciption: FeatureSubscriptionItem | null = subscriptions.filter(item => item.featureId === Feature.Rota)[0] ? {
            ...subscriptions.filter(item => item.featureId === Feature.Rota)[0].items[0],
            status: subscriptions.filter(item => item.featureId === Feature.Rota)[0].status,
        } : null;
        let rotaSubscriptionMeta = subscriptions.filter(item => item.featureId === Feature.Rota)[0] ? subscriptions.filter(item => item.featureId === Feature.Rota)[0] : null;
        let rotaRenewsLabel = rotaSubscriptionMeta ? this.willRenew(rotaSubscriptionMeta.status || "", rotaSubscriptionMeta.hasValidPaymentMethod || false, rotaSubscriptionMeta.renews || "", rotaSubscriptionMeta.expires || "", rotaSubsciption?.unitsPurchased || 0) : "";
        let rotaQuantityPriceLabel = this.state.rotaPriceDefinition ? (this.basePriceFormatter(this.state.rotaPriceDefinition.unitPrice) + "/month")   : "";

        let teamSubscriptions: FeatureSubscriptionItem[] = subscriptions.filter(item => item.featureId === Feature.Teams)[0] ? subscriptions.filter(item => item.featureId === Feature.Teams)[0].items : [];
        let teamSubscriptionMeta = subscriptions.filter(item => item.featureId === Feature.Teams)[0] ? subscriptions.filter(item => item.featureId === Feature.Teams)[0] : null;
        let teamQuantityPriceLabel = this.state.teamsPriceDefinition ? (this.state.teamsPriceDefinition.unitQuantity + " for " + this.basePriceFormatter(this.state.teamsPriceDefinition.unitPrice) + "/month")   : "";

        let smsQuqntityPriceLabel = SMS_CREDIT_INCREMENTS + " SMS for " + this.basePriceFormatter((SMS_CREDIT_PRICE * SMS_CREDIT_INCREMENTS * 100))
        
        return (
            <section
                className="main" 
                data-section="subscriptions" 
                style={{ marginBottom: 80 }}
            >
                <div className="section-header">
                    <h2>Subscriptions</h2>
                    <p> {/*Manage your subscriptions here. */}
                        { (this.getTotalPrice()) ?
                            `You authorise us to charge your credit / debit card monthly at ${this.formatTotalPrice() }, unless your subscription is cancelled. To cancel the subscription disable all features above. Different components of your subscriptions may renew at different times of the month.` :
                            `Achieve even more by using other premium features, with free 14 day no commitment trials.`
                        }
                    </p>
                    <div className="options">
                        <button
                            id="add-new-team-btn" 
                            data-testid="add-team" 
                            className={"" + ((this.state.guide === "teams" && (!teamSubscriptionMeta) && this.state.changedSubscriptions.length === 0) ? " pulse no-size" : "")} 
                            onClick={() => this.addNewTeam()}
                        >
                            Add new team
                        </button>
                        <button onClick={ async () => {
                            let url = await SubscriptionsApi.getCustomerPortalUrl();
                            window.location.href = url.url;
                        }}>
                            Manage Card Details
                        </button>
                        <button onClick={ async () => {
                            let url = await SubscriptionsApi.getCustomerPortalUrl();
                            window.location.href = url.url;
                        }}>
                            Invoices
                        </button>
                    </div>
                </div>
                <div className="section-content">

                    <div className="layout horizontal center-justified wrap subscriptions-manager">
                        <div>
                            { (subscriptions.length !== 0 && this.props.authenticatedUserProfile.subscriptionNotifications?.missingOrExpiredCard && this.props.authenticatedUserProfile.subscriptionNotifications.daysUntilNextSubscriptionExpiry && this.props.authenticatedUserProfile.subscriptionNotifications?.daysUntilNextSubscriptionExpiry < 7 ) &&
                                <div
                                    className="red-warning"
                                    style={{ cursor: 'pointer' }}
                                    onClick={ async () => {
                                        let url = await SubscriptionsApi.getCustomerPortalUrl();
                                        window.location.href = url.url;
                                    }}
                                >
                                    You have one or more expiring services in {this.props.authenticatedUserProfile.subscriptionNotifications.daysUntilNextSubscriptionExpiry} day(s), and no card details entered. Please add credit / debit card to keep enjoying all features or adjust your subscriptions.
                                    <button>Add credit / debit card</button>
                                </div>
                            }

                            <div className="subscriptions">

                                { teamSubscriptions.map((item, i) => {
                                    let meta = this.state.subscriptions.filter(item => item.featureId === Feature.Teams)[0];
                                    let renewsLabel = this.willRenew(meta.status || "", meta.hasValidPaymentMethod || false, meta.renews || "", meta.expires || "", item?.unitsPurchased || 0);
                                    let isLast = i === teamSubscriptions.length - 1;

                                    return (
                                        <div 
                                            key={item.id} 
                                            className={"subscription card" + (this.props.hintedElement === ("team-subscription-" + item.productOptions?.team.id) ? " guide-instruction" : "") }
                                            data-type="team" 
                                            data-team-id={item.productOptions?.team.id} 
                                            data-changed={this.state.changedSubscriptions.indexOf(item.id) !== -1}
                                        >
                                            <div className="card-header">
                                                <div style={{ background: (teamSubscriptionMeta && teamSubscriptionMeta.status === "trialing") ? theme.colours.orange : (item.unitsPurchased > 0 || (item.unitsPurchased === 1 && i === 0) ) ? theme.colours.green : theme.colours.red }}>
                                                    <i className='fas fa-users' />
                                                </div>
                                                <div>
                                                    <h4>Team "{item.productOptions?.team.name || "Untitled"}" Subscription</h4>
                                                    <p> 
                                                        <span className='renew-info'>
                                                            { (teamSubscriptionMeta && teamSubscriptionMeta.status === "trialing") &&
                                                                "Active Trial, then "
                                                            }
                                                            { (item.unitsPurchased > 0 || (item.unitsPurchased === 1 && i === 0) ) ?
                                                                ( (i === 0 && (item.unitsPurchased === 1 || item.unitsPurchased === 0) ) ? 
                                                                    "Free" : 
                                                                    (i === 0) ? 
                                                                        this.priceFormatter(item.unitsPurchased-1, item.unitPrice, item.unitQuantity) : 
                                                                        this.priceFormatter(item.unitsPurchased, item.unitPrice, item.unitQuantity)
                                                                ) :
                                                                "Not Active"
                                                            }
                                                        </span>
                                                        { (teamQuantityPriceLabel) &&
                                                            <span className='unit-price-info'>
                                                                {teamQuantityPriceLabel}
                                                            </span>
                                                        }
                                                        {renewsLabel && (", " + renewsLabel)}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="card-content">
                                                { (i === 0) &&
                                                    <p>
                                                        Your 1st Team of 3 colleagues is free, please choose invited colleagues to share contacts availability, offers and the staffing rota.
                                                    </p>
                                                }
                                                <div className="details">
                                                    <div className="name">
                                                        <input
                                                            id={isLast ? "team-name-input" : ""}
                                                            type="text"
                                                            placeholder={'Team name, E.g. "catering" or "cover staff"'}
                                                            className="form-control"
                                                            value={item.productOptions?.team.name}
                                                            onChange={(ev) => {
                                                                let newSubscriptions: SubscriptionsResponse = JSON.parse(JSON.stringify(this.state.subscriptions));
                                                                for (let j = 0; j < newSubscriptions.length; j++) {
                                                                    if (newSubscriptions[j].featureId === Feature.Teams) {
                                                                        // @ts-ignore
                                                                        newSubscriptions[j].items[i].productOptions.team.name = ev.target.value ? Utilities.capitalize(ev.target.value) : '';
                                                                        this.addToChangedSubscriptions(newSubscriptions[j].items[i].id);
                                                                        break;
                                                                    }
                                                                }
                                                                this.setState({ subscriptions: newSubscriptions })
                                                            }}
                                                            maxLength={255}
                                                        />
                                                    </div>
                                                    <div className="leader">
                                                        <select
                                                            className="form-control"
                                                            id={isLast ? "leader-select" : ""}
                                                            value={item.productOptions?.team.leaderUserId}
                                                            onChange={(ev) => {
                                                                let newSubscriptions: SubscriptionsResponse = JSON.parse(JSON.stringify(this.state.subscriptions));
                                                                for (let j = 0; j < newSubscriptions.length; j++) {
                                                                    if (newSubscriptions[j].featureId === Feature.Teams) {
                                                                        // @ts-ignore
                                                                        newSubscriptions[j].items[i].productOptions.team.leaderUserId = ev.target.value;
                                                                        this.addToChangedSubscriptions(newSubscriptions[j].items[i].id);
                                                                        break;
                                                                    }
                                                                }
                                                                this.setState({ subscriptions: newSubscriptions })
                                                            }}
                                                        >
                                                            <option value="select" >
                                                                Select Team Manager
                                                            </option>
                                                            { this.state.members?.map(member => {
                                                                return (
                                                                    <option
                                                                    key={member.id}
                                                                    value={member.id}
                                                                >
                                                                    {member.firstName} {member.lastName}
                                                                </option>
                                                                )
                                                            }) }
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="slider">
                                                    <p>Adjust the slider to change the number of colleagues in this team. The price of the subscription will change once you click the Confirm button</p>
                                                    <InputRange
                                                        maxValue={300}
                                                        minValue={0}
                                                        step={3}
                                                        formatLabel={value => `${value}`}
                                                        value={item.unitsPurchased * item.unitQuantity}
                                                        onChange={ (value) => {
                                                            // @ts-ignore
                                                            let newSubscriptions: SubscriptionsResponse = JSON.parse(JSON.stringify(subscriptions));
                                                            for (let i = 0; i < newSubscriptions.length; i++) {
                                                                if (newSubscriptions[i].featureId === Feature.Teams) {
                                                                    for (let j = 0; j < newSubscriptions[i].items.length; j++) {
                                                                        let team = newSubscriptions[i].items[j];
                                                                        if (team.id === item.id) {
                                                                            // @ts-ignore
                                                                            newSubscriptions[i].items[j].unitsPurchased = value / 3; // locationSubscription.unitQuantity;
                                                                            if (newSubscriptions[i].items[j].productOptions?.team.name === 'Colleagues' && newSubscriptions[i].items[j].unitsPurchased === 0) {
                                                                                newSubscriptions[i].items[j].unitsPurchased = 1;
                                                                            }
                                                                            this.addToChangedSubscriptions(newSubscriptions[i].items[j].id);
                                                                            this.setState({ subscriptions: newSubscriptions })
                                                                            break;
                                                                        }
                                                                    }
                                                                    break;
                                                                }
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                { this.state.changedSubscriptions.indexOf(item.id) !== -1 &&
                                                    <div className="opts">
                                                        <button id="create-team-btn" data-disabled={this.state.subscriptionUpdating} style={ ( (this.state.newSubscriptions.indexOf(item.id || 0) === -1) && (item.unitsPurchased === 0) ) ? { backgroundColor: theme.colours.red, border: 'none' } : {} } className="styled filled pulse no-size" onClick={() => { this.editSubscription(Feature.Teams, item.id || 0, item.unitsPurchased || 0, this.state.newSubscriptions.indexOf(item.id || 0) !== -1, this.state.teamsPriceDefinition?.priceId || 0, item.productOptions?.team.name || "New Team", item.productOptions?.team.leaderUserId || "0", item.productOptions?.team.id) }}>
                                                            { (this.state.newSubscriptions.indexOf(item.id || 0) !== -1) ? "Create Team" : (item.unitsPurchased === 0) ? "Cancel Subscription" : "Confirm Changes" }
                                                        </button>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    )
                                }) }
                                
                                <div className={"subscription card" + (this.props.hintedElement === "rota-subscription" ? " guide-instruction" : "") } data-type="rota">
                                    {/* { ( (rotaSubscriptionMeta && rotaSubscriptionMeta.status === "trialing") ) && <Badge color="danger" style={{ backgroundColor: theme.colours.red, color: "white", position: "absolute", top: -8, right: -8 }}>Trial</Badge> } */}
                                    <div className="card-header">
                                        <div style={{ background: (rotaSubscriptionMeta && rotaSubscriptionMeta.status === "trialing") ? theme.colours.orange : (rotaSubsciption && rotaSubsciption.unitPrice !== 0) ? theme.colours.green : theme.colours.red }}>
                                            <i className='far fa-calendar' />
                                        </div>
                                        <div>
                                            <h4>Schedule Subscription</h4>
                                            <p>
                                                <span className='renew-info'>
                                                    {
                                                        (rotaSubscriptionMeta && rotaSubscriptionMeta.status === "trialing") ?
                                                            "Trial Activated" :
                                                            (rotaSubsciption && rotaSubsciption.unitPrice !== 0) ? 
                                                                ("£" + (this.state.rotaPriceDefinition.unitPrice / 100) + " / month") :
                                                                "Not Active"
                                                    }
                                                    {rotaRenewsLabel && (", " + rotaRenewsLabel)}
                                                </span>
                                                { (rotaQuantityPriceLabel) &&
                                                    <div className='unit-price-info'>
                                                        {rotaQuantityPriceLabel}
                                                    </div>
                                                }
                                            </p>
                                        </div>
                                    </div>
                                    <div className="card-content">
                                        <p>This feature is made available for all members of the organization for a flat monthly rate. Use the below toggle to enable / disable the rota subscription</p>
                                        <div className="toggle-wrapper">
                                            <label data-testid="rota-toggle" className={((this.state.guide === "rota" && (!rotaSubscriptionMeta) && this.state.changedSubscriptions.length === 0) ? "pulse no-size" : "")}>
                                                <Toggle
                                                defaultChecked={(rotaSubsciption && (rotaSubsciption.unitsPurchased !== 0 || rotaSubsciption?.status === "active")) ? true : false}
                                                onChange={() => { 
                                                    // this.setState({ rotaChecked: !this.state.rotaChecked }) 
                                                    // @ts-ignore
                                                    let newSubscriptions: SubscriptionsResponse = JSON.parse(JSON.stringify(subscriptions));

                                                    if (rotaSubsciption) {
                                                        for (let i = 0; i < newSubscriptions.length; i++) {
                                                            if (newSubscriptions[i].featureId === Feature.Rota) {
                                                                newSubscriptions[i].items[0].unitsPurchased = (newSubscriptions[i].items[0].unitsPurchased !== 0 || newSubscriptions[i].status === "active") ? 0 : 1
                                                                this.addToChangedSubscriptions(newSubscriptions[i].items[0].id);
                                                                this.setState({ subscriptions: newSubscriptions })
                                                                break;
                                                            }
                                                        }
                                                    } else {
                                                        this.addRotaSubscription();
                                                    }

                                                }} />
                                            </label>
                                        </div>
                                        { rotaSubsciption && this.state.changedSubscriptions.indexOf(rotaSubsciption.id) !== -1 &&
                                            <div className="opts" style={{ marginTop: 15 }}>
                                                <button 
                                                    className="styled filled pulse no-size" 
                                                    data-disabled={this.state.subscriptionUpdating} 
                                                    onClick={() => { 
                                                        this.editSubscription(Feature.Rota, rotaSubsciption?.id || 0, rotaSubsciption?.unitsPurchased || 0, this.state.newSubscriptions.indexOf(rotaSubsciption?.id || 0) !== -1, this.state.rotaPriceDefinition?.priceId || 0);
                                                        if (this.props.resetHintedElement)
                                                            this.props.resetHintedElement();
                                                    }}>
                                                    { (this.state.justCreatedRotaSubscription) ? "Start free 14 day trial" : "Confirm Changes" }
                                                </button>
                                            </div>
                                        }
                                    </div>
                                </div>

                                <div className={"subscription card" + (this.props.hintedElement === "location-subscription" ? " guide-instruction" : "") } data-type="locations" data-changed={locationSubscription ? this.state.changedSubscriptions.indexOf(locationSubscription.id) !== -1 : false}>
                                    {/* { (  ) && <Badge color="danger" style={{ backgroundColor: theme.colours.red, color: "white", position: "absolute", top: -8, right: -8 }}>Trial</Badge> } */}
                                    <div className="card-header">
                                        <div style={{ background: (locationSubscriptionMeta && locationSubscriptionMeta.status === "trialing") ? theme.colours.orange : locationSubscription ? theme.colours.green : theme.colours.red }}>
                                            <i className='far fas fa-location-arrow' />
                                        </div>
                                        <div>
                                            <h4>Location Subscription</h4>
                                            <p>
                                                <span className='renew-info'>
                                                    { (locationSubscriptionMeta && locationSubscriptionMeta.status === "trialing") &&
                                                        "Active Trial, then "
                                                    }
                                                    { locationSubscription ?
                                                        this.priceFormatter(locationSubscription.unitsPurchased, locationSubscription.unitPrice, locationSubscription.unitQuantity) :
                                                        "Not Active"
                                                    }
                                                    {locationRenewsLabel && (", " + locationRenewsLabel)}
                                                </span>
                                                { (locationQuantityPriceLabel) &&
                                                    <div className='unit-price-info'>
                                                        {locationQuantityPriceLabel}
                                                    </div>
                                                }
                                            </p>
                                        </div>
                                    </div>
                                    <div className="card-content">
                                        <div className={"slider" + ((this.state.guide === "locations" && (!locationSubscriptionMeta) && this.state.changedSubscriptions.length === 0) ? " pulse no-size" : "")}>
                                            <p>Adjust the slider to change the number of locations you need. The price of the subscription will change once you click the Confirm button</p>
                                            <InputRange
                                                maxValue={300}
                                                minValue={0}
                                                step={3}
                                                formatLabel={value => `${value}`}
                                                value={locationSubscription ? (locationSubscription.unitsPurchased * locationSubscription.unitQuantity) : 0}
                                                onChange={ (value) => {
                                                    if (locationSubscription) {
                                                        let newSubscriptions: SubscriptionsResponse = JSON.parse(JSON.stringify(subscriptions));
                                                        for (let i = 0; i < newSubscriptions.length; i++) {
                                                            if (newSubscriptions[i].featureId === Feature.Locations) {
                                                                // @ts-ignore
                                                                newSubscriptions[i].items[0].unitsPurchased = value / locationSubscription.unitQuantity;
                                                                this.setState({
                                                                    subscriptions: newSubscriptions,
                                                                }, () => {
                                                                    this.addToChangedSubscriptions(newSubscriptions[i].items[0].id)
                                                                })
                                                                break;
                                                            }
                                                        }
                                                    } else {
                                                        let newId: number = Utilities.randomIntFromInterval(8000000, 9000000);
                                                        let newSubscriptions: SubscriptionsResponse = JSON.parse(JSON.stringify(this.state.subscriptions));
                                                        newSubscriptions.push({
                                                            title: "New",
                                                            subscriptionRelationshipType: 2,
                                                            subscribed: 1,
                                                            featureId: Feature.Locations,
                                                            paymentProviderId: 1,
                                                            renews: Utilities.dateAdd(new Date(), "week", 2).toISOString(),
                                                            expires: null,
                                                            trial: 1,
                                                            items: [{
                                                                id: newId, 
                                                                // @ts-ignore
                                                                unitsPurchased: value / 3,
                                                                capacityUsed: 0,
                                                                unitQuantity: this.state.locationPriceDefinition?.unitQuantity || 0,
                                                                unitLabel: "users",
                                                                billingFrequency:"monthly",
                                                                unitPrice: this.state.locationPriceDefinition?.unitPrice || 0,
                                                            }],
                                                        })
                                                        this.setState({ subscriptions: newSubscriptions });
                                                        this.addToChangedSubscriptions(newId);
                                                        this.addToNewSubscriptions(newId);
                                                    }
                                                }}
                                            />
                                        </div>
                                        { locationSubscription && this.state.changedSubscriptions.indexOf(locationSubscription.id) !== -1 &&
                                            <div className="opts">
                                                <button 
                                                    className="styled filled pulse no-size" 
                                                    style={ ( (this.state.newSubscriptions.indexOf(locationSubscription?.id || 0) === -1) && (locationSubscription.unitsPurchased === 0) ) ? { backgroundColor: theme.colours.red, border: 'none' } : {} }
                                                    data-disabled={this.state.subscriptionUpdating} 
                                                    onClick={() => { 
                                                        this.editSubscription(Feature.Locations, locationSubscription?.id || 0, locationSubscription?.unitsPurchased || 0, this.state.newSubscriptions.indexOf(locationSubscription?.id || 0) !== -1, this.state.locationPriceDefinition?.priceId || 0);
                                                        if (this.props.resetHintedElement)
                                                            this.props.resetHintedElement();
                                                    }}
                                                >
                                                    { (this.state.newSubscriptions.indexOf(locationSubscription?.id || 0) !== -1) ? "Start free 14 day trial" : (locationSubscription.unitsPurchased === 0) ? "Cancel Subscription" : "Confirm Changes" }
                                                </button>
                                            </div>
                                        }
                                    </div>
                                </div>

                                <div id="subscriptions-sms" className={"subscription card" + (this.props.hintedElement === "sms-credit" ? " guide-instruction" : "") } data-type="smscredit">
                                    { (this.state.successMessageInline && this.state.successMessageInline.element === "subscriptions-sms") &&
                                        <div 
                                            className="success-message-inline"
                                            onClick={() => {
                                                this.setState({
                                                    successMessageInline: undefined
                                                })
                                            }}
                                        >
                                            <div>
                                                <i className="fas fa-check-circle" />
                                                <p>{this.state.successMessageInline.message}</p>
                                                <button>Close</button>
                                            </div>
                                        </div>
                                    }
                                    <div className="card-header">
                                        <div style={{ background: theme.colours.red }}>
                                            <i className='fas fa-sitemap' />
                                        </div>
                                        <div>
                                            <h4>
                                                SMS Offer Credits
                                            </h4>
                                            <p>
                                                {this.props.authenticatedUserProfile.organisationSMSCredit || 0}
                                                { (smsQuqntityPriceLabel) &&
                                                    <div className='unit-price-info'>
                                                        {smsQuqntityPriceLabel}
                                                    </div>
                                                }
                                            </p>
                                        </div>
                                    </div>
                                    <div className="card-content">
                                        <p>Reach your contacts by SMS, so they can apply faster, even without internet connection</p>
                                        <div className='sms-credit'>
                                            <div className='slider-wrapper'>
                                                <Range
                                                    step={SMS_CREDIT_INCREMENTS}
                                                    min={SMS_CREDIT_INCREMENTS}
                                                    max={30000}
                                                    values={[this.state.smsCreditQuantity]}
                                                    onChange={(values) => this.setState({
                                                        smsCreditQuantity: values[0]
                                                    })}
                                                    renderTrack={({ props, children }) => (
                                                    <div
                                                        // @ts-ignore
                                                        {...props}
                                                        style={{
                                                        ...props.style,
                                                        height: '6px',
                                                        width: '100%',
                                                        borderRadius: '6px',
                                                        backgroundColor: '#aaa'
                                                        }}
                                                    >
                                                        {children}
                                                    </div>
                                                    )}
                                                    renderThumb={({ props }) => (
                                                        <div
                                                            // @ts-ignore
                                                            {...props}
                                                            style={{
                                                                ...props.style,
                                                                height: '28px',
                                                                width: '28px',
                                                                borderRadius: '100%',
                                                                backgroundColor: theme.colours.blue2
                                                            }}
                                                        >
                                                            <span style={{
                                                                position: "absolute",
                                                                top: -24,
                                                                left: -10,
                                                                fontSize: 14,
                                                                width: 46,
                                                                textAlign: "center",
                                                                display: "inline-block",
                                                                fontWeight: 700
                                                            }}>
                                                                {this.state.smsCreditQuantity}
                                                            </span>
                                                        </div>
                                                    )}
                                                />
                                            </div>
                                            <div className='options'>
                                                <button 
                                                    onClick={ async () => {
                                                        this.setState({
                                                            fullscreenLoadingMessage: "Preparing your Purchase..."
                                                        })
                                                        const checkoutSession = await SubscriptionsApi.createCheckoutSessionForSMSCreditPurchase(this.state.smsCreditQuantity);
                                                        console.log(checkoutSession);
                                                        if (checkoutSession && checkoutSession.url) {
                                                            localStorage.setItem("pendingPurchaseId", checkoutSession.id);
                                                            localStorage.setItem("pendingPurchaseProduct", checkoutSession.product);
                                                            localStorage.setItem("pendingPurchaseQuantity", checkoutSession.quantity.toString());
                                                            window.location.href = checkoutSession.url;
                                                        }
                                                        this.setState({
                                                            fullscreenLoadingMessage: null
                                                        })
                                                    }}
                                                    className="styled filled"
                                                >
                                                    Purchase <span>{this.state.smsCreditQuantity}</span> SMS credits for £{Utilities.toFixedNumber(this.state.smsCreditQuantity * SMS_CREDIT_PRICE)}
                                                </button>
                                            </div>
                                        </div>
                                        {/* <div 
                                            className="opts"
                                            style={{
                                                marginTop: 16,
                                                textAlign: 'left'
                                            }}
                                        >
                                            <button 
                                                className="styled filled" 
                                                onClick={() => { 
                                                    window.open("/support/sms-credit")
                                                }}
                                            >
                                                Contact Us for more credit
                                            </button>
                                        </div> */}
                                    </div>
                                </div>

                                <div className={"subscription card" + (this.props.hintedElement === "rota-subscription" ? " guide-instruction" : "") } data-type="rota">
                                    <div className="card-header">
                                        <div style={{ background: theme.colours.red }}>
                                            <i className='fas fa-sitemap' />
                                        </div>
                                        <div>
                                            <h4>
                                                Organization-wide Groups
                                            </h4>
                                            <p>Not Active</p>
                                        </div>
                                    </div>
                                    <div className="card-content">
                                        <p>Share unlimited numbers of Groups between all your colleagues</p>
                                        <div 
                                            className="opts"
                                            style={{
                                                marginTop: 16,
                                                textAlign: 'left'
                                            }}
                                        >
                                            <button 
                                                className="styled filled" 
                                                onClick={() => { 
                                                    window.open("/support/organisation-groups")
                                                }}
                                            >
                                                Contact Us for Consultation
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div className={"subscription card" + (this.props.hintedElement === "rota-subscription" ? " guide-instruction" : "") } data-type="rota">
                                    <div className="card-header">
                                        <div style={{ background: theme.colours.red }}>
                                            <i className="fas fa-laptop-code"></i>
                                        </div>
                                        <div>
                                            <h4>
                                                API Access
                                            </h4>
                                            <p>Not Active</p>
                                        </div>
                                    </div>
                                    <div className="card-content">
                                        <p>Share unlimited numbers of Groups between all your colleagues</p>
                                        <div 
                                            className="opts"
                                            style={{
                                                marginTop: 16,
                                                textAlign: 'left'
                                            }}
                                        >
                                            <button 
                                                className="styled filled" 
                                                onClick={() => { 
                                                    window.open('mailto:support@updatedge.com?body=Hi, I would like to enquiry about API Access. \n\nTelephone number: \nEmail address: \n Name:&subject=Enterprise Feature [API Access]')
                                                }}
                                            >
                                                Contact Us for Consultation
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <FullScreenLoader
                    loadingMessage={this.state.fullscreenLoadingMessage}
                />
            </section>
        );

    }

}

const connector = connect(
    (state: ApplicationState) => state.profile,
    ProfileStore.actionCreators
);

export default connector(ManageSubscriptions);