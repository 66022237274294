/* eslint-disable */
import React from 'react';

function TaskListStage3(props) {
    return (
      <svg width="142.406" height="102.413" viewBox="0 0 142.406 102.413">
      <g id="undraw_Scrum_board_re_wk7v" transform="translate(-16.492 -9.732)">
        <path id="Path_6668" data-name="Path 6668" d="M433.426,216.5H301.953a4.119,4.119,0,0,0-4.115,4.115v69.361a4.119,4.119,0,0,0,4.115,4.115H433.426a4.119,4.119,0,0,0,4.115-4.115V220.61A4.119,4.119,0,0,0,433.426,216.5Zm3.723,73.476a3.726,3.726,0,0,1-3.723,3.723H301.953a3.726,3.726,0,0,1-3.723-3.723V220.61a3.726,3.726,0,0,1,3.723-3.723H433.426a3.726,3.726,0,0,1,3.723,3.723Z" transform="translate(-278.642 -206.763)" fill="#3f3d56"/>
        <path id="Path_6669" data-name="Path 6669" d="M469.575,272.257H351.034a.2.2,0,1,1,0-.392H469.575a.2.2,0,0,1,0,.392Z" transform="translate(-321.258 -251.284)" fill="#3f3d56"/>
        <path id="Path_6670" data-name="Path 6670" d="M522.034,303.35a.2.2,0,0,1-.2-.2V238.691a.2.2,0,0,1,.392,0v64.463A.2.2,0,0,1,522.034,303.35Z" transform="translate(-458.753 -224.452)" fill="#3f3d56"/>
        <path id="Path_6671" data-name="Path 6671" d="M673.034,303.35a.2.2,0,0,1-.2-.2V238.691a.2.2,0,0,1,.392,0v64.463A.2.2,0,0,1,673.034,303.35Z" transform="translate(-580.167 -224.452)" fill="#3f3d56"/>
        <path id="Path_6672" data-name="Path 6672" d="M824.034,303.35a.2.2,0,0,1-.2-.2V238.691a.2.2,0,0,1,.392,0v64.463A.2.2,0,0,1,824.034,303.35Z" transform="translate(-701.58 -224.452)" fill="#3f3d56"/>
        <path id="Path_6673" data-name="Path 6673" d="M392.755,335.175H385.6a.883.883,0,0,1-.882-.882v-8.449a.883.883,0,0,1,.882-.882h7.159a.883.883,0,0,1,.882.882v8.449A.883.883,0,0,1,392.755,335.175Z" transform="translate(-348.496 -293.978)" fill="#6c63ff"/>
        <path id="Path_6674" data-name="Path 6674" d="M444.755,458.175H437.6a.883.883,0,0,1-.882-.882v-8.449a.883.883,0,0,1,.882-.882h7.159a.883.883,0,0,1,.882.882v8.449A.883.883,0,0,1,444.755,458.175Z" transform="translate(-390.307 -392.878)" fill="#6c63ff"/>
        <path id="Path_6675" data-name="Path 6675" d="M562.755,361.175H555.6a.883.883,0,0,1-.882-.882v-8.449a.883.883,0,0,1,.882-.882h7.159a.882.882,0,0,1,.882.882v8.449A.883.883,0,0,1,562.755,361.175Z" transform="translate(-485.187 -314.884)" fill="#6c63ff"/>
        <path id="Path_6676" data-name="Path 6676" d="M715.755,314.175H708.6a.883.883,0,0,1-.882-.882v-8.449a.883.883,0,0,1,.882-.882h7.159a.883.883,0,0,1,.882.882v8.449A.883.883,0,0,1,715.755,314.175Z" transform="translate(-608.209 -277.093)" fill="#6c63ff"/>
        <path id="Path_6677" data-name="Path 6677" d="M856.755,314.175H849.6a.883.883,0,0,1-.882-.882v-8.449a.883.883,0,0,1,.882-.882h7.159a.883.883,0,0,1,.882.882v8.449A.883.883,0,0,1,856.755,314.175Z" transform="translate(-721.582 -277.093)" fill="#6c63ff"/>
        <path id="Path_6678" data-name="Path 6678" d="M921.755,326.175H914.6a.883.883,0,0,1-.882-.882v-8.449a.883.883,0,0,1,.882-.882h7.16a.883.883,0,0,1,.882.882v8.449A.883.883,0,0,1,921.755,326.175Z" transform="translate(-773.846 -286.742)" fill="#e6e6e6"/>
        <path id="Path_6679" data-name="Path 6679" d="M892.755,403.175H885.6a.883.883,0,0,1-.882-.882v-8.449a.883.883,0,0,1,.882-.882h7.16a.883.883,0,0,1,.882.882v8.449A.883.883,0,0,1,892.755,403.175Z" transform="translate(-750.528 -348.654)" fill="#e6e6e6"/>
        <path id="Path_6680" data-name="Path 6680" d="M768.755,387.175H761.6a.883.883,0,0,1-.882-.882v-8.449a.883.883,0,0,1,.882-.882h7.159a.883.883,0,0,1,.882.882v8.449A.883.883,0,0,1,768.755,387.175Z" transform="translate(-650.824 -335.789)" fill="#ff6584"/>
        <path id="Path_6681" data-name="Path 6681" d="M697.755,403.175H690.6a.883.883,0,0,1-.882-.882v-8.449a.883.883,0,0,1,.882-.882h7.159a.883.883,0,0,1,.882.882v8.449A.883.883,0,0,1,697.755,403.175Z" transform="translate(-593.736 -348.654)" fill="#e6e6e6"/>
        <path id="Path_6682" data-name="Path 6682" d="M544.755,471.175H537.6a.883.883,0,0,1-.882-.882v-8.449a.883.883,0,0,1,.882-.882h7.159a.883.883,0,0,1,.882.882v8.449a.883.883,0,0,1-.882.882Z" transform="translate(-470.714 -403.331)" fill="#e6e6e6"/>
        <path id="Path_6683" data-name="Path 6683" d="M359.755,414.175H352.6a.883.883,0,0,1-.882-.882v-8.449a.883.883,0,0,1,.882-.882h7.16a.883.883,0,0,1,.882.882v8.449A.883.883,0,0,1,359.755,414.175Z" transform="translate(-321.962 -357.499)" fill="#ff6584"/>
        <path id="Path_6684" data-name="Path 6684" d="M462.755,335.175H455.6a.883.883,0,0,1-.882-.882v-8.449a.883.883,0,0,1,.882-.882h7.159a.883.883,0,0,1,.882.882v8.449A.883.883,0,0,1,462.755,335.175Z" transform="translate(-404.781 -293.978)" fill="#e6e6e6"/>
        <path id="Path_6685" data-name="Path 6685" d="M416.79,251.063H405.622a.784.784,0,0,1,0-1.567H416.79a.784.784,0,0,1,0,1.567Z" transform="translate(-364.677 -233.297)" fill="#ccc"/>
        <path id="Path_6686" data-name="Path 6686" d="M577.79,251.063H566.622a.784.784,0,1,1,0-1.567H577.79a.784.784,0,1,1,0,1.567Z" transform="translate(-494.132 -233.297)" fill="#ccc"/>
        <path id="Path_6687" data-name="Path 6687" d="M728.79,251.063H717.622a.784.784,0,1,1,0-1.567H728.79a.784.784,0,1,1,0,1.567Z" transform="translate(-615.545 -233.297)" fill="#ccc"/>
        <path id="Path_6688" data-name="Path 6688" d="M869.79,251.063H858.622a.784.784,0,1,1,0-1.567H869.79a.784.784,0,1,1,0,1.567Z" transform="translate(-728.918 -233.297)" fill="#ccc"/>
        <path id="Path_6689" data-name="Path 6689" d="M310.565,518.2a1.97,1.97,0,0,1-1.7-2.494l-5.022-4.675,2.655-.674,4.939,4.006a1.981,1.981,0,0,1-.867,3.838Z" transform="translate(-283.467 -443.047)" fill="#ffb8b8"/>
        <path id="Path_6690" data-name="Path 6690" d="M184.4,528.23h2.4l1.143-9.265H184.4Z" transform="translate(-150.369 -418.521)" fill="#ffb8b8"/>
        <path id="Path_6691" data-name="Path 6691" d="M0,0H7.55V2.917H2.917A2.917,2.917,0,0,1,0,0Z" transform="translate(41.068 111.94) rotate(179.997)" fill="#2f2e41"/>
        <path id="Path_6692" data-name="Path 6692" d="M100.345,520.658l2.321.618,3.488-8.66-3.426-.912Z" transform="translate(-82.782 -412.684)" fill="#ffb8b8"/>
        <path id="Path_6693" data-name="Path 6693" d="M0,0H7.55V2.917H2.917A2.917,2.917,0,0,1,0,0H0Z" transform="matrix(-0.966, -0.257, 0.257, -0.966, 23.788, 111.94)" fill="#2f2e41"/>
        <path id="Path_6694" data-name="Path 6694" d="M307.282,542.644h-3.665a.927.927,0,0,1-.926-.841L300.465,518.7a.734.734,0,0,0-1.434-.14l-6.461,21.592a.929.929,0,0,1-1.117.636l-3.41-.853a.93.93,0,0,1-.675-1.134c.056-.218,5.567-21.754,5.615-21.976,1.249-9.2,2.743-11.669,3.545-12.993.066-.11.127-.21.182-.306.069-.119.492-1.3.761-2.07a.939.939,0,0,1,.477-.583c3.372-1.725,7.318-.531,8.447-.124a.918.918,0,0,1,.458.356c3.387,5.108,1.725,34.777,1.357,40.673A.931.931,0,0,1,307.282,542.644Z" transform="translate(-270.2 -434.689)" fill="#2f2e41"/>
        <circle id="Ellipse_288" data-name="Ellipse 288" cx="4.812" cy="4.812" r="4.812" transform="translate(22.81 39.801)" fill="#ffb8b8"/>
        <path id="Path_6695" data-name="Path 6695" d="M314.624,443.246l-.035-.034c-.065-.063-6.477-6.344-5.647-12.153.355-2.484,2.365-4.072,5.813-4.591a5.317,5.317,0,0,1,5.878,3.746l3.409,11.655Z" transform="translate(-287.512 -375.548)" fill="#ccc"/>
        <path id="Path_6696" data-name="Path 6696" d="M299.649,463.281l-4.569-4.54a2.574,2.574,0,0,1-.682-2.428l2.212-9a2.029,2.029,0,1,1,3.917,1.057l-2.329,7.915,3.766,4.044Z" transform="translate(-275.818 -391.116)" fill="#ccc"/>
        <path id="Path_6697" data-name="Path 6697" d="M409.569,444.471a1.97,1.97,0,0,1-2.916.789l-6.1,3.161.27-2.685,5.407-3.346a1.981,1.981,0,0,1,3.339,2.081Z" transform="translate(-361.232 -387.8)" fill="#ffb8b8"/>
        <path id="Path_6698" data-name="Path 6698" d="M359.346,438.419l-5.946,2.569a2.51,2.51,0,0,1-2.445-.262l-7.561-5.417a2.029,2.029,0,1,1,2.436-3.243l6.483,5.1,5.153-1.994Z" transform="translate(-314.591 -379.749)" fill="#ccc"/>
        <path id="Path_6699" data-name="Path 6699" d="M297.405,371.59c.594-.744,1.7-.784,2.518-1.275a2.943,2.943,0,0,0,1.166-3.277,4.112,4.112,0,0,0-2.523-2.579,6.964,6.964,0,0,0-3.687-.236,7.486,7.486,0,0,0-4.743,2.659,4.686,4.686,0,0,0-.388,5.214,7.26,7.26,0,0,1,1.367,2.139,3.3,3.3,0,0,1-1.345,2.909,30.538,30.538,0,0,0-2.83,2.571,3.921,3.921,0,0,0-.993,3.533,3.572,3.572,0,0,0,2.226,2.152,9.435,9.435,0,0,0,3.159.452,5.336,5.336,0,0,0,3.015-.565,3.506,3.506,0,0,0,1.473-2.846,6.74,6.74,0,0,0-.832-3.193,12.264,12.264,0,0,1-.838-1.8,2.521,2.521,0,0,1,.084-1.932,2.41,2.41,0,0,1,1.686-1.116c.035-.007.073-.013.112-.019a1.45,1.45,0,0,0,1.241-1.691A1.416,1.416,0,0,1,297.405,371.59Z" transform="translate(-268.985 -325.435)" fill="#2f2e41"/>
        <path id="Path_6700" data-name="Path 6700" d="M468.682,529.277h-2.4l-1.143-9.265h3.545Z" transform="translate(-376.098 -419.363)" fill="#a0616a"/>
        <path id="Path_6701" data-name="Path 6701" d="M450.242,563.8h4.633v2.917h-7.55a2.917,2.917,0,0,1,2.917-2.917Z" transform="translate(-361.776 -454.569)" fill="#2f2e41"/>
        <path id="Path_6702" data-name="Path 6702" d="M543.153,518.827l-2.185,1-4.889-7.953L539.3,510.4Z" transform="translate(-433.14 -411.634)" fill="#a0616a"/>
        <path id="Path_6703" data-name="Path 6703" d="M2.917,0H7.55V2.917H0A2.917,2.917,0,0,1,2.917,0Z" transform="translate(103.329 109.492) rotate(-24.548)" fill="#2f2e41"/>
        <path id="Path_6704" data-name="Path 6704" d="M712.584,529.678a1.97,1.97,0,0,0,.157-3.017l2.824-6.407-3.587.608-2.131,5.991a1.981,1.981,0,0,0,2.738,2.825Z" transform="translate(-609.566 -451.004)" fill="#a0616a"/>
        <path id="Path_6705" data-name="Path 6705" d="M653.438,570.95h-2.295a.885.885,0,0,1-.879-.818l-1.251-33.419a.882.882,0,0,1,.965-.941l13.272,1.409a.882.882,0,0,1,.8.861l.278,14.741a.684.684,0,0,0,.046.234l5.444,14.118a.881.881,0,0,1-.518,1.144l-2.325.857a.882.882,0,0,1-1.089-.424l-6.688-13a.877.877,0,0,1-.079-.224l-1.142-5.5a.686.686,0,0,0-1.353.061l-2.3,20.127a.881.881,0,0,1-.876.781Z" transform="translate(-561.007 -463.479)" fill="#2f2e41"/>
        <circle id="Ellipse_289" data-name="Ellipse 289" cx="4.812" cy="4.812" r="4.812" transform="translate(88.761 37.48)" fill="#a0616a"/>
        <path id="Path_6706" data-name="Path 6706" d="M656.4,445a17.177,17.177,0,0,1-9.184-2.782l-.047-.032,0-.057,1.841-21.439a3.022,3.022,0,0,1,2.86-2.755c2.386-.11,5.59.029,7.306,1.306a8.365,8.365,0,0,1,3.428,7.977c-.858,6.509.114,15.289.228,16.268l.008.071-.065.03A15.14,15.14,0,0,1,656.4,445Z" transform="translate(-559.53 -368.71)" fill="#6c63ff"/>
        <path id="Path_6707" data-name="Path 6707" d="M713.026,463.838a.981.981,0,0,1-.721-.318l-1.053-1.148a.985.985,0,0,1-.217-.939l2.4-8.124-3.652-7.67a1.726,1.726,0,0,1,3.078-1.56l4.956,8.042a2.342,2.342,0,0,1,.141,2.194l-4.038,8.947a.977.977,0,0,1-.738.564A1,1,0,0,1,713.026,463.838Z" transform="translate(-609.738 -389.025)" fill="#6c63ff"/>
        <path id="Path_6708" data-name="Path 6708" d="M550.579,454.763a1.97,1.97,0,0,0,2.939-.7l6.944.9-1.6-3.27-6.349-.354a1.981,1.981,0,0,0-1.937,3.424Z" transform="translate(-481.212 -395.468)" fill="#a0616a"/>
        <path id="Path_6709" data-name="Path 6709" d="M581.847,429.081a2.631,2.631,0,0,1-.361-.025l-10.924-1.515a1.086,1.086,0,0,1-.833-.61,1.118,1.118,0,0,1-.062-.162h0a1.09,1.09,0,0,1,.113-.868l.9-1.5a1.094,1.094,0,0,1,.932-.526h0l9.522.011,7.111-6.371a1.927,1.927,0,0,1,2.641,2.805l-7.1,7.892A2.618,2.618,0,0,1,581.847,429.081Z" transform="translate(-497.175 -368.001)" fill="#6c63ff"/>
        <path id="Path_6710" data-name="Path 6710" d="M652.138,359.323c.44-1.018.812-2.373-.032-3.093a3.126,3.126,0,0,0-1.835-.47,5.645,5.645,0,0,1-4.548-2.56c-.883-1.582-.39-3.935,1.276-4.649a5.446,5.446,0,0,1,3.585.18l3.528,1a6.224,6.224,0,0,1,2.9,1.411,3.991,3.991,0,0,1,.158,4.824,8.656,8.656,0,0,1-4.044,3.516Z" transform="translate(-558.018 -312.749)" fill="#2f2e41"/>
      </g>
    </svg>
    
    );
}

export default TaskListStage3;
