/* eslint-disable */
import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { PendingEvent } from '../../api/events/ResponseTypes';
import { PendingEventDto } from '../../api/offers/ResponseTypes';
//import { MaxHeightProperty } from 'csstype';

import theme from '../../css/theme';

interface Props {
    title: string;
    events: PendingEvent[] | PendingEventDto[];
    maxHeight?: any;
}

const TimelineEvents = (props: Props) => {
    return (
        <EventContainer style={{ maxHeight: props.maxHeight || 'unset' }}>
            {(props.events as any).map((ev) => {
                const start = moment(ev.start);
                const end = moment(ev.end);

                return (
                    <TimelineEvent
                        className="app-timeline-event"
                        key={`event-preview-${ev.start}`}
                    >
                        <div className="app-timeline-event-body">
                            <div className="app-timeline-event-details layout horizontal">
                                <div className="app-timeline-event-details-body flex">
                                    <div className="title">{props.title}</div>
                                    <div className="times">
                                        {start.format('HH:mm')} -{' '}
                                        {moment(ev.end).format('HH:mm')}
                                    </div>
                                    {end.dayOfYear() ==
                                        start.dayOfYear() + 1 && (
                                        <i className="fas fa-cloud-moon" />
                                    )}
                                    <i className="fa fa-briefcase" />
                                </div>
                                <div className="app-timeline-event-date">
                                    <div className="dayName">
                                        {start.format('ddd')}
                                    </div>
                                    <div className="dayNum">
                                        {start.format('D')}
                                    </div>
                                    <div className="monthYear">
                                        {start.format('MMM YY')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </TimelineEvent>
                );
            })}
        </EventContainer>
    );
};

const EventContainer = styled.div`
    max-height: calc(100vh - 430px);
    margin-top: 10px;
    padding: 10px 10px 5px 10px;
    overflow-y: auto;
    background: #dcdcdc;
    box-shadow: 0px 0px 5px 2px #dcdcdc;
`;

const TimelineEvent = styled.div`
    margin-bottom: 5px;
    padding-right: 10px;
    padding-left: 10px;
    color: #6a6c6f;
    border: 1px solid transparent;

    .app-timeline-event-body {
        min-height: 60px;

        .app-timeline-event-details {
            min-height: 60px;
            background-color: #fff;
            border-left: solid 8px ${theme.colours.green};
            border-radius: 10px;

            .app-timeline-event-details-body {
                position: relative;
                float: left;
                padding: 8px 0 0 10px;

                .title {
                    min-height: 28px;
                    margin-bottom: 5px;
                    color: ${theme.colours.green};
                    font-weight: bold;
                    font-size: 0.8em;
                }

                .times {
                    font-size: 0.9em;
                }

                .fa-briefcase {
                    position: absolute;
                    right: 5px;
                    bottom: 5px;
                    color: ${theme.colours.green};
                }

                .fa-cloud-moon {
                    position: absolute;
                    right: 22px;
                    bottom: 5px;
                    color: ${theme.colours.green};
                    font-size: 12px;
                }
            }

            .app-timeline-event-date {
                min-height: 60px;
                padding: 3px 5px 0 5px;
                text-align: center;
                text-transform: uppercase;
                background-color: #f1f3f6;
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;

                .dayName,
                .monthYear {
                    font-size: 0.8em;
                }

                .dayNum {
                    font-size: 1.4em;
                }
            }
        }
    }
`;

export default TimelineEvents;
