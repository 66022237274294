/* eslint-disable */
import React from 'react';
import Schedule from './Schedule';
import Availability from './Availability';
import theme from 'src/css/theme';
import styled from 'styled-components';
import WorkerRater, { ContactWithRating } from 'src/components/rating/WorkerRater';
import { EphemeralAccessTokenStorageKey } from 'src/components/layout/EphemeralAccessLayout';
import { Rating } from 'src/api/offers/ResponseTypes';
import RatingsApi from 'src/api/ratings/Rating';
import { toast } from 'react-toastify';
import FullScreenLoader from 'src/components/ui-components/FullScreenLoader';
import '../css/ExternalRate.css';

interface State {
    rating: ContactWithRating | null;
    token: string;
}

export class ExternalRate extends React.Component<{}, State> {

    state: State = {
        rating: null,
        token: ""
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.loadRating()
    }

    loadRating() {
        const token = window.location.href.split("token=")[1].split("&")[0];
        if (token) {
            RatingsApi.getExternalRating(token).then(rating => {
                this.setState({
                    token: token,
                    rating: typeof rating === 'boolean' ? {
                        stars: 0,
                        publicComment: "",
                        privateComment: "",
                        name: "",
                        userId: ""
                    } as ContactWithRating : {
                        ...rating,
                        userId: rating.recipientUserId ? rating.recipientUserId : "",
                        name: rating.recipientFirstName && rating.recipientLastName ? (rating.recipientFirstName + " " + rating.recipientLastName) : ""
                    } as ContactWithRating
                })
            }).catch(e => {
                toast.error("Failed to fetch rating");
                setTimeout(() => {
                    window.location.href = "/";
                }, 2000)
            });
        } else {
            toast.error("Failed to fetch rating");
            setTimeout(() => {
                window.location.href = "/";
            }, 2000)
        }
    }

    render() {
        return (
            <div 
                className="external-rate-wrapper"
            >
                <div className='rating'>
                    { (this.state.rating) &&
                        <WorkerRater
                            showProfile={true}
                            showSave={true}
                            mode={"inline"}
                            id="rating-external-rating"
                            contacts={ this.state.rating ? [this.state.rating] : [] }
                            onSave={(contact) => {
                                console.log("onSave", contact);
                                RatingsApi.postExternalRating(
                                    this.state.token,
                                    [{
                                        userId: contact.userId,
                                        stars: contact.stars || 0,
                                        publicComment: contact.publicComment || "",
                                        privateComment: contact.privateComment || ""
                                    }],
                                    window.location.href.indexOf("direction=") > -1 ? parseInt(window.location.href.split("direction=")[1].split("&")[0]) : undefined
                                ).then(data => {
                                    toast.success("Rating saved");
                                }).catch(e => {
                                    toast.error("Failed to save rating");
                                })
                                return contact;
                            }}
                            onEdit={(contact) => {
                                return contact;
                            }}
                        />
                    }
                </div>
                <FullScreenLoader loadingMessage={this.state.rating ? null : "Loading Rating"} />
            </div>
        )
    }
}

export default ExternalRate;