/* eslint-disable */
import React from 'react';
import styled from 'styled-components';

import theme from '../../css/theme';

interface Props {
    loadingMessage?: string | null;
    fullBlock?: boolean;
    view?: string;
    success?: boolean;
    error?: boolean;
    noLoadingMessage?: boolean;
    successMessage?: string;
    errorMessage?: string;
    ctaButtonStr?: string;
    ctaButtonOnClick? (e: any): void;
    bottomImg?: string;
}

export default class FullScreenLoader extends React.Component<Props, {}> {
    render() {
        if (this.props.loadingMessage || this.props.noLoadingMessage)
            return (
                <FullScreenLoaderWrapper>
                    <div className='full-screen-loader-wrapper' style={ this.props.success ? { background: '#6ac871' } : this.props.error ? { background: '#D61C4E' } : this.props.fullBlock ? { background: theme.colours.blue2 } : this.props.view === "chat" ? { height: '100%' } : { } }>
                        <div>
                            { !this.props.success && !this.props.error &&
                                <div 
                                    className="loading-spinner"
                                    style={ this.props.fullBlock ? { border: '8px solid white', borderRightColor: 'transparent' } : { } } 
                                />
                            }
                            { this.props.success &&
                                <div className="success">
                                    <i className="fas fa-check"></i>
                                    { (this.props.successMessage) &&
                                        <h2>{this.props.successMessage}</h2>
                                    }
                                    { (this.props.ctaButtonStr && this.props.ctaButtonOnClick) &&
                                        <button onClick={this.props.ctaButtonOnClick}>{this.props.ctaButtonStr}</button>
                                    }
                                    { (this.props.bottomImg) &&
                                        <img src={this.props.bottomImg} alt="bottom-img" />
                                    }
                                </div>
                            }
                            { this.props.error &&
                                <div className="error">
                                    <i className="fas fa-times-circle"></i>
                                    { (this.props.errorMessage) &&
                                        <h2>{this.props.errorMessage}</h2>
                                    }
                                </div>
                            }
                            { (!this.props.noLoadingMessage) &&
                                <h2>{this.props.loadingMessage || 'Loading...'}</h2>
                            }
                        </div>
                    </div>
                </FullScreenLoaderWrapper>
            );
        return null;
    }
}

const FullScreenLoaderWrapper = styled.div`
    & > div {
        height: 100vh;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.7);
        z-index: 10000;
        position: fixed;
        left: 0;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        & > div {
            text-align: center;
            padding: 20px;

            h2 {
                color: white;
                font-weight: 500;
                font-size: 1.4em;
                line-height: 1.6em;
                margin: 20px 0 0 0;
                max-width: 400px;
            }
        }
    }

    @keyframes rotate-forever {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    .success, .error {
        position: relative;
        
        i {
            color: white;
            font-size: 36px;
        }
    }

    button {
        background: white;
        border: none;
        border-radius: 8px;
        color: black
        cursor: pointer;
        font-size: 1em;
        font-weight: 700;
        margin-top: 20px;
        padding: 10px 20px;
    }

    img {
        position: absolute;
        left: 50%;
        width: 450px;
        bottom: -400px;
        transform: translate(-50%, 0);
    }

    .loading-spinner {
        animation-duration: 0.75s;
        animation-iteration-count: infinite;
        animation-name: rotate-forever;
        animation-timing-function: linear;
        height: 52px;
        width: 52px;
        border: 8px solid ${theme.colours.blue};
        border-right-color: transparent;
        border-radius: 50%;
        display: inline-block;

        // &[data-view="chat"] {
        //     position: absolute;
        //     top: 0;
        //     left: 0;
        //     display: flex;
        //     align-items: center;
        //     background: rgba(0, 0, 0, .8);
        //     color: white;
        // }
    }
`;
