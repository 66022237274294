/* eslint-disable */
import AppConfig from '../../components/config/Config';
import {
    CreateOfferDto,
    UserOfferDto,
    OfferInfoDto,
    OfferInfoDtoWithPaging,
    PendingEventDto,
    FavouriteOfferDTO,
    RotaDto,
    MutualAgencyDto
} from './ResponseTypes';
import afetch from './../AuthenticatedFetch';
import { OfferRecipientAgency, NewFavouriteOfferDTO, OfferListParams } from './RequestTypes';

const apiUrls = {
    offer() {
        return `${AppConfig.Settings.api.externalUri}/offer`;
    },
    userOffers() {
        return `${AppConfig.Settings.api.baseUri}/publicapi/userOffers`;
    },
    accept(id: string, allowDirectEngagement: boolean) {
        return `${AppConfig.Settings.api.baseUri}/publicapi/offer/respond/${id}/accept/${allowDirectEngagement}`;
    },
    reject(id: string) {
        return `${AppConfig.Settings.api.baseUri}/publicapi/offer/respond/${id}/reject`;
    },
    offerList() {
        return `${AppConfig.Settings.api.externalUri}/offers/created`;
    },
    getOfferById(id) {
        return `${AppConfig.Settings.api.externalUri}/offer/${id}`;
    },
    withdraw(id: string) {
        return `${AppConfig.Settings.api.baseUri}/publicapi/offer/${id}/withdraw`;
    },
    delete(id: string) {
        return `${AppConfig.Settings.api.baseUri}/publicapi/offer/${id}`;
    },
    complete(offerId: string, rejectUnsuccessful: boolean, filesCount: number) {
        // return `${AppConfig.Settings.api.externalUri}/offer/${offerId}/complete`; // /${rejectUnsuccessful}`;
        return `${AppConfig.Settings.api.baseUri}/publicapi/offer/${offerId}/complete/${rejectUnsuccessful}/${filesCount}`;
    },
    scheduleRateRequest(offerId: string) {
        return `${AppConfig.Settings.api.externalUri}/offer/${offerId}/schedule-request-ratings`;
    },
    offerWorker(id: number) {
        return `${AppConfig.Settings.api.baseUri}/publicapi/offer/worker/${id}/withdraw`;
    },
    offerEvents(offerId: string) {
        return `${AppConfig.Settings.api.baseUri}/publicapi/offer/${offerId}/events`;
    },
    getHasCreated: () => {
        return `${AppConfig.Settings.api.baseUri}/publicapi/offer/any`;
    },
    acknowledgeOfferWithdrawn: (offerId: string) => {
        return `${AppConfig.Settings.api.baseUri}/publicapi/offer/${offerId}/acknowledge`;
    },
    declineOfferEvents: (offerId: string) => {
        return `${AppConfig.Settings.api.baseUri}/publicapi/offer/${offerId}/events/decline`;
    },
    getFavourites: () => {
        return `${AppConfig.Settings.api.externalUri}/offers/favourites`;
    },
    deleteFavourite: (id: string) => {
        return `${AppConfig.Settings.api.externalUri}/offers/favourite/${id}`;
    },
    saveToFavourites: () => {
        return `${AppConfig.Settings.api.externalUri}/offers/favourite`;
    },
    rota: () => {
        return `${AppConfig.Settings.api.externalUri}/rota`;
    },
    rotaForTimeSheet: (token: string) => {
        return `${AppConfig.Settings.api.externalUri}/rota/timesheet/${token}`;
    },
    getRotaForWorkerTimeSheet: (token: string) => {
        return `${AppConfig.Settings.api.externalUri}/rota/timesheet/worker/${token}`;
    },
    getShiftRota: () => {
        return `${AppConfig.Settings.api.externalUri}/rota/intervalsByDayAlternate`;
    },
    externalShiftRota: (token: string) => {
        return `${AppConfig.Settings.api.externalUri}/rota/intervalsByDayAlternate/${token}`;
    },
    mutualAgencies: () => {
        return `${AppConfig.Settings.api.externalUri}/offer/mutualagencies`;
    },
    respondToHelpRequest: (token: string, response: string) => {
        return `${AppConfig.Settings.api.externalUri}/linktoken/agency/offer?token=${token}&response=${response}`;
    },
    attachFilesAndFolders: (offerId: string) => {
        return `${AppConfig.Settings.api.externalUri}/offer/${offerId}/files`;
    }
};

const apiCalls = {
    attachFilesAndFolders: (offerId: string, fileIds: number[], folderIds: number[], onlyConfirmedContacts: boolean) => {
        return afetch.request<{ files: number[], folders: number[], onlyConfirmedContacts: boolean }, number[]>(
            apiUrls.attachFilesAndFolders(offerId),
            'POST',
            { files: fileIds, folders: folderIds, onlyConfirmedContacts: onlyConfirmedContacts },
            'Unable to update offer'
        );
    },
    getRotaForTimeSheet: (token: string) => {
        return afetch.request<null, RotaDto>(
            apiUrls.rotaForTimeSheet(token),
            'GET',
            null,
            'Unable to get schedule'
        );
    },
    getRotaForWorkerTimeSheet: (token: string) => {
        return afetch.request<null, RotaDto>(
            apiUrls.getRotaForWorkerTimeSheet(token),
            'GET',
            null,
            'Unable to get schedule'
        );
    },
    getShiftRotaExternal: (start: string, end: string, token: string) => {
        return afetch.request<{ start: string; end: string }, RotaDto>(
            apiUrls.externalShiftRota(token),
            'POST',
            { start: start, end: end },
            'Unable to get schedule'
        );
    },
    getShiftRota: (start: string, end: string) => {
        return afetch.request<{ start: string; end: string }, RotaDto>(
            apiUrls.getShiftRota(),
            'POST',
            { start: start, end: end },
            'Unable to get schedule'
        );
    },
    createOffer: (offer: CreateOfferDto) => {
        return afetch.request<CreateOfferDto, string>(
            apiUrls.offer(),
            'POST',
            offer,
            'Unable to create offer'
        );
    },
    getRota: (start: string, end: string) => {
        return afetch.request<{ start: string; end: string }, RotaDto>(
            apiUrls.rota(),
            'POST',
            { start: start, end: end },
            'Unable to get rota'
        );
    },
    addOfferWorkers: (offerId, workerIds, deadline) => {
        return afetch.request<{ workerIds: string[], deadline: string }, string>(
            `${AppConfig.Settings.api.externalUri}/offer/${offerId}`,
            'PUT',
            {
                workerIds: workerIds,
                deadline: deadline
            },
            'Unable to add workers to offer'
        );
    },
    getUserOffers: () => {
        return afetch.request<null, UserOfferDto[]>(
            apiUrls.userOffers(),
            'GET',
            null,
            'Unable to get offers'
        );
    },
    getOfferList: () => {
        return afetch.request<null, OfferInfoDto[]>(
            apiUrls.offerList(),
            'GET',
            null,
            'Unable to get offer list'
        );
    },
    getOfferListWithParams: (data: OfferListParams) => {
        return afetch.request<OfferListParams, OfferInfoDtoWithPaging>(
            apiUrls.offerList(),
            'POST',
            data,
            'Unable to get offer list'
        );
    },
    acceptOffer: (id: string, allowDirectEngagement: boolean) => {
        return afetch.request<null>(
            apiUrls.accept(id, allowDirectEngagement),
            'GET',
            null,
            'Unable to accept offer'
        );
    },
    rejectOffer: (id: string) => {
        return afetch.request<null>(
            apiUrls.reject(id),
            'GET',
            null,
            'Unable to reject offer'
        );
    },
    withdrawOffer: (id: string) => {
        return afetch.request<null>(
            apiUrls.withdraw(id),
            'GET',
            null,
            'Unable to withdraw offer'
        );
    },
    deleteOffer: (id: string) => {
        return afetch.request<null>(
            apiUrls.delete(id),
            'DELETE',
            null,
            'Unable to delete offer'
        );
    },
    completeOffer: (
        offerId: string,
        recipientAgencies: OfferRecipientAgency[],
        rejectUnsuccessful: boolean,
        filesCount: number = 0,
    ) => {
        return afetch.request<OfferRecipientAgency[]>(
            apiUrls.complete(offerId, rejectUnsuccessful, filesCount),
            'POST',
            recipientAgencies,
            'Unable to complete offer'
        );
    },
    scheduleRateRequest: (offerId: string) => {
        return afetch.request<null>(
            apiUrls.scheduleRateRequest(offerId),
            'PUT',
            null,
            'Unable to schedule rate request'
        );
    },
    withdrawOfferWorker: (id: number) => {
        return afetch.request<null>(
            apiUrls.offerWorker(id),
            'GET',
            null,
            'Unable to withdraw offer from worker'
        );
    },
    acknowledgeOfferWithdrawn: (offerId: string) => {
        return afetch.request<null>(
            apiUrls.acknowledgeOfferWithdrawn(offerId),
            'GET',
            null,
            'Unable to acknowledge offer withdrawal'
        );
    },
    getOfferEvents: (offerId: string) => {
        return afetch.request<null, PendingEventDto[]>(
            apiUrls.offerEvents(offerId),
            'GET',
            null,
            'Unable to get offer events'
        );
    },
    getOfferById: (offerId: string) => {
        return afetch.request<null, OfferInfoDto>(
            apiUrls.getOfferById(offerId),
            'GET',
            null,
            'Unable to get offer'
        );
    },
    confirmOfferEvents: (offerId: string) => {
        return afetch.request<null>(
            apiUrls.offerEvents(offerId),
            'POST',
            null,
            'Unable to confirm offer events'
        );
    },
    declineOfferEvents: (offerId: string) => {
        return afetch.request<null>(
            apiUrls.declineOfferEvents(offerId),
            'GET',
            null,
            'Unable to decline offer events'
        );
    },
    getHasCreated: () => {
        return afetch.request<null, boolean>(
            apiUrls.getHasCreated(),
            'GET',
            null
        );
    },
    getFavourites: () => {
        return afetch.request<null, FavouriteOfferDTO[]>(
            apiUrls.getFavourites(),
            'GET',
            null
        );
    },
    deleteFavourite: (id: string) => {
        return afetch.request<null, null>(
            apiUrls.deleteFavourite(id),
            'DELETE',
            null,
            'Unable to delete favourite'
        );
    },
    saveToFavourites: (payload: NewFavouriteOfferDTO) => {
        return afetch.request<NewFavouriteOfferDTO, FavouriteOfferDTO>(
            apiUrls.saveToFavourites(),
            'POST',
            payload,
            'Unable to save offer as a favourite'
        );
    },
    getMutualAgencies: (payload: string[]) => {
        return afetch.request<string[], MutualAgencyDto[]>(
            apiUrls.mutualAgencies(),
            'POST',
            payload,
            'Unable to get mutual agencies'
        );
    },
    respondToHelpRequest: (token: string, response: string) => {
        return afetch.request<null, null>(
            apiUrls.respondToHelpRequest(token, response),
            'GET',
            null,
            'Unable to send response'
        );
    }
};

export default apiCalls;
