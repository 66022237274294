/* eslint-disable */
/* eslint-disable */
import React from 'react';
import '../css/FeatureList.css';

export class FeatureList extends React.Component<{}, {}> {
    constructor(props) {
        super(props);
    }

    public render() {

        const TOP = 
            <div className="top">
                <img
                    src="/img/branding/updatedge-transparent.png"
                    alt="Updatedge Logo"
                />
                <h1>Updatedge</h1>
                <h2>
                    <strong>Don’t be the last to know</strong> - stay
                    connected with all your preferred contacts, see
                    their availability, send offers, confirm your
                    selection to them and any mutual agencies.{' '}
                </h2>
                <h2>
                    Save time, improve choice, control, value and
                    quality.
                </h2>
                <h2>
                    Free to use for workers, hirers, and agencies with
                    optional subscription features.
                </h2>
                <button
                    onClick={() => {
                        if (localStorage.getItem("timesheet-otp-link")) {
                            window.location.href = localStorage.getItem("timesheet-otp-link") || "";
                        } else {
                            window.location.href = '/';
                        }
                    }}
                >
                    Create account / Log In
                </button>
                <a
                    rel="noreferrer"
                    href="https://www.updatedge.com"
                    target="_blank"
                >
                    Learn More
                </a>
            </div>

            const BOTTOM = 
                <div className="features">
                    <div className="img">
                        <img src="/img/features/1.png" />
                    </div>
                    <div className="img">
                        <img src="/img/features/2.png" />
                    </div>
                    <div className="img">
                        <img src="/img/features/3.png" />
                    </div>
                </div>

        return (
            <div className="feature-list">
                <div className="desktop">
                    <div className="main-container">
                        <div className="left">
                            {TOP}
                            {BOTTOM}
                        </div>
                        <div className="right">{this.props.children}</div>
                    </div>
                </div>
                <div className="mobile">
                    <div className="main-container">
                        <div className="left">
                            {TOP}
                        </div>
                        <div className="right">{this.props.children}</div>
                        <div className="left">
                            {BOTTOM}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default FeatureList;
