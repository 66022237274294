/* eslint-disable */
import React from 'react';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import { History } from 'history';

import DashboardContainer from '../state-containers/dashboard.container';
import theme from '../css/theme';

import Toggle from 'react-toggle';
import 'react-toggle/style.css';
import SettingsApi from '../api/settings/Settings';
import { Setting } from '../api/settings/ResponseTypes';

import { ApplicationState } from '../store';

import { connect } from 'react-redux';

import * as ProfileState from '../store/Profile';
import { Subscribe } from '../unstated-fork/unstated';
import SearchTimePresets from './settings/SearchTimePresets';
import ManageAlerts from './settings/ManageAlerts';
import ManageEmails from './ManageEmails';
import CloseAccount from './settings/CloseAccount';
import InternalTracker from 'src/InternalTracker';
import SettingApi from '../api/settings/Settings';
import { actionCreators as profileActionCreators } from '../store/Profile';
import { toast } from 'react-toastify';

interface Props {
    history: History;
    dashboardContainer: DashboardContainer;
}

interface State {
    tasks: Task[]
}

interface Task {
    link?: string;
    icon: string;
    type: 'link' | 'toggle';
    title: string;
    description: string;
    buttonText?: string;
    var?: string;
    field?: any;
}

class Settings extends React.Component<Props, State> {

    constructor(props) {
        super(props);

        this.state = {
            tasks: []
        } as State;
    }

    scrollToSection(tab: string) {
        return;
        let i = 0;
        let interv = setInterval(() => {
            i++;
            let section = document.querySelector("div[data-section='" + tab + "']");
            if (section) {
                clearInterval(interv);
                setTimeout(() => {
                    // @ts-ignore
                    section.scrollIntoView({
                        behavior: 'smooth',
                        block: 'nearest',
                        inline: 'start'
                    });
                }, 300)
            } else if (i === 20) {
                clearInterval(interv);
            }
        }, 200);
    }

    componentDidMount() {
        // @ts-ignore
        let section = this.props?.match?.params?.section
        if (section) {
            this.scrollToSection(section);
        } else {
            this.scrollToSection("stpresets");
        }
    }

    render() {

        const { dashboardContainer: container } = this.props;
        const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user") || "{}") : null;

        return (
            <div>
                <SearchTimePresets />
                <ManageAlerts />
                <ManageEmails />
                <div className="row">
                    <div className="hpanel">
                        <div className="panel-body">
                            <h2>Skip Weekends</h2>
                            <small>
                            Turn this on to skip Saturdays and Sundays when you add a new day to a new offer, or when you are looking at the availability gird or schedule.
                            </small>
                            { container.state.data && container.state.loaded &&
                                <div style={{ marginTop: 12 }}>
                                    <Toggle
                                        id="Skip Weekends"
                                        defaultChecked={container.state.data?.offersContinueOnWeekdays || false}
                                        onChange={() => {
                                            let change = { offersContinueOnWeekdays: false };
                                            if (typeof container.state.data !== 'undefined') {
                                                change.offersContinueOnWeekdays = !container.state.data.offersContinueOnWeekdays;
                                                container.handleDataUpdate(change);
                                                SettingsApi.update(
                                                    Setting.Offers_ContinueOnWeekdays,
                                                    change.offersContinueOnWeekdays ? 'true' : 'false'
                                                );
                                                if (change.offersContinueOnWeekdays) {
                                                    localStorage.setItem("offersContinueOnWeekdays", "true")
                                                    InternalTracker.trackEvent("Enabled Weekend Skipping");
                                                } else {
                                                    localStorage.removeItem("offersContinueOnWeekdays");
                                                    InternalTracker.trackEvent("Disabled Weekend Skipping");
                                                }
                                            }
                                        }}
                                    />
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="hpanel">
                        <div className="panel-body">
                            <h2>Prevent Chat Message Send on Enter</h2>
                            <small>
                            Turn this on to prevent sending of messages when the enter key is pressed, instead a new line will be created.
                            </small>
                            { container.state.data && container.state.loaded &&
                                <div style={{ marginTop: 12 }}>
                                    <Toggle
                                        id="Chat Messages on Enter"
                                        defaultChecked={container.state.data?.newLineOnChatEnter || false}
                                        onChange={ async () => {
                                            let change = { newLineOnChatEnter: false };
                                            if (typeof container.state.data !== 'undefined') {
                                                change.newLineOnChatEnter = !container.state.data.newLineOnChatEnter;
                                                container.handleDataUpdate(change);
                                                await SettingsApi.update(
                                                    Setting.Chat_NewLineOnChatEnter,
                                                    change.newLineOnChatEnter ? 'true' : 'false'
                                                );
                                                if (change.newLineOnChatEnter) {
                                                    InternalTracker.trackEvent("Enabled new line on chat enter");
                                                } else {
                                                    InternalTracker.trackEvent("Disabled new line on chat enter");
                                                }
                                                window.location.reload();
                                            }
                                        }}
                                    />
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="hpanel">
                        <div className="panel-body">
                            <h2>Home Page</h2>
                            { user &&
                                <div>
                                    <select
                                        className="form-control"
                                        defaultValue={user.settings.find(setting => setting.settingId === Setting.Homepage) ? user.settings.find(setting => setting.settingId === Setting.Homepage).value :  '/'}
                                        onChange={async (ev) => {
                                            await SettingApi.update(Setting.Homepage, ev.target.value);
                                            if ((window as any).dispatchRefreshUserProfile) {
                                                (window as any).dispatchRefreshUserProfile();
                                            }
                                            toast.success("Home page updated");
                                        }}
                                    >
                                        { [
                                            { href: '', name: 'Default - Setup' },
                                            { href: 'availability', name: 'Availability' },
                                            { href: 'schedule', name: 'Schedule' },
                                            { href: 'offers', name: 'Offers' },
                                            { href: 'offers#create', name: 'Create New Offer' },
                                        ].map(item => {
                                            return (
                                                <option
                                                key={item.href}
                                                value={item.href}
                                            >
                                                {item.name}
                                            </option>
                                            )
                                        }) }
                                    </select>  
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="hpanel">
                        <div className="panel-body">
                            <h2>Distance</h2>
                            { container.state.data &&
                                <div 
                                    style={{
                                        marginTop: 12,
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}>
                                    <span style={{ marginRight: 10 }}>Miles</span>
                                    <Toggle
                                        icons={false}
                                        id="Distance"
                                        checked={container.state.data?.unitDistance === "km"}
                                        onChange={() => {
                                            let change = { unitDistance: container.state.data?.unitDistance === "km" ? "mi" : "km" };
                                            InternalTracker.trackEvent("Distance Unit Changed", {
                                                value: container.state.data?.unitDistance === "km" ? "mi" : "km"
                                            })
                                            if (typeof container.state.data !== 'undefined') {
                                                container.handleDataUpdate(change);
                                                SettingsApi.update(
                                                    Setting.Unit_Distance,
                                                    change.unitDistance
                                                );
                                                localStorage.setItem("distance", change.unitDistance)
                                            }
                                        }}
                                    />
                                    <span style={{ marginLeft: 10 }}>Kilometers</span>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <CloseAccount />
            </div>
        )
    }

    renderCard(
        dashboardContainer: DashboardContainer,
        type: string,
        path: string,
        icon: string,
        title: string,
        desc: string,
        buttonText?: string,
        field?: string,
        value?: any
    ) {
        return (
            <SettingsCard
                onClick={() => {
                    if (type === 'link') {
                        this.props.history.push(path);
                    }
                }}
            >
                <div className="layout horizontal center">
                    <i className={icon} />
                    <h4>{title}</h4>
                </div>
                <p className="m-t m-b">{desc}</p>
                {type === 'link' && (
                    <div className="layout horizontal center-justified m-t-sm">
                        <Button>{buttonText || 'Edit'}</Button>
                    </div>
                )}
                {type === 'toggle' && typeof value !== 'undefined' && (
                    <div>
                        <Toggle
                            id={title}
                            defaultChecked={value || false}
                            onChange={() => {
                                let change = {};
                                if (
                                    field &&
                                    typeof dashboardContainer.state.data !==
                                        'undefined'
                                ) {
                                    change[field] = !dashboardContainer.state
                                        .data[field];
                                    dashboardContainer.handleDataUpdate(change);
                                    SettingsApi.update(
                                        Setting.Offers_ContinueOnWeekdays,
                                        change[field] ? 'true' : 'false'
                                    );
                                    if (change[field]) {
                                        localStorage.setItem("offersContinueOnWeekdays", "true")
                                    } else {
                                        localStorage.removeItem("offersContinueOnWeekdays");
                                    }
                                }
                            }}
                        />
                    </div>
                )}
            </SettingsCard>
        );
    }
}

const SettingsCard = styled.div`
    max-width: 250px;
    margin: 15px;
    padding: 15px;
    background: white;
    box-shadow: 0px 0px 6px 0 #dadada;
    border-radius: 16px;
    cursor: pointer;

    &:hover {
        outline: 2px solid ${theme.colours.green}BB;
    }

    h4 {
        font-weight: 600;
        margin-left: 12px;
    }

    p {
        font-size: 14px;
    }

    i {
        font-size: 32px;
    }

    .btn {
        margin-top: 5px;

        &:active {
            background: white;
            border-color: #eaeaea;
            box-shadow: none;
        }

        &:focus {
            outline: 0;
        }
    }
`;

export default connect((state: ApplicationState) => {
    return {
        userProfileState: state.profile.authenticatedUserProfile
    };
}, ProfileState.actionCreators)((props) => (
    <Subscribe to={[DashboardContainer]}>
        {(dashboard: DashboardContainer) => (
            // @ts-ignore
            <Settings {...props} dashboardContainer={dashboard} />
        )}
    </Subscribe>
)) as any;