/* eslint-disable */
import React from 'react';
import moment from 'moment';
import styled, { keyframes } from 'styled-components';
import { Table } from 'react-bootstrap';
import {
    LineChart,
    Line,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    Label,
    BarChart,
    Bar,
    PieChart,
    Pie,
    Cell
} from 'recharts';
import { connect } from 'react-redux';
import { History } from 'history';

import history from '../../history';
import SvgQuestion from '../../components/svg/question';
import SvgAssignment from '../../components/svg/assignment';
import SvgTeamwork from '../../components/svg/teamwork';
import SvgAnalytics from '../../components/svg/analytics';
import SvgUpdates from '../../components/svg/updates';
import SvgCall from '../../components/svg/call';
import SvgAddcontact from '../../components/svg/addcontact';
import SvgInterview from '../../components/svg/interview';
import theme from '../../css/theme';
import DashboardContainer from '../../state-containers/dashboard.container';
import TeamsContainer from '../../state-containers/teams.container';
import { fromNow } from '../../constants';
import ProfileProgress from '../profile-progress/ProfileProgress';
import { Icon, IconType, Item, TaskItem } from '../overlayMenu/OverlayMenu';
import { UserTypeContainer } from '../../state-containers/user-type.container';
import { ApplicationState } from '../../store';
import * as ProfileState from '../../store/Profile';
import * as EventsState from '../../store/Events';
import { Subscribe } from '../../unstated-fork/unstated';
import Tasks from '../profile-progress/Tasks';
import TaskList from './TaskList';
import LookupsAPI from 'src/api/lookups/Lookups';

type Props = {
    dashboardContainer: DashboardContainer;
    teamsContainer: TeamsContainer;
    userType: UserTypeContainer;
    history: History;
    userProfileState: ProfileState.UserProfileModel;
    tasks: TaskItem[];
} & typeof ProfileState.actionCreators &
    typeof EventsState.actionCreators;

interface State {
    isResizing: boolean;
    graphWidthOffersSent: number;
    graphWidthAvailabilityUpdates: number;
    items: { [key: string]: Item };
}

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

class Dashboard extends React.Component<Props, State> {
    widgetOffersSent: React.RefObject<HTMLDivElement>;
    widgetUpdates: React.RefObject<HTMLDivElement>;

    constructor(props: Props) {
        super(props);

        this.widgetOffersSent = React.createRef();
        this.widgetUpdates = React.createRef();

        this.state = {
            isResizing: false,
            graphWidthOffersSent: 0,
            graphWidthAvailabilityUpdates: 0,
            items: {
                Chat: {
                    icon: {
                        path: 'fa-comments-o',
                        type: IconType.FontAwesome
                    } as Icon,
                    notificationNumber: 0,
                    tooltipText: 'Chat'
                } as Item,
                Guide: {
                    icon: {
                        path: 'fa-list',
                        type: IconType.FontAwesome
                    } as Icon,
                    notificationNumber: 0,
                    tooltipText: 'Guide'
                } as Item
            }
        };
    }

    async componentDidMount() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    resizeHandle?: any;

    handleResize = () => {
        clearTimeout(this.resizeHandle);

        if (!this.state.isResizing) {
            this.setState({
                isResizing: true
            });
        }

        this.resizeHandle = setTimeout(() => {
            if (this.widgetOffersSent && this.widgetOffersSent.current) {
                this.setState({
                    graphWidthOffersSent:
                        this.widgetOffersSent.current!.clientWidth - 40,
                    graphWidthAvailabilityUpdates:
                        this.widgetUpdates.current!.clientWidth - 40,
                    isResizing: false
                });
            }
        }, 500);
    };

    private TasksRef: typeof Tasks | null | undefined;

    public onClose() {
        if (this.TasksRef) {
            this.TasksRef.onClose();
        }
    }

    render() {
        const { dashboardContainer: container } = this.props;
        const { teamsContainer } = this.props;

        if (window.location.href.indexOf("/reports") !== -1) {
            return (
                <Wrapper>
                    <div className="stats layout horizontal wrap">
                        <div
                            className="flex layout vertical center-center clickable"
                            onClick={() => {
                                // container.toggleDismissed
                                let nextStep: HTMLElement = document.querySelector(
                                    '.tasks .pulse'
                                ) as HTMLElement;
                                if (nextStep) nextStep.click();
                            }}
                            style={{ minHeight: '236px' }}
                        >
                            {this.renderProfile(container)}
                            {/* <span>Return to guide?</span> */}
                        </div>
                        <div
                            className="flex layout vertical center-center clickable"
                            onClick={() =>
                                history.push(
                                    container.totalContacts
                                        ? '/offers?create=true'
                                        : '/invite'
                                )
                            }
                        >
                            {container.totalContacts ? (
                                <>
                                    <h4>
                                        {
                                            container.state.data!
                                                .contactsAvailableToday
                                        }
                                    </h4>
                                    <label>Workers available today</label>
                                    <span>Create offer?</span>
                                </>
                            ) : (
                                <>
                                    <SvgInterview />
                                    <label>
                                        Invite contacts to view their availability
                                    </label>
                                    <span>Invite contacts now?</span>
                                </>
                            )}
                        </div>
                        <div
                            className="flex layout vertical center-center offer-pie clickable"
                            style={{ minHeight: '236px' }}
                            onClick={() =>
                                history.push(
                                    container.totalContacts
                                        ? '/availability'
                                        : '/invite'
                                )
                            }
                        >
                            {container.totalContacts ? (
                                <>
                                    <label
                                        style={{
                                            marginBottom: '20px',
                                            marginTop: '0'
                                        }}
                                    >
                                        Contacts
                                    </label>
                                    <div>
                                        <PieChart width={450} height={175}>
                                            <Pie
                                                dataKey="value"
                                                isAnimationActive={false}
                                                data={this.dataContactTypes}
                                                outerRadius={80}
                                                innerRadius={40}
                                                paddingAngle={3}
                                                fill="#8884d8"
                                                label={(ev) =>
                                                    `⠀${ev.name}: ${ev.value}⠀`
                                                }
                                            >
                                                {this.dataContactTypes.map(
                                                    (_, index) => (
                                                        <Cell
                                                            key={`cell-${index}`}
                                                            fill={
                                                                COLORS[
                                                                    index %
                                                                        COLORS.length
                                                                ]
                                                            }
                                                        />
                                                    )
                                                )}
                                            </Pie>
                                        </PieChart>
                                        <div className="offer-total">
                                            {container.totalContacts}
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <SvgAddcontact />
                                    <label>You don't have any contacts</label>
                                    <span>Invite contacts now?</span>
                                </>
                            )}
                        </div>
                    </div>
                    <div className="stats layout horizontal">
                        <div
                            className="flex layout vertical center-center clickable"
                            ref={this.widgetOffersSent}
                            onClick={() =>
                                history.push(
                                    this.hasOffers
                                        ? '/offers'
                                        : '/offers?create=true'
                                )
                            }
                            style={{ minHeight: '206px' }}
                        >
                            {this.hasOffers ? (
                                <>
                                    <label style={{ marginBottom: '20px' }}>
                                        Offers sent
                                    </label>
                                    <div style={{ minHeight: '300px' }}>
                                        {!this.state.isResizing && (
                                            <LineChart
                                                width={
                                                    this.state.graphWidthOffersSent
                                                }
                                                height={300}
                                                data={
                                                    container.state.data!.offersSent
                                                }
                                                margin={{
                                                    top: 5,
                                                    right: 25,
                                                    left: 0,
                                                    bottom: 10
                                                }}
                                            >
                                                <Line
                                                    type="linear"
                                                    dataKey="count"
                                                    stroke="#8884d8"
                                                />
                                                <CartesianGrid stroke="#ccc" />
                                                <XAxis dataKey="week">
                                                    <Label
                                                        offset={0}
                                                        position="bottom"
                                                        value="Week"
                                                    />
                                                </XAxis>
                                                <YAxis />
                                                <Tooltip />
                                            </LineChart>
                                        )}
                                    </div>
                                </>
                            ) : (
                                <>
                                    <SvgCall />
                                    <label>
                                        You haven't sent any offers recently
                                    </label>
                                    <span>Send a new offer</span>
                                </>
                            )}
                        </div>
                        <div
                            className="flex layout vertical center-center clickable"
                            ref={this.widgetUpdates}
                            onClick={() =>
                                history.push(
                                    this.hasAvailabilityUpdates
                                        ? '/availability'
                                        : '/invite'
                                )
                            }
                            style={{ minHeight: '206px' }}
                        >
                            {this.hasAvailabilityUpdates ? (
                                <>
                                    <label style={{ marginBottom: '20px' }}>
                                        Availability updates
                                    </label>
                                    <div style={{ minHeight: '300px' }}>
                                        {!this.state.isResizing && (
                                            <BarChart
                                                width={
                                                    this.state
                                                        .graphWidthAvailabilityUpdates
                                                }
                                                height={300}
                                                data={
                                                    container.state.data!
                                                        .availabilityUpdates
                                                }
                                                margin={{
                                                    top: 5,
                                                    right: 25,
                                                    left: 0,
                                                    bottom: 10
                                                }}
                                            >
                                                <CartesianGrid stroke="#ccc" />
                                                <XAxis dataKey="week">
                                                    <Label
                                                        offset={0}
                                                        position="bottom"
                                                        value="Week"
                                                    />
                                                </XAxis>
                                                <YAxis />
                                                <Tooltip />
                                                <Bar
                                                    dataKey="count"
                                                    fill="#8884d8"
                                                />
                                            </BarChart>
                                        )}
                                    </div>
                                </>
                            ) : (
                                <>
                                    <SvgAnalytics />
                                    <label>
                                        You haven't had any recent updates
                                    </label>
                                    <span>Invite more contacts?</span>
                                </>
                            )}
                        </div>
                        <div
                            className={`flex layout vertical center ${!this
                                .hasRecentUpdates && 'center-justified clickable'}`}
                            onClick={() => {
                                if (this.hasRecentUpdates == false)
                                    history.push('/invite');
                            }}
                            style={{ minHeight: '206px' }}
                        >
                            {this.hasRecentUpdates ? (
                                <>
                                    <label style={{ marginBottom: '20px' }}>
                                        Recent updates
                                    </label>
                                    <div
                                        style={{
                                            width: '100%',
                                            maxHeight: '300px',
                                            overflowY: 'auto'
                                        }}
                                    >
                                        <Table striped responsive hover bordered>
                                            <thead>
                                                <tr>
                                                    <th>Worker</th>
                                                    <th>Last updated</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {container.state.data!.recentUpdates.map(
                                                    (u, i) => (
                                                        <tr
                                                            key={i}
                                                            onClick={() =>
                                                                history.push(
                                                                    `/contact/${u.contactId}`
                                                                )
                                                            }
                                                        >
                                                            <td>{u.contactName}</td>
                                                            <td>
                                                                {fromNow(
                                                                    moment(
                                                                        u.shareDate
                                                                    )
                                                                )}
                                                            </td>
                                                        </tr>
                                                    )
                                                )}
                                            </tbody>
                                        </Table>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <SvgUpdates />
                                    <label>
                                        No recent contact availability updates
                                    </label>
                                    <span>Invite more contacts?</span>
                                </>
                            )}
                        </div>
                    </div>
                    <div className="stats layout horizontal">
                        <div
                            className="flex layout vertical center-center offer-pie clickable"
                            onClick={() =>
                                history.push(
                                    this.totalFutureOffers
                                        ? '/offers'
                                        : '/offers?create=true'
                                )
                            }
                            style={{ minHeight: '206px' }}
                        >
                            {this.totalFutureOffers > 0 ? (
                                <>
                                    <label style={{ marginBottom: '20px' }}>
                                        Future offers
                                    </label>
                                    <div>
                                        <PieChart width={450} height={300}>
                                            <Pie
                                                dataKey="value"
                                                isAnimationActive={false}
                                                data={this.dataFutureOffers}
                                                outerRadius={80}
                                                innerRadius={35}
                                                paddingAngle={3}
                                                fill="#8884d8"
                                                label={(ev) =>
                                                    `⠀${ev.name} (${ev.value})⠀`
                                                }
                                            >
                                                {this.dataFutureOffers.map(
                                                    (_, index) => (
                                                        <Cell
                                                            key={`cell-${index}`}
                                                            fill={
                                                                COLORS[
                                                                    index %
                                                                        COLORS.length
                                                                ]
                                                            }
                                                        />
                                                    )
                                                )}
                                            </Pie>
                                        </PieChart>
                                        <div className="offer-total">
                                            {this.totalFutureOffers}
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <SvgQuestion />
                                    <label>You don't have any future offers</label>
                                    <span>Create offer?</span>
                                </>
                            )}
                        </div>
                        <div
                            className="flex layout vertical center-center clickable"
                            onClick={() =>
                                history.push(
                                    container.state.data!.futureOffers.confirmed
                                        ? '/offers'
                                        : '/offers?create=true'
                                )
                            }
                            style={{ minHeight: '206px' }}
                        >
                            {container.state.data!.futureOffers.assignments
                                .length ? (
                                <>
                                    <label style={{ marginBottom: '20px' }}>
                                        Future assignments
                                    </label>
                                    <div
                                        style={{
                                            width: '100%',
                                            maxHeight: '300px',
                                            overflowY: 'auto'
                                        }}
                                    >
                                        <Table striped responsive hover bordered>
                                            <thead>
                                                <tr>
                                                    <th>Start</th>
                                                    <th>Worker</th>
                                                    <th>Assignment</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {container.state.data!.futureOffers.assignments.map(
                                                    (a) => (
                                                        <tr key={a.id}>
                                                            <td>
                                                                {moment(
                                                                    a.start
                                                                ).format(
                                                                    'Do MMM - HH:mm'
                                                                )}
                                                            </td>
                                                            <td>
                                                                {a.confirmedWorker}
                                                            </td>
                                                            <td>{a.title}</td>
                                                        </tr>
                                                    )
                                                )}
                                            </tbody>
                                        </Table>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <SvgAssignment />
                                    <label>
                                        You don't have any confirmed assignments
                                    </label>
                                    <span>Create offer?</span>
                                </>
                            )}
                        </div>
                        <div
                            className="flex layout vertical center-center clickable"
                            onClick={() => {
                                history.push('/organisation/teams');
                            }}
                            style={{ minHeight: '206px' }}
                        >
                            <SvgTeamwork />
                            {container.state.data!.teams > 0 ? (
                                <>
                                    <h4 style={{ marginTop: '20px' }}>
                                        {container.state.data!.teams}
                                    </h4>
                                    <label>Teams in organization</label>
                                    <span>View teams?</span>
                                </>
                            ) : (
                                <>
                                    <label>You're missing out on Teams</label>
                                    <span>Find out more?</span>
                                </>
                            )}
                        </div>
                    </div>
                </Wrapper>
            );
        }

        return (
            <Wrapper>
                <TaskList />
            </Wrapper>
        )
    }

    renderProfile({
        completePercentageBronze,
        completePercentageSilver,
        completePercentageGold
    }: DashboardContainer) {
        return (
            <ProfileProgress
                {...{
                    completePercentageBronze,
                    completePercentageSilver,
                    completePercentageGold
                }}
            />
        );
    }

    get dataContactTypes() {
        if (!this.props.dashboardContainer) return [];

        return [
            {
                name: 'Receiving',
                value: this.props.dashboardContainer!.state.data!
                    .contactsReceiving
            },
            {
                name: 'Pending',
                value: this.props.dashboardContainer!.state.data!
                    .contactsPending
            }
        ];
    }

    get dataFutureOffers() {
        if (!this.props.dashboardContainer) return [];

        const {
            pending,
            confirmed,
            applied,
            withdrawn
        } = this.props.dashboardContainer.state.data!.futureOffers;

        const offers = [{ name: 'Pending', value: pending }];

        if (confirmed) {
            offers.push({ name: 'Confirmed', value: confirmed });
        }

        if (applied) {
            offers.push({ name: 'Applied for', value: applied });
        }

        if (withdrawn) {
            offers.push({ name: 'Withdrawn', value: withdrawn });
        }

        return offers;
    }

    get totalFutureOffers() {
        return this.dataFutureOffers.reduce((a, b) => a + b.value, 0);
    }

    get hasAvailabilityUpdates() {
        return (
            !!this.props.dashboardContainer &&
            this.props.dashboardContainer.state.data!.availabilityUpdates.some(
                (u) => !!u.count
            )
        );
    }

    get hasRecentUpdates() {
        return (
            !!this.props.dashboardContainer &&
            !!this.props.dashboardContainer.state.data!.recentUpdates.length
        );
    }

    get hasOffers() {
        return (
            !!this.props.dashboardContainer &&
            this.props.dashboardContainer.state.data!.offersSent.some(
                (o) => !!o.count
            )
        );
    }
}

export default connect(
    (state: ApplicationState) => {
        return {
            userProfileState: state.profile.authenticatedUserProfile
        };
    },
    { ...ProfileState.actionCreators, ...EventsState.actionCreators }
)((props) => (
    <Subscribe to={[DashboardContainer, UserTypeContainer, TeamsContainer]}>
        {(
            dashboard: DashboardContainer,
            userType: UserTypeContainer,
            teams: TeamsContainer
        ) => (
            <Dashboard
                // @ts-ignore
                {...props}
                dashboardContainer={dashboard}
                teamsContainer={teams}
                userType={userType}
            />
        )}
    </Subscribe>
)) as any;

const AnimWidget = keyframes`
    0% {
        transform: rotateY(-80deg);
        opacity: 0;
    }
    100% {
        transform: rotateY(0);
        opacity: 1;
    }
`;

const Wrapper = styled.div`
    margin-bottom: 40px;

    .stats {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: stretch;
        color: ${theme.colours.updatedge};

        > div {
            align-self: stretch;
            min-width: 260px;
            margin: 10px;
            padding: 30px 20px;
            text-align: center;
            background: white;
            border-radius: 7px;
            box-shadow: 0 0 4px 0px #ccc;
            /* animation: ${AnimWidget} 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
                both; */

            h4 {
                font-weight: bold;
                font-size: 32px;
            }

            label {
                font-weight: 600;
                font-size: 20px;
            }

            svg:not(.recharts-surface) {
                width: 70px;
                height: 70px;
            }

            > span {
                color: #00ec;
                font-weight: 600;
                font-style: italic;
                /* opacity: 0;
                transition: all 0.5s ease; */
            }

            &:hover {
                box-shadow: 0 0 5px 1px ${theme.colours.green};
            }

            .table-responsive {
                width: 100%;

                td {
                    text-align: left;
                }

                tbody > tr {
                    cursor: pointer;
                }
            }

            &.clickable {
                cursor: pointer;

                * {
                    cursor: pointer;
                }

                > label {
                    margin: 15px 0;
                }

                /* > span {
                    max-height: 0;
                }

                &:hover > span {
                    max-height: 20px;
                    opacity: 1;
                } */
            }
        }

        .offer-pie > div {
            position: relative;

            .offer-total {
                position: absolute;
                top: 50%;
                left: 50%;
                font-weight: bold;
                font-size: 26px;
                transform: translate(-50%, -50%);
            }

            .recharts-tooltip-wrapper {
                z-index: 2;
            }

            .recharts-pie-label-text {
                fill: #555;
            }
        }
    }
`;
