/* eslint-disable */
// TODO: Replace this type because the API cannot return a moment type
import { Moment } from 'moment';

import afetch from '../AuthenticatedFetch';
import AppConfig from '../../components/config/Config';
import { ContactAvailabilityOverviewDto, TimelineDTO } from './ResponseTypes';
import { PagedResponseDto } from '../BaseResponseTypes';
import * as ResponseTypes from './ResponseTypes';
import { StringDayIntervalDTO } from './RequestTypes';
import { EphemeralAccessTokenStorageKey } from '../../components/layout/EphemeralAccessLayout';
import { AvailabilityOrderingType, Skill } from 'src/store/Availability';
import Utilities from 'src/Utilities';

// API urls
const apiUrls = {
    getEventsForUserBetweenDatesInc(
        userId: string,
        start: Moment,
        end: Moment,
        page: number,
        pageSize: number
    ) {
        return `${
            AppConfig.Settings.api.baseUri
        }/publicapi/events/${userId}?start=${start
            .utc()
            .format()}&end=${end
            .utc()
            .format()}&page=${page}&pageSize=${pageSize}`;
    },
    getEventsForUserBetweenDatesIncNew(
        userId: string,
        start: Moment,
        end: Moment,
        page: number,
        pageSize: number
    ) {
        return `${
            AppConfig.Settings.api.externalUri
        }/timeline/flattened/${userId}?start=${start
            .utc()
            .format()}&end=${end
            .utc()
            .format()}&page=${page}&pageSize=${pageSize}`;
    },
    getTotalAvailabilityConsecutivelyForContactList(
        listId: number,
        start: Moment,
        end: Moment,
        consecutiveDays: number,
        keywords: string,
        hirerLocationId: string,
        sortOrder: AvailabilityOrderingType,
        page: number,
        limit: number,
        name: string,
        updatedAfter: string | null,
        postcode?: string,
        representingAgencyId?: string
    ) {
        const updatedAfterDate = updatedAfter ? new Date(updatedAfter) : null;
        const token = localStorage.getItem(EphemeralAccessTokenStorageKey);
        const postcodeQuery = postcode ? `&postcode=${postcode}` : "";
        const representingQuery = (representingAgencyId && representingAgencyId !== "0") ? `&representedByOrgName=${representingAgencyId}` : "";
        if (window.location.pathname.startsWith("/external/timesheet/rota/worker")) {
            return `${AppConfig.Settings.api.externalUri}/availability/timesheet/worker/${token}?start=${start.utc().format()}&end=${end.utc().format()}&daysToRepeat=${consecutiveDays}`;
        } else if (window.location.pathname.startsWith("/external/timesheet")) {
            return `${AppConfig.Settings.api.externalUri}/availability/timesheet/${token}?start=${start.utc().format()}&end=${end.utc().format()}&daysToRepeat=${consecutiveDays}&keywords=${keywords}${updatedAfterDate ? ("&updatedAfter=" + updatedAfterDate.toISOString()) : ""}&name=${name.trim()}${postcodeQuery}`;
        } else if (window.location.pathname.startsWith("/external/schedule")) {
            return `${AppConfig.Settings.api.externalUri}/availability/schedule/${token}?start=${start.utc().format()}&end=${end.utc().format()}&daysToRepeat=${consecutiveDays}&keywords=${keywords}${updatedAfterDate ? ("&updatedAfter=" + updatedAfterDate.toISOString()) : ""}&name=${name.trim()}${postcodeQuery}`;
        } else {
            return `${AppConfig.Settings.api.externalUri}/availability/getperdailyintervalForList?start=${start.utc().format()}&end=${end.utc().format()}&daysToRepeat=${consecutiveDays}&listId=${listId}&keywords=${keywords}&locationId=${hirerLocationId}&orderType=${sortOrder}&page=${page}&limit=${limit}${updatedAfterDate ? ("&updatedAfter=" + updatedAfterDate.toISOString()) : ""}&name=${name.trim()}${postcodeQuery}${representingQuery}`;
        }
    },
    getEventsFromContacts(
        start: string,
        end: string,
    ) {
        return `${
            AppConfig.Settings.api.externalUri
        }/timeline?start=${start}&end=${end}`;
    },
    getTotalAvailabilityconsecutivelyForUser(
        userId: string,
        start: Moment,
        end: Moment,
        days: number
    ) {
        return `${
            AppConfig.Settings.api.baseUri
        }/publicapi/availability/user/${userId}/consecutiveDays?start=${start
            .utc()
            .format()}&end=${end.utc().format()}&days=${days+1}`;
    },
    getAvailabilityForListByIntervals() {
        return `${AppConfig.Settings.api.externalUri}/availability/getPerinterval`;
    },
    removeWorkers(block: boolean) {
        return `${AppConfig.Settings.api.externalUri}/workers/${ block ? 'block' : 'disconnect' }`;
    },
    postAvailabilityImage() {
        return `${AppConfig.Settings.api.externalUri}/contact/availabilityAttachment`;
    }
};

/** Profile API */
const self = {
    getTotalAvailabilityConsecutivelyForContactList(
        listId: number,
        start: Moment,
        end: Moment,
        days: number,
        keywords: Skill[],
        hirerLocationId: string,
        sortOrder: AvailabilityOrderingType,
        page: number,
        limit: number,
        name: string,
        updatedAfter: string | null,
        postcode?: string,
        representingAgencyId?: string
    ) {
        return afetch.request<
            null,
            {
                data: ResponseTypes.ContactDayAvailabilityDto[],
                paging: {
                    page: number
                    totalPages: number
                    totalRows: number
                    hasMorePages: boolean
                }
            }
        >(
            apiUrls.getTotalAvailabilityConsecutivelyForContactList(
                listId,
                start,
                end,
                days,
                keywords.map(item => item.text).join("||"),
                hirerLocationId,
                sortOrder,
                page,
                limit,
                name,
                updatedAfter,
                postcode,
                representingAgencyId
            ),
            window.location.pathname.startsWith("/external") ? 'GET' : 'POST',
            null,
            'Failed to retrieve contact list availability.',
            undefined,
            false,
            true,
            true
        );
    },
    getTotalAvailabilityConsecutivelyForUser(
        userId: string,
        start: Moment,
        end: Moment,
        days: number
    ) {
        return afetch.request<null, ResponseTypes.OccupanciesDto>(
            apiUrls.getTotalAvailabilityconsecutivelyForUser(
                userId,
                start,
                end,
                days
            ),
            'GET',
            null,
            'Failed to get availability'
        );
    },
    /**
     * Retrieves events across a range inclusively for a given user
     * @param userId - User id
     * @param start - Start date/time
     * @param end - End date/time
     */
    getEventsForUserBetweenDatesInc: (
        userId: string,
        start: Moment,
        end: Moment
    ) => {
        return afetch
            .request<null, PagedResponseDto<ResponseTypes.TimelineEventDto>>(
                apiUrls.getEventsForUserBetweenDatesInc(
                    userId,
                    start,
                    end,
                    1,
                    200
                ),
                'GET',
                null,
                'Failed to retrieve events'
            )
            .then((timelineEvents) => {
                return timelineEvents;
            });
    },

    getEventsForUserBetweenDatesIncNew: (
        userId: string,
        start: Moment,
        end: Moment
    ) => {
        return afetch
            .request<null, ResponseTypes.TimelineEventNewDto[]>(
                apiUrls.getEventsForUserBetweenDatesIncNew(
                    userId,
                    start,
                    end,
                    1,
                    200
                ),
                'GET',
                null,
                'Failed to retrieve events'
            )
            .then((timelineEvents) => {
                return timelineEvents;
            });
    },

    getAvailabilityForListByIntervals: (
        listId: number,
        intervals: StringDayIntervalDTO[],
        skills: Skill[],
    ) => {
        let skillsStr = skills ? skills.map(item => item.id).join("||") : "";
        return afetch.request<
            {
                intervals: StringDayIntervalDTO[],
                groupId: number,
                keywords: string
            },
            ContactAvailabilityOverviewDto[]
        >(
            apiUrls.getAvailabilityForListByIntervals(),
            'POST',
            {
                groupId: listId,
                intervals: intervals,
                keywords: skillsStr
            },
            'Failed to get availability for list by intervals'
        );
    },
    removeWorkers: (workerIds: string[], block: boolean) => {
        return afetch.request<string[], {}>(
            apiUrls.removeWorkers(block),
            'POST',
            workerIds,
            'Failed to remove all workers'
        );
    },
    getEventsFromContacts: (contactIds: string[], start: string, end: string) => {
        return afetch.request<string[], TimelineDTO[]>(
            apiUrls.getEventsFromContacts(start, end),
            'POST',
            contactIds,
            'Failed to get contacts'
        );
    },
    postAvailabilityImage: (contactIds: string[], base64String: string) => {
        return afetch.request<{
            contactIds: string[],
            imageBase64: string
        }, null>(
            apiUrls.postAvailabilityImage(),
            'POST',
            {
                contactIds: contactIds,
                imageBase64: base64String
            },
            'Failed to get contacts'
        );
    }
};

export default self;
