import styled from 'styled-components';

import theme from '../../css/theme';

const OfferCard = styled.div`
    max-width: 520px;
    height: auto;
    max-height: 100%;
    margin: 0 auto;
    padding: 20px;
    overflow: scroll;
    background: white;
    border-radius: 12px;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 4;
    width: calc(100vw - 30px);
    box-shadow: 0px 0px 40px 0px ${theme.colours.updatedge};
    transform: translate(-50%, -50%);
    overflow-x: hidden;
    overflow-y: auto;

    ::before {
        margin: -20px -20px 10px;
        padding: 15px;
        color: white;
        font-weight: 600;
        font-size: 11px;
        letter-spacing: 0.5px;
        text-align: center;
        text-transform: uppercase;
    }

    &.favourite::before {
        background: #e9b21e;
        content: 'favourite';
    }

    &.pending::before {
        background: #676767;
        content: 'pending';
    }

    &.confirmed::before {
        background: #43b961;
        content: 'confirmed';
    }

    &.agency-confirmed::before {
        background: #0a6847;
        content: 'confirmed agency booking';
    }

    &.noLongerConfirmed::before {
        background: #b22222;
        color: #ffffff;
        content: 'No Longer Confirmed';
    }

    &.withdrawn::before {
        background: #ff5454;
        content: 'withdrawn';
    }

    &.complete::before {
        background: #c2fcd1;
        content: 'complete';
    }

    &.historic::before {
        background: #8e8e8e;
        content: 'historic';
    }

    > .fa-times {
        position: absolute;
        top: 13px;
        right: 15px;
        font-size: 20px;
        color: white;
        z-index: 2;
    }

    > h4 {
        font-weight: 600;
        font-size: 20px;
    }

    h5 {
        font-size: 1.1em;
        font-weight: 600;
        margin-top: 0;
    }

    .worker-selector-wrapper {
        & > div {
            margin: 0 0 10px 0;
        }
    }

    .worker-existing {
        & > div {
            margin: 5px 0 5px 0 !important;
        }
    }

    .details {
        display: flex;
        margin-top: 10px;
        position: relative;

        & > div {
            flex-basis: calc(50% - 7.5px);

            &:first-child {
                margin-right: 15px;
            }

            & > div {
                margin-bottom: 16px;

                p {
                    margin-top: 0;
                }

                button {
                    width: 100%;
                    display: block;
                    border: none;
                    border-radius: 4px;
                    padding: 6px;
                    background: #e9e9e9;
                }
            }
        }
    }

    label {
        margin-top: 5px;
    }

    table {
        margin-bottom: 0;

        tbody > tr {
            border-top: 1px solid #ddd;

            &:last-child {
                border-bottom: 1px solid #ddd;
            }

            &.selectable:not(.selected):hover {
                background: #f0f8fc;
            }

            &.selected {
                background: #def;
            }
        }

        td {
            vertical-align: middle;
        }
    }

    .vacancy-period {
        display: block;
        font-size: 14px;
    }

    .first {
        max-height: 700px;
        overflow: auto;
        transition: max-height 1.5s ease-out;
    }

    .second {
        max-height: 0;
        overflow: hidden;
        transition: max-height 1.5s ease-in;
        transition-delay: 0.75s;
    }

    &.confirmation {
        .first {
            max-height: 0;
            overflow: hidden;
        }

        .second {
            max-height: 700px;
            overflow-y: auto;
        }
    }

    .options {
        display: flex;

        & > div,
        & > button {
            flex-basis: 25%;
            display: inline-block;

            &:not(:last-child) {
                margin-right: 10px;
            }
        }

        & > div {
            height: 100%;
        }

        button {
            padding: 10px;
            margin-left: 0;
            white-space: unset !important;
            width: 100%;
            border-radius: 6px;
        }

        i,
        span {
            display: block;
        }

        i {
            margin-bottom: 6px;
            font-size: 20px;
        }

        span {
            font-size: 12px;
            line-height: 16px;
            max-width: 80px;
            margin: auto;
        }
    }

    @media (max-width: 550px) {
        .details {
            display: block !important;

            & > div {
                margin-right: 0 !important;
                margin-bottom: 15px;
            }
        }

        .location-details {
            position: absolute;
            top: 0;
            left: 0;
            margin: 0;
            padding: 10px;

            p {
                font-weight: 700;
            }

            h5 {
                display: none;
            }

            button {
                background-color: ${theme.colours.blue2} !important;
                color: white;
            }
        }

        .location-wrapper {
            height: 100px !important;

            img {
                height: 100px;
                width: 100%;
                object-fit: cover;
            }
        }

        .options {
            flex-wrap: wrap;

            & > div {
                margin: 0;
            }

            & > div,
            & > button,
            & > div > button {
                display: flex;
                align-items: center;

                span {
                    padding-left: 10px;
                    max-width: unset;
                }

                i {
                    margin: 0;
                }
            }

            & > div {
                margin-bottom: 0 !important;
            }

            button {
                flex-basis: calc(50% - 5px);
                margin: 10px 10px 0 0 !important;

                &:nth-child(even) {
                    margin-right: 0 !important;
                }
            }
        }
    }
`;

export default OfferCard;
